import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../CardDialog';

import { useStyles } from '../../assets/styles';

function PdfPreviewDialog({ fileUrl, onSubmit, disabled, onCancel, title, submitTitle }) {
  const classes = useStyles();
  const [pdfLoaded, setPdfLoaded] = useState(false);

  return (
    <CardDialog isDialog fullWidth title={title} onClose={onCancel} maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item md={12}>
          <object
            data={fileUrl}
            style={{ height: '70vh', width: '100%' }}
            type="application/pdf"
            onLoad={() => setPdfLoaded(true)}
          >
            {title}
          </object>
        </Grid>
        <Grid item md={12}>
          <div className={classes.buttonsContainer}>
            <div className={classes.cardDivRow}>
              {onCancel && <CancelButton disabled={!pdfLoaded || disabled} onClick={onCancel} />}
              <Button variant="contained" disabled={!pdfLoaded || disabled} color="primary" onClick={onSubmit}>
                {submitTitle}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </CardDialog>
  );
}

PdfPreviewDialog.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  submitTitle: PropTypes.string.isRequired,
};

export default PdfPreviewDialog;
