import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { noop } from 'lodash';

import { reportErrorInProductionOrThrow } from '../../../Utils';
import mixpanel from '../../CmsMain/mixpanel';

export const getEnumObjectFromArray = (valuesArray) =>
  new Proxy(Object.fromEntries(valuesArray.map((value) => [value, value])), {
    get: (target, prop) => {
      if (!(prop in target)) {
        reportErrorInProductionOrThrow(new Error(`Invalid enum value: ${prop}`));
      }
      return target[prop];
    },
    set: () => {
      reportErrorInProductionOrThrow(new Error('Cannot set enum values'));
      return false;
    },
  });

export const MUI_COLOR_STRINGS = ['default', 'inherit', 'primary', 'secondary'];

export const getMuiComponentWithPointerEvents = (MuiComponent) =>
  withStyles({
    root: {
      '&.Mui-disabled': {
        pointerEvents: 'auto',
      },
    },
  })(MuiComponent);

export const FsWrappersSharedProptypes = {
  ignorePermissions: PropTypes.bool,
};

export const FsWrappersSharedDefaultProptypes = {
  ignorePermissions: false,
};

export const FsWrappersWithTooltipSharedProptypes = {
  ...FsWrappersSharedProptypes,
  tooltipText: PropTypes.string,
};

export const mixpanelOnclickFactory = ({ mixpanelEvent, mixpanelEventData = {}, onClick = noop }) => {
  return (e) => {
    if (mixpanelEvent) {
      mixpanel.track(mixpanelEvent, mixpanelEventData);
    }
    onClick(e);
  };
};
