import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { policyPopulate } from '../../PolicyUtils';
import { usePositionLeftZenDesk } from '../hooks/useZenDesk';
import LoadingIndicator from '../LoadingIndicator';
import { PolicyContextProvider } from '../PolicyContainer';
import PolicySearchContainer from '../PolicySearch';
import TravelFnol from '../Travel/TravelFnol';

import { InsuredDetailsFormDialog } from './FnolScreen';
import LossWithoutPolicyCard from './LossWithoutPolicyCard';

import { useStyles } from '../../assets/styles';

function TravelFnolScreen({ organization }) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [manuallyFillingInsuredDetails, setManuallyFillingInsuredDetails] = useState(false);
  const [chosenPolicy, setChosenPolicy] = useState(null);
  const [isFetchingPreSelectedPolicy, setIsFetchingPreSelectedPolicy] = useState(false);
  const [showLossWithoutPolicyCard, setShowLossWithoutPolicyCard] = useState(true);
  const [selectedSubOrgId, setSelectedSubOrgId] = useState(undefined);

  usePositionLeftZenDesk();

  const handleLossWithoutPolicy = ({ subOrgId }) => {
    setSelectedSubOrgId(subOrgId);
    setManuallyFillingInsuredDetails(true);
    setShowLossWithoutPolicyCard(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    if (searchParams.policyId) {
      setIsFetchingPreSelectedPolicy(true);
      handlePreSelectedPolicy(searchParams.policyId);
    }
  }, [location]);

  const handlePreSelectedPolicy = async (policyId) => {
    const { data } = await axios.get(`api/v1/policies/${policyId}`);
    setChosenPolicy(policyPopulate(data));
    setIsFetchingPreSelectedPolicy(false);
  };

  const handleInsuredDetailsFilled = (insuredDetails) => {
    const newManualPolicy = {
      type: 'travel_policy',
      lob: 'travel_policy',
      is_manual: true,
      id: uuidv4(),
      contacts: [insuredDetails],
      organization_id: organization.id,
      client: organization.name,
      insured_contact: insuredDetails,
      insured_contact_id: insuredDetails.id,
      insured_contact_full_name: insuredDetails.full_name,
      insured_property_location: {
        address1: insuredDetails.street_address1,
        address2: insuredDetails.street_address2,
        city: insuredDetails.city,
        county: insuredDetails.county,
        state: insuredDetails.state,
        country: insuredDetails.country,
        zipcode: insuredDetails.zipcode,
      },
    };

    setChosenPolicy(newManualPolicy);
    setManuallyFillingInsuredDetails(false);
  };

  const handleFnolSubmitted = (claimId) => {
    setChosenPolicy(null);
    history.push(`/claims/${claimId}`);
  };

  if (manuallyFillingInsuredDetails) {
    return (
      <InsuredDetailsFormDialog
        onCancel={() => {
          setManuallyFillingInsuredDetails(false);
          setShowLossWithoutPolicyCard(true);
        }}
        onInsuredDetailsFilled={handleInsuredDetailsFilled}
      />
    );
  }

  if (isFetchingPreSelectedPolicy) {
    return <LoadingIndicator />;
  }

  if (!chosenPolicy) {
    return (
      <Grid container justify="center" spacing={3}>
        <Grid item xs={8}>
          <div className={classes.cardDivRow}>
            <PolicySearchContainer
              enableSelectingRelatedClaim
              choosePolicyFromRelatedClaimsText="Report New Loss"
              onSelectPolicy={(policy, _) => setChosenPolicy(policy)}
              onPolicyNotFound={() => setManuallyFillingInsuredDetails(true)}
              policyTypes={['travel_policy']}
              allowSearchByAddress
              // onFnolDraftSelected={(values) => console.log('Draft values:', values)}
            />
          </div>
        </Grid>
        {showLossWithoutPolicyCard && (
          <Grid item xs={8}>
            <LossWithoutPolicyCard onTakeLoss={handleLossWithoutPolicy} />
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <PolicyContextProvider policy={chosenPolicy}>
      <TravelFnol
        onSubmit={handleFnolSubmitted}
        subOrganizationId={selectedSubOrgId}
        // onSubmitDraft={(values) => console.log('Draft values:', values)}
      />
    </PolicyContextProvider>
  );
}

TravelFnolScreen.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default TravelFnolScreen;
