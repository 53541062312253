import React from 'react';
import { bool, func, string } from 'prop-types';
import { noop } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import HoverChangeField from '../HoverChangeField';
import SocialSecurityNumberFormat from '../SocialSecurityNumberFormat';
import { ShowOnlyTextField } from '../TextFieldFormik';

import { governmentIdValidationSchema, isUkRegion, isUsRegion, LABELS } from './utils';

import { useStyles } from '../../assets/styles';

const GovernmentIdFieldHover = ({ id, value = null, disabled = false, onHoverUpdate = noop }) => {
  const classes = useStyles();
  const label = isUkRegion() ? LABELS.NIN : LABELS.SSN;
  const inputProps = isUsRegion() ? { inputComponent: SocialSecurityNumberFormat } : undefined;

  // Currently we only showing this field for SSN and NIN ids.
  // TODO: Change this to support multiple regions.
  if (!isUsRegion() && !isUkRegion()) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid item xs={6}>
        <HoverChangeField
          id={id}
          name="government_id"
          label={label}
          value={value}
          onUpdate={onHoverUpdate}
          disabled={disabled}
          validationSchema={governmentIdValidationSchema}
          fullWidth
          width="450px"
          inputProps={inputProps}
        >
          <ShowOnlyTextField classes={classes} label={label} showOnlyValueComponent={value} width="100%" />
        </HoverChangeField>
      </Grid>
    </Grid>
  );
};

GovernmentIdFieldHover.propTypes = {
  id: string.isRequired,
  value: string,
  disabled: bool,
  showOnly: bool,
  onHoverUpdate: func,
};

export default GovernmentIdFieldHover;
