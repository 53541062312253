import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '~/components/core/TextComponents';

import { FsTooltip } from '../index';

import styles from './emailsDisplay.module.scss';

const EmailsDisplay = ({ email_addresses, colorVariant, variant, weight }) => {
  return (
    <Text colorVariant={colorVariant} variant={variant} weight={weight}>
      {email_addresses[0]}
      {email_addresses.length > 1 ? (
        <FsTooltip title={email_addresses.slice(1).join(', ')} placement="top">
          <span className={styles.domainCount}> +{email_addresses.length - 1}</span>
        </FsTooltip>
      ) : null}
    </Text>
  );
};

EmailsDisplay.propTypes = {
  colorVariant: PropTypes.string,
  variant: PropTypes.string,
  weight: PropTypes.string,
  email_addresses: PropTypes.arrayOf(PropTypes.string).isRequired,
};

EmailsDisplay.COLOR_VARIANTS = Text.COLOR_VARIANTS;
EmailsDisplay.VARIANTS = Text.VARIANTS;
EmailsDisplay.WEIGHTS = Text.WEIGHTS;

export default EmailsDisplay;
