import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const ChevronRightIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon width="14" height="14" viewBox="0 0 9 14" stroke="none" fill="none" {...props}>
    <path
      stroke={iconColor}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.05806 0.308058C1.30214 0.0639806 1.69786 0.0639806 1.94194 0.308058L8.19194 6.55806C8.43602 6.80214 8.43602 7.19786 8.19194 7.44194L1.94194 13.6919C1.69786 13.936 1.30214 13.936 1.05806 13.6919C0.813981 13.4479 0.813981 13.0521 1.05806 12.8081L6.86612 7L1.05806 1.19194C0.813981 0.947864 0.813981 0.552136 1.05806 0.308058Z"
      fill="#202020"
    />
  </SvgIcon>
);

ChevronRightIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default ChevronRightIcon;
