import React from 'react';
import PropTypes from 'prop-types';

import { ContactEntity } from '~/components/Contact';
import { SortableTable } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import useOrganization from '~/components/OrganizationContext';

const OrganizationContactsTable = ({
  contacts = [],
  contactsCount = 0,
  onSelectContact,
  onContactSearch,
  prevSearchParams,
  paginationProps,
}) => {
  const { organizationContactRolesDict, organizationMoiExpertises } = useOrganization();

  const columnData = [
    {
      id: 'full_name',
      label: 'Name',
      specialCell: (contact) => <ContactEntity contactId={contact.id} contactDisplayName={contact.full_name} />,
    },
    {
      id: 'role',
      label: 'Role',
      specialCell: (contact) => organizationContactRolesDict[contact.role].desc,
    },
    {
      id: 'moi_expertises',
      label: 'Expertises',
      specialCell: (contact) =>
        contact?.moi_expertises?.map((expertise) => organizationMoiExpertises[expertise].desc).join(', '),
      width: '200px',
    },
    {
      id: 'email',
      disableSort: true,
      label: 'Email',
      specialCell: (contact) =>
        contact.emails.length === 0
          ? ''
          : contact.emails[0].email + (contact.emails.length === 1 ? '' : ` (+${contact.emails.length - 1})`),
    },
    {
      id: 'phone',
      disableSort: true,
      label: 'Phone',
      specialCell: (contact) =>
        contact.phones.length === 0
          ? ''
          : contact.phones[0].phone + (contact.phones.length === 1 ? '' : ` (+${contact.phones.length - 1})`),
    },
    { id: 'full_address', disableSort: true, label: 'Address' },
    {
      id: 'select_contact',
      disableSort: true,
      numeric: false,
      rightPaddingOnly: true,
      width: 20,
      specialCell: (contact) => (
        <Button variant="contained" color="primary" onClick={() => onSelectContact(contact)}>
          Select
        </Button>
      ),
    },
  ];

  return (
    <SortableTable
      rows={contacts}
      columns={columnData}
      defaultOrderColumn={columnData.findIndex((column) => column.id === 'full_name')}
      order={paginationProps.order_by}
      stickyHeader
      paginationProps={{
        page: paginationProps.page,
        rowsPerPage: paginationProps.limit,
        onChangePage: (_, newPage) => {
          const newPaginationProps = {
            ...paginationProps,
            page: newPage,
          };

          onContactSearch(prevSearchParams, newPaginationProps);
        },
        count: contactsCount,
        onChangeRowsPerPage: (event) => {
          const newPaginationProps = {
            ...paginationProps,
            page: 0,
            limit: event.target.value,
          };

          onContactSearch(prevSearchParams, newPaginationProps);
        },
        rowsPerPageOptions: [10, 20, 30],
      }}
      onSortByColumn={(_, { id, order }) => {
        const newPaginationProps = {
          ...paginationProps,
          page: 0,
          sort_by: id,
          order_by: order,
        };

        onContactSearch(prevSearchParams, newPaginationProps);
      }}
    />
  );
};

OrganizationContactsTable.propTypes = {
  contacts: PropTypes.array,
  contactsCount: PropTypes.number,
  onSelectContact: PropTypes.func,
  onContactSearch: PropTypes.func,
  prevSearchParams: PropTypes.object,
  paginationProps: PropTypes.object,
};

export default OrganizationContactsTable;
