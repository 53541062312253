import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const UserLockIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8.251a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-4.5 3a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15.748a6 6 0 0 0-5.35 3.284.75.75 0 1 1-1.337-.68 7.5 7.5 0 0 1 13.375 0 .75.75 0 1 1-1.337.68A6 6 0 0 0 12 15.748Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.376 3.866a8.242 8.242 0 1 0 6.76 6.76.75.75 0 0 1 1.48-.249 9.743 9.743 0 1 1-7.99-7.99.75.75 0 1 1-.25 1.479Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.43 4.328a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h4.5v-1.5h-4.5Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.18 2.078c-.139 0-.316.068-.476.268a1.389 1.389 0 0 0-.275.857v1.125a.75.75 0 0 1-1.5 0V3.203c0-.649.205-1.296.604-1.794.402-.503.988-.83 1.646-.83.658 0 1.244.327 1.646.83.4.498.604 1.145.604 1.794v1.125a.75.75 0 0 1-1.5 0V3.203c0-.346-.111-.653-.275-.857-.16-.2-.337-.268-.475-.268Z"
      fill={iconColor}
    />
  </SvgIcon>
);

UserLockIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default UserLockIcon;
