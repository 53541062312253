import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { getLobDescription, getLobIcon } from '../../../Utils/lobUtils';
import useOrganization from '../../OrganizationContext';

import useLobOptions from './hooks/useLobOptions';

const LobRadioFormik = ({
  subOrganizationIds,
  lobFieldId,
  label,
  disabled,
  btnClassName,
  filterLobsFunc,
  isAllLobs,
  showOrgLevelLobs,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { subOrganizationEnabled, orgLevelSupportedLobs } = useOrganization();
  const { lobConfigurationsDict = {} } = useLobConfiguration();
  const isSubOrgDependent = subOrganizationEnabled && !isAllLobs && !showOrgLevelLobs;

  const { lobOptions: lobOptionsForNonSubOrgsCreation, isLoading } = useLobOptions({
    subOrganizationIds,
    filterLobsFunc,
    isAllLobs,
  });

  const lobOptions = showOrgLevelLobs ? orgLevelSupportedLobs : lobOptionsForNonSubOrgsCreation;

  useEffect(() => {
    if (!isLoading && values[lobFieldId] && !lobOptions.includes(values[lobFieldId])) {
      setFieldValue(lobFieldId, '');
    }
  }, [lobOptions, setFieldValue, values, lobFieldId, isLoading]);

  // Set default lob if there is only one lob
  useEffect(() => {
    if (!isLoading && !values[lobFieldId] && lobOptions?.length === 1) {
      setFieldValue(lobFieldId, lobOptions[0]);
    }
  }, [lobOptions, setFieldValue, values, lobFieldId, isLoading]);

  return (
    <RadioButtonGroupFormik
      label={label}
      id={lobFieldId}
      direction="row"
      options={lobOptions.map((lob) => ({
        text: getLobDescription(lob, lobConfigurationsDict),
        optionValue: lob,
        icon: getLobIcon({ lob, lobConfigurationsDict }),
        disabled: disabled || isLoading || (isSubOrgDependent && !subOrganizationIds.length),
      }))}
      btnClassName={btnClassName}
    />
  );
};

LobRadioFormik.propTypes = {
  subOrganizationIds: PropTypes.array.isRequired,
  lobFieldId: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  btnClassName: PropTypes.string,
  filterLobsFunc: PropTypes.func,
  isAllLobs: PropTypes.bool,
  showOrgLevelLobs: PropTypes.bool,
};

export default LobRadioFormik;
