import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import { Paper } from '@mui/material';

import colors from '../../../../theme/tailwind/colors';

import styles from './InnerTabs.module.scss';

const InnerDialogTabs = ({ tabs }) => {
  const [currTabValue, setCurrTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrTabValue(newValue);
  };

  // Get the currently selected tab's component
  const ActiveComponent = tabs[currTabValue]?.component;

  return (
    <>
      <Paper className="bg-initial relative" elevation={0}>
        <Tabs
          value={currTabValue}
          className={styles.innerDialogTabs}
          onChange={handleTabChange}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} style={{ color: tab.hasErrors ? colors.status.failed : '' }} />
          ))}
        </Tabs>
      </Paper>

      {ActiveComponent ? <ActiveComponent /> : null}
    </>
  );
};

InnerDialogTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
      hasErrors: PropTypes.bool,
    })
  ).isRequired,
};

export default InnerDialogTabs;
