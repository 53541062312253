const SIU_CRITERIA_DICT = {
  fire: {
    desc: 'Fire',
    claim_types: ['auto_claim', 'gl_claim', 'wc_claim'],
  },
  theft: {
    desc: 'Theft',
    claim_types: ['auto_claim', 'gl_claim', 'wc_claim'],
  },
  late_night_accident: {
    desc: 'Late Night Accident',
    claim_types: ['auto_claim', 'gl_claim', 'wc_claim'],
  },
  phantom_vehicle_involvement: {
    desc: 'Phantom Vehicle Involvement',
    claim_types: ['auto_claim', 'gl_claim', 'wc_claim'],
  },
  phantom_claimants: {
    desc: 'Phantom Claimants',
    claim_types: ['auto_claim', 'gl_claim', 'wc_claim'],
  },
  low_speed_impact_with_serious_injuries: {
    desc: 'Low Speed Impact With Serious Injuries',
    claim_types: ['auto_claim', 'gl_claim', 'wc_claim'],
  },
  delayed_reported_loss: {
    desc: 'Delayed Reported Loss',
    claim_types: ['auto_claim', 'gl_claim', 'wc_claim'],
  },
  material_misrepresentation: {
    desc: 'Material Misrepresentation',
    claim_types: ['auto_claim'],
  },
  date_of_loss_close_to_start_end_date_of_contract: {
    desc: 'Date of Loss Close to Start/End Date of Contract',
    claim_types: ['bike_claim', 'custom_claim'],
  },
  date_of_loss_close_to_endorsement: {
    desc: 'Date of Loss Close to Endorsement',
    claim_types: ['bike_claim', 'custom_claim'],
  },
  multiple_claims_close_to_each_other: {
    desc: 'Multiple Claims Close to Each Other',
    claim_types: ['bike_claim', 'custom_claim'],
  },
  known_in_datassur_rsr: {
    desc: 'Known in Datassur/RSR',
    claim_types: ['bike_claim', 'custom_claim'],
  },
  incident_with_relative_or_neighbor: {
    desc: 'Incident With Relative or Neighbor',
    claim_types: ['bike_claim', 'custom_claim'],
  },
  irregularities_in_documents: {
    desc: 'Irregularities in Documents',
    claim_types: ['bike_claim', 'custom_claim'],
  },
  quote_made_before_claim: {
    desc: 'Quote Made Before Claim',
    claim_types: ['bike_claim', 'custom_claim'],
  },
  strange_behavior_from_customer: {
    desc: 'Strange Behavior From Customer',
    claim_types: ['bike_claim', 'custom_claim'],
  },
  other: {
    desc: 'Other',
  },
} as const;

export default SIU_CRITERIA_DICT;
