import React from 'react';

import { FsIconButton } from '../../core';
import { PencilIcon } from '../../icons';
import { useLegalAction } from '../hooks/useLegalAction';
import { getLegalActionStateProps } from '../LegalActionsUtils';

import type { LegalActionStateProps } from './LegalActionsCompoentsTypes';

export const LegalActionEditButton: React.FC<{ onClick: () => void } & LegalActionStateProps> = ({
  onClick,
  ignoreLegalActionState,
}) => {
  const { legalAction } = useLegalAction();
  const { isDisabled, tooltipText } = getLegalActionStateProps(legalAction, ignoreLegalActionState);

  return (
    <FsIconButton onClick={onClick} disabled={isDisabled} tooltipText={tooltipText}>
      <PencilIcon />
    </FsIconButton>
  );
};
