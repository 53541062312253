import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const ArrowIntegerIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path d="M3 14.75L12 23.75L21 14.75H3Z" fill={iconColor} />
    <path d="M21 9L12 1.66869e-07L3 9L21 9Z" fill={iconColor} />
  </SvgIcon>
);

ArrowIntegerIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default ArrowIntegerIcon;
