const BIKE_COVERAGES_DICT = {
  coverage_theft: {
    desc: 'Theft',
  },
  coverage_material_damages: {
    desc: 'Material Damages',
  },
  coverage_emergency: {
    desc: 'Emergency',
  },
} as const;

export default BIKE_COVERAGES_DICT;
