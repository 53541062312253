import type { DateOfServiceFormValues, LegalActionPartyFormValues } from '../FormFragments/FormValuesTypes';

export const partyInitialValues: LegalActionPartyFormValues = {
  contact_id: null,
  counsel_contact_id: null,
  is_carrier_party: false,
  date_of_representation: undefined,
  file_number: '',
};

export const dateOfServiceInitialValues: DateOfServiceFormValues = {
  date_of_service: undefined,
  service_type: '',
  contact_id: null,
  other_text: '',
};
