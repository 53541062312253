import React from 'react';

import { useOrgNameLabel } from '~/components/LegalActions/hooks/useOrgNameLabel';

import { CurrencyCell } from '../../../components/CurrencyCell';
import { DateCell } from '../../../components/DateCell';
import { AddNegotiationLogDialog } from '../../../Dialogs/NegotiationLog/AddNegotiationLogDialog';
import { EditNegotiationLogDialog } from '../../../Dialogs/NegotiationLog/EditNegotiationLogDialog';
import { NEGOTIATION_LOG_FIELDS } from '../../../FormFragments/FormFieldsDefinitions';
import { useLegalAction } from '../../../hooks/useLegalAction';
import { useLegalActionsBackendRoutes } from '../../../hooks/useLegalActionsBackendRoutes';
import type { NegotiationLogApiResponse } from '../../../types';
import { LegalActionLogType } from '../../../types';
import type { LegalActionLogsCardProps } from '../../LegalActionScreenTypes';
import type { LogsCardContentColumnDefinition } from '../LogsCardConent';
import { LogsCardContainer } from '../LogsCardContainer';

import { NegotiationLogMoreDetails } from './NegotiationLogMoreDetials';

export const NegotiationLogsCard: React.FC<LegalActionLogsCardProps<NegotiationLogApiResponse>> = ({
  logs,
  reloadLogs,
}) => {
  const { legalAction } = useLegalAction();
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const offerLabel = useOrgNameLabel('Offer');

  const columns: LogsCardContentColumnDefinition<NegotiationLogApiResponse>[] = [
    {
      id: 'date',
      label: 'Date',
      specialCell: (row) => <DateCell property="date" log={row} />,
    },
    {
      id: 'adverse_party_demand',
      label: NEGOTIATION_LOG_FIELDS.adversePartyDemand.label,
      specialCell: (row) => <CurrencyCell value={row.adverse_party_demand} />,
    },
    {
      id: 'offer',
      label: offerLabel,
      specialCell: (row) => <CurrencyCell value={row.offer} />,
    },
  ];

  return (
    <LogsCardContainer<NegotiationLogApiResponse>
      NewItemComponent={AddNegotiationLogDialog}
      MoreDetailsComponent={NegotiationLogMoreDetails}
      EditItemComponent={EditNegotiationLogDialog}
      deleteUrl={backendRoutes.legalAction.logs.negotiation}
      logs={logs}
      logType={LegalActionLogType.negotiation}
      columns={columns}
      title="Negotiation Logs"
      addItemLabel="Negotiation Log"
      reload={reloadLogs}
      legalAction={legalAction}
    />
  );
};
