import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const ApiIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon viewBox="0 0 11 13" fill="none" stroke="none" {...props}>
    <path
      d="M5.14066 9.17139C5.14066 8.89524 5.36451 8.67139 5.64066 8.67139H6.64066C7.03848 8.67139 7.42001 8.82942 7.70132 9.11073C7.98262 9.39203 8.14066 9.77356 8.14066 10.1714C8.14066 10.5692 7.98262 10.9507 7.70132 11.232C7.42001 11.5134 7.03848 11.6714 6.64066 11.6714H6.14066V12.1714C6.14066 12.4475 5.9168 12.6714 5.64066 12.6714C5.36451 12.6714 5.14066 12.4475 5.14066 12.1714V9.17139ZM6.14066 10.6714H6.64066C6.77326 10.6714 6.90044 10.6187 6.99421 10.5249C7.08798 10.4312 7.14066 10.304 7.14066 10.1714C7.14066 10.0388 7.08798 9.9116 6.99421 9.81783C6.90044 9.72407 6.77326 9.67139 6.64066 9.67139H6.14066V10.6714Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={iconColor}
    />
    <path
      d="M10.1407 8.67139C10.4168 8.67139 10.6407 8.89524 10.6407 9.17139V12.1714C10.6407 12.4475 10.4168 12.6714 10.1407 12.6714C9.86451 12.6714 9.64066 12.4475 9.64066 12.1714V9.17139C9.64066 8.89524 9.86451 8.67139 10.1407 8.67139Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={iconColor}
    />
    <path
      d="M0.292893 0.792893C0.480429 0.605357 0.734783 0.5 1 0.5H7C7.13261 0.5 7.25979 0.552678 7.35355 0.646447L10.8536 4.14645C10.9473 4.24021 11 4.36739 11 4.5V7C11 7.27614 10.7761 7.5 10.5 7.5C10.2239 7.5 10 7.27614 10 7V4.70711L6.79289 1.5L1 1.5L1 7C1 7.27614 0.776142 7.5 0.5 7.5C0.223858 7.5 0 7.27614 0 7V1.5C0 1.23478 0.105357 0.980429 0.292893 0.792893Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={iconColor}
    />
    <path
      d="M7 0.5C7.27614 0.5 7.5 0.723858 7.5 1V4H10.5C10.7761 4 11 4.22386 11 4.5C11 4.77614 10.7761 5 10.5 5H7C6.72386 5 6.5 4.77614 6.5 4.5V1C6.5 0.723858 6.72386 0.5 7 0.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={iconColor}
    />
    <path
      d="M3.33296 12.6329C3.07806 12.7391 2.78533 12.6186 2.67912 12.3637L1.89066 10.4714L1.10219 12.3637C0.995986 12.6186 0.703249 12.7391 0.448348 12.6329C0.193447 12.5267 0.0729086 12.234 0.179117 11.9791L1.42912 8.97908C1.50675 8.79276 1.6888 8.67139 1.89066 8.67139C2.09251 8.67139 2.27456 8.79276 2.35219 8.97908L3.60219 11.9791C3.7084 12.234 3.58786 12.5267 3.33296 12.6329Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={iconColor}
    />
    <path
      d="M2.64066 12.1714H1.14066V11.1714H2.64066V12.1714Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={iconColor}
    />
  </SvgIcon>
);

ApiIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default ApiIcon;
