import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { useLegalActions } from '~/components/LegalActions/hooks/useLegalActions';

import { reportAxiosError } from '../../../../Utils';
import { REOPEN_LEGAL_ACTION_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { ReopenLegalActionFormValues } from '../../FormFragments/FormValuesTypes';
import { ReopenLegalActionForm } from '../../FormFragments/ReopenLegalActionForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { Option } from '../../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../../hooks/useLegalActionsConfiguration';
import type { DialogPropsWithId } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const ReopenLegalActionDialog: React.FC<DialogPropsWithId> = ({ legalActionId, onClose, onSubmitSuccess }) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalActionId);
  const { reopenReasonOptions } = useLegalActionsConfiguration();
  const { reloadLegalActions } = useLegalActions();

  const handleSubmit = async (values: ReopenLegalActionFormValues) => {
    try {
      await axios.post(backendRoutes.legalAction.reopen, values);
      await Promise.all([onSubmitSuccess(), reloadLegalActions()]);
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<ReopenLegalActionFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: {
          reopen_reason: '',
          reopen_text: '',
        },
        validationSchema: Yup.object().shape(REOPEN_LEGAL_ACTION_VALIDATION_SCHEMA(reopenReasonOptions as Option[])),
      }}
      title="Reopen Legal Action"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <ReopenLegalActionForm />
      </div>
    </FormDialog>
  );
};
