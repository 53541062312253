import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';

import CardDialog from '../../CardDialog';
import { ErrorHelperTextFormik } from '../../core/Formik/ErrorHelperTextFormik';
import { usePolicy } from '../../PolicyContainer';
import TextFieldFormik from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

function MarshmallowFnolPolicyVerificationsContainer({
  isVerified,
  verificationFormikId,
  onSubmitVerification,
  fnolMarshmallowValues,
}) {
  const [showVerificationDialog, setShowVerificationDialog] = useState(false);

  return (
    <>
      {isVerified ? (
        <Button onClick={() => setShowVerificationDialog(true)}>Policy info is verified &gt;</Button>
      ) : (
        <div>
          <Button color="secondary" onClick={() => setShowVerificationDialog(true)}>
            <ErrorIcon />
            Verify policy info &gt;
          </Button>
          <ErrorHelperTextFormik id={verificationFormikId} />
        </div>
      )}

      {showVerificationDialog && (
        <MarshmallowFnolPolicyVerificationsDialog
          currentDetails={fnolMarshmallowValues}
          onSubmit={(values) => {
            onSubmitVerification(values);
            setShowVerificationDialog(false);
          }}
          onClose={() => setShowVerificationDialog(false)}
        />
      )}
    </>
  );
}

MarshmallowFnolPolicyVerificationsContainer.propTypes = {
  isVerified: PropTypes.bool.isRequired,
  verificationFormikId: PropTypes.string.isRequired,
  onSubmitVerification: PropTypes.func.isRequired,
  fnolMarshmallowValues: PropTypes.object.isRequired,
};

function MarshmallowFnolPolicyVerificationsDialog({ currentDetails, onSubmit, onClose }) {
  const { policy } = usePolicy();
  const classes = useStyles();
  const contactOccupation = policy.insured_contact.contact_extra.marshmallow_contact_orig?.occ_description;

  return (
    <Formik
      initialValues={{
        ...{
          is_occupation_right: '',
          current_occupation: '',
          is_second_occupation_exists: '',
          second_occupation: '',
          is_self_employed: '',
          were_they_driving_a_company_car: '',
          are_they_taxed_registered: '',
          are_convictions_in_last_5_years: '',
          convictions_description: '',
          are_claims_in_the_last_5_years: '',
          claims_description: '',
          are_criminal_convictions: '',
          criminal_convictions: '',
          does_the_vehicle_have_any_modifications: '',
          modifications_description: '',
          any_dvla_medical_conditions: '',
          dvla_medical_conditions_description: '',
        },
        ...currentDetails,
      }}
      validationSchema={Yup.object().shape({
        is_occupation_right: contactOccupation
          ? Yup.boolean().nullable().required('Required')
          : Yup.boolean().nullable(),
        current_occupation: Yup.string().when('is_occupation_right', (is_occupation_right, schema) => {
          if (is_occupation_right === false || !contactOccupation) {
            return schema.required('Required');
          }
        }),
        is_second_occupation_exists: Yup.boolean().nullable().required('Required'),
        second_occupation: Yup.string().when('is_second_occupation_exists', {
          is: true,
          then: Yup.string().required('Required'),
        }),
        is_self_employed: Yup.boolean().nullable().required('Required'),
        were_they_driving_a_company_car: Yup.string().when('is_self_employed', {
          is: true,
          then: Yup.string().required('Required'),
        }),
        are_they_taxed_registered: Yup.string().when('is_self_employed', {
          is: true,
          then: Yup.string().required('Required'),
        }),
        are_convictions_in_last_5_years: Yup.boolean().nullable().required('Required'),
        convictions_description: Yup.string().when('are_convictions_in_last_5_years', {
          is: true,
          then: Yup.string().required('Required'),
        }),
        are_claims_in_the_last_5_years: Yup.boolean().nullable().required('Required'),
        claims_description: Yup.string().when('are_claims_in_the_last_5_years', {
          is: true,
          then: Yup.string().required('Required'),
        }),
        are_criminal_convictions: Yup.boolean().nullable().required('Required'),
        criminal_convictions: Yup.string().when('are_criminal_convictions', {
          is: true,
          then: Yup.string().required('Required'),
        }),
        does_the_vehicle_have_any_modifications: Yup.boolean().nullable().required('Required'),
        modifications_description: Yup.string().when('does_the_vehicle_have_any_modifications', {
          is: true,
          then: Yup.string().required('Required'),
        }),
        any_dvla_medical_conditions: Yup.boolean().nullable().required('Required'),
        dvla_medical_conditions_description: Yup.string().when('any_dvla_medical_conditions', {
          is: true,
          then: Yup.string().required('Required'),
        }),
      })}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, handleSubmit }) => (
        <CardDialog title="Policy Verification" isDialog onClose={onClose} maxWidth="md" fullWidth>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {contactOccupation && (
                <YesNoQuestionFormik
                  id="is_occupation_right"
                  questionText={`Is current occupation '${contactOccupation}'?`}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {(!contactOccupation || values.is_occupation_right === false) && (
                <TextFieldFormik id="current_occupation" label="Occupation" className={classes.textField} fullWidth />
              )}
            </Grid>

            <Grid item xs={6}>
              <YesNoQuestionFormik id="is_second_occupation_exists" questionText="Is there a second occupation?" />
            </Grid>

            <Grid item xs={6}>
              {values.is_second_occupation_exists === true && (
                <TextFieldFormik
                  id="second_occupation"
                  label="Second Occupation"
                  className={classes.textField}
                  fullWidth
                />
              )}
            </Grid>

            <Grid item xs={6}>
              <YesNoQuestionFormik id="is_self_employed" questionText="Is the customer self employed?" />
            </Grid>
            <Grid item xs={6} />

            {values.is_self_employed === true && (
              <>
                <Grid item xs={6}>
                  <YesNoQuestionFormik
                    id="were_they_driving_a_company_car"
                    questionText="Were they driving a company car?"
                  />
                </Grid>
                <Grid item xs={6}>
                  <YesNoQuestionFormik id="are_they_taxed_registered" questionText="Are they taxed registered?" />
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <YesNoQuestionFormik id="are_convictions_in_last_5_years" questionText="Convictions in last 5 years?" />
            </Grid>
            <Grid item xs={6}>
              {values.are_convictions_in_last_5_years === true && (
                <TextFieldFormik
                  id="convictions_description"
                  label="Convictions Description"
                  className={classes.textField}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <YesNoQuestionFormik id="are_criminal_convictions" questionText="Criminal convictions?" />
            </Grid>
            <Grid item xs={6}>
              {values.are_criminal_convictions === true && (
                <TextFieldFormik
                  id="criminal_convictions"
                  label="Convictions Description"
                  className={classes.textField}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <YesNoQuestionFormik id="are_claims_in_the_last_5_years" questionText="Claims in the last 5 years?" />
            </Grid>
            <Grid item xs={6}>
              {values.are_claims_in_the_last_5_years === true && (
                <TextFieldFormik
                  id="claims_description"
                  label="Claims Description"
                  className={classes.textField}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <YesNoQuestionFormik
                id="does_the_vehicle_have_any_modifications"
                questionText="Does the vehicle have any modifications?"
              />
            </Grid>
            <Grid item xs={6}>
              {values.does_the_vehicle_have_any_modifications === true && (
                <TextFieldFormik
                  id="modifications_description"
                  label="Modifications Description"
                  className={classes.textField}
                  fullWidth
                />
              )}
            </Grid>

            <Grid item xs={6}>
              <YesNoQuestionFormik id="any_dvla_medical_conditions" questionText="Any DVLA medical conditions?" />
            </Grid>
            <Grid item xs={6}>
              {values.any_dvla_medical_conditions === true && (
                <TextFieldFormik
                  id="dvla_medical_conditions_description"
                  label="Conditions Description"
                  className={classes.textField}
                  fullWidth
                />
              )}
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

MarshmallowFnolPolicyVerificationsDialog.propTypes = {
  currentDetails: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MarshmallowFnolPolicyVerificationsContainer;
