import type { ReactElement } from 'react';
import React from 'react';
// eslint-disable-next-line import/named
import type { FormikHelpers } from 'formik';
import { Formik, useFormikContext } from 'formik';
import type { FormikConfig, FormikValues } from 'formik/dist/types';

import DialogFooterActions from '~/components/core/DialogFooterActions';

import type { CardDialogProps } from '../../CardDialog';
import CardDialog from '../../CardDialog';

interface FormDialogProps<T> {
  formikConfig: FormikConfig<T> & {
    onSubmit: (values: T) => Promise<void>;
  };
  title: string;
  maxWidth?: CardDialogProps['maxWidth'];
  onClose: () => void;
  children: React.ReactNode;
}

export const FormDialog = <T extends FormikValues>({
  formikConfig,
  title,
  maxWidth,
  onClose,
  children,
}: FormDialogProps<T>): ReactElement => {
  const handleSubmit = async (values: T, formikHelpers: FormikHelpers<T>) => {
    const { setSubmitting } = formikHelpers;
    setSubmitting(true);
    await formikConfig.onSubmit(values);
    setSubmitting(false);
  };

  return (
    <Formik {...formikConfig} onSubmit={handleSubmit}>
      <FormDialogInner title={title} maxWidth={maxWidth} onClose={onClose}>
        {children}
      </FormDialogInner>
    </Formik>
  );
};

const FormDialogInner = <T extends FormikValues>({
  title,
  maxWidth,
  onClose,
  children,
}: Omit<FormDialogProps<T>, 'formikConfig'>) => {
  const { submitForm, isSubmitting } = useFormikContext<T>();

  return (
    <CardDialog
      isDialog
      open
      title={title}
      maxWidth={maxWidth}
      fullWidth
      onClose={onClose}
      footerActions={
        <DialogFooterActions onClickPrimary={submitForm} onClickSecondary={onClose} disabled={isSubmitting} />
      }
    >
      {children}
    </CardDialog>
  );
};
