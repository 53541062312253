import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Tooltip from '~/components/core/Atomic/Tooltip';
import PermissionDeniedTooltip from '~/components/core/Permissions/PermissionDeniedTooltip';
import { useRestrictedPermissions } from '~/components/core/Permissions/RestrictedPermissions';
import cn from '~/Utils/cn';

import { useStyles } from '../assets/styles';

const InlineIconButton = ({
  icon,
  defaultColor,
  disabled,
  className,
  iconStyle,
  tooltipTitle,
  useIconButton,
  wrapWithSpan,
  ignorePermissions,
  ariaLabel,
  ...restProps
}) => {
  const classes = useStyles();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { userHasContextPermissions } = useRestrictedPermissions();

  let IconComponent = icon;

  const iconComponentBaseProps = {
    onMouseEnter: () => setIsMouseOver(true),
    onMouseLeave: () => setIsMouseOver(false),
  };

  const style = { width: 16, height: 16, ...iconStyle };

  const getDisabledIconComponent = () => (
    <IconComponent
      style={style}
      className={cn(className, classes.hoverableIconDisabled)}
      color="disabled"
      disabled
      {...iconComponentBaseProps}
    />
  );

  if (!userHasContextPermissions && !ignorePermissions) {
    return <PermissionDeniedTooltip>{getDisabledIconComponent()}</PermissionDeniedTooltip>;
  }

  if (disabled) {
    // color={'disabled'} is backward compatibility for MUI
    // disabled is for our new icons
    return (
      <Tooltip open={!!tooltipTitle && isMouseOver} title={tooltipTitle || ''}>
        <span>{getDisabledIconComponent()}</span>
      </Tooltip>
    );
  }

  const iconComponentWithoutIconButtonProps = {
    ...iconComponentBaseProps,
    ...restProps,
    className,
    style: { cursor: 'pointer', ...style },
    color: isMouseOver ? 'primary' : defaultColor ? defaultColor : 'action',
    'aria-role': 'button',
  };

  let componentToRender;
  if (useIconButton) {
    componentToRender = (
      <IconButton {...restProps} className={className}>
        <IconComponent style={style} color={defaultColor ? defaultColor : 'action'} {...iconComponentBaseProps} />
      </IconButton>
    );
  } else {
    const nonIconButtonComponent = <IconComponent {...iconComponentWithoutIconButtonProps} />;
    componentToRender = wrapWithSpan ? <span>{nonIconButtonComponent}</span> : nonIconButtonComponent;
  }

  return (
    <Tooltip open={!!tooltipTitle && isMouseOver} title={tooltipTitle || ''} placement="top">
      <span role="button" aria-label={ariaLabel}>
        {componentToRender}
      </span>
    </Tooltip>
  );
};

InlineIconButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  defaultColor: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  iconStyle: PropTypes.object,
  onClick: requiredIf(PropTypes.func, (props) => !props.disabled),
  tooltipTitle: PropTypes.string,
  useIconButton: PropTypes.bool,
  wrapWithSpan: PropTypes.bool,
  ignorePermissions: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default InlineIconButton;
