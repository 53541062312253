import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

import Button from '~/components/core/Atomic/Buttons/Button';

import { reportAxiosError } from '../../../Utils';
import { TrashIcon_Deprecated, UploadIcon } from '../../icons';

import OrganizationLogo from './OrganizationLogo';
import { OrganizationLogoUploadDialog } from './OrganizationLogoUploadDialog';

import { useStyles } from '../../../assets/styles';
import colors from '../../../assets/colors.module.scss';
import styles from './OrganizationLogo.module.scss';

const OrganizationLogoUploadContainer = ({ onSave, onRemove, useBase64Only = false }) => {
  const classes = useStyles();

  const getInitialBase64Data = () => (useBase64Only ? '' : null);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [base64ImageString, setBase64Image] = useState(getInitialBase64Data());
  const [isNoLogo, setIsNoLogo] = useState(false);

  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const handleLogoHover = (isHover) => {
    if (!isHover) {
      setShowDeleteButton(false);
    } else if ((useBase64Only && base64ImageString !== '') || (!useBase64Only && !isNoLogo)) {
      setShowDeleteButton(true);
    }
  };

  const handleSave = async (image, type) => {
    try {
      setBase64Image(`data:${type};base64,${image}`);
      await onSave(image, type);
      setIsUploadDialogOpen(false);
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  const handleRemove = async () => {
    setBase64Image(getInitialBase64Data());
    await onRemove();
  };

  return (
    <>
      <Grid container direction="row" spacing={1} className={styles.logoUploadContainer}>
        <div onMouseEnter={() => handleLogoHover(true)} onMouseLeave={() => handleLogoHover(false)}>
          {showDeleteButton && (
            <div className={styles.removeLogoButtonContainer}>
              <div className={styles.removeLogoButton} onClick={handleRemove}>
                <TrashIcon_Deprecated />
              </div>
            </div>
          )}
          <Grid item>
            <div className={styles.logoBackgroundContainer}>
              <OrganizationLogo base64Image={base64ImageString} onNoLogo={() => setIsNoLogo(true)} overrideCache />
            </div>
          </Grid>
        </div>
        <Grid container direction="column" item alignItems="start" justifyContent="space-between" xs={9}>
          <Typography className={styles.title}>Update organization logo</Typography>
          <Typography variant="body2" className={styles.subtitle}>
            Use a logo that is 120px square or larger.
          </Typography>
          <Button className={styles.uploadButton} color="primary" onClick={() => setIsUploadDialogOpen(true)}>
            <UploadIcon size={12} iconColor={colors.buttonLink} className={classes.leftButtonIcon} />
            Upload Logo
          </Button>
        </Grid>
      </Grid>
      {isUploadDialogOpen && (
        <OrganizationLogoUploadDialog onSave={handleSave} onCancel={() => setIsUploadDialogOpen(false)} />
      )}
    </>
  );
};

OrganizationLogoUploadContainer.propTypes = {
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  useBase64Only: PropTypes.bool,
};

export default OrganizationLogoUploadContainer;
