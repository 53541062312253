import React, { useEffect } from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import HoverChangeField from '../HoverChangeField';
import LoadingIndicator from '../LoadingIndicator';
import PlainTable from '../PlainTable';
import { ShowOnlyTextField } from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

// NOTE: copied and generalized to frontend/src/components/Policy/GenericPolicy/CoveredEntity/CoveredEntityAccumulatedFinance.jsx
// NOTE: remove this file once Boost is on the GC PET product

function PetDeductibleContainer() {
  const { claim, onClaimUpdate } = useClaim();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();

  const handleChangeDeductible = async (newDeductibleDict) => {
    try {
      setIsSubmitting(true);
      await axios.put(`/api/v1/pet_claims/${claim.id}/deductible`, newDeductibleDict);
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  };

  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
      <Grid item>
        <HoverChangeField
          name="deductible_paid"
          value={claim.deductible_paid_in_claim}
          validationSchema={Yup.number().required()}
          specialFieldType="monetary"
          label="Deductible Paid in Claim"
          onUpdate={(deductible_paid) => handleChangeDeductible(deductible_paid)}
          width="250px"
          disabled={isSubmitting}
        >
          <ShowOnlyTextField
            classes={classes}
            label="Deductible Paid in Claim"
            showOnlyValueComponent={currencyFormatter.format(claim.deductible_paid_in_claim)}
          />
        </HoverChangeField>
      </Grid>
    </Grid>
  );
}

function PetAccumulatedFinancialContainer() {
  const { claim } = useClaim();
  const {
    isLoading,
    isError,
    data: accumulatedFinanceData,
    reloadData,
  } = useDataFetcher(`/api/v1/pet_claims/${claim.id}/claim_accumulated_finance`);
  const { currencyFormatter } = useCurrencyFormatter();

  useEffect(() => {
    // This is here to fire a reload of the data when the deductible pain in claim is updated
    reloadData();
  }, [reloadData, claim]);

  if (isLoading || isError) {
    return (
      <Grid container direction="column" justify="flex-start" alignItems="flex-start">
        <Grid item>
          <LoadingIndicator isError={isError} size={20} />
        </Grid>
      </Grid>
    );
  }

  const accumulatedDeductible = accumulatedFinanceData.accumulated_deductible;
  const accumulatedPaidInPolicy = accumulatedFinanceData.accumulated_paid_for_policy_and_pet;
  const accumulatedPaidForPet = accumulatedFinanceData.accumulated_paid_for_pet;

  const columnData = [
    { id: 'type', numeric: false, label: '' },
    { id: 'accumulated', numeric: false, label: 'Accumulated (including this claim)' },
    { id: 'policy_limit', numeric: false, label: 'Policy Limit' },
    { id: 'remaining', numeric: false, label: 'Remaining amount' },
  ];

  const deductible = {
    type: 'Deductible',
    accumulated: currencyFormatter.format(accumulatedDeductible),
    policy_limit: currencyFormatter.format(claim.involved_pet_deductible_per_policy),
    remaining: currencyFormatter.format(claim.involved_pet_deductible_per_policy - accumulatedDeductible),
  };
  const policy_paid = {
    type: 'Paid within policy',
    accumulated: currencyFormatter.format(accumulatedPaidInPolicy),
    policy_limit: currencyFormatter.format(claim.policy.policy_limit),
    remaining: currencyFormatter.format(claim.policy.policy_limit - accumulatedPaidInPolicy),
  };
  const lifetime_paid = {
    type: 'Lifetime paid',
    accumulated: accumulatedPaidForPet !== null ? currencyFormatter.format(accumulatedPaidForPet) : 'N\\A',
    policy_limit: currencyFormatter.format(claim.policy.limit_lifetime),
    remaining:
      accumulatedPaidForPet !== null
        ? currencyFormatter.format(claim.policy.limit_lifetime - accumulatedPaidForPet)
        : 'N\\A',
  };
  let rows = [deductible, policy_paid, lifetime_paid];
  rows = rows.map((row, idx) => ({ ...row, id: idx }));

  return (
    <CardDialog title="Accumulated payments">
      <Grid container direction="column" justify="flex-start" alignItems="flex-start">
        <Grid item>
          <Typography display="block" variant="body1">
            Accumulating amount for related pet and policy (including this claim)
          </Typography>
        </Grid>
        <Grid item>
          <PlainTable columns={columnData} rows={rows} keepRowsOrder />
        </Grid>
      </Grid>
    </CardDialog>
  );
}

export { PetAccumulatedFinancialContainer, PetDeductibleContainer };
