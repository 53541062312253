const EXTENT_OF_INJURY_TYPES_DICT = {
  abrasions: {
    desc: 'Abrasions',
  },
  contusions_bruising: {
    desc: 'Contusions/Bruising',
  },
  fracture: {
    desc: 'Fracture',
  },
  herniation: {
    desc: 'Herniation',
  },
  laceration: {
    desc: 'Laceration',
  },
  loss_of_consciousness: {
    desc: 'Loss of Consciousness',
  },
  scarring: {
    desc: 'Scarring',
  },
  sprain_strain: {
    desc: 'Sprain/Strain',
  },
} as const;

export default EXTENT_OF_INJURY_TYPES_DICT;
