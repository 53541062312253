import React from 'react';
import PropTypes from 'prop-types';
import { FileDocument } from 'mdi-material-ui';

import { useClaim } from '~/components/ClaimContainer';
import InlineIconButton from '~/components/InlineIconButton';

export const ClaimDocumentIconButton = ({ documentId }) => {
  const { claim } = useClaim();
  return (
    <InlineIconButton
      icon={FileDocument}
      onClick={() => window.open(`/api/v1/claims/${claim.id}/documents/${documentId}`, '_target')}
    />
  );
};

ClaimDocumentIconButton.propTypes = {
  documentId: PropTypes.number.isRequired,
};
