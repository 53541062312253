/* eslint-disable react/display-name */
import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormikContext } from 'formik';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import CardDialog from '../CardDialog';
import { useClaim, withClaim } from '../ClaimContainer';
import UploadDocument, { UploadMultipleMedia } from '../Documents/DocumentCard';
import DocumentsContainer, { getDocumentsTableColumnData } from '../Documents/DocumentsContainer';
import { isDocumentAPhoto } from '../Documents/DocumentUtils';
import { GalleryCard, GalleryContainerInner, getAllClaimPhotos } from '../Gallery/GalleryScreen';
import { useCms } from '../hooks/useCms';
import InlineIconButton from '../InlineIconButton';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';

import styles, { useStyles } from '../../assets/styles';

function AddEstimationDocumentDialog(props) {
  const { open, onCancel, onAttach, maxHeight } = props;
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);

  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();

  return (
    <CardDialog
      title="Add Document"
      isDialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={() => {
        setSelectedDocumentIds([]);
        onCancel();
      }}
    >
      <DocumentsContainer
        claim={claim}
        classes={classes}
        newDocumentDefaultType="damage_estimate"
        selectedDocumentIds={selectedDocumentIds}
        onDocumentsClick={(documentIds) => setSelectedDocumentIds(documentIds)}
        onNewDocumentCreated={async (documentId) => {
          await onClaimUpdate();
          setSelectedDocumentIds([...selectedDocumentIds, documentId]);
        }}
        maxHeight={maxHeight}
        autoPaginateRowsPerPage={25}
      />
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          disabled={selectedDocumentIds.length === 0}
          onClick={() => onAttach(selectedDocumentIds)}
        >
          Add
        </Button>
      </div>
    </CardDialog>
  );
}

AddEstimationDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAttach: PropTypes.func.isRequired,
  maxHeight: PropTypes.string.isRequired,
};

function FormikEstimationDocuments(props) {
  const {
    claim,
    exposureId,
    id,
    showOnly,
    disableViewPhotos,
    disableAddingEstimation,
    disableUploadPhoto,
    disableAddPhoto,
    disableDocumentDate,
    disableDocumentUploadDate,
    maxHeight,
  } = props;
  const formik = useFormikContext();

  const { values, setFieldValue } = formik;

  return (
    <EstimationDocumentsContainer
      showOnly={showOnly}
      exposureId={exposureId}
      estimationDocuments={claim.documents.filter((document) => values[id].includes(document.id))}
      onUpdateEstimationDocumentIds={(estimationDocumentIds) => setFieldValue(id, estimationDocumentIds)}
      disableViewPhotos={disableViewPhotos}
      disableAddingEstimation={disableAddingEstimation}
      disableUploadPhoto={disableUploadPhoto}
      disableAddPhoto={disableAddPhoto}
      disableDocumentDate={disableDocumentDate}
      disableDocumentUploadDate={disableDocumentUploadDate}
      maxHeight={maxHeight}
    />
  );
}

FormikEstimationDocuments.propTypes = {
  id: PropTypes.string.isRequired,
  claim: PropTypes.object.isRequired,
  exposureId: PropTypes.number.isRequired,
  showOnly: PropTypes.bool,
  disableViewPhotos: PropTypes.bool,
  disableAddingEstimation: PropTypes.bool,
  disableUploadPhoto: PropTypes.bool,
  disableAddPhoto: PropTypes.bool,
  disableDocumentDate: PropTypes.bool,
  disableDocumentUploadDate: PropTypes.bool,
  maxHeight: PropTypes.string,
};

export { FormikEstimationDocuments };

function ViewAttachmentsPhotosContainer({ photos, PhotoActionComponent, onClose }) {
  return (
    <>
      <CardDialog title="Damage Assessment Photos" open isDialog maxWidth="xl" fullWidth onClose={onClose}>
        <div style={{ height: '80vh' }}>
          <GalleryContainerInner
            allMedia={photos}
            PhotoActionComponent={PhotoActionComponent}
            shouldDisableLargePhotoActions
            shouldDisableUpload
          />
        </div>
      </CardDialog>
    </>
  );
}

ViewAttachmentsPhotosContainer.propTypes = {
  photos: PropTypes.array.isRequired,
  PhotoActionComponent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function EstimationDocumentsContainer(props) {
  const {
    estimationDocuments,
    exposureId,
    onUpdateEstimationDocumentIds,
    showOnly,
    disableViewPhotos,
    disableAddingEstimation,
    disableUploadPhoto,
    disableAddPhoto,
    disableDocumentDate,
    disableDocumentUploadDate,
    maxHeight,
  } = props;
  const classes = useStyles();
  const [showAttachDocumentDialog, setShowAttachDocumentDialog] = useState(false);
  const [showNewEstimationDocument, setShowNewEstimationDocument] = useState(false);
  const [showUploadPhotos, setShowUploadPhotos] = useState(false);
  const [showAddPhotos, setShowAddPhotos] = useState(false);
  const [showViewPhotos, setShowViewPhotos] = useState(false);
  const { claim, onClaimUpdate } = useClaim();
  const { documentTypesDict } = useOrganization();
  const { userOrganization } = useCms();

  const addReports = async (documentIds) => {
    await onUpdateEstimationDocumentIds(
      _.union(estimationDocuments ? estimationDocuments.map((doc) => doc.id) : [], documentIds)
    );
  };

  const handleRemoveReport = (documentId) => {
    const newEstimationDocuments = estimationDocuments
      .filter((document) => document.id !== documentId)
      .map((doc) => doc.id);
    onUpdateEstimationDocumentIds(newEstimationDocuments);
  };

  let columnData = [
    // copy a subset of the columns of the documents table
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'view_action'),
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'id'),
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'type'),
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'document_name'),
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'datetime_uploaded'),
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'document_date'),
    {
      id: 'remove_attachment',
      disableSort: true,
      specialCell: (document, isHover) =>
        isHover ? (
          <InlineIconButton
            icon={DeleteIcon}
            className={classes.textIcon}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveReport(document.id);
            }}
          />
        ) : (
          <div className={classes.textIconFiller} />
        ),
    },
  ];

  const RemovePhotoButtonComponent = (props) => {
    return (
      <InlineIconButton
        icon={DeleteIcon}
        tooltipTitle="Remove photo from damage assessment"
        // eslint-disable-next-line react/prop-types
        onClick={() => handleRemoveReport(props.photoId)}
      />
    );
  };

  if (showOnly) {
    columnData = columnData.filter((column) => column.id !== 'remove_attachment');
  }

  if (disableDocumentDate) {
    columnData = columnData.filter((column) => column.id !== 'document_date');
  }

  if (disableDocumentUploadDate) {
    columnData = columnData.filter((column) => column.id !== 'datetime_uploaded');
  }

  return (
    <>
      <Grid container>
        {!showOnly && (
          <>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" item xs={6}>
              <Grid item>
                <Button color="primary" size="small" onClick={() => setShowAttachDocumentDialog(true)}>
                  <AddIcon className={classes.leftButtonIcon} /> Add Documents
                </Button>
              </Grid>
              {!disableAddingEstimation && (
                <Grid item>
                  <Button color="primary" size="small" onClick={() => setShowNewEstimationDocument(true)}>
                    <CloudUploadIcon className={classes.leftButtonIcon} /> Upload Estimate
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container direction="column" justify="flex-start" alignItems="flex-end" item xs={6}>
              {!disableAddPhoto && (
                <Grid item>
                  <Button color="primary" size="small" onClick={() => setShowAddPhotos(true)}>
                    <AddIcon className={classes.leftButtonIcon} /> Add photos
                  </Button>
                </Grid>
              )}
              {!disableUploadPhoto && (
                <Grid>
                  <Button color="primary" size="small" onClick={() => setShowUploadPhotos(true)}>
                    <CloudUploadIcon className={classes.leftButtonIcon} /> Upload damage photos
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <PlainTable
            classes={classes}
            columns={columnData}
            rows={estimationDocuments}
            maxHeight={maxHeight}
            stickyHeader
          />
        </Grid>
        {!disableViewPhotos && (
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" onClick={() => setShowViewPhotos(true)}>
              View Photos
            </Button>
          </Grid>
        )}
      </Grid>
      <AddEstimationDocumentDialog
        open={showAttachDocumentDialog}
        initialValues={{
          exposure_ids: [exposureId],
          type: disableAddingEstimation ? '' : 'damage_estimate',
        }}
        onCancel={() => setShowAttachDocumentDialog(false)}
        onAttach={async (documentIds) => {
          await addReports(documentIds);
          setShowAttachDocumentDialog(false);
        }}
        maxHeight="550px"
      />
      {showNewEstimationDocument && (
        <UploadDocument
          classes={classes}
          claim={claim}
          onSubmitDocument={async (document) => {
            await onClaimUpdate();
            await addReports([document.id]);
            setShowNewEstimationDocument(false);
          }}
          onCancel={() => setShowNewEstimationDocument(false)}
          showFileBoxOnly
          initialValues={{
            exposure_ids: [exposureId],
            type: 'damage_estimate',
          }}
        />
      )}
      {showUploadPhotos && (
        <UploadMultipleMedia
          onClose={() => setShowUploadPhotos(false)}
          claimId={claim.id}
          mediaType="damage_photo"
          exposureIds={[exposureId]}
          onSubmit={async (documents) => {
            await onClaimUpdate();
            await addReports(documents.map((doc) => doc.id));
            setShowUploadPhotos(false);
          }}
          showFileBoxOnly
        />
      )}
      {showAddPhotos && (
        <GalleryCard
          allMedia={getAllClaimPhotos(claim)}
          title="Add Photos"
          onClose={() => setShowAddPhotos(false)}
          shouldAllowSelect
          onPhotosSelected={async (photosIds) => {
            await addReports(photosIds);
            setShowAddPhotos(false);
          }}
        />
      )}
      {showViewPhotos && (
        <ViewAttachmentsPhotosContainer
          photos={estimationDocuments.filter(isDocumentAPhoto)}
          onClose={() => setShowViewPhotos(false)}
          PhotoActionComponent={showOnly ? undefined : RemovePhotoButtonComponent}
        />
      )}
    </>
  );
}

EstimationDocumentsContainer.propTypes = {
  estimationDocuments: PropTypes.array.isRequired,
  showOnly: PropTypes.bool,
  exposureId: requiredIf(PropTypes.number, (props) => !props.showOnly),
  onUpdateEstimationDocumentIds: requiredIf(PropTypes.func, (props) => !props.showOnly),
  disableViewPhotos: PropTypes.bool,
  disableAddingEstimation: PropTypes.bool,
  disableUploadPhoto: PropTypes.bool,
  disableAddPhoto: PropTypes.bool,
  disableDocumentDate: PropTypes.bool,
  disableDocumentUploadDate: PropTypes.bool,
  maxHeight: PropTypes.string,
};

export default withClaim(withStyles(styles)(EstimationDocumentsContainer));
