import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const LockedIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M19.5 9.25H4.5C4.08579 9.25 3.75 9.58579 3.75 10V20.5C3.75 20.9142 4.08579 21.25 4.5 21.25H19.5C19.9142 21.25 20.25 20.9142 20.25 20.5V10C20.25 9.58579 19.9142 9.25 19.5 9.25Z"
      stroke={iconColor}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.625 9.25V5.875C8.625 4.97989 8.98058 4.12145 9.61351 3.48851C10.2465 2.85558 11.1049 2.5 12 2.5C12.8951 2.5 13.7535 2.85558 14.3865 3.48851C15.0194 4.12145 15.375 4.97989 15.375 5.875V9.25"
      stroke={iconColor}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16.375C12.6213 16.375 13.125 15.8713 13.125 15.25C13.125 14.6287 12.6213 14.125 12 14.125C11.3787 14.125 10.875 14.6287 10.875 15.25C10.875 15.8713 11.3787 16.375 12 16.375Z"
      fill={iconColor}
      stroke="none"
    />
  </SvgIcon>
);

export default LockedIcon;
