import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { ButtonGroup, Checkbox } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, stringCmp } from '~/Utils';

import { serverDateTimeToLocal } from '../../DateTimeUtils';
import { useClaim } from '../ClaimContainer';
import ClaimLink from '../ClaimLink';
import { SortableTable } from '../core';
import ExposureLabelChips from '../ExposureLabelChips';
import { useCms } from '../hooks/useCms';
import { AttachmentIcon, NotAnsweredIcon, VoicemailIcon } from '../icons';
import InlineIconButton from '../InlineIconButton';

import { getChannelDirectionColumns } from './CommunicationUtils';
import ViewCommunicationCardContainer from './ViewCommunicationCardContainer';

import { useStyles } from '../../assets/styles';

const getExtraIndicatorIcons = (comm) => {
  let iconsArr = [];

  if ((comm.channel === 'email' || comm.channel === 'sms') && comm.is_attachment_exist) {
    iconsArr.push({ name: 'Attachment', node: AttachmentIcon });
  }

  if (comm.channel === 'phone_call' && comm.is_no_answer) {
    if (comm.direction === 'Incoming' && comm.recording_stored_file_id) {
      iconsArr.push({ name: 'voicemail', node: VoicemailIcon });
    } else {
      iconsArr.push({ name: 'not-answered', node: NotAnsweredIcon });
    }
  }

  return (
    <>
      {iconsArr.map((icon) => {
        const Icon = icon.node;
        return <Icon size={20} key={icon.name} />;
      })}
    </>
  );
};

function CommunicationsTable({
  communications,
  selectedCommunicationId,
  onSelectCommunication,
  enableSelection,
  selectedList,
  onUpdatedSelected,
  maxHeight,
  autoPaginateRowsPerPage,
  addViewColumn,
  paginationProps,
  onSortByColumn,
  removeExposuresColumn,
  removeChannelColumn,
  removeDirectionColumn,
  removeAdjusterColumn,
  removeEmailDomainColumn,
  addClaimColumn,
  disableSortByUser,
  showCLabel = true,
  addSummaryColumn = true,
  addSubjectColumn = false,
}) {
  const [communicationToShowInDialog, setCommunicationToShowInDialog] = useState(undefined);
  const { userOrganization } = useCms();
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);

  const classes = useStyles();
  const { onClaimUpdate } = useClaim();

  let columnData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'exposure_ids',
      numeric: false,
      leftPaddingOnly: false,
      label: 'Label',
      disableSort: true,
      specialCell: (comm) => <ExposureLabelChips claimObject={comm} disablePadding />,
    },
    ...getChannelDirectionColumns({ isNewUIEnabled, showCLabel }),
    { id: 'adjuster', numeric: false, disableSort: true, rightPaddingOnly: true, label: 'Adjuster' },
    {
      id: 'contact',
      numeric: false,
      rightPaddingOnly: true,
      label: 'Contact',
      maxWidthPercentage: 15,
      disableSort: true,
      specialCell: (comm) => {
        const toCount = comm.to_contacts_ids ? comm.to_contacts_ids.length : 0;
        const ccCount = comm.cc_contacts_ids ? comm.cc_contacts_ids.length : 0;
        const bccCount = comm.bcc_contacts_ids ? comm.bcc_contacts_ids.length : 0;
        const totalCount = toCount + ccCount + bccCount;

        return comm.contact
          ? comm.contact.full_name + (totalCount > 0 ? ` (+${totalCount})` : '')
          : comm.specific_identifier;
      },
      specialCmpFunc: (comm1, comm2) =>
        stringCmp(
          comm1.contact?.full_name || comm1.specific_identifier || '',
          comm2.contact?.full_name || comm2.specific_identifier || ''
        ),
    },

    {
      id: 'email_domain',
      label: 'Email Domain',
      numeric: false,
      disableSort: true,
    },

    {
      id: 'datetime',
      numeric: false,
      rightPaddingOnly: true,
      label: 'Time',
      nowrap: true,
      specialCell: (comm) => serverDateTimeToLocal(comm.datetime),
    },
    {
      id: 'extra_indicator',
      rightPaddingOnly: true,
      numeric: false,
      disableSort: true,
      label: '',
      specialCell: (comm) => getExtraIndicatorIcons(comm),
    },
  ];

  if (addSummaryColumn) {
    columnData = columnData.concat([
      { id: 'summary', numeric: false, label: 'Summary', disableSort: true, maxWidthPercentage: 25, overflow: true },
    ]);
  }

  if (addSubjectColumn) {
    columnData = columnData.concat([
      { id: 'subject', numeric: false, label: 'Subject', disableSort: true, maxWidthPercentage: 25, overflow: true },
    ]);
  }

  if (addViewColumn) {
    columnData = columnData.concat([
      {
        id: 'view_action',
        width: 25,
        specialCell: (communication) => (
          <InlineIconButton icon={VisibilityIcon} onClick={() => setCommunicationToShowInDialog(communication)} />
        ),
      },
    ]);
  }

  if (addClaimColumn) {
    columnData.unshift({
      id: 'claim',
      label: 'Claim',
      width: 25,
      // eslint-disable-next-line react/display-name
      specialCell: (communication) =>
        communication.claim_id ? (
          <ClaimLink claimId={communication.claim_id} linkText={communication.claim_id_display} openInNewTab />
        ) : null,
    });
  }

  if (removeExposuresColumn) {
    columnData = columnData.filter((column) => column.id !== 'exposure_ids');
  }

  if (removeChannelColumn) {
    columnData = columnData.filter((column) => column.id !== 'channel');
  }

  if (removeDirectionColumn) {
    columnData = columnData.filter((column) => column.id !== 'direction');
  }

  if (removeAdjusterColumn) {
    columnData = columnData.filter((column) => column.id !== 'adjuster');
  }

  if (!userOrganization?.is_suborgs_domains_enabled || removeEmailDomainColumn) {
    columnData = columnData.filter((column) => column.id !== 'email_domain');
  }

  const handleUpdateSelectedCommunicationsList = (comm_id, value) => {
    if (value) {
      if (!selectedList.includes(comm_id)) {
        onUpdatedSelected([...selectedList, comm_id]);
      }
    } else {
      onUpdatedSelected(_.filter(selectedList, (id) => id !== comm_id));
    }
  };

  let communicationColumns = columnData;
  if (enableSelection) {
    const communicationSelectionColumn = {
      id: 'select_comm',
      disableSort: true,
      width: 20,
      disablePadding: true,
      // eslint-disable-next-line react/display-name
      specialCell: (comm) => (
        <Checkbox
          // eslint-disable-next-line react/prop-types
          checked={selectedList.includes(comm.id)}
          onChange={(event) => handleUpdateSelectedCommunicationsList(comm.id, event.target.checked)}
          color="primary"
        />
      ),
    };

    communicationColumns = [communicationSelectionColumn].concat(columnData);
  }

  const handleCommunicationClick = (communicationId) => {
    if (enableSelection) {
      onSelectCommunication(
        selectedList.includes(communicationId)
          ? _.without(selectedList, communicationId)
          : [...selectedList, communicationId]
      );
    } else {
      onSelectCommunication(communicationId);
    }
  };

  return (
    <>
      {enableSelection && (
        <div className={classes.cardDivRow}>
          <Grid container>
            <Grid item md={8}>
              <Typography variant="subtitle1">
                <strong>{selectedList.length}</strong>&nbsp; communications are selected
              </Typography>
            </Grid>
            <Grid item md={4}>
              <ButtonGroup variant="text" size="small" className={classes.inLineButtonsContainer}>
                <Button
                  onClick={() =>
                    onUpdatedSelected(
                      _.union(
                        selectedList,
                        communications.map((comm) => comm.id)
                      )
                    )
                  }
                >
                  All
                </Button>
                <Button
                  onClick={() =>
                    onUpdatedSelected(
                      selectedList.filter((comm_id) => !communications.find((comm) => comm.id === comm_id))
                    )
                  }
                >
                  None
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </div>
      )}
      <SortableTable
        rows={communications}
        columns={communicationColumns}
        onRowClick={handleCommunicationClick}
        selectedRowsIds={enableSelection ? selectedList : [selectedCommunicationId]}
        defaultOrderColumn={communicationColumns.findIndex((column) => column.id === 'datetime')}
        maxHeight={maxHeight}
        paginationProps={paginationProps}
        onSortByColumn={onSortByColumn}
        autoPaginateRowsPerPage={autoPaginateRowsPerPage}
        order="desc"
        stickyHeader
        disableSortByUser={disableSortByUser}
      />
      {communicationToShowInDialog && (
        <ViewCommunicationCardContainer
          claimId={communicationToShowInDialog.claim_id}
          communicationId={communicationToShowInDialog.id}
          onClose={() => setCommunicationToShowInDialog(undefined)}
          onUpdate={onClaimUpdate}
          isDialog
          displayAttachClaim={false}
        />
      )}
    </>
  );
}

CommunicationsTable.propTypes = {
  communications: PropTypes.array.isRequired,
  selectedCommunicationId: PropTypes.number,
  onSelectCommunication: PropTypes.func.isRequired,
  enableSelection: PropTypes.bool,
  selectedList: requiredIf(PropTypes.array, (props) => props.enableSelection),
  onUpdatedSelected: requiredIf(PropTypes.func, (props) => props.enableSelection),
  maxHeight: PropTypes.string,
  autoPaginateRowsPerPage: PropTypes.number,
  addViewColumn: PropTypes.bool,
  removeExposuresColumn: PropTypes.bool,
  removeChannelColumn: PropTypes.bool,
  removeDirectionColumn: PropTypes.bool,
  removeAdjusterColumn: PropTypes.bool,
  removeEmailDomainColumn: PropTypes.bool,
  addClaimColumn: PropTypes.bool,
  paginationProps: PropTypes.object,
  onSortByColumn: PropTypes.func,
  disableSortByUser: PropTypes.bool,
  showSummary: PropTypes.bool,
  showCLabel: PropTypes.bool,
  addSummaryColumn: PropTypes.bool,
  addSubjectColumn: PropTypes.bool,
};

export default CommunicationsTable;
