import React from 'react';

import SvgIcon from '../core/SvgIcon';

const CopyToClipboardIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path d="M10.6673 0.666668H2.66732C1.93398 0.666668 1.33398 1.26667 1.33398 2V11.3333H2.66732V2H10.6673V0.666668ZM12.6673 3.33333H5.33398C4.60065 3.33333 4.00065 3.93333 4.00065 4.66667V14C4.00065 14.7333 4.60065 15.3333 5.33398 15.3333H12.6673C13.4007 15.3333 14.0007 14.7333 14.0007 14V4.66667C14.0007 3.93333 13.4007 3.33333 12.6673 3.33333ZM12.6673 14H5.33398V4.66667H12.6673V14Z" />
  </SvgIcon>
);

export default CopyToClipboardIcon;
