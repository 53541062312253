import React, { Component, Fragment } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AddressAutocompleteFormik from '~/components/core/AddressAutocomplete/AddressAutocompleteFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { LOCATION_TYPES_DICT } from '../Types';
import { getPostalCodeYupTestParams, locationToLocationFullString } from '../Utils';

import useGlobalAddresses from './hooks/useGlobalAddresses';
import CardDialog from './CardDialog';
import CountryAutocompleteFormik from './CountryAutocompleteFormik';
import { StateFieldFormik } from './GlobalLossLocation';
import { PencilIcon } from './icons';
import TextFieldFormik, { ShowOnlyTextField } from './TextFieldFormik';

const spacing = 1;

const locationPropTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  county: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  zipcode: PropTypes.string, // to allow the empty string
};

const getLocationFields = (defaultCountry) => ({
  address1: '',
  address2: '',
  city: '',
  county: '',
  country: defaultCountry,
  state: '',
  zipcode: '',
});

function LocationFormikInner(props) {
  const {
    classes,
    handleSubmit, // comes from Formik
    setFieldValue, // comes from Formik
    values, // comes from Formik
    isSubmitting, // comes from Formik
    onCancel,
    submitText,
    locationOptions,
    additionalFieldsComponent,
    countriesConfigurationKey,
  } = props;
  const { defaultCountry } = useGlobalAddresses({ countriesConfigurationKey: 'loss_location' });

  function handleSelectLocationOption(e) {
    const locationOptionId = e.target.value;
    setFieldValue('location_option_id', locationOptionId);

    if (locationOptionId === 'custom') {
      Object.keys(getLocationFields(defaultCountry)).forEach((locationField) => {
        setFieldValue(locationField, '');
      });
      return;
    }

    const locationOption = locationOptions.find((lo) => lo.id === locationOptionId);
    Object.keys(getLocationFields(defaultCountry)).forEach((locationField) => {
      setFieldValue(locationField, locationOption[locationField]);
    });
  }

  const shouldShowState = values.country === 'US';

  return (
    <Grid container spacing={spacing}>
      {additionalFieldsComponent}
      {locationOptions && locationOptions.length > 0 && (
        <Grid item xs={12}>
          <TextFieldFormik
            id="location_option_id"
            select
            label="Addresses"
            className={classes.textField}
            onChange={handleSelectLocationOption}
            fullWidth
          >
            {locationOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
            <MenuItem value="custom">Custom</MenuItem>
          </TextFieldFormik>
        </Grid>
      )}
      <Grid item xs={12}>
        <CountryAutocompleteFormik
          id="country"
          label="Country"
          fullWidth
          countriesConfigurationKey={countriesConfigurationKey}
        />
      </Grid>
      <Grid item xs={12}>
        <AddressAutocompleteFormik
          id="address1"
          label="Address 1"
          fullWidth
          className={classes.textField}
          helperText={!shouldShowState && 'Street name & number, P.O. box, C/O, etc.'}
          streetAddress1FieldId="address1"
          countyFieldId="county"
          countryFieldId="country"
          zipcodeFieldId="zipcode"
          cityFieldId="city"
          stateFieldId="state"
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik
          id="address2"
          label="Address 2"
          fullWidth
          className={classes.textField}
          helperText={!shouldShowState && 'Apartment, suite, unit, building name, floor, etc.'}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik id="city" label="City" fullWidth className={classes.textField} />
      </Grid>
      {shouldShowState && (
        <>
          <Grid item xs={12}>
            <TextFieldFormik id="county" label="County" fullWidth className={classes.textField} />
          </Grid>
        </>
      )}
      {shouldShowState && (
        <Grid item xs={6}>
          <StateFieldFormik id="state" country={values.country} />
        </Grid>
      )}
      <Grid item xs={6}>
        <TextFieldFormik id="zipcode" label="Postal Code" className={classes.textField} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.buttonsContainer}>
          <CancelButton disabled={isSubmitting} onClick={onCancel} />
          <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
            {submitText || 'Save'}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

LocationFormikInner.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired, // comes from Formik
  setFieldValue: PropTypes.func.isRequired, // comes from Formik
  values: PropTypes.object.isRequired, // comes from Formik
  isSubmitting: PropTypes.bool, // comes from Formik
  onCancel: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  locationOptions: PropTypes.array,
  additionalFieldsComponent: PropTypes.node,
  countriesConfigurationKey: PropTypes.oneOf([...Object.keys(LOCATION_TYPES_DICT), 'contact_location']).isRequired,
};

const LocationFormik = (props) => {
  const { classes, location, onSave, onCancel, locationOptions, additionalFieldsComponent, countriesConfigurationKey } =
    props;
  const { countriesOptions, defaultCountry } = useGlobalAddresses({ countriesConfigurationKey });

  const initialValues = location ? { ...location } : { ...getLocationFields(defaultCountry) };

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      validationSchema={Yup.object().shape({
        country: Yup.string().oneOf(
          countriesOptions,
          `Country is not supported for ${LOCATION_TYPES_DICT[countriesConfigurationKey]}`
        ),
        zipcode: Yup.string().when('country', (country, currSchema) =>
          currSchema.test(...getPostalCodeYupTestParams(false, country))
        ),
      })}
      onSubmit={onSave}
      enableReinitialize
    >
      {(formikProps) => (
        <LocationFormikInner
          {...formikProps}
          classes={classes}
          onCancel={onCancel}
          locationOptions={locationOptions}
          additionalFieldsComponent={additionalFieldsComponent}
          countriesConfigurationKey={countriesConfigurationKey}
        />
      )}
    </Formik>
  );
};

LocationFormik.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape(locationPropTypes),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  locationOptions: PropTypes.array,
  additionalFieldsComponent: PropTypes.node,
  countriesConfigurationKey: PropTypes.oneOf([...Object.keys(LOCATION_TYPES_DICT), 'contact_location']).isRequired,
};

class LocationTextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      dialogOpenedOnceHack: false, // TODO: change this to something better
    };
  }

  handleFocus = (fullAddress) => {
    const { dialogOpenedOnceHack } = this.state;

    if (!fullAddress) {
      // prevent infinite modal if user leaves address empty
      if (!dialogOpenedOnceHack) {
        this.setState({ dialogOpen: true, dialogOpenedOnceHack: true });
      }
    }
  };

  handleSave = async (location) => {
    const { onChangeLocation } = this.props;
    await onChangeLocation(location);
    this.setState({ dialogOpen: false });
  };

  handleClose = () => this.setState({ dialogOpen: false, dialogOpenedOnceHack: true });

  render() {
    const {
      classes,
      label,
      placeHolder,
      location,
      showOnly,
      onEdit,
      disabled,
      error,
      helperText,
      additionalFieldsComponent,
      locationOptions,
      countriesConfigurationKey = 'loss_location',
    } = this.props;
    const { dialogOpen } = this.state;

    const fullAddress = location ? locationToLocationFullString(location) : placeHolder || '';

    return (
      <Fragment>
        <div className={classes.containerCentered}>
          {showOnly ? (
            <ShowOnlyTextField
              label={label}
              showOnlyValueComponent={fullAddress}
              classes={classes}
              className={classes.textField}
              fullWidth
              disabled={disabled}
              onEdit={
                onEdit && !disabled
                  ? () => {
                      onEdit();
                      this.setState({ dialogOpen: true });
                    }
                  : undefined
              }
            />
          ) : (
            <Fragment>
              <TextField
                label={label}
                className={classes.textField}
                value={fullAddress}
                fullWidth
                disabled={disabled}
                onChange={() => {}}
                onBlur={() => this.setState({ dialogOpenedOnceHack: false })}
                onFocus={() => this.handleFocus(fullAddress)}
                error={error}
                helperText={helperText}
                size="small"
              />
              <IconButton
                size="small"
                className={classes.rightButtonIcon}
                disabled={disabled}
                onClick={() => this.setState({ dialogOpen: true })}
              >
                <PencilIcon />
              </IconButton>
            </Fragment>
          )}
        </div>
        <CardDialog isDialog title={label} onClose={this.handleClose} open={dialogOpen} maxWidth="sm">
          <LocationFormik
            {...this.props}
            location={location}
            onCancel={this.handleClose}
            onSave={this.handleSave}
            locationOptions={locationOptions}
            additionalFieldsComponent={additionalFieldsComponent}
            countriesConfigurationKey={countriesConfigurationKey}
          />
        </CardDialog>
      </Fragment>
    );
  }
}

LocationTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  location: PropTypes.shape(locationPropTypes),
  onChangeLocation: requiredIf(PropTypes.func, (props) => !props.showOnly),
  locationOptions: PropTypes.array,
  showOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: requiredIf(PropTypes.string, (props) => props.error),
  placeHolder: PropTypes.node,
  additionalFieldsComponent: PropTypes.node,
  countriesConfigurationKey: PropTypes.oneOf([...Object.keys(LOCATION_TYPES_DICT), 'contact_location']),
};

export { getLocationFields, LocationFormikInner };
export default LocationTextField;
