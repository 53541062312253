import { useCallback, useEffect, useRef } from 'react';

const useUnloadWindowEventListener = () => {
  const shouldDisableWarnRef = useRef(false);

  const setDisableWarn = (value) => {
    shouldDisableWarnRef.current = value;
  };

  const onUnload = useCallback((e) => {
    // allow close if already submitted or saved a draft
    if (shouldDisableWarnRef.current) {
      return;
    }

    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = '';
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [onUnload]);

  return {
    setDisableWarn,
  };
};

export default useUnloadWindowEventListener;
