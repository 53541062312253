import React from 'react';
import { useFormikContext } from 'formik';

import { FsMenuItem } from '~/components/core';
import { LEGAL_ACTION_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import { useLegalActionsConfiguration } from '~/components/LegalActions/hooks/useLegalActionsConfiguration';
import { isOptionOther } from '~/components/LegalActions/LegalActionsUtils';
import TextFieldFormik, { MonetaryValueTextFieldFormik } from '~/components/TextFieldFormik';

export const CloseLegalActionForm = () => {
  const { methodOfConclusionOptions } = useLegalActionsConfiguration();
  const { values } = useFormikContext();

  return (
    <>
      <div className="flex w-full gap-32">
        <TextFieldFormik
          fullWidth
          id={LEGAL_ACTION_FIELDS.methodOfConclusion.key}
          label={LEGAL_ACTION_FIELDS.methodOfConclusion.label}
          select
        >
          {methodOfConclusionOptions.map((option) => (
            <FsMenuItem key={option.key} value={option.key}>
              {option.description}
            </FsMenuItem>
          ))}
        </TextFieldFormik>
        <TextFieldFormik
          fullWidth
          id={LEGAL_ACTION_FIELDS.methodOfConclusionText.key}
          label={LEGAL_ACTION_FIELDS.methodOfConclusionText.label}
          disabled={!isOptionOther(methodOfConclusionOptions, values[LEGAL_ACTION_FIELDS.methodOfConclusion.key])}
        />
      </div>
      <MonetaryValueTextFieldFormik
        id={LEGAL_ACTION_FIELDS.finalSettlementAmount.key}
        label={LEGAL_ACTION_FIELDS.finalSettlementAmount.label}
      />
    </>
  );
};
