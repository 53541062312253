import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import first from 'lodash/first';
import intersection from 'lodash/intersection';
import last from 'lodash/last';
import map from 'lodash/map';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import TextFieldFormik from '~/components/TextFieldFormik';

import { PAYMENT_PRE_SUBMIT_CHECKS } from '../../../../../Types';
import CardDialog from '../../../../CardDialog';
import { Heading, Text } from '../../../../core';
import { WarningIcon } from '../../../../icons';

import { getCheckComponentByCheckKey } from './getCheckComponentByCheckKey';

import { useStyles } from '../../../../../assets/styles';
import styles from './additionalPaymentVerificationDialog.module.scss';

const InfoBanner = ({ isNoneCanApprove, isOfacRequestFailed, responseFieldId }) => {
  if (isNoneCanApprove) {
    return (
      <AlertBanner
        alertType={AlertBanner.ALERT_TYPES.WARNING}
        note="Payment requires verifications which are beyond the authority of you or any of your supervisors. To proceed please reach out to the relevant authority."
      />
    );
  } else if (isOfacRequestFailed) {
    return (
      <>
        <AlertBanner
          alertType={AlertBanner.ALERT_TYPES.WARNING}
          title={PAYMENT_PRE_SUBMIT_CHECKS['check_types']['ofac_error']['issue_title']}
          note={PAYMENT_PRE_SUBMIT_CHECKS['check_types']['ofac_error']['issue_dialog_details']}
        />

        <div>
          <TextFieldFormik className={styles.reason} id={responseFieldId} label="OFAC override reason" fullWidth />
        </div>
      </>
    );
  } else {
    return (
      <Text className={styles.subtitle} variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
        Please review the details and add a reason before proceeding.
      </Text>
    );
  }
};

InfoBanner.propTypes = {
  isNoneCanApprove: PropTypes.bool.isRequired,
  isOfacRequestFailed: PropTypes.bool.isRequired,
  responseFieldId: PropTypes.string.isRequired,
};

const AdditionalPaymentVerificationDialog = ({ preSubmitChecks, onClose, onProceed, values, disabled }) => {
  const classes = useStyles();
  const theme = useTheme();

  const existingCheckKeys = Object.keys(preSubmitChecks);

  const getAllChecksApprovalOption = () => {
    const APPROVAL_OPTIONS_SORTED_BY_PRIORITY = [
      PAYMENT_PRE_SUBMIT_CHECKS['approval_options']['none'],
      PAYMENT_PRE_SUBMIT_CHECKS['approval_options']['supervisor'],
      PAYMENT_PRE_SUBMIT_CHECKS['approval_options']['adjuster_override'],
    ];

    const approvalOptions = map(preSubmitChecks, 'approval_option');
    const sortedOptions = intersection(APPROVAL_OPTIONS_SORTED_BY_PRIORITY, approvalOptions);

    return first(sortedOptions) || last(APPROVAL_OPTIONS_SORTED_BY_PRIORITY);
  };

  const approvalOption = getAllChecksApprovalOption();

  const isNoneCanApprove = approvalOption === PAYMENT_PRE_SUBMIT_CHECKS['approval_options']['none'];
  const requiresSupervisorApproval = approvalOption === PAYMENT_PRE_SUBMIT_CHECKS['approval_options']['supervisor'];
  const isAdjusterCanApprove = approvalOption === PAYMENT_PRE_SUBMIT_CHECKS['approval_options']['adjuster_override'];

  const isOfacRequestFailed =
    Object.keys(preSubmitChecks).includes('ofac_hit') &&
    preSubmitChecks.ofac_hit.issue_title === PAYMENT_PRE_SUBMIT_CHECKS['check_types']['ofac_error']['issue_title'];

  const getSubmitButtonText = () => {
    if (requiresSupervisorApproval) {
      return 'Request Approval';
    } else if (isAdjusterCanApprove) {
      return 'Make Payment';
    }
  };

  return (
    <Formik
      initialValues={{
        compliance_override_reason: '',
        force_duplicate_request: false,
        force_duplicate_request_reason: '',
        is_override_limit: false,
        override_limit_reason: '',
      }}
      validationSchema={Yup.object().shape({
        compliance_override_reason: ['ofac_hit', 'other_compliance'].some((key) => existingCheckKeys.includes(key))
          ? Yup.string().required('Required')
          : undefined,
        force_duplicate_request: Yup.boolean(),
        force_duplicate_request_reason: existingCheckKeys.includes('duplicate_payment')
          ? Yup.string().required('Required')
          : undefined,
        is_override_limit: Yup.boolean(),
        override_limit_reason: existingCheckKeys.includes('indemnity_limit_exceeded')
          ? Yup.string().required('Required')
          : undefined,
      })}
      onSubmit={async (values) => {
        await onProceed(values);
      }}
    >
      {(formikProps) => {
        return (
          <CardDialog
            noCardTitle
            isDialog
            onClose={onClose}
            className={styles.additionalPaymentVerificationDialog}
            maxWidth="sm"
            fullWidth
          >
            <div className={styles.textWrapper}>
              <WarningIcon iconColor={theme.palette.warning.main} size={50} />
              <Heading variant="h1">Please review potential issue{existingCheckKeys.length > 1 ? 's' : ''}</Heading>

              <InfoBanner
                isNoneCanApprove={isNoneCanApprove}
                isOfacRequestFailed={isOfacRequestFailed}
                responseFieldId="compliance_override_reason"
              />
            </div>

            <div className={styles.preSubmitCheckContainer}>
              {Object.keys(preSubmitChecks).map((issueKey) => (
                <div key={issueKey}>
                  {getCheckComponentByCheckKey(
                    issueKey,
                    preSubmitChecks,
                    values.amount,
                    !isNoneCanApprove,
                    isOfacRequestFailed
                  )}
                </div>
              ))}
            </div>

            <div className={classes.buttonsContainer}>
              <CancelButton disabled={formikProps.isSubmitting || disabled} onClick={onClose} />
              {isNoneCanApprove ? null : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={formikProps.isSubmitting || disabled}
                  onClick={formikProps.handleSubmit}
                >
                  {getSubmitButtonText()}
                </Button>
              )}
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

AdditionalPaymentVerificationDialog.propTypes = {
  preSubmitChecks: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AdditionalPaymentVerificationDialog;
