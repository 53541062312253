const MARSHMALLOW_WEATHER_CONDITIONS_DICT = {
  clear: {
    desc: 'Clear',
  },
  fog_mist: {
    desc: 'Fog/Mist',
  },
  rain_hail: {
    desc: 'Rain/Hail',
  },
  snow_sleet: {
    desc: 'Snow/Sleet',
  },
  strong_wind: {
    desc: 'Strong wind',
  },
  unknown: {
    desc: 'Unknown',
  },
  not_applicable: {
    desc: 'Not applicable',
  },
} as const;

export default MARSHMALLOW_WEATHER_CONDITIONS_DICT;
