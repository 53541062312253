import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import ElevatedCard from '~/components/core/Cards/ElevatedCard';
import PermissionError from '~/components/core/PermissionError/PermissionError';
import { logoutCurrentUser } from '~/Utils';

import { FsButton } from '../index';

import styles from './PermissionError.module.scss';

export const PermissionDeniedPage = ({ action, verb }) => {
  const history = useHistory();

  return (
    <div className={styles.permissionDeniedPage}>
      <ElevatedCard className={styles.dialogContent}>
        <PermissionError action={action} verb={verb} />
        <div className={styles.buttonContainer}>
          <FsButton variant={FsButton.VARIANTS.contained} onClick={() => history.push('/')}>
            Back To Home
          </FsButton>
        </div>
      </ElevatedCard>
    </div>
  );
};

PermissionDeniedPage.propTypes = {
  action: PropTypes.string,
  verb: PropTypes.string,
};

export const MissingProfilePage = () => {
  return (
    <div className={styles.permissionDeniedPage}>
      <ElevatedCard className={styles.dialogContent}>
        <PermissionError
          message={`
        Your user seems to be missing a permissions profile. users without a permissions profile can not access the system.
     Please contact your system administrator.`}
        />
        <div className={styles.logoutContainer}>
          <FsButton variant={FsButton.VARIANTS.contained} onClick={logoutCurrentUser} color={FsButton.COLORS.primary}>
            Logout
          </FsButton>
        </div>
      </ElevatedCard>
    </div>
  );
};
