import React from 'react';
import { Formik, useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import CheckboxFormik from '~/components/CheckboxFormik';
import { useClaim } from '~/components/ClaimContainer';
import type { ContactFullModel } from '~/components/Contacts/types';
import Button from '~/components/core/Atomic/Buttons/Button';
import { useCms } from '~/components/hooks/useCms';
import HoverActionField from '~/components/HoverActionField';
import { reminderValidationSchema } from '~/components/ReminderNotificationContainer';
import ReminderDialogCard from '~/components/ReminderNotificationContainer/ReminderDialogCard';
import { isoDateToUs } from '~/DateTimeUtils';

import type { ChannelType } from './constants';
import { getCommunicationReminderDefaultValues } from './utils';

interface CommunicationReminderContainerValues {
  add_follow_up_reminder?: boolean;
  channel: ChannelType;
  contact: ContactFullModel;
  reminder: {
    title: string;
    details: string;
    target_id: number;
    was_manually_edited: boolean;
    due_date: string;
  };
}

const CommunicationReminderContainer: React.FC = () => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<CommunicationReminderContainerValues>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { claim } = useClaim();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useCms();
  const classes = useStyles();
  const [reminderDialogOpen, setReminderDialogOpen] = React.useState(false);

  const { channel, contact } = values;
  const was_reminder_manually_edited = values['reminder']['was_manually_edited'];
  React.useEffect(() => {
    if (was_reminder_manually_edited) {
      return;
    }

    setFieldValue('reminder', getCommunicationReminderDefaultValues(channel, contact, user, claim));
  }, [setFieldValue, was_reminder_manually_edited, channel, contact, user, claim]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CheckboxFormik id="add_follow_up_reminder" label="Create a Reminder to follow up on this communication" />
        {values['add_follow_up_reminder'] && (
          <HoverActionField onAction={() => setReminderDialogOpen(true)} disabled={isSubmitting}>
            <em>{`Reminder will be set for ${isoDateToUs(values['reminder'].due_date)}`}</em>
          </HoverActionField>
        )}
      </div>
      {reminderDialogOpen && (
        <Formik
          initialValues={{
            ...values['reminder'], // NOTE: values is of the outer formik
          }}
          validationSchema={reminderValidationSchema}
          enableReinitialize
          onSubmit={(values) => {
            setFieldValue('reminder', { ...values, was_manually_edited: true }); // NOTE: setFieldValue is of the outer formik while values is of the inner one
            setReminderDialogOpen(false);
          }}
        >
          {(formikProps) => {
            const { isSubmitting, handleSubmit } = formikProps;

            const cardDialogProps = {
              open: true,
              isDialog: true,
              fullWidth: true,
              maxWidth: 'sm',
              onClose: () => setReminderDialogOpen(false),
              title: 'Communication Reminder',
              preventClose: isSubmitting,
            };

            const buttonsComponent = (
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                  Save
                </Button>
              </div>
            );

            return (
              <ReminderDialogCard buttonsComponent={buttonsComponent} cardDialogProps={cardDialogProps} claim={claim} />
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default CommunicationReminderContainer;
