import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import StrikeIcon from '../../../../icons/editor/StrikeIcon';
import ToolbarButton from '../ToolbarButton';

const Strike: React.FC = () => {
  const { editor } = useCurrentEditor();

  const toggle = () => {
    editor?.chain().focus().toggleStrike().run();
  };

  return (
    <ToolbarButton onClick={toggle} disabled={!editor?.can().toggleStrike()} isActive={editor?.isActive('strike')}>
      <StrikeIcon />
    </ToolbarButton>
  );
};

export default Strike;
