import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CardDialog from '../../CardDialog';
import DocumentsAttachment from '../../Documents/DocumentsAttachment';
import InlineIconButton from '../../InlineIconButton';

import { AttachmentIcon } from './../../icons';

import { useStyles } from '../../../assets/styles';

const AttachedDocumentsContainer = ({ row }) => {
  const classes = useStyles();

  const [documentsTableDialogOpen, setDocumentsTableDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDocumentsTableDialogOpen(false);
  };

  const handleOpenDocumentsTableDialog = () => {
    setDocumentsTableDialogOpen(true);
  };
  return (
    <>
      {row.payment_request.attached_document_ids.length > 0 ? (
        <InlineIconButton
          icon={AttachmentIcon}
          onClick={handleOpenDocumentsTableDialog}
          className={classes.hoverableNonFilledIcon}
        />
      ) : null}
      {documentsTableDialogOpen ? (
        <CardDialog isDialog title="Attached Documents" onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DocumentsAttachment
            attachedDocuments={row.payment_request.attached_documents}
            maxHeight="35vh"
            hideAttachmentFilename
            showOnly
          />
        </CardDialog>
      ) : null}
    </>
  );
};

AttachedDocumentsContainer.propTypes = {
  row: PropTypes.object.isRequired,
};

export default AttachedDocumentsContainer;
