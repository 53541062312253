import { CONFIGURATION_FEATURES_NAMES, COUNTRY_TO_STATE_MAP } from '../../../../Types';
import { isFeatureEnabled, isOrganizationUsOrg } from '../../../../Utils';

import { LICENSE_EXPIRATION_STATUS } from './consts';

export const RESIDENCY_FIELD_ID = 'residency_licenses';
export const OTHERS_FIELD_ID = 'non_residency_licenses';
export const ALL_LICENSES_FIELD_ID = 'licenses';

export const getLicenseStatus = (expiration) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // To make sure we are comparing from the start of today

  const expirationDate = new Date(expiration);

  if (expirationDate < today) {
    return LICENSE_EXPIRATION_STATUS.EXPIRED;
  }

  const thirtyDaysFromNow = new Date();
  thirtyDaysFromNow.setDate(today.getDate() + 30);
  return expirationDate <= thirtyDaysFromNow ? LICENSE_EXPIRATION_STATUS.EXPIRING : LICENSE_EXPIRATION_STATUS.VALID;
};

export const getStateName = (stateCode) => COUNTRY_TO_STATE_MAP.US[stateCode];

export const stateCodesArray = Object.keys(COUNTRY_TO_STATE_MAP.US).filter(Boolean).sort();

export const isLicensesEnabled = (organization) => {
  const featureEnabled = isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.STATES_LICENSES);
  const isUsOrg = isOrganizationUsOrg(organization);
  return featureEnabled && isUsOrg;
};
