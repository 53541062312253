import React from 'react';
import { noop } from 'lodash';

import type { TableauEmbedOptions } from '~/components/ReportsPage/types';

interface TableauEmbedProps {
  options: TableauEmbedOptions;
  ref: React.RefObject<HTMLTableElement>;
}

/**
 * This hook configure and register required events on the given Embedded Tableau Viz Element
 * @param options
 * @param ref
 */
export const useTableauEmbed = ({ options = {}, ref }: TableauEmbedProps): Record<string, never> => {
  const {
    onWorkbookPublished = noop,
    onWorkbookPublishedAs = noop,
    onWorkbookReadyToClose = noop,
    onEditButtonClicked = noop,
    onFirstInteractive = noop,
  } = options;
  React.useEffect(() => {
    if (!ref?.current) return;

    const viz = ref.current;
    viz.addEventListener('workbookpublished', onWorkbookPublished);
    viz.addEventListener('workbookpublishedas', onWorkbookPublishedAs);
    viz.addEventListener('workbookreadytoclose', onWorkbookReadyToClose);
    viz.addEventListener('editbuttonclicked', onEditButtonClicked);
    viz.addEventListener('firstinteractive', onFirstInteractive);

    return () => {
      viz.removeEventListener('workbookpublished', onWorkbookPublished);
      viz.removeEventListener('workbookpublishedas', onWorkbookPublishedAs);
      viz.removeEventListener('workbookreadytoclose', onWorkbookReadyToClose);
      viz.removeEventListener('editbuttonclicked', onEditButtonClicked);
      viz.removeEventListener('firstinteractive', onFirstInteractive);
    };
  }, [
    onWorkbookPublished,
    onWorkbookPublishedAs,
    onWorkbookReadyToClose,
    onEditButtonClicked,
    onFirstInteractive,
    ref,
  ]);

  return {};
};
