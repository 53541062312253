import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const PetIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      opacity="0.2"
      d="M14.4375 6.75C14.4375 7.40584 13.9058 7.9375 13.25 7.9375C12.5942 7.9375 12.0625 7.40584 12.0625 6.75C12.0625 6.09416 12.5942 5.5625 13.25 5.5625C13.9058 5.5625 14.4375 6.09416 14.4375 6.75Z"
      fill="#32C7CA"
      stroke={colors.lobIconMain}
      strokeWidth="0.125"
    />
    <path
      opacity="0.2"
      d="M3.9375 6.75C3.9375 7.40584 3.40584 7.9375 2.75 7.9375C2.09416 7.9375 1.5625 7.40584 1.5625 6.75C1.5625 6.09416 2.09416 5.5625 2.75 5.5625C3.40584 5.5625 3.9375 6.09416 3.9375 6.75Z"
      fill="#32C7CA"
      stroke={colors.lobIconMain}
      strokeWidth="0.125"
    />
    <path
      opacity="0.2"
      d="M6.9375 3.75C6.9375 4.40584 6.40584 4.9375 5.75 4.9375C5.09416 4.9375 4.5625 4.40584 4.5625 3.75C4.5625 3.09416 5.09416 2.5625 5.75 2.5625C6.40584 2.5625 6.9375 3.09416 6.9375 3.75Z"
      fill="#32C7CA"
      stroke={colors.lobIconMain}
      strokeWidth="0.125"
    />
    <path
      opacity="0.2"
      d="M11.4375 3.75C11.4375 4.40584 10.9058 4.9375 10.25 4.9375C9.59416 4.9375 9.0625 4.40584 9.0625 3.75C9.0625 3.09416 9.59416 2.5625 10.25 2.5625C10.9058 2.5625 11.4375 3.09416 11.4375 3.75Z"
      fill="#32C7CA"
      stroke={colors.lobIconMain}
      strokeWidth="0.125"
    />
    <path
      d="M11.3436 10.2537C11 10.5 10.5603 10.2537 10.5603 10.2537C10 10 9.61705 9.80377 9.22694 9.50997C8.83683 9.21617 8.38197 9.00004 7.8936 9.00004C7.40523 9.00004 6.95038 9.21617 6.56027 9.50997C6.17016 9.80377 5.3936 10.2537 5.3936 10.2537C5 10.5 4.5 10.5 4.4436 10.2537C4.05378 10.4661 3.7457 9.80209 3.56789 10.2088C3.39008 10.6156 3.35265 11.07 3.46149 11.5003C3.57034 11.9307 3.5438 12.7366 3.89361 13.0099C4.24341 13.2832 4.94968 13.0084 5.3936 13.0099C5.6618 13.0101 5.92735 12.957 6.17485 12.8537C6.71971 12.6278 7.30376 12.5115 7.8936 12.5115C8.48344 12.5115 9.06749 12.6278 9.61235 12.8537C9.85985 12.957 10.1254 13.0101 10.3936 13.0099C10.8375 13.0084 11.2683 12.8592 11.6181 12.5859C11.9679 12.3126 12.2169 11.9307 12.3257 11.5003C12.4346 11.07 12.3971 10.6156 12.2193 10.2088C12.0415 9.80209 11.7334 10.4661 11.3436 10.2537Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M13.25 8C13.9404 8 14.5 7.44036 14.5 6.75C14.5 6.05964 13.9404 5.5 13.25 5.5C12.5596 5.5 12 6.05964 12 6.75C12 7.44036 12.5596 8 13.25 8Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.75 8C3.44036 8 4 7.44036 4 6.75C4 6.05964 3.44036 5.5 2.75 5.5C2.05964 5.5 1.5 6.05964 1.5 6.75C1.5 7.44036 2.05964 8 2.75 8Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.75 5C6.44036 5 7 4.44036 7 3.75C7 3.05964 6.44036 2.5 5.75 2.5C5.05964 2.5 4.5 3.05964 4.5 3.75C4.5 4.44036 5.05964 5 5.75 5Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.25 5C10.9404 5 11.5 4.44036 11.5 3.75C11.5 3.05964 10.9404 2.5 10.25 2.5C9.55964 2.5 9 3.05964 9 3.75C9 4.44036 9.55964 5 10.25 5Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.45 9.74375C10.8235 9.39287 10.3608 8.80889 10.1625 8.11875C10.0276 7.64937 9.7437 7.23662 9.35359 6.94281C8.96348 6.64901 8.48837 6.49011 8 6.49011C7.51162 6.49011 7.03651 6.64901 6.6464 6.94281C6.25629 7.23662 5.97237 7.64937 5.8375 8.11875C5.6392 8.80889 5.1765 9.39287 4.55 9.74375C4.16018 9.95613 3.85209 10.2922 3.67428 10.6989C3.49647 11.1057 3.45904 11.56 3.56789 11.9904C3.67673 12.4208 3.92567 12.8027 4.27548 13.076C4.62528 13.3493 5.05608 13.4985 5.5 13.5C5.76819 13.5002 6.03375 13.4471 6.28125 13.3437C6.82611 13.1178 7.41016 13.0016 8 13.0016C8.58983 13.0016 9.17389 13.1178 9.71875 13.3437C9.96625 13.4471 10.2318 13.5002 10.5 13.5C10.9439 13.4985 11.3747 13.3493 11.7245 13.076C12.0743 12.8027 12.3233 12.4208 12.4321 11.9904C12.5409 11.56 12.5035 11.1057 12.3257 10.6989C12.1479 10.2922 11.8398 9.95613 11.45 9.74375Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default PetIcon;
