import React from 'react';

import EmptyState from '~/components/core/EmptyState';

import CardDialog from '../../CardDialog';
import { AddItemButton, LoadingSwitch, Text } from '../../core';
import LegalActionsEmptyStateIllustration from '../../illustrations/LegalActionsEmptyStateIllustration';
import { useLegalActions } from '../hooks/useLegalActions';
import { useLegalActionsConfiguration } from '../hooks/useLegalActionsConfiguration';
import type { LegalActionApiResponse } from '../types';

import { LegalActionLinkCard } from './LegalActionLinkCard';
import { LegalActionsHeader } from './LegalActionsPageHeader';

export const LegalActionsList: React.FC<{
  setIsNewLegalActionDialogOpen: (state: boolean) => void;
}> = ({ setIsNewLegalActionDialogOpen }) => {
  const { isLoading: isConfigLoading, isError: isConfigError } = useLegalActionsConfiguration();
  const { legalActions, isLegalActionsLoading, isLegalActionsError } = useLegalActions();

  const isLoading = isConfigLoading || isLegalActionsLoading;
  const isError = isConfigError || isLegalActionsError;
  const hasLegalActions = Boolean(legalActions?.length);

  return (
    <div className="flex w-full flex-col gap-20">
      <LegalActionsHeader
        onNewLegalActionClicked={() => setIsNewLegalActionDialogOpen(true)}
        showButton={!isLoading && hasLegalActions}
      />
      <LoadingSwitch isLoading={isLoading} isError={isError}>
        {hasLegalActions ? (
          <div className="flex flex-col gap-24">
            <div className="flex flex-row items-center justify-between bg-white p-24">
              <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD}>
                {`${
                  legalActions?.filter((legalAction: LegalActionApiResponse) => legalAction.is_open).length
                } Legal Actions Open`}
              </Text>
            </div>
            <div className="flex flex-row flex-wrap items-center">
              {legalActions?.map((legalAction: LegalActionApiResponse) => (
                <LegalActionLinkCard key={legalAction.id} legalAction={legalAction} />
              ))}
            </div>
          </div>
        ) : null}
        {!isLegalActionsLoading && !hasLegalActions ? (
          <CardDialog isDialog={false} noCardTitle>
            <div className="flex min-h-[70vh] flex-col gap-20">
              <div className="mt-[100px]">
                <EmptyState
                  title="There are no legal actions configured for this claim"
                  illustration={<LegalActionsEmptyStateIllustration />}
                  buttonComponent={
                    <div className="p-4">
                      <AddItemButton
                        text="Add Legal Action"
                        variant="contained"
                        onClick={() => setIsNewLegalActionDialogOpen(true)}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </CardDialog>
        ) : null}
      </LoadingSwitch>
    </div>
  );
};
