import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import { ThumbDownIcon, ThumbUpIcon } from '../../components/icons';
import { COVERAGE_DECISION_DICT, MGM_GL_COVERAGE_DECISION_DICT } from '../../Types';
import { isInshurClaim, isTscClaim } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import RadioButtonFormik from '../RadioButtonFormik';
import TextFieldFormik from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const coverageDecisionFields = {
  coverage_decision: '',
  is_eligibility_confirmed: '',
  reason: '',
};

const coverageDecisionValidationFields = {
  coverage_decision: Yup.string().required('Required'),
  reason: Yup.string().when(['coverage_decision', 'is_eligibility_confirmed'], {
    is: (coverage_decision, is_eligibility_confirmed) =>
      coverage_decision === 'denied' || (coverage_decision === 'confirmed' && is_eligibility_confirmed === false),
    then: Yup.string().required('Required'),
  }),
};

function CoverageDecisionCard(props) {
  // Can be called for a specific exposure, or per the entire claim.
  // Gets the coverageDecision object, and the exposures list (in case of a single exposure it will be of length==1)
  const { cardDialogProps, coverageDecision, exposures, onCancel, onDecideCoverage } = props;
  const [isEditing, setIsEditing] = useState(!coverageDecision);
  const classes = useStyles();
  const { claim } = useClaim();

  const isIndemnityReserveExists = exposures.reduce(
    (isIndemnityReserveExists, exposure) => isIndemnityReserveExists || exposure.indemnity.reserve !== 0,
    false
  );
  const isCoverageIssue = exposures.reduce(
    (isCoverageIssue, exposure) => isCoverageIssue || exposure.is_coverage_issue_exists,
    false
  );

  const disabled = !isEditing || isCoverageIssue;
  return (
    <Formik
      initialValues={coverageDecision ? { ...coverageDecision } : { ...coverageDecisionFields }}
      validationSchema={Yup.object().shape(coverageDecisionValidationFields)}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onDecideCoverage(values)
          .then(() => formikProps.resetForm())
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        return (
          <CardDialog
            title="Coverage Decision"
            {...cardDialogProps}
            closeOnBackdropClick={!isEditing}
            onClose={() => {
              formikProps.handleReset();
              onCancel();
            }}
          >
            {claim.type !== 'gl_claim' ? (
              <>
                <div>
                  <RadioButtonFormik
                    id="coverage_decision"
                    label={
                      <span className={classes.containerCentered}>
                        <ThumbUpIcon className={classes.leftButtonIcon} />
                        {COVERAGE_DECISION_DICT['confirmed']}
                      </span>
                    }
                    optionValue="confirmed"
                    disabled={disabled}
                  />
                </div>
                {(isTscClaim(claim) || isInshurClaim(claim)) &&
                  exposures.length === 1 &&
                  exposures[0].coverage_type === 'coverage_pip' && (
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                          Eligibility Confirmed
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                          <RadioButtonFormik
                            id="is_eligibility_confirmed"
                            label="No"
                            optionValue={false}
                            disabled={disabled || values.coverage_decision !== 'confirmed'}
                            size="small"
                          />
                          <RadioButtonFormik
                            id="is_eligibility_confirmed"
                            label="Yes"
                            optionValue={true}
                            disabled={disabled || values.coverage_decision !== 'confirmed'}
                            size="small"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                {!['wc_claim'].includes(claim.type) && (
                  <div>
                    <RadioButtonFormik
                      id="coverage_decision"
                      label={
                        <span className={classes.containerCentered}>
                          <ThumbUpIcon className={classes.leftButtonIcon} />
                          {COVERAGE_DECISION_DICT['partial_coverage']}
                        </span>
                      }
                      optionValue="partial_coverage"
                      disabled={disabled}
                    />
                  </div>
                )}
                <RadioButtonFormik
                  id="coverage_decision"
                  label={
                    <span className={classes.containerCentered}>
                      <ThumbDownIcon className={classes.leftButtonIcon} />
                      {COVERAGE_DECISION_DICT['denied']}
                    </span>
                  }
                  optionValue="denied"
                  disabled={!isEditing}
                />
                <div>
                  <ErrorHelperTextFormik id="coverage_decision" />
                </div>
                <div>
                  <TextFieldFormik
                    id="reason"
                    label="Reason"
                    rows="3"
                    className={classes.textField}
                    disabled={!isEditing}
                    fullWidth
                    multiline
                  />
                </div>
                {isEditing && isIndemnityReserveExists && values.coverage_decision === 'denied' ? (
                  <Typography display="block" variant="caption" style={{ color: '#e65100' }}>
                    Warning: Indemnity reserve will be zeroed
                  </Typography>
                ) : (
                  <div style={{ visibility: 'hidden' }}>
                    <Typography display="block">Hidden</Typography>
                  </div>
                )}
              </>
            ) : (
              <>
                {Object.keys(MGM_GL_COVERAGE_DECISION_DICT).map((k) => (
                  <div key={k}>
                    <RadioButtonFormik
                      id="coverage_decision"
                      label={
                        <span className={classes.containerCentered}>
                          {MGM_GL_COVERAGE_DECISION_DICT[k].decision === 'confirmed' ? (
                            <ThumbUpIcon className={classes.leftButtonIcon} />
                          ) : (
                            <ThumbDownIcon className={classes.leftButtonIcon} />
                          )}
                          {MGM_GL_COVERAGE_DECISION_DICT[k].desc}
                        </span>
                      }
                      optionValue={k}
                      disabled={disabled}
                    />
                  </div>
                ))}
                {isEditing &&
                isIndemnityReserveExists &&
                values.coverage_decision &&
                MGM_GL_COVERAGE_DECISION_DICT[values.coverage_decision].decision === 'denied' ? (
                  <Typography display="block" variant="caption" style={{ color: '#e65100' }}>
                    Warning: Indemnity reserve will be zeroed
                  </Typography>
                ) : (
                  <div style={{ visibility: 'hidden' }}>
                    <Typography display="block">Hidden</Typography>
                  </div>
                )}
              </>
            )}
            <div className={classes.buttonsContainer}>
              {!coverageDecision || isEditing ? (
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Decide on coverage
                </Button>
              ) : (
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => setIsEditing(true)}>
                  Update decision
                </Button>
              )}
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

CoverageDecisionCard.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  coverageDecision: PropTypes.object,
  exposures: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDecideCoverage: PropTypes.func.isRequired,
};

export default CoverageDecisionCard;
