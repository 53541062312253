import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import PaymentRequestDynamicContainer from '../../exposures/PaymentRequestContainer/PaymentRequestDynamicContainer';
import { ApprovalPaymentIcon } from '../../icons';
import InlineIconButton from '../../InlineIconButton';

import { useStyles } from '../../../assets/styles';

const DISPLAY_STATUS = ['pending_approval'];

const ApprovalRequestDecisionIconContainer = ({
  claimId,
  status,
  exposureId,
  onUpdate = noop,
  paymentRequest = {},
}) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!DISPLAY_STATUS.includes(status)) {
    return null;
  }

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleUpdate = async () => {
    await onUpdate();
    handleClose();
  };

  return (
    <>
      <InlineIconButton
        icon={ApprovalPaymentIcon}
        className={classes.hoverableNonFilledIcon}
        onClick={() => setIsDialogOpen(true)}
        wrapWithSpan
        tooltipTitle="Payment Request"
      />
      {isDialogOpen && (
        <PaymentRequestDynamicContainer
          claimId={claimId}
          exposureId={exposureId ?? 0}
          payableWithReserveId={paymentRequest.payable_with_reserve_id}
          paymentRequest={paymentRequest}
          cardDialogProps={{
            maxWidth: 'sm',
            fullWidth: true,
            isDialog: true,
          }}
          onClose={handleClose}
          onUpdate={handleUpdate}
        />
      )}
    </>
  );
};

ApprovalRequestDecisionIconContainer.propTypes = {
  claimId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  paymentRequest: PropTypes.object,
  exposureId: PropTypes.number,
  onUpdate: PropTypes.func,
};

export default ApprovalRequestDecisionIconContainer;
