import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import _ from 'lodash';

import { MultiSelectTextFieldFormik } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const ID_DIVIDER = '-';

export const IncidentSubTypeMultiselectFormik = ({
  incidentTypes = {},
  fieldId,
  disabled = false,
  label = 'Incident Sub Type',
}) => {
  const classes = useStyles();
  const isDisabled = disabled || _.isEmpty(incidentTypes);
  const subTypeDict = createSubTypeDict(incidentTypes);
  const displaySubType = (key) => subTypeDict[key]?.desc;
  const { setFieldValue, values } = useFormikContext();

  updateSelectedSubTypes({ values, subTypeDict, fieldId, setFieldValue });

  return (
    <MultiSelectTextFieldFormik
      id={fieldId}
      label={label}
      options={Object.keys(subTypeDict)}
      renderValue={(selected) => selected.map(displaySubType).join(', ')}
      className={classes.textField}
      fullWidth
      renderOption={displaySubType}
      disabled={isDisabled}
    />
  );
};

/**
 * Incident Types can be removed after selecting Incident sub type
 * This function will remove from current selected sub-types if the incident type was removed.
 * **/
const updateSelectedSubTypes = ({ values, fieldId, subTypeDict, setFieldValue }) => {
  const currentValues = getIn(values, fieldId, []);
  const existingValues = currentValues.filter((value) => subTypeDict[value]);

  if (!_.isEqual(currentValues, existingValues)) {
    setFieldValue(fieldId, existingValues);
  }
};

/**
 * Create incident subtype dictionary
 * @param {object} incidentTypes
 * @return {object} incidentType_incidentSubType: { desc: type - subType }
 * */
const createSubTypeDict = (incidentTypes) => {
  const subTypeDict = {};
  Object.entries(incidentTypes).forEach(([typeKey, type]) => {
    Object.entries(type.sub_types).forEach(([subTypeKey, subType]) => {
      subTypeDict[`${typeKey}${ID_DIVIDER}${subTypeKey}`] = { desc: `${type.desc} - ${subType.desc}` };
    });
  });

  return subTypeDict;
};

IncidentSubTypeMultiselectFormik.propTypes = {
  fieldId: PropTypes.string.isRequired,
  incidentTypes: PropTypes.shape({
    desc: PropTypes.string,
    sub_types: PropTypes.shape({
      desc: PropTypes.string,
    }),
  }),
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
