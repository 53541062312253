import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';
import { DatePickerTextFieldFormik, TextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function GenericLitigationStatusDialog({ litigationDetails, onSubmit, onClose, subtitle }) {
  const classes = useStyles();
  const { userOrganization } = useCms();
  const { organizationContactRolesDict } = useOrganization();

  const isDefenseAttorneyRequired = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DEFENSE_ATTORNEY);

  return (
    <Formik
      initialValues={litigationDetails}
      validationSchema={Yup.object().shape({
        involved_attorney_contact_id: Yup.number().required('Required'),
        defense_attorney_contact_id: isDefenseAttorneyRequired
          ? Yup.number().required('Required').typeError('you must specify a valid contact')
          : undefined,
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values);
          onClose();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        return (
          <CardDialog
            title={`Litigation Status${subtitle ? ` - ${subtitle}` : ''}`}
            onClose={onClose}
            width="sm"
            isDialog
            open
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              {isDefenseAttorneyRequired && (
                <Grid item xs={6}>
                  <ContactTextFieldFormik
                    id="defense_attorney_contact"
                    label={organizationContactRolesDict.defense_counsel?.desc}
                    className={classes.textField}
                    acceptedRoles={['defense_counsel']}
                    disabled={isSubmitting}
                    fixedSearchResults
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={isDefenseAttorneyRequired ? 6 : 12}>
                <ContactTextFieldFormik
                  id="involved_attorney_contact"
                  label={organizationContactRolesDict.attorney.desc}
                  className={classes.textField}
                  acceptedRoles={['attorney']}
                  disabled={isSubmitting}
                  fixedSearchResults
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="litigation_type"
                  label="Litigation Type"
                  className={classes.textField}
                  disabled={isSubmitting}
                  select
                  fullWidth
                >
                  {['Lawsuit', 'Mediation', 'Arbitration'].map((lt) => (
                    <MenuItem key={lt} value={lt}>
                      {lt}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={12}>
                <DatePickerTextFieldFormik
                  id="litigation_file_date"
                  label="Litigation File Date"
                  className={classes.textField}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePickerTextFieldFormik
                  id="litigation_upcoming_court_date"
                  label="Upcoming Court Date"
                  className={classes.textField}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxFormik id="is_litigation_closed" label="Mark litigation as closed" />
              </Grid>
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

GenericLitigationStatusDialog.propTypes = {
  litigationDetails: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
};

function ExposureLitigationStatusDialog({ exposure, onUpdate, onClose }) {
  const handleSubmit = async (values) => {
    const exposureLitigationUrl = `/api/v1/claims/${exposure.claim_id}/exposures/${exposure.id}/litigation`;
    const method = !exposure.is_in_litigation ? 'post' : 'put';
    try {
      await axios({ method, url: exposureLitigationUrl, data: values });
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }

    await onUpdate();
  };

  return (
    <GenericLitigationStatusDialog
      litigationDetails={{
        involved_attorney_contact_id: exposure.involved_attorney ? exposure.involved_attorney.id : '',
        involved_attorney_contact_full_name: exposure.involved_attorney ? exposure.involved_attorney.full_name : '',
        litigation_type: exposure.litigation_type || '',
        litigation_file_date: exposure.litigation_file_date || '',
        litigation_upcoming_court_date: exposure.litigation_upcoming_court_date || '',
        is_litigation_closed: exposure.is_litigation_closed || false,
        defense_attorney_contact_id: exposure.defense_attorney_contact_id,
      }}
      onSubmit={handleSubmit}
      onClose={onClose}
      subtitle="Exposure Referral"
    />
  );
}

ExposureLitigationStatusDialog.propTypes = {
  exposure: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ExposureLitigationStatusDialog, GenericLitigationStatusDialog };
