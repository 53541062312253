import React from 'react';

import Text from '~/components/core/TextComponents/Text';
import { serverDateTimeToLocal } from '~/DateTimeUtils';

interface CommunicationDateTimeSectionProps {
  communicationDateTime: string;
}

const CommunicationDateTimeSection: React.FC<CommunicationDateTimeSectionProps> = ({ communicationDateTime }) => (
  <div className="flex items-center">
    <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR} className="mr-4">
      {serverDateTimeToLocal(communicationDateTime)}
    </Text>
  </div>
);

export default CommunicationDateTimeSection;
