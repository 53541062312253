import type { CSSProperties } from 'react';
import React from 'react';
import InputBase from '@material-ui/core/InputBase';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';

import SendMessageIcon from '../../icons/SendMessageIcon';

export interface ChatInputFieldProps {
  onUserInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUserSubmit: () => Promise<void>;
  disabled: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  value: string;
  type: 'elevated' | 'inline';
}

const typeToStyleProps: Record<ChatInputFieldProps['type'], { className: string; style: CSSProperties }> = {
  elevated: {
    className: 'flex w-full items-center rounded-2xl border-2 border-solid border-[#1B2559] py-12 pl-20 pr-12',
    style: {
      boxShadow: 'rgb(78 97 236 / 16%) 0px 12px 20px 0px',
    },
  },
  inline: {
    className: 'flex w-full items-center py-12 pl-20 pr-12 border-0 border-t-1 border-solid border-[#23376c38] ',
    style: {},
  },
};

export const ChatInputField: React.FC<ChatInputFieldProps> = ({
  onUserInput,
  onUserSubmit,
  disabled,
  inputRef,
  value,
  type = 'inline',
}) => {
  return (
    <div {...typeToStyleProps[type]}>
      <InputBase
        inputRef={inputRef}
        autoFocus
        fullWidth
        value={value}
        placeholder="Ask me anything"
        onChange={onUserInput}
        disabled={disabled}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            await onUserSubmit();
          }
        }}
      />
      <IconButton className="bg-pink-600 p-12 hover:bg-pink-300" onClick={onUserSubmit} disabled={disabled}>
        <SendMessageIcon iconColor="white" size={20} />
      </IconButton>
    </div>
  );
};

// border-radius: 15px;
// border: 2px solid #1B2559;
// background: #FFF;
// box-shadow: 0px 12px 29.2px 0px rgba(48, 71, 236, 0.10);
