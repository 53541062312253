import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../theme/tailwind/colors';
import SvgIcon from '../core/SvgIcon';

const SendMessageIcon = ({ iconColor = colors.slate[900], ...props }) => (
  <SvgIcon viewBox="0 0 20 20" fill={iconColor} xmlns="http://www.w3.org/2000/svg" stroke={iconColor} {...props}>
    <path
      d="M17.4898 8.90622L4.36484 1.41482C4.1436 1.29071 3.88983 1.23686 3.63726 1.2604C3.38468 1.28395 3.14525 1.38379 2.95076 1.54665C2.75628 1.70951 2.61594 1.92769 2.54841 2.17221C2.48087 2.41673 2.48933 2.676 2.57265 2.9156L4.99452 9.98357C4.99421 9.98616 4.99421 9.98878 4.99452 9.99138C4.99409 9.99397 4.99409 9.99661 4.99452 9.99919L2.57265 17.0828C2.50592 17.2713 2.48538 17.473 2.51274 17.6711C2.5401 17.8691 2.61458 18.0577 2.72991 18.221C2.84525 18.3844 2.99808 18.5176 3.17557 18.6097C3.35307 18.7017 3.55005 18.7498 3.74999 18.75C3.96692 18.7494 4.18004 18.6929 4.36874 18.5859L17.4867 11.082C17.6802 10.9736 17.8414 10.8157 17.9537 10.6244C18.066 10.4332 18.1254 10.2155 18.1258 9.99369C18.1262 9.7719 18.0676 9.554 17.956 9.36234C17.8443 9.17069 17.6837 9.01219 17.4906 8.9031L17.4898 8.90622ZM3.74999 17.5V17.4929L6.10468 10.625H10.625C10.7908 10.625 10.9497 10.5591 11.0669 10.4419C11.1841 10.3247 11.25 10.1657 11.25 9.99997C11.25 9.83421 11.1841 9.67524 11.0669 9.55803C10.9497 9.44082 10.7908 9.37497 10.625 9.37497H6.11093L3.75468 2.50935L3.74999 2.49997L16.875 9.98669L3.74999 17.5Z"
      fill="iconColor"
    />
  </SvgIcon>
);

SendMessageIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default SendMessageIcon;
