const HOME_COVERAGES_DICT = {
  coverage_a: 'A - Dwelling',
  coverage_b: 'B - Other structures',
  coverage_c: 'C - Personal property',
  coverage_d: 'D - Loss of use',
  coverage_e: 'E - Personal liability',
  coverage_f: 'F - Medical payments to others',
  coverage_equipment_breakdown: 'Equipment breakdown',
  coverage_service_line: 'Service line',
  coverage_identity_theft_expense: 'Identity theft',
} as const;

export default HOME_COVERAGES_DICT;
