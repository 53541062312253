import * as Yup from 'yup';

const PaymentDetailsComponent = () => {
  return null;
};
const MethodDetailsComponent = () => {
  return null;
};
const getDefaultMethodSpecificPaymentRequest = () => {
  const methodSpecificInitialValues = {};
  const methodSpecificValidationSchema = Yup.object();
  const additionalFinanceTableMenuOptions = [];

  return {
    methodSpecificInitialValues,
    methodSpecificValidationSchema,
    PaymentDetailsComponent,
    MethodDetailsComponent,
    additionalFinanceTableMenuOptions,
  };
};

export default getDefaultMethodSpecificPaymentRequest;
