import { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { reportAxiosError } from '../../../../Utils';
import { usePrevious } from '../../../TextFieldFormik';

export const useCoverage = ({
  subOrganizationIds,
  organizationId,
  lobs,
  includeOnlyInvolvedPersonsCoverages = false,
  disableGeneral = false,
}) => {
  const prevSubOrgIdsRef = usePrevious(subOrganizationIds);
  const prevLobsRef = usePrevious(lobs);
  const [coveragesOptionsDict, setCoveragesOptionsDict] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      if (!_.isEqual(prevSubOrgIdsRef, subOrganizationIds) || !_.isEqual(prevLobsRef, lobs)) {
        setIsLoading(true);

        try {
          const responses = await Promise.all(
            lobs.map((lob) =>
              axios.get(`/api/v1/organizations/${organizationId}/coverages`, {
                params: {
                  sub_orgs_ids: subOrganizationIds,
                  lob,
                  involved_person_required: includeOnlyInvolvedPersonsCoverages,
                },
              })
            )
          );

          const results = responses.map((response) => response.data);
          const options = results.reduce(
            (options, result) => ({ ...options, ...result }),
            disableGeneral ? {} : { general: 'General' }
          );
          setCoveragesOptionsDict(options);
          setIsInitialized(true);
        } catch (error) {
          await reportAxiosError(error);
          setIsError(true);
        }
        setIsLoading(false);
      }
    })();
  }, [
    lobs,
    organizationId,
    prevLobsRef,
    prevSubOrgIdsRef,
    subOrganizationIds,
    disableGeneral,
    includeOnlyInvolvedPersonsCoverages,
  ]);

  return { coveragesOptionsDict, isLoading, isError, isInitialized };
};

export default useCoverage;
