import React from 'react';

import type { ContactFullModel } from '~/components/Contacts/types';
import Text from '~/components/core/TextComponents/Text';

interface NotePropsType {
  contact: ContactFullModel;
}

const Note: React.FC<NotePropsType> = ({ contact }) => {
  return (
    <>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
        Note
      </Text>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
        {contact.note || '-'}
      </Text>
    </>
  );
};

export default Note;
