import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Heading from '../TextComponents/Heading';
import Text from '../TextComponents/Text';

import styles from './cardLink.module.scss';

const CardLink = ({ title, titleProps, text, to, children }) => {
  return (
    <Link to={to} className={styles.cardLink}>
      <Heading variant={Heading.TYPES.H2} {...titleProps}>
        {title}
      </Heading>
      <Text variant={Text.VARIANTS.SM} className={styles.cardText}>
        {text}
      </Text>
      {children}
    </Link>
  );
};

CardLink.propTypes = {
  titleProps: Heading.propTypes,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
};

export default CardLink;
