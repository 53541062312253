import React from 'react';

import SvgIcon from '../core/SvgIcon';

const WarningCircleIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path d="M2.25 7.5C2.25 4.87665 4.37665 2.75 7 2.75C9.62335 2.75 11.75 4.87665 11.75 7.5C11.75 10.1234 9.62335 12.25 7 12.25C4.37665 12.25 2.25 10.1234 2.25 7.5ZM7 1.75C3.82436 1.75 1.25 4.32436 1.25 7.5C1.25 10.6756 3.82436 13.25 7 13.25C10.1756 13.25 12.75 10.6756 12.75 7.5C12.75 4.32436 10.1756 1.75 7 1.75ZM7 4.375C7.27614 4.375 7.5 4.59886 7.5 4.875V7.9375C7.5 8.21364 7.27614 8.4375 7 8.4375C6.72386 8.4375 6.5 8.21364 6.5 7.9375V4.875C6.5 4.59886 6.72386 4.375 7 4.375ZM7 10.5625C7.36244 10.5625 7.65625 10.2687 7.65625 9.90625C7.65625 9.54381 7.36244 9.25 7 9.25C6.63756 9.25 6.34375 9.54381 6.34375 9.90625C6.34375 10.2687 6.63756 10.5625 7 10.5625Z" />
  </SvgIcon>
);

export default WarningCircleIcon;
