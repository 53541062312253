const COMMUNICATION_CHANNEL_DICT = {
  sms: 'SMS',
  email: 'Email',
  phone_call: 'Phone Call',
  physical_mail: 'Physical Mail',
  video_call: 'Video Call',
  vendor: 'Vendor',
} as const;

export default COMMUNICATION_CHANNEL_DICT;
