import React from 'react';

import { Text } from '~/components/core';
import { useTableau } from '~/components/ReportsPage/Context/TableauContext';
import { TableauEmbedSession } from '~/components/ReportsPage/EmbedComponents/TableauEmbedSession';
import {
  getNonPersonalSpaceProjects,
  getPersonalSpaceProject,
  hasWorkbooks,
} from '~/components/ReportsPage/helpers/ReportsUtils';
import { useReportsFilter } from '~/components/ReportsPage/Hooks/useReportsFilter';
import { useTableauEmbedAuth } from '~/components/ReportsPage/Hooks/useTableauEmbedAuth';
import { ReportsActions } from '~/components/ReportsPage/ReportsActions/ReportsActions';
import { useReportsActions } from '~/components/ReportsPage/ReportsActions/useReportsActions';
import { ReportsEmptyState } from '~/components/ReportsPage/ReportsEmptyState';
import { ReportsFilter } from '~/components/ReportsPage/ReportsFilter/ReportsFilter';
import { ReportsGallery } from '~/components/ReportsPage/ReportsGallery/ReportsGallery';
import type { User } from '~/components/ReportsPage/types';

import LoadingSwitch from '../../core/Loading/LoadingSwitch';

interface ReportsTabsLayoutProps {
  title: string;
  subTitle: string;
  reloadData?: boolean;
  isDrafts?: boolean;
}

export const ReportsTabLayout: React.FC<ReportsTabsLayoutProps> = ({ title, subTitle, isDrafts = false }) => {
  const { token } = useTableauEmbedAuth();
  const { projects, users = [], isLoading, isError } = useTableau();
  const [searchText, setSearchText] = React.useState('');
  const [selectedOwners, setSelectedOwners] = React.useState<User[]>([]);
  const [isSessionReady, setIsSessionReady] = React.useState(false);
  const nonPersonalSpaceProjects = getNonPersonalSpaceProjects({ projects });
  const personalSpaceProject = getPersonalSpaceProject({ projects });
  const { action, onClose, onActionHandler, allowedProjectsToShare, showSuccessMessage, onCloseSuccessMessage } =
    useReportsActions();

  const tabProjects = React.useMemo(
    () => (isDrafts ? personalSpaceProject : nonPersonalSpaceProjects),
    [isDrafts, personalSpaceProject, nonPersonalSpaceProjects]
  );

  const { filteredProjects } = useReportsFilter({
    users,
    projects: tabProjects,
    searchText,
    selectedOwners,
  });

  const showEmptyState = React.useMemo(() => !hasWorkbooks({ projects: tabProjects }), [tabProjects]);
  const onSessionReadyHandler = () => setIsSessionReady(true);

  return (
    <>
      <TableauEmbedSession token={token} onReady={onSessionReadyHandler} />
      <section className="--MAIN-- flex h-[calc(100vh-110px)] w-full flex-col gap-32">
        <LoadingSwitch isLoading={isLoading} isError={isError}>
          {showEmptyState && (
            <div className="flex h-full flex-col items-center justify-center">
              <ReportsEmptyState onCreate={() => onActionHandler({ action: 'create', params: {} })} />
            </div>
          )}

          {!showEmptyState && (
            <div className="--MAIN-SCROLLABLE-COLUMN-- flex flex-col gap-20 pb-40">
              <div className="--TITLE-CONTAINER-- mx-40 mt-20 flex flex-col gap-12" hidden={!isSessionReady}>
                <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD}>
                  {title}
                </Text>

                <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                  {subTitle}
                </Text>
              </div>

              <div className="--FILTER-CONTAINER-- mx-40 mt-20">
                <ReportsFilter
                  isDrafts={isDrafts}
                  searchText={searchText}
                  onSearchTextChange={(e) => setSearchText(e.target.value)}
                  selectedOwners={selectedOwners}
                  onSelectedOwnersChange={(selected) => setSelectedOwners(selected)}
                  owners={users}
                />
              </div>

              <ReportsGallery projects={filteredProjects} isSessionReady={isSessionReady} onAction={onActionHandler} />

              <ReportsActions
                action={action}
                onClose={onClose}
                onConfirm={onActionHandler}
                projectsToShare={allowedProjectsToShare}
                isDrafts={isDrafts}
                showSuccessMessage={showSuccessMessage}
                onCloseSuccessMessage={onCloseSuccessMessage}
              />
            </div>
          )}
        </LoadingSwitch>
      </section>
    </>
  );
};
