import React from 'react';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import MenuItem from '~/components/core/Atomic/MenuItem';
import TextFieldFormik from '~/components/TextFieldFormik';

const FIELDS_BY_METHOD_KEY = {
  ccc: {
    primary_impact_area: 'Primary Impact Area',
  },
};

const FIELDS_OPTIONS_BY_FIELD_ID_AND_METHOD_KEY = {
  ccc: {
    primary_impact_area: {
      1: 'Right Front',
      2: 'Right Front Pillar',
      3: 'Right T-Bone',
      4: 'Right Quarter Post',
      5: 'Right Rear',
      6: 'Rear',
      7: 'Left Rear',
      8: 'Left Quarter Post',
      9: 'Left T- Bone',
      10: 'Left Front Pillar',
      11: 'Left Front',
      12: 'Front',
      13: 'Rollover',
      14: 'Unknown',
      15: 'Total Loss',
      16: 'Non-Collision',
      17: 'Left & Right Side',
      18: 'Front & Rear',
      19: 'All Over',
      20: 'Strip',
      21: 'Undercarriage',
      22: 'Total Burn',
      23: 'Interior Burn',
      24: 'Engine Burn',
      25: 'Fresh Water',
      26: 'Salt Water',
      27: 'Hail',
      28: 'Glass',
      29: 'Vandalism',
    },
  },
};

const FIELDS_VALIDATIONS_BY_METHOD_KEY = {
  ccc: {
    primary_impact_area: Yup.number().required('Required'),
  },
};

const METHOD_SPECIFIC_FIELDS_BASE_ID = 'method_specific';

const useMethodSpecific = (moi_method, disabled = false) => {
  const moi_method_key = moi_method?.method_type_key;
  const fields = Object.entries(FIELDS_BY_METHOD_KEY[moi_method_key] || []).map(([fieldId, fieldLabel]) => {
    const options = FIELDS_OPTIONS_BY_FIELD_ID_AND_METHOD_KEY[moi_method_key]?.[fieldId] || {};
    return (
      <div key={fieldId}>
        <TextFieldFormik
          id={`${METHOD_SPECIFIC_FIELDS_BASE_ID}.${fieldId}`}
          label={fieldLabel}
          select={options}
          fullWidth
          disabled={disabled}
        >
          {Object.keys(options).map((key) => (
            <MenuItem key={key} value={key}>
              {options[key]}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </div>
    );
  });

  const fieldsValidations = FIELDS_VALIDATIONS_BY_METHOD_KEY?.[moi_method_key] || {};
  const validationsMergedWithExistingSchema = (existingSchema, setSchema) => {
    if (!existingSchema.fields[METHOD_SPECIFIC_FIELDS_BASE_ID] && !isEmpty(fieldsValidations)) {
      const newSchema = existingSchema.concat(
        Yup.object().shape({
          [METHOD_SPECIFIC_FIELDS_BASE_ID]: Yup.object().shape(
            FIELDS_VALIDATIONS_BY_METHOD_KEY?.[moi_method_key] || {}
          ),
        })
      );
      setSchema(newSchema);
    }
  };
  return {
    fields,
    fieldsValidations,
    validationsMergedWithExistingSchema,
  };
};

export default useMethodSpecific;
