import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const CalendarIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      d="M7.875 12C7.875 11.5858 8.21079 11.25 8.625 11.25H11.25C11.5383 11.25 11.8011 11.4153 11.926 11.6751C12.0509 11.935 12.0158 12.2434 11.8357 12.4685L11.0055 13.5062C11.2487 13.6692 11.4584 13.8786 11.6221 14.1231C11.8282 14.4308 11.9551 14.7846 11.9916 15.1531C12.0281 15.5216 11.9731 15.8934 11.8315 16.2355C11.6899 16.5777 11.466 16.8796 11.1798 17.1144C10.8935 17.3493 10.5537 17.5099 10.1905 17.582C9.82725 17.654 9.45187 17.6353 9.09761 17.5276C8.74335 17.4198 8.42117 17.2262 8.15965 16.9641C7.86712 16.6708 7.86769 16.1959 8.16094 15.9034C8.45418 15.6109 8.92906 15.6114 9.2216 15.9047C9.30877 15.9921 9.41616 16.0566 9.53425 16.0925C9.65233 16.1284 9.77746 16.1347 9.89853 16.1107C10.0196 16.0866 10.1329 16.0331 10.2283 15.9548C10.3237 15.8765 10.3983 15.7759 10.4455 15.6618C10.4928 15.5478 10.5111 15.4239 10.4989 15.301C10.4867 15.1782 10.4444 15.0603 10.3758 14.9577C10.3071 14.8551 10.2142 14.7711 10.1052 14.7131C9.99629 14.655 9.87472 14.6248 9.75128 14.625C9.46275 14.6255 9.19951 14.4604 9.0743 14.2005C8.94909 13.9405 8.9841 13.6318 9.16435 13.4065L9.68953 12.75H8.625C8.21079 12.75 7.875 12.4142 7.875 12Z"
      fill={iconColor}
    />
    <path
      d="M15.75 12C15.75 11.7159 15.5895 11.4562 15.3354 11.3292C15.0813 11.2021 14.7773 11.2296 14.55 11.4L13.05 12.525C12.7186 12.7735 12.6515 13.2436 12.9 13.575C13.1485 13.9064 13.6186 13.9735 13.95 13.725L14.25 13.5V16.875C14.25 17.2892 14.5858 17.625 15 17.625C15.4142 17.625 15.75 17.2892 15.75 16.875V12Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 2.25C17.25 1.83579 16.9142 1.5 16.5 1.5C16.0858 1.5 15.75 1.83579 15.75 2.25V3H8.25V2.25C8.25 1.83579 7.91421 1.5 7.5 1.5C7.08579 1.5 6.75 1.83579 6.75 2.25V3H4.5C3.67157 3 3 3.67157 3 4.5V19.5C3 20.3284 3.67157 21 4.5 21H19.5C20.3284 21 21 20.3284 21 19.5V4.5C21 3.67157 20.3284 3 19.5 3H17.25V2.25ZM19.5 4.5H17.25V5.25C17.25 5.66421 16.9142 6 16.5 6C16.0858 6 15.75 5.66421 15.75 5.25V4.5H8.25V5.25C8.25 5.66421 7.91421 6 7.5 6C7.08579 6 6.75 5.66421 6.75 5.25V4.5H4.5V7.5H19.5V4.5ZM4.5 9H19.5V19.5H4.5V9Z"
      fill={iconColor}
    />
  </SvgIcon>
);

CalendarIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default CalendarIcon;
