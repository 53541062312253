import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const MedicalBillsIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 14.25C6.75 13.8358 7.08579 13.5 7.5 13.5H16.5C16.9142 13.5 17.25 13.8358 17.25 14.25C17.25 14.6642 16.9142 15 16.5 15H7.5C7.08579 15 6.75 14.6642 6.75 14.25Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 17.25C6.75 16.8358 7.08579 16.5 7.5 16.5H16.5C16.9142 16.5 17.25 16.8358 17.25 17.25C17.25 17.6642 16.9142 18 16.5 18H7.5C7.08579 18 6.75 17.6642 6.75 17.25Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.68934 2.68934C2.97064 2.40804 3.35217 2.25 3.75 2.25H20.25C20.6478 2.25 21.0294 2.40804 21.3107 2.68934C21.592 2.97065 21.75 3.35218 21.75 3.75V21.75C21.75 22.0099 21.6154 22.2513 21.3943 22.388C21.1732 22.5246 20.8971 22.5371 20.6646 22.4208L18 21.0885L15.3354 22.4208C15.1243 22.5264 14.8757 22.5264 14.6646 22.4208L12 21.0885L9.33541 22.4208C9.12426 22.5264 8.87574 22.5264 8.66459 22.4208L6 21.0885L3.33541 22.4208C3.10292 22.5371 2.82681 22.5246 2.6057 22.388C2.38459 22.2513 2.25 22.0099 2.25 21.75V3.75C2.25 3.35217 2.40804 2.97064 2.68934 2.68934ZM20.25 3.75L3.75 3.75L3.75 20.5365L5.66459 19.5792C5.87574 19.4736 6.12426 19.4736 6.33541 19.5792L9 20.9115L11.6646 19.5792C11.8757 19.4736 12.1243 19.4736 12.3354 19.5792L15 20.9115L17.6646 19.5792C17.8757 19.4736 18.1243 19.4736 18.3354 19.5792L20.25 20.5365V3.75Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0982 5.18719C12.5124 5.18719 12.8482 5.52298 12.8482 5.93719V10.4372C12.8482 10.8514 12.5124 11.1872 12.0982 11.1872C11.684 11.1872 11.3482 10.8514 11.3482 10.4372V5.93719C11.3482 5.52298 11.684 5.18719 12.0982 5.18719Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09824 8.18719C9.09824 7.77298 9.43402 7.43719 9.84824 7.43719H14.3482C14.7624 7.43719 15.0982 7.77298 15.0982 8.18719C15.0982 8.60141 14.7624 8.93719 14.3482 8.93719H9.84824C9.43402 8.93719 9.09824 8.60141 9.09824 8.18719Z"
      fill={iconColor}
    />
  </SvgIcon>
);

MedicalBillsIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default MedicalBillsIcon;
