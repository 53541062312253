import type { Option } from './hooks/useLegalActionsConfiguration';
import type { LegalActionApiResponse, PartyType } from './types';

export const getLabelByKey = (optionsArray: Option[], key: string): string => {
  const option = optionsArray?.find((option) => option.key === key);
  return option ? option.description : '';
};

export const isOptionOther = (optionsArray: Option[], key: string): boolean => {
  const option = optionsArray.find((option) => option.key === key);
  return option ? option.is_other ?? false : false;
};

export const getPartyTypeLabel = (partyType: PartyType): string =>
  partyType === 'PLAINTIFF' ? 'Plaintiff' : 'Defendant';

export const getPartyFieldLabel = (partyType: PartyType, fieldName: string): string =>
  `${getPartyTypeLabel(partyType)} ${fieldName}`;

export const getLegalActionStateProps = (
  legalAction: LegalActionApiResponse,
  ignoreLegalActionState: boolean | undefined
): {
  isDisabled: boolean;
  tooltipText: string | undefined;
} => {
  const isDisabled = !legalAction.is_open && !ignoreLegalActionState;
  const tooltipText = isDisabled ? 'This legal action is closed' : undefined;
  return { isDisabled, tooltipText };
};

const LEGAL_ACTION_PRIORITY_ORDER = ['low', 'medium', 'high'];

export const getLegalActionPriorityValue = (priority: string): number => LEGAL_ACTION_PRIORITY_ORDER.indexOf(priority);

const getHighestPriorityLegalAction = (legalActions: LegalActionApiResponse[]): LegalActionApiResponse | null => {
  const openActions = legalActions.filter((la) => la.is_open);

  if (openActions.length === 0) {
    return null;
  }

  return legalActions.reduce((highestPriorityAction, action) => {
    const actionPriorityValue = getLegalActionPriorityValue(action.priority);
    if (actionPriorityValue > getLegalActionPriorityValue(highestPriorityAction.priority)) {
      return action;
    }
    return highestPriorityAction;
  }, legalActions[0]);
};

export const getExposureHighestPriorityLegalAction = (
  legalActions: LegalActionApiResponse[],
  exposure: { id: number }
): LegalActionApiResponse | null => {
  const exposureActions = legalActions.filter((la) =>
    la.exposures.map((legal_action_exposure) => legal_action_exposure.id).includes(exposure.id)
  );

  if (!legalActions || legalActions?.length === 0) {
    return null;
  }

  return getHighestPriorityLegalAction(exposureActions);
};

export const getClaimLevelHighestPriorityLegalAction = (
  legalActions: LegalActionApiResponse[]
): LegalActionApiResponse | null => {
  if (!legalActions || legalActions?.length === 0) {
    return null;
  }

  const claimLevelActions = legalActions.filter((la) => la.is_claim_level);

  return getHighestPriorityLegalAction(claimLevelActions);
};
