import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function SocialSecurityNumberFormat(props) {
  const { inputRef, onChange, id, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            id,
            name,
            value: values.value,
          },
        });
      }}
      format="###-##-####"
      mask="_"
    />
  );
}

SocialSecurityNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default SocialSecurityNumberFormat;
