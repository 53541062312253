import React from 'react';

import SvgIcon from '../core/SvgIcon';

const FormIcon = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M12.5 5.625H7.5" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 8.125H7.5" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7.1875 17.5H5C4.65482 17.5 4.375 17.2202 4.375 16.875V3.125C4.375 2.77982 4.65482 2.5 5 2.5L15 2.5C15.3452 2.5 15.625 2.77982 15.625 3.125V16.875C15.625 17.2202 15.3452 17.5 15 17.5H12.8125"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.5 12.5L9.16477 15L7.5 13.75" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default FormIcon;
