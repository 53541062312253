import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { LEGAL_ACTION_PARTY_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { LegalActionPartyFormValues } from '../../FormFragments/FormValuesTypes';
import { PartyForm } from '../../FormFragments/PartyForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { LegalActionPartyApiResponse } from '../../types';
import { PartyType } from '../../types';
import { FormDialog } from '../FormDialog';

import type { AddPartyDialogProps } from './PartyDialogTypes';

interface EditPartyDialogProps extends AddPartyDialogProps {
  party: LegalActionPartyApiResponse;
}

export const EditPartyDialog: React.FC<EditPartyDialogProps> = ({
  partyType,
  onClose,
  onSubmitSuccess,
  legalAction,
  party,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: LegalActionPartyFormValues) => {
    try {
      await axios.put(backendRoutes.legalAction.party.base(party.id), {
        type: partyType,
        ...values,
      });
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  const title = `Edit ${partyType === PartyType.PLAINTIFF ? 'Plaintiff' : 'Defendant'}`;

  return (
    <FormDialog<LegalActionPartyFormValues>
      title={title}
      onClose={onClose}
      maxWidth="md"
      formikConfig={{
        onSubmit: handleSubmit,
        validationSchema: Yup.object().shape(LEGAL_ACTION_PARTY_VALIDATION_SCHEMA(legalAction)),
        initialValues: pick(party, [
          'id',
          'contact_id',
          'counsel_contact_id',
          'is_carrier_party',
          'date_of_representation',
          'file_number',
        ] as (keyof LegalActionPartyFormValues)[]),
      }}
    >
      <div className="flex w-full flex-col gap-20">
        <PartyForm
          partyType={partyType}
          hideCarrierInvolvedParty={legalAction.legal_action_parties
            .filter((p) => p.id !== party.id)
            .some((p) => p.is_carrier_party)}
        />
      </div>
    </FormDialog>
  );
};
