import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const PhonePlusIcon = ({ iconColor, ...props }) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={iconColor}
    disableStrokeColor
    {...props}
  >
    <path
      d="M21.52 15.4801c-.16-.28-.42-.5-.71-.63l-4.38-1.88c-.23-.1-.49-.14-.73-.12-.25.02-.5.11-.7.25l-2.34 1.57c-1.44-.69-2.61-1.86-3.31-3.29l1.57-2.38001c.13-.21.21-.44999.23-.68999.02-.24-.02-.49001-.12-.71001l-1.88-4.39c-.12-.3-.35-.56-.63-.72-.28-.16-.61-.23-.94-.19-1.27.16-2.43.78-3.27 1.74C3.47 5.00009 3 6.2301 3 7.5101c0 3.61 1.4 7 3.95 9.55 2.55 2.55 5.94 3.95 9.55 3.95 1.28 0 2.51-.47 3.47-1.31.96-.84 1.58-2.01 1.74-3.27.04-.32-.03-.65-.19-.93v-.02Zm-2.54 3.08c-.68.6-1.56.93-2.48.94-3.21 0-6.22-1.25-8.49-3.51-2.27-2.27-3.51-5.28-3.51-8.49001 0-.90999.33-1.79.94-2.48.6-.69 1.43-1.13 2.33-1.24l1.89 4.39-1.57 2.39001c-.14.22-.22.47-.24.72-.02.25.03.51.14.74.84 1.74 2.27 3.16 4.01 4 .24.11.49.16.76.14.26-.02.51-.11.72-.25l2.34-1.57 4.39 1.88c-.12.91-.56 1.74-1.24 2.34h.01Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.318 6.74558c.0005-.41422.3366-.74962.7509-.74914l6.3639.00731c.4142.00048.7496.33665.7491.75086-.0004.41421-.3366.74961-.7508.74914l-6.364-.00731c-.4142-.00048-.7496-.33665-.7491-.75086Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2545 2.81812c.4142.00047.7496.33664.7491.75086l-.0073 6.36395c-.0005.41417-.3366.74957-.7508.74917-.4143-.0005-.7497-.3367-.7492-.75089l.0073-6.36396c.0005-.41421.3367-.74961.7509-.74913Z"
      fill={iconColor}
    />
  </SvgIcon>
);

PhonePlusIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default PhonePlusIcon;
