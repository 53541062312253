import React from 'react';
import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { WitnessesSummary } from './WitnessSummary';

import { useStyles } from '../../../../assets/styles';

const FnolWitnesses = () => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();

  return (
    <WitnessesSummary
      classes={classes}
      witnesses={values['witnesses']}
      onAddWitness={(witness) => setFieldValue('witnesses', [...values['witnesses'], { ...witness, id: uuidv4() }])}
      onEditWitness={(witness) =>
        setFieldValue(
          'witnesses',
          values['witnesses'].map((w) => (w.id === witness.id ? witness : w))
        )
      }
      onDeleteWitness={(id) =>
        setFieldValue(
          'witnesses',
          values['witnesses'].filter((w) => w.id !== id)
        )
      }
      withRoundCard
    />
  );
};

export default FnolWitnesses;
