import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import {
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../../IncidentConfiguration/CustomFieldsContext';

const LOSS_OBJECT_ID = 'pecuniary_loss.losses';

const getInitialPecuniaryItemsPredefinedFields = (preDefinedFields) =>
  preparePreDefinedFields(get(preDefinedFields, LOSS_OBJECT_ID, {}));
const getPecuniaryItemsInitialValues = (preDefinedFields) =>
  getPredefinedFieldsEmptyFormikInitialValues(getInitialPecuniaryItemsPredefinedFields(preDefinedFields));

const getPecuniaryLossPredefinedFields = (preDefinedFields) =>
  preparePreDefinedFields(get(preDefinedFields, LOSS_OBJECT_ID, {}));

const getPecuniaryValidationFields = (preDefinedFields) => ({
  ...getAdditionalDataValidations(getPecuniaryLossPredefinedFields(preDefinedFields)),
});

const DIALOG_FIELDS = ['display_name', 'claimed_amount', 'claimed_loss', 'note'];

const PecuniaryLossItemsFragment = ({ preDefinedFields }) => {
  return (
    <Grid container spacing={1}>
      <PreDefinedField id="display_name" fields={preDefinedFields} inline />
      <PreDefinedField id="claimed_amount" fields={preDefinedFields} inline />
      <PreDefinedField id="claimed_loss" fields={preDefinedFields} inline decimalScale={0} gridXs={12} />
      <PreDefinedField id="note" fields={preDefinedFields} inline gridXs={12} />
    </Grid>
  );
};

PecuniaryLossItemsFragment.propTypes = {
  preDefinedFields: PropTypes.object.isRequired,
};

export {
  DIALOG_FIELDS,
  getPecuniaryItemsInitialValues,
  getPecuniaryLossPredefinedFields,
  getPecuniaryValidationFields,
  PecuniaryLossItemsFragment,
};
