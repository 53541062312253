import React, { useState } from 'react';

import { useStyles } from '~/assets/styles';
import NewVideoCommunicationCard from '~/components/communications/VideoCommunication/NewVideoCommunicationCard';
import { VideoIcon } from '~/components/icons/notifications';
import InlineIconButton from '~/components/InlineIconButton';
import cn from '~/Utils/cn';

import type { ContactFullModel } from '../../types';

interface Props {
  contact: ContactFullModel;
  onUpdate: () => void;
}

const VideoCommunicationPopover: React.FC<Props> = ({ contact, onUpdate }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const classes = useStyles();

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <InlineIconButton
        onClick={handleOpenDialog}
        icon={VideoIcon}
        className={cn({ [classes.hoverableNonFilledIcon]: true })}
        ignorePermissions={false}
        // undefined are to satisfy Typescript with prop-types
        defaultColor={undefined}
        disabled={undefined}
        iconStyle={undefined}
        tooltipTitle={undefined}
        useIconButton={undefined}
        wrapWithSpan={undefined}
        ariaLabel={undefined}
      />
      {isDialogOpen && (
        <NewVideoCommunicationCard
          contact={contact}
          onNewVideoCallCreated={() => {
            onUpdate();
            handleCloseDialog();
          }}
          onCancel={handleCloseDialog}
        />
      )}
    </>
  );
};

export default VideoCommunicationPopover;
