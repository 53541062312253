import React from 'react';
import PropTypes from 'prop-types';

import { useIncidentConfiguration } from '../../../../../hooks/useIncidentConfiguration';
import {
  getInitialSectionConfig,
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../../IncidentConfiguration/CustomFieldsContext';

const POLICE_AND_FIRE_DEPARTMENT_PATH = 'incident_details.auto_incident_additional.police_and_fire_department'; // Save those values together in the FE
const POLICE_AND_FIRE_DEPARTMENT_CONFIG_PATH =
  'incident_details.pre_defined_fields.auto_incident_additional.police_and_fire_department';

const getPoliceFireDepartmentInvolvementSectionConfig = (incidentConfiguration, isFieldSupportedBySubtype) =>
  getInitialSectionConfig(incidentConfiguration, POLICE_AND_FIRE_DEPARTMENT_CONFIG_PATH, {}, isFieldSupportedBySubtype);
const getPoliceFireDepartmentInvolvementPredefinedFields = (incidentConfiguration, additionalFieldOmitFn) =>
  preparePreDefinedFields(
    getPoliceFireDepartmentInvolvementSectionConfig(incidentConfiguration),
    additionalFieldOmitFn
  );
const getPoliceFireDepartmentInvolvementInitialValues = (incidentConfiguration, additionalFieldOmitFn) =>
  getPredefinedFieldsEmptyFormikInitialValues(
    getPoliceFireDepartmentInvolvementPredefinedFields(incidentConfiguration, additionalFieldOmitFn)
  );
const getPoliceFireDepartmentInvolvementValidationSchema = (incidentConfiguration, additionalFieldOmitFn) =>
  getAdditionalDataValidations(
    getPoliceFireDepartmentInvolvementPredefinedFields(incidentConfiguration, additionalFieldOmitFn)
  );

const PoliceFireDepartmentInvolvementFragment = ({ additionalFieldOmitFn }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getPoliceFireDepartmentInvolvementPredefinedFields(
    incidentConfiguration,
    additionalFieldOmitFn
  );

  return (
    <>
      <PreDefinedField id="were_police_involved" fields={preDefinedFields} inline />
      <PreDefinedField id="pending_prosecutions_or_investigations_exists" fields={preDefinedFields} inline />
      <PreDefinedField id="police_crime_reference_number" fields={preDefinedFields} inline />
      <PreDefinedField id="police_department_contact_id" fields={preDefinedFields} inline />
      <PreDefinedField id="police_report" fields={preDefinedFields} inline />
      <PreDefinedField id="did_speak_with_officer" fields={preDefinedFields} inline />
      <PreDefinedField id="was_ticket_received" fields={preDefinedFields} inline />
      <PreDefinedField id="violation_title" fields={preDefinedFields} inline />
      <PreDefinedField id="police_description" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="were_fire_department_involved" fields={preDefinedFields} inline />
      <PreDefinedField id="fire_department_description" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="were_any_hazardous_good_being_carried" fields={preDefinedFields} inline />
    </>
  );
};

PoliceFireDepartmentInvolvementFragment.propTypes = {
  additionalFieldOmitFn: PropTypes.func,
};

export {
  getPoliceFireDepartmentInvolvementInitialValues,
  getPoliceFireDepartmentInvolvementSectionConfig,
  getPoliceFireDepartmentInvolvementValidationSchema,
  POLICE_AND_FIRE_DEPARTMENT_PATH,
  PoliceFireDepartmentInvolvementFragment,
};
