import React from 'react';
import PropTypes from 'prop-types';

import Typography from '~/components/core/Atomic/Typography';

import CardDialog from '../../../../CardDialog';
import HoverActionField from '../../../../HoverActionField';

import { useStyles } from '../../../../../assets/styles';

function FaultAssessment(props) {
  const classes = useStyles();

  const { onEditFaultAssessment } = props;

  return (
    <CardDialog outlinedCard noCardTitle>
      <HoverActionField onAction={onEditFaultAssessment} classes={classes} disabled={false} permanent>
        <Typography display="block" variant="subtitle2">
          Fault Assessment
        </Typography>
      </HoverActionField>
    </CardDialog>
  );
}

FaultAssessment.propTypes = {
  onEditFaultAssessment: PropTypes.func.isRequired,
};

export default FaultAssessment;
