import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import BoldIcon from '../../../../icons/editor/BoldIcon';
import ToolbarButton from '../ToolbarButton';

const Bold: React.FC = () => {
  const { editor } = useCurrentEditor();

  const toggle = () => {
    editor?.chain().focus().toggleBold().run();
  };

  return (
    <ToolbarButton onClick={toggle} disabled={!editor?.can().toggleBold()} isActive={editor?.isActive('bold')}>
      <BoldIcon />
    </ToolbarButton>
  );
};

export default Bold;
