import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useNavigationBlocker = (
  shouldPreventNavigation: (pathname: string) => boolean,
  getConfirmationMessage: (pathname: string) => string
): void => {
  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unblock = history.block((nextLocation): any => {
      if (shouldPreventNavigation(nextLocation.pathname)) {
        return window.confirm(getConfirmationMessage(nextLocation.pathname));
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history, shouldPreventNavigation, getConfirmationMessage]);
};

export default useNavigationBlocker;
