import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isEmpty, noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { reportAxiosError } from '~/Utils';

import CardDialog from '../../CardDialog';
import { useClaim } from '../../ClaimContainer';
import SendEmailCommunicationCardContainer from '../../communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import { contactPopulate } from '../../ContactUtils';
import { ChevronLeftIcon } from '../../icons';

import MoiStepper from './MoiStepper';
import MoiTableCard from './MoiTableCard';
import { trackMoiClicked, trackMoiDetailsClicked } from './MoiUtils';
import VendorStatusDataDialogCard from './VendorStatusDataDialogCard';

const BackToTableButton = ({ onClick = noop }) => {
  const classes = useStyles();

  return (
    <>
      <Button style={{ paddingLeft: '0' }} onClick={onClick}>
        <ChevronLeftIcon className={classes.leftButtonIcon} />
        <span className={classes.moiBackToTableTitle}>Back to Selected MOIs</span>
      </Button>
    </>
  );
};

BackToTableButton.propTypes = {
  onClick: PropTypes.func,
};

const INITIAL_EMAIL_STATE = {
  sendEmailOpen: false,
  emailTextAndTitle: null,
  emailContact: null,
};

const NewMoiCard = ({ moiRecords, exposure, onClose, readOnly = false }) => {
  const { id: exposureId, label_text: exposureLabel } = exposure;
  const classes = useStyles();
  const { claim } = useClaim();
  const [isMoiDialogOpen, setIsMoiDialogOpen] = useState(isEmpty(moiRecords));
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewingStatusData, setIsViewingStatusData] = useState(false);
  const [currentMoi, setCurrentMoi] = useState(undefined);
  const [shouldReloadCommunications, setShouldReloadCommunications] = useState(false);
  const [emailState, setEmailState] = useState(INITIAL_EMAIL_STATE);
  const { sendEmailOpen, emailTextAndTitle, emailContact } = emailState;

  const handleSubmit = async (moiRecord) => {
    try {
      handleBackClick();
      setCurrentMoi(moiRecord);

      // Get Email autofill data from server
      if (moiRecord?.contact?.emails?.length > 0) {
        // checking if the contact is vendor api
        const { data } = await axios.get(`/api/v1/contacts/${moiRecord.contact.id}/is_vendor_api`);
        const ans = await axios.get(
          `/api/v1/xactanalysis/${claim.organization_id}/contractors/by_contact_id/${moiRecord.contact.id}`
        );

        if (!data?.is_vendor_api && !ans.data?.is_xactanalysis_contractor) {
          setIsMoiDialogOpen(false);
          const { data } = await axios.get(
            `/api/v1/claims/${claim.id}/method_of_inspection/${moiRecord.id}/assignment_email_title_and_body`
          );
          setEmailState({
            emailTextAndTitle: data,
            emailContact: contactPopulate(moiRecord?.contact),
            sendEmailOpen: true,
          });
        }
      }
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleSendEmail = async (emailRecord) => {
    try {
      setEmailState(INITIAL_EMAIL_STATE);
      await axios.post(
        `/api/v1/claims/${claim.id}/method_of_inspection/${currentMoi.id}/attach_first_communication/${emailRecord.id}`
      );
      setShouldReloadCommunications(true);
      setCurrentMoi(undefined);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleBackClick = () => {
    setIsEditMode(false);
    setIsViewingStatusData(false);
    setIsMoiDialogOpen(false);
    setCurrentMoi(undefined);
  };

  const handleAddClick = () => {
    setIsMoiDialogOpen(true);
    trackMoiClicked({ claim, exposure, actionType: 'add' });
  };

  const handleEditClick = (moiRecord) => {
    setCurrentMoi(moiRecord);
    setIsEditMode(true);
    setIsViewingStatusData(false);
    setIsMoiDialogOpen(true);
    trackMoiClicked({ claim, exposure, actionType: 'edit' });
  };

  const handleViewStatusDataClick = (moiRecord) => {
    setCurrentMoi(moiRecord);
    setIsViewingStatusData(true);
    setIsMoiDialogOpen(false);
    trackMoiDetailsClicked({ claim, exposure, moiId: moiRecord.id });
  };

  return (
    <>
      {isMoiDialogOpen && !readOnly ? (
        <MoiStepper
          exposure={exposure}
          onSubmit={handleSubmit}
          onBackClick={!isEmpty(moiRecords) ? handleBackClick : onClose}
          isEditMode={isEditMode}
          moiRecord={isEditMode ? currentMoi : undefined}
          onClose={onClose}
        />
      ) : (
        <CardDialog
          isDialog
          title={(isMoiDialogOpen || isViewingStatusData) && !isEmpty(moiRecords)}
          subheader={
            <Typography display="block" className={classes.moiCardHeader}>
              {isViewingStatusData ? (
                <b>MOI Assignment Status Update</b>
              ) : (
                <>
                  <b>{isEditMode ? 'Edit ' : isMoiDialogOpen && 'New '}MOI</b>&nbsp; (Method of Inspection)
                </>
              )}
            </Typography>
          }
          headerStyle={{ padding: '0 0 0 20px' }}
          maxWidth="lg"
          fullWidth
          onClose={onClose}
          className={classes.dialogFullHeight}
          trackAlt="New Method of Inspection Card"
        >
          <>
            {isViewingStatusData ? (
              <VendorStatusDataDialogCard
                moiRecord={currentMoi}
                onBackClick={!isEmpty(moiRecords) ? handleBackClick : onClose}
                claimIdDisplayWithExposureSuffix={exposure.claim_id_display_with_exposure_suffix}
              />
            ) : (
              <>
                <MoiTableCard
                  moiRecords={moiRecords}
                  exposureLabel={exposureLabel}
                  onAddClick={handleAddClick}
                  readOnly={readOnly}
                  onEditClick={handleEditClick}
                  onViewStatusDataClick={handleViewStatusDataClick}
                  shouldReloadCommunications={shouldReloadCommunications}
                  setShouldReloadCommunications={setShouldReloadCommunications}
                  claimIdDisplayWithExposureSuffix={exposure.claim_id_display_with_exposure_suffix}
                />
                <div className={classes.buttonsContainer}>
                  <CancelButton onClick={onClose} autoFocus contained content="CLOSE" withMarginRight={false} />
                </div>
              </>
            )}
          </>
        </CardDialog>
      )}
      {sendEmailOpen && (
        <SendEmailCommunicationCardContainer
          contact={emailContact}
          onClose={() => setEmailState({ ...emailState, sendEmailOpen: false })}
          onSendEmail={handleSendEmail}
          emailTitle={emailTextAndTitle.email_title}
          emailText={emailTextAndTitle.email_body}
          summary=""
          exposureIds={currentMoi?.exposure_ids || [exposureId]}
        />
      )}
    </>
  );
};

NewMoiCard.propTypes = {
  moiRecords: PropTypes.arrayOf(PropTypes.object).isRequired,
  exposure: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default NewMoiCard;
