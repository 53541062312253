import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { DateOfServiceForm } from '../../FormFragments/DateOfServiceForm';
import { DATE_OF_SERVICE_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { DateOfServiceFormValues } from '../../FormFragments/FormValuesTypes';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { LegalActionDateOfServiceApiResponse } from '../../types';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

interface EditDateOfServiceDialogProps extends DialogPropsWithLegalAction {
  dateOfService: LegalActionDateOfServiceApiResponse;
}

export const EditDateOfServiceDialog: React.FC<EditDateOfServiceDialogProps> = ({
  onClose,
  onSubmitSuccess,
  legalAction,
  dateOfService,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: DateOfServiceFormValues) => {
    try {
      await axios.put(backendRoutes.legalAction.date_of_service(dateOfService.id), values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<DateOfServiceFormValues>
      title="Edit Date of Service"
      onClose={onClose}
      maxWidth="sm"
      formikConfig={{
        onSubmit: handleSubmit,
        validationSchema: Yup.object().shape(DATE_OF_SERVICE_VALIDATION_SCHEMA),
        initialValues: pick(dateOfService, [
          'date_of_service',
          'service_type',
          'contact_id',
          'other_text',
        ]) as DateOfServiceFormValues,
      }}
    >
      <div className="flex w-full flex-col gap-20">
        <DateOfServiceForm />
      </div>
    </FormDialog>
  );
};
