import _ from 'lodash';
import moment from 'moment/moment';

import useDataFetcher from '~/components/useDataFetcher';
import { serverDateTimeToLocalMoment } from '~/DateTimeUtils';

export const useReplyEmailText = (communication, user, isGeneralQueueEmail = false) => {
  const emailSignatureEndPoint = isGeneralQueueEmail ? 'general_email_response_signature' : 'email_signature';
  const {
    isLoading: isLoadingEmailSignature,
    isError: isErrorEmailSignature,
    data: userSignatureHtmlData,
  } = useDataFetcher(`/api/v1/organizations/${user.organization_id}/${emailSignatureEndPoint}`, {
    params: { referred_communication_id: communication.id },
  });

  const userSignatureHtml =
    !isLoadingEmailSignature && !isErrorEmailSignature && userSignatureHtmlData
      ? userSignatureHtmlData.user_email_signature_html
      : '';

  const emailHtmlBody = communication.body_html
    ? communication.body_html
    : '<div>' + _.escape(communication.body_text).replace(/(\r\n|\n)/g, '<br/>') + '</div>';

  let origEmailSender = undefined;
  if (communication.direction === 'Outgoing') {
    origEmailSender = `${communication.adjuster} <${communication.adjuster_email}>`;
  } else {
    //Incoming
    origEmailSender = communication.contact // can be undefined in case of reply to unknown email
      ? `${communication.contact.full_name} <${communication.contact_email.email}>`
      : `${communication.specific_identifier} <${communication.specific_identifier}>`;
  }

  const emailText =
    `<div><br><br>${userSignatureHtml}</div><br/>` +
    '<span>------------------------------------------------------------------------</span><br/>' +
    `On ${moment(serverDateTimeToLocalMoment(communication.datetime)).format(
      'dddd, MMMM D, YYYY [at] h:mm:ss A'
    )} ${_.escape(origEmailSender)} wrote:` +
    `<br/><br/>${emailHtmlBody}`;

  return { emailText, isLoadingEmailSignature, isErrorEmailSignature };
};
