import React, { useEffect, useState } from 'react';

import { useCms } from './hooks/useCms';
import useOrganization from './OrganizationContext';
import { PolicyContextProvider } from './PolicyContainer';
import PolicyScreen from './PolicyScreen';
import PolicySearchContainer from './PolicySearch';

import { useStyles } from '../assets/styles';

function PoliciesTab() {
  const classes = useStyles();
  const { setPageTitle } = useCms();

  const [chosenPolicy, setChosenPolicy] = useState();
  const { supportedClaimTypes, policySearchConfig } = useOrganization();

  useEffect(() => {
    setPageTitle('Policies', 'Policies — Five Sigma CMS');
  }, [setPageTitle]);

  return (
    <>
      <div className={classes.cardDivRow}>
        <PolicySearchContainer
          policySearchConfig={policySearchConfig}
          onSelectPolicy={(policy, _) => setChosenPolicy(policy)}
          policyTypes={supportedClaimTypes.map((claimType) => claimType.replace('_claim', '_policy'))}
          allowSearchByAddress
        />
      </div>
      <div className={classes.cardDivRow}>
        {chosenPolicy && (
          <PolicyContextProvider policy={chosenPolicy}>
            <PolicyScreen classes={classes} policy={chosenPolicy} enableOpeningFnol />
          </PolicyContextProvider>
        )}
      </div>
    </>
  );
}

export default PoliciesTab;
