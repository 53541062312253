import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import { getAllSearchableContactRoles } from '~/components/communications/ContactUtils';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import MenuItem from '~/components/core/Atomic/MenuItem';
import useOrganization from '~/components/OrganizationContext';
import TextFieldFormik, { ShowOnlyTextField } from '~/components/TextFieldFormik';

const EmailRecipient = (props) => {
  const { recipientEntry, recipientEntryId, setFieldValue, disabled, showOnly, isNotClaimRelated } = props;
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  function handleSelectContact(contact) {
    if (!contact) {
      setFieldValue(`${recipientEntryId}.email_id`, '');
      return;
    }

    if (contact.id === recipientEntry.contact_id) {
      // same contact selected, do nothing
      return;
    }

    if (contact.emails.length === 1) {
      setFieldValue(`${recipientEntryId}.email_id`, contact.emails[0].id);
    } else {
      setFieldValue(`${recipientEntryId}.email_id`, '');
    }
  }

  return (
    <>
      <div>
        <ContactTextFieldFormik
          id={`${recipientEntryId}.contact`}
          fullWidth
          disabled={disabled}
          fixedSearchResults
          onSelectContact={handleSelectContact}
          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict).concat('user')}
          showOnly={showOnly}
          outOfContactsContextSearch={isNotClaimRelated}
        />
      </div>
      <div>
        {recipientEntry.contact &&
          (showOnly ? (
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={recipientEntry.email}
              label="" // To Prevent empty prop warning
            />
          ) : (
            <TextFieldFormik
              id={`${recipientEntryId}.email_id`}
              disabled={disabled || recipientEntry.contact.emails.length <= 1}
              select
              fullWidth
              showOnly={showOnly}
            >
              {recipientEntry.contact.emails.map((email) => (
                <MenuItem key={email.id} value={email.id}>
                  {email.email}
                </MenuItem>
              ))}
            </TextFieldFormik>
          ))}
      </div>
    </>
  );
};

EmailRecipient.propTypes = {
  recipientEntry: PropTypes.object.isRequired,
  recipientEntryId: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
  isNotClaimRelated: PropTypes.bool,
};

export default EmailRecipient;
