import React, { useEffect, useReducer } from 'react';
import isEqual from 'react-fast-compare';
import axios from 'axios';

import { reportAxiosError } from '../Utils';

async function fetchData(url, config) {
  if (!url) {
    return { type: 'FETCH_INIT' };
  }

  try {
    const result = await axios.get(url, config);
    return { type: 'FETCH_SUCCESS', payload: result.data };
  } catch (error) {
    reportAxiosError(error);
    return { type: 'FETCH_FAILURE' };
  }
}

function useDataFetcher(url, config = {}, shouldFetch = true) {
  const lastParamsRef = React.useRef([url, config]);
  const [currParams, setCurrParams] = React.useState([url, config]);

  const reloadData = React.useCallback(async () => {
    const [currUrl, currConfig] = currParams;

    const action = await fetchData(currUrl, currConfig);
    if (action) {
      dispatch(action);
      return action.payload;
    }
  }, [currParams]);

  const dataFetchReducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_INIT':
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case 'FETCH_SUCCESS':
        return {
          ...state,
          isLoading: false,
          isError: false,
          data: action.payload,
        };
      case 'FETCH_FAILURE':
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: shouldFetch,
    isError: false,
    data: undefined,
  });

  useEffect(() => {
    const [currUrl, currConfig] = currParams;

    let didCancel = false;
    const callFetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      const action = await fetchData(currUrl, currConfig);
      if (!didCancel) {
        dispatch(action);
      }
    };
    if (shouldFetch) {
      callFetchData();
    }
    return () => (didCancel = true);
  }, [currParams, shouldFetch]);

  React.useEffect(() => {
    if (lastParamsRef.current) {
      const [lastUrl, lastConfig] = lastParamsRef.current;
      if (lastUrl === url && isEqual(lastConfig, config)) {
        return;
      }
    }
    setCurrParams([url, config]);
    lastParamsRef.current = [url, config];
  }, [url, config]);

  return { ...state, reloadData };
}

export default useDataFetcher;
