import React from 'react';

import { DateCell } from '../../../components/DateCell';
import {
  AddLegalActionLogDialog,
  EditLegalActionLogDialog,
} from '../../../Dialogs/LegalActionLog/AddEditLegalActionLogDialog';
import { useLegalAction } from '../../../hooks/useLegalAction';
import { useLegalActionsBackendRoutes } from '../../../hooks/useLegalActionsBackendRoutes';
import type { Option } from '../../../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../../../hooks/useLegalActionsConfiguration';
import { getLabelByKey } from '../../../LegalActionsUtils';
import type { LegalActionLogApiResponse } from '../../../types';
import { LegalActionLogType } from '../../../types';
import type { LegalActionLogsCardProps } from '../../LegalActionScreenTypes';
import type { LogsCardContentColumnDefinition } from '../LogsCardConent';
import { LogsCardContainer } from '../LogsCardContainer';

import { LegalActionLogMoreDetails } from './LegalActionLogMoreDetails';

export const LegalActionLogsCard: React.FC<LegalActionLogsCardProps<LegalActionLogApiResponse>> = ({
  logs,
  reloadLogs,
}) => {
  const { legalAction } = useLegalAction();
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);
  const { logTypeOptions, statusOfLitigationOptions } = useLegalActionsConfiguration();

  const columns: LogsCardContentColumnDefinition<LegalActionLogApiResponse>[] = [
    {
      id: 'date',
      label: 'Date',
      specialCell: (row) => <DateCell property="date" log={row} />,
    },
    {
      id: 'log_type',
      label: 'Type',
      specialCell: (row) => <div>{getLabelByKey(logTypeOptions as Option[], row.log_type)}</div>,
    },
    {
      id: 'status_of_litigation',
      label: 'Status of Litigation',
      specialCell: (row) => <div>{getLabelByKey(statusOfLitigationOptions as Option[], row.status_of_litigation)}</div>,
    },
    {
      id: 'due_date',
      label: 'Due Date',
      specialCell: (row) => <DateCell property="due_date" log={row} />,
    },
  ];

  return (
    <LogsCardContainer<LegalActionLogApiResponse>
      NewItemComponent={AddLegalActionLogDialog}
      MoreDetailsComponent={LegalActionLogMoreDetails}
      EditItemComponent={EditLegalActionLogDialog}
      deleteUrl={backendRoutes.legalAction.logs.legal_action_log}
      logs={logs}
      logType={LegalActionLogType.log}
      columns={columns}
      title="Legal Action Logs"
      addItemLabel="Log"
      reload={reloadLogs}
      legalAction={legalAction}
    />
  );
};
