import useOrganization from '~/components/OrganizationContext';
import type { TableauConfig } from '~/components/ReportsPage/types';

/**
 * a hook to get the tableau configuration as defined on the organization configuration json
 */
export const useTableauConfiguration = (): TableauConfig => {
  const { orgTableauConfigs }: { orgTableauConfigs?: TableauConfig } = useOrganization();
  const { host_url, target_site, auth_workbook, auth_view, self_service_datasource } = orgTableauConfigs || {};

  return {
    host_url,
    target_site,
    auth_workbook,
    auth_view,
    self_service_datasource,
  };
};
