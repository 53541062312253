import React from 'react';
import PropTypes from 'prop-types';

import { useIncidentConfiguration } from '../../../../../hooks/useIncidentConfiguration';
import {
  getInitialSectionConfig,
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../../IncidentConfiguration/CustomFieldsContext';

const FAULT_ASSESSMENT_PATH = 'incident_details.auto_incident_additional.fault_assessment';
const FAULT_ASSESSMENT_CONFIG_PATH = 'incident_details.pre_defined_fields.auto_incident_additional.fault_assessment';

const getInitialFaultAssessmentSectionConfig = (incidentConfiguration, isFieldSupportedBySubtype) =>
  getInitialSectionConfig(incidentConfiguration, FAULT_ASSESSMENT_CONFIG_PATH, {}, isFieldSupportedBySubtype);
const getInitialFaultAssessmentPredefinedFields = (incidentConfiguration, additionalFieldOmitFn) =>
  preparePreDefinedFields(getInitialFaultAssessmentSectionConfig(incidentConfiguration), additionalFieldOmitFn);
const getInitialFaultAssessmentInitialValues = (incidentConfiguration, additionalFieldOmitFn) =>
  getPredefinedFieldsEmptyFormikInitialValues(
    getInitialFaultAssessmentPredefinedFields(incidentConfiguration, additionalFieldOmitFn)
  );
const getInitialFaultAssessmentInitialValidationSchema = (incidentConfiguration, additionalFieldOmitFn) =>
  getAdditionalDataValidations(getInitialFaultAssessmentPredefinedFields(incidentConfiguration, additionalFieldOmitFn));

const InitialFaultAssessmentFragment = ({ additionalFieldOmitFn }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInitialFaultAssessmentPredefinedFields(incidentConfiguration, additionalFieldOmitFn);

  return (
    <>
      <PreDefinedField id="first_party_fault_assessment" fields={preDefinedFields} inline />
      <PreDefinedField id="claim_handler_fault_assessment" fields={preDefinedFields} inline />
      <PreDefinedField id="is_indemnity_review_needed" fields={preDefinedFields} inline />
      <PreDefinedField id="is_fraud_review_needed" fields={preDefinedFields} inline />
      <PreDefinedField id="percentage_of_insured_liability" fields={preDefinedFields} inline />
      <PreDefinedField id="liability_summary" fields={preDefinedFields} inline />
    </>
  );
};

InitialFaultAssessmentFragment.propTypes = {
  additionalFieldOmitFn: PropTypes.func,
};

export {
  FAULT_ASSESSMENT_PATH,
  getInitialFaultAssessmentInitialValidationSchema,
  getInitialFaultAssessmentInitialValues,
  getInitialFaultAssessmentPredefinedFields,
  getInitialFaultAssessmentSectionConfig,
  InitialFaultAssessmentFragment,
};
