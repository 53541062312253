import React, { useState } from 'react';

import type { StepProps } from '~/components/core/Stepper/Step';

const StepperContext = React.createContext({});

const StepperContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<StepProps[]>([]);

  const isLastStep = activeStep === steps.length - 1;
  const isFirstStep = activeStep === 0;

  const handleNext = async () => {
    const validated = await steps[activeStep].onNext();

    if (validated === false) {
      return activeStep;
    }

    const nextStep = Math.min(activeStep + 1, steps.length - 1);
    setActiveStep(nextStep);

    return nextStep;
  };

  const handleBack = async (validate = true) => {
    if (validate) {
      const validated = await steps[activeStep].onBack();

      if (validated === false) {
        return activeStep;
      }
    }

    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    return Math.max(activeStep - 1, 0);
  };

  return (
    <StepperContext.Provider
      value={{
        handleBack,
        handleNext,
        isFirstStep,
        activeStep,
        setSteps,
        steps,
        isLastStep,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

interface StepperContextType {
  handleBack: (validate: boolean) => Promise<number>;
  handleNext: () => Promise<number>;
  isFirstStep: boolean;
  activeStep: number;
  setSteps: (steps: StepProps[]) => void;
  steps: StepProps[];
  isLastStep: boolean;
  nextStep: number;
}
const useStepper = (): StepperContextType => {
  const stepperContext = React.useContext(StepperContext);
  return { ...stepperContext } as StepperContextType;
};

export { StepperContextProvider, useStepper };
