import React, { useState } from 'react';
import { PhonePlus } from 'mdi-material-ui';

import { isClaimWriteDisabled } from '../Utils/ClaimUtils';

import DocumentPhoneCallCommunicationCardContainer from './communications/PhoneCallCommunicationCard';
import { useCms } from './hooks/useCms';
import { useClaim } from './ClaimContainer';
import InlineIconButton from './InlineIconButton';

function DocumentIncomingPhoneCallContainer() {
  const [showDocumentIncomingPhoneCall, setShowDocumentIncomingPhoneCall] = useState(false);

  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user } = useCms();

  if (!claim) {
    throw Error('Document call should be within claim context');
  }

  return (
    <>
      <InlineIconButton
        defaultColor="inherit"
        onClick={() => setShowDocumentIncomingPhoneCall(true)}
        icon={PhonePlus}
        tooltipTitle="Document call"
        disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
      />
      {showDocumentIncomingPhoneCall && (
        <DocumentPhoneCallCommunicationCardContainer
          claim={claim}
          onCancel={() => setShowDocumentIncomingPhoneCall(false)}
          onDocumentPhoneCall={() => {
            onAsyncClaimUpdate();
            setShowDocumentIncomingPhoneCall(false);
          }}
          direction="Incoming"
        />
      )}
    </>
  );
}

export default DocumentIncomingPhoneCallContainer;
