import React from 'react';

import type { AiChatReference } from '~/components/AiChat/types';
import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import { LoadingSwitch } from '~/components/core';
import { useCms } from '~/components/hooks/useCms';
import { NoteMiniCard as OldNote } from '~/components/Note';
import NoteMiniCard from '~/components/Notes/NoteMiniCard';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

interface NoteReferenceDialogProps {
  noteReference: AiChatReference;
  onClose: () => void;
  claimId: string;
}

export const NoteReferenceDialog: React.FC<NoteReferenceDialogProps> = ({ noteReference, onClose, claimId }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const {
    data: note,
    isLoading,
    reloadData,
    isError,
  } = useDataFetcher(`/api/v1/claims/${claimId}/notes/${noteReference?.id}`);
  const featureEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTES_2_0);

  return (
    <CardDialog open={true} onClose={onClose} isDialog noCardTitle>
      <LoadingSwitch isLoading={isLoading} isError={isError}>
        {featureEnabled && <NoteMiniCard note={note} claim={claim} onNoteUpdate={reloadData} />}
        {!featureEnabled && <OldNote note={note} onNoteUpdated={reloadData} viewOnly />}
      </LoadingSwitch>
    </CardDialog>
  );
};
