import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import TextFieldFormik from '../../../TextFieldFormik';

import { useStyles } from '../../../../assets/styles';

function getAllowedExpensePaymentTypes(expensePaymentTypes, claim, exposure) {
  const allowedTypes = [];
  Object.entries(expensePaymentTypes).forEach(([typeKey, type]) => {
    if (
      [
        type?.excluded_claim_types && type?.excluded_claim_types?.includes(claim.type),
        type?.included_claim_types && !type?.included_claim_types?.includes(claim.type),
        type?.coverages && !type?.coverages?.includes(exposure.coverage_type),
        type.is_removed,
      ].some(Boolean)
    ) {
      return;
    }
    allowedTypes.push([typeKey, type]);
  });
  return allowedTypes;
}

const ExpensesPaymentTypeFieldFormik = ({ claim, exposure, readOnly }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const { expensePaymentTypes, shouldUseExpensesLabels } = usePaymentsConfiguration(values.payment_method);

  const types = getAllowedExpensePaymentTypes(expensePaymentTypes, claim, exposure);

  return (
    <div style={{ marginBottom: '16px' }}>
      {shouldUseExpensesLabels ? (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextFieldFormik
              id="expense_type"
              label="Payment Type"
              className={classes.textField}
              fullWidth
              select
              disabled={readOnly}
            >
              {types.map(([typeKey, type]) => (
                <MenuItem key={typeKey} value={typeKey}>
                  {type['desc']}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={6}>
            {values.expense_type === 'other' && (
              <TextFieldFormik id="expense_other_type" label="Other Type" fullWidth className={classes.textField} />
            )}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

ExpensesPaymentTypeFieldFormik.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

export default ExpensesPaymentTypeFieldFormik;
