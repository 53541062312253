import React from 'react';
import PropTypes from 'prop-types';

import cn from '~/Utils/cn';

import plainListStyles from './plainList.module.scss';

const PlainList = ({ rows }) => {
  return (
    <div className={plainListStyles.container}>
      {rows.map(({ node, id, disabled, icon }) => (
        <div
          className={cn(plainListStyles.item, {
            [plainListStyles.disabled]: disabled,
          })}
          key={id}
        >
          <div className={plainListStyles.nodeAndIconWrapper}>
            {icon && <div className={cn(plainListStyles.icon)}>{icon}</div>}
            <div className={cn(plainListStyles.node)}>{node}</div>
          </div>
          <div className={plainListStyles.partialBorder} />
        </div>
      ))}
    </div>
  );
};

PlainList.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      node: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
      icon: PropTypes.node,
    })
  ),
};

export default PlainList;
