import type { MouseEventHandler } from 'react';
import React from 'react';
import { Radio as MuiRadio } from '@material-ui/core';

import cn from '~/Utils/cn';

export interface RadioProps {
  key?: string;
  color?: 'primary' | 'secondary' | 'default';
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  size?: 'small' | 'medium';
  style?: React.CSSProperties;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Radio: React.FC<RadioProps> = ({
  key,
  color = 'default',
  checked,
  onChange,
  disabled,
  size,
  onClick,
  style,
  className,
}) => {
  return (
    <MuiRadio
      className={cn({ ['text-teal-700']: checked }, className)}
      key={key}
      color={color}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      size={size}
      onClick={onClick}
      style={style}
    />
  );
};

export default Radio;
