import React from 'react';

import SvgIcon from '../core/SvgIcon';

const GalleryIcon = (props) => (
  <SvgIcon viewBox="0 0 16 16" stroke="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00012 4.5C3.89555 4.5 3.00012 5.39543 3.00012 6.5C3.00012 7.60457 3.89555 8.5 5.00012 8.5C6.10469 8.5 7.00012 7.60457 7.00012 6.5C7.00012 5.39543 6.10469 4.5 5.00012 4.5ZM2.00012 6.5C2.00012 4.84315 3.34327 3.5 5.00012 3.5C6.65697 3.5 8.00012 4.84315 8.00012 6.5C8.00012 8.15685 6.65697 9.5 5.00012 9.5C3.34327 9.5 2.00012 8.15685 2.00012 6.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00012 5C9.00012 4.72386 9.22398 4.5 9.50012 4.5H15.5001C15.7763 4.5 16.0001 4.72386 16.0001 5C16.0001 5.27614 15.7763 5.5 15.5001 5.5H9.50012C9.22398 5.5 9.00012 5.27614 9.00012 5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00012 8C9.00012 7.72386 9.22398 7.5 9.50012 7.5H15.5001C15.7763 7.5 16.0001 7.72386 16.0001 8C16.0001 8.27614 15.7763 8.5 15.5001 8.5H9.50012C9.22398 8.5 9.00012 8.27614 9.00012 8Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5001 11C10.5001 10.7239 10.724 10.5 11.0001 10.5H15.5001C15.7763 10.5 16.0001 10.7239 16.0001 11C16.0001 11.2761 15.7763 11.5 15.5001 11.5H11.0001C10.724 11.5 10.5001 11.2761 10.5001 11Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00012 9.49219C4.22297 9.49219 3.46793 9.75084 2.85402 10.2274C2.24011 10.7039 1.80226 11.3712 1.6095 12.1241C1.541 12.3916 1.26861 12.5529 1.0011 12.4844C0.733588 12.4159 0.572251 12.1435 0.640745 11.876C0.888584 10.908 1.45153 10.0501 2.24084 9.43742C3.03016 8.82474 4.00093 8.49219 5.00012 8.49219C5.99931 8.49219 6.97009 8.82474 7.7594 9.43742C8.54871 10.0501 9.11166 10.908 9.3595 11.876C9.42799 12.1435 9.26665 12.4159 8.99914 12.4844C8.73163 12.5529 8.45924 12.3916 8.39075 12.1241C8.19798 11.3712 7.76013 10.7039 7.14622 10.2274C6.53232 9.75084 5.77727 9.49219 5.00012 9.49219Z"
    />
  </SvgIcon>
);

export default GalleryIcon;
