import React from 'react';
import PropTypes from 'prop-types';

import { ClaimContextProvider } from '~/components/ClaimContainer';
import SendSmsCommunicationCardContainer from '~/components/communications/SmsCommunicationCard/SmsCommunicationCard';
import LoadingDialog from '~/components/LoadingDialog';
import useDataFetcher from '~/components/useDataFetcher';
import { useFetchClaim } from '~/Utils/ClaimUtils';

function ReplySmsCommunicationDialog({ communication, onClose }) {
  const [claim, isLoadingClaim, isErrorClaim, reloadClaim] = useFetchClaim(communication.claim_id);
  const {
    isLoading: isLoadingContact,
    isError: isErrorContact,
    data: contactToSendSms,
  } = useDataFetcher(`/api/v1/claims/${communication.claim_id}/contacts/${communication.contact.id}`);

  if (isLoadingClaim || isErrorClaim || isLoadingContact || isErrorContact) {
    return <LoadingDialog isError={isErrorClaim || isErrorContact} track="Reply Sms" />;
  }

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <SendSmsCommunicationCardContainer
        contact={contactToSendSms}
        contactPhoneId={communication.contact_phone.id}
        onCancel={onClose}
        onSendSms={async () => {
          await reloadClaim();
          onClose();
        }}
        messageContent=""
      />
    </ClaimContextProvider>
  );
}

ReplySmsCommunicationDialog.propTypes = {
  communication: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReplySmsCommunicationDialog;
