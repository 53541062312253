import React from 'react';

import { AddProsAndConsDialog } from '../../../Dialogs/ProsAndCons/AddProsAndCons';
import { EditProsAndConsDialog } from '../../../Dialogs/ProsAndCons/EditProsAndCons';
import { useLegalAction } from '../../../hooks/useLegalAction';
import { useLegalActionsBackendRoutes } from '../../../hooks/useLegalActionsBackendRoutes';
import type { Option } from '../../../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../../../hooks/useLegalActionsConfiguration';
import { getLabelByKey } from '../../../LegalActionsUtils';
import type { ProsAndConsApiResponse } from '../../../types';
import { LegalActionLogType } from '../../../types';
import type { LegalActionLogsCardProps } from '../../LegalActionScreenTypes';
import type { LogsCardContentColumnDefinition } from '../LogsCardConent';
import { LogsCardContainer } from '../LogsCardContainer';

export const ProsAndConsCard: React.FC<LegalActionLogsCardProps<ProsAndConsApiResponse>> = ({ logs, reloadLogs }) => {
  const { legalAction } = useLegalAction();
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);
  const { prosAndConsOptions } = useLegalActionsConfiguration();

  const columns: LogsCardContentColumnDefinition<ProsAndConsApiResponse>[] = [
    {
      id: 'pro_con_type',
      label: 'Type',
      specialCell: (row) => <div>{getLabelByKey(prosAndConsOptions as Option[], row.pro_con_type)}</div>,
    },
    {
      id: 'rationale',
      label: 'Rationale',
    },
  ];

  return (
    <LogsCardContainer<ProsAndConsApiResponse>
      NewItemComponent={AddProsAndConsDialog}
      EditItemComponent={EditProsAndConsDialog}
      deleteUrl={backendRoutes.legalAction.logs.pro_and_con}
      logs={logs}
      logType={LegalActionLogType.pros_and_cons}
      columns={columns}
      title="Pros and Cons Logs"
      addItemLabel="Pros and Cons"
      reload={reloadLogs}
      legalAction={legalAction}
    />
  );
};
