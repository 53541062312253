import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { isFunction } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';

import { getTabName } from '../../..//Utils';
import mixpanel from '../../../components/CmsMain/mixpanel';
import ActionMenuButton from '../../../components/core/ActionMenuButton/ActionMenuButton';
import { MIXPANEL_EVENTS } from '../../../pocs/mixpanel';
import { EXPORT_TYPES } from '../tableauUtils';

import { useStyles } from '../../../assets/styles';

export const TableauExportActions = ({ exportTableauView, isDashboardView = false }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const onExportClick = (exportTableauView, exportType) => {
    const { parentTab, tabName } = getTabName();

    exportTableauView(exportType);
    mixpanel.track(MIXPANEL_EVENTS.REPORTING_ENTITY_EXPORT_CLICKED, {
      reporting_entity: parentTab,
      tab_name: tabName,
      format: exportType,
    });
  };

  useEffect(() => {
    if (disabled && isFunction(exportTableauView)) {
      setDisabled(false);
    }
  }, [disabled, exportTableauView]);

  const actions = [
    {
      label: 'Export to CSV',
      onClick: () => onExportClick(exportTableauView, EXPORT_TYPES.CSV),
      disabled: isDashboardView,
    },
    {
      label: 'Export to Excel',
      onClick: () => onExportClick(exportTableauView, EXPORT_TYPES.EXCEL),
      disabled: isDashboardView,
    },
    { label: 'Export to PDF', onClick: () => onExportClick(exportTableauView, EXPORT_TYPES.PDF), disabled: false },
  ];

  return (
    <>
      <Button
        className={`${classes.actionButton} ${classes.button}`}
        variant="contained"
        color="primary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<ExpandMore />}
        disabled={disabled}
      >
        Actions
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        placement="bottom-end"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ width: 280 }}>
          {actions
            .filter((action) => !action?.disabled)
            .map(({ label, onClick }, idx) => (
              <Fragment key={idx}>
                <ActionMenuButton label={label} onClick={onClick} />
              </Fragment>
            ))}
        </div>
      </Popover>
    </>
  );
};

TableauExportActions.propTypes = {
  exportTableauView: PropTypes.func,
  isDashboardView: PropTypes.bool,
};
