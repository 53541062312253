import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../CardDialog';
import { getAllSearchableContactRoles } from '../communications/ContactUtils';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import useOrganization from '../OrganizationContext';

import { useStyles } from '../../assets/styles';

function CreditBureauDialog(props) {
  const { dialogTitle, onSubmitDetails, damageDetails, open, onCancel } = props;
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <Formik
      initialValues={{ ...damageDetails }}
      validationSchema={Yup.object().shape({
        credit_bureau_contact_id: Yup.number().required('Required'),
      })}
      enableReinitialize
      onSubmit={(values, formikBag) => {
        onSubmitDetails(values);
        formikBag.resetForm();
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title={dialogTitle}
            fullWidth
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ContactTextFieldFormik
                  id="credit_bureau_contact"
                  label="Credit Bureau"
                  className={classes.textField}
                  fullWidth
                  acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
                />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

CreditBureauDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  damageDetails: PropTypes.object.isRequired,
  onSubmitDetails: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreditBureauDialog;
