import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from '@material-ui/core';
import axios from 'axios';
import _ from 'lodash';

import MenuItem from '~/components/core/Atomic/MenuItem';

import { CONFIGURATION_FEATURES_NAMES } from '../Types';
import { isExposureFinancialOnly, isFeatureEnabled, reportAxiosError } from '../Utils';
import { getClaimExposuresWithGeneralFakeExposure } from '../Utils/ClaimUtils';

import { openTopLevelDialog } from './CmsMain/globals';
import CloseClaimDialog from './exposures/CloseClaimDialog';
import { ClosingReasonDialog } from './exposures/CloseExposureDialog';
import { useExposureClosingDisabledReason } from './exposures/ExposureUtils';
import { useCms } from './hooks/useCms';
import { useClaim } from './ClaimContainer';
import WithConfirm from './ConfirmModal';
import { FsButton } from './core';
import useOrganization from './OrganizationContext';
import ReopenClaimDialog from './ReopenClaimDialog';

import { useStyles } from '../assets/styles';

function CloseClaimContainer() {
  const { claim, onClaimUpdate } = useClaim();
  const classes = useStyles();
  const { userOrganization, user } = useCms();
  const history = useHistory();
  const { orgRequiredFieldsForCloseClaim, orgAdditionalConfigs, closeClaimReasons } = useOrganization();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [showCloseReasonDialog, setShowCloseReasonDialog] = React.useState({ showDialog: false, closeNote: undefined });
  const [showConfigurableCloseClaimDialog, setShowConfigurableCloseClaimDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const disableCloseExposureReason = useExposureClosingDisabledReason(getClaimExposuresWithGeneralFakeExposure(claim));

  const isClosingClaimWithOpenExposures = isExposureFinancialOnly(userOrganization);
  const shouldValidateBeforeCloseClaim = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.SHOULD_VALIDATE_BEFORE_CLOSING_CLAIM
  );
  const T__isMgmWcLabledCriteriaEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.T__MGM_WC_LABELED_CRITERIA
  );
  const isConfigurableClosingReasonEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.NEW_CLOSING_REASON
  );

  const handleCloseClaim = async (close_note, reason, extra, claim_reason, claim_extra) => {
    try {
      setIsFetching(true);
      await axios.post(`/api/v1/claims/${claim.id}/close`, {
        close_note,
        reason,
        extra,
        claim_reason,
        claim_extra,
      });
      await onClaimUpdate();
      if (isConfigurableClosingReasonEnabled) {
        setShowConfigurableCloseClaimDialog(false);
      } else {
        setShowCloseReasonDialog({ showDialog: false, closeNote: undefined });
      }
      setIsFetching(false);
      if (close_note === 'Delete claim') {
        history.push('/home');
      }
    } catch (error) {
      reportAxiosError(error);
      setIsFetching(false);
    }
  };

  const handleValidateBeforeCloseClaimOrClose = async (close_note, key) => {
    if (!shouldValidateBeforeCloseClaim) {
      await handleCloseClaimClicked(close_note);
      return;
    }

    try {
      setIsFetching(true);
      const { data } = await axios.get(`/api/v1/claims/${claim.id}/validate_before_close/${key}`);

      if (data.is_valid) {
        await handleCloseClaimClicked(close_note);
      } else {
        openTopLevelDialog({ title: "Can't close claim", message: data.message });
      }
    } catch (err) {
      reportAxiosError(err);
    } finally {
      setIsFetching(false);
    }
  };

  const handleCloseClaimClicked = async (close_note) => {
    if (isClosingClaimWithOpenExposures) {
      setShowCloseReasonDialog({ showDialog: true, closeNote: close_note });
    } else {
      await handleCloseClaim(close_note);
    }
  };

  const claimCloseTypes = (claimType, orgAdditionalConfigs, user, closeClaimReasons) =>
    Object.keys(closeClaimReasons).reduce((acc, close_type) => {
      const currOption = closeClaimReasons[close_type];
      if (currOption.excluded_claim_types && currOption.excluded_claim_types.includes(claimType)) {
        return acc;
      } else if (currOption.included_claim_types && !currOption.included_claim_types.includes(claimType)) {
        return acc;
      } else if (
        currOption.special_config_users_list &&
        !(
          orgAdditionalConfigs &&
          orgAdditionalConfigs[currOption.special_config_users_list] &&
          orgAdditionalConfigs[currOption.special_config_users_list].includes(user.id)
        )
      ) {
        return acc;
      }

      return acc.concat({ ...closeClaimReasons[close_type], key: close_type });
    }, []);

  const closeTypes = useMemo(
    () => claimCloseTypes(claim.type, orgAdditionalConfigs, user, closeClaimReasons),
    [claim.type, orgAdditionalConfigs, user, closeClaimReasons]
  );

  if (claim.is_closed) {
    return (
      <>
        <FsButton
          size="small"
          style={{ paddingTop: 3, paddingBottom: 3 }}
          variant="contained"
          color="primary"
          onClick={() => setOpenDialog(true)}
        >
          <span style={{ whiteSpace: 'nowrap' }}>Reopen Claim</span>
        </FsButton>
        <ReopenClaimDialog
          classes={classes}
          claim={claim}
          open={openDialog}
          onSubmit={() => onClaimUpdate().then(() => setOpenDialog(false))}
          onCancel={() => setOpenDialog(false)}
        />
      </>
    );
  }

  let disableClosing = false;
  let disableClosingReasons = [];

  if (claim.type === 'home_claim' && !claim.incident.cause_of_loss) {
    disableClosing = true;
    disableClosingReasons.push('Cause of Loss must be set');
  }

  if (isClosingClaimWithOpenExposures) {
    if (disableCloseExposureReason) {
      disableClosing = true;
      disableClosingReasons.push(disableCloseExposureReason);
    }
  } else {
    // ! isClosingClaimWithOpenExposures
    if (
      claim.exposures.reduce((isOpenExposureExists, exposure) => isOpenExposureExists || !exposure.is_closed, false)
    ) {
      disableClosing = true;
      disableClosingReasons.push('Exposures must be closed');
    }
  }

  if (
    !isConfigurableClosingReasonEnabled &&
    orgRequiredFieldsForCloseClaim &&
    orgRequiredFieldsForCloseClaim.required_fields.length
  ) {
    const { required_fields: fields } = orgRequiredFieldsForCloseClaim;
    fields.forEach(({ field, message }) => {
      const value = _.get(claim, field);
      // using == to catch null or undefined
      // eslint-disable-next-line eqeqeq
      if (value == null || value === '') {
        disableClosing = true;
        disableClosingReasons.push(message);
      }
    });
  }

  if (T__isMgmWcLabledCriteriaEnabled && claim.type === 'wc_claim') {
    if ([null, '', 'pending'].includes(claim.incident.labeled_criteria)) {
      disableClosing = true;
      disableClosingReasons.push('Claim type must be set');
    }
  }

  return (
    <>
      <FsButton
        size="small"
        style={{ paddingTop: 3, paddingBottom: 3 }}
        disabled={disableClosing || isFetching}
        variant="contained"
        color="primary"
        onClick={(e) => {
          if (isConfigurableClosingReasonEnabled) {
            setShowConfigurableCloseClaimDialog(true);
          } else {
            setAnchorEl(e.currentTarget);
          }
        }}
        tooltipText={disableClosing ? disableClosingReasons.join(', ') : null}
      >
        <span style={{ whiteSpace: 'nowrap' }}>Close Claim</span>
      </FsButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        {closeTypes.map((type, key) => {
          return (
            <WithConfirm
              key={key}
              title="Are you sure?"
              contentText={type.confirm_pop_up}
              primaryButtonName="Proceed"
              disableConfirm={!type.confirm_pop_up}
            >
              <MenuItem
                onClick={async () => {
                  await handleValidateBeforeCloseClaimOrClose(type.note, type.key);
                  setAnchorEl(null);
                }}
                disabled={isFetching}
              >
                <span dangerouslySetInnerHTML={{ __html: type.desc }} />
              </MenuItem>
            </WithConfirm>
          );
        })}
      </Menu>
      {showCloseReasonDialog.showDialog && ( // DEPRECATED - only for organizations where exposures are financial only
        <ClosingReasonDialog
          title="Closing Reason"
          claim={claim}
          exposures={claim.exposures}
          onClose={() => setShowCloseReasonDialog({ showDialog: false, closeNote: undefined })}
          onReasonChosen={(values) => handleCloseClaim(showCloseReasonDialog.closeNote, values.reason, values.extra)}
        />
      )}
      {showConfigurableCloseClaimDialog && (
        <CloseClaimDialog
          onClose={() => setShowConfigurableCloseClaimDialog(false)}
          title="Close Claim"
          claim={claim}
          onReasonChosen={(values) => handleCloseClaim('', '', '', values.claim_reason, values.claim_extra)}
        />
      )}
    </>
  );
}

export default CloseClaimContainer;
