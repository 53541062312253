import React from 'react';

import SvgIcon from '../core/SvgIcon';

const RefreshIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path d="M8 1.5C4.416 1.5 1.5 4.416 1.5 8C1.5 11.584 4.416 14.5 8 14.5C11.584 14.5 14.5 11.584 14.5 8H13.5C13.5 11.043 11.043 13.5 8 13.5C4.957 13.5 2.5 11.043 2.5 8C2.5 4.957 4.957 2.5 8 2.5C9.9375 2.5 11.631 3.492 12.6095 5H10V6H14V2H13V3.8595C11.8085 2.422 10.01 1.5 8 1.5Z" />
  </SvgIcon>
);

export default RefreshIcon;
