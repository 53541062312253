import React from 'react';

import FsButton from '../../FsWrappers/FsButton/FsButton';

import styles from './AiButton.module.scss';

const AiButton = (props) => (
  <FsButton
    variant={FsButton.VARIANTS.outlined}
    size={FsButton.SIZES.small}
    // TODO: colors are customized here with scss, decide if to add a button variant like this or align back to existing buttons
    className={styles.aiButton}
    {...props}
  />
);

export default AiButton;
