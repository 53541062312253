import React from 'react';

import type { MessageWithIllustrationProps } from '../../MessageWithIllustration';
import MessageWithIllustration from '../../MessageWithIllustration';

const StepperFinishScreen: React.FC<MessageWithIllustrationProps> = (props): React.JSX.Element => (
  <MessageWithIllustration {...props} />
);

export default StepperFinishScreen;
