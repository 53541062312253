import React from 'react';

import type { VideoCallCommunication } from '~/components/communications/types';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import OrganizationLogo from '~/components/core/OrganizationLogo/OrganizationLogo';
import Caption from '~/components/core/TextComponents/Caption';
import Text from '~/components/core/TextComponents/Text';
import ContactIcon from '~/components/icons/ContactIcon';
import CopySimpleIcon from '~/components/icons/CopySimpleIcon';
import LinkOutIcon from '~/components/icons/LinkOutIcon';
import useOrganization from '~/components/OrganizationContext';

interface Props {
  communication: VideoCallCommunication;
  allowClose: boolean;
  onClose: () => void;
}
const VideoCallsDyteTopBar: React.FC<Props> = ({ communication, allowClose, onClose }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();

  // || '' is for typescript, not supposed to happen since we should have provider_specific_details
  const guestLink = communication.provider_specific_details?.guest_link || '';

  return (
    <div className="flex items-center justify-between bg-grey-600 px-32 py-16 text-white">
      <div className="flex items-center justify-between gap-24">
        <OrganizationLogo />
        <div className="ml-12 flex items-center">
          <Caption variant={Caption.VARIANTS.MEDIUM} className="text-white">
            Guest link:
          </Caption>
          <Text
            variant={Text.VARIANTS.XS}
            colorVariant={Text.COLOR_VARIANTS.WHITE}
            className="ml-4 inline-block max-w-[180px] truncate"
          >
            {guestLink}
          </Text>
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(guestLink);
            }}
            className="p-4 text-white hover:text-teal-700"
          >
            <CopySimpleIcon iconColor="currentColor" />
          </IconButton>
        </div>
        <div className="flex items-center">
          <ContactIcon iconColor="currentColor" />
          <Text
            variant={Text.VARIANTS.SM}
            weight={Text.WEIGHTS.SEMI_BOLD}
            colorVariant={Text.COLOR_VARIANTS.WHITE}
            className="mx-12"
          >
            {communication?.contact?.full_name || ''}
          </Text>
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} colorVariant={Text.COLOR_VARIANTS.WHITE}>
            {organizationContactRolesDict[communication?.contact?.role].desc}
          </Text>
        </div>
        <Button
          color="primary"
          variant="contained"
          href={`/claims/${communication.claim_id}`}
          target="_blank"
          endIcon={<LinkOutIcon iconColor="currentColor" />}
        >
          GO TO CLAIM
        </Button>
      </div>
      <Button color="secondary" variant="contained" onClick={onClose} disabled={!allowClose}>
        Close
      </Button>
    </div>
  );
};

export default VideoCallsDyteTopBar;
