import React, { Component, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import ContactsContextProvider from '~/components/ContactsContext';
import { MinimizedClaimNavigationWrapper } from '~/components/core/MinimizedBar/Context/MinimizedClaimNavigationWrapper';
import { useCms } from '~/components/hooks/useCms';
import LoadingIndicator from '~/components/LoadingIndicator';
import { PolicyContextProvider } from '~/components/PolicyContainer';
import RefreshToaster from '~/components/RefreshToaster';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, isPermissionDeniedError, reportAxiosError } from '~/Utils';
import { populateClaim, touchClaim } from '~/Utils/ClaimUtils';

const ClaimContext = React.createContext({});

class ClaimContainer extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      claim: null,
      isLoading: true,
      isError: false,
      reloadingUuid: uuidv4(),
    };

    this.state = {
      ...this.initialState,
    };
  }

  componentDidMount() {
    const { claimId } = this.props;
    this.refreshData(true);
    touchClaim(claimId);
  }

  componentDidUpdate(prevProps) {
    const { claimId } = this.props;
    if (prevProps.claimId !== claimId) {
      this.setState({ ...this.initialState });
      this.refreshData();
      touchClaim(claimId);
    }
  }

  asyncRefreshData = () => {
    const time = new Date();
    const key = this.state.reloadingUuid;

    const toastTimer = setTimeout(() => {
      toast(<RefreshToaster />, {
        position: 'top-center',
        hideProgressBar: true,
        toastId: key,
        autoClose: false,
        transition: Slide,
      });
    }, 500);

    this.refreshData().then(() => {
      clearTimeout(toastTimer);
      const diffSinceStart = new Date() - time;
      const dismissDelay = diffSinceStart < 1000 ? diffSinceStart : 0;

      setTimeout(() => {
        if (toast?.isActive(key)) {
          toast.dismiss(key);
        }
      }, dismissDelay);
    });
  };

  refreshData = async (notFoundRedirectsToHome = false) => {
    const { claimId } = this.props;

    this.setState({ isLoading: true, reloadingUuid: uuidv4() });

    try {
      const response = await axios.get(`/api/v1/claims/${claimId}`);
      this.setState({ claim: populateClaim(response.data), isLoading: false });
      return response;
    } catch (error) {
      this.setState({ isError: true });
      if (isPermissionDeniedError(error) || error?.response?.status === 401) {
        this.props.history.push('/permission_denied', {});
      } else if (notFoundRedirectsToHome && error?.response?.status === 404) {
        this.props.history.push('/', {});
      } else {
        await reportAxiosError(error);
      }
    }
  };

  render() {
    const { children } = this.props;
    const { claim, isLoading, isError, reloadingUuid } = this.state;

    if (!claim && isLoading) {
      // TODO: replace with placeholder etc (or at least put in middle)
      return <LoadingIndicator isError={isError} />;
    }

    return (
      <ClaimContextProvider
        claim={claim}
        refreshData={this.refreshData}
        asyncRefreshData={this.asyncRefreshData}
        inClaimPage
        reloadingUuid={reloadingUuid}
      >
        <MinimizedClaimNavigationWrapper>{children}</MinimizedClaimNavigationWrapper>
      </ClaimContextProvider>
    );
  }
}

ClaimContainer.propTypes = {
  claimId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object, // from withRouter
};

function ClaimContextProvider(props) {
  const { claim, refreshData, children, inClaimPage, asyncRefreshData: asyncRefreshDataFunc, reloadingUuid } = props;
  const { userOrganization } = useCms();
  claim.organization = userOrganization;
  const subOrgId = userOrganization.sub_organizations_enabled ? claim?.policy?.sub_organization_id : null;
  if (subOrgId) {
    claim.policy.sub_organization = userOrganization.sub_organizations.find((sub_org) => sub_org.id === subOrgId);
  }
  const isAsyncClaimRefresh = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.PERFORMANCE_ASYNC_CLAIM_REFRESH
  );
  const asyncRefreshData = isAsyncClaimRefresh && asyncRefreshDataFunc ? asyncRefreshDataFunc : refreshData;

  return (
    <ClaimContext.Provider value={{ claim, refreshData, asyncRefreshData, inClaimPage, reloadingUuid }}>
      <PolicyContextProvider policy={claim.policy}>
        <ContactsContextProvider
          contacts={claim.contacts}
          onAddContact={refreshData}
          contactsOrganizationId={claim.policy.organization_id}
          rolesClaimType={claim.type}
        >
          {children}
        </ContactsContextProvider>
      </PolicyContextProvider>
    </ClaimContext.Provider>
  );
}

ClaimContextProvider.propTypes = {
  claim: PropTypes.shape({
    policy: PropTypes.object.isRequired,
    contacts: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    organization: PropTypes.object,
    organization_id: PropTypes.number.isRequired,
  }),
  refreshData: PropTypes.func.isRequired,
  asyncRefreshData: PropTypes.func,
  inClaimPage: PropTypes.bool,
  children: PropTypes.node.isRequired,
  reloadingUuid: PropTypes.string,
};

function withClaim(WrappedComponent) {
  class WithClaim extends React.Component {
    render() {
      return (
        <ClaimContext.Consumer>
          {(claimContext) => (
            <WrappedComponent
              claim={claimContext.claim}
              onClaimUpdate={claimContext.refreshData}
              onAsyncClaimUpdate={claimContext.asyncRefreshData}
              {...this.props}
            />
          )}
        </ClaimContext.Consumer>
      );
    }
  }

  WithClaim.displayName = `withClaim(${getDisplayName(WrappedComponent)})`;
  return WithClaim;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function useClaim() {
  const claimContext = useContext(ClaimContext);
  return {
    claim: claimContext.claim,
    onClaimUpdate: claimContext.refreshData,
    onAsyncClaimUpdate: claimContext.asyncRefreshData,
    inClaimPage: claimContext.inClaimPage,
    reloadingUuid: claimContext.reloadingUuid,
  };
}

export { ClaimContextProvider, useClaim, withClaim };
export default withRouter(ClaimContainer);
