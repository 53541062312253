const PAYMENT_PRE_SUBMIT_CHECKS = {
  approval_options: {
    supervisor: 'supervisor',
    adjuster_override: 'adjuster_override',
    none: 'none',
  },
  check_types: {
    ofac: {
      issue_key: 'ofac_hit',
    },
    ofac_error: {
      issue_title: 'Encountered error from OFAC servers',
      issue_dialog_details: 'Please try re-submitting the payment request.',
    },
    other_compliance: {
      issue_key: 'other_compliance',
    },
    duplicate_payment: {
      issue_key: 'duplicate_payment',
      issue_title: 'Potential duplicate payments were detected',
      issue_dialog_details:
        'It appears you are about to request a payment that may be a duplicate of the below transaction(s).',
    },
    indemnity_limit_exceeded: {
      issue_key: 'indemnity_limit_exceeded',
      issue_title: 'Policy limit is exceeded for Indemnity payments',
      issue_dialog_details: 'It appears you are about to request a payment that may exceed the Policy limit',
    },
    uk_bank_details_validation: {
      issue_key: 'uk_bank_details_validation',
    },
  },
} as const;

export default PAYMENT_PRE_SUBMIT_CHECKS;
