export const DOCUMENT_FIELD_IDS = {
  CLAIM_DOCUMENT_OR_UPLOAD_FILE: 'claim_document_or_upload_file',
  FILE: 'file',
  FILE_SIZE: 'file_size',
  FILE_TYPE: 'file_type',
  FILE_NAME: 'file_name',
  FILE_LAST_MODIFIED: 'file_last_modified',
  STORAGE_NAME: 'storage_name',
  CLAIM_ID: 'claim_id',
};

export const CLAIM_DOC_OR_UPLOAD_OPTIONS = {
  CLAIM_DOCUMENT: 'claim_document',
  UPLOAD_FILE: 'upload_file',
};
