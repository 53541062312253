import { __DEFAULT_PROFILES__ } from './DefaultProfiles';
import { __PERMISSION_VERBS__ } from './PermissionVerbs';
import { ConfigEnumBase, InnerSectionItem, SectionHeader } from './types';

const ALL_VERBS = Object.values(__PERMISSION_VERBS__);
const READ_WRITE = [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE];
const NONE_WRITE = [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.WRITE];
const NONE_READ_WRITE = [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE];
const NONE_READ = [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.READ];

/**
 *
 * @type {InnerSectionsConfig}
 */
const DEFAULT_SECTIONS_DEFINITION = {
  DEFAULT: {
    id: 'DEFAULT',
    title: '',
  },
};

const DEFAULT_PROFILES = new Proxy(__DEFAULT_PROFILES__, {
  get(target, name) {
    return target[name]?.id;
  },
});

class PERMISSION_ACTIONS extends ConfigEnumBase {
  // Claim Section
  CLAIM = new SectionHeader({
    title: 'Access Claim',
    subtitle: 'View claim details',
    description: '',
    allowedVerbs: [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.READ],
    sectionHeaderIndex: 0,
    innerSections: {
      GENERAL: {
        id: 'GENERAL',
        title: 'General',
      },
      PAYMENTS: {
        id: 'PAYMENTS',
        title: 'Payments',
      },
      DOCUMENTS_TAB: {
        id: 'DOCUMENTS_TAB',
        title: 'Documents Tab',
      },
      POLICY_TAB: {
        id: 'POLICY_TAB',
        title: 'Policy Tab',
      },
      LEGAL_ACTIONS: {
        id: 'LEGAL_ACTIONS',
        title: 'Legal Actions',
      },
    },
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Claim -> General
  DATE_OF_LOSS = new InnerSectionItem({
    title: 'Date of Loss',
    subtitle: 'Edit the incident date of loss ',
    description: '',
    allowedVerbs: [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  TIME_OF_LOSS = new InnerSectionItem({
    title: 'Time of Loss',
    subtitle: 'Edit the incident time of loss ',
    description: '',
    allowedVerbs: [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  DATE_OF_REPORT = new InnerSectionItem({
    title: 'Date of Report',
    subtitle: 'Edit the claim report date ',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  REPORTER = new InnerSectionItem({
    title: 'Reporter',
    subtitle: 'Edit the claim Reporter',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  EXTERNAL_CLAIM_NUMBER = new InnerSectionItem({
    title: 'External Claim Number',
    subtitle: 'Set/Edit the external claim numbers',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  COVERAGE_DECISION = new InnerSectionItem({
    title: 'Coverage Decision',
    subtitle: 'Set/Edit the coverage decision',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  NOTE = new InnerSectionItem({
    title: 'Notes',
    subtitle: 'View, create, delete, and set notes as confidential.',
    description: '',
    allowedVerbs: [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE, __PERMISSION_VERBS__.FULL],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 2,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  NOTIFICATION = new InnerSectionItem({
    title: 'Notifications',
    subtitle: 'View and create notifications',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 3,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CHANGE_CLAIM_OWNER = new InnerSectionItem({
    title: 'Change file owner',
    subtitle: 'Select a new file owner',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 4,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CHANGE_EXPOSURE_OWNER = new InnerSectionItem({
    title: 'Change exposure owner',
    subtitle: 'Select a new exposure owner',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 4,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  EXPORT_CLAIM = new InnerSectionItem({
    title: 'Export Claim',
    subtitle: 'Export claim to PDF',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 5,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  LEGAL_ACTIONS = new InnerSectionItem({
    title: 'Legal Actions Tab',
    subtitle: 'View and Edit Legal Actions',
    description: '',
    allowedVerbs: [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.LEGAL_ACTIONS,
    index: 6,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Claim -> Payments
  INDEMNITY_RESERVE = new InnerSectionItem({
    title: 'Set Indemnity Reserve',
    subtitle: 'Set and edit the indemnity reserve',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  EXPENSES_RESERVE = new InnerSectionItem({
    title: 'Set Expenses Reserve',
    subtitle: 'Set and edit the expenses reserve',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  GENERAL_EXPENSES_RESERVE = new InnerSectionItem({
    title: 'Set General Expenses Reserve',
    subtitle: 'Set and edit the general expenses reserve',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 2,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  INDEMNITY_PAYMENT = new InnerSectionItem({
    title: 'Issue Indemnity Payments',
    subtitle: 'Issue indemnity payments',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 3,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  EXPENSES_PAYMENT = new InnerSectionItem({
    title: 'Issue Expenses Payments',
    subtitle: 'Issue expenses payments',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 4,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  GENERAL_EXPENSES_PAYMENT = new InnerSectionItem({
    title: 'Issue General Expenses Payments',
    subtitle: 'Issue general expenses payments',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 5,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CANCEL_INDEMNITY_PAYMENT = new InnerSectionItem({
    title: 'Cancel Indemnity Payments',
    subtitle: 'Cancel indemnity payments',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 6,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CANCEL_EXPENSES_PAYMENT = new InnerSectionItem({
    title: 'Cancel Expenses Payments',
    subtitle: 'Cancel expenses payments',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 7,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CANCEL_GENERAL_EXPENSES_PAYMENT = new InnerSectionItem({
    title: 'Cancel General Expenses Payments',
    subtitle: 'Cancel general expenses payments',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 8,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  OFAC_OVERRIDE = new InnerSectionItem({
    title: 'Approve OFAC',
    subtitle: 'Approve payments after OFAC hit',
    description: '',
    allowedVerbs: NONE_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 9,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  APPROVE_INDEMNITY_PAYMENT_OVER_LIMIT = new InnerSectionItem({
    title: 'Approve indemnity payment over limit',
    subtitle: 'Allow approve indemnity payment over policy limit',
    description: '',
    allowedVerbs: NONE_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 9,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  APPROVE_CHANGE_INDEMNITY_RESERVE_OVER_LIMIT = new InnerSectionItem({
    title: 'Approve indemnity reserve over limit',
    subtitle: 'Allow approve indemnity reserve over policy limit',
    description: '',
    allowedVerbs: NONE_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 9,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Claim -> Documents Tab
  CLAIM_DOCUMENT = new InnerSectionItem({
    title: 'Documents tab',
    subtitle: 'Add new documents, view, edit and delete existing documents',
    description: '',
    allowedVerbs: [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE, __PERMISSION_VERBS__.FULL],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.DOCUMENTS_TAB,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Claim -> Policy Tab
  MANUAL_POLICY = new InnerSectionItem({
    title: 'Manual Policy',
    subtitle: 'Edit manual policy',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.POLICY_TAB,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  API_POLICY = new InnerSectionItem({
    title: 'API Policy',
    subtitle: 'Edit API policy',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.POLICY_TAB,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  MOI = new InnerSectionItem({
    title: 'MOI',
    subtitle: 'View and edit MOI',
    description: '',
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Contact
  CONTACT = new SectionHeader({
    title: 'Contacts and parties',
    subtitle: 'View and edit contacts and party cards',
    description: '',
    allowedVerbs: ALL_VERBS,
    sectionHeaderIndex: 1,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    preconditions: [],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CONTACT_PII = new InnerSectionItem({
    title: 'Contact PII',
    subtitle: 'View and edit contact PII',
    description: '',
    allowedVerbs: NONE_WRITE,
    section: this.CONTACT,
    innerSection: this.CONTACT.innerSections.DEFAULT,
    index: 0,
    preconditions: [
      {
        field: this.CONTACT,
        needsToBeAtLeast: __PERMISSION_VERBS__.WRITE,
        whenVerbIsEqualOrGreaterThan: __PERMISSION_VERBS__.WRITE,
        errorMessage:
          'If the Contact PII permission is set to Write, the Contacts and Parties permission must also be Write or Full',
      },
    ],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  W9_TIN = new InnerSectionItem({
    title: 'W9_TIN',
    subtitle: 'Upload a contact’s W9 and view and edit TIN',
    description: '',
    allowedVerbs: NONE_WRITE,
    section: this.CONTACT,
    innerSection: this.CONTACT.innerSections.DEFAULT,
    index: 1,
  });
  CREATE_FNOL = new SectionHeader({
    title: 'Create FNOL',
    subtitle: 'Create a new FNOL',
    description: '',
    allowedVerbs: NONE_WRITE,
    sectionHeaderIndex: 2,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    preconditions: [
      {
        field: this.CONTACT,
        needsToBeAtLeast: __PERMISSION_VERBS__.WRITE,
        whenVerbIsEqualOrGreaterThan: __PERMISSION_VERBS__.WRITE,
        errorMessage: 'If the FNOL permission is set to Write, the Contacts and Parties permission must also be Write',
      },
    ],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  QA = new SectionHeader({
    title: 'QA',
    subtitle: 'View QA results and run QA on selected claims',
    description: '',
    allowedVerbs: NONE_WRITE,
    sectionHeaderIndex: 3,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  GENERAL_EMAIL_CLASSIFICATION = new SectionHeader({
    title: 'General Email Classification',
    subtitle: 'Classify and attach incoming emails to claims',
    description: '',
    allowedVerbs: NONE_READ_WRITE,
    sectionHeaderIndex: 4,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  REPORT = new SectionHeader({
    title: 'View reports and dashboards',
    subtitle: 'View reports and dashboards',
    description: '',
    allowedVerbs: NONE_READ_WRITE,
    sectionHeaderIndex: 5,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  ADJUSTER_DASHBOARD = new InnerSectionItem({
    title: 'View Adjuster Suite',
    subtitle: 'View the reports and dashboards adjuster suite',
    description: '',
    allowedVerbs: NONE_READ,
    section: this.REPORT,
    innerSection: this.REPORT.innerSections.DEFAULT,
    index: 0,
    preconditions: [
      {
        field: this.REPORT,
        needsToBeAtLeast: __PERMISSION_VERBS__.READ,
        whenVerbIsEqualOrGreaterThan: __PERMISSION_VERBS__.READ,
        errorMessage:
          'If the Adjuster Dashboard permission is set to Read, the Report and Dashboards permission must also be Read',
      },
    ],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  MANAGEMENT_REPORTS_AND_DASHBOARDS = new InnerSectionItem({
    title: 'View Management Suite',
    subtitle: 'View the reports and dashboard management suite',
    description: '',
    allowedVerbs: NONE_READ,
    section: this.REPORT,
    innerSection: this.REPORT.innerSections.DEFAULT,
    index: 1,
    preconditions: [
      {
        field: this.REPORT,
        needsToBeAtLeast: __PERMISSION_VERBS__.READ,
        whenVerbIsEqualOrGreaterThan: __PERMISSION_VERBS__.READ,
        errorMessage:
          'If the Management Reports and Dashboards permission is set to Read, the Report and Dashboards permission must also be Read',
      },
    ],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  VIEW_GENERAL_REPORTS_SUITE = new InnerSectionItem({
    title: 'View General Suite',
    subtitle: 'View general reports and dashboards',
    description: '',
    allowedVerbs: NONE_READ,
    section: this.REPORT,
    innerSection: this.REPORT.innerSections.DEFAULT,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CREATE_REPORTS_AND_DASHBOARDS = new InnerSectionItem({
    title: 'Create Reports and Dashboards',
    subtitle: 'Create reports and dashboards and share them with your organization',
    description: '',
    allowedVerbs: NONE_WRITE,
    section: this.REPORT,
    innerSection: this.REPORT.innerSections.DEFAULT,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  SYSTEM_CONFIGURATION = new SectionHeader({
    title: 'System configurations',
    subtitle: ' View and edit system configurations',
    description: '',
    allowedVerbs: [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.FULL],
    sectionHeaderIndex: 6,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FULL,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FULL,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });

  constructor() {
    super();
    this._setKeysOnChildren();
    Object.values(this).forEach((value) => {
      if (value instanceof SectionHeader && value.preconditions) {
        value.preconditions.forEach((precondition) => {
          if (typeof precondition.field === 'string') {
            precondition.field = this[precondition.field];
          }
        });
      }
    });
  }
}

const __PERMISSION_ACTIONS__ = new PERMISSION_ACTIONS();

export { __PERMISSION_ACTIONS__ };
