import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { getMonthNames } from '../Utils';

function useYearMonthFilter(filterOnlyByMonth) {
  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();

  const [monthYearFilter, setMonthYearFilter] = React.useState({
    filterByMonth: true || filterOnlyByMonth, // filterOnlyByMonth - not to forget
    yearFilter: currentYear,
    monthFilter: currentMonthIndex,
  });

  const { filterByMonth, yearFilter, monthFilter } = monthYearFilter;

  const yearFilterMonthFilterProps = {
    yearValueInit: currentYear,
    monthValueInit: currentMonthIndex,
    onUpdate: setMonthYearFilter,
    filterByMonthInit: true,
    filterOnlyByMonth,
  };

  return { filterByMonth, yearFilter, monthFilter, yearFilterMonthFilterProps, YearMonthFilter };
}

function YearMonthFilter({ yearValueInit, monthValueInit, filterByMonthInit, onUpdate, filterOnlyByMonth }) {
  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();

  const [filterByMonth, setFilterByMonth] = useState(filterByMonthInit);
  const [yearFilter, setYearFilter] = useState(yearValueInit);
  const [monthFilter, setMonthFilter] = useState(monthValueInit);
  const [monthsToFilterBy, setMonthsToFilterBy] = useState([]);

  const monthNamesArray = getMonthNames();
  const validYearsToFilter = Array(currentYear - 2020 + 1)
    .fill()
    .map((_, idx) => 2020 + idx);

  useEffect(() => {
    if (!filterByMonth) {
      setMonthsToFilterBy([]);
      return;
    }

    if (yearFilter === currentYear) {
      setMonthsToFilterBy(
        Array(currentMonthIndex + 1)
          .fill()
          .map((_, idx) => idx)
      );
      return;
    }

    setMonthsToFilterBy(
      Array(12)
        .fill()
        .map((_, idx) => idx)
    );
  }, [currentMonthIndex, filterByMonth, yearFilter, currentYear, setMonthsToFilterBy]);

  useEffect(() => {
    onUpdate({
      filterByMonth,
      yearFilter,
      monthFilter,
    });
  }, [onUpdate, filterByMonth, yearFilter, monthFilter]);

  return (
    <Grid container spacing={1}>
      <Grid item>
        <TextField
          label="Filter By"
          select
          value={filterByMonth}
          onChange={(e) => setFilterByMonth(e.target.value)}
          style={{ width: 200 }}
          disabled={filterOnlyByMonth}
        >
          <MenuItem value={true}>Month</MenuItem>
          <MenuItem value={false}>Year</MenuItem>
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          label="Year"
          select
          value={yearFilter}
          onChange={(e) => setYearFilter(e.target.value)}
          style={{ width: 200 }}
        >
          {validYearsToFilter.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {filterByMonth && (
        <Grid item>
          <TextField
            label="Month"
            select
            value={monthFilter <= monthsToFilterBy.length ? monthFilter : ''}
            onChange={(e) => setMonthFilter(e.target.value)}
            style={{ width: 200 }}
          >
            {monthsToFilterBy.map((monthIndex) => (
              <MenuItem key={monthIndex} value={monthIndex}>
                {monthNamesArray[monthIndex]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </Grid>
  );
}

YearMonthFilter.propTypes = {
  yearValueInit: PropTypes.number.isRequired,
  monthValueInit: PropTypes.number.isRequired,
  filterByMonthInit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  filterOnlyByMonth: PropTypes.bool,
};

export default useYearMonthFilter;
