import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '../core';

const DASHBOARDS_PERMISSIONS_MAP = {
  AdjusterDashboard: {
    action: PERMISSION_ACTIONS.ADJUSTER_DASHBOARD,
    verb: PERMISSION_VERBS.READ,
  },
  Operational_Dashboard: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  Financial_Trends: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  SupervisorDashboard: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  MultiState: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  SMS: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },

  // Coterie
  CAT: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  ClosingRatio: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  FrequencyVsSeverity: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },

  // for the DEMO_US/UK configuration
  AdjusterView: {
    action: PERMISSION_ACTIONS.ADJUSTER_DASHBOARD,
    verb: PERMISSION_VERBS.READ,
  },
  SupervisorView: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  MultiStates: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
};

const REPORTS_PERMISSIONS_MAP = {
  Claims: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  Exposures: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  Transactions: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },

  // Coterie
  ProgramFinancialSummary: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  DeniedExposuresOnClaims: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
  CauseOfLoss: {
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  },
};

export const REPORTS_DASHBOARDS_PERMISSIONS_MAP = {
  ...DASHBOARDS_PERMISSIONS_MAP,
  ...REPORTS_PERMISSIONS_MAP,
};
