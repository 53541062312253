import React, { useState } from 'react';
import axios from 'axios';
import { noop } from 'lodash';

import colors from '~/assets/colors.module.scss';
import { AttachOtherContacts } from '~/components/communications/AttachCommunicationContainer';
import Button from '~/components/core/Atomic/Buttons/Button';
import { ContactIcon } from '~/components/icons';
import { reportAxiosError } from '~/Utils';

export const handleAttachContactToCommunication = ({
  communicationId,
  onAttachToContact,
  setShowAttachToContactDialog,
}: {
  communicationId: number;
  onAttachToContact: () => void;
  setShowAttachToContactDialog: React.Dispatch<React.SetStateAction<boolean>>;
}): (({
  contact_id,
  contact_attachment_choice,
}: {
  contact_id: number;
  contact_attachment_choice: string;
}) => Promise<void>) => {
  return async ({ contact_id, contact_attachment_choice }) => {
    try {
      await axios.post(`/api/v1/communications/${communicationId}/attach_contact`, {
        contact_id,
        should_create_new_contact: contact_attachment_choice === 'new_contact',
      });
      await onAttachToContact();
      setShowAttachToContactDialog(false);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };
};

interface AttachToContactSectionProps {
  communicationId: number;
  communicationClaimId?: number;
  onAttachToContact?: () => void;
  communicationChannel: string;
}

const AttachToContactSection: React.FC<AttachToContactSectionProps> = ({
  communicationId,
  communicationClaimId,
  communicationChannel,
  onAttachToContact = noop,
}) => {
  const [showAttachToContactDialog, setShowAttachToContactDialog] = useState(false);

  if (!communicationClaimId) {
    return null;
  }

  return (
    <>
      <Button
        className="flex items-end p-0 capitalize underline hover:underline"
        variant="text"
        color="primary"
        onClick={() => setShowAttachToContactDialog(true)}
      >
        <div className="flex items-center">
          <ContactIcon className="mr-4" iconColor={colors.buttonLink} />
          Attach to contact
        </div>
      </Button>
      {showAttachToContactDialog && (
        <AttachOtherContacts
          claimId={communicationClaimId}
          communicationId={communicationId}
          onClose={() => setShowAttachToContactDialog(false)}
          communicationChannel={communicationChannel}
          onSubmit={handleAttachContactToCommunication({
            communicationId,
            onAttachToContact,
            setShowAttachToContactDialog,
          })}
        />
      )}
    </>
  );
};

export default AttachToContactSection;
