import React from 'react';

import { LEGAL_ACTION_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';

import { getExposuresLabels } from '../../../Utils';
import { useClaim } from '../../ClaimContainer';
import { PropertyDetails } from '../../core';
import type { LegalActionApiResponse } from '../types';

export const ApplyToPropertyDetails: React.FC<{ legalAction: LegalActionApiResponse }> = ({ legalAction }) => {
  const { claim } = useClaim();
  const exposureLabels = getExposuresLabels(claim);

  const getExposureLabelById = (id: number) => {
    return exposureLabels.find((e: { id: number }) => e.id === id)?.label;
  };

  return (
    <PropertyDetails
      title={LEGAL_ACTION_FIELDS.isClaimLevel.label}
      text={legalAction.is_claim_level ? 'Claim Level' : legalAction.exposures.map((e) => getExposureLabelById(e.id))}
      useChips
    />
  );
};
