import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Paper } from '@material-ui/core';

import MenuItem from '~/components/core/Atomic/MenuItem';
import type { SuggestionOption } from '~/components/core/Editor/types';
import TextWithMDBold from '~/components/TextWithBold/TextWithMDBold';

interface MentionListProps {
  items: SuggestionOption[];
  command: (item: { id: string; label: string }) => void;
}

const MentionListRef: React.FC<MentionListProps> = forwardRef((props: MentionListProps, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const currentSelectedItem = props.items[selectedIndex];

  const selectItemByIndex = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.id, label: item.label });
    }
  };

  MentionListRef.displayName = 'MentionListRef';

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItemByIndex(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onKeyDown: ({ event }) => {
      if (event?.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Paper elevation={5}>
      {props.items.length ? (
        props.items.map((item: SuggestionOption, index: number) => (
          <MenuItem
            selected={currentSelectedItem === item}
            key={item.label}
            value={item.label}
            onClick={() => {
              selectItemByIndex(index);
            }}
          >
            <TextWithMDBold text={item.label} />
          </MenuItem>
        ))
      ) : (
        <MenuItem>No result</MenuItem>
      )}
    </Paper>
  );
});

export default MentionListRef;
