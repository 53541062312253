import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const ContactIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 3.75C9.10063 3.75 6.75013 6.1005 6.75013 9C6.75013 11.8995 9.10063 14.25 12.0001 14.25C14.8996 14.25 17.2501 11.8995 17.2501 9C17.2501 6.1005 14.8996 3.75 12.0001 3.75ZM5.25013 9C5.25013 5.27208 8.2722 2.25 12.0001 2.25C15.728 2.25 18.7501 5.27208 18.7501 9C18.7501 12.7279 15.728 15.75 12.0001 15.75C8.2722 15.75 5.25013 12.7279 5.25013 9Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 15.75C10.2885 15.75 8.60702 16.1998 7.12474 17.0556C5.64246 17.9115 4.41161 19.1425 3.55593 20.6249C3.34886 20.9837 2.89017 21.1066 2.53144 20.8996C2.1727 20.6925 2.04975 20.2338 2.25682 19.8751C3.24415 18.1646 4.66435 16.7442 6.37468 15.7566C8.085 14.7691 10.0252 14.25 12.0001 14.25C13.9751 14.25 15.9153 14.7691 17.6256 15.7566C19.3359 16.7442 20.7561 18.1646 21.7434 19.8751C21.9505 20.2338 21.8276 20.6925 21.4688 20.8996C21.1101 21.1066 20.6514 20.9837 20.4443 20.6249C19.5886 19.1425 18.3578 17.9115 16.8755 17.0556C15.3932 16.1998 13.7118 15.75 12.0001 15.75Z"
      fill={iconColor}
    />
  </SvgIcon>
);

ContactIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default ContactIcon;
