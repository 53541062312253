import React from 'react';

import SvgIcon from '../core/SvgIcon';

const GalleryIcon = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M16.875 3.75H3.125C2.77982 3.75 2.5 4.02982 2.5 4.375V15.625C2.5 15.9702 2.77982 16.25 3.125 16.25H16.875C17.2202 16.25 17.5 15.9702 17.5 15.625V4.375C17.5 4.02982 17.2202 3.75 16.875 3.75Z"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 13.125L6.42969 9.19531C6.48784 9.13626 6.55716 9.08936 6.63361 9.05735C6.71006 9.02534 6.79212 9.00886 6.875 9.00886C6.95788 9.00886 7.03994 9.02534 7.11639 9.05735C7.19284 9.08936 7.26216 9.13626 7.32031 9.19531L10.8047 12.6797C10.8628 12.7387 10.9322 12.7856 11.0086 12.8176C11.0851 12.8497 11.1671 12.8661 11.25 12.8661C11.3329 12.8661 11.4149 12.8497 11.4914 12.8176C11.5678 12.7856 11.6372 12.7387 11.6953 12.6797L13.3047 11.0703C13.3628 11.0113 13.4322 10.9644 13.5086 10.9324C13.5851 10.9003 13.6671 10.8839 13.75 10.8839C13.8329 10.8839 13.9149 10.9003 13.9914 10.9324C14.0678 10.9644 14.1372 11.0113 14.1953 11.0703L17.5 14.375"
      fill="none"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1875 8.75C12.7053 8.75 13.125 8.33027 13.125 7.8125C13.125 7.29473 12.7053 6.875 12.1875 6.875C11.6697 6.875 11.25 7.29473 11.25 7.8125C11.25 8.33027 11.6697 8.75 12.1875 8.75Z"
      stroke="none"
    />
  </SvgIcon>
);

export default GalleryIcon;
