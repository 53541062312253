import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import { SIZES } from './constants';
import FontComponent from './FontComponent';

const FontSizeComponent: React.FC = () => {
  const { editor } = useCurrentEditor();
  const setFontSize = (value: string) => {
    if (value) {
      editor?.chain().setFontSize(`${value}px`).focus().run();
    } else {
      editor?.chain().unsetFontSize().focus().run();
    }
  };

  return (
    <FontComponent
      options={SIZES.map((size) => ({ label: size, value: size }))}
      defaultOption="14"
      containerClassName="w-40"
      isActiveFunc={(value) => !!editor?.isActive('textStyle', { fontSize: `${value}px` })}
      isEnabledFunc={() => !!editor?.can().setFontSize(SIZES[0])}
      handleChange={setFontSize}
    />
  );
};

export default FontSizeComponent;
