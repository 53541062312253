import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { FieldArray, Formik, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import InnerCard from '~/components/core/Cards/InnerCard';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import EmptyState from '~/components/core/EmptyState';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import TitleWithColoredIndent from '~/components/core/IndentedSections/TitleWithColoredIndent';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { CUSTOM_FIELD_TYPES, DEFAULT_INCIDENT_DETAILS_FIELDS } from '../../Types';
import { isDevOrLocalOrUat, reportAxiosError } from '../../Utils';
import { UploadClaimJsonDialog } from '../Admin/TestActions';
import CardDialog from '../CardDialog';
import { openTopLevelDialog } from '../CmsMain/globals';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { useCms } from '../hooks/useCms';
import { DownloadIcon, EyeIcon, InfoIcon, RefreshIcon, TrashIcon_Deprecated, UploadIcon, ValidateIcon } from '../icons';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';
import TextFieldFormik, { ShowOnlyTextField } from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import ConfigurationSummary from './ConfigurationSummary';

import { useStyles } from '../../assets/styles';
import colors from '../../assets/colors.module.scss';

const CONFIGURATION_ID_REGEX = /^[a-zA-Z0-9_-]*$/;
const FIELD_ID_REGEX = /^[a-z][a-zA-Z0-9]{0,24}$/;
const FIELD_ID_RESTRICTION_ERROR = 'Must be camel case and only contain letters and numbers. Up to 25 characters long.';

const isSelect = (type) => type === 'select' || type === 'multiselect';

const CustomClaimConfigurations = () => {
  const [editConfigurationDialogOpen, setEditConfigurationDialogOpen] = useState(false);
  const [configurationToView, setConfigurationToView] = useState(null);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showUploadToValidateDialog, setShowUploadToValidateDialog] = useState(false);
  const {
    isLoading: isLoading,
    isError: isError,
    data: customConfigurations,
    reloadData: reloadConfigurations,
  } = useDataFetcher('/api/v1/custom_claims/configurations');
  const { setPageTitle } = useCms();
  const classes = useStyles();

  useEffect(
    () => setPageTitle('Custom Claim Configurations', 'Custom Claim Configurations - Five Sigma CMS'),
    [setPageTitle]
  );

  const onCancel = () => {
    setEditConfigurationDialogOpen(false);
    setConfigurationToView(null);
  };

  const viewConfiguration = (configuration) => {
    setConfigurationToView(configuration);
    setEditConfigurationDialogOpen(true);
  };

  const onSave = async (values) => {
    try {
      await axios.post('/api/v1/custom_claims/configurations', values);
      await reloadConfigurations();
      setEditConfigurationDialogOpen(false);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const onUploadClaimToValidate = async (formData, config) => {
    try {
      await axios.post('/api/v1/custom_claims/validate', formData, config);
      setShowUploadToValidateDialog(false);
      openTopLevelDialog({ title: 'Success', message: 'The claim JSON matches the schema' });
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const onUploadClaim = async (formData, config) => {
    try {
      const {
        data: { claim_id },
      } = await axios.post('/test/api/v1/upload_custom_claim_json', formData, config);
      setShowUploadDialog(false);
      window.open(`/claims/${claim_id}/summary`, '_self');
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const onRegenerateSchema = async (configuration) => {
    try {
      await axios.post(`/api/v1/custom_claims/configurations/${configuration.id}/schema/regenerate`);
      openTopLevelDialog({ title: 'Success', message: 'The schema was regenerated' });
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const onDownloadSchema = async (configuration) => {
    try {
      const { data: schema } = await axios.get(`/api/v1/custom_claims/configurations/${configuration.id}/schema`);
      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([JSON.stringify(schema, null, 2)], { type: 'text/plain' }));
      a.download = `${configuration.external_id}.json`;
      a.click();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const ActionsCell = (field) => {
    return (
      <>
        <Tooltip placement="top-end" arrow title="Regenerate Schema">
          <IconButton className={classes.tableActionsIcon} onClick={() => onRegenerateSchema(field)}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Tooltip placement="top-end" arrow title="View Configuration">
          <IconButton className={classes.tableActionsIcon} onClick={() => viewConfiguration(field)}>
            <EyeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip placement="top-end" arrow title="Download Schema">
          <IconButton className={classes.tableActionsIcon} onClick={() => onDownloadSchema(field)}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const columnData = [
    { id: 'name', label: 'Name' },
    { id: 'external_id', label: 'External ID' },
    { id: 'actions', label: '', specialCell: ActionsCell, align: 'right' },
  ];

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <>
      <CardDialog noCardTitle>
        {customConfigurations.length > 0 && (
          <div className={classes.buttonsContainer}>
            {isDevOrLocalOrUat() && customConfigurations.length > 0 && (
              <div
                style={{ background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setShowUploadToValidateDialog(true)}
                  style={{ marginRight: '20px' }}
                >
                  <ValidateIcon iconColor="#1A9C9E" size="24" />
                  <span style={{ marginLeft: '8px' }}>Validate Claim Json</span>
                </Button>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setShowUploadDialog(true)}
                  style={{ marginRight: '20px' }}
                >
                  <UploadIcon iconColor={colors.buttonLink} size={16} />
                  <span style={{ marginLeft: '8px' }}>Upload Custom Claim JSON</span>
                </Button>
              </div>
            )}
            <Button
              className={`${classes.actionButton} ${classes.button}`}
              variant="contained"
              color="primary"
              onClick={() => setEditConfigurationDialogOpen(true)}
            >
              <AddIcon className={classes.leftButtonIcon} />
              Add Configuration
            </Button>
          </div>
        )}
        <PlainTable
          columns={columnData}
          rows={customConfigurations}
          maxHeight={`${window.innerHeight - 200}px`}
          emptyStateComponent={
            <EmptyState
              subtitle="No custom claims configuration yet"
              buttonComponent={
                <Button variant="contained" color="primary" onClick={() => setEditConfigurationDialogOpen(true)}>
                  <span style={{ display: 'flex' }}>
                    <AddIcon className={classes.leftButtonIcon} />
                    ADD CONFIGURATION
                  </span>
                </Button>
              }
            />
          }
        />
      </CardDialog>
      {editConfigurationDialogOpen && (
        <EditConfigurationDialog
          onDownloadSchema={onDownloadSchema}
          showOnly={!!configurationToView}
          configuration={configurationToView}
          existingExternalIds={customConfigurations.map((config) => config.external_id)}
          onSave={onSave}
          onCancel={onCancel}
        />
      )}
      <UploadClaimJsonDialog
        open={showUploadDialog}
        onCancel={() => setShowUploadDialog(false)}
        onUpload={onUploadClaim}
      />
      <UploadClaimJsonDialog
        open={showUploadToValidateDialog}
        onCancel={() => setShowUploadToValidateDialog(false)}
        onUpload={onUploadClaimToValidate}
      />
    </>
  );
};

const getTypesValidations = () => ({
  desc: Yup.string().required('Required'),
  id: Yup.string().matches(FIELD_ID_REGEX, FIELD_ID_RESTRICTION_ERROR).required('Required'),
  subtypes: Yup.array()
    .required('Required')
    .min(1, 'At least one subtype is required')
    .of(
      Yup.object()
        .shape({
          desc: Yup.string().required('Required'),
          id: Yup.string().matches(FIELD_ID_REGEX, FIELD_ID_RESTRICTION_ERROR).required('Required'),
        })
        .uniqueInParent('id', 'Subtype id must be unique')
    ),
});

const getAdditionalDataValidations = () => ({
  id: Yup.string().required('Required').matches(FIELD_ID_REGEX, FIELD_ID_RESTRICTION_ERROR),
  desc: Yup.string().required('Required'),
  type: Yup.string().required('Required').oneOf(Object.keys(CUSTOM_FIELD_TYPES), 'Must be a valid type'),
  mandatory: Yup.boolean().required('Required'),
  options: Yup.mixed().when('type', {
    is: isSelect,
    then: Yup.array()
      .of(
        Yup.object()
          .shape({
            id: Yup.string().required('Required').matches(FIELD_ID_REGEX, FIELD_ID_RESTRICTION_ERROR),
            desc: Yup.string().required('Required'),
          })
          .uniqueInParent('id', 'Option id must be unique')
      )
      .required('Required')
      .min(1, 'At least one option is required'),
    otherwise: Yup.mixed().strip(),
  }),
});

const getIncidentDetailsValidations = () => ({
  types: Yup.array().of(Yup.object().shape(getTypesValidations()).uniqueInParent('id', 'Type id must be unique')),
  additional_data: Yup.array().of(
    Yup.object().shape(getAdditionalDataValidations()).uniqueInParent('id', 'Additional field id must be unique')
  ),
});

const getInvolvedPeopleValidations = (organizationContactRolesDict) => ({
  id: Yup.string().required('Required').matches(FIELD_ID_REGEX, FIELD_ID_RESTRICTION_ERROR),
  desc: Yup.string().required('Required'),
  contact_role: Yup.string()
    .required('Required')
    .oneOf(Object.keys(organizationContactRolesDict), 'Must be a valid contact role'),
  is_first_party: Yup.boolean().required('Required'),
  mandatory: Yup.boolean().required('Required'),
  additional_data: Yup.array()
    .of(Yup.object().shape(getAdditionalDataValidations()).uniqueInParent('id', 'Additional field id must be unique'))
    .required('Required'),
});

const getInvolvedPropertiesValidations = (organizationContactRolesDict) => ({
  id: Yup.string().required('Required').matches(FIELD_ID_REGEX, FIELD_ID_RESTRICTION_ERROR),
  desc: Yup.string().required('Required'),
  contact_role: Yup.string()
    .required('Required')
    .oneOf(Object.keys(organizationContactRolesDict), 'Must be a valid contact role'),
  is_first_party: Yup.boolean().required('Required'),
  mandatory: Yup.boolean().required('Required'),
  additional_data: Yup.array()
    .of(Yup.object().shape(getAdditionalDataValidations()).uniqueInParent('id', 'Additional field id must be unique'))
    .required('Required'),
});

const getInvolvedPartiesValidations = (organizationContactRolesDict) => ({
  people: Yup.object()
    .shape(getInvolvedPeopleValidations(organizationContactRolesDict))
    .uniqueInParent('id', 'Involved person id must be unique')
    .nullable(),
  properties: Yup.object()
    .shape(getInvolvedPropertiesValidations(organizationContactRolesDict))
    .uniqueInParent('id', 'Involved property id must be unique')
    .nullable(),
});

const EditConfigurationDialog = ({
  configuration,
  showOnly,
  existingExternalIds,
  onSave,
  onCancel,
  onDownloadSchema,
}) => {
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <Formik
      initialValues={{
        name: configuration?.name || '',
        external_id: configuration?.external_id || '',
        configuration: configuration?.configuration || {
          incident_details: { types: [], additional_data: [] },
          involved_parties: { people: [], properties: [] },
        },
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        external_id: Yup.string()
          .required('Required')
          .notOneOf(existingExternalIds, 'Must be unique')
          .matches(
            CONFIGURATION_ID_REGEX,
            'Can only contain letters, numbers and the following special characters: _-'
          ),
        configuration: Yup.object().shape({
          incident_details: Yup.object().shape(getIncidentDetailsValidations()),
          involved_parties: Yup.object().shape(getInvolvedPartiesValidations(organizationContactRolesDict)),
        }),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onSave(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title={showOnly ? 'Configuration' : 'Add Configuration'}
            maxWidth="lg"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <TextFieldFormik
                  id="name"
                  label="Configuration Name"
                  showOnly={showOnly}
                  disabled={isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextFieldFormik
                  id="external_id"
                  label="External ID"
                  showOnly={showOnly}
                  disabled={isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={10} container alignContent="flex-start" className={classes.fixedSizeConfigurationBox}>
                  <Grid item xs={12}>
                    <CollapsibleWrapper
                      title="Incident Details"
                      subtitle="Default Fields, Types and Subtypes and Additional Fields"
                      defaultState
                    >
                      <InnerCard
                        title="Default Fields"
                        tooltipContent="These fields are included in the incident details of any new configuration"
                      >
                        <Grid container spacing={showOnly ? 1 : 3}>
                          {DEFAULT_INCIDENT_DETAILS_FIELDS.map((rowFields, rowIdx) => {
                            return (
                              <React.Fragment key={rowIdx}>
                                {rowFields.map((fieldName) => {
                                  return <DefaultFieldDisplay key={fieldName} fieldName={fieldName} />;
                                })}
                                <Grid item xs={rowFields.length === 2 ? 6 : 4} />
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      </InnerCard>
                      <InnerCard
                        title="Types And Subtypes"
                        tooltipContent="Incident types and subtypes can be fields to the configuration"
                      >
                        <TypesAndSubtypes showOnly={showOnly} />
                      </InnerCard>
                      <InnerCard
                        title="Additional Fields"
                        tooltipContent="Add extra incident details to the configuration"
                      >
                        <AdditionalDataFields showOnly={showOnly} />
                      </InnerCard>
                    </CollapsibleWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <CollapsibleWrapper
                      title="Involved Parties"
                      subtitle="Involved Persons and Involved Properties"
                      defaultState
                    >
                      <InnerCard
                        title="Involved Persons"
                        tooltipContent='Set the involved persons in the claim, e.g. - "Driver", "Injured person", etc.'
                      >
                        <InvolvedParties showOnly={showOnly} entityName="Involved Person" fieldName="people" />
                      </InnerCard>
                      <InnerCard
                        title="Involved Properties"
                        tooltipContent='Set the involved properties in the claim, e.g - "Vehicle", "Suitcase", etc.'
                      >
                        <InvolvedParties
                          showOnly={showOnly}
                          fieldName="properties"
                          entityName="Involved Property"
                          contactRoleTitle="Owner Contact Role"
                        />
                      </InnerCard>
                    </CollapsibleWrapper>
                  </Grid>
                </Grid>
                <Grid item xs={2} className={classes.fixedSizeConfigurationBox}>
                  <ConfigurationSummary />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.buttonsContainer}>
                    {showOnly ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onDownloadSchema(configuration)}
                        disabled={isSubmitting}
                      >
                        Download Schema
                      </Button>
                    ) : (
                      <>
                        <CancelButton disabled={isSubmitting} onClick={onCancel} />
                        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditConfigurationDialog.propTypes = {
  configuration: PropTypes.object,
  existingExternalIds: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDownloadSchema: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
};

const InvolvedParties = ({ showOnly, entityName, fieldName, contactRoleTitle }) => {
  return (
    <EditNestedFields
      showOnly={showOnly}
      fieldName={`configuration.involved_parties.${fieldName}`}
      entityName={entityName}
      initialData={{
        id: '',
        desc: '',
        contact_role: '',
        is_first_party: false,
        mandatory: false,
        additional_data: [],
      }}
      withMandatory
      withFirstParty
      withContactRole
      contactRoleTitle={contactRoleTitle}
      mainParent
    >
      <EditNestedFields
        showOnly={showOnly}
        fieldName="additional_data"
        entityName="Additional Field"
        tooltipOnAdd={`Add additional information to the ${entityName}.`}
        initialData={{
          id: '',
          desc: '',
          type: '',
          mandatory: false,
          options: [],
        }}
        withMandatory
        withType
        conditionForChild={(value) => value && isSelect(value.type)}
      >
        <EditNestedFields
          showOnly={showOnly}
          fieldName="options"
          entityName="Option"
          initialData={{ desc: '', id: '' }}
          requiredChild
        />
      </EditNestedFields>
    </EditNestedFields>
  );
};

InvolvedParties.propTypes = {
  showOnly: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  contactRoleTitle: PropTypes.string,
};

const AdditionalDataFields = ({ showOnly }) => {
  return (
    <EditNestedFields
      showOnly={showOnly}
      fieldName="configuration.incident_details.additional_data"
      entityName="Additional Field"
      initialData={{
        id: '',
        desc: '',
        type: '',
        mandatory: false,
        options: [],
      }}
      withMandatory
      withType
      conditionForChild={(value) => value && isSelect(value.type)}
      mainParent
    >
      <EditNestedFields
        showOnly={showOnly}
        fieldName="options"
        entityName="Option"
        initialData={{ desc: '', id: '' }}
        requiredChild
      />
    </EditNestedFields>
  );
};

AdditionalDataFields.propTypes = {
  showOnly: PropTypes.bool,
};

const TypesAndSubtypes = ({ showOnly }) => {
  return (
    <EditNestedFields
      showOnly={showOnly}
      fieldName="configuration.incident_details.types"
      entityName="Type"
      initialData={{ desc: '', id: '', subtypes: [{ desc: '', id: '' }] }}
      mainParent
    >
      <EditNestedFields
        showOnly={showOnly}
        fieldName="subtypes"
        entityName="Subtype"
        initialData={{ desc: '', id: '' }}
        withEachObjectTitle
        requiredChild
      />
    </EditNestedFields>
  );
};

TypesAndSubtypes.propTypes = {
  showOnly: PropTypes.bool,
};

const EditNestedFields = ({
  showOnly,
  fieldName,
  entityName,
  initialData,
  children,
  parentFieldName,
  withType,
  withMandatory,
  conditionForChild,
  withEachObjectTitle,
  withFirstParty,
  withContactRole,
  contactRoleTitle,
  mainParent,
  tooltipOnAdd,
  requiredChild,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { organizationContactRolesDict } = useOrganization();

  const { values, isSubmitting, setFieldValue } = useFormikContext();

  const getFieldName = () => (parentFieldName ? `${parentFieldName}.${fieldName}` : fieldName);
  const getFieldIdPrefix = (idx) => `${getFieldName()}.${idx}`;
  const isParent = !!children;
  const isFirstChild = !mainParent && getIn(values, getFieldName()).length === 0;
  const elementsInLine =
    [withFirstParty, withContactRole, withMandatory, withType, !isParent].filter((a) => !!a).length + 2;
  const elementSize = showOnly ? 2 : Math.min(Math.floor(12 / elementsInLine), 3);

  const getTitle = (entityTitle, idx) => {
    if (mainParent) {
      return <TitleWithColoredIndent title={entityTitle} colorPalette="primary" colorType="darker" />;
    }
    let titleContent;
    if (withEachObjectTitle) {
      titleContent = entityTitle;
    } else if (idx === 0) {
      titleContent = `${entityName}s`;

      if (entityName === 'Option') {
        const type = getIn(values, `${parentFieldName}.type`);
        if (['select', 'multiselect'].includes(type)) {
          titleContent = `${CUSTOM_FIELD_TYPES[type].desc} ${titleContent}`;
        }
      }
    }

    return (
      titleContent && (
        <Typography
          display="block"
          style={{ marginRight: '10px', fontWeight: 550, letterSpacing: '0px', fontSize: '12px' }}
        >
          {titleContent}
        </Typography>
      )
    );
  };

  return (
    <FieldArray
      name={getFieldName()}
      render={({ remove, push }) => (
        <>
          {getIn(values, getFieldName()).map((_, idx, self) => {
            const entityTitle = `${entityName} ${idx + 1}`;
            const title = getTitle(entityTitle, idx);

            const onTypeChanged = (e) => {
              const type = e.target.value;
              const typeFieldId = `${getFieldIdPrefix(idx)}.type`;

              if (!isSelect(type)) {
                setFieldValue(`${getFieldIdPrefix(idx)}.options`, []);
              } else {
                setFieldValue(`${getFieldIdPrefix(idx)}.options`, [{ desc: '', id: '' }]);
              }
              setFieldValue(typeFieldId, type);
            };

            const shouldRenderChild =
              isParent && (conditionForChild ? conditionForChild(getIn(values, getFieldIdPrefix(idx))) : true);

            return (
              <React.Fragment key={idx}>
                <Grid container spacing={showOnly ? 1 : 2} alignItems="flex-start" style={{ marginBottom: '15px' }}>
                  {title && (
                    <Grid item xs={12}>
                      {title}
                      <ErrorHelperTextFormik id={`${getFieldIdPrefix(idx)}`} withoutChildren />
                    </Grid>
                  )}
                  <Grid item xs={elementSize} style={{ padding: '2px 9px' }}>
                    <TextFieldFormik
                      id={`${getFieldIdPrefix(idx)}.desc`}
                      label="Name"
                      showOnly={showOnly}
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={elementSize} style={{ padding: '2px 9px' }}>
                    <TextFieldFormik
                      id={`${getFieldIdPrefix(idx)}.id`}
                      label="ID"
                      showOnly={showOnly}
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  {withFirstParty && (
                    <Grid
                      item
                      xs={showOnly ? elementSize : undefined}
                      style={{ padding: showOnly ? '2px 9px' : '2px 9px 2px 0px' }}
                      className={showOnly ? '' : classes.customClaimsSwitch}
                    >
                      <SwitchFormik
                        className={classes.formsSwitch}
                        id={`${getFieldIdPrefix(idx)}.is_first_party`}
                        label="Is First Party"
                        showOnly={showOnly}
                        withShowOnlyText
                      />
                    </Grid>
                  )}
                  {withMandatory && (
                    <Grid
                      item
                      xs={showOnly ? elementSize : undefined}
                      style={{ padding: showOnly ? '2px 9px' : '2px 9px 2px 0px' }}
                      className={showOnly ? '' : classes.customClaimsSwitch}
                    >
                      <SwitchFormik
                        className={classes.formsSwitch}
                        id={`${getFieldIdPrefix(idx)}.mandatory`}
                        label="Required"
                        showOnly={showOnly}
                        withShowOnlyText
                      />
                    </Grid>
                  )}
                  {withContactRole && (
                    <Grid item xs={elementSize} style={{ padding: '2px 9px' }}>
                      <TextFieldFormik
                        id={`${getFieldIdPrefix(idx)}.contact_role`}
                        label={contactRoleTitle || 'Contact Role'}
                        select
                        showOnly={showOnly}
                        disabled={isSubmitting}
                        fullWidth
                      >
                        {Object.keys(organizationContactRolesDict).map((contact_role_key) => (
                          <MenuItem key={contact_role_key} value={contact_role_key}>
                            {organizationContactRolesDict[contact_role_key].desc}
                          </MenuItem>
                        ))}
                      </TextFieldFormik>
                    </Grid>
                  )}
                  {withType && (
                    <Grid item xs={elementSize} style={{ padding: '2px 9px' }}>
                      <TextFieldFormik
                        id={`${getFieldIdPrefix(idx)}.type`}
                        label="Type"
                        select
                        showOnly={showOnly}
                        disabled={isSubmitting}
                        onChange={onTypeChanged}
                        fullWidth
                      >
                        {Object.keys(CUSTOM_FIELD_TYPES).map((option) => (
                          <MenuItem key={option} value={option}>
                            {CUSTOM_FIELD_TYPES[option].desc}
                          </MenuItem>
                        ))}
                      </TextFieldFormik>
                    </Grid>
                  )}
                  {!showOnly && !(requiredChild && idx === 0) && (
                    <Grid item xs container style={{ padding: '2px 9px 2px 0px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0px' }}>
                        <IconButton size="small" onClick={() => remove(idx)}>
                          <TrashIcon_Deprecated iconColor={theme.palette.primary.darker} size="24" />
                        </IconButton>
                      </div>
                    </Grid>
                  )}
                </Grid>
                {shouldRenderChild &&
                  (withType ? (
                    <RenderOptionsChild
                      showOnly={showOnly}
                      getFieldIdPrefix={getFieldIdPrefix}
                      idx={idx}
                      elementSize={elementSize}
                    >
                      {children}
                    </RenderOptionsChild>
                  ) : (
                    <RenderNonOptionsChild showOnly={showOnly} getFieldIdPrefix={getFieldIdPrefix} idx={idx}>
                      {children}
                    </RenderNonOptionsChild>
                  ))}
                {!showOnly && !mainParent && idx + 1 === self.length && (
                  <Grid item xs={12} container style={{ padding: '2px 9px 2px 0px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-20px', marginBottom: '10px' }}>
                      <Button color="primary" startIcon={<AddIcon />} onClick={() => push(initialData)}>
                        <span>{`Add ${entityName}`}</span>
                      </Button>
                    </div>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
          <ErrorHelperTextFormik id={`${getFieldName()}`} withoutChildren />
          {!showOnly && isFirstChild && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => push(initialData)}
              style={{ display: 'flex' }}
            >
              <span style={{ marginRight: '5px' }}>{`Add ${entityName}`}</span>
              <Tooltip placement="top-start" arrow title={tooltipOnAdd}>
                <span style={{ display: 'flex' }}>
                  <InfoIcon size={20} />
                </span>
              </Tooltip>
            </Button>
          )}
          {!showOnly && mainParent && (
            <Button
              className={`${classes.actionButton} ${classes.button}`}
              variant="contained"
              color="primary"
              onClick={() => push(initialData)}
            >
              <AddIcon />
              <span>{`Add ${entityName}`}</span>
            </Button>
          )}
        </>
      )}
    />
  );
};

EditNestedFields.propTypes = {
  showOnly: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  initialData: PropTypes.object.isRequired,
  children: PropTypes.node,
  parentFieldName: PropTypes.string,
  withType: PropTypes.bool,
  withMandatory: PropTypes.bool,
  conditionForChild: PropTypes.func,
  withEachObjectTitle: PropTypes.bool,
  withFirstParty: PropTypes.bool,
  withContactRole: PropTypes.bool,
  contactRoleTitle: PropTypes.string,
  mainParent: PropTypes.bool,
  tooltipOnAdd: PropTypes.string,
  requiredChild: PropTypes.bool,
};

const RenderOptionsChild = ({ showOnly, children, getFieldIdPrefix, idx, elementSize }) => {
  const marginSize = elementSize * 2 + 1;

  return (
    <Grid item xs={12} container>
      {!showOnly && <Grid item xs={marginSize} />}
      <Grid item xs style={{ marginLeft: '60px', marginTop: '-20px' }}>
        {React.cloneElement(children, { parentFieldName: getFieldIdPrefix(idx) })}
      </Grid>
    </Grid>
  );
};

RenderOptionsChild.propTypes = {
  showOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
  getFieldIdPrefix: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  elementSize: PropTypes.number.isRequired,
};

const RenderNonOptionsChild = ({ children, getFieldIdPrefix, idx }) => {
  return (
    <Grid item xs={12} container>
      <Grid item xs={12} style={{ marginLeft: '50px' }}>
        {React.cloneElement(children, { parentFieldName: getFieldIdPrefix(idx) })}
      </Grid>
    </Grid>
  );
};

RenderNonOptionsChild.propTypes = {
  children: PropTypes.node.isRequired,
  getFieldIdPrefix: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
};

const DefaultFieldDisplay = ({ fieldName }) => {
  const classes = useStyles();
  return (
    <Grid item xs={3}>
      <ShowOnlyTextField classes={classes} label="Field Name" showOnlyValueComponent={fieldName} />
    </Grid>
  );
};

DefaultFieldDisplay.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default CustomClaimConfigurations;
