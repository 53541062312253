import React from 'react';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import { isEmpty, reduce } from 'lodash';

import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import type { ContactFullModel } from '~/components/Contacts/types';
import UpsertContactFormik from '~/components/Contacts/UpsertContact/UpsertContactFormik';
import { useContactTabs } from '~/components/Contacts/UpsertContact/useContactTabs';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import { useCms } from '~/components/hooks/useCms';

import type { InitialValuesType, SpecialRoleBehaviorType } from '../types';
import { ContactContextProvider } from '../useContact';

interface CreateContactDialogInnerPropsType {
  onClose: () => void;
  title?: string;
}

interface CreateContactPropsType extends CreateContactDialogInnerPropsType {
  onContactCreate: (contact: ContactFullModel) => Promise<void>;
  initialValues?: InitialValuesType;
}

interface CreateContactWrapperPropsType extends CreateContactPropsType, SpecialRoleBehaviorType {}

const CreateContactDialogInner: React.FC<CreateContactDialogInnerPropsType> = ({
  onClose,
  title = 'Create Contact',
}) => {
  const { isSubmitting, handleSubmit, errors, touched } = useFormikContext();
  const { filteredTabs } = useContactTabs();

  const tabHasErrors = (tabKey: string) =>
    !isEmpty(getIn(errors, tabKey)) &&
    getIn(touched, tabKey) &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(errors[tabKey]).some((i) => Object.keys(touched[tabKey]).includes(i));

  const tabs = filteredTabs.map((tab) => ({
    hasErrors: tabHasErrors(tab.tabKey),
    label: tab.label,
    component: tab.tabComponent,
  }));

  return (
    <CardDialog isDialog title={title} onClose={onClose} maxWidth="md" fullWidth>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <div className="my-20 max-h-[600px] overflow-scroll">
        {tabs.map((tab) => {
          const Component = tab.component;
          return (
            <CollapsibleWrapper
              key={tab.label}
              title={tab.label}
              actionCard
              noDivider
              defaultState
              backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
              titleColor={
                tab.hasErrors ? CollapsibleWrapper.TITLE_COLOR.error : CollapsibleWrapper.TITLE_COLOR.textPrimary
              }
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Component />
            </CollapsibleWrapper>
          );
        })}
      </div>
      <div className="mt-px flex w-full justify-end">
        <CancelButton disabled={isSubmitting} onClick={onClose} />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
          Create
        </Button>
      </div>
    </CardDialog>
  );
};

const CreateContactFormik: React.FC<CreateContactPropsType> = ({ onClose, onContactCreate, title, initialValues }) => {
  const { claim } = useClaim();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const { allTabs } = useContactTabs();

  const handlePreparePayload = (payload: Record<string, unknown>) => {
    return reduce(
      allTabs,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (result, tab) => {
        if (payload[tab.tabKey]) {
          const tabPayload = payload[tab.tabKey] as Record<string, unknown>;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete result[tab.tabKey];
          return { ...result, ...tabPayload };
        }
        return result;
      },
      { ...payload, organization_id: userOrganization.id }
    );
  };

  const handleSubmit = async (payload: Record<string, unknown>) => {
    const response = await axios.post(`/api/v1/contacts`, payload);

    if (claim) {
      await axios.post(`/api/v1/claims/${claim.id}/contacts`, {
        contact_id: response.data.id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        exposure_ids: payload['exposure_ids'],
      });
    }

    await onContactCreate(response.data);
  };

  return (
    <UpsertContactFormik
      onClose={onClose}
      onSubmit={handleSubmit}
      onPreparePayload={handlePreparePayload}
      initialValues={initialValues}
      dialogInnerComponent={<CreateContactDialogInner onClose={onClose} title={title} />}
    />
  );
};

const CreateContactDialog: React.FC<CreateContactWrapperPropsType> = ({
  acceptedRoles,
  editingRoleDisabled,
  ...restProps
}) => {
  return (
    <ContactContextProvider specialRoleBehavior={{ acceptedRoles, editingRoleDisabled }}>
      <CreateContactFormik {...restProps} />
    </ContactContextProvider>
  );
};

export { CreateContactDialog };
