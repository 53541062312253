const BASE_PATH = `/api/v1/generic_templates`;

export const GENERIC_TEMPLATES_ROUTES = {
  GET_TEMPLATES: (organizationId: number): string => `${BASE_PATH}/organization/${organizationId}`,
  CREATE_TEMPLATE: (organizationId: number): string => `${BASE_PATH}/organization/${organizationId}`,
  GET_TEMPLATE: (organizationId: number, template_id: number): string =>
    `${BASE_PATH}/organization/${organizationId}/template/${template_id}`,
  GENERATE_FROM_TEMPLATE: ({
    organizationId,
    templateId,
    claimId,
    exposureId,
    recipientContactId,
    contactId,
    documentId,
    paymentRequestId,
  }: {
    organizationId: number;
    templateId: number;
    claimId: number;
    exposureId?: number;
    recipientContactId?: number | string;
    contactId?: number | string;
    documentId?: number | string;
    paymentRequestId?: number | string;
  }): string => {
    const queryParams = [];
    if (exposureId) queryParams.push(`exposure_id=${exposureId}`);
    if (recipientContactId) queryParams.push(`recipient_contact_id=${recipientContactId}`);
    if (contactId) queryParams.push(`contact_id=${contactId}`);
    if (documentId) queryParams.push(`document_id=${documentId}`);
    if (paymentRequestId) queryParams.push(`payment_request_id=${paymentRequestId}`);

    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    return `${BASE_PATH}/organization/${organizationId}/claim/${claimId}/template/${templateId}/generate_from_template${queryString}`;
  },
  UPDATE_TEMPLATE: (organizationId: number, templateId: number): string =>
    `${BASE_PATH}/organization/${organizationId}/template/${templateId}`,
  GENERATE_VIDEO_INVITATION: ({
    organizationId,
    claimId,
    communicationId,
  }: {
    organizationId: number;
    claimId: number;
    communicationId: number;
  }): string =>
    `${BASE_PATH}/organization/${organizationId}/claim/${claimId}/generate_from_video_template/${communicationId}`,
};
