import React from 'react';

import SvgIcon from '../core/SvgIcon';

const CsvIcon = (props) => (
  <SvgIcon fill="none" viewBox="0 0 22 22" {...props}>
    <path
      d="M16.125 15.75L18 20.25L19.875 15.75"
      stroke="#1A9C9E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 19.7344C7.14117 20.0624 6.67366 20.2461 6.1875 20.25C5.04375 20.25 4.125 19.2469 4.125 18C4.125 16.7531 5.04375 15.75 6.1875 15.75C6.67366 15.7539 7.14117 15.9376 7.5 16.2656"
      stroke="#1A9C9E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 19.875C10.9063 20.1781 11.3994 20.3424 11.9062 20.3438C12.75 20.3437 13.5 20.0625 13.5 19.125C13.5 17.625 10.5 18.2812 10.5 16.875C10.5 16.125 11.0625 15.6562 11.9062 15.6562C12.4131 15.6576 12.9062 15.8219 13.3125 16.125"
      stroke="#1A9C9E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 12V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H14.25L19.5 8.25V12"
      stroke="#1A9C9E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.25 3V8.25H19.5" stroke="#1A9C9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default CsvIcon;
