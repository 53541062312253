const BIKE_LOCK_ATTRIBUTE = {
  frame_locked_to_a_fixed_point: {
    desc: 'Frame Locked to a Fixed Point',
  },
  other_parts_locked_to_a_fixed_point: {
    desc: 'Other Parts Locked to a Fixed Point',
  },
  self_locked: {
    desc: 'Self locked',
  },
  unlocked: {
    desc: 'Unlocked',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default BIKE_LOCK_ATTRIBUTE;
