const AUTO_OPEN_FNOL_PARTIES_REQUIRED = {
  insured_party: {
    insured_party_type: 'vehicle',
    should_include_passengers: true,
  },
  third_party_vehicles: [],
  third_party_pedestrians: [],
  third_party_bicyclists: [],
  third_party_other_properties: [],
  allow_add_third_party_vehicles: true,
  allow_add_third_party_non_motorist: true,
  should_allow_delete_third_party_vehicles: true,
} as const;

export default AUTO_OPEN_FNOL_PARTIES_REQUIRED;
