import React from 'react';
import PropTypes from 'prop-types';

const AllDoneIllustration = ({ overrideStyle }) => (
  <svg width="240" height="198" viewBox="0 0 240 198" fill="none" xmlns="http://www.w3.org/2000/svg" {...overrideStyle}>
    <path
      d="M238.404 134.832C231.876 101.969 193.633 75.7041 186.174 41.4585C172.617 -2.11119 127.932 -8.99964 98.8951 9.92265C52.886 39.9053 101.968 47.3457 11.7593 118.283C-10.5153 135.798 -3.41759 213.117 66.657 195.373C104.368 185.824 115.832 184.484 132.795 182.911C175.55 178.948 249.362 189.997 238.404 134.832Z"
      fill="#EFFAFC"
    />
    <g filter="url(#filter0_d_243_4109)">
      <path
        d="M191.09 39.9053H47.5678C44.0432 39.9053 41.186 42.7613 41.186 46.2845V151.324C41.186 154.847 44.0432 157.703 47.5678 157.703H191.09C194.615 157.703 197.472 154.847 197.472 151.324V46.2845C197.472 42.7613 194.615 39.9053 191.09 39.9053Z"
        fill="white"
      />
      <path
        d="M197.472 46.3001V52.7347H41.186V46.3001C41.186 44.6041 41.8584 42.9775 43.0552 41.7783C44.252 40.579 45.8753 39.9053 47.5678 39.9053H191.09C192.783 39.9053 194.406 40.579 195.603 41.7783C196.8 42.9775 197.472 44.6041 197.472 46.3001Z"
        fill="#EDEDED"
      />
      <path
        d="M68.8691 97.3799C68.7855 97.38 68.7027 97.3636 68.6255 97.3316C68.5483 97.2996 68.4782 97.2526 68.4192 97.1935L65.4496 94.2239C65.3337 94.1039 65.2696 93.9432 65.271 93.7763C65.2725 93.6095 65.3394 93.4499 65.4574 93.3319C65.5754 93.2139 65.735 93.147 65.9018 93.1455C66.0686 93.1441 66.2294 93.2082 66.3494 93.3241L69.319 96.2937C69.4079 96.3827 69.4685 96.4961 69.4931 96.6195C69.5176 96.7429 69.505 96.8708 69.4568 96.9871C69.4087 97.1033 69.3272 97.2027 69.2225 97.2726C69.1179 97.3426 68.9949 97.3799 68.8691 97.3799V97.3799Z"
        fill="#0CBC8B"
      />
      <path
        d="M65.8994 97.3799C65.7735 97.3799 65.6505 97.3426 65.5459 97.2726C65.4413 97.2027 65.3598 97.1033 65.3116 96.9871C65.2635 96.8708 65.2509 96.7429 65.2754 96.6195C65.2999 96.4961 65.3605 96.3827 65.4495 96.2937L68.4191 93.3241C68.5391 93.2082 68.6998 93.1441 68.8667 93.1455C69.0335 93.147 69.1931 93.2139 69.3111 93.3319C69.4291 93.4499 69.496 93.6095 69.4974 93.7763C69.4989 93.9432 69.4347 94.1039 69.3188 94.2239L66.3493 97.1935C66.2903 97.2527 66.2202 97.2996 66.1429 97.3316C66.0657 97.3636 65.983 97.38 65.8994 97.3799V97.3799Z"
        fill="#0CBC8B"
      />
      <path d="M153.893 125.763L153.045 127.707L158.262 129.981L159.11 128.037L153.893 125.763Z" fill="#0CBC8B" />
      <path d="M155.54 73.6336L150.634 76.5166L151.708 78.3454L156.615 75.4623L155.54 73.6336Z" fill="#0CBC8B" />
      <path d="M73.7811 78.5711L73.124 80.5879L78.535 82.3509L79.1921 80.3342L73.7811 78.5711Z" fill="#0CBC8B" />
      <path d="M80.1158 112.397L75.4551 115.658L76.6705 117.395L81.3312 114.135L80.1158 112.397Z" fill="#0CBC8B" />
      <path d="M165.6 104.922L165.405 107.034L170.948 107.545L171.143 105.433L165.6 104.922Z" fill="#0CBC8B" />
      <path
        d="M171.245 89.7291C171.161 89.7292 171.079 89.7128 171.001 89.6808C170.924 89.6488 170.854 89.6018 170.795 89.5426L167.826 86.573C167.765 86.5143 167.716 86.4441 167.683 86.3665C167.65 86.2889 167.632 86.2054 167.631 86.1209C167.631 86.0364 167.647 85.9526 167.679 85.8744C167.711 85.7962 167.758 85.7251 167.818 85.6654C167.877 85.6056 167.949 85.5584 168.027 85.5264C168.105 85.4944 168.189 85.4783 168.273 85.479C168.358 85.4798 168.441 85.4973 168.519 85.5307C168.596 85.564 168.667 85.6125 168.725 85.6733L171.695 88.6428C171.784 88.7318 171.844 88.8452 171.869 88.9686C171.894 89.092 171.881 89.22 171.833 89.3362C171.785 89.4525 171.703 89.5518 171.599 89.6218C171.494 89.6917 171.371 89.729 171.245 89.7291V89.7291Z"
        fill="#0CBC8B"
      />
      <path
        d="M168.275 89.7291C168.15 89.729 168.027 89.6917 167.922 89.6218C167.817 89.5518 167.736 89.4525 167.688 89.3362C167.639 89.22 167.627 89.092 167.651 88.9686C167.676 88.8452 167.737 88.7318 167.825 88.6428L170.795 85.6733C170.854 85.6125 170.924 85.564 171.002 85.5307C171.079 85.4973 171.163 85.4798 171.247 85.479C171.332 85.4783 171.415 85.4944 171.494 85.5264C171.572 85.5584 171.643 85.6056 171.703 85.6654C171.762 85.7251 171.81 85.7962 171.842 85.8744C171.874 85.9526 171.89 86.0364 171.889 86.1209C171.888 86.2054 171.871 86.2889 171.837 86.3665C171.804 86.4441 171.756 86.5143 171.695 86.573L168.725 89.5426C168.666 89.6018 168.596 89.6487 168.519 89.6807C168.442 89.7127 168.359 89.7291 168.275 89.7291V89.7291Z"
        fill="#0CBC8B"
      />
      <path
        opacity="0.4"
        d="M119.662 135.216C138.342 135.216 153.485 120.072 153.485 101.392C153.485 82.7125 138.342 67.5693 119.662 67.5693C100.982 67.5693 85.8389 82.7125 85.8389 101.392C85.8389 120.072 100.982 135.216 119.662 135.216Z"
        fill="#0CBC8B"
      />
      <path
        d="M119.672 124.972C132.876 124.972 143.581 114.528 143.581 101.646C143.581 88.7629 132.876 78.3193 119.672 78.3193C106.467 78.3193 95.7622 88.7629 95.7622 101.646C95.7622 114.528 106.467 124.972 119.672 124.972Z"
        fill="#0CBC8B"
      />
      <path
        d="M114.423 111.378C114.241 111.378 114.061 111.339 113.893 111.263C113.726 111.188 113.575 111.077 113.449 110.939L106.072 102.825C105.947 102.691 105.848 102.531 105.782 102.356C105.716 102.181 105.683 101.994 105.685 101.805C105.688 101.616 105.726 101.43 105.797 101.257C105.868 101.084 105.971 100.927 106.1 100.797C106.229 100.666 106.381 100.563 106.547 100.495C106.714 100.427 106.891 100.394 107.07 100.399C107.248 100.404 107.424 100.446 107.587 100.523C107.75 100.6 107.897 100.711 108.019 100.848L114.492 107.967L131.415 91.9242C131.547 91.797 131.701 91.6986 131.869 91.6347C132.037 91.5708 132.215 91.5427 132.393 91.552C132.571 91.5613 132.746 91.6079 132.907 91.6889C133.068 91.77 133.212 91.884 133.331 92.0243C133.451 92.1646 133.542 92.3285 133.601 92.5065C133.66 92.6845 133.685 92.873 133.674 93.0612C133.664 93.2494 133.618 93.4336 133.54 93.603C133.462 93.7724 133.352 93.9238 133.218 94.0484L115.324 111.012C115.077 111.248 114.756 111.378 114.423 111.378Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_243_4109"
        x="31.186"
        y="33.9053"
        width="176.286"
        height="137.798"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_243_4109" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_243_4109" result="shape" />
      </filter>
    </defs>
  </svg>
);

AllDoneIllustration.propTypes = {
  overrideStyle: PropTypes.object,
};

export default AllDoneIllustration;
