import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const MinimizeIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={iconColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10.5C2.5 10.1548 2.77982 9.875 3.125 9.875H16.875C17.2202 9.875 17.5 10.1548 17.5 10.5C17.5 10.8452 17.2202 11.125 16.875 11.125H3.125C2.77982 11.125 2.5 10.8452 2.5 10.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);
MinimizeIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default MinimizeIcon;
