import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { serverDateToLocal } from '../../../../DateTimeUtils';
import { getLobDescription } from '../../../../Utils/lobUtils';
import ClaimLink from '../../../ClaimLink';
import { SortableTable } from '../../../core';
import useOrganization from '../../../OrganizationContext';

const ExposureQaTable = (props) => {
  const { subOrganizationId, claimType, entries, includeQaScore, includeQaStatus } = props;
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();

  const getSubOrganizationName = (subOrganizationId) => {
    if (!subOrganizationId || subOrganizationId === '') {
      return;
    }
    return find(subOrganizations, ({ id }) => id === subOrganizationId)?.name;
  };

  let columnData = [
    {
      id: 'claim_id_display_with_exposure_suffix',
      numeric: false,
      disablePadding: false,
      label: 'Exposure',
      // eslint-disable-next-line react/display-name
      specialCell: (entry) => (
        <ClaimLink
          claimId={entry.claim_id}
          linkText={entry.claim_id_display_with_exposure_suffix}
          openInNewTab
          extraLinkArgs={{ qa: entry.exposure_id }}
        />
      ),
    },
    {
      id: 'adjuster',
      label: 'Adjuster',
    },
    {
      id: 'sub_organization',
      label: 'Sub Organization',
      isHidden: !subOrganizationEnabled || !subOrganizationId,
      specialCell: () => getSubOrganizationName(subOrganizationId),
    },
    {
      id: 'lob',
      label: 'LOB',
      isHidden: !claimType,
      specialCell: () => getLobDescription(claimType, lobConfigurationsDict),
    },
    {
      id: 'incident_type_desc',
      numeric: false,
      label: 'Incident Type',
    },
    {
      id: 'incident_sub_type_desc',
      numeric: false,
      label: 'Incident Sub-type',
    },
    {
      id: 'coverage_type',
      numeric: false,
      label: 'Coverage Type',
      specialCell: (entry) => entry.coverage_type_desc,
    },
    {
      id: 'date_closed',
      numeric: false,
      label: 'Date Closed',
      nowrap: true,
      specialCell: (entry) => serverDateToLocal(entry.date_closed),
    },
  ];

  if (includeQaScore) {
    columnData = columnData.concat([
      { id: 'neg_num', numeric: true, label: 'No. of NOs', nowrap: true },
      { id: 'score', numeric: true, label: 'QA Score', nowrap: true },
    ]);
  }

  if (includeQaStatus) {
    columnData = columnData.concat([{ id: 'qa_status', label: 'QA Status', nowrap: true }]);
  }

  return <SortableTable rows={entries} columns={columnData} stickyHeader />;
};

ExposureQaTable.propTypes = {
  entries: PropTypes.array.isRequired,
  subOrganizationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  claimType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  includeQaScore: PropTypes.bool,
  includeQaStatus: PropTypes.bool,
};

export default ExposureQaTable;
