import type { ChangeEvent } from 'react';
import React from 'react';
import type { InputProps } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import type { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { default as MuiAutocomplete } from '@material-ui/lab/Autocomplete';
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@material-ui/lab/useAutocomplete/useAutocomplete';
import type { AutocompleteInputChangeReason } from '@mui/material';

export interface AutoCompleteProps<T = string> {
  id: string;
  label: string;
  options: T[];
  value: T;
  getOptionLabel: (option: T) => string;
  textFieldProps?: TextFieldProps;
  showOnly?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  sortAlphabetic?: boolean;
  onChange?: (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: ChangeEvent<{}>,
    value: T | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined
  ) => void;
  autoSelect?: boolean;
  autoComplete?: boolean;
  autoHighlight?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onInputChange?: (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void;
  className?: string;
  InputProps?: Partial<InputProps>;
}

const AutoComplete = <T,>({
  className,
  id,
  options,
  getOptionLabel,
  onChange,
  value,
  disabled,
  autoSelect,
  autoHighlight,
  autoComplete,
  onInputChange,
  label,
  fullWidth,
  textFieldProps,
  InputProps,
}: AutoCompleteProps<T>): JSX.Element => {
  return (
    <MuiAutocomplete
      className={className}
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      filterOptions={(_, value) =>
        options.filter((option) => getOptionLabel(option).toLowerCase().includes(value.inputValue.toLowerCase()))
      }
      value={value}
      disabled={disabled}
      autoSelect={autoSelect}
      autoHighlight={autoHighlight}
      autoComplete={autoComplete}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <TextField
          className="my-4"
          label={label}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fullWidth={fullWidth}
          {...params}
          {...textFieldProps}
          InputProps={{
            ...InputProps,
            ...params.InputProps,
            margin: 'dense', // to match other TextField components
          }}
        />
      )}
    />
  );
};

export default AutoComplete;
