import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import {
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';
import BodilyInjuriesFragment from '../AutoParties/BodilyInjuriesFragment';
import IcdCodesFragment from '../IcdCodesFragment';

const INVOLVED_PERSON_DEFAULT_TYPE = 'involved_person';

const getInvovledPersonPredefinedFields = (incidentConfiguration) =>
  preparePreDefinedFields(get(incidentConfiguration, `involved_parties.${INVOLVED_PERSON_DEFAULT_TYPE}`, {}));

const involvedPersonFields = (incidentConfiguration) =>
  getPredefinedFieldsEmptyFormikInitialValues(getInvovledPersonPredefinedFields(incidentConfiguration));

const getInvolvedPersonDefinedFields = (incidentConfiguration, involvedKey) =>
  preparePreDefinedFields(get(incidentConfiguration.involved_parties, involvedKey, {}));

const getInvolvedPersonValidationFields = (incidentConfiguration) =>
  getAdditionalDataValidations(getInvolvedPersonDefinedFields(incidentConfiguration));

const InvolvedPersonFragment = ({ showOnly = false }) => {
  const { values } = useFormikContext();
  const { incidentConfiguration } = useIncidentConfiguration();
  const involvedKey = values?.general_claim_involved_type || INVOLVED_PERSON_DEFAULT_TYPE;
  const preDefinedFields = getInvolvedPersonDefinedFields(incidentConfiguration, involvedKey);

  return (
    <Grid container spacing={1}>
      <PreDefinedField id="contact_id" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="relationship_to_policyholder" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="attorney_contact_id" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="attorney_reference_number" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="representative_contact_id" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="was_ambulance_needed" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="was_hospitalized" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="hospital_contact_id" fields={preDefinedFields} inline readOnly={showOnly} gridXs={4} />
      <PreDefinedField id="admission_datetime" fields={preDefinedFields} inline readOnly={showOnly} gridXs={4} />
      <PreDefinedField id="discharge_datetime" fields={preDefinedFields} inline readOnly={showOnly} gridXs={4} />
      <PreDefinedField id="legal_venue_state" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="legal_venue_reason" fields={preDefinedFields} inline readOnly={showOnly} />
      {involvedKey === INVOLVED_PERSON_DEFAULT_TYPE && <BodilyInjuriesFragment showOnly={showOnly} />}
      <PreDefinedField id="injuries_description" fields={preDefinedFields} inline readOnly={showOnly} gridXs={12} />
      <IcdCodesFragment showOnly={showOnly} fields={preDefinedFields} />
      <PreDefinedField id="note" fields={preDefinedFields} inline readOnly={showOnly} />
    </Grid>
  );
};

InvolvedPersonFragment.propTypes = {
  showOnly: PropTypes.bool,
};

export { getInvolvedPersonValidationFields, involvedPersonFields, InvolvedPersonFragment };
