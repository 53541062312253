import React from 'react';
import PropTypes from 'prop-types';

import { PAYMENT_MIXPANEL_EVENTS } from '../../../pocs/mixpanel';
import { FINANCIAL_STATUS_DICT } from '../../../Types';
import { useClaim } from '../../ClaimContainer';
import mixpanel from '../../CmsMain/mixpanel';
import { MultiSelectFilter } from '../../core';

import styles from './financesFilters.module.scss';

const PAYABLE_TYPES = {
  indemnity: 'Indemnity',
  expenses: 'Expenses',
};

const FinancesFilters = ({ selectedFilters, setSelectedFilters }) => {
  const { claim } = useClaim();

  const onUpdateFilters = (fieldId, newValue) => {
    mixpanel.track(PAYMENT_MIXPANEL_EVENTS.PAYMENTS_FINANCES_PAGE_FILTER_APPLIED, {
      filter_name: fieldId,
      filter_value: newValue,
    });
    setSelectedFilters({ ...selectedFilters, [fieldId]: newValue });
  };

  return (
    <div className={styles.wrapper}>
      <MultiSelectFilter
        label="Exposure"
        value={selectedFilters.exposures_filters || []}
        onChange={(val) => onUpdateFilters('exposures_filters', val)}
        options={claim?.exposures.map((exposure) => exposure.id).concat('general')}
        renderOption={(value) =>
          value === 'general'
            ? 'General expenses'
            : claim?.exposures.find((exposure) => exposure.id === value).label_text
        }
        withOptionChips
      />
      <MultiSelectFilter
        label="Payable type"
        value={selectedFilters.payable_types_filter || []}
        onChange={(val) => onUpdateFilters('payable_types_filter', val)}
        options={Object.keys(PAYABLE_TYPES)}
        renderOption={(value) => PAYABLE_TYPES[value]}
        withOptionChips
      />
      <MultiSelectFilter
        label="Financial status"
        value={selectedFilters.financial_status_filters || []}
        onChange={(val) => onUpdateFilters('financial_status_filters', val)}
        options={Object.keys(FINANCIAL_STATUS_DICT)}
        renderOption={(value) => FINANCIAL_STATUS_DICT[value].desc}
        withOptionChips
      />
    </div>
  );
};

FinancesFilters.propTypes = {
  selectedFilters: PropTypes.object.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
};

export default FinancesFilters;
