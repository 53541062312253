import React from 'react';
import PropTypes from 'prop-types';

import Chip from '~/components/core/Atomic/Chip/Chip';

import { getExposuresLabels } from '../../Utils';
import { useClaim } from '../ClaimContainer';
import OverflowTextWithToolTip from '../OverflowTextWithToolTip';

import { useStyles } from '../../assets/styles';

function ExposuresLabelFilter(props) {
  const { onUpdateFiltered, filterList, exposureOptionsIds, disabled } = props;
  const { claim } = useClaim();
  const classes = useStyles();
  const exposuresLabels = exposureOptionsIds
    ? getExposuresLabels(claim).filter((exposureLabel) => exposureOptionsIds.includes(exposureLabel.id))
    : getExposuresLabels(claim);

  const handleChipClick = (exposureId) => {
    const isIncluded = filterList.includes(exposureId);
    let newActiveLabels;

    if (isIncluded) {
      newActiveLabels = filterList.filter((id) => id !== exposureId);
    } else {
      newActiveLabels = filterList.concat([exposureId]);
    }
    onUpdateFiltered(newActiveLabels);
  };

  return (
    <>
      {exposuresLabels.map((exposureLabel) => (
        <Chip
          key={exposureLabel.id}
          size="small"
          color={filterList.includes(exposureLabel.id) ? 'primary' : 'default'}
          onClick={() => handleChipClick(exposureLabel.id)}
          label={<OverflowTextWithToolTip maxWidth="220px">{exposureLabel.label}</OverflowTextWithToolTip>}
          className={classes.chip}
          disabled={disabled}
        />
      ))}
    </>
  );
}

ExposuresLabelFilter.propTypes = {
  filterList: PropTypes.array,
  onUpdateFiltered: PropTypes.func.isRequired,
  exposureOptionsIds: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
};

export default ExposuresLabelFilter;
