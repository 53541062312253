import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PolicyContextProvider } from '../PolicyContainer';

import WCFnol from './WCFnol';
import WCFnolSearchScreen from './WCFnolSearchScreen';

function WCFnolContainer(props) {
  const history = useHistory();
  const { organization } = props;
  const [searchValues, setSearchValues] = useState(undefined);

  if (!searchValues) {
    return <WCFnolSearchScreen onReportNewFnol={setSearchValues} />;
  } else {
    return (
      <PolicyContextProvider
        policy={{ type: 'wc_policy', lob: 'wc_policy', contacts: [], organization_id: organization.id }}
      >
        {' '}
        {/* actual policy is fetched later based on property */}
        <WCFnol
          organization={organization}
          searchValues={searchValues}
          onSubmit={(claimId) => history.push(`/claims/${claimId}`)}
        />
      </PolicyContextProvider>
    );
  }
}

WCFnolContainer.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default WCFnolContainer;
