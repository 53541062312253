import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const CustomIcon = (props) => (
  <SvgIcon stroke="none" fill="none" {...props}>
    <path
      d="M2 5.33341C2 4.96522 2.29848 4.66675 2.66667 4.66675H6.66666C7.03485 4.66675 7.33333 4.96522 7.33333 5.33341V8.00008C7.33333 8.36827 7.03485 8.66674 6.66666 8.66674H2.66667C2.29848 8.66674 2 8.36827 2 8.00008V5.33341Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M8.6665 10.6665C8.6665 10.2984 8.96498 9.99988 9.33317 9.99988H13.3332C13.7014 9.99988 13.9998 10.2984 13.9998 10.6665V13.3332C13.9998 13.7014 13.7014 13.9999 13.3332 13.9999H9.33317C8.96498 13.9999 8.6665 13.7014 8.6665 13.3332V10.6665Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M8.6665 4.33333C8.6665 4.14924 8.96498 4 9.33317 4H13.3332C13.7014 4 13.9998 4.14924 13.9998 4.33333V5.66667C13.9998 5.85076 13.7014 6 13.3332 6H9.33317C8.96498 6 8.6665 5.85076 8.6665 5.66667V4.33333Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M2.3335 12.3333C2.3335 12.1492 2.59466 12 2.91683 12H6.41683C6.73899 12 7.00016 12.1492 7.00016 12.3333V13.6667C7.00016 13.8508 6.73899 14 6.41683 14H2.91683C2.59466 14 2.3335 13.8508 2.3335 13.6667V12.3333Z"
      fill={colors.lobIconSecondary}
    />
    <rect x="2.5" y="2.5" width="4.33333" height="5.66666" rx="0.5" stroke={colors.lobIconMain} />
    <rect x="9.1665" y="7.83325" width="4.33333" height="5.66666" rx="0.5" stroke={colors.lobIconMain} />
    <rect x="9.1665" y="2.5" width="4.33333" height="3" rx="0.5" stroke={colors.lobIconMain} />
    <rect x="2.5" y="10.5001" width="4.33333" height="3" rx="0.5" stroke={colors.lobIconMain} />
  </SvgIcon>
);

export default CustomIcon;
