import React, { useState } from 'react';
import { useCurrentEditor } from '@tiptap/react';

import TextHighlightIcon from '../../../../icons/editor/TextHighlightIcon';

import PopoverColorPicker from './PopoverColorPicker';

const HighlightComponent: React.FC = () => {
  const { editor } = useCurrentEditor();
  const [color, setColor] = useState('');

  const toggleHighlight = (hexColor: string) => {
    editor?.chain().focus().toggleHighlight({ color: hexColor }).run();
    setColor(hexColor);
  };

  if (!editor) {
    return null;
  }

  return (
    <PopoverColorPicker
      onChange={toggleHighlight}
      isEnabledFunc={() => !!editor?.can().toggleHighlight({ color })}
      icon={<TextHighlightIcon />}
    />
  );
};

export default HighlightComponent;
