import { ROLES_TYPES_DICT } from './Types';

export function isUserAdjuster(user) {
  // claim_rep and field_rep
  return ROLES_TYPES_DICT[user.role.role_type].is_adjuster;
}

export function isUserPhoneRep(user) {
  return user.role.role_type === 'phone_rep';
}

export function isUserOshaRep(user) {
  return user.role.role_type === 'osha_rep';
}

export function isUserFiveSigma(user) {
  return user.organization_name.toUpperCase() === 'Five Sigma'.toUpperCase();
}

export function isUserDaSpecialist(user) {
  return user.role.role_type === 'field_rep';
}

export function isUserClaimPrivileged(user) {
  return isUserAdjuster(user) || isUserDaSpecialist(user) || isUserReadOnly(user);
}

export function isUserReadOnly(user) {
  return user.role.is_view_only;
}

export function isUserSuperUser(user) {
  return user.is_super_user && user.is_super_organization;
}

export function isUserITManagerRole(user) {
  return user.role.role_type === 'it_manager';
}

export function isSysConfigEditor(user) {
  return isUserITManagerRole(user) || user.is_sys_config_editor;
}

export function isUserFromSuperOrganization(user) {
  return user.is_super_organization;
}

export function isUserImpersonated(user) {
  return !!user.impersonating_user_id;
}
