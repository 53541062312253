import React from 'react';
import PropTypes from 'prop-types';

import CardDialog from '../../../../CardDialog';
import LoadingDialog from '../../../../LoadingDialog';
import useDataFetcher from '../../../../useDataFetcher';
import ExposureQaTable from '../../ExposureQaTable';

const DetailedQaResults = (props) => {
  const { searchCriteria, onClose } = props;
  const {
    isLoading,
    isError,
    data: exposuresClaimQas,
  } = useDataFetcher('/api/v1/claims/qa', { params: searchCriteria });

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} onClose={onClose} track="Detailed QA Results" />;
  }

  return (
    <CardDialog isDialog title="Detailed QA Results" onClose={onClose} maxWidth="lg" fullWidth>
      <ExposureQaTable entries={exposuresClaimQas} includeQaScore />
    </CardDialog>
  );
};

DetailedQaResults.propTypes = {
  searchCriteria: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DetailedQaResults;
