import React from 'react';

import { FormRow } from '~/components/LegalActions/components/FormRow';
import { OptionsSelectFieldWithOtherText } from '~/components/LegalActions/components/OptionsSelectFieldWithOtherText';
import { LEGAL_ACTION_LOG_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import { useLegalActionsConfiguration } from '~/components/LegalActions/hooks/useLegalActionsConfiguration';
import TextFieldFormik, { DatePickerTextFieldFormik } from '~/components/TextFieldFormik';

export const LegalActionLogForm = () => {
  const { statusOfLitigationOptions, logTypeOptions } = useLegalActionsConfiguration();

  return (
    <>
      <FormRow>
        <DatePickerTextFieldFormik
          containerClassName="w-full"
          fullWidth
          id={LEGAL_ACTION_LOG_FIELDS.date.key}
          label={LEGAL_ACTION_LOG_FIELDS.date.label}
          nullIfEmpty
        />
        <DatePickerTextFieldFormik
          containerClassName="w-full"
          fullWidth
          id={LEGAL_ACTION_LOG_FIELDS.dueDate.key}
          label={LEGAL_ACTION_LOG_FIELDS.dueDate.label}
          nullIfEmpty
        />
      </FormRow>
      <FormRow>
        <OptionsSelectFieldWithOtherText
          controllingFieldKey={LEGAL_ACTION_LOG_FIELDS.logType.key}
          controllingFieldLabel={LEGAL_ACTION_LOG_FIELDS.logType.label}
          controllingFieldOptions={logTypeOptions}
          controlledFieldKey={LEGAL_ACTION_LOG_FIELDS.logTypeText.key}
          controlledFieldLabel={LEGAL_ACTION_LOG_FIELDS.logTypeText.label}
        />
      </FormRow>
      <FormRow>
        <OptionsSelectFieldWithOtherText
          controllingFieldKey={LEGAL_ACTION_LOG_FIELDS.statusOfLitigation.key}
          controllingFieldLabel={LEGAL_ACTION_LOG_FIELDS.statusOfLitigation.label}
          controllingFieldOptions={statusOfLitigationOptions}
          controlledFieldKey={LEGAL_ACTION_LOG_FIELDS.statusOfLitigationText.key}
          controlledFieldLabel={LEGAL_ACTION_LOG_FIELDS.statusOfLitigationText.label}
        />
      </FormRow>
      <TextFieldFormik
        id={LEGAL_ACTION_LOG_FIELDS.counselUpdate.key}
        label={LEGAL_ACTION_LOG_FIELDS.counselUpdate.label}
        fullWidth
        multiline
        rows={1}
      />
      <TextFieldFormik
        id={LEGAL_ACTION_LOG_FIELDS.nextAction.key}
        label={LEGAL_ACTION_LOG_FIELDS.nextAction.label}
        fullWidth
        multiline
        rows={1}
      />
    </>
  );
};
