import React from 'react';
import PropTypes from 'prop-types';

import Radio from '~/components/core/Atomic/Radio';
import PermissionDeniedTooltip from '~/components/core/Permissions/PermissionDeniedTooltip';
import { useRestrictedPermissions } from '~/components/core/Permissions/RestrictedPermissions';

import {
  FsWrappersSharedProptypes,
  getEnumObjectFromArray,
  getMuiComponentWithPointerEvents,
} from '../FsWrappersUtils';

const RadioWithPointerEvents = getMuiComponentWithPointerEvents(Radio);

export const FsRadio = ({ key, color, checked, onChange, disabled, size, onClick, style, ignorePermissions }) => {
  const { userHasContextPermissions } = useRestrictedPermissions();
  const hasPermissions = ignorePermissions || userHasContextPermissions;
  const isDisabled = disabled || !hasPermissions;

  const RadioComponent = hasPermissions ? Radio : RadioWithPointerEvents;
  const radioButton = (
    <RadioComponent
      key={key}
      color={color}
      checked={checked}
      onChange={onChange}
      disabled={isDisabled}
      size={size}
      onClick={onClick}
      style={style}
    />
  );

  if (hasPermissions) {
    return radioButton;
  }

  return <PermissionDeniedTooltip>{radioButton}</PermissionDeniedTooltip>;
};

FsRadio.SIZES = getEnumObjectFromArray(['small', 'medium']);

FsRadio.propTypes = {
  ...FsWrappersSharedProptypes,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(FsRadio.SIZES)),
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default FsRadio;
