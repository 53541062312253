import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const PhysicalMailIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      d="M4 3.66675C3.20435 3.66675 2.44129 3.98282 1.87868 4.54543C1.31607 5.10804 1 5.8711 1 6.66675V12.6667H7V6.66675C7 5.8711 6.68393 5.10804 6.12132 4.54543C5.55871 3.98282 4.79565 3.66675 4 3.66675ZM4 2.66675H12C13.0609 2.66675 14.0783 3.08818 14.8284 3.83832C15.5786 4.58847 16 5.60588 16 6.66675V12.6667C16 12.932 15.8946 13.1863 15.7071 13.3739C15.5196 13.5614 15.2652 13.6667 15 13.6667H1C0.734784 13.6667 0.48043 13.5614 0.292893 13.3739C0.105357 13.1863 0 12.932 0 12.6667V6.66675C0 5.60588 0.421427 4.58847 1.17157 3.83832C1.92172 3.08818 2.93913 2.66675 4 2.66675ZM6.646 3.66675C7.07208 4.04172 7.41326 4.50329 7.64675 5.02063C7.88024 5.53796 8.00067 6.09916 8 6.66675V12.6667H15V6.66675C15 5.8711 14.6839 5.10804 14.1213 4.54543C13.5587 3.98282 12.7956 3.66675 12 3.66675H6.646Z"
      fill={iconColor}
    />
    <path
      d="M11.793 8.16675H9V7.16675H14C14.1326 7.16675 14.2598 7.21943 14.3536 7.31319C14.4473 7.40696 14.5 7.53414 14.5 7.66675V8.66675C14.5 8.79936 14.4473 8.92653 14.3536 9.0203C14.2598 9.11407 14.1326 9.16675 14 9.16675H13C12.9343 9.16687 12.8692 9.15403 12.8085 9.12898C12.7477 9.10393 12.6925 9.06715 12.646 9.02075L11.793 8.16675ZM5 6.66675C5 7.21875 4.552 6.66675 4 6.66675C3.448 6.66675 3 7.21875 3 6.66675C3 6.40153 3.10536 6.14718 3.29289 5.95964C3.48043 5.77211 3.73478 5.66675 4 5.66675C4.26522 5.66675 4.51957 5.77211 4.70711 5.95964C4.89464 6.14718 5 6.40153 5 6.66675Z"
      fill={iconColor}
    />
  </SvgIcon>
);

PhysicalMailIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default PhysicalMailIcon;
