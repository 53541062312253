import React from 'react';

import { MainCard, PropertyDetails } from '../../../core';
import { PropertyDetailsRow } from '../../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { LegalActionEditButton } from '../../components/LegalActionEditButton';
import { AlternativeDisputeResolutionDialog } from '../../Dialogs/AlternativeDisputeResolution/AlternativeDisputeResolutionDialog';
import { ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS } from '../../FormFragments/FormFieldsDefinitions';
import { useLegalAction } from '../../hooks/useLegalAction';
import type { Option } from '../../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../../hooks/useLegalActionsConfiguration';
import { getLabelByKey, isOptionOther } from '../../LegalActionsUtils';

export const AlternativeDisputeResolutionCard: React.FC = () => {
  const { legalAction, reloadLegalAction } = useLegalAction();
  const { adrTypeOptions, earlyCaseResolutionExploredOptions } = useLegalActionsConfiguration();
  const [isEditAlternativeDisputeResolutionDialogOpen, setIsEditAlternativeDisputeResolutionDialogOpen] =
    React.useState(false);

  return (
    <>
      <MainCard
        type="elevated"
        title="Alternative Dispute Resolution"
        action={<LegalActionEditButton onClick={() => setIsEditAlternativeDisputeResolutionDialogOpen(true)} />}
        collapsible
        openByDefault
      >
        <div className="flex w-full">
          <PropertyDetailsRow>
            <PropertyDetails
              title={ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS.earlyCaseResolutionExplored.label}
              text={getLabelByKey(
                earlyCaseResolutionExploredOptions as Option[],
                legalAction.early_case_resolution_explored
              )}
            />
            <PropertyDetails
              title={ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS.adrExplored.label}
              text={getLabelByKey(adrTypeOptions as Option[], legalAction.alternative_dispute_resolution_explored)}
            />
          </PropertyDetailsRow>
        </div>
        {isOptionOther(adrTypeOptions as Option[], legalAction.alternative_dispute_resolution_explored) ? (
          <div className="flex w-full">
            <PropertyDetailsRow>
              <PropertyDetails title="Note" text={legalAction.alternative_dispute_resolution_text} />
            </PropertyDetailsRow>
          </div>
        ) : null}
      </MainCard>
      {isEditAlternativeDisputeResolutionDialogOpen ? (
        <AlternativeDisputeResolutionDialog
          legalAction={legalAction}
          onClose={() => setIsEditAlternativeDisputeResolutionDialogOpen(false)}
          onSubmitSuccess={reloadLegalAction}
        />
      ) : null}
    </>
  );
};
