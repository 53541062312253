import React from 'react';
import PropTypes from 'prop-types';

import { EditContactDialog } from '~/components/Contacts/UpsertContact/Edit/EditContactDialog';
import { shouldCheckForCommunicationPermissions } from '~/components/ContactUtils';
import Button from '~/components/core/Atomic/Buttons/Button';
import { useCms } from '~/components/hooks/useCms';
import { FNOL_MIXPANEL_EVENTS } from '~/pocs/mixpanel';

import mixpanel from '../CmsMain/mixpanel';
import LoadingDialog from '../LoadingDialog';
import useDataFetcher from '../useDataFetcher';

function InsuredInfoVerificationCard(props) {
  const { insuredContactId, onDetailsVerified, onClose } = props;
  const {
    isLoading,
    isError,
    data: insuredContact,
    reloadData,
  } = useDataFetcher(`/api/v1/contacts/${insuredContactId}`);

  const { userOrganization } = useCms();

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} track="Verify Insured Info" />;
  }

  const buttonsComponentOverride = ({ isSubmitting, handleSubmit }) => (
    <Button
      variant="contained"
      color="primary"
      disabled={isSubmitting}
      onClick={() => {
        handleSubmit();
        mixpanel.track(FNOL_MIXPANEL_EVENTS.START_CREATING_FNOL_CLICKED_AFTER_INSURED_DETAILS);
      }}
    >
      verify
    </Button>
  );
  const requireCommunicationPrivileges = shouldCheckForCommunicationPermissions(
    userOrganization,
    insuredContact.is_company,
    insuredContact.role
  );

  const handleContactUpdate = async () => {
    await reloadData();
    await onDetailsVerified();
    onClose();
  };

  return (
    <EditContactDialog
      contact={insuredContact}
      onClose={onClose}
      onContactUpdate={handleContactUpdate}
      title="Verify Policyholder Details"
      buttonsOverride={buttonsComponentOverride}
      specialValidationRequirements={{ requireCommunicationPrivileges }}
    />
  );
}

InsuredInfoVerificationCard.propTypes = {
  insuredContactId: PropTypes.number.isRequired,
  onDetailsVerified: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InsuredInfoVerificationCard;
