import React, { useState } from 'react';
import { getIn, useFormikContext } from 'formik';
import type { FormikValues } from 'formik/dist/types';
import { every, set } from 'lodash';
import * as Yup from 'yup';

import { useClaim } from '~/components/ClaimContainer';
import type { Tab } from '~/components/Contacts/UpsertContact/types';
import UploadDocument from '~/components/Contacts/UpsertContact/UploadDocument';
import { DOCUMENT_FIELD_IDS } from '~/components/Contacts/UpsertContact/UploadDocument/constants';
import {
  getDocumentInitialValues,
  getDocumentPayload,
  getDocumentValidationSchema,
} from '~/components/Contacts/UpsertContact/UploadDocument/utils';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import { getFullFieldPath, getInitializedValues } from '~/components/Contacts/UpsertContact/utils';
import useOrganization from '~/components/OrganizationContext';
import { DatePickerTextFieldFormik } from '~/components/TextFieldFormik';

import TabWrapper from './TabWrapper';

export const COI_TAB_KEY = 'coi';

export const FORMIK_COI_FIELD_IDS = {
  COI_DOCUMENT_ID: 'coi_document_id',
  COI_EXPIRATION: 'coi_expiration',
};

export const getFullCOIPath = (fieldId: string): string => getFullFieldPath(COI_TAB_KEY, fieldId);

const CoiTab: React.FC = () => {
  const { isSubmitting, values } = useFormikContext();
  const { documentUploadPercent } = useCoiTab();

  const sharedProps = {
    disabled: isSubmitting,
  };

  return (
    <TabWrapper>
      <div className="mb-32 grid grid-cols-2 gap-20">
        <UploadDocument
          {...sharedProps}
          documentFieldKey={FORMIK_COI_FIELD_IDS.COI_DOCUMENT_ID}
          documentType="coi_form"
          documentFieldLabel="COI"
          getFullPath={getFullCOIPath}
          documentUploadPercent={documentUploadPercent}
        />
        <DatePickerTextFieldFormik
          id={getFullCOIPath(FORMIK_COI_FIELD_IDS.COI_EXPIRATION)}
          label="Expiration date"
          {...sharedProps}
          disablePast
          clearable
          disabled={
            sharedProps.disabled ||
            !(
              getIn(values, getFullCOIPath(FORMIK_COI_FIELD_IDS.COI_DOCUMENT_ID)) ||
              getIn(values, getFullCOIPath(DOCUMENT_FIELD_IDS.FILE))
            )
          }
        />
      </div>
    </TabWrapper>
  );
};

const useCoiTab = (): Tab => {
  // No Formik Context
  const { currentRole, originalContact, isCreation } = useContact();
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore */
  const { organizationContactRolesDict } = useOrganization();
  const { claim } = useClaim();
  const [documentUploadPercent, setDocumentUploadPercent] = useState(0);

  const getInitialValues = () => {
    const values = {};

    const setInitialValue = (fieldId: string, initialValue: unknown, defaultInitialValue: unknown = '') =>
      set(values, fieldId, initialValue ?? defaultInitialValue);

    const documentInitialValues = getDocumentInitialValues(FORMIK_COI_FIELD_IDS.COI_DOCUMENT_ID, claim);
    every(Object.keys(documentInitialValues), (formikFieldId) =>
      setInitialValue(formikFieldId, documentInitialValues[formikFieldId])
    );

    setInitialValue(FORMIK_COI_FIELD_IDS.COI_EXPIRATION, '');

    return values;
  };

  const getValidationSchema = () => {
    const validations = {};

    const addValidation = (fieldId: string, fieldValidation: Yup.AnySchema) =>
      set(validations, fieldId, fieldValidation);

    const documentValidationSchema = getDocumentValidationSchema(FORMIK_COI_FIELD_IDS.COI_DOCUMENT_ID, true, claim);
    every(Object.keys(documentValidationSchema), (formikFieldId) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      addValidation(formikFieldId, documentValidationSchema[formikFieldId])
    );

    addValidation(
      FORMIK_COI_FIELD_IDS.COI_EXPIRATION,
      Yup.date().when([DOCUMENT_FIELD_IDS.FILE, FORMIK_COI_FIELD_IDS.COI_DOCUMENT_ID], {
        is: (file: unknown, docId: number) => file || docId,
        then: Yup.date().required('Required'),
      })
    );

    return validations;
  };

  const getValuesToCompare = (values: FormikValues) => {
    return getInitializedValues(values);
  };

  const getPreparedPayload = async (values: FormikValues) => {
    return await getDocumentPayload(values, originalContact, setDocumentUploadPercent);
  };

  const isHiddenForContact = () => {
    // Hidden Tabs cannot have absolute require fields, only conditionally required fields
    return isCreation || !organizationContactRolesDict[currentRole]?.is_coi_required;
  };

  return {
    label: 'COI',
    tabKey: COI_TAB_KEY,
    tabComponent: CoiTab,
    getInitialValues,
    getValidationSchema,
    getValuesToCompare,
    isHiddenForContact,
    documentUploadPercent,
    getPreparedPayload,
  };
};

export default useCoiTab;
