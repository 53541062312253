import React from 'react';

import AlertBanner from '~/components/core/AlertBanner';

export enum IssueSeverity {
  WARNING = 'warning',
  ERROR = 'error',
}

interface IssueWithSeverity {
  issue: string;
  severity: IssueSeverity;
}

interface AnomalousPaymentBannerProps {
  issuesAndSeverities: IssueWithSeverity[];
}

const AnomalousPaymentBanner: React.FC<AnomalousPaymentBannerProps> & { IssueSeverity: typeof IssueSeverity } = ({
  issuesAndSeverities,
}: AnomalousPaymentBannerProps) => {
  const isAnyIssueError = issuesAndSeverities.filter((ias) => ias.severity === IssueSeverity.ERROR).length > 0;
  return (
    <>
      {issuesAndSeverities.length > 0 ? (
        <div className="mb-20 mt-20">
          <AlertBanner
            alertType={isAnyIssueError ? AlertBanner.ALERT_TYPES.ERROR : AlertBanner.ALERT_TYPES.WARNING}
            title="Payment Warning"
            note={
              <div>
                {issuesAndSeverities.map((ias) => (
                  <div key={ias.issue}>{ias.issue}</div>
                ))}
              </div>
            }
            withIcon
          />
        </div>
      ) : null}
    </>
  );
};

AnomalousPaymentBanner.IssueSeverity = IssueSeverity;

export default AnomalousPaymentBanner;
