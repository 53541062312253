import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';

import { CONFIGURATION_FEATURES_NAMES } from '../../../Types';
import { isFeatureEnabled, isInshurUser } from '../../../Utils';
import CardDialog from '../../CardDialog';
import ClaimLink from '../../ClaimLink';
import { useCms } from '../../hooks/useCms';
import LoadingDialog from '../../LoadingDialog';
import LoadingIndicator from '../../LoadingIndicator';
import PlainTable from '../../PlainTable';
import useDataFetcher from '../../useDataFetcher';
import useYearMonthFilter from '../../YearMonthFilter';

import { useStyles } from '../../../assets/styles';

function KpiDashboardsContainer() {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { user, userOrganization } = useCms();

  const [openProductionDashboard, setOpenProductionDashboard] = useState(false);
  const [openApdPerformanceDashboard, setOpenApdPerformanceDashboard] = useState(false);
  const [openPipPerformanceDashboard, setOpenPipPerformanceDashboard] = useState(false);
  const [openBiPerformanceDashboard, setOpenBiPerformanceDashboard] = useState(false);

  const iconButtonProps = { style: { color: 'white', width: 40, height: 40 }, className: classes.actionButton };

  return (
    <>
      <IconButton {...iconButtonProps} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <BarChartIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PRODUCTION_DASHBOARD) && (
          <MenuItem
            onClick={() => {
              setOpenProductionDashboard(true);
              setAnchorEl(null);
            }}
          >
            Production Dashboard
          </MenuItem>
        )}
        {(isInshurUser(user) ||
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.INSHUR_PERFORMANCE_DASHBOARDS)) && (
          <div>
            <MenuItem
              onClick={() => {
                setOpenApdPerformanceDashboard(true);
                setAnchorEl(null);
              }}
            >
              APD Performance Dashboard
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenPipPerformanceDashboard(true);
                setAnchorEl(null);
              }}
            >
              PIP Performance Dashboard
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenBiPerformanceDashboard(true);
                setAnchorEl(null);
              }}
            >
              BI Performance Dashboard
            </MenuItem>
          </div>
        )}
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PHONE_CALLS_DASHBOARD) &&
          user.is_call_center_allowed && (
            <MenuItem
              onClick={() => {
                window.open('/phone_calls_dashboard', '_blank');
                setAnchorEl(null);
              }}
            >
              Phone Calls Dashboard &nbsp; <OpenInNewIcon color="action" />
            </MenuItem>
          )}
      </Menu>

      {openProductionDashboard && (
        <ProductionDashboard
          onClose={() => {
            setOpenProductionDashboard(false);
            setAnchorEl(null);
          }}
        />
      )}
      {openApdPerformanceDashboard && <ApdPerformanceDashboard onClose={() => setOpenApdPerformanceDashboard(false)} />}
      {openPipPerformanceDashboard && <PipPerformanceDashboard onClose={() => setOpenPipPerformanceDashboard(false)} />}
      {openBiPerformanceDashboard && <BiPerformanceDashboard onClose={() => setOpenBiPerformanceDashboard(false)} />}
    </>
  );
}

function ProductionDashboard({ onClose }) {
  const classes = useStyles();
  const { filterByMonth, yearFilter, monthFilter, yearFilterMonthFilterProps, YearMonthFilter } = useYearMonthFilter();
  const [coveragesListToShow, setCoveragesListToShow] = useState(undefined);
  const [claimsListToShow, setClaimsListToShow] = useState(undefined);
  const { user } = useCms();

  const {
    isLoading,
    isError,
    data: productionDashboard,
  } = useDataFetcher('/api/v1/team-dashboard/production_dashboard', {
    params: { year: yearFilter, month: filterByMonth ? monthFilter + 1 : undefined },
  });

  let productionStats;
  let numOfBusinessDays;

  const shouldShowControlFileColumn = isInshurUser(user);

  if (!isLoading) {
    productionStats = productionDashboard.production_stats;
    numOfBusinessDays = productionDashboard.num_of_business_days;
  }

  const getFormattedPerDay = (countInPeriod) => {
    const averagePerDay = numOfBusinessDays ? (countInPeriod / numOfBusinessDays).toFixed(2) : countInPeriod;

    return `${averagePerDay} (${countInPeriod}/${numOfBusinessDays})`;
  };

  function getCoveragesDetailsCellContent(value, statusType, adjusterId = undefined) {
    return value === 0 ? (
      value
    ) : (
      <Link
        to=""
        style={{ margin: 0, textDecoration: 'underline' }}
        onClick={(e) => {
          e.preventDefault();
          setCoveragesListToShow({ statusType, adjusterId });
        }}
      >
        {value}
      </Link>
    );
  }

  function getClaimsDetailsCellContent(value, statusType, adjusterId = undefined) {
    return value === 0 ? (
      value
    ) : (
      <Link
        to=""
        style={{ margin: 0, textDecoration: 'underline' }}
        onClick={(e) => {
          e.preventDefault();
          setClaimsListToShow({ statusType, adjusterId });
        }}
      >
        {value}
      </Link>
    );
  }

  return (
    <>
      <CardDialog title="Production Dashboard" onClose={onClose} isDialog maxWidth={false} fullWidth>
        <YearMonthFilter {...yearFilterMonthFilterProps} />

        {isLoading || isError ? (
          <LoadingIndicator isError={isError} />
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Adjuster</TableCell>
                <TableCell className={classes.tableCell} align="right">
                  New Coverages
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  Closed Coverages
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  Pending Coverages
                </TableCell>
                {shouldShowControlFileColumn && (
                  <TableCell className={classes.tableCell} align="right">
                    Control Files
                  </TableCell>
                )}
                <TableCell className={classes.tableCell} align="right">
                  New Coverages Per Day
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  Closed Coverages Per Day
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productionStats.map((adjusterStat) => (
                <TableRow key={adjusterStat.user_id}>
                  <TableCell className={classes.tableCell} padding="default" align="left">
                    {adjusterStat.username}
                  </TableCell>
                  <TableCell className={classes.tableCell} padding="default" align="right">
                    {getCoveragesDetailsCellContent(adjusterStat.open_count, 'new', adjusterStat.user_id)}
                  </TableCell>
                  <TableCell className={classes.tableCell} padding="default" align="right">
                    {getCoveragesDetailsCellContent(adjusterStat.closed_count, 'closed', adjusterStat.user_id)}
                  </TableCell>
                  <TableCell className={classes.tableCell} padding="default" align="right">
                    {getCoveragesDetailsCellContent(adjusterStat.inventory_count, 'pending', adjusterStat.user_id)}
                  </TableCell>
                  {shouldShowControlFileColumn && (
                    <TableCell className={classes.tableCell} padding="default" align="right">
                      {getClaimsDetailsCellContent(
                        adjusterStat.control_file_count,
                        'control_file',
                        adjusterStat.user_id
                      )}
                    </TableCell>
                  )}
                  <TableCell className={classes.tableCell} padding="default" align="right">
                    {getFormattedPerDay(adjusterStat.open_count)}
                  </TableCell>
                  <TableCell className={classes.tableCell} padding="default" align="right">
                    {getFormattedPerDay(adjusterStat.closed_count)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className={classes.summaryFooterTableCell}>Total</TableCell>
                <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                  {getCoveragesDetailsCellContent(
                    productionStats.reduce((acc, curr) => acc + curr.open_count, 0),
                    'new'
                  )}
                </TableCell>
                <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                  {getCoveragesDetailsCellContent(
                    productionStats.reduce((acc, curr) => acc + curr.closed_count, 0),
                    'closed'
                  )}
                </TableCell>
                <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                  {getCoveragesDetailsCellContent(
                    productionStats.reduce((acc, curr) => acc + curr.inventory_count, 0),
                    'pending'
                  )}
                </TableCell>
                {shouldShowControlFileColumn && (
                  <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                    {getClaimsDetailsCellContent(
                      productionStats.reduce((acc, curr) => acc + curr.control_file_count, 0),
                      'control_file'
                    )}
                  </TableCell>
                )}
                <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                  {getFormattedPerDay(productionStats.reduce((acc, curr) => acc + curr.open_count, 0))}
                </TableCell>
                <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                  {getFormattedPerDay(productionStats.reduce((acc, curr) => acc + curr.closed_count, 0))}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </CardDialog>
      {coveragesListToShow && (
        <ProductionDashboardCoverageDetails
          year={yearFilter}
          month={monthFilter}
          filterByMonth={filterByMonth}
          adjusterId={coveragesListToShow.adjusterId}
          statusType={coveragesListToShow.statusType}
          onClose={() => setCoveragesListToShow(undefined)}
        />
      )}
      {claimsListToShow && (
        <ProductionDashboardClaimsDetails
          year={yearFilter}
          month={monthFilter}
          filterByMonth={filterByMonth}
          adjusterId={claimsListToShow.adjusterId}
          statusType={claimsListToShow.statusType}
          onClose={() => setClaimsListToShow(undefined)}
        />
      )}
    </>
  );
}

ProductionDashboard.propTypes = {
  onClose: PropTypes.func.isRequired,
};

function ProductionDashboardCoverageDetails({ filterByMonth, year, month, adjusterId, statusType, onClose }) {
  const {
    isLoading,
    isError,
    data: exposuresList,
  } = useDataFetcher(`/api/v1/team-dashboard/production_dashboard/${statusType}/exposures`, {
    params: {
      year,
      month: filterByMonth ? month + 1 : undefined,
      statusType,
      adjuster_id: adjusterId,
    },
  });

  if (isLoading || isError) {
    return (
      <LoadingDialog
        track={`Loading ProductionDashboardCoverageDetails ${statusType} ${adjusterId}`}
        text="Loading..."
      />
    );
  }

  return (
    <CardDialog isDialog title="Exposures" onClose={onClose}>
      <PlainTable rows={exposuresList} columns={ExposureColumnData} hideHeader />
    </CardDialog>
  );
}

ProductionDashboardCoverageDetails.propTypes = {
  filterByMonth: PropTypes.bool.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number,
  adjusterId: PropTypes.string,
  statusType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

function ProductionDashboardClaimsDetails({ filterByMonth, year, month, adjusterId, statusType, onClose }) {
  const {
    isLoading,
    isError,
    data: claimsList,
  } = useDataFetcher(`/api/v1/team-dashboard/production_dashboard/${statusType}/claims`, {
    params: {
      year,
      month: filterByMonth ? month + 1 : undefined,
      statusType,
      adjuster_id: adjusterId,
    },
  });

  if (isLoading || isError) {
    return (
      <LoadingDialog track={`Loading ProductionDashboardClaimsDetails ${statusType} ${adjusterId}`} text="Loading..." />
    );
  }

  return (
    <CardDialog isDialog title="Claims" onClose={onClose}>
      <PlainTable rows={claimsList} columns={ClaimColumnData} hideHeader />
    </CardDialog>
  );
}

ProductionDashboardClaimsDetails.propTypes = {
  filterByMonth: PropTypes.bool.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number,
  adjusterId: PropTypes.string,
  statusType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

function ApdPerformanceDashboard({ onClose }) {
  const classes = useStyles();
  const { user } = useCms();

  const { filterByMonth, yearFilter, monthFilter, yearFilterMonthFilterProps, YearMonthFilter } = useYearMonthFilter();

  const standardKpis = [
    {
      desc: '24 hours to first contact',
      posKey: 'first_contact_within_period',
      negKey: 'first_contact_not_within_period',
    },
    { desc: 'MOI within 2 days from report', posKey: 'moi_within_period', negKey: 'moi_not_within_period' },
    { desc: 'DA within 3 days of assignment', posKey: 'da_within_period', negKey: 'da_not_within_period' },
    {
      desc: 'Initial Payment within 7 business days',
      posKey: 'first_payment_within_period',
      negKey: 'first_payment_not_within_period',
    },
    {
      desc: 'Recovered within 60 days',
      posKey: 'first_recovery_payment_within_period',
      negKey: 'first_recovery_payment_not_within_period',
    },
    {
      desc: 'Coverage closed with less than $125 of ALAE',
      posKey: 'closed_with_small_payment',
      negKey: 'was_not_closed_with_small_payment',
    },
  ];

  const {
    isLoading,
    isError,
    data: apdPerformanceStats,
  } = useDataFetcher(`/api/v1/team-dashboard/organizations/${user.organization_id}/physical_damage_dashboard`, {
    params: { year: yearFilter, month: filterByMonth ? monthFilter + 1 : undefined },
  });

  let totalQaScores;
  if (!isLoading) {
    totalQaScores = apdPerformanceStats.reduce((acc, curr) => acc + curr.qa_count, 0);
  }

  return (
    <CardDialog title="APD Performance Dashboard" onClose={onClose} isDialog maxWidth={false} fullWidth>
      <YearMonthFilter {...yearFilterMonthFilterProps} />

      {isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Adjuster</TableCell>
              {standardKpis.map((kpi) => (
                <TableCell key={kpi.desc} className={classes.tableCell} align="right">
                  {kpi.desc}
                </TableCell>
              ))}
              <TableCell className={classes.tableCell} align="right">
                QA Count
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                QA Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apdPerformanceStats.map((adjusterStat) => (
              <TableRow key={adjusterStat.username}>
                <TableCell className={classes.tableCell} padding="default" align="left">
                  {adjusterStat.username}
                </TableCell>
                {standardKpis.map((kpi) => (
                  <TableCell key={kpi.desc} className={classes.tableCell} padding="default" align="right">
                    <StandardKpiContainer adjusterStatsDict={adjusterStat} kpiInfoDict={kpi} />
                  </TableCell>
                ))}
                <TableCell className={classes.tableCell} padding="default" align="right">
                  {adjusterStat.qa_count}
                </TableCell>
                <TableCell className={classes.tableCell} padding="default" align="right">
                  {adjusterStat.qa_score}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={classes.summaryFooterTableCell}>Total</TableCell>
              {standardKpis.map((kpi) => (
                <TableCell key={kpi.desc} className={classes.summaryFooterTableCell} padding="default" align="right">
                  {getFormattedPercentage(
                    apdPerformanceStats.reduce((acc, curr) => acc + curr[kpi.posKey], 0),
                    apdPerformanceStats.reduce((acc, curr) => acc + curr[kpi.posKey] + curr[kpi.negKey], 0)
                  )}
                </TableCell>
              ))}
              <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                {totalQaScores}
              </TableCell>
              <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                {totalQaScores !== 0 &&
                  Math.floor(
                    apdPerformanceStats.reduce(
                      (acc, curr) => acc + (curr.qa_score ? curr.qa_score * curr.qa_count : 0),
                      0
                    ) / totalQaScores
                  )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </CardDialog>
  );
}

ApdPerformanceDashboard.propTypes = {
  onClose: PropTypes.func.isRequired,
};

function PipPerformanceDashboard({ onClose }) {
  const classes = useStyles();
  const { user } = useCms();
  const { filterByMonth, yearFilter, monthFilter, yearFilterMonthFilterProps, YearMonthFilter } = useYearMonthFilter();

  const standardKpis = [
    {
      desc: '24 hours to first contact',
      posKey: 'first_contact_within_period',
      negKey: 'first_contact_not_within_period',
    },
    {
      desc: 'Bills Resolved within 25 days',
      posKey: 'bills_resolved_within_period',
      negKey: 'bills_were_not_resolved_within_period',
    },
    {
      desc: 'Recovered within 120 days',
      posKey: 'first_recovery_payment_within_period',
      negKey: 'first_recovery_payment_not_within_period',
    },
  ];

  const {
    isLoading,
    isError,
    data: pipPerformanceStats,
  } = useDataFetcher(`/api/v1/team-dashboard/organizations/${user.organization_id}/pip_dashboard`, {
    params: { year: yearFilter, month: filterByMonth ? monthFilter + 1 : undefined },
  });

  let totalQaScores;
  if (!isLoading) {
    totalQaScores = pipPerformanceStats.reduce((acc, curr) => acc + curr.qa_count, 0);
  }

  return (
    <CardDialog title="Pip Performance Dashboard" onClose={onClose} isDialog maxWidth={false} fullWidth>
      <YearMonthFilter {...yearFilterMonthFilterProps} />

      {isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Adjuster</TableCell>
              {standardKpis.map((kpi) => (
                <TableCell key={kpi.desc} className={classes.tableCell} align="right">
                  {kpi.desc}
                </TableCell>
              ))}
              <TableCell className={classes.tableCell} align="right">
                QA Count
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                QA Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pipPerformanceStats.map((adjusterStat) => (
              <TableRow key={adjusterStat.id}>
                <TableCell className={classes.tableCell} padding="default" align="left">
                  {adjusterStat.username}
                </TableCell>
                {standardKpis.map((kpi) => (
                  <TableCell key={kpi.desc} className={classes.tableCell} padding="default" align="right">
                    <StandardKpiContainer adjusterStatsDict={adjusterStat} kpiInfoDict={kpi} />
                  </TableCell>
                ))}
                <TableCell className={classes.tableCell} padding="default" align="right">
                  {adjusterStat.qa_count}
                </TableCell>
                <TableCell className={classes.tableCell} padding="default" align="right">
                  {adjusterStat.qa_score}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={classes.summaryFooterTableCell}>Total</TableCell>
              {standardKpis.map((kpi) => (
                <TableCell key={kpi.desc} className={classes.summaryFooterTableCell} padding="default" align="right">
                  {getFormattedPercentage(
                    pipPerformanceStats.reduce((acc, curr) => acc + curr[kpi.posKey], 0),
                    pipPerformanceStats.reduce((acc, curr) => acc + curr[kpi.posKey] + curr[kpi.negKey], 0)
                  )}
                </TableCell>
              ))}
              <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                {totalQaScores}
              </TableCell>
              <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                {totalQaScores !== 0 &&
                  Math.floor(
                    pipPerformanceStats.reduce(
                      (acc, curr) => acc + (curr.qa_score ? curr.qa_score * curr.qa_count : 0),
                      0
                    ) / totalQaScores
                  )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </CardDialog>
  );
}

PipPerformanceDashboard.propTypes = {
  onClose: PropTypes.func.isRequired,
};

function BiPerformanceDashboard({ onClose }) {
  const classes = useStyles();
  const { filterByMonth, yearFilter, monthFilter, yearFilterMonthFilterProps, YearMonthFilter } = useYearMonthFilter();
  const { user } = useCms();

  const standardKpis = [
    {
      desc: '24 hours to first contact',
      posKey: 'first_contact_within_period',
      negKey: 'first_contact_not_within_period',
    },
    {
      desc: 'Coverages Closed Within 180 days',
      posKey: 'closed_within_period',
      negKey: 'was_not_closed_within_period',
    },
    {
      desc: 'Suits Closed Within 360 days',
      posKey: 'suits_closed_within_period',
      negKey: 'suits_was_not_closed_within_period',
    },
    {
      desc: 'Closed With Less Than $500 ALAE',
      posKey: 'closed_with_small_payment',
      negKey: 'was_not_closed_with_small_payment',
    },
    {
      desc: 'Suits Closed With Less Than $7000 ALAE',
      posKey: 'suits_closed_with_small_payment',
      negKey: 'suits_was_not_closed_small_payment',
    },
  ];

  const {
    isLoading,
    isError,
    data: biPerformanceStats,
  } = useDataFetcher(`/api/v1/team-dashboard/organizations/${user.organization_id}/liability_dashboard`, {
    params: { year: yearFilter, month: filterByMonth ? monthFilter + 1 : undefined },
  });

  let totalQaScores;
  if (!isLoading) {
    totalQaScores = biPerformanceStats.reduce((acc, curr) => acc + curr.qa_count, 0);
  }

  return (
    <CardDialog title="BI/UM Performance Dashboard" onClose={onClose} isDialog maxWidth={false} fullWidth>
      <YearMonthFilter {...yearFilterMonthFilterProps} />

      {isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Adjuster</TableCell>
              <TableCell className={classes.tableCell} align="right">
                24 hours to first contact
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Coverages Closed Within 180 days
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Suits Closed Within 360 days
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Closed With Less Than $500 ALAE
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Suits Closed With Less Than $7000 ALAE
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                QA Count
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                QA Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {biPerformanceStats.map((adjusterStat) => (
              <TableRow key={adjusterStat.id}>
                <TableCell className={classes.tableCell} padding="default" align="left">
                  {adjusterStat.username}
                </TableCell>
                {standardKpis.map((kpi) => (
                  <TableCell key={kpi.desc} className={classes.tableCell} padding="default" align="right">
                    <StandardKpiContainer adjusterStatsDict={adjusterStat} kpiInfoDict={kpi} />
                  </TableCell>
                ))}
                <TableCell className={classes.tableCell} padding="default" align="right">
                  {adjusterStat.qa_count}
                </TableCell>
                <TableCell className={classes.tableCell} padding="default" align="right">
                  {adjusterStat.qa_score}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={classes.summaryFooterTableCell}>
                <strong>Total</strong>
              </TableCell>
              {standardKpis.map((kpi) => (
                <TableCell key={kpi.desc} className={classes.summaryFooterTableCell} padding="default" align="right">
                  {getFormattedPercentage(
                    biPerformanceStats.reduce((acc, curr) => acc + curr[kpi.posKey], 0),
                    biPerformanceStats.reduce((acc, curr) => acc + curr[kpi.posKey] + curr[kpi.negKey], 0)
                  )}
                </TableCell>
              ))}
              <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                {totalQaScores}
              </TableCell>
              <TableCell className={classes.summaryFooterTableCell} padding="default" align="right">
                {totalQaScores !== 0 &&
                  Math.floor(
                    biPerformanceStats.reduce(
                      (acc, curr) => acc + (curr.qa_score ? curr.qa_score * curr.qa_count : 0),
                      0
                    ) / totalQaScores
                  )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </CardDialog>
  );
}

BiPerformanceDashboard.propTypes = {
  onClose: PropTypes.func.isRequired,
};

function getFormattedPercentage(numerator, denominator) {
  if (denominator === 0) {
    return '0% (0/0)';
  }

  return `${((numerator * 100) / denominator).toFixed(2)}% (${numerator}/${denominator})`;
}

function StandardKpiContainer(props) {
  const { adjusterStatsDict, kpiInfoDict } = props;
  const [exposuresDialogOpen, setExposuresDialogOpen] = useState(false);

  const formattedPercentage = getFormattedPercentage(
    adjusterStatsDict[kpiInfoDict.posKey],
    adjusterStatsDict[kpiInfoDict.posKey] + adjusterStatsDict[kpiInfoDict.negKey]
  );

  return (
    <>
      <Link
        to=""
        style={{ color: '#305a7f', margin: 0, textDecoration: 'underline' }}
        onClick={(e) => {
          e.preventDefault();
          setExposuresDialogOpen(true);
        }}
      >
        {formattedPercentage}
      </Link>
      {exposuresDialogOpen && (
        <CardDialog isDialog title={kpiInfoDict.desc} onClose={() => setExposuresDialogOpen(false)}>
          <Typography>{`Score: ${formattedPercentage}`}</Typography>
          <div style={{ marginTop: '16px', marginBottom: '16px' }}>
            <CardDialog title={`Exposures Meeting KPI (${adjusterStatsDict[kpiInfoDict.posKey]})`} outlinedCard>
              <PlainTable
                rows={adjusterStatsDict[kpiInfoDict.posKey + '_exposures']}
                columns={ExposureColumnData}
                maxHeight="35vh"
                hideHeader
              />
            </CardDialog>
          </div>
          <div style={{ marginTop: '16px', marginBottom: '16px' }}>
            <CardDialog title={`Exposures Not Meeting KPI (${adjusterStatsDict[kpiInfoDict.negKey]})`} outlinedCard>
              <PlainTable
                rows={adjusterStatsDict[kpiInfoDict.negKey + '_exposures']}
                columns={ExposureColumnData}
                maxHeight="35vh"
                hideHeader
              />
            </CardDialog>
          </div>
        </CardDialog>
      )}
    </>
  );
}

StandardKpiContainer.propTypes = {
  adjusterStatsDict: PropTypes.object.isRequired,
  kpiInfoDict: PropTypes.object.isRequired,
};

const ExposureColumnData = [
  {
    id: 'claim_id_display_with_exposure_suffix',
    numeric: false,
    disablePadding: false,
    label: 'Exposure',
    // eslint-disable-next-line react/display-name
    specialCell: (entry) => (
      <ClaimLink claimId={entry.claim_id} linkText={entry.claim_id_display_with_exposure_suffix} openInNewTab />
    ),
  },
  { id: 'label_text', label: '' },
];

const ClaimColumnData = [
  {
    id: 'claim_id_display',
    numeric: false,
    disablePadding: false,
    label: 'Claim',
    // eslint-disable-next-line react/display-name
    specialCell: (entry) => <ClaimLink claimId={entry.claim_id} linkText={entry.claim_id_display} openInNewTab />,
  },
];

export default KpiDashboardsContainer;
