import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

const LazyLoadedAllSetIllustration = ({ shouldLoad }) => {
  let WizardAllDoneIllustration = () => <></>;
  if (shouldLoad) {
    try {
      WizardAllDoneIllustration = lazy(() =>
        import(/* webpackChunkName: "LazyLoadedWizardAllDoneIllustration"*/ './WizardAllDoneIllustration')
      );
    } catch (err) {
      throw Error(`Error while lazy loading WizardAllDoneIllustration ${err}`);
    }
  }

  return (
    <Suspense fallback={<div />}>
      <WizardAllDoneIllustration />
    </Suspense>
  );
};

LazyLoadedAllSetIllustration.propTypes = {
  shouldLoad: PropTypes.bool,
};

export default LazyLoadedAllSetIllustration;
