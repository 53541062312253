import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';
import InvolvedInsurerFields from '~/components/Fnol/NewFnolUI/InvolvedParties/InvolvedInsurerFields';

import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { PreDefinedField, preparePreDefinedFields } from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';
import InvolvedPersonDialog, { involvedPersonFields } from '../../../../InvolvedPerson';
import IcdCodesFragment from '../IcdCodesFragment';

import BodilyInjuriesFragment from './BodilyInjuriesFragment';

const involvedPassengerFields = {
  ...involvedPersonFields,
  contact_id: '',
  contact_full_name: '',
  contact: null,
  position: '',
  was_seat_belt_worn: '',
  was_ambulance_needed: '',
  was_hospital_attended: '',
  injuries: [],
  injuries_description: '',
  attorney_contact_id: '',
  attorney_contact_full_name: '',
  representative_contact_id: '',
  representative_contact_full_name: '',
  attorney_reference_number: '',
  note: '',
  is_medicare_eligible: null,
  medicare_number: '',
  hospitals: [],
};

const getInvolvedPassengerDefinedFields = (incidentConfiguration) => {
  const preDefinedFields = {
    ...incidentConfiguration.involved_parties.involved_person,
    ...incidentConfiguration.involved_parties.involved_passenger,
  };

  return preparePreDefinedFields(preDefinedFields);
};

const getInvolvedPassengerValidationFields = (incidentConfiguration) =>
  getAdditionalDataValidations(getInvolvedPassengerDefinedFields(incidentConfiguration));

const PassengerInvolvedFragment = (props) => {
  const { contactSearchProps, disableEditIdentity, disableEditContact, showOnly } = props;

  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInvolvedPassengerDefinedFields(incidentConfiguration);

  return (
    <Grid container spacing={1} alignItems="center">
      <PreDefinedField
        id="contact_id"
        fields={preDefinedFields}
        inline
        disabled={disableEditIdentity || disableEditContact}
        readOnly={showOnly}
        contactSearchProps={contactSearchProps}
        fixedSearchResults
      />
      <PreDefinedField id="relationship_to_policyholder" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField
        id="attorney_contact_id"
        fields={preDefinedFields}
        inline
        fixedSearchResults
        readOnly={showOnly}
      />
      <PreDefinedField id="attorney_reference_number" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="representative_contact_id" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="position" fields={preDefinedFields} inline disabled={showOnly} readOnly={showOnly} />
      <PreDefinedField
        id="was_seat_belt_worn"
        fields={preDefinedFields}
        inline
        disabled={showOnly}
        readOnly={showOnly}
      />
      <PreDefinedField
        id="was_ambulance_needed"
        fields={preDefinedFields}
        inline
        disabled={showOnly}
        readOnly={showOnly}
      />
      <PreDefinedField id="was_hospitalized" fields={preDefinedFields} inline disabled={showOnly} readOnly={showOnly} />
      <PreDefinedField id="hospital_contact_id" fields={preDefinedFields} inline gridXs={4} readOnly={showOnly} />
      <PreDefinedField id="admission_datetime" fields={preDefinedFields} inline readOnly={showOnly} gridXs={4} />
      <PreDefinedField id="discharge_datetime" fields={preDefinedFields} inline readOnly={showOnly} gridXs={4} />
      <InvolvedInsurerFields preDefinedFields={preDefinedFields} showOnly={showOnly} />
      <PreDefinedField id="legal_venue_state" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="legal_venue_reason" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="injury_date" fields={preDefinedFields} inline readOnly={showOnly} />
      <BodilyInjuriesFragment showOnly={showOnly} />
      <PreDefinedField id="injuries_description" fields={preDefinedFields} inline readOnly={showOnly} gridXs={12} />
      <IcdCodesFragment showOnly={showOnly} fields={preDefinedFields} />
      <PreDefinedField id="note" fields={preDefinedFields} inline readOnly={showOnly} />
    </Grid>
  );
};

PassengerInvolvedFragment.propTypes = {
  disabled: PropTypes.bool,
  disableEditIdentity: PropTypes.bool,
  showOnly: PropTypes.bool,
  contactSearchProps: PropTypes.object,
  disableEditContact: PropTypes.bool,
  hideInjuries: PropTypes.bool,
};

const ShowOnlyPassengerInvolvedDialog = (props) => {
  const { open, passenger, onCancel } = props;
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <InvolvedPersonDialog
      person={{ ...passenger }}
      onCancel={onCancel}
      open={open}
      title="Passenger Details"
      CustomInvolvedPersonFragment={PassengerInvolvedFragment}
      customValidationFields={getInvolvedPassengerValidationFields(incidentConfiguration)}
      customInitialFields={involvedPassengerFields}
      showOnly
    />
  );
};

ShowOnlyPassengerInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  passenger: requiredIf(PropTypes.object, (props) => props.open),
  onCancel: PropTypes.func.isRequired,
};

export { getInvolvedPassengerValidationFields, involvedPassengerFields };

export { ShowOnlyPassengerInvolvedDialog };
export default PassengerInvolvedFragment;
