const MGM_LABEL_DICT = {
  gl_claim: {
    liquor_liability: {
      desc: 'Liquor liability',
    },
    auto_liability: {
      desc: 'Auto liability',
    },
    pollution: {
      desc: 'Pollution',
    },
    valet: {
      desc: 'Valet',
    },
    aviation: {
      desc: 'Aviation',
    },
    marine: {
      desc: 'Marine',
    },
    third_party_property_damage: {
      desc: '3rd Party Property Damage/Innkeeper',
    },
    bodily_injury: {
      desc: 'Bodily injury',
    },
    horse_legal_liability: {
      desc: 'Horse Legal Liability',
    },
    human_trafficking: {
      desc: 'Human Trafficking',
    },
  },
  wc_claim: {
    pending: {
      desc: 'Pending',
    },
    denied: {
      desc: 'Denied',
    },
    medical_only: {
      desc: 'Medical Only',
    },
    lost_time: {
      desc: 'Lost Time',
    },
    c1_not_reportable: {
      desc: 'C1 - not reportable',
    },
  },
} as const;

export default MGM_LABEL_DICT;
