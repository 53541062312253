import React from 'react';

import type { ContactFullModel } from '~/components/Contacts/types';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import cn from '~/Utils/cn';

interface IdentityHeaderPropsType {
  contact: ContactFullModel;
}

const IdentityHeader: React.FC<IdentityHeaderPropsType> = ({ contact }) => {
  return (
    <OverflowTextWithToolTip textWrapperClassName={cn(contact.is_deceased && 'text-slate-700')} maxWidth="400px">
      {`${contact.title} ${contact.full_name}`}
      {contact.is_deceased ? ' (deceased)' : ''}
    </OverflowTextWithToolTip>
  );
};

export default IdentityHeader;
