import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ReplyAllIcon = (props) => (
  <SvgIcon fill="none" {...props}>
    <path d="M8 9.5L5 6.5L8 3.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 9.5L2 6.5L5 3.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14 11.5C14 10.1739 13.5259 8.90215 12.682 7.96447C11.8381 7.02678 10.6935 6.5 9.5 6.5H5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ReplyAllIcon;
