const FINANCIAL_STATUS_DICT = {
  in_progress: {
    desc: 'In Progress',
    is_active: true,
    is_cancellable: true,
    active_payment_position: 1,
  },
  submitted: {
    desc: 'Submitted',
    is_active: true,
    is_cancellable: true,
    active_payment_position: 3,
  },
  issued: {
    desc: 'Issued',
    is_active: true,
    is_cancellable: true,
    active_payment_position: 4,
  },
  success: {
    desc: 'Success',
    is_active: true,
    is_cancellable: false,
    active_payment_position: 5,
  },
  stopped: {
    desc: 'Stopped',
    is_active: false,
    is_cancellable: false,
  },
  cancelled: {
    desc: 'Cancelled',
    is_active: false,
    is_cancellable: false,
  },
  pending_approval: {
    desc: 'Pending Approval',
    is_active: false,
    is_cancellable: false,
  },
  cancel_pending: {
    desc: 'Cancel Pending',
    is_active: true,
    is_cancellable: false,
    should_remove_in_patch_api_error: true,
    active_payment_position: 0,
  },
  error: {
    desc: 'Error',
    is_active: false,
    is_cancellable: false,
  },
  pending_submission: {
    desc: 'Pending Submission',
    is_active: true,
    is_cancellable: true,
    should_remove_in_patch_api_error: true,
    active_payment_position: 2,
  },
} as const;

export default FINANCIAL_STATUS_DICT;
