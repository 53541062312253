import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const ReminderIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill={iconColor} disableStrokeColor {...props}>
    <path
      d="M12 22.5c-1 0-1.94-.39-2.65-1.1-.71-.71-1.1-1.65-1.1-2.65H4.59a1.503 1.503 0 0 1-1.3-.75c-.13-.23-.2-.49-.2-.75s.07-.52.2-.75c.56-.96 1.22-2.78 1.22-6v-.75a7.509 7.509 0 0 1 2.2-5.32c.7-.7 1.53-1.25 2.45-1.62.92-.38 1.89-.54 2.88-.56 4.1.03 7.43 3.43 7.43 7.58v.67c0 3.22.66 5.04 1.22 6a1.494 1.494 0 0 1-1.3 2.24h-3.66c0 1-.39 1.94-1.1 2.65-.71.71-1.65 1.1-2.65 1.1l.02.01Zm-2.25-3.75c0 .6.23 1.17.66 1.59.85.85 2.33.85 3.18 0 .42-.42.66-.99.66-1.59h-4.5Zm2.26-15c-.78 0-1.54.15-2.27.45a6.1 6.1 0 0 0-1.96 1.3c-.56.56-1 1.22-1.31 1.95-.3.73-.46 1.51-.46 2.3v.75c0 3.55-.77 5.63-1.42 6.75H19.4c-.65-1.12-1.42-3.2-1.42-6.75v-.67c0-3.33-2.67-6.06-5.94-6.08h-.03Z"
      fill={iconColor}
    />
  </SvgIcon>
);

ReminderIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default ReminderIcon;
