import _ from 'lodash';

import type { Project, User, View, Workbook } from '~/components/ReportsPage/types';
import { reportTableauError } from '~/Utils';

// Personal Space Project - a dummy project that represents the personal space of the user
// Tableau doesn't have an id for that project, so we use a constant.
export const PERSONAL_SPACE_PROJECT_ID = 'personal_space';
export const buildPersonalSpaceProject = (): Project => ({
  id: PERSONAL_SPACE_PROJECT_ID,
  name: ' ',
  workbooks: [],
});

/**
 * fixAdminUserName - fix the admin username to be 'System' instead of 'admin'
 * @param userName
 */
export const fixAdminUserName = (userName?: string): string | undefined => {
  return userName === 'admin' ? 'System' : userName;
};

interface UsersProps {
  users: User[];
}

export const getAdminUser = ({ users }: UsersProps): User | undefined => {
  const admin = users?.find((user) => user.name === 'admin');
  if (!admin) {
    const error = new Error(`Tableau Configuration Problem:
        User 'admin' is missing from the returned Users. 
        please check Tableau configuration and permissions for this user.`);
    reportTableauError(error);
  }

  return admin;
};

export const verifyWorkbookProjectExists = ({
  workbooksByProject,
  project,
}: {
  workbooksByProject: { [key: string]: Workbook[] };
  project: Project;
}): void => {
  if (!workbooksByProject[project.id]) {
    const error = new Error(`Tableau Configuration Problem: 
      Project ${project.id} appears in some workbooks but is missing on the returned Projects for this user. 
      please check Tableau configuration and permissions for this user.`);
    reportTableauError(error);
  }
};

export const getLastSegment = (path: string): string => path?.split('/')?.slice(-1)[0] || '';

export const removeLastSegment = (path: string): string => path.substring(0, path.lastIndexOf('/')) || '';

interface ProjectsParam {
  projects: Project[];
}

export const sortProjects = ({ projects }: ProjectsParam): Project[] => {
  const projectMap = _.keyBy(projects, 'name');
  const adjuster = projectMap['adjuster'];
  const management = projectMap['management'];
  const general = projectMap['general'];
  return [adjuster, management, general].filter(Boolean);
};

export const getNonPersonalSpaceProjects = ({ projects }: ProjectsParam): Project[] => {
  const filtered = projects.filter((project) => project.id !== PERSONAL_SPACE_PROJECT_ID);
  return sortProjects({ projects: filtered });
};

export const getPersonalSpaceProject = ({ projects }: ProjectsParam): Project[] => {
  return projects.filter((project) => project.id === PERSONAL_SPACE_PROJECT_ID);
};

export const hasWorkbooks = ({ projects }: ProjectsParam): boolean => {
  if (projects?.length === 0) return false;

  return projects.some((project) => project?.workbooks?.length);
};

export const getBackButtonText = (url: string): string => {
  const parentPageUrl = removeLastSegment(url);
  const parentPageName = getLastSegment(parentPageUrl);
  if (parentPageName === 'reports-dashboards') return 'Back to Published Reports and Dashboard';
  if (parentPageName === 'my-drafts') return 'Back to My Drafts';
  return 'Back to Reports & Dashboards';
};

export const fixContentUrl = (view: View): View => {
  if (view.content_url) {
    view.content_url = getLastSegment(view.content_url);
  }
  return view;
};

interface BuildProjectsMapProps {
  views: View[];
  workbooks: Workbook[];
  projects: Project[];
  users: User[];
}
/**
 * buildProjectRelations - build structural data:  Projects > Workbooks > Views and return a map of projects
 * @param views
 * @param workbooks
 * @param projects
 * @param users
 */
export const buildProjectRelations = ({ views, workbooks, projects, users }: BuildProjectsMapProps): Project[] => {
  // Enrich workbooks with owner name and views
  const usersMap = _.keyBy(users, 'id');
  const fixedViews = views.map(fixContentUrl);
  const viewsByWorkbook = _.groupBy(fixedViews, 'workbook_id');
  const workbooksWithViews = workbooks.map((workbook: Workbook) => ({
    ...workbook,
    owner_name: usersMap[workbook.owner_id || '']?.name || 'Unknown',
    views: viewsByWorkbook[workbook.id] || [],
  }));

  // attach workbooks to project
  const workbooksByProject = _.groupBy(workbooksWithViews, 'project_id');
  const personalSpaceProject = buildPersonalSpaceProject();
  const projectsWithWorkbooks = [personalSpaceProject, ...projects];

  projectsWithWorkbooks.forEach((project) => {
    verifyWorkbookProjectExists({ workbooksByProject, project });
    const projectWorkbooks = workbooksByProject[project.id] || [];

    project.workbooks = projectWorkbooks.sort((a: Workbook, b: Workbook) => {
      if (a.owner_name === 'admin') return -1;
      if (b.owner_name === 'admin') return 1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return a.updated_at > b.updated_at ? -1 : 1;
    });
  });

  return projectsWithWorkbooks;
};

export interface IReportsFilterParams {
  searchText: string;
  selectedOwners: User[];
  projects: Project[];
}
export const filterProjects = ({ searchText, selectedOwners, projects }: IReportsFilterParams): Project[] => {
  const shouldFilter = searchText || selectedOwners?.length;
  if (!shouldFilter) return projects;

  const hasSelectedOwners = !!selectedOwners?.length;
  return projects.map((project) => {
    const filteredWorkbooks = project?.workbooks?.filter((workbook) => {
      const isSearchMatch = !searchText || workbook.name.toLowerCase().includes(searchText.toLowerCase());
      const isOwnerMatch = !hasSelectedOwners || selectedOwners.some((owner) => workbook?.owner_id === owner.id);

      return isSearchMatch && isOwnerMatch;
    });

    return {
      ...project,
      workbooks: filteredWorkbooks,
    };
  });
};

export const finalizeProjectName = (projectName: string): string => {
  const trimmed = projectName?.trim();
  if (!trimmed) return '';
  if (trimmed === 'personal_space') return 'My Drafts';
  const capitalized = projectName.charAt(0).toUpperCase() + projectName.slice(1);
  return `${capitalized} Suite`;
};
