import React from 'react';

import { AiAvatar } from '~/components/AiChat/components/Message/AiAvatar';
import { AiMessageContainer } from '~/components/AiChat/components/Message/AiMessageContainer';
import { BaseMessage } from '~/components/AiChat/components/Message/Message';
import type { BaseMessageProps, MessageProps } from '~/components/AiChat/components/Message/MessageTypes';
import { AI_NAME } from '~/components/AiChat/components/Message/MessageTypes';

export const AiMessage: React.FC<
  MessageProps &
    Pick<
      BaseMessageProps,
      'onUserFeedback' | 'withTypeEffect' | 'onType' | 'onTypeEnd' | 'onRegenerate' | 'showDisclaimer' | 'createdAt'
    >
> = ({ exchange, onUserFeedback, withTypeEffect, onType, onRegenerate, showDisclaimer }) => {
  return (
    <BaseMessage
      text={exchange?.response || ''}
      avatar={<AiAvatar />}
      sender={AI_NAME}
      isLoading={!exchange?.response}
      withActions={!!exchange.response}
      withFeedback
      withTypeEffect={withTypeEffect}
      onType={onType}
      onTypeEnd={onType}
      onUserFeedback={onUserFeedback}
      onRegenerate={onRegenerate}
      exchangeId={exchange.id}
      currentUserFeedback={exchange?.user_feedback_score}
      messageContainer={AiMessageContainer}
      referencesIds={exchange.documents_used}
      showDisclaimer={showDisclaimer}
      createdAt={exchange.created_at}
    />
  );
};
