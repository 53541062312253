import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { COLUMN_KEYS, EXTENDABLE_COLUMN_KEYS } from '~/components/Finances/FinancesTable/constants';

import CardDialog from '../../CardDialog';
import { useClaim } from '../../ClaimContainer';
import FinancesFilters from '../FinancesFilters';
import FinancesTable from '../FinancesTable';

import styles from './financesScreen.module.scss';

const FinancesScreen = ({
  selectedFilters,
  onFiltersChange,
  finances,
  isLoading,
  isError,
  page,
  setPage,
  totalFinancesCount,
  sortByColumn,
  setSortByColumn,
  isDisablePagination,
  financesPerPage,
}) => {
  const { onClaimUpdate } = useClaim();
  const classes = useStyles();
  const displayLoading = isLoading || isError;

  return (
    <Grid container>
      <Grid item md={12}>
        <div className={classes.cardDivRow}>
          <CardDialog headerStyle={{ paddingLeft: '29px' }}>
            <Grid container className={styles.screenWrapper}>
              <Grid item xs={12} className={styles.titleWrapper}>
                <div className={styles.title}>Filters</div>
              </Grid>
              <Grid item xs={12}>
                <FinancesFilters selectedFilters={selectedFilters} setSelectedFilters={onFiltersChange} />
              </Grid>
              <Grid item xs={12}>
                {displayLoading && <SkeletonTable rowsCount={5} columnsCount={8} isError={isError} />}
                {!displayLoading && (
                  <FinancesTable
                    finances={finances}
                    paginationProps={{
                      page,
                      rowsPerPage: financesPerPage,
                      onChangePage: (e, newPage) => {
                        setPage(newPage);
                      },
                      disabled: isDisablePagination,
                      count: totalFinancesCount || 0,
                      rowsPerPageOptions: [financesPerPage],
                    }}
                    onSortByColumn={(_, sortByColumn) => {
                      setSortByColumn(sortByColumn);
                      setPage(0);
                    }}
                    displayColumnIds={DISPLAY_COLUMN_IDS}
                    sortByColumn={sortByColumn}
                    onReloadFinances={onClaimUpdate}
                  />
                )}
              </Grid>
            </Grid>
          </CardDialog>
        </div>
      </Grid>
    </Grid>
  );
};

const DISPLAY_COLUMN_IDS = [
  COLUMN_KEYS.request_date,
  COLUMN_KEYS.financial_status_last_update,
  COLUMN_KEYS.exposure_label,
  COLUMN_KEYS.payable_with_reserve_type,
  COLUMN_KEYS.request_status,
  COLUMN_KEYS.financial_status,
  COLUMN_KEYS.amount,
  COLUMN_KEYS.actions,

  EXTENDABLE_COLUMN_KEYS.sub_reserves,
  EXTENDABLE_COLUMN_KEYS.issued_by,
  EXTENDABLE_COLUMN_KEYS.payment_type,
  EXTENDABLE_COLUMN_KEYS.payees,
  EXTENDABLE_COLUMN_KEYS.sent_to,
  EXTENDABLE_COLUMN_KEYS.reference,
  EXTENDABLE_COLUMN_KEYS.note,
  EXTENDABLE_COLUMN_KEYS.deductible_applied,
  EXTENDABLE_COLUMN_KEYS.payment_method,
];

FinancesScreen.propTypes = {
  selectedFilters: PropTypes.object,
  onFiltersChange: PropTypes.func,
  finances: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  page: PropTypes.number,
  setPage: PropTypes.func,
  totalFinancesCount: PropTypes.number,
  sortByColumn: PropTypes.object,
  setSortByColumn: PropTypes.func,
  isDisablePagination: PropTypes.bool,
  financesPerPage: PropTypes.number,
};

export default FinancesScreen;
