import React, { useState } from 'react';
import type { AxiosResponse } from 'axios';
import { useFormikContext } from 'formik';
import type { BaseSchema } from 'yup';

import CardDialog from '~/components/CardDialog';
import type { ContactFullModel } from '~/components/Contacts/types';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import AssignmentProgressStep from '~/components/exposures/moi/MoiStepper/AssignmentProgressStep';
import { getAssignmentProgressStepConfig } from '~/components/exposures/moi/MoiStepper/MoiSteps';
import MoiStepWrapper from '~/components/exposures/moi/MoiStepper/MoiStepWrapper';
import MoiSummary from '~/components/exposures/moi/MoiStepper/MoiSummary';
import type { MoiAlert, MoiFormikValues } from '~/components/exposures/moi/MoiStepper/types';
import type { UseMoiMethodsResults } from '~/components/exposures/moi/MoiStepper/useMoiMethods';
import { EDIT_MOI_INSTRUCTIONS_TEXT } from '~/components/exposures/moi/MoiUtils';
import type { ExposureModel } from '~/components/exposures/types';

import Text from '../../../core/TextComponents/Text';

interface EditMoiDialogInnerProps {
  exposure?: ExposureModel;
  selectedContact?: ContactFullModel;
  setSelectedContact: (contact: ContactFullModel | undefined) => void;
  getIsContactVendorApi: (contact: ContactFullModel) => Promise<AxiosResponse<{ is_vendor_api: boolean }>>;
  setIsXactContractor: (isXactContractor: boolean) => void;
  setIsVendorApi: (isVendorApi: boolean) => void;
  isVendorApi: boolean;
  onClose?: () => void;
  setCurrentValidation: (currentValidation: BaseSchema) => void;
  currentValidation: BaseSchema;
  setAlertMessage: (alertMessage: MoiAlert) => void;
  alertMessage: MoiAlert;
  handleSubmitForm: () => void;
  moiMethodsReturn: UseMoiMethodsResults;
}

const EditMoiDialogInner: React.FC<EditMoiDialogInnerProps> = ({
  onClose,
  alertMessage,
  exposure,
  selectedContact,
  setSelectedContact,
  getIsContactVendorApi,
  setIsXactContractor,
  setIsVendorApi,
  isVendorApi,
  setCurrentValidation,
  currentValidation,
  setAlertMessage,
  handleSubmitForm,
  moiMethodsReturn,
}) => {
  const formikContext = useFormikContext<MoiFormikValues>();
  const [edited, setEdited] = useState(false);

  return (
    <CardDialog
      subheader={
        <>
          <Text variant={Text.VARIANTS.XL}>Edit MOI (Method of Inspection)</Text>
          <div className="mb-32 mt-20 leading-5 text-slate-800">{EDIT_MOI_INSTRUCTIONS_TEXT}</div>
        </>
      }
      maxWidth="lg"
      fullWidth
      onClose={onClose}
      trackAlt="Edit Method of Inspection Card"
      isDialog
      headerStyle={{ padding: '0 0 0 20px' }}
      containerClassName="overflow-hidden pt-0"
      footerClassName="p-0 shadow-lg"
      footerActions={
        <DialogFooterActions
          primaryDisabled={formikContext.isSubmitting || !edited}
          onClickPrimary={handleSubmitForm}
          onClickSecondary={onClose}
          primaryLabel="Update"
        />
      }
    >
      <div className="flex">
        <div className="flex flex-1 flex-col justify-between">
          <MoiStepWrapper alertMessage={alertMessage}>
            <AssignmentProgressStep
              exposure={exposure}
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              getIsContactVendorApi={getIsContactVendorApi}
              setIsXactContractor={setIsXactContractor}
              setIsVendorApi={setIsVendorApi}
              isVendorApi={isVendorApi}
              setCurrentValidation={setCurrentValidation}
              currentValidation={currentValidation}
              setAlertMessage={setAlertMessage}
              alertMessage={alertMessage}
              setEdited={setEdited}
              isEditMode
              moiMethodsReturn={moiMethodsReturn}
            />
          </MoiStepWrapper>
        </div>
        <MoiSummary
          exposure={exposure}
          currentStep={getAssignmentProgressStepConfig({ formikContext })}
          selectedContact={selectedContact}
          isEditMode
          moiMethodsReturn={moiMethodsReturn}
        />
      </div>
    </CardDialog>
  );
};

export default EditMoiDialogInner;
