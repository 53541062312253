import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import { concat, isEmpty } from 'lodash';

import cn from '~/Utils/cn';

import { useClaim } from '../../ClaimContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../core';
import { useCms } from '../../hooks/useCms';
import HoverActionField from '../../HoverActionField';
import ExposureTooltipedLock from '../ExposureTooltipedLock';
import { isExposureWriteDisabled } from '../ExposureUtils';

import { trackMoiClicked } from './MoiUtils';
import NewMoiCard from './NewMoiCard';

import styles from './NewMoiContainer.module.scss';

const NewMoiContainer = ({ exposure }) => {
  const { user, userOrganization } = useCms();
  const { claim } = useClaim();
  const [openMoiCard, setOpenMoiCard] = useState(false);

  const claimLevelMoiEnabled = userOrganization?.configuration?.moi_configuration?.claim_level_moi_enabled;
  const claimLevelMoiRecords = claim?.claim_level_methods_of_inspection || [];
  let moiRecords = exposure?.methods_of_inspection || [];

  if (claimLevelMoiEnabled) {
    if (exposure?.isGeneralExpenses) {
      moiRecords = claimLevelMoiRecords;
    } else {
      moiRecords = concat(moiRecords, claimLevelMoiRecords);
    }
  }

  const isMoiRecordsExists = !isEmpty(moiRecords);
  const isMoiAssigned = exposure?.is_moi_assigned || (exposure?.isGeneralExpenses && isMoiRecordsExists);
  const readOnly = isExposureWriteDisabled(exposure, user) || exposure.is_coverage_issue_exists;
  const labelClassNames = cn(styles.moiAssignmentLabel, {
    [styles.isGeneral]: exposure?.isGeneralExpenses,
    [styles.isNotAssigned]: !isMoiAssigned,
  });

  if ((!isMoiRecordsExists && readOnly) || exposure?.is_coverage_issue_exists || exposure?.is_closed) {
    return <ExposureTooltipedLock exposure={exposure} />;
  }

  const handleActionClick = () => {
    setOpenMoiCard(true);
    if (!isMoiAssigned) {
      trackMoiClicked({ claim, exposure, actionType: 'add' });
    }
  };

  return (
    <>
      <RestrictedPermissions
        action={PERMISSION_ACTIONS.MOI}
        verb={isMoiAssigned ? PERMISSION_VERBS.READ : PERMISSION_VERBS.WRITE}
      >
        <HoverActionField
          icon={readOnly ? InfoIcon : undefined}
          onAction={handleActionClick}
          permanent
          actionAriaLabel="Set method of inspection"
        >
          <span className={labelClassNames}>{isMoiAssigned ? 'Assigned' : 'Not Assigned'}</span>
        </HoverActionField>
      </RestrictedPermissions>

      {openMoiCard && (
        <NewMoiCard
          moiRecords={moiRecords}
          exposure={exposure}
          onClose={() => setOpenMoiCard(false)}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

NewMoiContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export default NewMoiContainer;
