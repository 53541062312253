import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { stringCmp } from '~/Utils';

import LoadingIndicator from '../LoadingIndicator';
import { useSysconfig } from '../SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '../useDataFetcher';

import { usePaymentsConfiguration } from './usePaymentsConfiguration';

const subReservesFilterHidden = (subReserveKey, configObj, payableWithReserve) => {
  let hasSubReservesAmount = false;

  if (payableWithReserve) {
    hasSubReservesAmount = payableWithReserve.sub_reserves?.[subReserveKey] > 0;
  }

  return !configObj[subReserveKey].is_hidden || hasSubReservesAmount;
};

const subReservesConfigObjToMap = (configObj, payableWithReserve = undefined) => {
  const map = new Map();

  Object.keys(configObj)
    .sort((a, b) => stringCmp(configObj[a].desc, configObj[b].desc))
    .filter((subReserveKey) => subReservesFilterHidden(subReserveKey, configObj, payableWithReserve))
    .forEach((subReservesKey) => {
      map.set(subReservesKey, configObj[subReservesKey]);
    });
  return map;
};

export const useExposureSubReserves = (payableType, exposure) => {
  const { isExpensesSubReservesConfigEnabled, expensesSubReservesConfig, isMultipleSubReservePaymentsEnabled } =
    usePaymentsConfiguration();

  const isSubReservesConfigEnabled =
    payableType === 'expenses'
      ? isExpensesSubReservesConfigEnabled
      : exposure?.is_indemnity_sub_reserves_config_enabled || false;

  const getSubReservesObj = () => {
    if (!isSubReservesConfigEnabled) {
      return null;
    }

    if (payableType === 'expenses') {
      return expensesSubReservesConfig;
    } else {
      return exposure?.indemnity_sub_reserves_config;
    }
  };

  const getSubReservesMap = () => {
    const subReservesObj = getSubReservesObj();

    if (!subReservesObj) {
      return null;
    }

    const payableWithReserve = exposure[payableType];

    return subReservesConfigObjToMap(subReservesObj, payableWithReserve);
  };

  const subReservesConfigMap = getSubReservesMap();

  return {
    isSubReservesConfigEnabled,
    isMultipleSubReservePaymentsEnabled: isSubReservesConfigEnabled && isMultipleSubReservePaymentsEnabled,
    subReservesConfigMap,
  };
};

const SubReservesContext = React.createContext({});

export const SubReservesContextProvider = ({ children }) => {
  const { organization } = useSysconfig();
  const [configsDict, setConfigsDict] = useState({});

  const { isExpensesSubReservesConfigEnabled, expensesSubReservesConfig } = usePaymentsConfiguration();
  const { isLoading, isError, data } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/coverages`);

  useEffect(() => {
    if (!isLoading && data) {
      setConfigsDict(_.mapKeys(data, 'coverage_key'));
    }
  }, [data, isLoading]);

  const getIsExpensesSubReservesEnabled = useCallback(() => {
    return isExpensesSubReservesConfigEnabled;
  }, [isExpensesSubReservesConfigEnabled]);

  const getExpensesSubReservesConfigMap = useCallback(() => {
    return subReservesConfigObjToMap(expensesSubReservesConfig);
  }, [expensesSubReservesConfig]);

  const getIsIndemnitySubReservesEnabled = useCallback(
    (coverageKey) => {
      if (!coverageKey) {
        return null;
      }

      return configsDict?.[coverageKey]?.is_sub_reserves_config_enabled;
    },
    [configsDict]
  );

  const getIndemnitySubReservesConfigMap = useCallback(
    (coverageKey) => {
      if (getIsIndemnitySubReservesEnabled(coverageKey)) {
        return subReservesConfigObjToMap(configsDict[coverageKey].sub_reserves_config);
      }
      return null;
    },
    [configsDict, getIsIndemnitySubReservesEnabled]
  );

  if (isLoading) {
    return <LoadingIndicator isError={isError} />;
  }

  const contextValues = {
    isLoading,
    isError,
    getIsExpensesSubReservesEnabled,
    getExpensesSubReservesConfigMap,
    getIsIndemnitySubReservesEnabled,
    getIndemnitySubReservesConfigMap,
  };
  return <SubReservesContext.Provider value={contextValues}>{children}</SubReservesContext.Provider>;
};

SubReservesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSubReservesConfigurations = () => {
  return useContext(SubReservesContext);
};
