import { useCallback, useEffect, useRef, useState } from 'react';
import { noop } from 'lodash';

import { authenticateTableauServer } from '../../tableauAuthenticationUtils';
import { getTabName, reportAxiosError } from '../../Utils';
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';
import {
  addFilterEventListener,
  buildAuthUrl,
  buildViewUrl,
  EXPORT_TYPES,
  TABLEAU_API_SCRIPT_SRC,
} from '../ReportsDashboardsPage/tableauUtils';

import useScript from './useScript';

const useTableauOld = ({
  options = {},
  retryCount = 1,
  workbook,
  view,
  selfServiceUrl,
  onFilterApplied = noop,
} = {}) => {
  const { isLoading: isScriptLoading, isError: isScriptError } = useScript({ scriptUrl: TABLEAU_API_SCRIPT_SRC });
  const { user, userOrganization } = useCms();
  const { orgTableauConfigs } = useOrganization();
  const [tableauVizInstance, setTableauVizInstance] = useState(null);
  const [retry, setRetry] = useState(retryCount);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [token, setToken] = useState(null);
  const tableauRef = useRef(null);
  const { tableau: tableauApiInstance } = window;
  const { host_url, target_site, auth_workbook, auth_view } = orgTableauConfigs;
  const authEndpointUrl = buildAuthUrl(user.organization_id);
  const { parentTab } = getTabName();

  const initViz = useCallback(async () => {
    return await new Promise((resolve, reject) => {
      try {
        const url = selfServiceUrl
          ? selfServiceUrl
          : buildViewUrl({
              serverBaseUrl: host_url,
              site: target_site,
              workbook,
              view,
            });
        const tableauOptions = {
          hideToolbar: false,
          hideTabs: true,
          width: `${window.innerWidth - 150}px`,
          height: `${window.innerHeight - 200}px`,
          ...options,
          onFirstInteractive: () => {
            resolve();
          },
        };

        const vizInstance = new tableauApiInstance.Viz(tableauRef.current, url, tableauOptions);
        setTableauVizInstance(vizInstance);
      } catch (error) {
        reject(error);
      }
    });
  }, [selfServiceUrl, host_url, options, tableauApiInstance, target_site, view, workbook]);

  const authAndInitTableau = useCallback(async () => {
    try {
      setRetry(retry - 1);

      const token = await authenticateTableauServer({
        serverBaseUrl: host_url,
        site: target_site,
        workbook: auth_workbook,
        view: auth_view,
        authEndpointUrl,
        onError: (error) => reportAxiosError(error),
      });
      setToken(token);

      await initViz();
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  }, [authEndpointUrl, auth_view, auth_workbook, host_url, initViz, retry, target_site]);

  useEffect(() => {
    if (isScriptError && !isError) {
      setIsLoading(false);
      setIsError(true);
    }

    if (tableauVizInstance) {
      if (parentTab === 'reports') {
        addFilterEventListener({ tableauApiInstance, tableauVizInstance, onFilterApplied });
      }

      setIsLoading(false);
    }

    if (!isScriptLoading && tableauApiInstance && tableauRef.current && retry) {
      return authAndInitTableau();
    }
  }, [
    initViz,
    tableauApiInstance,
    tableauRef,
    retry,
    isScriptLoading,
    isScriptError,
    isError,
    tableauVizInstance,
    onFilterApplied,
    host_url,
    target_site,
    auth_workbook,
    auth_view,
    authEndpointUrl,
    parentTab,
    user,
    userOrganization,
    authAndInitTableau,
  ]);

  const exportTableauView = useCallback(
    (exportType) => {
      if (isLoading || !exportType) {
        return;
      }

      if (!tableauVizInstance) {
        throw new Error('Tableau is not initialized.');
      }

      switch (exportType) {
        case EXPORT_TYPES.CSV:
          return tableauVizInstance?.showExportCrossTabDialog();
        case EXPORT_TYPES.EXCEL:
          return tableauVizInstance?.exportCrossTabToExcel();
        case EXPORT_TYPES.PDF:
          return tableauVizInstance?.showExportPDFDialog();
        default:
          throw new Error('Unsupported export type.');
      }
    },
    [isLoading, tableauVizInstance]
  );

  return {
    tableauApiInstance,
    tableauVizInstance,
    tableauRef,
    exportTableauView,
    isLoading,
    isError,
    token,
  };
};

export default useTableauOld;
