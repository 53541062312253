import { isReportViewer } from '../../Utils';
import { isPermissionsEnabled, PERMISSION_ACTIONS, PERMISSION_VERBS } from '../core/Permissions/PermissionUtils';
import useOrganization from '../OrganizationContext';

import { useCms } from './useCms';
import { useHasPermission } from './useHasPermission';

export const useIsReportViewer = () => {
  const { user, userOrganization } = useCms();
  const { orgAdditionalConfigs } = useOrganization();
  const userHasReportsPermissions = useHasPermission({
    action: PERMISSION_ACTIONS.REPORT,
    verb: PERMISSION_VERBS.READ,
  });

  return isPermissionsEnabled(userOrganization)
    ? userHasReportsPermissions
    : isReportViewer(orgAdditionalConfigs, user);
};
