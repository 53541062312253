import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useTableau } from '~/components/ReportsPage/Context/TableauContext';
import { TableauEmbedCreator } from '~/components/ReportsPage/EmbedComponents/TableauEmbedCreator';
import { removeLastSegment } from '~/components/ReportsPage/helpers/ReportsUtils';
import { useTableauEmbedAuth } from '~/components/ReportsPage/Hooks/useTableauEmbedAuth';
import { WorkbookEditorLayout } from '~/components/ReportsPage/pages/WorkbookEditorLayout';

export const WorkbookCreatorPage: React.FC = () => {
  const { token } = useTableauEmbedAuth();
  const { isLoading, reloadAll } = useTableau();

  const history = useHistory();
  const match = useRouteMatch();

  const onWorkbookReadyToClose = () => {
    reloadAll();
    history.push(removeLastSegment(match.url));
  };

  return (
    <WorkbookEditorLayout
      editorComponent={
        <TableauEmbedCreator
          token={token}
          options={{
            width: '100%',
            height: 'calc(100vh - 130px)',
            onWorkbookReadyToClose,
          }}
        />
      }
      isLoading={isLoading}
      token={token}
    />
  );
};
