import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const AttachmentIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" disableStrokeColor {...props}>
    <path
      d="M23.25 12C23.2485 13.3919 22.6949 14.7264 21.7107 15.7107C20.7264 16.6949 19.3919 17.2485 18 17.25H4.5C3.50544 17.25 2.55161 16.8549 1.84835 16.1517C1.14509 15.4484 0.75 14.4946 0.75 13.5C0.75 12.5054 1.14509 11.5516 1.84835 10.8483C2.55161 10.1451 3.50544 9.75 4.5 9.75H18C18.5967 9.75 19.169 9.98705 19.591 10.409C20.0129 10.831 20.25 11.4033 20.25 12C20.25 12.5967 20.0129 13.169 19.591 13.591C19.169 14.0129 18.5967 14.25 18 14.25H7.5C7.30109 14.25 7.11032 14.171 6.96967 14.0303C6.82902 13.8897 6.75 13.6989 6.75 13.5C6.75 13.3011 6.82902 13.1103 6.96967 12.9697C7.11032 12.829 7.30109 12.75 7.5 12.75H18C18.1989 12.75 18.3897 12.671 18.5303 12.5303C18.671 12.3897 18.75 12.1989 18.75 12C18.75 11.8011 18.671 11.6103 18.5303 11.4697C18.3897 11.329 18.1989 11.25 18 11.25H4.5C3.90326 11.25 3.33097 11.4871 2.90901 11.909C2.48705 12.331 2.25 12.9033 2.25 13.5C2.25 14.0967 2.48705 14.669 2.90901 15.091C3.33097 15.5129 3.90326 15.75 4.5 15.75H18C18.9946 15.75 19.9484 15.3549 20.6516 14.6517C21.3549 13.9484 21.75 12.9946 21.75 12C21.75 11.0054 21.3549 10.0516 20.6516 9.34835C19.9484 8.64509 18.9946 8.25 18 8.25H7.5C7.30109 8.25 7.11032 8.17098 6.96967 8.03033C6.82902 7.88968 6.75 7.69891 6.75 7.5C6.75 7.30109 6.82902 7.11032 6.96967 6.96967C7.11032 6.82902 7.30109 6.75 7.5 6.75H18C19.3919 6.75149 20.7264 7.30509 21.7107 8.28933C22.6949 9.27358 23.2485 10.6081 23.25 12Z"
      fill={iconColor}
    />
  </SvgIcon>
);

AttachmentIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default AttachmentIcon;
