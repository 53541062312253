import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const CalledOffIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon stroke="none" fill="none" {...props}>
    <path d="M12.5 3.5L3.5 12.5" stroke={iconColor} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 12.5L3.5 3.5" stroke={iconColor} strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

CalledOffIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default CalledOffIcon;
