import React from 'react';

import SortableTable from './core/Tables/SortableTable';

// wrapper for SortableTable's functionality
const PlainTable = (props) => {
  return <SortableTable {...props} disableSortByUser />;
};

export default PlainTable;
