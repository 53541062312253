import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import { FsTooltip } from '../../../../core';
import { WarningCircleIcon, WarningIcon } from '../../../../icons';
import { LICENSE_EXPIRATION_STATUS } from '../consts';
import { getLicenseStatus } from '../utils';

import colors from '../../../../../assets/colors.module.scss';
import styles from './Licenses.module.scss';

export const LicenseExpirationWarningIcon = ({ id }) => {
  const { values } = useFormikContext();
  const ExpiredIcon = <WarningCircleIcon iconColor={colors.statusFailed} className={styles.licenseWarningIcon} />;
  const ExpiringIcon = <WarningIcon iconColor={colors.statusWarning} className={styles.licenseWarningIcon} />;
  const expirationDate = getIn(values, id);
  const expirationState = getLicenseStatus(expirationDate);

  if (expirationState === LICENSE_EXPIRATION_STATUS.VALID) {
    return null;
  }

  return (
    <div className={styles.licenseWarningIconContainer}>
      <FsTooltip
        title={
          expirationState === LICENSE_EXPIRATION_STATUS.EXPIRED
            ? 'Your license is expired'
            : 'Your license will expire soon'
        }
        placement="top"
      >
        <span>{expirationState === LICENSE_EXPIRATION_STATUS.EXPIRED ? ExpiredIcon : ExpiringIcon}</span>
      </FsTooltip>
    </div>
  );
};

LicenseExpirationWarningIcon.propTypes = {
  id: PropTypes.string.isRequired,
};
