import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useClaim } from '~/components/ClaimContainer';
import EmptyState from '~/components/core/EmptyState';
import RoundEmptyStateWrapper from '~/components/core/EmptyState/RoundEmptyStateWrapper';
import DocumentsAttachment from '~/components/Documents/DocumentsAttachment';
import { attachedDocumentsMaxHeight } from '~/components/exposures/PaymentRequestContainer/paymentContainerUtils';
import { isXactAnalysisIntegrationEnabled } from '~/components/hooks/useXactAnalysisContractor';
import { AttachmentIcon } from '~/components/icons';
import { reportAxiosError } from '~/Utils';

import { useCms } from './useCms';

const useXactAssigment = (moiId) => {
  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const isXactAnalysisEnabled = isXactAnalysisIntegrationEnabled(userOrganization);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [xactTransactionId, setXactTransactionId] = useState('');
  const [xactAssignmentDocuments, setXactAssignmentDocuments] = useState([]);

  useEffect(() => {
    const fetchXactAssignment = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(`/api/v1/xactanalysis/${userOrganization.id}/assignments/by_moi_id/${moiId}`, {
          params: { method_of_inspection_id: moiId },
        });
        if (data) {
          setXactTransactionId(data.xact_transaction_id);
          setXactAssignmentDocuments(
            data?.assignment_documents?.map((assignedDocument) => assignedDocument?.document_id) || []
          );
        }
      } catch (error) {
        setIsError(true);
        await reportAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isXactAnalysisEnabled && moiId && !xactTransactionId) {
      return fetchXactAssignment();
    }
  }, [xactTransactionId, moiId, isXactAnalysisEnabled, userOrganization?.id]);

  return {
    isXactAnalysisEnabled,
    isLoadingXactAssignment: isLoading,
    isErrorXactAssigment: isError,
    isXactAssignment: !!xactTransactionId,
    xactTransactionDetails: (
      <div className="w-full">
        {!xactAssignmentDocuments.length && (
          <EmptyState
            title="No documents were attached to the assignment"
            illustration={
              <RoundEmptyStateWrapper>
                <AttachmentIcon />
              </RoundEmptyStateWrapper>
            }
          />
        )}
        {xactAssignmentDocuments.length > 0 && (
          <>
            <div className="my-12">Assigned Documents</div>
            <DocumentsAttachment
              showOnly
              attachedDocuments={claim.documents.filter((document) => xactAssignmentDocuments.includes(document.id))}
              maxHeight={attachedDocumentsMaxHeight}
              hideAttachmentFilename
            />
          </>
        )}
      </div>
    ),
  };
};

export default useXactAssigment;
