const SUPERVISOR_APPROVAL_STATUS_DICT = {
  pending: {
    desc: 'Pending',
    is_approved: false,
  },
  approved_automatically: {
    desc: 'Approved Automatically',
    is_approved: true,
  },
  approved: {
    desc: 'Approved',
    is_approved: true,
  },
  approved_different: {
    desc: 'Approved Different',
    is_approved: true,
  },
  declined: {
    desc: 'Declined',
    is_approved: false,
  },
  cancelled: {
    desc: 'Cancelled Request',
    is_approved: false,
  },
} as const;

export default SUPERVISOR_APPROVAL_STATUS_DICT;
