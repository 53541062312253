import React from 'react';

import { useStyles } from '~/assets/styles';
import { displayRoleName, getAllSearchableContactRoles } from '~/components/communications/ContactUtils';
import { ContactEntity } from '~/components/Contact';
import type { ContactFullModel } from '~/components/Contacts/types';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import { Text } from '~/components/core';
import { useCms } from '~/components/hooks/useCms';
import useOrganization from '~/components/OrganizationContext';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';
import cn from '~/Utils/cn';

interface NonViewCommunicationCardHeaderContactDetailsProps {
  enableChoosingContact?: boolean;
  contactId?: number;
  contactFullName?: string;
  contactRole?: string;
  onSelectContact?: (contact: ContactFullModel) => void;
  dynamicContactLabel?: string;
  outOfContactsContextSearch?: boolean;
}

const NonViewCommunicationCardHeaderContactDetails: React.FC<NonViewCommunicationCardHeaderContactDetailsProps> = ({
  enableChoosingContact,
  contactId,
  contactFullName,
  contactRole,
  onSelectContact,
  dynamicContactLabel,
  outOfContactsContextSearch,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);

  if (enableChoosingContact) {
    return (
      <div className={cn({ ['w-1/2 max-w-[280px]']: isNewUIEnabled })}>
        <ContactTextFieldFormik
          id="contact"
          label={dynamicContactLabel ? dynamicContactLabel : 'Contact'}
          className={classes.textField}
          fullWidth={isNewUIEnabled}
          style={{ width: '280px' }}
          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict).concat(['user'])} // allow communicating with users
          fixedSearchResults
          onSelectContact={onSelectContact}
          outOfContactsContextSearch={outOfContactsContextSearch}
        />
      </div>
    );
  }

  // Can happen when we reply / forward and the email is not attached to a specific contact
  if (!contactId) {
    return null;
  }

  return (
    <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} className="flex">
      <ContactEntity contactId={contactId} contactDisplayName={contactFullName} />
      <span className="ml-16">
        <em>{displayRoleName(organizationContactRolesDict, contactRole)}</em>
      </span>
    </Text>
  );
};

export default NonViewCommunicationCardHeaderContactDetails;
