import React from 'react';
import { Paper } from '@material-ui/core';

import UserClaimsNotifications from './UserClaimsNotificationsContainer';

function NotificationsPage() {
  return (
    // The <Paper> is needed for the scroll to work
    <Paper>
      <UserClaimsNotifications showFiltering openClaimInNewTab defaultSortByDate />
    </Paper>
  );
}

export default NotificationsPage;
