import React, { useEffect, useRef, useState } from 'react';

import { Text } from '~/components/core/TextComponents';
import colors from '~/theme/tailwind/colors';
import cn from '~/Utils/cn';

import { getCollapsibleChevron } from '../useCollapsible';

export type NumberOfLines = 1 | 2 | 3 | 4 | 5 | 6;

const iconColor = colors?.teal[700];

interface ShowAllCollapsibleProps {
  isOpen?: boolean;
  openByDefault?: boolean;
  onCollapseClick?: (isOpen: boolean) => void;
  visibleLines?: NumberOfLines;
  showAsInlineButton?: boolean;
}

const VISIBLE_LINES_TO_CLASS_MAP: Record<NumberOfLines, string> = {
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
  4: 'line-clamp-4',
  5: 'line-clamp-5',
  6: 'line-clamp-6',
};

const ShowAllCollapsible: React.FC<React.PropsWithChildren<ShowAllCollapsibleProps>> = ({
  children,
  isOpen,
  openByDefault,
  onCollapseClick,
  visibleLines = 2,
  showAsInlineButton = false,
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isActuallyOpen, setIsActuallyOpen] = useState(false);
  const [isInitialOverflowCheckDone, setIsInitialOverflowCheckDone] = useState(false);

  useEffect(() => {
    // NOTICE: This will fail if the visibleLines / children are changed while the component is rendered
    // since it's an edge case, we're ignoring this for now
    if (isInitialOverflowCheckDone) {
      setIsActuallyOpen(isOpen !== undefined ? isOpen : !!openByDefault);
    }
  }, [isOpen, isInitialOverflowCheckDone, openByDefault]);

  useEffect(() => {
    if (containerRef.current) {
      const { clientHeight: containerHeight, scrollHeight } = containerRef.current;
      if (scrollHeight > containerHeight) {
        setIsOverflowing(true);
      }
      setIsInitialOverflowCheckDone(true);
    }
  }, [visibleLines, children]);

  return (
    <div
      className={cn('flex flex-col', {
        'gap-20': !showAsInlineButton,
      })}
    >
      <div
        ref={containerRef}
        className={cn({
          [VISIBLE_LINES_TO_CLASS_MAP[visibleLines]]: !isActuallyOpen,
        })}
      >
        {children}
      </div>
      {isOverflowing ? (
        <div className="row-auto flex items-center">
          <div
            className={cn({
              'row-auto mt-4 flex w-full gap-12': showAsInlineButton,
            })}
            onClick={() => {
              setIsActuallyOpen(!isActuallyOpen);
              onCollapseClick && onCollapseClick(!isActuallyOpen);
            }}
          >
            <div
              className={cn({
                'cursor-pointer': showAsInlineButton,
                'flex justify-end gap-12': !showAsInlineButton,
              })}
            >
              <Text
                variant={Text.VARIANTS.SM}
                weight={Text.WEIGHTS.MEDIUM}
                colorVariant={Text.COLOR_VARIANTS.BUTTON_LINK}
              >
                {isActuallyOpen ? 'Show Less' : 'Show All'}
              </Text>
              {!showAsInlineButton && (
                <div className="flex items-center">
                  {getCollapsibleChevron(isActuallyOpen, iconColor, 'ml-[-4px]', true)}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ShowAllCollapsible;
