import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const PoliciesIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.25 2.25C14.6642 2.25 15 2.58579 15 3V7.5H19.5C19.9142 7.5 20.25 7.83579 20.25 8.25C20.25 8.66421 19.9142 9 19.5 9H14.25C13.8358 9 13.5 8.66421 13.5 8.25V3C13.5 2.58579 13.8358 2.25 14.25 2.25Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 2.25C4.85217 2.25 4.47064 2.40804 4.18934 2.68934C3.90804 2.97064 3.75 3.35217 3.75 3.75V12C3.75 12.4142 4.08579 12.75 4.5 12.75C4.91421 12.75 5.25 12.4142 5.25 12L5.25 3.75L13.9393 3.75L18.75 8.56066V20.25H14.25C13.8358 20.25 13.5 20.5858 13.5 21C13.5 21.4142 13.8358 21.75 14.25 21.75H18.75C19.1478 21.75 19.5294 21.592 19.8107 21.3107C20.092 21.0294 20.25 20.6478 20.25 20.25V8.25C20.25 8.05109 20.171 7.86032 20.0303 7.71967L14.7803 2.46967C14.6397 2.32902 14.4489 2.25 14.25 2.25H5.25ZM6 14.7188V16.7045C6 19.4196 8.13338 20.4519 8.85938 20.7237C9.58537 20.4519 11.7188 19.4196 11.7188 16.7045V14.7188H6ZM4.81578 13.5345C5.01796 13.3323 5.29219 13.2188 5.57812 13.2188H12.1406C12.4266 13.2188 12.7008 13.3323 12.903 13.5345C13.1052 13.7367 13.2188 14.0109 13.2188 14.2969V16.7045C13.2188 20.666 9.85314 21.976 9.20687 22.1917C8.982 22.271 8.73675 22.271 8.51188 22.1917C7.86561 21.976 4.5 20.666 4.5 16.7045V14.2969C4.5 14.0109 4.61359 13.7367 4.81578 13.5345ZM10.9984 15.9396C11.3079 16.2149 11.3357 16.6889 11.0604 16.9984L9.05928 19.2484C8.91688 19.4085 8.71281 19.5001 8.49855 19.5C8.28428 19.4999 8.08029 19.4082 7.93803 19.248L6.93916 18.123C6.66415 17.8132 6.6923 17.3392 7.00204 17.0642C7.31179 16.7891 7.78582 16.8173 8.06084 17.127L8.49934 17.6209L9.93958 16.0016C10.2149 15.6921 10.6889 15.6643 10.9984 15.9396Z"
      fill={iconColor}
    />
  </SvgIcon>
);

PoliciesIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default PoliciesIcon;
