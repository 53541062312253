import React from 'react';
import axios from 'axios';

import { TerminateLegalActionRepresentationDialog } from '~/components/LegalActions/Dialogs/Party/TerminatePartyRepresentationDialog';
import { PartyMoreDetailsDialog } from '~/components/LegalActions/LegalActionScreen/InfoTab/Parties/PartyMoreDetailsDialog';

import { reportAxiosError } from '../../../../../Utils';
import { MainCard } from '../../../../core';
import { LegalActionAddItemButton } from '../../../components/LegalActionAddItemButton';
import { AddPartyDialog } from '../../../Dialogs/Party/AddPartyDialog';
import { EditPartyDialog } from '../../../Dialogs/Party/EditPartyDialog';
import { useLegalAction } from '../../../hooks/useLegalAction';
import { useLegalActionsBackendRoutes } from '../../../hooks/useLegalActionsBackendRoutes';
import { getPartyTypeLabel } from '../../../LegalActionsUtils';
import type { LegalActionPartyApiResponse, PartyType } from '../../../types';

import { PartyObjectCard } from './PartyObjectCard';

export const PartiesCard: React.FC<{ partyType: PartyType }> = ({ partyType }) => {
  const { legalAction, reloadLegalAction } = useLegalAction();
  const [isAddPartyDialogOpen, setIsAddPartyDialogOpen] = React.useState(false);
  const [partyInEdit, setPartyInEdit] = React.useState<LegalActionPartyApiResponse | null>(null);
  const [partyInTerminate, setPartyInTerminate] = React.useState<LegalActionPartyApiResponse | null>(null);
  const [partyInMoreDetails, setPartyInMoreDetails] = React.useState<LegalActionPartyApiResponse | null>(null);

  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const relevantParties = legalAction.legal_action_parties.filter((party) => party.type === partyType);
  const partyLabel = getPartyTypeLabel(partyType);
  const numberOfParties = relevantParties.length;
  const title = `${partyLabel} Info (${numberOfParties} ${partyLabel}${numberOfParties === 1 ? '' : 's'})`;

  const handleDeleteParty = async (party: LegalActionPartyApiResponse) => {
    try {
      await axios.delete(backendRoutes.legalAction.party.base(party.id));
      await reloadLegalAction();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  const getOrderedParties = (): LegalActionPartyApiResponse[] => {
    const carrierInvolved = relevantParties.find((party) => party.is_carrier_party);

    return [
      carrierInvolved,
      ...relevantParties.sort((a, b) => (a.id > b.id ? 1 : -1)).filter((party) => !party.is_carrier_party),
    ].filter(Boolean) as LegalActionPartyApiResponse[];
  };

  return (
    <>
      <MainCard collapsible type="elevated" title={title} openByDefault>
        {getOrderedParties().map((party) => (
          <PartyObjectCard
            party={party}
            key={party.id}
            onEditClick={() => setPartyInEdit(party)}
            onDeleteClick={() => handleDeleteParty(party)}
            onViewMore={() => setPartyInMoreDetails(party)}
            onTerminateRepresentation={() => setPartyInTerminate(party)}
          />
        ))}
        <div>
          <LegalActionAddItemButton text={`Add ${partyLabel}`} onClick={() => setIsAddPartyDialogOpen(true)} />
        </div>
      </MainCard>
      {isAddPartyDialogOpen && (
        <AddPartyDialog
          partyType={partyType}
          legalAction={legalAction}
          onClose={() => setIsAddPartyDialogOpen(false)}
          onSubmitSuccess={reloadLegalAction}
        />
      )}
      {partyInEdit && (
        <EditPartyDialog
          partyType={partyInEdit.type}
          legalAction={legalAction}
          onClose={() => setPartyInEdit(null)}
          onSubmitSuccess={reloadLegalAction}
          party={partyInEdit}
        />
      )}
      {partyInTerminate && (
        <TerminateLegalActionRepresentationDialog
          legalAction={legalAction}
          onClose={() => setPartyInTerminate(null)}
          onSubmitSuccess={reloadLegalAction}
          party={partyInTerminate}
        />
      )}
      {partyInMoreDetails && (
        <PartyMoreDetailsDialog party={partyInMoreDetails} onClose={() => setPartyInMoreDetails(null)} />
      )}
    </>
  );
};
