import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const BuildingsIcon = (props) => (
  <SvgIcon fill="none" {...props}>
    <path d="M1 13.5H15" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9 13.5V2.5C9 2.36739 8.94732 2.24021 8.85355 2.14645C8.75979 2.05268 8.63261 2 8.5 2H2.5C2.36739 2 2.24021 2.05268 2.14645 2.14645C2.05268 2.24021 2 2.36739 2 2.5V13.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 13.5V6.5C14 6.36739 13.9473 6.24021 13.8536 6.14645C13.7598 6.05268 13.6326 6 13.5 6H9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 4.5H6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 8.5H7" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 11H6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 11H12" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 8.5H12" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

BuildingsIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default BuildingsIcon;
