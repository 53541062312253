import React from 'react';

import SvgIcon from '../core/SvgIcon';

const OptionsIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <circle cx="8" cy="3" r="1" />
    <circle cx="8" cy="8" r="1" />
    <circle cx="8" cy="13" r="1" />
  </SvgIcon>
);

export default OptionsIcon;
