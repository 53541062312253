import React from 'react';

import LoadingIndicator from '~/components/LoadingIndicator';
import { useTableau } from '~/components/ReportsPage/Context/TableauContext';
import { fixAdminUserName } from '~/components/ReportsPage/helpers/ReportsUtils';
import { useTableauEmbedRoutes } from '~/components/ReportsPage/Hooks/useTableauEmbedRoutes';
import { ProjectContainer } from '~/components/ReportsPage/ReportsGallery/ProjectContainer/ProjectContainer';
import WorkbookCard from '~/components/ReportsPage/ReportsGallery/WorkbookCard/WorkbookCard';
import type { Project, ReportAction } from '~/components/ReportsPage/types';

interface ReportsGalleryProps {
  isSessionReady: boolean;
  projects: Project[];
  onAction: ({ action, params }: { action: ReportAction; params: { id: string } }) => void;
}
export const ReportsGallery: React.FC<ReportsGalleryProps> = ({ isSessionReady = false, projects, onAction }) => {
  const { getImageUrl } = useTableauEmbedRoutes();
  const { me } = useTableau();

  if (!isSessionReady) return <LoadingIndicator isError={false} />;

  return (
    <>
      {projects?.map((project: Project) => (
        <ProjectContainer name={project.name} key={project.id}>
          {project.workbooks?.map((workbook) => {
            const isOwner = workbook.owner_id === me?.id;
            return (
              <WorkbookCard
                key={workbook.id}
                id={workbook.id}
                name={workbook.name}
                imageUrl={getImageUrl({
                  workbook: workbook.content_url,
                  view: workbook?.views?.[0]?.content_url,
                })}
                numberOfViews={workbook.views?.length || 0}
                owner={fixAdminUserName(workbook?.owner_name)}
                updatedDate={workbook.updated_at}
                onClick={() => onAction({ action: 'view', params: { id: workbook.id } })}
                onEdit={isOwner ? () => onAction({ action: 'edit-dialog', params: { id: workbook.id } }) : undefined}
                onDelete={
                  isOwner ? () => onAction({ action: 'delete-dialog', params: { id: workbook.id } }) : undefined
                }
                onShare={isOwner ? () => onAction({ action: 'share-dialog', params: { id: workbook.id } }) : undefined}
              />
            );
          })}
        </ProjectContainer>
      ))}
    </>
  );
};
