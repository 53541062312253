import React from 'react';

import { useOrgNameLabel } from '~/components/LegalActions/hooks/useOrgNameLabel';

import { serverDateToLocal } from '../../../../../DateTimeUtils';
import { PropertyDetails } from '../../../../core';
import { PropertyDetailsRow } from '../../../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { CurrencyPropertyDetails } from '../../../components/CurrencyPropertyDetails';
import { MoreDetailsDialog } from '../../../Dialogs/MoreDetailsDialog';
import { NEGOTIATION_LOG_FIELDS } from '../../../FormFragments/FormFieldsDefinitions';
import type { NegotiationLogApiResponse } from '../../../types';

export const NegotiationLogMoreDetails: React.FC<{ log: NegotiationLogApiResponse; onClose: () => void }> = ({
  log,
  onClose,
}) => {
  const offerLabel = useOrgNameLabel('Offer');

  return (
    <MoreDetailsDialog onClose={onClose} title="Negotiation Log">
      <PropertyDetailsRow>
        <PropertyDetails title={NEGOTIATION_LOG_FIELDS.date.label} text={serverDateToLocal(log.date)} />
        <PropertyDetails
          title={NEGOTIATION_LOG_FIELDS.adversePartyContact.label}
          text={log.adverse_party_contact_name}
        />
      </PropertyDetailsRow>
      <PropertyDetailsRow>
        <CurrencyPropertyDetails title={offerLabel} value={log.offer} />
        <CurrencyPropertyDetails
          title={NEGOTIATION_LOG_FIELDS.adversePartyDemand.label}
          value={log.adverse_party_demand}
        />
      </PropertyDetailsRow>
      <PropertyDetailsRow>
        <PropertyDetails title={NEGOTIATION_LOG_FIELDS.notes.label} text={log.notes} />
      </PropertyDetailsRow>
    </MoreDetailsDialog>
  );
};
