import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { set } from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import { COVERAGE_CONFIGURATION_LIMITS } from '../../../../../Types';
import CardDialog from '../../../../CardDialog';
import { useClaim } from '../../../../ClaimContainer';
import ConfiguredFields, {
  cleanConfiguredFieldsFormikValuesBeforeSend,
} from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';
import useOrganization from '../../../../OrganizationContext';
import { MonetaryValueTextFieldFormik } from '../../../../TextFieldFormik';

import { useStyles } from '../../../../../assets/styles';
import styles from './EditCoveredEntityDialog.module.scss';

function EditCoveredEntityDialog(props) {
  const {
    defaultFields,
    configuredFields,
    coveredEntity,
    onClose,
    isSaving,
    icon,
    coveredObjectTypeLabel,
    isUpdate,
    onSaveClick,
    titleWithoutPrefix,
  } = props;
  const classes = useStyles();
  const { coverageConfiguration } = useOrganization();
  const { claim } = useClaim();

  const allFields = defaultFields.concat(configuredFields);

  const getInitialValues = (fields) => {
    const dictValues = {};
    fields.forEach((field) => {
      set(dictValues, field.id, field.value);
    });
    return dictValues;
  };

  const vehicleFields = allFields
    .filter((field) => field?.id?.startsWith('vehicle.'))
    .map((field) => ({ ...field, id: field.id.split('vehicle.')[1] }));
  const otherFields = allFields.filter((field) => !field?.id?.startsWith('vehicle.'));

  const validationSchema = Yup.object().shape({
    vehicle: claim?.lob === 'auto_claim' ? Yup.object().shape(getAdditionalDataValidations(vehicleFields)) : undefined,
    ...getAdditionalDataValidations(otherFields),
  });

  const coveredEntityTitle = (
    <span className={styles.cardTitleWrapper}>
      {icon}
      <span className={styles.cardTitleWrapperLabel}>
        {!titleWithoutPrefix && (isUpdate ? 'Edit' : 'Add')} {coveredObjectTypeLabel}
      </span>
    </span>
  );

  return (
    <Formik
      initialValues={{ ...getInitialValues(allFields), covered_entity: { ...coveredEntity } }}
      validationSchema={validationSchema}
      onSubmit={async (values, formikProps) => {
        try {
          let sanitizedValues = {
            ...cleanConfiguredFieldsFormikValuesBeforeSend(otherFields, values, { shouldSendNullForEmpty: true }),
            covered_entity: { ...values.covered_entity },
          };
          if (values.vehicle) {
            sanitizedValues['vehicle'] = {
              ...cleanConfiguredFieldsFormikValuesBeforeSend(vehicleFields, values.vehicle, {
                shouldSendNullForEmpty: true,
              }),
            };
          }
          await onSaveClick(sanitizedValues);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
      validateOnBlur={false}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <CardDialog
          isDialog
          dontTrack
          title={coveredEntityTitle}
          maxWidth="sm"
          fullWidth
          onClose={onClose}
          footerActions={
            <DialogFooterActions
              primaryLabel={isUpdate ? 'Update' : 'Save'}
              onClickPrimary={handleSubmit}
              onClickSecondary={onClose}
              disabled={isSaving}
            />
          }
        >
          <ConfiguredFields
            values={values}
            customFields={allFields}
            inline
            onFieldUpdate={(id, value) => setFieldValue(id, value)}
            idPrefix=""
          />
          {coveredEntity && (
            <Grid container spacing={1}>
              {claim && claim.lob === 'pet_claim' && (
                <Grid item xs={6}>
                  <MonetaryValueTextFieldFormik
                    id="covered_entity.deductible_per_policy_per_covered_entity"
                    label="Deductible per Listed Entity (per Policy)"
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="covered_entity.limit_per_policy_per_covered_entity"
                  label={COVERAGE_CONFIGURATION_LIMITS.limit_per_policy_per_covered_entity.desc}
                  disabled={!coverageConfiguration.limit_per_policy_per_covered_entity}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="covered_entity.limit_per_covered_entity"
                  label={COVERAGE_CONFIGURATION_LIMITS.limit_per_covered_entity.desc}
                  disabled={!coverageConfiguration.limit_per_covered_entity}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
            </Grid>
          )}
        </CardDialog>
      )}
    </Formik>
  );
}

EditCoveredEntityDialog.propTypes = {
  defaultFields: PropTypes.array.isRequired,
  configuredFields: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  titleWithoutPrefix: PropTypes.bool,
  icon: PropTypes.object.isRequired,
  coveredObjectTypeLabel: PropTypes.string.isRequired,
  coveredEntity: requiredIf(PropTypes.object, (props) => props.isUpdate),
};

export default EditCoveredEntityDialog;
