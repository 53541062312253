import React from 'react';
import { Grid as MuiGrid } from '@material-ui/core';

export type GridItemsAlignment = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
export type GridContentAlignment = 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
export type GridDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type GridSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type GridJustification =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export type GridWrap = 'nowrap' | 'wrap' | 'wrap-reverse';
export type GridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface GridProps {
  className?: string;
  alignContent?: GridContentAlignment;
  alignItems?: GridItemsAlignment;
  children?: React.ReactNode;
  container?: boolean;
  direction?: GridDirection;
  item?: boolean;
  justify?: GridJustification; // Deprecated
  justifyContent?: GridJustification;
  spacing?: GridSpacing;
  wrap?: GridWrap;
  zeroMinWidth?: boolean;
  xs?: boolean | GridSize | undefined;
  sm?: boolean | GridSize | undefined;
  md?: boolean | GridSize | undefined;
  lg?: boolean | GridSize | undefined;
  xl?: boolean | GridSize | undefined;
  component?: React.ElementType | undefined;
  innerRef?: React.RefObject<HTMLDivElement> | undefined;
}

const Grid: React.FC<GridProps> = ({ innerRef, children, ...gridProps }) => {
  return (
    <MuiGrid ref={innerRef} {...gridProps}>
      {children}
    </MuiGrid>
  );
};

export default Grid;
