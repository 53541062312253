import React from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { Paper } from '@mui/material';
import urljoin from 'url-join';

import EmptyState from '~/components/core/EmptyState';
import cn from '~/Utils/cn';

import { useStyles } from '../../../../assets/styles';
import styles from './InnerTabs.module.scss';

export interface InnerTabSingleProps<T> {
  label: string;
  url: string;
  component: React.FC<T>;
  props?: T;
  disableTab?: boolean;
  className?: string;
  isTabHidden?: boolean;
}
export interface InnerTabsProps<T> {
  tabs: InnerTabSingleProps<T>[];
  tabsContainerClassName?: string;
  renderContainerClassName?: string;
  actionsComponent?: () => React.JSX.Element;
}

const InnerTabs = <T,>({
  tabs,
  tabsContainerClassName,
  renderContainerClassName,
  actionsComponent,
}: InnerTabsProps<T>): React.JSX.Element => {
  const classes = useStyles();
  const match = useRouteMatch();

  const path = location.pathname.substring(match.url.length + 1);
  const breadcrumbs = path.split('/');
  const activeTabs = tabs.filter((tab) => !tab.disableTab);
  let currTabValue = activeTabs.findIndex((tab) => breadcrumbs.includes(tab.url));
  currTabValue = currTabValue >= 0 ? currTabValue : 0;
  if (activeTabs.length === 0) {
    return <EmptyState title="No tabs available to show" />;
  }

  return (
    <>
      <div>
        <Paper
          className={cn(
            'bg-initial relative rounded-none border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-slate-600',
            tabsContainerClassName
          )}
          elevation={0}
        >
          {actionsComponent && actionsComponent()}
          <Tabs
            value={currTabValue}
            className={styles.innerTabsContainer}
            variant="standard"
            indicatorColor="primary"
            textColor="primary"
          >
            {activeTabs.map((tab: InnerTabSingleProps<T>) => (
              <Tab
                className={cn(styles.innerTab, tab.className)}
                key={tab.url}
                label={tab.label}
                component={Link}
                to={`${match.url}/${tab.url}`}
              />
            ))}
          </Tabs>
        </Paper>
      </div>
      <div className={cn(classes.pageBody, renderContainerClassName)}>
        <Switch>
          <Redirect exact from={`${match.path}/`} to={urljoin(match.path, activeTabs[0].url)} />
          {activeTabs.map((tab: InnerTabSingleProps<T>) => (
            <Route
              key={tab.url}
              path={urljoin(match.path, tab.url)}
              render={() => <tab.component {...((tab.props ?? ({} as T)) as T & React.Attributes)} />}
            />
          ))}
          ;
          <Redirect to={match.path} />
        </Switch>
      </div>
    </>
  );
};

export default InnerTabs;
