import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Formik, getIn, useFormikContext } from 'formik';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { isoUtcDateTimeToLocal, serverDateTimeToLocal } from '../../DateTimeUtils';
import {
  AUTO_LIABILITY_INDICATORS_DICT,
  AUTO_OPEN_FNOL_PARTIES_REQUIRED,
  CONFIGURATION_FEATURES_NAMES,
  CRIF_AND_SIRA_EXCLUDED_SUB_ORGS,
} from '../../Types';
import {
  capitalize,
  getBasicFnolValues,
  isFeatureEnabled,
  isInshurUkOrganization,
  isMarshmallowPolicy,
  reportAxiosError,
} from '../../Utils';
import CardDialog from '../CardDialog';
import MarshmallowFollowupDetails from '../ClientSpecific/Marshmallow/MarshmallowFollowUpDetails';
import { useContacts } from '../ContactsContext';
import PdfPreviewDialog from '../Documents/PdfPreviewCard';
import FnolHeader from '../Fnol/FnolHeader';
import { trackOldFnolClick } from '../Fnol/FnolScreen';
import { useCms } from '../hooks/useCms';
import { usePositionLeftZenDesk } from '../hooks/useZenDesk';
import { InvolvedPropertyParty } from '../InvolvedProperty';
import LoadingDialog from '../LoadingDialog';
import useOrganization from '../OrganizationContext';
import { usePolicy } from '../PolicyContainer';
import { TextFieldFormik } from '../TextFieldFormik';
import { WitnessesSummary } from '../Witness';

import NonMotoristDetails, {
  AddNonMotoristParty,
  bicyclistPartyFields,
  pedestrianPartyFields,
} from './AutoInvolved/NonMotoristInvolved';
import VehicleParty, { vehiclePartyFields } from './AutoInvolved/VehicleParty';
import {
  autoIncidentDetailsFields,
  AutoIncidentDetailsFormikInner,
  autoIncidentDetailsValidationFields,
} from './AutoIncidentDetails';
import { autoPolicyDetailsFields } from './AutoPolicyDetails';

import styles, { useStyles } from '../../assets/styles';

function AutoFnol(props) {
  const {
    classes,
    policy,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    partiesStore,
    onChangePartiesStore,
    onSaveDraft,
    setSubmitting,
    fnolDraft,
    subOrganizationId,
  } = props;

  const [draftLastUpdate, setDraftLastUpdate] = useState(
    fnolDraft ? serverDateTimeToLocal(fnolDraft.last_updated) : undefined
  );
  const { userOrganization } = useCms();
  const { fnolSubmissionTypes } = useOrganization();

  usePositionLeftZenDesk();

  const isSubmissionTypeEnabled = isInshurUkOrganization(userOrganization);

  const handleSubmitDraft = async () => {
    await onSaveDraft(values, setSubmitting);
    setDraftLastUpdate(isoUtcDateTimeToLocal(new Date()));
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <FnolHeader policy={policy} subOrganizationId={subOrganizationId} claimType="auto_claim" />
        <div style={{ flex: '1 1 auto', overflowY: 'auto' }}>
          <Grid container alignItems="stretch">
            <Grid item md={6}>
              <div className={classes.cardDivRow}>
                <AutoIncidentDetailsFormikInner {...props} fnol stateCode={policy.insured_property_state} />
              </div>
              <div className={classes.cardDivRow}>
                <WitnessesSummary
                  classes={classes}
                  witnesses={values['witnesses']}
                  onAddWitness={(witness) =>
                    setFieldValue('witnesses', [...values['witnesses'], { ...witness, id: uuidv4() }])
                  }
                  onEditWitness={(witness) =>
                    setFieldValue(
                      'witnesses',
                      values['witnesses'].map((w) => (w.id === witness.id ? witness : w))
                    )
                  }
                  onDeleteWitness={(id) =>
                    setFieldValue(
                      'witnesses',
                      values['witnesses'].filter((w) => w.id !== id)
                    )
                  }
                />
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={classes.cardDivRow}>
                <FnolPartiesCard
                  policy={policy}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  touched={touched}
                  partiesStore={partiesStore}
                  onChangePartiesStore={onChangePartiesStore}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.cardDivRow}>
          {isSubmissionTypeEnabled && (
            <div className={classes.buttonsContainer}>
              <TextFieldFormik
                id="custom_extra.fnol_submission_mode"
                label="Submission Mode"
                className={classes.textField}
                select
                style={{ width: '160px' }}
              >
                {(fnolSubmissionTypes.auto_claim || []).map((m) => (
                  <MenuItem key={m} value={m}>
                    {m}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </div>
          )}
          <div style={{ padding: '8px' }}>
            <div className={classes.inLineButtonsContainer}>
              {!policy.is_manual && (
                <>
                  {draftLastUpdate && (
                    <Typography
                      className={classes.cardDivRow}
                      color="textSecondary"
                    >{`Draft saved on ${draftLastUpdate}`}</Typography>
                  )}
                  <Button
                    onClick={async () => {
                      setSubmitting(true);
                      await handleSubmitDraft();
                    }}
                    variant="contained"
                    color="primary"
                    className={classes.leftButtonDialog}
                    disabled={isSubmitting || partiesStore.isFetching}
                  >
                    Save Draft
                  </Button>
                </>
              )}
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={isSubmitting || !partiesStore || partiesStore.isFetching}
              >
                Create FNOL
              </Button>
              {isSubmitting && <LoadingDialog isError={false} track="Submit auto fnol" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

AutoFnol.propTypes = {
  classes: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  partiesStore: PropTypes.object.isRequired,
  onChangePartiesStore: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  fnolDraft: PropTypes.object,
  subOrganizationId: PropTypes.number,
};

const initialFnolValues = {
  ...autoPolicyDetailsFields,
  ...autoIncidentDetailsFields,
};

function AutoFnolFormik({ onSubmitFnol, claimValues, onSubmitDraft, fnolDraft, subOrganizationId }) {
  const classes = useStyles();
  const { incidentDetailsExtraFields, subOrganizationEnabled, subOrganizations } = useOrganization();
  const { policy } = usePolicy();
  const { contacts } = useContacts();
  const { userOrganization } = useCms();
  const [partiesStore, setPartiesStore] = React.useState(fnolDraft ? fnolDraft.draft.partiesStore || {} : {});
  const [crifStoredFileToPreview, setCrifStoredFileToPreview] = useState('');
  const [claimId, setClaimId] = useState();
  const [crifMessage, setCrifMessage] = useState();

  const isSubmissionTypeEnabled = isInshurUkOrganization(userOrganization);
  const CLAIM_TYPE = 'auto_claim';

  const isCrifEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CRIF_ENABLED);

  const submitAutoFnol = async (values, setSubmitting) => {
    let valuesToSend = { ...values };

    try {
      const res = await axios.post('/api/v1/auto_claims', {
        ...valuesToSend,
        fnol_contacts_ids: contacts.map((contact) => contact.id),
      });

      const claimId = res.data.id;

      if (isCrifEnabled && !CRIF_AND_SIRA_EXCLUDED_SUB_ORGS.includes(res.data.policy.sub_organization.external_id)) {
        const crifRes = await axios.post(`/api/v1/auto_claims/${claimId}/add_claim_to_crif`);
        if (crifRes.data) {
          setClaimId(claimId);
          setCrifStoredFileToPreview(crifRes.data.stored_file_id);
          setCrifMessage(crifRes.data.message);
        }
      } else {
        setSubmitting(false);
        onSubmitFnol(claimId);
      }
    } catch (error) {
      setSubmitting(false);
      reportAxiosError(error);
    }
  };

  const handleSubmitDraft = async (values, setSubmitting) => {
    await onSubmitDraft({ ...values, partiesStore });
    setSubmitting(false);
  };

  const mapPropsToFormikValues = () => {
    let formikVals = {
      ...getBasicFnolValues(),
      ...initialFnolValues,
      ...policy,
      policy_id: policy.id,
      is_manual_policy: !!policy.is_manual,
      sub_organization_id: subOrganizationId ?? '',
      primary_contact_id: '',
      first_party_type: undefined,
      witnesses: [],
      verified_insured_info: !!policy.is_manual,
      verified_policy_info: false,
      first_party: { ...vehiclePartyFields, id: 'new_first_party' }, // the id must be constant (otherwise the Formik will reinitialize)
      third_party_vehicles: [],
      third_party_non_motorists: [],
      third_party_other_properties: [],
      marshmallow_extra: {
        was_anyone_under_drugs: '',
        was_cctv_footage: '',
        is_notification_only: '',
        ph_fault_assessment: '',
        claim_handler_fault_assessment: '',
        weather_conditions: '',
        vehicle_speed: '',
        journey_purpose: '',
        was_driver_using_the_phone: '',
        were_hazardous_carried: '',
        keys_place_during_theft: '',
        were_belongings_stolen_from_vehicle: '',
        belongings_stolen: '',
        was_vehicle_locked: '',
        were_windows_closed_and_secured: '',
        car_fitted_with_security_devices: '',
        security_devices: '',
        car_fitted_with_tracking_devices: '',
        is_vehicle_for_sale: '',
        car_was_in_an_accident: '',
        is_indemnity_review_needed: '',
        is_fraud_review_needed: '',
        review_concern_desc: '',
        fnol_handler: isMarshmallowPolicy(policy) ? 'MARSHMALLOW' : '',
      },
      custom_extra: {
        fnol_submission_mode: '',
        ..._.mapValues(_.keyBy(incidentDetailsExtraFields[CLAIM_TYPE], 'field_name'), () => ''),
      },
    };

    if (claimValues) {
      formikVals = { ...formikVals, ...claimValues };
      formikVals.should_lock_date_of_loss = !!claimValues.date_of_loss; // TODO: ugly hack! remove someday when we are more generic
    }

    return formikVals;
  };

  const getOrganizationSpecialValidation = () => {
    if (
      !isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.UK_FNOL_FIELDS_MM_STYLE) &&
      !isMarshmallowPolicy(policy)
    ) {
      return {};
    }

    return {
      verified_insured_info: Yup.boolean().oneOf([true], 'Must verify insured info'),
      verified_policy_info: Yup.boolean().oneOf([true], 'Must verify policy info'),
      marshmallow_extra: Yup.object().when(
        ['incident_type', 'incident_sub_type'],
        (incident_type, incident_sub_type, schema) => {
          if (!incident_type || !incident_sub_type) {
            return schema;
          }

          const commonValidations = {
            ph_fault_assessment: Yup.string().required('Required'),
            claim_handler_fault_assessment: Yup.string().required('Required'),
            review_concern_desc: Yup.string().when(['is_indemnity_review_needed', 'is_fraud_review_needed'], {
              is: (is_indemnity_review_needed, is_fraud_review_needed) =>
                is_indemnity_review_needed || is_fraud_review_needed,
              then: Yup.string().required('Required'),
            }),
          };

          let incidentSpecific = {};
          if (incident_type === 'theft') {
            incidentSpecific = {
              keys_place_during_theft: Yup.string().required('Required'),
              were_belongings_stolen_from_vehicle: Yup.boolean().nullable().required('Required'),
              belongings_stolen: Yup.string().when('were_belongings_stolen_from_vehicle', {
                is: true,
                then: Yup.string().required('Required'),
              }),
              was_vehicle_locked: Yup.boolean().nullable().required('Required'),
              were_windows_closed_and_secured: Yup.boolean().nullable().required('Required'),
              car_fitted_with_security_devices: Yup.boolean().nullable().required('Required'),
              security_devices: Yup.string().when('car_fitted_with_security_devices', {
                is: true,
                then: Yup.string().required('Required'),
              }),
              car_fitted_with_tracking_devices: Yup.boolean().nullable().required('Required'),
              is_vehicle_for_sale: Yup.boolean().nullable().required('Required'),
              car_was_in_an_accident: ['theft_vehicle_not_recovered', 'theft_vehicle_recovered'].includes(
                incident_sub_type
              )
                ? Yup.boolean().nullable().required('Required')
                : undefined,
            };
          } else if (incident_type === 'accident') {
            incidentSpecific = {
              journey_purpose: Yup.string().required('Required'),
              was_driver_using_the_phone: Yup.boolean().nullable().required('Required'),
              was_anyone_under_drugs: Yup.boolean().nullable().required('Required'),
            };
          } else if (incident_type === 'fire') {
            incidentSpecific = {
              were_hazardous_carried: Yup.boolean().nullable().required('Required'),
            };
          }
          return schema.shape({ ...commonValidations, ...incidentSpecific });
        }
      ),
    };
  };

  return (
    <>
      <Formik
        initialValues={{ ...mapPropsToFormikValues(), ...fnolDraft?.draft }}
        validationSchema={Yup.object().shape({
          ...autoIncidentDetailsValidationFields,
          reporter_contact_id: Yup.number().required('Required'),
          primary_contact_id: Yup.number().required('Required'),
          ...getPartiesValidation(partiesStore),
          ...getOrganizationSpecialValidation(),
          custom_extra: Yup.object()
            .nullable()
            .shape({
              fnol_submission_mode: isSubmissionTypeEnabled ? Yup.string().required('Required') : undefined,
            }),
          sub_organization_id:
            subOrganizationEnabled && policy.is_manual
              ? Yup.number()
                  .required('Required')
                  .oneOf(
                    subOrganizations.map((so) => so.id),
                    'Invalid sub organization'
                  )
              : undefined,
        })}
        // enableReinitialize
        onSubmit={(values, formikProps) => {
          const { setSubmitting } = formikProps;
          setSubmitting(true);
          submitAutoFnol(values, setSubmitting);
          trackOldFnolClick(policy, 'old_auto_fnol');
        }}
      >
        {(formikProps) => (
          <>
            <AutoFnol
              classes={classes}
              {...formikProps}
              policy={policy}
              partiesStore={partiesStore}
              onChangePartiesStore={setPartiesStore}
              onSaveDraft={handleSubmitDraft}
              fnolDraft={fnolDraft}
              subOrganizationId={subOrganizationId}
            />
            {(crifStoredFileToPreview || crifMessage) && (
              <CrifMatchingClaimsDialog
                onProceed={() => {
                  formikProps.setSubmitting(false);
                  onSubmitFnol(claimId);
                }}
                claimId={claimId}
                fileId={crifStoredFileToPreview}
                errorMessage={crifMessage}
              />
            )}
          </>
        )}
      </Formik>
    </>
  );
}

AutoFnolFormik.propTypes = {
  onSubmitFnol: PropTypes.func.isRequired,
  claimValues: PropTypes.object,
  onSubmitDraft: PropTypes.func.isRequired,
  fnolDraft: PropTypes.object,
  subOrganizationId: PropTypes.number,
};

function CrifMatchingClaimsDialog({ onProceed, claimId, fileId, errorMessage }) {
  const classes = useStyles();
  if (fileId) {
    return (
      <PdfPreviewDialog
        title="Crif Search Results"
        onSubmit={onProceed}
        fileUrl={`/api/v1/auto_claims/${claimId}/crif_search_results/${fileId}`}
        submitTitle="Continue"
      />
    );
  }

  return (
    <CardDialog isDialog fullWidth title="Failed To Send To Crif" maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Typography variant="subtitle2">{errorMessage}</Typography>
        </Grid>
        <Grid item md={12}>
          <div className={classes.buttonsContainer}>
            <div className={classes.cardDivRow}>
              <Button variant="contained" color="primary" onClick={onProceed}>
                Continue
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </CardDialog>
  );
}

CrifMatchingClaimsDialog.propTypes = {
  onProceed: PropTypes.func.isRequired,
  fileId: PropTypes.number,
  claimId: PropTypes.number.isRequired,
  errorMessage: PropTypes.string,
};

function getPartiesValidation(partiesStore) {
  const partiesStoreData = partiesStore.data;

  // partiesStore wasn't initialized
  if (!partiesStoreData) {
    return {};
  }

  const insuredPartyType = partiesStoreData.insured_party.insured_party_type;

  let validationFields = {
    first_party: Yup.object().shape({
      involved_vehicle: insuredPartyType === 'vehicle' && Yup.object().nullable().required('Required'),
      involved_non_motorist:
        ['pedestrian', 'bicyclist'].includes(insuredPartyType) && Yup.object().nullable().required('Required'),
      driver:
        insuredPartyType === 'vehicle' &&
        partiesStoreData.insured_party.should_include_passengers &&
        !partiesStoreData.is_open_fnol
          ? Yup.object().nullable().required('Required')
          : undefined,
    }),
  };

  return validationFields;
}

function RuleBasedFnolParties({ partiesStore, onChangePartiesStore }) {
  const classes = useStyles();

  const { setFieldValue, values } = useFormikContext();
  const { incidentTypesDict } = useOrganization();
  const { policy } = usePolicy();

  const disabled = partiesStore.isFetching;

  const firstParty = values['first_party'];
  const thirdPartyVehicles = values['third_party_vehicles'];
  const thirdPartyNonMotorists = values['third_party_non_motorists'];
  const thirdPartyPedestrians = thirdPartyNonMotorists.filter(
    (thirdPartyNonMotorist) => thirdPartyNonMotorist.non_motorist_type === 'pedestrian'
  );
  const thirdPartyBicyclists = thirdPartyNonMotorists.filter(
    (thirdPartyNonMotorist) => thirdPartyNonMotorist.non_motorist_type === 'bicyclist'
  );
  const thirdPartyOtherProperties = values['third_party_other_properties'];

  const incidentType = values['incident_type'];

  async function fetchPartiesStore(incidentType, incidentSubType) {
    if (!incidentType || !incidentSubType) {
      return;
    }

    try {
      onChangePartiesStore((currPartiesStore) => ({ ...currPartiesStore, isFetching: true }));
      const res = await axios.get('/api/v1/auto_claims/fnol/requirements', {
        params: {
          organization_id: policy.organization_id,
          incident_type: incidentType,
          incident_sub_type: incidentSubType,
        },
      });

      const newPartiesStoreData = res.data.parties_required;
      const liabilityOptions = res.data.liability_options;

      setFieldValue('first_party', {
        ...firstParty,
        non_motorist_type: ['pedestrian', 'bicyclist'].includes(newPartiesStoreData.insured_party.insured_party_type)
          ? newPartiesStoreData.insured_party.insured_party_type
          : undefined,
        are_passengers_involved: newPartiesStoreData.insured_party.should_include_passengers,
      });

      let newThirdPartyVehicles = thirdPartyVehicles;
      if (thirdPartyVehicles.length < newPartiesStoreData.third_party_vehicles.length) {
        newThirdPartyVehicles = [
          ...thirdPartyVehicles,
          ...Array(newPartiesStoreData.third_party_vehicles.length - thirdPartyVehicles.length)
            .fill()
            .map(() => ({ ...vehiclePartyFields, id: uuidv4() })),
        ];
      }

      newPartiesStoreData.third_party_vehicles.forEach((third_party_vehicle_from_store, idx) => {
        if (
          newThirdPartyVehicles[idx].are_passengers_involved !==
          third_party_vehicle_from_store.should_include_passengers
        ) {
          newThirdPartyVehicles = [...newThirdPartyVehicles];
          newThirdPartyVehicles[idx].are_passengers_involved = third_party_vehicle_from_store.should_include_passengers;
        }
      });

      if (newThirdPartyVehicles !== thirdPartyVehicles) setFieldValue('third_party_vehicles', newThirdPartyVehicles);

      if (thirdPartyPedestrians.length < newPartiesStoreData.third_party_pedestrians.length) {
        setFieldValue('third_party_non_motorists', [
          ...thirdPartyNonMotorists,
          ...Array(newPartiesStoreData.third_party_pedestrians.length - thirdPartyPedestrians.length)
            .fill()
            .map(() => ({ ...pedestrianPartyFields, id: uuidv4() })),
        ]);
      }

      if (thirdPartyBicyclists.length < newPartiesStoreData.third_party_bicyclists.length) {
        setFieldValue('third_party_non_motorists', [
          ...thirdPartyNonMotorists,
          ...Array(newPartiesStoreData.third_party_bicyclists.length - thirdPartyBicyclists.length)
            .fill()
            .map(() => ({ ...bicyclistPartyFields, id: uuidv4() })),
        ]);
      }

      if (thirdPartyOtherProperties.length < newPartiesStoreData.third_party_other_properties.length) {
        setFieldValue('third_party_other_properties', [
          ...thirdPartyOtherProperties,
          ...Array(newPartiesStoreData.third_party_other_properties.length - thirdPartyOtherProperties.length)
            .fill()
            .map(() => ({ id: uuidv4() })),
        ]);
      }

      onChangePartiesStore({ data: newPartiesStoreData, liabilityOptions });
    } catch (error) {
      onChangePartiesStore((currPartiesStore) => ({ ...currPartiesStore, isFetching: false }));
      setFieldValue('incident_sub_type', '');
      reportAxiosError(error);
    }
  }

  const autoIncidentsDict = incidentTypesDict['auto_claim'];
  let incidentsTypeKeys = Object.keys(autoIncidentsDict);
  let incidentSubTypeDictForIncidentType = incidentType === '' ? {} : autoIncidentsDict[incidentType].sub_types;
  let incidentSubTypeKeysForIncidentType = Object.keys(incidentSubTypeDictForIncidentType);

  return (
    <>
      <Grid item xs={6}>
        <TextFieldFormik
          id="incident_type"
          select
          label="Incident type"
          className={classes.textField}
          disabled={disabled}
          onChange={(e) => {
            setFieldValue('incident_type', e.target.value);
            setFieldValue('incident_sub_type', '');
          }}
          fullWidth
        >
          {incidentsTypeKeys.map((option) => (
            <MenuItem key={option} value={option}>
              {autoIncidentsDict[option].desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id="incident_sub_type"
          select
          label="Incident sub-type"
          disabled={disabled}
          className={classes.textField}
          onChange={(e) => {
            const newIncidentSubType = e.target.value;
            setFieldValue('incident_sub_type', newIncidentSubType);
            fetchPartiesStore(incidentType, newIncidentSubType);
          }}
          fullWidth
        >
          {incidentSubTypeKeysForIncidentType.map((option) => (
            <MenuItem key={option} value={option}>
              {incidentSubTypeDictForIncidentType[option].desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
    </>
  );
}

RuleBasedFnolParties.propTypes = {
  partiesStore: PropTypes.object.isRequired,
  onChangePartiesStore: PropTypes.func.isRequired,
};

function OpenFnolParties({ onChangePartiesStore }) {
  const { setFieldValue, values } = useFormikContext();
  const firstPartyTypes = ['vehicle', 'pedestrian', 'bicyclist'];

  useEffect(() => {
    function buildOpenPartiesStore(firstPartyType) {
      let store = {
        parties_required: {
          is_open_fnol: true,
          ...AUTO_OPEN_FNOL_PARTIES_REQUIRED,
        },
        // HACK: either get from BE, put in enums.json or do something smart
        liability_options: {
          should_show_liability_indicator: true,
          liability_indicator_options_dict: AUTO_LIABILITY_INDICATORS_DICT,
          liability_summary_type: 'text',
        },
      };

      store.parties_required.insured_party.insured_party_type = firstPartyType;

      const newPartiesStoreData = store.parties_required;
      const liabilityOptions = store.liability_options;

      setFieldValue(
        'first_party.non_motorist_type',
        ['pedestrian', 'bicyclist'].includes(newPartiesStoreData.insured_party.insured_party_type)
          ? newPartiesStoreData.insured_party.insured_party_type
          : undefined
      );
      setFieldValue('first_party.are_passengers_involved', newPartiesStoreData.insured_party.should_include_passengers);

      onChangePartiesStore({ data: newPartiesStoreData, liabilityOptions });
    }

    buildOpenPartiesStore(values.first_party_type);
  }, [onChangePartiesStore, setFieldValue, values.first_party_type]);

  return (
    <Grid item xs={12}>
      <RadioButtonGroupFormik
        label="First party type"
        id="first_party_type"
        direction="row"
        options={firstPartyTypes.map((firstPartyType) => ({
          text: capitalize(firstPartyType),
          optionValue: firstPartyType,
        }))}
      />
    </Grid>
  );
}

OpenFnolParties.propTypes = {
  partiesStore: PropTypes.object,
  onChangePartiesStore: PropTypes.func.isRequired,
};

function useFnolParties(isOpenFnol) {
  if (!isOpenFnol) {
    return RuleBasedFnolParties;
  } else {
    return OpenFnolParties;
  }
}

function FnolPartiesCard(props) {
  const { setFieldValue, values, errors, touched, partiesStore, onChangePartiesStore } = props;
  const { userOrganization } = useCms();
  const classes = useStyles();
  const { policy } = usePolicy();
  const disabled = partiesStore.isFetching;
  const [showNewNonMotoristParty, setShowNewNonMotoristParty] = useState(false);
  const partiesStoreData = partiesStore.data;
  const FnolPartiesSelectorCompnent = useFnolParties(false);
  const firstParty = values['first_party'];
  const thirdPartyVehicles = values['third_party_vehicles'];
  const thirdPartyNonMotorists = values['third_party_non_motorists'];
  const thirdPartyPedestrians = thirdPartyNonMotorists.filter(
    (thirdPartyNonMotorist) => thirdPartyNonMotorist.non_motorist_type === 'pedestrian'
  );
  const thirdPartyBicyclists = thirdPartyNonMotorists.filter(
    (thirdPartyNonMotorist) => thirdPartyNonMotorist.non_motorist_type === 'bicyclist'
  );
  const thirdPartyOtherProperties = values['third_party_other_properties'];

  const incidentType = values['incident_type'];
  const incidentSubType = values['incident_sub_type'];

  const handleUpdateVehicleParty = (vehiclePartyId, operationType, data) => {
    try {
      const vehicleParty = getIn(values, vehiclePartyId);
      if (vehicleParty === null || vehicleParty === undefined) {
        throw Error(`Unknown vehiclePartyId: ${vehiclePartyId}`);
      }

      switch (operationType) {
        case 'SET_INVOLVED_VEHICLE':
          if (vehicleParty.involved_vehicle) {
            throw Error(`Involved Vehicle already defined, vehiclePartyId: ${vehiclePartyId}`);
          }

          setFieldValue(vehiclePartyId, { ...vehicleParty, involved_vehicle: data });
          break;
        case 'UPDATE_INVOLVED_VEHICLE':
          if (!vehicleParty.involved_vehicle) {
            throw Error(`Involved Vehicle isn't defined, vehiclePartyId: ${vehiclePartyId}`);
          }

          setFieldValue(vehiclePartyId, { ...vehicleParty, involved_vehicle: data });
          break;
        case 'DELETE_INVOLVED_VEHICLE':
          if (!vehicleParty.involved_vehicle) {
            throw Error(`Involved Vehicle isn't defined, vehiclePartyId: ${vehiclePartyId}`);
          }

          setFieldValue(vehiclePartyId, { ...vehicleParty, involved_vehicle: null });
          break;
        case 'SET_DRIVER':
          if (vehicleParty.driver) {
            throw Error(`Driver already defined, vehiclePartyId: ${vehiclePartyId}`);
          }

          setFieldValue(vehiclePartyId, { ...vehicleParty, driver: data });
          break;

        case 'UPDATE_DRIVER':
          if (!vehicleParty.driver) {
            throw Error(`Driver isn't defined, vehiclePartyId: ${vehiclePartyId}`);
          }
          setFieldValue(vehiclePartyId, { ...vehicleParty, driver: data });
          break;

        case 'DELETE_DRIVER':
          if (!vehicleParty.driver) {
            throw Error(`Driver isn't defined, vehiclePartyId: ${vehiclePartyId}`);
          }
          setFieldValue(vehiclePartyId, { ...vehicleParty, driver: null });
          break;

        case 'ADD_PASSENGER':
          setFieldValue(vehiclePartyId, { ...vehicleParty, passengers: [...vehicleParty.passengers, data] });
          break;

        case 'UPDATE_PASSENGER':
          // data = { passenger, idx }
          setFieldValue(vehiclePartyId, {
            ...vehicleParty,
            passengers: vehicleParty.passengers.map((p, idx) => (idx === data.idx ? data.passenger : p)),
          });
          break;

        case 'DELETE_PASSENGER':
          // data = { passenger, idx }
          setFieldValue(vehiclePartyId, {
            ...vehicleParty,
            passengers: vehicleParty.passengers.filter((_, idx) => idx !== data.idx),
          });
          break;

        default:
          throw Error(`Should not happen Unknown ${operationType}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const liabilityOptions = partiesStore.liabilityOptions;
  const firstPartyType = partiesStore.data?.insured_party.insured_party_type;

  return (
    <>
      <CardDialog title="Involved Parties">
        <Grid container alignItems="stretch" spacing={1}>
          <FnolPartiesSelectorCompnent partiesStore={partiesStore} onChangePartiesStore={onChangePartiesStore} />
          {!(incidentType && incidentSubType && partiesStoreData) ? (
            <Typography variant="subtitle2">Choose incident type and sub-type to add parties</Typography>
          ) : (
            <>
              {liabilityOptions.should_show_liability_indicator && (
                <>
                  <Grid item xs={5}>
                    <TextFieldFormik
                      id="liability_indicator"
                      select
                      label="Liability Indicator"
                      className={classes.textField}
                      disabled={disabled}
                      fullWidth
                    >
                      {Object.keys(liabilityOptions.liability_indicator_options_dict).map((option) => (
                        <MenuItem key={option} value={option}>
                          {liabilityOptions.liability_indicator_options_dict[option]}
                        </MenuItem>
                      ))}
                    </TextFieldFormik>
                  </Grid>
                  <Grid item xs={7}>
                    {liabilityOptions.liability_summary_type === 'text' && (
                      <TextFieldFormik
                        id="liability_summary"
                        label="Liability Summary"
                        className={classes.textField}
                        disabled={disabled}
                        fullWidth
                      />
                    )}
                    {liabilityOptions.liability_summary_type === 'select' && (
                      <TextFieldFormik
                        id="liability_summary"
                        label="Liability Summary"
                        className={classes.textField}
                        disabled={disabled}
                        fullWidth
                        select
                      >
                        {Object.keys(liabilityOptions.liability_summary_options_dict).map((option) => (
                          <MenuItem key={option} value={option}>
                            {liabilityOptions.liability_summary_options_dict[option]}
                          </MenuItem>
                        ))}
                      </TextFieldFormik>
                    )}
                  </Grid>
                </>
              )}
              {(isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.UK_FNOL_FIELDS_MM_STYLE) ||
                isMarshmallowPolicy(policy)) && (
                <MarshmallowFollowupDetails incidentType={incidentType} incidentSubType={values.incident_sub_type} />
              )}

              <Grid item xs={12}>
                <div className={classes.cardDivRowInternal}>
                  {firstPartyType === 'vehicle' ? (
                    <VehicleParty
                      classes={classes}
                      title="Insured Party Vehicle"
                      isInsured={true}
                      disabled={disabled}
                      errors={touched['first_party'] && errors['first_party']}
                      vehicleParty={firstParty}
                      onUpdateVehicle={(operation, data) =>
                        Promise.resolve(handleUpdateVehicleParty('first_party', operation, data))
                      }
                      allowDeleteInvolved
                      shouldHidePassengers={!values['first_party'].are_passengers_involved}
                    />
                  ) : (
                    <NonMotoristDetails
                      classes={classes}
                      title={`Insured Party (${capitalize(firstPartyType?.replace('_', ' '))})`}
                      disabled={disabled}
                      nonMotoristParty={firstParty}
                      onSaveNonMotoristDetails={async (nonMotorist) =>
                        await setFieldValue('first_party', { ...firstParty, involved_non_motorist: nonMotorist })
                      }
                      errors={touched['first_party'] && errors['first_party']}
                    />
                  )}
                </div>
                {thirdPartyVehicles.map(
                  (thirdPartyVehicle, curIdx) =>
                    (partiesStoreData.third_party_vehicles.length > curIdx ||
                      partiesStoreData.allow_add_third_party_vehicles) && (
                      <div className={classes.cardDivRowInternal} key={thirdPartyVehicle.id}>
                        <VehicleParty
                          classes={classes}
                          title={`3rd Party Vehicle (${curIdx + 1})`}
                          disabled={disabled}
                          vehicleParty={thirdPartyVehicle}
                          onUpdateVehicle={(operation, data) =>
                            Promise.resolve(
                              handleUpdateVehicleParty(`third_party_vehicles[${curIdx}]`, operation, data)
                            )
                          }
                          onDeleteVehicle={
                            curIdx >= partiesStoreData.third_party_vehicles.length ||
                            partiesStoreData.should_allow_delete_third_party_vehicles
                              ? () =>
                                  Promise.resolve(
                                    setFieldValue(
                                      'third_party_vehicles',
                                      values.third_party_vehicles.filter((_, idx) => curIdx !== idx)
                                    )
                                  )
                              : undefined
                          }
                          allowDeleteInvolved
                          isInsured={false}
                          shouldHidePassengers={!values['third_party_vehicles'][curIdx].are_passengers_involved}
                        />
                      </div>
                    )
                )}
                {[
                  { nonMotoristType: 'pedestrian', partiesList: thirdPartyPedestrians },
                  { nonMotoristType: 'bicyclist', partiesList: thirdPartyBicyclists },
                ].map(({ nonMotoristType, partiesList }) =>
                  partiesList.map((nonMotoristParty, curIdx) => {
                    const partyNonMotoristIndex = thirdPartyNonMotorists.findIndex(
                      (thirdPartyNonMotorist) => thirdPartyNonMotorist.id === nonMotoristParty.id
                    );

                    if (
                      !(
                        partiesStoreData[`third_party_${nonMotoristType}s`].length > curIdx ||
                        partiesStoreData.allow_add_third_party_non_motorist
                      )
                    ) {
                      return <div key={nonMotoristParty.id} />;
                    }

                    return (
                      <div className={classes.cardDivRowInternal} key={nonMotoristParty.id}>
                        <NonMotoristDetails
                          classes={classes}
                          title={`3rd Party ${capitalize(nonMotoristType?.replace('_', ' '))} (${curIdx + 1})`}
                          disabled={disabled}
                          nonMotoristParty={nonMotoristParty}
                          onSaveNonMotoristDetails={async (nonMotorist) =>
                            await setFieldValue(`third_party_non_motorists[${partyNonMotoristIndex}]`, {
                              ...nonMotoristParty,
                              involved_non_motorist: nonMotorist,
                            })
                          }
                          onDeleteNonMotorist={
                            curIdx >= partiesStoreData[`third_party_${nonMotoristType}s`].length
                              ? async () =>
                                  await setFieldValue(
                                    'third_party_non_motorists',
                                    values.third_party_non_motorists.filter((_, idx) => idx !== partyNonMotoristIndex)
                                  )
                              : undefined
                          }
                        />
                      </div>
                    );
                  })
                )}

                {thirdPartyOtherProperties.map((involvedPropertyParty, curIdx) => (
                  <div className={classes.cardDivRowInternal} key={involvedPropertyParty.id}>
                    <InvolvedPropertyParty
                      title={`3rd Party Other Property - ${curIdx + 1}`}
                      propertyLabel="Other Property"
                      involvedPropertyParty={involvedPropertyParty}
                      onSaveInvolvedPropertyDetails={(involvedPropertyParty) =>
                        Promise.resolve(setFieldValue(`third_party_other_properties[${curIdx}]`, involvedPropertyParty))
                      }
                      onDeleteInvolvedProperty={() =>
                        Promise.resolve(
                          setFieldValue(
                            'third_party_other_properties',
                            values.third_party_other_properties.filter((_, idx) => curIdx !== idx)
                          )
                        )
                      }
                      disabled={disabled}
                    />
                  </div>
                ))}
                <div className={classes.buttonsContainer}>
                  <Button
                    onClick={() =>
                      setFieldValue('third_party_other_properties', [...thirdPartyOtherProperties, { id: uuidv4() }])
                    }
                  >
                    <AddIcon />
                    Add Other Property
                  </Button>
                  {partiesStoreData.allow_add_third_party_non_motorist && (
                    <Button onClick={() => setShowNewNonMotoristParty(true)}>
                      <AddIcon />
                      Add Non Motorist
                    </Button>
                  )}
                  {partiesStoreData.allow_add_third_party_vehicles && (
                    <Button
                      onClick={() =>
                        setFieldValue('third_party_vehicles', [
                          ...thirdPartyVehicles,
                          { ...vehiclePartyFields, id: uuidv4(), are_passengers_involved: 'True' },
                        ])
                      }
                    >
                      <AddIcon />
                      Add Vehicle
                    </Button>
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </CardDialog>
      {showNewNonMotoristParty && (
        <AddNonMotoristParty
          onSubmitNewNonMotorist={(newParty) => {
            setFieldValue('third_party_non_motorists', [...thirdPartyNonMotorists, { id: uuidv4(), ...newParty }]);
            setShowNewNonMotoristParty(false);
          }}
          onCancel={() => setShowNewNonMotoristParty(false)}
        />
      )}
    </>
  );
}

FnolPartiesCard.propTypes = {
  policy: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  partiesStore: PropTypes.object.isRequired,
  onChangePartiesStore: PropTypes.func.isRequired,
};

export default withStyles(styles)(AutoFnolFormik);
