import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const DismissIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon stroke={iconColor} fill="none" {...props}>
    <path d="M10.75 6.5L7.08125 10L5.25 8.25" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

DismissIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default DismissIcon;
