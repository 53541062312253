import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const ItalicIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M18.75 5.25C18.75 5.44891 18.671 5.63968 18.5303 5.78033C18.3897 5.92098 18.1989 6 18 6H14.7909L10.7906 18H13.5C13.6989 18 13.8897 18.079 14.0303 18.2197C14.171 18.3603 14.25 18.5511 14.25 18.75C14.25 18.9489 14.171 19.1397 14.0303 19.2803C13.8897 19.421 13.6989 19.5 13.5 19.5H6C5.80109 19.5 5.61032 19.421 5.46967 19.2803C5.32902 19.1397 5.25 18.9489 5.25 18.75C5.25 18.5511 5.32902 18.3603 5.46967 18.2197C5.61032 18.079 5.80109 18 6 18H9.20906L13.2094 6H10.5C10.3011 6 10.1103 5.92098 9.96967 5.78033C9.82902 5.63968 9.75 5.44891 9.75 5.25C9.75 5.05109 9.82902 4.86032 9.96967 4.71967C10.1103 4.57902 10.3011 4.5 10.5 4.5H18C18.1989 4.5 18.3897 4.57902 18.5303 4.71967C18.671 4.86032 18.75 5.05109 18.75 5.25Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default ItalicIcon;
