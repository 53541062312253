import React, { useState } from 'react';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { isClaimPolicyManuallyFilled, isPolicyNotFound, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import WithConfirm from '../ConfirmModal';
import { FsButton, FsIconButton, PERMISSION_ACTIONS, PERMISSION_VERBS } from '../core';
import { useHasPermission } from '../hooks/useHasPermission';
import PencilIcon from '../icons/PencilIcon';
import PolicySearchContainer from '../PolicySearch';

import AutoManualPolicyDetailsDialog from './AutoManualPolicyDetailsDialog';
import HomeManualPolicyDetailsDialog from './HomeManualPolicyDetailsDialog';
import PetManualPolicyDetailsDialog from './PetManualPolicyDetailsDialog';

import { useStyles } from '../../assets/styles';

function PolicyNotFoundContainer() {
  const [showNewPolicyDetailsManually, setShowNewPolicyDetailsManually] = React.useState(false);
  const { claim, onClaimUpdate } = useClaim();
  const [isAttachDisabled, setIsAttachDisabled] = React.useState(false);
  const classes = useStyles();

  const userHasPolicyWritePermission = useHasPermission({
    action: PERMISSION_ACTIONS.MANUAL_POLICY,
    verb: PERMISSION_VERBS.WRITE,
  });
  const userHasPolicyReadPermission = useHasPermission({
    action: PERMISSION_ACTIONS.MANUAL_POLICY,
    verb: PERMISSION_VERBS.READ,
  });

  const handleManuallyAttachPolicy = async (policy) => {
    setIsAttachDisabled(true);
    try {
      await axios.post(`/api/v1/claims/${claim.id}/attach_policy`, { policy_id: policy.id });
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsAttachDisabled(false);
  };

  const handlePolicyNotFound = async (reason) => {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/close_claim_policy_not_found`, { reason });
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  if (!isPolicyNotFound(claim)) {
    throw Error("Claim policy shouldn't be filled manually");
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <div className={classes.cardDiv}>
            <CardDialog title="Attach to Existing Policy">
              <PolicySearchContainer
                classes={classes}
                constSearchValues={{ date_of_loss: claim.incident.date_of_loss }}
                onSelectPolicy={handleManuallyAttachPolicy}
                policyTypes={[claim.type.replace('_claim', '_policy')]}
                selectButtonText="Attach"
                disableSelectButton={isAttachDisabled || !userHasPolicyWritePermission}
                disabled={!userHasPolicyReadPermission}
              />
            </CardDialog>
          </div>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <div className={classes.cardDiv}>
            <CardDialog noCardTitle>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FsButton
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowNewPolicyDetailsManually(true)}
                  >
                    <AddIcon className={classes.rightButtonIcon} />
                    Insert Policy Details manually
                  </FsButton>
                </Grid>
                <Grid item xs={12}>
                  <WithConfirm
                    title="Close Claim?"
                    contentText="Claim will be closed, and coverage denied with reason: No Policy Found. Verify that claimant was informed, and that a Coverage Denial letter was sent."
                    primaryButtonName="Deny Coverage"
                    shouldCloseOnPrimary={true}
                  >
                    <FsButton
                      size="large"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handlePolicyNotFound('Policy Not Found - Coverage Denied')}
                    >
                      <AddIcon className={classes.rightButtonIcon} />
                      Deny Coverage
                    </FsButton>
                  </WithConfirm>
                </Grid>
                <Grid item xs={12}>
                  <WithConfirm
                    title="Mark as Record Only?"
                    contentText="Claim will be closed, and marked as: Record Only - No Policy. Verify that claimant was informed, and that a Coverage Denial letter was sent."
                    primaryButtonName="Mark as Record Only"
                    shouldCloseOnPrimary={true}
                  >
                    <FsButton
                      size="large"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handlePolicyNotFound('Policy Not Found - Record Only')}
                    >
                      <AddIcon className={classes.rightButtonIcon} />
                      Record Only - No Policy
                    </FsButton>
                  </WithConfirm>
                </Grid>
              </Grid>
            </CardDialog>
          </div>
        </Grid>
      </Grid>

      {claim.type === 'home_claim' && showNewPolicyDetailsManually && (
        <HomeManualPolicyDetailsDialog
          onClose={() => setShowNewPolicyDetailsManually(false)}
          onSubmitPolicy={async (values) => {
            try {
              await axios.post(`/api/v1/claims/${claim.id}/manual_policy`, values);
              setShowNewPolicyDetailsManually(true);
              await onClaimUpdate();
            } catch (error) {
              reportAxiosError(error);
              throw error;
            }
          }}
        />
      )}

      {claim.type === 'auto_claim' && showNewPolicyDetailsManually && (
        <AutoManualPolicyDetailsDialog
          onClose={() => setShowNewPolicyDetailsManually(false)}
          onSubmitPolicy={async (values) => {
            try {
              await axios.post(`/api/v1/claims/${claim.id}/manual_policy`, values);
              setShowNewPolicyDetailsManually(true);
              await onClaimUpdate();
            } catch (error) {
              reportAxiosError(error);
              throw error;
            }
          }}
        />
      )}

      {claim.type === 'pet_claim' && showNewPolicyDetailsManually && (
        <PetManualPolicyDetailsDialog
          onClose={() => setShowNewPolicyDetailsManually(false)}
          onSubmitPolicy={async (values) => {
            try {
              await axios.post(`/api/v1/claims/${claim.id}/manual_policy`, values);
              setShowNewPolicyDetailsManually(true);
              await onClaimUpdate();
            } catch (error) {
              reportAxiosError(error);
              throw error;
            }
          }}
        />
      )}
    </>
  );
}

function EditManuallyFilledPolicyContainer() {
  const { claim, onClaimUpdate } = useClaim();
  const [showPolicyDetailsForm, setShowPolicyDetailsForm] = useState(false);

  if (!claim || !isClaimPolicyManuallyFilled(claim)) {
    return <></>;
  }

  const handleSubmitPolicyDetails = async (policy) => {
    try {
      await axios.patch(`/api/v1/claims/${claim.id}/manual_policy`, policy);
      await onClaimUpdate();
      setShowPolicyDetailsForm(false);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <>
      <FsIconButton onClick={() => setShowPolicyDetailsForm(true)}>
        <PencilIcon />
      </FsIconButton>
      {showPolicyDetailsForm && claim.type === 'auto_claim' && (
        <AutoManualPolicyDetailsDialog
          onClose={() => setShowPolicyDetailsForm(false)}
          onSubmitPolicy={handleSubmitPolicyDetails}
          isExistingPolicy
        />
      )}
      {showPolicyDetailsForm && claim.type === 'home_claim' && (
        <HomeManualPolicyDetailsDialog
          onClose={() => setShowPolicyDetailsForm(false)}
          onSubmitPolicy={handleSubmitPolicyDetails}
          isExistingPolicy
        />
      )}
      {showPolicyDetailsForm && claim.type === 'pet_claim' && (
        <PetManualPolicyDetailsDialog
          onClose={() => setShowPolicyDetailsForm(false)}
          onSubmitPolicy={handleSubmitPolicyDetails}
          isExistingPolicy
        />
      )}
    </>
  );
}

export { EditManuallyFilledPolicyContainer };
export default PolicyNotFoundContainer;
