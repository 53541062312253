import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const DashboardReportsIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H20.25C21.0784 3.75 21.75 4.42157 21.75 5.25V16.5C21.75 17.3284 21.0784 18 20.25 18H3.75C2.92157 18 2.25 17.3284 2.25 16.5V5.25ZM20.25 5.25H3.75V16.5H20.25V5.25Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5315 16.6643C14.8549 16.4056 15.3269 16.458 15.5857 16.7815L18.5857 20.5315C18.8444 20.8549 18.792 21.3269 18.4685 21.5857C18.1451 21.8444 17.6731 21.792 17.4143 21.4685L14.4143 17.7185C14.1556 17.3951 14.208 16.9231 14.5315 16.6643Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.46852 16.6643C9.79197 16.9231 9.84441 17.3951 9.58565 17.7185L6.58565 21.4685C6.32689 21.792 5.85493 21.8444 5.53148 21.5857C5.20803 21.3269 5.15559 20.8549 5.41435 20.5315L8.41435 16.7815C8.67311 16.458 9.14507 16.4056 9.46852 16.6643Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10.5C9.41421 10.5 9.75 10.8358 9.75 11.25V13.5C9.75 13.9142 9.41421 14.25 9 14.25C8.58579 14.25 8.25 13.9142 8.25 13.5V11.25C8.25 10.8358 8.58579 10.5 9 10.5Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9C12.4142 9 12.75 9.33579 12.75 9.75V13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5V9.75C11.25 9.33579 11.5858 9 12 9Z"
      fill={iconColor}
      stroke={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 7.5C15.4142 7.5 15.75 7.83579 15.75 8.25V13.5C15.75 13.9142 15.4142 14.25 15 14.25C14.5858 14.25 14.25 13.9142 14.25 13.5V8.25C14.25 7.83579 14.5858 7.5 15 7.5Z"
      fill={iconColor}
      stroke={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.5C12.4142 1.5 12.75 1.83579 12.75 2.25V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V2.25C11.25 1.83579 11.5858 1.5 12 1.5Z"
      fill={iconColor}
      stroke={iconColor}
    />
  </SvgIcon>
);

DashboardReportsIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default DashboardReportsIcon;
