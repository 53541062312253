import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const SmileyNeutralIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 12.25C3.25 7.27944 7.27944 3.25 12.25 3.25C17.2206 3.25 21.25 7.27944 21.25 12.25C21.25 17.2206 17.2206 21.25 12.25 21.25C7.27944 21.25 3.25 17.2206 3.25 12.25ZM12.25 1.75C6.45101 1.75 1.75 6.45101 1.75 12.25C1.75 18.049 6.45101 22.75 12.25 22.75C18.049 22.75 22.75 18.049 22.75 12.25C22.75 6.45101 18.049 1.75 12.25 1.75ZM9.8125 10.2188C9.8125 10.8918 9.26685 11.4375 8.59375 11.4375C7.92065 11.4375 7.375 10.8918 7.375 10.2188C7.375 9.54565 7.92065 9 8.59375 9C9.26685 9 9.8125 9.54565 9.8125 10.2188ZM15.9062 11.4375C16.5793 11.4375 17.125 10.8918 17.125 10.2188C17.125 9.54565 16.5793 9 15.9062 9C15.2332 9 14.6875 9.54565 14.6875 10.2188C14.6875 10.8918 15.2332 11.4375 15.9062 11.4375ZM9 15.3281C8.58579 15.3281 8.25 15.6639 8.25 16.0781C8.25 16.4923 8.58579 16.8281 9 16.8281H16C16.4142 16.8281 16.75 16.4923 16.75 16.0781C16.75 15.6639 16.4142 15.3281 16 15.3281H9Z"
      fill={iconColor}
    />
  </SvgIcon>
);

SmileyNeutralIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default SmileyNeutralIcon;
