import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const PLIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path d="M7.5 10H8.5L9 14L8 14.5L7 14L7.5 10Z" fill={colors.lobIconSecondary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00008 2.5C6.06709 2.5 4.50008 4.067 4.50008 6C4.50008 7.933 6.06709 9.5 8.00008 9.5C9.93308 9.5 11.5001 7.933 11.5001 6C11.5001 4.067 9.93308 2.5 8.00008 2.5ZM3.50008 6C3.50008 3.51472 5.5148 1.5 8.00008 1.5C10.4854 1.5 12.5001 3.51472 12.5001 6C12.5001 8.48528 10.4854 10.5 8.00008 10.5C5.5148 10.5 3.50008 8.48528 3.50008 6Z"
      fill={colors.lobIconMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00008 10.5C5.6003 10.5 3.56047 11.802 2.43284 13.7504C2.29452 13.9894 1.98864 14.0711 1.74964 13.9328C1.51063 13.7944 1.42901 13.4886 1.56733 13.2496C2.86095 11.0143 5.21861 9.5 8.00008 9.5C10.7816 9.5 13.1392 11.0143 14.4328 13.2496C14.5712 13.4886 14.4895 13.7944 14.2505 13.9328C14.0115 14.0711 13.7056 13.9894 13.5673 13.7504C12.4397 11.802 10.3999 10.5 8.00008 10.5Z"
      fill={colors.lobIconMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.76066 10.0611C7.00308 9.92882 7.3068 10.0182 7.43903 10.2606L7.7969 10.9167H8.20327L8.56114 10.2606C8.69337 10.0182 8.99708 9.92882 9.23951 10.0611C9.48193 10.1933 9.57126 10.497 9.43903 10.7394L9.03771 11.4752L9.54386 13.2186C9.71309 13.8015 9.51435 14.4289 9.04038 14.8081L8.93713 14.8907C8.3893 15.3289 7.61087 15.3289 7.06304 14.8907L6.95979 14.8081C6.48582 14.4289 6.28708 13.8015 6.45631 13.2186L6.96245 11.4752L6.56114 10.7394C6.4289 10.497 6.51823 10.1933 6.76066 10.0611ZM7.87557 11.9167L7.41665 13.4974C7.36024 13.6917 7.42649 13.9008 7.58448 14.0272L7.68774 14.1098C7.87034 14.2559 8.12982 14.2559 8.31243 14.1098L8.41569 14.0272C8.57367 13.9008 8.63992 13.6917 8.58351 13.4974L8.1246 11.9167H7.87557Z"
      fill={colors.lobIconMain}
    />
  </SvgIcon>
);

export default PLIcon;
