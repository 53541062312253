import React from 'react';
import axios from 'axios';

import CommunicationAttachmentTable from '~/components/communications/CommunicationAttachmentTable';
import type {
  VideoCallCommunication,
  VideoCallSpecificBodyCommunicationState,
} from '~/components/communications/types';
import { getAttachmentFileUrl } from '~/components/communications/utils';
import EndedVideoCallDetails from '~/components/communications/VideoCommunication/EndedVideoCallDetails';
import JoinVideoCallProviderSpecificDetails from '~/components/communications/VideoCommunication/JoinVideoCallProviderSpecificDetails';
import VideoCommunicationChatDisplay from '~/components/communications/VideoCommunication/VideoCommunicationChatDisplay';
import { reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';

interface Props {
  communication: VideoCallCommunication;
  isNew: boolean;
  showActionButtons: boolean;
  onUpdate: () => Promise<void>;
}

const VideoCallSpecificBody: React.FC<Props> = ({ isNew, communication, showActionButtons = true, onUpdate }) => {
  if (isNew) {
    return null;
  }

  if (!communication.contact) {
    reportErrorInProductionOrThrow(
      `No contact for communication ${communication.id}, can not render JoinVideoCallProviderSpecificDetails`
    );
    return null;
  }

  const communicationState: VideoCallSpecificBodyCommunicationState = getVideoCommunicationState(communication);

  return (
    <>
      <JoinVideoCallProviderSpecificDetails
        communicationState={communicationState}
        contactId={communication.contact.id}
        communication={communication}
        showActionButtons={showActionButtons}
        onCancelMeeting={async () => {
          try {
            await axios.patch(`/api/v1/claims/communications/${communication.id}/cancel_video_call`);
            onUpdate();
          } catch (error) {
            reportAxiosError(error);
          }
        }}
      />
      {communicationState === 'expired' ? <div className="my-20">This video call expired</div> : null}
      {communicationState === 'canceled' ? <div className="my-20">This video call was canceled</div> : null}
      {communicationState === 'ended' ? (
        <>
          <EndedVideoCallDetails communication={communication} />
          {communication.chat ? <VideoCommunicationChatDisplay chat={communication.chat} /> : null}

          {communication.attachments?.length > 0 ? (
            <CommunicationAttachmentTable
              communication={communication}
              communicationType="video"
              onUpdate={onUpdate}
              maxHeight="200px"
              getAttachmentFileUrl={(attachment) => getAttachmentFileUrl(attachment, 'video')}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

const getVideoCommunicationState = (communication: VideoCallCommunication) => {
  let communicationState: VideoCallSpecificBodyCommunicationState = 'ready';

  switch (true) {
    case !!communication.meeting_end_datetime:
      communicationState = 'ended';
      break;
    case !!communication.meeting_start_datetime:
      communicationState = 'ongoing';
      break;
    case communication.is_meeting_cancelled:
      communicationState = 'canceled';
      break;
    case communication.is_meeting_expired:
      communicationState = 'expired';
      break;
    default:
      break;
  }

  return communicationState;
};

export default VideoCallSpecificBody;
