import React from 'react';
import * as PropTypes from 'prop-types';

import { isUserFromSuperOrganization } from '../../UserUtils';
import ContactsContextProvider from '../ContactsContext';
import { useCms } from '../hooks/useCms';

import ContactSearchContainer from './ContactSearchContainer';

const ContactSearchContainerOutOfClaim = ({
  textInputRef,
  overrideOfContactsContextSearchOrganizationId,
  ...restProps
}) => {
  const { user } = useCms();

  if (overrideOfContactsContextSearchOrganizationId && !isUserFromSuperOrganization(user)) {
    throw Error('Permission Denied');
  }

  return (
    <>
      <ContactsContextProvider
        contactsOrganizationId={overrideOfContactsContextSearchOrganizationId || user.organization_id}
        contacts={[]}
        onAddContact={() => {}}
      >
        <ContactSearchContainer textInputRef={textInputRef} RemoveOutOfClaimDivider {...restProps} />
      </ContactsContextProvider>
    </>
  );
};

ContactSearchContainerOutOfClaim.propTypes = {
  textInputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  overrideOfContactsContextSearchOrganizationId: PropTypes.number,
};

export default ContactSearchContainerOutOfClaim;
