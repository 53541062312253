import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { array, bool, func } from 'prop-types';
import { Checkbox } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import { noop } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';

import { serverDateToLocal } from '../DateTimeUtils';
import { WC_COVERAGE_DICT } from '../Types';
import { reportAxiosError, stringCmp } from '../Utils';

import { localeDetails } from './CmsMain/globals';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import WithConfirm from './ConfirmModal';
import { SortableTable } from './core';
import LoadingIndicator from './LoadingIndicator';
import useOrganization from './OrganizationContext';
import TextFieldFormik, { DatePickerTextFieldFormik } from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

const FILTER_PARAMS_INITIAL_VALUES = {
  check_number: '',
  claim_number: '',
  amount_from: '',
  amount_to: '',
  requester_user: '',
  coverage_type: '',
  indemnity_payment_type: '',
  account_name: '',
  check_date_from: '',
  check_date_to: '',
  invoice_date_from: '',
  invoice_date_to: '',
  payee_first_name: '',
  payee_last_name: '',
  payee_ssn: '',
  payee_company_name: '',
};

// TODO: Extract the search logic into an base AdvancedSearch component.
const AdvancedChecksSearchPage = () => {
  const classes = useStyles();
  const { user, setPageTitle } = useCms();
  const [isGeneratingNewChecks, setIsGeneratingNewChecks] = useState();
  const [isMarkingCheckAsVoid, setIsMarkingCheckAsVoid] = useState();
  const location = useLocation();
  const [filterParams, setFilterParams] = useState(FILTER_PARAMS_INITIAL_VALUES);
  const isGLChecks = location.pathname === '/organization_gl_checks';
  const filterData = isGLChecks ? { claim_type: 'gl_claim', property_name: 'aria' } : {};
  const config = { params: { ...filterParams, ...filterData } };
  const { isLoading, isError, data, reloadData } = useDataFetcher(
    `/api/v1/organizations/${user.organization_id}/checks/issued`,
    config
  );
  const [showResults, setShowResults] = useState(true);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  function handleBatchIdClicked(batch_id) {
    window.open(`/api/v1/organizations/${user.organization_id}/checks/${batch_id}/batch_file`, '_blank');
    window.open(`/api/v1/organizations/${user.organization_id}/checks/${batch_id}/ledger_file`, '_blank');
  }

  useEffect(() => setPageTitle('Organization Checks', 'Organization Checks - Five Sigma CMS'), [setPageTitle]);

  async function handleGenerateChecks() {
    try {
      setIsGeneratingNewChecks(true);
      const res = await axios.post(`/api/v1/organizations/${user.organization_id}/checks/generate`, filterData);
      await reloadData();
      if (res.data) {
        handleBatchIdClicked(res.data.batch_id);
      } // TODO: add indication if no new checks where created
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsGeneratingNewChecks(false);
    }
  }

  async function handleMarkAsVoid(check_id) {
    try {
      setIsMarkingCheckAsVoid(check_id);
      await axios.post(`/api/v1/organizations/${user.organization_id}/checks/mark_as_void`, { check_id });
      await reloadData();
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsMarkingCheckAsVoid(undefined);
    }
  }

  const onSubmit = (values) => {
    setShowResults(true);
    setFilterParams(values);
  };

  const onReset = (values) => {
    setShowResults(false);
    setFilterParams(values);
  };

  return (
    <CardDialog noCardTitle>
      <div className={classes.inLineButtonsContainer}>
        <div style={{ position: 'relative' }}>
          <Button variant="contained" color="primary" disabled={isGeneratingNewChecks} onClick={handleGenerateChecks}>
            Generate new checks
          </Button>
          {isGeneratingNewChecks && <LoadingIndicator size={24} className={classes.buttonProgress} />}
        </div>
      </div>
      <AdvancedChecksSearchDialog
        onSubmit={onSubmit}
        onReset={onReset}
        isGLChecks={isGLChecks}
        onCollapse={() => setIsSearchOpen(!isSearchOpen)}
      />
      {(isLoading || isError) && showResults && (
        <LoadingIndicator isError={isError} size={24} className={classes.buttonProgress} />
      )}
      {showResults && !isLoading && (
        <ChecksTable
          rows={data}
          handleMarkAsVoid={handleMarkAsVoid}
          isMarkingCheckAsVoid={isMarkingCheckAsVoid}
          handleBatchIdClicked={handleBatchIdClicked}
          isSearchOpen={isSearchOpen}
        />
      )}
    </CardDialog>
  );
};

const ChecksTable = ({ rows, handleMarkAsVoid, isMarkingCheckAsVoid, handleBatchIdClicked, isSearchOpen }) => {
  const columnData = [
    { id: 'pay_to_line', numeric: false, label: 'Payee' },
    {
      id: 'paid_amount',
      numeric: true,
      label: 'Amount',
      width: '50px',
      specialCell: (row) =>
        Intl.NumberFormat(localeDetails.locale, {
          style: 'currency',
          currency: row.paid_currency || localeDetails.currency,
        }).format(row.paid_amount),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'batch_id',
      numeric: true,
      label: 'Batch Id',
      width: '50px',
      specialCell: (row) => (
        <Button onClick={() => handleBatchIdClicked(row.batch_id)} color="primary">
          {row.batch_id}
        </Button>
      ),
    },
    { id: 'check_number', numeric: true, label: 'Check Number', width: '50px' },
    {
      id: 'check_date',
      numeric: false,
      label: 'Issued At',
      width: '70px',
      specialCell: (row) => serverDateToLocal(row.check_date),
      specialCmpFunc: (row1, row2) => stringCmp(row1.check_date, row2.check_date),
    },
    { id: 'requester_user', numeric: false, label: 'Issued By', width: '90px' },
    // eslint-disable-next-line react/display-name
    {
      id: 'mark_as_void',
      numeric: false,
      label: 'Mark As Void',
      specialCell: (row) => (
        <WithConfirm
          key={row.id}
          title="Are you sure?"
          contentText="This will void the check and unmark the payment as paid"
          primaryButtonName="Mark As Void"
          shouldCloseOnPrimary={true}
        >
          <Button
            onClick={() => handleMarkAsVoid(row.id)}
            color="primary"
            disabled={!!row.void_date || !!isMarkingCheckAsVoid}
          >
            {row.void_date ? 'Void' : 'Mark As Void'}
          </Button>
        </WithConfirm>
      ),
    },
  ];

  return (
    <SortableTable
      rows={rows}
      columns={columnData}
      defaultOrderColumn={columnData.findIndex((column) => column.id === 'batch_id')}
      order="desc"
      maxHeight={`${window.innerHeight - (isSearchOpen ? 600 : 300)}px`}
      stickyHeader
    />
  );
};

ChecksTable.propTypes = {
  rows: array,
  handleMarkAsVoid: func.isRequired,
  isMarkingCheckAsVoid: bool,
  handleBatchIdClicked: func.isRequired,
  isSearchOpen: bool,
};

const AdvancedChecksSearchDialog = ({ onSubmit, onReset, isGLChecks = false, onCollapse = noop }) => {
  const classes = useStyles();
  const { legacyIndemnityPaymentTypes } = useOrganization();
  const [isCompany, setIsCompany] = useState(false);
  const toggleIsCompany = () => setIsCompany(!isCompany);

  return (
    <Formik
      initialValues={FILTER_PARAMS_INITIAL_VALUES}
      validationSchema={Yup.object().shape({
        check_number: Yup.string().nullable(),
        claim_number: Yup.string().nullable(),
        amount_from: Yup.string().nullable(),
        amount_to: Yup.string().nullable(),
        requester_user: Yup.string().nullable(),
        coverage_type: Yup.string().nullable(),
        indemnity_payment_type: Yup.string().nullable(),
        account_name: Yup.string().nullable(),
        check_date_from: Yup.string().nullable(),
        check_date_to: Yup.string().nullable(),
        invoice_date_from: Yup.string().nullable(),
        invoice_date_to: Yup.string().nullable(),
        payee_first_name: Yup.string().nullable(),
        payee_last_name: Yup.string().nullable(),
        payee_ssn: Yup.string().nullable(),
        payee_company_name: Yup.string().nullable(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      onReset={(values, { setSubmitting }) => {
        onReset(values);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, isSubmitting, resetForm, values }) => (
        <CardDialog noCardTitle outlinedCard containerStyle={{ padding: '16px 0' }}>
          <CollapsibleWrapper title="Advanced Search" onCollapse={onCollapse}>
            <Grid container justify="center" alignItems="center" spacing={1}>
              <Grid item xs={11} md={10} lg={9}>
                <Typography
                  display="block"
                  variant="subtitle1"
                  style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '120%' }}
                >
                  General Details
                </Typography>
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  wrap="nowrap"
                  spacing={1}
                  style={{ height: '100%' }}
                >
                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="check_number"
                      label="Check Number"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="claim_number"
                      label="Claim Number"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="amount_from"
                      label="Amount From"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="amount_to"
                      label="Amount To"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  wrap="nowrap"
                  spacing={1}
                  style={{ height: '100%' }}
                >
                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="requester_user"
                      label="Issued By"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextFieldFormik
                      id="account_name"
                      label="Account Name"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                      select
                    >
                      {/*  TODO: Make this operational once we'll have more then one account in MGM */}
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Workers Compensations">Workers Compensations</MenuItem>
                    </TextFieldFormik>
                  </Grid>

                  <Grid item xs={3}>
                    {!isGLChecks && (
                      <TextFieldFormik
                        id="coverage_type"
                        label="Coverage Type"
                        className={classes.textField}
                        fullWidth
                        disabled={isSubmitting}
                        select
                      >
                        <MenuItem value={null}>All</MenuItem>
                        {Object.entries(WC_COVERAGE_DICT).map(([type, { desc }]) => (
                          <MenuItem key={type} value={type}>
                            {desc}
                          </MenuItem>
                        ))}
                      </TextFieldFormik>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {!isGLChecks && (
                      <TextFieldFormik
                        id="indemnity_payment_type"
                        label="Payment Type"
                        className={classes.textField}
                        fullWidth
                        disabled={isSubmitting}
                        select
                      >
                        <MenuItem value={null}>All</MenuItem>
                        {legacyIndemnityPaymentTypes['wc_claim'][values['coverage_type'] || ''] &&
                          Object.keys(legacyIndemnityPaymentTypes['wc_claim'][values['coverage_type']]).map((type) => (
                            <MenuItem key={type} value={type}>
                              {legacyIndemnityPaymentTypes['wc_claim'][values['coverage_type']][type]['desc']}
                            </MenuItem>
                          ))}
                      </TextFieldFormik>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  wrap="nowrap"
                  spacing={1}
                  style={{ height: '100%' }}
                >
                  <Grid item xs={3}>
                    <DatePickerTextFieldFormik
                      id="check_date_from"
                      label="Transaction Date From"
                      className={classes.textField}
                      disableFuture
                      fullWidth
                      disabled={isSubmitting}
                      clearable
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <DatePickerTextFieldFormik
                      id="check_date_to"
                      label="Transaction Date To"
                      className={classes.textField}
                      disableFuture
                      fullWidth
                      disabled={isSubmitting}
                      clearable
                    />
                  </Grid>

                  <Grid item xs={3}>
                    {!isGLChecks && (
                      <DatePickerTextFieldFormik
                        id="invoice_date_from"
                        label="Invoice Date From"
                        className={classes.textField}
                        disableFuture
                        fullWidth
                        disabled={isSubmitting}
                        clearable
                      />
                    )}
                  </Grid>

                  <Grid item xs={3}>
                    {!isGLChecks && (
                      <DatePickerTextFieldFormik
                        id="invoice_date_to"
                        label="Invoice Date To"
                        className={classes.textField}
                        disableFuture
                        fullWidth
                        disabled={isSubmitting}
                        clearable
                      />
                    )}
                  </Grid>
                </Grid>

                <Typography
                  display="block"
                  variant="subtitle1"
                  style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '120%' }}
                >
                  Payee Details
                </Typography>

                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  wrap="nowrap"
                  spacing={1}
                  style={{ height: '100%' }}
                >
                  {isCompany ? (
                    <Grid item xs={3}>
                      <TextFieldFormik
                        id="payee_company_name"
                        label="Payee Company Name"
                        className={classes.textField}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={3}>
                        <TextFieldFormik
                          id="payee_first_name"
                          label="First Name"
                          className={classes.textField}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextFieldFormik
                          id="payee_last_name"
                          label="Last Name"
                          className={classes.textField}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextFieldFormik
                          id="payee_ssn"
                          label="Social Security Number"
                          className={classes.textField}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={3}>
                    <Checkbox onChange={toggleIsCompany} />
                    Payee is a Company
                  </Grid>
                  {isCompany && <Grid item xs={6} />}
                </Grid>
                <Grid container style={{ paddingTop: '8px' }}>
                  <div className={classes.buttonsContainer}>
                    <Button onClick={resetForm} variant="contained" className={classes.cancelButton}>
                      Clear
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                      Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CollapsibleWrapper>
        </CardDialog>
      )}
    </Formik>
  );
};

AdvancedChecksSearchDialog.propTypes = {
  onSubmit: func.isRequired,
  onReset: func.isRequired,
  isGLChecks: bool,
  onCollapse: func,
};

export default AdvancedChecksSearchPage;
