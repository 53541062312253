import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const SmileyHappyIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 12.25C3.25 7.27944 7.27944 3.25 12.25 3.25C17.2206 3.25 21.25 7.27944 21.25 12.25C21.25 17.2206 17.2206 21.25 12.25 21.25C7.27944 21.25 3.25 17.2206 3.25 12.25ZM12.25 1.75C6.45101 1.75 1.75 6.45101 1.75 12.25C1.75 18.049 6.45101 22.75 12.25 22.75C18.049 22.75 22.75 18.049 22.75 12.25C22.75 6.45101 18.049 1.75 12.25 1.75ZM9.8125 10.2188C9.8125 10.8918 9.26685 11.4375 8.59375 11.4375C7.92065 11.4375 7.375 10.8918 7.375 10.2188C7.375 9.54565 7.92065 9 8.59375 9C9.26685 9 9.8125 9.54565 9.8125 10.2188ZM15.9062 11.4375C16.5793 11.4375 17.125 10.8918 17.125 10.2188C17.125 9.54565 16.5793 9 15.9062 9C15.2332 9 14.6875 9.54565 14.6875 10.2188C14.6875 10.8918 15.2332 11.4375 15.9062 11.4375ZM8.83672 14.312C8.62934 13.9534 8.17055 13.8309 7.81199 14.0383C7.45343 14.2457 7.33089 14.7045 7.53828 15.063C8.49467 16.7165 10.1387 17.875 12.25 17.875C14.3613 17.875 16.0053 16.7165 16.9617 15.063C17.1691 14.7045 17.0466 14.2457 16.688 14.0383C16.3295 13.8309 15.8707 13.9534 15.6633 14.312C14.9337 15.5733 13.7482 16.375 12.25 16.375C10.7518 16.375 9.56626 15.5733 8.83672 14.312Z"
      fill={iconColor}
    />
  </SvgIcon>
);

SmileyHappyIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default SmileyHappyIcon;
