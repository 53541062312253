import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { getExposuresArrayFromClaimByIds, getExposuresLabels, isFeatureEnabled, reportAxiosError } from '~/Utils';

import AutocompleteFormik from '../AutocompleteFormik';
import CardDialog from '../CardDialog';
import mixpanel from '../CmsMain/mixpanel';
import DisableableTooltip from '../DisableableTooltip';
import { ExposureInvolvedHoverInfo } from '../exposures/ExposuresCard';
import { useCms } from '../hooks/useCms';
import useScript from '../hooks/useScript';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import {
  extractDynamicFieldsValuesFromFormikValues,
  getDocumentTemplateDynamicFieldsValidation,
  TemplateDynamicFieldsFragment,
} from '../Templating/TemplateUtils';
import TextFieldFormik from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { getAllowedDocumentTypes } from './DocumentCard';

const spacing = 1;

const UploadDocumentFromTemplate = ({ claim, onCancel, onSubmitDocument, open, onMinimized, disableMinimized }) => {
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [onlineEditOpen, setOnlineEditOpen] = React.useState(false);
  const [documentTemplateDynamicFields, setDocumentTemplateDynamicFields] = React.useState();

  const { userOrganization } = useCms();

  const shouldAllowOnlineEdit = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ONLINE_EDIT);

  const initialValues = {
    exposure_id: '',
    document_template: { id: '' },
    document_template_unique_id: '',
  };

  const handleUploadDocumentTemplate = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const res = await axios.post(
        `/api/v1/claims/${claim.id}/documents/${values['document_template']['type']}s/upload_document_template`,
        values
      );
      setSubmitting(false);
      onSubmitDocument(res.data.id);
    } catch (error) {
      setSubmitting(false);
      reportAxiosError(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        exposure_id: Yup.number().required('Required'),
        document_type: Yup.string().required('Required'),
        document_template_unique_id: Yup.string().required('Required'),
        ...getDocumentTemplateDynamicFieldsValidation(documentTemplateDynamicFields),
      })}
      onSubmit={(values, formikPros) => {
        setPreviewOpen(true);
        formikPros.setSubmitting(false);
        const exposures = getExposuresArrayFromClaimByIds(claim, [values.exposure_id]);
        mixpanel.track(MIXPANEL_EVENTS.DOCUMENT_PREVIEWED, {
          ...mixpanel.getMixpanelAttributes(claim, exposures, {
            source: MIXPANEL_EVENT_SOURCES.DOCUMENT_PREVIEW_FROM_TEMPLATE,
            document_type: values.document_type,
            template: values.document_template,
          }),
        });
      }}
      enableReinitialize
    >
      {(formikPros) => {
        const { values, isSubmitting } = formikPros;

        const createValuesForPreview = (values) => {
          let valuesForPreview = {};
          Object.keys(initialValues).forEach((initialValuesKey) => {
            valuesForPreview[initialValuesKey] = values[initialValuesKey];
          });
          valuesForPreview = {
            ...valuesForPreview,
            ...extractDynamicFieldsValuesFromFormikValues(documentTemplateDynamicFields, values),
          };
          return valuesForPreview;
        };

        return (
          <>
            <UploadDocumentTemplateFormikInner
              initialValues={initialValues}
              onUpdateTemplateDynamicFields={setDocumentTemplateDynamicFields}
              onCancel={onCancel}
              claim={claim}
              documentTemplateDynamicFields={documentTemplateDynamicFields}
              open={open}
              onMinimized={onMinimized}
              disableMinimized={disableMinimized}
            />
            {previewOpen && ( // dispose of the dialog after values change to get updated preview
              <DocumentTemplatePreviewDialog
                open={previewOpen}
                claim={claim}
                valuesForPreview={createValuesForPreview(values)}
                isSubmitting={isSubmitting}
                onClose={() => setPreviewOpen(false)}
                onOnlineEdit={
                  shouldAllowOnlineEdit
                    ? () => {
                        setPreviewOpen(false);
                        setOnlineEditOpen(true);
                      }
                    : null
                }
                exposureId={values.exposure_id}
                onCreateDocument={async () => {
                  await handleUploadDocumentTemplate(values, formikPros);
                  setPreviewOpen(false);
                  const exposures = getExposuresArrayFromClaimByIds(claim, [values.exposure_id]);
                  mixpanel.track(MIXPANEL_EVENTS.DOCUMENT_UPLOADED_TO_CLAIM, {
                    ...mixpanel.getMixpanelAttributes(claim, exposures, {
                      source: MIXPANEL_EVENT_SOURCES.DOCUMENT_PREVIEW_UPLOAD_TO_CLAIM,
                      document_type: values['document_template']['type'],
                      template: values['document_template'],
                    }),
                  });
                }}
              />
            )}
            {onlineEditOpen && ( // dispose of the dialog after values change to get updated preview
              <DocumentTemplateOnlineEditor
                open={onlineEditOpen}
                claim={claim}
                values={values}
                onClose={() => setOnlineEditOpen(false)}
                onUploaded={(doc) => {
                  onSubmitDocument(doc.id);
                  setPreviewOpen(false);
                }}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

UploadDocumentFromTemplate.propTypes = {
  claim: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmitDocument: PropTypes.func.isRequired,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
  open: PropTypes.bool,
};

const UploadDocumentTemplateFormikInner = ({
  open,
  onCancel,
  onMinimized,
  disableMinimized,
  claim,
  initialValues,
  documentTemplateDynamicFields,
  onUpdateTemplateDynamicFields,
}) => {
  const { values, setFieldValue, handleSubmit, isSubmitting } = useFormikContext();
  const classes = useStyles();
  const { documentTypesDict } = useOrganization();

  const exposureLabels = getExposuresLabels(claim, true);
  const chosenExposureId = values.exposure_id;
  const chosenExposure =
    chosenExposureId && chosenExposureId !== 0 && claim.exposures.find((exposure) => exposure.id === chosenExposureId);

  const enoughValuesToFetchDynamicFields = !!(
    values['document_template_unique_id'] && typeof values['exposure_id'] === 'number'
  );
  const documentTemplateValues = values['document_template'];

  const dynamicFieldsUri = enoughValuesToFetchDynamicFields
    ? `/api/v1/claims/${claim.id}/documents/${documentTemplateValues['type']}s/${documentTemplateValues['doc_id']}/dynamic_fields`
    : null;

  const {
    isLoading: isLoadingDynamicFields,
    isError: isErrorDynamicFields,
    data: updatedDocumentTemplateDynamicFields,
  } = useDataFetcher(dynamicFieldsUri, { params: { exposure_id: chosenExposureId } }, enoughValuesToFetchDynamicFields);

  React.useEffect(() => {
    if (
      !enoughValuesToFetchDynamicFields ||
      isLoadingDynamicFields ||
      isErrorDynamicFields ||
      !updatedDocumentTemplateDynamicFields
    ) {
      onUpdateTemplateDynamicFields(undefined);
      return;
    }

    updatedDocumentTemplateDynamicFields.forEach((templateField) => {
      switch (templateField.type) {
        case 'contact':
        case 'ocontact':
          setFieldValue(`${templateField.id}_id`, '');
          setFieldValue(`${templateField.id}`, '');
          setFieldValue(`${templateField.id}_full_name`, '');
          break;
        case 'mselect':
          setFieldValue(templateField.id, []);
          break;
        case 'checkbox':
          setFieldValue(templateField.id, false);
          break;
        case 'string':
        case 'mstring':
        case 'omstring':
        case 'ostring':
        case 'date':
        case 'odate':
        case 'monetary':
        case 'number':
        case 'omonetary':
        case 'onumber':
          setFieldValue(templateField.id, '');
          break;
        default:
          throw Error(`Unknown document template field type: ${templateField.type}, field id: ${templateField.id}`);
      }
    });

    onUpdateTemplateDynamicFields(updatedDocumentTemplateDynamicFields);
  }, [
    updatedDocumentTemplateDynamicFields,
    isErrorDynamicFields,
    isLoadingDynamicFields,
    setFieldValue,
    onUpdateTemplateDynamicFields,
    enoughValuesToFetchDynamicFields,
  ]);

  return (
    <>
      <CardDialog
        isDialog
        title="Create from Template"
        maxWidth="xs"
        fullWidth
        onClose={onCancel}
        preventClose={isSubmitting}
        onMinimized={onMinimized}
        open={open}
        disableMinimized={disableMinimized}
      >
        <Grid container alignItems="flex-end" spacing={spacing}>
          <Grid item xs={11}>
            <TextFieldFormik
              id="exposure_id"
              select
              label="Exposure Label"
              className={classes.textField}
              onChange={(e) => {
                setFieldValue('exposure_id', e.target.value);
                setFieldValue('document_template', initialValues.document_template);
                setFieldValue('document_template_unique_id', initialValues.document_template_unique_id);
              }}
              fullWidth
            >
              {exposureLabels.map((exposure) => (
                <MenuItem key={exposure.id} value={exposure.id}>
                  {exposure.label}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={1}>
            {chosenExposure ? <ExposureInvolvedHoverInfo exposure={chosenExposure} /> : null}
          </Grid>
          {values['exposure_id'] !== '' && (
            <Grid item xs={12}>
              <DocumentTemplatesAndBundlesFormikSelect
                id="document_template"
                label="Template"
                claimId={claim.id}
                exposureId={values['exposure_id']}
                className={classes.textField}
                values={values}
                setFieldValue={setFieldValue}
                fullWidth
              />
            </Grid>
          )}
          {values['exposure_id'] !== '' && values['document_template_unique_id'] && (
            <Grid item xs={12}>
              <AutocompleteFormik
                id="document_type"
                label="Document Type"
                options={getAllowedDocumentTypes(documentTypesDict, claim.type).concat(['templated_document'])}
                getOptionLabel={(option) => documentTypesDict[option]['desc']}
                sortAlphabetic
              />
            </Grid>
          )}
          {values['exposure_id'] !== '' && values['document_template_unique_id'] !== '' && (
            <TemplateDynamicFieldsFragment
              exposureId={values['exposure_id']}
              isLoading={isLoadingDynamicFields}
              templateDynamicFields={documentTemplateDynamicFields}
            />
          )}
          <Grid item xs={12}>
            <div className={classes.buttonsContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                // documentTemplateDynamicFields === undefined while fetching and is [] if no dynamic params; don't allow submit while still fetching dynamic params
                disabled={
                  !values['document_template_unique_id'] || documentTemplateDynamicFields === undefined || isSubmitting
                }
              >
                Preview
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardDialog>
    </>
  );
};

UploadDocumentTemplateFormikInner.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
  claim: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  documentTemplateDynamicFields: PropTypes.array,
  onUpdateTemplateDynamicFields: PropTypes.func.isRequired,
};

function DocumentTemplatesAndBundlesFormikSelect(props) {
  const { id, label, claimId, exposureId, setFieldValue, ...rest } = props;

  const {
    isLoading,
    isError,
    data: templatesAndBundlesList,
  } = useDataFetcher(`/api/v1/claims/${claimId}/documents/document_templates_and_bundles`, {
    params: { exposure_id: exposureId },
  });

  const documentTemplateUniqueIdKey = id + '_unique_id';

  return isLoading || isError ? (
    <LoadingIndicator isError={isError} />
  ) : (
    <TextFieldFormik
      id={documentTemplateUniqueIdKey}
      label={label}
      onChange={(e) => {
        setFieldValue(documentTemplateUniqueIdKey, e.target.value);
        const templateOrBundle = templatesAndBundlesList.find((doc) => doc.unique_id === e.target.value);
        setFieldValue(id, templateOrBundle);
        setFieldValue('document_type', templateOrBundle.document_type);
      }}
      select
      {...rest}
      key={isLoading}
    >
      {sortBy(templatesAndBundlesList, ['display_name']).map((docTemplate) => (
        <MenuItem key={docTemplate.unique_id} value={docTemplate.unique_id}>
          {docTemplate.display_name}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
}

DocumentTemplatesAndBundlesFormikSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  claimId: PropTypes.number.isRequired,
  exposureId: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

function DocumentTemplatePreviewDialog(props) {
  const { open, claim, valuesForPreview, isSubmitting, onClose, onOnlineEdit, onCreateDocument, exposureId } = props;

  const classes = useStyles();

  const BASE_URL = `/api/v1/claims/${claim.id}/documents/${valuesForPreview['document_template']['type']}s/${valuesForPreview['document_template']['doc_id']}`;
  const templatesParams = useMemo(() => ({ params: valuesForPreview }), [valuesForPreview]);

  const {
    isLoading,
    isError,
    data: docTemplateRes,
  } = useDataFetcher(
    valuesForPreview['document_template_unique_id'] ? `${BASE_URL}/preview` : undefined,
    templatesParams
  );

  const exposures = getExposuresArrayFromClaimByIds(claim, [exposureId]);
  const handleClickDownloadForEditing = async () => {
    mixpanel.track(MIXPANEL_EVENTS.DOCUMENT_DOWNLOADED, {
      ...mixpanel.getMixpanelAttributes(claim, exposures, {
        source: MIXPANEL_EVENT_SOURCES.DOCUMENT_PREVIEW_DOWNLOADED_FOR_EDITING,
        document_type: valuesForPreview['document_template']['document_type'],
        template: valuesForPreview['document_template'],
      }),
    });
    const url = `${BASE_URL}/download_for_editing`;
    try {
      const response = await axios.post(url, valuesForPreview, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = downloadUrl;
      a.download = valuesForPreview['document_template']['display_name']; // Set the filename here
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  return (
    <Dialog open={open} fullScreen>
      {isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : (
        <>
          <div style={{ display: 'flex', margin: '15px' }}>
            <Button
              className={classes.leftButtonDialog}
              color="primary"
              variant="contained"
              size="small"
              disabled={isSubmitting}
              onClick={handleClickDownloadForEditing}
            >
              <span>Download for Editing</span>
            </Button>
            {onOnlineEdit && (
              <Button
                className={classes.leftButtonDialog}
                color="primary"
                variant="contained"
                size="small"
                disabled={isSubmitting}
                onClick={onOnlineEdit}
              >
                Edit Online
              </Button>
            )}
            <div style={{ flex: '1 1' }} />
            <DisableableTooltip
              disabled={docTemplateRes['is_missing']}
              title="Some values are still missing, download and edit manually"
            >
              <Button
                className={classes.leftButtonDialog}
                color="primary"
                variant="contained"
                size="small"
                disabled={docTemplateRes['is_missing'] || isSubmitting}
                onClick={onCreateDocument}
              >
                <span>Upload to Claim</span>
              </Button>
            </DisableableTooltip>
            <IconButton disabled={isSubmitting} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <object
            style={{ height: '100%', width: '100%', zIndex: '1' }}
            alt=""
            data={`data:application/pdf;base64,${docTemplateRes['document_base64']}`}
            type="application/pdf"
          />
        </>
      )}
    </Dialog>
  );
}

DocumentTemplatePreviewDialog.propTypes = {
  open: PropTypes.bool,
  claim: PropTypes.object.isRequired,
  valuesForPreview: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onOnlineEdit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onCreateDocument: PropTypes.func.isRequired,
  exposureId: PropTypes.number,
};

function DocumentTemplateOnlineEditor(props) {
  const { open, claim, values, onClose, onUploaded } = props;
  // See documentation: https://www.zoho.com/officeintegrator/api/v1/postmessage-api.html
  const { isLoaded: isZohoScriptLoaded } = useScript({
    id: 'zoho_crm',
    scriptUrl: 'https://js.zohocdn.com/officeplatform/v1/js/common/xdc-1.0.min.js',
  });
  const [zohoData, setZohoData] = React.useState();
  const [isError, setIsError] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const zohoRef = React.useRef(null);

  const classes = useStyles();

  const BASE_URL = `/api/v1/claims/${claim.id}/documents/${values['document_template']['type']}s/${values['document_template']['doc_id']}`;

  React.useEffect(() => {
    async function fetchOnlineEditorUrl() {
      try {
        const res = await axios.post(`${BASE_URL}/edit_online`, values);
        setZohoData(res.data);
      } catch (error) {
        reportAxiosError(error);
        setIsError(true);
      }
    }

    fetchOnlineEditorUrl();
  }, [BASE_URL, values]);

  React.useEffect(() => {
    if (!isZohoScriptLoaded || !zohoData) {
      return;
    }

    window.XDC.setTarget({
      origin: 'https://writer.zoho.com',
      window: zohoRef.current.contentWindow,
    });

    // NOTE: we're registering to listen on a SaveDocumentResponse event
    // this event will be received after successful upload of the document
    window.XDC.receiveMessage('SaveDocumentResponse', function (data) {
      // data is of the form: { status, response: { RESPONSE, STATUS_CODE } }
      let status = data.status;
      if (status === 200) {
        let doc = null;
        try {
          doc = JSON.parse(data.response.RESPONSE);
          // eslint-disable-next-line no-empty
        } catch {}
        onUploaded(doc);
      } else {
        const errorJson = { type: 'zoho_error', ...data };
        // Note: we intentionally don't check for failure of the axios.post since the original exception might have been caused by a network error
        // (and we don't want a weird recursion)
        axios.post('/api/v1/errors', errorJson);
        setIsSubmitting(false);
      }
    });
  }, [isZohoScriptLoaded, zohoData, onUploaded]);

  const onSave = () => {
    setIsSubmitting(true);
    // NOTE: send the iframe a message to save the document, the callback will actually upload the document to the claim
    window.XDC.postMessage({
      message: 'SaveDocument',
      data: {
        hideSaveButton: true, // Default value will be true
        forceSave: true, // Default value will be true
        format: 'pdf',
      },
      // Use "SaveDocumentResponse" event for oncomplete
      onexception(data) {
        const errorJson = { type: 'zoho_error', ...data };
        // Note: we intentionally don't check for failure of the axios.post since the original exception might have been caused by a network error
        // (and we don't want a weird recursion)
        axios.post('/api/v1/errors', errorJson);
        setIsSubmitting(false);
      },
    });
  };

  return (
    <Dialog open={open} fullScreen>
      {!zohoData ? (
        <>
          <div style={{ display: 'flex', margin: '15px' }}>
            <div style={{ flex: '1 1' }} />
            <IconButton disabled={isSubmitting} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <LoadingIndicator isError={isError} />
        </>
      ) : (
        <>
          <div style={{ display: 'flex', margin: '15px' }}>
            <div style={{ flex: '1 1' }} />
            <Button
              className={classes.leftButtonDialog}
              color="primary"
              variant="contained"
              size="small"
              disabled={!isZohoScriptLoaded || isSubmitting}
              onClick={onSave}
            >
              <span>Upload to Claim</span>
            </Button>
            <IconButton disabled={isSubmitting} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {zohoData && (
            <iframe ref={zohoRef} style={{ height: '100%', width: '100%', zIndex: '1' }} src={zohoData.document_url} />
          )}
        </>
      )}
    </Dialog>
  );
}

DocumentTemplateOnlineEditor.propTypes = {
  open: PropTypes.bool,
  claim: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUploaded: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  exposureId: PropTypes.number,
};

export default UploadDocumentFromTemplate;
