const BIKE_TYPE_DICT = {
  city_bike: {
    desc: 'City Bike',
  },
  electric_city_bike: {
    desc: 'Electric City Bike',
  },
  racing_bike: {
    desc: 'Racing Bike',
  },
  electric_racing_bike: {
    desc: 'Electric Racing Bike',
  },
  mountain_bike: {
    desc: 'Mountain Bike',
  },
  electric_mountain_bike: {
    desc: 'Electric Mountain Bike',
  },
  cargo_bike: {
    desc: 'Cargo Bike',
  },
  electric_cargo_bike: {
    desc: 'Electric Cargo Bike',
  },
} as const;

export default BIKE_TYPE_DICT;
