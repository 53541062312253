import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ArrowDownwardIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path d="M13.3333 7.99996L12.3933 7.05996L8.66663 10.78V2.66663H7.33329V10.78L3.61329 7.05329L2.66663 7.99996L7.99996 13.3333L13.3333 7.99996Z" />
  </SvgIcon>
);

export default ArrowDownwardIcon;
