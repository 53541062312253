import mixpanel from '../../components/CmsMain/mixpanel';
import { MIXPANEL_EVENTS } from '../../pocs/mixpanel';
import { getTabName } from '../../Utils';

import { REPORTS_DASHBOARDS_PERMISSIONS_MAP } from './ReportsDashboardsPermissions';
import TableauView from './TableauView';

export const onTabClick = () => {
  const { parentTab, tabName } = getTabName();

  mixpanel.track(MIXPANEL_EVENTS.REPORTING_ENTITY_TAB_CLICKED, {
    reporting_entity: parentTab,
    tab_name: tabName,
  });
};

const hasPermissions = (item) => item?.props?.action && item?.props?.verb;

export const buildViewItem = ({ label, url, workbook, view }) => ({
  label,
  url,
  component: TableauView,
  props: {
    workbook,
    view,
    action: REPORTS_DASHBOARDS_PERMISSIONS_MAP[view]?.action,
    verb: REPORTS_DASHBOARDS_PERMISSIONS_MAP[view]?.verb,
  },
});

export const buildViewItems = ({ overrideViewConfiguration = {} }) =>
  Object.values(overrideViewConfiguration || {})
    .map(buildViewItem)
    .filter(hasPermissions);
