import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useClaim } from '~/components/ClaimContainer';
import { CreateSubDocumentDialog, ExtractDocumentPhotosContainer } from '~/components/Documents/DocumentCard';
import ManipulatePdfViewer from '~/components/Documents/DocumentMoreActions/ManipulatePdf/ManipulatePdfViewer';

const ManipulatePdfContainer = ({ isOpen, document, onClose }) => {
  const { claim, onClaimUpdate } = useClaim();
  const [showExtractPhotos, setShowExtractPhotos] = useState(false);
  const [showCreateSubDocument, setShowCreateSubDocument] = useState(false);

  return (
    <>
      {isOpen && (
        <ManipulatePdfViewer
          claim={claim}
          document={document}
          onExtractPhotosClick={() => setShowExtractPhotos(true)}
          onCreateSubDocumentClick={() => setShowCreateSubDocument(true)}
          onClose={() => onClose()}
        />
      )}
      {showExtractPhotos && (
        <ExtractDocumentPhotosContainer
          claim={claim}
          onCancel={() => setShowExtractPhotos(false)}
          document={document}
          onUpdate={async () => await onClaimUpdate()}
        />
      )}
      {showCreateSubDocument && (
        <CreateSubDocumentDialog
          claim={claim}
          onCancel={() => setShowCreateSubDocument(false)}
          document={document}
          onSubDocCreated={async (document) => {
            window.open(`/api/v1/claims/${document.claim_id}/documents/${document.id}`, '_target');
            await onClaimUpdate();
            setShowCreateSubDocument(false);
          }}
        />
      )}
    </>
  );
};

ManipulatePdfContainer.propTypes = {
  isOpen: PropTypes.bool,
  document: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default ManipulatePdfContainer;
