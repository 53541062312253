import React from 'react';

import { PropertyDetails } from '../../core';
import { PropertyDetailsRow } from '../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import type { LegalActionsConfigurationContextValue, Option } from '../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../hooks/useLegalActionsConfiguration';
import { getLabelByKey } from '../LegalActionsUtils';

interface ConfigurationValuePropertyDetailsProps {
  title: string;
  value: string;
  otherTextTitle?: string;
  otherTextValue?: string;
  optionsKey: keyof LegalActionsConfigurationContextValue;
}

export const ConfigurationValuePropertyDetails: React.FC<ConfigurationValuePropertyDetailsProps> = ({
  title,
  value,
  optionsKey,
  otherTextValue,
  otherTextTitle,
}) => {
  const { [optionsKey]: options } = useLegalActionsConfiguration();

  const propertyDetailsValueComponent = (
    <PropertyDetails title={title} text={getLabelByKey(options as Option[], value) || ''} />
  );

  if (!otherTextTitle) {
    return propertyDetailsValueComponent;
  }

  return (
    <PropertyDetailsRow>
      {propertyDetailsValueComponent}
      {otherTextTitle && otherTextValue !== undefined ? (
        <PropertyDetails title={otherTextTitle} text={otherTextValue} />
      ) : null}
    </PropertyDetailsRow>
  );
};
