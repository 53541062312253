import { useClaim } from '~/components/ClaimContainer';
import useDataFetcher from '~/components/useDataFetcher';

interface UseClaimChatAuthReturn {
  isClaimChatEnabled: boolean;
}
export const useClaimChatAuth = (): UseClaimChatAuthReturn => {
  const { claim } = useClaim();
  const { data: specialUserChatPermission } = useDataFetcher(`/api/v1/claims/${claim.id}/chat/is_allowed`, {});

  return {
    isClaimChatEnabled: specialUserChatPermission?.is_allowed,
  };
};
