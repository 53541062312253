import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const TravelIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99989 13.5L10.4998 14.5V12.5L9.49978 11.5V9.49995L14.4998 10.5V8.49995L9.49978 5.99995V2.99995C9.49978 2.60213 9.34175 2.2206 9.06045 1.93929C8.79906 1.67791 8.45114 1.52295 8.08402 1.50232C8.02928 1.65803 7.99951 1.82551 7.99951 1.99993C7.99951 2.96873 7.99963 11.4996 7.99989 13.5Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M8 13.5L5.5 14.5V12.5L6.5 11.5V9.5L1.5 10.5V8.5L6.5 6V3C6.5 2.60218 6.65804 2.22064 6.93934 1.93934C7.22064 1.65804 7.60218 1.5 8 1.5C8.39782 1.5 8.77936 1.65804 9.06066 1.93934C9.34196 2.22064 9.5 2.60218 9.5 3V6L14.5 8.5V10.5L9.5 9.5V11.5L10.5 12.5V14.5L8 13.5Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default TravelIcon;
