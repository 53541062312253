import type { ReactNode } from 'react';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { flatten, isEmpty, throttle } from 'lodash';

import cn from '~/Utils/cn';

import CollapsibleWrapper from '../../Collapsible/CollapsibleWrapper';
import { Heading, Text } from '../../TextComponents';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './FsIndexPanel.module.scss';

interface IndexPanelSection {
  id: string | number;
  title: string;
  node: ReactNode;
  isHidden?: boolean;
  isCollapsible?: boolean;
  children?: IndexPanelSection[];
}

interface FsIndexPanelProps {
  panelTitle: string;
  sections: IndexPanelSection[];
  SectionWrapperComponent: React.FC;
  sectionContainerClassName?: string;
  handleScrollTopOffset: number;
}

const WAIT_MS = 200;

const FsIndexPanel: React.FC<FsIndexPanelProps> = ({
  panelTitle,
  sections,
  SectionWrapperComponent = Fragment,
  sectionContainerClassName,
  handleScrollTopOffset = 0,
}) => {
  const [activeSection, setActiveSection] = useState(sections[0].id);

  const sectionRefs = useRef<{ [sectionId: string]: HTMLElement | null }>({});

  const handleScroll = useCallback(() => {
    Object.keys(sectionRefs.current).find((sectionId) => {
      const element = sectionRefs.current[sectionId];
      if (element) {
        const rect = element.getBoundingClientRect();

        const bottomShows = rect.top < window.innerHeight && rect.bottom >= 0;
        const topShows = rect.top >= handleScrollTopOffset && rect.top <= window.innerHeight;

        if (bottomShows && topShows) {
          setActiveSection(sectionId);
          return bottomShows && topShows;
        }
      }
    });
  }, [handleScrollTopOffset]);

  useEffect(() => {
    // this listener creates dual-dependency on the scroll event - throttle handleScroll
    const fn = throttle(handleScroll, WAIT_MS);
    window.addEventListener('scroll', fn, true);
    return () => {
      window.removeEventListener('scroll', fn, true);
    };
  }, [handleScroll]);

  const scrollToSection = (sectionId: string | number) => {
    if (sectionRefs.current[sectionId]) {
      sectionRefs.current[sectionId]?.scrollIntoView({
        behavior: 'smooth',
      });
      setActiveSection(sectionId);
    }
  };

  const sectionsToShowOnIndex = sections.filter(({ isHidden }) => !isHidden);
  const sectionsToShowOnView = flatten(
    sectionsToShowOnIndex?.map((section) => (!isEmpty(section?.children) ? section.children : section)) || []
  );

  return (
    <div className={styles.indexPanelWrapper}>
      <div className="w-full">
        {sectionsToShowOnView?.map((section) => {
          if (!section) return null;

          const { node, id } = section;
          return (
            <div
              className={sectionContainerClassName}
              key={id}
              ref={(ref) => {
                if (sectionRefs?.current) sectionRefs.current[id] = ref;
              }}
            >
              <SectionWrapperComponent>{node}</SectionWrapperComponent>
            </div>
          );
        })}
      </div>
      <div className={styles.indexPanel}>
        <Heading variant={Heading.TYPES.H3} className={styles.heading}>
          {panelTitle}
        </Heading>
        <div className={styles.sectionsIndexes}>
          {sectionsToShowOnIndex.map(({ title, id, children = [] }) => (
            <>
              {isEmpty(children) ? (
                <div
                  onClick={() => scrollToSection(id)}
                  className={cn(styles.index, { [styles.activeIndex]: activeSection === id })}
                >
                  <Text
                    variant={Text.VARIANTS.SM}
                    colorVariant={Text.COLOR_VARIANTS.SECONDARY}
                    className={styles.sectionTitle}
                  >
                    {title}
                  </Text>
                </div>
              ) : (
                <CollapsibleWrapper
                  chevronSide={CollapsibleWrapper.CHEVRON_SIDE.RIGHT}
                  variant={CollapsibleWrapper.VARIANT.slim}
                  summarySectionClassname={styles.index}
                  title={
                    <div>
                      <Text
                        variant={Text.VARIANTS.SM}
                        colorVariant={Text.COLOR_VARIANTS.SECONDARY}
                        className={styles.sectionTitle}
                      >
                        {title}
                      </Text>
                    </div>
                  }
                  key={id}
                  noBorder
                  noDivider
                  withActionsContainerFullWidth={false}
                >
                  {children.map(({ title, id }) => (
                    <div
                      key={id}
                      onClick={() => scrollToSection(id)}
                      className={cn(styles.index, {
                        [styles.collapseChild]: true,
                        [styles.activeIndex]: activeSection === id,
                      })}
                    >
                      <Text
                        variant={Text.VARIANTS.SM}
                        colorVariant={Text.COLOR_VARIANTS.SECONDARY}
                        className={styles.sectionTitle}
                      >
                        {title}
                      </Text>
                    </div>
                  ))}
                </CollapsibleWrapper>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FsIndexPanel;
