import React from 'react';
import { Divider as MuiDivider } from '@material-ui/core';

import cn from '~/Utils/cn';
/**
 This Component is a wrapper on MUI component.
 This component should be imported and not MUI directly!
**/
export interface DividerProps {
  className?: string;
}

const Divider: React.VFC<DividerProps> = ({ className }) => {
  return <MuiDivider className={cn('bg-slate-600', className)} />;
};

export default Divider;
