import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { BOOL4_OPTIONS } from '../../../../Types';
import RadioButtonFormik from '../../../RadioButtonFormik';
import { ErrorHelperTextFormik } from '../ErrorHelperTextFormik';

import colors from '../../../../assets/colors.module.scss';
import styles from './index.module.scss';

const DEFAULT_OPTIONS = [
  {
    label: BOOL4_OPTIONS.yes,
    optionValue: 'yes',
  },
  {
    label: BOOL4_OPTIONS.no,
    optionValue: 'no',
  },
  {
    label: BOOL4_OPTIONS.na,
    optionValue: 'na',
  },
  {
    label: BOOL4_OPTIONS.unknown,
    optionValue: 'unknown',
  },
];

const isBtnDisabled = ({ disabled, option, disable_na, disable_unknown }) =>
  disabled || (option.optionValue === 'na' && disable_na) || (option.optionValue === 'unknown' && disable_unknown);

const YesNoNaUnknownQuestionFormik = ({
  id,
  questionText,
  disabled = false,
  disable_na = false,
  disable_unknown = false,
  options = DEFAULT_OPTIONS,
}) => {
  return (
    <Grid container className={styles.container}>
      <Grid item xs={4}>
        <span className={styles.questionText}>
          {questionText}
          <ErrorHelperTextFormik id={id} className={styles.error} />
        </span>
      </Grid>
      <Grid item xs={8}>
        <div className={styles.radioBtnContainer}>
          {options.map((option) => (
            <RadioButtonFormik
              key={option.label}
              id={id}
              label={option.label}
              optionValue={option.optionValue}
              disabled={isBtnDisabled({
                disabled,
                option,
                disable_na,
                disable_unknown,
              })}
              size="small"
              color={colors.buttonLink}
            />
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

YesNoNaUnknownQuestionFormik.propTypes = {
  id: PropTypes.string.isRequired,
  questionText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disable_na: PropTypes.bool,
  disable_unknown: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      optionValue: PropTypes.string.isRequired,
    })
  ),
};

YesNoNaUnknownQuestionFormik.DEFAULT_OPTIONS = DEFAULT_OPTIONS;

export default YesNoNaUnknownQuestionFormik;
