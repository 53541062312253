import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';

import CommunicationActionSendNew from '~/components/communications/CommunicationCard/CommunicationActionSendNew';
import useCreateCommunication from '~/components/communications/CommunicationCard/useCreateCommunication';
import SmsCommunicationSpecificBody from '~/components/communications/SmsCommunicationCard/SmsCommunicationSpecificBody';
import { MAX_SMS_LENGTH, smsMessageValidation } from '~/components/communications/SmsCommunicationCard/utils';
import { FROM_TEMPLATE_FORM_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { useCms } from '~/components/hooks/useCms';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { getExposuresArrayFromClaimByIds, isFeatureEnabled, reportAxiosError } from '~/Utils';

import { useClaim } from '../../ClaimContainer';
import mixpanel from '../../CmsMain/mixpanel';
import LoadingDialog from '../../LoadingDialog';
import useDataFetcher from '../../useDataFetcher';
import CommunicationCard from '../CommunicationCard';

function SendSmsCommunicationCardContainer(props) {
  const { contact, contactPhoneId, onCancel, onSendSms, messageContent, onMinimized, disableMinimized, open } = props;
  const validationSchema = smsMessageValidation;

  const { claim } = useClaim();
  const {
    isLoading,
    isError,
    data: userSignatureData,
  } = useDataFetcher(`/api/v1/organizations/${claim.organization_id}/sms_signature`);
  const { communicationCommonInitialValues } = useCreateCommunication('sms', { contact });

  const { userOrganization } = useCms();
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);

  if (isLoading) {
    return <LoadingDialog isError={isError} track="Send Sms" />;
  }

  const userSignature = userSignatureData.user_sms_signature;

  return (
    <Formik
      initialValues={{
        ...communicationCommonInitialValues,
        message_content:
          messageContent === undefined
            ? `Regarding claim ${claim.claim_id_display} with ${claim.policy.client},\n\n\n\n${userSignature}`
            : messageContent,
        phone_id: contactPhoneId,
        [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_ID]: null,
        [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_CONTEXT]: null,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formikProps) => {
        return axios
          .post(
            `/api/v1/claims/${claim.id}/contacts/${values.contact.id}/phones/${values['phone_id']}/send_sms`,
            values
          )
          .then((res) => {
            formikProps.setSubmitting(false);
            onSendSms(res.data);

            const exposures = getExposuresArrayFromClaimByIds(claim, values.exposure_ids);
            mixpanel.track(MIXPANEL_EVENTS.SMS_COMMUNICATION_SENT, {
              ...mixpanel.getMixpanelAttributes(claim, exposures, {
                communication_id: res.data.id,
                characters: values.message_content?.length,
                character_limit: MAX_SMS_LENGTH,
                status: res.status === 204 ? 'success' : 'failed',
                http_response_code: res.status,
              }),
            });
          })
          .catch((error) => {
            formikProps.setSubmitting(false);
            reportAxiosError(error);
          });
      }}
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;

        return (
          <CommunicationCard
            title={isNewUIEnabled ? 'New Text Message' : undefined}
            communicationInitialFields={{
              communicationChannel: 'sms',
              communicationContact: values.contact,
              communicationDirection: 'Outgoing',
            }}
            communicationHeaderAdditionalProps={{
              isDynamicContact: !contact,
              dynamicContactLabel: 'Contact',
              onSelectContact: (contact) => {
                if (!contact || contact.id === '') {
                  setFieldValue('phone_id', '');
                } else {
                  if (contact.phones.length > 0) {
                    setFieldValue('phone_id', contact.phones[0].id);
                  } else {
                    setFieldValue('phone_id', '');
                  }
                }
              },
            }}
            CommunicationTypeSpecificBody={SmsCommunicationSpecificBody}
            communicationTypeSpecificBodyProps={{
              signature: userSignature,
            }}
            isDialog
            isNew
            onClose={onCancel}
            CommunicationAction={CommunicationActionSendNew}
            communicationActionProps={{
              onSubmit: () => {
                formikProps.handleSubmit();
              },
              isSubmitting: formikProps.isSubmitting,
            }}
            onMinimized={onMinimized}
            disableMinimized={disableMinimized}
            open={open}
          />
        );
      }}
    </Formik>
  );
}

SendSmsCommunicationCardContainer.propTypes = {
  contact: PropTypes.object.isRequired,
  contactPhoneId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSendSms: PropTypes.func.isRequired,
  messageContent: PropTypes.string,
  open: PropTypes.bool,
  disableMinimized: PropTypes.bool,
  onMinimized: PropTypes.func,
};

export default SendSmsCommunicationCardContainer;
