import React from 'react';
import axios from 'axios';

import { reportAxiosError } from '../../../../../Utils';
import { ObjectCard, SecondaryCard } from '../../../../core';
import { LegalActionAddItemButton } from '../../../components/LegalActionAddItemButton';
import { LegalActionItemMenu } from '../../../components/LegalActionItemMenu';
import { AddDateOfServiceDialog } from '../../../Dialogs/DateOfService/AddDateOfServiceDialog';
import { EditDateOfServiceDialog } from '../../../Dialogs/DateOfService/EditDateOfServiceDialog';
import { useLegalAction } from '../../../hooks/useLegalAction';
import { useLegalActionsBackendRoutes } from '../../../hooks/useLegalActionsBackendRoutes';
import type { LegalActionDateOfServiceApiResponse } from '../../../types';

import { DateOfServiceContent } from './DateOfServiceContent';
import { DateOfServiceMoreDetailsDialog } from './DateOfServiceMoreDetailsDialog';

export const DatesOfServiceCard: React.FC = () => {
  const { legalAction, reloadLegalAction } = useLegalAction();
  const [isNewDateOfServiceDialogOpen, setIsNewDateOfServiceDialogOpen] = React.useState(false);
  const [dateOfServiceInEdit, setDateOfServiceInEdit] = React.useState<LegalActionDateOfServiceApiResponse | null>(
    null
  );
  const [dateOfServiceInMoreDetails, setDateOfServiceInMoreDetails] =
    React.useState<LegalActionDateOfServiceApiResponse | null>(null);

  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleDeleteDateOfService = async (dateOfService: LegalActionDateOfServiceApiResponse) => {
    try {
      await axios.delete(backendRoutes.legalAction.date_of_service(dateOfService.id));
      await reloadLegalAction();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <>
      <SecondaryCard type="filled" title="Date of Service Information" collapsible openByDefault>
        {legalAction.dates_of_service.map((dateOfService) => (
          <ObjectCard
            key={dateOfService.id}
            type="contained"
            action={
              <LegalActionItemMenu
                onDeleteClick={() => handleDeleteDateOfService(dateOfService)}
                onEditClick={() => setDateOfServiceInEdit(dateOfService)}
                onViewMore={() => setDateOfServiceInMoreDetails(dateOfService)}
                entityLabel="Date of Service"
              />
            }
          >
            <DateOfServiceContent dateOfService={dateOfService} rows={1} />
          </ObjectCard>
        ))}
        <div>
          <LegalActionAddItemButton text="Add Date Of Service" onClick={() => setIsNewDateOfServiceDialogOpen(true)} />
        </div>
      </SecondaryCard>
      {isNewDateOfServiceDialogOpen ? (
        <AddDateOfServiceDialog
          legalAction={legalAction}
          onClose={() => setIsNewDateOfServiceDialogOpen(false)}
          onSubmitSuccess={reloadLegalAction}
        />
      ) : null}
      {dateOfServiceInEdit ? (
        <EditDateOfServiceDialog
          dateOfService={dateOfServiceInEdit}
          legalAction={legalAction}
          onClose={() => setDateOfServiceInEdit(null)}
          onSubmitSuccess={reloadLegalAction}
        />
      ) : null}
      {dateOfServiceInMoreDetails ? (
        <DateOfServiceMoreDetailsDialog
          onClose={() => setDateOfServiceInMoreDetails(null)}
          dateOfService={dateOfServiceInMoreDetails}
        />
      ) : null}
    </>
  );
};
