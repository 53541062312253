import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const PhoneIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill={iconColor} disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 21.0032C12.89 21.0032 9.5 19.6032 6.95 17.0532C4.4 14.5032 3 11.1132 3 7.50317C3 6.22317 3.47 4.99317 4.31 4.03317C5.15 3.07317 6.32 2.45317 7.58 2.29317C7.9 2.25317 8.23 2.31317 8.52 2.48317C8.8 2.64317 9.03 2.90317 9.15 3.20317L11.03 7.59317C11.13 7.81317 11.17 8.06317 11.15 8.30317C11.13 8.54317 11.05 8.78317 10.92 8.99317L9.35 11.3732C10.05 12.8032 11.22 13.9732 12.65 14.6732L14.99 13.1032C15.19 12.9632 15.44 12.8832 15.69 12.8532C15.94 12.8332 16.2 12.8732 16.43 12.9732L20.81 14.8532C21.1 14.9732 21.36 15.2032 21.52 15.4832C21.68 15.7632 21.75 16.0932 21.71 16.4132C21.55 17.6832 20.93 18.8432 19.97 19.6832C19.01 20.5232 17.78 20.9932 16.5 20.9932V21.0032ZM7.77 3.78317C6.87 3.89317 6.04 4.34317 5.44 5.02317C4.84 5.70317 4.51 6.58317 4.5 7.50317C4.5 10.7132 5.75 13.7232 8.01 15.9832C10.28 18.2532 13.29 19.4932 16.5 19.4932C17.41 19.4932 18.29 19.1632 18.98 18.5632C19.66 17.9632 20.11 17.1332 20.22 16.2232L15.83 14.3432L13.49 15.9132C13.28 16.0532 13.03 16.1432 12.77 16.1632C12.51 16.1832 12.25 16.1332 12.02 16.0232C10.27 15.1832 8.85 13.7632 8.01 12.0232C7.9 11.7932 7.85 11.5332 7.87 11.2832C7.89 11.0332 7.97 10.7832 8.11 10.5632L9.68 8.18317L7.79 3.79317L7.77 3.78317Z"
      fill={iconColor}
    />
  </SvgIcon>
);

PhoneIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default PhoneIcon;
