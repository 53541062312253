import React from 'react';

import SvgIcon from '../core/SvgIcon';

const FinancesIcon = (props) => (
  <SvgIcon fill="none" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_369_3172)">
      <path
        d="M16.25 4.375H3.75C3.05964 4.375 2.5 4.93464 2.5 5.625V14.375C2.5 15.0654 3.05964 15.625 3.75 15.625H16.25C16.9404 15.625 17.5 15.0654 17.5 14.375V5.625C17.5 4.93464 16.9404 4.375 16.25 4.375Z"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 9.375H6.9375C7.08144 9.37421 7.22113 9.42374 7.33241 9.51505C7.44368 9.60635 7.51955 9.73368 7.54687 9.875C7.66623 10.4371 7.97502 10.9412 8.42157 11.3029C8.86812 11.6646 9.42534 11.8619 10 11.8619C10.5747 11.8619 11.1319 11.6646 11.5784 11.3029C12.025 10.9412 12.3338 10.4371 12.4531 9.875C12.4805 9.73368 12.5563 9.60635 12.6676 9.51505C12.7789 9.42374 12.9186 9.37421 13.0625 9.375H17.5"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.5 6.875H17.5" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_369_3172">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default FinancesIcon;
