import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { LEGAL_ACTION_PARTY_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { LegalActionPartyFormValues } from '../../FormFragments/FormValuesTypes';
import { PartyForm } from '../../FormFragments/PartyForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import { PartyType } from '../../types';
import { FormDialog } from '../FormDialog';
import { partyInitialValues } from '../InitialValues';

import type { AddPartyDialogProps } from './PartyDialogTypes';

export const AddPartyDialog: React.FC<AddPartyDialogProps> = ({ partyType, onClose, onSubmitSuccess, legalAction }) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: LegalActionPartyFormValues) => {
    try {
      await axios.post(backendRoutes.legalAction.parties, {
        type: partyType,
        ...values,
      });
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  const title = `Add ${partyType === PartyType.PLAINTIFF ? 'Plaintiff' : 'Defendant'}`;

  return (
    <FormDialog<LegalActionPartyFormValues>
      title={title}
      onClose={onClose}
      maxWidth="md"
      formikConfig={{
        onSubmit: handleSubmit,
        validationSchema: Yup.object().shape(LEGAL_ACTION_PARTY_VALIDATION_SCHEMA(legalAction)),
        initialValues: partyInitialValues,
      }}
    >
      <div className="flex w-full flex-col gap-20">
        <PartyForm
          partyType={partyType}
          hideCarrierInvolvedParty={legalAction.legal_action_parties.some((p) => p.is_carrier_party)}
        />
      </div>
    </FormDialog>
  );
};
