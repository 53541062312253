import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';

import { useClaim } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import CommunicationCard from '~/components/communications/CommunicationCard';
import CommunicationActionSendNew from '~/components/communications/CommunicationCard/CommunicationActionSendNew';
import useCreateCommunication from '~/components/communications/CommunicationCard/useCreateCommunication';
import EmailCommunicationSpecificBody from '~/components/communications/EmailCommunicationCard/EmailCommunicationSpecificBody';
import {
  getEmailMessageNotAttachedToClaimValidation,
  getEmailMessageValidation,
} from '~/components/communications/EmailCommunicationCard/schemaValidations';
import { getNamesOfMissingEmailsFromRecipients } from '~/components/communications/EmailCommunicationCard/utils';
import { Text } from '~/components/core';
import { FROM_TEMPLATE_FORM_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { useCms } from '~/components/hooks/useCms';
import LoadingDialog from '~/components/LoadingDialog';
import useDataFetcher from '~/components/useDataFetcher';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { getExposuresArrayFromClaimByIds, isFeatureEnabled, reportAxiosError } from '~/Utils';

const SendEmailCommunicationCardContainer = (props) => {
  const {
    contact,
    communication,
    cc_contacts,
    bcc_contacts,
    contactEmailId,
    onClose,
    onSendEmail,
    emailTitle,
    emailText,
    summary,
    attachments,
    exposureIds,
    forcedRecipient, // forcedRecipient is given in case of reply email of unknown claim
    onSubmitOverride,
    referredCommunicationId,
    open,
    onMinimized,
    disableMinimized,
    isNotClaimRelated = false,
    shouldDisplaySenderEmail = true,
  } = props;
  const validationSchema = forcedRecipient
    ? getEmailMessageNotAttachedToClaimValidation()
    : getEmailMessageValidation('Outgoing', isNotClaimRelated);
  const { claim } = useClaim(); // claim may be undefined - In case of reply to email of an unknown claim
  const { userOrganization } = useCms();
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);

  const {
    isLoading,
    isError,
    data: userSignatureHtmlData,
  } = useDataFetcher(`/api/v1/organizations/${userOrganization.id}/email_signature`, {
    params: { claim_id: claim?.id, referred_communication_id: referredCommunicationId },
  });
  const { communicationCommonInitialValues } = useCreateCommunication('email', {
    summary,
    contact,
    exposure_ids: exposureIds,
  });

  if (isLoading) {
    return <LoadingDialog isError={isError} isLoading={isLoading} track="Send Email" />;
  }

  const userSignatureHtml = userSignatureHtmlData?.user_email_signature_html;

  return (
    <Formik
      initialValues={{
        ...communicationCommonInitialValues,
        cc_contacts: cc_contacts || [],
        bcc_contacts: bcc_contacts || [],
        subject: emailTitle || `Regarding claim ${claim.claim_id_display} with ${claim.policy.client}`,
        body_html: emailText || `<div></div><div></div>${userSignatureHtml}`,
        attached_documents: attachments || [],
        email_id: contact ? contactEmailId || (contact.primary_email && contact.primary_email.id) : '',
        [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_ID]: null,
        [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_CONTEXT]: null,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formikProps) => {
        const exposures = getExposuresArrayFromClaimByIds(claim, values.exposure_ids);
        if (onSubmitOverride) {
          return onSubmitOverride(values)
            .then(() => {
              formikProps.setSubmitting(false);
              if (claim) {
                mixpanel.track(MIXPANEL_EVENTS.EMAIL_COMMUNICATION_SENT, {
                  ...mixpanel.getMixpanelAttributes(claim, exposures, {
                    with_reminder: values.add_follow_up_reminder,
                    contact_id: values.contact.id,
                    attached_files: [
                      ...values.attached_documents.map(
                        (attachment) =>
                          `File Name: ${attachment.file_name}; File Size: ${attachment.stored_file.size}; File Type: ${attachment.stored_file.mime_type}`
                      ),
                    ],
                  }),
                });
              } else {
                mixpanel.track(MIXPANEL_EVENTS.EMAIL_COMMUNICATION_SENT, {
                  with_reminder: values.add_follow_up_reminder,
                  contact_id: values.contact?.id,
                  attached_files: [
                    ...values.attached_documents.map(
                      (attachment) =>
                        `File Name: ${attachment.file_name}; File Size: ${attachment.stored_file.size}; File Type: ${attachment.stored_file.mime_type}`
                    ),
                  ],
                });
              }
            })
            .catch(async (error) => {
              formikProps.setSubmitting(false);
              await reportAxiosError(error);
            });
        }

        let url;
        let valuesToSend = values;

        if (forcedRecipient) {
          url = '/api/v1/communications/reply_to_not_claim_related_email';
          valuesToSend = {
            ...valuesToSend,
            forced_contact: forcedRecipient.optionalContact,
            forced_contact_email: forcedRecipient.optionalContactEmail,
            email_address: forcedRecipient.emailAddress,
            referred_communication_id: referredCommunicationId,
          };
        } else {
          url = `/api/v1/claims/${claim.id}/contacts/${values.contact.id}/emails/${values['email_id']}/send_email`;
        }

        return axios
          .post(url, valuesToSend)
          .then(async (res) => {
            await onSendEmail(res.data);
            if (claim) {
              mixpanel.track(MIXPANEL_EVENTS.EMAIL_COMMUNICATION_SENT, {
                ...mixpanel.getMixpanelAttributes(claim, exposures, {
                  with_reminder: values.add_follow_up_reminder,
                  contact_id: values.contact.id,
                  communication_id: res.data.id,
                  attached_files: [
                    ...values.attached_documents.map(
                      (attachment) =>
                        `File Name: ${attachment.file_name}; File Size: ${attachment.stored_file.size}; File Type: ${attachment.stored_file.mime_type}`
                    ),
                  ],
                  status: res.status === 200 ? 'Success' : 'Failed',
                  http_response_code: res.status,
                }),
              });
            } else {
              mixpanel.track(MIXPANEL_EVENTS.EMAIL_COMMUNICATION_SENT, {
                with_reminder: values.add_follow_up_reminder,
                contact_id: values.contact?.id,
                communication_id: res.data.id,
                attached_files: [
                  ...values.attached_documents.map(
                    (attachment) =>
                      `File Name: ${attachment.file_name}; File Size: ${attachment.stored_file.size}; File Type: ${attachment.stored_file.mime_type}`
                  ),
                ],
                status: res.status === 200 ? 'Success' : 'Failed',
                http_response_code: res.status,
              });
            }
          })
          .catch(async (error) => {
            formikProps.setSubmitting(false);
            await reportAxiosError(error);
          });
      }}
    >
      {(formikProps) => {
        const { values, setFieldValue, setFieldTouched } = formikProps;

        return (
          <CommunicationCard
            title={isNewUIEnabled ? 'New Email' : undefined}
            communication={communication}
            senderFragment={
              userOrganization?.is_claims_inbox_enabled && shouldDisplaySenderEmail ? (
                <SenderFragment userSignatureHtmlData={userSignatureHtmlData} />
              ) : (
                <></>
              )
            }
            communicationInitialFields={{
              communicationChannel: 'email',
              communicationContact: values.contact,
              communicationDirection: 'Outgoing',
            }}
            CommunicationTypeSpecificBody={EmailCommunicationSpecificBody}
            communicationTypeSpecificBodyProps={{
              forcedRecipient,
              isNotClaimRelated: !!forcedRecipient || isNotClaimRelated,
              emailSignature: userSignatureHtml,
            }}
            communicationHeaderAdditionalProps={{
              outOfContactsContextSearch: !claim,
              isDynamicContact: !contact && !forcedRecipient,
              dynamicContactLabel: 'Send To',
              onSelectContact: (contact) => {
                if (!contact || contact.id === '') {
                  setFieldValue('email_id', '');
                  setFieldValue('email_address', '');
                  setFieldTouched('email_address', '');
                  setFieldTouched('email_id', '');
                } else if (contact.emails.length > 0) {
                  setFieldValue('email_id', contact.emails[0].id);
                  setFieldValue('email_address', contact.emails[0].email);
                  setFieldTouched('email_address', '');
                  setFieldTouched('email_id', '');
                } else {
                  setFieldValue('email_id', '');
                  setFieldValue('email_address', '');
                  setFieldTouched('email_address', '');
                  setFieldTouched('email_id', '');
                }
              },
            }}
            isDialog
            isNew
            maxWidth="lg"
            onClose={onClose}
            CommunicationAction={CommunicationActionSendNew}
            communicationActionProps={{
              onSubmit: () => {
                formikProps.handleSubmit();
              },
              isSubmitting: formikProps.isSubmitting,
              disabled: getNamesOfMissingEmailsFromRecipients(values['cc_contacts']).length > 0,
            }}
            open={open}
            onMinimized={onMinimized}
            disableMinimized={disableMinimized}
          />
        );
      }}
    </Formik>
  );
};

const SenderFragment = ({ userSignatureHtmlData }) => {
  return (
    <div className="flex items-center justify-center">
      <Text variant={Text.VARIANTS.XS}>
        <b>From:</b> {userSignatureHtmlData?.sender_email_address}
      </Text>
    </div>
  );
};

SenderFragment.propTypes = {
  userSignatureHtmlData: {
    sender_email_address: PropTypes.string,
  },
};

SendEmailCommunicationCardContainer.propTypes = {
  contact: PropTypes.object,
  communication: PropTypes.object,
  cc_contacts: PropTypes.array,
  bcc_contacts: PropTypes.array,
  contactEmailId: PropTypes.number,
  onSendEmail: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onMinimized: PropTypes.func,
  attachments: PropTypes.array,
  emailTitle: requiredIf(PropTypes.string, (props) => props.forcedRecipient),
  emailText: PropTypes.string,
  summary: PropTypes.string,
  exposureIds: PropTypes.array,
  forcedRecipient: PropTypes.object,
  onSubmitOverride: PropTypes.func,
  referredCommunicationId: PropTypes.number,
  isNotClaimRelated: PropTypes.bool,
  shouldDisplaySenderEmail: PropTypes.bool,
  disableMinimized: PropTypes.bool,
  open: PropTypes.bool,
};

export default SendEmailCommunicationCardContainer;
