import { __PERMISSION_VERBS__ } from './PermissionVerbs';
import { ConfigEnumBase, DefaultProfile } from './types';

class DEFAULT_PROFILES extends ConfigEnumBase {
  ADJUSTER = new DefaultProfile({
    display_name: 'Adjuster',
    description: 'Generic system profile for claim reps',
    defaults: __PERMISSION_VERBS__.WRITE.optionValue,
    isSelectableInWizard: true,
  });
  SUPERVISOR = new DefaultProfile({
    display_name: 'Supervisor',
    description: 'Generic system profile for phone reps',
    defaults: __PERMISSION_VERBS__.FULL.optionValue,
    isSelectableInWizard: true,
  });
  PHONE_REP = new DefaultProfile({
    display_name: 'Phone rep',
    description: 'Generic system profile for phone reps',
    defaults: __PERMISSION_VERBS__.FORBIDDEN.optionValue,
    isSelectableInWizard: false,
  });
  IT_MANAGER = new DefaultProfile({
    display_name: 'IT manager',
    description: 'Generic system profile for IT managers',
    defaults: __PERMISSION_VERBS__.FORBIDDEN.optionValue,
    isSelectableInWizard: false,
  });
  EXTERNAL_USER = new DefaultProfile({
    display_name: 'External User',
    description: 'Generic system profile for external users',
    defaults: __PERMISSION_VERBS__.READ.optionValue,
    isSelectableInWizard: false,
  });

  constructor() {
    super();
    this._setKeysOnChildren();
  }
}

const __DEFAULT_PROFILES__ = new DEFAULT_PROFILES();

export { __DEFAULT_PROFILES__ };
