import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';

import Tooltip from '~/components/core/Atomic/Tooltip';
import useFetchExposures from '~/components/exposures/useFetchExposures';

import { isoDateToUs, serverDateTimeToLocal } from '../DateTimeUtils';
import { PAYMENT_MIXPANEL_SOURCE } from '../pocs/mixpanel';

import CancelTransactionActionContainer from './Finances/CancelTransactionActionContainer';
import { paymentTypeToPermissionAction } from './Finances/paymentsUtils';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import { PERMISSION_VERBS, RestrictedPermissions } from './core';
import { CurrencyFormatterContextProvider, useCurrencyFormatter } from './CurrencyFormatterContext';
import LoadingIndicator from './LoadingIndicator';
import PlainTable from './PlainTable';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

const AmountSpecialCell = ({ amount }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  return currencyFormatter.format(amount);
};

const GenericTransactionsSummary = (props) => {
  const {
    isError,
    isGeneralExpenses,
    isLoading,
    transactions,
    exposures, // exposure full structure to be used, not only display information
    title,
    isDialog,
    onClose,
    hideExposure,
    hideReserves,
    onCancelPayment,
  } = props;
  const { claim } = useClaim();
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();

  let columnData = [
    {
      id: 'date',
      numeric: false,
      label: 'Date',
      nowrap: true,
      specialCell: (row) => (row.is_external ? isoDateToUs(row.date) : serverDateTimeToLocal(row.date)),
    },
    { id: 'type', numeric: false, label: 'Transaction Type' },
    { id: 'method', numeric: false, label: 'Method', specialCell: (row) => row.payment_method || row.method },
    { id: 'exposure_label', numeric: false, label: 'Exposure' },
    { id: 'issued_by', numeric: false, label: 'Issued By', disableSort: true },
    {
      id: 'amount',
      numeric: true,
      label: 'Amount',
      specialCell: (row) =>
        row.request_currency && (row.request_amount_orig_currency || row.decision_amount_orig_currency) ? (
          <CurrencyFormatterContextProvider currency={row.request_currency}>
            <AmountSpecialCell amount={row.request_amount_orig_currency || row.decision_amount_orig_currency} />
          </CurrencyFormatterContextProvider>
        ) : (
          <AmountSpecialCell amount={row.amount} />
        ),
    },
    { id: 'payment_type_desc', numeric: false, label: 'Payment Type' },
    { id: 'subjects', numeric: false, label: 'Payees', disableSort: true },
    // eslint-disable-next-line react/display-name
    {
      id: 'sent_to',
      numeric: false,
      label: 'Sent To',
      disableSort: true,
      specialCell: (row) => (row.sent_to ? <pre>{row.sent_to}</pre> : ''),
    },
    {
      id: 'reference',
      numeric: false,
      label: 'Reference',
      disableSort: true,
      specialCell: (row) => row.reference || '',
    },
    { id: 'note', numeric: false, label: 'Note' },
    {
      id: 'total_indemnity_paid',
      numeric: true,
      label: 'Total Indemnity Paid',
      specialCell: (row) => currencyFormatter.format(row.total_indemnity_paid),
    },
    {
      id: 'total_expenses_paid',
      numeric: true,
      label: 'Total Expenses Paid',
      specialCell: (row) => currencyFormatter.format(row.total_expenses_paid),
    },
    {
      id: 'total_recoveries_paid',
      numeric: true,
      label: 'Total Recoveries Paid',
      specialCell: (row) => currencyFormatter.format(row.total_recoveries_paid),
    },
    {
      id: 'total_indemnity_reserve_outstanding',
      numeric: true,
      label: 'Total Indemnity Reserve Outstanding',
      specialCell: (row) => currencyFormatter.format(row.total_indemnity_reserve_outstanding),
    },
    {
      id: 'total_expenses_reserve_outstanding',
      numeric: true,
      label: 'Total Expenses Reserve Outstanding',
      specialCell: (row) => currencyFormatter.format(row.total_expenses_reserve_outstanding),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'actions',
      width: 25,
      disableSort: true,
      specialCell: (row) => {
        const exposure = row.exposure_id ? exposures.find((exposure) => exposure.id === row.exposure_id) : undefined; // In general expenses - exposure_id is 0

        if (row.is_cancel_pending) {
          return (
            <Tooltip title="Cancel Payment Is Pending">
              <WarningIcon className={classes.warningColor} style={{ paddingLeft: '5px' }} />
            </Tooltip>
          );
        }

        const isRecoveryTransaction = row.recovery_type && row.recovery_amount_id;

        const isPayment = row.request_type === 'payment_request';

        const paymentType =
          row.exposure_label === 'General Expenses' ? 'general_expenses' : row?.payable_type?.toLowerCase();
        const action = isPayment ? paymentTypeToPermissionAction(paymentType) : undefined;

        return (
          <RestrictedPermissions action={action} verb={PERMISSION_VERBS.WRITE}>
            <CancelTransactionActionContainer
              claimId={claim.id}
              isClaimClosed={claim.is_closed}
              isCancellable={row.is_cancellable}
              paymentRequestId={row.financial_request_id}
              exposureFinanceMetadata={exposure}
              paymentType={row.payable_type.toLowerCase()}
              mixpanelSource={!isRecoveryTransaction && PAYMENT_MIXPANEL_SOURCE.TRANSACTION_HISTORY}
              onCancelTransaction={onCancelPayment}
              recoveryType={row.recovery_type}
              recoveryAmountId={row.recovery_amount_id}
            />
          </RestrictedPermissions>
        );
      },
    },
  ];

  if (claim.type === 'gl_claim') {
    const noteIndex = columnData.findIndex((column) => column.id === 'note');
    columnData.splice(noteIndex, 0, { id: 'payment_method', numeric: false, label: 'Method', disableSort: true });
  } else if (claim.type === 'wc_claim') {
    columnData = columnData.filter(
      (column) => column.id !== 'total_expenses_paid' && column.id !== 'total_expenses_reserve_outstanding'
    );
    columnData.find((column) => column.id === 'total_indemnity_paid').label = 'Total Paid';
    columnData.find((column) => column.id === 'total_indemnity_reserve_outstanding').label =
      'Total Reserve Outstanding';
  }

  if (hideExposure) {
    columnData = columnData.filter((column) => column.id !== 'exposure_label');
  }

  if (hideReserves) {
    columnData = columnData.filter(
      (column) =>
        column.id !== 'total_indemnity_reserve_outstanding' && column.id !== 'total_expenses_reserve_outstanding'
    );
  }

  if (isGeneralExpenses) {
    columnData = columnData.filter((column) => !['total_indemnity_paid', 'total_recoveries_paid'].includes(column.id));
  }

  return (
    <CardDialog title={title} isDialog={isDialog} maxWidth={false} onClose={onClose}>
      {isLoading && !transactions ? (
        <LoadingIndicator isError={isError} />
      ) : (
        <>
          {isDialog && <div style={{ marginTop: '8px' }} />}
          <PlainTable
            rows={transactions.map((transaction) =>
              transaction.is_cancelled ? { ...transaction, styles: { textDecoration: 'line-through' } } : transaction
            )}
            columns={columnData}
            order="desc"
            stickyHeader
            maxHeight="75vh"
            autoPaginateRowsPerPage={15}
            keepRowsOrder
          />
        </>
      )}
    </CardDialog>
  );
};

GenericTransactionsSummary.propTypes = {
  title: PropTypes.string.isRequired,
  transactions: requiredIf(PropTypes.array, (props) => !(props.isLoading || props.isError)),
  exposures: PropTypes.required,
  isDialog: PropTypes.bool,
  isGeneralExpenses: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  onClose: PropTypes.func,
  hideExposure: PropTypes.bool,
  hideReserves: PropTypes.bool,
  onCancelPayment: PropTypes.func,
};

const ExposureGenericTransactionsSummaryContainer = (props) => {
  const { exposure, claim, onUpdate, isDialog, onClose } = props;

  const exposureName = exposure.isGeneralExpenses ? 'General Expenses' : exposure.coverage_type_desc;
  const transactionsUrl = exposure.isGeneralExpenses
    ? `/api/v1/claims/${claim.id}/general_expenses/transactions_history`
    : `/api/v1/claims/${claim.id}/exposures/${exposure.id}/transactions_history`;

  const { isLoading, isError, data: transactionsHistory, reloadData } = useDataFetcher(transactionsUrl);

  const handleCancelTransaction = async () => {
    await reloadData();
    await onUpdate();
  };

  return (
    <GenericTransactionsSummary
      isLoading={isLoading}
      isError={isError}
      transactions={transactionsHistory}
      exposures={[exposure]}
      title={`History for ${exposureName}`}
      isGeneralExpenses={exposure.isGeneralExpenses}
      isDialog={isDialog}
      onClose={onClose}
      hideExposure
      hideReserves
      onCancelPayment={handleCancelTransaction}
    />
  );
};

ExposureGenericTransactionsSummaryContainer.propTypes = {
  exposure: PropTypes.shape({
    id: PropTypes.number.isRequired,
    coverage_type: PropTypes.string.isRequired,
    coverage_type_desc: PropTypes.string.isRequired,
    isGeneralExpenses: PropTypes.bool,
  }).isRequired,
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isDialog: PropTypes.bool,
  onClose: PropTypes.func,
};

const ClaimGenericTransactionsSummaryContainer = (props) => {
  const { onClose, isDialog, onUpdate } = props;
  const { claim } = useClaim();

  const {
    isLoading: isLoadingTranscations,
    isError: isErrorTranscations,
    data: transactionsHistory,
    reloadData,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/transactions_history`);

  const { isExposuresLoading, isExposuresError, exposures } = useFetchExposures();

  const isLoading = isExposuresLoading || isLoadingTranscations;
  const isError = isExposuresError || isErrorTranscations;

  const handleCancelTransaction = async () => {
    await reloadData();
    await onUpdate();
  };

  return (
    <GenericTransactionsSummary
      isLoading={isLoading}
      isError={isError}
      onClose={onClose}
      title={"Claim's Transactions History"}
      transactions={transactionsHistory}
      exposures={exposures}
      isDialog={isDialog}
      onCancelPayment={handleCancelTransaction}
    />
  );
};

ClaimGenericTransactionsSummaryContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  isDialog: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
};

export { ClaimGenericTransactionsSummaryContainer, ExposureGenericTransactionsSummaryContainer };
