import React from 'react';

import { useTableauEmbed } from '~/components/ReportsPage/EmbedComponents/useTableauEmbed';
import { useTableauConfiguration } from '~/components/ReportsPage/Hooks/useTableauConfiguration';
import { useTableauEmbedRoutes } from '~/components/ReportsPage/Hooks/useTableauEmbedRoutes';
import type { TableauEmbedCreateProps } from '~/components/ReportsPage/types';

export const TableauEmbedCreator: React.FC<TableauEmbedCreateProps> = ({ token, options }) => {
  const { self_service_datasource } = useTableauConfiguration();
  const { getEmbedCreatorUrl } = useTableauEmbedRoutes();
  const src = getEmbedCreatorUrl({ datasourceName: self_service_datasource || '' });

  const ref = React.useRef(null);
  useTableauEmbed({ options, ref });

  return (
    <tableau-authoring-viz
      ref={ref}
      id="tableauAuthoringViz"
      src={src}
      token={token}
      hide-edit-in-desktop-button="true"
      show-share-options="false"
      suppress-default-edit-behavior="true"
      width={options.width}
      height={options.height}
    >
      <custom-parameter name=":embed" value="yes" />
      <custom-parameter name=":dataDetails" value="no" />
      <custom-parameter name=":customViews" value="no" />
      <custom-parameter name=":subscriptions" value="no" />
      <custom-parameter name=":alerts" value="no" />
      <custom-parameter name=":showShareOptions" value="false" />
    </tableau-authoring-viz>
  );
};
