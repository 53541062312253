import React from 'react';

import SvgIcon from '../core/SvgIcon';

import { iconProptypesBase } from './propTypes';

const ChevronUpIcon = ({ iconColor, ...props }) => (
  <SvgIcon width="24" height="24" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M3.75 12.5L10 6.25L16.25 12.5"
      stroke={iconColor}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

ChevronUpIcon.propTypes = iconProptypesBase;

export default ChevronUpIcon;
