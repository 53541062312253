const CONTACT_ADDRESS_CHANGE_REASONS = {
  typo: {
    desc: 'Correct error in address',
  },
  addition: {
    desc: 'Adding address details',
  },
  update: {
    desc: "Contact's address has changed",
  },
  other: {
    desc: 'Other',
  },
} as const;

export default CONTACT_ADDRESS_CHANGE_REASONS;
