import React from 'react';

import COUNTRY_TO_STATE_MAP from '~/server_shared/generated-types/COUNTRY_TO_STATE_MAP';

import { serverDateToLocal } from '../../../../DateTimeUtils';
import { MainCard, PropertyDetails } from '../../../core';
import { PropertyDetailsRow } from '../../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { LegalActionEditButton } from '../../components/LegalActionEditButton';
import { EditJurisdictionInformationDialog } from '../../Dialogs/EditJurisdictionInformation/EditJurisdictionInformationDialog';
import { JURISDICTION_INFORMATION_FIELDS } from '../../FormFragments/FormFieldsDefinitions';
import { useLegalAction } from '../../hooks/useLegalAction';

import { DisputeResolutionProfessionalsCard } from './DisputeResolutionProfessionals/DisputeResolutionProfessionalsCard';

export const JurisdictionCard: React.FC = () => {
  const { legalAction, reloadLegalAction } = useLegalAction();
  const [isEditJurisdictionDialogOpen, setIsEditJurisdictionDialogOpen] = React.useState(false);

  const stateName = (COUNTRY_TO_STATE_MAP.US as Record<string, string>)[legalAction.jurisdiction_state];

  return (
    <>
      <MainCard
        type="elevated"
        title="Jurisdiction Information"
        action={<LegalActionEditButton onClick={() => setIsEditJurisdictionDialogOpen(true)} />}
        collapsible
        openByDefault
      >
        <div className="flex w-1/2">
          <PropertyDetailsRow>
            <PropertyDetails title={JURISDICTION_INFORMATION_FIELDS.jurisdiction.label} text={stateName} />
            <PropertyDetails title={JURISDICTION_INFORMATION_FIELDS.venue.label} text={legalAction.venue} />
          </PropertyDetailsRow>
        </div>
        <div className="flex w-1/2">
          <PropertyDetailsRow>
            <PropertyDetails title={JURISDICTION_INFORMATION_FIELDS.court.label} text={legalAction.court} />
            <PropertyDetails
              title={JURISDICTION_INFORMATION_FIELDS.dateFilledInCourt.label}
              text={legalAction.date_filled_in_court ? serverDateToLocal(legalAction.date_filled_in_court) : ''}
            />
          </PropertyDetailsRow>
        </div>
        <DisputeResolutionProfessionalsCard />
      </MainCard>
      {isEditJurisdictionDialogOpen ? (
        <EditJurisdictionInformationDialog
          legalAction={legalAction}
          onClose={() => setIsEditJurisdictionDialogOpen(false)}
          onSubmitSuccess={reloadLegalAction}
        />
      ) : null}
    </>
  );
};
