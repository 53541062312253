import React from 'react';
import PropTypes from 'prop-types';
import MinimizeIcon from '@material-ui/icons/Minimize';
import { ArrowCollapse } from 'mdi-material-ui';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';

import CardDialog from '../../../CardDialog';

const ClaimQaFormDialog = ({ children, onClose, title, subtitle, onChangeCardSize }) => (
  <CardDialog
    title={title}
    subheader={subtitle}
    isDialog
    maxWidth="md"
    fullWidth
    onClose={onClose}
    action={
      <>
        <IconButton onClick={() => onChangeCardSize('minimized')} size="small">
          <MinimizeIcon />
        </IconButton>
        <IconButton onClick={() => onChangeCardSize('float')} size="small">
          <ArrowCollapse />
        </IconButton>
      </>
    }
  >
    {children}
  </CardDialog>
);

ClaimQaFormDialog.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.object.isRequired,
  onChangeCardSize: PropTypes.func.isRequired,
};

export default ClaimQaFormDialog;
