export const DETAILS_TAB_KEY = 'identity';

export const CONTACT_EXTRA_KEY = 'contact_extra';
export const SEX_OPTIONS = ['Male', 'Female'];

export const FORMIK_DETAILS_FIELD_IDS = {
  IS_COMPANY: 'is_company',

  FIRST_NAME: 'first_name',
  MIDDLE_NAME: 'middle_name',
  LAST_NAME: 'last_name',
  COMPANY_NAME: 'company_name',
  DATE_OF_BIRTH: 'date_of_birth',
  GOVERNMENT_ID: 'government_id',
  SEX: 'sex',
  IS_DECEASED: 'is_deceased',
  ROLE: 'role',
  ROLE_OTHER_DESC: 'role_other_desc',

  ATTORNEY_BAR_STATE: 'attorney_bar_state',
  ATTORNEY_BAR_NUMBER: 'attorney_bar_number',

  IS_VAT_REGISTERED: 'is_vat_registered',
  VAT_NUMBER: 'vat_number',

  IBAN_LANGUAGE: 'language',
  IBAN: 'iban',

  UK_BANK_SORT_CODE: 'uk_sort_code',
  UK_BANK_ACCOUNT_NUMBER: 'uk_bank_account_number',

  IS_MOI_SERVICE_PROVIDER: 'is_moi_service_provider',
  MOI_EXPERTISES: 'moi_expertises',

  EXPOSURE_IDS: 'exposure_ids',
  NOTE: 'note',

  REASON: 'reason',
  EXPLANATION: 'explanation',

  PROFESSION: `${CONTACT_EXTRA_KEY}.profession`,
};
