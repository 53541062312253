import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Edit from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { serverDateToLocal } from '../DateTimeUtils';
import { reportAxiosError, stringCmp } from '../Utils';

import { useCms } from './hooks/useCms';
import { Osha300EditDialog } from './WC/WCOsha';
import CardDialog from './CardDialog';
import CheckboxFormik from './CheckboxFormik';
import { SortableTable } from './core';
import InlineIconButton from './InlineIconButton';
import LoadingIndicator from './LoadingIndicator';
import useOrganization from './OrganizationContext';
import PlainTable from './PlainTable';
import TextFieldFormik, { DatePickerTextFieldFormik, TimePickerTextFieldFormik } from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

function OshaReports() {
  const classes = useStyles();
  const { user, setPageTitle } = useCms();
  const [isGeneratingForm, setIsGeneratingForm] = useState(false);
  const [generateDialogOpen, setGenerateDialogOpen] = useState(false);
  const [isQuerying, setIsQuerying] = useState(false);
  const [rows, setRows] = useState(null);
  const [reportQuery, setReportQuery] = useState({});
  const [showQueryClaimDialog, setShowQueryClaimDialog] = useState(false);
  const { insuredPropertiesAndLocationsDict } = useOrganization();

  const { isLoading, isError, data, reloadData } = useDataFetcher(
    `/api/v1/organizations/${user.organization_id}/osha_reports/issued`
  );

  useEffect(() => setPageTitle('Calendar', 'Calendar - Five Sigma CMS'), [setPageTitle]);

  function handleFormIdClicked(form_id) {
    window.open(`/api/v1/organizations/${user.organization_id}/osha_reports/${form_id}`, '_blank');
  }

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  async function handleGenerateForm(values) {
    try {
      setIsGeneratingForm(true);
      const res = await axios.post(`/api/v1/organizations/${user.organization_id}/osha_reports/generate`, values);
      await reloadData();
      handleFormIdClicked(res.data.report_id);
      setGenerateDialogOpen(false);
      setRows(null);
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsGeneratingForm(false);
    }
  }

  async function handleQuery(values) {
    try {
      setIsQuerying(true);
      const res = await axios.post(`/api/v1/organizations/${user.organization_id}/osha_reports/preview`, values);
      if (res.data) {
        setRows(res.data.rows);
      }
      setGenerateDialogOpen(false);
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsQuerying(false);
    }
  }

  function getReportType(row) {
    if (row.is_301_included && row.is_300A_included) {
      return '300, 300A and 301';
    } else if (row.is_301_included) {
      return '300 and 301';
    } else if (row.is_300A_included) {
      return '300 and 300A';
    } else {
      return 'only 300';
    }
  }

  const columnData = [
    {
      id: 'view_action',
      width: 25,
      disableSort: true,
      specialCell:
        // eslint-disable-next-line react/display-name
        (row) => (
          <InlineIconButton
            disabled={isGeneratingForm}
            icon={VisibilityIcon}
            onClick={() => handleFormIdClicked(row.id)}
          />
        ),
    },
    {
      id: 'is_300A_included',
      numeric: false,
      label: 'Report Type',
      width: '70px',
      specialCell: (row) => getReportType(row),
    },
    { id: 'created_by_username', numeric: false, label: 'Created By', width: '70px' },
    {
      id: 'creation_date',
      numeric: false,
      label: 'Issued At',
      width: '70px',
      specialCell: (row) => serverDateToLocal(row.creation_date),
      specialCmpFunc: (row1, row2) => stringCmp(row1.creation_date, row2.creation_date),
    },
    {
      id: 'from_date',
      numeric: false,
      label: 'Start of range',
      width: '70px',
      specialCell: (row) => serverDateToLocal(row.from_date),
      specialCmpFunc: (row1, row2) => stringCmp(row1.from_date, row2.from_date),
    },
    {
      id: 'to_date',
      numeric: false,
      label: 'End of range',
      width: '70px',
      specialCell: (row) => serverDateToLocal(row.to_date),
      specialCmpFunc: (row1, row2) => stringCmp(row1.to_date, row2.to_date),
    },
    {
      id: 'insured_property_id',
      numeric: false,
      label: 'Establishment',
      width: '70px',
      specialCell: (row) => insuredPropertiesAndLocationsDict[row.insured_property_id]?.desc,
    },
    {
      id: 'insured_property_osha_subproperty_id',
      numeric: false,
      label: 'Sub Establishment',
      width: '70px',
      specialCell: (row) =>
        insuredPropertiesAndLocationsDict[row.insured_property_id].subproperties?.[
          row.insured_property_osha_subproperty_id
        ]?.name,
    },
  ];

  return (
    <div className={classes.pageBody}>
      <CardDialog title="Osha Reports">
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.inLineButtonsContainer}>
              <div style={{ position: 'relative', margin: '10px 0px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isQuerying || isGeneratingForm}
                  onClick={() => setShowQueryClaimDialog(true)}
                >
                  Query claims to new report
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            {rows && (
              <OshaPreview
                onSubmitGenerateClaim={setGenerateDialogOpen}
                rows={rows}
                isQuerying={isQuerying}
                reloadRows={() => handleQuery(reportQuery)}
                onCancel={() => setRows(null)}
              />
            )}
          </Grid>
          {!rows && (
            <Grid item xs={12}>
              <CardDialog title="Previous osha reports" outlinedCard containerStyle={{ overflow: 'scroll' }}>
                <SortableTable
                  rows={data}
                  columns={columnData}
                  defaultOrderColumn={columnData.findIndex((column) => column.id === 'creation_date')}
                  order="desc"
                />
              </CardDialog>
            </Grid>
          )}
        </Grid>
      </CardDialog>
      {generateDialogOpen && (
        <GenerateOshaFormDialog
          onSubmit={handleGenerateForm}
          onCancel={() => setGenerateDialogOpen(false)}
          reportQuery={reportQuery}
        />
      )}
      {showQueryClaimDialog && (
        <QueryOshaPreview
          handlePreviewSubmit={async (values) => {
            setReportQuery(values);
            await handleQuery(values);
          }}
          onClose={() => setShowQueryClaimDialog(false)}
          onSubmit={() => setShowQueryClaimDialog(false)}
        />
      )}
    </div>
  );
}

function QueryOshaPreview({ handlePreviewSubmit, onClose, onSubmit }) {
  const { insuredPropertiesAndLocationsDict } = useOrganization();
  const insuredProperties = Object.keys(insuredPropertiesAndLocationsDict).map((id) => {
    const property = insuredPropertiesAndLocationsDict[id];
    property.property_id = id;
    return property;
  });
  const properties = insuredProperties.sort((a, b) => (a?.name > b?.name ? 1 : -1));

  return (
    <CardDialog title="Query claims report" isDialog onClose={onClose} containerStyle={{ overflow: 'scroll' }}>
      <Formik
        initialValues={{
          from: '',
          to: '',
          property_id: '',
          subproperty_id: '',
        }}
        validationSchema={Yup.object().shape({
          from: Yup.date().required('Required'),
          to: Yup.date().required('Required'),
          property_id: Yup.number().required('Required'),
        })}
        onSubmit={async (values, formikProps) => {
          try {
            await handlePreviewSubmit(values);
            onSubmit();
          } catch (err) {
            reportAxiosError(err);
          } finally {
            formikProps.setSubmitting(false);
          }
        }}
        onCancel={onClose}
      >
        <QueryOshaPreviewInner properties={properties} />
      </Formik>
    </CardDialog>
  );
}

QueryOshaPreview.propTypes = {
  handlePreviewSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function QueryOshaPreviewInner({ properties }) {
  const { insuredPropertiesAndLocationsDict } = useOrganization();
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values, setFieldValue } = useFormikContext();

  const property = values.property_id && insuredPropertiesAndLocationsDict[values.property_id];
  const shouldShowSubproperty = property && !!Object.keys(property.subproperties).length;

  useEffect(() => {
    setFieldValue('subproperty_id', '');
  }, [values.property_id, setFieldValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DatePickerTextFieldFormik
          id="from"
          label="Date Range Start"
          disableFuture
          className={classes.textField}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <DatePickerTextFieldFormik
          id="to"
          label="Date Range End"
          disableFuture
          className={classes.textField}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik id="property_id" label="Establishment" fullWidth className={classes.textField} select>
          {properties.map((property) => (
            <MenuItem key={property.property_id} value={property.property_id}>
              {property?.name}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      {shouldShowSubproperty && (
        <Grid item xs={6}>
          <TextFieldFormik id="subproperty_id" label="Sub Establishment" fullWidth className={classes.textField} select>
            {Object.keys(property.subproperties).map((key) => (
              <MenuItem key={key} value={key}>
                {property.subproperties[key].name}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
      )}
      <Grid item xs={12}>
        <div className={classes.buttonsContainer}>
          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

QueryOshaPreviewInner.propTypes = {
  properties: PropTypes.array.isRequired,
};

function OshaPreview({ onSubmitGenerateClaim, rows, isQuerying, reloadRows, onCancel }) {
  const classes = useStyles();
  const [rowToEdit, setRowToEdit] = useState(null);

  const columnData = [
    { id: 'case_number', numeric: false, label: 'Case No.', width: '70px' },
    { id: 'employee_name', numeric: false, label: "Employee's Name", width: '70px' },
    { id: 'job_title', numeric: false, label: 'Job Title', width: '70px' },
    { id: 'date_of_injury', numeric: false, label: 'Date of injury of onset of illness', width: '70px' },
    { id: 'event_location', numeric: false, label: 'Where the event occurred', width: '70px' },
    {
      id: 'injury_description',
      numeric: false,
      label:
        'Describe injury or illness, parts of body affected, and object/substance that directly injured or made person ill',
      width: '70px',
    },
    { id: 'death_case', numeric: false, label: 'Death', width: '70px' },
    { id: 'days_away_from_work_case', numeric: false, label: 'Days away from work', width: '70px' },
    { id: 'job_transfer_case', numeric: false, label: 'Job transfer or restriction', width: '70px' },
    { id: 'other_recordable_cases_case', numeric: false, label: 'Other recordable cases', width: '70px' },
    { id: 'days_away_from_work', numeric: true, label: 'Away from work (days)', width: '70px' },
    { id: 'days_on_job_transfer', numeric: true, label: 'On job transfer or restriction (days)', width: '70px' },
    { id: 'injury_type', numeric: false, label: 'Injury', width: '70px' },
    { id: 'skin_disorder_type', numeric: false, label: 'Skin disorder', width: '70px' },
    { id: 'respiratory_condition_type', numeric: false, label: 'Respiratory condition', width: '70px' },
    { id: 'poisoning_type', numeric: false, label: 'Poisoning', width: '70px' },
    { id: 'hearing_loss_type', numeric: false, label: 'Hearing loss', width: '70px' },
    { id: 'all_other_illnesses_type', numeric: false, label: 'All other illnesses', width: '70px' },
    {
      id: 'edit_action',
      width: 25,
      disableSort: true,
      specialCell:
        // eslint-disable-next-line react/display-name
        (row) => <InlineIconButton disabled={isQuerying} icon={Edit} onClick={() => setRowToEdit(row)} />,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.leftButtonsContainer}>
          <div style={{ position: 'relative' }}>
            <CancelButton disabled={!rows || isQuerying} onClick={onCancel} content="Clear" />
            <Button
              variant="contained"
              color="primary"
              disabled={!rows || isQuerying}
              onClick={() => onSubmitGenerateClaim(true)}
            >
              Generate report
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div style={{ margin: '10px 0px' }}>
          <CardDialog title="Osha 300 rows" fullWidth maxWidth="xl" outlinedCard>
            <PlainTable rows={rows} columns={columnData} />
            {rowToEdit && (
              <Osha300EditContainer
                rowToEdit={rowToEdit}
                onCancel={() => setRowToEdit(null)}
                onSubmit={async () => {
                  await reloadRows();
                  setRowToEdit(null);
                }}
              />
            )}
          </CardDialog>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.leftButtonsContainer}>
          <div style={{ position: 'relative' }}>
            <CancelButton disabled={!rows || isQuerying} onClick={onCancel} content="Clear" />
            <Button
              variant="contained"
              color="primary"
              disabled={!rows || isQuerying}
              onClick={() => onSubmitGenerateClaim(true)}
            >
              Generate report
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

OshaPreview.propTypes = {
  rows: PropTypes.array,
  isQuerying: PropTypes.bool.isRequired,
  onSubmitGenerateClaim: PropTypes.func.isRequired,
  reloadRows: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const getSelectedKey = (values, sliceKey) => {
  const selected = Object.keys(values).find((currKey) => values[currKey] === 'x');
  return selected ? sliceKey(selected) : '';
};

function Osha300EditContainer({ rowToEdit, onCancel, onSubmit }) {
  const {
    case_number,
    employee_name,
    job_title,
    date_of_injury,
    event_location,
    injury_description,
    incident_description,
    death_case,
    days_away_from_work_case,
    job_transfer_case,
    other_recordable_cases_case,
    days_away_from_work,
    days_on_job_transfer,
    is_privacy_case,
    is_recordable,
    injury_type,
    skin_disorder_type,
    respiratory_condition_type,
    poisoning_type,
    hearing_loss_type,
    all_other_illnesses_type,
    insured_property_osha_subproperty_id,
    light_duty_days_away_from_work,
    light_duty_days_on_job_transfer,
  } = rowToEdit;
  const cases = { death_case, days_away_from_work_case, job_transfer_case, other_recordable_cases_case };
  const types = {
    injury_type,
    skin_disorder_type,
    respiratory_condition_type,
    poisoning_type,
    hearing_loss_type,
    all_other_illnesses_type,
  };
  return (
    <Osha300EditDialog
      onSubmit={onSubmit}
      oshaDetails={{
        caseNumber: case_number,
        employeeName: employee_name,
        jobTitle: job_title,
        dateOfInjury: date_of_injury,
        location: event_location,
        injuryDescription: injury_description || incident_description,
        caseClassification: getSelectedKey(cases, (key) => key.slice(0, -5)),
        daysAwayFromWork: days_away_from_work || 0,
        daysOnJobTransfer: days_on_job_transfer || 0,
        isPrivacyCase: !!is_privacy_case,
        isNotRecordable: !(is_recordable === null || is_recordable),
        injuryType: getSelectedKey(types, (key) => key.slice(0, -5)),
        subpropertyId: insured_property_osha_subproperty_id || '',
        daysJobTransferShowOnly: light_duty_days_on_job_transfer > 0,
        daysAwayFromWorkShowOnly: light_duty_days_away_from_work > 0,
      }}
      onCancel={onCancel}
      claimId={rowToEdit.id}
      propertyId={rowToEdit.insured_property_id}
    />
  );
}

Osha300EditContainer.propTypes = {
  rowToEdit: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function GenerateOshaFormDialog({ onSubmit, onCancel, reportQuery }) {
  const { insuredPropertiesAndLocationsDict } = useOrganization();
  const classes = useStyles();
  const property = reportQuery.property_id && insuredPropertiesAndLocationsDict[reportQuery.property_id];
  const shouldShowSubproperty = property && !!Object.keys(property.subproperties).length;

  return (
    <CardDialog title="Generate Report" isDialog onClose={onCancel}>
      <Formik
        initialValues={{
          with_300A: false,
          with_301: false,
          from: reportQuery.from,
          to: reportQuery.to,
          property_id: reportQuery.property_id,
          subproperty_id: reportQuery.subproperty_id,
          city: '',
          state: '',
          street: '',
          zip: '',
          industry_description: '',
          annual_avg_employees: '',
          hours_worked: '',
          company_executive: '',
          sign_here_date: '',
          sign_here_time: '',
          title: '',
          phone: '',
          NAICS: '',
          SIC: '',
        }}
        validationSchema={Yup.object().shape({
          with_300A: Yup.bool(),
          with_301: Yup.bool(),
          from: Yup.date().required('Required'),
          to: Yup.date().required('Required'),
          property_id: Yup.number().required('Required'),
          city: Yup.string(),
          state: Yup.string(),
          street: Yup.string(),
          zip: Yup.string(),
          industry_description: Yup.string(),
          annual_avg_employees: Yup.number(),
          hours_worked: Yup.number(),
          company_executive: Yup.string(),
          sign_here_date: Yup.date(),
          sign_here_time: Yup.string().matches(
            /^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/,
            'Invalid time supplied'
          ),
          phone: Yup.string(),
          title: Yup.string(),
          NAICS: Yup.string().length(6, 'NAICS should be 6 digits'),
          SIC: Yup.string().length(4, 'SIC should be 4 digits'),
        })}
        onSubmit={onSubmit}
        onCancel={onCancel}
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit, values } = formikProps;
          return (
            <Grid container>
              <Grid item xs={12}>
                <DatePickerTextFieldFormik
                  id="from"
                  label="Date Range Start"
                  disableFuture
                  className={classes.textField}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <DatePickerTextFieldFormik
                  id="to"
                  label="Date Range End"
                  disableFuture
                  className={classes.textField}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="property_id"
                  label="Establishment"
                  fullWidth
                  className={classes.textField}
                  select
                  disabled
                >
                  {Object.keys(insuredPropertiesAndLocationsDict).map((id) => (
                    <MenuItem key={id} value={id}>
                      {insuredPropertiesAndLocationsDict[id]?.name}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              {shouldShowSubproperty && (
                <Grid item xs={12}>
                  <TextFieldFormik
                    id="subproperty_id"
                    label="Sub Establishment"
                    fullWidth
                    className={classes.textField}
                    select
                    disabled
                  >
                    {Object.keys(property.subproperties).map((key) => (
                      <MenuItem key={key} value={key}>
                        {property.subproperties[key].name}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextFieldFormik id="city" label="Establishment city" fullWidth className={classes.textField} />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik id="state" label="Establishment state" fullWidth className={classes.textField} />
              </Grid>
              <Grid item xs={12}>
                <CheckboxFormik id="with_300A" label="Generate 300A as well?" />
              </Grid>
              {values.with_300A && (
                <>
                  <Grid item xs={12}>
                    <TextFieldFormik id="street" label="Street" fullWidth className={classes.textField} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik id="zip" label="Zip" fullWidth className={classes.textField} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="industry_description"
                      label="Industry description (e.g., Manufacture of motor truck trailers)"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="SIC"
                      label="Standard Industrial Classification (SIC), if known (e.g., SIC 3715)"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="NAICS"
                      label="North American Industrial Classification (NAICS), if known (e.g., 336212)"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="annual_avg_employees"
                      label="Annual average number of employees"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="hours_worked"
                      label="Total hours worked by all employees last year"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="company_executive"
                      label="Company executive"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik id="title" label="Title" fullWidth className={classes.textField} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik id="phone" label="Phone" fullWidth className={classes.textField} />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePickerTextFieldFormik
                      id="sign_here_date"
                      label="Date"
                      className={classes.textField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TimePickerTextFieldFormik
                      id="sign_here_time"
                      label="Time"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <CheckboxFormik id="with_301" label="Generate 301 as well?" />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.buttonsContainer}>
                  <CancelButton disabled={isSubmitting} onClick={onCancel} />
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Generate Report
                  </Button>
                </div>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </CardDialog>
  );
}

GenerateOshaFormDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  reportQuery: PropTypes.object.isRequired,
};

export default OshaReports;
