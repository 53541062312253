import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import _ from 'lodash';
import { PlaylistEdit as PlaylistEditIcon } from 'mdi-material-ui';
import moment from 'moment';

import Button from '~/components/core/Atomic/Buttons/Button';

import { serverDateToLocal } from '../../../DateTimeUtils';
import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import ClaimLink from '../../ClaimLink';
import WithConfirm, { ConfirmModal } from '../../ConfirmModal';
import { MultiSelectFilter, SortableTable } from '../../core';
import MedicalBillAdjudicationDialog from '../../exposures/MedicalBillAdjudicationContainer';
import { useCms } from '../../hooks/useCms';
import InlineIconButton from '../../InlineIconButton';
import LoadingIndicator from '../../LoadingIndicator';
import useDataFetcher from '../../useDataFetcher';

import { useStyles } from '../../../assets/styles';

const ALL_OPTION = 'All';

function MgmMedicalBillsPage() {
  const classes = useStyles();
  const { setPageTitle, user } = useCms();
  const {
    isLoading,
    isError,
    data: pendingMedicalBills,
    reloadData: reloadPendingMedicalBills,
  } = useDataFetcher('/api/v1/wc_claims/medical_bill_adjudication/pending');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [medicalBillToShow, setMedicalBillToShow] = useState(undefined);
  const [filteredMedicalBills, setFilteredMedicalBills] = useState([]);
  const [selectedInjuredEmployees, setSelectedInjuredEmployees] = useState([]);
  const [injuredEmployeesOptions, setInjuredEmployeesOptions] = useState([ALL_OPTION]);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [providerOptions, setProviderOptions] = useState([ALL_OPTION]);
  const [isMergingBills, setIsMergingBills] = useState(false);
  const [selectedMedicalTreatmentsIds, setSelectedMedicalTreatmentsIds] = useState([]);
  const [selectedMedicalBillsClaimAndProviderId, setSelectedMedicalBillsClaimAndProviderId] = useState({});
  const [touchedBill, setTouchedBill] = useState(undefined);

  useEffect(() => setPageTitle('Medical Bills', 'Medical Bills - Five Sigma CMS'), [setPageTitle]);

  const isMedicalBillMatchFilter = (filteredField, medicalBill, selectedValuesForFilter) =>
    selectedValuesForFilter.length === 0 || selectedValuesForFilter.includes(medicalBill[filteredField]);

  async function handleDeleteDraft(medicalBill) {
    try {
      setIsSubmitting(true);
      await axios.delete(
        `/api/v1/wc_claims/${medicalBill.claim_id}/medicals/${medicalBill.id}/medical_bill_adjudicate/draft`
      );
      await reloadPendingMedicalBills();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  const handleTouchMedicalBill = async (medicalBill) => {
    try {
      await axios.post(
        `/api/v1/wc_claims/${medicalBill.claim_id}/medicals/${medicalBill.id}/medical_bill_adjudicate/touch`
      );
      await reloadPendingMedicalBills();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  useEffect(() => {
    if (pendingMedicalBills) {
      setInjuredEmployeesOptions(_.uniq(pendingMedicalBills.map((medicalBill) => medicalBill.injured_employee_name)));
      setProviderOptions(_.uniq(pendingMedicalBills.map((medicalBill) => medicalBill.provider_name)));
      setFilteredMedicalBills(
        pendingMedicalBills.filter((medicalBill) => {
          const doesMatchInjuredEmployee = isMedicalBillMatchFilter(
            'injured_employee_name',
            medicalBill,
            selectedInjuredEmployees
          );
          const doesMatchProvider = isMedicalBillMatchFilter('provider_name', medicalBill, selectedProvider);
          return doesMatchInjuredEmployee && doesMatchProvider;
        })
      );
    }
  }, [pendingMedicalBills, selectedInjuredEmployees, selectedProvider]);

  useEffect(() => {
    if (!selectedMedicalTreatmentsIds.length) {
      setSelectedMedicalBillsClaimAndProviderId({});
    } else if (selectedMedicalTreatmentsIds.length === 1) {
      const selectedMedicalTreatment = filteredMedicalBills.find(({ id }) => id === selectedMedicalTreatmentsIds[0]);
      setSelectedMedicalBillsClaimAndProviderId({
        claimId: selectedMedicalTreatment.claim_id,
        providerName: selectedMedicalTreatment.provider_name,
      });
    }
  }, [selectedMedicalTreatmentsIds, filteredMedicalBills]);

  const shouldConfirmMedicalBillEdit = (pendingMedicalBill) =>
    pendingMedicalBill.touched_by_user_id && pendingMedicalBill.touched_by_user_id !== user.id;
  const overrideTouchedAndOpenMedicalBill = async (pendingMedicalBill) => {
    await handleTouchMedicalBill(pendingMedicalBill);
    setMedicalBillToShow(pendingMedicalBill);
    setTouchedBill(undefined);
  };

  const handleEditClick = async (pendingMedicalBill) => {
    // TODO: Get only one medical bill
    const { data } = await axios.get('/api/v1/wc_claims/medical_bill_adjudication/pending');

    const updatedMedicalBill = data.find(({ id }) => id === pendingMedicalBill.id);

    if (shouldConfirmMedicalBillEdit(updatedMedicalBill)) {
      setTouchedBill(updatedMedicalBill);
      return;
    }

    await overrideTouchedAndOpenMedicalBill(pendingMedicalBill);
  };

  const columnData = [
    {
      id: 'date_received',
      label: 'Date received',
      width: '150px',
      specialCmpFunc: (d1, d2) =>
        (d1 && d2 && moment.utc(d1.date_received).isAfter(moment.utc(d2.date_received))) || (!d1 && d2) ? -1 : 1,
      specialCell: (row) => (row.date_received ? serverDateToLocal(row.date_received) : ''),
    },
    { id: 'days_in_queue', label: 'Number of days since received', width: '230px', numeric: true },
    // eslint-disable-next-line react/display-name
    {
      id: 'claim_id_display',
      label: 'Claim',
      specialCell: (row) => <ClaimLink claimId={row.claim_id} linkText={row.claim_id_display} />,
      width: '70px',
    },
    { id: 'document_name', label: 'Name' },
    { id: 'injured_employee_name', label: 'Injured Employee', width: '250px' },
    { id: 'provider_name', label: 'Provider', width: '250px' },
    {
      id: 'view_action',
      width: 25,
      disableSort: true,
      specialCell:
        // eslint-disable-next-line react/display-name
        (pendingMedicalBill) => (
          <div style={{ display: 'flex' }}>
            {pendingMedicalBill.draft && (
              <WithConfirm
                key={pendingMedicalBill.id}
                title="Are you sure?"
                contentText="This will delete the draft"
                primaryButtonName="Discard"
                shouldCloseOnPrimary={true}
              >
                <InlineIconButton
                  tooltipTitle="discard draft"
                  disabled={isSubmitting}
                  icon={DeleteIcon}
                  onClick={() => handleDeleteDraft(pendingMedicalBill)}
                />
              </WithConfirm>
            )}
            <InlineIconButton
              disabled={isSubmitting}
              icon={pendingMedicalBill.draft ? PlaylistEditIcon : VisibilityIcon}
              onClick={async () => {
                await handleEditClick(pendingMedicalBill);
              }}
            />
          </div>
        ),
    },
  ];

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  function documentTypeToService(documentType) {
    return {
      medical_bill_record_pt: 'PT',
      medical_bill_record_surgery: 'Surgery',
      medical_bill_record_concentra: 'Concentra',
      medical_bill_record_other: 'Other',
    }[documentType];
  }

  async function handleMergeMedicalBills() {
    if (!selectedMedicalTreatmentsIds.length || !selectedMedicalBillsClaimAndProviderId.claimId) {
      return;
    }
    try {
      setIsMergingBills(true);
      const { data: mergedBillId } = await axios.post(
        `/api/v1/wc_claims/${selectedMedicalBillsClaimAndProviderId.claimId}/merge_medical_bills`,
        { medical_bills_ids: selectedMedicalTreatmentsIds }
      );
      await reloadPendingMedicalBills();
      const mergedBill = pendingMedicalBills.find((pendingMedicalBill) => `${pendingMedicalBill.id}`, mergedBillId);
      setMedicalBillToShow(mergedBill);
      setSelectedMedicalTreatmentsIds([]);
      setSelectedMedicalBillsClaimAndProviderId({});
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsMergingBills(false);
    }
  }

  let orderByColumnIdx = columnData.findIndex((column) => column.id === 'date_received');

  const shouldDisableCheckingBill = (ptBill) => {
    if (
      (selectedMedicalBillsClaimAndProviderId.claimId &&
        selectedMedicalBillsClaimAndProviderId.claimId !== ptBill.claim_id) ||
      (selectedMedicalBillsClaimAndProviderId.providerName &&
        selectedMedicalBillsClaimAndProviderId.providerName !== ptBill.provider_name) ||
      ptBill.draft
    ) {
      return true;
    }
    return false;
  };

  async function handleSaveDraft(draftValues, medicalBill) {
    try {
      setIsSubmitting(true);
      await axios.post(
        `/api/v1/wc_claims/${medicalBill.claim_id}/medicals/${medicalBill.id}/medical_bill_adjudicate/draft`,
        draftValues
      );
      await reloadPendingMedicalBills();
      setMedicalBillToShow(undefined);
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  const adjudicateColumn = {
    id: 'adjudicate',
    width: 25,
    disableSort: true,
    // eslint-disable-next-line react/display-name
    specialCell: (pendingPTBill) => (
      <Checkbox
        checked={selectedMedicalTreatmentsIds.findIndex((id) => id === pendingPTBill.id) > -1}
        disabled={shouldDisableCheckingBill(pendingPTBill)}
        onChange={(e, wasAdded) => {
          const newSelectedIds = wasAdded
            ? [...selectedMedicalTreatmentsIds, pendingPTBill.id]
            : selectedMedicalTreatmentsIds.filter((id) => id !== pendingPTBill.id);
          setSelectedMedicalTreatmentsIds(newSelectedIds);
        }}
      />
    ),
  };

  return (
    <div className={classes.pageBody}>
      <CardDialog title="Medical Bills">
        <div style={{ display: 'flex' }}>
          <MultiSelectFilter
            label="Injured Employee"
            value={selectedInjuredEmployees}
            onChange={setSelectedInjuredEmployees}
            options={injuredEmployeesOptions}
            allOptionsLabel={ALL_OPTION}
            enableAllOptionsSelection
          />
          <MultiSelectFilter
            label="Provider"
            value={selectedProvider}
            onChange={setSelectedProvider}
            options={providerOptions}
            allOptionsLabel={ALL_OPTION}
            enableAllOptionsSelection
          />
        </div>
        {['PT', 'Surgery', 'Concentra', 'Other'].map((medicalBillType) => (
          <div key={medicalBillType} className={classes.cardDivRow}>
            <CardDialog title={medicalBillType}>
              {medicalBillType === 'PT' && (
                <div className={classes.inLineButtonsContainer}>
                  <div style={{ position: 'relative' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isMergingBills || selectedMedicalTreatmentsIds.length < 2}
                      onClick={handleMergeMedicalBills}
                    >
                      Adjudicate all selected
                    </Button>
                    {isMergingBills && <LoadingIndicator size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              )}
              <SortableTable
                columns={medicalBillType !== 'PT' ? columnData : [...columnData, adjudicateColumn]}
                rows={filteredMedicalBills.filter(
                  (x) => x.document_type === `medical_bill_record_${medicalBillType.toLowerCase()}`
                )}
                defaultOrderColumn={orderByColumnIdx}
                order="desc"
              />
            </CardDialog>
          </div>
        ))}
      </CardDialog>
      {medicalBillToShow && (
        <MedicalBillAdjudicationDialog
          title=""
          claimId={medicalBillToShow.claim_id}
          medicalBillPdfUrl={`/api/v1/claims/${medicalBillToShow.claim_id}/documents/${medicalBillToShow.document_id}`}
          onSubmit={async (vals) => {
            try {
              setIsSubmitting(true);
              await axios.post(
                `/api/v1/wc_claims/${medicalBillToShow.claim_id}/medicals/${medicalBillToShow.id}/medical_bill_adjudicate`,
                {
                  ...vals,
                  service: documentTypeToService(medicalBillToShow.document_type),
                  record_document_id: medicalBillToShow.document_id,
                }
              );
              await reloadPendingMedicalBills();
              setMedicalBillToShow(undefined);
            } catch (error) {
              reportAxiosError(error);
            }
            setIsSubmitting(false);
          }}
          onClose={() => setMedicalBillToShow(undefined)}
          onSaveDraft={(draftValues) => handleSaveDraft(draftValues, medicalBillToShow)}
          draftValues={medicalBillToShow.draft}
        />
      )}
      {touchedBill && (
        <ConfirmModal
          isOpen={!!touchedBill}
          title={`${touchedBill.touched_by} has already started handling this Medical Bill. Are you sure you want to override it?`}
          contentText={`make sure ${touchedBill.touched_by} is no longer handling this bill`}
          primaryButtonName="Discard"
          onClose={() => {
            setTouchedBill(undefined);
          }}
          onPrimaryBtnClick={async () => await overrideTouchedAndOpenMedicalBill(touchedBill)}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
}

export default MgmMedicalBillsPage;
