import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const BoldIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M15.9825 10.8469C16.5749 10.2766 16.9834 9.54234 17.1555 8.73828C17.3275 7.93421 17.2554 7.09707 16.9483 6.3343C16.6412 5.57154 16.1131 4.91797 15.4318 4.45749C14.7506 3.99701 13.9473 3.75064 13.125 3.75H6.75C6.55109 3.75 6.36032 3.82902 6.21967 3.96967C6.07902 4.11032 6 4.30109 6 4.5V18.75C6 18.9489 6.07902 19.1397 6.21967 19.2803C6.36032 19.421 6.55109 19.5 6.75 19.5H14.25C15.2901 19.5 16.2981 19.1397 17.1026 18.4803C17.907 17.821 18.4582 16.9033 18.6624 15.8834C18.8666 14.8636 18.7112 13.8044 18.2226 12.8862C17.734 11.968 16.9425 11.2473 15.9825 10.8469ZM7.5 5.25H13.125C13.8212 5.25 14.4889 5.52656 14.9812 6.01884C15.4734 6.51113 15.75 7.17881 15.75 7.875C15.75 8.57119 15.4734 9.23887 14.9812 9.73116C14.4889 10.2234 13.8212 10.5 13.125 10.5H7.5V5.25ZM14.25 18H7.5V12H14.25C15.0456 12 15.8087 12.3161 16.3713 12.8787C16.9339 13.4413 17.25 14.2044 17.25 15C17.25 15.7956 16.9339 16.5587 16.3713 17.1213C15.8087 17.6839 15.0456 18 14.25 18Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default BoldIcon;
