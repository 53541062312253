import React, { forwardRef } from 'react';

export interface VideoPlayerProps {
  src: string;
  preload?: HTMLVideoElement['preload'];
  className?: string;
  width?: number;
  height?: number;
  autoPlay?: HTMLVideoElement['autoplay'];
  loop?: HTMLVideoElement['loop'];
  muted?: HTMLVideoElement['muted'];
  controls?: HTMLVideoElement['controls'];
}

export type Ref = HTMLVideoElement;

const VideoPlayer = forwardRef<Ref, VideoPlayerProps>(
  ({ src, preload = 'metadata', className, width, height, controls, autoPlay, loop, muted }, ref) => {
    return (
      <video
        ref={ref}
        controls={controls}
        src={src}
        preload={preload}
        className={className}
        width={width}
        height={height}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
      />
    );
  }
);

VideoPlayer.displayName = 'VideoPlayer';
export default VideoPlayer;
