import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { PROS_AND_CONS_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { ProsAndConsFormValues } from '../../FormFragments/FormValuesTypes';
import { ProsAndConsForm } from '../../FormFragments/ProsAndConsForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { ProsAndConsApiResponse } from '../../types';
import type { DialogPropsWithLog } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const EditProsAndConsDialog: React.FC<DialogPropsWithLog<ProsAndConsApiResponse>> = ({
  onClose,
  onSubmitSuccess,
  legalAction,
  log,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: ProsAndConsFormValues) => {
    try {
      await axios.put(backendRoutes.legalAction.logs.pro_and_con(log.id), values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<ProsAndConsFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: pick(log, ['pro_con_type', 'rationale']),
        validationSchema: Yup.object().shape(PROS_AND_CONS_VALIDATION_SCHEMA),
      }}
      title="Edit Pros and Cons"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <ProsAndConsForm />
      </div>
    </FormDialog>
  );
};
