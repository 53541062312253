import type { ReactElement } from 'react';
import React from 'react';

interface TextWithIconProps {
  text: string;
  icon: ReactElement;
  position: 'start' | 'end';
}
export const TextWithIcon: React.FC<TextWithIconProps> = ({ text, icon, position }) => (
  <span className="flex items-center">
    {position === 'start' && icon}
    {text}
    {position === 'end' && icon}
  </span>
);
