import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DialogFooterActions from '~/components/core/DialogFooterActions';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import cn from '~/Utils/cn';

import { reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import WithConfirm from '../../../../ConfirmModal';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '../../../../core';
import { InvolvedPersonIcon, PencilIcon, TrashIcon_Deprecated } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';
import { InvolvedPartiesInnerCard } from '../InvolvedPartiesInnerCard';

import { getInvolvedPersonValidationFields, involvedPersonFields } from './InvolvedPersonFragment';

import { useStyles } from '../../../../../assets/styles';

const InvolvedPersonDialog = ({
  dialogAction,
  disableEditIdentity,
  involvedPerson,
  onCancel,
  onSubmit,
  open,
  showOnly,
  title,
  InvolvedPersonFragmentOverride,
  involvedPersonValidationFields = undefined,
}) => {
  const { incidentConfiguration } = useIncidentConfiguration();

  if (!open) {
    return <></>;
  }

  const personInitialValues = involvedPerson ? involvedPerson : { ...involvedPersonFields };
  const personValidationFields = involvedPersonValidationFields
    ? involvedPersonValidationFields
    : getInvolvedPersonValidationFields(incidentConfiguration);

  return (
    <Formik
      initialValues={personInitialValues}
      validationSchema={Yup.object().shape({ ...personValidationFields })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (error) {
          reportAxiosError(error);
        }

        setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title={title}
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
            action={dialogAction}
            footerActions={
              <DialogFooterActions
                disabled={isSubmitting}
                onClickPrimary={showOnly ? onCancel : handleSubmit}
                onClickSecondary={onCancel}
                primaryLabel={showOnly ? 'Close' : 'Save'}
                showSecondary={!showOnly}
              />
            }
          >
            <InvolvedPersonFragmentOverride
              disableEditIdentity={disableEditIdentity}
              showOnly={showOnly}
              {...formikProps}
            />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

InvolvedPersonDialog.propTypes = {
  dialogAction: PropTypes.node,
  disableEditIdentity: PropTypes.bool,
  involvedPerson: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
  InvolvedPersonFragmentOverride: PropTypes.func,
  involvedPersonValidationFields: PropTypes.object,
};

const EditInvolvedPersonDialog = ({
  open,
  involvedPerson,
  onCancel,
  onSaveInvolvedDetails,
  propertyLabel,
  InvolvedPersonFragmentOverride = undefined,
  involvedPersonValidationFields = undefined,
}) => {
  return (
    <InvolvedPersonDialog
      disableEditIdentity={involvedPerson.is_locked}
      involvedPerson={involvedPerson}
      onCancel={onCancel}
      onSubmit={onSaveInvolvedDetails}
      open={open}
      title={`Edit ${propertyLabel}`}
      InvolvedPersonFragmentOverride={InvolvedPersonFragmentOverride}
      involvedPersonValidationFields={involvedPersonValidationFields}
    />
  );
};

EditInvolvedPersonDialog.propTypes = {
  open: PropTypes.bool,
  involvedPerson: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveInvolvedDetails: PropTypes.func.isRequired,
  onDeleteInvolvedPerson: PropTypes.func,
  propertyLabel: PropTypes.string.isRequired,
  InvolvedPersonFragmentOverride: PropTypes.func,
  involvedPersonValidationFields: PropTypes.object,
};

const ShowOnlyInvolvedPersonDialog = ({
  open,
  involvedPerson,
  onCancel,
  propertyLabel,
  InvolvedPersonFragmentOverride = undefined,
}) => {
  return (
    <InvolvedPersonDialog
      disableEditIdentity={involvedPerson?.is_locked}
      involvedPerson={involvedPerson}
      onCancel={onCancel}
      open={open}
      title={`${propertyLabel} Details`}
      InvolvedPersonFragmentOverride={InvolvedPersonFragmentOverride}
      showOnly
    />
  );
};

ShowOnlyInvolvedPersonDialog.propTypes = {
  open: PropTypes.bool,
  involvedPerson: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  propertyLabel: PropTypes.string.isRequired,
  InvolvedPersonFragmentOverride: PropTypes.func,
};

const InvolvedPersonSummary = ({
  onSaveInvolvedDetails,
  involvedPerson,
  propertyLabel,
  InvolvedPersonFragmentOverride = undefined,
  involvedPersonValidationFields = undefined,
}) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <InlineIconButton
        icon={PencilIcon}
        tooltipTitle="Edit"
        className={cn(classes.inLineButtonsContainer, classes.buttonsContainer)}
        onClick={() => {
          setEditDialogOpen(true);
        }}
        wrapWithSpan
      />
      <EditInvolvedPersonDialog
        open={editDialogOpen}
        involvedPerson={involvedPerson}
        propertyLabel={propertyLabel}
        onSaveInvolvedDetails={async (involvedPerson) => {
          await onSaveInvolvedDetails(involvedPerson);
          setEditDialogOpen(false);
        }}
        onCancel={() => setEditDialogOpen(false)}
        InvolvedPersonFragmentOverride={InvolvedPersonFragmentOverride}
        involvedPersonValidationFields={involvedPersonValidationFields}
      />
    </>
  );
};

InvolvedPersonSummary.propTypes = {
  involvedPerson: PropTypes.object.isRequired,
  propertyLabel: PropTypes.string.isRequired,
  onSaveInvolvedDetails: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  InvolvedPersonFragmentOverride: PropTypes.func,
  involvedPersonValidationFields: PropTypes.object,
};

const InvolvedPersonParty = ({
  title,
  involvedPersonParty,
  onSaveInvolvedPersonDetails,
  onDeleteInvolvedPerson,
  propertyLabel,
  InvolvedPersonFragmentOverride = undefined,
  involvedPersonValidationFields = undefined,
}) => {
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const classes = useStyles();

  const action = onDeleteInvolvedPerson && (
    <WithConfirm title="Delete Person?" primaryButtonName="Delete" shouldCloseOnPrimary>
      <InlineIconButton
        icon={TrashIcon_Deprecated}
        tooltipTitle="Delete"
        className={cn(classes.marginedIcon, classes.hoverableNonFilledIcon)}
        onClick={onDeleteInvolvedPerson}
        wrapWithSpan
      />
    </WithConfirm>
  );

  const isInvolvedPersonExist = involvedPersonParty.contact_full_name;
  const userHasContactWritePermission = useHasPermission({
    action: PERMISSION_ACTIONS.CONTACT,
    verb: PERMISSION_VERBS.WRITE,
  });

  const dataToShow = [
    {
      field: 'Injured Person',
      value: isInvolvedPersonExist && involvedPersonParty.contact_full_name,
    },
  ];

  return (
    <>
      <InvolvedPartiesInnerCard
        title={title}
        action={action}
        Icon={InvolvedPersonIcon}
        iconStyle={{ paddingTop: '5px' }}
        dataToShow={dataToShow}
        onSetParty={() => setAddDialogOpen(true)}
        propertyLabel={propertyLabel}
        isSetMode={!isInvolvedPersonExist && userHasContactWritePermission}
      >
        {isInvolvedPersonExist && (
          <InvolvedPersonSummary
            involvedPerson={involvedPersonParty}
            propertyLabel={propertyLabel}
            onSaveInvolvedDetails={async (newInvolvedPerson) => {
              await onSaveInvolvedPersonDetails(newInvolvedPerson);
              setAddDialogOpen(false);
            }}
            InvolvedPersonFragmentOverride={InvolvedPersonFragmentOverride}
            involvedPersonValidationFields={involvedPersonValidationFields}
          />
        )}
      </InvolvedPartiesInnerCard>

      <InvolvedPersonDialog
        open={addDialogOpen}
        onCancel={() => setAddDialogOpen(false)}
        onSubmit={async (newInvolvedPerson) => {
          await onSaveInvolvedPersonDetails(newInvolvedPerson);
          setAddDialogOpen(false);
        }}
        title={`Add ${propertyLabel}`}
        InvolvedPersonFragmentOverride={InvolvedPersonFragmentOverride}
        involvedPersonValidationFields={involvedPersonValidationFields}
        involvedPerson={involvedPersonParty}
      />
    </>
  );
};

InvolvedPersonParty.propTypes = {
  title: PropTypes.string.isRequired,
  propertyLabel: PropTypes.string.isRequired,
  involvedPersonParty: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSaveInvolvedPersonDetails: PropTypes.func.isRequired,
  onDeleteInvolvedPerson: PropTypes.func,
  InvolvedPersonFragmentOverride: PropTypes.func,
  involvedPersonValidationFields: PropTypes.object,
};

export { EditInvolvedPersonDialog, InvolvedPersonParty, InvolvedPersonSummary, ShowOnlyInvolvedPersonDialog };

export default InvolvedPersonDialog;
