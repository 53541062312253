import React from 'react';

import { MoreDetailsDialog } from '../../../Dialogs/MoreDetailsDialog';
import type { LegalActionDateOfServiceApiResponse } from '../../../types';

import { DateOfServiceContent } from './DateOfServiceContent';

export const DateOfServiceMoreDetailsDialog: React.FC<{
  onClose: () => void;
  dateOfService: LegalActionDateOfServiceApiResponse;
}> = ({ onClose, dateOfService }) => (
  <MoreDetailsDialog title="Date Of Service" onClose={onClose}>
    <div className="flex w-full flex-col gap-20">
      <DateOfServiceContent dateOfService={dateOfService} rows={2} />
    </div>
  </MoreDetailsDialog>
);
