const BIKE_DAMAGES_LIST = [
  'Front Wheel',
  'Rear Wheel',
  'Frame',
  'Brakes',
  'Battery',
  'Fork',
  'Handlebar',
  'Saddle/Seatpost',
  'Derailleur/Chain',
  'Pedals',
  'Other',
] as const;

export default BIKE_DAMAGES_LIST;
