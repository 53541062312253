import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const InvolvedVehicleIcon = ({ ...props }) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    stroke="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.625 9.375C0.625 9.02982 0.904822 8.75 1.25 8.75H18.75C19.0952 8.75 19.375 9.02982 19.375 9.375C19.375 9.72018 19.0952 10 18.75 10H1.25C0.904822 10 0.625 9.72018 0.625 9.375Z"
      fill="#202020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.375 13.75C14.7202 13.75 15 14.0298 15 14.375V16.25H16.875V14.375C16.875 14.0298 17.1548 13.75 17.5 13.75C17.8452 13.75 18.125 14.0298 18.125 14.375V16.25C18.125 16.5815 17.9933 16.8995 17.7589 17.1339C17.5245 17.3683 17.2065 17.5 16.875 17.5H15C14.6685 17.5 14.3505 17.3683 14.1161 17.1339C13.8817 16.8995 13.75 16.5815 13.75 16.25V14.375C13.75 14.0298 14.0298 13.75 14.375 13.75Z"
      fill="#202020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 13.75C2.84518 13.75 3.125 14.0298 3.125 14.375V16.25H5V14.375C5 14.0298 5.27982 13.75 5.625 13.75C5.97018 13.75 6.25 14.0298 6.25 14.375V16.25C6.25 16.5815 6.1183 16.8995 5.88388 17.1339C5.64946 17.3683 5.33152 17.5 5 17.5H3.125C2.79348 17.5 2.47554 17.3683 2.24112 17.1339C2.0067 16.8995 1.875 16.5815 1.875 16.25V14.375C1.875 14.0298 2.15482 13.75 2.5 13.75Z"
      fill="#202020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.375 11.875C4.375 11.5298 4.65482 11.25 5 11.25H6.25C6.59518 11.25 6.875 11.5298 6.875 11.875C6.875 12.2202 6.59518 12.5 6.25 12.5H5C4.65482 12.5 4.375 12.2202 4.375 11.875Z"
      fill="#202020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.125 11.875C13.125 11.5298 13.4048 11.25 13.75 11.25H15C15.3452 11.25 15.625 11.5298 15.625 11.875C15.625 12.2202 15.3452 12.5 15 12.5H13.75C13.4048 12.5 13.125 12.2202 13.125 11.875Z"
      fill="#202020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.40625 3.125H14.5963C14.8386 3.12598 15.0755 3.19739 15.278 3.33055C15.4798 3.46322 15.6388 3.65148 15.7358 3.87255L18.071 9.12093C18.1066 9.2009 18.125 9.28746 18.125 9.375V14.375C18.125 14.7202 17.8452 15 17.5 15H2.5C2.15482 15 1.875 14.7202 1.875 14.375V9.375C1.875 9.28746 1.89339 9.2009 1.92897 9.12093L4.26416 3.87261C4.36121 3.65151 4.52022 3.46323 4.722 3.33055C4.92452 3.19739 5.16137 3.12598 5.40374 3.12501L5.40625 3.125ZM5.40876 4.375L5.40698 4.37908L3.125 9.50777V13.75H16.875V9.50777L14.5912 4.375L5.40876 4.375Z"
      fill="#202020"
    />
  </SvgIcon>
);

InvolvedVehicleIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default InvolvedVehicleIcon;
