import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import * as Yup from 'yup';

import type { DialogPropsWithLegalAction } from '~/components/LegalActions/Dialogs/DialogTypes';
import { LEGAL_ACTION_PARTY_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import { DatePickerTextFieldFormik } from '~/components/TextFieldFormik';

import { reportAxiosError } from '../../../../Utils';
import { LEGAL_ACTION_PARTY_TERMINATE_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { LegalActionPartyFormValues } from '../../FormFragments/FormValuesTypes';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { LegalActionPartyApiResponse } from '../../types';
import { FormDialog } from '../FormDialog';

export const TerminateLegalActionRepresentationDialog: React.FC<
  DialogPropsWithLegalAction & { party: LegalActionPartyApiResponse }
> = ({ onClose, onSubmitSuccess, legalAction, party }) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: Pick<LegalActionPartyFormValues, 'end_date_of_representation'>) => {
    try {
      await axios.post(backendRoutes.legalAction.party.terminate(party.id), values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<Pick<LegalActionPartyFormValues, 'end_date_of_representation'>>
      title="Terminate Representation"
      onClose={onClose}
      maxWidth="sm"
      formikConfig={{
        onSubmit: handleSubmit,
        validationSchema: Yup.object().shape(LEGAL_ACTION_PARTY_TERMINATE_VALIDATION_SCHEMA),
        initialValues: pick(party, ['end_date_of_representation'] as (keyof LegalActionPartyFormValues)[]),
      }}
    >
      <div className="flex w-full flex-col gap-20">
        <DatePickerTextFieldFormik
          containerClassName="w-full"
          fullWidth
          id={LEGAL_ACTION_PARTY_FIELDS.endDateOfRepresentation.key}
          label={LEGAL_ACTION_PARTY_FIELDS.endDateOfRepresentation.label}
        />
      </div>
    </FormDialog>
  );
};
