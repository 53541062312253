import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isFeatureEnabled, isRwOrganization, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { useCms } from '../hooks/useCms';
import InvolvedPersonDialog, {
  involvedPersonFields,
  InvolvedPersonFragment,
  InvolvedPersonsListDetails,
  involvedPersonValidationFields,
} from '../InvolvedPerson';
import TextFieldFormik, { DatePickerTextFieldFormik, MonetaryValueTextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const involvedEmployeeFields = {
  ...involvedPersonFields,
  employee_external_id: '',
  supervisor_contact_id: '',
  supervisor_contact_full_name: '',
  years_of_service: '',
  job_title: '',
  position_code: '',
  employee_department_name: '',
  employee_department_id: '',
  date_hired: '',
  termination_date: '',
  employee_status: '',
  previous_earnings: '',
  one_year_earnings: '',
  meal_allowance: '',
  involved_person_filled: false,
  employee_department_code: '',
};

function getInvolvedEmployeeFieldsValidationFields(userOrganization) {
  const validationFields = {
    ...involvedPersonValidationFields,
    employee_external_id: Yup.number()
      .typeError('Must be a number')
      .integer('Must be a number')
      .positive('Must be a number'),
    years_of_service: Yup.number().nullable().typeError('Must be a number').positive('Must be a number'),
    job_title: Yup.string(),
    involved_person_filled: Yup.boolean().oneOf([true], 'Must fill employee info'),
  };

  if (
    !isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.WC_EMPLOYEE_HR_SYSTEM_INTEGRATION) &&
    isRwOrganization(userOrganization)
  ) {
    validationFields['job_title'] = Yup.string().required('Required');
    validationFields['employee_department_name'] = Yup.string().required('Required');
  }

  return validationFields;
}

function EmployeeInvolvedFormik({ parentId, showOnly, isFnol, ContactFieldOverride }) {
  const classes = useStyles();
  const { userOrganization } = useCms();

  let idPrefix = '';
  if (parentId) {
    idPrefix = parentId + '.';
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InvolvedPersonFragment
            contactSearchProps={{ newContactRole: 'injured_employee' }}
            contactAcceptedRoles={['injured_employee']}
            personLabel="Employee"
            parentId={parentId}
            showOnly={showOnly}
            ContactFieldOverride={ContactFieldOverride}
            additionalAfterContactFields={
              isFnol &&
              isFeatureEnabled(
                userOrganization,
                CONFIGURATION_FEATURES_NAMES.WC_EMPLOYEE_HR_SYSTEM_INTEGRATION
              ) ? undefined : (
                <>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id={`${idPrefix}employee_external_id`}
                      label="Employee ID"
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id={`${idPrefix}job_title`}
                      label="Job Title"
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id={`${idPrefix}position_code`}
                      label="Position Code"
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id={`${idPrefix}employee_department_name`}
                      label="Employee Department"
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id={`${idPrefix}employee_department_code`}
                      label="Employee Department Code"
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ContactTextFieldFormik
                      id={`${idPrefix}supervisor_contact`}
                      label="Supervisor"
                      className={classes.textField}
                      acceptedRoles={['employer']}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id={`${idPrefix}years_of_service`}
                      label="Years(s) of service"
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerTextFieldFormik
                      id="date_hired"
                      label="Date Hired"
                      className={classes.textField}
                      disableFuture
                      showOnly={showOnly}
                      clearable
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerTextFieldFormik
                      id="termination_date"
                      label="Termination Date"
                      className={classes.textField}
                      disableFuture
                      showOnly={showOnly}
                      clearable
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id={`${idPrefix}employee_status`}
                      label="Employee Status"
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MonetaryValueTextFieldFormik
                      id={`${idPrefix}previous_earnings`}
                      label="Previous 12 weeks history of earnings"
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MonetaryValueTextFieldFormik
                      id={`${idPrefix}one_year_earnings`}
                      label={"One year's worth of earnings"}
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MonetaryValueTextFieldFormik
                      id={`${idPrefix}meal_allowance`}
                      label="Meal allowance"
                      className={classes.textField}
                      fullWidth
                      showOnly={showOnly}
                    />
                  </Grid>
                </>
              )
            }
            hideInjuries
          />
        </Grid>
      </Grid>
    </>
  );
}

EmployeeInvolvedFormik.propTypes = {
  parentId: PropTypes.string,
  showOnly: PropTypes.bool,
  isFnol: PropTypes.bool,
  ContactFieldOverride: PropTypes.func,
};

function EmployeeInvolvedCard() {
  const { claim, onClaimUpdate } = useClaim();
  const { userOrganization } = useCms();

  const handleEditEmployee = async (person) => {
    try {
      await axios.put(`/api/v1/wc_claims/${claim.id}/involved_employees/${person.id}`, person);
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <CardDialog title="Involved Parties">
      <InvolvedPersonsListDetails
        involvedPersons={[
          {
            ...involvedEmployeeFields,
            ..._.omitBy(claim.employee_party.involved_person, _.isNil),
            involved_person_filled: true,
          },
        ]}
        onSaveInvolvedPersonDetails={handleEditEmployee}
        personLabel="Employee"
        CustomInvolvedPersonFragment={EmployeeInvolvedFormik}
        customValidationFields={getInvolvedEmployeeFieldsValidationFields(userOrganization)}
        contactAcceptedRoles={['injured_employee']}
        allowDelete={false}
        disableAddingProperty
      />
    </CardDialog>
  );
}

const ShowOnlyEmployeeInvolvedDialog = (props) => {
  const { open, employee, onCancel } = props;

  return (
    <InvolvedPersonDialog
      onCancel={onCancel}
      person={employee}
      open={open}
      title="Employee Details"
      personLabel="Employee"
      CustomInvolvedPersonFragment={EmployeeInvolvedFormik}
      showOnly
    />
  );
};

ShowOnlyEmployeeInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  employee: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export {
  EmployeeInvolvedCard,
  EmployeeInvolvedFormik,
  getInvolvedEmployeeFieldsValidationFields,
  involvedEmployeeFields,
  ShowOnlyEmployeeInvolvedDialog,
};
