import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';
import * as Twilio from 'twilio-client';

import { ClaimContextProvider } from '~/components/ClaimContainer';
import { TWILIO_ERRORS_TO_IGNORE } from '~/components/communications/CmsCallCenter';
import { getContact } from '~/components/Contact';
import { MinimizedDialogContextProvider } from '~/components/core/MinimizedBar/Context';
import { useCms } from '~/components/hooks/useCms';
import LoadingDialog from '~/components/LoadingDialog';
import { reportAxiosError, reportTwilioError } from '~/Utils';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import CallInProgressCard from './components/CallInProgressCard';
import { CommunicationCallProvider, useCommunicationCallContext } from './Context/CommunicationCallContext';

const OutgoingCallInProgressContainer = () => (
  <CommunicationCallProvider>
    <MinimizedDialogContextProvider>
      <OutgoingCallInProgressContainerInner />
    </MinimizedDialogContextProvider>
  </CommunicationCallProvider>
);

const OutgoingCallInProgressContainerInner = () => {
  const deviceRef = React.useRef(undefined);
  const communicationIdRef = React.useRef(undefined);
  const offlineErrorRef = React.useRef(false);
  const communicationCreatedRef = React.useRef(false);

  const { user, callInProgress, setCallInProgress, userReloadTwilioDetails } = useCms();
  const {
    contactId,
    contactPhoneId,
    claimId,
    content,
    summary,
    exposureIds,
    phoneNumber,
    isNotClaimRelated,
    claimToAttachId,
  } = callInProgress;
  const [claim, isLoadingClaim, isErrorLoadingClaim, reloadClaim] = useFetchClaim(claimId || claimToAttachId);

  const { callStatus, communication, updateCallConnection, updateCommunication, updateDisconnectOrCancel } =
    useCommunicationCallContext();

  const communicationId = communication && communication.id;
  async function setupDevice(device) {
    const res = await axios.get('/api/v1/calls/twilio_outgoing_access_token');

    const token = res.data;
    device.setup(token);
  }

  const handleIncomingCall = () => {
    throw Error('Outgoing calls should not accept calls');
  };

  const handleCallAccepted = React.useCallback(
    async (connection) => {
      updateCallConnection(connection);
      userReloadTwilioDetails();

      if (communicationIdRef.current) {
        const communicationRes = await axios.get(`/api/v1/communications/${communicationIdRef.current}`);
        updateCommunication(communicationRes.data); // If the communication was updated after the call initialized (e.g recording started)
      }
    },
    [userReloadTwilioDetails, updateCallConnection, updateCommunication]
  );

  const handleDisconnectOrCancel = React.useCallback(
    (connection) => {
      updateDisconnectOrCancel(connection);

      deviceRef.current.destroy();
      setTimeout(userReloadTwilioDetails, 5000); // So the status would be updated
    },
    [userReloadTwilioDetails, updateDisconnectOrCancel]
  );

  const handleCallFinished = useCallback(() => {
    setCallInProgress(null);
    userReloadTwilioDetails();
  }, [userReloadTwilioDetails, setCallInProgress]);

  const handleOffline = useCallback(() => {
    if (offlineErrorRef.current) {
      return;
    }
    offlineErrorRef.current = true;
  }, []);

  useEffect(() => {
    communicationIdRef.current = communicationId;
  }, [communicationId]);

  useEffect(() => {
    function callContact(communication) {
      const res = deviceRef.current.connect({
        CmsUserId: user.id,
        CmsClaimId: claimId,
        CmsCommunicationId: communication.id,
        OutgoingFeCall: true,
      });

      updateCallConnection(res);
    }

    const createCommunication = async () => {
      const result = await axios.post('/api/v1/communications/phone_calls', {
        direction: 'Outgoing',
        is_no_answer: true,
        phone_number: phoneNumber,
        content,
        summary,
      });

      updateCommunication(result.data);

      return result.data;
    };

    const createClaimCommunication = async (contact) => {
      const result = await axios.post(
        `/api/v1/claims/${claimId}/contacts/${contact.id}/phones/${
          contactPhoneId ? contactPhoneId : contact.primary_phone.id
        }/communications`,
        { direction: 'Outgoing', is_no_answer: true, exposure_ids: exposureIds, content, summary }
      );

      updateCommunication(result.data);

      return result.data;
    };

    async function handleReady(device) {
      if (communicationCreatedRef.current) {
        return; //  handleReady may be called again if connection disconnected, we don't want to create communication again
      }

      try {
        deviceRef.current = device;
        let communication;
        if (isNotClaimRelated) {
          communication = await createCommunication();
        } else {
          const contact = await getContact(contactId);
          communication = await createClaimCommunication(contact);
        }
        callContact(communication);
        communicationCreatedRef.current = true;
      } catch (error) {
        await reportAxiosError(error);
        handleCallFinished(); // We want to initialize callInProgress to enable receiving new calls
      }
    }

    const initializeCallCenter = () => {
      const device = new Twilio.Device();
      setupDevice(device);

      device.on('ready', handleReady);
      device.on('cancel', handleDisconnectOrCancel);
      device.on('incoming', handleIncomingCall);
      device.on('connect', handleCallAccepted);
      device.on('disconnect', handleDisconnectOrCancel);
      device.on('offline', handleOffline);
      device.on('error', function (error) {
        if (TWILIO_ERRORS_TO_IGNORE.includes(error.code)) {
          return;
        }

        reportTwilioError(error);
      });
    };

    initializeCallCenter();
  }, [
    contactId,
    claimId,
    phoneNumber,
    user.id,
    contactPhoneId,
    content,
    summary,
    exposureIds,
    isNotClaimRelated,
    handleDisconnectOrCancel,
    handleCallAccepted,
    handleCallFinished,
    handleOffline,
    updateCallConnection,
    updateCommunication,
  ]);

  useEffect(() => {
    async function updateClaimCommunication() {
      try {
        const res = await axios.get(`/api/v1/claims/${claimToAttachId}/communications/${communicationId}`);
        updateCommunication(res.data);
      } catch (error) {
        await reportAxiosError(error);
      }
    }

    if (!claimToAttachId) {
      return;
    }

    updateClaimCommunication();
  }, [claimToAttachId, communicationId, updateCommunication]);

  if (isEmpty(communication) || isNil(communication) || !callStatus || isLoadingClaim || isErrorLoadingClaim) {
    return <LoadingDialog track="Initiate outgoing phone call" isError={isErrorLoadingClaim} />;
  }

  const callInProgressCardComponent = (
    <CallInProgressCard isIncoming={false} onCallHandledAndFinished={handleCallFinished} />
  );

  return claim ? (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      {callInProgressCardComponent}
    </ClaimContextProvider>
  ) : (
    callInProgressCardComponent
  );
};

export default OutgoingCallInProgressContainer;
