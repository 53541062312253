import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { useLegalActions } from '~/components/LegalActions/hooks/useLegalActions';

import { reportAxiosError } from '../../../../Utils';
import { CloseLegalActionForm } from '../../FormFragments/CloseLegalActionForm';
import { CLOSE_LEGAL_ACTION_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { CloseLegalActionFormValues } from '../../FormFragments/FormValuesTypes';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { Option } from '../../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../../hooks/useLegalActionsConfiguration';
import type { DialogPropsWithId } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const CloseLegalActionDialog: React.FC<DialogPropsWithId> = ({ legalActionId, onClose, onSubmitSuccess }) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalActionId);
  const { methodOfConclusionOptions } = useLegalActionsConfiguration();
  const { reloadLegalActions } = useLegalActions();

  const handleSubmit = async (values: CloseLegalActionFormValues) => {
    try {
      await axios.post(backendRoutes.legalAction.close, values);
      await Promise.all([onSubmitSuccess(), reloadLegalActions()]);
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<CloseLegalActionFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: {
          method_of_conclusion: '',
          method_of_conclusion_text: '',
          final_settlement_amount: undefined,
        },
        validationSchema: Yup.object().shape(
          CLOSE_LEGAL_ACTION_VALIDATION_SCHEMA(methodOfConclusionOptions as Option[])
        ),
      }}
      title="Close Legal Action"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <CloseLegalActionForm />
      </div>
    </FormDialog>
  );
};
