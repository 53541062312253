import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FsButton, FsMenuItem } from '~/components/core/FsWrappers';

import { FsWrappersSharedDefaultProptypes, FsWrappersSharedProptypes } from '../../../core/FsWrappers/FsWrappersUtils';
import { Text } from '../../TextComponents';

import styles from './FsMenu.module.scss';

const FsMenu = ({ buttonTitle, items, ignorePermissions }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickAndClose = (onClick) => {
    onClick();
    setAnchorEl(null);
  };

  return (
    <>
      <div className={styles.fsMenuButtonWrapper}>
        <FsButton
          className={styles.menuButton}
          variant={FsButton.VARIANTS.text}
          color={FsButton.COLORS.default}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          ignorePermissions={ignorePermissions}
          endIcon={<ExpandMoreIcon />}
        >
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.MEDIUM}>
            {buttonTitle}
          </Text>
        </FsButton>
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <>
          {items.map(({ id, label, onClick, disabled, disabledFunc, displayFunc }) => (
            <>
              {displayFunc() && (
                <FsMenuItem
                  key={id}
                  ignorePermissions={ignorePermissions}
                  onClick={() => onClickAndClose(onClick)}
                  disabled={disabled || disabledFunc()}
                >
                  {label}
                </FsMenuItem>
              )}
            </>
          ))}
        </>
      </Menu>
    </>
  );
};

FsMenu.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      disabledFunc: PropTypes.func,
      displayFunc: PropTypes.func,
    })
  ).isRequired,
  ...FsWrappersSharedProptypes,
};

FsMenu.defaultProps = {
  ...FsWrappersSharedDefaultProptypes,
};

export default FsMenu;
