import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { reportAxiosError } from '../../Utils';

export const usePaymentsLimits = (claim, exposure, payableType, requestType, fetch = false) => {
  const [userExposureLimit, setUserExposureLimit] = useState(null);
  const [isUserExposureLimitLoading, setIsUserExposureLimitLoading] = useState(false);

  const fetchLimit = useCallback(async () => {
    const url = exposure.id
      ? `/api/v1/claims/${claim.id}/exposures/${exposure.id}/${payableType}/${requestType}/limit`
      : `/api/v1/claims/${claim.id}/general_expenses/${requestType}/limit`;
    try {
      setIsUserExposureLimitLoading(true);
      const response = await axios.get(url);
      setUserExposureLimit(response.data);
      setIsUserExposureLimitLoading(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  }, [claim, exposure, payableType, requestType]);

  useEffect(() => {
    if (fetch && _.isNil(userExposureLimit)) {
      fetchLimit();
    } else if (!fetch) {
      setUserExposureLimit(null);
    }
  }, [fetchLimit, fetch, userExposureLimit]);

  return { userExposureLimit, isUserExposureLimitLoading };
};

export default usePaymentsLimits;
