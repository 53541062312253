import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { NEGOTIATION_LOG_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { LegalActionNegotiationLogFormValues } from '../../FormFragments/FormValuesTypes';
import { NegotiationLogForm } from '../../FormFragments/NegotiationLogForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const AddNegotiationLogDialog: React.FC<DialogPropsWithLegalAction> = ({
  onClose,
  onSubmitSuccess,
  legalAction,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: LegalActionNegotiationLogFormValues) => {
    try {
      await axios.post(backendRoutes.legalAction.logs.negotiations, values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<LegalActionNegotiationLogFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: {
          date: null,
          adverse_party_demand: null,
          notes: null,
          offer: null,
          adverse_party_contact_id: null,
        },
        validationSchema: Yup.object().shape(NEGOTIATION_LOG_VALIDATION_SCHEMA),
      }}
      title="Add Negotiation Log"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <NegotiationLogForm />
      </div>
    </FormDialog>
  );
};
