import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { reportAxiosError } from '../../Utils';
import { findIncidentParty } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import PencilIcon from '../icons/PencilIcon';
import { usePolicy } from '../PolicyContainer';
import TextFieldFormik, { DatePickerTextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const petFields = {
  type: '',
  pet_name: '',
  date_of_birth: '',
  breed: '',
  gender: '',
};

const petValidationScheme = Yup.object().shape({
  type: Yup.string().required('Required'),
});

const petInvolvedFields = {
  covered_pet_id: '',
  pet: petFields,
  owner_contact_id: '',
  owner_contact_full_name: '',
  attorney_contact_id: '',
  attorney_contact_full_name: '',
  attorney_reference_number: '',
};

const involvedPetValidationScheme = Yup.object().shape({
  covered_pet_id: Yup.number().required('Required'),
  pet: petValidationScheme,
});

function PetInvolvedCard() {
  const { claim, onClaimUpdate } = useClaim();
  const { policy } = usePolicy();

  const petParty = findIncidentParty(claim, claim.pet_party_id);
  const [showInvolvedPetDialog, setShowInvolvedPetDialog] = useState(false);

  const handleUpdateInvolvedPetDetails = async (pet_values) => {
    try {
      await axios.put(`/api/v1/pet_claims/${claim.id}/pet_party/involved_pet`, pet_values);
      await onClaimUpdate();
      setShowInvolvedPetDialog(false);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  let involvedPetValues = { ...petInvolvedFields, ...petParty.involved_pet };
  const petId = petParty.involved_pet.pet.id;
  const coveredPet = policy.covered_pets && policy.covered_pets.find((cp) => cp.pet.id === petId);
  if (coveredPet) {
    involvedPetValues = { ...involvedPetValues, covered_pet_id: coveredPet.id };
  } else {
    // Unlisted
    involvedPetValues = { ...involvedPetValues, covered_pet_id: 0 };
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardDialog
            title="Involved Pet"
            action={
              <IconButton onClick={() => setShowInvolvedPetDialog(true)}>
                <PencilIcon />
              </IconButton>
            }
          >
            <Formik enableReinitialize initialValues={involvedPetValues} onSubmit={() => {}}>
              {() => {
                return (
                  <Grid container spacing={1}>
                    <InvolvedPetFormik showOnly />
                  </Grid>
                );
              }}
            </Formik>
          </CardDialog>
        </Grid>
      </Grid>

      {showInvolvedPetDialog && (
        <InvolvedPetEditDialog
          petInvolved={involvedPetValues}
          onCancel={() => setShowInvolvedPetDialog(false)}
          onSavePetDetails={handleUpdateInvolvedPetDetails}
        />
      )}
    </>
  );
}

function InvolvedPetEditDialog({ petInvolved, onCancel, onSavePetDetails, showOnly }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ ...petInvolved }}
      validationSchema={involvedPetValidationScheme}
      onSubmit={async (values, formikProps) => {
        try {
          await onSavePetDetails(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog isDialog title="Involved Pet" maxWidth="sm" onClose={onCancel} preventClose={isSubmitting}>
            <InvolvedPetFormik showOnly={showOnly} />
            {!showOnly && (
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

InvolvedPetEditDialog.propTypes = {
  petInvolved: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSavePetDetails: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
  isDialog: PropTypes.bool,
};

function InvolvedPetFormik({ parentId, showOnly, disableEditIdentity, disabled }) {
  const classes = useStyles();
  const { policy } = usePolicy();
  const { values, setFieldValue } = useFormikContext();

  let idPrefix = '';
  if (parentId) {
    idPrefix = parentId + '.';
  }

  const coveredPets = policy && !policy.is_manual ? policy.covered_pets : [];

  let disablePetEditing = disabled || disableEditIdentity;
  const isUnlistedPet = getIn(values, `${idPrefix}covered_pet_id`) === 0;
  disablePetEditing = disablePetEditing || !isUnlistedPet;

  const handleSelectCoveredPet = (e) => {
    const coveredPetId = e.target.value;
    if (getIn(values, `${idPrefix}covered_pet_id`) === coveredPetId) {
      return;
    }

    setFieldValue(`${idPrefix}covered_pet_id`, coveredPetId);

    if (coveredPetId === 0) {
      // Unlisted Pet
      setFieldValue(`${idPrefix}pet`, petFields);
      setFieldValue(`${idPrefix}owner_contact_id`, policy.insured_contact.id);
      setFieldValue(`${idPrefix}owner_contact_full_name`, policy.insured_contact.full_name);
      return;
    }

    const coveredPet = coveredPets.find((pet) => pet.id === coveredPetId);
    setFieldValue(`${idPrefix}pet`, coveredPet.pet);
    setFieldValue(`${idPrefix}owner_contact_id`, policy.insured_contact.id);
    setFieldValue(`${idPrefix}owner_contact_full_name`, policy.insured_contact.full_name);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextFieldFormik
          id={`${idPrefix}covered_pet_id`}
          label="Covered Pet"
          select
          fullWidth
          disabled={disabled || disableEditIdentity}
          className={classes.textField}
          onChange={handleSelectCoveredPet}
          showOnly={showOnly}
        >
          {coveredPets.concat([{ id: 0, pet: { display_name: 'Unlisted Pet' } }]).map((coveredPet) => (
            <MenuItem key={coveredPet.id} value={coveredPet.id}>
              {coveredPet.pet.display_name}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <PetFragmentDetails parentId={`${idPrefix}pet`} showOnly={showOnly} disablePetEditing={disablePetEditing} />
      <Grid item xs={6}>
        <ContactTextFieldFormik
          id={`${idPrefix}owner_contact`}
          label="Owner"
          className={classes.textField}
          acceptedRoles={['insured', 'spouse', 'family_member', 'other']}
          showOnly={showOnly}
          fixedSearchResults
          fullWidth
        />
      </Grid>
      <Grid item xs={6} />
      {/* <Grid item xs={6}>
        <ContactTextFieldFormik
          id={`${idPrefix}attorney_contact`}
          label={'Attorney'}
          className={classes.textField}
          showOnly={showOnly}
          acceptedRoles={['attorney']}
          fixedSearchResults
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id={`${idPrefix}attorney_reference_number`}
          label={'Attorney Reference Number'}
          className={classes.textField}
          showOnly={showOnly}
          fullWidth
        />
      </Grid> */}
    </Grid>
  );
}
InvolvedPetFormik.propTypes = {
  parentId: PropTypes.string,
  showOnly: PropTypes.bool,
  disableEditIdentity: PropTypes.bool,
  disabled: PropTypes.bool,
};

function PetFragmentDetails({ parentId, showOnly, disablePetEditing }) {
  const classes = useStyles();

  let idPrefix = '';
  if (parentId) {
    idPrefix = parentId + '.';
  }

  return (
    <>
      <Grid item xs={4}>
        <TextFieldFormik
          id={`${idPrefix}type`}
          label="Type"
          fullWidth
          className={classes.textField}
          showOnly={showOnly}
          disabled={disablePetEditing}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik
          id={`${idPrefix}pet_name`}
          label="Pet Name"
          fullWidth
          className={classes.textField}
          showOnly={showOnly}
          disabled={disablePetEditing}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePickerTextFieldFormik
          id={`${idPrefix}date_of_birth`}
          label="Date of Birth"
          className={classes.textField}
          showOnly={showOnly}
          disabled={disablePetEditing}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik
          id={`${idPrefix}breed`}
          label="Breed"
          fullWidth
          className={classes.textField}
          showOnly={showOnly}
          disabled={disablePetEditing}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik
          id={`${idPrefix}gender`}
          label="Gender"
          showOnly={showOnly}
          className={classes.textField}
          fullWidth
          select
          disabled={disablePetEditing}
        >
          <MenuItem key="male" value="male">
            Male
          </MenuItem>
          <MenuItem key="female" value="female">
            Female
          </MenuItem>
        </TextFieldFormik>
      </Grid>
      <Grid item xs={4} />
    </>
  );
}

PetFragmentDetails.propTypes = {
  parentId: PropTypes.string,
  showOnly: PropTypes.bool,
  disablePetEditing: PropTypes.bool,
};

function ShowOnlyPetDetailsInfoCardDialog({ pet, title, isDialog, onClose }) {
  return (
    <Formik enableReinitialize initialValues={{ ...pet }} onSubmit={() => {}}>
      {() => {
        return (
          <CardDialog isDialog={isDialog} outlinedCard title={title || 'Pet Info'} maxWidth="xs" onClose={onClose}>
            <Grid container spacing={1}>
              <PetFragmentDetails showOnly />
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}
ShowOnlyPetDetailsInfoCardDialog.propTypes = {
  pet: PropTypes.object.isRequired,
  title: PropTypes.string,
  isDialog: PropTypes.bool,
  onClose: PropTypes.func,
};

function EditPetDetailsDialog({ pet, onSavePetDetails, onClose }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ ...pet }}
      validationSchema={petValidationScheme}
      onSubmit={onSavePetDetails}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        return (
          <CardDialog isDialog title="Edit Pet Info" maxWidth="sm" onClose={onClose}>
            <Grid container spacing={1}>
              <PetFragmentDetails />
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}
EditPetDetailsDialog.propTypes = {
  pet: PropTypes.object.isRequired,
  onSavePetDetails: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export {
  EditPetDetailsDialog,
  InvolvedPetEditDialog,
  InvolvedPetFormik,
  involvedPetValidationScheme,
  petFields,
  PetInvolvedCard,
  petInvolvedFields,
  ShowOnlyPetDetailsInfoCardDialog,
};
