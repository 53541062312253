import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Text } from '../../../../core';
import { useCurrencyFormatter } from '../../../../CurrencyFormatterContext';
import TextFieldFormik from '../../../../TextFieldFormik';

import styles from './additionalPaymentVerificationDialog.module.scss';

const OtherComplianceDetailsFormikFragment = ({ checkDetails, responseFieldId, paymentAmount, showReasonInput }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  return (
    <div className={styles.specificCheckContainer}>
      <div className={styles.internalContainer}>
        <Heading variant={Heading.TYPES.H4}>{checkDetails.issue_title}</Heading>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {checkDetails.issue_dialog_details}
        </Text>
      </div>
      {showReasonInput ? (
        <>
          <Text variant={Text.VARIANTS.SM}>
            {`Are you sure you want to make a payment of ${currencyFormatter.format(paymentAmount)}?`}
          </Text>
          <div className={styles.internalContainer}>
            <TextFieldFormik
              className={styles.reason}
              id={responseFieldId}
              label="Compliance override Reason"
              fullWidth
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

OtherComplianceDetailsFormikFragment.propTypes = {
  checkDetails: PropTypes.object.isRequired,
  responseFieldId: PropTypes.string.isRequired,
  paymentAmount: PropTypes.number.isRequired,
  showReasonInput: PropTypes.bool,
};

export default OtherComplianceDetailsFormikFragment;
