import React from 'react';
import PropTypes from 'prop-types';

import Button from '~/components/core/Atomic/Buttons/Button';
import cn from '~/Utils/cn';

import styles from './ActionMenuButton.module.scss';

export default function ActionMenuButton({ onClick, label, IconComponent = null, disabled = false }) {
  const Icon = IconComponent ? <IconComponent className={styles.icon} size={20} /> : null;

  return (
    <Button
      onClick={onClick}
      classes={{
        root: cn(styles.actionsMenuButton, styles.root),
        label: disabled ? styles.disabled : styles.enabled,
        startIcon: !disabled && styles.icon,
      }}
      disabled={disabled}
      disableRipple
      startIcon={Icon}
    >
      {label}
    </Button>
  );
}

ActionMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  IconComponent: PropTypes.object,
  disabled: PropTypes.bool,
};
