import React from 'react';
import { FileDocument } from 'mdi-material-ui';

import InlineIconButton from '../../../../InlineIconButton';
import { DateCell } from '../../../components/DateCell';
import { useLegalAction } from '../../../hooks/useLegalAction';
import { useLegalActionsBackendRoutes } from '../../../hooks/useLegalActionsBackendRoutes';
import type { DocumentApiResponse } from '../../../types';
import type { LogsCardContentColumnDefinition } from '../LogsCardConent';
import { LogsCardContainer } from '../LogsCardContainer';

import { AddLegalActionDocument } from './AddLegalActionDocument';
import { DocumentMoreDetails } from './DocumentMoreDetails';
import { EditLegalActionDocument } from './EditLegalActionDocument';

interface DocumentsCardProps {
  documents: DocumentApiResponse[];
  reloadDocuments: () => Promise<void>;
}

export const LegalActionDocumentsCard: React.FC<DocumentsCardProps> = ({ documents, reloadDocuments }) => {
  const { legalAction } = useLegalAction();
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const columns: LogsCardContentColumnDefinition<DocumentApiResponse>[] = [
    {
      id: '',
      label: '',
      specialCell: (row) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <InlineIconButton
          icon={FileDocument}
          onClick={() => window.open(`/api/v1/claims/${row?.claim_id}/documents/${row?.id}`, '_target')}
        />
      ),
      disableSort: true,
    },
    {
      id: 'document_name',
      label: 'Document Name',
    },
    {
      id: 'document_date',
      label: 'Document Date',
      specialCell: (row) => <DateCell<DocumentApiResponse> log={row} property="document_date" />,
    },
    {
      id: 'uploaded_by',
      label: 'Uploaded By',
    },
  ];

  return (
    <LogsCardContainer
      NewItemComponent={AddLegalActionDocument}
      MoreDetailsComponent={DocumentMoreDetails}
      EditItemComponent={EditLegalActionDocument}
      deleteUrl={backendRoutes.legalAction.document}
      logs={documents}
      logType={undefined}
      columns={columns}
      title="Documents"
      addItemLabel="Document"
      reload={reloadDocuments}
      legalAction={legalAction}
    />
  );
};
