import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Collapse, TableCell, TableRow } from '@material-ui/core';
import _ from 'lodash';

import cn from '~/Utils/cn';

import { ChevronLeftIcon } from '../../../../icons';
import ExtendableInnerTable from '../ExtendableInnerTable';
import SortableTableCell from '../SortableTableCell';
import { expandActionColumn } from '../utils';

import parentStyles from '../sortableTable.module.scss';
import styles from './sortableTableRow.module.scss';

const calcMaxWidth = (columnMaxWidthPercentage, tableWidth, totalColumnsWidth) =>
  columnMaxWidthPercentage && tableWidth
    ? `${Math.floor((columnMaxWidthPercentage * tableWidth) / 100)}px`
    : totalColumnsWidth;

function SortableTableRow({
  columns,
  row,
  selectedRowsIds,
  selectedColumnId,
  order,
  orderBy,
  onRowClick,
  tableWidth,
  boldRowsIds,
  greyedOutRowsIds,
  onExpandColumns,
  highlightOnHover = true,
  shouldRenderExpandAsTable = false,
  isRestrictedPredicate,
  restrictedContentTooltipText,
  hasExpandableRows = false,
  subRowsPropertyName,
  doNotBubbleHover,
}) {
  const [isMouseOverRow, setIsMouseOverRow] = useState(false);

  const [expandedMap, setExpandedMap] = useState({});

  const selected = _.includes(selectedRowsIds, row.id);
  const isBold = boldRowsIds?.includes(row.id);
  const isGreyedOut = greyedOutRowsIds?.includes(row.id);

  const staticColumns = columns.filter((column) => !column.expandable);
  const expandableColumns = columns.filter((column) => column.expandable);

  const isRestricted = isRestrictedPredicate && isRestrictedPredicate(row);
  const shouldGreyOut = isRestricted || isGreyedOut;
  const subRows = subRowsPropertyName && row[subRowsPropertyName];

  const getCellProps = (column) => ({
    row,
    isSelected: selectedColumnId === column.id,
    onRowClick,
    isBold,
    isGreyedOut: shouldGreyOut,
    isMouseOverRow,
    sortDirection: orderBy === column.id ? order : undefined,
    maxWidth: calcMaxWidth(column.maxWidthPercentage, tableWidth, columns.width),
    isRestricted,
    restrictedContentTooltipText,
  });

  const expandActionColumnWithSpecialCell = {
    ...expandActionColumn,
    specialCell: (row) => {
      if (_.isEmpty(expandableColumns) && _.isEmpty(subRows)) {
        return <span />;
      }

      return (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (onExpandColumns) {
              onExpandColumns();
            }
            setExpandedMap({ ...expandedMap, [row.id]: !expandedMap[row.id] });
          }}
        >
          <ChevronLeftIcon className={expandedMap[row.id] ? styles.upChevron : styles.downChevron} />
        </span>
      );
    },
  };

  const staticColumnsComponents = staticColumns.map((column) => (
    <SortableTableCell key={column.id} column={column} {...getCellProps(column)} />
  ));

  return (
    <>
      <TableRow
        selected={selected}
        hover={highlightOnHover}
        onMouseEnter={(e) => {
          // Hack because of the ContactSearch component re-rendering when the mouse is out of the screen NGTPA-13953
          if ((doNotBubbleHover && !e.bubbles) || !doNotBubbleHover) {
            setIsMouseOverRow(true);
          }
        }}
        onMouseLeave={(e) => {
          // Hack because of the ContactSearch component re-rendering when the mouse is out of the screen NGTPA-13953
          if ((doNotBubbleHover && !e.bubbles) || !doNotBubbleHover) {
            setIsMouseOverRow(false);
          }
        }}
        className={cn({
          [parentStyles.selectedRow]: selected,
          [styles.expandedRowParent]: expandedMap[row.id],
        })}
      >
        {(!_.isEmpty(expandableColumns) || hasExpandableRows) && (
          <SortableTableCell
            key={expandActionColumnWithSpecialCell.id}
            column={expandActionColumnWithSpecialCell}
            {...getCellProps(expandActionColumnWithSpecialCell)}
          />
        )}
        {staticColumnsComponents}
      </TableRow>
      {!_.isEmpty(expandableColumns) && (
        <TableRow
          className={cn(styles.row, {
            [styles.collapsedRow]: !expandedMap[row.id],
            [styles.nonTableCollapsedRow]: !shouldRenderExpandAsTable,
          })}
        >
          <TableCell colSpan={staticColumnsComponents.length + 1} className={styles.expandedRowCell}>
            <Collapse in={expandedMap[row.id]} timeout="auto">
              <ExtendableInnerTable
                columns={expandableColumns}
                selectedColumnId={selectedColumnId}
                orderBy={orderBy}
                order={order}
                selected={selected}
                highlightOnHover={highlightOnHover}
                getCellProps={getCellProps}
                shouldRenderAsTable={shouldRenderExpandAsTable}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {subRows &&
        expandedMap[row.id] &&
        subRows.map((subRow) => (
          <SortableTableRow
            key={subRow.id}
            columns={columns}
            row={subRow}
            subRowsPropertyName={subRowsPropertyName}
            selectedRowsIds={selectedRowsIds}
            selectedColumnId={selectedColumnId}
            order={order}
            orderBy={orderBy}
            onRowClick={onRowClick}
            tableWidth={tableWidth}
            boldRowsIds={boldRowsIds}
            greyedOutRowsIds={greyedOutRowsIds}
            highlightOnHover={highlightOnHover}
            shouldRenderExpandAsTable={shouldRenderExpandAsTable}
            onExpandColumns={onExpandColumns}
            isRestrictedPredicate={isRestrictedPredicate}
            restrictedContentTooltipText={restrictedContentTooltipText}
            hasExpandableRows={hasExpandableRows}
          />
        ))}
    </>
  );
}

SortableTableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  selectedColumnId: PropTypes.string,
  order: PropTypes.oneOf(['asc', 'desc']),
  row: PropTypes.object.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRowClick: PropTypes.func,
  tableWidth: PropTypes.number,
  highlightOnHover: PropTypes.bool,
  shouldRenderExpandAsTable: PropTypes.bool,
  onExpandColumns: PropTypes.func,
  isRestrictedPredicate: PropTypes.func,
  restrictedContentTooltipText: PropTypes.string,
  hasExpandableRows: PropTypes.bool,
  subRowsPropertyName: requiredIf(PropTypes.string, (props) => props.hasExpandableRows),
  selectedRowsIds: PropTypes.array,
  boldRowsIds: PropTypes.arrayOf(PropTypes.number),
  greyedOutRowsIds: PropTypes.arrayOf(PropTypes.number),
  doNotBubbleHover: PropTypes.bool,
};

export default SortableTableRow;
