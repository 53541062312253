import { reportErrorInProductionOrThrow } from '../../Utils';
import { getEntityIcon } from '../../Utils/ClaimUtils';
import { useLob } from '../hooks/useLob';

import { useIncidentConfiguration } from './useIncidentConfiguration';
import { useLobConfiguration } from './useLobConfiguration';

const getDefaultFields = (coveredObjectTypeLabel, isProperty, coveredObject = null) => {
  const displayNameField = {
    id: 'display_name',
    desc: coveredObjectTypeLabel?.replace('Covered ', ''),
    type: 'string',
    value: coveredObject?.display_name ?? '',
    mandatory: true,
    active: true,
  };
  const contactField = {
    id: 'contact_id',
    desc: isProperty ? 'Owner' : 'Name',
    type: 'contact',
    value: coveredObject?.contact?.id,
    mandatory: true,
    active: true,
  };
  return isProperty ? [displayNameField, contactField] : [contactField];
};

const useLobConfiguredFields = (coveredObject = null, throwIfNotExist = true) => {
  const { lobConfigurationsDict } = useLobConfiguration();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { lob } = useLob();

  const coveredObjectType = coveredObject?.type ?? lobConfigurationsDict[lob]?.covered_objects[0].id;
  const coveredObjectConfiguration = lobConfigurationsDict[lob]?.covered_objects?.find(
    (c) => c.id === coveredObjectType
  );
  const incidentCoveredObject = incidentConfiguration?.policy?.covered_objects?.find((c) => c.id === coveredObjectType);

  if (throwIfNotExist && (!coveredObjectConfiguration || !incidentCoveredObject)) {
    reportErrorInProductionOrThrow(`Invalid covered object type ${coveredObjectType}`);
  }

  const isProperty = incidentCoveredObject?.type === 'property';
  const Icon = getEntityIcon(coveredObjectConfiguration?.icon);
  const coveredObjectTypeLabel = coveredObjectConfiguration?.desc;
  const defaultFields = getDefaultFields(coveredObjectTypeLabel, isProperty, coveredObject);
  const configuredFields =
    incidentCoveredObject?.configured_fields
      ?.filter((field) => field.active)
      .map((field) => {
        let value =
          (coveredObject?.configured_fields_values && coveredObject?.configured_fields_values[field.id]) ?? '';
        if (!value) {
          if (field.type === 'location') {
            value = {};
          }
          if (field.type === 'multiselect') {
            value = [];
          }
        }
        return { ...field, value };
      }) ?? [];

  return { Icon, coveredObjectTypeLabel, configuredFields, coveredObjectType, isProperty, defaultFields };
};

export { useLobConfiguredFields };
