import React from 'react';

import { FsMenuItem } from '~/components/core';
import { JURISDICTION_INFORMATION_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import TextFieldFormik, { DatePickerTextFieldFormik } from '~/components/TextFieldFormik';
import { COUNTRY_TO_STATE_MAP } from '~/Types';

export const JurisdictionInformationForm = () => {
  return (
    <>
      <div className="flex w-full gap-32">
        <div className="flex w-1/2">
          <TextFieldFormik
            fullWidth
            id={JURISDICTION_INFORMATION_FIELDS.jurisdiction.key}
            label={JURISDICTION_INFORMATION_FIELDS.jurisdiction.label}
            select
          >
            {Object.entries(COUNTRY_TO_STATE_MAP.US)
              .sort(([code_a], [code_b]) => code_a.localeCompare(code_b))
              .map(([code, label]) => (
                <FsMenuItem key={code} value={code}>
                  {label}
                </FsMenuItem>
              ))}
          </TextFieldFormik>
        </div>
        <div className="flex w-1/2">
          <TextFieldFormik
            id={JURISDICTION_INFORMATION_FIELDS.venue.key}
            label={JURISDICTION_INFORMATION_FIELDS.venue.label}
            fullWidth
          />
        </div>
      </div>
      <div className="flex w-full gap-32">
        <div className="flex w-1/2">
          <TextFieldFormik
            id={JURISDICTION_INFORMATION_FIELDS.court.key}
            label={JURISDICTION_INFORMATION_FIELDS.court.label}
            fullWidth
          />
        </div>
        <div className="flex w-1/2">
          <DatePickerTextFieldFormik
            containerClassName="w-full"
            fullWidth
            id={JURISDICTION_INFORMATION_FIELDS.dateFilledInCourt.key}
            label={JURISDICTION_INFORMATION_FIELDS.dateFilledInCourt.label}
            nullIfEmpty
          />
        </div>
      </div>
    </>
  );
};
