import type { ReactElement } from 'react';
import React from 'react';
import Popover from '@material-ui/core/Popover';
import type { PopoverProps } from '@material-ui/core/Popover/Popover';

interface StablePopoverProps extends Partial<PopoverProps> {
  children: React.ReactNode;
  onClose?: () => void;
  closeOnBackdropClick?: boolean;
}

export default function StablePopover({
  children,
  open,
  anchorEl,
  onClose,
  anchorOrigin,
  transformOrigin,
  closeOnBackdropClick,
  ...restProps
}: StablePopoverProps): ReactElement {
  const closeOnEscOnly = onClose
    ? undefined
    : (e: React.SyntheticEvent, reason: string) => {
        // close only on 'escapeKeyDown' not on 'backdropClick'
        if (reason === 'escapeKeyDown' && onClose) {
          return (onClose as () => void)();
        }
      };

  return (
    <Popover
      open={!!open}
      anchorEl={anchorEl}
      onClose={closeOnBackdropClick ? onClose : closeOnEscOnly}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...restProps}
    >
      {children}
    </Popover>
  );
}

StablePopover.defaultProps = {
  placement: 'bottom-end',
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'center' },
};
