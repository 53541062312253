import React from 'react';
import { useFormikContext } from 'formik';
import { keyBy } from 'lodash';

import useFetchAdjusters from '~/Adjuster/useFetchAdjusters';
import { useClaim } from '~/components/ClaimContainer';
import type { ContactFullModel } from '~/components/Contacts/types';
import SummaryCard from '~/components/core/SummaryCard';
import type { LabeledValueWithKeyProps, SummarySectionProps } from '~/components/core/SummaryCard/SummarySection';
import SummarySection from '~/components/core/SummaryCard/SummarySection';
import type { MoiFormikValues, MoiStepKeys } from '~/components/exposures/moi/MoiStepper/types';
import type { UseMoiMethodsResults } from '~/components/exposures/moi/MoiStepper/useMoiMethods';
import {
  GENERAL_EXPOSURE_FAKE_ID,
  GENERAL_EXPOSURE_LABEL,
  moiMethodToString,
} from '~/components/exposures/moi/MoiUtils';
import type { ExposureModel } from '~/components/exposures/types';
import useOrganization from '~/components/OrganizationContext';

import type { MoiStepConfig } from './MoiSteps';

interface MoiSummaryProps {
  exposure?: ExposureModel;
  currentStep?: MoiStepConfig;
  selectedContact?: ContactFullModel;
  isEditMode: boolean;
  moiMethodsReturn: UseMoiMethodsResults;
}

interface MoiSummarySectionConfig extends SummarySectionProps {
  key: MoiStepKeys;
}

interface MoiSummaryFieldsConfig extends LabeledValueWithKeyProps {
  isHidden?: boolean;
}

const MoiSummary: React.FC<MoiSummaryProps> = ({ currentStep, selectedContact, moiMethodsReturn }) => {
  const { moiMethodsById } = moiMethodsReturn;
  const { values } = useFormikContext<MoiFormikValues>();
  const { claim } = useClaim();
  const {
    moiOptions,
  }: {
    moiOptions?: {
      [claimType: string]: {
        [methodKey: string]: {
          title: string;
          is_removed: boolean;
          contact_roles: string[];
          contact_select_title: string;
          required_vendor: boolean;
        };
      };
    };
  } = useOrganization();

  const isInternalUserAssignment = values.assignee_type === 'internal_user';
  const { possibleAdjustersWithRemoved = [] } = useFetchAdjusters(claim.organization_id, isInternalUserAssignment);

  const exposuresById = keyBy(claim.exposures, 'id');
  const getChipLabel = (exposure_id: number): string =>
    exposure_id === GENERAL_EXPOSURE_FAKE_ID ? GENERAL_EXPOSURE_LABEL : exposuresById[exposure_id].label_text;

  const usersById = keyBy(possibleAdjustersWithRemoved, 'id');

  const filterFields = (field: MoiSummaryFieldsConfig) => !field.isHidden;

  const isOldMoiDisplay = values?.method;
  const oldMoiValue = isOldMoiDisplay && moiMethodToString({ method: values?.method }, claim, moiOptions);

  const assignmentFields: MoiSummaryFieldsConfig[] = [
    {
      label: 'Method of Inspection',
      value: isOldMoiDisplay ? oldMoiValue : moiMethodsById[values.moi_method_id]?.display_name,
      key: 'moi_method',
    },
    {
      label: 'Exposure',
      value: values.exposure_ids.map(getChipLabel).join(', '),
      type: 'Chip',
      key: 'exposure',
    },
  ];

  const assigneeFields: MoiSummaryFieldsConfig[] = [
    {
      label: 'Service Provider',
      key: 'service_provider',
      value: selectedContact?.full_name || '',
      isHidden: values.assignee_type !== 'service_provider',
    },
    {
      label: 'Internal User',
      key: 'internal_user',
      value: usersById[values['user_id']]?.username || '',
      isHidden: !isInternalUserAssignment,
    },
    {
      label: 'Appraiser',
      key: 'appraiser',
      value: values?.selectedVendor?.name || '',
      isHidden: values.assignee_type !== 'appraiser',
    },
  ];

  let sections: MoiSummarySectionConfig[] = [
    { title: 'Assignment', key: 'assignment', fields: assignmentFields },
    { title: 'Assignee', key: 'assignee', fields: assigneeFields },
  ];

  if (currentStep?.key === 'assignment') {
    sections = sections.slice(0, 1);
  }

  return (
    <SummaryCard title="Summary" className="ml-24 h-[560px]">
      {sections.map(({ title, key, fields }) => {
        return <SummarySection title={title} fields={fields.filter(filterFields)} key={key} />;
      })}
    </SummaryCard>
  );
};

export default MoiSummary;
