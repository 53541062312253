import React from 'react';

import type { LegalActionApiResponse } from '~/components/LegalActions/types';

export const LegalActionsTabLabel: React.FC<{ legalActions: LegalActionApiResponse[] }> = ({ legalActions }) => {
  const legalActionsTabLabelText = !legalActions ? 'Legal Actions' : `Legal Actions (${legalActions?.length})`;

  const highPriorityActions = legalActions?.filter((action) => action.priority === 'high');

  return (
    <div className="flex gap-12">
      {legalActionsTabLabelText}
      {highPriorityActions?.length > 0 && <span className="text-status-failed">!</span>}
    </div>
  );
};
