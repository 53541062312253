import React from 'react';

import { MoreDetailsDialog } from '../../../Dialogs/MoreDetailsDialog';
import type { DisputeResolutionProfessionalApiResponse } from '../../../types';

import { DisputeResolutionProfessionalContent } from './DisputeResolutionProfessionalContent';

export const DisputeResolutionProfessionalMoreDetailsDialog: React.FC<{
  onClose: () => void;
  drp: DisputeResolutionProfessionalApiResponse;
}> = ({ onClose, drp }) => (
  <MoreDetailsDialog title="Dispute Resolution Professional" onClose={onClose}>
    <div className="flex w-full flex-col gap-20">
      <DisputeResolutionProfessionalContent drp={drp} rows={2} />
    </div>
  </MoreDetailsDialog>
);
