import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import FileDropZone from '../../../FileDropZone';
import { TrashIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import { DocumentPropertyDetails } from '../../components/Document/DocumentPropertyDetails';
import { LEGAL_ACTION_LOG_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { LegalActionLogFormValues } from '../../FormFragments/FormValuesTypes';
import { LegalActionLogForm } from '../../FormFragments/LegalActionLogForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { LegalActionLogApiResponse } from '../../types';
import type { DialogPropsWithLegalAction, DialogPropsWithLog } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

interface AddEditLegalActionLogDialogProps extends DialogPropsWithLegalAction {
  log?: LegalActionLogApiResponse;
}

export const AddLegalActionLogDialog: React.FC<DialogPropsWithLegalAction> = ({
  legalAction,
  onClose,
  onSubmitSuccess,
}) => <AddEditLegalActionLogDialog legalAction={legalAction} onClose={onClose} onSubmitSuccess={onSubmitSuccess} />;

export const EditLegalActionLogDialog: React.FC<DialogPropsWithLog<LegalActionLogApiResponse>> = ({
  legalAction,
  onClose,
  onSubmitSuccess,
  log,
}) => (
  <AddEditLegalActionLogDialog
    legalAction={legalAction}
    onClose={onClose}
    onSubmitSuccess={onSubmitSuccess}
    log={log}
  />
);

const AddEditLegalActionLogDialog: React.FC<AddEditLegalActionLogDialogProps> = ({
  legalAction,
  onClose,
  onSubmitSuccess,
  log,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);
  const [file, setFile] = React.useState<File | null>(null);
  const [uploadPercent, setUploadPercent] = React.useState<number>(0);
  const [shouldRemoveDocument, setShouldRemoveDocument] = React.useState<boolean>(false);

  const handleSubmit = async (values: LegalActionLogFormValues) => {
    const payload: LegalActionLogFormValues & {
      file_name?: string;
      file_size?: number;
      file_type?: string;
      storage_filename?: string;
      should_remove_document?: boolean;
    } = values;
    try {
      if (file) {
        const { storageFilename, uploadUrl } = await backendRoutes.getUploadUrl(file);
        await axios.put(uploadUrl, file, {
          onUploadProgress: (progressEvent) => {
            setUploadPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
        });

        payload.file_name = file?.name;
        payload.file_size = file?.size;
        payload.file_type = file?.type;
        payload.storage_filename = storageFilename;
      }

      if (log && shouldRemoveDocument) {
        payload.should_remove_document = true;
      }

      if (log) {
        await axios.put(backendRoutes.legalAction.logs.legal_action_log(log.id), payload);
      } else {
        await axios.post(backendRoutes.legalAction.logs.legal_action_logs, payload);
      }
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  const initialValues: LegalActionLogFormValues = log
    ? (pick(log, [
        'date',
        'log_type',
        'log_type_text',
        'status_of_litigation',
        'status_of_litigation_text',
        'counsel_update',
        'due_date',
        'next_action',
      ]) as unknown as LegalActionLogFormValues)
    : {
        date: null,
        log_type: '',
        log_type_text: '',
        status_of_litigation: '',
        status_of_litigation_text: '',
        counsel_update: '',
        due_date: null,
        next_action: '',
      };

  const shouldShowUpload = !log || (log && shouldRemoveDocument) || (log && !log.document);

  return (
    <FormDialog<LegalActionLogFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues,
        validationSchema: Yup.object().shape(LEGAL_ACTION_LOG_VALIDATION_SCHEMA),
      }}
      title="Add Legal Action Log"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <LegalActionLogForm />
        {log?.document && !shouldRemoveDocument ? (
          <DocumentPropertyDetails
            title="Counsel Report"
            document={log?.document}
            secondaryAction={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <InlineIconButton icon={TrashIcon} onClick={() => setShouldRemoveDocument(true)} />
            }
          />
        ) : null}
        {shouldShowUpload ? (
          <FileDropZone
            uploadPercent={uploadPercent}
            onCancelFileSelect={() => setFile(null)}
            onFileSelect={(file) => setFile(file)}
            file={file || undefined}
          />
        ) : null}
      </div>
    </FormDialog>
  );
};
