import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { omit } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import cn from '~/Utils/cn';

import { getLocalDateToday } from '../../../../DateTimeUtils';
import { FNOL_MIXPANEL_EVENTS } from '../../../../pocs/mixpanel';
import mixpanel from '../../../CmsMain/mixpanel';
import { useIncidentConfiguration } from '../../../hooks/useIncidentConfiguration';
import { PreDefinedField, preparePreDefinedFields } from '../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../IncidentConfiguration/CustomFieldsContext';
import LoadingDialog from '../../../LoadingDialog';
import { usePolicy } from '../../../PolicyContainer';
import { getPredefinedIncidentDetailsFieldsInitialValues } from '../IncidentDetails';

import { useStyles } from '../../../../assets/styles';
import styles from './index.module.scss';

const getFooterInitialValues = (incidentConfiguration, claimValues) => ({
  ...getPredefinedIncidentDetailsFieldsInitialValues(omit(incidentConfiguration.fnol, 'instructions')),
  reported_date: claimValues?.reported_date || getLocalDateToday(),
});
const getFooterValidation = (incidentConfiguration) => ({
  ...getAdditionalDataValidations(preparePreDefinedFields(omit(incidentConfiguration.fnol, 'instructions'))),
  reported_date: Yup.date()
    .max(new Date())
    .nullable()
    .required('Required')
    .when('date_of_loss', {
      is: (val) => !!val,
      then: Yup.date().nullable().required('Required').min(Yup.ref('date_of_loss'), 'Must be after the Date of Loss'),
    }),
});

const FnolFooter = ({ onSaveDraft, partiesStore }) => {
  const classes = useStyles();
  const { handleSubmit, isSubmitting, setSubmitting, values } = useFormikContext();
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = omit(incidentConfiguration.fnol, 'instructions');
  const { policy } = usePolicy();

  const handleSubmitDraft = async () => {
    await onSaveDraft(values, setSubmitting);
  };

  const handleOnClickCreated = () => {
    handleSubmit();
    mixpanel.track(FNOL_MIXPANEL_EVENTS.CREATE_FNOL_CLICKED, {
      date_of_report: values.reported_date,
      submission_mode: values.submission_mode,
      reporting_type: policy.is_manual ? 'reported without policy' : 'reported from existing policy',
      fnol_type: 'new_fnol',
    });
  };
  return (
    <>
      <div className={styles.fnolFooter}>
        <Grid container className={styles.footerContent} spacing={2}>
          <PreDefinedField id="submission_mode" fields={preDefinedFields} inline gridXs={2} />
          <PreDefinedField id="reported_date" fields={preDefinedFields} inline gridXs={2} disableFuture />
          <Grid item>
            <Button
              disabled={isSubmitting || partiesStore.isFetching}
              onClick={async () => {
                setSubmitting(true);
                await handleSubmitDraft();
              }}
              variant="contained"
              className={cn(classes.cancelButton, styles.fnolCreateBtn)}
            >
              Save Draft
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={isSubmitting}
              onClick={handleOnClickCreated}
              variant="contained"
              color="primary"
              className={styles.fnolCreateBtn}
            >
              Create FNOL
            </Button>
          </Grid>
        </Grid>
      </div>
      {isSubmitting && <LoadingDialog isError={false} track="Submit configurable fnol" />}
    </>
  );
};

FnolFooter.propTypes = {
  partiesStore: PropTypes.object.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
};

export { getFooterInitialValues, getFooterValidation };

export default FnolFooter;
