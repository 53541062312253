import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useTableau } from '~/components/ReportsPage/Context/TableauContext';
import { TableauEmbedEditor } from '~/components/ReportsPage/EmbedComponents/TableauEmbedEditor';
import { removeLastSegment } from '~/components/ReportsPage/helpers/ReportsUtils';
import { useTableauEmbedAuth } from '~/components/ReportsPage/Hooks/useTableauEmbedAuth';
import { WorkbookEditorLayout } from '~/components/ReportsPage/pages/WorkbookEditorLayout';

interface WorkbookEditorPageProps {
  workbookId: string;
}

export const WorkbookEditorPage: React.FC<WorkbookEditorPageProps> = ({ workbookId }) => {
  const { token } = useTableauEmbedAuth();
  const { workbooksMap, isLoading, reloadAll } = useTableau();
  const workbook = workbooksMap[workbookId];
  const history = useHistory();
  const match = useRouteMatch();

  const onWorkbookReadyToClose = () => {
    reloadAll();
    history.push(removeLastSegment(match.url));
  };

  return (
    <WorkbookEditorLayout
      editorComponent={
        <TableauEmbedEditor
          workbook={workbook.content_url}
          view={workbook?.views?.[0]?.content_url || ''}
          token={token}
          options={{
            width: '100%',
            height: 'calc(100vh - 130px)',
            onWorkbookReadyToClose,
          }}
        />
      }
      isLoading={isLoading}
      token={token}
    />
  );
};
