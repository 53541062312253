import React from 'react';
import { FieldArray } from 'formik';

import { AddItemButton, FormItemCard, SecondaryCard } from '../../../core';
import { DateOfServiceForm } from '../../FormFragments/DateOfServiceForm';
import { LEGAL_ACTION_FIELDS } from '../../FormFragments/FormFieldsDefinitions';
import type { DateOfServiceFormValues } from '../../FormFragments/FormValuesTypes';
import { dateOfServiceInitialValues } from '../InitialValues';

import { DeleteIconButton } from './DeleteIconButton';

export const DatesOfServiceFormCard: React.FC = () => {
  return (
    <SecondaryCard type="contained" title="Dates of Service" collapsible openByDefault>
      <FieldArray
        name={LEGAL_ACTION_FIELDS.datesOfService.key}
        render={({ form, remove, push }) => {
          const datesOfService = form.values[LEGAL_ACTION_FIELDS.datesOfService.key];

          return (
            <>
              {datesOfService.map((dateOfService: DateOfServiceFormValues, index: number) => {
                const title = `Date of Service #${index + 1}`;

                return (
                  <FormItemCard
                    key={title}
                    title={title}
                    action={<DeleteIconButton onClick={() => remove(index)} index={0} />}
                  >
                    <div className="flex w-full flex-col gap-20">
                      <DateOfServiceForm arrayFieldPrefix={LEGAL_ACTION_FIELDS.datesOfService.key} index={index} />
                    </div>
                  </FormItemCard>
                );
              })}
              <div>
                <AddItemButton
                  text="Add Date Of Service"
                  variant="text"
                  onClick={() => {
                    push(dateOfServiceInitialValues);
                  }}
                />
              </div>
            </>
          );
        }}
      />
    </SecondaryCard>
  );
};
