import React from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';

import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';
import RadioButtonFormik from '../../../RadioButtonFormik';
import { TextFieldFormik } from '../../../TextFieldFormik';
import { QUESTIONS } from '../utils';

const ClaimQaQuestion = ({ questionId, questionText, disabled, automaticAnswer, explanation, isFreeText = false }) => (
  <>
    {!isFreeText ? (
      <>
        <Grid item xs={8}>
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="body2" color={automaticAnswer ? 'primary' : undefined}>
              {questionText}
            </Typography>
            <ErrorHelperTextFormik style={{ marginTop: 0, marginLeft: '8px' }} id={questionId} />
          </span>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {explanation && (
              <div style={{ marginTop: 'auto', marginRight: '16px' }}>
                <Tooltip title={explanation} size="small">
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>
            )}
            {Object.values(QUESTIONS).map(({ text, answerValue }) => (
              <RadioButtonFormik
                key={answerValue}
                id={questionId}
                label={<Typography variant="body2">{text}</Typography>}
                optionValue={answerValue}
                disabled={disabled}
                size="small"
              />
            ))}
          </div>
        </Grid>
      </>
    ) : (
      <TextFieldFormik id={questionId} label={questionText} rows="3" fullWidth multiline disabled={disabled} />
    )}
  </>
);

ClaimQaQuestion.propTypes = {
  questionId: PropTypes.string.isRequired,
  questionText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  automaticAnswer: PropTypes.object,
  explanation: PropTypes.string,
  isFreeText: PropTypes.bool,
};

export default ClaimQaQuestion;
