import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { getAllClaimRelatedRoles } from '../../../../communications/ContactUtils';
import ContactTextFieldFormik from '../../../../ContactTextFieldFormik';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { PreDefinedField, preparePreDefinedFields } from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';
import useOrganization from '../../../../OrganizationContext';

import { useStyles } from '../../../../../assets/styles';

const involvedPropertyFields = {
  property_description: '',
  owner_contact_id: '',
  owner_contact: null,
  owner_contact_full_name: '',
  attorney_contact_id: '',
  attorney_contact: null,
  attorney_reference_number: '',
  insurer_contact_id: '',
  insurer_contact: null,
  insurer_reference_number: '',
  insurer_policy_number: '',
  damages: [],
  location: {},
  damages_description: '',
  is_stolen: null,
};

const getInvolvedPropertyDefinedFields = (incidentConfiguration) => {
  const preDefinedFields = {
    ...incidentConfiguration?.involved_parties?.involved_property,
    ...incidentConfiguration?.involved_parties?.involved_generic_property,
  };

  return preparePreDefinedFields(preDefinedFields);
};

const getInvolvedPropertyValidationFields = (incidentConfiguration) => ({
  owner_contact_id: Yup.number().required('Required'),
  ...getAdditionalDataValidations(getInvolvedPropertyDefinedFields(incidentConfiguration)),
});

const InvolvedPropertyFragment = ({ disableEditIdentity, showOnly }) => {
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInvolvedPropertyDefinedFields(incidentConfiguration);

  return (
    <Grid container spacing={1}>
      <PreDefinedField id="property_description" fields={preDefinedFields} inline gridXs={12} />
      <Grid item xs={12}>
        <ContactTextFieldFormik
          id="owner_contact"
          label="Owner"
          className={classes.textField}
          fullWidth
          acceptedRoles={getAllClaimRelatedRoles(organizationContactRolesDict).filter(
            (role) => !['insured', 'witness'].includes(role)
          )}
          contactSearchProps={{ newContactRole: 'claimant' }}
          disabled={disableEditIdentity}
          showOnly={showOnly}
          fixedSearchResults
        />
      </Grid>
      <PreDefinedField id="attorney_contact_id" fields={preDefinedFields} inline fixedSearchResults />
      <PreDefinedField id="attorney_reference_number" fields={preDefinedFields} inline />
      <PreDefinedField id="insurer_contact_id" fields={preDefinedFields} inline fixedSearchResults />
      <PreDefinedField id="insurer_reference_number" fields={preDefinedFields} inline />
      <PreDefinedField id="insurer_policy_number" fields={preDefinedFields} inline />
      <Grid item xs={6} />
      <PreDefinedField id="damages" fields={preDefinedFields} inline />
      <PreDefinedField id="is_stolen" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="damages_description" fields={preDefinedFields} inline gridXs={12} />
      <Grid item xs={6} />
      <PreDefinedField id="location" fields={preDefinedFields} inline gridXs={12} />
    </Grid>
  );
};

InvolvedPropertyFragment.propTypes = {
  disableEditIdentity: PropTypes.bool,
  showOnly: PropTypes.bool,
};

export { getInvolvedPropertyValidationFields, involvedPropertyFields, InvolvedPropertyFragment };
