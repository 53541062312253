import React from 'react';

import type { DocumentMetadata } from '~/components/communications/types';
import ShowAllCollapsible from '~/components/core/Molecules/Collapsible/ShowAllCollapsible';
import DocumentLink from '~/components/Documents/DocumentLink';
import type { NoteContentProps } from '~/components/Notes/NoteContent/types';
import useOrganization from '~/components/OrganizationContext';

import Text from '../../core/TextComponents/Text';

const SingleDocumentNote: React.FC<NoteContentProps> = ({
  note,
  isCollapsible,
  isExpended,
  onCollapseClick,
  maxRows,
}) => {
  if (!note.document) {
    return <></>;
  }

  return (
    <DocumentNote
      document={note.document}
      isCollapsible={isCollapsible}
      isExpended={isExpended}
      onCollapseClick={onCollapseClick}
      maxRows={maxRows}
    />
  );
};

interface DocumentType {
  desc: string;
  is_public_option: boolean;
}

interface DocumentNoteProps extends Omit<NoteContentProps, 'note'> {
  document: DocumentMetadata;
}

export const DocumentNote: React.FC<DocumentNoteProps> = ({
  document,
  isCollapsible,
  isExpended,
  onCollapseClick,
  maxRows,
}) => {
  if (!document) {
    return <></>;
  }

  return isCollapsible ? (
    <>
      <DocumentNoteStaticSection document={document} />
      <ShowAllCollapsible
        visibleLines={maxRows || 1}
        showAsInlineButton
        isOpen={isExpended}
        onCollapseClick={onCollapseClick}
      >
        <DocumentNotePossiblyCollapsedSection document={document} />
      </ShowAllCollapsible>
    </>
  ) : (
    <>
      <DocumentNotePossiblyCollapsedSection document={document} />
      <DocumentNoteStaticSection document={document} />
    </>
  );
};

interface DocumentNoteInnerProps {
  document: DocumentMetadata;
}

const DocumentNotePossiblyCollapsedSection: React.FC<DocumentNoteInnerProps> = ({ document }) => {
  const { documentTypesDict } = useOrganization() as { documentTypesDict: { [document_type: string]: DocumentType } };

  return (
    <>
      {document.is_removed && (
        <Text
          className="mb-12"
          colorVariant={Text.COLOR_VARIANTS.ERROR}
          weight={Text.WEIGHTS.REGULAR}
          variant={Text.VARIANTS.SM}
        >
          Document was removed from claim, Reason: {document.removed_reason}
        </Text>
      )}
      <a
        href={`/api/v1/claims/${document.claim_id}/documents/${document.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Document #{document.claim_internal_id}
      </a>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
        Name: {document.document_name}
      </Text>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
        Type: {documentTypesDict?.[document.type]?.desc || document.type}
      </Text>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
        Summary: ${document.summary}
      </Text>
    </>
  );
};

const DocumentNoteStaticSection: React.FC<DocumentNoteInnerProps> = ({ document }) => {
  return (
    <div className="my-12">
      <DocumentLink text="Document details" document={document} displayLinkAsButton />
    </div>
  );
};

export default SingleDocumentNote;
