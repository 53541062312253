import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import { ClaimContextProvider } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import SendEmailCommunicationCardContainer from '~/components/communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import CommunicationPopoverLoader from '~/components/Contacts/ContactCommunications/CommunicationPopoverLoader';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { useCms } from '~/components/hooks/useCms';
import EmailIcon from '~/components/icons/notifications/EmailIcon';
import InlineIconButton from '~/components/InlineIconButton';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';
import { useFetchClaim } from '~/Utils/ClaimUtils';
import cn from '~/Utils/cn';

const SendEmailCommunicationCardContainerWithClaimContext = ({ claimId, ...props }) => {
  const [claim, isLoadingClaim, isError, onClaimUpdate] = useFetchClaim(claimId);

  return (
    <LoadingSwitch isLoading={isLoadingClaim} isError={isError} LoadingComponent={CommunicationPopoverLoader}>
      <ClaimContextProvider refreshData={onClaimUpdate} claim={claim}>
        <SendEmailCommunicationCardContainer {...props} />
      </ClaimContextProvider>
    </LoadingSwitch>
  );
};

SendEmailCommunicationCardContainerWithClaimContext.propTypes = {
  claimId: PropTypes.number,
};

const EmailCommunicationPopover = ({
  contact,
  onUpdate,
  communicationDetails = {},
  mixpanelSource,
  ignorePermissions = false,
  emailTitle,
  emailText,
  useIconButton = false,
  claimId,
}) => {
  const classes = useStyles();
  const { user, userOrganization } = useCms();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { add } = useMinimizedDialogs();

  const isMinimizedDialogsEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MINIMIZED_DIALOG_FEATURE
  );

  const sendEmailDialogProps = {
    contact,
    contactEmailId: communicationDetails.id,
    onClose: () => setIsDialogOpen(false),
    onSendEmail: () => {
      setIsDialogOpen(false);
      if (onUpdate) {
        onUpdate();
      }
    },
    emailTitle,
    emailText,
  };

  const handleOpenDialog = () => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_EMAIL_COMMUNICATION_CLICKED, { source: mixpanelSource });

    if (isMinimizedDialogsEnabled) {
      const type = 'EMAIL';
      const { handleCloseDialog } = add({
        barHeader: `New Email: ${emailTitle || contact.full_name}`,
        type,
        dialogComponent: SendEmailCommunicationCardContainerWithClaimContext,
        dialogProps: {
          ...sendEmailDialogProps,
          claimId,
          onMinimized: () => {
            mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
              source: mixpanelSource,
              type,
            });
          },
          onClose: () => handleCloseDialog(),
          onSendEmail: () => {
            handleCloseDialog();
            if (onUpdate) {
              onUpdate();
            }
          },
        },
      });
    } else {
      setIsDialogOpen(true);
    }
  };

  const isDisabled = contact.is_emailing_allowed === false || user.role.is_view_only;

  return (
    <>
      <InlineIconButton
        icon={EmailIcon}
        onClick={handleOpenDialog}
        disabled={isDisabled}
        className={cn({ [classes.hoverableIcon]: !isDisabled })}
        ignorePermissions={ignorePermissions}
        useIconButton={useIconButton}
      />
      {!isMinimizedDialogsEnabled && isDialogOpen ? (
        <SendEmailCommunicationCardContainerWithClaimContext {...sendEmailDialogProps} claimId={claimId} />
      ) : null}
    </>
  );
};

EmailCommunicationPopover.propTypes = {
  contact: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  communicationDetails: PropTypes.object,
  mixpanelSource: PropTypes.string,
  ignorePermissions: PropTypes.bool,
  useIconButton: PropTypes.bool,
  emailTitle: PropTypes.string,
  emailText: PropTypes.string,
  claimId: PropTypes.number,
};

export default EmailCommunicationPopover;
