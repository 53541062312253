import React from 'react';
import Popover from '@material-ui/core/Popover';
import { useCurrentEditor } from '@tiptap/react';

import type { SuggestionOption } from '~/components/core/Editor/types';
import DropDownIcon from '~/components/icons/editor/DropDownIcon';
import SuggestionIcon from '~/components/icons/editor/SuggestionIcon';

import ToolbarButton from '../ToolbarButton';

import SuggestionMenuItem from './SuggestionMenuItem';

interface SuggestionComponentProps {
  buttonClassName?: string;
  options: SuggestionOption[];
}

const SuggestionComponent: React.FC<SuggestionComponentProps> = ({ buttonClassName, options }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { editor } = useCurrentEditor();

  // This is a workaround to trigger the mention func.
  // Creating an Extension Extend works but throws console errors
  const triggerMentionInEditor = ({ id, label }: { id: string; label: string }) => {
    editor?.extensionManager.extensions
      .find(({ name }) => name === 'mention')
      ?.options.suggestion.command({
        editor,
        range: { from: editor.state.selection.ranges[0].$from.pos, to: editor.state.selection.ranges[0].$from.pos },
        props: { label, id },
      });
    return editor;
  };

  const selectItem = ({ id, label }: { label: string | null; id: string | null }) => {
    id && label && triggerMentionInEditor({ id, label });
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const disabled = !editor?.isEditable;

  return (
    <>
      <ToolbarButton disabled={disabled} onClick={handleClick} className={buttonClassName}>
        <div className="inline-flex items-center justify-between">
          <SuggestionIcon />
          <DropDownIcon className="ml-4 min-w-fit" size={12} />
        </div>
      </ToolbarButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {options.map(({ label, id, sub_items }) => (
          <SuggestionMenuItem key={label} id={id} label={label} subItems={sub_items} handleSelectItem={selectItem} />
        ))}
      </Popover>
    </>
  );
};

export default SuggestionComponent;
