import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const HomeIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fill={iconColor}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9887 2.90784C11.265 2.65553 11.6257 2.51562 12 2.51562C12.3743 2.51562 12.7351 2.65557 13.0115 2.90794C13.0118 2.90826 13.0122 2.90858 13.0125 2.9089L20.5106 9.72284L20.5212 9.73262C20.6694 9.87259 20.7881 10.0408 20.8703 10.2274C20.9526 10.4139 20.9966 10.615 20.9999 10.8189L21.0001 10.831L21 19.5029C21 19.9007 20.842 20.2822 20.5607 20.5635C20.2794 20.8449 19.8978 21.0029 19.5 21.0029H15C14.6022 21.0029 14.2206 20.8449 13.9393 20.5635C13.658 20.2822 13.5 19.9007 13.5 19.5029V15.0029L10.5 15.0029L10.5 19.5029C10.5 19.9007 10.342 20.2822 10.0607 20.5635C9.77935 20.8449 9.39782 21.0029 9 21.0029H4.5C4.10218 21.0029 3.72065 20.8449 3.43934 20.5635C3.15804 20.2822 3 19.9007 3 19.5029V10.8189C3.0033 10.615 3.04745 10.4139 3.12966 10.2274C3.21187 10.0408 3.33057 9.8726 3.47879 9.73262L3.48935 9.72284L10.9875 2.9089C10.9879 2.90855 10.9883 2.9082 10.9887 2.90784ZM4.50711 10.8248L11.9982 4.01731L12 4.01562L12.0018 4.01731L19.4929 10.8248C19.4949 10.827 19.4965 10.8295 19.4977 10.8323C19.499 10.8351 19.4997 10.8381 19.5 10.8412V19.5029H15V15.0029C15 14.6051 14.842 14.2235 14.5607 13.9422C14.2794 13.6609 13.8978 13.5029 13.5 13.5029H10.5C10.1022 13.5029 9.72065 13.6609 9.43934 13.9422C9.15804 14.2235 9 14.6051 9 15.0029V19.5029H4.5V10.8412C4.50026 10.8381 4.50103 10.8351 4.50229 10.8323C4.50349 10.8295 4.50512 10.827 4.50711 10.8248Z"
    />
  </SvgIcon>
);

HomeIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default HomeIcon;
