import { reduce } from 'lodash';

import { reportErrorInProductionOrThrow } from '../../../../Utils';

export const QUESTIONS = {
  YES: {
    text: 'Yes',
    answerValue: 'yes',
    value: true,
  },
  NO: {
    text: 'No',
    answerValue: 'no',
    value: false,
  },
  NA: {
    text: 'N/A',
    answerValue: 'n/a',
    value: null,
  },
};

export const answerToString = (answer) => {
  const answerValue = Object.values(QUESTIONS).find(({ value }) => value === answer);

  if (answerValue === undefined) {
    reportErrorInProductionOrThrow(`Unknown answer: ${answer}`);
    return;
  }
  return answerValue.answerValue;
};

export const answerStringToBool = (answer) =>
  Object.values(QUESTIONS).find(({ answerValue }) => answerValue === answer)?.value;

export const answersToAnswerStrings = (answers) => {
  return reduce(
    Object.keys(answers),
    (acc, currQuestionId) => ({ ...acc, [currQuestionId]: answerToString(answers[currQuestionId]) }),
    {}
  );
};

export const freeTextAnswersToAnswersStrings = (answers) => {
  return reduce(
    Object.keys(answers),
    (acc, currQuestionId) => ({ ...acc, [currQuestionId]: answers[currQuestionId] }),
    {}
  );
};
