import React from 'react';
import PropTypes from 'prop-types';
import { keyBy } from 'lodash';

import { useClaim } from '~/components/ClaimContainer';
import { FORMIK_DETAILS_FIELD_IDS } from '~/components/Contacts/UpsertContact/constants';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import { getFullDetailsTabFieldPath } from '~/components/Contacts/UpsertContact/utils';
import { MultiSelectTextFieldFormik } from '~/components/TextFieldFormik';
import { getExposuresLabels } from '~/Utils';

const Exposures = ({ disabled }) => {
  const { claim } = useClaim();
  const { originalContact } = useContact();

  if (!claim || !originalContact || !claim.contacts.some((contact) => contact.id === originalContact.id)) {
    return null;
  }

  const exposureLabels = getExposuresLabels(claim);
  const exposureLabelsDict = keyBy(exposureLabels, 'id');
  const immutableExposureIds = originalContact?.immutable_exposure_ids || [];

  return (
    <MultiSelectTextFieldFormik
      id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.EXPOSURE_IDS)}
      label="Exposure Labels"
      disabled={disabled}
      fullWidth
      renderOption={(option) => exposureLabelsDict[option]['label']}
      options={exposureLabels.map((ex) => ex.id)}
      disabledOptions={immutableExposureIds}
      renderValue={() => ''}
      withOptionChips
    />
  );
};

Exposures.propTypes = {
  disabled: PropTypes.bool,
};

export default Exposures;
