import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';
import cn from '~/Utils/cn';

import { Text } from '../../../core';
import ConfigPanel from '../../../core/ConfigPanel/ConfigPanel';
import { useCurrencyFormatter } from '../../../CurrencyFormatterContext';

import styles from '../financesScreen.module.scss';

const SumCard = ({ header, expensesAmount, indemnityAmount, className, shouldDisplayOnlyHeader }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  const labelTextProps = {
    colorVariant: Text.COLOR_VARIANTS.SECONDARY,
    weight: Text.WEIGHTS.SEMI_BOLD,
  };

  return (
    <ConfigPanel
      className={className}
      isBottomAligned
      label={
        !shouldDisplayOnlyHeader ? (
          <div className={styles.panelLabel}>
            <Grid container>
              <Grid item className={cn('inline-flex justify-end', styles.panelLabelSum, styles.withBorder)}>
                <div>
                  <Text variant={Text.VARIANTS.XXS} {...labelTextProps}>
                    Expenses
                  </Text>
                  <Text variant={Text.VARIANTS.SM} {...labelTextProps}>
                    {currencyFormatter.format(expensesAmount)}
                  </Text>
                </div>
              </Grid>
              <Grid item className={styles.panelLabelSum}>
                <div>
                  <Text variant={Text.VARIANTS.XXS} {...labelTextProps}>
                    Indemnity
                  </Text>
                  <Text variant={Text.VARIANTS.SM} {...labelTextProps}>
                    {currencyFormatter.format(indemnityAmount)}
                  </Text>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : null
      }
      mainActionChild={
        <div className={styles.panelAction}>
          <Text className="nowrap" variant={Text.VARIANTS.XXS} weight={Text.WEIGHTS.SEMI_BOLD}>
            {header}
          </Text>
          <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD}>
            {currencyFormatter.format(expensesAmount + indemnityAmount)}
          </Text>
        </div>
      }
    />
  );
};

SumCard.propTypes = {
  header: PropTypes.string,
  expensesAmount: PropTypes.number,
  indemnityAmount: PropTypes.number,
  className: PropTypes.string,
  shouldDisplayOnlyHeader: PropTypes.bool,
};

export default SumCard;
