import axios from 'axios';
import { getIn, setIn } from 'formik';
import * as Yup from 'yup';

import {
  CLAIM_DOC_OR_UPLOAD_OPTIONS,
  DOCUMENT_FIELD_IDS,
} from '~/components/Contacts/UpsertContact/UploadDocument/constants';
import { reportAxiosError } from '~/Utils';

export const getDocumentInitialValues = (documentFieldId, claim) => {
  return {
    [DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE]: claim ? '' : 'upload_file',
    [DOCUMENT_FIELD_IDS.CLAIM_ID]: claim?.id ?? '',
    [documentFieldId]: '',
    [DOCUMENT_FIELD_IDS.FILE_SIZE]: 0,
  };
};

export const getDocumentValidationSchema = (documentFieldId, isDocumentOptional, claim) => {
  if (!isDocumentOptional) {
    return {
      [DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE]: claim ? Yup.string().required('Required') : undefined,
      [documentFieldId]: Yup.number().when(DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE, {
        is: CLAIM_DOC_OR_UPLOAD_OPTIONS.CLAIM_DOCUMENT,
        then: Yup.number().required('Required'),
      }),
      [DOCUMENT_FIELD_IDS.FILE_SIZE]: Yup.number().when(DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE, {
        is: CLAIM_DOC_OR_UPLOAD_OPTIONS.UPLOAD_FILE,
        then: Yup.number().required('Required').min(1, 'Required'),
      }),
    };
  }

  return {};
};

const generateUploadUrl = async (file, contact) => {
  const uploadUrlResponse = await axios.post(`/api/v1/contacts/${contact.id}/documents/upload_url`, {
    file_size: file.size,
    file_type: file.type,
    file_name: file.name,
  });

  return uploadUrlResponse.data;
};

const uploadFileToUrl = async (file, url, setUploadPercent) => {
  const config = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadPercent(percentCompleted);
    },
  };

  await axios.put(url, file, config);
};

const uploadFile = async (tabValues, contact, setUploadPercent) => {
  let params = { ...tabValues };
  const file = getIn(tabValues, DOCUMENT_FIELD_IDS.FILE);

  const uploadUrlResponse = await generateUploadUrl(file, contact);

  const uploadUrl = uploadUrlResponse.upload_url;
  const storageFileName = uploadUrlResponse.storage_filename;

  await uploadFileToUrl(file, uploadUrl, setUploadPercent);

  params = setIn(params, DOCUMENT_FIELD_IDS.FILE, {
    [DOCUMENT_FIELD_IDS.FILE_SIZE]: file.size,
    [DOCUMENT_FIELD_IDS.FILE_TYPE]: file.type,
    [DOCUMENT_FIELD_IDS.FILE_NAME]: file.name,
    [DOCUMENT_FIELD_IDS.STORAGE_NAME]: storageFileName,
  });

  return params;
};

export const getDocumentPayload = async (tabValues, contact, setUploadPercent) => {
  try {
    let params = { ...tabValues };

    if (
      getIn(tabValues, DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE) === CLAIM_DOC_OR_UPLOAD_OPTIONS.UPLOAD_FILE &&
      getIn(tabValues, DOCUMENT_FIELD_IDS.FILE)
    ) {
      params = uploadFile(tabValues, contact, setUploadPercent);
    }
    if (
      getIn(tabValues, DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE) === CLAIM_DOC_OR_UPLOAD_OPTIONS.UPLOAD_FILE &&
      !getIn(tabValues, DOCUMENT_FIELD_IDS.FILE)
    ) {
      params = setIn(params, DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE, '');
    }

    return params;
  } catch (error) {
    await reportAxiosError(error);
    throw error;
  }
};
