import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';

import { useCurrencyFormatter } from '../../../../CurrencyFormatterContext';
import { GenericItems } from '../Items';

import {
  DIALOG_FIELDS,
  getPecuniaryItemsInitialValues,
  getPecuniaryLossPredefinedFields,
  getPecuniaryValidationFields,
  PecuniaryLossItemsFragment,
} from './PecuniaryLoss/PecuniaryLossItemsFragment';
import { GenericSectionInnerCard } from './InvolvedGenericFragment';

const LOSS_OBJECT_ID = 'pecuniary_loss';
const LOSSES_OBJECT_ID = 'pecuniary_loss.losses';

const PecuniaryLossItemsSectionFragment = ({ involvedParty, preDefinedFields }) => {
  const { values } = useFormikContext();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { currencyFormatter } = useCurrencyFormatter();
  const predefinedFields = getPecuniaryLossPredefinedFields(preDefinedFields);

  const isAllowedPecuniaryLoss = incidentConfiguration.involved_parties?.[involvedParty]?.is_allowed_pecuniary_loss;
  const fragmentId = `involved_parties.${involvedParty}.${LOSS_OBJECT_ID}`;
  const isSectionActive = get(incidentConfiguration, `${fragmentId}.active`, true);
  const isLossesActive = get(incidentConfiguration, `${fragmentId}.losses.active`, true);

  let columnsData = [
    { id: 'display_name', numeric: false, label: 'Display Name', is_visible: !!predefinedFields?.display_name },
    {
      id: 'claimed_amount',
      numeric: true,
      label: 'Claimed Amount',
      is_visible: !!predefinedFields?.claimed_amount,
      align: 'left',
      specialCell: (row) => row.claimed_amount && currencyFormatter.format(row.claimed_amount),
    },
    { id: 'claimed_loss', numeric: false, is_visible: !!predefinedFields?.claimed_loss, label: 'Claimed Loss' },
  ];

  columnsData = columnsData.filter((col) => col.is_visible !== false);

  const initialValues = getPecuniaryItemsInitialValues(preDefinedFields);
  const validationFields = getPecuniaryValidationFields(preDefinedFields);

  return (
    <GenericSectionInnerCard
      sectionTitle="Pecuniary Losses"
      isSectionAllowed={isAllowedPecuniaryLoss}
      isSectionActive={isSectionActive}
      preDefinedFields={preDefinedFields}
      enablingFieldId="is_pecuniary_loss"
      enablingValue={values?.is_pecuniary_loss}
      collapsibleTitle="Fill Other Losses Details"
    >
      {isLossesActive && (
        <GenericItems
          itemId={LOSSES_OBJECT_ID}
          columnsDataItemsValues={columnsData}
          dialogFields={DIALOG_FIELDS}
          ItemsFragment={<PecuniaryLossItemsFragment preDefinedFields={predefinedFields} />}
          itemsInitialValues={initialValues}
          itemsValidationFields={validationFields}
          itemName="loss"
        />
      )}
    </GenericSectionInnerCard>
  );
};

PecuniaryLossItemsSectionFragment.propTypes = {
  involvedParty: PropTypes.string,
  preDefinedFields: PropTypes.object.isRequired,
};

export { PecuniaryLossItemsSectionFragment };
