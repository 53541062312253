import React from 'react';

import { FORMIK_DETAILS_FIELD_IDS } from '~/components/Contacts/UpsertContact/constants';
import { TextFieldFormik } from '~/components/TextFieldFormik';

import { getFullDetailsTabFieldPath } from '../../utils';

interface NotePropsType {
  disabled?: boolean;
}

const Note: React.FC<NotePropsType> = ({ disabled }) => (
  <TextFieldFormik
    id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.NOTE)}
    label="Note"
    fullWidth
    disabled={disabled}
  />
);

export default Note;
