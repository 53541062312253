import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const CyberIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00879 11.7694C4.28193 11.4342 4.63215 11.1531 5.04889 10.9557C6.62661 10.208 7.53553 9.26851 8.09546 8.20577C8.68349 7.08969 9.00023 5.61743 9.00023 3.66667C9.00023 3.43754 9.02592 3.21441 9.07457 3H13.0002C13.1328 3 13.26 3.05268 13.3538 3.14645C13.4476 3.24021 13.5002 3.36739 13.5002 3.5V7.16875C13.5002 12.4187 9.04398 14.1562 8.15648 14.45C8.05566 14.4873 7.9448 14.4873 7.84398 14.45C7.29618 14.2687 5.38872 13.5373 4.00879 11.7694Z"
      fill={colors.lobIconSecondary}
      stroke="none"
    />
    <path
      opacity="0.2"
      d="M8 13.975C7.13735 13.6893 3 12.0601 3 7.16875V3.5H13V7.16875C13 12.0601 8.86266 13.6893 8 13.975Z"
      stroke={colors.lobIconMain}
      fill="none"
    />
    <path
      d="M2.5 7.16875V3.5C2.5 3.36739 2.55268 3.24021 2.64645 3.14645C2.74021 3.05268 2.86739 3 3 3H13C13.1326 3 13.2598 3.05268 13.3536 3.14645C13.4473 3.24021 13.5 3.36739 13.5 3.5V7.16875C13.5 12.4187 9.04375 14.1562 8.15625 14.45C8.05543 14.4873 7.94457 14.4873 7.84375 14.45C6.95625 14.1562 2.5 12.4187 2.5 7.16875Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.8714 6.76489H6.12808C6.02471 6.76489 5.94092 6.85707 5.94092 6.97077V9.85313C5.94092 9.96683 6.02471 10.059 6.12808 10.059H9.8714C9.97477 10.059 10.0586 9.96683 10.0586 9.85313V6.97077C10.0586 6.85707 9.97477 6.76489 9.8714 6.76489Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.76465 6.76473V5.94121C6.76465 5.72279 6.8948 5.51332 7.12646 5.35888C7.35812 5.20444 7.67232 5.11768 7.99994 5.11768C8.32756 5.11768 8.64176 5.20444 8.87343 5.35888C9.10509 5.51332 9.23524 5.72279 9.23524 5.94121V6.76473"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default CyberIcon;
