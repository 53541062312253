import { useMemo } from 'react';
import _ from 'lodash';

import useCachedDataFetcher from '~/components/hooks/useCachedDataFetcher';
import { useCms } from '~/components/hooks/useCms';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

interface License {
  id: number;
  api_uid: string;
  user_id: string;
  license_id: string;
  effective_date: Date;
  expiration_date: Date;
  state: string;
  expiration_notification_id: number;
  is_residency_license: boolean;
}

interface AdjusterUser {
  id: string;
  title: string;
  username: string;
  email: string;
  is_removed: boolean;
  role_type: string;
  sub_organization_ids: number[];
  licenses: License[];
  is_active: boolean;
  is_sys_config_editor: boolean;
  is_sys_config_editor_by_permission: boolean;
}

interface IFetchAdjusters {
  isLoadingPossibleAdjusters: boolean;
  isErrorPossibleAdjusters: boolean;
  possibleAdjustersWithRemoved: AdjusterUser[];
  possibleAdjustersWithDaSpecialist: AdjusterUser[];
  possibleAdjustersWithoutDaSpecialist: AdjusterUser[];
}

export default function useFetchAdjusters(organizationId: string | number, shouldFetch?: boolean): IFetchAdjusters {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();

  const {
    isLoading,
    isError,
    data: possibleAdjustersWithLicenses,
  } = useCachedDataFetcher({
    url: `/api/v1/organizations/${organizationId}/possible_adjusters_with_licenses`,
    shouldUseCache: isFeatureEnabled(
      userOrganization,
      CONFIGURATION_FEATURES_NAMES.PERFORMANCE_CACHE_OPERATIONAL_DETAILS
    ),
    localCacheKey: `possible_adjusters_with_licenses_${organizationId}`,
    shouldFetch,
  });

  const { possibleAdjustersWithRemoved, possibleAdjustersWithDaSpecialist, possibleAdjustersWithoutDaSpecialist } =
    useMemo(() => {
      const possibleAdjustersWithRemoved = _.orderBy(possibleAdjustersWithLicenses, ['username']);
      const possibleAdjustersWithDaSpecialist = possibleAdjustersWithRemoved.filter((adjuster) => !adjuster.is_removed);

      const possibleAdjustersWithoutDaSpecialist = possibleAdjustersWithDaSpecialist.filter(
        (adjuster) => adjuster.role_type === 'claim_rep'
      );
      return {
        possibleAdjustersWithRemoved,
        possibleAdjustersWithDaSpecialist,
        possibleAdjustersWithoutDaSpecialist,
      };
    }, [possibleAdjustersWithLicenses]);

  return {
    isLoadingPossibleAdjusters: isLoading,
    isErrorPossibleAdjusters: isError,
    possibleAdjustersWithRemoved,
    possibleAdjustersWithDaSpecialist,
    possibleAdjustersWithoutDaSpecialist,
  };
}
