import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const InvolvedCyberIcon = ({ ...props }) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    stroke="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.86612 3.49112C3.10054 3.2567 3.41848 3.125 3.75 3.125H16.25C16.5815 3.125 16.8995 3.2567 17.1339 3.49112C17.3683 3.72554 17.5 4.04348 17.5 4.375V8.96094C17.5 15.9521 11.5645 18.266 10.4004 18.653C10.1415 18.7457 9.85847 18.7457 9.59958 18.653C8.43552 18.266 2.5 15.9521 2.5 8.96094V4.375C2.5 4.04348 2.6317 3.72554 2.86612 3.49112ZM16.25 4.375L3.75 4.375L3.75 8.96094C3.75 15.0751 8.92168 17.1117 10 17.4688C11.0783 17.1117 16.25 15.0751 16.25 8.96094V4.375Z"
      fill="#202020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.375 9.5C6.375 9.15482 6.65482 8.875 7 8.875H12.625C12.9702 8.875 13.25 9.15482 13.25 9.5V12.9375C13.25 13.2827 12.9702 13.5625 12.625 13.5625H7C6.65482 13.5625 6.375 13.2827 6.375 12.9375V9.5ZM7.625 10.125V12.3125H12V10.125H7.625Z"
      fill="#202020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.8125 7.625C9.56386 7.625 9.3254 7.72377 9.14959 7.89959C8.97377 8.0754 8.875 8.31386 8.875 8.5625V9.5C8.875 9.84518 8.59518 10.125 8.25 10.125C7.90482 10.125 7.625 9.84518 7.625 9.5V8.5625C7.625 7.98234 7.85547 7.42594 8.2657 7.0157C8.67594 6.60547 9.23234 6.375 9.8125 6.375C10.3927 6.375 10.9491 6.60547 11.3593 7.0157C11.7695 7.42594 12 7.98234 12 8.5625V9.5C12 9.84518 11.7202 10.125 11.375 10.125C11.0298 10.125 10.75 9.84518 10.75 9.5V8.5625C10.75 8.31386 10.6512 8.0754 10.4754 7.89959C10.2996 7.72377 10.0611 7.625 9.8125 7.625Z"
      fill="#202020"
    />
  </SvgIcon>
);

InvolvedCyberIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default InvolvedCyberIcon;
