import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, getIn } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../Utils';

import CardDialog from './CardDialog';
import CheckboxFormik from './CheckboxFormik';
import FileDropZone from './FileDropZone';
import { TextFieldFormik } from './TextFieldFormik';

import { useStyles } from './../assets/styles';

const UploadFileDialog = ({
  open,
  onClose,
  onUpload,
  onUploadError,
  title,
  postUrl,
  withIsIncoming,
  withClaimIdDisplay,
  upperExplanation,
  withReceivingEmailAddress,
  withUuid,
  openDialogError = true,
}) => {
  const classes = useStyles();
  const [uploadPercent, setUploadPercent] = React.useState(0);

  return (
    <Formik
      initialValues={{
        file_name: '',
        file: undefined,
        file_size: 0,
        is_incoming: withIsIncoming, // true if withIsIncoming is on, undefined otherwise
        claim_id_display: withClaimIdDisplay && '', // '' if withClaimIdDisplay is on, undefined otherwise
        receiving_email_address: withReceivingEmailAddress && '',
        email_uuid: uuidv4(),
      }}
      validationSchema={Yup.object().shape({
        file_size: Yup.number().required('Required').min(1, 'File size has to be larger then 0.'), // we prefer validating file_size to file since we can vouch for its value
      })}
      enableReinitialize
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const config = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadPercent(percentCompleted);
          },
        };

        const formData = new FormData();
        formData.append('file', values['file']);
        formData.append('file_name', values['file_name']);
        withIsIncoming && formData.append('is_incoming', values['is_incoming']);
        withClaimIdDisplay && formData.append('claim_id_display', values['claim_id_display']);
        withReceivingEmailAddress && formData.append('receiving_email_address', values['receiving_email_address']);
        withUuid && formData.append('email_uuid', values['email_uuid']);
        try {
          let response;
          if (postUrl) {
            response = await axios.post(postUrl, formData, config);
          }

          await onUpload(response);
          resetForm();
        } catch (error) {
          reportAxiosError(error, openDialogError);
          onUploadError && (await onUploadError(error?.response));
          setSubmitting(false);
        }
        setUploadPercent(0);
      }}
    >
      {({ isSubmitting, handleSubmit, errors, values, touched, setFieldValue, setFieldTouched }) => {
        const onFileSelect = (file) => {
          setFieldValue('file', file);
          setFieldTouched('file', true);
          setFieldValue('file_name', file.name);
          setFieldTouched('file_name', true);
          setFieldValue('file_size', Number(file.size));
          setFieldTouched('file_size', true);
        };

        const onCancelFileSelect = () => {
          setFieldValue('file', undefined);
          setFieldValue('file_name', undefined);
          setFieldValue('file_size', undefined);
        };

        return (
          <CardDialog open={open} isDialog title={title} onClose={onClose} maxWidth="sm" fullWidth>
            <Grid container spacing={1}>
              {upperExplanation}
              <Grid item xs={12}>
                <FileDropZone
                  onFileSelect={onFileSelect}
                  onCancelFileSelect={onCancelFileSelect}
                  uploadPercent={uploadPercent}
                  file={values['file']}
                  error={getIn(values, 'file_size') <= 0 && getIn(errors, 'file_size') && getIn(touched, 'file_size')}
                  errorMessage={getIn(errors, 'file_size')}
                />
              </Grid>
              {withIsIncoming && (
                <Grid item xs={12}>
                  <CheckboxFormik id="is_incoming" label="Incoming" fullWidth />
                </Grid>
              )}
              {withClaimIdDisplay && (
                <Grid item xs={12}>
                  <TextFieldFormik id="claim_id_display" label="Claim Number" fullWidth />
                </Grid>
              )}
              {withReceivingEmailAddress && (
                <Grid item xs={12}>
                  <TextFieldFormik id="receiving_email_address" label="Receiving email address" fullWidth />
                </Grid>
              )}
              <Grid item xs={12}>
                <div className={classes.buttonsContainer}>
                  <CancelButton disabled={isSubmitting} onClick={onClose} />
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    Upload
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

UploadFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  onUploadError: PropTypes.func,
  title: PropTypes.string.isRequired,
  postUrl: PropTypes.string,
  withIsIncoming: PropTypes.bool,
  withClaimIdDisplay: PropTypes.bool,
  upperExplanation: PropTypes.node,
  withReceivingEmailAddress: PropTypes.bool,
  withUuid: PropTypes.bool,
  openDialogError: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UploadFileDialog;
