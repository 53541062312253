/**
 * This is a POC component for creating a new workbook in Tableau - it will be deleted soon
 */
import React from 'react';
import PropTypes from 'prop-types';

import useTableauOld from '~/components/hooks/useTableauOld';
import useOrganization from '~/components/OrganizationContext';

const OPTIONS = {
  width: `${window.innerWidth - 80}px`,
  height: `${window.innerHeight - 115}px`,
  hideToolbar: true,
};
export const NewWorkbookCreator = ({ options }) => {
  const { orgTableauConfigs } = useOrganization();
  const { host_url, target_site, self_service_datasource } = orgTableauConfigs;
  const selfServiceUrl = `${host_url}/t/${target_site}/authoringNewWorkbook/${crypto.randomUUID()}/${self_service_datasource}`;
  // const selfServiceUrl = `${host_url}/askData/lens/InshurUSDWH2`;
  // const selfServiceUrl = `${host_url}/t/${target_site}/newWorkbook/${crypto.randomUUID()}`;
  const { tableauRef } = useTableauOld({ selfServiceUrl, options });

  return (
    <div>
      <div ref={tableauRef} />
    </div>
  );
};

NewWorkbookCreator.propTypes = {
  options: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    hideTabs: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    onFirstInteractive: PropTypes.func,
  }),
};

NewWorkbookCreator.defaultProps = {
  options: OPTIONS,
};
