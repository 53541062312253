import React from 'react';
import { Button as MuiButton } from '@material-ui/core';

/**
 This Component is a wrapper on MUI component.
 This component should be imported and not MUI directly!
**/
interface ClassKeyValue {
  [name: string]: string;
}

export interface ButtonProps {
  // Add any additional custom props here
  className?: string;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  startIcon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  key?: React.Key;
  component?: React.ElementType;
  to?: string;
  disableRipple?: boolean;
  classes?: ClassKeyValue;
  from?: string;
  endIcon?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  size?: 'small' | 'medium' | 'large';
  autoFocus?: boolean;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  href?: string;
  children: React.ReactNode;
  target?: React.HTMLAttributeAnchorTarget;
}

const Button: React.FC<ButtonProps> = ({ children, ...buttonProps }) => {
  return <MuiButton {...buttonProps}>{children}</MuiButton>;
};

export default Button;
