import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Checkbox } from '@material-ui/core';
import { noop } from 'lodash';

import cn from '~/Utils/cn';

import { ErrorIcon } from '../../icons';

import colors from '../../../assets/colors.module.scss';
import styles from './index.module.scss';

export const CHECK_BANNER_ALLOWED_COLORS = {
  BUTTON_LINK: styles.withButtonLinkColor,
};

const CheckBanner = ({
  note,
  withIcon = false,
  withBold = false,
  onClick = noop,
  checked = undefined,
  disabled = false,
  cardClassName,
  checkboxClassNames,
  wrapperClassNames,
}) => {
  const icon = withIcon ? <ErrorIcon size={20} iconColor={colors.textPrimary} /> : undefined;
  const cardClassNames = cn(styles.checkBannerCard, { [styles.disabled]: disabled }, wrapperClassNames);
  const textClassNames = cn(styles.textContainer, { [styles.withBold]: withBold });
  const checkboxClassName = cn(styles.checkContainer, { [styles.checked]: checked }, checkboxClassNames);
  const cardContentClassName = cn(styles.checkBannerCardContent, cardClassName);

  return (
    <Card className={cardClassNames} onClick={onClick}>
      <CardContent className={cardContentClassName}>
        <div className={styles.innerContainer}>
          <div className={textClassNames}>
            <Checkbox onClick={onClick} checked={checked} disabled={disabled} className={checkboxClassName} />
            {note}
          </div>
          {icon && <div className={styles.iconContainer}>{icon}</div>}
        </div>
      </CardContent>
    </Card>
  );
};

CheckBanner.propTypes = {
  note: PropTypes.string.isRequired,
  withIcon: PropTypes.bool,
  withBold: PropTypes.bool,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  cardClassName: PropTypes.string,
  checkboxClassNames: PropTypes.string,
  wrapperClassNames: PropTypes.string,
};

export default CheckBanner;
