import React, { useState } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Paper, Tab, Tabs } from '@material-ui/core';
import urljoin from 'url-join';

import { isActionAllowed } from '~/components/core/Permissions/PermissionUtils';

import { onTabClick } from '../../../components/ReportsDashboardsPage/ReportsDashboardUtils';
import { MIXPANEL_EVENTS } from '../../../pocs/mixpanel';
import { getCurrentTabValue, getTabName } from '../../../Utils';
import CardDialog from '../../CardDialog';
import mixpanel from '../../CmsMain/mixpanel';
import { useCms } from '../../hooks/useCms';
import { TableauExportActions } from '../TableauExportActions/TableauExportActions';

import { useStyles } from '../../../assets/styles';

export const HeaderTabs = ({ tabs }) => {
  const match = useRouteMatch();
  const classes = useStyles();
  const [{ exportTableauView }, setExportTableauView] = useState({});
  const isDashboardView = match.url.includes('dashboards') || match.url.includes('resilience_package');
  const { userProfile, userOrganization } = useCms();
  const allowedTabs = React.useMemo(
    () =>
      tabs.filter((tab) => {
        return isActionAllowed({
          action: tab.props?.action,
          verb: tab.props?.verb,
          profile: userProfile,
          userOrganization,
        });
      }),
    [tabs, userProfile, userOrganization]
  );

  const onFilterApplied = (filterName, filteredValues) => {
    const { parentTab, tabName } = getTabName();

    mixpanel.track(MIXPANEL_EVENTS.REPORTING_ENTITY_FILTER_APPLIED, {
      reporting_entity: parentTab,
      tab_name: tabName,
      filter_name: filterName,
      filter_value: JSON.stringify(filteredValues),
    });
  };

  return (
    <>
      <CardDialog noCardTitle height="100%">
        <Paper className={classes.reportsDashboardHeaderTab}>
          <Tabs
            value={getCurrentTabValue({ tabs: allowedTabs, match })}
            variant="standard"
            indicatorColor="primary"
            textColor="primary"
            style={{ minHeight: 40 }}
          >
            {allowedTabs.map((tab) => (
              <Tab
                key={tab.url}
                style={{ minHeight: 40, margin: 0, minWidth: 'auto', padding: 0 }}
                classes={{
                  wrapper: 'px-16 text-base',
                }}
                label={tab.label}
                component={Link}
                to={`${match.url}/${tab.url}`}
                onClick={onTabClick}
              />
            ))}
          </Tabs>
          <TableauExportActions exportTableauView={exportTableauView} isDashboardView={isDashboardView} />
        </Paper>
        <div className={classes.pageBody}>
          <Switch>
            <Redirect exact from={`${match.path}/`} to={urljoin(match.path, allowedTabs?.[0]?.url)} />
            {allowedTabs.map((tab) => (
              <Route
                key={tab.url}
                path={urljoin(match.path, tab.url)}
                render={(routerProps) => {
                  return (
                    <div style={{ padding: '24px 0px' }}>
                      <tab.component
                        classes={classes}
                        {...routerProps}
                        {...tab.props}
                        onLoad={setExportTableauView}
                        onFilterApplied={onFilterApplied}
                      />
                    </div>
                  );
                }}
              />
            ))}
            <Redirect to={match.path} />
          </Switch>
        </div>
      </CardDialog>
    </>
  );
};

HeaderTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      props: PropTypes.shape({
        action: PropTypes.string,
        verb: PropTypes.string,
      }),
    })
  ),
};

HeaderTabs.defaultProps = {
  tabs: [],
};
