import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import cn from '../../../../Utils/cn';

export interface MenuButtonProps {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
  disabled?: boolean;
  disabledHoverEffect?: boolean;
}

const ToolbarButton: React.FC<MenuButtonProps> = ({
  className,
  onClick,
  isActive,
  disabled,
  disabledHoverEffect,
  children,
}) => {
  const { editor } = useCurrentEditor();
  const disableButton = !editor?.isEditable || disabled;

  return (
    <button
      className={cn(
        'm-4 inline-flex items-center rounded border-0 bg-transparent',
        {
          'bg-slate-400': isActive && !disableButton,
          'opacity-50': disableButton,
          'hover:bg-slate-400 hover:bg-opacity-50': !disabledHoverEffect && !disableButton,
          'cursor-pointer': !disableButton,
        },
        className
      )}
      onClick={onClick}
      disabled={disableButton}
    >
      {children}
    </button>
  );
};

export default ToolbarButton;
