import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import cn from '~/Utils/cn';

import { InfoIcon } from '../../icons';

import { useStyles } from '../../../assets/styles';

const InnerCard = ({ className, titleClassName, title, tooltipContent, children }) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.innerCard, className)}>
      {title && (
        <div className={cn(classes.innerCardTitle, titleClassName)}>
          <Typography
            display="block"
            variant="subtitle1"
            style={{ marginRight: '10px', fontWeight: 550, letterSpacing: '0px' }}
          >
            {title}
          </Typography>

          {tooltipContent && (
            <Tooltip placement="top-start" arrow title={tooltipContent}>
              <span style={{ height: '24px', display: 'flex', alignItems: 'center' }}>
                <InfoIcon size={20} />
              </span>
            </Tooltip>
          )}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

InnerCard.propTypes = {
  title: PropTypes.string,
  tooltipContent: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
};

export default InnerCard;
