import type { SyntheticEvent } from 'react';
import React from 'react';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useFormikContext } from 'formik';
import { noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import { useClaim } from '~/components/ClaimContainer';
import { getAllSearchableContactRoles } from '~/components/communications/ContactUtils';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import MenuItem from '~/components/core/Atomic/MenuItem';
import type { MoiFormikValues, MoiStatuses, MoiStepProps } from '~/components/exposures/moi/MoiStepper/types';
import { LossLocationTextFieldFormik } from '~/components/GlobalLossLocation';
import useOrganization from '~/components/OrganizationContext';
import TextFieldFormik, { DatePickerTextFieldFormik } from '~/components/TextFieldFormik';
import MOI_STATUSES from '~/server_shared/generated-types/MOI_STATUSES';

interface AssignmentProgressStepProps extends MoiStepProps {
  isEditMode?: boolean;
  setEdited?: (edited: boolean) => void;
}

const AssignmentProgressStep: React.FC<AssignmentProgressStepProps> = ({
  isVendorApi,
  isEditMode,
  setEdited = noop,
}) => {
  const { values, isSubmitting, setFieldValue, setFieldTouched } = useFormikContext<MoiFormikValues>();
  const { claim } = useClaim();
  const { organizationContactRolesDict }: { organizationContactRolesDict?: { [key: string]: unknown } } =
    useOrganization();
  const classes = useStyles();

  const statuses: MoiStatuses = { ...MOI_STATUSES };
  if (!isVendorApi) {
    delete statuses['completed'];
    delete statuses['accepted'];
  }

  return (
    <div className="grid grid-cols-2 items-end gap-x-32 gap-y-12">
      <div>
        <DatePickerTextFieldFormik
          id="assignment_date"
          label="Assignment Date"
          className={classes.textField}
          fullWidth
          disabled={isSubmitting || !values['moi_method_id'] || isEditMode}
        />
      </div>
      <div>
        <DatePickerTextFieldFormik
          id="due_datetime"
          label="Due Date"
          className={classes.textField}
          fullWidth
          disabled={isSubmitting || (!values['moi_method_id'] && !values['method'])}
          onChange={(date: MaterialUiPickersDate | null) => {
            if (date && isEditMode) {
              setEdited(true);
            }
            setFieldValue('due_datetime', date ? date.format('YYYY-MM-DD') : '');
            setFieldTouched('due_datetime', true);
          }}
        />
      </div>
      <div className="col-span-2">
        <TextFieldFormik
          id="special_instructions"
          label="Special Instructions"
          placeholder="Add special instructions to the assignee"
          className={classes.textField}
          disabled={isSubmitting || !values['moi_method_id'] || isEditMode}
          fullWidth
          multiline
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rows="2"
        />
      </div>
      <div>
        <ContactTextFieldFormik
          id="scheduling_inspection_contact"
          label="Contact for scheduling inspection"
          showOnly={isSubmitting || !values['moi_method_id'] || isEditMode}
          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
          fullWidth
          fixedSearchResults
          nullIfEmpty
        />
      </div>
      <div>
        <LossLocationTextFieldFormik
          id="inspection_location"
          label="Inspection Location"
          flatten
          withIsHighway={claim.lob === 'auto_claim'}
          disabled={isSubmitting || !values['moi_method_id'] || isEditMode}
          withClearButton
        />
      </div>
      <div>
        <TextFieldFormik
          id="status"
          label="Assignment Status"
          className={classes.textField}
          fullWidth
          disabled={isSubmitting || (!values['moi_method_id'] && !values['method'])}
          select
          onChange={(e: Event) => {
            const value = (e.target as HTMLInputElement).value;
            setFieldValue('status', value);
            setFieldTouched('status', true);

            if (!['done', 'completed'].includes(value)) {
              setFieldValue('completion_datetime', undefined);
            }

            if (value !== 'rejected') {
              setFieldValue('reject_reason', '');
              setFieldTouched('reject_reason', false);
            }

            if (isEditMode) {
              setEdited(true);
            }
          }}
        >
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          {Object.entries(statuses).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </div>
      <div>
        <DatePickerTextFieldFormik
          id="completion_datetime"
          label="Closing Date"
          className={classes.textField}
          fullWidth
          disabled={
            isSubmitting ||
            (!values['moi_method_id'] && !values['method']) ||
            !['done', 'rejected', 'completed', 'called_off'].includes(values['status'])
          }
          onChange={(date: MaterialUiPickersDate | null) => {
            if (date && isEditMode) {
              setEdited(true);
            }

            setFieldValue('completion_datetime', date ? date.format('YYYY-MM-DD') : '');
            setFieldTouched('completion_datetime', true);
          }}
        />
      </div>
      <div className="col-span-2">
        <TextFieldFormik
          id="reject_reason"
          label="Reject Reason"
          className={classes.textField}
          disabled={isSubmitting || values['status'] !== 'rejected'}
          fullWidth
        />
      </div>
      <div className="col-span-2">
        <TextFieldFormik
          id="note"
          label="Note"
          className={classes.textField}
          disabled={isSubmitting || !values['moi_method_id']}
          onChange={(e: SyntheticEvent) => {
            if (isEditMode) {
              setEdited(true);
            }

            setFieldValue('note', (e.target as HTMLInputElement).value);
            setFieldTouched('note', true);
          }}
          fullWidth
          multiline
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rows="2"
        />
      </div>
    </div>
  );
};

export default AssignmentProgressStep;
