import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const SmsNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      d="M30.25 14.6875H13.75C13.4019 14.6875 13.0681 14.8258 12.8219 15.0719C12.5758 15.3181 12.4375 15.6519 12.4375 16V31C12.4362 31.2502 12.507 31.4956 12.6415 31.7066C12.776 31.9176 12.9685 32.0853 13.196 32.1897C13.3696 32.2702 13.5586 32.3121 13.75 32.3125C14.0592 32.3127 14.3584 32.2027 14.5938 32.0022L14.6022 31.9947L17.6153 29.3538C17.6491 29.3267 17.6911 29.3121 17.7344 29.3125H30.25C30.5981 29.3125 30.932 29.1742 31.1781 28.9281C31.4242 28.6819 31.5625 28.3481 31.5625 28V16C31.5625 15.6519 31.4242 15.3181 31.1781 15.0719C30.932 14.8258 30.5981 14.6875 30.25 14.6875ZM30.4375 28C30.4375 28.0497 30.4178 28.0974 30.3826 28.1326C30.3474 28.1677 30.2997 28.1875 30.25 28.1875H17.7344C17.4256 28.1879 17.1269 28.2971 16.8906 28.4959L16.8822 28.5034L13.8672 31.1444C13.8398 31.1663 13.8067 31.1802 13.7718 31.1842C13.7369 31.1883 13.7015 31.1825 13.6697 31.1675C13.6379 31.1524 13.6111 31.1287 13.5921 31.0991C13.5731 31.0695 13.5629 31.0352 13.5625 31V16C13.5625 15.9503 13.5823 15.9026 13.6174 15.8674C13.6526 15.8323 13.7003 15.8125 13.75 15.8125H30.25C30.2997 15.8125 30.3474 15.8323 30.3826 15.8674C30.4178 15.9026 30.4375 15.9503 30.4375 16V28ZM25.5625 20.5C25.5625 20.6492 25.5033 20.7923 25.3978 20.8977C25.2923 21.0032 25.1492 21.0625 25 21.0625H19C18.8508 21.0625 18.7078 21.0032 18.6023 20.8977C18.4968 20.7923 18.4375 20.6492 18.4375 20.5C18.4375 20.3508 18.4968 20.2077 18.6023 20.1023C18.7078 19.9968 18.8508 19.9375 19 19.9375H25C25.1492 19.9375 25.2923 19.9968 25.3978 20.1023C25.5033 20.2077 25.5625 20.3508 25.5625 20.5ZM25.5625 23.5C25.5625 23.6492 25.5033 23.7923 25.3978 23.8977C25.2923 24.0032 25.1492 24.0625 25 24.0625H19C18.8508 24.0625 18.7078 24.0032 18.6023 23.8977C18.4968 23.7923 18.4375 23.6492 18.4375 23.5C18.4375 23.3508 18.4968 23.2077 18.6023 23.1023C18.7078 22.9968 18.8508 22.9375 19 22.9375H25C25.1492 22.9375 25.2923 22.9968 25.3978 23.1023C25.5033 23.2077 25.5625 23.3508 25.5625 23.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default SmsNoteIcon;
