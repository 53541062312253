import PropTypes from 'prop-types';

import { useHasPermission } from '../../hooks/useHasPermission';

const PermissionsRenderWrapper = ({ action, verb, useDefaults, children }) => {
  const hasPermission = useHasPermission({ action, verb });

  if (useDefaults) {
    return hasPermission ? children : null;
  }

  return hasPermission || (!action && !verb) ? children : null;
};

PermissionsRenderWrapper.propTypes = {
  action: PropTypes.string,
  verb: PropTypes.string,
  children: PropTypes.node.isRequired,
  useDefaults: PropTypes.bool,
};

export default PermissionsRenderWrapper;
