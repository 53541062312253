import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';

import { TrashIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';

export const DeleteIconButton = ({ onClick, index }) => {
  const classes = useStyles();

  return (
    <InlineIconButton
      icon={TrashIcon}
      tooltipTitle="Remove"
      iconColor="currentColor"
      className={`${classes.textIcon} ${classes.hoverableIconNew}`}
      onClick={() => onClick(index)}
      wrapWithSpan
    />
  );
};

DeleteIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
