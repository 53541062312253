import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';

import { FNOL_MIXPANEL_EVENTS } from '../../pocs/mixpanel';
import mixpanel from '../CmsMain/mixpanel';
import { FsButton } from '../core';
import useOrganization from '../OrganizationContext';
import LobRadioFormik from '../TPA/LOB/LobRadioFormik';
import SubOrganizationSelectFormik from '../TPA/SubOrganizations/SubOrganizationSelectFormik';

/**
 * @callback onTakeLossCallback
 * @param {object} _
 * @param {string} _.subOrgId - sub-organization id
 * @param {string} _.lob - line of business
 */

/**
 * Loss Without Policy Card
 * @param {onTakeLossCallback} onTakeLoss
 * @returns {JSX.Element}
 */
const LossWithoutPolicyCard = ({ onTakeLoss }) => {
  const { subOrganizationEnabled } = useOrganization();

  const initialValues = {
    lob: '',
    sub_organization_id: '',
  };

  return (
    <CollapsibleWrapper
      onCollapse={() => mixpanel.track(FNOL_MIXPANEL_EVENTS.FNOL_TAKE_LOSS_WITHOUT_POLICY_CLICKED_FNOL)}
      actionCard
      title="Take Loss Without Policy"
      noBorderLine
      backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object().shape({
          sub_organization_id: subOrganizationEnabled
            ? Yup.string().required('Select a sub-organization')
            : Yup.string().nullable(),
          lob: Yup.string().required('Select line of business'),
        })}
        onSubmit={(values) => {
          onTakeLoss({ subOrgId: values.sub_organization_id, lob: values.lob });
        }}
      >
        {(formikProps) => {
          const { handleSubmit, values } = formikProps;
          return (
            <Grid container direction="column" spacing={4}>
              {subOrganizationEnabled && (
                <Grid item xs={2}>
                  <SubOrganizationSelectFormik
                    fieldId="sub_organization_id"
                    formatEntry={getFormatSubOrganizationEntryFunc()}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <LobRadioFormik
                  subOrganizationIds={values.sub_organization_id ? [values.sub_organization_id] : []}
                  lobFieldId="lob"
                  label="Select line of business"
                />
              </Grid>
              <Grid item xs={12}>
                <FsButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSubmit();
                    mixpanel.track(FNOL_MIXPANEL_EVENTS.TAKE_A_LOSS_CLICKED_NO_POLICY_FNOL);
                  }}
                >
                  TAKE A LOSS
                </FsButton>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </CollapsibleWrapper>
  );
};

const getFormatSubOrganizationEntryFunc =
  () =>
  ({ name, description }) => {
    return `${name} ${description ? `(${description})` : ''}`;
  };

LossWithoutPolicyCard.propTypes = {
  onTakeLoss: PropTypes.func.isRequired,
};

export default LossWithoutPolicyCard;
