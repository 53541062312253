import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';

import Typography from '~/components/core/Atomic/Typography';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';

const DeprecatedMinimizedCard = ({ onClick, communication, isCallActive, callDurationSec, secString }) => {
  return (
    <Paper
      style={{
        width: '250px',
        maxWidth: '250px',
        height: '60px',
        position: 'fixed',
        bottom: '50px',
        right: '30px',
        zIndex: 2,
      }}
      elevation={6}
      onClick={onClick}
    >
      <div style={{ padding: '8px' }}>
        {communication.contact ? (
          <OverflowTextWithToolTip maxWidth={220}>
            {isCallActive ? 'Active call' : 'Call ended'} with <strong>{communication.contact.full_name}</strong>
          </OverflowTextWithToolTip>
        ) : (
          `${isCallActive ? 'Active call' : 'Call ended'} with ${communication.specific_identifier}`
        )}
        <Typography variant="subtitle2">
          {Math.floor(callDurationSec / 60)}:{secString}
        </Typography>
      </div>
    </Paper>
  );
};

DeprecatedMinimizedCard.propTypes = {
  onClick: PropTypes.func,
  communication: PropTypes.object,
  isCallActive: PropTypes.bool,
  callDurationSec: PropTypes.number,
  secString: PropTypes.string,
};

export default DeprecatedMinimizedCard;
