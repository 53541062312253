import React from 'react';
import PropTypes from 'prop-types';

import cn from '~/Utils/cn';

import { Text, TooltipIcon } from '../../core';
import { WarningIcon } from '../../icons';

import styles from './dotWithText.module.scss';

const DOT_TYPES = {
  NO_INFO: 'no_info',
  ERROR: 'error',
  WARNING: 'warning',
  PENDING: 'pending',
  SUCCESS: 'success',
};

const TYPE_TO_STYLE = {
  [DOT_TYPES.NO_INFO]: styles.dotTypeNoInfo,
  [DOT_TYPES.ERROR]: styles.dotTypeError,
  [DOT_TYPES.WARNING]: styles.dotTypeWarning,
  [DOT_TYPES.PENDING]: styles.dotTypePending,
  [DOT_TYPES.SUCCESS]: styles.dotTypeSuccess,
};

const DotWithText = ({
  dotType,
  primaryText,
  secondaryText,
  iconElement,
  showDot,
  showWarning,
  iconTooltip,
  onHoverWarningIcon,
}) => {
  const dotClassnames = cn({ [styles.dot]: showDot }, TYPE_TO_STYLE[dotType]);

  return (
    <div className={styles.container}>
      {showDot && iconTooltip ? (
        <TooltipIcon title={iconTooltip}>
          <div className={dotClassnames} />
        </TooltipIcon>
      ) : showDot ? (
        <div className={dotClassnames} />
      ) : null}
      {showWarning
        ? iconTooltip && (
            <div onMouseEnter={onHoverWarningIcon}>
              <TooltipIcon title={iconTooltip}>
                <WarningIcon />
              </TooltipIcon>
            </div>
          )
        : null}
      <div className={styles.textContainer}>
        {primaryText && (
          <Text variant={Text.VARIANTS.SM} className="whitespace-nowrap">
            {primaryText}
          </Text>
        )}
        {secondaryText && (
          <Text variant={Text.VARIANTS.SM} className={styles.secondaryText}>
            {secondaryText}
          </Text>
        )}
        {iconElement}
      </div>
    </div>
  );
};

DotWithText.propTypes = {
  dotType: PropTypes.oneOf(Object.values(DOT_TYPES)),
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  iconElement: PropTypes.element,
  showDot: PropTypes.bool,
  showWarning: PropTypes.bool,
  iconTooltip: PropTypes.string,
  onHoverWarningIcon: PropTypes.func,
};

DotWithText.TYPES = DOT_TYPES;

export default DotWithText;
