const BIKE_INCIDENTS_DICT = {
  theft: {
    desc: 'Theft',
    sub_types: {
      total_theft: {
        desc: 'Total Theft',
      },
      theft_attempt: {
        desc: 'Theft Attempt',
      },
      bike_found_back_thief_unknown: {
        desc: 'Bike Found Back - Thief unknown',
      },
      bike_found_back_thief_known: {
        desc: 'Bike Found Back - Thief Known',
      },
      theft_battery: {
        desc: 'Battery Theft',
      },
      Unknown: {
        desc: 'Unknown',
      },
    },
  },
  material_damage: {
    desc: 'Material Damage',
    sub_types: {
      vandalism: {
        desc: 'Vandalism',
      },
      weather_damage: {
        desc: 'Weather Damage',
      },
      accident_with_a_third_party: {
        desc: 'Accident With a Third Party',
      },
      accident_alone: {
        desc: 'Accident Alone',
      },
      other: {
        desc: 'Other',
      },
      unknown: {
        desc: 'Unknown',
      },
    },
  },
  emergency: {
    desc: 'Emergency',
    sub_types: {
      after_accident: {
        desc: 'After Accident',
      },
      after_theft: {
        desc: 'After Theft',
      },
      after_vandalism: {
        desc: 'After vandalism',
      },
      bodily_injury_consecutive_of_the_use_of_the_bike: {
        desc: 'Bodily Injury Consecutive of The Use of The Bike',
      },
      unknown: {
        desc: 'Unknown',
      },
    },
  },
} as const;

export default BIKE_INCIDENTS_DICT;
