import React from 'react';
import PropTypes from 'prop-types';

import { FsTooltip } from '../../../../core';
import { WarningIcon } from '../../../../icons';

import colors from '../../../../../assets/colors.module.scss';
import medicareWarningStyles from '../../../../Fnol/NewFnolUI/InvolvedParties/IcdCodesFragment/index.module.scss';

const NONE_MEDICARE_ELIGIBLE_PREFIXES = ['v', 'w', 'x', 'y'];

export const isPrefixMedicareEligible = (icdCodeKey = '') =>
  !NONE_MEDICARE_ELIGIBLE_PREFIXES.some((prefix) => icdCodeKey.toLowerCase().startsWith(prefix));

export const MedicareWarning = ({ icdCode }) => {
  return (
    <div className={medicareWarningStyles.icdWarningContainer}>
      {!isPrefixMedicareEligible(icdCode) && (
        <FsTooltip title="This ICD code can't be used for Medicare reporting">
          <WarningIcon iconColor={colors.statusPending} />
        </FsTooltip>
      )}
    </div>
  );
};

MedicareWarning.propTypes = {
  icdCode: PropTypes.object.isRequired,
};
