import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { CONFIGURATION_FEATURES_NAMES } from '../../../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../../../Utils';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../../core';
import { useCms } from '../../../hooks/useCms';
import { PreDefinedField } from '../../../IncidentConfiguration/ConfiguredFields';

const DateOfReportContainer = ({ claim, onUpdate, readOnly, withoutLabelOnReadOnly = false }) => {
  const { userOrganization } = useCms();
  const [isFetching, setIsFetching] = useState(false);
  const disabled = readOnly || isFetching;
  const isPermissionsEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT);

  const preDefinedFields = claim.incident_configuration?.fnol || {};
  if (!preDefinedFields.reported_date) {
    return <></>;
  }

  const updateWrapper = async (axiosVerb, url, updateObject) => {
    try {
      setIsFetching(true);
      await axiosVerb(url, updateObject);
      await onUpdate();
    } catch (error) {
      await reportAxiosError(error);
    }
    setIsFetching(false);
  };

  const handleUpdateClaimReportedDate = async (fieldName, fieldValue) => {
    await updateWrapper(axios.put, `/api/v1/claims/${claim.id}/reported_date`, {
      [fieldName]: fieldValue,
    });
  };

  return (
    <RestrictedPermissions action={PERMISSION_ACTIONS.DATE_OF_REPORT} verb={PERMISSION_VERBS.WRITE}>
      <PreDefinedField
        id="reported_date"
        fields={preDefinedFields}
        value={claim.reported_date}
        onUpdate={isPermissionsEnabled ? handleUpdateClaimReportedDate : undefined}
        disabled={disabled}
        showOnly={!isPermissionsEnabled}
        readOnly={!isPermissionsEnabled}
        disableFuture
        gridXs={withoutLabelOnReadOnly ? 12 : undefined}
        withoutLabelOnReadOnly={withoutLabelOnReadOnly}
      />
    </RestrictedPermissions>
  );
};

DateOfReportContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool,
  withHeaderStyle: PropTypes.bool,
  withoutLabelOnReadOnly: PropTypes.bool,
};

export default DateOfReportContainer;
