import React from 'react';

import { useChatContext } from '~/components/AiChat/hooks/useChat';
import Chip from '~/components/core/Atomic/Chip/Chip';
import LoadingIcon from '~/components/icons/LoadingIcon';
import { serverDateTimeToLocal } from '~/DateTimeUtils';
import colors from '~/theme/tailwind/colors';

export const ChatDialogStatusBar: React.FC = () => {
  const { chatStatus } = useChatContext();

  if (chatStatus?.status !== 'PENDING') {
    return null;
  }

  const updated_at = chatStatus?.updated_at ? serverDateTimeToLocal(chatStatus?.updated_at) : '';

  return (
    <div className="__CHAT_STATUS__ flex items-center gap-16 border-0 border-b-1 border-solid border-slate-400 bg-aiV2-fadedWhite py-4 pl-16 pr-[25px]">
      <Chip
        size="small"
        label="Updating Data..."
        icon={<LoadingIcon iconColor={colors.blue[600]} className="h-15 w-15 rotate-180 animate-spin" />}
        className="h-[28px] gap-4 bg-teal-200 p-4 font-semibold text-blue-600"
      />
      <span className="text-xs">
        Questions will be answered based on the last update{updated_at ? `: ${updated_at}` : ''}
      </span>
    </div>
  );
};
