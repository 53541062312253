import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import {
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';

const LOSS_OBJECT_ID = 'related_property_loss.items';

const getInitialPropertyItemsPredefinedFields = (preDefinedFields) =>
  preparePreDefinedFields(get(preDefinedFields, LOSS_OBJECT_ID, {}));
const getPropertyItemsInitialValues = (preDefinedFields) =>
  getPredefinedFieldsEmptyFormikInitialValues(getInitialPropertyItemsPredefinedFields(preDefinedFields));

const getPropertyItemsPredefinedFields = (preDefinedFields) =>
  preparePreDefinedFields(get(preDefinedFields, LOSS_OBJECT_ID, {}));

const getInvolvedPropertyValidationFields = (preDefinedFields) => ({
  ...getAdditionalDataValidations(getPropertyItemsPredefinedFields(preDefinedFields)),
});

const DIALOG_FIELDS = [
  'description',
  'category',
  'quantity',
  'purchase_date',
  'purchase_price',
  'claimed_amount',
  'scheduled',
];

const InvolvedPropertyItemsFragment = ({ preDefinedFields }) => {
  return (
    <Grid container spacing={1}>
      <PreDefinedField id="description" fields={preDefinedFields} inline />
      <PreDefinedField id="category" fields={preDefinedFields} inline />
      <PreDefinedField id="quantity" fields={preDefinedFields} inline decimalScale={0} />
      <PreDefinedField id="purchase_date" fields={preDefinedFields} inline />
      <PreDefinedField id="purchase_price" fields={preDefinedFields} inline />
      <PreDefinedField id="claimed_amount" fields={preDefinedFields} inline />
      <PreDefinedField id="scheduled" fields={preDefinedFields} inline />
    </Grid>
  );
};

InvolvedPropertyItemsFragment.propTypes = {
  preDefinedFields: PropTypes.object.isRequired,
};

export {
  DIALOG_FIELDS,
  getInvolvedPropertyValidationFields,
  getPropertyItemsInitialValues,
  getPropertyItemsPredefinedFields,
  InvolvedPropertyItemsFragment,
};
