import { get } from 'lodash';

import { CLAIM_TYPES_CONFIGURATION } from '../Types';

import { getClaimIcon, getClaimTypeFromPolicy, getConfigurableLobClaimIcon } from './ClaimUtils';

export const getLobIcon = ({ lob, lobConfigurationsDict = {} }) => {
  if (lob in lobConfigurationsDict) {
    return getConfigurableLobClaimIcon(get(lobConfigurationsDict, lob));
  }

  return getClaimIcon(lob);
};

export const getLobFromPolicy = (policy) => policy?.lob?.replace('_policy', '_claim') || getClaimTypeFromPolicy(policy);

export const getConfigLOBs = (lobConfigs, supportedClaimTypes) => {
  const configurableLobs = Object.keys(lobConfigs);
  const supportedConfigClaimTypes = supportedClaimTypes.filter((supportedClaimType) =>
    configurableLobs?.includes(supportedClaimType)
  );
  return supportedConfigClaimTypes;
};

export const getLobDescription = (lob, lobConfigurationsDict) => {
  if (lob in lobConfigurationsDict) {
    return lobConfigurationsDict[lob]?.desc?.replace(' Claim', '');
  } else {
    return CLAIM_TYPES_CONFIGURATION[lob]?.desc;
  }
};
