import React from 'react';
import type { ExtractRouteParams } from 'react-router';
import type { RouteComponentProps, RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';

import type { SafeComponentProps } from '~/components/core/SafeComponent/index';
import { SafeComponentPage, withSafeComponentPage } from '~/components/core/SafeComponent/index';

export interface SafeRouteProps extends RouteProps {
  safeConfig?: Pick<SafeComponentProps, 'criticalityLevel' | 'errorMetadata'>;
}

export const SafeRoute: React.FC<SafeRouteProps> = ({ children, component, render, safeConfig, ...rest }) => {
  const safeConfigWithDefaults: React.Ref<SafeComponentProps> = React.useRef({
    location: rest.location?.pathname,
    ...(safeConfig || {}),
  });

  const safeRender = render
    ? (routerProps: RouteComponentProps<ExtractRouteParams<string, string>>) => {
        return <SafeComponentPage {...safeConfigWithDefaults.current}>{render(routerProps)}</SafeComponentPage>;
      }
    : undefined;

  const safeComponent = React.useMemo(
    () => (component ? withSafeComponentPage(component, safeConfigWithDefaults?.current || {}) : undefined),
    [component, safeConfigWithDefaults]
  );

  return (
    <Route component={safeComponent} render={safeRender} {...rest}>
      {children}
    </Route>
  );
};
