// TODO https://fivesigma.atlassian.net/jira/software/c/projects/NGTPA/boards/12?assignee=62d3b3d19189e98a20172dad&selectedIssue=NGTPA-14757
export const FONT_FAMILY_OPTIONS_DEFAULT = [
  { label: 'Arial', value: 'Arial' },
  { label: 'Calibri', value: 'Calibri' },
  // { label: 'Courier New', value: 'Courier New' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Helvetica', value: 'Helvetica' },
  // { label: 'Lucida Sans', value: 'Lucida Sans' },
  { label: 'Palatino', value: 'Palatino' },
  { label: 'Tahoma', value: 'Tahoma' },
  // { label: 'Times New Roman', value: 'Times New Roman' },
  // { label: 'Trebuchet MS', value: 'Trebuchet MS' },
  { label: 'Verdana', value: 'Verdana' },
];

export const SIZES = ['8', '9', '10', '11', '12', '14', '16', '18', '20', '24', '30', '36', '48', '60', '72'];
