import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const DoneNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6677 19.2323C26.9536 19.532 26.9424 20.0067 26.6427 20.2927L21.1396 25.5427C20.8496 25.8193 20.3934 25.8191 20.1037 25.5422L17.3568 22.9172C17.0574 22.631 17.0466 22.1563 17.3328 21.8568C17.619 21.5574 18.0937 21.5466 18.3932 21.8328L20.6223 23.963L25.6073 19.2073C25.907 18.9214 26.3817 18.9326 26.6677 19.2323Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 13.75C17.4437 13.75 13.75 17.4437 13.75 22C13.75 26.5563 17.4437 30.25 22 30.25C26.5563 30.25 30.25 26.5563 30.25 22C30.25 17.4437 26.5563 13.75 22 13.75ZM12.25 22C12.25 16.6152 16.6152 12.25 22 12.25C27.3848 12.25 31.75 16.6152 31.75 22C31.75 27.3848 27.3848 31.75 22 31.75C16.6152 31.75 12.25 27.3848 12.25 22Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default DoneNoteIcon;
