const TRAVEL_COVERAGE_DICT = {
  trip_delay_coverage: {
    desc: 'Trip Delay',
    is_limit_per_policy: true,
  },
  travel_inconvenience_coverage: {
    desc: 'Travel Inconvenience',
    is_limit_per_policy: true,
  },
  baggage_and_personal_effects_coverage: {
    desc: 'Baggage and Personal Effects',
    is_limit_per_policy: true,
  },
  baggage_delay_coverage: {
    desc: 'Baggage Delay',
    is_limit_per_policy: true,
  },
  trip_interruption_coverage: {
    desc: 'Trip Interruption',
    is_limit_per_policy: true,
  },
  trip_cancellation_coverage: {
    desc: 'Trip Cancellation',
    is_limit_per_policy: true,
  },
  missed_trip_connection_coverage: {
    desc: 'Missed Trip Connection',
    is_limit_per_policy: true,
  },
  pet_parent_coverage: {
    desc: 'Pet Parent',
    is_limit_per_policy: true,
  },
  rental_car_damage_coverage: {
    desc: 'Rental Car Damage',
    is_limit_per_policy: true,
  },
  rental_property_damage_coverage: {
    desc: 'Rental Property Damage',
    is_limit_per_policy: true,
  },
  emergency_medical_coverage: {
    desc: 'Emergency Medical',
    is_limit_per_policy: true,
  },
  medical_evacuation_coverage: {
    desc: 'Medical Evacuation',
    is_limit_per_policy: true,
  },
  non_medical_evacuation_coverage: {
    desc: 'Non-Medical Evacuation',
    is_limit_per_policy: true,
  },
  is_adventure_and_sports_coverage: {
    desc: 'Adventure and Sports Coverage',
    boolean_option: true,
  },
  cancel_for_any_reason_coverage: {
    desc: 'Cancel for Any Reason Coverage',
    is_limit_per_policy: true,
  },
} as const;

export default TRAVEL_COVERAGE_DICT;
