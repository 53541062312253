import type { ReactElement } from 'react';
import React from 'react';
import axios from 'axios';

import { reportAxiosError } from '../../../../Utils';
import { MainCard } from '../../../core';
import { LegalActionItemMenu } from '../../components/LegalActionItemMenu';
import type { DialogPropsWithLegalAction, DialogPropsWithLog } from '../../Dialogs/DialogTypes';
import { useLegalAction } from '../../hooks/useLegalAction';
import type { RouteGetter } from '../../hooks/useLegalActionsBackendRoutes';
import type { DocumentApiResponse, LegalActionApiResponse, LegalActionLogBase, LegalActionLogType } from '../../types';
import type { LogMoreDetailsDialogProps } from '../LegalActionScreenTypes';

import type { LogsCardContentColumnDefinition } from './LogsCardConent';
import { LogsCardContent } from './LogsCardConent';

interface LogsCardContainerProps<T extends LegalActionLogBase | DocumentApiResponse> {
  NewItemComponent: React.FC<DialogPropsWithLegalAction>;
  MoreDetailsComponent?: React.FC<LogMoreDetailsDialogProps<T>>;
  EditItemComponent?: React.FC<DialogPropsWithLog<T>>;
  deleteUrl?: RouteGetter;
  logs: T[];
  logType?: LegalActionLogType;
  columns: LogsCardContentColumnDefinition<T>[];
  title: string;
  addItemLabel: string;
  reload: () => Promise<void>;
  legalAction: LegalActionApiResponse;
}

export const LogsCardContainer = <T extends LegalActionLogBase | DocumentApiResponse>({
  NewItemComponent,
  MoreDetailsComponent,
  EditItemComponent,
  deleteUrl,
  logs,
  logType,
  columns,
  title,
  addItemLabel,
  reload,
  legalAction,
}: LogsCardContainerProps<T>): ReactElement => {
  const { reloadLegalAction } = useLegalAction();
  const filteredLogs = logType ? logs.filter((log) => (log as LegalActionLogBase).type === logType) : logs;
  const [logInViewMore, setLogInViewMore] = React.useState<T | null>(null);
  const [isAddLogDialogOpen, setIsAddLogDialogOpen] = React.useState(false);
  const [logInEdit, setLogInEdit] = React.useState<T | null>(null);

  const reloadAll = async () => {
    await Promise.all([reloadLegalAction(), reload()]);
  };

  const handleDelete = async (log: T) => {
    if (!deleteUrl) return;

    try {
      await axios.delete(deleteUrl(log?.id));
      await reloadAll();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <>
      <MainCard type="elevated" title={title} collapsible openByDefault>
        <LogsCardContent<T>
          columns={columns}
          rows={filteredLogs}
          onAddItem={() => setIsAddLogDialogOpen(true)}
          addItemText={`Add ${addItemLabel}`}
          actions={(row) => (
            <LegalActionItemMenu<T>
              onViewMore={MoreDetailsComponent ? () => setLogInViewMore(row) : undefined}
              onEditClick={EditItemComponent ? () => setLogInEdit(row) : undefined}
              onDeleteClick={deleteUrl ? () => handleDelete(row) : undefined}
              entityLabel={addItemLabel}
              ignoreLegalActionState
            />
          )}
        />
      </MainCard>
      {logInViewMore && MoreDetailsComponent ? (
        <MoreDetailsComponent log={logInViewMore} onClose={() => setLogInViewMore(null)} />
      ) : null}
      {isAddLogDialogOpen ? (
        <NewItemComponent
          legalAction={legalAction}
          onClose={() => setIsAddLogDialogOpen(false)}
          onSubmitSuccess={reloadAll}
        />
      ) : null}
      {logInEdit && EditItemComponent ? (
        <EditItemComponent
          legalAction={legalAction}
          log={logInEdit}
          onClose={() => setLogInEdit(null)}
          onSubmitSuccess={reloadAll}
        />
      ) : null}
    </>
  );
};
