import React from 'react';

import SvgIcon from '../core/SvgIcon';

const FilesIcon = (props) => (
  <SvgIcon width="14" height="17" viewBox="0 0 14 17" stroke="0" {...props}>
    <path d="M0.491117 3.24112C0.725537 3.0067 1.04348 2.875 1.375 2.875H7.625C7.79076 2.875 7.94973 2.94085 8.06694 3.05806L11.1919 6.18306C11.3092 6.30027 11.375 6.45924 11.375 6.625V15.375C11.375 15.7065 11.2433 16.0245 11.0089 16.2589C10.7745 16.4933 10.4565 16.625 10.125 16.625H1.375C1.04348 16.625 0.725538 16.4933 0.491117 16.2589C0.256696 16.0245 0.125 15.7065 0.125 15.375V4.125C0.125 3.79348 0.256696 3.47554 0.491117 3.24112ZM7.36612 4.125H1.375L1.375 15.375H10.125V6.88388L7.36612 4.125Z" />
    <path d="M2.99112 0.741117C3.22554 0.506696 3.54348 0.375 3.875 0.375H10.125C10.2908 0.375 10.4497 0.440848 10.5669 0.558058L13.6919 3.68306C13.8092 3.80027 13.875 3.95924 13.875 4.125V12.875C13.875 13.2065 13.7433 13.5245 13.5089 13.7589C13.2745 13.9933 12.9565 14.125 12.625 14.125H10.75C10.4048 14.125 10.125 13.8452 10.125 13.5C10.125 13.1548 10.4048 12.875 10.75 12.875H12.625V4.38388L9.86612 1.625L3.875 1.625V3.5C3.875 3.84518 3.59518 4.125 3.25 4.125C2.90482 4.125 2.625 3.84518 2.625 3.5V1.625C2.625 1.29348 2.7567 0.975537 2.99112 0.741117Z" />
    <path d="M3.25 10.375C3.25 10.0298 3.52982 9.75 3.875 9.75H7.625C7.97018 9.75 8.25 10.0298 8.25 10.375C8.25 10.7202 7.97018 11 7.625 11H3.875C3.52982 11 3.25 10.7202 3.25 10.375Z" />
    <path d="M3.25 12.875C3.25 12.5298 3.52982 12.25 3.875 12.25H7.625C7.97018 12.25 8.25 12.5298 8.25 12.875C8.25 13.2202 7.97018 13.5 7.625 13.5H3.875C3.52982 13.5 3.25 13.2202 3.25 12.875Z" />
  </SvgIcon>
);

export default FilesIcon;
