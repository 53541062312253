import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const TextHighlightIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M14.7677 11.25H10.8808L12.8242 7.03969L14.7677 11.25ZM21.0742 3V21C21.0742 21.1989 20.9952 21.3897 20.8545 21.5303C20.7139 21.671 20.5231 21.75 20.3242 21.75H5.32422C5.12531 21.75 4.93454 21.671 4.79389 21.5303C4.65324 21.3897 4.57422 21.1989 4.57422 21V3C4.57422 2.80109 4.65324 2.61032 4.79389 2.46967C4.93454 2.32902 5.12531 2.25 5.32422 2.25H20.3242C20.5231 2.25 20.7139 2.32902 20.8545 2.46967C20.9952 2.61032 21.0742 2.80109 21.0742 3ZM8.01016 15.6806C8.19068 15.7638 8.39685 15.7719 8.58336 15.7032C8.76987 15.6345 8.92146 15.4945 9.00484 15.3141L10.1889 12.75H15.4595L16.6436 15.3141C16.7309 15.488 16.8825 15.6211 17.0662 15.6854C17.2499 15.7497 17.4514 15.74 17.6281 15.6585C17.8048 15.577 17.9429 15.4299 18.0132 15.2484C18.0835 15.067 18.0805 14.8652 18.0048 14.6859L13.5048 4.93594C13.4447 4.80605 13.3487 4.69608 13.2281 4.619C13.1075 4.54193 12.9673 4.50098 12.8242 4.50098C12.6811 4.50098 12.541 4.54193 12.4203 4.619C12.2997 4.69608 12.2037 4.80605 12.1436 4.93594L7.64359 14.6859C7.56041 14.8665 7.55229 15.0726 7.62103 15.2591C7.68976 15.4456 7.82972 15.5972 8.01016 15.6806ZM19.5742 18C19.5742 17.8011 19.4952 17.6103 19.3545 17.4697C19.2139 17.329 19.0231 17.25 18.8242 17.25H6.82422C6.62531 17.25 6.43454 17.329 6.29389 17.4697C6.15324 17.6103 6.07422 17.8011 6.07422 18C6.07422 18.1989 6.15324 18.3897 6.29389 18.5303C6.43454 18.671 6.62531 18.75 6.82422 18.75H18.8242C19.0231 18.75 19.2139 18.671 19.3545 18.5303C19.4952 18.3897 19.5742 18.1989 19.5742 18Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default TextHighlightIcon;
