import PropTypes from 'prop-types';

export const paginationPropsPropTypesShape = PropTypes.shape({
  page: PropTypes.number,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  disabled: PropTypes.bool,
});
