import { useCms } from '~/components/hooks/useCms';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

export default function useFetchExposure(claimId, exposureId) {
  const { userOrganization } = useCms();
  const isEfficientClaimForFe =
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL) &&
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.EFFICIENT_CLAIM_FOR_FE);

  const shouldFetchExposure = claimId && exposureId && isEfficientClaimForFe;

  const {
    isLoading: isLoadingExposure,
    isError: isErrorExposure,
    data: exposure,
  } = useDataFetcher(shouldFetchExposure ? `/api/v1/claims/${claimId}/exposures/${exposureId}` : undefined);
  if (!shouldFetchExposure) {
    return { isLoadingExposure: false, isErrorExposure: false };
  }
  return { isLoadingExposure, isErrorExposure, exposure };
}
