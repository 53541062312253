import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getClaimTypeFromPolicy } from '../Utils/ClaimUtils';
import { getLobFromPolicy } from '../Utils/lobUtils';

import { LobContextProvider } from './hooks/useLob';
import { SubOrganizationContextProvider } from './hooks/useSubOrganization';
import ContactsContextProvider from './ContactsContext';
import { CurrencyFormatterContextProvider } from './CurrencyFormatterContext';

const PolicyContext = React.createContext({});

function PolicyContextProvider(props) {
  const { policy, children } = props;
  const [contacts, setContacts] = useState(policy.contacts);

  const handleAddContact = (contact) => {
    setContacts((currContacts) => [...currContacts, contact]);
  };

  return (
    <PolicyContext.Provider value={{ policy }}>
      <CurrencyFormatterContextProvider currency={policy.policy_currency}>
        <ContactsContextProvider
          contacts={contacts}
          onAddContact={handleAddContact}
          contactsOrganizationId={policy.organization_id}
          rolesClaimType={getClaimTypeFromPolicy(policy)}
        >
          <SubOrganizationContextProvider subOrganization={policy.sub_organization}>
            <LobContextProvider claimType={getClaimTypeFromPolicy(policy)} lob={getLobFromPolicy(policy)}>
              {children}
            </LobContextProvider>
          </SubOrganizationContextProvider>
        </ContactsContextProvider>
      </CurrencyFormatterContextProvider>
    </PolicyContext.Provider>
  );
}

PolicyContextProvider.propTypes = {
  policy: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

function withPolicy(WrappedComponent) {
  class WithPolicy extends React.Component {
    render() {
      return (
        <PolicyContext.Consumer>
          {(policyContext) => (
            <WrappedComponent policy={policyContext ? policyContext.policy : undefined} {...this.props} />
          )}
        </PolicyContext.Consumer>
      );
    }
  }

  WithPolicy.displayName = `withPolicy(${getDisplayName(WrappedComponent)})`;
  return WithPolicy;
}

function usePolicy() {
  const policyContext = React.useContext(PolicyContext);
  return { policy: policyContext.policy };
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export { PolicyContextProvider, usePolicy, withPolicy };
