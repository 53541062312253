import React, { useEffect, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { isEmpty } from 'lodash';

import { useClaim } from '~/components/ClaimContainer';
import AutoComplete from '~/components/core/Atomic/AutoComplete';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import DatePickerField from '~/components/core/Molecules/Fields/DatePickerField/DatePickerField';
import MultiSelectTextField from '~/components/core/Molecules/Fields/MultiSelectField/MultiSelectField';
import TextField from '~/components/core/Molecules/Fields/TextField/TextField';
import ToggleButtonGroup from '~/components/core/Molecules/Fields/ToggleButtonGroup/ToggleButtonGroup';
import { MainCard } from '~/components/core/NewDesignSystem/Cards/MainCard/MainCard';
import ExposuresLabelFilter from '~/components/exposures/ExposuresLabelsFilter';
import { getUserRelatedExposuresId } from '~/components/exposures/ExposureUtils';
import { useCms } from '~/components/hooks/useCms';
import CloseIcon from '~/components/icons/CloseIcon';
import { NOTE_SUBJECT } from '~/Types';

const NOTE_TYPE_OPTIONS = [
  { key: 'all', value: null, label: 'All' },
  { key: 'automatic', value: 'automatic', label: 'Automatic' },
  { key: 'manual', value: 'manual', label: 'Manual' },
];

type NoteType = null | 'automatic' | 'manuel';

export interface NotesFiltersValues {
  exposure_ids: number[];
  creation_mode_type: NoteType;
  search_text?: string;
  note_subjects?: (keyof typeof NOTE_SUBJECT)[];
  adjuster_id: string | null;
  start_date: string | null;
  end_date: string | null;
}

interface NotesFilterProps {
  hideTypeFilter?: boolean;
  exposureOptionsIds?: number[];
  adjusterOptionsIds: { [adjusterId: string]: string };
  noteSubjectsOptions: (keyof typeof NOTE_SUBJECT)[];
  onSearch: (currentFilters: NotesFiltersValues) => void;
}

const NotesFilter: React.FC<NotesFilterProps> = ({
  exposureOptionsIds,
  adjusterOptionsIds,
  noteSubjectsOptions,
  onSearch,
}) => {
  const { claim } = useClaim();
  const { user } = useCms() as { user: unknown };
  const [selectedNoteType, setSelectedNoteType] = useState<NoteType>(null);
  const [freeTextSearch, setFreeTextSearch] = useState<string>('');
  const [selectedNoteSubjects, setSelectedNoteSubjects] = useState<(keyof typeof NOTE_SUBJECT)[]>([]);
  const [selectedAdjuster, setSelectedAdjuster] = useState<string | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<string | null>(null);
  const [selectedExposureIds, setSelectedExposureIds] = useState<number[]>(
    exposureOptionsIds ?? getUserRelatedExposuresId(user, claim)
  );

  useEffect(() => {
    onSearch({
      exposure_ids: selectedExposureIds,
      creation_mode_type: selectedNoteType,
      search_text: freeTextSearch,
      note_subjects: selectedNoteSubjects,
      adjuster_id: selectedAdjuster,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
    });
  }, [
    freeTextSearch,
    onSearch,
    selectedAdjuster,
    selectedEndDate,
    selectedExposureIds,
    selectedNoteSubjects,
    selectedNoteType,
    selectedStartDate,
  ]);

  return (
    <div className="mb-12">
      <div className="mb-32 mt-12 flex justify-between">
        <div>
          <ExposuresLabelFilter
            exposureOptionsIds={exposureOptionsIds}
            filterList={selectedExposureIds}
            onUpdateFiltered={setSelectedExposureIds}
          />
        </div>
        <div>
          <ToggleButtonGroup
            value={selectedNoteType}
            onChange={(e, newValue) => setSelectedNoteType(newValue as NoteType)}
            options={NOTE_TYPE_OPTIONS}
          />
        </div>
      </div>
      <MainCard type="filled" collapsible title="Search and Filter" openByDefault>
        <div className="grid grid-cols-3 gap-x-20 gap-y-32">
          <div>
            <TextField
              id="free_text_search"
              label="Search"
              value={freeTextSearch}
              fullWidth
              onChange={(newValue) => {
                setFreeTextSearch(newValue as string);
              }}
              InputProps={{
                endAdornment: freeTextSearch ? (
                  <InputAdornment position="end">
                    <IconButton className="p-8" onClick={() => setFreeTextSearch('')}>
                      <CloseIcon size={10} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </div>
          <div>
            <MultiSelectTextField<keyof typeof NOTE_SUBJECT>
              id="note_subject"
              label="Note Type"
              options={noteSubjectsOptions}
              disabled={isEmpty(noteSubjectsOptions)}
              renderValue={(selectedSubjectKeys: unknown) => {
                const selectedSubjectKeysCast = selectedSubjectKeys as unknown as (keyof typeof NOTE_SUBJECT)[];
                return selectedSubjectKeysCast
                  .map((selectedSubjectKey) => NOTE_SUBJECT[selectedSubjectKey].display)
                  .join(', ');
              }}
              renderOption={(subjectKey: unknown) => {
                const castSubjectKey = subjectKey as keyof typeof NOTE_SUBJECT;
                return NOTE_SUBJECT[castSubjectKey].display;
              }}
              fullWidth
              sortAlphabetic
              onChange={setSelectedNoteSubjects as (newValues: string[]) => void}
              value={selectedNoteSubjects}
              InputProps={{
                endAdornment: isEmpty(selectedNoteSubjects) ? null : (
                  <InputAdornment className="mr-24" position="start">
                    <IconButton className="p-8" onClick={() => setSelectedNoteSubjects([])}>
                      <CloseIcon size={10} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="-mt-4">
            <AutoComplete
              id="adjuster_id"
              options={Object.keys(adjusterOptionsIds)}
              label="Adjuster"
              disabled={isEmpty(adjusterOptionsIds)}
              value={selectedAdjuster}
              onChange={(_, adjuster) => setSelectedAdjuster(adjuster)}
              getOptionLabel={(adjusterId) => (adjusterId ? adjusterOptionsIds[adjusterId] : '')}
              fullWidth
              InputProps={{
                endAdornment: selectedAdjuster ? (
                  <InputAdornment className="mr-24" position="start">
                    <IconButton className="p-8" onClick={() => setSelectedAdjuster(null)}>
                      <CloseIcon size={10} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </div>
          <div>
            <DatePickerField
              value={selectedStartDate}
              onChange={(date) => setSelectedStartDate(date ? date.format('YYYY-MM-DD') : null)}
              label="Start Date"
              disableFuture
              fullWidth
              clearable
            />
          </div>
          <div>
            <DatePickerField
              value={selectedEndDate}
              onChange={(date) => setSelectedEndDate(date ? date.format('YYYY-MM-DD') : null)}
              label="End Date"
              disableFuture
              fullWidth
              clearable
            />
          </div>
        </div>
      </MainCard>
    </div>
  );
};

export default NotesFilter;
