import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import MenuItem from '~/components/core/Atomic/MenuItem';
import { ONE_INC_CLAIMSPAY_TYPES } from '~/components/OneInc/consts';

import { reportAxiosError } from '../../../Utils';
import { useCms } from '../../hooks/useCms';

import UpdatePayeeDetailsDialog from './UpdatePayeeDetailsDialog/UpdatePayeeDetailsDialog';
import OneIncPaymentDetailsDialog from './OneIncPaymentDetailsDialog';
import ResendEmailDialog from './ResendEmailDialog';

const isOneIncStatusAllowResendEmail = (status) => {
  return status === 'submitted';
};

const additionalFinanceTableMenuOptions = [
  { id: 'update_payee_details', MenuItemComponent: UpdatePayeeDetailsMenuItem },
  { id: 'resend_communication', MenuItemComponent: ResendCommunicationMenuItem },
  { id: 'view_one_inc_payment_details', MenuItemComponent: ViewOneIncPaymentDetailsMenuItem },
];

function UpdatePayeeDetailsMenuItem({ financeRecord }) {
  const paymentRequest = financeRecord.payment_request;
  const { userOrganization } = useCms();
  const [isUpdatePayeeDetailsOpen, setUpdatePayeeDetailsOpen] = useState(false);
  const [updatePayeeLoading, setUpdatePayeeLoading] = useState(false);
  const [isUpdatePayeeError, setIsUpdatePayeeError] = useState(false);
  const [updatePayeeErrorMessage, setUpdatePayeeErrorMessage] = useState(null);

  const handleOpenPayeeDetailsDialog = () => {
    setUpdatePayeeDetailsOpen(true);
  };

  const handleCloseUpdatePayeeDetailsDialog = () => {
    setUpdatePayeeDetailsOpen(false);
    setUpdatePayeeErrorMessage(null);
    setIsUpdatePayeeError(false);
    setUpdatePayeeLoading(false);
  };

  const handleUpdatePayeeDetails = async (values) => {
    try {
      setUpdatePayeeLoading(true);
      await axios.post(
        `/api/v1/one_inc/organizations/${userOrganization.id}/payments/${paymentRequest.id}/update_payee`,
        values
      );
      handleCloseUpdatePayeeDetailsDialog();
    } catch (error) {
      await reportAxiosError(error, false);
      setUpdatePayeeErrorMessage(error?.response?.data?.message);
      setIsUpdatePayeeError(true);
    } finally {
      setUpdatePayeeLoading(false);
    }
  };

  return (
    <>
      <MenuItem
        onClick={handleOpenPayeeDetailsDialog}
        disabled={[ONE_INC_CLAIMSPAY_TYPES.VENDOR, ONE_INC_CLAIMSPAY_TYPES.LIENHOLDER].includes(
          paymentRequest.method_specific.one_inc_claimspay_type
        )}
      >
        Update payee details
      </MenuItem>
      {isUpdatePayeeDetailsOpen ? (
        <UpdatePayeeDetailsDialog
          payees={paymentRequest.payees}
          primary_payee_id={paymentRequest.primary_payee_id}
          onClose={handleCloseUpdatePayeeDetailsDialog}
          onSubmit={handleUpdatePayeeDetails}
          isSubmitLoading={updatePayeeLoading}
          isSubmitError={isUpdatePayeeError}
          errorMessage={updatePayeeErrorMessage}
        />
      ) : null}
    </>
  );
}

UpdatePayeeDetailsMenuItem.propTypes = {
  financeRecord: PropTypes.object.isRequired,
};

function ResendCommunicationMenuItem({ financeRecord }) {
  const paymentRequest = financeRecord.payment_request;
  const { userOrganization } = useCms();
  const [resendEmailDialogOpen, setResendEmailDialogOpen] = useState(false);
  const [resendEmailLoading, setResendEmailLoading] = useState(false);
  const [isResendEmailError, setIsResendEmailError] = useState(false);
  const [resendEmailErrorMessage, setResendEmailErrorMessage] = useState(null);

  const handleResendEmailClick = async () => {
    try {
      setResendEmailLoading(true);
      setResendEmailDialogOpen(true);
      await axios.post(
        `/api/v1/one_inc/organizations/${userOrganization.id}/payments/${paymentRequest.id}/resend_communications`
      );
    } catch (error) {
      await reportAxiosError(error, false);
      setResendEmailErrorMessage(error?.response?.data?.message);
      setIsResendEmailError(true);
    } finally {
      setResendEmailLoading(false);
    }
  };

  return (
    <>
      <MenuItem
        disabled={!isOneIncStatusAllowResendEmail(paymentRequest.financial_status) || resendEmailLoading}
        onClick={() => {
          handleResendEmailClick();
        }}
      >
        Resend communication
      </MenuItem>
      {resendEmailDialogOpen ? (
        <ResendEmailDialog
          isLoading={resendEmailLoading}
          isError={isResendEmailError}
          errorMessage={resendEmailErrorMessage}
          onClose={() => {
            setResendEmailDialogOpen(false);
            setResendEmailLoading(false);
            setIsResendEmailError(false);
            setResendEmailErrorMessage(null);
          }}
        />
      ) : null}
    </>
  );
}

ResendCommunicationMenuItem.propTypes = {
  financeRecord: PropTypes.object.isRequired,
};

function ViewOneIncPaymentDetailsMenuItem({ financeRecord }) {
  const paymentRequest = financeRecord.payment_request;
  const [isOneIncDetailsOpen, setIsOneIncDetailsOpen] = useState(false);

  const handleOpenOneIncDetailsDialog = () => {
    setIsOneIncDetailsOpen(true);
  };

  const handleCloseOneIncDetailsDialog = () => {
    setIsOneIncDetailsOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleOpenOneIncDetailsDialog}>View One Inc payment details</MenuItem>
      {isOneIncDetailsOpen ? (
        <OneIncPaymentDetailsDialog payment_request_id={paymentRequest.id} onClose={handleCloseOneIncDetailsDialog} />
      ) : null}
    </>
  );
}

ViewOneIncPaymentDetailsMenuItem.propTypes = {
  financeRecord: PropTypes.object.isRequired,
};

export default additionalFinanceTableMenuOptions;
