import React from 'react';

import SvgIcon from '../../../core/SvgIcon';

import styles from './sparkle.module.scss';

// TODO: Fix 32 viewBox
const SparkleV2 = (props) => (
  <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      className={styles.elementToFadeInAndOut}
      d="M9.33848 7.91943C9.52779 7.57113 10.0278 7.57113 10.2171 7.91943L13.1503 13.3159C13.1964 13.4007 13.266 13.4704 13.3508 13.5165L18.7473 16.4497C19.0956 16.639 19.0956 17.139 18.7473 17.3283L13.3508 20.2615C13.266 20.3076 13.1964 20.3772 13.1503 20.462L10.2171 25.8585C10.0278 26.2068 9.52779 26.2068 9.33848 25.8585L6.40525 20.462C6.35916 20.3772 6.28952 20.3076 6.20473 20.2615L0.808219 17.3283C0.459923 17.139 0.459923 16.639 0.808219 16.4497L6.20473 13.5165C6.28952 13.4704 6.35916 13.4007 6.40525 13.3159L9.33848 7.91943Z"
      fill="url(#paint0_linear_102_17782)"
    />
    <path
      className={styles.elementToFadeInAndOutSmall}
      opacity="0.5"
      d="M26.227 0.80822C26.4164 0.459924 26.9163 0.459923 27.1056 0.808219L28.4738 3.32535C28.5199 3.41014 28.5895 3.47978 28.6743 3.52587L31.1915 4.89403C31.5398 5.08335 31.5398 5.58332 31.1915 5.77263L28.6743 7.1408C28.5895 7.18689 28.5199 7.25653 28.4738 7.34132L27.1056 9.85845C26.9163 10.2067 26.4164 10.2067 26.227 9.85845L24.8589 7.34132C24.8128 7.25653 24.7431 7.18689 24.6584 7.1408L22.1412 5.77263C21.7929 5.58332 21.7929 5.08335 22.1412 4.89403L24.6584 3.52587C24.7431 3.47978 24.8128 3.41014 24.8589 3.32535L26.227 0.80822Z"
      fill="url(#paint1_linear_102_17782)"
    />
    <path
      className={styles.elementToFadeInAndOutSmallSec}
      opacity="0.2"
      d="M26.227 22.1416C26.4164 21.7933 26.9163 21.7933 27.1056 22.1416L28.4738 24.6587C28.5199 24.7435 28.5895 24.8132 28.6743 24.8592L31.1915 26.2274C31.5398 26.4167 31.5398 26.9167 31.1915 27.106L28.6743 28.4742C28.5895 28.5203 28.5199 28.5899 28.4738 28.6747L27.1056 31.1918C26.9163 31.5401 26.4164 31.5401 26.227 31.1918L24.8589 28.6747C24.8128 28.5899 24.7431 28.5203 24.6584 28.4742L22.1412 27.106C21.7929 26.9167 21.7929 26.4167 22.1412 26.2274L24.6584 24.8592C24.7431 24.8132 24.8128 24.7435 24.8589 24.6587L26.227 22.1416Z"
      fill="url(#paint2_linear_102_17782)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_102_17782"
        x1="11.6758"
        y1="23.3308"
        x2="15.8346"
        y2="8.29721"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23376C" />
        <stop offset="0.927091" stopColor="#305A7F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_102_17782"
        x1="27.7016"
        y1="8.84706"
        x2="29.97"
        y2="0.646913"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23376C" />
        <stop offset="0.927091" stopColor="#305A7F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_102_17782"
        x1="27.7016"
        y1="30.1804"
        x2="29.97"
        y2="21.9803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23376C" />
        <stop offset="0.927091" stopColor="#305A7F" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default SparkleV2;
