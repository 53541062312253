import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ExpandIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.39092 9.65901C6.32037 9.58849 6.22471 9.54887 6.12496 9.54887C6.02521 9.54887 5.92955 9.58849 5.85901 9.65901L2.77738 12.7406V10.6581C2.77738 10.5584 2.73774 10.4627 2.6672 10.3921C2.59665 10.3216 2.50097 10.282 2.4012 10.282C2.30143 10.282 2.20575 10.3216 2.1352 10.3921C2.06466 10.4627 2.02502 10.5584 2.02502 10.6581V13.6487C2.02502 13.7485 2.06466 13.8442 2.1352 13.9147C2.20575 13.9853 2.30143 14.0249 2.4012 14.0249H5.3918C5.49156 14.0249 5.58724 13.9853 5.65779 13.9147C5.72834 13.8442 5.76797 13.7485 5.76797 13.6487C5.76797 13.549 5.72834 13.4533 5.65779 13.3827C5.58724 13.3122 5.49156 13.2726 5.3918 13.2726H3.30929L6.39084 10.191C6.46136 10.1205 6.50106 10.0247 6.50106 9.92496C6.50106 9.82522 6.46144 9.72955 6.39092 9.65901ZM9.65913 6.3908C9.72967 6.46132 9.82534 6.50093 9.92509 6.50093C10.0248 6.50093 10.1205 6.46132 10.191 6.3908L13.2727 3.30917V5.39167C13.2727 5.49144 13.3123 5.58712 13.3829 5.65767C13.4534 5.72822 13.5491 5.76785 13.6488 5.76785C13.7486 5.76785 13.8443 5.72822 13.9148 5.65767C13.9854 5.58712 14.025 5.49144 14.025 5.39167V2.40108C14.025 2.30131 13.9854 2.20563 13.9148 2.13508C13.8443 2.06453 13.7486 2.0249 13.6488 2.0249H10.6583C10.5585 2.0249 10.4628 2.06453 10.3923 2.13508C10.3217 2.20563 10.2821 2.30131 10.2821 2.40108C10.2821 2.50085 10.3217 2.59653 10.3923 2.66707C10.4628 2.73762 10.5585 2.77725 10.6583 2.77725H12.7408L9.65921 5.8588C9.58869 5.92935 9.54899 6.02509 9.54899 6.12484C9.54899 6.22459 9.58861 6.32025 9.65913 6.3908Z"
    />
  </SvgIcon>
);

export default ExpandIcon;
