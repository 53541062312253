import { isEqual } from 'lodash';

import { ConfigEnumBase, PermissionVerbEnumMember } from './types';

/**
 * @type {Object.<string, PermissionVerbEnumMember>}
 */
export class PERMISSION_VERBS extends ConfigEnumBase {
  FORBIDDEN = new PermissionVerbEnumMember({
    displayName: 'None',
    hierarchyIndex: 0,
    optionValue: ['FORBIDDEN'],
  });
  READ = new PermissionVerbEnumMember({
    displayName: 'Read',
    hierarchyIndex: 1,
    optionValue: ['READ'],
  });
  WRITE = new PermissionVerbEnumMember({
    displayName: 'Write',
    hierarchyIndex: 2,
    optionValue: ['READ', 'WRITE'],
  });
  FULL = new PermissionVerbEnumMember({
    displayName: 'Full',
    hierarchyIndex: 3,
    optionValue: ['FULL'],
  });

  constructor() {
    super();
    this._setKeysOnChildren();
  }

  /**
   * @param id
   * @returns {PermissionVerbEnumMember}
   */
  getVerbById(id) {
    return this[id];
  }

  /**
   * @param {string[]} optionValue
   * @returns {any}
   */
  getVerbByOptionValue(optionValue) {
    return Object.values(this).find((verb) => isEqual(verb.optionValue, optionValue));
  }
}

const __PERMISSION_VERBS__ = new PERMISSION_VERBS();

export { __PERMISSION_VERBS__ };
