import React from 'react';

import { useClaim } from '~/components/ClaimContainer';
import type { DocumentMetadata } from '~/components/communications/types';
import ShowAllCollapsible from '~/components/core/Molecules/Collapsible/ShowAllCollapsible';
import PhotosLink from '~/components/Gallery/PhotosLink';
import type { NoteContentProps } from '~/components/Notes/NoteContent/types';

const PhotosNote: React.FC<NoteContentProps> = ({ note, isExpended, onCollapseClick, isCollapsible, maxRows }) => {
  return isCollapsible ? (
    <ShowAllCollapsible
      visibleLines={maxRows || 1}
      showAsInlineButton
      isOpen={isExpended}
      onCollapseClick={onCollapseClick}
    >
      <PhotosNoteInner note={note} />
    </ShowAllCollapsible>
  ) : (
    <PhotosNoteInner note={note} />
  );
};

const PhotosNoteInner: React.FC<NoteContentProps> = ({ note }) => {
  const { claim } = useClaim() as { claim: { documents: DocumentMetadata[] } };

  return (
    <div className="mt-12">
      <PhotosLink
        photos={claim.documents.filter((doc) => note.document_ids?.includes(doc.id))}
        text="View media files"
        galleryDialogTitle="Media Uploaded"
        displayLinkAsButton
      />
    </div>
  );
};

export default PhotosNote;
