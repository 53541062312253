import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import CommunicationExposureLabels from '~/components/communications/CommunicationCard/CommunicationExposureLabels';
import CommunicationFullHeader from '~/components/communications/CommunicationCard/CommunicationFullHeader';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ClaimLink from '../ClaimLink';
import { FsIconButton, PERMISSION_VERBS, RestrictedPermissions } from '../core';
import { ExpandIcon } from '../icons';
import { TextFieldFormik } from '../TextFieldFormik';

const CommunicationCard = (props) => {
  const {
    open,
    communication,
    communicationInitialFields,
    communicationHeaderAdditionalProps,
    CommunicationTypeSpecificBody,
    communicationTypeSpecificBodyProps,
    CommunicationAction,
    communicationActionProps,
    communicationIdentifier,
    additionalCardActions,
    disableSoftClose,
    isEditing,
    isNew,
    isDialog,
    isDocument,
    onRequestEdit,
    onClose,
    onMinimized,
    disableMinimized,
    title,
    maxWidth,
    isCommunicationNotRelatedToClaimInContext,
    senderFragment,
    leftCardActions,
    onCommunicationUpdate,
  } = props;

  const classes = useStyles();
  const formik = useFormikContext();
  const { inClaimPage } = useClaim();
  const communicationClaimId = communication?.claim_id;
  const [claim] = useFetchClaim(communicationClaimId);

  const [requestedAsFullscreen, setRequestedAsFullscreen] = useState(false);

  const { isSubmitting } = formik;
  const isView = !isNew && !isDocument;

  let channel;
  let direction;
  let contact;

  if (isView) {
    channel = communication.channel;
    contact = communication.contact;
    direction = communication.direction;
  } else {
    const { communicationChannel, communicationContact, communicationDirection } = communicationInitialFields;
    channel = communicationChannel;
    contact = communicationContact;
    direction = communicationDirection;
  }

  let dialogProps = {};
  if (isDialog) {
    dialogProps = {
      isDialog,
      onClose: disableSoftClose || isEditing ? undefined : onClose,
      maxWidth: maxWidth ? maxWidth : 'sm',
      fullWidth: true,
    };
  }
  const fullScreenProps = {
    isDialog: true,
    onClose: () => setRequestedAsFullscreen(false),
    maxWidth: 'lg',
    fullWidth: true,
  };

  const displayDetachAction =
    isView &&
    !isEditing &&
    communicationClaimId &&
    ['email', 'phone_call', 'physical_mail'].includes(communication.channel);
  const displayNotificationAction = isView && !isEditing && !!communication.claim_id;

  const rightCardActions = React.Children.map(additionalCardActions, (child) =>
    React.cloneElement(child, {
      displayDetachAction,
      displayNotificationAction,
    })
  );

  const action = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {leftCardActions}
      {!requestedAsFullscreen && !isDialog && (
        <RestrictedPermissions verb={PERMISSION_VERBS.READ}>
          <span>
            <FsIconButton tooltipText="Full screen" icon={ExpandIcon} onClick={() => setRequestedAsFullscreen(true)} />
          </span>
        </RestrictedPermissions>
      )}
      {rightCardActions}
    </div>
  );

  const additionalHeaderProp = communicationHeaderAdditionalProps ? communicationHeaderAdditionalProps : {};
  return (
    <>
      {/* This is our way of showing the communication in 'Full Screen' - open in it again but as large dialog  */}
      {requestedAsFullscreen && <CommunicationCard {...props} {...fullScreenProps} />}
      <CardDialog
        action={action}
        open={open}
        {...dialogProps}
        title={title ? title : undefined}
        preventClose={isSubmitting}
        trackAlt="Communication"
        onMinimized={onMinimized}
        disableMinimized={disableMinimized}
        subheader={
          (!inClaimPage || isCommunicationNotRelatedToClaimInContext) &&
          claim && ( // Show link to the claim only if communication's claim different from the claim page were in, or we're outside claim page
            <ClaimLink claimId={claim.id} linkText={`Claim: ${claim.claim_id_display}`} />
          )
        }
      >
        {senderFragment}
        <CommunicationFullHeader
          communication={communication}
          communicationClaimId={communicationClaimId}
          channel={channel}
          direction={direction}
          contact={contact}
          isView={isView}
          onCommunicationUpdate={onCommunicationUpdate}
          additionalHeaderProp={additionalHeaderProp}
          communicationIdentifier={communicationIdentifier}
        />
        <CommunicationTypeSpecificBody
          communication={communication}
          communicationInitialFields={communicationInitialFields}
          isDocument={isDocument}
          isEditing={isEditing}
          onRequestEdit={onRequestEdit}
          isNew={isNew}
          {...communicationTypeSpecificBodyProps}
        />

        {(!isView || communication.summary || isEditing || onRequestEdit) && ( // show summary field only if exists or can be edited
          <div className={classes.inputContainer}>
            <TextFieldFormik
              id="summary"
              label="Summary"
              className={classes.textField}
              fullWidth
              onEdit={onRequestEdit}
              showOnly={isView && !isEditing}
            />
          </div>
        )}

        {(!isView || communication.claim_id) && ( // don't display if we're in an existing communication which is not yet claim related
          <CommunicationExposureLabels isView={isView} isEditing={isEditing} onEdit={onRequestEdit} />
        )}

        <CommunicationAction
          communication={communication}
          isDocument={isDocument}
          isEditing={isEditing}
          isNew={isNew}
          {...communicationActionProps}
        />
      </CardDialog>
    </>
  );
};

CommunicationCard.propTypes = {
  open: PropTypes.bool,
  communication: requiredIf(PropTypes.object, (props) => !props.isNew && !props.isDocument),
  onCommunicationUpdate: PropTypes.func,
  communicationIdentifier: PropTypes.string,
  isDialog: PropTypes.bool,
  title: PropTypes.string,
  disableSoftClose: PropTypes.bool,
  onClose: requiredIf(PropTypes.func, (props) => props.isDialog && !props.disableSoftClose),
  onMinimized: PropTypes.func,
  isNew: PropTypes.bool,
  isDocument: PropTypes.bool,
  disableMinimized: PropTypes.bool,
  additionalCardActions: PropTypes.node,
  communicationInitialFields: PropTypes.shape({
    communicationChannel: PropTypes.string.isRequired,
    communicationContact: PropTypes.object,
    communicationDirection: requiredIf(PropTypes.oneOf(['Incoming', 'Outgoing']), (props) => props.isNew),
  }),
  communicationHeaderAdditionalProps: PropTypes.object,
  CommunicationTypeSpecificBody: PropTypes.func.isRequired,
  communicationTypeSpecificBodyProps: PropTypes.object,
  CommunicationAction: PropTypes.func.isRequired,
  communicationActionProps: PropTypes.object,
  isEditing: PropTypes.bool,
  onRequestEdit: PropTypes.func,
  formik: PropTypes.object, // From formik
  maxWidth: PropTypes.string,
  isCommunicationNotRelatedToClaimInContext: PropTypes.bool,
  senderFragment: PropTypes.node,
  leftCardActions: PropTypes.node,
};

export default CommunicationCard;
