const CPT_TRANSACTION_TYPES = {
  'WC-AC-WC': {
    desc: 'Acupuncture',
  },
  'WC-AM-WC': {
    desc: 'Ambulance',
  },
  'WC-AN-WC': {
    desc: 'Anesthesiologist',
  },
  'WC-AU-WC': {
    desc: 'Audiometry',
  },
  'WC-BR-WC': {
    desc: 'Bill Review',
  },
  'WC-BS-WC': {
    desc: 'Bone Scan',
  },
  'WC-DC-WC': {
    desc: 'Chiropractor',
  },
  'WC-CL-WC': {
    desc: 'Clinic',
  },
  'WC-DSCT-WC': {
    desc: 'Ct Scans',
  },
  'WC-DD-WC': {
    desc: 'Dentist',
  },
  'WC-DS-WC': {
    desc: 'Diagnostic Study',
  },
  'WC-DME-WC': {
    desc: 'Durable Medical',
  },
  'WC-ER-WC': {
    desc: 'Emergency Room',
  },
  'WC-EXRM-WC': {
    desc: 'Excess Reimburse Med',
  },
  'WC-MFF-WC': {
    desc: 'Facility Fee',
  },
  'WC-FCE-WC': {
    desc: 'Functional Cpcty Eval',
  },
  'WC-FM-WC': {
    desc: 'Future Medical',
  },
  'WC-HHC-WC': {
    desc: 'Home Health Care',
  },
  'WC-HOIPS-WC': {
    desc: 'Hosp In-Pt Surgery',
  },
  'WC-HOOPS-WC': {
    desc: 'Hosp Out-Pt Surgery',
  },
  'WC-HO-WC': {
    desc: 'Hospital (Inpatient)',
  },
  'WC-IME-WC': {
    desc: 'Independent Med Eval',
  },
  'WC-INJ-WC': {
    desc: 'Injection',
  },
  'WC-INJA-WC': {
    desc: 'Injection Administration',
  },
  'WC-IPD-WC': {
    desc: 'Inpatient Doctor Visit',
  },
  'WC-INM-WC': {
    desc: 'Investigation Med',
  },
  'WC-JSA-WC': {
    desc: 'Job Site Analysis',
  },
  'WC-LA-WC': {
    desc: 'Laboratory',
  },
  'WC-CM': {
    desc: 'Medical Collection',
  },
  'WC-MD-WC': {
    desc: 'Medical Doctor',
  },
  'WC-MJC-WC': {
    desc: 'Medical Journal Correction',
  },
  'WC-MRR-WC': {
    desc: 'Medical Record Review',
  },
  'WC-MRP-WC': {
    desc: 'Medical Rehab Payment',
  },
  'WC-MRE-WC': {
    desc: 'Medical Reimbursement',
  },
  'WC-MS-WC': {
    desc: 'Medical Supplies',
  },
  'WC-MSAB-WC': {
    desc: 'Medicare Set Aside-Beau Rivage',
  },
  'WC-MSAT-WC': {
    desc: 'Medicare Set Aside-Tunica',
  },
  'WC-MI-WC': {
    desc: 'Miscellaneous Medical',
  },
  'WC-DSMR-WC': {
    desc: 'Mri Scan',
  },
  'WC-NS-WC': {
    desc: 'No Show For Appointment',
  },
  'WC-OCTH-WC': {
    desc: 'Occupational Therapy',
  },
  'WC-OFF-WC': {
    desc: 'Office Visits',
  },
  'WC-OPH-WC': {
    desc: 'Ophthalmology',
  },
  'WC-OD-WC': {
    desc: 'Optomotrist',
  },
  'WC-RAD-WC': {
    desc: 'Other Radiology Services',
  },
  'WC-OSRV-WC': {
    desc: 'Outpatient Services',
  },
  'WC-OSRG-WC': {
    desc: 'Outpatient Surgery',
  },
  'WC-OM-WC': {
    desc: 'Overpayment Medical',
  },
  'WC-PM-WC': {
    desc: 'Pain Management',
  },
  'WC-PATH-WC': {
    desc: 'Pathology-Prof',
  },
  'WC-TH-WC': {
    desc: 'Physical Therapy',
  },
  'WC-PPDE-WC': {
    desc: 'Ppd Evaluation',
  },
  'WC-PC-WC': {
    desc: 'Pre-Certification',
  },
  'WC-PR-WC': {
    desc: 'Prescription Reimbursement',
  },
  'WC-RXR-WC': {
    desc: 'Prescription Repricing',
  },
  'WC-RX-WC': {
    desc: 'Prescriptions (Purchased)',
  },
  'WC-PS-WC': {
    desc: 'Psychological Services',
  },
  'WC-ST-WC': {
    desc: 'Speech Therapy',
  },
  'WC-SM-WC': {
    desc: 'Subro Med Reimbuse',
  },
  'WC-SBSQM-WC': {
    desc: 'Subsequent Inj Med',
  },
  'WC-URG-WC': {
    desc: 'Urgent Care',
  },
  'WC-VCM-WC': {
    desc: 'Voided Check Medical',
  },
  'WC-WHP-WC': {
    desc: 'Work Hardening Program',
  },
  'WC-XR-WC': {
    desc: 'X-Rays',
  },
  'WC-ATT': {
    desc: 'Attorney Fees',
  },
  'WC-CC': {
    desc: 'Comp Collection',
  },
  'WC-EXRC': {
    desc: 'Excess Reimburse Comp',
  },
  'WC-EXRO': {
    desc: 'Excess Reimburse Other',
  },
  'WC-EXRR': {
    desc: 'Excess Reimburse Rehab',
  },
  'WC-IN': {
    desc: 'Sub Rosa Investigation',
  },
  'WC-MM': {
    desc: 'Medical Management',
  },
  'WC-O': {
    desc: 'Other',
  },
  'WC-OC': {
    desc: 'Other Collection',
  },
  'WC-OO': {
    desc: 'Overpayment Other',
  },
  'WC-OPC': {
    desc: 'Overpayment Comp',
  },
  'WC-RA': {
    desc: 'Rehab Administration',
  },
  'WC-RM': {
    desc: 'Rehab Maintenance',
  },
  'WC-RR': {
    desc: 'Misc. Rehab Recovery',
  },
  'WC-SC': {
    desc: 'Subro Comp Reimburse',
  },
  'WC-SO': {
    desc: 'Subro Other Reimburse',
  },
  'WC-SR': {
    desc: 'Subro Rehab Reimburse',
  },
  'WC-TRN': {
    desc: 'Transportation',
  },
  'WC-OR': {
    desc: 'Overpayment Rehab',
  },
  'WC-PN': {
    desc: 'Penalty',
  },
  'WC-R': {
    desc: 'Rehabilitation – Phase 1',
  },
} as const;

export default CPT_TRANSACTION_TYPES;
