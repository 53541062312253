import React from 'react';

import SvgIcon from '../core/SvgIcon';

const PedestrianIcon = (props) => (
  <SvgIcon stroke="none" fill="#202020" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.875 1.5C8.18464 1.5 7.625 2.05964 7.625 2.75C7.625 3.44036 8.18464 4 8.875 4C9.56536 4 10.125 3.44036 10.125 2.75C10.125 2.05964 9.56536 1.5 8.875 1.5ZM6.375 2.75C6.375 1.36929 7.49429 0.25 8.875 0.25C10.2557 0.25 11.375 1.36929 11.375 2.75C11.375 4.13071 10.2557 5.25 8.875 5.25C7.49429 5.25 6.375 4.13071 6.375 2.75Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 9L0.308058 8.55806C0.0639806 8.80214 0.0639806 9.19786 0.308058 9.44194C0.552073 9.68596 0.947661 9.68602 1.19175 9.44213L1.19319 9.4407L1.20063 9.43336L1.23352 9.40139C1.26325 9.37273 1.3083 9.32986 1.36715 9.27545C1.48495 9.16655 1.65745 9.01195 1.87257 8.83285C2.30495 8.47285 2.89935 8.0223 3.56041 7.64403C4.22792 7.26206 4.92372 6.9769 5.56538 6.90533C6.18636 6.83606 6.73549 6.96609 7.2006 7.39631L7.2014 7.39705C7.43526 7.61262 7.67308 7.86976 7.9279 8.14528C9.03095 9.33795 10.4525 10.875 13.25 10.875C13.5952 10.875 13.875 10.5952 13.875 10.25C13.875 9.90482 13.5952 9.625 13.25 9.625C11.0461 9.625 10.0522 8.56561 8.97595 7.41843C8.68191 7.10502 8.38172 6.78506 8.04904 6.47835C7.26422 5.7526 6.32913 5.56239 5.42681 5.66303C4.54503 5.76138 3.67833 6.13637 2.93959 6.5591C2.1944 6.98551 1.5388 7.48419 1.07275 7.87223C0.838642 8.06715 0.649817 8.23628 0.518591 8.3576C0.452928 8.41831 0.401543 8.46718 0.365971 8.50147C0.348181 8.51862 0.334334 8.53214 0.324621 8.54168L0.313172 8.55297L0.309814 8.55631L0.308058 8.55806C0.308058 8.55806 0.308058 8.55806 0.75 9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.44444 6.03617C7.76101 6.17376 7.9061 6.54193 7.76852 6.8585L3.1982 17.3741C3.06061 17.6907 2.69245 17.8358 2.37587 17.6982C2.0593 17.5606 1.91421 17.1924 2.0518 16.8759L6.62211 6.36025C6.7597 6.04368 7.12787 5.89858 7.44444 6.03617Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.04578 10.02C5.24616 9.73893 5.63645 9.67353 5.91751 9.87391L9.23782 12.2411C9.40233 12.3584 9.5 12.548 9.5 12.75V17.125C9.5 17.4702 9.22018 17.75 8.875 17.75C8.52982 17.75 8.25 17.4702 8.25 17.125V13.072L5.19187 10.8917C4.91081 10.6913 4.8454 10.3011 5.04578 10.02Z"
    />
  </SvgIcon>
);

export default PedestrianIcon;
