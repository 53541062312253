import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { isEmpty, noop } from 'lodash';
import * as Yup from 'yup';

import BackTextButton from '~/components/core/Buttons/BackTextButton';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { useClaim } from '../../ClaimContainer';
import { LoadingSwitch, PERMISSION_ACTIONS, PERMISSION_VERBS } from '../../core';
import { useHasPermission } from '../../hooks/useHasPermission';
import useDataFetcher from '../../useDataFetcher';

import ExternalClaimNumberFragment from './ExternalClaimNumberFragment';
import ExternalClaimNumbersTable from './ExternalClaimNumbersTable';

const MAIN_TITLE = 'External Claim Numbers';
const ADD_TITLE = 'Add External Claim Number';
const EDIT_TITLE = 'Edit External Claim Number';
const INITIAL_VALUES = {
  external_claim_number: '',
  external_contact: null,
  external_contact_id: null,
  description: '',
};

const ExternalClaimNumber = ({ onClose = noop }) => {
  const { claim } = useClaim();

  const {
    isLoading,
    isError,
    data: externalClaimNumbers = [],
    reloadData: reloadExternalClaimNumbers,
  } = useDataFetcher(`/api/v1/external_claim_number/${claim.id}`);
  const hasWritePermission = useHasPermission({
    action: PERMISSION_ACTIONS.EXTERNAL_CLAIM_NUMBER,
    verb: PERMISSION_VERBS.WRITE,
  });

  const isEmptyExternalClaimNumbers = isEmpty(externalClaimNumbers);

  const [isAddMode, setIsAddMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  useEffect(() => {
    if (isEmptyExternalClaimNumbers && !isAddMode && !isEditMode && !isLoading && !isError) {
      setIsAddMode(true);
    }
  }, [isAddMode, isEditMode, isEmptyExternalClaimNumbers, isError, isLoading]);

  const handleDelete = async (externalClaimNumberId) => {
    try {
      await axios.delete(`/api/v1/external_claim_number/${claim.id}/${externalClaimNumberId}`);
      await reloadExternalClaimNumbers();
      setCurrentRecord(null);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleEdit = async (externalClaimNumberId, values) => {
    try {
      await axios.patch(`/api/v1/external_claim_number/${claim.id}/${externalClaimNumberId}`, values);
      await reloadExternalClaimNumbers();
      setIsEditMode(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleAdd = async (values) => {
    try {
      await axios.post(`/api/v1/external_claim_number/${claim.id}`, values);
      await reloadExternalClaimNumbers();
      setIsAddMode(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const getDialogTitle = () => {
    if (isAddMode && hasWritePermission) {
      return (
        <>
          {!isEmptyExternalClaimNumbers && (
            <BackTextButton title={`Back to ${MAIN_TITLE}`} onClick={() => setIsAddMode(false)} upperCase={false} />
          )}
          {ADD_TITLE}
        </>
      );
    }

    if (isEditMode && hasWritePermission) {
      return (
        <>
          <BackTextButton
            title={`Back to ${MAIN_TITLE}`}
            onClick={() => {
              setIsEditMode(false);
              setCurrentRecord(null);
            }}
            upperCase={false}
          />
          {EDIT_TITLE}
        </>
      );
    }

    return (
      <>
        <span className="text-pink-600">{claim.claim_id_display}</span>&nbsp;&nbsp;{MAIN_TITLE}
      </>
    );
  };

  const onClickPrimary = async (values) => {
    if (isAddMode) {
      await handleAdd(values);
    } else if (isEditMode) {
      await handleEdit(values.id, values);
    }
  };

  const initialValues = currentRecord ? currentRecord : INITIAL_VALUES;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        external_claim_number: Yup.string().test(
          'not-empty-string',
          'Required',
          (external_claim_number) => external_claim_number?.trim()?.length > 0
        ),
        external_contact_id: Yup.number().nullable(),
        description: Yup.string().nullable(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        await onClickPrimary(values);
        return formikProps.resetForm();
      }}
    >
      {(formikProps) => {
        const { resetForm, isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            title={getDialogTitle()}
            isDialog
            open
            onClose={onClose}
            maxWidth="md"
            fullWidth
            footerActions={
              <DialogFooterActions
                onClickPrimary={handleSubmit}
                onClickSecondary={onClose}
                showPrimary={(isAddMode || isEditMode) && hasWritePermission}
                disabled={isSubmitting}
                secondaryLabel={isAddMode || isEditMode ? 'Cancel' : 'Close'}
                ignorePermissionsSecondary
              />
            }
          >
            <LoadingSwitch isLoading={isLoading} isError={isError}>
              {((!isEmptyExternalClaimNumbers && !isAddMode && !isEditMode) || !hasWritePermission) && (
                <ExternalClaimNumbersTable
                  rows={externalClaimNumbers}
                  onDeleteClick={handleDelete}
                  onAddClick={() => {
                    resetForm();
                    setCurrentRecord(null);
                    setIsAddMode(true);
                  }}
                  onEditClick={(record) => {
                    resetForm();
                    setIsEditMode(true);
                    setCurrentRecord(record);
                  }}
                />
              )}
              {hasWritePermission && (isAddMode || isEditMode) && <ExternalClaimNumberFragment />}
            </LoadingSwitch>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

ExternalClaimNumber.propTypes = {
  onClose: PropTypes.func,
};

export default ExternalClaimNumber;
