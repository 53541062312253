import React from 'react';
import { Divider } from '@material-ui/core';
import { Chip } from '@mui/material';
import { keyBy } from 'lodash';

import { useClaim } from '~/components/ClaimContainer';
import type { ContactFullModel } from '~/components/Contacts/types';
import Text from '~/components/core/TextComponents/Text';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import { getExposuresLabels } from '~/Utils';

interface ExposuresPropsType {
  contact: ContactFullModel;
}

const Exposures: React.FC<ExposuresPropsType> = ({ contact }) => {
  const { claim } = useClaim();

  if (!claim) {
    return null;
  }

  const exposureLabels = getExposuresLabels(claim);
  const exposureLabelsDict = keyBy(exposureLabels, 'id');

  return (
    <>
      <Divider className="my-12" />
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
        Exposures
      </Text>
      {(contact.exposure_ids || []).map((exposure_id) => (
        <>
          <Chip
            key={exposure_id}
            size="small"
            className="my-4 mr-4"
            label={
              <OverflowTextWithToolTip maxWidth="130px">
                {exposureLabelsDict[exposure_id].label}
              </OverflowTextWithToolTip>
            }
          />
        </>
      ))}
    </>
  );
};

export default Exposures;
