import React from 'react';

import CardDialog from '../CardDialog';
import InnerTabs from '../core/Layout/InnerTabs/InnerTabs';
import PaymentSearchPage from '../Finances/PaymentsSearchTab';

import AdvancedClaimsSearchPage from './AdvancedClaimsSearchFiltersFormik';

import { useStyles } from '../../assets/styles';

const AdvancedSearchPage = () => {
  const classes = useStyles();
  const tabs = [
    {
      label: 'Claims',
      url: 'advanced_claims_search',
      component: AdvancedClaimsSearchPage,
    },
    {
      label: 'Payments',
      url: 'finance_search',
      component: PaymentSearchPage,
    },
  ];

  return (
    <>
      <div className={classes.cardDivRow}>
        <CardDialog title="Advanced Search" fullWidth maxWidth="xl">
          <InnerTabs tabs={tabs} />
        </CardDialog>
      </div>
    </>
  );
};

export default AdvancedSearchPage;
