const CUSTOM_FIELD_TYPES = {
  boolean: {
    desc: 'Yes / No',
    json_type: 'boolean',
    complex_json_type: false,
  },
  string: {
    desc: 'Text',
    json_type: 'string',
    complex_json_type: false,
  },
  number: {
    desc: 'Number',
    json_type: 'number',
    complex_json_type: false,
  },
  select: {
    desc: 'Single Select',
    json_type: 'string',
    complex_json_type: true,
  },
  multiselect: {
    desc: 'Multiple Select',
    json_type: 'array',
    complex_json_type: true,
  },
  date: {
    desc: 'Date',
    json_type: 'string',
    complex_json_type: true,
  },
  time: {
    desc: 'Time',
    json_type: 'string',
    complex_json_type: true,
  },
} as const;

export default CUSTOM_FIELD_TYPES;
