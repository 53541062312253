import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { TextFieldFormik } from '../../TextFieldFormik';
import UsStatesSelectTextFieldFormik from '../../UsStatesSelectTextFieldFormik';

import { useStyles } from '../../../assets/styles';

const LegalVenueContainer = ({ showOnly, idPrefix }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={6}>
        <UsStatesSelectTextFieldFormik
          id={`${idPrefix}legal_venue_state`}
          label="Legal Venue"
          elect
          fullWidth
          showOnly={showOnly}
          className={classes.textField}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          className={classes.textField}
          id={`${idPrefix}legal_venue_reason`}
          label="Reason"
          showOnly={showOnly}
          fullWidth
        />
      </Grid>
    </>
  );
};

LegalVenueContainer.propTypes = {
  showOnly: PropTypes.bool,
  idPrefix: PropTypes.string,
};

export default LegalVenueContainer;
