import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';

import { useCurrencyFormatter } from '../../../CurrencyFormatterContext';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../../../TextFieldFormik';

import { useStyles } from '../../../../assets/styles';

function IndemnityPayment1099ReportFieldsFormik({ amount, readOnly }) {
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();
  const { values, setFieldValue } = useFormikContext();

  const reportable_1099_indemnity_amount = getIn(values, 'reportable_1099_indemnity_amount');

  // if amount changes, reset reportable_1099_indemnity_amount
  React.useEffect(() => {
    setFieldValue('reportable_1099_indemnity_amount', '');
  }, [amount, setFieldValue]);

  return (
    <Grid container justify="space-between" spacing={1}>
      <Grid item xs={6}>
        <TextFieldFormik
          id="is_1099_reportable"
          label="Is 1099 Reportable"
          className={classes.textField}
          select
          fullWidth
          disabled={readOnly}
        >
          <MenuItem value={true}>Reportable</MenuItem>
          <MenuItem value={false}>Not Reportable</MenuItem>
        </TextFieldFormik>
      </Grid>
      <Grid item xs={6} />
      {getIn(values, 'is_1099_reportable') && amount ? (
        <>
          <Grid item xs={6}>
            <MonetaryValueTextFieldFormik
              id="reportable_1099_indemnity_amount"
              label="Indemnity Amount to Report"
              className={classes.textField}
              fullWidth
              showOnly={readOnly}
            />
          </Grid>
          {reportable_1099_indemnity_amount && (
            <Grid item xs={12}>
              <Typography display="block">{`Expenses Amount to Report ${currencyFormatter.format(
                amount - reportable_1099_indemnity_amount
              )}`}</Typography>
            </Grid>
          )}
        </>
      ) : null}
    </Grid>
  );
}
IndemnityPayment1099ReportFieldsFormik.propTypes = {
  amount: PropTypes.number,
  readOnly: PropTypes.bool,
};

export default IndemnityPayment1099ReportFieldsFormik;
