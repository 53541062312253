import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const BikeIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      d="M12.9998 12.6667C14.2885 12.6667 15.3332 11.9205 15.3332 11C15.3332 10.0796 14.2885 9.33337 12.9998 9.33337C11.7112 9.33337 10.6665 10.0796 10.6665 11C10.6665 11.9205 11.7112 12.6667 12.9998 12.6667Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M2.99984 12.6667C4.2885 12.6667 5.33317 11.9205 5.33317 11C5.33317 10.0796 4.2885 9.33337 2.99984 9.33337C1.71117 9.33337 0.666504 10.0796 0.666504 11C0.666504 11.9205 1.71117 12.6667 2.99984 12.6667Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4H9.5L13 10"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13 12.5C14.3807 12.5 15.5 11.3807 15.5 10C15.5 8.61929 14.3807 7.5 13 7.5C11.6193 7.5 10.5 8.61929 10.5 10C10.5 11.3807 11.6193 12.5 13 12.5Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3 12.5C4.38071 12.5 5.5 11.3807 5.5 10C5.5 8.61929 4.38071 7.5 3 7.5C1.61929 7.5 0.5 8.61929 0.5 10C0.5 11.3807 1.61929 12.5 3 12.5Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path d="M3 4H4.75L8.25 10" stroke={colors.lobIconMain} strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path
      d="M10.6313 6H5.91875L3 10"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default BikeIcon;
