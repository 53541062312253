import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import cn from '~/Utils/cn';

import { Text } from '../../../../components/core';

import colors from '../../../../assets/colors.module.scss';

const CARD_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const useStyles = makeStyles(() => ({
  card: () => ({
    padding: '15px 24px',
    borderRadius: '6px',
    marginBottom: '14px',
    borderWidth: '1px',
    borderStyle: 'solid',
  }),
  title: () => ({
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
  }),
  primary: () => ({
    borderColor: colors.dividerColor,
  }),
  secondary: () => ({
    borderColor: 'none',
    borderWidth: 0,
    backgroundColor: colors.listBackground,
  }),
}));

const Card = ({ className, title, cardType = CARD_TYPES.PRIMARY, children }) => {
  const classes = useStyles();

  const cardTypeClass = cn(classes.card, className, {
    [classes.primary]: cardType === CARD_TYPES.PRIMARY,
    [classes.secondary]: cardType === CARD_TYPES.SECONDARY,
  });

  return (
    <div className={cardTypeClass}>
      {title && (
        <div className={cn(classes.title)}>
          <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD}>
            {title}
          </Text>
        </div>
      )}
      <div className={classes.content}>{children}</div>
    </div>
  );
};

Card.CARD_TYPES = CARD_TYPES;

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cardType: PropTypes.oneOf(Object.values(CARD_TYPES)),
};

export { Card, CARD_TYPES };
