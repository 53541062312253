import type { ReactElement } from 'react';
import React from 'react';
import { noop } from 'lodash';

export interface StepProps {
  onNext: () => Promise<boolean> | void;
  onBack: () => Promise<boolean> | void;
  label: string;
  disableBack?: boolean;
  disableNext?: boolean;
  children: ReactElement[] | ReactElement;
}
const Step: React.FC<StepProps> = () => <></>;

const stepDefaultProps = {
  /**
   * Move to the next stage will be prevented
   * when onNext returns explicit `false`
   * */
  onNext: noop,
  /**
   * Move to the previous stage will be prevented
   * when onBack returns explicit `false`
   * */
  onBack: noop,
};

Step.defaultProps = stepDefaultProps;

export default Step;
