import React from 'react';
import { Typography as MuiTypography } from '@material-ui/core';

export type TypographyAlignment = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export type TypographyColor =
  | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error';

export type TypographyVariant =
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'inherit'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2';

export interface TypographyProps {
  className?: string;
  variant?: TypographyVariant;
  children?: React.ReactNode;
  component?: React.ElementType;
  display?: 'initial' | 'block' | 'inline';
  style?: React.CSSProperties;
  color?: TypographyColor;
  align?: TypographyAlignment;
  key?: React.Key | null | undefined;
  id?: string;
  gutterBottom?: boolean;
  innerRef?: React.RefObject<HTMLDivElement> | undefined;
}
/*
 * DEPRECATION NOTICE: Typography component is a wrapper around Material-UI LEGACY Typography component.
 * For new components, use Atomic Text, Caption and Header components instead.
 */
const Typography: React.FC<TypographyProps> = ({ innerRef, children, ...rest }) => {
  return (
    <MuiTypography ref={innerRef} {...rest}>
      {children}
    </MuiTypography>
  );
};

export default Typography;
