import React, { useEffect, useState } from 'react';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { useFormikContext } from 'formik';

import { useClaim } from '~/components/ClaimContainer';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import { useStepper } from '~/components/core/Stepper/StepperContext';
import type {
  MoiAssigneeMethodConfig,
  MoiFormikValues,
  MoiStepProps,
} from '~/components/exposures/moi/MoiStepper/types';
import { GENERAL_EXPOSURE_FAKE_ID } from '~/components/exposures/moi/MoiUtils';

import type { SearchVendorStepProps } from './SearchVendorStep';
import SearchVendorStep from './SearchVendorStep';
import type { SelectAssigneeStepProps } from './SelectAssigneeStep';
import SelectAssigneeStep from './SelectAssigneeStep';

const AssigneeStep: React.FC<MoiStepProps> = ({ exposure, setAlertMessage, moiMethodsReturn, ...rest }) => {
  const [assigneeMethodParams, setAssigneeMethodParams] = useState<MoiAssigneeMethodConfig>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const { values } = useFormikContext<MoiFormikValues>();
  const { moiMethodsById } = moiMethodsReturn;
  const { claim } = useClaim();
  const { handleBack } = useStepper();

  useEffect(() => {
    const fetchAssigneeMethodParams = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.post(`/api/v1/claims/${claim.id}/method_of_inspection/assignee_method`, {
          moi_method_key: moiMethodsById[values.moi_method_id]?.key,
          exposure_ids: values?.exposure_ids?.includes(GENERAL_EXPOSURE_FAKE_ID) ? [] : values?.exposure_ids,
          method_specific: values?.method_specific,
        });

        setAssigneeMethodParams(data);
      } catch (error) {
        setIsError(true);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const errorMessage = error.response.data.message;
        if (errorMessage.startsWith('Notify User:')) {
          const notifyUserMessage = errorMessage.replace('Notify User:', '');
          setAlertMessage({
            message: notifyUserMessage,
            type: 'error',
          });
        } else {
          setAlertMessage({
            type: 'error',
            message: (error as AxiosError).message,
          });
        }
        await handleBack(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (
      values &&
      values.moi_method_id &&
      moiMethodsById[values.moi_method_id] &&
      !isLoading &&
      !isError &&
      !assigneeMethodParams
    ) {
      fetchAssigneeMethodParams();
    }
  }, [
    values?.exposure_ids,
    values.moi_method_id,
    moiMethodsById,
    values,
    isLoading,
    isError,
    assigneeMethodParams,
    claim.id,
    setAlertMessage,
    handleBack,
  ]);

  const props = {
    ...rest,
    ...assigneeMethodParams?.assignee_method_params,
    exposure,
    setAlertMessage,
    moiMethodsReturn,
  };

  return (
    <LoadingSwitch isLoading={!assigneeMethodParams || isLoading} isError={isError}>
      {assigneeMethodParams?.assignee_method_type === 'vendor_search' && (
        <SearchVendorStep {...(props as SearchVendorStepProps)} />
      )}
      {assigneeMethodParams?.assignee_method_type === 'standard' && (
        <SelectAssigneeStep {...(props as SelectAssigneeStepProps)} />
      )}
    </LoadingSwitch>
  );
};

export default AssigneeStep;
