import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ErrorIcon = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <circle cx="7.5" cy="7.5" r="7" stroke="inherit" fill="none" />
    <path
      d="M7.47541 4C7.69611 4 7.87395 4.18091 7.87018 4.40158L7.79222 8.956C7.78926 9.12885 7.64829 9.26743 7.47541 9.26743V9.26743C7.30253 9.26743 7.16156 9.12885 7.1586 8.956L7.08064 4.40158C7.07687 4.18091 7.25471 4 7.47541 4V4ZM7.5082 11C7.36612 11 7.2459 10.9488 7.14754 10.8464C7.04918 10.744 7 10.6188 7 10.4708C7 10.3229 7.04918 10.1977 7.14754 10.0953C7.2459 9.99289 7.36612 9.94168 7.5082 9.94168C7.64481 9.94168 7.75956 9.99289 7.85246 10.0953C7.95082 10.1977 8 10.3229 8 10.4708C8 10.6188 7.95082 10.744 7.85246 10.8464C7.75956 10.9488 7.64481 11 7.5082 11Z"
      fill="inherit"
      stroke="none"
    />
  </SvgIcon>
);

export default ErrorIcon;
