import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const CopySimpleIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" disableStrokeColor iconColor={iconColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.75C6 3.33579 6.33579 3 6.75 3H20.25C20.6642 3 21 3.33579 21 3.75V17.25C21 17.6642 20.6642 18 20.25 18C19.8358 18 19.5 17.6642 19.5 17.25V4.5H6.75C6.33579 4.5 6 4.16421 6 3.75Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6.75C3 6.33579 3.33579 6 3.75 6H17.25C17.6642 6 18 6.33579 18 6.75V20.25C18 20.6642 17.6642 21 17.25 21H3.75C3.33579 21 3 20.6642 3 20.25V6.75ZM4.5 7.5V19.5H16.5V7.5H4.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default CopySimpleIcon;
