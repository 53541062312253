const DAMAGE_ASSESSMENT_EXCEPTION_CODES = [
  'Additional Labor Allowance',
  'Calculations',
  'Damage Identification',
  'Depreciation',
  'General Category Items',
  'Item Codes/Material Identification',
  'Matching Items',
  'Measurements',
  'Minimum Charges',
  'Overhead & Profit',
  'Overlap/Inclusion',
  'Pricing',
  'Repair v Replace',
  'Waste',
] as const;

export default DAMAGE_ASSESSMENT_EXCEPTION_CODES;
