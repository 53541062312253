import useAddressTab from '~/components/Contacts/UpsertContact/Tabs/Address';
import useCoiTab from '~/components/Contacts/UpsertContact/Tabs/COI';
import useCommunicationTab from '~/components/Contacts/UpsertContact/Tabs/Communication';
import useContractOfServiceTab from '~/components/Contacts/UpsertContact/Tabs/ContractOfService';
import useDetailsTab from '~/components/Contacts/UpsertContact/Tabs/Details';
import useFinanceTab from '~/components/Contacts/UpsertContact/Tabs/Finance';
import useIntegrationsTab from '~/components/Contacts/UpsertContact/Tabs/Integrations';
import useW9Tab from '~/components/Contacts/UpsertContact/Tabs/W9';
import type { Tab } from '~/components/Contacts/UpsertContact/types';

export const useContactTabs = (): { allTabs: Tab[]; filteredTabs: Tab[] } => {
  // Outside of Formik Context
  const DetailsTab = useDetailsTab();
  const AddressTab = useAddressTab();
  const CommunicationTab = useCommunicationTab();
  const W9Tab = useW9Tab();
  const CoiTab = useCoiTab();
  const ContractOfServiceTab = useContractOfServiceTab();
  const FinanceTab = useFinanceTab();
  const IntegrationsTab = useIntegrationsTab();

  const allTabs: Tab[] = [
    DetailsTab,
    AddressTab,
    CommunicationTab,
    W9Tab,
    CoiTab,
    ContractOfServiceTab,
    FinanceTab,
    IntegrationsTab,
  ];

  const filteredTabs: Tab[] = allTabs.filter((tab) => !tab.isHiddenForContact || !tab.isHiddenForContact());

  return {
    allTabs,
    filteredTabs,
  };
};
