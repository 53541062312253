import React from 'react';

import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import { useCurrencyFormatter } from './CurrencyFormatterContext';
import PlainTable from './PlainTable';

function WCClaimFinanceSummaryTable() {
  const { claim } = useClaim();
  const { currencyFormatter } = useCurrencyFormatter();

  const columnData = [
    { id: 'type', numeric: false, label: '' },
    {
      id: 'paid_to_date',
      numeric: false,
      label: 'Paid to date',
      specialCell: (exp) => currencyFormatter.format(exp.indemnity.paid_sum),
    },
    {
      id: 'reserve_to_date',
      numeric: false,
      label: 'Reserve to date',
      specialCell: (exp) => currencyFormatter.format(exp.indemnity.reserve),
    },
    {
      id: 'incurred',
      numeric: false,
      label: 'Incurred',
      specialCell: (exp) => currencyFormatter.format(exp.indemnity.paid_sum + exp.indemnity.reserve),
    },
  ];

  const getExposureByType = (type) => (exposure) => exposure.coverage_type === type;

  const medicalsExposure = claim.exposures.find(getExposureByType('coverage_wc_medicals'));
  const compensationExposure = claim.exposures.find(getExposureByType('coverage_wc_compensation'));
  const otherExpenseExposure = claim.exposures.find(getExposureByType('coverage_wc_other_expense'));
  const vocRehabExposure = claim.exposures.find(getExposureByType('coverage_wc_voc_rehab'));

  const medicalsRow = { ...medicalsExposure, type: 'Medicals' };
  const compensationRow = { ...compensationExposure, type: 'Composition' };
  const otherRow = { ...otherExpenseExposure, type: 'Other Expense' };
  const vocRehabRow = { ...vocRehabExposure, type: 'Voc. Rehab' };

  let rows = [medicalsRow, compensationRow, otherRow, vocRehabRow];
  rows = rows.map((row, idx) => ({ ...row, id: idx }));

  return (
    <>
      <CardDialog containerStyle={{ overflow: 'scroll' }} outlinedCard title="Finance summary table">
        <PlainTable columns={columnData} rows={rows} />
      </CardDialog>
    </>
  );
}

function ClaimFinanceSummaryTable() {
  const { claim } = useClaim();
  const { currencyFormatter } = useCurrencyFormatter();

  const columnData = [
    { id: 'type', numeric: false, label: '' },
    { id: 'paid_to_date', numeric: false, label: 'Paid to date' },
    { id: 'reserve_to_date', numeric: false, label: 'Reserve to date' },
    { id: 'incurred', numeric: false, label: 'Incurred' },
  ];

  const indemnity_paid_sum = claim.exposures.reduce((acc, exposure) => acc + exposure.indemnity.paid_sum, 0);
  const expenses_paid_sum =
    claim.exposures.reduce((acc, exposure) => acc + exposure.expenses.paid_sum, 0) + claim.general_expenses.paid_sum;
  const indemnity_reserve = claim.exposures.reduce((acc, exposure) => acc + exposure.indemnity.reserve, 0);
  const expenses_reserve =
    claim.exposures.reduce((acc, exposure) => acc + exposure.expenses.reserve, 0) + claim.general_expenses.reserve;
  const indemnity = {
    type: 'Indemnity',
    paid_to_date: currencyFormatter.format(indemnity_paid_sum),
    reserve_to_date: currencyFormatter.format(indemnity_reserve),
    incurred: currencyFormatter.format(indemnity_paid_sum + indemnity_reserve),
  };
  const expenses = {
    type: 'Expenses',
    paid_to_date: currencyFormatter.format(expenses_paid_sum),
    reserve_to_date: currencyFormatter.format(expenses_reserve),
    incurred: currencyFormatter.format(expenses_paid_sum + expenses_reserve),
  };
  const total = {
    type: 'Total',
    paid_to_date: currencyFormatter.format(expenses_paid_sum + indemnity_paid_sum),
    reserve_to_date: currencyFormatter.format(expenses_reserve + indemnity_reserve),
    incurred: currencyFormatter.format(expenses_paid_sum + expenses_reserve + indemnity_paid_sum + indemnity_reserve),
  };

  let rows = [indemnity, expenses, total];
  rows = rows.map((row, idx) => ({ ...row, id: idx }));

  return (
    <>
      <CardDialog containerStyle={{ overflow: 'scroll' }} outlinedCard title="Finance summary table">
        <PlainTable columns={columnData} rows={rows} />
      </CardDialog>
    </>
  );
}

export { WCClaimFinanceSummaryTable };
export default ClaimFinanceSummaryTable;
