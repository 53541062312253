import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import axios from 'axios';
import { Formik, getIn } from 'formik';
import iban from 'iban';
import { cloneDeep, isEmpty, uniqBy } from 'lodash';
import { Cash, TicketPercentOutline } from 'mdi-material-ui';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import InnerCard from '~/components/core/Cards/InnerCard';
import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';
import AnomalousPaymentBanner from '~/components/exposures/PaymentRequestContainer/MakePaymentRequestDialog/AnomalousPaymentBanner';
import {
  ApplyDeductibleCheckbox,
  checkDeductibleApplicationAndDeductibleSubReserve,
  DeductibleSumExplanation,
  getDeductibleConsideredAmount,
} from '~/components/exposures/PaymentRequestContainer/MakePaymentRequestDialog/DeductibleUtils';
import useFetchPaymentPossibility from '~/components/exposures/PaymentRequestContainer/MakePaymentRequestDialog/useFetchPaymentRequestPossibility';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { PAYMENT_MIXPANEL_EVENTS } from '../../../../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES, LOCATION_TYPES_DICT, PIP_HCRA_DICT } from '../../../../Types';
import {
  capitalize,
  isFeatureEnabled,
  isInshurClaim,
  isMgmClaim,
  isQoverClaim,
  isRwClaim,
  isTscClaim,
  reportAxiosError,
} from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import CheckboxFormik from '../../../CheckboxFormik';
import { localeDetails } from '../../../CmsMain/globals';
import mixpanel from '../../../CmsMain/mixpanel';
import { isTinRequired } from '../../../communications/ContactUtils';
import { ConfirmModal } from '../../../ConfirmModal';
import { LoadingSwitch, Text } from '../../../core';
import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';
import { useCurrencyFormatter } from '../../../CurrencyFormatterContext';
import DocumentTextFieldFormik from '../../../Documents/DocumentTextFieldFormik';
import FormikDocumentsAttachment from '../../../Documents/FormikDocumentsAttachment';
import { useCms } from '../../../hooks/useCms';
import useGlobalAddresses from '../../../hooks/useGlobalAddresses';
import { usePaymentsLimits } from '../../../hooks/usePaymentLimits';
import { useExposureSubReserves } from '../../../hooks/useSubReserves';
import useOrganization from '../../../OrganizationContext';
import RadioButtonFormik from '../../../RadioButtonFormik';
import { DatePickerTextFieldFormik, MonetaryValueTextFieldFormik, TextFieldFormik } from '../../../TextFieldFormik';
import ToggleButtonGroupFormik from '../../../ToggleButtonGroupFormik';
import { getAutoPersonCoverages, isLimitExists } from '../../ExposureUtils';
import {
  attachedDocumentsMaxHeight,
  getAnomalousPaymentBannerProps,
  getCharsLimitValidationMessage,
  getPayeeContacts,
  getPaymentsAboveReserveIssuesAndSeverities,
  getReferenceInitialValue,
  isStateCountry,
  PAY_TO_LENGTH_VALIDATION_FIELDS,
  shouldDisablePayment,
  shouldUseInvoiceForPaymentReferenceInitialValue,
} from '../paymentContainerUtils';
import PaymentMethodSelectorFormik from '../PaymentMethodSelectorFormik';
import getMethodSpecificPaymentRequest from '../PaymentMethodSpecificRequest';

import AdditionalPaymentVerificationDialog from './AdditionalPaymentVerification/AdditionalPaymentVerificationDialog';
import CheckDescriptionContainer from './CheckDescriptionContainer';
import ExpensesPayeesFormikInner from './ExpensesPayeesFormikInner';
import ExpensesPaymentTypeFieldFormik from './ExpensesPaymentTypeField';
import IndemnityPayment1099ReportFieldsFormik from './IndemnityPayment1099ReportFieldsFormik';
import IndemnityPaymentAmountInner from './IndemnityPaymentAmountInner';
import IndemnityPaymentTypeFieldFormik from './IndemnityPaymentTypeField';
import MailToFormikInner from './MailToFormikInner';
import MgmCompensationDatesFormikInner from './MgmCompensationDates';
import PaymentAmountFields from './PaymentAmountFields';
import PaymentRequestStorePayeesSelectFormikInner from './PaymentRequestStorePayeesSelectFormikInner';

import { useStyles } from '../../../../assets/styles';
import colors from '../../../../assets/colors.module.scss';
import styles from '../PaymentRequestContainer.module.scss';

function SumExplanationComponent({
  payableType,
  currencyFormatter,
  payableWithReserve,
  policyExposureLimitPerAccident,
  policyExposureLimit,
  exposure,
  claim,
}) {
  if (payableType === 'expenses') {
    return (
      <Typography display="block">{`Total expenses paid: ${currencyFormatter.format(
        payableWithReserve.paid_sum
      )}`}</Typography>
    );
  } else {
    const isDifferPerPersonAndAccident = !!policyExposureLimitPerAccident;
    return (
      <>
        {isLimitExists(policyExposureLimit) && (
          <Typography display="block">
            {`Coverage limit${isDifferPerPersonAndAccident ? ' per person' : ''}: ${currencyFormatter.format(
              policyExposureLimit
            )}`}
          </Typography>
        )}

        {isDifferPerPersonAndAccident && (
          <Typography display="block">
            {`Coverage limit per accident: ${currencyFormatter.format(policyExposureLimitPerAccident)}`}
          </Typography>
        )}

        {exposure.damage_assessment && exposure.damage_assessment?.amount && (
          <Typography display="block">
            {`Damage assessment: ${currencyFormatter.format(exposure.damage_assessment?.amount)}`}
          </Typography>
        )}

        <Typography display="block">{`Total paid: ${currencyFormatter.format(
          payableWithReserve.paid_sum
        )}`}</Typography>

        <DeductibleSumExplanation exposure={exposure} claim={claim} />
      </>
    );
  }
}

SumExplanationComponent.propTypes = {
  payableType: PropTypes.string.isRequired,
  currencyFormatter: PropTypes.object.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  policyExposureLimitPerAccident: PropTypes.number.isRequired,
  policyExposureLimit: PropTypes.number.isRequired,
  exposure: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
};

const AmountWatcher = ({ setAmount }) => {
  useFormikChangeListener({
    listenForKeys: ['amount'],
    onChange: (newValues, _) => {
      setAmount(newValues.amount ? Number(newValues.amount) : 0);
    },
    runOnFirstRender: true,
  });
  return <></>;
};

AmountWatcher.propTypes = {
  setAmount: PropTypes.func.isRequired,
};

function MakePaymentRequestDialog({
  onSubmitPaymentRequest,
  cardDialogProps,
  onCancel,
  claim,
  exposure,
  payableWithReserve,
  payableType,
  policyExposureLimit,
  policyExposureLimitPerAccident,
  overridePaymentRequestStore,
  defaultAmount,
  overrideInitialValues,
  forceManualDataInit = false,
}) {
  const { organizationContactRolesDict } = useOrganization();
  const { shouldDisablePaymentExceedingReserve } = usePaymentsConfiguration();
  const classes = useStyles();
  const exposure_name = exposure.isGeneralExpenses ? 'General Expenses' : exposure.coverage_type_desc;
  const [amount, setAmount] = React.useState(0);
  const { isLoadingPaymentIssues, policyLimitBreaches, isPaymentIssuePreventingPayment } = useFetchPaymentPossibility(
    claim.id,
    exposure.id,
    payableType,
    amount
  );

  const [showConfirmExpensesDialog, setShowConfirmExpensesDialog] = React.useState(false);
  const [showPreSubmitCheckResultsDialog, setShowPreSubmitCheckResultsDialog] = React.useState({ open: false });
  const [paymentRequestStore, setPaymentRequestStore] = React.useState(
    overridePaymentRequestStore ? { data: overridePaymentRequestStore } : {}
  );

  const { userOrganization, user } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();
  const { countriesOptions } = useGlobalAddresses({ countriesConfigurationKey: 'checks_address' });

  const { defaultPaymentMethod } = usePaymentsConfiguration();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(defaultPaymentMethod);
  const { userExposureLimit, isUserExposureLimitLoading } = usePaymentsLimits(
    claim,
    exposure,
    payableType,
    'payment',
    true
  );

  const {
    isLobPayment,
    shouldEnableDocuments,
    isOneIncPayment,
    shouldUseUKBankTransfer,
    shouldUseIbanBankTransfer,
    shouldUseMail,
    shouldUseReference,
    shouldUseCheckDescription,
    checkDescriptionLimit,
    isRequireApprovalForEveryPayment,
    shouldEnableReissued,
    mailToLimits,
    shouldEnableRecordOnly,
    prefillIndemnityReferenceWithClaimNumber,
    prefillExpensesReferenceWithInvoiceNumber,
    defaultPayToLineLimit,
    isPayeeEmailRequired,
    isPayeePhoneNumberRequired,
    isCustomMethod,
    paymentMethodDisplayName,
    referenceMaxLength,
    includeVatInPaymentScreen,
    show1099Fields,
    isDateOfBirthRequired,
    shouldUseExpensesLabels,
    adjustReserveIncludesDeductible,
    isPaymentLimitExistsForPayableType,
    getPaymentLimitForPayableType,
    isMultipleSubReservePaymentsEnabled,
  } = usePaymentsConfiguration(selectedPaymentMethod);

  const { isSubReservesConfigEnabled, subReservesConfigMap } = useExposureSubReserves(payableType, exposure);

  const {
    methodSpecificInitialValues,
    methodSpecificValidationSchema,
    PaymentDetailsComponent,
    MethodDetailsComponent,
  } = getMethodSpecificPaymentRequest(selectedPaymentMethod, userOrganization);

  const handlePaymentMethodChange = (method, methodConfig, resetForm) => {
    setSelectedPaymentMethod(method);

    setPaymentRequestStore({});

    resetForm({
      values: {
        ...getInitialValuesWithOverride(method),
        is_custom_method: methodConfig?.is_custom_method || false,
        custom_method_display_name: methodConfig?.is_custom_method ? methodConfig.payment_method_display_name : '',
        method_specific: getMethodSpecificPaymentRequest(method, userOrganization).methodSpecificInitialValues,
      },
    });
  };

  const [deliveryError, setDeliveryError] = React.useState(undefined);

  const compensationPeriodEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MGM_COMPENSATION_PERIOD_DATES
  );
  const shouldShowCompensationDates =
    compensationPeriodEnabled && exposure.coverage_type === 'coverage_wc_compensation';
  const isCheckPrintingEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PAYMENT_CHECKS);
  const shouldShowDatesOfService = exposure.should_include_dates_of_service && payableType === 'indemnity';
  const shouldUsePaymentRequestStoreForExpenses = isOneIncPayment;

  const deductibleConsideredAmount = getDeductibleConsideredAmount(claim, exposure, payableType);

  const _getTestForPayee = (scheme, isPayeeEmailRequired, isPayeePhoneNumberRequired) => {
    if (isPayeeEmailRequired) {
      scheme = scheme
        .test(
          'is-email-exists',
          'Contact must contain at least one email address',
          (contact) => !contact || (contact.emails && contact.emails.length > 0)
        )
        .test(
          'is-email-allowed',
          'Sending emails to this contact is not permitted',
          (contact) => !contact || contact.is_emailing_allowed !== false
        )
        .test(
          'is-email-not-empty',
          'Invalid email',
          (contact) => !contact || !contact.emails.find((emailObject) => emailObject.email === undefined)
        );
    }

    if (isPayeePhoneNumberRequired) {
      scheme = scheme.test(
        'is-phone-exists',
        'Contact must contain at least one phone number',
        (contact) => !contact || (contact.phones && contact.phones.length > 0)
      );
    }

    return scheme;
  };

  const getTestForPayee = (scheme) => {
    return _getTestForPayee(scheme, isPayeeEmailRequired, isPayeePhoneNumberRequired);
  };

  const getTestForContactSelected = (scheme, contact_title, shouldTestTin, paymentRequestStoreEntry = undefined) => {
    if (shouldTestTin) {
      scheme = scheme.test(
        'is-valid',
        `${contact_title} must contain a TIN`,
        (contact) => !isTinRequired(organizationContactRolesDict, contact) || contact.tin
      );
    }

    if (paymentRequestStoreEntry) {
      scheme = _getTestForPayee(
        scheme,
        paymentRequestStoreEntry.is_email_required,
        paymentRequestStoreEntry.is_phone_number_required
      );
    }
    return scheme;
  };

  const getTestForContactCountry = (scheme, contact_title) => {
    return scheme.test(
      'is-valid-country',
      `${contact_title} country is not supported for ${LOCATION_TYPES_DICT.checks_address}`,
      (contact) => !contact || countriesOptions.includes(contact?.country)
    );
  };

  const getTestAtLeastOnePayee = (scheme, paymentRequestStore) => {
    const possiblePrimaryPayees = paymentRequestStore.data.filter(
      (entry) => entry.type === 'optional_known_contact' && entry.can_be_primary
    );
    return scheme.test(
      'at-least-one-payee',
      `Either of ${possiblePrimaryPayees.map((entry) => entry.label).join(', ')} must be chosen`,
      function test() {
        return possiblePrimaryPayees.some(
          (entry) =>
            this.parent[
              entry.type === 'optional_known_contact' ? entry.field_name : 'should_include_' + entry.field_name
            ] === true
        );
      }
    );
  };

  function getExpensesPayeeValidationFields() {
    let validationFields = {
      expense_other_type: Yup.string().when('expense_type', {
        is: 'other',
        then: Yup.string().required('Required'),
      }),
    };

    if (shouldUsePaymentRequestStoreForExpenses) {
      validationFields = {
        ...validationFields,
        primary_payee_id: Yup.number().required('Primary payee must be selected'),
        primary_payee: getTestForPayee(Yup.object().required('Required')),
      };
      validationFields = getPaymentRequestStoreValidationFields(paymentRequestStore, validationFields);
    } else {
      validationFields = {
        ...validationFields,
        vendor_payee_id: Yup.number().required('Required'),
        vendor_payee: getTestForPayee(getTestForContactSelected(Yup.object().required('Required'), 'Vendor', true)),
        vendor_payee_email_id: isPayeeEmailRequired ? Yup.number().required('Required') : Yup.number(),
        vendor_payee_phone_id: isPayeePhoneNumberRequired ? Yup.number().required('Required') : Yup.number(),
      };
    }

    return validationFields;
  }

  const getPaymentRequestStoreValidationFields = (paymentRequestStore, validationFields, shouldTestTin) => {
    if (!paymentRequestStore.data) {
      return validationFields;
    }

    paymentRequestStore.data.forEach((paymentRequestStoreDataEntry) => {
      switch (paymentRequestStoreDataEntry.type) {
        case 'optional_known_contact':
          validationFields[paymentRequestStoreDataEntry.field_name] = Yup.bool();
          if (paymentRequestStoreDataEntry.can_be_primary) {
            validationFields[paymentRequestStoreDataEntry.field_name] = getTestAtLeastOnePayee(
              validationFields[paymentRequestStoreDataEntry.field_name],
              paymentRequestStore
            );
          }

          validationFields[paymentRequestStoreDataEntry.field_name + '_contact'] = Yup.object().when(
            paymentRequestStoreDataEntry.field_name,
            {
              is: true,
              then: getTestForContactSelected(Yup.object(), 'Contact', shouldTestTin, paymentRequestStoreDataEntry),
            }
          );
          break;
        case 'contact_search_single':
          validationFields[paymentRequestStoreDataEntry.field_name] = Yup.object().shape({
            contact_id: paymentRequestStoreDataEntry.is_required
              ? Yup.number().nullable().required('Required')
              : Yup.number(),
            contact: getTestForContactSelected(
              Yup.object().nullable(),
              'Contact',
              shouldTestTin,
              paymentRequestStoreDataEntry
            ),
            email_id: paymentRequestStoreDataEntry.is_email_required
              ? Yup.number().when('contact', { is: (contact) => !!contact, then: Yup.number().required('Required') })
              : undefined,
            phone_id: paymentRequestStoreDataEntry.is_phone_number_required
              ? Yup.number().when('contact', { is: (contact) => !!contact, then: Yup.number().required('Required') })
              : undefined,
          });
          break;
        case 'contact_search':
          validationFields[paymentRequestStoreDataEntry.field_name] = Yup.array().of(
            Yup.object().shape({
              contact_id:
                paymentRequestStoreDataEntry.is_required !== false
                  ? Yup.number().typeError('Required').required('Required')
                  : Yup.number(),
              contact: getTestForContactSelected(
                Yup.object().nullable(),
                'Contact',
                shouldTestTin,
                paymentRequestStoreDataEntry
              ),
            })
          );
          break;
        default:
          throw Error(`Unknown paymentRequestStoreDataEntry type: ${paymentRequestStoreDataEntry.type}`);
      }
    });

    // update primary_payee_id validation, now that we have the store
    validationFields['primary_payee_id'] = validationFields['primary_payee_id'].test(
      'valid-primary-payee',
      'Primary payee must be selected',
      function test() {
        return getPayeeContacts(this.parent, paymentRequestStore.data, claim)
          .map((contact) => contact.id)
          .includes(this.parent['primary_payee_id']);
      }
    );

    return validationFields;
  };

  function getIndemnityPayeeValidationFields() {
    const shouldEnforceTinForIndemnityContact = isFeatureEnabled(
      userOrganization,
      CONFIGURATION_FEATURES_NAMES.INDEMNITY_PAYMENTS_PAYEE_TEST
    );

    let validationFields = {
      primary_payee_id: Yup.number().required('Primary payee must be selected'),
      primary_payee: getTestForPayee(Yup.object().required('Required')),
      primary_payee_email_id: isPayeeEmailRequired ? Yup.number().required('Email is required') : Yup.number(),
      primary_payee_phone_id: isPayeePhoneNumberRequired
        ? Yup.number().required('Phone Number is required')
        : Yup.number(),
      primary_payee_date_of_birth: isDateOfBirthRequired
        ? Yup.string().test('require-date-of-birth', 'Date of Birth is required', (value, ctx) => {
            if (!ctx.parent.primary_payee?.is_company) {
              return ctx.parent.primary_payee?.date_of_birth;
            }
            return true;
          })
        : Yup.string(),
    };

    if (
      (isTscClaim(claim) || isInshurClaim(claim)) &&
      ['coverage_pip', 'coverage_apip'].includes(exposure.coverage_type)
    ) {
      validationFields['hcra'] = Yup.string().required('Required');
    }

    validationFields = getPaymentRequestStoreValidationFields(
      paymentRequestStore,
      validationFields,
      shouldEnforceTinForIndemnityContact
    );

    return validationFields;
  }

  function getInitialAmount() {
    if (defaultAmount) {
      return defaultAmount;
    }

    // on first auto payment, if we have a damage assessment amount - suggest the amount without the deductible
    if (
      claim.type === 'auto_claim' &&
      payableType === 'indemnity' &&
      !getAutoPersonCoverages().includes(exposure.coverage_type) &&
      exposure.damage_assessment?.amount &&
      deductibleConsideredAmount >= 0 &&
      exposure.damage_assessment?.amount > deductibleConsideredAmount
    ) {
      return exposure.damage_assessment?.amount - deductibleConsideredAmount;
    }

    return '';
  }

  function getInitialDeductibleAmount() {
    if (!isQoverClaim(claim)) {
      return '';
    }

    return exposure.policy_deductible;
  }

  function getInitialValues(paymentMethod = defaultPaymentMethod) {
    let initialValues = {
      payment_uuid: uuidv4(),
      deductible_type: exposure.policy_deductible_type?.toLowerCase() || '',
      gross_amount: '',
      deductible_amount: getInitialDeductibleAmount(),
      depreciation: 0,
      amount: getInitialAmount(),
      deductible_considered_amount: deductibleConsideredAmount,
      note: '',
      custom_pay_to_line: '',
      check_description: '',
      is_reissued_payment: false,
      is_record_only: false,
      payment_method: paymentMethod,
      compensation_type: '',
      number_of_units: '',
      hcra: '',
      iban: '',
      reference: getReferenceInitialValue(claim, payableType, prefillIndemnityReferenceWithClaimNumber),
      // the following are to ensure validation occurs
      mail_to_contact_id: '',
      mail_to_contact: '',
      pay_to_line: '',
      request_extra: {
        uk_bank: {
          uk_sort_code: '',
          uk_bank_account_number: '',
        },
      },
      check_req_document_id: '',
      use_invoice_for_payment_reference: shouldUseInvoiceForPaymentReferenceInitialValue(
        payableType,
        prefillExpensesReferenceWithInvoiceNumber
      ),
      is_custom_method: isCustomMethod,
      custom_method_display_name: paymentMethodDisplayName,
      is_vat_included: '',
      vat_amount: '',
      method_specific: methodSpecificInitialValues,
      method_specific_approval_requests: {},
      request_currency: claim?.policy?.policy_currency || localeDetails.currency,
    };

    if (isLobPayment || shouldEnableDocuments) {
      initialValues = { ...initialValues, attached_documents: [] };
    }

    if (isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.INTERNAL_PAYMENT_ATTACHMENTS)) {
      initialValues = { ...initialValues, internal_attached_documents: [] };
    }

    if (shouldShowCompensationDates) {
      initialValues = {
        ...initialValues,
        request_extra: {
          ...initialValues.request_extra,
          compensation_period: {
            compensation_start_date: null,
            compensation_end_date: null,
          },
        },
      };
    }

    if (shouldShowDatesOfService) {
      initialValues = {
        ...initialValues,
        service_start_date: '',
        service_end_date: '',
      };
    }

    if (isSubReservesConfigEnabled) {
      initialValues = {
        ...initialValues,
        sub_reserve_amounts: [{ type_key: '', amount: '' }],
      };
    }

    if (payableType === 'expenses') {
      return {
        ...initialValues,
        vendor_payee_id: '',
        vendor_payee: '',
        vendor_payee_phone_id: '',
        vendor_payee_email_id: '',
        invoice_number: '',
        expense_type: '',
        expense_other_type: '',
      };
    } else {
      initialValues = {
        ...initialValues,
        primary_payee_id: '',
        primary_payee: '',
        primary_payee_phone_id: '',
        primary_payee_email_id: '',
        primary_payee_date_of_birth: '',
      };

      if (show1099Fields) {
        initialValues = {
          ...initialValues,
          is_1099_reportable: '',
          reportable_1099_indemnity_amount: '',
        };
      }

      return initialValues;
    }
  }

  function getPayeeValidationFields() {
    if (payableType === 'expenses') {
      return getExpensesPayeeValidationFields();
    } else {
      return getIndemnityPayeeValidationFields();
    }
  }

  function cleanValuesFromOptionalEmptyPayees(values) {
    if (!paymentRequestStore.data) {
      return values;
    }

    let cleanValues = cloneDeep(values);

    paymentRequestStore.data.forEach((paymentRequestStoreDataEntry) => {
      switch (paymentRequestStoreDataEntry.type) {
        case 'optional_known_contact':
          break;
        case 'contact_search_single': {
          const contactEntry = values[paymentRequestStoreDataEntry.field_name];
          if (!contactEntry.contact_id) {
            delete cleanValues[paymentRequestStoreDataEntry.field_name];
          }
          break;
        }
        case 'contact_search':
          cleanValues[paymentRequestStoreDataEntry.field_name] = cleanValues[
            paymentRequestStoreDataEntry.field_name
          ].filter((payeeEntry) => payeeEntry.contact_id);
          break;
        default:
          throw Error(`Unknown paymentRequestStoreDataEntry type: ${paymentRequestStoreDataEntry.type}`);
      }
    });

    return cleanValues;
  }

  async function handleSubmitPayment(values, resetForm, setSubmitting) {
    const valuesToSubmit = cleanValuesFromOptionalEmptyPayees(values);

    try {
      // true - skipReportAxiosError. Because we check for address is undeliverable we need to reportAxiosError ourself
      await onSubmitPaymentRequest(valuesToSubmit, true);

      mixpanel.track(PAYMENT_MIXPANEL_EVENTS.SET_PAYMENT_SUBMITTED, {
        method: valuesToSubmit.payment_method,
        amount: valuesToSubmit.amount,
        mark_as_reissued_payment: valuesToSubmit.is_reissued_payment,
        mark_as_record_only: valuesToSubmit.is_record_only,
        payment_reason: valuesToSubmit.payment_reason,
      });

      resetForm({ values: getInitialValues(selectedPaymentMethod) });
    } catch (error) {
      if (error?.response?.data?.message?.startsWith('Notify User: Address is undeliverable')) {
        setDeliveryError(error.response.data.message.split(':')[2]);
      } else {
        await reportAxiosError(error);
      }
      setSubmitting(false);
    }
  }

  function getInitialValuesWithOverride(paymentMethod = defaultPaymentMethod) {
    const initialValues = getInitialValues(paymentMethod);
    return {
      ...initialValues,
      ...overrideInitialValues,
      request_extra: {
        ...initialValues.request_extra,
        ...overrideInitialValues?.request_extra,
      },
      payment_data_init_method: overrideInitialValues && !forceManualDataInit ? 'auto' : 'manual',
    };
  }

  const shouldIncludeCheckReqDocument =
    (isMgmClaim(claim) || isRwClaim(claim)) &&
    claim.type === 'gl_claim' &&
    !['event_mgm_property', 'event_mgm_arena', 'claim_mgm_72'].includes(claim.subtype);

  const getMailToValidations = () => {
    return getTestForContactCountry(Yup.object(), 'Contact')
      .test(
        'is-valid',
        'Contact must contain address1, city, postal code and state or country',
        (value) =>
          !value ||
          (value.street_address1 &&
            value.city &&
            (isStateCountry(value.country) ? value.state : value.country) &&
            value.zipcode)
      )
      .test('mail-to-max-size', 'Mail to fields cannot exceed 36 characters', function test(mailToContact) {
        if (!mailToContact) {
          return true;
        }

        const createError = function createError({ name, maxChars, value }) {
          return this.createError({
            message: `Mail to contact ${name} cannot exceed ${maxChars} (current length is ${value.length} characters)`,
          });
        }.bind(this);

        for (const { fieldKey, maxCharsKey, description, joinedFieldsList } of PAY_TO_LENGTH_VALIDATION_FIELDS) {
          const fieldValue = joinedFieldsList
            ? joinedFieldsList.map((field) => mailToContact[field] || '').join(', ')
            : mailToContact[fieldKey];
          const maxLength = mailToLimits?.[maxCharsKey];
          if (maxLength && fieldValue?.length > maxLength) {
            const error = createError({
              name: description,
              maxChars: maxLength,
              value: fieldValue,
            });
            return error;
          }
        }

        return true;
      })
      .test(
        'mail-to-address-in-checks-max-size',
        'Mail to fields in printed checks cannot exceed 50 characters',
        function testLength(mailToContact) {
          if (!mailToContact || !isCheckPrintingEnabled) {
            return true;
          }

          const checkLines = [
            { fields: ['street_address1', 'street_address2'], desc: 'Address 1 and Address 2', maxChars: 50 },
            {
              fields: ['city', 'county', 'state', 'zipcode'],
              desc: 'City, County, State and Zip code',
              maxChars: 50,
            },
          ];

          for (const checkLineKey in checkLines) {
            const checkLine = checkLines[checkLineKey];
            const checkLineStr = checkLine.fields.map((field) => mailToContact[field]).join(', ');
            if (checkLineStr.length > checkLine.maxChars) {
              return this.createError({
                message: `Mail to contact ${checkLine.desc} cannot exceed together ${checkLine.maxChars} characters (current length is ${checkLineStr.length} characters)`,
              });
            }
          }

          return true;
        }
      );
  };

  const getReferenceValidation = (claim, payableType) => {
    return Yup.string().when(
      ['use_invoice_for_payment_reference', 'payment_method'],
      (useInvoiceForPaymentReference, paymentMethod, currSchema) => {
        if (payableType === 'expenses' && useInvoiceForPaymentReference) {
          return currSchema;
        }

        if (referenceMaxLength) {
          return currSchema
            .required('Required')
            .max(referenceMaxLength, getCharsLimitValidationMessage(referenceMaxLength));
        }
        return currSchema;
      }
    );
  };

  const payToLineMaxSize = mailToLimits?.pay_to_line_max_length ?? defaultPayToLineLimit;
  const validatePayToLineMaxLength = (value) => {
    return !value || payToLineMaxSize === 0 || value.length <= payToLineMaxSize;
  };

  const { shouldChooseDeductibleSubReserve } = checkDeductibleApplicationAndDeductibleSubReserve({
    adjustReserveIncludesDeductible,
    isMultipleSubReservePaymentsEnabled,
    subReservesConfigMap,
    claimType: claim.type,
    exposure,
  });

  return (
    <Formik
      initialValues={getInitialValuesWithOverride(selectedPaymentMethod)}
      // don't enableReinitialize - otherwise, everything will reset when mortgagee is force/unforced
      validationSchema={Yup.object().shape({
        deductible_type:
          isQoverClaim(claim) && payableType === 'indemnity' ? Yup.string().required('Required') : undefined,
        gross_amount:
          isQoverClaim(claim) && payableType === 'indemnity'
            ? Yup.number()
                .required('Required')
                .min(0.01, `Must be at least ${currencyFormatter.format(0.01)}`)
            : undefined,
        deductible_amount:
          isQoverClaim(claim) && payableType === 'indemnity' ? Yup.number().required('Required') : undefined,
        depreciation:
          isQoverClaim(claim) && payableType === 'indemnity' ? Yup.number().required('Required') : undefined,
        amount: Yup.number()
          .required('Required')
          .min(0.01, `Must be at least ${currencyFormatter.format(0.01)}`),
        payment_method: Yup.string().required('Required'),
        number_of_units: Yup.number().when('payment_method', {
          is: 'compensation',
          then: Yup.number().required('Required'),
        }),
        compensation_type: Yup.string().when('payment_method', {
          is: 'compensation',
          then: Yup.string().required('Required'),
        }),
        pay_to_line: Yup.string().test(
          'pay-to-max-size',
          ({ value }) =>
            `Pay to cannot exceed ${payToLineMaxSize} characters (current length is ${value.length} characters)`,
          validatePayToLineMaxLength
        ),
        mail_to_contact_id: shouldUseMail ? Yup.number().required('Required') : undefined,
        mail_to_contact: shouldUseMail ? getMailToValidations() : undefined,
        request_extra: Yup.object().shape({
          uk_bank: !shouldUseUKBankTransfer
            ? undefined
            : Yup.object().shape({
                uk_sort_code: Yup.string()
                  .required('Required')
                  .test(
                    'uk_sort_code-validity',
                    'Sort code must be of the form 20-00-00 or 200000',
                    (value) => !value || /^\d{2}-\d{2}-\d{2}$/.test(value) || /^\d{6}$/.test(value)
                  ),
                uk_bank_account_number: Yup.string()
                  .required('Required')
                  .test(
                    'uk_bank_account_number-validity',
                    'Account number must be 8 digits',
                    (value) => !value || /^\d{8}$/.test(value)
                  ),
              }),
          compensation_period: !shouldShowCompensationDates
            ? undefined
            : Yup.object().shape({
                compensation_start_date: Yup.date().required('Required'),
                compensation_end_date: Yup.date().required('Required'),
              }),
        }),
        service_start_date: shouldShowDatesOfService ? Yup.date().required('Required') : undefined,
        service_end_date: shouldShowDatesOfService ? Yup.date().required('Required') : undefined,
        invoice_number: Yup.string().when('use_invoice_for_payment_reference', {
          is: true,
          then: Yup.string().max(18, 'max 18 characters'),
        }),
        reference: getReferenceValidation(claim, payableType),
        iban: shouldUseIbanBankTransfer
          ? Yup.string()
              .required('Required')
              .test('iban-validity', 'Invalid IBAN', (value) => !value || iban.isValid(value))
          : undefined,
        check_description: Yup.string().test(
          'check-description-max-size',
          ({ value }) => `Check description cannot exceed 60 characters (current length is ${value.length} characters)`,
          (value) => !shouldUseCheckDescription || !value || value.length <= checkDescriptionLimit
        ),
        ...getPayeeValidationFields(paymentRequestStore),
        check_req_document_id: shouldIncludeCheckReqDocument ? Yup.number().required('Required') : undefined,
        is_custom_method: Yup.boolean(),
        custom_method_display_name: Yup.string(),
        is_1099_reportable: payableType === 'indemnity' && show1099Fields ? Yup.bool().required('Required') : undefined,
        reportable_1099_indemnity_amount:
          payableType === 'indemnity' && show1099Fields
            ? Yup.number()
                .when('is_1099_reportable', (is_1099_reportable, currSchema) =>
                  !is_1099_reportable ? currSchema : currSchema.required('Required')
                )
                .when('amount', (amount, currSchema) =>
                  !amount ? currSchema : currSchema.max(amount, 'Must be lower than total amount paid')
                )
            : undefined,
        is_vat_included: includeVatInPaymentScreen ? Yup.boolean().required('Required') : Yup.boolean(),
        vat_amount: Yup.number().when('is_vat_included', (is_vat_included, currSchema) =>
          is_vat_included
            ? currSchema
                .required('Required')
                .test(
                  'is-bigger-than-amount',
                  'VAT amount cannot be bigger than payment amount',
                  (value, context) => value <= context.parent.amount
                )
            : currSchema
        ),
        method_specific: methodSpecificValidationSchema,
        sub_reserve_amounts: isSubReservesConfigEnabled
          ? Yup.array()
              .of(
                Yup.object().shape({
                  type_key: Yup.string().required('Required'),
                  amount: Yup.number().typeError('Required').min(1, 'Required').required('Required'),
                })
              )
              .required('Required')
              .min(1, 'Required')
              .when([], (schema) =>
                isMultipleSubReservePaymentsEnabled ? schema : schema.max(1, 'Only one sub reserve is allowed')
              )
              .test('unique-type_key', 'Each sub reserve can only be chosen once', function (value) {
                return uniqBy(value, 'type_key').length === (value || []).length;
              })
          : undefined,
        sub_reserve_key_for_adjust_reserve_deductible: Yup.string().when('deductible_considered_amount', {
          is: (value) => {
            return value > 0 && shouldChooseDeductibleSubReserve;
          },
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        expense_type:
          payableType === 'expenses' && shouldUseExpensesLabels ? Yup.string().required('Required') : undefined,
      })}
      onSubmit={async (values, formikProps) => {
        if (!showPreSubmitCheckResultsDialog.open) {
          try {
            const payeeContacts =
              payableType === 'indemnity' || shouldUsePaymentRequestStoreForExpenses
                ? getPayeeContacts(values, paymentRequestStore.data, claim)
                : [values['vendor_payee']];

            const preSubmitChecks = await axios.get(`/api/v1/claims/${claim.id}/payment_pre_submit_checks`, {
              params: {
                exposure_id: exposure.id !== 0 ? exposure.id : undefined,
                contact_ids: payeeContacts.map((contact) => contact.id),
                payment_method: values.payment_method,
                payable_type: payableType,
                amount: values.amount,
                primary_payee_id: values.primary_payee_id,
                request_extra: values.request_extra,
              },
            });

            if (!isEmpty(preSubmitChecks?.data)) {
              setShowPreSubmitCheckResultsDialog({
                open: true,
                preSubmitChecks: preSubmitChecks.data,
              });
              return;
            }
          } catch (error) {
            reportAxiosError(error);
            formikProps.setSubmitting(false);
            return;
          }
        }

        if (payableType === 'expenses' && values.amount >= 10000) {
          setShowConfirmExpensesDialog(true);
          formikProps.setSubmitting(false);
          return;
        }

        if (payableType === 'expenses' && getIn(values, 'use_invoice_for_payment_reference')) {
          values.reference = values.invoice_number;
        }

        await handleSubmitPayment(values, formikProps.resetForm, formikProps.setSubmitting);
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, setFieldValue, values, resetForm, setSubmitting } = formikProps;
        const reserveAdjustmentAmount =
          adjustReserveIncludesDeductible && values['deductible_considered_amount']
            ? amount + values['deductible_considered_amount']
            : amount;

        const issuesAndSeveritiesForPaymentsAboveReserves = getPaymentsAboveReserveIssuesAndSeverities({
          isSubReservesConfigEnabled,
          isMultipleSubReservePaymentsEnabled,
          payableWithReserve,
          paymentAmount: reserveAdjustmentAmount,
          amountWithoutDeductible: amount,
          subReserveAmounts: values['sub_reserve_amounts'],
          subReserveKeyForAdjustReserveDeductible: values['sub_reserve_key_for_adjust_reserve_deductible'],
          shouldDisablePaymentExceedingReserve,
          subReservesConfigMap,
          currencyFormatter,
        });

        const checkIsExceedsUserAuthority = () => isLimitExists(userExposureLimit) && userExposureLimit < amount;

        const checkIsExceedsClosedExposurePaymentLimit = () => {
          return (
            (exposure.is_closed || claim.is_closed) &&
            isPaymentLimitExistsForPayableType(payableType) &&
            getPaymentLimitForPayableType(payableType) < amount
          );
        };

        const isMethodSpecificApproveRequired =
          !user.is_org_level_supervisor && Object.keys(values?.method_specific_approval_requests || {}).length > 0;
        const isRequireApprove = () =>
          checkIsExceedsUserAuthority() || isRequireApprovalForEveryPayment || isMethodSpecificApproveRequired;
        const readOnly = values['payment_data_init_method'] === 'auto';
        const disablePayment = shouldDisablePayment({
          shouldDisablePaymentExceedingReserve,
          issuesAndSeveritiesForPaymentsAboveReserves,
          isSubmitting,
          checkIsExceedsClosedExposurePaymentLimit,
          isLoadingPaymentIssues,
          isPaymentIssuePreventingPayment,
        });

        const subtitle = (() => {
          if (!exposure.label_text) {
            return `${exposure_name}`;
          }

          // TODO: generalize
          if (claim.type === 'wc_claim') {
            return `${exposure.label_text}`;
          }

          return `${exposure.label_text} - ${capitalize(payableType)}`;
        })();

        return (
          <>
            <AmountWatcher setAmount={setAmount} />
            <CardDialog
              className={styles.mainContainer}
              containerClassName={styles.mainContainer}
              title="Set Payment"
              preventClose={isSubmitting}
              {...cardDialogProps}
              // This can be either card or dialog, so onCancel isn't mandatory (e.g. in case of a card inside another card)
              onClose={
                onCancel
                  ? () => {
                      formikProps.resetForm({ values: getInitialValues() });
                      onCancel();
                    }
                  : undefined
              }
            >
              <LoadingSwitch isLoading={isUserExposureLimitLoading}>
                <span className={styles.subtitle}>{subtitle}</span>
                {(isMgmClaim(claim) || isRwClaim(claim)) && claim.type === 'gl_claim' && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography display="block" style={{ marginRight: '8px' }}>
                      Payment method:
                    </Typography>
                    <ToggleButtonGroupFormik
                      id="payment_method"
                      size="small"
                      onChange={(method, methodConfig) => handlePaymentMethodChange(method, methodConfig, resetForm)}
                    >
                      <ToggleButton value="check">
                        <>
                          <Cash />
                          &nbsp; Check
                        </>
                      </ToggleButton>
                      <ToggleButton value="compensation">
                        <>
                          <TicketPercentOutline />
                          &nbsp; Compensation
                        </>
                      </ToggleButton>
                    </ToggleButtonGroupFormik>
                  </div>
                )}
                <div style={{ marginTop: '12px', marginBottom: '6px' }}>
                  <SumExplanationComponent
                    payableType={payableType}
                    currencyFormatter={currencyFormatter}
                    payableWithReserve={payableWithReserve}
                    policyExposureLimitPerAccident={policyExposureLimitPerAccident}
                    policyExposureLimit={policyExposureLimit}
                    exposure={exposure}
                    claim={claim}
                  />
                </div>
                <Divider className={styles.divider} />
                <PaymentMethodSelectorFormik
                  claim={claim}
                  onPaymentMethodSelection={(method, methodConfig) =>
                    handlePaymentMethodChange(method, methodConfig, resetForm)
                  }
                />
                <Typography className={styles.sectionTitle}>Payment details</Typography>
                <AnomalousPaymentBanner
                  {...getAnomalousPaymentBannerProps({
                    checkIsExceedsUserAuthority,
                    checkIsExceedsClosedExposurePaymentLimit,
                    issuesAndSeveritiesForPaymentsAboveReserves,
                    policyLimitBreaches,
                    isPaymentIssuePreventingPayment,
                  })}
                />
                <PaymentDetailsComponent payableType={payableType} paymentRequestStore={paymentRequestStore} />
                <Grid container justify="space-between" spacing={1}>
                  {payableType === 'indemnity' ? (
                    <>
                      <IndemnityPaymentAmountInner
                        claim={claim}
                        exposure={exposure}
                        readOnly={readOnly}
                        payableWithReserve={payableWithReserve}
                      />
                      <ApplyDeductibleCheckbox exposure={exposure} claim={claim} />
                    </>
                  ) : (
                    <Grid item xs={isSubReservesConfigEnabled ? 12 : 3}>
                      <PaymentAmountFields
                        readOnly={readOnly}
                        payableType={payableType}
                        exposure={exposure}
                        payableWithReserve={payableWithReserve}
                      />
                    </Grid>
                  )}

                  <Grid item xs={9} />
                  {includeVatInPaymentScreen && (
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={3}>
                        <Text variant={Text.VARIANTS.XS}>Is VAT included?</Text>
                        <ErrorHelperTextFormik id="is_vat_included" />
                      </Grid>
                      <Grid item xs={9}>
                        <RadioButtonFormik
                          id="is_vat_included"
                          label="No"
                          optionValue={false}
                          disabled={readOnly}
                          color={colors.buttonLink}
                          size="small"
                        />
                        <RadioButtonFormik
                          id="is_vat_included"
                          label="Yes"
                          optionValue={true}
                          disabled={readOnly}
                          color={colors.buttonLink}
                          size="small"
                        />
                      </Grid>
                      {values.is_vat_included ? (
                        <Grid item xs={6}>
                          <MonetaryValueTextFieldFormik
                            id="vat_amount"
                            label="VAT Amount"
                            className={classes.textField}
                            disabled={readOnly}
                            fullWidth
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={6} />
                      )}
                    </Grid>
                  )}
                </Grid>
                <br />

                {payableType === 'expenses' ? (
                  <ExpensesPaymentTypeFieldFormik exposure={exposure} claim={claim} readOnly={readOnly} />
                ) : (
                  <IndemnityPaymentTypeFieldFormik exposure={exposure} claim={claim} readOnly={readOnly} />
                )}
                <InnerCard title="" className={styles.payeesCard}>
                  {payableType === 'expenses' ? (
                    <>
                      {shouldUsePaymentRequestStoreForExpenses ? (
                        <PaymentRequestStorePayeesSelectFormikInner
                          claim={claim}
                          exposure={exposure}
                          payableType={payableType}
                          amount={amount}
                          paymentRequestStore={paymentRequestStore}
                          onChangePaymentRequestStore={setPaymentRequestStore}
                          setFieldValue={formikProps.setFieldValue}
                          values={formikProps.values}
                          disableUpdatingPaymentRequestStore={!!overridePaymentRequestStore}
                          readOnly={readOnly}
                        />
                      ) : (
                        <ExpensesPayeesFormikInner claim={claim} exposure={exposure} readOnly={readOnly} />
                      )}
                    </>
                  ) : (
                    <>
                      {shouldShowCompensationDates && <MgmCompensationDatesFormikInner />}
                      <PaymentRequestStorePayeesSelectFormikInner
                        claim={claim}
                        exposure={exposure}
                        payableType={payableType}
                        amount={amount}
                        paymentRequestStore={paymentRequestStore}
                        onChangePaymentRequestStore={setPaymentRequestStore}
                        setFieldValue={formikProps.setFieldValue}
                        values={formikProps.values}
                        disableUpdatingPaymentRequestStore={!!overridePaymentRequestStore}
                        readOnly={readOnly}
                      />

                      {(isTscClaim(claim) || isInshurClaim(claim)) &&
                        ['coverage_pip', 'coverage_apip'].includes(exposure.coverage_type) && (
                          <div className={classes.inputContainer}>
                            <TextFieldFormik
                              id="hcra"
                              select
                              label="HCRA"
                              className={classes.textFieldRow}
                              disabled={readOnly}
                            >
                              {Object.keys(PIP_HCRA_DICT).map((option) => (
                                <MenuItem key={option} value={option}>
                                  {PIP_HCRA_DICT[option]}
                                </MenuItem>
                              ))}
                            </TextFieldFormik>
                          </div>
                        )}
                    </>
                  )}
                </InnerCard>

                {shouldShowDatesOfService && (
                  <Grid container xs={12} spacing={1}>
                    <Grid item xs={6}>
                      <DatePickerTextFieldFormik
                        id="service_start_date"
                        label="Service Start Date"
                        className={classes.textField}
                        disableFuture
                        fullWidth
                        clearable
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePickerTextFieldFormik
                        id="service_end_date"
                        label="Service End Date"
                        className={classes.textField}
                        disableFuture
                        fullWidth
                        clearable
                      />
                    </Grid>
                  </Grid>
                )}
                {payableType === 'indemnity' && show1099Fields && (
                  <IndemnityPayment1099ReportFieldsFormik amount={amount} readOnly={readOnly} />
                )}

                <Divider className={styles.divider} />
                <Typography className={styles.sectionTitle}>Method details</Typography>
                <MethodDetailsComponent />

                {values['payment_method'] !== 'compensation' && shouldEnableReissued && (
                  <Grid item xs={12}>
                    <CheckboxFormik id="is_reissued_payment" label="Mark as reissued payment" disabled={readOnly} />
                  </Grid>
                )}

                {shouldEnableRecordOnly && (
                  <Grid item xs={12}>
                    <CheckboxFormik id="is_record_only" label="Mark as record only" disabled={readOnly} />
                  </Grid>
                )}

                {shouldUseMail && values['payment_method'] !== 'compensation' && (
                  <div style={{ marginTop: '24px' }}>
                    <MailToFormikInner showOnly={readOnly} />
                  </div>
                )}

                {payableType === 'expenses' && !isQoverClaim(claim) && (
                  <>
                    <TextFieldFormik id="invoice_number" label="Invoice #" fullWidth className={classes.textField} />
                    {shouldUseReference && (
                      <CheckboxFormik
                        id="use_invoice_for_payment_reference"
                        label="Also use invoice number as payment reference"
                        disabled={readOnly}
                      />
                    )}
                  </>
                )}

                <Grid container direction="row" spacing={1}>
                  {shouldUseIbanBankTransfer && (
                    <Grid item xs={6}>
                      <TextFieldFormik id="iban" label="IBAN" className={classes.textFieldRow} disabled={readOnly} />
                    </Grid>
                  )}

                  {shouldUseUKBankTransfer && (
                    <>
                      <Grid item xs={4}>
                        <TextFieldFormik
                          id="request_extra.uk_bank.uk_sort_code"
                          label="Sort Number"
                          className={classes.textField}
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextFieldFormik
                          id="request_extra.uk_bank.uk_bank_account_number"
                          label="Account Number"
                          className={classes.textField}
                          disabled={readOnly}
                        />
                      </Grid>
                    </>
                  )}

                  {/* shouldUseUKBankTransfer || shouldUseIbanBankTransfer can be removed once v2 is deprecated*/}
                  {(shouldUseReference || shouldUseUKBankTransfer || shouldUseIbanBankTransfer) &&
                    !getIn(values, 'use_invoice_for_payment_reference') && (
                      <Grid item xs={shouldUseUKBankTransfer ? 4 : 6}>
                        <TextFieldFormik
                          id="reference"
                          label="Reference"
                          className={shouldUseUKBankTransfer ? classes.textField : classes.textFieldRow}
                          disabled={readOnly}
                          fullWidth
                        />
                      </Grid>
                    )}
                </Grid>

                {shouldIncludeCheckReqDocument && (
                  <div style={{ marginTop: '16px' }}>
                    <DocumentTextFieldFormik
                      id="check_req_document_id"
                      label="Check Requisition Form"
                      initialValues={{ type: 'check_requisition_form' }}
                      allowTemplates
                    />
                  </div>
                )}
                {(isLobPayment || shouldEnableDocuments) && (
                  <>
                    <FormikDocumentsAttachment
                      id="attached_documents"
                      maxHeight={attachedDocumentsMaxHeight}
                      documentsAttachmentProps={{ hideAttachmentFilename: true, showOnly: readOnly }}
                    />
                    <Typography variant="caption">
                      All documents added will be sent to the payee with the payment
                    </Typography>
                  </>
                )}
                {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.INTERNAL_PAYMENT_ATTACHMENTS) && (
                  <>
                    <FormikDocumentsAttachment
                      id="internal_attached_documents"
                      maxHeight={attachedDocumentsMaxHeight}
                      documentsAttachmentProps={{ hideAttachmentFilename: true, showOnly: readOnly }}
                    />
                    <Typography variant="caption">
                      All documents added will be saved internally with the payment
                    </Typography>
                  </>
                )}
                <div className={classes.inputContainer}>
                  <TextFieldFormik id="payment_reason" label="Payment Reason" className={classes.textField} fullWidth />
                </div>
                <div className={classes.inputContainer}>
                  <TextFieldFormik
                    id="note"
                    label="Description"
                    rows="2"
                    className={classes.textField}
                    fullWidth
                    multiline
                    disabled={readOnly}
                  />
                </div>
                <CheckDescriptionContainer />
                <div className={classes.buttonsContainer}>
                  {readOnly && (
                    <Button
                      className={classes.leftButtonDialog}
                      variant="contained"
                      color="primary"
                      onClick={() => setFieldValue('payment_data_init_method', 'auto_override')}
                      disabled={isSubmitting}
                    >
                      Edit Fields
                    </Button>
                  )}
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disablePayment}>
                    {isRequireApprove() ? 'Request Supervisor Approval' : 'Make Payment'}
                  </Button>
                </div>
              </LoadingSwitch>
            </CardDialog>
            <ConfirmModal
              isOpen={showConfirmExpensesDialog}
              title="Make Payment?"
              isSubmitting={isSubmitting}
              contentText={`Are you sure you want to make a payment of ${currencyFormatter.format(values.amount)}?`}
              secondaryButtonName="No"
              primaryButtonName="Yes"
              onClose={() => setShowConfirmExpensesDialog(false)}
              onPrimaryBtnClick={async () => {
                setSubmitting(true);
                setShowConfirmExpensesDialog(false);
                await handleSubmitPayment(values, resetForm, setSubmitting);
              }}
            />
            {showPreSubmitCheckResultsDialog.open && (
              <AdditionalPaymentVerificationDialog
                onClose={() => setShowPreSubmitCheckResultsDialog({ open: false })}
                isSubmitting={isSubmitting}
                values={values}
                disabled={isSubmitting}
                preSubmitChecks={showPreSubmitCheckResultsDialog.preSubmitChecks}
                onProceed={async (overrideDetails) => {
                  setFieldValue('additional_verifications_extra', {
                    results: showPreSubmitCheckResultsDialog.preSubmitChecks,
                    override_details: overrideDetails,
                  });
                  await handleSubmit();
                }}
              />
            )}
            <ConfirmModal
              isOpen={!!deliveryError}
              title="Send to Undeliverable Address?"
              isSubmitting={formikProps.isSubmitting}
              contentText={`The address ${deliveryError} was not recognized by USPS and might be undeliverable. If you are sure the address is correct, we can try to send anyway.`}
              primaryButtonName="Make Payment Anyway"
              onClose={() => setDeliveryError(undefined)}
              onPrimaryBtnClick={async () => {
                // This is Hack. we piggyback compliance_check_extra for this. otherwise we'll need to add a new parameter and pass it all the way to lop_api.py...
                setFieldValue('compliance_check_extra', {
                  lob_override_address_verification: 'override',
                });
                await formikProps.handleSubmit();
              }}
            />
          </>
        );
      }}
    </Formik>
  );
}
MakePaymentRequestDialog.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  onSubmitPaymentRequest: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  policyExposureLimit: PropTypes.number.isRequired,
  policyExposureLimitPerAccident: PropTypes.number,
  overridePaymentRequestStore: PropTypes.array,
  defaultAmount: PropTypes.number,
  overrideInitialValues: PropTypes.object,
  forceManualDataInit: PropTypes.bool,
};

export default MakePaymentRequestDialog;
