import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const DocumentPhoneIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <path
      d="M20.2008 7.99687c0-.1-.1-.19999-.2-.19999l-5.2-5.20001c-.1-.1-.2-.09999-.2-.19999-.1 0-.2-.1-.3-.1H5.30078c-.4 0-.8.2-1.1.4-.3.2-.4.7-.4 1v9.00002c0 .4.3.7.8.7s.8-.3.8-.7V3.69688h8.20002v4.49999c0 .4.3.8.8.8h4.5V20.2969h-.9c-.4 0-.8.3-.8.8s.3.8.8.8h.8c.4 0 .8-.2 1.1-.4.3-.3.4-.7.4-1.1V8.39687c-.1-.3-.1-.3-.1-.4Zm-5.2-3.19999 2.7 2.69999h-2.7V4.79688Z"
      fill={iconColor}
    />
    <path
      d="m14.7008 18.2969-2.2-.9c-.2-.1-.4-.1-.6-.1-.2 0-.4.1-.5.2l-1 .7c-.50002-.3-.90002-.7-1.20002-1.2l.7-1c.10002-.2.20002-.3.20002-.5s0-.4-.1-.5l-1.00002-2.3c-.1-.2-.3-.4-.5-.5-.1-.2-.4-.2-.6-.2-.7.1-1.4.4-1.9 1-.5.5-.7 1.3-.7 2 0 1.9.7 3.7 2.1 5 1.3 1.3 3.10002 2.1 5.00002 2.1.7 0 1.4-.3 2-.7.5-.5.9-1.1 1-1.9 0-.2 0-.5-.1-.7-.2-.2-.4-.4-.6-.5Zm-1.3 1.9c-.3.2-.6.4-1 .4-1.5 0-2.90002-.6-4.00002-1.6-1.1-1.1-1.6-2.5-1.6-4 0-.4.1-.7.4-1 .2-.2.4-.4.7-.4l.7 1.7-.7 1c-.1.2-.2.3-.2.5s0 .4.1.6c.5.9 1.2 1.7 2.20002 2.2.2.1.4.1.6.1.2 0 .4-.1.5-.2l1-.7 1.7.7c-.1.3-.2.6-.4.7Z"
      fill={iconColor}
    />
  </SvgIcon>
);

DocumentPhoneIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default DocumentPhoneIcon;
