import React, { lazy, Suspense, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CardDialog from '~/components/CardDialog';
import type { VideoCallCommunication } from '~/components/communications/types';
import DyteDialogLoader from '~/components/communications/VideoCommunication/Dyte/DyteDialogLoader';
import VideoCallsDyteTopBar from '~/components/communications/VideoCommunication/Dyte/VideoCallsDyteTopBar';
import cn from '~/Utils/cn';

const VideoCallsDyte = lazy(() => import(/* webpackChunkName: "VideoCallsDyte" */ './VideoCallsDyte'));

// needed to override material ui card in CardDialog
const useStyles = makeStyles({
  container: {
    '&:first-child': {
      paddingTop: 0,
    },
  },
});

interface Props {
  communication: VideoCallCommunication;
  onClose: () => void;
}

const VideoCallsDyteDialog: React.FC<Props> = ({ communication, onClose }) => {
  const [allowClose, setAllowClose] = useState(true);

  const classes = useStyles();

  return (
    <CardDialog isDialog fullScreen noCardTitle hideTitleContainer containerClassName={cn(classes.container, 'p-0')}>
      <VideoCallsDyteTopBar communication={communication} allowClose={allowClose} onClose={onClose} />

      <Suspense fallback={<DyteDialogLoader />}>
        <VideoCallsDyte communication={communication} setAllowClose={setAllowClose} />
      </Suspense>
    </CardDialog>
  );
};

export default VideoCallsDyteDialog;
