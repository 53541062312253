import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const ThumbUpIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      d="M7.5 20.25H3C2.6 20.25 2.22 20.09 1.94 19.81C1.66 19.53 1.5 19.14 1.5 18.75V10.5C1.5 10.1 1.66 9.72 1.94 9.44C2.22 9.16 2.61 9 3 9H7.04L10.58 1.91C10.71 1.66 10.97 1.5 11.25 1.5C12.25 1.5 13.19 1.89 13.9 2.6C14.61 3.31 15 4.25 15 5.25V6.75H20.05C20.34 6.75 20.69 6.82 20.98 6.95C21.27 7.08 21.53 7.27 21.74 7.51C21.95 7.75 22.11 8.03 22.2 8.34C22.29 8.65 22.32 8.97 22.28 9.29L21.16 18.28C21.09 18.82 20.83 19.32 20.42 19.68C20.01 20.04 19.49 20.24 18.94 20.25H7.5ZM8.25 18.75H18.93C19.11 18.75 19.28 18.68 19.42 18.56C19.56 18.44 19.64 18.27 19.67 18.09L20.79 9.09C20.8 8.98 20.79 8.87 20.77 8.77C20.74 8.67 20.69 8.58 20.62 8.5C20.55 8.42 20.47 8.36 20.37 8.31C20.27 8.27 20.17 8.24 20.06 8.24H14.25C13.84 8.24 13.5 7.9 13.5 7.49V5.24C13.5 4.65 13.26 4.07 12.84 3.65C12.53 3.34 12.12 3.12 11.69 3.04L8.25 9.92V18.74V18.75ZM3 10.5V18.75H6.75V10.5H3Z"
      fill={iconColor}
    />
  </SvgIcon>
);

ThumbUpIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default ThumbUpIcon;
