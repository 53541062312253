import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { get, pick } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import {
  adjustGeneralInvolvedProperty,
  adjustInvolvedPerson,
} from '~/components/Fnol/NewFnolUI/InvolvedParties/GeneralClaimParties/index';

import { cleanEmptyValues, reportAxiosError } from '../../../../../Utils';
import { useClaim } from '../../../../ClaimContainer';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { cleanConfiguredFieldsFormikValuesBeforeSend } from '../../../../IncidentConfiguration/ConfiguredFields';
import { involvedGenericFields } from '../InvolvedGenericParty/InvolvedGenericFragment';

import GeneralInvolvedPartiesCard from './GeneralInvolvedPartiesCard';

const PROPERTY_ENDPOINT = '/api/v1/claims/{claimId}/involved_property';
const PERSON_ENDPOINT = '/api/v1/claims/{claimId}/involved_person';
const PUT_METHOD = 'put';
const POST_METHOD = 'post';
const GET_METHOD = 'get';

const filterParty = (arr, isFirstParty) =>
  arr?.sort((a, b) => a.id - b.id).filter((item) => item.is_first_party === isFirstParty) || [];

const PROPERTY_DAMAGE_FIELDS = ['damages_description', 'location', 'note'];
const BODILY_INJURY_FIELDS = [
  'was_ambulance_needed',
  'was_hospitalized',
  'hospital_contact_id',
  'admission_datetime',
  'discharge_datetime',
  'legal_venue_state',
  'legal_venue_reason',
  'injuries',
  'injuries_description',
  'note',
];

const mapInvolvedPropertiesParties = (involvedPropertiesParties) => {
  return (
    involvedPropertiesParties.map(({ generic_property_involved }) => ({
      ...generic_property_involved,
    })) || []
  );
};

const mapInvolvedPersons = (involvedPersons) => {
  return (
    involvedPersons.map(({ involved_person }) => ({
      ...involved_person,
      bodily_injury_loss: {
        ...pick(involved_person, BODILY_INJURY_FIELDS),
        bodily_injuries: involved_person?.injuries || [],
      },
      related_property_loss: {
        ...involved_person?.related_property_loss,
        damages: involved_person?.related_property_loss?.damages || [],
      },
      pecuniary_loss: {
        ...involved_person?.pecuniary_loss,
      },
    })) || []
  );
};

const getFirstPartyValues = (claim) => {
  return getPartiesByFirstParty(claim, true);
};

const getThirdPartyValues = (claim) => {
  return getPartiesByFirstParty(claim, false);
};

const getPartiesByFirstParty = (claim, isFirstParty) => {
  const { incident } = claim ?? {};
  const otherPropertiesParties = filterParty(incident?.other_properties_parties, isFirstParty);
  const involvedPersons = filterParty(incident?.involved_person_parties, isFirstParty);
  return [...mapInvolvedPropertiesParties(otherPropertiesParties), ...mapInvolvedPersons(involvedPersons)];
};

const NewFnolGeneralInvolvedCard = () => {
  const { claim, onAsyncClaimUpdate } = useClaim();
  const [firstPartyValues, setFirstPartyValues] = useState(getFirstPartyValues(claim));
  const [thirdPartyValues, setThirdPartyValues] = useState(getThirdPartyValues(claim));
  const [claimUpdated, setClaimUpdated] = useState(false);
  const { incidentConfiguration } = useIncidentConfiguration();
  const incidentConfigParties = incidentConfiguration?.involved_parties || {};

  useEffect(() => {
    if (claimUpdated) {
      setFirstPartyValues(getFirstPartyValues(claim));
      setThirdPartyValues(getThirdPartyValues(claim));
      setClaimUpdated(false);
    }
  }, [claim, claimUpdated]);

  const callModifyingApi = async (url, method, values) => {
    try {
      await axios({
        method,
        url,
        data: values,
      });
      await onAsyncClaimUpdate();
      setClaimUpdated(true);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const callGetterApi = async (url) => {
    try {
      const ret = await axios({
        GET_METHOD,
        url,
      });
      return ret.data;
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const handleAddParty = (partyTypeKey) => {
    const partyIncidentConfig = incidentConfigParties[partyTypeKey];
    const isFirstParty = partyIncidentConfig?.is_first_party;
    const partyValues = isFirstParty ? firstPartyValues.slice() : thirdPartyValues.slice();
    partyValues.push({
      ...involvedGenericFields(incidentConfiguration, partyTypeKey),
      type: partyTypeKey,
      general_claim_involved_type: partyTypeKey,
    });
    isFirstParty ? setFirstPartyValues(partyValues) : setThirdPartyValues(partyValues);
  };

  const isInvolvedEntityProperty = (party) =>
    incidentConfigParties[party?.type]?.type === 'property' || party?.type === 'generic_involved_property';

  const getEndpoint = (id, party) => {
    const isInvolvedProperty = isInvolvedEntityProperty(party);
    if (id) {
      return isInvolvedProperty
        ? `${PROPERTY_ENDPOINT.replace('{claimId}', claim.id)}/${id}`
        : `${PERSON_ENDPOINT.replace('{claimId}', claim.id)}/${id}`;
    }
    return isInvolvedProperty
      ? PROPERTY_ENDPOINT.replace('{claimId}', claim.id)
      : PERSON_ENDPOINT.replace('{claimId}', claim.id);
  };

  const getRequestBody = (party, partyTypeKey) => {
    const {
      property_damage = {},
      related_property_loss = {},
      bodily_injury_loss = {},
      property_description,
      pecuniary_loss = {},
    } = party;
    const { bodily_injuries = [] } = bodily_injury_loss;
    const partyIncidentConfig = incidentConfigParties[partyTypeKey];
    const isFirstParty = partyIncidentConfig?.is_first_party;
    const configuredFields = get(incidentConfiguration, `involved_parties.${partyTypeKey}.configured_fields`, []);
    const cleanConfiguredFields = cleanConfiguredFieldsFormikValuesBeforeSend(
      configuredFields,
      get(party, 'configured_fields_values'),
      { shouldSendNullForEmpty: true }
    );
    const requestBody = {
      ...cleanEmptyValues(party),
      property_damage: cleanEmptyValues(property_damage),
      related_property_loss: cleanEmptyValues(related_property_loss),
      bodily_injury_loss: {
        ...cleanEmptyValues(bodily_injury_loss),
        injuries: bodily_injuries,
      },
      is_first_party: isFirstParty,
      pecuniary_loss: cleanEmptyValues(pecuniary_loss),
      configured_fields_values: cleanConfiguredFields,
    };
    if (partyIncidentConfig.type === 'property') {
      requestBody.property_name = property_description || ' ';
    }
    return requestBody;
  };

  const handleSaveParty = async (party = {}, partyTypeKey) => {
    const { id } = party;
    const endpoint = getEndpoint(id, party);
    const method = id ? PUT_METHOD : POST_METHOD;
    const requestBody = getRequestBody(party, partyTypeKey);
    return await callModifyingApi(endpoint, method, requestBody);
  };

  const handleFetchInvolvedDetails = async (involvedHeader) => {
    const { id } = involvedHeader;
    const endpoint = getEndpoint(id, involvedHeader);
    const fetchedInvolvedDetails = await callGetterApi(endpoint);
    if (isInvolvedEntityProperty(fetchedInvolvedDetails)) {
      return adjustGeneralInvolvedProperty(fetchedInvolvedDetails);
    } else {
      return adjustInvolvedPerson(fetchedInvolvedDetails);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <GeneralInvolvedPartiesCard
          firstPartyValues={firstPartyValues}
          thirdPartyValues={thirdPartyValues}
          onFetchInvolvedDetails={handleFetchInvolvedDetails}
          onAddParty={handleAddParty}
          onSaveParty={handleSaveParty}
        />
      </Grid>
    </Grid>
  );
};

export { BODILY_INJURY_FIELDS, PROPERTY_DAMAGE_FIELDS };
export default NewFnolGeneralInvolvedCard;
