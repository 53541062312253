import type { ReactElement } from 'react';
import React from 'react';

import { PropertyDetails, Text } from '../../../core';
import type { DocumentApiResponse } from '../../types';

import { ClaimDocumentIconButton } from './ClaimDocumentIconButton';

export const DocumentPropertyDetails: React.FC<{
  document?: DocumentApiResponse;
  title: string;
  secondaryAction?: ReactElement;
}> = ({ document, title, secondaryAction }) => {
  const textComponent = document ? (
    <div className="flex items-center gap-12">
      <ClaimDocumentIconButton documentId={document.id} />
      <Text
        className="mt-[-4px]"
        variant={Text.VARIANTS.SM}
        colorVariant={Text.COLOR_VARIANTS.PRIMARY}
        weight={Text.WEIGHTS.REGULAR}
      >
        {document?.document_name_with_ext}
      </Text>
      {secondaryAction}
    </div>
  ) : null;

  return (
    <PropertyDetails
      title={title}
      text={secondaryAction ? null : document?.document_name_with_ext}
      textComponent={secondaryAction ? textComponent : undefined}
      textIcon={document ? <ClaimDocumentIconButton documentId={document.id} /> : null}
    />
  );
};
