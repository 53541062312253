import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import CheckBanner from '~/components/core/CheckBanner';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import useIsConfigurationFieldSupportedBySubtype from '~/components/hooks/useIsConfigurationFieldSupportedBySubtype';
import { FNOL_MIXPANEL_EVENTS } from '~/pocs/mixpanel';

import mixpanel from '../../../../CmsMain/mixpanel';
import { ErrorHelperTextFormik } from '../../../../core/Formik/ErrorHelperTextFormik';
import AdditionalInformationDialog from '../AdditionalInformationDialog';
import { getIsSupplementaryInformationSectionActive } from '../Fragments/SupplementaryInformationFragment';

import styles from './index.module.scss';

const AdditionalInformationSection = ({
  id,
  initialValues,
  onCancel,
  onSubmit,
  title,
  showOnly,
  sectionConfig,
  AdditionalInformationFragment,
  AdditionalInformationFragmentInitialValues,
  AdditionalInformationFragmentValidationSchema,
}) => {
  const { setFieldValue, values } = useFormikContext();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { isFieldSupportedBySubtype } = useIsConfigurationFieldSupportedBySubtype();
  const [showDialog, setShowDialog] = useState(false);
  const [checked, setChecked] = useState(false);
  const supportedBySubtypeCategory = isFieldSupportedBySubtype(sectionConfig);
  const isActive =
    id === 'supplementary_information'
      ? getIsSupplementaryInformationSectionActive(incidentConfiguration)
      : sectionConfig?.active;

  useEffect(() => {
    if (!supportedBySubtypeCategory || !sectionConfig?.mandatory) {
      setFieldValue(`verified_${id}`, true);
    } else {
      if (!checked) {
        setFieldValue(`verified_${id}`, false);
      }
    }
    if (!checked && get(values, `checked_${id}`, false)) {
      setChecked(true);
    }
  }, [checked, id, sectionConfig?.mandatory, setFieldValue, supportedBySubtypeCategory, values]);

  if (!isActive) {
    return <></>;
  }

  return (
    <>
      <Grid item xs={12} className={styles.AdditionalInformationSection}>
        <CheckBanner
          withBold
          note={title}
          checked={checked}
          onClick={() => setShowDialog(true)}
          disabled={!supportedBySubtypeCategory}
        />
        <div className={styles.errorHelper}>
          <ErrorHelperTextFormik id={`verified_${id}`} />
        </div>
      </Grid>

      {showDialog && (
        <AdditionalInformationDialog
          id={id}
          open={showDialog}
          title={title}
          initialValues={initialValues}
          additionalFieldOmitFn={(field) => isFieldSupportedBySubtype(field)}
          AdditionalInformationFragment={AdditionalInformationFragment}
          AdditionalInformationFragmentInitialValues={AdditionalInformationFragmentInitialValues}
          AdditionalInformationFragmentValidationSchema={AdditionalInformationFragmentValidationSchema}
          showOnly={showOnly}
          onCancel={async () => {
            onCancel && (await onCancel());
            setShowDialog(false);
          }}
          onSubmit={async (values) => {
            onSubmit && (await onSubmit(values));
            setChecked(true);
            setFieldValue(`verified_${id}`, true);
            setFieldValue(`checked_${id}`, true);
            setShowDialog(false);
            mixpanel.track(FNOL_MIXPANEL_EVENTS.FNOL_SECTION_DETAILS_UPDATE_CLICKED, { update_fnol_section: title });
          }}
        />
      )}
    </>
  );
};

AdditionalInformationSection.propTypes = {
  id: PropTypes.string.isRequired,
  dialogAction: PropTypes.node,
  disableEditIdentity: PropTypes.bool,
  initialValues: PropTypes.object,
  isInsured: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  showOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
  sectionConfig: PropTypes.object,
  AdditionalInformationFragment: PropTypes.func,
  AdditionalInformationFragmentInitialValues: PropTypes.object,
  AdditionalInformationFragmentValidationSchema: PropTypes.object,
};

export default AdditionalInformationSection;
