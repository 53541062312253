import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';

import { useClaim } from './ClaimContainer';
import { useRestrictedPermissions } from './core';
import HoverActionField from './HoverActionField';
import { InvolvedPersonHoverInfo } from './InvolvedPersonHoverInfo';
import { InvolvedPropertyHoverInfo } from './InvolvedPropertyHoverInfo';

import { useStyles } from '../assets/styles';

const InvolvedWrapper = ({ children, onEdit, disabled, involved, involvedType }) => {
  const classes = useStyles();
  const { userHasContextPermissions } = useRestrictedPermissions();
  const { claim } = useClaim();

  if (!userHasContextPermissions) {
    return (
      <div>
        <span
          className={classes.showValueField}
          style={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}
        >
          <span style={{ height: '100%' }}>{children}</span>
          {involvedType === 'person' ? (
            <InvolvedPersonHoverInfo claimId={claim.id} involvedPerson={involved} />
          ) : (
            <InvolvedPropertyHoverInfo claimId={claim.id} involvedProperty={involved} />
          )}
        </span>
      </div>
    );
  }

  return (
    <HoverActionField onAction={onEdit} classes={classes} disabled={disabled}>
      {children}
    </HoverActionField>
  );
};

InvolvedWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onEdit: requiredIf(PropTypes.func, (props) => !props.disabled),
  involved: PropTypes.object.isRequired,
  involvedType: PropTypes.oneOf(['person', 'property']).isRequired,
};

export default InvolvedWrapper;
