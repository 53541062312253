import React from 'react';
import _ from 'lodash';

import { useCms } from '~/components/hooks/useCms';
import { buildProjectRelations, getAdminUser } from '~/components/ReportsPage/helpers/ReportsUtils';
import { useTableauApiRoutes } from '~/components/ReportsPage/Hooks/useTableauApiRoutes';
import type { ITableauData, Project, User, Workbook } from '~/components/ReportsPage/types';
import useDataFetcher from '~/components/useDataFetcher';

export const useTableauData = (): ITableauData => {
  const [projects, setProjects] = React.useState([] as Project[]);
  const [workbooksMap, setWorkbookMap] = React.useState({} as Record<string, Workbook>);
  const { viewsUrl, workbooksUrl, projectsUrl, usersUrl } = useTableauApiRoutes();
  const { user }: { user?: { id: string } } = useCms();

  const {
    data: views,
    isLoading: isLoadingViews,
    isError: isErrorViews,
    reloadData: reloadViews,
  } = useDataFetcher(viewsUrl, {});

  const {
    data: workbooks,
    isLoading: isLoadingWorkbooks,
    isError: isErrorWorkbooks,
    reloadData: reloadWorkbooks,
  } = useDataFetcher(workbooksUrl, {});

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    reloadData: reloadUsers,
  } = useDataFetcher(usersUrl, {});

  const {
    data: rawProjects,
    isLoading: isLoadingProjects,
    isError: isErrorProjects,
    reloadData: reloadProjects,
  } = useDataFetcher(projectsUrl, {});

  const isLoading = React.useMemo(
    () => isLoadingViews || isLoadingWorkbooks || isLoadingUsers || isLoadingProjects,
    [isLoadingViews, isLoadingWorkbooks, isLoadingUsers, isLoadingProjects]
  );

  const isError = React.useMemo(
    () => isErrorViews || isErrorWorkbooks || isErrorUsers || isErrorProjects,
    [isErrorViews, isErrorWorkbooks, isErrorUsers, isErrorProjects]
  );

  const adminUser = React.useMemo(() => getAdminUser({ users }), [users]);

  const me = React.useMemo(
    () => users?.find((tableauUser: User) => tableauUser.org_user_id === user?.id),
    [users, user?.id]
  );

  const reloadAll = async () => {
    await Promise.all([reloadViews(), reloadWorkbooks(), reloadProjects(), reloadUsers()]);
    return;
  };

  React.useEffect(() => {
    if (!isLoadingViews && !isLoadingWorkbooks && !isLoadingProjects) {
      const readyProjects = buildProjectRelations({ views, workbooks, projects: rawProjects, users });
      setProjects(readyProjects);

      // build map of workbooks populated with views
      setWorkbookMap(_.keyBy(_.flatMap(readyProjects, 'workbooks'), 'id'));
    }
  }, [isLoadingViews, isLoadingWorkbooks, isLoadingProjects, workbooks, views, rawProjects, users]);

  return {
    projects,
    workbooksMap,
    users,
    me,
    adminUser,
    isLoading,
    isError,
    reloadAll,
  };
};
