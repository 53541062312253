import React from 'react';
import { connect, useFormikContext } from 'formik';
import { get } from 'lodash';

import {
  getCommunicationDirectionIcon,
  getCommunicationDirectionIconComponent,
} from '~/components/communications/CommunicationUtils';
import InlineIconButton from '~/components/InlineIconButton';

const FormikToggleDirection = () => {
  const { values, setFieldValue } = useFormikContext();
  const direction = get(values, 'direction', 'Outgoing');

  return (
    <>
      {direction === 'Outgoing' ? (
        <>
          {getCommunicationDirectionIconComponent('Outgoing')}
          <InlineIconButton
            onClick={() => setFieldValue('direction', 'Incoming')}
            icon={getCommunicationDirectionIcon('Incoming')}
            defaultColor={undefined}
            disabled={undefined}
            className={undefined}
            iconStyle={undefined}
            tooltipTitle={undefined}
            useIconButton={undefined}
            wrapWithSpan={undefined}
            ignorePermissions={undefined}
            ariaLabel={undefined}
          />
        </>
      ) : (
        <>
          <InlineIconButton
            onClick={() => setFieldValue('direction', 'Outgoing')}
            icon={getCommunicationDirectionIcon('Outgoing')}
            defaultColor={undefined}
            disabled={undefined}
            className={undefined}
            iconStyle={undefined}
            tooltipTitle={undefined}
            useIconButton={undefined}
            wrapWithSpan={undefined}
            ignorePermissions={undefined}
            ariaLabel={undefined}
          />
          {getCommunicationDirectionIconComponent('Incoming')}
        </>
      )}
    </>
  );
};

export default connect(FormikToggleDirection);
