import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const CoverageDecisionNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.4015 15.0395L31.9908 19.7915C32.0117 19.9588 31.997 20.1286 31.9478 20.2897C31.8986 20.4508 31.8159 20.5995 31.7052 20.7259C31.5946 20.8524 31.4585 20.9536 31.3061 21.0229C31.1536 21.0922 30.9883 21.1281 30.8211 21.128H28.0712V21.92C28.0712 22.4451 27.8642 22.9487 27.4959 23.3201C27.1275 23.6914 26.6279 23.9 26.107 23.9C26.034 23.9001 25.9624 23.8796 25.9003 23.841C25.8382 23.8023 25.788 23.747 25.7554 23.6812L23.9002 19.94H21.7857C21.5773 19.94 21.3775 19.8566 21.2301 19.708C21.0828 19.5595 21 19.3581 21 19.148V14.792C21 14.5819 21.0828 14.3805 21.2301 14.232C21.3775 14.0834 21.5773 14 21.7857 14H30.2318C30.5189 13.9999 30.7962 14.1055 31.0116 14.2969C31.227 14.4883 31.3656 14.7523 31.4015 15.0395ZM21.7857 19.148H23.7499V14.792H21.7857V19.148ZM30.9829 20.3014C31.0338 20.2783 31.0791 20.2443 31.1157 20.2019C31.1529 20.16 31.1807 20.1104 31.1972 20.0567C31.2136 20.003 31.2183 19.9463 31.211 19.8905L30.6217 15.1385C30.6097 15.0428 30.5635 14.9548 30.4917 14.891C30.4199 14.8272 30.3275 14.792 30.2318 14.792H24.5356V19.4504L26.3382 23.0852C26.6054 23.0313 26.8458 22.8858 27.0185 22.6733C27.1912 22.4609 27.2856 22.1947 27.2855 21.92V20.732C27.2855 20.627 27.3269 20.5262 27.4005 20.452C27.4742 20.3777 27.5741 20.336 27.6783 20.336H30.8211C30.8769 20.3364 30.9321 20.3246 30.9829 20.3014Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3059 23.6155C22.4583 23.6785 22.5944 23.7705 22.705 23.8854C22.8157 24.0003 22.8984 24.1355 22.9477 24.282C22.997 24.4285 23.0117 24.5829 22.9908 24.735L22.4015 29.055C22.3656 29.3161 22.227 29.5561 22.0116 29.7301C21.7962 29.9041 21.5189 30.0001 21.2318 30H12.7857C12.5773 30 12.3775 29.9241 12.2301 29.7891C12.0828 29.6541 12 29.471 12 29.28V25.32C12 25.129 12.0828 24.9459 12.2301 24.8109C12.3775 24.6759 12.5773 24.6 12.7857 24.6H14.9002L16.7554 21.1989C16.788 21.1391 16.8382 21.0888 16.9003 21.0537C16.9624 21.0185 17.034 21 17.107 21C17.6279 21 18.1275 21.1896 18.4959 21.5272C18.8642 21.8648 19.0712 22.3226 19.0712 22.8V23.52H21.8211C21.9883 23.52 22.1535 23.5525 22.3059 23.6155ZM14.7499 25.32H12.7857V29.28H14.7499V25.32ZM21.6217 28.965L22.211 24.645C22.218 24.5943 22.2131 24.5429 22.1967 24.494C22.1802 24.4452 22.1527 24.4001 22.1158 24.3618C22.0789 24.3235 22.0336 24.2929 21.9828 24.2718C21.9319 24.2508 21.8768 24.24 21.8211 24.24H18.6783C18.5741 24.24 18.4742 24.2021 18.4006 24.1346C18.3269 24.067 18.2855 23.9755 18.2855 23.88V22.8C18.2856 22.5503 18.1912 22.3083 18.0185 22.1152C17.8458 21.922 17.6054 21.7897 17.3383 21.7407L15.5356 25.045V29.28H21.2318C21.3275 29.28 21.42 29.248 21.4917 29.19C21.5635 29.132 21.6098 29.052 21.6217 28.965Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default CoverageDecisionNoteIcon;
