import React from 'react';

import SvgIcon from '../core/SvgIcon';

import { iconProptypesBase } from './propTypes';

const ContactIconPlus = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0008 12.75C18.0008 12.3358 18.3366 12 18.7508 12H23.2508C23.665 12 24.0008 12.3358 24.0008 12.75C24.0008 13.1642 23.665 13.5 23.2508 13.5H18.7508C18.3366 13.5 18.0008 13.1642 18.0008 12.75Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0008 9.75C21.415 9.75 21.7508 10.0858 21.7508 10.5V15C21.7508 15.4142 21.415 15.75 21.0008 15.75C20.5866 15.75 20.2508 15.4142 20.2508 15V10.5C20.2508 10.0858 20.5866 9.75 21.0008 9.75Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1258 4.5C7.43341 4.5 5.2508 6.68261 5.2508 9.375C5.2508 12.0674 7.43341 14.25 10.1258 14.25C12.8182 14.25 15.0008 12.0674 15.0008 9.375C15.0008 6.68261 12.8182 4.5 10.1258 4.5ZM3.7508 9.375C3.7508 5.85418 6.60498 3 10.1258 3C13.6466 3 16.5008 5.85418 16.5008 9.375C16.5008 12.8958 13.6466 15.75 10.1258 15.75C6.60498 15.75 3.7508 12.8958 3.7508 9.375Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1258 15.7489C8.702 15.7489 7.29547 16.0608 6.00509 16.6625C4.7147 17.2643 3.57176 18.1413 2.6566 19.2321C2.39036 19.5494 1.9173 19.5908 1.59998 19.3246C1.28266 19.0583 1.24126 18.5853 1.5075 18.2679C2.56344 17.0094 3.88222 15.9974 5.37113 15.3031C6.86004 14.6087 8.48295 14.2489 10.1258 14.2489C11.7686 14.2489 13.3916 14.6087 14.8805 15.3031C16.3694 15.9974 17.6882 17.0094 18.7441 18.2679C19.0103 18.5853 18.9689 19.0583 18.6516 19.3246C18.3343 19.5908 17.8612 19.5494 17.595 19.2321C16.6798 18.1413 15.5369 17.2643 14.2465 16.6625C12.9561 16.0608 11.5496 15.7489 10.1258 15.7489Z"
      fill={iconColor}
    />
  </SvgIcon>
);

ContactIconPlus.propTypes = iconProptypesBase;

export default ContactIconPlus;
