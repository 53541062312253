import { isUserClaimPrivileged } from '~/UserUtils';

export const areActionsEnabled = (user, claim) => {
  const isUserPrivileged = isUserClaimPrivileged(user);

  if (!isUserPrivileged) {
    return false;
  }

  return !!claim;
};
