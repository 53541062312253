import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';

import { reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import TextFieldFormik from '../../../../TextFieldFormik';
import ExposureQaTable from '../../ExposureQaTable';
import QaQueryFilters from '../../Filters';

import { useStyles } from '../../../../../assets/styles';
import styles from './newQaQuery.module.scss';

const NewQaQueryDialog = ({ onClose, onQaQuerySaved }) => {
  const classes = useStyles();
  const [qaQueryResults, setQaQueryResults] = React.useState();
  const [showSaveQueryDialog, setShowSaveQueryDialog] = React.useState(false);

  async function handleSelectQaQueryFilters(qaQueryFilters) {
    try {
      const res = await axios.post('/api/v1/qa_queries/run_query', qaQueryFilters);
      setQaQueryResults({ entries: res.data, filters: qaQueryFilters });
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleSaveQuery(queryName) {
    try {
      const res = await axios.post('/api/v1/qa_queries', { ...qaQueryResults, query_name: queryName });
      await onQaQuerySaved(res.data);
      setShowSaveQueryDialog(false);
    } catch (error) {
      reportAxiosError(error);
    }
  }

  return (
    <>
      <CardDialog title="Files Query" isDialog onClose={onClose} maxWidth="lg" fullWidth>
        <QaQueryFilters
          filters={qaQueryResults ? qaQueryResults.filters : undefined}
          onSelect={handleSelectQaQueryFilters}
        />
        {qaQueryResults && (
          <>
            <Typography display="block" variant="subtitle1" className={styles.exposureResultsTitle}>
              Exposure Results
            </Typography>
            <ExposureQaTable
              entries={qaQueryResults.entries}
              subOrganizationId={qaQueryResults.filters?.sub_organization_id}
              claimType={qaQueryResults.filters?.claim_type}
            />
            <div className={classes.buttonsContainer}>
              <Button
                variant="contained"
                color="primary"
                disabled={qaQueryResults.entries.length === 0}
                onClick={() => setShowSaveQueryDialog(true)}
              >
                Save Query
              </Button>
            </div>
          </>
        )}
      </CardDialog>
      {showSaveQueryDialog && (
        <Formik
          initialValues={{
            query_name: '',
          }}
          validationSchema={Yup.object().shape({
            query_name: Yup.string().required('Required'),
          })}
          onSubmit={async (values, formikProps) => {
            try {
              await handleSaveQuery(values.query_name);
            } catch (error) {
              formikProps.setSubmitting(false);
            }
          }}
        >
          {(formikProps) => (
            <CardDialog isDialog title="Save Query" onClose={() => setShowSaveQueryDialog(false)}>
              <TextFieldFormik id="query_name" label="Query Name" fullWidth />
              <div className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.handleSubmit}
                >
                  Save
                </Button>
              </div>
            </CardDialog>
          )}
        </Formik>
      )}
    </>
  );
};

NewQaQueryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onQaQuerySaved: PropTypes.func.isRequired,
  savedQaQuery: PropTypes.object,
};

export default NewQaQueryDialog;
