import React from 'react';
import { bool, string } from 'prop-types';

import SocialSecurityNumberFormat from '../SocialSecurityNumberFormat';
import { TextFieldFormik } from '../TextFieldFormik';

import { isUkRegion, isUsRegion, LABELS } from './utils';

import { useStyles } from '../../assets/styles';

const GovernmentIdFieldFormik = ({ id, disabled = false, showOnly = false }) => {
  const classes = useStyles();
  const label = isUkRegion() ? LABELS.NIN : LABELS.SSN;
  const inputProps = isUsRegion() ? { inputComponent: SocialSecurityNumberFormat } : undefined;

  // Currently we only showing this field for SSN and NIN ids.
  // TODO: Change this to support multiple regions.
  if (!isUsRegion() && !isUkRegion()) {
    return null;
  }

  return (
    <TextFieldFormik
      id={id}
      label={label}
      fullWidth
      className={classes.textField}
      InputProps={inputProps}
      disabled={disabled}
      showOnly={showOnly}
    />
  );
};

GovernmentIdFieldFormik.propTypes = {
  id: string.isRequired,
  disabled: bool,
  showOnly: bool,
};

export default GovernmentIdFieldFormik;
