import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Tooltip from '~/components/core/Atomic/Tooltip';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { ExposureDetailedTitle } from '~/components/exposures/ExposureDetailedTitle';
import { GeneralRecoveryPayableWithReserveSum } from '~/components/exposures/GeneralRecoveryDialog';
import useFetchExposures from '~/components/exposures/useFetchExposures';
import { LegalActionPriorityIcon } from '~/components/LegalActions/components/LegalActionPriorityIcon';
import { useLegalActions } from '~/components/LegalActions/hooks/useLegalActions';
import {
  getClaimLevelHighestPriorityLegalAction,
  getExposureHighestPriorityLegalAction,
} from '~/components/LegalActions/LegalActionsUtils';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';
import { findInvolvedProperty, getClaimExposuresWithGeneralFakeExposure } from '~/Utils/ClaimUtils';

import { Heading, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../core';
import ExposureCoverageDecisionContainer from '../../CoverageDecision/ExposureCoverageDecisionContainer';
import { useCms } from '../../hooks/useCms';
import useOrganization from '../../OrganizationContext';
import PlainTable from '../../PlainTable';
import DamageAssessmentContainer from '../DamageAssessmentContainer';
import ExposureOwnerContainer from '../ExposureOwnerContainer';
import ExposuresMoreActionsContainer from '../ExposuresMoreActionsContainer';
import GcDeductibleContainer from '../GcDeductibleContainer';
import LiabilityDashboardContainer from '../LiabilityDashboard';
import NewMoiContainer from '../moi/NewMoiContainer';
import MoiContainer from '../MoiContainer';
import MorContainer from '../MorContainer';
import PaidSumContainer from '../PaidSumContainer';
import PipDashboardContainer from '../PipDashboardContainer';
import { RecoveryStatusContainer } from '../Recovery';
import ReserveContainer from '../ReserveContainer';
import TravelDamageAssessmentContainer from '../TravelDamageAssessmentContainer';

import { adjustColumnByClaim } from './exposuresTableUtils';
import TotalIncurredCell from './TotalIncurredCell';

const ExposuresTable = (props) => {
  const { claim } = props;

  const classes = useStyles();
  const { userOrganization } = useCms();
  const { coverageConfiguration, moiConfiguration } = useOrganization();
  const { legalActions, isLegalActionsLoading, hasLegalActionsPermissions, isLegalActionsFeatureEnabled } =
    useLegalActions();
  const { isExposuresLoading, exposures, exposuresTotals } = useFetchExposures();

  const shouldDisplayLegalActionsData = !isLegalActionsLoading && hasLegalActionsPermissions;

  const isExpensesEmpty = !claim.general_expenses.reserve && !claim.general_expenses.paid_sum;
  const isGeneralExpensesDisabled =
    (coverageConfiguration?.is_general_expenses_disabled && isExpensesEmpty) || claim.type === 'wc_claim';
  const isNewMoiExperienceEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NEW_MOI_EXPERIENCE);
  const claimLevelMoiEnabled = moiConfiguration?.claim_level_moi_enabled;

  const isExposureWithTotalLossInvolvedVehicle = (exposure) => {
    if (exposure.involved_property && exposure.involved_property.type === 'involved_vehicle') {
      const involvedVehicle = findInvolvedProperty(claim, exposure.involved_property);

      return involvedVehicle.is_total_loss === 'yes' || involvedVehicle.is_total_loss === true;
    }

    return false;
  };

  const getMoiComponent = (exposure) =>
    isNewMoiExperienceEnabled ? <NewMoiContainer exposure={exposure} /> : <MoiContainer exposure={exposure} />;

  const getDamageAssessmentComponent = (claim, exposure) => {
    if (['pip', 'medical', 'worldtrip_demo', 'worldtrip_demo_line'].includes(exposure.damage_assessment_type)) {
      return <PipDashboardContainer exposure={exposure} />;
    } else if (exposure.damage_assessment_type === 'liability') {
      return <LiabilityDashboardContainer claimId={claim.id} exposure={exposure} />;
    } else if (claim.type === 'gl_claim') {
      return <LiabilityDashboardContainer claimId={claim.id} exposure={exposure} />;
    } else if (
      [
        'coverage_wc_medicals',
        'coverage_wc_compensation',
        'coverage_wc_other_expense',
        'coverage_wc_voc_rehab',
      ].includes(exposure.coverage_type)
    ) {
      return <PipDashboardContainer exposure={exposure} />;
    } else if (claim.type === 'travel_claim' || exposure.damage_assessment_type === 'multiple_damages') {
      return <TravelDamageAssessmentContainer claimId={claim.id} exposure={exposure} />;
    } else {
      return (
        <DamageAssessmentContainer
          claimId={claim.id}
          exposure={exposure}
          enableReinspection={claim.type === 'home_claim'}
        />
      );
    }
  };

  const isNonExposureRow = (row) => row.isGeneralExpenses || row.isTotalIncurred;

  let columnData = [
    {
      id: 'coverage_type',
      label: 'Coverage',
      specialCell: (row) => {
        if (row.isTotalIncurred) {
          return <Heading variant={Heading.TYPES.H4}>Total</Heading>;
        } else if (row.isGeneralExpenses) {
          const label = <Heading variant={Heading.TYPES.H4}>General</Heading>;
          if (shouldDisplayLegalActionsData) {
            const highestPriorityLegalAction = getClaimLevelHighestPriorityLegalAction(legalActions);
            return (
              <div className="flex w-full items-center gap-12">
                {label}
                {highestPriorityLegalAction ? (
                  <Chip
                    size="small"
                    color="default"
                    label="Legal Action"
                    className={classes.chip}
                    icon={<LegalActionPriorityIcon priority={highestPriorityLegalAction.priority} className="ml-4" />}
                  />
                ) : null}
              </div>
            );
          }
          return <Heading variant={Heading.TYPES.H4}>General</Heading>;
        } else {
          const highestPriorityLegalAction = shouldDisplayLegalActionsData
            ? getExposureHighestPriorityLegalAction(legalActions, row)
            : null;

          return (
            <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {/* eslint-disable-next-line react/prop-types */}
              <ExposureDetailedTitle exposure={row} />
              <span style={{ whiteSpace: 'nowrap', paddingLeft: '6px' }}>
                {row.is_closed && (
                  <Tooltip title={row.close_reason}>
                    <Chip size="small" color="primary" label="closed" />
                  </Tooltip>
                )}
                {row.is_coverage_issue_exists && (
                  <Tooltip title={row.coverage_issue_desc}>
                    <Chip
                      size="small"
                      color="default"
                      label="Coverage Issue"
                      className={`${classes.chip} ${classes.warningBackground}`}
                    />
                  </Tooltip>
                )}
                {isExposureWithTotalLossInvolvedVehicle(row) && (
                  <Chip
                    size="small"
                    color="default"
                    label="Total loss"
                    className={`${classes.chip} ${classes.warningBackground}`}
                  />
                )}
                {row.is_in_litigation && !row.is_litigation_closed && !isLegalActionsFeatureEnabled ? (
                  <Chip
                    size="small"
                    color="default"
                    label="Litigation"
                    className={`${classes.chip} ${classes.warningBackground}`}
                  />
                ) : null}
                {highestPriorityLegalAction ? (
                  <Chip
                    size="small"
                    color="default"
                    label="Legal Action"
                    className={classes.chip}
                    icon={<LegalActionPriorityIcon priority={highestPriorityLegalAction.priority} className="ml-4" />}
                  />
                ) : null}
              </span>
            </div>
          );
        }
      },
    },
    { id: 'claim_internal_id', label: 'Id', width: '10px' },
    {
      id: 'coverage_decision',
      label: 'Covered',
      specialCell: (row) =>
        !isNonExposureRow(row) && (
          <RestrictedPermissions action={PERMISSION_ACTIONS.COVERAGE_DECISION} verb={PERMISSION_VERBS.WRITE}>
            <ExposureCoverageDecisionContainer exposure={row} />
          </RestrictedPermissions>
        ),
    },
    {
      id: 'moi',
      label: 'MOI',
      specialCell: (row) =>
        row.isTotalIncurred || (!claimLevelMoiEnabled && row.isGeneralExpenses) ? <></> : getMoiComponent(row),
    },
    {
      id: 'damage_assessment',
      label: 'Damage Assessment',
      specialCell: (row) => !isNonExposureRow(row) && getDamageAssessmentComponent(claim, row),
    },
    {
      id: 'mor',
      label: 'MOR',
      width: '20px',
      specialCell: (row) => !isNonExposureRow(row) && <MorContainer exposure={row} />,
    },
    {
      id: 'deductible',
      label: 'Deductible',
      width: '20px',
      specialCell: (row) => {
        if (row.isTotalIncurred) {
          return (
            <TotalIncurredCell
              claim={claim}
              cellType={TotalIncurredCell.CELL_TYPES.TOTAL_DEDUCTIBLE_AMOUNT}
              totalSum={exposuresTotals.total_deductible_amount}
            />
          );
        } else {
          return <GcDeductibleContainer exposure={row} />;
        }
      },
    },
    {
      id: 'indemnity_reserve',
      numeric: false,
      label: 'Indemnity Reserve',
      specialCell: (row) => {
        if (row.isTotalIncurred) {
          return (
            <TotalIncurredCell
              claim={claim}
              cellType={TotalIncurredCell.CELL_TYPES.INDEMNITY_RESERVES}
              totalSum={exposuresTotals.total_indemnity_reserve_including_pending}
            />
          );
        } else if (row.isGeneralExpenses) {
          return <></>;
        } else {
          return (
            <RestrictedPermissions action={PERMISSION_ACTIONS.INDEMNITY_RESERVE} verb={PERMISSION_VERBS.WRITE}>
              <ReserveContainer exposure={row} payableWithReserve={row.indemnity} />
            </RestrictedPermissions>
          );
        }
      },
    },
    {
      id: 'expenses_reserve',
      numeric: false,
      label: 'Expenses Reserve',
      specialCell: (row) => {
        if (row.isTotalIncurred) {
          return (
            <TotalIncurredCell
              claim={claim}
              cellType={TotalIncurredCell.CELL_TYPES.EXPENSES_RESERVES}
              totalSum={exposuresTotals.total_expenses_reserve_including_pending}
            />
          );
        } else if (isGeneralExpensesDisabled && row.isGeneralExpenses) {
          return <></>;
        } else {
          const action = row.isGeneralExpenses
            ? PERMISSION_ACTIONS.GENERAL_EXPENSES_RESERVE
            : PERMISSION_ACTIONS.EXPENSES_RESERVE;

          return (
            <RestrictedPermissions action={action} verb={PERMISSION_VERBS.WRITE}>
              <ReserveContainer exposure={row} payableWithReserve={row.expenses} />
            </RestrictedPermissions>
          );
        }
      },
    },
    {
      id: 'indemnity_paid_sum',
      numeric: false,
      label: 'Indemnity Paid',
      specialCell: (row) => {
        if (row.isTotalIncurred) {
          return (
            <TotalIncurredCell
              claim={claim}
              cellType={TotalIncurredCell.CELL_TYPES.INDEMNITY_PAID_SUM}
              totalSum={exposuresTotals.total_indemnity_paid_including_pending_minus_recoveries}
            />
          );
        } else if (row.isGeneralExpenses) {
          return <></>;
        } else {
          return (
            <RestrictedPermissions action={PERMISSION_ACTIONS.INDEMNITY_PAYMENT} verb={PERMISSION_VERBS.WRITE}>
              <PaidSumContainer exposure={row} payableWithReserve={row.indemnity} />
              <GeneralRecoveryPayableWithReserveSum payableWithReserve={row.indemnity} />
              <RecoveryStatusContainer exposure={row} />
            </RestrictedPermissions>
          );
        }
      },
    },
    {
      id: 'expenses_paid_sum',
      numeric: false,
      label: 'Expenses Paid',
      specialCell: (row) => {
        if (row.isTotalIncurred) {
          return (
            <TotalIncurredCell
              claim={claim}
              cellType={TotalIncurredCell.CELL_TYPES.EXPENSES_PAID_SUM}
              totalSum={exposuresTotals.total_expenses_paid_including_pending_minus_recoveries}
            />
          );
        } else if (isGeneralExpensesDisabled && row.isGeneralExpenses) {
          return <></>;
        } else {
          const action = row.isGeneralExpenses
            ? PERMISSION_ACTIONS.GENERAL_EXPENSES_PAYMENT
            : PERMISSION_ACTIONS.EXPENSES_PAYMENT;

          return (
            <RestrictedPermissions action={action} verb={PERMISSION_VERBS.WRITE}>
              <PaidSumContainer exposure={row} payableWithReserve={row.expenses} />
              <GeneralRecoveryPayableWithReserveSum payableWithReserve={row.expenses} />
            </RestrictedPermissions>
          );
        }
      },
    },
    {
      id: 'owner',
      label: 'Owner',
      numeric: false,
      specialCell: (row) => !isNonExposureRow(row) && <ExposureOwnerContainer exposure={row} />,
    },
    {
      id: 'more_actions',
      rightPaddingOnly: true,
      numeric: false,
      width: '30px',
      specialCell: (row) => !row.isTotalIncurred && <ExposuresMoreActionsContainer exposure={row} />,
    },
  ];

  const exposureTableRows =
    isGeneralExpensesDisabled && !claimLevelMoiEnabled
      ? exposures
      : [...exposures, getClaimExposuresWithGeneralFakeExposure(claim).pop()];

  const totalIncurredRow = {
    isTotalIncurred: true,
  };

  const exposureTableRowsWithTotalIncurred = [...exposureTableRows, totalIncurredRow];

  columnData = adjustColumnByClaim(columnData, claim, userOrganization);

  // The default order column should be the claim_internal_id column (1), not the coverage_type column (0)
  const claimInternalIdColumnIndex = columnData?.findIndex((col) => col?.id === 'claim_internal_id') || 1;
  const displayLoading = isExposuresLoading || (hasLegalActionsPermissions && isLegalActionsLoading);

  return (
    <>
      {displayLoading && <SkeletonTable rowsCount={5} columnsCount={12} />}
      {!displayLoading && (
        <PlainTable
          defaultOrderColumn={claimInternalIdColumnIndex}
          columns={columnData}
          rows={exposureTableRowsWithTotalIncurred}
          doNotBubbleHover
        />
      )}
    </>
  );
};

ExposuresTable.propTypes = {
  claim: PropTypes.object.isRequired,
};

export default ExposuresTable;
