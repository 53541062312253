import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const AddIcon = ({ iconColor, ...rest }) => (
  <SvgIcon fill="none" viewBox="0 0 18 18" {...rest} iconColor={iconColor}>
    <path
      stroke="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0C9.41421 0 9.75 0.335786 9.75 0.75V8.25H17.25C17.6642 8.25 18 8.58579 18 9C18 9.41421 17.6642 9.75 17.25 9.75H9.75V17.25C9.75 17.6642 9.41421 18 9 18C8.58579 18 8.25 17.6642 8.25 17.25V9.75H0.75C0.335786 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H8.25V0.75C8.25 0.335786 8.58579 0 9 0Z"
      fill={iconColor}
    />
  </SvgIcon>
);

AddIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default AddIcon;
