import React from 'react';

import CardDialog from '~/components/CardDialog';
import LoadingIndicator from '~/components/LoadingIndicator';

const CommunicationPopoverLoader: React.FC = () => {
  return (
    <CardDialog isDialog>
      <LoadingIndicator />
    </CardDialog>
  );
};

export default CommunicationPopoverLoader;
