import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const SmsIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" iconColor={iconColor} disableStrokeColor {...props}>
    <path
      d="M21.2992 4.9C20.9992 4.6 20.5992 4.5 20.1992 4.5H3.79922C3.39922 4.5 2.99922 4.7 2.69922 4.9C2.39922 5.1 2.19922 5.6 2.19922 6V20.9C2.19922 21.2 2.29922 21.5 2.39922 21.7C2.59922 21.9 2.79922 22.1 2.99922 22.2C3.19922 22.3 3.39922 22.3 3.59922 22.3C3.69922 22.3 3.69922 22.3 3.79922 22.3C4.09922 22.3 4.29922 22.1 4.59922 22L7.59922 19.5H20.0992C20.4992 19.5 20.8992 19.3 21.1992 19.1C21.4992 18.8 21.5992 18.4 21.5992 18V6C21.7992 5.6 21.5992 5.2 21.2992 4.9ZM20.1992 18H7.69922C7.29922 18 6.99922 18.1 6.69922 18.4L3.69922 20.9V6H20.1992V18Z"
      fill={iconColor}
    />
    <path
      d="M11.9984 13.0984C12.606 13.0984 13.0984 12.606 13.0984 11.9984C13.0984 11.3909 12.606 10.8984 11.9984 10.8984C11.3909 10.8984 10.8984 11.3909 10.8984 11.9984C10.8984 12.606 11.3909 13.0984 11.9984 13.0984Z"
      fill={iconColor}
    />
    <path
      d="M7.49844 13.0984C8.10595 13.0984 8.59844 12.606 8.59844 11.9984C8.59844 11.3909 8.10595 10.8984 7.49844 10.8984C6.89092 10.8984 6.39844 11.3909 6.39844 11.9984C6.39844 12.606 6.89092 13.0984 7.49844 13.0984Z"
      fill={iconColor}
    />
    <path
      d="M16.4984 13.0984C17.106 13.0984 17.5984 12.606 17.5984 11.9984C17.5984 11.3909 17.106 10.8984 16.4984 10.8984C15.8909 10.8984 15.3984 11.3909 15.3984 11.9984C15.3984 12.606 15.8909 13.0984 16.4984 13.0984Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default SmsIcon;
