import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const TextColorIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M5.76094 16.2607C5.82789 16.2924 5.90043 16.3105 5.97441 16.3141C6.04838 16.3176 6.12233 16.3066 6.19202 16.2815C6.2617 16.2564 6.32575 16.2178 6.38047 16.1679C6.4352 16.118 6.47954 16.0578 6.51094 15.9907L8.12156 12.5641H15.8784L17.4909 15.9907C17.5363 16.0874 17.6083 16.1691 17.6985 16.2264C17.7886 16.2837 17.8932 16.3141 18 16.3141C18.0827 16.3144 18.1643 16.2961 18.2391 16.2607C18.3061 16.2293 18.3664 16.185 18.4163 16.1302C18.4662 16.0755 18.5048 16.0115 18.5298 15.9418C18.5549 15.8721 18.566 15.7982 18.5624 15.7242C18.5589 15.6502 18.5407 15.5777 18.5091 15.5107L12.5091 2.76071C12.4636 2.66407 12.3916 2.58236 12.3015 2.52513C12.2114 2.46789 12.1068 2.4375 12 2.4375C11.8932 2.4375 11.7886 2.46789 11.6985 2.52513C11.6084 2.58236 11.5364 2.66407 11.4909 2.76071L5.49094 15.5107C5.45927 15.5777 5.44113 15.6502 5.43756 15.7242C5.43399 15.7982 5.44507 15.8721 5.47016 15.9418C5.49524 16.0115 5.53384 16.0755 5.58374 16.1302C5.63364 16.185 5.69386 16.2293 5.76094 16.2607ZM12 4.32258L15.3488 11.4391H8.65125L12 4.32258ZM20.8125 20.2516C20.8125 20.4008 20.7532 20.5439 20.6477 20.6494C20.5423 20.7549 20.3992 20.8141 20.25 20.8141H3.75C3.60082 20.8141 3.45774 20.7549 3.35225 20.6494C3.24676 20.5439 3.1875 20.4008 3.1875 20.2516C3.1875 20.1025 3.24676 19.9594 3.35225 19.8539C3.45774 19.7484 3.60082 19.6891 3.75 19.6891H20.25C20.3992 19.6891 20.5423 19.7484 20.6477 19.8539C20.7532 19.9594 20.8125 20.1025 20.8125 20.2516Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default TextColorIcon;
