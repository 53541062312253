import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ForwardIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3131 2.9797C10.5083 2.78444 10.8249 2.78444 11.0202 2.9797L14.3535 6.31303C14.5488 6.50829 14.5488 6.82488 14.3535 7.02014L11.0202 10.3535C10.8249 10.5487 10.5083 10.5487 10.3131 10.3535C10.1178 10.1582 10.1178 9.84163 10.3131 9.64637L13.2929 6.66659L10.3131 3.68681C10.1178 3.49154 10.1178 3.17496 10.3131 2.9797Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 13.3334C1.5 9.37527 4.70852 6.16675 8.66667 6.16675H14C14.2761 6.16675 14.5 6.39061 14.5 6.66675C14.5 6.94289 14.2761 7.16675 14 7.16675H8.66667C5.26081 7.16675 2.5 9.92756 2.5 13.3334V14.0001C2.5 14.2762 2.27614 14.5001 2 14.5001C1.72386 14.5001 1.5 14.2762 1.5 14.0001V13.3334Z"
    />
  </SvgIcon>
);

export default ForwardIcon;
