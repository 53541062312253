import React from 'react';

import { Text } from '~/components/core';
import LoadingIndicator from '~/components/LoadingIndicator';
import { useTableau } from '~/components/ReportsPage/Context/TableauContext';
import { MissingTableauLicenseMessage } from '~/components/ReportsPage/MissingTableauLicenseMessage';
import { HowToUseTableauLink } from '~/components/ReportsPage/pages/HowToUseTableauLink';
import { WorkbookPageHeaderLayout } from '~/components/ReportsPage/pages/WorkbookPageHeaderLayout';

interface WorkbookEditorLayoutProps {
  editorComponent: React.ReactNode;
  isLoading: boolean;
  token?: string;
}

export const WorkbookEditorLayout: React.FC<WorkbookEditorLayoutProps> = ({ editorComponent, isLoading, token }) => {
  const { me } = useTableau();
  const hasExplorerRole = me && me.site_role.startsWith('Explorer') && !isLoading;

  return (
    <section className="flex h-[calc(100vh-100px)] flex-col justify-center bg-slate-100">
      {(!token || isLoading) && <LoadingIndicator isError={false} />}
      {!hasExplorerRole && <MissingTableauLicenseMessage />}
      {hasExplorerRole && token && (
        <>
          <WorkbookPageHeaderLayout
            headerComponent={
              <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD}>
                Report Builder
              </Text>
            }
            actionsComponent={<HowToUseTableauLink />}
          />
          <div className="mx-20 border-1 border-solid border-slate-600">{editorComponent}</div>
        </>
      )}
    </section>
  );
};
