import React from 'react';
import { TablePagination } from '@material-ui/core';

import { paginationPropsPropTypesShape } from '../propTypes';

import PaginationActions from './PaginationActions';

import styles from '../sortableTable.module.scss';

const TablePaginationSection = ({ combinedPaginationProps }) => {
  const { onChangePage: onPageChange, page, count, rowsPerPage, disabled } = combinedPaginationProps;

  return (
    <TablePagination
      className={styles.tablePagination}
      rowsPerPageOptions={combinedPaginationProps.rowsPerPageOptions}
      component="div"
      rowsPerPage={rowsPerPage}
      count={count}
      page={page}
      onChangePage={combinedPaginationProps.onChangePage}
      onChangeRowsPerPage={combinedPaginationProps.onChangeRowsPerPage}
      ActionsComponent={() => (
        <PaginationActions
          count={count}
          page={page}
          onPageChange={onPageChange}
          disabled={disabled}
          rowsPerPage={rowsPerPage}
        />
      )}
    />
  );
};

TablePaginationSection.propTypes = {
  combinedPaginationProps: paginationPropsPropTypesShape,
};

export default TablePaginationSection;
