export const INTEGRATIONS_TAB_KEY = 'integrations';

export const XACT_FIELD_ID = 'xact';

export const XACT_FIELDS = {
  IS_XACT_CONTRACTOR: `is_xactanalysis_contractor`,
  XACT_CARRIER_ID: `carrier_id`,
  XACTNET_ADDRESS: `xactnet_address`,
};

export const FORMIK_INTEGRATIONS_FIELD_IDS = {
  IS_XACT_CONTRACTOR: `${XACT_FIELD_ID}.${XACT_FIELDS.IS_XACT_CONTRACTOR}`,
  XACT_CARRIER_ID: `${XACT_FIELD_ID}.${XACT_FIELDS.XACT_CARRIER_ID}`,
  XACTNET_ADDRESS: `${XACT_FIELD_ID}.${XACT_FIELDS.XACTNET_ADDRESS}`,
};
