import defaultAdditionalFinanceMenuOptions from '../../exposures/PaymentRequestContainer/DefaultMethodSpecificPaymentRequest/DefaultAdditionalFinanceMenuOptions/DefaultAdditionalFinanceMenuOptions';
import getMethodSpecificPaymentRequest from '../../exposures/PaymentRequestContainer/PaymentMethodSpecificRequest';
import { useCms } from '../../hooks/useCms';

interface ExposureFinanceMetadata {
  id: number;
  deductible_amount: number;
  is_deductible_waived: boolean;
  was_deductible_paid: boolean;
}

interface FinanceRecord {
  id: number;
  request_date: string;
  financial_status_last_update: string;
  exposure_label: string;
  request_status: string;
  decision_date: string;
  financial_status: string;
  amount: number;
  amount_orig_currency: number;
  request_currency: string;
  issued_by: string;
  expense_other_type: string;
  payment_type_desc: string;
  payees: Array<Record<string, unknown>>;
  sent_to: string;
  reference: string;
  note: string;
  payment_request: Record<string, unknown>;
  claim_id: number;
  claim_type: string;
  claim_id_display: string;
  claim_is_closed: boolean;
  exposure_id: number;
  exposure_finance_metadata: ExposureFinanceMetadata;
  payable_with_reserve_type: 'indemnity' | 'expenses';
  is_cancellable: boolean;
  payment_method_display_name: string;
  sub_reserve_desc: string;
  sub_reserves?: { type_key: string; desc: string; amount: number }[];
  sub_organization_id: number;
}

interface MenuItemComponentProps {
  financeRecord: FinanceRecord;
  onReloadFinances: () => void;
}

interface AdditionalFinanceTableMenuOption {
  id: string;
  condition: (financeRecord: FinanceRecord) => boolean;
  MenuItemComponent: React.FC<MenuItemComponentProps>;
}

function useAdditionalFinanceTableMenuOptions(financeRecord: FinanceRecord): AdditionalFinanceTableMenuOption[] {
  const paymentRequest = financeRecord.payment_request;
  const paymentMethod = paymentRequest.payment_method;

  const { userOrganization } = useCms() as { userOrganization: { name: string } };

  const { additionalFinanceTableMenuOptions: methodSpecificAdditionalFinanceTableMenuOptions } =
    getMethodSpecificPaymentRequest(paymentMethod, userOrganization);
  const additionalFinanceTableMenuOptions: AdditionalFinanceTableMenuOption[] = [
    ...defaultAdditionalFinanceMenuOptions,
    ...methodSpecificAdditionalFinanceTableMenuOptions,
  ];
  return additionalFinanceTableMenuOptions.filter((opt) => !opt.condition || opt.condition(financeRecord));
}

export default useAdditionalFinanceTableMenuOptions;
