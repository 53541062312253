import { useFormikContext } from 'formik';

import { useClaim } from '~/components/ClaimContainer';
import type { MoiFormikValues, MoiMethod } from '~/components/exposures/moi/MoiStepper/types';
import { useXactAnalysis } from '~/components/SystemConfiguration/Integrations/XactAnalysis/hooks/useXactAnalysis';
import useDataFetcher from '~/components/useDataFetcher';
import { stringCmp } from '~/Utils';

export interface UseMoiMethodsResults {
  isLoading: boolean;
  isError: boolean;
  moiMethodsById: { [key: string]: MoiMethod };
  getMoiMethodExpertises: (method_id: string | number) => string[];
  isMoiMethodOnlyForServiceProviders: (method: MoiMethod) => boolean;
  moiMethodsOptions: MoiMethod[];
  selectedMoiMethod: MoiMethod | null;
}

const useMoiMethods = (exposureId: number | undefined, isGeneralExposure?: boolean): UseMoiMethodsResults => {
  const { isEnabled: isXactAnalysisEnabled } = useXactAnalysis();
  const { claim } = useClaim();
  const { values } = useFormikContext<MoiFormikValues>();

  const {
    isLoading,
    isError,
    data: moi_methods,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/exposures/${exposureId}/method_of_inspection/methods`);

  const isMoiMethodSupported = (moiMethod: MoiMethod): boolean => {
    const doesFitXact = !(!isXactAnalysisEnabled && moiMethod.key === 'xactanalysis');
    const doesFitClaimLevel = !(isGeneralExposure && moiMethod.is_claim_level_allowed === false);

    return doesFitXact && doesFitClaimLevel;
  };

  const moiMethodsOptions =
    moi_methods
      ?.sort((method1: MoiMethod, method2: MoiMethod): boolean =>
        stringCmp(method1.display_name || '', method2.display_name || '')
      )
      ?.filter(isMoiMethodSupported) ?? [];

  const moiMethodsById = Object.fromEntries(moiMethodsOptions.map((method: MoiMethod) => [method.id, method]));
  const getMoiMethodExpertises = (method_id: string | number): string[] => {
    return moiMethodsOptions?.find(({ id }: { id: string }) => id === method_id)?.moi_expertises || [];
  };

  const isMoiMethodOnlyForServiceProviders = (method: MoiMethod): boolean => {
    return !method || ['xactanalysis'].includes(method?.key);
  };

  return {
    isLoading,
    isError,
    moiMethodsById,
    getMoiMethodExpertises,
    isMoiMethodOnlyForServiceProviders,
    moiMethodsOptions,
    selectedMoiMethod: moiMethodsById[values?.moi_method_id] || null,
  };
};

export default useMoiMethods;
