import React from 'react';

import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions, TooltipIcon } from '~/components/core';
import useXactAssigment from '~/components/hooks/useXactAssignment';
import { EyeIcon, PencilIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';

import useCccAssignment from '../../hooks/useCccAssigment';

import type { MethodOfInspection } from './MoiStepper/types';

interface MoiTableActionsProps {
  moiRecord: MethodOfInspection;
  onViewStatusDataClick: (moiRecord: MethodOfInspection) => void;
  readOnly: boolean;
  onEditClick: (moiRecord: MethodOfInspection) => void;
  claimIdDisplayWithExposureSuffix: string;
}

const MoiTableActions: React.FC<MoiTableActionsProps> = ({
  moiRecord,
  onViewStatusDataClick,
  readOnly,
  onEditClick,
  claimIdDisplayWithExposureSuffix,
}) => {
  const { isCccEnabled, isCccAssignment } = useCccAssignment(moiRecord.id, claimIdDisplayWithExposureSuffix);
  const { isXactAnalysisEnabled, isXactAssignment } = useXactAssigment(moiRecord.id);
  const getIsVendorExtraData = (moiRecord: MethodOfInspection) => {
    return (
      moiRecord?.vendor_status_data?.length > 0 ||
      (isCccEnabled && isCccAssignment) ||
      (isXactAnalysisEnabled && isXactAssignment)
    );
  };
  return (
    <div className="flex w-full justify-end pr-4">
      {getIsVendorExtraData(moiRecord) && (
        <TooltipIcon title="View Status Update">
          <InlineIconButton
            icon={EyeIcon}
            className="ml-4 mr-16 cursor-pointer hover:fill-teal-700"
            onClick={() => onViewStatusDataClick(moiRecord)}
            defaultColor={undefined}
            disabled={undefined}
            iconStyle={undefined}
            tooltipTitle={undefined}
            useIconButton={undefined}
            wrapWithSpan={undefined}
            ignorePermissions={undefined}
            ariaLabel={undefined}
          />
        </TooltipIcon>
      )}
      {!readOnly && (
        <RestrictedPermissions action={PERMISSION_ACTIONS.MOI} verb={PERMISSION_VERBS.WRITE}>
          <TooltipIcon title="Edit MOI">
            <InlineIconButton
              icon={PencilIcon}
              className="ml-4 cursor-pointer hover:stroke-teal-700"
              onClick={() => onEditClick(moiRecord)}
              defaultColor={undefined}
              disabled={undefined}
              iconStyle={undefined}
              tooltipTitle={undefined}
              useIconButton={undefined}
              wrapWithSpan={undefined}
              ignorePermissions={undefined}
              ariaLabel={undefined}
            />
          </TooltipIcon>
        </RestrictedPermissions>
      )}
    </div>
  );
};

export default MoiTableActions;
