import React, { useCallback, useEffect, useState } from 'react';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';

import Chip from '~/components/core/Atomic/Chip/Chip';

import { reportAxiosError } from '../Utils';

import ViewCommunicationCardContainer from './communications/ViewCommunicationCardContainer';
import CardDialog from './CardDialog';
import ClaimLink from './ClaimLink';
import FileClassificationContainer from './FileClassificationContainer';
import InlineIconButton from './InlineIconButton';
import LoadingIndicator from './LoadingIndicator';
import OverflowTextWithToolTip from './OverflowTextWithToolTip';
import PlainTable from './PlainTable';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

function FileClassificationPage() {
  const {
    isLoading: isLoadingActive,
    isError: isErrorActive,
    data: requestsData,
    reloadData: reloadDataActive,
  } = useDataFetcher('/api/v1/claims/file_classification_requests');
  const {
    isLoading: isLoadingRecent,
    isError: isErrorRecent,
    data: recentData,
    reloadData: reloadDataRecent,
  } = useDataFetcher('/api/v1/claims/file_classification_requests/recent');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileClassificationRequestToShow, setFileClassificationRequestToShow] = useState();
  const [showClassifiedCommunicationId, setShowClassifiedCommunicationId] = useState();
  const classes = useStyles();

  const [allFileClassificationRequests, setAllFileClassificationRequests] = useState([]);
  const [filteredFileClassificationRequests, setFilteredFileClassificationRequests] = useState([]);
  const [allRecentFileClassificationRequests, setAllRecentFileClassificationRequests] = useState([]);
  const [filteredRecentFileClassificationRequests, setFilteredRecentFileClassificationRequests] = useState([]);
  const [classificationLabelsDict, setClassificationLabelsDict] = useState({});
  const [requestsToLabels, setRequestsToLabels] = useState({});
  const [sortedClassificationLabels, setSortedClassificationLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState(new Set());

  const mergeMetadata = useCallback((metadata) => {
    if (metadata?.labelsDict) {
      setClassificationLabelsDict((classificationLabelsDict) => ({
        ...classificationLabelsDict,
        ...metadata.labelsDict,
      }));
    }
    if (metadata?.requestsToLabels) {
      setRequestsToLabels((requestsToLabels) => ({ ...requestsToLabels, ...metadata.requestsToLabels }));
    }
  }, []);

  // Set data when the requests response is done loading
  useEffect(() => {
    if (!isLoadingActive && !isErrorActive) {
      setAllFileClassificationRequests(requestsData.allRequests);
      setFilteredFileClassificationRequests(requestsData.allRequests);

      mergeMetadata(requestsData.metadata);
    }
  }, [isLoadingActive, isErrorActive, mergeMetadata, requestsData]);

  // Set data when the recent requests response is done loading
  useEffect(() => {
    if (!isLoadingRecent && !isErrorRecent) {
      setAllRecentFileClassificationRequests(recentData.allRequests);
      setFilteredRecentFileClassificationRequests(recentData.allRequests);

      mergeMetadata(recentData.metadata);
    }
  }, [isLoadingRecent, isErrorRecent, mergeMetadata, recentData]);

  useEffect(() => {
    // Sort labels
    const sorted = Object.entries(classificationLabelsDict).sort((label1, label2) => {
      const label1Name = label1[1].toUpperCase();
      const label2Name = label2[1].toUpperCase();
      if (label1Name === label2Name) {
        return 0;
      }

      return label1Name > label2Name ? 1 : -1;
    });
    setSortedClassificationLabels(sorted);
  }, [classificationLabelsDict]);

  // filter the requests and recent requests based on the selected labels whenever that list changes
  useEffect(() => {
    if (selectedLabels.size === 0) {
      setFilteredFileClassificationRequests([...allFileClassificationRequests]);
      setFilteredRecentFileClassificationRequests([...allRecentFileClassificationRequests]);
      return;
    }

    // converted to strings because the labels are object keys and therefore saved as strings in the set
    const filteredRequests = allFileClassificationRequests.filter((request) =>
      selectedLabels.has((requestsToLabels[request.id] || '').toString())
    );
    const filteredRecent = allRecentFileClassificationRequests.filter((request) =>
      selectedLabels.has((requestsToLabels[request.id] || '').toString())
    );

    setFilteredFileClassificationRequests(filteredRequests);
    setFilteredRecentFileClassificationRequests(filteredRecent);
  }, [allFileClassificationRequests, allRecentFileClassificationRequests, requestsToLabels, selectedLabels]);

  const activeRequestsColumnData = [
    { id: 'file_name', label: 'Name' },
    {
      id: 'view_action',
      width: 25,
      disableSort: true,
      specialCell:
        // eslint-disable-next-line react/display-name
        (fileClassificationRequest) => (
          <InlineIconButton
            disabled={isSubmitting}
            icon={VisibilityIcon}
            onClick={() => setFileClassificationRequestToShow(fileClassificationRequest)}
          />
        ),
    },
  ];

  const handleReload = async () => {
    await Promise.all([reloadDataActive(), reloadDataRecent()]);
  };

  const handleUndismissFile = async (fileClassificationRequest) => {
    try {
      setIsSubmitting(true);
      await axios.post(`/api/v1/claims/file_classification_requests/${fileClassificationRequest.id}/undismiss`);
      await handleReload();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  };
  const recentlyHandledRequestsColumnData = [
    { id: 'file_name', label: 'Name' },
    {
      id: 'status',
      label: 'Status',
      specialCell: (fileClassificationRequest) =>
        fileClassificationRequest.datetime_classified ? 'Classified' : 'Deleted',
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'claim',
      label: 'Claim',
      specialCell: (fileClassificationRequest) => {
        return fileClassificationRequest.result_communication_claim_id ? (
          <ClaimLink
            claimId={parseInt(fileClassificationRequest.result_communication_claim_id)}
            linkText={fileClassificationRequest.result_communication_claim_id_display}
            openInNewTab
          />
        ) : null;
      },
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'restore_action',
      width: 25,
      disableSort: true,
      specialCell: (fileClassificationRequest) =>
        fileClassificationRequest.datetime_dismissed && (
          <InlineIconButton
            disabled={isSubmitting}
            icon={SettingsBackupRestoreIcon}
            onClick={() => handleUndismissFile(fileClassificationRequest)}
          />
        ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'view_action',
      width: 25,
      disableSort: true,
      specialCell: (fileClassificationRequest) => (
        <InlineIconButton
          onClick={() =>
            fileClassificationRequest.datetime_classified
              ? setShowClassifiedCommunicationId(fileClassificationRequest.result_communication_id)
              : window.open(
                  `/api/v1/claims/file_classification_requests/${fileClassificationRequest.id}/stored_file`,
                  '_blank'
                )
          }
          icon={VisibilityIcon}
          disabled={isSubmitting}
        />
      ),
    },
  ];

  if (sortedClassificationLabels.length > 1) {
    const labelColumn = {
      id: 'request_label',
      label: requestsData?.metadata?.labelHeader || 'Classification',
      width: 250,
      specialCell: (fileClassificationRequest) => {
        return requestsToLabels[fileClassificationRequest.id]
          ? classificationLabelsDict[requestsToLabels[fileClassificationRequest.id]]
          : '';
      },
    };

    activeRequestsColumnData.splice(-1, 0, labelColumn);
    recentlyHandledRequestsColumnData.splice(-1, 0, labelColumn);
  }

  if (isLoadingActive || isErrorActive || isLoadingRecent || isErrorRecent) {
    return <LoadingIndicator isError={isErrorActive || isErrorRecent} />;
  }

  const handleLabelClipClick = (stringLabelValue) => {
    const currentSelectedLabels = new Set(selectedLabels.values());
    if (currentSelectedLabels.has(stringLabelValue)) {
      currentSelectedLabels.delete(stringLabelValue);
    } else {
      currentSelectedLabels.add(stringLabelValue);
    }
    setSelectedLabels(currentSelectedLabels);
  };

  return (
    <div className={classes.pageBody}>
      <CardDialog title="Physical Mail Classification">
        {sortedClassificationLabels.length > 1 &&
          sortedClassificationLabels.map(([labelId, label]) => (
            <Chip
              key={labelId}
              size="small"
              color={selectedLabels.has(labelId) ? 'primary' : 'default'}
              onClick={() => handleLabelClipClick(labelId)}
              label={<OverflowTextWithToolTip maxWidth="220px">{label}</OverflowTextWithToolTip>}
              className={classes.chip}
            />
          ))}
        <div className={classes.cardDivRow}>
          <CardDialog title="New Mail">
            <PlainTable
              columns={activeRequestsColumnData}
              rows={filteredFileClassificationRequests}
              autoPaginateRowsPerPage={25}
            />
          </CardDialog>
        </div>
        <div className={classes.cardDivRow}>
          <CardDialog title="Recently Handled">
            <PlainTable
              columns={recentlyHandledRequestsColumnData}
              rows={filteredRecentFileClassificationRequests}
              autoPaginateRowsPerPage={25}
            />
          </CardDialog>
        </div>
      </CardDialog>

      {fileClassificationRequestToShow && (
        <FileClassificationContainer
          fileClassificationRequest={fileClassificationRequestToShow}
          open
          onClose={() => setFileClassificationRequestToShow(undefined)}
          onUpdate={handleReload}
        />
      )}

      {showClassifiedCommunicationId && (
        <ViewCommunicationCardContainer
          communicationId={showClassifiedCommunicationId}
          onUpdate={handleReload}
          onClose={() => setShowClassifiedCommunicationId(null)}
          isDialog
        />
      )}
    </div>
  );
}

export default FileClassificationPage;
