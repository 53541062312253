import React from 'react';
import PropTypes from 'prop-types';

import Button from '~/components/core/Atomic/Buttons/Button';

import PermissionDeniedTooltip from '../../Permissions/PermissionDeniedTooltip';
import { useRestrictedPermissions } from '../../Permissions/RestrictedPermissions';
import {
  FsWrappersWithTooltipSharedProptypes,
  getEnumObjectFromArray,
  mixpanelOnclickFactory,
  MUI_COLOR_STRINGS,
} from '../FsWrappersUtils';
import { WrapperWithTooltip } from '../UtilityComponents/WrapperWithTooltip';

const FsButton = ({
  children,
  onClick,
  className,
  classes,
  disabled,
  disableRipple,
  color,
  variant,
  endIcon,
  component,
  to,
  startIcon,
  type,
  size,
  style,
  autoFocus,
  fullWidth,
  href,
  ignorePermissions,
  tooltipText,
  mixpanelEvent,
  mixpanelEventData,
}) => {
  const { userHasContextPermissions } = useRestrictedPermissions();
  const hasPermissions = ignorePermissions || userHasContextPermissions;
  const isDisabled = disabled || !hasPermissions;

  const handleClick = mixpanelEvent ? mixpanelOnclickFactory({ mixpanelEvent, mixpanelEventData, onClick }) : onClick;

  const button = (
    <Button
      onClick={handleClick}
      className={className}
      classes={classes}
      disabled={isDisabled}
      disableRipple={disableRipple}
      color={color}
      variant={variant}
      endIcon={endIcon}
      component={component}
      to={to}
      startIcon={startIcon}
      type={type}
      size={size}
      style={style}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      href={href}
    >
      {children}
    </Button>
  );

  if (hasPermissions) {
    return <WrapperWithTooltip tooltipText={tooltipText}>{button}</WrapperWithTooltip>;
  }

  return <PermissionDeniedTooltip>{button}</PermissionDeniedTooltip>;
};

FsButton.VARIANTS = getEnumObjectFromArray(['text', 'outlined', 'contained']);
FsButton.COLORS = getEnumObjectFromArray(MUI_COLOR_STRINGS);
FsButton.SIZES = getEnumObjectFromArray(['small', 'medium', 'large']);

FsButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  disableRipple: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(FsButton.COLORS)),
  variant: PropTypes.oneOf(Object.keys(FsButton.VARIANTS)),
  endIcon: PropTypes.node,
  component: PropTypes.elementType,
  to: PropTypes.string,
  startIcon: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(FsButton.SIZES)),
  style: PropTypes.object,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  mixpanelEvent: PropTypes.string,
  mixpanelEventData: PropTypes.object,
  ...FsWrappersWithTooltipSharedProptypes,
};

export default FsButton;
