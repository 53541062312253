import React from 'react';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';

interface CommunicationActionSendNewProps {
  isSubmitting: boolean;
  onSubmit: () => void;
  disabled?: boolean;
  buttonText?: string;
}

const CommunicationActionSendNew: React.FC<CommunicationActionSendNewProps> = ({
  isSubmitting,
  onSubmit,
  disabled,
  buttonText,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonsContainer}>
      <Button variant="contained" color="primary" onClick={onSubmit} disabled={isSubmitting || disabled}>
        {buttonText || 'Send'}
      </Button>
    </div>
  );
};

export default CommunicationActionSendNew;
