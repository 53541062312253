import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import { useLob } from '~/components/hooks/useLob';
import { useLobConfiguredFields } from '~/components/hooks/useLobConfiguredFields';

import { CONFIGURATION_FEATURES_NAMES } from '../../../Types';
import { isFeatureEnabled, isPolicyManuallyFilled, reportAxiosError } from '../../../Utils';
import { getEntityIcon, getFirstPartyFromClaim } from '../../../Utils/ClaimUtils';
import { useClaim } from '../../ClaimContainer';
import { FsButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../core';
import { useCms } from '../../hooks/useCms';
import { PedestrianIcon } from '../../icons';
import useOrganization from '../../OrganizationContext';

import UnlistedVehicleCard from './CoveredEntity/AutoSpecific/UnlistedVehicleCard';
import CoveredEntityCard from './CoveredEntity/CoveredEntityCard';
import EditCoveredEntityDialog from './CoveredEntity/EditCoveredEntityDialog/EditCoveredEntityDialog';
import PolicyDetailsCard from './PolicyDetails/PolicyDetailsCard';

import { useStyles } from '../../../assets/styles';
import styles from './GenericPolicyPage.module.scss';

const getAutoFieldsConfig = ({
  incidentConfiguration,
  coveredObject = null,
  vehicleTypesDict = null,
  unlisted = false,
}) => {
  const coveredObjectTypeLabel = unlisted ? 'Unlisted First Party' : 'Covered Vehicle';
  const Icon = getEntityIcon('vehicle_icon');
  const coveredObjectType = 'covered_vehicle';
  const vehicleTypesList = Object.keys(vehicleTypesDict).map((id) => ({ id, desc: vehicleTypesDict[id].desc }));

  const fields = [
    { id: 'year', type: 'year', desc: 'Year', active: true },
    { id: 'make', type: 'string', desc: 'Make', active: true },
    { id: 'model', type: 'string', desc: 'Model', active: true },
    { id: 'series', type: 'string', desc: 'Series', active: true },
    { id: 'vin', type: 'string', desc: 'VIN', active: true },
    { id: 'plate_number', type: 'string', desc: 'Plate Number', active: true },
    { id: 'vehicle_plate_state', type: 'us_state_select', desc: 'Registration State', active: true },
    { id: 'vehicle_owner_contact_id', type: 'contact', desc: 'Owner', active: true },
    { id: 'vehicle_type', type: 'select', desc: 'Vehicle Type', options: vehicleTypesList, active: true },
    { id: 'mileage', type: 'number', desc: 'Mileage', active: true },
    { id: 'ownership_status', type: 'string', desc: 'Ownership Status', active: true },
    { id: 'color', type: 'string', desc: 'Color', active: true },
    { id: 'fuel_type', type: 'string', desc: 'Fuel Type', active: true },
    { id: 'vehicle_value', type: 'monetary', desc: 'Vahicle Value', active: true },
    { id: 'vehicle_cost_new', type: 'monetary', desc: 'New Vahicle Value', active: true },
    { id: 'engine_type', type: 'string', desc: 'Engine Type', active: true },
    { id: 'engine_capacity', type: 'number', desc: 'Engine Capacity', active: true },
    { id: 'number_of_seats', type: 'number', desc: 'Number Of Seats', active: true },
  ];

  const overrideTypes = ['year'];

  const defaultFields = fields.map((field) => {
    const vehicleConf = incidentConfiguration?.policy?.covered_vehicle?.vehicle ?? {};
    return {
      ...field,
      ...vehicleConf[field.id],
      type: overrideTypes.includes(field?.type) ? field?.type : vehicleConf[field.id]?.type || field?.type,
      id: `vehicle.${field.id}`,
      value: coveredObject?.vehicle[field.id],
    };
  });

  const configuredFields = incidentConfiguration?.policy?.covered_vehicle?.configured_fields ?? [];
  return { Icon, coveredObjectTypeLabel, configuredFields, coveredObjectType, defaultFields };
};

const FirstPartyPedestrianWrapper = () => {
  const { claim } = useClaim();
  const firstParty = getFirstPartyFromClaim(claim);
  const isManualPolicy = isPolicyManuallyFilled(claim);
  const defaultFields = [
    {
      id: 'contact_id',
      desc: 'Name',
      type: 'contact',
      value: firstParty.involved_non_motorist.contact_id,
      mandatory: true,
      active: true,
    },
  ];

  const isBicyclist = firstParty.non_motorist_type === 'bicyclist';
  const pedestrianTitle = isBicyclist ? 'Bicyclist' : 'Pedestrian';
  const Icon = isBicyclist ? DirectionsBikeIcon : PedestrianIcon;
  const policyPath = isManualPolicy ? `pedestrian_covered_entity` : `api_policy/pedestrian_covered_entity`;
  const pedestrianUpdatePath = `/api/v1/auto_claims/${claim.id}/${policyPath}`;
  const pedestrianCoverageUpdatePath = `/api/v1/auto_claims/${claim.id}/${policyPath}/coverage/`;
  const pedestrianSharedCoverageUpdatePath = `/api/v1/auto_claims/${claim.id}/${policyPath}/shared_coverage/`;

  return (
    <CoveredEntityCard
      icon={<Icon />}
      coveredObjectTypeLabel={`First Party ${pedestrianTitle}`}
      defaultFields={defaultFields}
      configuredFields={[]}
      getPatchCoveredEntityEndpoint={() => pedestrianUpdatePath}
      getPatchCoveragesEndpoint={(coverageTypeId) => `${pedestrianCoverageUpdatePath}${coverageTypeId}`}
      getPatchSharedCoveragesEndpoint={(sharedCoverageKey) =>
        `${pedestrianSharedCoverageUpdatePath}${sharedCoverageKey}`
      }
      fetchCoveredEntityEndpoint={`/api/v1/auto_claims/${claim.id}/pedestrian_covered_entity_details`}
    />
  );
};

const GeneralCoveredObjectWrapper = ({ coveredObject, isFirstParty = false }) => {
  const { Icon, coveredObjectTypeLabel, configuredFields, defaultFields } = useLobConfiguredFields(coveredObject, true);

  return (
    <CoveredEntityCard
      icon={Icon && <Icon />}
      coveredObjectTypeLabel={coveredObjectTypeLabel}
      defaultFields={defaultFields}
      configuredFields={configuredFields}
      coveredObjectIdForPatchEndpoints={coveredObject.id}
      coveredObject={coveredObject}
      isFirstParty={isFirstParty}
      fetchCoveredEntityEndpoint={`/api/v1/policies/${coveredObject.policy_id}/covered_entities/${coveredObject.covered_entity_id}`}
    />
  );
};

GeneralCoveredObjectWrapper.propTypes = {
  coveredObject: PropTypes.object.isRequired,
  isFirstParty: PropTypes.bool,
};

const CoveredVehicleWrapper = ({ coveredObject, isFirstParty = false }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const { vehicleTypesDict } = useOrganization();
  const { Icon, coveredObjectTypeLabel, configuredFields, defaultFields } = getAutoFieldsConfig({
    incidentConfiguration,
    coveredObject,
    vehicleTypesDict,
  });

  return (
    <CoveredEntityCard
      icon={Icon && <Icon />}
      coveredObjectTypeLabel={coveredObjectTypeLabel}
      defaultFields={defaultFields}
      configuredFields={configuredFields}
      coveredObjectIdForPatchEndpoints={coveredObject.id}
      coveredObject={coveredObject}
      isFirstParty={isFirstParty}
      fetchCoveredEntityEndpoint={`/api/v1/policies/${coveredObject.policy_id}/covered_entities/${coveredObject.covered_entity_id}`}
    />
  );
};

CoveredVehicleWrapper.propTypes = {
  coveredObject: PropTypes.object.isRequired,
  isFirstParty: PropTypes.bool,
};

const GenericPolicyPage = () => {
  const classes = useStyles();
  const { userOrganization } = useCms();
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { lob } = useLob();
  const { incidentConfiguration } = useIncidentConfiguration();
  const isAutoClaim = lob === 'auto_claim';
  const coveredObjectsKey = isAutoClaim ? 'covered_vehicles' : 'covered_objects';
  const coveredObjects = claim.policy[coveredObjectsKey];
  let { icon, coveredObjectTypeLabel, configuredFields, coveredObjectType, defaultFields, isProperty } =
    useLobConfiguredFields(null, false);
  const [addObjectDialogIsOpen, setAddObjectDialogIsOpen] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const isConfigurableCoveragesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );
  const { vehicleTypesDict } = useOrganization();
  const isManualPolicy = isPolicyManuallyFilled(claim);
  const policyPrefix = `${isManualPolicy ? '' : 'api_'}`;
  coveredObjects?.sort((x, y) => x.id - y.id);

  if (isAutoClaim) {
    const autoSpecificFields = getAutoFieldsConfig({ incidentConfiguration, coveredObject: null, vehicleTypesDict });
    icon = autoSpecificFields.icon;
    coveredObjectTypeLabel = autoSpecificFields.coveredObjectTypeLabel;
    configuredFields = autoSpecificFields.configuredFields;
    coveredObjectType = autoSpecificFields.coveredObjectType;
    defaultFields = autoSpecificFields.defaultFields;
    coveredObjects?.sort((x, y) => (y?.id === claim?.covered_vehicle_id ? 1 : -1));
  } else {
    const parties = isProperty ? claim?.incident?.other_properties_parties : claim?.incident?.involved_person_parties;
    const firstParty = parties?.find((involved) => involved?.is_first_party);
    const firstPartyCoveredObjectId = (isProperty ? firstParty?.generic_property_involved : firstParty?.involved_person)
      ?.covered_object_id;
    coveredObjects?.sort((x, y) => (y?.id === firstPartyCoveredObjectId ? 1 : -1));
  }

  async function handleCreateNewCoveredEntity(values) {
    setIsSaving(true);
    try {
      await axios.post(`/api/v1/claims/${claim.id}/${policyPrefix}policy/new_covered_object`, {
        ...values,
        type: coveredObjectType,
      });
      await onAsyncClaimUpdate();
      setAddObjectDialogIsOpen(false);
    } catch (error) {
      reportAxiosError(error);
      setIsSaving(false);
      throw error;
    }
    setIsSaving(false);
  }

  return (
    <div className={styles.policyPageContainer}>
      <Grid container className={styles.policyPage}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography display="block" variant="h5" className={styles.policyPageHeader}>
              Policy
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.buttonsContainer}>
              <RestrictedPermissions
                action={isManualPolicy ? PERMISSION_ACTIONS.MANUAL_POLICY : PERMISSION_ACTIONS.API_POLICY}
                verb={PERMISSION_VERBS.WRITE}
              >
                <FsButton color="primary" onClick={() => setAddObjectDialogIsOpen(true)}>
                  <AddIcon className={classes.leftButtonIcon} />
                  {`Add ${coveredObjectTypeLabel}`}
                </FsButton>
              </RestrictedPermissions>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.shadowedCardDivRow}>
            <PolicyDetailsCard claim={claim} onClaimUpdate={onAsyncClaimUpdate} />
          </div>
        </Grid>
        <Grid item xs={6}>
          {isAutoClaim && claim?.unlisted_covered_vehicle && (
            <div className={classes.shadowedCardDivRow}>
              <UnlistedVehicleCard
                claim={claim}
                onClaimUpdate={onAsyncClaimUpdate}
                coveredVehicle={claim?.unlisted_covered_vehicle}
                isRental={claim?.is_unlisted_vehicle_rental}
              />
            </div>
          )}
          {coveredObjects?.map((coveredObject, i) => (
            <div key={i} className={classes.shadowedCardDivRow}>
              {isAutoClaim ? (
                <CoveredVehicleWrapper coveredObject={coveredObject} isFirstParty={i === 0} />
              ) : (
                <GeneralCoveredObjectWrapper coveredObject={coveredObject} isFirstParty={i === 0} />
              )}
            </div>
          ))}

          {isConfigurableCoveragesEnabled && claim.pedestrian_covered_entity_id && (
            <div className={classes.shadowedCardDivRow}>
              <FirstPartyPedestrianWrapper />
            </div>
          )}
        </Grid>
      </Grid>

      {addObjectDialogIsOpen && (
        <EditCoveredEntityDialog
          isUpdate={false}
          onClose={() => setAddObjectDialogIsOpen(false)}
          icon={icon}
          coveredObjectTypeLabel={coveredObjectTypeLabel}
          onSaveClick={handleCreateNewCoveredEntity}
          isSaving={isSaving}
          configuredFields={configuredFields}
          defaultFields={defaultFields}
        />
      )}
    </div>
  );
};

export { getAutoFieldsConfig };
export default GenericPolicyPage;
