import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useCms } from './hooks/useCms';
import AdjusterCalendar from './AdjusterCalendar';
import CardDialog from './CardDialog';

function CalendarContainer({ onClose, isDialog }) {
  const { setPageTitle } = useCms();
  const history = useHistory();
  history.listen(() => onClose && onClose());

  useEffect(() => setPageTitle('Calendar', 'Calendar - Five Sigma CMS'), [setPageTitle]);

  return (
    <CardDialog passThrough={!isDialog} isDialog open title="Calendar" onClose={onClose} maxWidth="xl" fullWidth>
      <div style={{ height: '85vh' }}>
        <AdjusterCalendar />
      </div>
    </CardDialog>
  );
}

CalendarContainer.propTypes = {
  onClose: PropTypes.func,
  isDialog: PropTypes.bool,
};

export default CalendarContainer;
