import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { Formik } from 'formik';
import { merge } from 'lodash';
import { AccountLockOutline } from 'mdi-material-ui';
import queryString from 'query-string';
import urljoin from 'url-join';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import SendEmailCommunicationCardContainer from '~/components/communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { CriticalityLevel, withSafeComponentPage } from '~/components/core/SafeComponent';
import { FallbackErrorPage } from '~/components/core/SafeComponent/FallbackErrorPage';
import SuccessMessageDialog from '~/components/core/SuccessMessageDialog';
import { useHasMultiplePermissions, useSanitizeByPermissions } from '~/components/hooks/useHasPermission';
import { LegalActionsContextProvider, useLegalActions } from '~/components/LegalActions/hooks/useLegalActions';
import { LegalActionsTab } from '~/components/LegalActions/LegalActionsTab/LegalActionsTab';
import { LegalActionsTabLabel } from '~/components/LegalActions/LegalActionsTab/LegalActionsTabLabel';
import CreateEditNoteDialog from '~/components/Notes/CreateEditNoteDialog';
import cn from '~/Utils/cn';

import { isoDateToUs, timeToLocalTime } from '../DateTimeUtils';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '../Types';
import { isUserAdjuster, isUserReadOnly } from '../UserUtils';
import {
  compose,
  isFeatureEnabled,
  isMarshmallowPolicy,
  isPolicyNotFound,
  isQoverClaim,
  isTscClaim,
  reportAxiosError,
  reportErrorInProductionOrThrow,
} from '../Utils';
import { isClaimWriteDisabled } from '../Utils/ClaimUtils';

import NewUIClaimPageBar from './ClaimPage/ClaimPageBar';
import mixpanel from './CmsMain/mixpanel';
import CommunicationsTab from './communications/CommunicationsTab';
import DocumentPhoneCallCommunicationCardContainer, {
  NewPhoneCallCommunicationCard,
} from './communications/PhoneCallCommunicationCard';
import DocumentPhysicalMailCommunicationCardContainer from './communications/PhysicalMailCommunicationCard';
import SendSmsCommunicationCardContainer from './communications/SmsCommunicationCard/SmsCommunicationCard';
import NewVideoCommunicationCard from './communications/VideoCommunication/NewVideoCommunicationCard';
import ActionMenuButton from './core/ActionMenuButton/ActionMenuButton';
import { isPermissionsEnabled } from './core/Permissions/PermissionUtils';
import DocumentsScreen from './Documents/DocumentsScreen';
import FinancesScreen from './Finances/FinancesScreen';
import GalleryScreen from './Gallery/GalleryScreen';
import { useCms, withCmsContext } from './hooks/useCms';
import {
  EmailIcon,
  FaxIcon,
  InternalCommunicationIcon,
  MailBoxIcon,
  PhoneIcon,
  ReminderIcon,
  SmsIcon,
  VideoIcon,
} from './icons/notifications';
import { useClaimSearch } from './SystemConfiguration/Integrations/ClaimSearch/hooks/useClaimSearch';
import Osha301Screen from './WC/WCOsha';
import CardDialog from './CardDialog';
import { useClaim, withClaim } from './ClaimContainer';
import ClaimManagementTab from './ClaimManagementTab';
import ClaimOwnerWithEdit from './ClaimOwnerWithEdit';
import { ClaimQaCard, ClaimQAContainer } from './ClaimQA';
import ClaimSummary from './ClaimSummary';
import ClaimSummaryPhoneRep from './ClaimSummaryPhoneRep';
import CloseClaimContainer from './CloseClaimContainer';
import { ContactEntity } from './Contact';
import ContactsTab from './ContactsTab';
import {
  PERMISSION_ACTIONS,
  PERMISSION_VERBS,
  PermissionsButtonWrapper,
  PermissionsRenderWrapper,
  RestrictedPermissions,
  Text,
} from './core';
import DocumentIncomingPhoneCallContainer from './DocumentIncomingPhoneCallContainer';
import { ExportClaimDialog } from './ExportClaimContainer';
import HoverActionField from './HoverActionField';
import HoverChangeField from './HoverChangeField';
import {
  ClipboardIcon,
  CommunicationIcon,
  ContactsIcon,
  DocumentIcon,
  DocumentPhoneIcon,
  FinancesIcon,
  FormIcon,
  GalleryIcon,
  GavelIcon,
  ManagementIcon,
  NoteIcon,
  PolicyIcon,
  UserLockIcon,
} from './icons';
import { AddInternalCommunicationDialog } from './InternalCommunication';
import { LimitAccessDialog } from './LimitClaimAccessContainer';
import useOrganization, { withOrganization } from './OrganizationContext';
import PolicyScreen from './PolicyScreen';
import { AddReminderDialog } from './ReminderNotificationContainer';
import { DatePickerTextFieldFormik, TimePickerTextFieldFormik } from './TextFieldFormik';

import styles, { useStyles } from '../assets/styles';
import colors from '../assets/colors.module.scss';

// TODO - Handle phone rep/read only

class ClaimPage extends Component {
  state = {
    tabValue: false,
  };

  updatePageTitle = () => {
    const { claim, setPageTitle, classes, isLoading } = this.props;
    setPageTitle(
      <ClaimTitle claim={claim} classes={classes} isLoading={isLoading} />,
      `Claims / ${claim.claim_id_display} / — Five Sigma CMS`
    );
  };

  componentDidUpdate(prevProps) {
    const { claim } = this.props;

    if (
      prevProps.claim.is_closed !== claim.is_closed ||
      prevProps.claim.close_note !== claim.close_note ||
      prevProps.claim.close_reason_display_name !== claim.close_reason_display_name ||
      prevProps.claim.subtype !== claim.subtype ||
      getMedicareEligibleStatus(prevProps.claim) !== getMedicareEligibleStatus(claim)
    ) {
      this.updatePageTitle();
    }
  }

  render() {
    return (
      <LegalActionsContextProvider>
        <ClaimPageFunctionalInner {...this.props} updatePageTitle={this.updatePageTitle} />
      </LegalActionsContextProvider>
    );
  }
}

ClaimPage.propTypes = {
  classes: PropTypes.object.isRequired, // from styles context
  claim: PropTypes.object.isRequired, // from claim context
  onClaimUpdate: PropTypes.func.isRequired, // from claim context
  onAsyncClaimUpdate: PropTypes.func.isRequired, // from claim context
  user: PropTypes.object.isRequired, // from cms context
  setPageTitle: PropTypes.func.isRequired, // from cms context
  match: PropTypes.object.isRequired, // from routes context
  location: PropTypes.object.isRequired, // from routes context
  userOrganization: PropTypes.object.isRequired, // from routes context
  isLoading: PropTypes.bool.isRequired, // from organization context
};

const ClaimPageFunctionalInner = ({ classes, claim, match, location, user, userOrganization, updatePageTitle }) => {
  const newClaimPageBarEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);
  const [qaInProgress, setQaInProgress] = useState(null);

  React.useEffect(() => {
    const searchParams = queryString.parse(location.search);
    if (searchParams.qa) {
      setQaInProgress({ exposureId: parseInt(searchParams.qa), shouldCloseWindow: true });
    }

    updatePageTitle();
  }, [location, updatePageTitle]);

  const { legalActions, reloadLegalActions, hasLegalActionsPermissions } = useLegalActions();

  let tabs = [
    {
      label: 'Summary',
      url: 'summary',
      component: ClaimSummary,
      IconComponent: ClipboardIcon,
      shouldDisplay: true,
      preLoad: true,
      persist: true,
      shouldReloadLegalActions: true,
    },
    {
      label: `Management (${claim.exposures.length})`,
      url: 'management',
      component: ClaimManagementTab,
      IconComponent: ManagementIcon,
      shouldDisplay: true,
      preLoad: true,
      persist: true,
      shouldReloadLegalActions: true,
    },
    {
      label: 'Legal Actions',
      LabelComponent: LegalActionsTabLabel,
      url: 'legal_actions',
      component: LegalActionsTab,
      IconComponent: GavelIcon,
      shouldDisplay: hasLegalActionsPermissions,
      labelComponentProps: { legalActions },
      componentProps: { onNewLegalAction: reloadLegalActions },
      shouldReloadLegalActions: true,
    },
    {
      label: 'Finances',
      url: 'finances',
      component: FinancesScreen,
      IconComponent: FinancesIcon,
      shouldDisplay: true,
      persist: true,
    },
    {
      label: 'Communications',
      url: 'communications',
      component: CommunicationsTab,
      IconComponent: CommunicationIcon,
      shouldDisplay: true,
      preLoad: true,
      persist: true,
    },
    { label: 'Contacts', url: 'contacts', component: ContactsTab, IconComponent: ContactsIcon, shouldDisplay: true },
    {
      label: 'Documents',
      url: 'documents',
      component: DocumentsScreen,
      IconComponent: DocumentIcon,
      shouldDisplay: true,
    },
    { label: 'Gallery', url: 'gallery', component: GalleryScreen, IconComponent: GalleryIcon, shouldDisplay: true },
    {
      label: 'Policy',
      url: 'policy',
      component: PolicyScreen,
      IconComponent: PolicyIcon,
      shouldDisplay: true,
      persist: true,
    },
    {
      label: 'OSHA form',
      url: 'osha',
      component: Osha301Screen,
      IconComponent: FormIcon,
      shouldDisplay:
        claim.type === 'wc_claim' && isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.OSHA_REPORTS),
    },
  ];
  const shouldPersistClaimTabs = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.PERFORMANCE_PERSIST_CLAIM_TABS
  );

  tabs = tabs.filter((tab) => tab.shouldDisplay);

  if (isPermissionsEnabled(userOrganization) || isUserAdjuster(user) || isUserReadOnly(user)) {
    const claimInternalPath = tabs.map((tab) => urljoin(match.path, tab.url));
    return (
      <RestrictedPermissions>
        {newClaimPageBarEnabled ? (
          <NewUIClaimPageBar />
        ) : (
          <ClaimPageBar onRequestExposureQa={(exposureId) => setQaInProgress({ qaInProgress: { exposureId } })} />
        )}
        <div style={{ flexGrow: 1 }}>
          <Grid container wrap="nowrap" style={{ height: '100%' }}>
            <Grid item>
              <div className={classes.claimMenu}>
                {tabs.map(
                  (
                    {
                      label,
                      IconComponent,
                      url,
                      permissions,
                      LabelComponent,
                      labelComponentProps,
                      shouldReloadLegalActions,
                    },
                    idx
                  ) => {
                    const isActive = location.pathname.startsWith(urljoin(match.url, url));

                    const tabComponent = (
                      <div key={idx} className={classes.claimMenuButtonContainer}>
                        <div className={classes.displayUpMd}>
                          <Button
                            className={cn(classes.claimMenuButton, { [classes.claimMenuButtonSelected]: isActive })}
                            component={Link}
                            to={urljoin(match.url, url)}
                            disableRipple
                            classes={{
                              label: cn(classes.claimMenuButtonLabel, {
                                [classes.claimMenuButtonLabelSelected]: isActive,
                              }),
                            }}
                            startIcon={<IconComponent className={cn({ [classes.claimMenuIconSelected]: isActive })} />}
                            onClick={async () => {
                              if (shouldReloadLegalActions) {
                                await reloadLegalActions();
                              }
                            }}
                          >
                            {LabelComponent ? <LabelComponent {...labelComponentProps} /> : label}
                          </Button>
                        </div>

                        <div className={classes.displayDownLg}>
                          <Tooltip title={label} placement="right" arrow>
                            <Button
                              className={cn(classes.claimMenuButton, { [classes.claimMenuButtonSelected]: isActive })}
                              component={Link}
                              from={`${match.path}/`}
                              to={urljoin(match.url, url)}
                              disableRipple
                              classes={{
                                label: cn(classes.claimMenuButtonLabel, {
                                  [classes.claimMenuButtonLabelSelected]: isActive,
                                }),
                              }}
                              startIcon={
                                <IconComponent className={cn({ [classes.claimMenuIconSelected]: isActive })} />
                              }
                              onClick={async () => {
                                mixpanel?.track(MIXPANEL_EVENTS.CLAIM_PANEL_CLICK, {
                                  element: label,
                                  claim_id: claim?.id,
                                });
                                if (shouldReloadLegalActions) {
                                  await reloadLegalActions();
                                }
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    );

                    return permissions ? (
                      <PermissionsRenderWrapper key={idx} verb={permissions.verb} action={permissions.action}>
                        {tabComponent}
                      </PermissionsRenderWrapper>
                    ) : (
                      tabComponent
                    );
                  }
                )}
              </div>
            </Grid>

            <Grid item container direction="column" style={{ height: '100%', position: 'relative' }}>
              <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, overflow: 'auto' }}>
                <Switch>
                  <Redirect exact from={`${match.path}/`} to={urljoin(match.path, tabs[0].url)} />
                  {shouldPersistClaimTabs ? (
                    <Route
                      key="main"
                      path={claimInternalPath}
                      render={(routerProps) => {
                        const activeTab = tabs.find(({ url }) => location.pathname.startsWith(urljoin(match.url, url)));
                        if (activeTab) {
                          // We cant call setPageTitleHere - and we don't have to
                          document.title = `Claims / ${claim.claim_id_display} / ${activeTab.label} — Five Sigma CMS`;
                        }
                        return (
                          <TabsWrapper routerProps={routerProps} claim={claim} tabs={tabs} activeTab={activeTab} />
                        );
                      }}
                    />
                  ) : (
                    tabs.map((tab) => (
                      <Route
                        key={tab.url}
                        path={urljoin(match.path, tab.url)}
                        render={(routerProps) => {
                          // We cant call setPageTitleHere - and we don't have to
                          document.title = `Claims / ${claim.claim_id_display} / ${tab.label} — Five Sigma CMS`;
                          return <tab.component classes={classes} {...routerProps} {...(tab.componentProps ?? {})} />;
                        }}
                      />
                    ))
                  )}

                  <Redirect to={match.path} />
                </Switch>
              </div>
            </Grid>
          </Grid>
        </div>
        {qaInProgress &&
        qaInProgress.exposureId &&
        claim?.exposures?.find((exposure) => exposure.id === qaInProgress.exposureId) ? (
          <ClaimQaCard
            claim={claim}
            exposure={claim.exposures.find((exposure) => exposure.id === qaInProgress.exposureId)}
            onSubmit={() => {
              setQaInProgress({ qaInProgress: undefined });
              if (qaInProgress.shouldCloseWindow) {
                window.close();
              }
            }}
            onClose={() => setQaInProgress({ qaInProgress: undefined })}
          />
        ) : null}
      </RestrictedPermissions>
    );
  } else {
    return <PhoneRepClaimPage claim={claim} classes={classes} />;
  }
};

ClaimPageFunctionalInner.propTypes = {
  ...ClaimPage.propTypes,
  updatePageTitle: PropTypes.func.isRequired,
};

function ClaimPageBar(props) {
  const { onRequestExposureQa } = props;
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();

  return (
    <div className={classes.claimPageBar}>
      <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
        <Grid item>
          <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap" spacing={2}>
            {claim.policy.insured_contact_id && ( // For self-insured organizations there is noe insured contact
              <Grid item>
                <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <strong>{isQoverClaim(claim) ? 'Policy Holder' : 'Named Insured:'}</strong>
                  <ContactEntity
                    classes={classes}
                    inline
                    contactId={claim.policy.insured_contact_id}
                    contactDisplayName={claim.policy.insured_contact_full_name}
                  />
                </div>
              </Grid>
            )}
            {!isQoverClaim(claim) && (
              <Grid item>
                <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <strong>Preferred Contact: </strong>
                  <PreferredContactContainer claim={claim} onClaimUpdate={onClaimUpdate} />
                </div>
              </Grid>
            )}

            <Grid item>
              <div style={{ padding: 4, display: 'inline-flex', alignItems: 'center' }}>
                <strong>
                  {(['wc_claim', 'gl_claim', 'pet_claim', 'travel_claim'].includes(claim.type) && 'DOI:') ||
                    (isQoverClaim(claim) && 'DOL:') ||
                    'TOL'}
                </strong>
                &nbsp;
                <span>
                  <TimeOfLossContainer claim={claim} onClaimUpdate={onClaimUpdate} />
                </span>
              </div>
            </Grid>

            {!isPolicyNotFound(claim) && (
              <Grid item>
                <span>
                  <strong>Policy #: </strong>
                  <span style={{ whiteSpace: 'nowrap' }}>{claim.policy.policy_number}</span>
                  {claim.policy.is_manually_filled ? ' (Manually filled)' : ''}
                </span>
              </Grid>
            )}
            {!isPolicyNotFound(claim) && isMarshmallowPolicy(claim.policy) && (
              <Grid item>
                <span>
                  <strong>Package: </strong>
                  <span style={{ whiteSpace: 'nowrap' }}>{claim.policy.policy_type}</span>
                </span>
              </Grid>
            )}

            {user.is_org_level_supervisor &&
              isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.QA_DASHBOARD) && (
                <Grid item>
                  <ClaimQAContainer onRequestExposureQa={onRequestExposureQa} />
                </Grid>
              )}

            {isQoverClaim(claim) && (
              <>
                <Grid item>
                  <span>
                    <strong>Partner: </strong>
                    <span style={{ whiteSpace: 'nowrap' }}>{claim.policy.partner}</span>
                  </span>
                </Grid>

                <Grid item>
                  <span>
                    <strong>Product: </strong>
                    <span style={{ whiteSpace: 'nowrap' }}>{claim.policy.product}</span>
                  </span>
                </Grid>
              </>
            )}

            {!isTscClaim(claim) && (
              <Grid item>
                <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <strong>File Owner:</strong> &nbsp;
                  <span>
                    <ClaimOwnerWithEdit classes={classes} claim={claim} />
                  </span>
                </div>
              </Grid>
            )}
            {userOrganization.sub_organizations_enabled && claim.policy.sub_organization && (
              <Grid item>
                <span>
                  <strong>Sub Org:</strong> &nbsp;
                  <span>{claim.policy.sub_organization.name}</span>
                </span>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          wrap="nowrap"
          spacing={2}
          style={{ flexBasis: 0 }}
        >
          <Grid item>
            <ClaimActionButtonContainer />
          </Grid>
          <Grid item>
            <CloseClaimContainer />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

ClaimPageBar.propTypes = {
  onRequestExposureQa: PropTypes.func.isRequired,
};

const TabsWrapper = ({ routerProps, activeTab, tabs }) => {
  const { claim } = useClaim(null);
  const classes = useStyles();
  const [displayedUrls, setDisplayedUrls] = useState([]);

  useEffect(() => {
    setDisplayedUrls([]);
  }, [claim]);

  tabs.forEach((tab) => {
    const isActive = tab === activeTab;
    const shouldLoadTab = isActive || tab.preLoad;
    if (shouldLoadTab && !displayedUrls.includes(tab.url) && tab.persist) {
      setDisplayedUrls((prevState) => [...prevState, tab.url]);
    }
  });

  return (
    <>
      {tabs.map((tab) => {
        const isActive = tab === activeTab;
        if (isActive || displayedUrls.includes(tab.url)) {
          return (
            <span
              key={tab.url}
              className={cn({
                block: isActive,
                hidden: !isActive,
              })}
            >
              <tab.component
                classes={classes}
                {...routerProps}
                {...(tab.componentProps ?? {})}
                isTabActive={isActive}
              />
            </span>
          );
        }
        return null;
      })}
    </>
  );
};

const TabType = PropTypes.shape({
  url: PropTypes.string,
  componentProps: PropTypes.object,
  component: PropTypes.element,
});

TabsWrapper.propTypes = {
  routerProps: PropTypes.object,
  activeTab: TabType,
  tabs: PropTypes.arrayOf(TabType),
};

function ClaimActionButtonContainer() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { claim, onAsyncClaimUpdate } = useClaim(null);
  const { user, userOrganization } = useCms();
  const { outgoingPhysicalMailMethod, isClaimSearchEnabled, isDyteVideoEnabled } = useOrganization();
  const [actionDialogDetails, setActionDialogDetails] = useState(null);
  const dialogIdToHandleCloseRef = useRef({});
  const { validateIsReporting } = useClaimSearch();
  const { add } = useMinimizedDialogs();

  // TODO should be integrated better with the components as their dialog should no be in the popup

  const isMinimizedDialogsEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MINIMIZED_DIALOG_FEATURE
  );

  const updateClaimAndCloseActionDialog = async ({ minimizedDialogId } = {}) => {
    await onAsyncClaimUpdate();
    if (minimizedDialogId && isMinimizedDialogsEnabled) {
      await handleMinimizedDialogClose(minimizedDialogId);
    } else {
      setActionDialogDetails(null);
    }
  };

  const handleMinimizedDialogClose = (id) => {
    const handleClose = dialogIdToHandleCloseRef.current[id];
    if (handleClose) {
      handleClose();
      delete dialogIdToHandleCloseRef.current[id];
    }
  };

  const actions = [
    {
      label: 'Create Note',
      shouldDisplay: true,
      IconComponent: NoteIcon,
      DialogComponent: CreateEditNoteDialog,
      dialogComponentProps: {
        claimId: claim.id,
        onClose: () => setActionDialogDetails(false),
        onSubmitNote: updateClaimAndCloseActionDialog,
      },
      permissions: { action: PERMISSION_ACTIONS.NOTE, verb: PERMISSION_VERBS.WRITE },
      extendMinimizedDialogProps: isMinimizedDialogsEnabled
        ? (minimizedDialogId) => ({
            barHeader: 'New Note',
            type: 'NOTE',
            dialogProps: {
              onClose: () => handleMinimizedDialogClose(minimizedDialogId),
              onSubmitNote: async () => await updateClaimAndCloseActionDialog({ minimizedDialogId }),
            },
          })
        : null,
    },
    {
      label: 'Create Reminder',
      shouldDisplay: true,
      IconComponent: ReminderIcon,
      DialogComponent: AddReminderDialog,
      dialogComponentProps: {
        open: true,
        onCancel: () => setActionDialogDetails(false),
        onSubmit: updateClaimAndCloseActionDialog,
      },
      permissions: { action: PERMISSION_ACTIONS.NOTIFICATION, verb: PERMISSION_VERBS.WRITE },
      extendMinimizedDialogProps: isMinimizedDialogsEnabled
        ? (minimizedDialogId) => ({
            barHeader: 'Create Reminder',
            type: 'REMINDER',
            dialogProps: {
              onCancel: () => handleMinimizedDialogClose(minimizedDialogId),
              onSubmit: async () => await updateClaimAndCloseActionDialog({ minimizedDialogId }),
            },
          })
        : null,
    },
    {
      label: 'Make Phone Call',
      shouldDisplay: !!user.is_call_center_allowed,
      IconComponent: PhoneIcon,
      DialogComponent: NewPhoneCallCommunicationCard,
      dialogComponentProps: {
        onCancel: () => setActionDialogDetails(false),
        onNewPhoneCallCreated: updateClaimAndCloseActionDialog,
      },
      mixpanelProps: {
        source: MIXPANEL_EVENT_SOURCES.CLAIM_ACTIONS_NEW_PHONE_CALL,
        mixpanel_event: MIXPANEL_EVENTS.NEW_PHONE_CALL_COMMUNICATION_CLICKED,
      },
    },
    {
      label: 'Make Video Call',
      shouldDisplay: isDyteVideoEnabled,
      IconComponent: VideoIcon,
      DialogComponent: NewVideoCommunicationCard,
      dialogComponentProps: {
        claim,
        onCancel: () => setActionDialogDetails(false),
        onNewVideoCallCreated: updateClaimAndCloseActionDialog,
      },
    },
    {
      label: 'Document Phone Call',
      shouldDisplay: true,
      IconComponent: DocumentPhoneIcon,
      DialogComponent: DocumentPhoneCallCommunicationCardContainer,
      dialogComponentProps: {
        claim,
        onCancel: () => setActionDialogDetails(false),
        onDocumentPhoneCall: updateClaimAndCloseActionDialog,
      },
      extendMinimizedDialogProps: isMinimizedDialogsEnabled
        ? (minimizedDialogId) => ({
            barHeader: 'Document Phone Call',
            type: 'PHONE',
            dialogProps: {
              onCancel: () => handleMinimizedDialogClose(minimizedDialogId),
              onDocumentPhoneCall: async () => await updateClaimAndCloseActionDialog({ minimizedDialogId }),
            },
          })
        : null,
      mixpanelProps: {
        source: MIXPANEL_EVENT_SOURCES.CLAIM_ACTIONS_DOCUMENT_NEW_PHONE_CALL,
        mixpanel_event: MIXPANEL_EVENTS.DOCUMENT_NEW_PHONE_CALL_COMMUNICATION_CLICKED,
      },
    },
    {
      label: 'Send Internal Communication',
      shouldDisplay: true,
      IconComponent: CommunicationIcon,
      DialogComponent: AddInternalCommunicationDialog,
      dialogComponentProps: {
        open: true,
        claim,
        icObject: { type: 'claim', claim },
        onCancel: () => setActionDialogDetails(false),
        onSubmit: updateClaimAndCloseActionDialog,
      },
      extendMinimizedDialogProps: isMinimizedDialogsEnabled
        ? (minimizedDialogId) => ({
            barHeader: 'Send Internal Communication',
            type: 'INTERNAL_COMMUNICATION',
            dialogProps: {
              onCancel: () => handleMinimizedDialogClose(minimizedDialogId),
              onSubmit: async () => await updateClaimAndCloseActionDialog({ minimizedDialogId }),
            },
          })
        : null,
      permissions: { action: PERMISSION_ACTIONS.NOTIFICATION, verb: PERMISSION_VERBS.WRITE },
    },
    {
      label: 'Send Text Message',
      shouldDisplay: !!user.is_sending_sms_allowed,
      IconComponent: SmsIcon,
      DialogComponent: SendSmsCommunicationCardContainer,
      dialogComponentProps: {
        onCancel: () => setActionDialogDetails(false),
        onSendSms: updateClaimAndCloseActionDialog,
      },
      mixpanelProps: {
        source: MIXPANEL_EVENT_SOURCES.CLAIM_ACTIONS_NEW_SMS,
        mixpanel_event: MIXPANEL_EVENTS.NEW_SMS_COMMUNICATION_CLICKED,
      },
      extendMinimizedDialogProps: isMinimizedDialogsEnabled
        ? (minimizedDialogId) => ({
            barHeader: 'New SMS',
            type: 'SMS',
            dialogProps: {
              onCancel: () => handleMinimizedDialogClose(minimizedDialogId),
              onSendSms: async () => await updateClaimAndCloseActionDialog({ minimizedDialogId }),
            },
          })
        : null,
    },
    {
      label: 'Send Email Message',
      shouldDisplay: true,
      IconComponent: EmailIcon,
      DialogComponent: SendEmailCommunicationCardContainer,
      dialogComponentProps: {
        onClose: () => setActionDialogDetails(false),
        onSendEmail: updateClaimAndCloseActionDialog,
      },
      extendMinimizedDialogProps: isMinimizedDialogsEnabled
        ? (minimizedDialogId) => ({
            barHeader: 'New Email',
            type: 'EMAIL',
            dialogProps: {
              onClose: () => handleMinimizedDialogClose(minimizedDialogId),
              onSendEmail: async () => await updateClaimAndCloseActionDialog({ minimizedDialogId }),
            },
          })
        : null,
      mixpanelProps: {
        source: MIXPANEL_EVENT_SOURCES.CLAIM_ACTIONS_NEW_EMAIL,
        mixpanel_event: MIXPANEL_EVENTS.NEW_EMAIL_COMMUNICATION_CLICKED,
      },
    },

    {
      label: `${outgoingPhysicalMailMethod ? 'Send' : 'Document'} Physical Mail`,
      shouldDisplay: true,
      IconComponent: MailBoxIcon,
      DialogComponent: DocumentPhysicalMailCommunicationCardContainer,
      dialogComponentProps: {
        claim,
        onCancel: () => setActionDialogDetails(false),
        onDocumentPhysicalMail: updateClaimAndCloseActionDialog,
      },
      mixpanelProps: outgoingPhysicalMailMethod
        ? {
            source: MIXPANEL_EVENT_SOURCES.CLAIM_ACTIONS_NEW_PHYSICAL_MAIL,
            mixpanel_event: MIXPANEL_EVENTS.NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED,
          }
        : undefined,
    },
    {
      label: 'Export/Print Claim',
      shouldDisplay: true,
      IconComponent: FaxIcon,
      DialogComponent: ExportClaimDialog,
      dialogComponentProps: {
        onClose: () => setActionDialogDetails(false),
      },
      isAllowedForReadOnly: true,
      permissions: { action: PERMISSION_ACTIONS.EXPORT_CLAIM, verb: PERMISSION_VERBS.WRITE },
    },
    {
      label: claim.is_access_limited ? 'Enable External Users' : 'Lock External Users',
      shouldDisplay: true,
      IconComponent: UserLockIcon,
      DialogComponent: LimitAccessDialog,
      dialogComponentProps: {
        claim,
        onClose: () => setActionDialogDetails(false),
        onUpdate: updateClaimAndCloseActionDialog,
      },
    },
    {
      label: 'Report to ClaimSearch',
      shouldDisplay:
        isClaimSearchEnabled && validateIsReporting(userOrganization.id, claim.policy.sub_organization?.id),
      IconComponent: InternalCommunicationIcon,
      iconColor: colors.buttonSelected,
      iconSize: 20,
      DialogComponent: ClaimSearchDialog,
      dialogComponentProps: {
        claimIdDisplay: claim.claim_id_display,
        onClose: () => setActionDialogDetails(false),
        subOrganizationId:
          userOrganization.sub_organizations_enabled && claim.policy.sub_organization
            ? claim.policy.sub_organization.id
            : null,
      },
    },
  ];

  const handleDialogComponentClick = (DialogComponent, dialogComponentProps, mixpanelProps) => {
    setActionDialogDetails({ DialogComponent, dialogComponentProps });
    setAnchorEl(null);
    if (mixpanelProps) {
      mixpanel.track(mixpanelProps.mixpanel_event, { source: mixpanelProps.source });
    }
  };

  const handleMinimizedDialogComponentClick = ({
    DialogComponent,
    dialogComponentProps,
    extendMinimizedDialogProps,
    mixpanelProps,
  }) => {
    const id = uuidv4();

    const minimizedDialogProps = extendMinimizedDialogProps(id);
    const { handleCloseDialog } = add(
      merge(
        {
          dialogProps: {
            onMinimized: () => {
              mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
                source: mixpanelProps?.source,
                type: minimizedDialogProps.type,
              });
            },
          },
        },
        {
          dialogComponent: DialogComponent,
          dialogProps: dialogComponentProps,
        },
        minimizedDialogProps
      )
    );
    dialogIdToHandleCloseRef.current[id] = handleCloseDialog;

    setAnchorEl(null);
    if (mixpanelProps) {
      mixpanel.track(mixpanelProps.mixpanel_event, { source: mixpanelProps.source });
    }
  };

  return (
    <>
      <Button
        className={`${classes.actionButton} ${classes.button}`}
        variant="contained"
        color="primary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<ExpandMoreIcon />}
      >
        Actions
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        placement="bottom-end"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ width: 290, padding: '15px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {actions
            .filter((action) => action.shouldDisplay)
            .map(
              (
                {
                  label,
                  IconComponent,
                  DialogComponent,
                  dialogComponentProps,
                  mixpanelProps,
                  isAllowedForReadOnly = false,
                  permissions,
                  extendMinimizedDialogProps,
                },
                idx
              ) => (
                <Fragment key={idx}>
                  <PermissionsButtonWrapper {...permissions}>
                    <ActionMenuButton
                      label={label}
                      onClick={() => {
                        extendMinimizedDialogProps
                          ? handleMinimizedDialogComponentClick({
                              DialogComponent,
                              dialogComponentProps,
                              extendMinimizedDialogProps,
                              mixpanelProps,
                            })
                          : handleDialogComponentClick(DialogComponent, dialogComponentProps, mixpanelProps);
                      }}
                      IconComponent={IconComponent}
                      disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true, isAllowedForReadOnly })}
                    />
                  </PermissionsButtonWrapper>
                </Fragment>
              )
            )}
        </div>
      </Popover>
      {actionDialogDetails && <actionDialogDetails.DialogComponent {...actionDialogDetails.dialogComponentProps} />}
    </>
  );
}

function TimeOfLossContainer(props) {
  const { claim, onClaimUpdate } = props;
  const { user } = useCms();

  const [openDateDialog, setOpenDateDialog] = useState(false);
  const classes = useStyles();

  const lossString = ['wc_claim', 'gl_claim', 'pet_claim', 'travel_claim'].includes(claim.type) ? 'Incident' : 'Loss';

  const userPermissions = useHasMultiplePermissions({
    actions: [PERMISSION_ACTIONS.DATE_OF_LOSS, PERMISSION_ACTIONS.TIME_OF_LOSS],
    verb: PERMISSION_VERBS.WRITE,
  });
  const hasAnyOfPermissions = Object.values(userPermissions).some((hasPermission) => hasPermission);

  const sanitizePayload = useSanitizeByPermissions({
    fieldsPermissionActions: {
      date_of_loss: PERMISSION_ACTIONS.DATE_OF_LOSS,
      time_of_loss: PERMISSION_ACTIONS.TIME_OF_LOSS,
    },
    verb: PERMISSION_VERBS.WRITE,
  });

  const getUpdateRouteSuffix = () => {
    if (userPermissions[PERMISSION_ACTIONS.DATE_OF_LOSS] && userPermissions[PERMISSION_ACTIONS.TIME_OF_LOSS]) {
      return 'datetime_of_loss';
    } else if (userPermissions[PERMISSION_ACTIONS.DATE_OF_LOSS]) {
      return 'date_of_loss';
    } else if (userPermissions[PERMISSION_ACTIONS.TIME_OF_LOSS]) {
      return 'time_of_loss';
    }
    reportErrorInProductionOrThrow('No permissions to update date or time of loss, but update function was called');
  };

  return (
    <>
      <HoverActionField
        disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
        onAction={() => setOpenDateDialog(true)}
      >
        {isoDateToUs(claim.incident.date_of_loss)}
        {claim.incident.time_of_loss ? ' ' + timeToLocalTime(claim.incident.time_of_loss) : ''}
      </HoverActionField>
      {openDateDialog && (
        <Formik
          initialValues={{ date_of_loss: claim.incident.date_of_loss, time_of_loss: claim.incident.time_of_loss }}
          validationSchema={Yup.object().shape({
            date_of_loss: claim?.reported_date
              ? Yup.date()
                  .max(new Date())
                  .max(claim?.reported_date, 'Must be before the Date of Report')
                  .required('Required')
              : Yup.date().max(new Date()).required('Required'),
            time_of_loss: Yup.string()
              .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
              .nullable(),
          })}
          enableReinitialize
          onSubmit={async (values, formikProps) => {
            try {
              const payload = sanitizePayload(values);
              const routeSuffix = getUpdateRouteSuffix();
              await axios.put(`/api/v1/claims/${claim.id}/${routeSuffix}`, payload);
              await onClaimUpdate();
              setOpenDateDialog(false);
              formikProps.setSubmitting(false);
            } catch (error) {
              await reportAxiosError(error);
              formikProps.setSubmitting(false);
            }
          }}
        >
          {(formikProps) => (
            <CardDialog
              isDialog={true}
              open={openDateDialog}
              title={`Edit Time of ${lossString}`}
              maxWidth="sm"
              onClose={() => setOpenDateDialog(false)}
              preventClose={formikProps.isSubmitting}
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <RestrictedPermissions action={PERMISSION_ACTIONS.DATE_OF_LOSS} verb={PERMISSION_VERBS.WRITE}>
                    <PermissionsButtonWrapper>
                      <DatePickerTextFieldFormik
                        id="date_of_loss"
                        label={`Date of ${lossString}`}
                        disableFuture
                        className={classes.textField}
                        fullWidth
                      />
                    </PermissionsButtonWrapper>
                  </RestrictedPermissions>
                </Grid>
                <Grid item xs={6}>
                  <RestrictedPermissions action={PERMISSION_ACTIONS.TIME_OF_LOSS} verb={PERMISSION_VERBS.WRITE}>
                    <PermissionsButtonWrapper>
                      <TimePickerTextFieldFormik
                        id="time_of_loss"
                        label={`Time of ${lossString}`}
                        fullWidth
                        className={classes.textField}
                      />
                    </PermissionsButtonWrapper>
                  </RestrictedPermissions>
                </Grid>
              </Grid>
              <div className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  disabled={formikProps.isSubmitting || !hasAnyOfPermissions}
                  color="primary"
                  onClick={() => formikProps.handleSubmit()}
                >
                  Update
                </Button>
              </div>
            </CardDialog>
          )}
        </Formik>
      )}
    </>
  );
}

TimeOfLossContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  onClaimUpdate: PropTypes.func.isRequired,
};

function PreferredContactContainer(props) {
  const { claim, onClaimUpdate } = props;
  const { user } = useCms();
  const classes = useStyles();

  let acceptedRoles = [
    'insured',
    'named_driver',
    'spouse',
    'family_member',
    'public_adjuster',
    'attorney',
    'agent',
    'other',
  ];
  if (['gl_claim', 'wc_claim'].includes(claim.type)) {
    acceptedRoles.push('claimant');
  }

  return (
    <HoverChangeField
      name="primary_contact_id"
      customFieldIdName="primary_contact"
      value={claim.primary_contact_id || ''}
      label="Preferred Contact"
      validationSchema={Yup.string().required('Required')}
      onUpdate={async ({ primary_contact_id }) => {
        const url =
          claim.type === 'general_claim' ? `/api/v1/claims/${claim.id}` : `/api/v1/${claim.type}s/${claim.id}`;
        try {
          await axios.patch(url, { primary_contact_id });
          await onClaimUpdate();
        } catch (error) {
          reportAxiosError(error);
        }
      }}
      specialFieldType="contact"
      specialFieldAdditionalProps={{ acceptedRoles }}
      showOnly
      disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
    >
      {claim.primary_contact_id && (
        <ContactEntity
          classes={classes}
          contactId={claim.primary_contact_id}
          contactDisplayName={claim.primary_contact_full_name}
          inline
        />
      )}
    </HoverChangeField>
  );
}

PreferredContactContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  onClaimUpdate: PropTypes.func.isRequired,
};

function PhoneRepClaimPage(props) {
  const { claim, classes } = props;

  return (
    <>
      <div className={classes.claimPageBar}>
        <Table style={{ height: '100%', backgroundColor: '#F8F8F8' }}>
          <TableBody>
            <TableRow className={classes.claimPageBarTableRow}>
              <TableCell className={classes.tableCell}>
                <strong>Claim #:</strong>
                {claim.claim_id_display}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <span>
                  <strong>Named Insured: </strong>
                  {claim.policy.insured_contact_full_name}
                </span>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <strong>TOL:</strong>
                {isoDateToUs(claim.incident.date_of_loss)}
                {claim.incident.time_of_loss ? ' ' + timeToLocalTime(claim.incident.time_of_loss) : ''}
              </TableCell>
              {isPolicyNotFound(claim) ? (
                <TableCell className={classes.tableCell}>
                  <span style={{ color: 'red' }}>
                    <strong>{claim.close_reason_display_name || claim.close_note || 'Policy Not Found'}</strong>
                  </span>
                </TableCell>
              ) : (
                <TableCell className={classes.tableCell}>
                  <span>
                    <strong>Policy #:</strong>
                    &nbsp;
                    <span style={{ whiteSpace: 'nowrap' }}>{claim.policy.policy_number}</span>
                    {claim.policy.is_manually_filled ? ' (Manually filled)' : ''}
                  </span>
                </TableCell>
              )}
              <TableCell className={classes.tableCell}>
                <DocumentIncomingPhoneCallContainer />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <span>
                  <strong>File Owner: </strong>
                  {claim.handling_adjuster}
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={classes.claimPageBodyNoTabs}>
        <ClaimSummaryPhoneRep claim={claim} classes={classes} />
      </div>
    </>
  );
}

PhoneRepClaimPage.propTypes = {
  claim: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

function ClaimTitle({ claim, classes, isLoading }) {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {claim.is_access_limited && (
        <AccountLockOutline color="secondary" style={{ marginRight: 5, fontSize: '1.5em' }} />
      )}
      {claim.claim_id_display}
      {((claim.is_closed && claim.close_note) || isPolicyNotFound(claim)) && (
        <Typography display="inline" variant="body1" color="secondary" style={{ paddingLeft: 6 }}>
          <strong>{claim.close_reason_display_name || claim.close_note || 'Policy Was Not Found'}</strong>
        </Typography>
      )}
      {!isLoading && claim.type === 'gl_claim' && <GLStatusTimeline claim={claim} classes={classes} />}
      {(claim.type === 'gl_claim' || claim.type === 'wc_claim') && (
        <MedicareEligibleChip claim={claim} classes={classes} />
      )}
    </span>
  );
}

ClaimTitle.propTypes = {
  claim: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function GLStatusTimeline({ claim, classes }) {
  const { incidentTypesDict } = useOrganization();

  return (
    <>
      {claim.prev_subtype && (
        <>
          <Chip size="small" label={incidentTypesDict.gl_claim[claim.prev_subtype].desc} className={classes.chip} />→
        </>
      )}
      <Chip
        size="small"
        label={incidentTypesDict.gl_claim[claim.subtype].desc}
        color="primary"
        className={classes.chip}
      />
    </>
  );
}

GLStatusTimeline.propTypes = {
  claim: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const getMedicareEligibleStatus = (claim) => {
  if (claim.type !== 'gl_claim' && claim.type !== 'wc_claim') {
    return null;
  }
  const claimantParty = claim.type === 'gl_claim' ? claim.claimant_party : claim.employee_party;
  return claimantParty.involved_person.is_medicare_eligible;
};

function MedicareEligibleChip({ claim, classes }) {
  const medicareEligibleStatus = getMedicareEligibleStatus(claim);
  if (medicareEligibleStatus) {
    return (
      <Chip
        size="small"
        label="Medicare Eligible"
        className={classes.chip}
        style={{ backgroundColor: '#1A9C9E', color: 'white' }}
      />
    );
  }
  return null;
}

MedicareEligibleChip.propTypes = {
  claim: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const ClaimSearchDialog = ({ onClose, claimIdDisplay, subOrganizationId }) => {
  const classes = useStyles();
  const { reportToClaimSearch } = useClaimSearch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await reportToClaimSearch(claimIdDisplay, subOrganizationId);
      setIsSuccess(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isSuccess ? (
        <SuccessMessageDialog title="Claim was sent successfully" onClose={onClose} />
      ) : (
        <CardDialog
          isDialog
          maxWidth="sm"
          fullWidth
          onClose={onClose}
          title="Report to ClaimSearch"
          preventClose={isLoading}
        >
          <Text variant={Text.VARIANTS.MD} weight={Text.WEIGHTS.REGULAR}>
            Updated claim data will be sent to ClaimSearch <br /> and a new search will be initiated
          </Text>
          <div className={classes.buttonsContainer}>
            <CancelButton onClick={onClose} disabled={isLoading} />
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
              Send
            </Button>
          </div>
        </CardDialog>
      )}
    </>
  );
};

ClaimSearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  claimIdDisplay: PropTypes.string.isRequired,
  subOrganizationId: PropTypes.number,
};

const ClaimPageWithContext = compose(
  withOrganization,
  withCmsContext,
  withClaim,
  withRouter,
  withStyles(styles)
)(ClaimPage);

const SafeClaimPage = withSafeComponentPage(ClaimPageWithContext, {
  location: 'claim_page',
  criticalityLevel: CriticalityLevel.HIGHEST,
  fallbackComponent: <FallbackErrorPage />,
});

export {
  ClaimActionButtonContainer,
  SafeClaimPage as ClaimPage,
  ClaimPageBar,
  PreferredContactContainer,
  TimeOfLossContainer,
};
