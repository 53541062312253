import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';

import { WATER_DAMAGES_SOURCES } from '../../Types';
import { reportAxiosError, reportErrorInProductionOrThrow } from '../../Utils';
import CardDialog from '../CardDialog';
import useOrganization from '../OrganizationContext';
import { usePolicy } from '../PolicyContainer';
import { TextFieldFormik } from '../TextFieldFormik';

import { BiDamagesContainer, PdDamagesContainer } from './BiPdDetailsContainer';
import CreditBureauDialog from './CreditBureau';
import DamageDialogContainer from './DamageDialogContainer';
import HomeDamagesDetailsContainer from './HomeDamagesDetailsContainer';
import MitigationDetailsContainer from './MitigationDetailsContainer';
import PoliceReportDialog from './PoliceReportDialog';

import { useStyles } from '../../assets/styles';

const isObject = (o) => typeof o === 'object';

const getDamageName = (damage) => (isObject(damage) ? damage.name : damage);

function renderDamageContainer(damage, values, setFieldValue, vendorsStatus) {
  const damageName = isObject(damage) ? damage.name : damage;
  let extraProps = {};
  if (isObject(damage)) {
    if (damage.extra_props) {
      if (damage.extra_props.helper_text) {
        extraProps = {
          ...extraProps,
          helperText: (
            <Typography display="block" variant="caption">
              {damage.extra_props.helper_text}
            </Typography>
          ),
        };
      }
    }
  }

  switch (damageName) {
    case 'someone_injured':
      return <BiDamagesContainer values={values} setFieldValue={setFieldValue} />;
    case 'property_damaged':
      return <PdDamagesContainer values={values} setFieldValue={setFieldValue} />;
    case 'total_burn':
      return (
        <DamageDialogContainer
          damageId="total_burn"
          questionText="Total Burn?"
          values={values}
          setFieldValue={setFieldValue}
          {...extraProps}
        />
      );
    case 'tornado':
      return (
        <DamageDialogContainer
          damageId="tornado"
          questionText="Tornado?"
          values={values}
          setFieldValue={setFieldValue}
          {...extraProps}
        />
      );
    case 'hurricane':
      return (
        <DamageDialogContainer
          damageId="hurricane"
          questionText="Hurricane?"
          values={values}
          setFieldValue={setFieldValue}
          {...extraProps}
        />
      );
    case 'fire_department_involved':
      return (
        <DamageDialogContainer
          damageId="fire_department_involved"
          questionText="Were the fire department involved?"
          values={values}
          setFieldValue={setFieldValue}
          {...extraProps}
        />
      );
    case 'police_report':
      return (
        <DamageDialogContainer
          damageId="police_report"
          questionText="Is there a police report?"
          values={values}
          setFieldValue={setFieldValue}
          customDialogTitle="Police report"
          CustomDetailsDialog={PoliceReportDialog}
          {...extraProps}
        />
      );
    case 'credit_bureau':
      return (
        <DamageDialogContainer
          damageId="credit_bureau"
          questionText="Have any credit bureaus been contacted?"
          values={values}
          setFieldValue={setFieldValue}
          customDialogTitle="Credit Bureau"
          CustomDetailsDialog={CreditBureauDialog}
          {...extraProps}
        />
      );
    case 'home_damage':
      if (isObject(damage) && damage.is_home_extra) {
        return <HomeDamagesDetailsContainer values={values} setFieldValue={setFieldValue} {...extraProps} />;
      }
      return (
        <DamageDialogContainer
          damageId="home_damage"
          questionText="Was the home damaged?"
          values={values}
          setFieldValue={setFieldValue}
          {...extraProps}
        />
      );
    case 'personal_property':
      return (
        <DamageDialogContainer
          damageId="personal_property"
          questionText={
            isObject(damage) && damage.alt_question_text
              ? damage.alt_question_text
              : 'Was there any personal property damaged?'
          }
          values={values}
          setFieldValue={setFieldValue}
          {...extraProps}
        />
      );
    case 'structures_damage':
      return (
        <DamageDialogContainer
          damageId="structures_damage"
          questionText="Were other (detached) structures damaged?"
          values={values}
          setFieldValue={setFieldValue}
          {...extraProps}
        />
      );
    case 'safely_livable':
      return (
        <DamageDialogContainer
          damageId="safely_livable"
          questionText="Is the home habitable?"
          values={values}
          setFieldValue={setFieldValue}
          dialogForNo
          {...extraProps}
        />
      );
    case 'mitigation':
      return (
        <MitigationDetailsContainer
          values={values}
          questionText={
            isObject(damage) && damage.alt_question_text
              ? damage.alt_question_text
              : 'Is there any immediate mitigation necessary? associated water damage, holes in the structure windows or roof, or other temporary repairs'
          }
          setFieldValue={setFieldValue}
          vendorsStatus={vendorsStatus}
          {...extraProps}
        />
      );
    case 'source_of_water':
      return (
        <>
          <Grid item xs={6}>
            <TextFieldFormik id="source_of_water" select label="Source of Water" fullWidth>
              {WATER_DAMAGES_SOURCES.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          {values.source_of_water && (
            <>
              {['Pipe', 'Appliance'].includes(values.source_of_water) && (
                <Typography display="block" variant="body1" style={{ color: '#e65100' }}>
                  <strong>NOTE:</strong>
                  <br />
                  Advise the insured to keep all parts/pieces for our review.
                </Typography>
              )}
              {['Flood', 'Sump Pump'].includes(values.source_of_water) && (
                <Typography display="block" variant="body1" style={{ color: '#e65100' }}>
                  <strong>NOTE:</strong>
                  <br />
                  Advise insured that the claim would need to be fully investigated and no action taken should be deemed
                  as coverage being extended
                </Typography>
              )}
            </>
          )}
        </>
      );

    default:
      throw Error(`Unsupported damage type ${damageName}`);
  }
}

function renderAdditionalComponent(additionalComponent) {
  if (additionalComponent.type === 'note') {
    return (
      <Typography display="block" key={additionalComponent.key} variant="body1" style={{ color: '#e65100' }}>
        <strong>NOTE</strong>
        <br />
        {additionalComponent.text}
      </Typography>
    );
  } else {
    reportErrorInProductionOrThrow(`Unknown additional component type: ${additionalComponent.type}`);
    return null;
  }
}

function renderDamageDetailsFromFnolStore(fnolStore, values, setFieldValue, vendorsStatus) {
  return (
    <>
      {fnolStore.damages.map((damage) => (
        <React.Fragment key={getDamageName(damage)}>
          {renderDamageContainer(damage, values, setFieldValue, vendorsStatus)}
        </React.Fragment>
      ))}
      {fnolStore.additional_components.map(renderAdditionalComponent)}
    </>
  );
}

const damagesDetailsFields = {
  peril: '',
  someone_injured: { is_damaged: '', damage_details: {} },
  property_damaged: { is_damaged: '', damage_details: {} },
  mitigation: { is_damaged: '', damage_details: {} },
  total_burn: { is_damaged: '' },
  tornado: { is_damaged: '' },
  hurricane: { is_damaged: '' },
  home_damage: { is_damaged: '', damage_details: {} },
  personal_property: { is_damaged: '', damage_details: {} },
  structures_damage: { is_damaged: '', damage_details: {} },
  safely_livable: { is_damaged: '', damage_details: {} },
  police_report: { is_damaged: '', damage_details: {} },
  credit_bureau: { is_damaged: '', damage_details: {} },
  fire_department_involved: { is_damaged: '', damage_details: {} },
  restoration_work: { is_damaged: '', damage_details: {} },
  source_of_water: '',
};

const getDamagesDetailsValidationFields = (fnolStore, _vendorsStatus) => {
  const specialDamageValidationFields = {
    source_of_water: Yup.string().required('Required'),
  };

  let validationFields = {
    peril: Yup.string().required('Required'),
  };

  if (fnolStore && fnolStore.damages) {
    fnolStore.damages.forEach((damage) => {
      const damageName = getDamageName(damage);
      if (isObject(damage) && damage.extraProps && damage.extraProps.disabled) {
        // disabled fields are not required
        return;
      }
      validationFields = {
        ...validationFields,
        [damageName]:
          specialDamageValidationFields[damageName] ||
          Yup.object().shape({ is_damaged: Yup.string().required('Required') }),
      };
    });
  }

  return validationFields;
};

function DamagesDetailsFormikInner(props) {
  const { fnolStore, onChangeFnolStore, values, setFieldValue, vendorsStatus } = props;
  const classes = useStyles();
  const { incidentTypesDict } = useOrganization();
  const { policy } = usePolicy();
  const perils_dict = incidentTypesDict['home_claim'];

  React.useEffect(() => {
    if (!values.peril) {
      return;
    }

    async function getNewFnolStore() {
      try {
        const res = await axios.post('/api/v1/home_claims/fnol/fnol_questions', values, {
          params: { organization_id: policy.organization_id },
        });
        onChangeFnolStore(res.data);
      } catch (error) {
        reportAxiosError(error);
      }
    }

    getNewFnolStore();
  }, [values, onChangeFnolStore, vendorsStatus, policy.organization_id]);

  return (
    <CardDialog title="Damages">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextFieldFormik id="peril" select label="Peril" className={classes.textField} fullWidth>
            {Object.keys(perils_dict).map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {perils_dict[option].desc}
                </MenuItem>
              );
            })}
          </TextFieldFormik>
        </Grid>
        <Grid item xs={6} />
        {!values.peril && (
          <Typography display="block" variant="body1">
            <br />
            Select Peril for further questions
          </Typography>
        )}
        {values.peril && renderDamageDetailsFromFnolStore(fnolStore, values, setFieldValue, vendorsStatus)}
      </Grid>
    </CardDialog>
  );
}

DamagesDetailsFormikInner.propTypes = {
  fnolStore: PropTypes.object.isRequired,
  onChangeFnolStore: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired, // from Formik
  values: PropTypes.object.isRequired, // from Formik
  vendorsStatus: PropTypes.object.isRequired,
};

export default DamagesDetailsFormikInner;
export { damagesDetailsFields, getDamagesDetailsValidationFields };
