import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RotateRightIcon from '@material-ui/icons/RotateRight';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { FsIconButton } from '~/components/core';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import VideoPlayer from '~/components/core/Atomic/VideoPlayer';
import EditDocumentContainer from '~/components/Documents/EditDocumentContainer';
import ExposureLabelChips from '~/components/ExposureLabelChips';
import { useCms } from '~/components/hooks/useCms';
import AddInternalCommunicationContainer from '~/components/InternalCommunication';
import LoadingDialog from '~/components/LoadingDialog';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import { isoDateToUs } from '~/DateTimeUtils';

import { getProcessedUrl, isDocumentAPhoto } from '../Documents/DocumentUtils';

function LargeMediaContainer(props) {
  const { media, mediaLink, onClose, isStoredFile, shouldDisableLargePhotoActions, onRotatePhoto } = props;
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { user } = useCms();
  const videoRef = useRef();
  const documentName = !isStoredFile ? media.document_name : media.file_name;
  const isPhoto = isDocumentAPhoto(media);

  const handleRotatePhoto = async () => {
    setIsLoading(true);
    await onRotatePhoto();
  };

  return (
    <>
      {isLoading && <LoadingDialog isError={false} track="Rotate Photo" />}
      <CardDialog
        title={<OverflowTextWithToolTip maxWidth="300px">{documentName}</OverflowTextWithToolTip>}
        trackAlt={documentName}
        isDialog
        onClose={onClose}
        maxWidth="lg"
        closeOnBackdropClick
        action={
          !shouldDisableLargePhotoActions && (
            <>
              {onRotatePhoto && isPhoto && (
                <Tooltip title="Rotate image to the right">
                  <FsIconButton onClick={handleRotatePhoto} disabled={user.role.is_view_only}>
                    <RotateRightIcon />
                  </FsIconButton>
                </Tooltip>
              )}
              <Tooltip title="open in full screen">
                <IconButton
                  onClick={() => {
                    videoRef.current && videoRef.current.pause();
                    window.open(getProcessedUrl(media, mediaLink), '_target');
                  }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
              {!isStoredFile && (
                <>
                  <EditDocumentContainer isIconButton document={media} />
                  <AddInternalCommunicationContainer isIconButton icObject={{ type: 'document', document: media }} />
                </>
              )}
            </>
          )
        }
      >
        {!isStoredFile && (
          <>
            <ExposureLabelChips claimObject={media} />
            <Grid container>
              <Typography
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
                className={classes.textField}
                variant="body2"
              >
                <em>{isoDateToUs(media.document_date)}</em>
              </Typography>
            </Grid>
            {media.summary && (
              <OverflowTextWithToolTip maxWidth="600px" tooltipMaxWidth="500px" tooltipPlacement="top-start">
                <Typography className={classes.textField}>{media.summary}</Typography>
              </OverflowTextWithToolTip>
            )}
          </>
        )}
        <Grid container justify="center">
          {isPhoto ? (
            <img
              src={getProcessedUrl(media, mediaLink, true)}
              alt={documentName}
              onLoad={() => setIsLoading(false)}
              style={{ marginTop: '4px', maxWidth: '70vw', maxHeight: '70vh' }}
            />
          ) : (
            <VideoPlayer
              src={getProcessedUrl(media, mediaLink)}
              ref={videoRef}
              controls
              autoPlay
              className="max-h-70vw mt-4 max-w-[70vw]"
            />
          )}
        </Grid>
      </CardDialog>
    </>
  );
}

LargeMediaContainer.propTypes = {
  media: PropTypes.object.isRequired,
  mediaLink: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isStoredFile: PropTypes.bool,
  shouldDisableLargePhotoActions: PropTypes.bool,
  onRotatePhoto: PropTypes.func,
};

export default LargeMediaContainer;
