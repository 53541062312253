import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import SumCard from './SumCard';

import styles from '../financesScreen.module.scss';

const SumsHeader = ({ amounts, shouldDisplayOnlyHeader }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <SumCard
          header="Paid to Date Claim Total"
          expensesAmount={amounts.expenses_paid_sum}
          indemnityAmount={amounts.indemnity_paid_sum}
          shouldDisplayOnlyHeader={shouldDisplayOnlyHeader}
        />
      </Grid>
      <Grid item xs={4}>
        <SumCard
          header="Reserve to Date Claim Total"
          expensesAmount={amounts.expenses_reserve}
          indemnityAmount={amounts.indemnity_reserve}
          shouldDisplayOnlyHeader={shouldDisplayOnlyHeader}
        />
      </Grid>
      <Grid item xs={4}>
        <SumCard
          className={styles.incurredSumCard}
          header="Incurred Claim Total"
          expensesAmount={amounts.expenses_incurred}
          indemnityAmount={amounts.indemnity_incurred}
          shouldDisplayOnlyHeader={shouldDisplayOnlyHeader}
        />
      </Grid>
    </Grid>
  );
};

SumsHeader.propTypes = {
  amounts: PropTypes.object,
  shouldDisplayOnlyHeader: PropTypes.bool,
};

export default SumsHeader;
