import React from 'react';
import PropTypes from 'prop-types';

import AlertBanner from '~/components/core/AlertBanner';

export const GeneralLicensesExpirationAlertBanner = ({ count }) => {
  const getWarningText = () => {
    if (!count) return null;

    return `There are ${count} licenses that are expired or about to be expired soon.`;
  };

  const warningText = getWarningText();

  return warningText ? (
    <AlertBanner withIcon={true} title="Please note" note={warningText} alertType={AlertBanner.ALERT_TYPES.WARNING} />
  ) : null;
};

GeneralLicensesExpirationAlertBanner.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
