import React from 'react';

import colors from '../../../../theme/tailwind/colors';
import { AddIcon } from '../../../icons';
import FsButton from '../../FsWrappers/FsButton/FsButton';
import { PermissionsButtonWrapper, useRestrictedPermissions } from '../../index';

import type { ButtonType } from './types';

export interface AddItemButtonProps {
  text?: string;
  onClick?: () => void;
  ignorePermissions?: boolean;
  variant?: Omit<ButtonType, 'outlined'>;
  disabled?: boolean;
  tooltipText?: string;
}

export const AddItemButton: React.FC<AddItemButtonProps> = ({
  text,
  onClick,
  ignorePermissions,
  variant = 'text',
  disabled,
  tooltipText,
}) => {
  const { userHasContextPermissions } = useRestrictedPermissions() as { userHasContextPermissions: boolean };
  const iconActiveColor = variant === 'contained' ? 'white' : colors?.teal[700];
  const iconDisabledColor = colors?.slate[900];

  const iconColor = userHasContextPermissions && !disabled ? iconActiveColor : iconDisabledColor;

  return (
    <FsButton
      variant={variant as string}
      startIcon={
        <PermissionsButtonWrapper>
          <AddIcon iconColor={iconColor} className="mt-2" disabled={disabled} />
        </PermissionsButtonWrapper>
      }
      color="primary"
      onClick={onClick}
      ignorePermissions={ignorePermissions}
      disabled={disabled}
      tooltipText={tooltipText}
    >
      {text}
    </FsButton>
  );
};
