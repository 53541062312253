import React from 'react';

import { ReportsTabLayout } from '~/components/ReportsPage/Tabs/ReportsTabLayout';

export const ReportsDashboardsTab: React.FC = () => {
  return (
    <ReportsTabLayout
      title="Published Reports and Dashboards"
      subTitle="Here you can view and manage all published reports and dashboards"
      isDrafts={false}
    />
  );
};
