import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import { PAYMENT_MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import { useClaim } from '../ClaimContainer';
import mixpanel from '../CmsMain/mixpanel';
import MultiPaymentsDialog from '../Finances/MultiPaymentsDialog';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import { CreditCardIcon } from '../icons';
import PencilIcon from '../icons/PencilIcon';
import TooltipLock from '../TooltipLock';

import SumContainer from './ExposuresTable/SumContainer';
import PaymentRequestContainer, {
  MinimizedPaymentRequestContainer,
} from './PaymentRequestContainer/PaymentRequestContainer';
import ExposureTooltipedLock from './ExposureTooltipedLock';
import { getPayableWithReserveType, isExposureWriteDisabled, PENDING_COLOR } from './ExposureUtils';

function PaidSumContainer(props) {
  const { exposure, payableWithReserve } = props;
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  const { claim, onClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const payableType = getPayableWithReserveType(exposure, payableWithReserve);
  const { add } = useMinimizedDialogs();

  const isMinimizedDialogsEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MINIMIZED_DIALOG_FEATURE
  );

  const {
    allowMultiplePendingPaymentRequests,
    allowIndemnityPaymentsOnlyForExistingPropertyOwner,
    isPaymentAllowedOnClosedExposureForPayableType,
  } = usePaymentsConfiguration();

  if (exposure.is_coverage_issue_exists) {
    return <ExposureTooltipedLock exposure={exposure} />;
  }

  // Assuming that for involved person claimant always exists
  if (
    allowIndemnityPaymentsOnlyForExistingPropertyOwner &&
    payableType === 'indemnity' &&
    exposure.involved_property &&
    !exposure.involved_property.owner_contact_id
  ) {
    return <TooltipLock title="Missing owner details" />;
  }

  const {
    paid_sum,
    pending_sum,
    are_pending_payment_requests_exist,
    payment_request_pending,
    are_pending_reserve_change_requests_exist,
  } = payableWithReserve;

  const allowIfDenied = payableType === 'expenses' || claim.type === 'wc_claim';

  const allowIfClosed = isPaymentAllowedOnClosedExposureForPayableType(payableType);

  const disableChangePayment =
    (exposure.is_pending_request_exists && !are_pending_payment_requests_exist) ||
    isExposureWriteDisabled(exposure, user, {
      enableIfDenied: allowIfDenied,
      allowOnClosedExposure: allowIfClosed,
    });

  const disableMakePayment =
    are_pending_reserve_change_requests_exist ||
    are_pending_payment_requests_exist ||
    isExposureWriteDisabled(exposure, user, {
      enableIfDenied: allowIfDenied,
      allowOnClosedExposure: allowIfClosed,
    });

  const paidIncludingPending = paid_sum + pending_sum;

  const shouldDisplaySinglePaymentRequest = !allowMultiplePendingPaymentRequests || paidIncludingPending === 0;

  const commonDialogProps = {
    claim,
    exposure,
    payableWithReserve,
    payableType,
    onClose: () => setShowPaymentDialog(false),
    onUpdate: onClaimUpdate,
  };

  const singlePaymentRequestDialogProps = {
    ...commonDialogProps,
    paymentRequest: payment_request_pending,
    cardDialogProps: {
      isDialog: true,
      maxWidth: 'md',
      fullWidth: true,
    },
  };

  const multiPaymentsDialogProps = {
    ...commonDialogProps,
  };

  const openPaymentRequestDialog = () => {
    if (isMinimizedDialogsEnabled) {
      const { handleCloseDialog } = add({
        barHeader: 'New Payment Request',
        type: 'PAYMENT',
        dialogComponent: shouldDisplaySinglePaymentRequest ? MinimizedPaymentRequestContainer : MultiPaymentsDialog,
        dialogProps: {
          ...(shouldDisplaySinglePaymentRequest ? singlePaymentRequestDialogProps : multiPaymentsDialogProps),
          onClose: () => handleCloseDialog(),
        },
      });
    } else {
      setShowPaymentDialog(true);
    }
  };

  const handleClick = () => {
    openPaymentRequestDialog();

    if (!payment_request_pending && shouldDisplaySinglePaymentRequest) {
      mixpanel.track(PAYMENT_MIXPANEL_EVENTS.SET_PAYMENT_CLICKED);
    }
  };

  // TODO: refactor - after we will refactor Reserves table we may add PaidSumContainer in the proper cell
  return (
    <>
      <HoverActionField
        onAction={handleClick}
        icon={disableMakePayment ? PencilIcon : CreditCardIcon}
        disabled={disableMakePayment && disableChangePayment}
        permanent
        actionAriaLabel={`Set ${payableType} payment`}
      >
        <SumContainer
          sum={paidIncludingPending}
          color={are_pending_payment_requests_exist ? PENDING_COLOR : undefined}
          fontWeight={exposure?.isGeneralExpenses ? 700 : undefined}
        />
      </HoverActionField>
      {showPaymentDialog &&
        (shouldDisplaySinglePaymentRequest ? (
          <PaymentRequestContainer {...singlePaymentRequestDialogProps} />
        ) : (
          <MultiPaymentsDialog {...multiPaymentsDialogProps} />
        ))}
    </>
  );
}

PaidSumContainer.propTypes = {
  payableWithReserve: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
};

export default PaidSumContainer;
