import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { reportAxiosError } from '../../../../Utils';
import CheckboxFormik from '../../../CheckboxFormik';
import { getAllSearchableContactRoles } from '../../../communications/ContactUtils';
import { ContactShowOnlyTextField } from '../../../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';
import useFormikChangeListener from '../../../core/Formik/FormikChangeListener';
import { TrashIcon_Deprecated } from '../../../icons';
import LoadingIndicator from '../../../LoadingIndicator';
import useOrganization from '../../../OrganizationContext';
import { getPayeeContacts } from '../paymentContainerUtils';
import PaymentPayeeFormik from '../PaymentPayeeFormik';
import PayToFormikInner from '../PayToFormikInner';
import PrimaryPayeeSelectorFormikInner from '../PrimaryPayeeSelectorFormikInner';

import { useStyles } from '../../../../assets/styles';
import styles from '../PaymentRequestContainer.module.scss';

function PaymentRequestStorePayeesSelectFormikInner({
  claim,
  exposure,
  payableType,
  paymentRequestStore,
  onChangePaymentRequestStore,
  values,
  setFieldValue,
  disableUpdatingPaymentRequestStore,
  readOnly,
}) {
  const classes = useStyles();
  const isStoreEmpty = !paymentRequestStore.data;
  const { initialValues } = useFormikContext();
  const { organizationContactRolesDict } = useOrganization();
  const {
    shouldUseUKBankTransfer,
    isPayeeEmailRequired,
    isPayeePhoneNumberRequired,
    shouldUseIbanBankTransfer,
    isDateOfBirthRequired,
  } = usePaymentsConfiguration(values.payment_method);
  const [minPayeeEntriesToShow, setMinPayeeEntriesToShow] = React.useState(0);

  const { primary_payee_id: primaryPayeeId } = values;
  const payeeContactsSelected = paymentRequestStore.data
    ? getPayeeContacts(values, paymentRequestStore.data, claim)
    : undefined;
  const primaryPayee = payeeContactsSelected?.find((contact) => contact.id === primaryPayeeId);
  const primaryContactIban = primaryPayee?.iban || '';
  const primaryContactUkSortCode = primaryPayee?.uk_sort_code || '';
  const primaryContactUkBankAccountNumber = primaryPayee?.uk_bank_account_number || '';

  const buildPaymentRequirementsUrl = () => {
    const baseUrlBeginning = `/api/v1/claims/${claim.id}`;
    const baseUrlEnd = `${payableType}/payment_requests/requirements`;

    if (payableType === 'expenses') {
      return `${baseUrlBeginning}/${baseUrlEnd}`;
    }

    return `${baseUrlBeginning}/exposures/${exposure.id}/${baseUrlEnd}`;
  };

  const updateValuesAccordingToNewStore = useCallback(
    (newPaymentRequestStoreData, shouldSetInitialValues, setFieldValue, initialValues, existingPayeeFieldNames) => {
      newPaymentRequestStoreData.forEach((paymentRequestStoreDataEntry) => {
        switch (paymentRequestStoreDataEntry.type) {
          case 'optional_known_contact':
            if (paymentRequestStoreDataEntry.should_force) {
              setFieldValue(paymentRequestStoreDataEntry.field_name, true);
            } else if (
              shouldSetInitialValues &&
              !paymentRequestStoreDataEntry.should_force &&
              !initialValues[paymentRequestStoreDataEntry.field_name]
            ) {
              setFieldValue(paymentRequestStoreDataEntry.field_name, false);
            }
            setFieldValue(
              paymentRequestStoreDataEntry.field_name + '_contact_id',
              paymentRequestStoreDataEntry.contact_id
            );
            setFieldValue(
              paymentRequestStoreDataEntry.field_name + '_contact',
              paymentRequestStoreDataEntry.contact ||
                claim.contacts.find((contact) => paymentRequestStoreDataEntry.contact_id === contact.id)
            );
            break;
          case 'contact_search_single':
            if (shouldSetInitialValues || !existingPayeeFieldNames.includes(paymentRequestStoreDataEntry.field_name)) {
              setFieldValue(paymentRequestStoreDataEntry.field_name, {
                contact_id: paymentRequestStoreDataEntry.contact_id || '',
                contact_full_name: paymentRequestStoreDataEntry.contact_full_name || '',
                contact:
                  paymentRequestStoreDataEntry.contact ||
                  claim.contacts.find((contact) => paymentRequestStoreDataEntry.contact_id === contact.id),
              });
              if (paymentRequestStoreDataEntry.is_email_required) {
                setFieldValue(`${paymentRequestStoreDataEntry.field_name}.email_id`, '');
              }
              if (paymentRequestStoreDataEntry.is_phone_number_required) {
                setFieldValue(`${paymentRequestStoreDataEntry.field_name}.phone_id`, '');
              }
            }
            break;
          case 'contact_search':
            if (shouldSetInitialValues || !existingPayeeFieldNames.includes(paymentRequestStoreDataEntry.field_name)) {
              setFieldValue(paymentRequestStoreDataEntry.field_name, [
                {
                  contact_id: paymentRequestStoreDataEntry.contact_id || '',
                  contact_full_name: paymentRequestStoreDataEntry.contact_full_name || '',
                  contact:
                    paymentRequestStoreDataEntry.contact ||
                    claim.contacts.find((contact) => paymentRequestStoreDataEntry.contact_id === contact.id),
                },
              ]);
            }
            break;
          default:
            throw Error(`Unknown paymentRequestStoreDataEntry type: ${paymentRequestStoreDataEntry.type}`);
        }
      });

      setFieldValue('payment_request_store', newPaymentRequestStoreData);
    },
    [claim]
  );

  useFormikChangeListener({
    listenForKeys: ['amount', 'payment_method', 'method_specific'],
    onChange: (newValues, _) => {
      async function fetchPaymentRequestStore() {
        onChangePaymentRequestStore((currPaymentRequestStore) => ({ ...currPaymentRequestStore, isFetching: true }));
        try {
          const res = await axios.get(buildPaymentRequirementsUrl(), {
            params: {
              amount: newValues.amount || 0,
              payment_method: newValues.payment_method,
              method_specific: newValues.method_specific,
            },
          });
          const newPaymentRequestStoreData = res.data;

          const existingPayeeFieldNames = newPaymentRequestStoreData
            .map((paymentRequestStoreDataEntry) => paymentRequestStoreDataEntry.field_name)
            .filter((fieldName) => fieldName in newValues);

          // update before notifying on store change to avoid uncontrolled components becoming controlled (new formik components rendering before values are set)
          updateValuesAccordingToNewStore(
            newPaymentRequestStoreData,
            isStoreEmpty,
            setFieldValue,
            initialValues,
            existingPayeeFieldNames
          );
          onChangePaymentRequestStore({ data: newPaymentRequestStoreData });
        } catch (error) {
          reportAxiosError(error);
          onChangePaymentRequestStore({ isError: true });
        }
      }

      if (disableUpdatingPaymentRequestStore) {
        return;
      }

      fetchPaymentRequestStore();
    },
    runOnFirstRender: true,
  });

  // on new paymentRequestStore, update should_force fields - can't do it before Formik initialValues are updated
  React.useEffect(() => {
    if (!paymentRequestStore.data) {
      return;
    }

    paymentRequestStore.data.forEach((paymentRequestStoreDataEntry) => {
      if (
        paymentRequestStoreDataEntry.type === 'optional_known_contact' &&
        paymentRequestStoreDataEntry.should_force &&
        values[paymentRequestStoreDataEntry.field_name] === false
      ) {
        setFieldValue(paymentRequestStoreDataEntry.field_name, true);
      }
    });
  }, [paymentRequestStore, setFieldValue, values]);

  React.useEffect(() => {
    if (!disableUpdatingPaymentRequestStore) {
      return;
    }
    updateValuesAccordingToNewStore(paymentRequestStore.data, true, setFieldValue, initialValues, []);
  }, [
    setFieldValue,
    disableUpdatingPaymentRequestStore,
    paymentRequestStore,
    initialValues,
    updateValuesAccordingToNewStore,
  ]);

  React.useEffect(() => {
    if (!shouldUseIbanBankTransfer) {
      return;
    }

    setFieldValue('iban', primaryContactIban);
  }, [setFieldValue, primaryContactIban, claim, shouldUseIbanBankTransfer]);

  React.useEffect(() => {
    if (!shouldUseUKBankTransfer) {
      return;
    }

    setFieldValue('request_extra.uk_bank.uk_sort_code', primaryContactUkSortCode);
    setFieldValue('request_extra.uk_bank.uk_bank_account_number', primaryContactUkBankAccountNumber);
  }, [setFieldValue, primaryContactUkSortCode, primaryContactUkBankAccountNumber, claim, shouldUseUKBankTransfer]);

  const disabled = paymentRequestStore.isFetching || readOnly;

  if (!paymentRequestStore.data) {
    return <LoadingIndicator isError={paymentRequestStore.isError} />;
  }

  const requiredPayeeEntries = Math.max(
    1,
    !paymentRequestStore.data
      ? 0
      : paymentRequestStore.data.filter(
          (paymentRequestStoreEntry) =>
            paymentRequestStoreEntry.type === 'optional_known_contact' ||
            (paymentRequestStoreEntry.type === 'contact_search_single' && paymentRequestStoreEntry.is_required)
        ).length
  );
  // NOTE: I would like to sincerely apologize for this code, I saw no other way
  // we assume that entries are ordered by required-first, hence we always show the first requied entires (requiredPayeeEntries)
  // we allow to add a payee only if all available payee-entries are filled
  // we only allow removing an entree if it's empty and last
  const payeeIdxToShow = Math.max(minPayeeEntriesToShow, requiredPayeeEntries, payeeContactsSelected.length);

  return (
    <>
      <div>
        <Typography display="block" className={styles.sectionTitle}>
          Payees
        </Typography>
        <ErrorHelperTextFormik id="primary_payee_id" />
        {paymentRequestStore.data.map((paymentRequestStoreEntry, paymentRequestStoreEntryIdx) => (
          <div key={paymentRequestStoreEntry.field_name}>
            {paymentRequestStoreEntry.type === 'optional_known_contact' && (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <PrimaryPayeeSelectorFormikInner
                  setFieldValue={setFieldValue}
                  values={values}
                  contactId={paymentRequestStoreEntry.contact_id}
                  disabled={disabled || !values[paymentRequestStoreEntry.field_name]}
                  paymentRequestStore={paymentRequestStore}
                />
                <CheckboxFormik
                  id={paymentRequestStoreEntry.field_name}
                  disabled={disabled || paymentRequestStoreEntry.should_force}
                  label={
                    <ContactShowOnlyTextField
                      contactId={paymentRequestStoreEntry.contact_id}
                      contactDisplayName={paymentRequestStoreEntry.contact_full_name}
                      label={paymentRequestStoreEntry.label}
                    />
                  }
                />
                <ErrorHelperTextFormik id={`${paymentRequestStoreEntry.field_name}_contact`} />
              </span>
            )}
            {paymentRequestStoreEntry.type === 'contact_search_single' &&
              (paymentRequestStoreEntryIdx < payeeIdxToShow ||
                values[paymentRequestStoreEntry.field_name].contact_id) && (
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={1}>
                    <PrimaryPayeeSelectorFormikInner
                      setFieldValue={setFieldValue}
                      values={values}
                      contactId={values[paymentRequestStoreEntry.field_name].contact_id}
                      disabled={disabled}
                      paymentRequestStore={paymentRequestStore}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PaymentPayeeFormik
                      contactFieldId={`${paymentRequestStoreEntry.field_name}.contact`}
                      label={paymentRequestStoreEntry.label}
                      acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
                      isDisabled={disabled}
                      isEmailRequired={
                        (values['primary_payee_id'] ===
                          getIn(values, `${paymentRequestStoreEntry.field_name}.contact_id`) &&
                          isPayeeEmailRequired) ||
                        paymentRequestStoreEntry.is_email_required
                      }
                      isPhoneNumberRequired={
                        (values['primary_payee_id'] ===
                          getIn(values, `${paymentRequestStoreEntry.field_name}.contact_id`) &&
                          isPayeePhoneNumberRequired) ||
                        paymentRequestStoreEntry.is_phone_number_required
                      }
                      emailFieldId={
                        isPayeeEmailRequired
                          ? 'primary_payee_email_id'
                          : `${paymentRequestStoreEntry.field_name}.email_id`
                      }
                      phoneNumberFieldId={
                        isPayeePhoneNumberRequired
                          ? 'primary_payee_phone_id'
                          : `${paymentRequestStoreEntry.field_name}.phone_id`
                      }
                      fixedSearchResults
                      excludedContactIdsList={getPayeeContacts(values, paymentRequestStore.data, claim, {
                        shouldIncludeOnlyChosen: false,
                      }).map((contact) => contact.id)}
                      isDateOfBirthRequired={
                        values['primary_payee_id'] ===
                          getIn(values, `${paymentRequestStoreEntry.field_name}.contact_id`) && isDateOfBirthRequired
                      }
                      dateOfBirthFieldId="primary_payee_date_of_birth"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <>
                      {!values[paymentRequestStoreEntry.field_name].contact_id &&
                        paymentRequestStoreEntryIdx === payeeIdxToShow - 1 &&
                        paymentRequestStoreEntryIdx >= requiredPayeeEntries && (
                          <IconButton
                            className={styles.payeesIconButton}
                            onClick={() => setMinPayeeEntriesToShow(payeeIdxToShow - 1)}
                          >
                            <TrashIcon_Deprecated />
                          </IconButton>
                        )}
                      {values[paymentRequestStoreEntry.field_name].contact_id &&
                        payeeContactsSelected.length >= requiredPayeeEntries &&
                        paymentRequestStoreEntryIdx === payeeIdxToShow - 1 &&
                        paymentRequestStoreEntryIdx < paymentRequestStore.data.length - 1 && (
                          <IconButton
                            className={styles.payeesIconButton}
                            onClick={() => setMinPayeeEntriesToShow(payeeIdxToShow + 1)}
                          >
                            <AddIcon />
                          </IconButton>
                        )}
                    </>
                  </Grid>
                </Grid>
              )}
            {paymentRequestStoreEntry.type === 'contact_search' &&
              (paymentRequestStoreEntryIdx < payeeIdxToShow ||
                values[paymentRequestStoreEntry.field_name][0].contact_id) && (
                <Grid container spacing={1} alignItems="center">
                  {values[paymentRequestStoreEntry.field_name].map((payeeContact, idx) => (
                    <Fragment key={values[paymentRequestStoreEntry.field_name][idx].id || 'first'}>
                      <Grid item xs={1}>
                        <PrimaryPayeeSelectorFormikInner
                          setFieldValue={setFieldValue}
                          values={values}
                          contactId={values[paymentRequestStoreEntry.field_name][idx].contact_id}
                          disabled={disabled}
                          paymentRequestStore={paymentRequestStore}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <PaymentPayeeFormik
                          contactFieldId={`${paymentRequestStoreEntry.field_name}[${idx}].contact`}
                          additionalContactFieldId={
                            values['primary_payee_id'] ===
                            getIn(values, `${paymentRequestStoreEntry.field_name}[${idx}].contact_id`)
                              ? 'primary_payee'
                              : null
                          }
                          label={paymentRequestStoreEntry.label}
                          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
                          isDisabled={disabled}
                          isEmailRequired={
                            values['primary_payee_id'] ===
                              getIn(values, `${paymentRequestStoreEntry.field_name}[${idx}].contact_id`) &&
                            isPayeeEmailRequired
                          }
                          isPhoneNumberRequired={
                            values['primary_payee_id'] ===
                              getIn(values, `${paymentRequestStoreEntry.field_name}[${idx}].contact_id`) &&
                            isPayeePhoneNumberRequired
                          }
                          emailFieldId="primary_payee_email_id"
                          phoneNumberFieldId="primary_payee_phone_id"
                          fixedSearchResults
                          excludedContactIdsList={getPayeeContacts(values, paymentRequestStore.data, claim, {
                            shouldIncludeOnlyChosen: false,
                          }).map((contact) => contact.id)}
                          isDateOfBirthRequired={
                            values['primary_payee_id'] ===
                              getIn(values, `${paymentRequestStoreEntry.field_name}[${idx}].contact_id`) &&
                            isDateOfBirthRequired
                          }
                          dateOfBirthFieldId="primary_payee_date_of_birth"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <>
                          {values[paymentRequestStoreEntry.field_name].length === 1 &&
                            !values[paymentRequestStoreEntry.field_name][0].contact_id &&
                            paymentRequestStoreEntryIdx === payeeIdxToShow - 1 &&
                            paymentRequestStoreEntryIdx >= requiredPayeeEntries && (
                              <IconButton
                                className={styles.payeesIconButton}
                                onClick={() => setMinPayeeEntriesToShow(payeeIdxToShow - 1)}
                              >
                                <TrashIcon_Deprecated />
                              </IconButton>
                            )}
                          {values[paymentRequestStoreEntry.field_name].length > 1 && (
                            <IconButton
                              className={styles.payeesIconButton}
                              onClick={() =>
                                setFieldValue(
                                  paymentRequestStoreEntry.field_name,
                                  values[paymentRequestStoreEntry.field_name].filter((_, currIdx) => currIdx !== idx)
                                )
                              }
                            >
                              <TrashIcon_Deprecated />
                            </IconButton>
                          )}
                          {idx === values[paymentRequestStoreEntry.field_name].length - 1 &&
                            payeeContact.contact_id && (
                              <IconButton
                                className={styles.payeesIconButton}
                                onClick={() =>
                                  setFieldValue(paymentRequestStoreEntry.field_name, [
                                    ...values[paymentRequestStoreEntry.field_name],
                                    { contact_id: '', id: uuidv4() },
                                  ])
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            )}
                        </>
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              )}
          </div>
        ))}
        <div className={classes.inputContainer}>
          <PayToFormikInner payeeContactsSelected={payeeContactsSelected} showOnly={disabled} />
        </div>
      </div>
    </>
  );
}

PaymentRequestStorePayeesSelectFormikInner.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  payableType: PropTypes.oneOf(['indemnity', 'expenses']).isRequired,
  amount: PropTypes.number.isRequired,
  paymentRequestStore: PropTypes.object.isRequired,
  onChangePaymentRequestStore: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  disableUpdatingPaymentRequestStore: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default PaymentRequestStorePayeesSelectFormikInner;
