import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const SignOutIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7822 7.53217C16.0751 7.23928 16.5499 7.23928 16.8428 7.53217L20.7803 11.4697C21.0732 11.7626 21.0732 12.2374 20.7803 12.5303L16.8428 16.4678C16.5499 16.7607 16.0751 16.7607 15.7822 16.4678C15.4893 16.1749 15.4893 15.7001 15.7822 15.4072L19.1893 12L15.7822 8.59283C15.4893 8.29994 15.4893 7.82506 15.7822 7.53217Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12C9 11.5858 9.33579 11.25 9.75 11.25H20.25C20.6642 11.25 21 11.5858 21 12C21 12.4142 20.6642 12.75 20.25 12.75H9.75C9.33579 12.75 9 12.4142 9 12Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.43934 3.43934C3.72064 3.15804 4.10217 3 4.5 3H9.75C10.1642 3 10.5 3.33579 10.5 3.75C10.5 4.16421 10.1642 4.5 9.75 4.5L4.5 4.5L4.5 19.5H9.75C10.1642 19.5 10.5 19.8358 10.5 20.25C10.5 20.6642 10.1642 21 9.75 21H4.5C4.10218 21 3.72065 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V4.5C3 4.10217 3.15804 3.72064 3.43934 3.43934Z"
      fill={iconColor}
    />
  </SvgIcon>
);

SignOutIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default SignOutIcon;
