import React from 'react';

import { LegalActionPriorityIcon } from '~/components/LegalActions/components/LegalActionPriorityIcon';

import { PropertyDetails } from '../../core';
import { LEGAL_ACTION_FIELDS } from '../FormFragments/FormFieldsDefinitions';
import type { Option } from '../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../hooks/useLegalActionsConfiguration';
import { getLabelByKey } from '../LegalActionsUtils';

export const PriorityPropertyDetails: React.FC<{ priority: string }> = ({ priority }) => {
  const { priorityOptions } = useLegalActionsConfiguration();
  return (
    <PropertyDetails
      title={LEGAL_ACTION_FIELDS.priority.label}
      text={getLabelByKey(priorityOptions as Option[], priority)}
      textIcon={<LegalActionPriorityIcon priority={priority} />}
    />
  );
};
