const BIKE_MARKING_TYPE_DICT = {
  no: {
    desc: 'No',
  },
  bicycode: {
    desc: 'Bicycode',
  },
  paravol: {
    desc: 'Paravol',
  },
  recobike: {
    desc: 'Recobike',
  },
  'mybike.brussel': {
    desc: 'mybike.brussel',
  },
  bikebank: {
    desc: 'Bikebank',
  },
  velopass: {
    desc: 'Velopass',
  },
  bikeRegister: {
    desc: 'Bike Register',
  },
  bikeIndex: {
    desc: 'Bike Index',
  },
  Other: {
    desc: 'Other',
  },
} as const;

export default BIKE_MARKING_TYPE_DICT;
