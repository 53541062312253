import React from 'react';

import { useTableauEmbed } from '~/components/ReportsPage/EmbedComponents/useTableauEmbed';
import { useTableauEmbedRoutes } from '~/components/ReportsPage/Hooks/useTableauEmbedRoutes';
import type { TableauEmbedProps } from '~/components/ReportsPage/types';

export const TableauEmbedViewer: React.FC<TableauEmbedProps> = ({ workbook, view, token, options }) => {
  const { getEmbedUrl } = useTableauEmbedRoutes();
  const src = getEmbedUrl({ workbook, view });

  const ref = React.useRef(null);
  useTableauEmbed({ options, ref });

  return (
    <tableau-viz
      ref={ref}
      id="tableauViz"
      src={src}
      token={token}
      toolbar="bottom" // top, bottom, hidden
      hide-tabs="true"
      hide-edit-in-desktop-button="true"
      show-share-options="false"
      suppress-default-edit-behavior="true"
      hide-edit-button="false"
      width={options.width}
      height={options.height}
    >
      <custom-parameter name=":embed" value="y" />
      <custom-parameter name=":dataDetails" value="no" />
      <custom-parameter name=":customViews" value="no" />
      <custom-parameter name=":subscriptions" value="no" />
      <custom-parameter name=":alerts" value="no" />
      <custom-parameter name=":showShareOptions" value="false" />
    </tableau-viz>
  );
};
