import React, { useState } from 'react';
import axios from 'axios';

import colors from '~/assets/colors.module.scss';
import { AttachOtherContacts } from '~/components/communications/AttachCommunicationContainer';
import { displayRoleName } from '~/components/communications/ContactUtils';
import { ContactEntity } from '~/components/Contact';
import { Text } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import { ContactIcon } from '~/components/icons';
import useOrganization from '~/components/OrganizationContext';
import { reportAxiosError } from '~/Utils';

import type { ChannelType } from './constants';

interface ViewCommunicationCardHeaderContactDetailsProps {
  contactId?: number;
  contactFullName?: string;
  contactRole?: string;
  enableAttachToContact?: boolean;
  communicationId: number;
  communicationClaimId?: number;
  communicationChannel: ChannelType;
  onAttachToContact?: () => void;
}

const ViewCommunicationCardHeaderContactDetails: React.FC<ViewCommunicationCardHeaderContactDetailsProps> = ({
  contactId,
  contactFullName,
  contactRole,
  communicationId,
  communicationClaimId,
  communicationChannel,
  enableAttachToContact,
  onAttachToContact,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();
  const [showAttachToContactDialog, setShowAttachToContactDialog] = useState(false);

  const handleAttachContactToCommunication = async ({
    contact_id,
    contact_attachment_choice,
  }: {
    contact_id: number;
    contact_attachment_choice: string;
  }) => {
    try {
      await axios.post(`/api/v1/communications/${communicationId}/attach_contact`, {
        contact_id,
        should_create_new_contact: contact_attachment_choice === 'new_contact',
      });
      if (onAttachToContact) {
        await onAttachToContact();
      }
      setShowAttachToContactDialog(false);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  if (!contactId) {
    if (!enableAttachToContact || !communicationClaimId) {
      return null;
    }

    return (
      <>
        <Button
          className="flex items-center"
          variant="text"
          color="primary"
          onClick={() => setShowAttachToContactDialog(true)}
        >
          <ContactIcon className="mr-4" iconColor={colors.buttonLink} />
          Attach to contact
        </Button>
        {showAttachToContactDialog && (
          <AttachOtherContacts
            claimId={communicationClaimId}
            communicationId={communicationId}
            onClose={() => setShowAttachToContactDialog(false)}
            communicationChannel={communicationChannel}
            onSubmit={handleAttachContactToCommunication}
          />
        )}
      </>
    );
  }

  return (
    <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} className="flex">
      <ContactEntity contactId={contactId} contactDisplayName={contactFullName} />
      <span className="ml-16">
        <em>{displayRoleName(organizationContactRolesDict, contactRole)}</em>
      </span>
    </Text>
  );
};

export default ViewCommunicationCardHeaderContactDetails;
