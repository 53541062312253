import React from 'react';
import PropTypes from 'prop-types';

import FsTooltip from '../FsTooltip/FsTooltip';

export const WrapperWithTooltip = ({ component, children, tooltipText }) => {
  return tooltipText ? <FsTooltip title={tooltipText}>{component || children}</FsTooltip> : component || children;
};

WrapperWithTooltip.propTypes = {
  component: PropTypes.node,
  tooltipText: PropTypes.string,
  children: PropTypes.node,
};
