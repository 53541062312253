import type { ReactElement, ReactNode } from 'react';
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { setNestedObjectValues, useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import type { ButtonProps } from '~/components/core/Atomic/Buttons/Button';
import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import cn from '~/Utils/cn';

import CancelButton from './core/Buttons/CancelButton';
import { FsIconButton } from './core/FsWrappers';
import { CloseIcon } from './icons';

// NOTE: original idea from https://itnext.io/declarative-dialogs-in-react-and-jsx-570aa40d258c

const DEFAULT_TRIGGER_FUNC = 'onClick';

interface MutualConfirmProps {
  title: ReactNode;
  contentText?: ReactNode;
  secondaryButtonName?: string;
  primaryButtonName: string;
  centerDialog?: boolean;
  newSecondaryButton?: boolean;
  secondaryButtonProps?: ButtonProps;
  removeSecondaryButton?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

interface ConfirmModalProps extends MutualConfirmProps {
  isOpen: boolean;
  isSubmitting?: boolean;
  onClose: () => void;
  onPrimaryBtnClick: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  isSubmitting,
  onClose,
  title,
  contentText,
  secondaryButtonName,
  primaryButtonName,
  onPrimaryBtnClick,
  removeSecondaryButton,
  maxWidth,
  secondaryButtonProps = {},
  centerDialog = false,
  newSecondaryButton = false,
}) => {
  const classes = useStyles();
  const titleClassName = centerDialog ? classes.centeredDialogTitleContainer : classes.containerTitle;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} className={cn({ 'cursor-progress': isSubmitting })}>
      <DialogTitle className={titleClassName} disableTypography>
        <div className={classes.dialogTitleContainer}>
          <div className={classes.dialogTitleContent}>
            <Typography display="block" variant="h6">
              {title}
            </Typography>
          </div>
          <div className={classes.dialogTitleAction}>
            <div className="absolute right-24 top-24">
              <FsIconButton onClick={onClose} icon={CloseIcon} ignorePermissions />
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={`text-slate-900 ${centerDialog ? 'text-center' : ''}`}>
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="p-24 pt-0">
        {secondaryButtonName && secondaryButtonName !== 'Cancel' && !newSecondaryButton ? (
          <Button disabled={isSubmitting} onClick={onClose} color="secondary" autoFocus {...secondaryButtonProps}>
            {secondaryButtonName}
          </Button>
        ) : (
          !removeSecondaryButton && (
            <CancelButton
              content={secondaryButtonName || 'Cancel'}
              disabled={isSubmitting}
              onClick={onClose}
              autoFocus
              contained
            />
          )
        )}
        {primaryButtonName && (
          <Button
            disabled={isSubmitting}
            onClick={onPrimaryBtnClick}
            color="primary"
            variant="contained"
            className="min-w-90 ml-0"
          >
            {primaryButtonName}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export interface WithConfirmProps extends MutualConfirmProps {
  children: ReactElement;
  // NOTE: if modal is inside a dialog and confirm closes parent dialog, anything after onClick (like closing the modal) will happen on an unmounted component,
  // NOTE: so, shouldCloseOnPrimary should be false
  shouldCloseOnPrimary?: boolean;
  disableConfirm?: boolean;
  postOnClick?: () => void;
  onSecondaryBtnClick?: () => void;
  triggerMethod: string;
  onOpenConfirmModal?: () => void;
  disabled?: boolean; // to allow disabling the button below
}

interface WithConfirmState {
  isOpen: boolean;
  isSubmitting: boolean;
}

class WithConfirm extends Component<WithConfirmProps, WithConfirmState> {
  static defaultProps = {
    triggerMethod: DEFAULT_TRIGGER_FUNC,
  };

  constructor(props: WithConfirmProps) {
    super(props);

    this.state = {
      isOpen: false,
      isSubmitting: false,
    };
  }

  handlePrimaryBtnClick = (): void => {
    const { children, shouldCloseOnPrimary, triggerMethod } = this.props;

    this.setState({ isSubmitting: true });

    Promise.resolve(children.props[triggerMethod]())
      .then(() => {
        if (shouldCloseOnPrimary) {
          this.setState({ isOpen: false });
        }
        this.setState({ isSubmitting: false });
      })
      .then(() => {
        if (this.props.postOnClick) {
          return this.props.postOnClick();
        }
      })
      .catch(() => this.setState({ isSubmitting: false }));
  };

  handleSecondaryBtnClick = (): void => {
    this.setState({ isOpen: false });
    this.props.onSecondaryBtnClick && this.props.onSecondaryBtnClick();
  };

  componentDidUpdate = (): void => {
    if (this.state.isOpen && this.props.onOpenConfirmModal) {
      this.props.onOpenConfirmModal();
    }
  };

  render(): React.JSX.Element {
    const {
      children,
      title,
      contentText,
      secondaryButtonName,
      primaryButtonName,
      disableConfirm,
      triggerMethod,
      maxWidth,
      removeSecondaryButton,
      secondaryButtonProps,
      disabled,
      centerDialog,
      newSecondaryButton,
    } = this.props;
    const { isOpen, isSubmitting } = this.state;

    if (disableConfirm) {
      return children;
    }

    const newChildren = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        [triggerMethod]: () => this.setState({ isOpen: true }),
        disabled: children.props.disabled || disabled, // to allow pass-through of disabling through this component
      })
    );

    return (
      <span>
        {newChildren}
        <ConfirmModal
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          title={title}
          contentText={contentText}
          secondaryButtonName={secondaryButtonName}
          primaryButtonName={primaryButtonName}
          onClose={this.handleSecondaryBtnClick}
          onPrimaryBtnClick={this.handlePrimaryBtnClick}
          maxWidth={maxWidth}
          removeSecondaryButton={removeSecondaryButton}
          secondaryButtonProps={secondaryButtonProps}
          centerDialog={centerDialog}
          newSecondaryButton={newSecondaryButton}
        />
      </span>
    );
  }
}

interface WithConfirmFormikProps extends MutualConfirmProps {
  children: ReactElement;
  shouldCloseOnPrimary?: boolean;
  disabled?: boolean;
}

const WithConfirmFormik: React.FC<WithConfirmFormikProps> = (props: WithConfirmFormikProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const { children, shouldCloseOnPrimary, title, contentText, secondaryButtonName, primaryButtonName, disabled } =
    props;
  const { validateForm, setTouched } = useFormikContext();

  async function handlePrimaryBtnClick() {
    setIsSubmitting(true);

    try {
      await (children as ReactElement).props.onClick();
      if (shouldCloseOnPrimary) {
        setIsOpen(false);
      }
      setIsSubmitting(false);
    } catch {
      setIsSubmitting(false);
    }
  }

  const newChildren = React.Children.map(children, (child: ReactElement) =>
    React.cloneElement(child, {
      onClick: async () => {
        // on Formik forms, to avoid showing the confirmation modal and only then showing the user form errors - validate and touch all fields with errors (otherwise, the user won't see the error)
        const errors = await validateForm();
        if (Object.keys(errors).length !== 0) {
          setTouched(setNestedObjectValues(errors, true));
        } else {
          setIsOpen(true);
        }
      },
    })
  );

  if (disabled) {
    return children;
  }

  return (
    <span>
      {newChildren}
      <ConfirmModal
        isOpen={isOpen}
        isSubmitting={isSubmitting}
        title={title}
        contentText={contentText}
        secondaryButtonName={secondaryButtonName}
        primaryButtonName={primaryButtonName}
        onClose={() => setIsOpen(false)}
        onPrimaryBtnClick={handlePrimaryBtnClick}
      />
    </span>
  );
};

export { ConfirmModal, WithConfirmFormik };
export default WithConfirm;
