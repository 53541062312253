import type { ReactNode } from 'react';
import React from 'react';

import { CardBase } from '../CardBase';
import type { CardBaseExternalProps } from '../types';
import { CardTypeToStyleAttributesMap } from '../types';

interface FormItemCardProps extends CardBaseExternalProps {
  titleAction?: ReactNode;
}

export const FormItemCard: React.FC<FormItemCardProps> = ({ titleAction, children, action, title }) => (
  <CardBase
    titleProps={{
      variant: 'SM',
      weight: 'SEMI_BOLD',
      colorVariant: 'PRIMARY',
    }}
    {...CardTypeToStyleAttributesMap['filled']}
    collapsible={false}
    paddingX={20}
    paddingY={20}
    contentGap={10}
    action={action}
    titleAction={titleAction}
    title={title}
  >
    {children}
  </CardBase>
);
