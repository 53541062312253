import React from 'react';
import axios from 'axios';

import { reportAxiosError } from '../../../../../Utils';
import { ObjectCard, SecondaryCard } from '../../../../core';
import { LegalActionAddItemButton } from '../../../components/LegalActionAddItemButton';
import { LegalActionItemMenu } from '../../../components/LegalActionItemMenu';
import { AddDisputeResolutionProfessionalDialog } from '../../../Dialogs/DisputeResolutionProfessional/AddDisputeResolutionProfessionalDialog';
import { EditDisputeResolutionProfessionalDialog } from '../../../Dialogs/DisputeResolutionProfessional/EditDisputeResolutionProfessionalDialog';
import { useLegalAction } from '../../../hooks/useLegalAction';
import { useLegalActionsBackendRoutes } from '../../../hooks/useLegalActionsBackendRoutes';
import type { DisputeResolutionProfessionalApiResponse } from '../../../types';

import { DisputeResolutionProfessionalContent } from './DisputeResolutionProfessionalContent';
import { DisputeResolutionProfessionalMoreDetailsDialog } from './DisputeResolutionProfessionalMoreDetailsDialog';

export const DisputeResolutionProfessionalsCard: React.FC = () => {
  const { legalAction, reloadLegalAction } = useLegalAction();
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const [isAddDisputeResolutionProfessionalDialogOpen, setIsAddDisputeResolutionProfessionalDialogOpen] =
    React.useState(false);
  const [disputeResolutionProfessionalInEdit, setDisputeResolutionProfessionalInEdit] =
    React.useState<DisputeResolutionProfessionalApiResponse | null>(null);
  const [disputeResolutionProfessionalInMoreDetails, setDisputeResolutionProfessionalInMoreDetails] =
    React.useState<DisputeResolutionProfessionalApiResponse | null>(null);

  const handleDeleteClick = async (disputeResolutionProfessional: DisputeResolutionProfessionalApiResponse) => {
    try {
      await axios.delete(backendRoutes.legalAction.dispute_resolution_professional(disputeResolutionProfessional.id));
      await reloadLegalAction();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <>
      <SecondaryCard type="filled" title="Dispute Resolution Professionals" collapsible openByDefault>
        {legalAction.dispute_resolution_professionals.map((drp) => (
          <ObjectCard
            key={drp.id}
            type="contained"
            action={
              <LegalActionItemMenu
                entityLabel="Dispute Resolution Professional"
                onDeleteClick={() => handleDeleteClick(drp)}
                onEditClick={() => setDisputeResolutionProfessionalInEdit(drp)}
                onViewMore={() => setDisputeResolutionProfessionalInMoreDetails(drp)}
              />
            }
          >
            <DisputeResolutionProfessionalContent drp={drp} rows={1} />
          </ObjectCard>
        ))}
        <div>
          <LegalActionAddItemButton
            text="Add Dispute Resolution Professional"
            onClick={() => setIsAddDisputeResolutionProfessionalDialogOpen(true)}
          />
        </div>
      </SecondaryCard>
      {isAddDisputeResolutionProfessionalDialogOpen ? (
        <AddDisputeResolutionProfessionalDialog
          legalAction={legalAction}
          onClose={() => setIsAddDisputeResolutionProfessionalDialogOpen(false)}
          onSubmitSuccess={reloadLegalAction}
        />
      ) : null}
      {disputeResolutionProfessionalInEdit ? (
        <EditDisputeResolutionProfessionalDialog
          disputeResolutionProfessional={disputeResolutionProfessionalInEdit}
          legalAction={legalAction}
          onClose={() => setDisputeResolutionProfessionalInEdit(null)}
          onSubmitSuccess={reloadLegalAction}
        />
      ) : null}
      {disputeResolutionProfessionalInMoreDetails ? (
        <DisputeResolutionProfessionalMoreDetailsDialog
          onClose={() => setDisputeResolutionProfessionalInMoreDetails(null)}
          drp={disputeResolutionProfessionalInMoreDetails}
        />
      ) : null}
    </>
  );
};
