import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { isExposureFinancialOnly, reportAxiosError, reportErrorInProductionOrThrow } from '../../Utils';
import { useClaim } from '../ClaimContainer';
import ExposureTooltipedLock from '../exposures/ExposureTooltipedLock';
import { isExposureWriteDisabled } from '../exposures/ExposureUtils';
import { useCms } from '../hooks/useCms';

import CoverageDecisionContainer from './CoverageDecisionContainer';

function ExposureCoverageDecisionContainer({ exposure }) {
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();

  const coverageDecision = exposure.coverage_decision;

  if (isExposureFinancialOnly(userOrganization)) {
    reportErrorInProductionOrThrow(
      `ExposureCoverageDecisionContainer was called for an exposure of claim which is not supported: ${claim.id} `
    );
    return null;
  }

  const handleUpdateCoverageDecision = async (values) => {
    try {
      const method = coverageDecision ? 'put' : 'post';
      await axios({
        method,
        url: `/api/v1/claims/${claim.id}/exposures/${exposure.id}/coverage_decision`,
        data: values,
      });
      await onAsyncClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const readOnly = isExposureWriteDisabled(exposure, user, { enableIfDenied: true });

  if (!coverageDecision && readOnly) {
    return <ExposureTooltipedLock exposure={exposure} />;
  }

  return (
    <CoverageDecisionContainer
      exposures={[exposure]}
      coverageDecision={coverageDecision}
      readOnly={readOnly}
      onUpdateCoverageDecision={handleUpdateCoverageDecision}
    />
  );
}

ExposureCoverageDecisionContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export default ExposureCoverageDecisionContainer;
