import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const QaResultsLink = ({ text, onClick }) => (
  <>
    <Link
      href=""
      style={{ margin: 0, textDecoration: 'underline' }}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {text}
    </Link>
  </>
);

QaResultsLink.propTypes = {
  text: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default QaResultsLink;
