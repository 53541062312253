import React from 'react';

import { ContactEntity } from '~/components/Contact';
import { Text } from '~/components/core';
import useOrganization from '~/components/OrganizationContext';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';

import { displayRoleName } from '../ContactUtils';

interface ContactSectionProps {
  contactId: number;
  contactRole?: string;
  contactFullName?: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({ contactId, contactRole, contactFullName }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();

  return (
    <>
      <div className="flex items-center">
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD} className="flex">
          <ContactEntity contactId={contactId} contactDisplayName={contactFullName} />
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className="ml-12 flex">
            <OverflowTextWithToolTip maxWidth="100px">
              {displayRoleName(organizationContactRolesDict, contactRole)}
            </OverflowTextWithToolTip>
          </Text>
        </Text>
      </div>
    </>
  );
};

export default ContactSection;
