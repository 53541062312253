import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const ChevronLeftIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M10.6666 13.5L5.66663 8.5L10.6666 3.5"
      stroke={iconColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

ChevronLeftIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default ChevronLeftIcon;
