import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import CheckboxFormik from '~/components/CheckboxFormik';
import useXactAnalysisContractor from '~/components/hooks/useXactAnalysisContractor';
import LoadingIndicator from '~/components/LoadingIndicator';
import { TextFieldFormik } from '~/components/TextFieldFormik';

import { useContact } from '../../useContact';

import { FORMIK_INTEGRATIONS_FIELD_IDS } from './constants';
import { getFullIntegrationsPath } from './utils';

const Xact = ({ disabled }) => {
  const { values } = useFormikContext();
  const { contactId, currentIsMoiServiceProvider } = useContact();
  const { isContractorLoading, isXactAnalysisEnabled } = useXactAnalysisContractor(
    contactId,
    getFullIntegrationsPath(FORMIK_INTEGRATIONS_FIELD_IDS.IS_XACT_CONTRACTOR),
    getFullIntegrationsPath(FORMIK_INTEGRATIONS_FIELD_IDS.XACT_CARRIER_ID),
    getFullIntegrationsPath(FORMIK_INTEGRATIONS_FIELD_IDS.XACTNET_ADDRESS)
  );

  const isXactanalysisContractor = getIn(
    values,
    getFullIntegrationsPath(FORMIK_INTEGRATIONS_FIELD_IDS.IS_XACT_CONTRACTOR)
  );

  // TODO: reset fields on xact diasable & moi service provider unchecked

  if (!isXactAnalysisEnabled) {
    return null;
  }

  const xactIsDisabled = disabled || !currentIsMoiServiceProvider;

  return (
    <>
      <div className="grid grid-cols-2 gap-20">
        {isContractorLoading ? (
          <LoadingIndicator size={16} />
        ) : (
          <>
            <div className="col-span-full">
              <CheckboxFormik
                id={getFullIntegrationsPath(FORMIK_INTEGRATIONS_FIELD_IDS.IS_XACT_CONTRACTOR)}
                label="XactAnalysis Dataset"
                fullWidth
                disabled={xactIsDisabled}
              />
            </div>
            <TextFieldFormik
              id={getFullIntegrationsPath(FORMIK_INTEGRATIONS_FIELD_IDS.XACT_CARRIER_ID)}
              label="XactAnalysis Dataset Carrier ID"
              fullWidth
              disabled={xactIsDisabled || !isXactanalysisContractor}
            />
            <TextFieldFormik
              id={getFullIntegrationsPath(FORMIK_INTEGRATIONS_FIELD_IDS.XACTNET_ADDRESS)}
              label="Recipient XactNet Address"
              fullWidth
              disabled={xactIsDisabled || !isXactanalysisContractor}
            />
          </>
        )}
      </div>
    </>
  );
};

Xact.propTypes = {
  disabled: PropTypes.bool,
};
export default Xact;
