const CLAIM_SEARCH = {
  SUPPORTED_TRIGGER_METHODS: ['immediate', 'following_information'],
  SUPPORTED_SCHEDULE_PERIOD: {
    every: {
      desc: 'Every (repeating)',
      key: 'every',
    },
    after: {
      desc: 'After',
      key: 'after',
    },
  },
  SCHEDULE_TIME_FRAME: {
    hours: {
      desc: 'Hours',
      key: 'hours',
    },
    days: {
      desc: 'Days',
      key: 'days',
    },
    business_days: {
      desc: 'Business Days',
      key: 'business_days',
    },
    months: {
      desc: 'Months',
      key: 'months',
    },
  },
  SUPPORTED_SCOPE: {
    insured_only: {
      desc: 'Insured only',
    },
    all_parties: {
      desc: 'All parties',
    },
  },
  SUPPORTED_ADDITIONAL_INITIAL_TRIGGER: {
    ssn_or_tax_id: {
      desc: 'SSN/Tax ID',
    },
    mobile_phone: {
      desc: 'Mobile phone number',
    },
    home_phone: {
      desc: 'Home phone number',
    },
    business_phone: {
      desc: 'Business phone number',
    },
    email: {
      desc: 'Email',
    },
    driver_license_number: {
      desc: 'Driver license number + issuing state',
    },
    vehicle_plate_number: {
      desc: 'Vehicle plate number + issuing state',
    },
    date_of_birth: {
      desc: 'Date of birth',
    },
    zip_code: {
      desc: 'Zip code',
    },
  },
  SUPPORTED_SEARCH_TRIGGERS: {
    any_required_fields_update: {
      desc: 'Any required field update',
    },
    party_added_or_updated: {
      desc: 'New party added or info updated ',
    },
    coverage_added: {
      desc: 'New coverage added',
    },
    ssn_or_tax_id_added_or_updated: {
      desc: 'SSN/Tax ID added/updated',
    },
    mobile_phone_added_or_updated: {
      desc: 'Mobile phone number added/updated',
    },
    home_phone_added_or_updated: {
      desc: 'Home phone number added/updated',
    },
    business_phone_added_or_updated: {
      desc: 'Business phone number added/updated ',
    },
    email_added_or_updated: {
      desc: 'Email added/updated',
    },
    driver_license_added_or_updated: {
      desc: 'Driver license number added/updated',
    },
    driver_license_issuing_state_added_or_updated: {
      desc: 'Driver license issuing state added/updated',
    },
    vehicle_plate_added_or_updated: {
      desc: 'Vehicle plate number added/updated',
    },
    vehicle_plate_issuing_state_added_or_updated: {
      desc: 'Vehicle plate Issuing state added/updated',
    },
    date_of_birth_added_or_updated: {
      desc: 'Date of birth added/updated',
    },
    zip_code_added_or_updated: {
      desc: 'Zip code added/updated',
    },
  },
  CLAIM_SEARCH_RESPONSE_RISK_INDICATORS: {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
  },
} as const;

export default CLAIM_SEARCH;
