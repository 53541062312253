import React from 'react';

import { Text } from '~/components/core';
import { SparkleV2 } from '~/components/icons';

export const ChatEmptyState: React.FC = () => (
  <div className="flex max-h-[200px] flex-col items-center justify-center gap-24 p-40">
    <Text>We are preparing the chat for you, this may take some time</Text>
    <SparkleV2 size={100} />
  </div>
);
