import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const MailBoxIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill={iconColor} disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 7.875C1.5 5.18261 3.68261 3 6.375 3C6.50118 3 6.62623 3.00479 6.75 3.01421V3H17.25C20.1495 3 22.5 5.35051 22.5 8.25V15H15V20.25C15 20.6642 14.6642 21 14.25 21C13.8358 21 13.5 20.6642 13.5 20.25V15H1.5V7.875ZM9.75 13.5V8.625V7.875C9.75 6.01104 8.23896 4.5 6.375 4.5C4.51104 4.5 3 6.01104 3 7.875V8.625V13.5H9.75ZM3 8.625C3 8.21079 3.33579 7.875 3.75 7.875H9C9.41421 7.875 9.75 8.21079 9.75 8.625C9.75 9.03921 9.41421 9.375 9 9.375H3.75C3.33579 9.375 3 9.03921 3 8.625ZM11.25 13.5H21V8.25C21 6.17893 19.3211 4.5 17.25 4.5H9.89282C10.7334 5.37592 11.25 6.56513 11.25 7.875V13.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);

MailBoxIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default MailBoxIcon;
