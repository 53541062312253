import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { MainCard, SecondaryCard } from '../../../core';
import { AlternativeDisputeResolutionForm } from '../../FormFragments/AlternativeDisputeResolutionForm';
import { NEW_LEGAL_ACTION_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { NewLegalActionValues } from '../../FormFragments/FormValuesTypes';
import { JurisdictionInformationForm } from '../../FormFragments/JurisdictionInformationForm';
import { LawsuitDetailsForm } from '../../FormFragments/LawsuitDetailsForm';
import { LegalActionsSummaryForm } from '../../FormFragments/LegalActionsSummaryForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import { PartyType } from '../../types';
import type { BaseDialogProps } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

import { DatesOfServiceFormCard } from './DatesOfServiceFormCard';
import { DisputeResolutionProfessionalFormCard } from './DisputeResolutionProfessionalFormCard';
import { PartiesFormCard } from './PartiesFormCard';

interface NewLegalActionDialogProps extends Omit<BaseDialogProps, 'onSubmitSuccess'> {
  onSubmitSuccess: (legalActionId: number) => void;
}

export const NewLegalActionDialog: React.FC<NewLegalActionDialogProps> = ({ onClose, onSubmitSuccess }) => {
  const backendRoutes = useLegalActionsBackendRoutes();

  const handleSubmit = async (values: NewLegalActionValues) => {
    try {
      const response = await axios.post(backendRoutes.legalActionsBase, values);
      const { id } = response.data;
      onSubmitSuccess(id);
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<NewLegalActionValues>
      title="Add Legal Action"
      maxWidth="md"
      onClose={onClose}
      formikConfig={{
        enableReinitialize: true,
        onSubmit: handleSubmit,
        initialValues: {
          is_claim_level: true,
          exposures: [],
          display_name: '',
          priority: '',
          lawsuit_amount: null,
          complaint_allegations_summary: '',
          jurisdiction_state: null,
          venue: null,
          court: null,
          date_filled_in_court: null,
          alternative_dispute_resolution_explored: null,
          alternative_dispute_resolution_text: null,
          early_case_resolution_explored: null,
          complaint_number: null,
          lawsuit_type: null,
          nature_of_dispute: null,
          nature_of_dispute_text: null,
          legal_action_plaintiffs: [],
          legal_action_defendants: [],
          dates_of_service: [],
          dispute_resolution_professionals: [],
        },
        validationSchema: Yup.object().shape(NEW_LEGAL_ACTION_VALIDATION_SCHEMA),
      }}
    >
      <div className="flex w-full flex-col gap-20">
        <MainCard type="contained" title="Legal Action Summary">
          <LegalActionsSummaryForm />
        </MainCard>
        <PartiesFormCard partyType={PartyType.PLAINTIFF} />
        <PartiesFormCard partyType={PartyType.DEFENDANT} />
        <MainCard type="contained" title="Lawsuit Information" collapsible openByDefault>
          <SecondaryCard type="contained" title="Lawsuit Details" collapsible>
            <LawsuitDetailsForm />
            <DatesOfServiceFormCard />
          </SecondaryCard>
          <SecondaryCard type="contained" title="Jurisdiction Information" collapsible>
            <JurisdictionInformationForm />
            <DisputeResolutionProfessionalFormCard />
          </SecondaryCard>
          <SecondaryCard type="contained" title="Alternative Resolution Initiative" collapsible>
            <AlternativeDisputeResolutionForm />
          </SecondaryCard>
        </MainCard>
      </div>
    </FormDialog>
  );
};
