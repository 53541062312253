import React from 'react';

import SvgIcon from '../core/SvgIcon';

const StarFullIcon = (props) => (
  <SvgIcon stroke="none" {...props} viewBox="0 0 16 16">
    <path d="M11.7 13.5C11.51 13.5 11.32 13.45 11.15 13.34L7.99999 11.34L5.05999 13.2C4.66999 13.44 4.17999 13.43 3.80999 13.16C3.42999 12.88 3.24999 12.4 3.36999 11.94L4.20999 8.62L1.36999 6.22C1.03999 5.95 0.909993 5.5 1.04999 5.09C1.17999 4.69 1.53999 4.41 1.95999 4.38L5.64999 4.14L7.04999 0.65C7.11999 0.47 7.23999 0.3 7.41999 0.18C7.75999 -0.06 8.24999 -0.06 8.58999 0.18C8.75999 0.3 8.88999 0.47 8.95999 0.67L10.33 4.12L14.04 4.38C14.46 4.41 14.82 4.69 14.95 5.09C15.08 5.5 14.95 5.94 14.63 6.22L11.81 8.58L12.71 12.21C12.82 12.63 12.66 13.06 12.31 13.31C12.13 13.44 11.92 13.51 11.71 13.51L11.7 13.5Z" />
  </SvgIcon>
);

export default StarFullIcon;
