import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Checkbox, InputLabel, Select } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { isEmpty, noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { FAILING_COLOR } from '~/components/exposures/ExposureUtils';

export const SelectMultipleSearchValues = ({
  id,
  label,
  setState,
  selectedValues,
  entitiesMap,
  disabled,
  showWarning = false,
  warningMessage = '',
  setWarning = noop,
  acceptedEntities = [],
  allowNone = false,
}) => {
  const classes = useStyles();
  const entities = isEmpty(acceptedEntities) ? Object.keys(entitiesMap)?.sort() : acceptedEntities;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', marginLeft: '20px' }}>
        <InputLabel id={id} className={classes.textFieldRow}>
          {label}
        </InputLabel>
        <Select
          id={id}
          onChange={(event) => {
            const value = event.target.value;
            if (value.length > 0) {
              setState(value);
              setWarning(false);
            }
          }}
          label={label}
          labelId={id}
          className={classes.textFieldRow}
          style={{ width: '300px' }}
          value={selectedValues}
          disabled={disabled}
          multiple
          autoWidth
          renderValue={(selectedValues) => selectedValues.map((key) => entitiesMap[key].desc).join(', ')}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            disableAutoFocus: true,
          }}
        >
          {entities?.map((key) => (
            <MenuItem key={key} value={key}>
              <Checkbox checked={selectedValues.indexOf(key) > -1} />
              <ListItemText primary={entitiesMap[key].desc} />
            </MenuItem>
          ))}
        </Select>
        {showWarning && <div style={{ color: FAILING_COLOR, marginLeft: '5px' }}>{warningMessage}</div>}
      </div>
      <Grid item>
        <ButtonGroup variant="text" size="small">
          <Button
            size="small"
            onClick={() => {
              setState(entities);
              setWarning(false);
            }}
          >
            All
          </Button>
          {!allowNone && (
            <Button
              size="small"
              onClick={() => {
                setState([]);
                setWarning(true);
              }}
            >
              None
            </Button>
          )}
        </ButtonGroup>
      </Grid>
    </div>
  );
};

SelectMultipleSearchValues.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  selectedValues: PropTypes.array.isRequired,
  entitiesMap: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  showWarning: PropTypes.bool,
  warningMessage: PropTypes.string,
  setWarning: PropTypes.func,
  acceptedEntities: PropTypes.arrayOf(PropTypes.string),
  allowNone: PropTypes.bool,
};
