import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Divider, Popover, Switch } from '@material-ui/core';
import axios from 'axios';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import EmailsDisplay from '~/components/core/EmailsDisplay/EmailsDisplay';
import { FeatureEnabled } from '~/components/core/FeatureFlagLayoutSwitch/FeatureFlagSwitch';
import { PropertyDetailsRow } from '~/components/core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../CardDialog';
import { Label, PropertyDetails, Text } from '../core';
import { useCms } from '../hooks/useCms';
import { LockedIcon, MinusCircleIcon, SignOutIcon } from '../icons';
import { ReminderIcon } from '../icons/notifications';
import OutOfOfficeDialog from '../OutOfOfficeDialog';
import { ShareNotificationsContainer } from '../ShareNotifications';
import useDataFetcher from '../useDataFetcher';

import colors from '../../assets/colors.module.scss';
import styles from './userMenu.module.scss';

const UserMenu = ({ onUpdatePassword, anchorEl, onClose }) => {
  const { user, userLogout, userReLogin, userProfile } = useCms();
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState();
  const [showOutOfOfficeDialog, setShowOutOfOfficeDialog] = useState(false);
  const [showShareNotifications, setShowShareNotifications] = useState(false);

  const {
    isLoading,
    isError,
    data: sharedUsersIds,
    reloadData: reloadSharedUsers,
  } = useDataFetcher('/api/v1/users/me/share_notifications');

  const isUserOutOfOffice = user.is_out_of_office;

  const handleToggleOutOfOffice = async (isOutOfOffice) => {
    setIsSubmitting(true);

    try {
      if (isOutOfOffice) {
        setShowOutOfOfficeDialog(true);
      } else {
        await axios.delete('/api/v1/users/me/out_of_office');
        await userReLogin();
      }
    } catch (error) {
      await reportAxiosError(error);
    }
    setIsSubmitting(false);
  };

  const handleSetOutOfOffice = async (values) => {
    try {
      await axios.post('/api/v1/users/me/out_of_office', values);
      await userReLogin();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleShareNotifications = async (shouldShare) => {
    setIsSubmitting(true);

    if (shouldShare) {
      setShowShareNotifications(true);
    } else {
      try {
        await axios.post('/api/v1/users/me/share_notifications', {
          share_notification_with_users_ids: [],
        });
        await reloadSharedUsers();
      } catch (error) {
        reportAxiosError(error);
      }
    }

    setIsSubmitting(false);
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <CardDialog
        width="350px"
        onClose={onClose}
        containerClassName={styles.userMenuContainer}
        contentStyle={{ paddingBottom: 0 }}
      >
        <Grid container className={styles.headerContainer}>
          <Grid item xs={2} className={styles.avatarContainer}>
            <Avatar
              src={`/api/v1/organizations/${user.organization_id}/users/${user.id}/avatar`}
              className={cn(styles.avatar, { [styles.outOfOffice]: isUserOutOfOffice })}
            >
              {user.first_name[0]}
              {user.last_name[0]}
            </Avatar>
          </Grid>
          <Grid item xs={10}>
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.MEDIUM}>
              {user.username}
            </Text>
            <EmailsDisplay
              email_addresses={user.claim_emails}
              colorVariant={EmailsDisplay.COLOR_VARIANTS.SECONDARY}
              variant={EmailsDisplay.VARIANTS.XXS}
            />
            {user.twilio_phone_number && <Label>{user.twilio_phone_number}</Label>}
          </Grid>
        </Grid>
        <div className="mt-6 mb-12 flex flex-col gap-16 rounded bg-blueSky-100 px-16 py-12">
          <FeatureEnabled featureFlag={CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT}>
            <PropertyDetails title="Your Permission Profile" text={userProfile?.display_name} />
          </FeatureEnabled>
          <FeatureEnabled featureFlag={CONFIGURATION_FEATURES_NAMES.ORG_HIERARCHY_WORKFLOWS}>
            <PropertyDetails title="Unit Member" text={user.member_of_unit?.name} />
            {!!user.leader_of_units.length && (
              <PropertyDetailsRow>
                <PropertyDetails title="Unit Leader" text={user.leader_of_units.map((unit) => unit.name)} useChips />
              </PropertyDetailsRow>
            )}
          </FeatureEnabled>
        </div>
        <div className={styles.rowContainer}>
          <div className={styles.iconWithTextContainer}>
            <MinusCircleIcon iconColor={colors.textPrimary} />
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              Out of Office
            </Text>
          </div>
          <Switch
            disabled={isSubmitting}
            checked={isUserOutOfOffice}
            onChange={(e, checked) => handleToggleOutOfOffice(checked)}
            className={classes.formsSwitch}
            size="small"
          />
        </div>

        <Divider />

        <div className={styles.rowContainer}>
          <div className={styles.iconWithTextContainer}>
            <ReminderIcon iconColor={colors.textPrimary} />
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              Share notifications
            </Text>
          </div>
          <Switch
            disabled={isSubmitting || isLoading || isError}
            checked={sharedUsersIds?.length > 0}
            onChange={(e, checked) => handleShareNotifications(checked)}
            className={classes.formsSwitch}
            size="small"
          />
        </div>

        {onUpdatePassword && (
          <MenuItem
            disabled={isSubmitting}
            onClick={() => {
              onUpdatePassword();
              onClose();
            }}
            className={styles.menuItem}
          >
            <div className={styles.iconWithTextContainer}>
              <LockedIcon />
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                Update Password
              </Text>
            </div>
          </MenuItem>
        )}

        <Divider />

        <MenuItem className={styles.menuItem} disabled={isSubmitting} onClick={userLogout}>
          <div className={styles.iconWithTextContainer}>
            <SignOutIcon />
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              Logout
            </Text>
          </div>
        </MenuItem>

        {showOutOfOfficeDialog && (
          <OutOfOfficeDialog
            user={user}
            onSubmit={handleSetOutOfOffice}
            onClose={() => {
              setIsSubmitting(false);
              setShowOutOfOfficeDialog(false);
            }}
            currentSharedUsersIds={sharedUsersIds || []}
          />
        )}
        {showShareNotifications && (
          <ShareNotificationsContainer
            onClose={() => setShowShareNotifications(false)}
            onUpdate={reloadSharedUsers}
            currentSharedUsersIds={sharedUsersIds}
          />
        )}
      </CardDialog>
    </Popover>
  );
};

UserMenu.propTypes = {
  onUpdatePassword: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default UserMenu;
