import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';

import { PreDefinedField, preparePreDefinedFields } from '../../../../IncidentConfiguration/ConfiguredFields';
import BodilyInjuriesFragment from '../AutoParties/BodilyInjuriesFragment';
import IcdCodesFragment from '../IcdCodesFragment';

import { GenericSectionInnerCard } from './InvolvedGenericFragment';

const LOSS_OBJECT_ID = 'bodily_injury_loss';

const BodilyInjuryFragment = ({ involvedParty, preDefinedFields }) => {
  const { values } = useFormikContext();
  const { incidentConfiguration } = useIncidentConfiguration();
  const isAllowedBodilyInjuries =
    involvedParty && incidentConfiguration.involved_parties[involvedParty]?.is_allowed_bodily_injury;
  const bodilyInjuryLossPredefinedFields = preparePreDefinedFields(get(preDefinedFields, LOSS_OBJECT_ID, {}));
  const fragmentId = `involved_parties.${involvedParty}.${LOSS_OBJECT_ID}`;
  const sectionTitle = get(incidentConfiguration, `${fragmentId}.desc`, 'Bodily Injury');
  const bodilyInjuriesId = `${LOSS_OBJECT_ID}.bodily_injuries`;
  const bodilyInjuriesConfigPath = `${fragmentId}.bodily_injuries`;
  const isSectionActive = get(incidentConfiguration, `${fragmentId}.active`, true);

  return (
    <GenericSectionInnerCard
      sectionTitle={sectionTitle}
      isSectionAllowed={isAllowedBodilyInjuries}
      isSectionActive={isSectionActive}
      preDefinedFields={preDefinedFields}
      enablingFieldId="is_injured"
      enablingValue={values?.is_injured}
      collapsibleTitle="Fill Bodily Injury Details"
    >
      <Grid container spacing={1}>
        <PreDefinedField
          id="was_ambulance_needed"
          fields={bodilyInjuryLossPredefinedFields}
          inline
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="was_hospitalized"
          fields={bodilyInjuryLossPredefinedFields}
          inline
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="hospital_contact_id"
          fields={bodilyInjuryLossPredefinedFields}
          inline
          gridXs={4}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="admission_datetime"
          fields={bodilyInjuryLossPredefinedFields}
          inline
          gridXs={4}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="discharge_datetime"
          fields={bodilyInjuryLossPredefinedFields}
          inline
          gridXs={4}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="legal_venue_state"
          fields={bodilyInjuryLossPredefinedFields}
          inline
          gridXs={4}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="legal_venue_reason"
          fields={bodilyInjuryLossPredefinedFields}
          inline
          gridXs={8}
          idPrefix={LOSS_OBJECT_ID}
        />
        <BodilyInjuriesFragment fieldId={bodilyInjuriesId} fieldConfigPath={bodilyInjuriesConfigPath} />
        <PreDefinedField
          id="injuries_description"
          fields={bodilyInjuryLossPredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
        <IcdCodesFragment showOnly={false} fields={get(preDefinedFields, LOSS_OBJECT_ID, {})} />
        <PreDefinedField id="note" fields={bodilyInjuryLossPredefinedFields} inline idPrefix={LOSS_OBJECT_ID} />
      </Grid>
    </GenericSectionInnerCard>
  );
};

BodilyInjuryFragment.propTypes = {
  involvedParty: PropTypes.string,
  preDefinedFields: PropTypes.object.isRequired,
};

export { BodilyInjuryFragment };
