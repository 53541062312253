import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Link } from '@material-ui/core';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import DotWithText from '~/components/core/DotWithText/DotWithText';

import { CLAIM_SEARCH_MIXPANEL_EVENTS, CLAIM_SEARCH_MIXPANEL_SOURCES } from '../../../pocs/mixpanel';
import { CLAIM_SEARCH } from '../../../Types';
import CardDialog from '../../CardDialog';
import { ControlFileClaimSummaryChip, SiuClaimSummaryChip } from '../../ClaimSummary';
import mixpanel from '../../CmsMain/mixpanel';
import { LoadingSwitch, TooltipIcon } from '../../core';
import { FlagIcon, LinkOutIcon } from '../../icons';
import IncidentMutualChips from '../../IncidentMutualChips';
import useDataFetcher from '../../useDataFetcher';

import DividerCell from './DividerCell';

import colors from '../../../assets/colors.module.scss';
import styles from './claimPageBar.module.scss';

const RISK_INDICATOR_TO_TEXT = {
  [CLAIM_SEARCH.CLAIM_SEARCH_RESPONSE_RISK_INDICATORS.HIGH]: 'High',
  [CLAIM_SEARCH.CLAIM_SEARCH_RESPONSE_RISK_INDICATORS.MEDIUM]: 'Medium',
  [CLAIM_SEARCH.CLAIM_SEARCH_RESPONSE_RISK_INDICATORS.LOW]: 'Low',
};

const RISK_INDICATOR_TO_COLOR = {
  [CLAIM_SEARCH.CLAIM_SEARCH_RESPONSE_RISK_INDICATORS.HIGH]: DotWithText.TYPES.ERROR,
  [CLAIM_SEARCH.CLAIM_SEARCH_RESPONSE_RISK_INDICATORS.MEDIUM]: DotWithText.TYPES.WARNING,
  [CLAIM_SEARCH.CLAIM_SEARCH_RESPONSE_RISK_INDICATORS.LOW]: DotWithText.TYPES.SUCCESS,
};

const EmptyComponent = () => null;

const ClaimAlerts = ({ claimSearchScoreRoute, claim }) => {
  const { data: claimScoreData, isLoading, isError } = useDataFetcher(claimSearchScoreRoute);

  const [showFlags, setShowFlags] = useState(false);

  const handleViewClick = (e) => {
    e.preventDefault();
    setShowFlags(true);

    mixpanel.track(CLAIM_SEARCH_MIXPANEL_EVENTS.VIEW_ALL_BUTTON_CLICKED);
  };

  const onHoverWarningIcon = () => {
    mixpanel.track(CLAIM_SEARCH_MIXPANEL_EVENTS.CLAIM_SEARCH_OUTDATED_TOOLTIP_PRESENTED);
  };

  const handleClaimSearchLinkClicked = () => {
    mixpanel.track(CLAIM_SEARCH_MIXPANEL_EVENTS.CLAIM_SEARCH_LINK_CLICKED, {
      source: CLAIM_SEARCH_MIXPANEL_SOURCES.TOP_CLAIM_PANEL,
    });
  };

  const handleViewClose = () => {
    setShowFlags(false);
  };

  const isSiu = claim.incident.is_siu;
  const isCat = claim.incident.is_cat;
  const isControlFile = claim.incident.is_control_file;
  const isActiveFlag = isSiu || isCat || isControlFile;

  const showWarning = !claimScoreData?.is_data_validated && claimScoreData?.risk_indicator;
  const showDot = !showWarning && (!claimScoreData?.last_updated || claimScoreData?.is_data_validated);
  return (
    <LoadingSwitch
      isLoading={isLoading}
      isError={isError}
      LoadingComponent={EmptyComponent}
      ErrorComponent={EmptyComponent}
    >
      <DividerCell />
      <Grid item className={styles.tableCell}>
        <div className={styles.headline}>Claim Alerts</div>
        <div className={styles.claimAlertsContentContainer}>
          <DotWithText
            showDot={showDot}
            showWarning={showWarning}
            iconTooltip={getIconTooltipText(showWarning, claimScoreData?.risk_indicator, claimScoreData?.last_updated)}
            dotType={
              claimScoreData?.risk_indicator
                ? RISK_INDICATOR_TO_COLOR[claimScoreData.risk_indicator]
                : DotWithText.TYPES.NO_INFO
            }
            primaryText={
              claimScoreData?.risk_indicator ? RISK_INDICATOR_TO_TEXT[claimScoreData.risk_indicator] : 'No info'
            }
            secondaryText={claimScoreData?.risk_director_score ? `(${claimScoreData.risk_director_score})` : null}
            iconElement={
              claimScoreData?.iso_file_link ? (
                <TooltipIcon title="Go to ClaimSearch">
                  <a
                    href={claimScoreData.iso_file_link}
                    className={styles.claimSearchLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkOutIcon size={16} iconColor={colors.linkIcon} onClick={handleClaimSearchLinkClicked} />
                  </a>
                </TooltipIcon>
              ) : null
            }
            onHoverWarningIcon={onHoverWarningIcon}
          />

          <Divider orientation="vertical" variant="middle" className={styles.innerDivider} />

          <div className={styles.flagAndViewContainer}>
            <TooltipIcon title={isActiveFlag ? 'Active Flags' : 'No Active Flags'}>
              <FlagIcon size={16} iconColor={isActiveFlag ? colors.error : colors.linkIcon} />

              {isActiveFlag && (
                <Link href="" className={styles.viewAllLink} onClick={handleViewClick}>
                  View
                </Link>
              )}
            </TooltipIcon>
          </div>
        </div>

        {showFlags && (
          <ViewFlagsDialog
            isSiu={isSiu}
            isCat={isCat}
            isControlFile={isControlFile}
            handleViewClose={handleViewClose}
            claim={claim}
          />
        )}
      </Grid>
    </LoadingSwitch>
  );
};

const getIconTooltipText = (isWarning, riskIndicator, lastReportedDate) => {
  if (isWarning) {
    return 'ClaimSearch updates are blocked. Complete missing data to resume updates';
  }
  if (!riskIndicator) {
    return 'Risk Level- No ClaimSearch Response';
  }
  if (lastReportedDate) {
    return `Risk Level (Last update: ${convertDate(lastReportedDate)}) View in ClaimSearch`;
  }
};

const convertDate = (reportedDate) => {
  const dateObj = new Date(reportedDate);

  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1; // Months are 0-11
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

ClaimAlerts.propTypes = {
  claimSearchScoreRoute: PropTypes.string.isRequired,
  claim: PropTypes.object,
};

const ViewFlagsDialog = ({ isSiu, isCat, handleViewClose, claim, isControlFile }) => {
  return (
    <CardDialog title="Flags" isDialog onClose={handleViewClose} maxWidth="sm" fullWidth>
      {isSiu && (
        <div>
          <Typography variant="subtitle1">SIU</Typography>
          <SiuClaimSummaryChip />
        </div>
      )}
      {isCat && (
        <div>
          <Typography variant="subtitle1">CAT</Typography>
          <IncidentMutualChips claim={claim} />
        </div>
      )}
      {isControlFile && (
        <div>
          <Typography variant="subtitle1">Control File</Typography>
          <ControlFileClaimSummaryChip />
        </div>
      )}
    </CardDialog>
  );
};

ViewFlagsDialog.propTypes = {
  isSiu: PropTypes.bool,
  isCat: PropTypes.bool,
  handleViewClose: PropTypes.func.isRequired,
  claim: PropTypes.object,
  isControlFile: PropTypes.bool,
};

export default ClaimAlerts;
