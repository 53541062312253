import React from 'react';
import PropTypes from 'prop-types';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import CallEndIcon from '@material-ui/icons/CallEnd';
import DialpadIcon from '@material-ui/icons/Dialpad';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import PauseIcon from '@material-ui/icons/Pause';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import TextFieldFormik from '~/components/TextFieldFormik';

import AdditionalConferenceParticipants from './AdditionalConferenceParticipants';
import PhoneCallRecordingToggle from './PhoneCallRecordingToggle';

const PhoneCallCommunicationSpecificBody = (props) => {
  const {
    communication,
    setCommunication,
    participantsDetails,
    callStatus,
    onHangup,
    onToggleMute,
    isOnHold,
    isUpdatingHoldStatus,
    onClickPutOnHold,
    onClickDialPad,
    isMuted,
    onClickAddParticipant,
    callDurationSec,
  } = props;
  const classes = useStyles();

  const seconds = callDurationSec % 60;
  const secString = seconds >= 10 ? String(seconds) : `0${seconds}`;
  const isCallActive = callStatus === 'open';

  return (
    <>
      <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography display="block" variant="subtitle2">
          {communication.extra.phone_number}
        </Typography>
      </div>
      <Typography display="block" variant="subtitle1" align="center">
        {`Call Duration: ${Math.floor(callDurationSec / 60)}:${secString}`}
      </Typography>
      {callStatus === 'closed' && (
        <>
          <Typography display="block" variant="subtitle1" align="center" style={{ color: '#e65100' }}>
            Call Ended
          </Typography>
          {communication.extra.phone_call_forwarded && (
            <Typography style={{ color: '#00217d' }} display="block">
              Call was forwarded to {communication.extra.phone_call_forwarded.type}{' '}
              {communication.extra.phone_call_forwarded.target_friendly_name}
            </Typography>
          )}
        </>
      )}
      {isCallActive && (
        <>
          <div
            className={classes.container}
            style={{ justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px' }}
          >
            <div style={{ justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px' }}>
              <Tooltip title={isMuted ? 'Unmute' : 'Mute'}>
                {isMuted ? (
                  <Button variant="contained" className={classes.ButtonInverseIcon} onClick={() => onToggleMute(false)}>
                    <MicOffIcon />
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" onClick={() => onToggleMute(true)}>
                    <MicIcon />
                  </Button>
                )}
              </Tooltip>
              <Tooltip title="Add Call Participant">
                <Button
                  className={classes.rightButtonIcon}
                  variant="contained"
                  color="primary"
                  onClick={onClickAddParticipant}
                  disabled={isUpdatingHoldStatus}
                >
                  <AddIcCallIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Dial Pad">
                <Button
                  className={classes.rightButtonIcon}
                  variant="contained"
                  color="primary"
                  onClick={onClickDialPad}
                  disabled={isUpdatingHoldStatus}
                >
                  <DialpadIcon />
                </Button>
              </Tooltip>
              <Tooltip title={isOnHold ? 'Remove Participants From Hold' : 'Put Participants On Hold'}>
                <Button
                  variant="contained"
                  className={
                    isOnHold && !isUpdatingHoldStatus ? classes.rightButtonInverseIcon : classes.rightButtonIcon
                  }
                  color={isOnHold ? '' : 'primary'}
                  disabled={isUpdatingHoldStatus}
                  onClick={onClickPutOnHold}
                >
                  <PauseIcon />
                </Button>
              </Tooltip>
            </div>
            <div style={{ paddingTop: '10px' }}>
              <Button variant="contained" style={{ backgroundColor: '#F44336' }} color="secondary" onClick={onHangup}>
                <CallEndIcon className={classes.leftButtonIcon} />
                Hang Up
              </Button>
            </div>
          </div>
          <div style={{ paddingTop: '10px' }}>
            <PhoneCallRecordingToggle communication={communication} setCommunication={setCommunication} />
          </div>
        </>
      )}

      <AdditionalConferenceParticipants
        isCallActive={isCallActive}
        participantsDetails={participantsDetails}
        communication={communication}
      />

      <TextFieldFormik id="content" label="Content" className={classes.textField} fullWidth multiline rows="5" />
    </>
  );
};

PhoneCallCommunicationSpecificBody.propTypes = {
  callStatus: PropTypes.string,
  onHangup: PropTypes.func.isRequired,
  onToggleMute: PropTypes.func.isRequired,
  onClickDialPad: PropTypes.func.isRequired,
  onClickAddParticipant: PropTypes.func.isRequired,
  communication: PropTypes.object.isRequired,
  setCommunication: PropTypes.func.isRequired,
  participantsDetails: PropTypes.array.isRequired,
  isMuted: PropTypes.bool,
  onClickPutOnHold: PropTypes.func.isRequired,
  isOnHold: PropTypes.bool,
  isUpdatingHoldStatus: PropTypes.bool,
  callDurationSec: PropTypes.number,
};

export default PhoneCallCommunicationSpecificBody;
