import React from 'react';
import PropTypes from 'prop-types';

import { useHasPermission } from '../../hooks/useHasPermission';

import PermissionDeniedTooltip from './PermissionDeniedTooltip';
import { useRestrictedPermissions } from './RestrictedPermissions';

const PermissionsButtonWrapper = ({ action, verb, children }) => {
  const { userHasContextPermissions } = useRestrictedPermissions();
  const userHasExplicitPermissions = useHasPermission({ action, verb });
  const shouldUseExplicitPermissions = action && verb;

  const isPermissionDenied = shouldUseExplicitPermissions ? !userHasExplicitPermissions : !userHasContextPermissions;

  if (!children) return null;

  if (Array.isArray(children) || !React.isValidElement(children)) {
    throw new Error('PermissionDeniedTooltip: button wrapper children should be a single node');
  }

  if (!isPermissionDenied) return children;

  const modifiedChild = React.cloneElement(children, {
    ...children.props,
    disabled: children.props.disabled || true,
  });

  return (
    <PermissionDeniedTooltip action={action} verb={verb}>
      <span>{modifiedChild}</span>
    </PermissionDeniedTooltip>
  );
};

PermissionsButtonWrapper.propTypes = {
  action: PropTypes.string,
  verb: PropTypes.string,
  children: PropTypes.node,
};

export default PermissionsButtonWrapper;
