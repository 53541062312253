import React from 'react';

import Chip from '~/components/core/Atomic/Chip/Chip';
import cn from '~/Utils/cn';

export const BetaTag: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <Chip
      className={cn(className, 'h-[23px] bg-teal-500 normal-case leading-normal')}
      classes={{ label: 'text-xs font-semibold text-teal-800' }}
      size="small"
      label="Beta"
    />
  );
};
