import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { CancelGeneralRecoveryDialog } from '~/components/exposures/GeneralRecoveryDialog';
import { useCms } from '~/components/hooks/useCms';

import { PAYMENT_MIXPANEL_EVENTS } from '../../../pocs/mixpanel';
import mixpanel from '../../CmsMain/mixpanel';
import CancelPaymentDialog from '../../exposures/CancelPaymentDialog';
import CancelRecoveryDialog from '../../exposures/CancelRecoveryDialog';
import { CancelIcon } from '../../icons';
import InlineIconButton from '../../InlineIconButton';

import { useStyles } from '../../../assets/styles';

const CancelTransactionActionContainer = ({
  isCancellable,
  recoveryAmountId,
  recoveryType,
  paymentRequestId,
  exposureFinanceMetadata,
  paymentType,
  mixpanelSource,
  claimId,
  isClaimClosed,
  onCancelTransaction = noop,
}) => {
  const classes = useStyles();
  const [isCancelPaymentDialogOpen, setIsCancelPaymentDialogOpen] = useState(false);
  const { user } = useCms();

  const isRecoveryTransaction = recoveryAmountId && recoveryType;

  const shouldShowCancelButton = isCancellable || user.role.is_view_only;

  const handleCancel = async () => {
    if (mixpanelSource) {
      mixpanel.track(PAYMENT_MIXPANEL_EVENTS.CANCEL_PAYMENT_SUBMITTED, {
        source: mixpanelSource,
      });
    }
    await onCancelTransaction();
    setIsCancelPaymentDialogOpen(false);
  };

  return (
    <>
      {shouldShowCancelButton && (
        <InlineIconButton
          icon={CancelIcon}
          className={classes.hoverableNonFilledIcon}
          onClick={() => setIsCancelPaymentDialogOpen(true)}
          wrapWithSpan
          tooltipTitle="Cancel payment"
        />
      )}
      {isCancelPaymentDialogOpen && !isRecoveryTransaction && (
        <CancelPaymentDialog
          open
          claimId={claimId}
          exposureFinanceMetadata={exposureFinanceMetadata}
          payableType={paymentType}
          paymentRequestId={paymentRequestId}
          onCancel={() => setIsCancelPaymentDialogOpen(false)}
          onUpdate={handleCancel}
          isClaimClosed={isClaimClosed}
        />
      )}
      {isCancelPaymentDialogOpen &&
        isRecoveryTransaction &&
        (recoveryType === 'general' ? (
          <CancelGeneralRecoveryDialog
            claimId={claimId}
            exposureIdOrNullIfGeneralExpenses={exposureFinanceMetadata.id}
            payableType={paymentType}
            recoveryAmountId={recoveryAmountId}
            onCancel={() => setIsCancelPaymentDialogOpen(false)}
            onUpdate={handleCancel}
          />
        ) : (
          <CancelRecoveryDialog
            claimId={claimId}
            exposureId={exposureFinanceMetadata.id}
            recoveryAmountId={recoveryAmountId}
            recoveryType={recoveryType}
            onCancel={() => setIsCancelPaymentDialogOpen(false)}
            onUpdate={handleCancel}
          />
        ))}
    </>
  );
};

CancelTransactionActionContainer.propTypes = {
  claimId: PropTypes.string.isRequired,
  isClaimClosed: PropTypes.bool.isRequired,
  isCancellable: PropTypes.bool,
  paymentRequestId: PropTypes.number,
  exposureFinanceMetadata: PropTypes.object,
  paymentType: PropTypes.string.isRequired,
  mixpanelSource: PropTypes.string,
  recoveryAmountId: PropTypes.number,
  recoveryType: PropTypes.string,
  onCancelTransaction: PropTypes.func,
};

export default CancelTransactionActionContainer;
