import React from 'react';
import PropTypes from 'prop-types';

import cn from '~/Utils/cn';

import { Sparkle } from '../../icons';
import { Text } from '../TextComponents';

const SIZE_VARIANTS = {
  XL: 'xl',
  LG: 'large',
  SM: 'small',
};

const AssistanceAiBanner = ({ title, subTitle, weight, size, iconSize = 32, hasBackground, className }) => (
  <div className={cn('inline-flex w-full items-center', hasBackground && 'mb-12 bg-slate-100 p-20', className)}>
    <Sparkle size={iconSize} />
    <Text
      className="pl-12 pr-4 text-pink-600"
      variant={size}
      colorVariant={Text.COLOR_VARIANTS.SECONDARY}
      weight={weight}
    >
      {title}
    </Text>
    {subTitle ? (
      <Text variant={size} colorVariant={Text.COLOR_VARIANTS.SECONDARY} weight={weight}>
        {subTitle}
      </Text>
    ) : null}
  </div>
);

AssistanceAiBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZE_VARIANTS)),
  iconSize: PropTypes.number,
  weight: PropTypes.oneOf(Object.values(Text.WEIGHTS)),
  hasBackground: PropTypes.bool,
  className: PropTypes.string,
};

AssistanceAiBanner.SIZE_VARIANTS = SIZE_VARIANTS;
AssistanceAiBanner.WEIGHTS = Text.WEIGHTS;

export default AssistanceAiBanner;
