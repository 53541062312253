import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { serverDateToLocal } from '../../DateTimeUtils';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { ControlFileClaimSummaryChip, LitigationClaimSummaryChip, SiuClaimSummaryChip } from '../ClaimSummary';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import HoverChangeField from '../HoverChangeField';
import IncidentMoreActionsContainer from '../IncidentMoreActionsContainer';
import IncidentMutualChips from '../IncidentMutualChips';
import IncidentTypeUpdateDialog from '../IncidentTypeUpdateDialog';
import LocationTextField from '../Location';
import { ShowOnlyTextField } from '../TextFieldFormik';

import CustomFields from './CustomFields';

import { useStyles } from '../../assets/styles';

const CustomIncidentDetailsCard = ({ readOnly }) => {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const [isFetching] = useState(false);
  const { incident } = claim;
  const disabled = readOnly || isFetching;

  const handleUpdateIncidentField = async (prefixPath, fieldValue) => {
    try {
      await axios.patch(`/api/v1/custom_claims/${claim.id}`, _.set({}, prefixPath, fieldValue));
      await onClaimUpdate();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleUpdateLitigationFields = async (values) => {
    const claimLitigationUrl = `/api/v1/claims/${claim.id}/litigation`;
    const method = !claim.is_in_first_party_litigation ? 'post' : 'put';
    try {
      await axios({ method, url: claimLitigationUrl, data: values });
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
    await onClaimUpdate();
  };

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          !readOnly && (
            <IncidentMoreActionsContainer
              incident={claim.incident}
              onUpdateFields={async (updatedObject) => await handleUpdateIncidentField('incident', updatedObject)}
              includeIsControlFile
              includeAdvancedFirstPartyLitigation
              onChangeIsInFirstPartyLitigation={handleUpdateLitigationFields}
            />
          )
        }
      >
        <IncidentMutualChips claim={claim} />
        {incident.is_control_file && <ControlFileClaimSummaryChip />}
        {claim.incident.is_siu && <SiuClaimSummaryChip />}
        {claim.is_in_first_party_litigation && !claim.is_litigation_closed && <LitigationClaimSummaryChip />}
        <Grid container alignItems="stretch" spacing={1}>
          <Grid item xs={12}>
            <CustomIncidentTypeAndSubtype
              onUpdate={async (updatedValues) => {
                await handleUpdateIncidentField('incident', updatedValues);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.reporter_contact_id}
              contactDisplayName={claim.reporter_contact_full_name}
              label="Reporter"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={serverDateToLocal(claim.creation_date)}
              label="Reported On"
              width="100%"
            />
          </Grid>
          <Grid item xs={12}>
            <LocationTextField
              classes={classes}
              label="Loss Location"
              location={claim.incident.loss_location}
              onChangeLocation={async (newLossLocation) =>
                await handleUpdateIncidentField('incident.loss_location', newLossLocation)
              }
              onEdit={() => {}}
              showOnly
              disabled={disabled}
              countriesConfigurationKey="loss_location"
            />
          </Grid>
          <Grid item xs={12}>
            <HoverChangeField
              name="were_police_involved"
              value={claim.incident.were_police_involved}
              label="Were the Police Involved?"
              specialFieldType="bool"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('incident.were_police_involved', updatedValues['were_police_involved'])
              }
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  claim.incident.were_police_involved
                    ? 'Yes'
                    : claim.incident.were_police_involved === false
                    ? 'No'
                    : ''
                }
                label="Were the Police Involved?"
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={12}>
            <CustomIncidentFields
              values={incident.additional_data}
              customFields={claim.custom_configuration.configuration.incident_details.additional_data}
            />
          </Grid>
          <Grid item xs={12}>
            <HoverChangeField
              name="special_internal_note"
              value={claim.special_internal_note}
              label="Internal Note"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('special_internal_note', updatedValues['special_internal_note'])
              }
              fullWidth
              multiline
              rows={3}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.special_internal_note}
                label="Internal Note"
                maxHeight="20em"
                width="100%"
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={12} style={{ height: '250px' }}>
            <HoverChangeField
              name="description"
              value={claim.incident.description}
              label="Description"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('incident.description', updatedValues['description'])
              }
              fullWidth
              multiline
              rows={5}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.description}
                label="Description"
                maxHeight="20em"
                width="100%"
              />
            </HoverChangeField>
          </Grid>
        </Grid>
      </CardDialog>
    </>
  );
};

CustomIncidentDetailsCard.propTypes = {
  readOnly: PropTypes.bool,
};

const CustomIncidentFields = ({ values, customFields }) => {
  const { claim, onClaimUpdate } = useClaim();

  const onFieldUpdate = async (fieldId, updatedValue) => {
    try {
      await axios.patch(`/api/v1/custom_claims/${claim.id}`, {
        incident: { additional_data: { [fieldId]: updatedValue } },
      });
      await onClaimUpdate();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  return <CustomFields customFields={customFields} values={values} onFieldUpdate={onFieldUpdate} />;
};

CustomIncidentFields.propTypes = {
  values: PropTypes.object.isRequired,
  customFields: PropTypes.array.isRequired,
};

const CustomIncidentTypeAndSubtype = ({ onUpdate }) => {
  const classes = useStyles();
  const { claim } = useClaim();

  const [updateIncidentTypeDialogOpen, setUpdateIncidentTypeDialogOpen] = useState(false);

  const typesConfig = claim.custom_configuration.configuration.incident_details.types;
  const typeConfig = typesConfig.find((t) => t.id === claim.incident.incident_type);
  const subtypeConfig = typeConfig ? typeConfig.subtypes.find((s) => s.id === claim.incident.incident_subtype) : null;

  // Converts the types/subtypes from the existing format to the format that IncidentTypeUpdateDialog expects
  // existing format: [{id: "type1", "desc": "Type 1", subtypes: [{id: "subtype1", "desc": "Subtype 1"}]}]
  // expected format: {"type1": {"desc": "Type 1", "sub_types": {"subtype1": {"desc": "Subtype 1"}}}}
  const mappedTypesConfig = _.chain(typesConfig)
    .keyBy('id')
    .mapValues((t) => {
      const copy = { ...t };
      delete copy.id;
      copy.sub_types = _.chain(copy.subtypes).keyBy('id').value();
      delete copy.subtypes;
      return copy;
    })
    .value();

  const onTypesUpdated = async (values) => {
    // sub_type -> subtype
    await onUpdate({ incident_type: values.incident_type, incident_subtype: values.incident_sub_type });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={typeConfig?.desc}
            label="Incident Type"
            onEdit={() => setUpdateIncidentTypeDialogOpen(true)}
          />
        </Grid>
        <Grid item xs={6}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={subtypeConfig?.desc}
            label="Incident Sub-type"
            onEdit={() => setUpdateIncidentTypeDialogOpen(true)}
          />
        </Grid>
      </Grid>
      {updateIncidentTypeDialogOpen && (
        <IncidentTypeUpdateDialog
          incidentTypesDict={mappedTypesConfig}
          incidentType={claim.incident.incident_type}
          incidentSubType={claim.incident.incident_subtype}
          onUpdate={onTypesUpdated}
          onClose={() => setUpdateIncidentTypeDialogOpen(false)}
        />
      )}
    </>
  );
};

CustomIncidentTypeAndSubtype.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};

export { CustomIncidentDetailsCard };
