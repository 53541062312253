import type { ReactNode } from 'react';
import React from 'react';
import noop from 'lodash/noop';

import { useStyles } from '~/assets/styles';
import cn from '~/Utils/cn';

import copies from '../copies.json';
import { FsButton } from '../FsWrappers';

const CONTAINED_PROP = { variant: 'contained' };

interface CancelButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  autoFocus?: boolean;
  contained?: boolean;
  content?: ReactNode;
  style?: React.CSSProperties;
  withMarginRight?: boolean;
  withoutBorder?: boolean;
  classNameOverride?: string;
  ignorePermissions?: boolean;
}

const cancelButtonDefaultProps: Partial<CancelButtonProps> = {
  onClick: noop,
  disabled: false,
  autoFocus: false,
  contained: false,
  content: copies.cancel,
  withMarginRight: true,
  withoutBorder: false,
  style: {},
  ignorePermissions: false,
};

const CancelButton: React.FC<CancelButtonProps> = ({
  onClick,
  disabled,
  autoFocus,
  contained,
  content,
  withMarginRight,
  withoutBorder,
  style,
  classNameOverride,
  ignorePermissions,
}) => {
  const classes = useStyles();
  const className = cn(classes.cancelButton, classNameOverride, { withMarginRight, withoutBorder });
  const rest = contained ? CONTAINED_PROP : {};

  return (
    <FsButton
      className={className}
      onClick={onClick}
      disabled={disabled}
      autoFocus={autoFocus}
      style={style}
      ignorePermissions={ignorePermissions}
      {...rest}
    >
      {content}
    </FsButton>
  );
};

CancelButton.defaultProps = cancelButtonDefaultProps;

export default CancelButton;
