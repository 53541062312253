import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import CardDialog from '../../../../CardDialog';
import { MultiSelectFilter } from '../../../../core';
import { useCms } from '../../../../hooks/useCms';

import { useStyles } from '../../../../../assets/styles';
import styles from '../../financeApprovals.module.scss';

const PAYABLE_TYPES = {
  indemnity: 'Indemnity',
  expenses: 'Expenses',
};

const FILTER_KEYS = {
  payable_types_filter: 'payable_types_filter',
  requester_supervisor_user_id: 'requester_supervisor_user_id',
};

const Filters = ({ filters, onChange }) => {
  const classes = useStyles();
  const { user } = useCms();
  const isRequestedToMe = !!filters[FILTER_KEYS.requester_supervisor_user_id];

  return (
    <CardDialog noCardTitle>
      <div>
        <Typography display="block" variant="subtitle1">
          Filters
        </Typography>
        <Grid container className={styles.filtersContainer} spacing={1}>
          <Grid item>
            <MultiSelectFilter
              label="Payable type"
              value={filters[FILTER_KEYS.payable_types_filter] || []}
              onChange={(paymentFilters) => onChange(FILTER_KEYS.payable_types_filter, paymentFilters)}
              options={Object.keys(PAYABLE_TYPES)}
              renderOption={(value) => PAYABLE_TYPES[value]}
              withOptionChips
            />
          </Grid>
          {(user.is_org_level_supervisor || user.is_manager) && (
            <Grid item xs={3} className={styles.filterRequestToMe}>
              <div className={styles.switchContainer}>
                <Switch
                  checked={isRequestedToMe}
                  className={classes.formsSwitch}
                  size="small"
                  onChange={() => onChange(FILTER_KEYS.requester_supervisor_user_id, isRequestedToMe ? null : user.id)}
                />
                <span>Requested to me</span>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </CardDialog>
  );
};

Filters.propTypes = {
  filters: PropTypes.shape({
    [FILTER_KEYS.payable_types_filter]: PropTypes.arrayOf(PropTypes.string),
    [FILTER_KEYS.requester_supervisor_user_id]: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default Filters;
