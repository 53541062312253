import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { NEGOTIATION_LOG_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { LegalActionNegotiationLogFormValues } from '../../FormFragments/FormValuesTypes';
import { NegotiationLogForm } from '../../FormFragments/NegotiationLogForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { NegotiationLogApiResponse } from '../../types';
import type { DialogPropsWithLog } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const EditNegotiationLogDialog: React.FC<DialogPropsWithLog<NegotiationLogApiResponse>> = ({
  onClose,
  onSubmitSuccess,
  legalAction,
  log,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: LegalActionNegotiationLogFormValues) => {
    try {
      await axios.put(backendRoutes.legalAction.logs.negotiation(log.id), values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<LegalActionNegotiationLogFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: pick(log, ['date', 'adverse_party_demand', 'notes', 'offer', 'adverse_party_contact_id']),
        validationSchema: Yup.object().shape(NEGOTIATION_LOG_VALIDATION_SCHEMA),
      }}
      title="Edit Negotiation Log"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <NegotiationLogForm />
      </div>
    </FormDialog>
  );
};
