import React, { useState } from 'react';
import { Switch } from '@material-ui/core';
import axios from 'axios';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import { useCms } from '../../../hooks/useCms';
import LoadingIndicator from '../../../LoadingIndicator';
import ApprovalFinances from '../ApprovalsFinances';

import CurrentBalances from './CurrentBalances';
import Filters, { FILTER_KEYS } from './Filters';

import { useStyles } from '../../../../assets/styles';
import styles from '../financeApprovals.module.scss';

const PaymentSubmissions = () => {
  const { userOrganization } = useCms();
  const [filters, setFilters] = useState({
    [FILTER_KEYS.payment_method]: 'commerce_bank',
    [FILTER_KEYS.payable_types_filter]: [],
    [FILTER_KEYS.sub_organization_id]: '',
  });
  const [page, setPage] = useState(0);
  const [isLoadingBalances, setIsLoadingBalances] = useState(false);
  const [isErrorBalances, setIsErrorBalances] = useState(null);
  const [isLoadingFinances, setIsLoadingFinances] = useState(false);
  const [isErrorFinances, setIsErrorFinances] = useState(null);
  const [statuses] = useState(['in_progress']);
  const [selectPaymentsEnabled, setSelectPaymentsEnabled] = useState(false);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isBalancesReloadNeeded, setIsBalancesReloadNeeded] = useState(false);
  const [isFinancesReloadNeeded, setIsFinancesReloadNeeded] = useState(false);

  const isLoading = isLoadingBalances || isLoadingFinances;
  const isError = isErrorBalances || isErrorFinances;

  const classes = useStyles();

  const handleChange = (key, values) => {
    setFilters((prevState) => ({ ...prevState, [key]: values }));
    setPage(0);
    // setSelectedPayments([]);
  };

  const handleUpdatedSelectedPayments = (payments) => {
    setSelectedPayments(payments);
  };

  const handleSelectPaymentsEnabledChanged = (newValue) => {
    setSelectPaymentsEnabled(newValue);
    setSelectedPayments([]);
  };

  const refreshPage = () => {
    setPage(0);
    setIsFinancesReloadNeeded(true);
    setIsBalancesReloadNeeded(true);
  };

  const handleSubmitPayments = async () => {
    setIsSubmitting(true);
    try {
      const paymentIds = selectedPayments.map((p) => p.id);
      await axios.post(`/api/v1/organizations/${userOrganization.id}/payment_requests/submit`, paymentIds);
      refreshPage();
    } catch (error) {
      await reportAxiosError(error);
    }
    setIsSubmitting(false);
    handleSelectPaymentsEnabledChanged(false);
  };

  return (
    <CardDialog noCardTitle>
      <Grid container className={styles.paymentApprovalsContainer}>
        <Grid item xs={12} className={styles.enableSwitch}>
          <Switch
            disabled={isLoading || isError || isSubmitting}
            checked={selectPaymentsEnabled}
            className={`${classes.formsSwitch} ${''}`}
            id="select_payments_enabled"
            onChange={() => handleSelectPaymentsEnabledChanged(!selectPaymentsEnabled)}
          />
          Enabled select payments
        </Grid>
        <Grid item xs={12}>
          <Filters filters={filters} onChange={handleChange} disabled={isLoading || isError} />
        </Grid>
        <Grid item xs={12}>
          <CurrentBalances
            setIsLoading={setIsLoadingBalances}
            setIsError={setIsErrorBalances}
            filters={filters}
            selectedPayments={selectedPayments}
            shouldReload={isBalancesReloadNeeded}
            onReload={() => setIsBalancesReloadNeeded(false)}
          />
        </Grid>
        <Grid item xs={12}>
          <ApprovalFinances
            filters={filters}
            statuses={statuses}
            page={page}
            setPage={setPage}
            onIsLoadingChanged={setIsLoadingFinances}
            onIsErrorChanged={setIsErrorFinances}
            enableSelection={selectPaymentsEnabled}
            selectedFinances={selectedPayments}
            onUpdatedSelectedFinances={handleUpdatedSelectedPayments}
            shouldReload={isFinancesReloadNeeded}
            onReload={() => setIsFinancesReloadNeeded(false)}
          />
        </Grid>
        <Grid item xs={12}>
          {(isLoading || isError) && <LoadingIndicator isError={isError} />}
        </Grid>
      </Grid>
      {selectPaymentsEnabled && (
        <div className={classes.buttonsContainer}>
          <CancelButton
            disabled={isSubmitting}
            onClick={() => {
              handleSelectPaymentsEnabledChanged(false);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting || _.isEmpty(selectedPayments)}
            onClick={handleSubmitPayments}
          >
            SEND PAYMENTS
          </Button>
        </div>
      )}
    </CardDialog>
  );
};

export default PaymentSubmissions;
