import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const OshaReportsIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3.75C4.5 2.92157 5.17157 2.25 6 2.25H18C18.8284 2.25 19.5 2.92157 19.5 3.75V20.25C19.5 21.0784 18.8284 21.75 18 21.75H15.375C14.9608 21.75 14.625 21.4142 14.625 21C14.625 20.5858 14.9608 20.25 15.375 20.25H18V3.75H6V20.25H8.625C9.03921 20.25 9.375 20.5858 9.375 21C9.375 21.4142 9.03921 21.75 8.625 21.75H6C5.17157 21.75 4.5 21.0784 4.5 20.25V3.75ZM8.25 6.75C8.25 6.33579 8.58579 6 9 6H15C15.4142 6 15.75 6.33579 15.75 6.75C15.75 7.16421 15.4142 7.5 15 7.5H9C8.58579 7.5 8.25 7.16421 8.25 6.75ZM8.25 9.75C8.25 9.33579 8.58579 9 9 9H15C15.4142 9 15.75 9.33579 15.75 9.75C15.75 10.1642 15.4142 10.5 15 10.5H9C8.58579 10.5 8.25 10.1642 8.25 9.75ZM15.6001 14.5502C15.8486 14.8816 15.7813 15.3517 15.4498 15.6001L11.4476 18.6001C11.1808 18.8001 10.814 18.7999 10.5474 18.5998L8.54967 17.0998C8.21844 16.851 8.15154 16.3809 8.40025 16.0497C8.64895 15.7184 9.11909 15.6515 9.45033 15.9002L10.9981 17.0624L14.5502 14.3999C14.8816 14.1514 15.3517 14.2187 15.6001 14.5502Z"
      fill={iconColor}
    />
  </SvgIcon>
);

OshaReportsIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default OshaReportsIcon;
