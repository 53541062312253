const CPT_CODES_PLACE_OF_SERVICES = {
  telehealth_in_patient_home: {
    code: 10,
    desc: "TELEHEALTH PROVIDED IN PATIENT'S HOME",
  },
  doctor_office: {
    code: 11,
    desc: "DOCTOR'S OFFICE",
  },
  home: {
    code: 12,
    desc: 'HOME',
  },
  assisted_living_facility: {
    code: 13,
    desc: 'ASSISTED LIVING FACILITY',
  },
  group_home: {
    code: 14,
    desc: 'GROUP HOME',
  },
  mobile_unit: {
    code: 15,
    desc: 'MOBILE UNIT',
  },
  employment_worksite: {
    code: 18,
    desc: 'PLACE OF EMPLOYMENT-WORKSITE',
  },
  off_campus_outpatient_hospital: {
    code: 19,
    desc: 'OFF CAMPUS-OUTPATIENT HOSPITAL',
  },
  urgent_care_facility: {
    code: 20,
    desc: 'URGENT CARE FACILITY',
  },
  inpatien_hospital: {
    code: 21,
    desc: 'INPATIENT HOSPITAL',
  },
  on_campus_outpatient_hospital: {
    code: 22,
    desc: 'ON CAMPUS OUTPATIENT HOSPITAL',
  },
  emergency_room_hospital: {
    code: 23,
    desc: 'EMERGENCY ROOM - HOSPITAL',
  },
  ambulatory_surgical_center: {
    code: 24,
    desc: 'AMBULATORY SURGICAL CENTER',
  },
  skilled_nursing_facility: {
    code: 31,
    desc: 'SKILLED NURSING FACILITY',
  },
  hospice: {
    code: 34,
    desc: 'HOSPICE',
  },
  ambulance_land: {
    code: 41,
    desc: 'AMBULANCE - LAND',
  },
  ambulance_air_or_water: {
    code: 42,
    desc: 'AMBULANCE - AIR OR WATER',
  },
  independent_clinic: {
    code: 49,
    desc: 'INDEPENDENT CLINIC',
  },
  inpatient_psychiatric_facility: {
    code: 51,
    desc: 'INPATIENT PSYCHIATRIC FACILITY',
  },
  psychiatric_facility_partial_hospitalization: {
    code: 52,
    desc: 'PSYCHIATRIC FACILITY PARTIAL HOSPITALIZATION',
  },
  community_mental_health_center: {
    code: 53,
    desc: 'COMMUNITY MENTAL HEALTH CENTER',
  },
  comprehensive_inpatient_rehabilitation_center: {
    code: 61,
    desc: 'COMPREHENSIVE INPATIENT REHABILITATION CENTER',
  },
  comprehensive_outpatient_rehabilitation_center: {
    code: 62,
    desc: 'COMPREHENSIVE OUTPATIENT REHABILITATION CENTER',
  },
  rural_health_clinic: {
    code: 72,
    desc: 'RURAL HEALTH CLINIC',
  },
  independent_laboratory: {
    code: 81,
    desc: 'INDEPENDENT LABORATORY',
  },
  clinical_lab: {
    code: 82,
    desc: 'CLINICAL LAB',
  },
  diagnostic_testing_facility: {
    code: 83,
    desc: 'DIAGNOSTIC TESTING FACILITY',
  },
  out_patient_facility_psy: {
    code: 84,
    desc: 'OUT PATIENT FACILITY (PSY)',
  },
  physical_therapy_clinic: {
    code: 85,
    desc: 'PHYSICAL THERAPY CLINIC',
  },
  residential_treatment_center: {
    code: 86,
    desc: 'RESIDENTIAL TREATMENT CENTER',
  },
  specialized_treatment_facility: {
    code: 87,
    desc: 'SPECIALIZED TREATMENT FACILITY',
  },
  transportation_non_er: {
    code: 88,
    desc: 'TRANSPORTATION NON-ER',
  },
  other_locations: {
    code: 99,
    desc: 'OTHER LOCATIONS',
  },
} as const;

export default CPT_CODES_PLACE_OF_SERVICES;
