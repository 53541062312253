import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';

import { reportAxiosError } from '~/Utils';

import { useClaim } from '../ClaimContainer';
import SendEmailCommunicationCardContainer from '../communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import useOrganization from '../OrganizationContext';

import ExposureTooltipedLock from './ExposureTooltipedLock';
import { isExposureWriteDisabled } from './ExposureUtils';
import MoiCard, { doesMoiMethodRequiresEmail, isVendorWithNoNeedForEmail, moiMethodToString } from './MoiCard';

function moiToString(exposure, moi, claim, moiOptions) {
  const title = moiMethodToString(moi, claim, moiOptions);
  return title || moi.custom_title || moi.method;
}

function MoiContainer(props) {
  const { exposure } = props;

  const [openMoiCard, setOpenMoiCard] = useState(false);
  const [selectNewMoi, setSelectNewMoi] = useState(false);

  const getEmptyEmailPreviewState = () => {
    return { sendEmailOpen: false, emailTextAndTitle: null, emailContact: null };
  };

  const [emailPreviewState, setEmailPreviewState] = useState(getEmptyEmailPreviewState());

  const { sendEmailOpen, emailTextAndTitle, emailContact, moiId } = emailPreviewState;
  const { claim, onClaimUpdate } = useClaim();
  const { moiOptions } = useOrganization();
  const { user } = useCms();

  const moi =
    exposure?.methods_of_inspection && exposure?.methods_of_inspection[exposure.methods_of_inspection.length - 1];
  //this component only supports the old MOI experience
  const readOnly = isExposureWriteDisabled(exposure, user) || exposure.is_coverage_issue_exists;

  if (!moi && readOnly) {
    return <ExposureTooltipedLock exposure={exposure} />;
  }

  const handleClosePopover = () => {
    setOpenMoiCard(false);
    setSelectNewMoi(false);
  };

  const handleSubmitMoi = async (moiValues) => {
    try {
      const moiRes = await axios.post(
        `/api/v1/claims/${claim.id}/exposures/${exposure.id}/method_of_inspection`,
        moiValues
      );
      const moi = moiRes.data;

      let contact;
      if (doesMoiMethodRequiresEmail(moiValues.method, moiOptions, claim.type)) {
        contact = moiValues.manual_vendor;
      }

      if (contact && contact.emails.length > 0 && !isVendorWithNoNeedForEmail(contact, claim)) {
        const res = await axios.get(
          `/api/v1/claims/${claim.id}/exposures/${exposure.id}/method_of_inspection/${moi.id}/assignment_email_text`
        );
        setEmailPreviewState({
          emailTextAndTitle: res.data,
          emailContact: contact,
          sendEmailOpen: true,
          moiId: moi.id,
        });
      } else {
        await onClaimUpdate();
      }

      handleClosePopover();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const connectMoiCommunication = async (moiId, communicationId) => {
    try {
      await axios.post(
        `/api/v1/claims/${claim.id}/exposures/${exposure.id}/method_of_inspection/${moiId}/connect_communication`,
        { communication_id: communicationId }
      );
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const permanentCTA = exposure.coverage_decision && !moi; // only if coverage decision was made but MOI decision not

  let exposuresToSuggest = [];

  if (claim.type === 'home_claim') {
    if (exposure.coverage_type === 'coverage_a') {
      const exposureToSuggest = claim.exposures.find((exp) => exp.coverage_type === 'coverage_b');
      if (exposureToSuggest) {
        exposuresToSuggest.push(exposureToSuggest);
      }
    }

    if (exposure.coverage_type === 'coverage_b') {
      const exposureToSuggest = claim.exposures.find((exp) => exp.coverage_type === 'coverage_a');
      if (exposureToSuggest) {
        exposuresToSuggest.push(exposureToSuggest);
      }
    }
  }

  return (
    <>
      {readOnly ? (
        moi && (
          <HoverActionField
            icon={InfoIcon}
            onAction={() => setOpenMoiCard(true)}
            actionAriaLabel="Set method of inspection"
          >
            {moiToString(exposure, moi, claim, moiOptions)}
          </HoverActionField>
        )
      ) : (
        <HoverActionField onAction={() => setOpenMoiCard(true)} permanent={permanentCTA}>
          {!moi ? permanentCTA ? <em>Decide</em> : '' : moiToString(exposure, moi, claim, moiOptions)}
        </HoverActionField>
      )}

      {openMoiCard && (
        <MoiCard
          cardDialogProps={{
            isDialog: true,
            open: openMoiCard,
            closeOnBackdropClick: moi && !selectNewMoi,
          }}
          key={selectNewMoi} // The size of the popover is different, depends if its viewing or choosing. we want render again if it changes
          moi={selectNewMoi ? undefined : moi}
          exposure={exposure}
          exposuresToSuggest={exposuresToSuggest}
          onCancel={handleClosePopover}
          onSelectMoi={(values) => handleSubmitMoi(values)}
          readOnly={readOnly}
          onSelectNewMoi={() => setSelectNewMoi(true)}
        />
      )}

      {sendEmailOpen && (
        <SendEmailCommunicationCardContainer
          contact={emailContact}
          onClose={async () => {
            await onClaimUpdate();
            setEmailPreviewState(getEmptyEmailPreviewState());
          }}
          onSendEmail={async (communication) => {
            await connectMoiCommunication(moiId, communication.id);
            await onClaimUpdate();
            setEmailPreviewState(getEmptyEmailPreviewState());
          }}
          emailTitle={emailTextAndTitle.email_title}
          emailText={emailTextAndTitle.email_body}
          summary={`Vendor ${emailContact.full_name} was assigned to assess damages`}
          exposureIds={[exposure.id]}
        />
      )}
    </>
  );
}

MoiContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export { moiToString };
export default MoiContainer;
