import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ThreeDotsHorizontalIcon = ({ ...props }) => (
  <SvgIcon fill="none" {...props}>
    <circle cx="2.75" cy="8" r="0.5" transform="rotate(90 2.75 8)" />
    <circle cx="7.75" cy="8" r="0.5" transform="rotate(90 7.75 8)" />
    <circle cx="12.75" cy="8" r="0.5" transform="rotate(90 12.75 8)" />
  </SvgIcon>
);

export default ThreeDotsHorizontalIcon;
