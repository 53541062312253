import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import { isoDateToUs, timeToLocalTime } from '../../DateTimeUtils';
import { CLAIM_TYPES_CONFIGURATION } from '../../Types';
import { isUserFiveSigma } from '../../UserUtils';
import { isMarshmallowPolicy } from '../../Utils';
import { ContactEntity } from '../Contact';
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';

import { useStyles } from '../../assets/styles';

function FnolHeader({ policy, subOrganizationId, claimType }) {
  const { user } = useCms();
  const { subOrganizations } = useOrganization();
  const classes = useStyles();

  const subOrganizationLabel = () => {
    const subOrganization = subOrganizationId
      ? subOrganizations.find((subOrganization) => subOrganization.id === subOrganizationId)
      : null;

    return subOrganization ? (
      <TableCell className={classes.tableCell}>
        <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
          <strong>Sub-Organization: </strong>
          <span style={{ marginLeft: '8px' }}>{subOrganization.name}</span>
        </span>
      </TableCell>
    ) : (
      <></>
    );
  };

  const lineOfBusinessLabel = () => {
    const lob = claimType ? CLAIM_TYPES_CONFIGURATION[claimType]?.desc ?? claimType : null;
    return lob ? (
      <TableCell className={classes.tableCell}>
        <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
          <strong>LOB: </strong>
          <span style={{ marginLeft: '8px' }}>{lob}</span>
        </span>
      </TableCell>
    ) : (
      <></>
    );
  };

  return (
    <Table>
      <TableBody>
        <TableRow className={classes.fnolPageBarTableRow}>
          {policy.is_manual ? (
            <TableCell className={classes.tableCell}>
              <span style={{ color: 'red' }}>
                <strong>Policy Not Found</strong>
              </span>
            </TableCell>
          ) : (
            <TableCell className={classes.tableCell}>
              <strong>Policy #: </strong>
              {policy.policy_number}
            </TableCell>
          )}
          {!policy.is_manual && isMarshmallowPolicy(policy) && (
            <TableCell className={classes.tableCell}>
              <strong>Package: </strong>
              {policy.policy_type}
            </TableCell>
          )}
          <TableCell className={classes.tableCell}>
            <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
              <strong>Named Insured: </strong>
              <ContactEntity
                classes={classes}
                contactId={policy.insured_contact.id}
                contactDisplayName={policy.insured_contact.full_name}
              />
            </span>
          </TableCell>
          <>
            {subOrganizationLabel()}
            {lineOfBusinessLabel()}
          </>
          {!policy.is_manual && (
            <>
              <TableCell className={classes.tableCell}>
                <strong>Effective Date: </strong>
                {isoDateToUs(policy.policy_effective_date) + ' ' + timeToLocalTime(policy.effective_time)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <strong>Expiration Date: </strong>
                {isoDateToUs(policy.policy_expiration_date) + ' ' + timeToLocalTime(policy.expiration_time)}
              </TableCell>
            </>
          )}
          {isUserFiveSigma(user) && <TableCell className={classes.tableCell}>{policy.client}</TableCell>}
        </TableRow>
      </TableBody>
    </Table>
  );
}

FnolHeader.propTypes = {
  policy: PropTypes.object.isRequired,
  subOrganizationId: PropTypes.number,
  claimType: PropTypes.string,
};

export default FnolHeader;
