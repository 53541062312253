const theme = {
  spacing: 6,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1366,
      xl: 1920,
    },
  },
  props: {
    MuiPaper: {
      elevation: 0,
    },
    MuiCard: {
      elevation: 0,
    },
  },
  palette: {
    background: {
      default: '#F8F8F8',
    },
    primary: {
      main: '#305a7f', // TODO - update secondary
      darker: '#1A9C9E',
    },
    secondary: {
      main: '#c00b4b',
    },
    warning: {
      main: '#FFBB00',
    },
  },
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
        fontWeight: 600,
      },
    },
    MuiTab: {
      wrapper: {
        padding: '10px 35px',
        lineHeight: '24px',
      },
      root: {
        fontSize: 16,
        fontWeight: 'normal',
        color: '#606060',
        textTransform: 'none',
        '&.Mui-selected': {
          fontWeight: 'bold',
          borderBottom: '1px solid #305a7f',
        },
      },
      textColorInherit: {
        minWidth: '220px',
        opacity: 1,
        fontWeight: 400,
        fontSize: '16px',
        marginLeft: '24px',
        '&.Mui-selected': {
          backgroundColor: '#FFFFFF',
          color: '#202020',
          textTransform: 'none',
          fontWeight: 600,
          borderTop: '5px solid #32C7CA',
          borderBottom: 'none',
          borderRadius: '5px 5px 0px 0px',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '20px',
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#d6dee1',
          borderRadius: '20px',
          border: '6px solid transparent',
          backgroundClip: 'content-box',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#a8bbbf',
        },
        body: {
          fontFamily: 'Poppins',
          fontSize: 14,
          letterSpacing: '-0.3px',
        },
      },
    },
    MuiButton: {
      root: {
        height: '32px',
      },
      textPrimary: {
        color: '#1A9C9E',
      },
      label: {
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '-0.3px',
        lineHeight: '23px',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 13,
        height: '50px',
      },
      head: {
        height: '50px',
        lineHeight: '18px',
      },
    },
    MuiPickersTimePickerToolbar: {
      hourMinuteLabel: {
        alignItems: 'center',
      },
    },
    MuiFormHelperText: {
      root: {
        opacity: 1,
        fontWeight: 400,
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '15px',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '6px',
      },
    },
  },
  MuiTextField: {
    root: {
      marginTop: 5,
      marginBottom: 5,
    },
  },
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(','),
    h6: {
      color: '#202020',
      fontSize: '18px',
      letterSpacing: '-0.4px',
      lineHeight: '30px',
      fontWeight: 600,
    },
    h5: {
      color: '#202020',
    },
    subtitle1: {
      fontWeight: 600,
      letterSpacing: '-0.3px',
      lineHeight: '24px',
      color: '#202020',
    },
    body1: {
      color: '#202020',
      fontSize: 14,
    },
    body2: {
      color: '#202020',
      fontSize: 13,
    },
    caption: {
      fontWeight: 600,
      opacity: 0.5,
      letterSpacing: '-0.26px',
      lineHeight: '18px',
      color: '#202020',
    },
  },
};

export default theme;
