import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Button from '~/components/core/Atomic/Buttons/Button';
import EmptyState from '~/components/core/EmptyState';
import { removeLastSegment } from '~/components/ReportsPage/helpers/ReportsUtils';

export const MissingTableauLicenseMessage: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const onBackClick = React.useCallback(() => {
    history.push(removeLastSegment(match.url));
  }, [history, match]);

  return (
    <EmptyState
      title="You do not have permission to access the Report Builder"
      subtitle={
        <div className="px-1/12 flex w-1/2 flex-col">
          <span>
            You need a Tableau Explorer license to access the Report Builder. To upgrade your Tableau account, please
            contact your Admin.
          </span>
        </div>
      }
      buttonComponent={
        <Button onClick={onBackClick} variant="outlined">
          Go Back
        </Button>
      }
    />
  );
};
