import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const TextAlignCenterIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M3.23438 6C3.23438 5.80109 3.31339 5.61032 3.45405 5.46967C3.5947 5.32902 3.78546 5.25 3.98438 5.25H20.4844C20.6833 5.25 20.8741 5.32902 21.0147 5.46967C21.1554 5.61032 21.2344 5.80109 21.2344 6C21.2344 6.19891 21.1554 6.38968 21.0147 6.53033C20.8741 6.67098 20.6833 6.75 20.4844 6.75H3.98438C3.78546 6.75 3.5947 6.67098 3.45405 6.53033C3.31339 6.38968 3.23438 6.19891 3.23438 6ZM6.23438 9C6.03546 9 5.8447 9.07902 5.70405 9.21967C5.56339 9.36032 5.48438 9.55109 5.48438 9.75C5.48438 9.94891 5.56339 10.1397 5.70405 10.2803C5.8447 10.421 6.03546 10.5 6.23438 10.5H18.2344C18.4333 10.5 18.6241 10.421 18.7647 10.2803C18.9054 10.1397 18.9844 9.94891 18.9844 9.75C18.9844 9.55109 18.9054 9.36032 18.7647 9.21967C18.6241 9.07902 18.4333 9 18.2344 9H6.23438ZM20.4844 12.75H3.98438C3.78546 12.75 3.5947 12.829 3.45405 12.9697C3.31339 13.1103 3.23438 13.3011 3.23438 13.5C3.23438 13.6989 3.31339 13.8897 3.45405 14.0303C3.5947 14.171 3.78546 14.25 3.98438 14.25H20.4844C20.6833 14.25 20.8741 14.171 21.0147 14.0303C21.1554 13.8897 21.2344 13.6989 21.2344 13.5C21.2344 13.3011 21.1554 13.1103 21.0147 12.9697C20.8741 12.829 20.6833 12.75 20.4844 12.75ZM18.2344 16.5H6.23438C6.03546 16.5 5.8447 16.579 5.70405 16.7197C5.56339 16.8603 5.48438 17.0511 5.48438 17.25C5.48438 17.4489 5.56339 17.6397 5.70405 17.7803C5.8447 17.921 6.03546 18 6.23438 18H18.2344C18.4333 18 18.6241 17.921 18.7647 17.7803C18.9054 17.6397 18.9844 17.4489 18.9844 17.25C18.9844 17.0511 18.9054 16.8603 18.7647 16.7197C18.6241 16.579 18.4333 16.5 18.2344 16.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default TextAlignCenterIcon;
