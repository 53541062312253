import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { isEmpty, noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import type { MinimizeBarsContextType, MinimizedBarProps, MinimizedType } from '../types';
import MinimizedBar from '..';

import 'react-toastify/dist/ReactToastify.css';

const MinimizeBarsContext = createContext<MinimizeBarsContextType>({
  addBar: () => ({
    updateBar: noop,
    barId: '',
  }),
  getBars: () => [],
  canAddBarByType: () => true,
  removeBarById: noop,
});

export const MinimizedBarContextProvider: React.FC = ({ children }) => {
  const toastIdToBarDictRef = useRef<{ [toastbarId: string]: MinimizedBarProps }>({});
  const containerId = useRef<string>(uuidv4());

  const getBars = useCallback(() => Object.values(toastIdToBarDictRef.current), [toastIdToBarDictRef]);

  const canAddBarByType = useCallback(
    (barType: MinimizedType) => isEmpty(getBars().find(({ type }) => barType === type)),
    [getBars]
  );

  const removeBarById = useCallback((barId: string) => {
    const toastIdToBarDict = toastIdToBarDictRef.current;

    if (toastIdToBarDict[barId]) {
      toast.dismiss(barId);

      const { [barId]: removedBar, ...updatedBars } = toastIdToBarDict;
      toastIdToBarDictRef.current = updatedBars;

      return removedBar;
    }
  }, []);

  const addBar = useCallback(
    (bar: MinimizedBarProps) => {
      const key = uuidv4();
      if (canAddBarByType(bar.type)) {
        toast(() => <MinimizedBarWrapper {...bar} />, {
          autoClose: false,
          closeOnClick: false,
          toastId: key,
          bodyClassName: 'p-0 m-0',
          containerId: containerId.current,
        });

        toastIdToBarDictRef.current[key] = bar;

        return {
          updateBar: (updateProps: MinimizedBarProps) => {
            toast.update(key, {
              render: () => <MinimizedBarWrapper {...bar} {...updateProps} />,
            });
          },
          barId: key,
        };
      }
      return {
        updateBar: noop,
        barId: key,
      };
    },
    [canAddBarByType]
  );

  const contextValue = useMemo(
    () => ({ getBars, addBar, canAddBarByType, removeBarById }),
    [canAddBarByType, getBars, addBar, removeBarById]
  );

  /** Deprecated in V.10:
   *  [link]https://fkhadra.github.io/react-toastify/migration-v10#the-enablemulticontainer-prop-has-been-removed **/

  return (
    <>
      <ToastContainer
        draggable={false}
        closeButton={() => null}
        transition={Zoom}
        limit={10}
        position={toast.POSITION.BOTTOM_RIGHT}
        className="w-fit"
        newestOnTop
        toastClassName="p-0 shadow-none bg-transparent mb-0"
        containerId={containerId.current}
        enableMultiContainer
      />
      <MinimizeBarsContext.Provider value={contextValue}>{children}</MinimizeBarsContext.Provider>
    </>
  );
};

const MinimizedBarWrapper: React.FC<MinimizedBarProps> = ({ type, onClick, children }) => {
  return (
    <MinimizedBar type={type} onClick={onClick}>
      {children}
    </MinimizedBar>
  );
};

export const useMinimizeBars = (): MinimizeBarsContextType => {
  return useContext(MinimizeBarsContext);
};
