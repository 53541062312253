import React from 'react';
import { get } from 'lodash';

import { Caption, Text } from '~/components/core';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { RECIPIENTS_TYPES } from '~/Types';

import type { Direction, RecipientType } from './constants';

interface AdjusterSectionProps {
  internalEmailAddress?: string;
  adjusterRecipientType?: RecipientType;
  adjuster?: string;
  direction: Direction;
}

const AdjusterSection: React.FC<AdjusterSectionProps> = ({
  internalEmailAddress,
  adjusterRecipientType,
  adjuster,
  direction,
}) => {
  const adjusterRecipientTypeDesc = get(RECIPIENTS_TYPES, adjusterRecipientType || '', '');
  const withAdjusterRecipientType = !!adjusterRecipientTypeDesc && adjusterRecipientType !== 'to';

  return (
    <div>
      <Tooltip title={<>{internalEmailAddress}</>} disabled={!internalEmailAddress}>
        <span className="flex items-center">
          <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.SEMI_BOLD} className="mr-4 flex">
            {`${direction === 'Outgoing' ? 'From' : 'To'}`}
          </Text>
          <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
            {adjuster} &nbsp;
          </Text>
          {withAdjusterRecipientType ? (
            <Caption variant={Caption.VARIANTS.LABEL}>{adjusterRecipientTypeDesc}</Caption>
          ) : null}
        </span>
      </Tooltip>
    </div>
  );
};

export default AdjusterSection;
