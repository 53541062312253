import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import cn from '~/Utils/cn';

import { reportAxiosError } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import { withClaim } from '../../../ClaimContainer';
import WithConfirm from '../../../ConfirmModal';
import { ContactEntity } from '../../../Contact';
import ContactTextFieldFormik from '../../../ContactTextFieldFormik';
import { FsButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../../core';
import { useIncidentConfiguration } from '../../../hooks/useIncidentConfiguration';
import { ContactIcon, PencilIcon, TrashIcon_Deprecated } from '../../../icons';
import {
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../IncidentConfiguration/CustomFieldsContext';
import InlineIconButton from '../../../InlineIconButton';
import PlainTable from '../../../PlainTable';
import TextFieldFormik from '../../../TextFieldFormik';

const witnessFields = {
  contact_id: '',
  contact_full_name: '',
  contact: null,
  description: '',
};

const getWitnessesPredefinedFields = (incidentConfiguration) =>
  preparePreDefinedFields(incidentConfiguration?.witnesses || {});
const getWitnessesInitialValues = (incidentConfiguration) =>
  getPredefinedFieldsEmptyFormikInitialValues(getWitnessesPredefinedFields(incidentConfiguration));
const getWitnessesValidationSchema = (incidentConfiguration) =>
  incidentConfiguration?.witnesses?.active
    ? getAdditionalDataValidations(getWitnessesPredefinedFields(incidentConfiguration))
    : {};

const getWitnessesIncidentPredefinedFields = (incidentConfiguration) =>
  preparePreDefinedFields({
    were_there_any_witnesses: incidentConfiguration?.incident_details?.pre_defined_fields.were_there_any_witnesses,
    witnesses_note: incidentConfiguration?.incident_details?.pre_defined_fields.witnesses_note,
  });
const getWitnessesIncidentInitialValues = (incidentConfiguration) =>
  getPredefinedFieldsEmptyFormikInitialValues(getWitnessesIncidentPredefinedFields(incidentConfiguration));
const getWitnessesIncidentValidationSchema = (incidentConfiguration) =>
  incidentConfiguration?.witnesses?.active
    ? getAdditionalDataValidations(getWitnessesIncidentPredefinedFields(incidentConfiguration))
    : {};

const WitnessFragment = ({ classes, buttonsComponent }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getWitnessesPredefinedFields(incidentConfiguration);
  const relationshipToPolicyholderActive = preDefinedFields?.relationship_to_policyholder?.active;
  const contactXs = relationshipToPolicyholderActive ? 6 : 12;

  return (
    <Grid container spacing={1}>
      <Grid item xs={contactXs}>
        <ContactTextFieldFormik
          id="contact"
          label="Witness"
          className={classes.textField}
          fullWidth
          acceptedRoles={['witness']}
          fixedSearchResults
        />
      </Grid>
      {relationshipToPolicyholderActive && (
        <PreDefinedField id="relationship_to_policyholder" fields={preDefinedFields} inline />
      )}
      <Grid item xs={12}>
        <TextFieldFormik id="description" label="Description" fullWidth multiline className={classes.textField} />
      </Grid>
      {buttonsComponent && (
        <Grid item xs={12}>
          <div className={classes.buttonsContainer}>{buttonsComponent}</div>
        </Grid>
      )}
    </Grid>
  );
};

WitnessFragment.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonsComponent: PropTypes.node,
};

const WitnessFragmentWithClaim = withClaim(WitnessFragment);

const AddWitnessFormikInner = (props) => {
  const {
    onCancel,
    isSubmitting, // comes from Formik
    handleSubmit, // comes from Formik
  } = props;

  return (
    <CardDialog
      isDialog={true}
      title="Add Witness"
      maxWidth="sm"
      onClose={onCancel}
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions
          disabled={isSubmitting}
          onClickPrimary={handleSubmit}
          onClickSecondary={onCancel}
          primaryLabel="Add"
        />
      }
    >
      <WitnessFragmentWithClaim {...props} />
    </CardDialog>
  );
};

AddWitnessFormikInner.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired, // comes from Formik
  isSubmitting: PropTypes.bool.isRequired, // comes from Formik
};

const AddWitnessDialog = (props) => {
  const { classes, onCancel, onAddWitness } = props;
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <Formik
      initialValues={{ ...witnessFields, ...getWitnessesInitialValues(incidentConfiguration) }}
      validationSchema={Yup.object().shape({
        ...getWitnessesValidationSchema(incidentConfiguration),
      })}
      onSubmit={(values, formikProps) => {
        onAddWitness(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => <AddWitnessFormikInner {...formikProps} onCancel={onCancel} classes={classes} />}
    </Formik>
  );
};

AddWitnessDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddWitness: PropTypes.func.isRequired,
};

const EditWitnessFormikInner = (props) => {
  const {
    onCancel,
    isSubmitting, // comes from Formik
    handleSubmit, // comes from Formik
  } = props;

  return (
    <CardDialog
      isDialog={true}
      title="Edit Witness"
      maxWidth="sm"
      onClose={onCancel}
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions disabled={isSubmitting} onClickPrimary={handleSubmit} onClickSecondary={onCancel} />
      }
    >
      <WitnessFragmentWithClaim {...props} />
    </CardDialog>
  );
};

EditWitnessFormikInner.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired, // comes from Formik
  isSubmitting: PropTypes.bool.isRequired, // comes from Formik
};

const EditWitnessDialog = (props) => {
  const { classes, onCancel, witness, onEditWitness, onDeleteWitness } = props;
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <Formik
      initialValues={{ ...witnessFields, ...getWitnessesInitialValues(incidentConfiguration), ...witness }}
      validationSchema={Yup.object().shape({
        ...getWitnessesValidationSchema(incidentConfiguration),
      })}
      onSubmit={(values, formikProps) => {
        onEditWitness(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <EditWitnessFormikInner
          {...formikProps}
          onCancel={onCancel}
          onDeleteWitness={onDeleteWitness}
          classes={classes}
        />
      )}
    </Formik>
  );
};

EditWitnessDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEditWitness: PropTypes.func.isRequired,
  onDeleteWitness: PropTypes.func.isRequired,
  witness: PropTypes.object.isRequired,
};

const WitnessesSummary = ({
  classes,
  readOnly,
  witnesses,
  onAddWitness,
  onEditWitness,
  onDeleteWitness,
  onUpdateIncidentField,
  withRoundCard = false,
  claim,
}) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [curWitnessToEdit, setCurWitnessToEdit] = useState(null);
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getWitnessesPredefinedFields(incidentConfiguration);
  const relationshipToPolicyholderField = preDefinedFields?.relationship_to_policyholder;
  const summaryPredefinedFields = getWitnessesIncidentPredefinedFields(incidentConfiguration);
  const isEditMode = !!claim && !!onUpdateIncidentField;

  if (!incidentConfiguration?.witnesses?.active) {
    return <></>;
  }

  const handleAddWitness = async (witness) => {
    await onAddWitness(witness);
    setAddDialogOpen(false);
  };

  const handleEditWitness = async (witnessWithNewValues) => {
    await onEditWitness(witnessWithNewValues);
    setEditDialogOpen(false);
  };

  const handleDeleteWitness = async (witnessId) => {
    await onDeleteWitness(witnessId);
  };

  const actionsCell = () => ({
    id: 'actions',
    numeric: false,
    label: 'Actions',
    specialCell: (witness) => (
      <div>
        <InlineIconButton
          icon={PencilIcon}
          tooltipTitle="Edit"
          className={cn(classes.textIcon, classes.marginedIcon, classes.hoverableNonFilledIcon)}
          onClick={() => {
            setCurWitnessToEdit(witness);
            setEditDialogOpen(true);
          }}
          wrapWithSpan
          iconStyle={{ width: 16, height: 16 }}
        />
        <WithConfirm title="Delete Witness?" primaryButtonName="Delete" shouldCloseOnPrimary>
          <InlineIconButton
            icon={TrashIcon_Deprecated}
            tooltipTitle="Delete"
            className={cn(classes.textIcon, classes.marginedIcon, classes.hoverableNonFilledIcon)}
            onClick={() => handleDeleteWitness(witness.id)}
            wrapWithSpan
            iconStyle={{ width: 16, height: 16 }}
          />
        </WithConfirm>
      </div>
    ),
  });

  const witnessesColumnData = [
    {
      id: 'contact_full_name',
      disablePadding: true,
      numeric: false,
      label: 'Witness',
      specialCell: (row) =>
        row.contact_id ? (
          <ContactEntity
            classes={classes}
            disabled={readOnly}
            contactId={row.contact_id}
            contactDisplayName={row.contact_full_name}
          />
        ) : (
          <ContactIcon />
        ),
    },
    {
      id: 'relationship_to_policyholder',
      disablePadding: true,
      numeric: false,
      label: relationshipToPolicyholderField?.desc || '',
    },
    { id: 'description', disablePadding: true, numeric: false, label: 'Description' },
    actionsCell(),
  ].filter((col) => !(col.id === 'relationship_to_policyholder' && !relationshipToPolicyholderField?.active));

  return (
    <>
      <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
        <CardDialog title="Witnesses" containerStyle={withRoundCard ? { borderRadius: '6px' } : undefined}>
          <Grid container spacing={2}>
            <PreDefinedField
              id="were_there_any_witnesses"
              fields={summaryPredefinedFields}
              inline={!isEditMode}
              value={claim?.incident?.were_there_any_witnesses}
              onUpdate={onUpdateIncidentField}
            />
            <PreDefinedField
              id="witnesses_note"
              fields={summaryPredefinedFields}
              inline={!isEditMode}
              value={claim?.incident?.witnesses_note}
              onUpdate={onUpdateIncidentField}
              gridXs={isEditMode ? 4 : 12}
            />
            <Grid item xs={12} />
          </Grid>
          <PlainTable classes={classes} columns={witnessesColumnData} rows={witnesses} />
          <FsButton color="primary" disabled={readOnly} onClick={() => setAddDialogOpen(true)}>
            <AddIcon />
            Add witness
          </FsButton>
        </CardDialog>
        {addDialogOpen && (
          <AddWitnessDialog
            classes={classes}
            onCancel={() => setAddDialogOpen(false)}
            onAddWitness={handleAddWitness}
          />
        )}
        {editDialogOpen && (
          <EditWitnessDialog
            classes={classes}
            witness={curWitnessToEdit}
            onCancel={() => setEditDialogOpen(false)}
            onEditWitness={handleEditWitness}
            onDeleteWitness={async () => await handleDeleteWitness(curWitnessToEdit.id)}
          />
        )}
      </RestrictedPermissions>
    </>
  );
};

WitnessesSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  witnesses: PropTypes.array.isRequired,
  onAddWitness: PropTypes.func.isRequired,
  onEditWitness: PropTypes.func.isRequired,
  onDeleteWitness: PropTypes.func.isRequired,
  onUpdateIncidentField: PropTypes.func,
  withRoundCard: PropTypes.bool,
  claim: PropTypes.object,
};

class WitnessesSummaryContainer extends Component {
  handleAddWitness = (witness) => {
    const { claim, onUpdateWitnesses } = this.props;
    return axios
      .post(`/api/v1/claims/${claim.id}/witnesses`, witness)
      .then(() => onUpdateWitnesses())
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleEditWitness = (witness) => {
    const { claim, onUpdateWitnesses } = this.props;
    return axios
      .put(`/api/v1/claims/${claim.id}/witnesses/${witness.id}`, witness)
      .then(() => onUpdateWitnesses())
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleDeleteWitness = (witnessId) => {
    const { claim, onUpdateWitnesses } = this.props;
    return axios
      .delete(`/api/v1/claims/${claim.id}/witnesses/${witnessId}`)
      .then(() => onUpdateWitnesses())
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleUpdateIncidentField = async (fieldName, fieldValue) => {
    await this.handleUpdateFields({ incident: { [fieldName]: fieldValue } });
  };

  handleUpdateFields = async (updateObject) => {
    const { claim, onUpdateWitnesses } = this.props;

    try {
      const url = claim.type === 'general_claim' ? `/api/v1/claims/${claim.id}` : `/api/v1/${claim.type}s/${claim.id}`;
      await axios.patch(url, updateObject);
      await onUpdateWitnesses();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  render() {
    const { claim, classes, witnesses, onUpdateWitnesses } = this.props;

    const readOnly = !onUpdateWitnesses;

    return (
      <WitnessesSummary
        classes={classes}
        witnesses={witnesses}
        readOnly={readOnly}
        onAddWitness={this.handleAddWitness}
        onEditWitness={this.handleEditWitness}
        onDeleteWitness={this.handleDeleteWitness}
        onUpdateIncidentField={this.handleUpdateIncidentField}
        claim={claim}
      />
    );
  }
}

WitnessesSummaryContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  witnesses: PropTypes.array.isRequired, // TODO add shape
  onUpdateWitnesses: PropTypes.func.isRequired,
};

const WitnessesSummaryContainerWithClaim = withClaim(WitnessesSummaryContainer);

export {
  getWitnessesIncidentInitialValues,
  getWitnessesIncidentValidationSchema,
  WitnessesSummaryContainerWithClaim as NewUIWitnessesSummaryContainer,
  WitnessesSummary,
};
