import React from 'react';

import PhysicalMailCommunicationPopover from '~/components/Contacts/ContactCommunications/PhysicalMail/PhysicalMailCommunicationPopover';
import type { ContactSubDetailsPropsType } from '~/components/Contacts/ContactMiniCard/types';
import Text from '~/components/core/TextComponents/Text';

const Address: React.FC<ContactSubDetailsPropsType> = ({ contact, onUpdate, actionsEnabled }) => {
  return (
    <>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
        Address
      </Text>
      <div className="my-4 flex items-center">
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className="grow">
          {contact.full_address || ''}
        </Text>
        {actionsEnabled ? (
          <PhysicalMailCommunicationPopover contact={contact} onUpdate={onUpdate} ignorePermissions={true} />
        ) : null}
      </div>
    </>
  );
};

export default Address;
