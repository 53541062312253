import React, { useMemo, useState } from 'react';
import { noop } from 'lodash';

interface ModalContextType {
  openModal: () => void;
  closeModal: () => void;
  isOpen: boolean;
}

const LinkModalContext = React.createContext<ModalContextType>({
  openModal: noop,
  closeModal: noop,
  isOpen: false,
});

export const useLinkModal = (): ModalContextType => React.useContext(LinkModalContext);

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalLinkProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const contextValue = useMemo(() => {
    return {
      isOpen,
      openModal: () => setIsOpen(true),
      closeModal: () => setIsOpen(false),
    };
  }, [isOpen]);

  return <LinkModalContext.Provider value={contextValue}>{children}</LinkModalContext.Provider>;
};
