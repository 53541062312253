import React from 'react';
import axios from 'axios';

import { useClaim } from '~/components/ClaimContainer';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core/Permissions/PermissionUtils';
import Text from '~/components/core/TextComponents/Text';
import ThreeDotsMenu from '~/components/core/ThreeDotsMenu';
import { useCms } from '~/components/hooks/useCms';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import LockedIcon from '~/components/icons/LockedIcon';
import PencilIcon from '~/components/icons/PencilIcon';
import TrashIcon from '~/components/icons/TrashIcon';
import type { ExposureLabelAndId, NotePartProps } from '~/components/Notes/NoteMiniCard';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import type { NotesConfiguration } from '~/components/SystemConfiguration/NotesConfiguration/types';
import { convertSecondsToHours, datesDiffFromUtcNow, serverDateTimeToLocal, serverDateToLocal } from '~/DateTimeUtils';
import { reportAxiosError } from '~/Utils';
import { isClaimWriteDisabled } from '~/Utils/ClaimUtils';

interface NoteHeaderProps extends NotePartProps {
  exposureLabels: ExposureLabelAndId[];
  onNoteUpdate: () => Promise<void> | void;
  configuration?: NotesConfiguration;
  onEdit?: () => void;
}
const NoteHeader: React.FC<NoteHeaderProps> = ({ note, exposureLabels, onNoteUpdate, configuration, onEdit }) => {
  const { claim } = useClaim();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useCms();

  const userHasNoteFullPermissions = useHasPermission({
    action: PERMISSION_ACTIONS.NOTE,
    verb: PERMISSION_VERBS.FULL,
  });

  const userHasNoteWritePermissions = useHasPermission({
    action: PERMISSION_ACTIONS.NOTE,
    verb: PERMISSION_VERBS.WRITE,
  });

  const handleChangeConfidentiality = async () => {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/notes/${note.id}/confidentiality`, {
        is_confidential: !note.is_confidential,
      });
      await onNoteUpdate();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleDeleteNoteClicked = async () => {
    try {
      await axios.delete(`/api/v1/claims/${claim.id}/notes/${note.id}`);
      await onNoteUpdate();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleEditNoteClicked = () => {
    onEdit && onEdit();
  };

  const menuOptions = [];

  if (userHasNoteWritePermissions && configuration?.is_edit_note_enabled && note.type === 'generic_note') {
    const shouldDisableEditNote = () => {
      const creatorIsCurrentUserOrSupervisedBy = [...user.all_subordinates_recursive_users_ids, user.id].includes(
        note.creator_id
      );
      if (!creatorIsCurrentUserOrSupervisedBy) {
        return {
          shouldDisableEdit: true,
          disableTooltipText: "Editing is only enabled to it's creator or supervisor",
        };
      }

      const timeframeIsPassed = datesDiffFromUtcNow(note.creation_date);
      if (!configuration.note_edit_time_in_seconds || !timeframeIsPassed) {
        return {
          shouldDisableEdit: true,
          disableTooltipText: 'Could not edit note',
        };
      }

      return {
        shouldDisableEdit: configuration.note_edit_time_in_seconds < timeframeIsPassed,
        disableTooltipText: `The editing of this note is no longer available due to a ${convertSecondsToHours(
          configuration.note_edit_time_in_seconds
        )}H limitation`,
      };
    };

    const { shouldDisableEdit, disableTooltipText } = shouldDisableEditNote();

    menuOptions.push({
      onClick: () => handleEditNoteClicked(),
      disabled: shouldDisableEdit,
      node: (
        <div className="flex items-center">
          <PencilIcon className="mr-4" />
          Edit
        </div>
      ),
      key: 'edit',
      tooltipText: shouldDisableEdit ? disableTooltipText : '',
    });
  }

  if (userHasNoteFullPermissions) {
    menuOptions.push({
      onClick: () => handleDeleteNoteClicked(),
      disabled: false,
      node: (
        <div className="flex items-center">
          <TrashIcon className="mr-4" />
          Delete
        </div>
      ),
      key: 'delete',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      withConfirmProps: {
        title: 'Delete note?',
        contentText:
          'Are you sure you want to delete this note? You will not be able to recover the note without contacting 5S support',
        primaryButtonName: 'Delete',
      },
    });
    menuOptions.push({
      onClick: () => handleChangeConfidentiality(),
      disabled: isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true }),
      node: (
        <div className="flex items-center">
          <LockedIcon className="mr-4" />
          {note.is_confidential ? 'Untag Confidential' : 'Tag Confidential'}
        </div>
      ),
      key: 'confidential',
    });
  }

  return (
    <div className="flex justify-between">
      <div>
        <div className="flex gap-4">
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
            #{note.claim_internal_id}
          </Text>
          <Text variant={Text.VARIANTS.SM}>{note.title}</Text>
        </div>
        <div className="mt-4">
          {exposureLabels
            .filter((exposure) => note.exposure_ids.includes(exposure.id))
            .map((exposure) => (
              <Chip
                key={exposure.id}
                label={<OverflowTextWithToolTip maxWidth="120px">{exposure.label}</OverflowTextWithToolTip>}
                size="small"
                className="-mt-2"
              />
            ))}
        </div>
      </div>
      <div className="flex gap-16">
        {note.is_confidential && (
          <Tooltip title="Confidential notes will not appear in claim export">
            <span>
              <Chip
                icon={<LockedIcon size={16} className="m-4 mr-0" />}
                variant="outlined"
                size="small"
                label="Confidential"
                className="-mt-2 p-4 font-medium"
              />
            </span>
          </Tooltip>
        )}

        <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR} className="whitespace-nowrap">
          {note.creator}
        </Text>
        <Tooltip title={serverDateTimeToLocal(note.creation_date)}>
          <span>
            <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
              {serverDateToLocal(note.creation_date)}
            </Text>
          </span>
        </Tooltip>
        {menuOptions.length > 0 && (
          <span className="-mb-2">
            <ThreeDotsMenu options={menuOptions} />
          </span>
        )}
      </div>
    </div>
  );
};

export default NoteHeader;
