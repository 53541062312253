import React from 'react';

import SvgIcon from '../../core/SvgIcon';

const EllipseIcon = (props) => (
  <SvgIcon size={7} fill="none" stroke="none" {...props}>
    <circle cx="7" cy="7" r="7" fill="#32C7CA" />
  </SvgIcon>
);

export default EllipseIcon;
