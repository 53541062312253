import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { LoadingSwitch } from '../core';
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';
import TextFieldFormik from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function CloseExposureDialog(props) {
  const { claim, exposure, onClose, onUpdate } = props;

  const handleCloseExposure = (values) => {
    return axios
      .post(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/close`, { reason: values.reason, extra: values.extra })
      .then(() => {
        onUpdate();
        onClose();
      })
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  return (
    <ClosingReasonDialog claim={claim} exposures={[exposure]} onClose={onClose} onReasonChosen={handleCloseExposure} />
  );
}

CloseExposureDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const getClosingReasons = async (claim) => {
  const { data: closingReasons } = await axios.get(`/api/v1/claims/${claim.id}/exposures/closing_reasons`);
  return closingReasons;
};

function ClosingReasonDialog({ title, claim, onReasonChosen, onClose, exposures }) {
  const classes = useStyles();
  const { exposuresClosingReasonsDict } = useOrganization();
  const { userOrganization } = useCms();
  const isNewClosingReason = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NEW_CLOSING_REASON);
  const [closingReasons, setClosingReasons] = useState([]);
  const [isClosingReasonsLoaded, setIsClosingReasonsLoaded] = useState(false);
  const coverages = exposures.map((exposure) => exposure.coverage_type);
  let exposuresClosingReasons = exposuresClosingReasonsDict[claim.lob];
  let possibleReasons = Object.keys(exposuresClosingReasons)
    .filter((reason) => !exposuresClosingReasons[reason].internal_only)
    .filter(
      (reason) =>
        !(
          exposuresClosingReasons[reason].coverage_specific &&
          _.intersection(exposuresClosingReasons[reason].coverage_specific, coverages).length === 0
        )
    );

  useEffect(() => {
    const func = async () => {
      if (isNewClosingReason) {
        const answer = await getClosingReasons(claim);
        setClosingReasons(answer);
        setIsClosingReasonsLoaded(true);
      }
    };
    func();
  }, [claim, isNewClosingReason]);

  if (isNewClosingReason) {
    const reasonDict = {};
    closingReasons?.forEach((closingReason) => {
      reasonDict[closingReason.closing_reason_key] = {
        desc: closingReason.display_name,
        extra_needed: closingReason.is_note_enabled,
        is_note_required: closingReason.is_note_required,
      };
    });
    possibleReasons = Object.keys(reasonDict);
    exposuresClosingReasons = reasonDict;
  }

  return (
    <Formik
      initialValues={{ reason: '', extra: '' }}
      validationSchema={Yup.object().shape({
        reason: Yup.string().required('Required'),
        extra: Yup.string().when('reason', {
          is: (val) =>
            (!isNewClosingReason && val && exposuresClosingReasons[val].extra_needed) ||
            (isNewClosingReason && val && exposuresClosingReasons[val].is_note_required),
          then: Yup.string().required('Required'),
        }),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onReasonChosen(values)
          .then(() => formikProps.resetForm())
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleReset, handleSubmit } = formikProps;
        const showExtra = values.reason && exposuresClosingReasons[values.reason].extra_needed;
        return (
          <CardDialog
            isDialog={true}
            open={true}
            title={title || 'Close Exposure'}
            fullWidth
            maxWidth="sm"
            onClose={() => {
              handleReset();
              onClose();
            }}
            preventClose={isSubmitting}
          >
            <LoadingSwitch isLoading={!isClosingReasonsLoaded && isNewClosingReason}>
              {exposures.reduce(
                (isOutstandingReserveExists, exposure) =>
                  isOutstandingReserveExists || exposure.indemnity.reserve !== 0 || exposure.expenses.reserve !== 0,
                false
              ) && (
                <div className={classes.alertBanner}>
                  <AlertBanner
                    title="Please Note"
                    note="Warning: Outstanding reserves will be zeroed."
                    alertType={AlertBanner.ALERT_TYPES.WARNING}
                    withIcon
                  />
                </div>
              )}
              {/* TODO - don't close in case of */}
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextFieldFormik id="reason" select label="Reason" className={classes.textField} fullWidth>
                    {possibleReasons
                      .sort((a, b) => exposuresClosingReasons[a].desc.localeCompare(exposuresClosingReasons[b].desc))
                      .map((reason) => (
                        <MenuItem key={reason} value={reason}>
                          {exposuresClosingReasons[reason].desc}
                        </MenuItem>
                      ))}
                  </TextFieldFormik>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="extra"
                    label={isNewClosingReason ? 'Note' : 'Extra'}
                    disabled={!showExtra}
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <div className={classes.buttonsContainer}>
                <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                  Close Exposure
                </Button>
              </div>
            </LoadingSwitch>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

ClosingReasonDialog.propTypes = {
  title: PropTypes.string,
  claim: PropTypes.object.isRequired,
  exposures: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onReasonChosen: PropTypes.func.isRequired,
};

export default CloseExposureDialog;
export { ClosingReasonDialog };
