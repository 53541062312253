import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const CustomClaimConfigurationIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 5.25C6.25736 5.25 5.25 6.25736 5.25 7.5C5.25 8.74264 6.25736 9.75 7.5 9.75C8.74264 9.75 9.75 8.74264 9.75 7.5C9.75 6.25736 8.74264 5.25 7.5 5.25ZM3.75 7.5C3.75 5.42893 5.42893 3.75 7.5 3.75C9.57107 3.75 11.25 5.42893 11.25 7.5C11.25 9.57107 9.57107 11.25 7.5 11.25C5.42893 11.25 3.75 9.57107 3.75 7.5Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 5.25C15.2574 5.25 14.25 6.25736 14.25 7.5C14.25 8.74264 15.2574 9.75 16.5 9.75C17.7426 9.75 18.75 8.74264 18.75 7.5C18.75 6.25736 17.7426 5.25 16.5 5.25ZM12.75 7.5C12.75 5.42893 14.4289 3.75 16.5 3.75C18.5711 3.75 20.25 5.42893 20.25 7.5C20.25 9.57107 18.5711 11.25 16.5 11.25C14.4289 11.25 12.75 9.57107 12.75 7.5Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 14.25C6.25736 14.25 5.25 15.2574 5.25 16.5C5.25 17.7426 6.25736 18.75 7.5 18.75C8.74264 18.75 9.75 17.7426 9.75 16.5C9.75 15.2574 8.74264 14.25 7.5 14.25ZM3.75 16.5C3.75 14.4289 5.42893 12.75 7.5 12.75C9.57107 12.75 11.25 14.4289 11.25 16.5C11.25 18.5711 9.57107 20.25 7.5 20.25C5.42893 20.25 3.75 18.5711 3.75 16.5Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 12.75C16.9142 12.75 17.25 13.0858 17.25 13.5V19.5C17.25 19.9142 16.9142 20.25 16.5 20.25C16.0858 20.25 15.75 19.9142 15.75 19.5V13.5C15.75 13.0858 16.0858 12.75 16.5 12.75Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 16.5C12.75 16.0858 13.0858 15.75 13.5 15.75H19.5C19.9142 15.75 20.25 16.0858 20.25 16.5C20.25 16.9142 19.9142 17.25 19.5 17.25H13.5C13.0858 17.25 12.75 16.9142 12.75 16.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);

CustomClaimConfigurationIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default CustomClaimConfigurationIcon;
