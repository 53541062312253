const MOR_OPTIONS_DICT = {
  insured_directed_repair: {
    desc: 'Insured Directed Repair',
  },
  claimant_directed_repair: {
    desc: 'Claimant Directed Repair',
  },
  contractor_directed_repair: {
    desc: 'Contractor Directed Repair',
  },
  body_shop_directed_repair: {
    desc: 'Body Shop Directed Repair',
  },
  managed_repair_manual: {
    desc: 'Managed Repair',
  },
  rebuild: {
    desc: 'Rebuild',
  },
  replace: {
    desc: 'Replace',
  },
  adverse_carrier_directed_repair: {
    desc: 'Adverse Carrier Directed Repair',
  },
  compensation_cash: {
    desc: 'Compensation/Cash',
  },
  cash: {
    desc: 'Cash',
  },
  voucher: {
    desc: 'Voucher',
  },
  fb_credit: {
    desc: 'F&B Credit',
  },
  room_comp: {
    desc: 'Room Comp.',
  },
  gl_other: {
    desc: 'Other',
  },
  pay_to_claimant: {
    desc: 'Pay to Claimant',
  },
  pay_to_attorney: {
    desc: 'Pay to Attorney',
  },
} as const;

export default MOR_OPTIONS_DICT;
