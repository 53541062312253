import React from 'react';
import axios from 'axios';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { InvolvedPersonsListDetails } from '../InvolvedPerson';

function GLInvolvedCard() {
  const { claim, onClaimUpdate } = useClaim();

  const handleEditPerson = async (person) => {
    try {
      await axios.put(`/api/v1/gl_claims/${claim.id}/involved_persons/${person.id}`, person);
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <CardDialog title="Involved Parties">
      <InvolvedPersonsListDetails
        involvedPersons={[claim.claimant_party.involved_person]}
        onSaveInvolvedPersonDetails={handleEditPerson}
        contactAcceptedRoles={['claimant']}
        personLabel="Involved"
        allowDelete={false}
        disableAddingProperty
      />
    </CardDialog>
  );
}

export default GLInvolvedCard;
