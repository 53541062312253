import React, { useState } from 'react';

import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../../../CardDialog';
import LoadingIndicator from '../../../LoadingIndicator';
import ApprovalFinances from '../ApprovalsFinances';

import Filters from './Filters';

import styles from '../financeApprovals.module.scss';

const PaymentApprovals = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [statuses] = useState(['pending_approval']);

  const handleChange = (key, values) => {
    setFilters((prevState) => setFilters({ ...prevState, [key]: values }));
    setPage(0);
  };

  return (
    <CardDialog noCardTitle>
      <Grid container className={styles.paymentApprovalsContainer}>
        <Grid item xs={12}>
          <Filters filters={filters} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <ApprovalFinances
            filters={filters}
            statuses={statuses}
            page={page}
            setPage={setPage}
            onIsLoadingChanged={setIsLoading}
            onIsErrorChanged={setIsError}
          />
        </Grid>
        <Grid item xs={12}>
          {(isLoading || isError) && <LoadingIndicator isError={isError} />}
        </Grid>
      </Grid>
    </CardDialog>
  );
};

export default PaymentApprovals;
