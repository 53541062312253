import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ContactsIcon = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M6.875 12.5C9.11866 12.5 10.9375 10.6812 10.9375 8.4375C10.9375 6.19384 9.11866 4.375 6.875 4.375C4.63134 4.375 2.8125 6.19384 2.8125 8.4375C2.8125 10.6812 4.63134 12.5 6.875 12.5Z"
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
    <path
      d="M12.1406 4.52344C12.4997 4.42604 12.8701 4.37613 13.2422 4.375C14.3196 4.375 15.3529 4.80301 16.1148 5.56488C16.8767 6.32675 17.3047 7.36006 17.3047 8.4375C17.3047 9.51494 16.8767 10.5483 16.1148 11.3101C15.3529 12.072 14.3196 12.5 13.2422 12.5"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 15.4219C1.88433 14.5192 2.72659 13.7825 3.70563 13.2738C4.68467 12.7652 5.77173 12.4997 6.875 12.4997C7.97827 12.4997 9.06533 12.7652 10.0444 13.2738C11.0234 13.7825 11.8657 14.5192 12.5 15.4219"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2422 12.5C14.3455 12.4993 15.4328 12.7645 16.412 13.2731C17.3911 13.7817 18.2333 14.5188 18.8672 15.4219"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ContactsIcon;
