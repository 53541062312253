import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { CLAIM_TYPES_CONFIGURATION } from '../../../../Types';
import useOrganization from '../../../OrganizationContext';
import { usePrevious } from '../../../TextFieldFormik';

const useLobOptions = ({ subOrganizationIds, isAllLobs = false, filterLobsFunc = () => true }) => {
  const [lobOptions, setLobOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const prevSubOrgIdsRef = usePrevious(subOrganizationIds);
  const { fetchLobs } = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();
  const didRun = isLoaded || isError;

  useEffect(() => {
    (async () => {
      if (!didRun || !isEqual(prevSubOrgIdsRef, subOrganizationIds)) {
        setIsLoading(true);

        try {
          const allLobs = [...Object.keys(CLAIM_TYPES_CONFIGURATION), ...Object.keys(lobConfigurationsDict)];
          const data = isAllLobs ? allLobs : (await fetchLobs(subOrganizationIds)).data;
          setLobOptions(data?.filter(filterLobsFunc));
          setIsLoaded(true);
        } catch {
          setIsError(true);
        }
        setIsLoading(false);
      }
    })();
  }, [didRun, prevSubOrgIdsRef, subOrganizationIds, fetchLobs, isAllLobs, filterLobsFunc, lobConfigurationsDict]);

  return { lobOptions, isLoading, isLoaded, isError };
};

export default useLobOptions;
