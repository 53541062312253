import React from 'react';
import PropTypes from 'prop-types';

import { useCurrencyFormatter } from '../../../../CurrencyFormatterContext';
import {
  DIALOG_FIELDS,
  getInvolvedPropertyValidationFields,
  getPropertyItemsInitialValues,
  getPropertyItemsPredefinedFields,
  InvolvedPropertyItemsFragment,
} from '../InvolvedPropertyParty/InvolvedPropertyItemsFragment';
import { GenericItems } from '../Items';

const InvolvedPropertyItemsSectionFragment = ({ preDefinedFields, propertyItemsId }) => {
  const { currencyFormatter } = useCurrencyFormatter();
  const itemsPredefinedFields = getPropertyItemsPredefinedFields(preDefinedFields);
  const initialValues = getPropertyItemsInitialValues(preDefinedFields);
  const validationFields = getInvolvedPropertyValidationFields(preDefinedFields);

  let columnsData = [
    { id: 'description', numeric: false, label: 'Item Description', is_visible: !!itemsPredefinedFields?.description },
    {
      id: 'category',
      numeric: false,
      label: 'Category',
      is_visible: !!itemsPredefinedFields?.category,
      specialCell: (row) =>
        itemsPredefinedFields?.category?.options?.find((option) => option?.id === row?.category)?.desc,
    },
    { id: 'quantity', numeric: false, label: 'Quantity', is_visible: !!itemsPredefinedFields?.quantity },
    { id: 'purchase_date', numeric: false, label: 'Purchase Date', is_visible: !!itemsPredefinedFields?.purchase_date },
    {
      id: 'purchase_price',
      numeric: true,
      label: 'Purchase Price',
      is_visible: !!itemsPredefinedFields?.purchase_price,
      specialCell: (row) => row.purchase_price && currencyFormatter.format(row.purchase_price),
    },
    {
      id: 'claimed_amount',
      numeric: true,
      label: 'Claim Amount',
      is_visible: !!itemsPredefinedFields?.claimed_amount,
      specialCell: (row) => row.claimed_amount && currencyFormatter.format(row.claimed_amount),
    },
    { id: 'scheduled', numeric: false, label: 'Scheduled', is_visible: !!itemsPredefinedFields?.scheduled },
  ];

  columnsData = columnsData.filter((col) => col.is_visible !== false);
  return (
    <>
      <GenericItems
        itemId={propertyItemsId}
        columnsDataItemsValues={columnsData}
        dialogFields={DIALOG_FIELDS}
        ItemsFragment={<InvolvedPropertyItemsFragment preDefinedFields={itemsPredefinedFields} />}
        itemsInitialValues={initialValues}
        itemsValidationFields={validationFields}
        itemName="item"
        title="Items"
      />
    </>
  );
};

InvolvedPropertyItemsSectionFragment.propTypes = {
  preDefinedFields: PropTypes.object.isRequired,
  propertyItemsId: PropTypes.string,
};

export { InvolvedPropertyItemsSectionFragment };
