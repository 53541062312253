import React from 'react';
import PropTypes from 'prop-types';

import { displayAttorneyInformation } from '~/components/communications/ContactUtils';
import { FORMIK_DETAILS_FIELD_IDS } from '~/components/Contacts/UpsertContact/constants';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import { TextFieldFormik } from '~/components/TextFieldFormik';
import UsStatesSelectTextFieldFormik from '~/components/UsStatesSelectTextFieldFormik';
import { isLocaleRegionIsUs } from '~/Utils';

import { getFullDetailsTabFieldPath } from '../../utils';

const Attorney = ({ disabled }) => {
  const { currentRole: role } = useContact();

  if (!displayAttorneyInformation(role)) {
    return null;
  }

  const isLocaleRegionUs = isLocaleRegionIsUs();

  return (
    <div className="grid grid-cols-2 gap-20">
      {isLocaleRegionUs ? (
        <UsStatesSelectTextFieldFormik
          id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.ATTORNEY_BAR_STATE)}
          label="State Bar"
          fullWidth
          disabled={disabled}
        />
      ) : null}
      <TextFieldFormik
        id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.ATTORNEY_BAR_NUMBER)}
        label={`${isLocaleRegionUs ? 'Bar' : 'SRA'} Number`}
        fullWidth
        disabled={disabled}
      />
    </div>
  );
};

Attorney.propTypes = {
  disabled: PropTypes.bool,
};

export default Attorney;
