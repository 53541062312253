import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

const getContactPrimaryPhone = (contact) => {
  if (!contact.phones || contact.phones.length === 0) {
    return undefined;
  }

  const mobilePhones = contact.phones.filter((contact_phone) => contact_phone.phone_type === 'mobile');
  if (mobilePhones.length > 0) {
    return mobilePhones[0];
  }

  return contact.phones[0];
};

const contactPopulate = (contact) => ({
  ...contact,
  primary_email: contact.emails && contact.emails.length > 0 ? contact.emails[0] : undefined,
  primary_phone: getContactPrimaryPhone(contact),
});

export { contactPopulate };

// If you update this function, make sure you update should_check_for_communication_permissions as well (BE)
// and EditContact/Permissions as well until this is deprecated
export const shouldCheckForCommunicationPermissions = (organization, isContactCompany, contactRole) => {
  return (
    isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.ALWAYS_CHECK_COMMUNICATION_PERMISSION) ||
    (!isContactCompany && contactRole !== 'vendor')
  );
};
