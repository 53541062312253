import React, { useContext } from 'react';

export const CmsContext = React.createContext({});

export const withCmsContext = (WrappedComponent) => {
  class withCmsContext extends React.Component {
    render() {
      return (
        <CmsContext.Consumer>
          {(cmsContext) => <WrappedComponent {...cmsContext} {...this.props} />}
        </CmsContext.Consumer>
      );
    }
  }

  withCmsContext.displayName = `withCmsContext(${getDisplayName(WrappedComponent)})`;
  return withCmsContext;
};

export const useCms = () => {
  return useContext(CmsContext);
};

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};
