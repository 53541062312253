export const ONE_INC_CLAIMSPAY_TYPES = {
  CONTACTS: 'Contact(s)',
  VENDOR: 'Vendor',
  LIENHOLDER: 'Lienholder',
  CONTACTS_AND_VENDOR: 'Contact(s) and Vendor',
  CONTACTS_AND_MORTGAGEE: 'Contact(s) and Mortgagee',
};

export const ONE_INC_CLAIMSPAY_METHODS = {
  LET_CUSTOMER_PICKUP: 'Let Customer Pickup',
  CHECK: 'Check',
};

export const ONE_INC_METHOD_CHECK_ISSUANCE_TYPES = {
  STANDARD: 'Standard',
  EXPEDITED: 'Expedited',
  CERTIFIED: 'Certified',
};

export const ONE_INC_TYPES_REQUIRING_METHOD = [
  ONE_INC_CLAIMSPAY_TYPES.CONTACTS,
  ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_VENDOR,
  ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_MORTGAGEE,
];

export const MAX_PAYEES_PER_CLAIMSPAY_TYPE = {
  [ONE_INC_CLAIMSPAY_TYPES.CONTACTS]: 2,
  [ONE_INC_CLAIMSPAY_TYPES.VENDOR]: 1,
  [ONE_INC_CLAIMSPAY_TYPES.LIENHOLDER]: 1,
  [ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_VENDOR]: 3,
  [ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_MORTGAGEE]: 3,
};

export const ONE_INC_EXPENSES_CLAIMSPAY_TYPES = [
  ONE_INC_CLAIMSPAY_TYPES.VENDOR,
  ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_VENDOR,
];
