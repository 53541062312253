export const COMMUNICATION_TAB_KEY = 'communication';

export const FORMIK_COMMUNICATION_FIELD_IDS = {
  EMAILS: 'emails',
  PHONES: 'phones',
  IS_SMSING_ALLOWED: 'is_smsing_allowed',
  IS_EMAILING_ALLOWED: 'is_emailing_allowed',
  REASON: `reason`,
  EXPLANATION: `explanation`,
};

export const FORMIK_COMMUNICATION_METHOD_FIELD_IDS = {
  ID: 'id',
  DESCRIPTION: 'description',
  EMAIL: 'email',
  PHONE: 'phone',
  PHONE_TYPE: 'phone_type',
};

export const PHONE_TYPES = ['home', 'mobile', 'work', 'fax'];
