import type { ReactElement } from 'react';
import React from 'react';
import type { InputLabelProps, InputProps, SelectProps } from '@material-ui/core';
import { TextField as MuiTextField } from '@material-ui/core';

export interface TextFieldProps<T> {
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: React.ReactNode;
  id?: string;
  InputLabelProps?: Partial<InputLabelProps>;
  multiline?: boolean;
  rows?: string | number;
  value?: T;
  onChange?: (newValue: T) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: string;
  className?: string;
  select?: boolean; // should be used from the select component only
  SelectProps?: Partial<SelectProps>; // should be used from the select component only
  children?: React.ReactNode; // should be used from the select component only
  InputProps?: Partial<InputProps>; // should be used from the select component only
  name?: string;
}

const TextField = <T,>({
  value,
  disabled,
  multiline,
  rows,
  fullWidth,
  helperText,
  onChange,
  onBlur,
  error,
  id,
  ...rest
}: TextFieldProps<T>): ReactElement => {
  return (
    <MuiTextField
      disabled={disabled}
      value={value}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={(e) => (onChange ? onChange(e.target.value) : null)}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      name={id}
      multiline={multiline}
      rows={rows}
      fullWidth={fullWidth}
      size="small"
      InputLabelProps={{ htmlFor: id }}
      {...rest}
    />
  );
};

export default TextField;
