import React from 'react';

import DuplicatePaymentsDetailsFormikFragment from './DuplicatePaymentsDetailsFormikFragment';
import ExceedIndemnityPolicyLimitDetailsFormikFragment from './ExceedIndemnityPolicyLimitDetailsFormikFragment';
import OfacDetailsFormikFragment from './OfacDetailsFormikFragment';
import OtherComplianceDetailsFormikFragment from './OtherComplianceDetailsFormikFragment';
import UkBankValidationFormikFragment from './UkBankValidationFormikFragment';

const CHECK_KEY_TO_COMPONENT_AND_RESPONSE_FIELD_ID = {
  duplicate_payment: {
    responseFieldId: 'force_duplicate_request_reason',
    component: DuplicatePaymentsDetailsFormikFragment,
  },
  ofac_hit: {
    responseFieldId: 'compliance_override_reason',
    component: OfacDetailsFormikFragment,
  },
  other_compliance: {
    responseFieldId: 'compliance_override_reason',
    component: OtherComplianceDetailsFormikFragment,
  },
  indemnity_limit_exceeded: {
    responseFieldId: 'override_limit_reason',
    component: ExceedIndemnityPolicyLimitDetailsFormikFragment,
  },
  uk_bank_details_validation: {
    component: UkBankValidationFormikFragment,
  },
};

export const getCheckComponentByCheckKey = (
  issueKey,
  preSubmitChecks,
  amount,
  showReasonInput,
  isOfacRequestFailed
) => {
  const genericProps = {
    checkDetails: preSubmitChecks[issueKey],
    responseFieldId: CHECK_KEY_TO_COMPONENT_AND_RESPONSE_FIELD_ID[issueKey]?.responseFieldId,
    showReasonInput,
  };

  if (issueKey === 'other_compliance') {
    genericProps.paymentAmount = amount;
  } else if (issueKey === 'ofac_hit') {
    genericProps.isOfacRequestFailed = isOfacRequestFailed;
  }

  const Component = CHECK_KEY_TO_COMPONENT_AND_RESPONSE_FIELD_ID[issueKey]?.component;
  return Component ? <Component {...genericProps} /> : null;
};
