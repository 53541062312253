import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { cleanEmptyValues, reportGenericException } from '../../../../../Utils';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { InvolvedPropertyParty } from '../../../../InvolvedProperty';
import {
  getInvolvedPropertyValidationFields,
  involvedPropertyFields,
  InvolvedPropertyFragment,
} from '../InvolvedPropertyParty/InvolvedPropertyFragment';

import NonMotoristDetails, { involvedNonMotoristFields } from './NonMotoristInvolved';
import VehicleParty, { vehiclePartyFields } from './VehicleParty';

import { useStyles } from '../../../../../assets/styles';
import styles from './index.module.scss';

const FIRST_PARTY_TYPES = [
  {
    id: 'vehicle',
    desc: 'Vehicle',
  },
  {
    id: 'non_motorist',
    desc: 'Non Motorist',
  },
];

const autoInvolvedPartiesInitialValues = {
  first_party_type: 'vehicle',
  involved_parties: [],
  first_party: {
    id: 'initial_first_party_id', // the id must be constant so Formik won't reinitialize the form
    ...vehiclePartyFields,
  },
  third_party_vehicles: [],
  third_party_non_motorists: [],
  third_party_other_properties: [],
};

const getAutoInvolvedPartiesValidationSchema = (partiesStore) => {
  const partiesStoreData = partiesStore.data;

  // partiesStore wasn't initialized
  if (!partiesStoreData) {
    return {};
  }

  const insuredPartyType = partiesStoreData.insured_party.insured_party_type;

  return {
    first_party_type: Yup.string().required('Required'),
    first_party: Yup.object().shape({
      involved_vehicle: insuredPartyType === 'vehicle' ? Yup.object().nullable().required('Required') : undefined,
      involved_non_motorist:
        insuredPartyType === 'non_motorist' ? Yup.object().nullable().required('Required') : undefined,
      driver: insuredPartyType === 'vehicle' ? Yup.object().nullable().required('Required') : undefined,
    }),
  };
};

const buildAutoInvolvedParties = ({ values }) => {
  const involvedParties = [];
  const firstPartyInvolvedNonMotorist = cleanEmptyValues(values?.first_party?.involved_non_motorist);
  if (values?.first_party_type === 'non_motorist' && firstPartyInvolvedNonMotorist) {
    involvedParties.push({
      external_id: uuidv4(),
      type: 'involved_non_motorist',
      is_first_party: true,
      ...firstPartyInvolvedNonMotorist,
      bodily_injuries: firstPartyInvolvedNonMotorist?.injuries || [],
    });
  } else if (values?.first_party_type === 'vehicle') {
    const firstPartyInvolvedVehicleId = uuidv4();
    const firstPartyInvolvedVehicle = cleanEmptyValues(values?.first_party?.involved_vehicle);
    if (firstPartyInvolvedVehicle['covered_vehicle_id'] && firstPartyInvolvedVehicle['vehicle']) {
      delete firstPartyInvolvedVehicle['vehicle'];
    } else {
      firstPartyInvolvedVehicle.vehicle = cleanEmptyValues(firstPartyInvolvedVehicle?.vehicle || {});
    }

    const firstPartyInvolvedDriver = cleanEmptyValues(values?.first_party?.driver);
    const firstPartyInvolvedPassengers = values?.first_party?.passengers || [];
    // first party involved_vehicle is required
    involvedParties.push({
      external_id: firstPartyInvolvedVehicleId,
      type: 'involved_vehicle',
      is_first_party: true,
      ...firstPartyInvolvedVehicle,
    });
    if (firstPartyInvolvedDriver) {
      involvedParties.push({
        external_id: uuidv4(),
        type: 'involved_driver',
        is_first_party: true,
        ...firstPartyInvolvedDriver,
        vehicle_id: firstPartyInvolvedVehicleId,
      });
    }
    firstPartyInvolvedPassengers.forEach((passenger) => {
      involvedParties.push({
        external_id: uuidv4(),
        type: 'involved_passenger',
        is_first_party: true,
        ...cleanEmptyValues(passenger),
        vehicle_id: firstPartyInvolvedVehicleId,
      });
    });
  }
  values?.third_party_vehicles.forEach((thirdPartyVehicle) => {
    const involvedVehicleId = uuidv4();
    const involvedVehicle = thirdPartyVehicle?.involved_vehicle;
    const involvedDriver = thirdPartyVehicle?.driver;
    const partyInvolvedPassengers = thirdPartyVehicle?.passengers || [];
    if (involvedVehicle) {
      involvedParties.push({
        external_id: involvedVehicleId,
        type: 'involved_vehicle',
        is_first_party: false,
        ...cleanEmptyValues(involvedVehicle),
        vehicle: cleanEmptyValues(involvedVehicle?.vehicle || {}),
      });
    }
    if (involvedDriver) {
      involvedParties.push({
        external_id: uuidv4(),
        type: 'involved_driver',
        is_first_party: false,
        ...cleanEmptyValues(involvedDriver),
        vehicle_id: involvedVehicleId,
      });
    }
    partyInvolvedPassengers.forEach((passenger) => {
      involvedParties.push({
        external_id: uuidv4(),
        type: 'involved_passenger',
        is_first_party: false,
        ...cleanEmptyValues(passenger),
        vehicle_id: involvedVehicleId,
      });
    });
  });
  values?.third_party_non_motorists.forEach((thirdPartyNonMotorist) => {
    if (thirdPartyNonMotorist.involved_non_motorist) {
      involvedParties.push({
        external_id: uuidv4(),
        type: 'involved_non_motorist',
        is_first_party: false,
        ...cleanEmptyValues(thirdPartyNonMotorist?.involved_non_motorist),
        bodily_injuries: thirdPartyNonMotorist?.injuries || [],
      });
    }
  });
  values?.third_party_other_properties.forEach((thirdPartyOtherProperty) => {
    if (thirdPartyOtherProperty.generic_property_involved) {
      involvedParties.push({
        external_id: uuidv4(),
        type: 'involved_generic_property',
        is_first_party: false,
        ...cleanEmptyValues(thirdPartyOtherProperty?.generic_property_involved),
      });
    }
  });
  return involvedParties;
};

function OpenFnolParties({ onChangePartiesStore }) {
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    function buildOpenPartiesStore(firstPartyType) {
      let store = {
        parties_required: {
          is_open_fnol: true,
          insured_party: {
            insured_party_type: 'vehicle',
            should_include_passengers: true,
          },
          third_party_vehicles: [],
          third_party_other_properties: [],
          allow_add_third_party_vehicles: true,
          allow_add_third_party_non_motorist: true,
          should_allow_delete_third_party_vehicles: true,
          third_party_non_motorists: [],
        },
      };

      store.parties_required.insured_party.insured_party_type = firstPartyType;

      const newPartiesStoreData = store.parties_required;

      setFieldValue(
        'first_party.non_motorist_type',
        newPartiesStoreData.insured_party.insured_party_type === 'non_motorist'
          ? newPartiesStoreData.insured_party.insured_party_type
          : undefined
      );
      setFieldValue('first_party.are_passengers_involved', newPartiesStoreData.insured_party.should_include_passengers);

      onChangePartiesStore({ data: newPartiesStoreData });
    }

    buildOpenPartiesStore(values.first_party_type);
  }, [onChangePartiesStore, setFieldValue, values.first_party_type]);

  return (
    <Grid item xs={12}>
      <div className={styles.involvedPartiesContainer}>
        <RadioButtonGroupFormik
          id="first_party_type"
          direction="row"
          btnClassName={styles.partiesSelectionBtn}
          btnLabelClassName={styles.partiesSelectionBtnLabel}
          options={FIRST_PARTY_TYPES.map((firstPartyType) => ({
            optionValue: firstPartyType.id,
            text: firstPartyType.desc,
          }))}
        />
      </div>
    </Grid>
  );
}

OpenFnolParties.propTypes = {
  partiesStore: PropTypes.object,
  onChangePartiesStore: PropTypes.func.isRequired,
};

function FnolPartiesCard(props) {
  const { setFieldValue, values, errors, touched, partiesStore, onChangePartiesStore } = props;
  const classes = useStyles();
  const { incidentConfiguration } = useIncidentConfiguration();
  const disabled = partiesStore.isFetching;
  const partiesStoreData = partiesStore.data;

  const firstParty = values['first_party'];
  const thirdPartyVehicles = values['third_party_vehicles'];
  const thirdPartyNonMotorists = values['third_party_non_motorists'];
  const thirdPartyOtherProperties = values['third_party_other_properties'];

  const handleUpdateVehicleParty = (vehiclePartyId, operationType, data) => {
    try {
      const vehicleParty = getIn(values, vehiclePartyId);
      if (vehicleParty === null || vehicleParty === undefined) {
        throw Error(`Unknown vehiclePartyId: ${vehiclePartyId}`);
      }

      switch (operationType) {
        case 'SET_INVOLVED_VEHICLE':
          if (vehicleParty.involved_vehicle) {
            throw Error(`Involved Vehicle already defined, vehiclePartyId: ${vehiclePartyId}`);
          }

          setFieldValue(vehiclePartyId, { ...vehicleParty, involved_vehicle: data });
          break;
        case 'UPDATE_INVOLVED_VEHICLE':
          if (!vehicleParty.involved_vehicle) {
            throw Error(`Involved Vehicle isn't defined, vehiclePartyId: ${vehiclePartyId}`);
          }

          setFieldValue(vehiclePartyId, { ...vehicleParty, involved_vehicle: data });
          break;
        case 'DELETE_INVOLVED_VEHICLE':
          if (!vehicleParty.involved_vehicle) {
            throw Error(`Involved Vehicle isn't defined, vehiclePartyId: ${vehiclePartyId}`);
          }

          setFieldValue(vehiclePartyId, { ...vehicleParty, involved_vehicle: null });
          break;
        case 'SET_DRIVER':
          if (vehicleParty.driver) {
            throw Error(`Driver already defined, vehiclePartyId: ${vehiclePartyId}`);
          }

          setFieldValue(vehiclePartyId, { ...vehicleParty, driver: data });
          break;

        case 'UPDATE_DRIVER':
          if (!vehicleParty.driver) {
            throw Error(`Driver isn't defined, vehiclePartyId: ${vehiclePartyId}`);
          }

          setFieldValue(vehiclePartyId, { ...vehicleParty, driver: data });
          break;

        case 'DELETE_DRIVER':
          if (!vehicleParty.driver) {
            throw Error(`Driver isn't defined, vehiclePartyId: ${vehiclePartyId}`);
          }
          setFieldValue(vehiclePartyId, { ...vehicleParty, driver: null });
          break;

        case 'ADD_PASSENGER':
          setFieldValue(vehiclePartyId, { ...vehicleParty, passengers: [...vehicleParty.passengers, data] });
          break;

        case 'UPDATE_PASSENGER':
          // data = { passenger, idx }
          setFieldValue(vehiclePartyId, {
            ...vehicleParty,
            passengers: vehicleParty.passengers.map((p, idx) => (idx === data.idx ? data.passenger : p)),
          });
          break;

        case 'DELETE_PASSENGER':
          // data = { passenger, idx }
          setFieldValue(vehiclePartyId, {
            ...vehicleParty,
            passengers: vehicleParty.passengers.filter((_, idx) => idx !== data.idx),
          });
          break;

        default:
          throw Error(`Should not happen Unknown ${operationType}`);
      }
    } catch (error) {
      reportGenericException(error);
    }
  };

  const firstPartyType = partiesStore.data?.insured_party.insured_party_type;

  return (
    <>
      <OpenFnolParties partiesStore={partiesStore} onChangePartiesStore={onChangePartiesStore} />
      <>
        <Grid item xs={12}>
          <div className={classes.cardDivRowInternal}>
            {firstPartyType === 'vehicle' ? (
              <VehicleParty
                classes={classes}
                title="Insured Party Vehicle"
                isInsured={true}
                disabled={disabled}
                errors={touched['first_party'] && errors['first_party']}
                vehicleParty={firstParty}
                onFetchInvolvedPerson={async (involvedHeader) => await involvedHeader}
                onFetchInvolvedProperty={async (involvedHeader) => await involvedHeader}
                onUpdateVehicle={(operation, data) =>
                  Promise.resolve(handleUpdateVehicleParty('first_party', operation, data))
                }
                allowDeleteInvolved
                shouldHidePassengers={!values['first_party'].are_passengers_involved}
              />
            ) : (
              <NonMotoristDetails
                classes={classes}
                title="Insured Party Non Motorist"
                disabled={disabled}
                nonMotoristParty={firstParty}
                onFetchNonMotoristDetails={(involvedHeader) => involvedHeader}
                onSaveNonMotoristDetails={async (nonMotorist) =>
                  await setFieldValue('first_party', { ...firstParty, involved_non_motorist: nonMotorist })
                }
                errors={touched['first_party'] && errors['first_party']}
              />
            )}
          </div>
          {thirdPartyVehicles.map(
            (thirdPartyVehicle, curIdx) =>
              (partiesStoreData.third_party_vehicles.length > curIdx ||
                partiesStoreData.allow_add_third_party_vehicles) && (
                <div className={classes.cardDivRowInternal} key={thirdPartyVehicle.id}>
                  <VehicleParty
                    classes={classes}
                    title={`3rd Party Vehicle (${curIdx + 1})`}
                    disabled={disabled}
                    vehicleParty={thirdPartyVehicle}
                    onFetchInvolvedPerson={async (involvedHeader) => await involvedHeader}
                    onFetchInvolvedProperty={async (involvedHeader) => await involvedHeader}
                    onUpdateVehicle={(operation, data) =>
                      Promise.resolve(handleUpdateVehicleParty(`third_party_vehicles[${curIdx}]`, operation, data))
                    }
                    onDeleteVehicle={
                      curIdx >= partiesStoreData.third_party_vehicles.length ||
                      partiesStoreData.should_allow_delete_third_party_vehicles
                        ? () =>
                            Promise.resolve(
                              setFieldValue(
                                'third_party_vehicles',
                                values.third_party_vehicles.filter((_, idx) => curIdx !== idx)
                              )
                            )
                        : undefined
                    }
                    allowDeleteInvolved
                    isInsured={false}
                    shouldHidePassengers={!values['third_party_vehicles'][curIdx].are_passengers_involved}
                  />
                </div>
              )
          )}
          {thirdPartyNonMotorists.map((nonMotoristParty, curIdx) => {
            const partyNonMotoristIndex = thirdPartyNonMotorists.findIndex(
              (thirdPartyNonMotorist) => thirdPartyNonMotorist.id === nonMotoristParty.id
            );

            return (
              <div className={classes.cardDivRowInternal} key={nonMotoristParty.id}>
                <NonMotoristDetails
                  classes={classes}
                  title={`3rd Party Non Motorist (${curIdx + 1})`}
                  disabled={disabled}
                  nonMotoristParty={nonMotoristParty}
                  onFetchNonMotoristDetails={(involvedHeader) => involvedHeader}
                  onSaveNonMotoristDetails={async (nonMotorist) =>
                    await setFieldValue(`third_party_non_motorists[${partyNonMotoristIndex}]`, {
                      ...nonMotoristParty,
                      involved_non_motorist: nonMotorist,
                    })
                  }
                  onDeleteNonMotorist={
                    curIdx >= partiesStoreData['third_party_non_motorists'].length
                      ? async () =>
                          await setFieldValue(
                            'third_party_non_motorists',
                            values.third_party_non_motorists.filter((_, idx) => idx !== partyNonMotoristIndex)
                          )
                      : undefined
                  }
                />
              </div>
            );
          })}
          {thirdPartyOtherProperties.map((involvedPropertyParty, curIdx) => (
            <div className={classes.cardDivRowInternal} key={involvedPropertyParty.id}>
              <InvolvedPropertyParty
                title={`3rd Party Property - ${curIdx + 1}`}
                propertyLabel="Property"
                involvedPropertyParty={involvedPropertyParty}
                onFetchInvolvedPropertyDetails={async (involvedHeader) => await involvedHeader}
                onSaveInvolvedPropertyDetails={(involvedPropertyParty) =>
                  Promise.resolve(setFieldValue(`third_party_other_properties[${curIdx}]`, involvedPropertyParty))
                }
                onDeleteInvolvedProperty={() =>
                  Promise.resolve(
                    setFieldValue(
                      'third_party_other_properties',
                      values.third_party_other_properties.filter((_, idx) => curIdx !== idx)
                    )
                  )
                }
                disabled={disabled}
                InvolvedPropertyFragmentOverride={InvolvedPropertyFragment}
                involvedPropertyValidationFields={getInvolvedPropertyValidationFields(incidentConfiguration)}
              />
            </div>
          ))}
          <div className={classes.buttonsContainer}>
            <Button
              onClick={() =>
                setFieldValue('third_party_other_properties', [
                  ...thirdPartyOtherProperties,
                  { ...involvedPropertyFields, id: uuidv4() },
                ])
              }
            >
              <AddIcon />
              Add Property
            </Button>
            <Button
              onClick={() =>
                setFieldValue('third_party_non_motorists', [
                  ...thirdPartyNonMotorists,
                  { ...involvedNonMotoristFields, id: uuidv4() },
                ])
              }
            >
              <AddIcon />
              Add Non Motorist
            </Button>
            <Button
              onClick={() =>
                setFieldValue('third_party_vehicles', [
                  ...thirdPartyVehicles,
                  { ...vehiclePartyFields, id: uuidv4(), are_passengers_involved: 'True' },
                ])
              }
            >
              <AddIcon />
              Add Vehicle
            </Button>
          </div>
        </Grid>
      </>
    </>
  );
}

FnolPartiesCard.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  partiesStore: PropTypes.object.isRequired,
  onChangePartiesStore: PropTypes.func.isRequired,
  isOpenFnol: PropTypes.bool,
};

const AutoInvolvedPartiesCardFormik = ({ partiesStore, onChangePartiesStore }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FnolPartiesCard
          partiesStore={partiesStore}
          onChangePartiesStore={onChangePartiesStore}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          isOpenFnol
        />
      </Grid>
    </Grid>
  );
};

AutoInvolvedPartiesCardFormik.propTypes = {
  partiesStore: PropTypes.object.isRequired,
  onChangePartiesStore: PropTypes.func.isRequired,
};

export {
  AutoInvolvedPartiesCardFormik,
  autoInvolvedPartiesInitialValues,
  buildAutoInvolvedParties,
  getAutoInvolvedPartiesValidationSchema,
};
