const TSC_AUTO_LIABILITY_INDICATORS_DICT = {
  insured_at_fault: 'Insured at Fault',
  insured_not_at_fault: 'Insured not at Fault',
  insured_partially_at_fault: 'Insured partially at Fault',
  liability_undetermined: 'Liability Undetermined',
  catastrophe_related: 'Catastrophe Related',
  not_reported: 'Not Reported',
} as const;

export default TSC_AUTO_LIABILITY_INDICATORS_DICT;
