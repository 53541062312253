import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';

import styles from '../../sortableTable.module.scss';

const PaginationActions = ({ count, page, rowsPerPage, disabled, onPageChange }) => {
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <div className={styles.actionContainer}>
      <IconButton onClick={handleBackButtonClick} disabled={disabled || page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={disabled || page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

PaginationActions.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  disabled: PropTypes.bool,
  onPageChange: PropTypes.func,
};

export default PaginationActions;
