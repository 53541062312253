import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';

import { useStyles } from '~/assets/styles';
import cn from '~/Utils/cn';

/**
 This Component is a wrapper on MUI component.
 This component should be imported and not MUI directly!
**/

interface ClassKeyValue {
  [name: string]: string;
}

export interface ChipProps {
  className?: string;
  avatar?: React.ReactElement;
  clickable?: boolean;
  color?: 'primary' | 'secondary' | 'default';
  deleteIcon?: React.ReactElement;
  disabled?: boolean;
  icon?: React.ReactElement;
  label?: React.ReactNode;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  size?: 'small' | 'medium';
  variant?: 'default' | 'outlined';
  classes?: ClassKeyValue;
  component?: React.ElementType | undefined;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Chip: React.VFC<ChipProps> = ({ className, ...chipProps }) => {
  const classes = useStyles();

  return <MuiChip {...chipProps} className={cn(classes.chip, className)} />;
};

export default Chip;
