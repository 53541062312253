import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const PhysicalMailNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 17.875C11.5 15.1826 13.6826 13 16.375 13C16.5012 13 16.6262 13.0048 16.75 13.0142V13H27.25C30.1495 13 32.5 15.3505 32.5 18.25V25H25V30.25C25 30.6642 24.6642 31 24.25 31C23.8358 31 23.5 30.6642 23.5 30.25V25H11.5V17.875ZM19.75 23.5V18.625V17.875C19.75 16.011 18.239 14.5 16.375 14.5C14.511 14.5 13 16.011 13 17.875V18.625V23.5H19.75ZM13 18.625C13 18.2108 13.3358 17.875 13.75 17.875H19C19.4142 17.875 19.75 18.2108 19.75 18.625C19.75 19.0392 19.4142 19.375 19 19.375H13.75C13.3358 19.375 13 19.0392 13 18.625ZM21.25 23.5H31V18.25C31 16.1789 29.3211 14.5 27.25 14.5H19.8928C20.7334 15.3759 21.25 16.5651 21.25 17.875V23.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default PhysicalMailNoteIcon;
