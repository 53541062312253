import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';

import { Text } from '../../core';

import styles from './additionalFinanceTableMenuOptions.module.scss';

const ErrorComponent = ({ message }) => {
  return (
    <div className={styles.errorContainer}>
      <ErrorIcon fontSize="large" />
      <Text
        variant={Text.VARIANTS.SM}
        colorVariant={Text.COLOR_VARIANTS.PRIMARY}
        weight={Text.WEIGHTS.REGULAR}
        className={styles.text}
      >
        {message}
      </Text>
    </div>
  );
};

ErrorComponent.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorComponent;
