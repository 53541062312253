import React from 'react';
import PropTypes from 'prop-types';

import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import Text from '~/components/core/TextComponents/Text';

import styles from './addExposures.module.scss';

const OWNER_ASSIGNMENT_TYPES = [
  {
    text: 'Run Automatic Rules',
    value: true,
  },
  {
    text: 'Select Handling Adjuster',
    value: false,
  },
];

export const ChooseOwnerAssignment = ({ formikId, disabled = false }) => {
  return (
    <div className={styles.selectOwnerAssignmentContainer}>
      <div className={styles.ownerAssignmentTitle}>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          Owner Assignment
        </Text>
      </div>
      <RadioButtonGroupFormik
        label=""
        id={formikId}
        direction="row"
        options={OWNER_ASSIGNMENT_TYPES.map((type) => ({
          text: type.text,
          optionValue: type.value,
          disabled,
        }))}
        btnClassName={styles.ownerAssignmentBtnClassName}
        btnContainerClassName={styles.ownerAssignmentBtnContainerClassName}
      />
    </div>
  );
};

ChooseOwnerAssignment.propTypes = {
  formikId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
