import React from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useClaim } from '~/components/ClaimContainer';
import CommunicationCard from '~/components/communications/CommunicationCard';
import CommunicationActionSendNew from '~/components/communications/CommunicationCard/CommunicationActionSendNew';
import useCreateCommunication from '~/components/communications/CommunicationCard/useCreateCommunication';
import type { VideoCallCommunication } from '~/components/communications/types';
import VideoCallSpecificBody from '~/components/communications/VideoCommunication/VideoCallSpecificBody';
import type { ContactFullModel } from '~/components/Contacts/types';
import { reportAxiosError } from '~/Utils';

interface Props {
  setNewVideoCallData: React.Dispatch<React.SetStateAction<VideoCallCommunication | null>>;
  cardTitle: string;
  onCancel: () => void;
  contact: ContactFullModel;
}
const PreVideoCommunicationCard: React.FC<Props> = ({ setNewVideoCallData, cardTitle, onCancel, contact }) => {
  const { communicationCommonInitialValues } = useCreateCommunication('video_call', { contact });
  const { claim } = useClaim();
  return (
    <Formik
      initialValues={{ ...communicationCommonInitialValues, provider: 'Dyte' }}
      validationSchema={Yup.object().shape({
        contact_id: Yup.number().required('Required'),
        exposure_ids: Yup.array().required('Required').min(1, 'Required'),
        summary: Yup.string(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          const res = await axios.post(
            `/api/v1/claims/${claim.id}/contacts/${values?.contact?.id}/create_video_call`,
            values
          );
          setNewVideoCallData(res.data);
        } catch (error) {
          formikProps.setSubmitting(false);
          reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        const { values } = formikProps;
        return (
          <CommunicationCard
            title={cardTitle}
            communicationInitialFields={{
              communicationChannel: 'video_call',
              communicationContact: values.contact,
              communicationDirection: 'Outgoing',
            }}
            communicationTypeSpecificBodyProps={{ showActionButtons: false }}
            CommunicationTypeSpecificBody={VideoCallSpecificBody}
            communicationHeaderAdditionalProps={{
              isDynamicContact: !contact,
              dynamicContactLabel: 'Call To',
            }}
            isDialog
            isNew
            maxWidth="sm"
            onClose={onCancel}
            CommunicationAction={CommunicationActionSendNew}
            communicationActionProps={{
              onSubmit: () => {
                formikProps.handleSubmit();
              },
              isSubmitting: formikProps.isSubmitting,
              buttonText: 'Generate Video Meeting',
            }}
          />
        );
      }}
    </Formik>
  );
};

export default PreVideoCommunicationCard;
