import React from 'react';

import Grid from '~/components/core/Atomic/Grid/Grid';
import SafeComponent from '~/components/core/SafeComponent';

const SectionWrapper: React.FC = ({ children = <></> }: { children?: React.ReactNode }) => (
  <SafeComponent>
    <Grid container className="relative left-[300px] w-[1024px] py-12">
      <Grid item xs={12} className="w-full">
        {children}
      </Grid>
    </Grid>
  </SafeComponent>
);

export default SectionWrapper;
