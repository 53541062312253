import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const TextAlignRightIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M3.21289 6C3.21289 5.80109 3.29191 5.61032 3.43256 5.46967C3.57321 5.32902 3.76398 5.25 3.96289 5.25H20.4629C20.6618 5.25 20.8526 5.32902 20.9932 5.46967C21.1339 5.61032 21.2129 5.80109 21.2129 6C21.2129 6.19891 21.1339 6.38968 20.9932 6.53033C20.8526 6.67098 20.6618 6.75 20.4629 6.75H3.96289C3.76398 6.75 3.57321 6.67098 3.43256 6.53033C3.29191 6.38968 3.21289 6.19891 3.21289 6ZM20.4629 9H8.46289C8.26398 9 8.07321 9.07902 7.93256 9.21967C7.79191 9.36032 7.71289 9.55109 7.71289 9.75C7.71289 9.94891 7.79191 10.1397 7.93256 10.2803C8.07321 10.421 8.26398 10.5 8.46289 10.5H20.4629C20.6618 10.5 20.8526 10.421 20.9932 10.2803C21.1339 10.1397 21.2129 9.94891 21.2129 9.75C21.2129 9.55109 21.1339 9.36032 20.9932 9.21967C20.8526 9.07902 20.6618 9 20.4629 9ZM20.4629 12.75H3.96289C3.76398 12.75 3.57321 12.829 3.43256 12.9697C3.29191 13.1103 3.21289 13.3011 3.21289 13.5C3.21289 13.6989 3.29191 13.8897 3.43256 14.0303C3.57321 14.171 3.76398 14.25 3.96289 14.25H20.4629C20.6618 14.25 20.8526 14.171 20.9932 14.0303C21.1339 13.8897 21.2129 13.6989 21.2129 13.5C21.2129 13.3011 21.1339 13.1103 20.9932 12.9697C20.8526 12.829 20.6618 12.75 20.4629 12.75ZM20.4629 16.5H8.46289C8.26398 16.5 8.07321 16.579 7.93256 16.7197C7.79191 16.8603 7.71289 17.0511 7.71289 17.25C7.71289 17.4489 7.79191 17.6397 7.93256 17.7803C8.07321 17.921 8.26398 18 8.46289 18H20.4629C20.6618 18 20.8526 17.921 20.9932 17.7803C21.1339 17.6397 21.2129 17.4489 21.2129 17.25C21.2129 17.0511 21.1339 16.8603 20.9932 16.7197C20.8526 16.579 20.6618 16.5 20.4629 16.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default TextAlignRightIcon;
