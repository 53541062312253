import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const CardProtectionIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      d="M12.1649 8.67346C14.4448 7.15453 14.2342 4.60748 13.8105 3H14.6576L15.6655 3.14184V5.6949L15.4537 6.68775L13.8105 8.67346C12.6402 8.92135 11.7699 8.93663 12.1649 8.67346Z"
      fill={colors.lobIconSecondary}
    />
    <rect x="2.66602" y="11" width="12" height="2" fill="#D6F3F5" />
    <path
      d="M3.66602 3.5C2.83759 3.5 2.16602 4.17157 2.16602 5V12C2.16602 12.8284 2.83759 13.5 3.66602 13.5H13.666C14.4944 13.5 15.166 12.8284 15.166 12V9.65714C15.166 9.381 14.9422 9.15714 14.666 9.15714C14.3899 9.15714 14.166 9.381 14.166 9.65714V12C14.166 12.2761 13.9422 12.5 13.666 12.5H3.66602C3.38987 12.5 3.16602 12.2761 3.16602 12V6.5H8.16602C8.44216 6.5 8.66602 6.27614 8.66602 6C8.66602 5.72386 8.44216 5.5 8.16602 5.5H3.16602V5C3.16602 4.72386 3.38987 4.5 3.66602 4.5H8.16602C8.44216 4.5 8.66602 4.27614 8.66602 4C8.66602 3.72386 8.44216 3.5 8.16602 3.5H3.66602Z"
      fill={colors.lobIconMain}
    />
    <path
      d="M6.66602 10.5C6.38987 10.5 6.16602 10.7239 6.16602 11C6.16602 11.2761 6.38987 11.5 6.66602 11.5H8.66602C8.94216 11.5 9.16602 11.2761 9.16602 11C9.16602 10.7239 8.94216 10.5 8.66602 10.5H6.66602Z"
      fill={colors.lobIconMain}
    />
    <path
      d="M10.166 11C10.166 10.7239 10.3899 10.5 10.666 10.5H12.666C12.9422 10.5 13.166 10.7239 13.166 11C13.166 11.2761 12.9422 11.5 12.666 11.5H10.666C10.3899 11.5 10.166 11.2761 10.166 11Z"
      fill={colors.lobIconMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1136 3.36488C10.0967 3.36488 10.0804 3.37134 10.0685 3.38285C10.0565 3.39435 10.0498 3.40995 10.0498 3.42622V5.22639C10.0498 7.67104 12.2042 8.48357 12.6472 8.62445L12.6535 8.62658C12.6621 8.62963 12.6715 8.62963 12.6801 8.62658L12.6864 8.62445C13.1294 8.48357 15.2838 7.67104 15.2838 5.22639V3.42622C15.2838 3.40995 15.2771 3.39435 15.2651 3.38285C15.2532 3.37134 15.2369 3.36488 15.22 3.36488H10.1136ZM9.66602 3C9.74981 2.91948 10.0475 2.92029 10.166 2.92029H15.1221C15.2406 2.92029 15.5522 2.8638 15.636 2.94431C15.7198 3.02483 15.6668 3.31235 15.6668 3.42622V5.22639C15.6668 7.92839 13.2798 8.82282 12.8096 8.97292C12.7173 9.00486 12.6163 9.00486 12.524 8.97292C12.0538 8.82282 9.6668 7.92839 9.6668 5.22639V3.42622C9.6668 3.31235 9.58222 3.08052 9.66602 3Z"
      fill={colors.lobIconMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.65909 2.97841C9.78095 2.86132 9.94458 2.79688 10.1136 2.79688H15.22C15.389 2.79688 15.5526 2.86132 15.6745 2.97841L15.636 2.94431L15.5347 3.12386M15.6745 2.97841C15.7966 3.09577 15.8668 3.25667 15.8668 3.42622V5.22639C15.8668 6.64507 15.237 7.5958 14.5471 8.20639C13.8642 8.81073 13.1227 9.08271 12.8725 9.16276C12.7394 9.20824 12.5942 9.20824 12.4611 9.16276C12.2109 9.08271 11.4694 8.81073 10.7865 8.20639C10.0966 7.5958 9.4668 6.64507 9.4668 5.22639V3.42622C9.4668 3.25667 9.53696 3.09577 9.65909 2.97841M15.636 2.94431L15.6745 2.97841L15.636 2.94431ZM10.2498 3.56488V5.22639C10.2498 6.3812 10.7555 7.14388 11.315 7.63964C11.8464 8.11048 12.4266 8.33983 12.6668 8.42044C12.907 8.33983 13.4872 8.11048 14.0186 7.63964C14.5781 7.14388 15.0838 6.3812 15.0838 5.22639V3.56488H10.2498Z"
      fill={colors.lobIconMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8609 4.70728C14.0217 4.81453 14.0651 5.03184 13.9579 5.19266L13.0929 6.48971C12.9004 6.77845 12.4918 6.81888 12.2464 6.57348L11.9189 6.24596C11.7822 6.10928 11.7822 5.88767 11.9189 5.75099C12.0556 5.6143 12.2772 5.6143 12.4139 5.75099L12.612 5.94914L13.3755 4.80429C13.4827 4.64347 13.7 4.60004 13.8609 4.70728Z"
      fill={colors.lobIconMain}
    />
  </SvgIcon>
);

export default CardProtectionIcon;
