import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';

import { DEFAULT_INCIDENT_DETAILS_FIELDS } from '../../Types';

import { useStyles } from '../../assets/styles';

const defaultFieldsFlatted = DEFAULT_INCIDENT_DETAILS_FIELDS.flat();

const filterEmptyFields = ({ name, desc }) => !!(name || desc);

const getOptionsTooltip = (type, options) =>
  `${type === 'select' ? 'Single Select Options:' : 'Multiple Select Options'}${
    options && options.length ? ` ${options.map((option) => option.desc).join(', ')}` : ''
  }`;

const getSummaryObjectForInvolvedParties = ({ desc, additional_data }) => ({
  name: desc,
  count: additional_data && additional_data.length,
  tooltipContent: additional_data
    .map(
      ({ type, options, desc: fieldDesc }) =>
        `${fieldDesc}${options && options.length ? ` (${getOptionsTooltip(type, options)})` : ''}`
    )
    .join(', '),
});

const getIncidentDetailsFields = (incident_details) => {
  if (!incident_details) {
    return [];
  }

  return [
    {
      name: 'Default Fields',
      count: defaultFieldsFlatted.length,
      tooltipContent: defaultFieldsFlatted.join(', '),
    },
    {
      name: 'Types and Subtypes',
      subFields: incident_details.types.map(({ desc, subtypes }) => ({
        name: desc,
        count: subtypes.filter(filterEmptyFields).length,
        tooltipContent: `Subtypes: ${subtypes.map((subtype) => subtype.desc).join(', ')}`,
      })),
    },
    {
      name: 'Additional Fields',
      subFields: incident_details.additional_data.map(({ desc, options, type }) => ({
        name: desc,
        count: options && options.filter(filterEmptyFields).length,
        tooltipContent: getOptionsTooltip(type, options),
      })),
    },
  ];
};

const getIncidentPartiesFields = (involved_parties) => {
  if (!involved_parties) {
    return [];
  }

  return [
    {
      name: 'Involved Persons',
      subFields: involved_parties.people.map(getSummaryObjectForInvolvedParties),
    },
    {
      name: 'Involved Properties',
      subFields: involved_parties.properties.map(getSummaryObjectForInvolvedParties),
    },
  ];
};

const CustomClaimConfigurationSummary = () => {
  const { values } = useFormikContext();
  const classes = useStyles();

  return (
    <div className={classes.summary}>
      <Typography style={{ paddingRight: '16px', marginBottom: '3px' }} display="block" variant="h6">
        SUMMARY
      </Typography>
      <SummarySection
        title="Incident Details"
        fields={getIncidentDetailsFields(values.configuration.incident_details)}
      />
      <SummarySection
        title="Incident Parties"
        fields={getIncidentPartiesFields(values.configuration.involved_parties)}
      />
    </div>
  );
};

const SummarySection = ({ title, fields }) => {
  const classes = useStyles();
  return (
    <>
      <Typography display="block" variant="subtitle1" className={classes.summarySectionTitle}>
        {title}
      </Typography>
      {fields.map(({ name, subFields, count, tooltipContent }) => {
        return (subFields && subFields.length) || count ? (
          <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column' }} key={name}>
            <div style={{ display: 'flex' }}>
              <Typography display="block" variant="subtitle1" className={classes.summarySubTitle}>
                {name}
              </Typography>
              {count && (
                <Tooltip arrow placement="top-start" title={tooltipContent}>
                  <Typography
                    display="block"
                    variant="subtitle1"
                    className={classes.summaryNumber}
                  >{`+${count}`}</Typography>
                </Tooltip>
              )}
            </div>
            {subFields &&
              subFields
                .filter(filterEmptyFields)
                .map(({ name: innerName, count: innerCount, tooltipContent: innerTooltipContent }) => {
                  return (
                    <div style={{ display: 'flex' }} key={innerName}>
                      <Typography
                        display="block"
                        variant="subtitle1"
                        className={classes.summarySubTitle}
                        style={{ fontWeight: 400 }}
                      >
                        {innerName}
                      </Typography>
                      {innerCount > 0 && (
                        <Tooltip arrow placement="top-start" title={innerTooltipContent}>
                          <Typography
                            display="block"
                            variant="subtitle1"
                            className={classes.summaryNumber}
                            style={{ fontWeight: 550 }}
                          >{`+${innerCount}`}</Typography>
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
          </div>
        ) : null;
      })}
    </>
  );
};

SummarySection.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
};

export default CustomClaimConfigurationSummary;
