import React, { useState } from 'react';
import { Menu } from '@material-ui/core';

import { useClaim } from '../../../ClaimContainer';
import { FsMenuItem } from '../../../core';
import HoverActionField from '../../../HoverActionField';
import { ThreeDotsIcon } from '../../../icons';
import ExternalClaimNumber from '../../ExternalClaimNumber';

import styles from '../claimPageBar.module.scss';

const ClaimNumberContainer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExternalClaimNumberDialogOpen, setIsExternalClaimNumberDialogOpen] = useState(false);
  const { claim } = useClaim();

  const handleOpenExternalClaimNumberDialog = () => {
    setAnchorEl(null);
    setIsExternalClaimNumberDialogOpen(true);
  };

  return (
    <>
      <span>
        Claim Number
        <div className={styles.claimNumber}>
          {claim.claim_id_display}
          <>
            <HoverActionField
              icon={ThreeDotsIcon}
              onAction={(event) => setAnchorEl(event.currentTarget)}
              permanent={true}
              ignorePermissions
              tooltipTitle="Manage External Claim Numbers"
            />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <FsMenuItem onClick={() => handleOpenExternalClaimNumberDialog()} disabled={false} ignorePermissions>
                Manage External Claim Numbers
              </FsMenuItem>
            </Menu>
          </>
        </div>
      </span>
      {isExternalClaimNumberDialogOpen && (
        <ExternalClaimNumber onClose={() => setIsExternalClaimNumberDialogOpen(false)} />
      )}
    </>
  );
};

export default ClaimNumberContainer;
