import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { Heading, Text } from '../../../../core';
import TextFieldFormik from '../../../../TextFieldFormik';

import styles from './additionalPaymentVerificationDialog.module.scss';

const ExceedIndemnityPolicyLimitDetailsFormikFragment = ({ checkDetails, responseFieldId, showReasonInput }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('is_override_limit', true);
  }, [setFieldValue]);

  return (
    <div className={styles.specificCheckContainer}>
      <div className={styles.internalContainer}>
        <Heading variant={Heading.TYPES.H4}>{checkDetails.issue_title}</Heading>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {checkDetails.issue_dialog_details}
        </Text>
      </div>
      {showReasonInput ? (
        <>
          <Text variant={Text.VARIANTS.SM}>Set payment over policy limit?</Text>
          <div className={styles.internalContainer}>
            <TextFieldFormik className={styles.reason} id={responseFieldId} label="Limit override Reason" fullWidth />
          </div>
        </>
      ) : null}
    </div>
  );
};

ExceedIndemnityPolicyLimitDetailsFormikFragment.propTypes = {
  checkDetails: PropTypes.object.isRequired,
  responseFieldId: PropTypes.string.isRequired,
  showReasonInput: PropTypes.bool.isRequired,
};

export default ExceedIndemnityPolicyLimitDetailsFormikFragment;
