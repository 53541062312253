import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { isoDateToUs, timeToLocalTime } from '../../DateTimeUtils';
import { getPolicyStatus, getPolicyStatusDuringLoss } from '../../PolicyUtils';
import { BIKE_COVERAGES_DICT } from '../../Types';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import PlainTable from '../PlainTable';
import { ShowOnlyTextField } from '../TextFieldFormik';

import { ShowOnlyBikeDetailsInfoCardDialog } from './BikeInvolvedCard';

import { useStyles } from '../../assets/styles';

function BikePolicyPage() {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const policy = claim.policy;

  return (
    <Grid container>
      <Grid item xs={7}>
        <div className={classes.cardDivRow}>
          <BikePolicyDetailsCard claim={claim} onClaimUpdate={onClaimUpdate} />
        </div>
      </Grid>
      <Grid item xs={5}>
        <div className={classes.cardDivRow}>
          <ShowOnlyBikeDetailsInfoCardDialog bike={policy.bike} title="Insured Bike Details" />
        </div>
      </Grid>
      <Grid item xs={7}>
        <div className={classes.cardDivRow}>
          <BikeCoverageTableCard policy={policy} />
        </div>
      </Grid>
    </Grid>
  );
}

function BikePolicyDetailsCard() {
  const { claim } = useClaim();
  const { policy } = claim;
  const classes = useStyles();
  const policyStatusDuringLoss = getPolicyStatusDuringLoss(
    policy,
    claim?.incident?.date_of_loss,
    claim?.incident?.time_of_loss
  );
  const policyStatus = getPolicyStatus(policyStatusDuringLoss);

  return (
    <>
      <CardDialog
        className={classes.CardDialog}
        title="Policy Details"
        subheader={
          <span style={{ color: policyStatus === 'In Force' ? 'green' : 'red' }}>
            <strong>{policyStatus}</strong>
          </span>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ShowOnlyTextField label="Policy Number" classes={classes} showOnlyValueComponent={policy.policy_number} />
          </Grid>
          <Grid item xs={4}>
            <ContactShowOnlyTextField
              contactId={policy.insured_contact_id}
              contactDisplayName={policy.insured_contact.full_name}
              label="Policy Holder"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Customer ID"
              classes={classes}
              showOnlyValueComponent={policy.insured_external_id}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Effective Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_effective_date) + ' ' + timeToLocalTime(policy.effective_time)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Expiration Date"
              classes={classes}
              showOnlyValueComponent={
                isoDateToUs(policy.policy_expiration_date) + ' ' + timeToLocalTime(policy.expiration_time)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Risk Carrier"
              classes={classes}
              showOnlyValueComponent={policy.underwriting_company}
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField label="Ops Agent" classes={classes} showOnlyValueComponent={claim.ops_agent || ''} />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Eligibility Check Date"
              classes={classes}
              showOnlyValueComponent={policy.eligibility_check_date ? isoDateToUs(policy.eligibility_check_date) : ''}
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Depreciation During First 3 Years"
              classes={classes}
              showOnlyValueComponent={
                <>
                  {policy.depreciation_included === true && 'Yes'}
                  {policy.depreciation_included === false && 'No'}
                </>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <ShowOnlyTextField
              label="Version Number"
              classes={classes}
              showOnlyValueComponent={policy.version_number || ''}
            />
          </Grid>
        </Grid>
      </CardDialog>
    </>
  );
}

BikePolicyDetailsCard.propTypes = {
  showOnly: PropTypes.bool,
};

function BikeCoverageTableCard({ policy }) {
  const { currencyFormatter } = useCurrencyFormatter();
  const bikeCoverageColumns = [
    { id: 'coverage', label: 'Coverage', specialCell: ({ coverage_type }) => BIKE_COVERAGES_DICT[coverage_type].desc },
    {
      id: 'limit',
      label: 'Limit',
      specialCell: ({ coverage_type }) =>
        policy[`${coverage_type}_limit`] !== null
          ? currencyFormatter.format(policy[`${coverage_type}_limit`])
          : undefined,
    },
    {
      id: 'deductible',
      label: 'Deductible',
      specialCell: ({ coverage_type }) => currencyFormatter.format(policy[`${coverage_type}_deductible`]),
    },
    {
      id: 'deductible_type',
      label: 'Deductible Type',
      specialCell: ({ coverage_type }) => policy[`${coverage_type}_deductible_type`] || '',
    },
  ];

  return (
    <CardDialog title="Coverage Details">
      <PlainTable
        columns={bikeCoverageColumns}
        rows={Object.keys(BIKE_COVERAGES_DICT).map((coverage_type, idx) => {
          return { id: idx, coverage_type };
        })}
      />
    </CardDialog>
  );
}

BikeCoverageTableCard.propTypes = {
  policy: PropTypes.object.isRequired,
};

export default BikePolicyPage;
