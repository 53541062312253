import React from 'react';
import PropTypes from 'prop-types';

import ExpensesPayeesFormikShowOnly from './ExpensesPayeesFormikShowOnly';
import IndemnityPayeesFormikShowOnly from './IndemnityPayeesFormikShowOnly';

// helper component that shows all payees - assumes we're under Formik context
function PayeesFormikShowOnly(props) {
  const { claim, exposure, payableType, paymentRequest } = props;

  if (payableType === 'expenses') {
    return <ExpensesPayeesFormikShowOnly claim={claim} paymentRequest={paymentRequest} />;
  } else {
    return <IndemnityPayeesFormikShowOnly claim={claim} exposure={exposure} paymentRequest={paymentRequest} />;
  }
}

PayeesFormikShowOnly.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  paymentRequest: PropTypes.object.isRequired,
};

export default PayeesFormikShowOnly;
