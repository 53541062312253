import React from 'react';

import type { VideoCallCommunication } from '~/components/communications/types';
import VideoCallsDyteDialog from '~/components/communications/VideoCommunication/Dyte/VideoCallsDyteDialog';
import type { ProvidersUnion } from '~/components/communications/VideoCommunication/types';
import { reportErrorInProductionOrThrow } from '~/Utils';

interface Props {
  provider: ProvidersUnion;
  communication: VideoCallCommunication;
  onClose: () => void;
}

const VideoCallProviderSpecificDialog: React.FC<Props> = ({ provider, communication, onClose }) => {
  if (provider === 'Dyte') {
    return <VideoCallsDyteDialog communication={communication} onClose={onClose} />;
  }
  reportErrorInProductionOrThrow(`Unimplemented video provider passed to VideoCallProviderSpecificDialog ${provider}`);
  return null;
};

export default VideoCallProviderSpecificDialog;
