import React from 'react';
import PropTypes from 'prop-types';

import AlertBanner from '~/components/core/AlertBanner';

const EmailSpoofingVerificationAlertBanner = ({ communication = {} }) => {
  switch (communication.email_verification_status) {
    case 'fail':
      return (
        <AlertBanner
          alertType={AlertBanner.ALERT_TYPES.ERROR}
          title="This email seems dangerous"
          note="This email appears suspicious and may be spoofed. Avoid clicking links, downloading attachments, or replying with personal information"
        />
      );
    case 'warning':
      return (
        <AlertBanner
          alertType={AlertBanner.ALERT_TYPES.WARNING}
          title="Be careful with this message"
          note="This email might be suspicious. Exercise caution, avoid clicking links, and refrain from sharing sensitive information."
        />
      );
    default:
      return <></>;
  }
};

EmailSpoofingVerificationAlertBanner.propTypes = {
  communication: PropTypes.object,
};

export default EmailSpoofingVerificationAlertBanner;
