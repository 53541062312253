import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Text } from '~/components/core';

import styles from './additionalPaymentVerificationDialog.module.scss';

const UkBankValidationFormikFragment = ({ checkDetails }) => {
  return (
    <div className={styles.specificCheckContainer}>
      <div className={styles.internalContainer}>
        <Heading variant={Heading.TYPES.H4}>{checkDetails.issue_title}</Heading>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {checkDetails.issue_dialog_details}
        </Text>
      </div>
    </div>
  );
};

UkBankValidationFormikFragment.propTypes = {
  checkDetails: PropTypes.object.isRequired,
};

export default UkBankValidationFormikFragment;
