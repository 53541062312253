import React, { Fragment, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import LoadingDialog from '~/components/LoadingDialog';

import { CONFIGURATION_FEATURES_NAMES } from '../Types';
import { isFeatureEnabled } from '../Utils';

import { getAllClaimRelatedRoles } from './communications/ContactUtils';
import { InvolvedPropertyItems } from './Fnol/NewFnolUI/InvolvedParties/InvolvedPropertyParty/InvolvedPropertyItems';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import WithConfirm from './ConfirmModal';
import { ContactEntity } from './Contact';
import ContactTextFieldFormik from './ContactTextFieldFormik';
import {
  FsIconButton,
  PERMISSION_ACTIONS,
  PERMISSION_VERBS,
  PermissionsButtonWrapper,
  RestrictedPermissions,
} from './core';
import { TrashIcon } from './icons';
import InvolvedWrapper from './InvolvedWrapper';
import useOrganization from './OrganizationContext';
import { MultiSelectTextFieldFormik, TextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';
import styles from './Fnol/NewFnolUI/InvolvedParties/index.module.scss';

const involvedPropertyFields = {
  property_name: '',
  owner_contact_id: '',
  owner_contact_full_name: '',
  attorney_contact_id: '',
  attorney_reference_number: '',
  insurer_contact_id: '',
  insurer_reference_number: '',
  insurer_policy_number: '',
  damages: [],
  location: {},
  owner_contact: null,
  attorney_contact: null,
  insurer_contact: null,
};

const defaultInvolvedPropertyValidationFields = {
  property_name: Yup.string().required('Required'),
  owner_contact_id: Yup.number().required('Required'),
};

function InvolvedPropertyDialog(props) {
  const {
    dialogAction,
    disableEditIdentity,
    property,
    onCancel,
    onSubmit,
    open,
    showOnly,
    title,
    InvolvedPropertyFragmentOverride = InvolvedPropertyFragment,
    involvedPropertyValidationFields = undefined,
  } = props;
  const { userOrganization } = useCms();
  const isNewFnolUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);

  if (!open) {
    return <></>;
  }

  const propertyInitialValues = property ? property : { ...involvedPropertyFields };
  const propertyValidationFields = involvedPropertyValidationFields
    ? involvedPropertyValidationFields
    : defaultInvolvedPropertyValidationFields;

  return (
    <Formik
      initialValues={propertyInitialValues}
      validationSchema={Yup.object().shape({ ...propertyValidationFields })}
      onSubmit={(values, formikProps) => {
        onSubmit(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title={title}
            maxWidth={isNewFnolUIEnabled ? 'md' : 'sm'}
            onClose={onCancel}
            preventClose={isSubmitting}
            action={dialogAction}
            footerActions={
              <DialogFooterActions
                disabled={isSubmitting}
                onClickPrimary={showOnly ? onCancel : handleSubmit}
                onClickSecondary={onCancel}
                showSecondary={!showOnly}
                primaryLabel={showOnly ? 'Close' : 'Save'}
              />
            }
          >
            <InvolvedPropertyFragmentOverride
              disableEditIdentity={disableEditIdentity}
              showOnly={showOnly}
              {...formikProps}
            />
            {isNewFnolUIEnabled && <InvolvedPropertyItems />}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

InvolvedPropertyDialog.propTypes = {
  dialogAction: PropTypes.node,
  disableEditIdentity: PropTypes.bool,
  property: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
  InvolvedPropertyFragmentOverride: PropTypes.func,
  involvedPropertyValidationFields: PropTypes.object,
  InvolvedPropertyItems: PropTypes.func,
};

function ShowOnlyGenericInvolvedPropertyDialog(props) {
  const { involvedProperty, onCancel, open } = props;

  if (!open) {
    return <></>;
  }

  return (
    <Formik initialValues={involvedProperty} onSubmit={() => {}}>
      {(formikProps) => (
        <CardDialog isDialog={true} title="Property Details" maxWidth="sm" onClose={onCancel}>
          <InvolvedPropertyFragment showOnly {...formikProps} />
        </CardDialog>
      )}
    </Formik>
  );
}

ShowOnlyGenericInvolvedPropertyDialog.propTypes = {
  involvedProperty: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function PreDefinedMultiSelectTextFieldFormik({ id, fieldConfig, isDisabled }) {
  const classes = useStyles();

  if (!fieldConfig || !fieldConfig?.active) {
    return null;
  }

  const optionsDict = Object.fromEntries(fieldConfig.options.map((opt) => [opt.id, opt]));

  const getDesc = (optionId) => optionsDict[optionId]?.desc || optionId;

  return (
    <MultiSelectTextFieldFormik
      id={id}
      label={fieldConfig.desc}
      options={Object.keys(optionsDict)}
      renderValue={(selected) => selected.map(getDesc).join(', ')}
      className={classes.textField}
      fullWidth
      renderOption={getDesc}
      disabled={isDisabled}
    />
  );
}

PreDefinedMultiSelectTextFieldFormik.propTypes = {
  id: PropTypes.string.isRequired,
  fieldConfig: PropTypes.object, // not putting isRequired - if field is newer than config, fieldConfig will be undefined
  isDisabled: PropTypes.bool,
};

function InvolvedPropertyFragment(props) {
  const { disableEditIdentity, showOnly } = props;
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextFieldFormik
          id="property_name"
          label="Property Name"
          fullWidth
          multiline
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <ContactTextFieldFormik
          id="owner_contact"
          label="Owner"
          className={classes.textField}
          fullWidth
          acceptedRoles={getAllClaimRelatedRoles(organizationContactRolesDict).filter(
            (role) => !['insured', 'witness'].includes(role)
          )}
          contactSearchProps={{ newContactRole: 'claimant' }}
          disabled={disableEditIdentity}
          showOnly={showOnly}
          fixedSearchResults
        />
      </Grid>
      <Grid item xs={6}>
        <ContactTextFieldFormik
          id="attorney_contact"
          label="Attorney"
          acceptedRoles={['attorney']}
          className={classes.textField}
          fullWidth
          showOnly={showOnly}
          fixedSearchResults
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id="attorney_reference_number"
          label="Attorney Reference Number"
          className={classes.textField}
          showOnly={showOnly}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <ContactTextFieldFormik
          id="insurer_contact"
          label="Adverse Carrier"
          acceptedRoles={['insurer']}
          className={classes.textField}
          fullWidth
          showOnly={showOnly}
          fixedSearchResults
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id="insurer_reference_number"
          label="Adverse Carrier Reference Number"
          className={classes.textField}
          showOnly={showOnly}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id="insurer_policy_number"
          label="Adverse Carrier Policy Number"
          className={classes.textField}
          showOnly={showOnly}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} />
    </Grid>
  );
}

InvolvedPropertyFragment.propTypes = {
  disableEditIdentity: PropTypes.bool,
  showOnly: PropTypes.bool,
};

function EditInvolvedPropertyDialog(props) {
  const {
    open,
    involvedProperty,
    onCancel,
    onSaveInvolvedDetails,
    propertyLabel,
    InvolvedPropertyFragmentOverride = undefined,
    involvedPropertyValidationFields = undefined,
  } = props;

  return (
    <InvolvedPropertyDialog
      disableEditIdentity={involvedProperty.is_locked}
      property={involvedProperty}
      onCancel={onCancel}
      onSubmit={onSaveInvolvedDetails}
      open={open}
      title={`Edit ${propertyLabel}`}
      InvolvedPropertyFragmentOverride={InvolvedPropertyFragmentOverride}
      involvedPropertyValidationFields={involvedPropertyValidationFields}
    />
  );
}

EditInvolvedPropertyDialog.propTypes = {
  open: PropTypes.bool,
  involvedProperty: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveInvolvedDetails: PropTypes.func.isRequired,
  propertyLabel: PropTypes.string.isRequired,
  InvolvedPropertyFragmentOverride: PropTypes.func,
  involvedPropertyValidationFields: PropTypes.object,
  InvolvedPropertyItems: PropTypes.func,
};

function InvolvedPropertySummary(props) {
  const {
    onFetchInvolvedPropertyDetails,
    onSaveInvolvedDetails,
    involvedProperty,
    onDeleteInvolvedProperty,
    propertyLabel,
    disabled,
    InvolvedPropertyFragmentOverride = undefined,
    involvedPropertyValidationFields = undefined,
  } = props;
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const classes = useStyles();
  const [involvedFetched, setInvolvedFetched] = React.useState();

  const openEditDialog = async () => {
    setEditDialogOpen(true);
    // backwards compatibility for old FNOL: if onFetchInvolvedPropertyDetails is not given, assume involvedProperty is full
    if (onFetchInvolvedPropertyDetails) {
      try {
        const propertyFullDetails = await onFetchInvolvedPropertyDetails(involvedProperty);
        setInvolvedFetched(propertyFullDetails);
      } catch {
        setEditDialogOpen(false);
      }
    } else {
      setInvolvedFetched(involvedProperty);
    }
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setInvolvedFetched(undefined);
  };

  return (
    <>
      <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
        <InvolvedWrapper
          onEdit={openEditDialog}
          disabled={disabled}
          involved={involvedProperty}
          involvedType="property"
        >
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            {involvedProperty.display_name || involvedProperty.property_name}
            <span style={{ paddingLeft: '10px' }}>
              <ContactEntity
                classes={classes}
                inline
                contactId={involvedProperty.owner_contact_id}
                contactDisplayName={involvedProperty.owner_contact_full_name}
              />
            </span>
          </span>
        </InvolvedWrapper>
      </RestrictedPermissions>

      {editDialogOpen &&
        (!involvedFetched ? (
          <LoadingDialog isError={false} track="EditInvolvedPropertyDialog" />
        ) : (
          <EditInvolvedPropertyDialog
            open
            involvedProperty={involvedFetched}
            propertyLabel={propertyLabel}
            onSaveInvolvedDetails={async (involvedProperty) => {
              await onSaveInvolvedDetails(involvedProperty);
              closeEditDialog();
            }}
            onDeleteInvolvedProperty={
              onDeleteInvolvedProperty &&
              (async () => {
                await onDeleteInvolvedProperty();
                closeEditDialog();
              })
            }
            onCancel={closeEditDialog}
            InvolvedPropertyFragmentOverride={InvolvedPropertyFragmentOverride}
            involvedPropertyValidationFields={involvedPropertyValidationFields}
          />
        ))}
    </>
  );
}

InvolvedPropertySummary.propTypes = {
  involvedProperty: PropTypes.object.isRequired,
  propertyLabel: PropTypes.string.isRequired,
  onFetchInvolvedPropertyDetails: PropTypes.func,
  onSaveInvolvedDetails: PropTypes.func.isRequired,
  onDeleteInvolvedProperty: PropTypes.func,
  disabled: PropTypes.bool,
  InvolvedPropertyFragmentOverride: PropTypes.func,
  involvedPropertyValidationFields: PropTypes.object,
  InvolvedPropertyItems: PropTypes.func,
};

function InvolvedPropertyParty(props) {
  const {
    title,
    disabled,
    involvedPropertyParty,
    onFetchInvolvedPropertyDetails,
    onSaveInvolvedPropertyDetails,
    onDeleteInvolvedProperty,
    propertyLabel,
    InvolvedPropertyFragmentOverride = undefined,
    involvedPropertyValidationFields = undefined,
  } = props;

  const [addDialogOpen, setAddDialogOpen] = React.useState(false);

  const action = onDeleteInvolvedProperty && (
    <PermissionsButtonWrapper verb={PERMISSION_VERBS.FULL} action={PERMISSION_ACTIONS.CONTACT}>
      <WithConfirm title="Delete Property?" primaryButtonName="Delete" shouldCloseOnPrimary>
        <FsIconButton onClick={onDeleteInvolvedProperty} disabled={disabled} icon={TrashIcon} />
      </WithConfirm>
    </PermissionsButtonWrapper>
  );

  return (
    <>
      <CardDialog
        title={
          <>
            {title} {action}
          </>
        }
        outlinedCard
      >
        {involvedPropertyParty.generic_property_involved ? (
          <InvolvedPropertySummary
            involvedProperty={involvedPropertyParty.generic_property_involved}
            propertyLabel={propertyLabel}
            onFetchInvolvedPropertyDetails={
              onFetchInvolvedPropertyDetails
                ? async () => await onFetchInvolvedPropertyDetails(involvedPropertyParty.generic_property_involved)
                : undefined
            }
            onSaveInvolvedDetails={(newInvolvedProperty) =>
              onSaveInvolvedPropertyDetails({ generic_property_involved: newInvolvedProperty })
            }
            onDeleteInvolvedProperty={onDeleteInvolvedProperty && (() => onDeleteInvolvedProperty())}
            disabled={disabled}
            InvolvedPropertyFragmentOverride={InvolvedPropertyFragmentOverride}
            involvedPropertyValidationFields={involvedPropertyValidationFields}
          />
        ) : (
          <div className={styles.buttonWrapper}>
            <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
              <Button
                className={styles.setButton}
                color="primary"
                disabled={disabled}
                onClick={() => setAddDialogOpen(true)}
              >
                Set Property
              </Button>
            </PermissionsButtonWrapper>
          </div>
        )}
      </CardDialog>

      <InvolvedPropertyDialog
        open={addDialogOpen}
        onCancel={() => setAddDialogOpen(false)}
        onSubmit={async (newInvolvedProperty) => {
          await onSaveInvolvedPropertyDetails({ generic_property_involved: newInvolvedProperty });
          setAddDialogOpen(false);
        }}
        title={`Add ${propertyLabel}`}
        InvolvedPropertyFragmentOverride={InvolvedPropertyFragmentOverride}
        involvedPropertyValidationFields={involvedPropertyValidationFields}
        property={involvedPropertyParty.generic_property_involved}
      />
    </>
  );
}

InvolvedPropertyParty.propTypes = {
  title: PropTypes.string.isRequired,
  propertyLabel: PropTypes.string.isRequired,
  involvedPropertyParty: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onFetchInvolvedPropertyDetails: PropTypes.func,
  onSaveInvolvedPropertyDetails: PropTypes.func.isRequired,
  onDeleteInvolvedProperty: PropTypes.func,
  InvolvedPropertyFragmentOverride: PropTypes.func,
  involvedPropertyValidationFields: PropTypes.object,
  InvolvedPropertyItems: PropTypes.func,
};

// TODO: Add disabled option (not used now)
function InvolvedPropertiesListDetails(props) {
  const {
    disableAddingProperty,
    onAddInvolvedProperty,
    involvedProperties,
    onSaveInvolvedPropertyDetails,
    onDeleteInvolvedProperty,
    allowDelete,
    propertyLabel,
    fullWidthAddButton = false,
    InvolvedPropertyFragmentOverride = undefined,
    involvedPropertyValidationFields = undefined,
  } = props;
  const [addInvolvedPropertyDialogOpen, setAddInvolvedPropertyDialogOpen] = useState(false);

  const classes = useStyles();
  return (
    <>
      {involvedProperties.map((involvedProperty, index) => (
        <Fragment key={index}>
          <InvolvedPropertySummary
            involvedProperty={involvedProperty}
            propertyLabel={propertyLabel}
            onSaveInvolvedDetails={(newInvolvedProperty) => onSaveInvolvedPropertyDetails(newInvolvedProperty, index)}
            onDeleteInvolvedProperty={allowDelete && (() => onDeleteInvolvedProperty(involvedProperty, index))}
            InvolvedPropertyFragmentOverride={InvolvedPropertyFragmentOverride}
            involvedPropertyValidationFields={involvedPropertyValidationFields}
          />
          <Divider />
        </Fragment>
      ))}

      <Grid container spacing={0}>
        <Grid item xs={fullWidthAddButton ? 12 : 6}>
          <PermissionsButtonWrapper>
            <Button
              color="primary"
              disabled={disableAddingProperty}
              onClick={() => setAddInvolvedPropertyDialogOpen(true)}
            >
              <AddIcon className={classes.rightButtonIcon} />
              Add {propertyLabel}
            </Button>
          </PermissionsButtonWrapper>
        </Grid>
      </Grid>

      <InvolvedPropertyDialog
        open={addInvolvedPropertyDialogOpen}
        onCancel={() => setAddInvolvedPropertyDialogOpen(false)}
        onSubmit={async (involvedProperty) => {
          await onAddInvolvedProperty(involvedProperty);
          setAddInvolvedPropertyDialogOpen(false);
        }}
        title={`Add ${propertyLabel}`}
        InvolvedPropertyFragmentOverride={InvolvedPropertyFragmentOverride}
        involvedPropertyValidationFields={involvedPropertyValidationFields}
      />
    </>
  );
}

InvolvedPropertiesListDetails.propTypes = {
  involvedProperties: PropTypes.array.isRequired,
  disableAddingProperty: PropTypes.bool,
  onSaveInvolvedPropertyDetails: PropTypes.func.isRequired,
  onAddInvolvedProperty: requiredIf(PropTypes.func, (props) => !props.disableAddingProperty),
  onDeleteInvolvedProperty: requiredIf(PropTypes.func, (props) => props.allowDelete),
  propertyLabel: PropTypes.string.isRequired,
  allowDelete: PropTypes.bool,
  fullWidthAddButton: PropTypes.bool,
  InvolvedPropertyFragmentOverride: PropTypes.func,
  involvedPropertyValidationFields: PropTypes.object,
};

export {
  EditInvolvedPropertyDialog,
  InvolvedPropertiesListDetails,
  InvolvedPropertyDialog,
  involvedPropertyFields,
  InvolvedPropertyFragment,
  InvolvedPropertyParty,
  InvolvedPropertySummary,
  ShowOnlyGenericInvolvedPropertyDialog,
};

export default InvolvedPropertyDialog;
