import type { ReactElement } from 'react';
import React from 'react';

import { serverDateToLocal } from '../../../DateTimeUtils';
import type { DocumentApiResponse, LegalActionLogBase } from '../types';

export const DateCell = <T extends LegalActionLogBase | DocumentApiResponse>({
  log,
  property,
}: {
  log: T;
  property: keyof T;
}): ReactElement => <div>{log[property] ? serverDateToLocal(log[property]) : ''}</div>;
