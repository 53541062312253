import { CONFIGURATION_FEATURES_NAMES } from '../../../Types';
import { isFeatureEnabled, reportErrorInProductionOrThrow } from '../../../Utils';

import { __DEFAULT_PROFILES__ } from './enums/DefaultProfiles';
import { __PERMISSION_ACTIONS__ } from './enums/PermissionActions';
import { __PERMISSION_VERBS__ } from './enums/PermissionVerbs';

const getImmutableObjectHandlerConfig = (objectType) => ({
  get: (target, name) => {
    if (name in target) {
      return name;
    }
    reportErrorInProductionOrThrow(`Permission ${objectType} ${name} is not defined`);
  },
  set: () => {
    reportErrorInProductionOrThrow(`Permission ${objectType}s are immutable`);
    return false;
  },
});

export const PERMISSION_ACTIONS = new Proxy(__PERMISSION_ACTIONS__, getImmutableObjectHandlerConfig('action'));

export const PERMISSION_VERBS = new Proxy(__PERMISSION_VERBS__, getImmutableObjectHandlerConfig('verb'));

/**
 * @type {PERMISSION_ACTIONS}
 */
export const PERMISSION_ACTIONS_CONFIG = __PERMISSION_ACTIONS__;
/**
 * @type {PERMISSION_VERBS}
 */
export const PERMISSION_VERBS_CONFIG = __PERMISSION_VERBS__;

// To avoid circular dependency we assign the permissions after the actions config and the default profiles are defined
Object.values(__DEFAULT_PROFILES__).forEach((profile) => profile.assignPermissions(PERMISSION_ACTIONS_CONFIG));
/**
 * @type {DEFAULT_PROFILES}
 */
export const DEFAULT_PROFILES_CONFIG = __DEFAULT_PROFILES__;

export const isVerbValid = (verb) => Object.keys(PERMISSION_VERBS).includes(verb);
export const isActionValid = (action) => Object.keys(PERMISSION_ACTIONS).includes(action);

export const getPermissionIdString = ({ action, verb }) =>
  action && verb ? `${action}:${verb}` : 'DEFAULT:WRITE or higher';

export const getPermissionDeniedTooltipText = ({ action, verb, actions }) => {
  let permissionIdString = getPermissionIdString({ action, verb });
  if (actions) {
    permissionIdString = actions.map(getPermissionIdString).join(', ');
  }

  return `Sorry, you don't have permission to access this feature.
Please contact your system administrator (Permission id: ${permissionIdString})`;
};

export const isPermissionsEnabled = (organization) => {
  return Boolean(organization && isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT));
};

const profileHasAction = (profile, action) => {
  return action in profile.permissions;
};

const profileHasVerbForAction = (profile, action, verbs) => {
  if (!profileHasAction(profile, action)) {
    return false;
  }
  const actionVerbs = profile.permissions[action];

  return actionVerbs.includes(PERMISSION_VERBS.FULL) || actionVerbs.filter((value) => verbs.includes(value)).length > 0;
};

export const isActionAllowed = ({ action, verb, profile, userOrganization }) => {
  // TODO: consider also checking for the general PERMISSIONS FF
  if (!isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT)) {
    return true;
  }

  if (!profile) {
    return false;
  }

  if (profileHasVerbForAction(profile, action, [verb, PERMISSION_VERBS.FULL])) {
    return true;
  }

  if (profileHasVerbForAction(profile, action, PERMISSION_VERBS.FORBIDDEN)) {
    return false;
  }

  if (!profileHasAction(profile, action)) {
    return profile.defaults.includes(PERMISSION_VERBS.FULL) || profile.defaults.includes(verb);
  }

  return false;
};
