import React, { useState } from 'react';
import { useCurrentEditor } from '@tiptap/react';

import TextColorIcon from '../../../../icons/editor/TextColorIcon';

import PopoverColorPicker from './PopoverColorPicker';

const TextColorComponent: React.FC = () => {
  const { editor } = useCurrentEditor();
  const [color, setColor] = useState('');

  const setTextColor = (hexColor: string) => {
    editor?.chain().focus().setColor(hexColor).run();
    setColor(hexColor);
  };

  if (!editor) {
    return null;
  }

  return (
    <PopoverColorPicker
      onChange={setTextColor}
      isEnabledFunc={() => !!editor?.can().setColor(color)}
      icon={<TextColorIcon />}
    />
  );
};

export default TextColorComponent;
