import React from 'react';

import colors from '~/assets/colors.module.scss';
import SvgIcon from '~/components/core/SvgIcon';

const GeneralPropertyIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path d="M15 6.5L8 14L5 6.5L8 2.5H11.5L15 6.5Z" fill={colors.lobIconSecondary} />
    <path
      d="M15.3758 6.17063L11.8758 2.17062C11.829 2.11718 11.7714 2.07431 11.7067 2.04489C11.6421 2.01547 11.5719 2.00017 11.5008 2H4.50083C4.42979 2.00017 4.3596 2.01547 4.29494 2.04489C4.23027 2.07431 4.17262 2.11718 4.12583 2.17062L0.625827 6.17063C0.544175 6.26385 0.500053 6.38407 0.502016 6.50798C0.503979 6.63189 0.551888 6.75066 0.636452 6.84125L7.63645 14.3413C7.68324 14.3914 7.73984 14.4314 7.80274 14.4587C7.86564 14.4861 7.93349 14.5002 8.00208 14.5002C8.07066 14.5002 8.13852 14.4861 8.20142 14.4587C8.26432 14.4314 8.32092 14.3914 8.3677 14.3413L15.3677 6.84125C15.4519 6.75035 15.4994 6.63141 15.5009 6.5075C15.5024 6.38359 15.4578 6.26354 15.3758 6.17063ZM13.899 6H11.2508L9.00083 3H11.274L13.899 6ZM4.66208 7L6.5452 11.7081L2.15145 7H4.66208ZM10.2621 7L8.00083 12.6538L5.73958 7H10.2621ZM6.00083 6L8.00083 3.33313L10.0008 6H6.00083ZM11.3396 7H13.8502L9.45645 11.7081L11.3396 7ZM4.7277 3H7.00083L4.75083 6H2.1027L4.7277 3Z"
      fill={colors.lobIconMain}
    />
  </SvgIcon>
);

export default GeneralPropertyIcon;
