import React from 'react';

import type { AddItemButtonProps } from '../../core/NewDesignSystem/Buttons/AddItemButton';
import { AddItemButton } from '../../core/NewDesignSystem/Buttons/AddItemButton';
import { useLegalAction } from '../hooks/useLegalAction';
import { getLegalActionStateProps } from '../LegalActionsUtils';

import type { LegalActionStateProps } from './LegalActionsCompoentsTypes';

export const LegalActionAddItemButton: React.FC<LegalActionStateProps & AddItemButtonProps> = ({
  ignoreLegalActionState,
  onClick,
  text,
  variant,
}) => {
  const { legalAction } = useLegalAction();
  const { isDisabled, tooltipText } = getLegalActionStateProps(legalAction, ignoreLegalActionState);

  return (
    <AddItemButton onClick={onClick} text={text} variant={variant} disabled={isDisabled} tooltipText={tooltipText} />
  );
};
