import React from 'react';

import GeneralEmailSentCommunicationTab from './communications/GeneralEmailSentCommunicationTab';
import InnerTabs from './core/Layout/InnerTabs/InnerTabs';
import GeneralEmailCommunicationsTab from './GeneralEmailCommunicationsTab';

const GeneralEmailClassification = () => {
  const tabs = [
    {
      label: 'Queue',
      url: 'queue',
      component: GeneralEmailCommunicationsTab,
    },
    {
      label: 'Sent',
      url: 'sent',
      component: GeneralEmailSentCommunicationTab,
    },
  ];

  return <InnerTabs tabs={tabs} />;
};

export default GeneralEmailClassification;
