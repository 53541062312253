import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const ContactNewIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3.75C4.5 2.92157 5.17157 2.25 6 2.25H19.5C20.3284 2.25 21 2.92157 21 3.75V20.25C21 21.0784 20.3284 21.75 19.5 21.75H6C5.17157 21.75 4.5 21.0784 4.5 20.25V18.375H3C2.58579 18.375 2.25 18.0392 2.25 17.625C2.25 17.2108 2.58579 16.875 3 16.875H4.5V14.625H3C2.58579 14.625 2.25 14.2892 2.25 13.875C2.25 13.4608 2.58579 13.125 3 13.125H4.5V10.875H3C2.58579 10.875 2.25 10.5392 2.25 10.125C2.25 9.71079 2.58579 9.375 3 9.375H4.5V7.125H3C2.58579 7.125 2.25 6.78921 2.25 6.375C2.25 5.96079 2.58579 5.625 3 5.625H4.5V3.75ZM6 20.25H19.5V3.75H6V20.25ZM12.75 8.25C11.5074 8.25 10.5 9.25736 10.5 10.5C10.5 11.7426 11.5074 12.75 12.75 12.75C13.9926 12.75 15 11.7426 15 10.5C15 9.25736 13.9926 8.25 12.75 8.25ZM15.2759 13.2718C16.028 12.5859 16.5 11.5981 16.5 10.5C16.5 8.42893 14.8211 6.75 12.75 6.75C10.6789 6.75 9 8.42893 9 10.5C9 11.5981 9.47197 12.5859 10.2241 13.2717C10.1145 13.319 10.0061 13.3695 9.89901 13.423C9.01381 13.8656 8.24381 14.5083 7.65 15.3C7.40147 15.6314 7.46863 16.1015 7.8 16.35C8.13137 16.5985 8.60147 16.5314 8.85 16.2C9.30409 15.5945 9.89291 15.1031 10.5698 14.7647C11.2468 14.4262 11.9932 14.25 12.75 14.25C13.5068 14.25 14.2532 14.4262 14.9302 14.7647C15.6071 15.1031 16.1959 15.5945 16.65 16.2C16.8985 16.5314 17.3686 16.5985 17.7 16.35C18.0314 16.1015 18.0985 15.6314 17.85 15.3C17.2562 14.5082 16.4862 13.8656 15.601 13.423C15.4939 13.3695 15.3855 13.319 15.2759 13.2718Z"
      fill={iconColor}
    />
  </SvgIcon>
);

ContactNewIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default ContactNewIcon;
