import React from 'react';
import PropTypes from 'prop-types';

import Button from '~/components/core/Atomic/Buttons/Button';
import cn from '~/Utils/cn';

import CardDialog from '../CardDialog';

import { useStyles } from '../../assets/styles';
import styles from './TopLevelDialog.module.scss';

const TopLevelDialog = ({ isOpen, title, message, contentComponent, showOkButton, onClose }) => {
  const classes = useStyles();

  return (
    <>
      {isOpen && (
        <CardDialog isDialog open={isOpen} title={title} onClose={onClose}>
          {message && <span className={styles.message}>{message}</span>}
          {contentComponent}
          {showOkButton && (
            <div className={styles.buttonContainer}>
              <Button
                className={cn(classes.button, styles.button)}
                variant="contained"
                color="primary"
                onClick={onClose}
              >
                Ok
              </Button>
            </div>
          )}
        </CardDialog>
      )}
    </>
  );
};

TopLevelDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  contentComponent: PropTypes.object,
  showOkButton: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TopLevelDialog;
