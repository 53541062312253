import React from 'react';

import useCurrencyFormatter from '../../CurrencyFormatterContext';
import type { CurrencyFormatterType } from '../types';

export const CurrencyCell: React.FC<{ value?: number | null }> = ({ value }) => {
  const { currencyFormatter } = useCurrencyFormatter() as CurrencyFormatterType;

  return <div>{typeof value === 'number' ? currencyFormatter.format(value) : ''}</div>;
};
