import React from 'react';
import { TextField } from '@material-ui/core';

import { useStyles } from '~/assets/styles';
import type { OnUserFeedbackCallback } from '~/components/AiChat/components/Message/MessageTypes';
import CardDialog from '~/components/CardDialog';
import { FsButton, FsIconButton } from '~/components/core';
import { ThumbDownIcon, ThumbUpIcon } from '~/components/icons';
import cn from '~/Utils/cn';

import colors from '../../../../theme/tailwind/colors';

const MessageFeedbackIconButton: React.FC<{
  tooltipText: string;
  onClick: OnUserFeedbackCallback;
  isSelectedValue: number;
  currentScore?: number;
  exchangeId: number;
  iconComponent: React.FC<{ iconColor?: string; size?: number }>;
  withText?: boolean;
  disabled?: boolean;
}> = ({
  tooltipText,
  onClick,
  isSelectedValue,
  currentScore,
  exchangeId,
  iconComponent,
  withText = false,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [feedbackText, setFeedbackText] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const classes = useStyles() as { hoverableIcon: string };
  const isSelected = isSelectedValue === currentScore;
  const Icon = iconComponent;

  const showFeedbackDialog = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);

  const submitDialogHandler = () => {
    setIsSubmitting(true);
    setIsSubmitted(true);
    onClick(exchangeId, isSelectedValue, feedbackText);
    setIsSubmitting(false);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isSelected || disabled) return;
    if (withText) {
      showFeedbackDialog(event);
      return;
    }

    // Submit feedback without text
    onClick(exchangeId, isSelectedValue, feedbackText);
  };

  return (
    <>
      <FsIconButton
        className={cn(classes.hoverableIcon, {
          ['cursor-default']: isSelected || isSubmitted || disabled,
          ['cursor-progress']: isSubmitting,
        })}
        tooltipText={tooltipText}
        onClick={handleClick}
      >
        <Icon size={20} iconColor={isSelected || isSubmitted ? colors.teal[700] : undefined} />
      </FsIconButton>

      <CardDialog
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        anchorEl={anchorEl}
        isPopover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        closeOnBackdropClick
        title={<span className="text-base font-bold">I’d appreciate your feedback</span>}
        subheader={<span className="text-sm font-normal">Don’t like this answer? Tell me why so I can improve</span>}
        width="380px"
        className="p-0"
        contentStyle={{ padding: '8px 16px' }}
        anchorOrigin={{ vertical: 40, horizontal: -115 }}
      >
        <div className="__FEEDBACK_DIALOG_ROOT__ flex w-full flex-col gap-12">
          <TextField
            variant="outlined"
            size="small"
            multiline
            fullWidth
            placeholder="Add more details here (optional)"
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            inputProps={{ maxLength: 10000 }}
          />
          <div className="__FEEDBACK_DIALOG_FOOTER__ my-12 flex">
            <FsButton
              className="ml-auto rounded-md border-2 border-aiV2-pink bg-aiV2-lightpink px-20 text-aiV2-pink"
              variant={FsButton.VARIANTS.outlined}
              size={FsButton.SIZES.small}
              onClick={submitDialogHandler}
            >
              Submit
            </FsButton>
          </div>
        </div>
      </CardDialog>
    </>
  );
};

interface MessageFeedbackProps {
  onClick: OnUserFeedbackCallback;
  exchangeId: number;
  currentScore?: number;
  feedbackText?: string;
  disabled?: boolean;
}

export const PositiveFeedbackIconButton: React.FC<MessageFeedbackProps> = ({
  onClick,
  exchangeId,
  currentScore,
  disabled,
}) => (
  <MessageFeedbackIconButton
    tooltipText="This was a helpful response!"
    onClick={onClick}
    isSelectedValue={1}
    currentScore={currentScore}
    exchangeId={exchangeId}
    iconComponent={ThumbUpIcon}
    disabled={disabled}
  />
);

export const NegativeFeedbackIconButton: React.FC<MessageFeedbackProps> = ({
  onClick,
  exchangeId,
  currentScore,
  disabled,
}) => (
  <MessageFeedbackIconButton
    tooltipText="This is not helpful"
    onClick={onClick}
    isSelectedValue={-1}
    currentScore={currentScore}
    exchangeId={exchangeId}
    iconComponent={ThumbDownIcon}
    disabled={disabled}
    withText
  />
);
