import React from 'react';
import PropTypes from 'prop-types';

import { COUNTRIES_DICT } from '../Types';

import useGlobalAddresses from './hooks/useGlobalAddresses';
import AutocompleteFormik from './AutocompleteFormik';

function CountryAutocompleteFormik(props) {
  const { id, label, onChange, countriesConfigurationKey, disabled = false } = props;
  const { countriesOptions } = useGlobalAddresses({ countriesConfigurationKey });

  return (
    <AutocompleteFormik
      id={id}
      label={label}
      options={countriesOptions}
      getOptionLabel={(option) => COUNTRIES_DICT[option]}
      sortAlphabetic
      onChange={onChange}
      disabled={disabled}
    />
  );
}

CountryAutocompleteFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  countriesConfigurationKey: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CountryAutocompleteFormik;
