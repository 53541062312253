import React from 'react';
import PropTypes from 'prop-types';

import InvolvedPersonDialog from '../InvolvedPerson';

function ShowOnlyInjuredPersonDialog(props) {
  const { open, injuredPerson, onCancel } = props;

  return (
    <InvolvedPersonDialog
      onCancel={onCancel}
      open={open}
      person={{ ...injuredPerson }}
      title="Person Details"
      showOnly
    />
  );
}

ShowOnlyInjuredPersonDialog.propTypes = {
  open: PropTypes.bool,
  injuredPerson: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { ShowOnlyInjuredPersonDialog };
