import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const InvolvedPropertyIcon = ({ ...props }) => (
  <SvgIcon
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.875 9.625C3.875 9.27982 4.15482 9 4.5 9H9.5C9.84518 9 10.125 9.27982 10.125 9.625C10.125 9.97018 9.84518 10.25 9.5 10.25H4.5C4.15482 10.25 3.875 9.97018 3.875 9.625Z"
      fill="#202020"
    />
    <path
      d="M4.5 11.5C4.15482 11.5 3.875 11.7798 3.875 12.125C3.875 12.4702 4.15482 12.75 4.5 12.75H9.5C9.84518 12.75 10.125 12.4702 10.125 12.125C10.125 11.7798 9.84518 11.5 9.5 11.5H4.5Z"
      fill="#202020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.375 0.875C1.04348 0.875 0.725536 1.0067 0.491116 1.24112C0.256695 1.47554 0.125 1.79348 0.125 2.125V15.875C0.125 16.2065 0.256695 16.5245 0.491116 16.7589C0.725536 16.9933 1.04348 17.125 1.375 17.125H12.625C12.9565 17.125 13.2745 16.9933 13.5089 16.7589C13.7433 16.5245 13.875 16.2065 13.875 15.875V5.875C13.875 5.7059 13.8078 5.55249 13.6988 5.43997L13.6919 5.43306L9.31694 1.05806L9.31003 1.05125C9.20094 0.945499 9.05725 0.883272 8.90599 0.875769C8.89569 0.875257 8.88536 0.875 8.875 0.875H1.375ZM8.25 2.125H1.375V15.875H12.625V6.5H8.875C8.52982 6.5 8.25 6.22018 8.25 5.875V2.125ZM11.7411 5.25L9.5 3.00888V5.25H11.7411Z"
      fill="#202020"
    />
  </SvgIcon>
);

InvolvedPropertyIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default InvolvedPropertyIcon;
