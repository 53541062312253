import type { ReactElement, ReactNode } from 'react';
import React from 'react';

import { AddItemButton, SortableTable } from '../../../core';
import type { DocumentApiResponse, LegalActionLogBase } from '../../types';

export interface LogsCardContentColumnDefinition<T> {
  id: keyof T | 'actions' | '';
  label: string;
  specialCell?: (row: T) => ReactElement;
  disableSort?: boolean;
}

interface LogsCardContentProps<T> {
  columns: LogsCardContentColumnDefinition<T>[];
  rows: T[];
  onAddItem: () => void;
  addItemText: string;
  actions: (row: T) => ReactNode;
}

export const LogsCardContent = <T extends LegalActionLogBase | DocumentApiResponse>({
  addItemText,
  columns,
  rows,
  onAddItem,
  actions,
}: LogsCardContentProps<T>): ReactElement => {
  if (!rows || !rows.length) {
    return (
      <div>
        <AddItemButton onClick={onAddItem} text={addItemText} />
      </div>
    );
  }

  const columnsWithActions = [
    ...columns,
    {
      id: 'actions',
      label: 'Actions',
      width: 20,
      disableSort: true,
      specialCell: (row: T) => <div className="flex justify-center">{actions(row)}</div>,
    },
  ];

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full justify-end">
        <AddItemButton onClick={onAddItem} text={addItemText} />
      </div>
      <div className="w-full">
        <SortableTable columns={columnsWithActions} rows={rows} />
      </div>
    </div>
  );
};
