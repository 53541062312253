import React from 'react';

import SvgIcon from '../core/SvgIcon';

const InvolvedBusinessIcon = ({ ...props }) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.1009 7.4C18.1009 7.4 18.1009 7.35 18.1009 7.33L16.9809 3.4C16.9009 3.14 16.7509 2.91 16.5309 2.75C16.3109 2.59 16.0609 2.5 15.7809 2.5H4.21086C3.94086 2.5 3.68086 2.59 3.47086 2.75C3.26086 2.91 3.10086 3.14 3.02086 3.4L1.90086 7.32C1.90086 7.32 1.90086 7.37 1.90086 7.39C1.90086 7.42 1.88086 7.46 1.88086 7.49V8.74C1.88086 9.57 2.21086 10.36 2.80086 10.95C2.90086 11.05 3.02086 11.14 3.13086 11.22V16.24C3.13086 16.57 3.26086 16.89 3.50086 17.12C3.73086 17.35 4.06086 17.49 4.38086 17.49H15.6209C15.9509 17.49 16.2709 17.36 16.5009 17.12C16.7309 16.89 16.8709 16.57 16.8709 16.24V11.22C16.9809 11.13 17.1009 11.05 17.2009 10.94C17.7909 10.35 18.1209 9.56 18.1209 8.73V7.48C18.1209 7.48 18.1109 7.41 18.1009 7.38V7.4ZM4.22086 3.75H15.7809L16.6709 6.87H3.33086L4.22086 3.75ZM3.68086 10.07C3.33086 9.72 3.13086 9.24 3.13086 8.74V8.12H6.88086V8.74C6.88086 9.24 6.68086 9.71 6.33086 10.07C5.62086 10.78 4.39086 10.78 3.68086 10.07ZM8.13086 8.12H11.8809V8.74C11.8809 9.24 11.6809 9.71 11.3309 10.07C10.6209 10.78 9.39086 10.78 8.68086 10.07C8.33086 9.72 8.13086 9.24 8.13086 8.74V8.12ZM15.6309 16.25H4.38086V11.81C4.58086 11.85 4.79086 11.87 5.00086 11.87C5.83086 11.87 6.62086 11.54 7.21086 10.95C7.32086 10.84 7.41086 10.72 7.50086 10.6C7.59086 10.72 7.68086 10.84 7.79086 10.95C8.38086 11.54 9.17086 11.87 10.0009 11.87C10.8309 11.87 11.6209 11.54 12.2109 10.95C12.3209 10.84 12.4109 10.72 12.5009 10.6C12.5909 10.72 12.6809 10.84 12.7909 10.95C13.3809 11.54 14.1709 11.87 15.0009 11.87C15.2109 11.87 15.4209 11.85 15.6209 11.81V16.25H15.6309ZM16.3309 10.08C15.6209 10.79 14.3909 10.79 13.6809 10.08C13.3309 9.73 13.1309 9.25 13.1309 8.75V8.13H16.8809V8.75C16.8809 9.25 16.6809 9.72 16.3309 10.08Z"
      fill="#202020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0009 16V15C11.0009 14.4477 10.5531 14 10.0009 14C9.44857 14 9.00086 14.4477 9.00086 15V16H11.0009ZM10.0009 13C8.89629 13 8.00086 13.8954 8.00086 15V17H12.0009V15C12.0009 13.8954 11.1054 13 10.0009 13Z"
      fill="#202020"
    />
  </SvgIcon>
);

export default InvolvedBusinessIcon;
