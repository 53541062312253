import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { useCms } from '../hooks/useCms';
import { usePositionLeftZenDesk } from '../hooks/useZenDesk';
import PetFnol from '../Pet/OldPetFnol';
import { PolicyContextProvider } from '../PolicyContainer';
import PolicySearchContainer from '../PolicySearch';

import { InsuredDetailsFormDialog } from './FnolScreen';
import LossWithoutPolicyCard from './LossWithoutPolicyCard';

import { useStyles } from '../../assets/styles';

function BoostFnolScreen({ organization }) {
  const { userOrganization } = useCms();
  const history = useHistory();
  const classes = useStyles();
  const [manuallyFillingInsuredDetails, setManuallyFillingInsuredDetails] = useState(false);
  const [chosenPolicy, setChosenPolicy] = useState(null);
  const [showLossWithoutPolicyCard, setShowLossWithoutPolicyCard] = useState(true);
  const [selectedSubOrgId, setSelectedSubOrgId] = useState(undefined);

  usePositionLeftZenDesk();

  const handleLossWithoutPolicy = ({ subOrgId }) => {
    setSelectedSubOrgId(subOrgId);
    setManuallyFillingInsuredDetails(true);
    setShowLossWithoutPolicyCard(false);
  };

  const handleInsuredDetailsFilled = (insuredDetails) => {
    const subOrganization = selectedSubOrgId
      ? userOrganization.sub_organizations.find((subOrganization) => subOrganization.id === selectedSubOrgId)
      : null;

    const newManualPolicy = {
      type: 'pet_policy',
      lob: 'pet_policy',
      is_manual: true,
      id: uuidv4(),
      contacts: [insuredDetails],
      organization_id: organization.id,
      sub_organization: subOrganization,
      client: organization.name,
      insured_contact: insuredDetails,
      insured_contact_id: insuredDetails.id,
      insured_contact_full_name: insuredDetails.full_name,
      insured_property_location: {
        address1: insuredDetails.street_address1,
        address2: insuredDetails.street_address2,
        city: insuredDetails.city,
        county: insuredDetails.county,
        state: insuredDetails.state,
        country: insuredDetails.country,
        zipcode: insuredDetails.zipcode,
      },
    };

    setChosenPolicy(newManualPolicy);
    setManuallyFillingInsuredDetails(false);
  };

  const handleFnolSubmitted = (claimId) => {
    setChosenPolicy(null);
    history.push(`/claims/${claimId}`);
  };

  if (manuallyFillingInsuredDetails) {
    return (
      <InsuredDetailsFormDialog
        onCancel={() => {
          setManuallyFillingInsuredDetails(false);
          setShowLossWithoutPolicyCard(true);
        }}
        onInsuredDetailsFilled={handleInsuredDetailsFilled}
      />
    );
  }

  if (!chosenPolicy) {
    return (
      <Grid container justify="center">
        <Grid item xs={8}>
          <div className={classes.cardDivRow}>
            <PolicySearchContainer
              enableSelectingRelatedClaim
              choosePolicyFromRelatedClaimsText="Report New Loss"
              onSelectPolicy={(policy, _) => setChosenPolicy(policy)}
              onPolicyNotFound={() => setManuallyFillingInsuredDetails(true)}
              policyTypes={['pet_policy']}
              // onFnolDraftSelected={(values) => console.log('Draft values:', values)}
            />
          </div>
        </Grid>
        {showLossWithoutPolicyCard && (
          <Grid item xs={8}>
            <LossWithoutPolicyCard onTakeLoss={handleLossWithoutPolicy} />
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <PolicyContextProvider policy={chosenPolicy}>
      <PetFnol
        onSubmit={handleFnolSubmitted}
        subOrganizationId={selectedSubOrgId}
        // onSubmitDraft={(values) => console.log('Draft values:', values)}
      />
    </PolicyContextProvider>
  );
}

BoostFnolScreen.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default BoostFnolScreen;
