import _ from 'lodash';

import { useLegalActions } from '~/components/LegalActions/hooks/useLegalActions';
import { getExposureHighestPriorityLegalAction } from '~/components/LegalActions/LegalActionsUtils';

import { reportErrorInProductionOrThrow } from '../../Utils';

export const PENDING_COLOR = 'orange';
export const FAILING_COLOR = 'red';

export function isExposureWriteDisabled(exposure, user, options = {}) {
  const { allowOnClosedExposure, enableIfDenied } = options;

  if (Object.keys(options).filter((key) => !['allowOnClosedExposure', 'enableIfDenied'].includes(key)).length > 0) {
    reportErrorInProductionOrThrow(`Unknown options in isExposureWriteDisabled: ${Object.keys(options)}`);
  }

  if (!enableIfDenied && exposure.coverage_decision && exposure.coverage_decision?.coverage_decision === 'denied') {
    return true;
  }

  if (exposure.is_closed && !allowOnClosedExposure) {
    return true;
  }

  if (user.role.is_view_only) {
    return true;
  }

  return false;
}

export function getPolicyExposureLimit(claim, exposure) {
  if (exposure.isGeneralExpenses) {
    return -1;
  } else {
    return exposure.policy_coverage_limit;
  }
}

export function getPolicyExposureLimitPerAccidentIfExists(exposure) {
  if (exposure.isGeneralExpenses) {
    return -1;
  } else {
    return exposure.policy_coverage_limit_per_accident_or_none;
  }
}

export function isPayableWithReservePending(payableWithReserve) {
  return (
    payableWithReserve.are_pending_reserve_change_requests_exist ||
    payableWithReserve.are_pending_payment_requests_exist
  );
}

export function isLimitExists(limit) {
  return !_.isNil(limit) && limit !== -1;
}

export function getUserRelatedExposuresId(user, claim) {
  const userRelatedExposure = claim.exposures
    .filter((exposure) => exposure.handling_adjuster_id === user.id)
    .map((exposure) => exposure.id);
  return userRelatedExposure.length === 0 ? [] : userRelatedExposure.concat([0]);
}

export function getPayableWithReserveType(exposure, payableWithReserve) {
  if (exposure.isGeneralExpenses) {
    return 'expenses';
  } else if (exposure.expenses.id === payableWithReserve.id) {
    return 'expenses';
  } else if (exposure.indemnity.id === payableWithReserve.id) {
    return 'indemnity';
  } else {
    throw Error('Unknown payable with reserve type, payable is not part of the exposure');
  }
}

export function getAutoPersonCoverages() {
  return [
    'coverage_pip',
    'coverage_apip',
    'coverage_adb',
    'coverage_obel',
    'coverage_medpay',
    'coverage_bodily_injury',
    'coverage_bodily_injury_loss_transfer',
    'coverage_um',
    'coverage_uim',
    'coverage_eco_xpl',
    'coverage_tp_pi',
    'coverage_bodily_injury_uk',
    'coverage_pi',
  ];
}

export function getExposuresClosingDisabledReason(exposures) {
  for (let exposure of exposures) {
    // exposure can be general expenses (exposure.isGeneralExpenses) - so it might not have indemnity
    if (
      exposure.indemnity?.are_pending_reserve_change_requests_exist ||
      exposure.expenses.are_pending_reserve_change_requests_exist
    ) {
      return 'Pending reserve change request exists';
    } else if (
      exposure.indemnity?.are_pending_payment_requests_exist ||
      exposure.expenses.are_pending_payment_requests_exist
    ) {
      return 'Pending payment request exists';
    } else if (exposure.is_in_litigation && !exposure.is_litigation_closed) {
      return 'Exposure still in litigation';
    }
  }
}

export const useExposureClosingDisabledReason = (exposures) => {
  const { isLegalActionsFeatureEnabled, legalActions } = useLegalActions();

  for (let exposure of exposures) {
    // exposure can be general expenses (exposure.isGeneralExpenses) - so it might not have indemnity
    if (
      exposure.indemnity?.are_pending_reserve_change_requests_exist ||
      exposure.expenses?.are_pending_reserve_change_requests_exist
    ) {
      return 'Pending reserve change request exists';
    } else if (
      exposure.indemnity?.are_pending_payment_requests_exist ||
      exposure.expenses?.are_pending_payment_requests_exist
    ) {
      return 'Pending payment request exists';
    } else if (
      isLegalActionsFeatureEnabled &&
      legalActions &&
      getExposureHighestPriorityLegalAction(legalActions, exposure)
    ) {
      return 'Exposure has an open legal action';
    } else if (!isLegalActionsFeatureEnabled && exposure.is_in_litigation && !exposure.is_litigation_closed) {
      return 'Exposure still in litigation';
    }
  }
};
