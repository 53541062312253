const OSHA_300_INJURY_OPTIONS = {
  injury: 'Injury',
  skin_disorder: 'Skin Disorder',
  respiratory_condition: 'Respiratory Condition',
  poisoning: 'Poisoning',
  hearing_loss: 'Hearing loss',
  all_other_illnesses: 'All other illnesses',
} as const;

export default OSHA_300_INJURY_OPTIONS;
