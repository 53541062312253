import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect, getIn } from 'formik';

import Radio from '~/components/core/Atomic/Radio';

import styles from '../assets/styles';

const MUI_COLORS = {
  DEFAULT: 'default',
  INHERIT: 'inherit',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const RadioButtonFormik = (props) => {
  const {
    formik,
    id,
    label,
    labelClassName,
    optionValue,
    onClick,
    disabled,
    onChange,
    showOnly,
    size,
    color,
    buttonClassName,
  } = props;

  const { values, setFieldValue } = formik;

  return (
    <FormControl>
      <FormControlLabel
        value=""
        disabled={showOnly || disabled}
        control={
          <Radio
            color={!disabled && color && Object.values(MUI_COLORS).includes(color) ? color : MUI_COLORS.DEFAULT}
            onClick={(e) => onClick && onClick(e)}
            checked={getIn(values, id) === optionValue}
            onChange={() => (onChange ? onChange(id, optionValue) : setFieldValue(id, optionValue))}
            disabled={showOnly || disabled}
            size={size}
            style={{ color: !disabled && color?.startsWith('#') ? color : undefined }}
            className={buttonClassName}
          />
        }
        className={labelClassName}
        label={label}
        labelPlacement="end"
      />
    </FormControl>
  );
};

RadioButtonFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  optionValue: PropTypes.any.isRequired,
  labelClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  showOnly: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  formik: PropTypes.object,
  onClick: PropTypes.func,
};

export default withStyles(styles)(connect(RadioButtonFormik));
