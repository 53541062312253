import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import axios from 'axios';
import localforage from 'localforage';
import { isEqual } from 'lodash';

import { CACHE_TTL, reportAxiosError } from '~/Utils';

const fetchData = async (url, config) => {
  if (!url) {
    return { type: 'FETCH_INIT' };
  }

  try {
    const result = await axios.get(url, config);
    return { type: 'FETCH_SUCCESS', payload: result.data };
  } catch (error) {
    await reportAxiosError(error);
    return { type: 'FETCH_FAILURE' };
  }
};

const updateCacheData = (localCacheKey, data) => {
  if (localCacheKey) {
    localforage.setItem(localCacheKey, {
      timestamp: Date.now(),
      data,
    });
  }
};

const useCachedDataFetcher = ({ url, config = {}, shouldFetch = true, localCacheKey = '', shouldUseCache = false }) => {
  const lastParamsRef = useRef([url, config]);
  const [currParams, setCurrParams] = useState([url, config]);

  const reloadData = useCallback(async () => {
    const [currUrl, currConfig] = currParams;

    const action = await fetchData(currUrl, currConfig);
    if (action) {
      dispatch(action);

      if (shouldUseCache && localCacheKey) {
        updateCacheData(localCacheKey, action.payload);
      }
      return action.payload;
    }
  }, [currParams, localCacheKey, shouldUseCache]);

  const dataFetchReducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_INIT':
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case 'FETCH_SUCCESS':
        return {
          ...state,
          isLoading: false,
          isError: false,
          data: action.payload,
        };
      case 'FETCH_FAILURE':
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: shouldFetch,
    isError: false,
    data: undefined,
  });

  useEffect(() => {
    const [currUrl, currConfig] = currParams;

    let didCancel = false;
    const callFetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      const action = await fetchData(currUrl, currConfig);
      if (!didCancel) {
        dispatch(action);

        if (shouldUseCache && localCacheKey) {
          updateCacheData(localCacheKey, action.payload);
        }
      }
    };
    if (shouldFetch) {
      callFetchData();
    }
    return () => (didCancel = true);
  }, [currParams, shouldFetch, localCacheKey, shouldUseCache]);

  useEffect(() => {
    if (lastParamsRef.current) {
      const [lastUrl, lastConfig] = lastParamsRef.current;
      if (lastUrl === url && isEqual(lastConfig, config)) {
        return;
      }
    }
    setCurrParams([url, config]);
    lastParamsRef.current = [url, config];
  }, [url, config]);

  useEffect(() => {
    if (shouldUseCache && localCacheKey) {
      localforage.getItem(localCacheKey).then((cachedData) => {
        if (cachedData && Date.now() - cachedData.timestamp < CACHE_TTL) {
          dispatch({ type: 'FETCH_SUCCESS', payload: cachedData.data });
        }
      });
    }
  }, [localCacheKey, shouldUseCache]);

  return { ...state, reloadData };
};

export default useCachedDataFetcher;
