import React from 'react';
import PropTypes from 'prop-types';

import cn from '~/Utils/cn';

import { useStyles } from '../../../assets/styles';

const ElevatedCard = ({ children, className }) => {
  const classes = useStyles();

  return <div className={cn(classes.elevatedCard, className)}>{children}</div>;
};

ElevatedCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ElevatedCard;
