import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const InvolvedEntityIcon = ({ ...props }) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    stroke="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.86612 3.49112C3.10054 3.2567 3.41848 3.125 3.75 3.125H16.25C16.5815 3.125 16.8995 3.2567 17.1339 3.49112C17.3683 3.72554 17.5 4.04348 17.5 4.375V8.96094C17.5 15.9521 11.5645 18.266 10.4004 18.653C10.1415 18.7457 9.85847 18.7457 9.59958 18.653C8.43552 18.266 2.5 15.9521 2.5 8.96094V4.375C2.5 4.04348 2.6317 3.72554 2.86612 3.49112ZM16.25 4.375L3.75 4.375L3.75 8.96094C3.75 15.0751 8.92168 17.1117 10 17.4688C11.0783 17.1117 16.25 15.0751 16.25 8.96094V4.375Z"
      fill="#202020"
    />
  </SvgIcon>
);

InvolvedEntityIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default InvolvedEntityIcon;
