const MEDICAL_BILL_PROVIDER_PROFESSIONS = {
  acupuncture: {
    desc: 'ACUPUNCTURE',
  },
  ambulance: {
    desc: 'AMBULANCE',
  },
  anesthesia: {
    desc: 'ANESTHESIA',
  },
  attorney_firms: {
    desc: 'ATTORNEY FIRMS',
  },
  audiology: {
    desc: 'AUDIOLOGY',
  },
  chiropractor: {
    desc: 'CHIROPRACTOR',
  },
  clinic: {
    desc: 'CLINIC',
  },
  dentist: {
    desc: 'DENTIST',
  },
  diagnostic_center: {
    desc: 'DIAGNOSTIC CENTER',
  },
  doctors: {
    desc: 'DOCTORS',
  },
  educational_institution: {
    desc: 'EDUCATIONAL INSTITUTION',
  },
  emergency_physicians: {
    desc: 'EMERGENCY PHYSICIANS',
  },
  home_health: {
    desc: 'HOME HEALTH',
  },
  hospital: {
    desc: 'HOSPITAL',
  },
  hospitalist: {
    desc: 'HOSPITALIST',
  },
  'managed_care-organization': {
    desc: 'MANAGED CARE ORGANIZATION',
  },
  medical_records: {
    desc: 'MEDICAL RECORDS',
  },
  medical_supplies: {
    desc: 'MEDICAL SUPPLIES',
  },
  neurology: {
    desc: 'NEUROLOGY',
  },
  'nurse_case-manager': {
    desc: 'NURSE CASE MANAGER',
  },
  optometrist: {
    desc: 'OPTOMETRIST',
  },
  orthopaedic: {
    desc: 'ORTHOPAEDIC',
  },
  other: {
    desc: 'OTHER',
  },
  physical_therapy: {
    desc: 'PHYSICAL THERAPY',
  },
  psychology_psychiatry: {
    desc: 'PSYCHOLOGY/PSYCHIATRY',
  },
  private_investigator: {
    desc: 'PRIVATE INVESTIGATOR',
  },
  pulmonary: {
    desc: 'PULMONARY',
  },
  radiology: {
    desc: 'RADIOLOGY',
  },
  rehabilitation: {
    desc: 'REHABILITATION',
  },
  state_agency: {
    desc: 'STATE AGENCY',
  },
  surgical_center: {
    desc: 'SURGICAL CENTER',
  },
} as const;

export default MEDICAL_BILL_PROVIDER_PROFESSIONS;
