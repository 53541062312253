import React from 'react';
import PropTypes from 'prop-types';

import CardDialog from '~/components/CardDialog';
import { Heading } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import cn from '~/Utils/cn';

const DialPadDialog = ({ onClickDigit, currentlyClickedDigits, onClose }) => {
  const digitToLetters = {
    2: 'ABC',
    3: 'DEF',
    4: 'GHI',
    5: 'JKL',
    6: 'MNO',
    7: 'PQRS',
    8: 'TUV',
    9: 'WXYZ',
  };
  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

  return (
    <CardDialog noCardTitle maxWidth="xs" isDialog onClose={onClose}>
      <div className="flex flex-col items-center justify-center space-y-12">
        <Heading variant={Heading.TYPES.H4} className={cn(!currentlyClickedDigits && 'hidden', 'text-center text-xl')}>
          {currentlyClickedDigits || 'Place Holder'}
        </Heading>
        <div className="grid grid-cols-3 gap-12">
          {numbers.map((digit) => (
            <div key={digit}>
              <Button
                className="h-full"
                color="primary"
                variant="outlined"
                onClick={() => onClickDigit(digit)}
                fullWidth
              >
                <div className="flex flex-col">
                  <strong className="text-lg">{digit}</strong>
                  <span className="text-primary-500">{digitToLetters[digit] || '\u00A0'}</span>
                </div>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </CardDialog>
  );
};

DialPadDialog.propTypes = {
  onClickDigit: PropTypes.func.isRequired,
  currentlyClickedDigits: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DialPadDialog;
