import React from 'react';

import Chip from '~/components/core/Atomic/Chip/Chip';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';

import Caption from '../../TextComponents/Caption';
import Text from '../../TextComponents/Text';

export interface LabeledValueProps {
  value: string;
  label: string;
  type?: 'Text' | 'Chip';
  withOverflowTextWithToolTip?: boolean;
}

const LabeledValue: React.FC<LabeledValueProps> = ({
  value,
  label,
  type = 'Text',
  withOverflowTextWithToolTip = true,
}) => (
  <div>
    <Caption variant={Caption.VARIANTS.LABEL} className="mb-4">
      {label}
    </Caption>
    {type === 'Text' ? (
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
        {value}
      </Text>
    ) : (
      <div className="flex flex-wrap gap-4">
        {value.split(',').map((val) => (
          <Chip
            key={val}
            label={
              withOverflowTextWithToolTip ? (
                <OverflowTextWithToolTip maxWidth="120px">{val}</OverflowTextWithToolTip>
              ) : (
                val
              )
            }
            classes={{ root: 'bg-slate-400' }}
          />
        ))}
      </div>
    )}
  </div>
);

export default LabeledValue;
