import React from 'react';
import PropTypes from 'prop-types';

import { FsTooltip } from './';

import { useStyles } from '../../assets/styles';

const TooltipIcon = ({ title, arrow = false, placement, children, disabled }) => {
  const classes = useStyles();

  return (
    <FsTooltip placement={placement} arrow={arrow} title={title} disabled={disabled}>
      <span className={classes.tooltipIcon}>{children}</span>
    </FsTooltip>
  );
};

TooltipIcon.propTypes = {
  title: PropTypes.string.isRequired,
  arrow: PropTypes.bool,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default TooltipIcon;
