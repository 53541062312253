import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Menu } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import useIsConfigurationFieldSupportedBySubtype from '~/components/hooks/useIsConfigurationFieldSupportedBySubtype';

import { CONFIGURATION_FEATURES_NAMES, MGM_LABEL_DICT, SIU_CRITERIA_DICT } from '../Types';
import {
  getIsCatSupportedForClaimType,
  isFeatureEnabled,
  isHospitalityUser,
  isMgmClaim,
  isRwClaim,
  reportAxiosError,
} from '../Utils';

import DocumentTextFieldFormik from './Documents/DocumentTextFieldFormik';
import { SendLitigationReferralEmailDialog } from './exposures/ExposureLitigationReferral';
import { GenericLitigationStatusDialog } from './exposures/LitigationStatusDialog';
import AdditionalInformationDialog from './Fnol/NewFnolUI/AdditionalInformation/AdditionalInformationDialog';
import {
  getInitialFaultAssessmentInitialValidationSchema,
  getInitialFaultAssessmentInitialValues,
  getInitialFaultAssessmentSectionConfig,
  InitialFaultAssessmentFragment,
} from './Fnol/NewFnolUI/AdditionalInformation/Fragments/InitialFaultAssessmentFragment';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import { FsMenuItem } from './core';
import DisableableToolTip from './DisableableTooltip';
import HoverActionField from './HoverActionField';
import { ThreeDotsIcon } from './icons';
import LoadingDialog from './LoadingDialog';
import useOrganization from './OrganizationContext';
import { MultiSelectTextFieldFormik, TextFieldFormik } from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

function IncidentMoreActionsContainer(props) {
  const {
    incident,
    onUpdateFields,
    includeIsComplex,
    includeIsQra,
    includeFirstPartyLitigation,
    includeAdvancedFirstPartyLitigation,
    includeIsMultiLoss,
    onChangeIsInFirstPartyLitigation,
    includeIsControlFile,
    includesMarkAsUmbrella,
    includesLitigationReferral,
  } = props;
  const { isFieldSupportedBySubtype } = useIsConfigurationFieldSupportedBySubtype();
  const { incidentConfiguration } = useIncidentConfiguration();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [openMarkAsCat, setOpenMarkAsCat] = useState(false);
  const [openMarkAsMultiLoss, setOpenMarkAsMultiLoss] = useState(false);
  const [openMarkAsSIU, setOpenMarkAsSIU] = useState(false);
  const [openMarkLabel, setOpenMarkLabel] = useState(false);
  const [openMarkAsQra, setOpenMarkAsQra] = useState(false);
  const [openMarkAsControlFile, setOpenMarkAsControlFile] = useState(false);
  const [openMarkAsDeferredFile, setOpenMarkAsDeferredFile] = useState(false);
  const [openAdvancedMarkAsInFirstPartyLitigation, setOpenAdvancedMarkAsInFirstPartyLitigation] = useState(false);
  const [openDefenseLitigationReferralDialog, setOpenDefenseLitigationReferralDialog] = useState(false);
  const [openInitialFaultAssessment, setOpenInitialFaultAssessment] = useState(false);

  const {
    is_complex,
    is_cat,
    is_multi_loss,
    is_siu,
    is_control_file,
    is_deferred_file,
    labeled_criteria,
    is_umbrella,
    is_uw,
  } = incident;

  const { claim, onClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const { isCatastropheEventsEnabled } = useOrganization();
  const classes = useStyles();
  const { is_in_first_party_litigation: isInFirstPartyLitigation, is_qra: isQra } = claim;

  const currentFaultAssessment = {
    first_party_fault_assessment: incident?.first_party_fault_assessment,
    claim_handler_fault_assessment: incident?.claim_handler_fault_assessment,
    percentage_of_insured_liability: incident?.percentage_of_insured_liability,
    is_indemnity_review_needed: incident?.is_indemnity_review_needed,
    is_fraud_review_needed: incident?.is_fraud_review_needed,
    liability_summary: incident?.liability_summary,
  };

  const isUWFeatureEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ENABLE_MARK_AS_UW);
  const isControlFileFeatureEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.ENABLE_MARK_AS_CONTROL_FILE
  );
  const isDeferredFeatureEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.ENABLE_MARK_AS_DEFERRED
  );

  const isNewFnolUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);

  const isMoveFaultAssessmentEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MOVE_FAULT_ASSESSMENT_TO_MANAGEMENT_TAB
  );

  const handleUpdatingFields = async (fieldsValues) => {
    setIsUpdating(true);

    try {
      await onUpdateFields(fieldsValues);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleMarkClaim = async (method, resourceName, values) => {
    try {
      setIsUpdating(true);
      const res = await axios({ method, url: `/api/v1/claims/${claim.id}/${resourceName}`, data: values });
      await onClaimUpdate(res.id);
      setIsUpdating(false);
    } catch (error) {
      reportAxiosError(error);
      setIsUpdating(false);
      throw error;
    }
  };

  const handleCatItemClicked = () => {
    setOpenMarkAsCat(true);
    setAnchorEl(null);
  };

  const handleMultiLossItemClicked = () => {
    setOpenMarkAsMultiLoss(true);
    setAnchorEl(null);
  };

  const handleSIUItemClicked = () => {
    setOpenMarkAsSIU(true);
    setAnchorEl(null);
  };

  const handleControlFileItemClicked = () => {
    setOpenMarkAsControlFile(true);
    setAnchorEl(null);
  };

  const handleLabelClaimItemClicked = () => {
    setOpenMarkLabel(true);
    setAnchorEl(null);
  };

  const handleDeferredFileItemClicked = () => {
    setOpenMarkAsDeferredFile(true);
    setAnchorEl(null);
  };

  const handleIsComplexItemClicked = async () => {
    await handleUpdatingFields({ is_complex: !is_complex });
    setAnchorEl(null);
  };

  const handleIsQraItemClicked = () => {
    setOpenMarkAsQra(true);
    setAnchorEl(null);
  };

  const handleIsInFirstPartyLitigationItemClicked = async () => {
    try {
      await onChangeIsInFirstPartyLitigation(!isInFirstPartyLitigation);
      setAnchorEl(null);
    } catch {
      return;
    }
  };

  const handleMarkAsUmbrella = async () => {
    await handleMarkClaim(is_umbrella ? 'delete' : 'post', 'umbrella');
    setAnchorEl(null);
  };

  const handleMarkAsUW = async () => {
    await handleMarkClaim(is_uw ? 'delete' : 'post', 'uw');
    setAnchorEl(null);
  };

  const handleInitialFaultAssessment = () => {
    setOpenInitialFaultAssessment(true);
    setAnchorEl(null);
  };

  const handleDefenseLitigationReferral = () => {
    setOpenDefenseLitigationReferralDialog(true);
    setAnchorEl(null);
  };

  const newUIAutoAdditionalInfo = [
    {
      id: 'initial_fault_assessment',
      label: 'Fault Assessment',
      onClick: handleInitialFaultAssessment,
      disabledFunc: () => !isFieldSupportedBySubtype(getInitialFaultAssessmentSectionConfig(incidentConfiguration)),
      displayFunc: () =>
        getInitialFaultAssessmentSectionConfig(incidentConfiguration)?.active && !isMoveFaultAssessmentEnabled,
    },
  ];

  const getNewUIAdditionalInfo = () => {
    if (claim.type === 'auto_claim') {
      return newUIAutoAdditionalInfo;
    }
  };

  return (
    <>
      <div className={classes.button}>
        <HoverActionField
          icon={ThreeDotsIcon}
          onAction={(event) => setAnchorEl(event.currentTarget)}
          permanent={true}
          ignorePermissions
          style={{ marginTop: '10px' }}
        />
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {claim && isCatastropheEventsEnabled && getIsCatSupportedForClaimType(claim) && (
          <FsMenuItem onClick={handleCatItemClicked} disabled={isUpdating}>
            <span style={{ visibility: is_cat ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            Mark as Catastrophic Event
          </FsMenuItem>
        )}
        {includeIsMultiLoss && (
          <FsMenuItem onClick={handleMultiLossItemClicked} disabled={isUpdating}>
            <span style={{ visibility: is_multi_loss ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>{' '}
            Mark as Multi Loss Incident
          </FsMenuItem>
        )}
        <FsMenuItem onClick={handleSIUItemClicked} disabled={isUpdating}>
          <span style={{ visibility: is_siu ? 'visible' : 'hidden' }}>
            <DoneIcon />
            &nbsp;
          </span>
          Mark as SIU
        </FsMenuItem>
        {(isMgmClaim(claim) || isRwClaim(claim) || isHospitalityUser(user)) && (
          <FsMenuItem onClick={handleLabelClaimItemClicked} disabled={isUpdating}>
            <span style={{ visibility: labeled_criteria ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            Claim Type
          </FsMenuItem>
        )}
        {(includeIsControlFile || isControlFileFeatureEnabled) && (
          <FsMenuItem onClick={handleControlFileItemClicked} disabled={isUpdating}>
            <span style={{ visibility: is_control_file ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            Mark as Control File
          </FsMenuItem>
        )}
        {isDeferredFeatureEnabled && (
          // This used to be 'Mark as Deferred file'. We keeping the name deferred_file in the code to save the re-naming of it everywhere (including the DB)
          <MenuItem onClick={handleDeferredFileItemClicked} disabled={isUpdating}>
            <span style={{ visibility: is_deferred_file ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            Mark as Misc
          </MenuItem>
        )}
        {includeIsComplex && (
          <FsMenuItem onClick={handleIsComplexItemClicked} disabled={isUpdating}>
            <span style={{ visibility: is_complex ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            Mark as Complex
          </FsMenuItem>
        )}
        {includeIsQra && (
          <FsMenuItem onClick={handleIsQraItemClicked} disabled={isUpdating}>
            <span style={{ visibility: isQra ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            Mark as QRA
          </FsMenuItem>
        )}

        {includeFirstPartyLitigation && (
          <FsMenuItem onClick={handleIsInFirstPartyLitigationItemClicked} disabled={isUpdating}>
            <span style={{ visibility: isInFirstPartyLitigation ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            Mark as In 1st Party Litigation
          </FsMenuItem>
        )}

        {includeAdvancedFirstPartyLitigation && (
          <FsMenuItem
            disabled={isUpdating}
            onClick={() => {
              setOpenAdvancedMarkAsInFirstPartyLitigation(true);
              setAnchorEl(null);
            }}
          >
            <span style={{ visibility: isInFirstPartyLitigation ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            {isInFirstPartyLitigation ? 'Update litigation status' : 'Mark as in litigation'}
          </FsMenuItem>
        )}

        {includesMarkAsUmbrella && (
          <FsMenuItem onClick={handleMarkAsUmbrella} disabled={isUpdating}>
            <span style={{ visibility: is_umbrella ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            Mark as Umbrella
          </FsMenuItem>
        )}

        {includesLitigationReferral && (
          <DisableableToolTip
            disabled={!isInFirstPartyLitigation}
            title="First mark as litigation"
            placement="top-start"
          >
            <FsMenuItem onClick={handleDefenseLitigationReferral} disabled={isUpdating || !isInFirstPartyLitigation}>
              <span style={{ visibility: 'hidden' }}>
                <DoneIcon />
                &nbsp;
              </span>
              Send Litigation Referral
            </FsMenuItem>
          </DisableableToolTip>
        )}

        {isUWFeatureEnabled && (
          <FsMenuItem onClick={handleMarkAsUW} disabled={isUpdating}>
            <span style={{ visibility: is_uw ? 'visible' : 'hidden' }}>
              <DoneIcon />
              &nbsp;
            </span>
            Mark as UW
          </FsMenuItem>
        )}
        {isNewFnolUIEnabled && (
          <>
            {getNewUIAdditionalInfo()?.map(({ id, label, onClick, disabledFunc, displayFunc }) => (
              <>
                {displayFunc() && (
                  <FsMenuItem key={id} onClick={onClick} disabled={isUpdating || disabledFunc()}>
                    <span style={{ visibility: 'hidden' }}>
                      <DoneIcon />
                      &nbsp;
                    </span>
                    {label}
                  </FsMenuItem>
                )}
              </>
            ))}
          </>
        )}
      </Menu>
      {openMarkAsCat && (
        <MarkAsCatDialog
          open={openMarkAsCat}
          incident={incident}
          onSubmit={async (catId) => {
            await handleMarkClaim('post', 'cat', { cat_id: catId });
            setOpenMarkAsCat(false);
          }}
          onClose={() => setOpenMarkAsCat(false)}
          onRemove={async () => {
            await handleMarkClaim('delete', 'cat');
            setOpenMarkAsCat(false);
          }}
        />
      )}
      {openMarkAsMultiLoss && (
        <MarkAsMultiLossDialog
          open={openMarkAsMultiLoss}
          incident={incident}
          onSubmit={async (multiLossName) => {
            await handleMarkClaim('post', 'multi_loss', { multi_loss_name: multiLossName });
            setOpenMarkAsMultiLoss(false);
          }}
          onClose={() => setOpenMarkAsMultiLoss(false)}
          onRemove={async () => {
            await handleMarkClaim('delete', 'multi_loss');
            setOpenMarkAsMultiLoss(false);
          }}
        />
      )}
      {openMarkAsSIU && (
        <MarkAsSIU
          open={openMarkAsSIU}
          incident={incident}
          onSubmit={async (values) => {
            await handleMarkClaim(incident.is_siu ? 'put' : 'post', 'siu', values);
            setOpenMarkAsSIU(false);
          }}
          onClose={() => setOpenMarkAsSIU(false)}
          onRemove={async () => {
            await handleMarkClaim('delete', 'siu');
            setOpenMarkAsSIU(false);
          }}
          onFinish={async (values) => {
            await handleMarkClaim('post', 'siu/finish', values);
            setOpenMarkAsSIU(false);
          }}
          onReopen={async (values) => {
            await handleMarkClaim('delete', 'siu/finish', values);
            setOpenMarkAsSIU(false);
          }}
        />
      )}
      {openMarkLabel && (
        <MarkLabelClaim
          incident={incident}
          onSubmit={async (values) => {
            await handleMarkClaim(incident.labeled_criteria ? 'put' : 'post', 'label_claim', values);
            setOpenMarkLabel(false);
          }}
          onClose={() => setOpenMarkLabel(false)}
          onRemove={async () => {
            await handleMarkClaim('delete', 'label_claim');
            setOpenMarkLabel(false);
          }}
        />
      )}
      {openMarkAsControlFile && (
        <MarkAsControlFile
          open={openMarkAsControlFile}
          incident={incident}
          onSubmit={async (values) => {
            await handleMarkClaim(incident.is_control_file ? 'put' : 'post', 'control_file', values);
            setOpenMarkAsControlFile(false);
          }}
          onClose={() => setOpenMarkAsControlFile(false)}
          onRemove={async () => {
            await handleMarkClaim('delete', 'control_file');
            setOpenMarkAsControlFile(false);
          }}
        />
      )}
      {openMarkAsQra && (
        <MarkAsQraDialog
          open={openMarkAsQra}
          onSubmit={async (qraNote) => {
            await handleMarkClaim('post', 'qra', { qra_note: qraNote });
            setOpenMarkAsQra(false);
          }}
          onClose={() => setOpenMarkAsQra(false)}
          onRemove={async () => {
            await handleMarkClaim('delete', 'qra');
            setOpenMarkAsQra(false);
          }}
        />
      )}
      {openMarkAsDeferredFile && (
        <MarkAsDeferredFileDialog
          open={openMarkAsDeferredFile}
          incident={incident}
          onSubmit={async (values) => {
            await handleMarkClaim(incident.is_deferred_file ? 'put' : 'post', 'deferred_file', values);
            setOpenMarkAsDeferredFile(false);
          }}
          onClose={() => setOpenMarkAsDeferredFile(false)}
          onRemove={async () => {
            await handleMarkClaim('delete', 'deferred_file');
            setOpenMarkAsDeferredFile(false);
          }}
        />
      )}
      {openInitialFaultAssessment && (
        <AdditionalInformationDialog
          initialValues={currentFaultAssessment}
          open={openInitialFaultAssessment}
          onCancel={() => setOpenInitialFaultAssessment(false)}
          onSubmit={async (values) => {
            await handleUpdatingFields(values);
            setOpenInitialFaultAssessment(false);
          }}
          title="Fault Assessment"
          additionalFieldOmitFn={(field) => isFieldSupportedBySubtype(field)}
          AdditionalInformationFragment={InitialFaultAssessmentFragment}
          AdditionalInformationFragmentInitialValues={getInitialFaultAssessmentInitialValues(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
          AdditionalInformationFragmentValidationSchema={getInitialFaultAssessmentInitialValidationSchema(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
        />
      )}
      {openAdvancedMarkAsInFirstPartyLitigation && (
        <GenericLitigationStatusDialog
          litigationDetails={{
            involved_attorney_contact_id: claim.first_party_attorney_contact_id || '',
            involved_attorney_contact_full_name: claim.first_party_attorney_contact_full_name || '',
            litigation_type: claim.litigation_type || '',
            litigation_file_date: claim.litigation_file_date || '',
            litigation_upcoming_court_date: claim.litigation_upcoming_court_date || '',
            is_litigation_closed: claim.is_litigation_closed || false,
            defense_attorney_contact_id: claim.defense_attorney_contact_id,
          }}
          onSubmit={onChangeIsInFirstPartyLitigation}
          onClose={() => setOpenAdvancedMarkAsInFirstPartyLitigation(false)}
          subtitle="Claim Referral"
        />
      )}
      {openDefenseLitigationReferralDialog && (
        <SendLitigationReferralEmailDialog
          onClose={() => setOpenDefenseLitigationReferralDialog(false)}
          contact={claim.defense_attorney_contact}
        />
      )}
    </>
  );
}

IncidentMoreActionsContainer.propTypes = {
  incident: PropTypes.object.isRequired,
  onUpdateFields: PropTypes.func.isRequired,
  includeIsComplex: PropTypes.bool,
  includeFirstPartyLitigation: PropTypes.bool,
  includeAdvancedFirstPartyLitigation: PropTypes.bool,
  includeIsMultiLoss: PropTypes.bool,
  includeIsQra: PropTypes.bool,
  includeIsControlFile: PropTypes.bool,
  onChangeIsInFirstPartyLitigation: requiredIf(PropTypes.func, (props) => props.includeFirstPartyLitigation),
  includesMarkAsUmbrella: PropTypes.bool,
  includesLitigationReferral: PropTypes.bool,
};

function MarkAsCatDialog(props) {
  const { incident, onSubmit, onClose, onRemove } = props;
  const [openRemovePopup, setOpenRemovePopup] = useState(false);
  const classes = useStyles();
  const isUpdate = incident.is_cat ? true : false;

  const { claim } = useClaim();

  const { isLoading, isError, data: catsList } = useDataFetcher(`/api/v1/claims/cats/${claim.organization_id}`);

  const catsListSorted = catsList?.sort((cata, catb) => ((cata.start_date ?? '') > (catb.start_date ?? '') ? -1 : 1));

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} onClose={onClose} track="Mark as CAT" />;
  }

  const CatInputFieldWithFeatureEnabled = () => {
    return catsListSorted.length > 0 ? (
      <TextFieldFormik id="cat_id" label="Catastrophic Event Name" className={classes.textFieldRow} fullWidth select>
        {catsListSorted.map((catEntry) => (
          <MenuItem key={catEntry.id} value={catEntry.id}>
            {catEntry.name}
          </MenuItem>
        ))}
      </TextFieldFormik>
    ) : (
      <div>No catastrophic events are configured. To add new events, contact your system administrator.</div>
    );
  };

  const ButtonContainerWithFeatureFlagEnabled = ({ isSubmitting, handleSubmit }) => {
    return (
      <>
        <div className={classes.buttonsContainer}>
          {catsListSorted.length === 0 ? (
            <Button variant="contained" color="primary" onClick={onClose} className={classes.button}>
              Ok
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={isUpdate ? setOpenRemovePopup : onClose}
                disabled={isSubmitting}
                className={classes.cancelButtonWithRoundMargin}
              >
                {isUpdate ? 'Remove' : 'Cancel'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className={classes.button}
              >
                {isUpdate ? ' Update' : 'Save'}
              </Button>
            </>
          )}
        </div>
        {openRemovePopup && <RemoveCatPopup />}
      </>
    );
  };
  ButtonContainerWithFeatureFlagEnabled.propTypes = {
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
  };

  const RemoveCatPopup = () => {
    return (
      <CardDialog isDialog title="Remove Catastrophic Event Mark from Incident">
        <Typography>Are you sure that you want to remove Catastrophic Event Mark from Incident?</Typography>
        <div className={classes.buttonsContainer}>
          <Button variant="contained" onClick={onClose} className={classes.cancelButtonWithRoundMargin}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenRemovePopup(false);
              onRemove();
            }}
            className={classes.button}
          >
            Yes, remove
          </Button>
        </div>
      </CardDialog>
    );
  };

  // to be removed with New Cat FF
  const ButtonContainerWithFeatureFlagDisabled = ({ isSubmitting, handleSubmit }) => {
    return (
      <Grid container justify={isUpdate ? 'space-between' : 'flex-end'}>
        {isUpdate && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onRemove}
            disabled={isSubmitting}
            className={classes.button}
          >
            Remove
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={classes.button}
        >
          {isUpdate ? ' Update' : 'Set'}
        </Button>
      </Grid>
    );
  };
  ButtonContainerWithFeatureFlagDisabled.propTypes = {
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
  };

  return (
    <Formik
      initialValues={{
        cat_name: incident.cat_name || '',
        cat_id: incident.cat_id || '',
      }}
      validationSchema={Yup.object().shape({
        cat_name: Yup.string(),
        cat_id: Yup.number().required('Required'),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => onSubmit(values.cat_id).catch(() => formikProps.setSubmitting(false))}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        return (
          <CardDialog title="Mark as Catastrophic Event" isDialog maxWidth="xs" onClose={onClose} fullWidth>
            <CatInputFieldWithFeatureEnabled />
            <ButtonContainerWithFeatureFlagEnabled isSubmitting={isSubmitting} handleSubmit={handleSubmit} />
          </CardDialog>
        );
      }}
    </Formik>
  );
}

MarkAsCatDialog.propTypes = {
  incident: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function MarkAsMultiLossDialog(props) {
  const { incident, onSubmit, onClose, onRemove } = props;
  const classes = useStyles();
  const isUpdate = incident.is_multi_loss ? true : false;

  return (
    <Formik
      initialValues={{
        multi_loss_name: incident.multi_loss_name || '',
      }}
      validationSchema={Yup.object().shape({
        multi_loss_name: Yup.string(),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => onSubmit(values.multi_loss_name).catch(() => formikProps.setSubmitting(false))}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        return (
          <CardDialog title="Mark as Multi Loss Incident" isDialog maxWidth="xs" onClose={onClose} fullWidth>
            <TextFieldFormik
              id="multi_loss_name"
              label="Multi Loss Incident name"
              className={classes.textField}
              fullWidth
            />
            <Grid container justify={isUpdate ? 'space-between' : 'flex-end'}>
              {isUpdate && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onRemove}
                  disabled={isSubmitting}
                  className={classes.button}
                >
                  Remove
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className={classes.button}
              >
                {isUpdate ? ' Update' : 'Set'}
              </Button>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

MarkAsMultiLossDialog.propTypes = {
  incident: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function MarkAsSIU(props) {
  const { incident, onSubmit, onClose, onRemove, onFinish, onReopen } = props;
  const classes = useStyles();
  const [finishOpen, setFinishOpen] = React.useState(false);

  const { claim } = useClaim();

  const isUpdate = incident.is_siu ? true : false;
  const possibleSiuCriteria = Object.keys(SIU_CRITERIA_DICT).filter(
    (criteria) =>
      !SIU_CRITERIA_DICT[criteria].claim_types || SIU_CRITERIA_DICT[criteria].claim_types.includes(claim.type)
  );
  const [isRemoving, setIsRemoving] = useState(false);

  const handleRemove = async () => {
    setIsRemoving(true);
    await onRemove();
    setIsRemoving(false);
  };

  if (incident.siu_finished_datetime) {
    return <FinishSIU incident={incident} onReopen={onReopen} onClose={onClose} />;
  }

  return (
    <>
      <Formik
        initialValues={{
          siu_report_status: incident.siu_report_status || '',
          siu_criteria: incident.siu_criteria || [],
        }}
        validationSchema={Yup.object().shape({
          siu_report_status: Yup.string(),
          siu_criteria: Yup.array().of(Yup.string().oneOf(possibleSiuCriteria)),
        })}
        enableReinitialize
        onSubmit={async (values, formikProps) => {
          if (
            incident.siu_report_status === values['siu_report_status'] &&
            incident.siu_criteria === values['siu_criteria']
          ) {
            onClose();
            return;
          }
          try {
            await onSubmit({ ...values, siu_criteria: values['siu_criteria'].join(',') });
          } catch (error) {
            formikProps.setSubmitting(false);
          }
        }}
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit } = formikProps;
          const disableSubmitting = isSubmitting || isRemoving;

          return (
            <CardDialog title="Mark as SIU" isDialog maxWidth="sm" onClose={onClose} fullWidth>
              <Grid container spacing={1}>
                {['auto_claim', 'gl_claim', 'wc_claim', 'bike_claim', 'custom_claim'].includes(claim.type) && (
                  <Grid item xs={12}>
                    <MultiSelectTextFieldFormik
                      id="siu_criteria"
                      label="SIU Criteria"
                      options={possibleSiuCriteria}
                      renderValue={(selected) => selected.map((option) => SIU_CRITERIA_DICT[option]['desc']).join('; ')}
                      renderOption={(option) => SIU_CRITERIA_DICT[option]['desc']}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextFieldFormik id="siu_report_status" label="Report status" fullWidth multiline rows="4" />
                </Grid>
              </Grid>
              <Grid container justify={isUpdate ? 'space-between' : 'flex-end'} style={{ marginTop: '16px' }}>
                {isUpdate ? (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleRemove}
                      disabled={disableSubmitting}
                      className={classes.button}
                    >
                      Remove
                    </Button>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setFinishOpen(true)}
                        disabled={disableSubmitting}
                        className={classes.button}
                      >
                        Finish
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={disableSubmitting}
                        className={classes.button}
                      >
                        Update
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={disableSubmitting}
                    className={classes.button}
                  >
                    Set
                  </Button>
                )}
              </Grid>
            </CardDialog>
          );
        }}
      </Formik>
      {finishOpen && <FinishSIU incident={incident} onFinish={onFinish} onClose={() => setFinishOpen(false)} />}
    </>
  );
}

MarkAsSIU.propTypes = {
  incident: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onReopen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function FinishSIU(props) {
  const { incident, onFinish, onReopen, onClose } = props;
  const classes = useStyles();

  const isFinished = incident.siu_finished_datetime;

  return (
    <Formik
      initialValues={{
        siu_result: incident.siu_result || '',
        siu_investigation_summary_document_id: incident.siu_investigation_summary_document_id || '',
      }}
      validationSchema={Yup.object().shape({
        siu_result: Yup.string().required('Required'),
        siu_investigation_summary_document_id: Yup.number(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          if (isFinished) {
            await onReopen(values);
          } else {
            await onFinish(values);
          }
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        const disableSubmitting = isSubmitting;

        return (
          <CardDialog title="Finish SIU Investigation" isDialog maxWidth="sm" onClose={onClose} fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="siu_result"
                  label="SIU Result"
                  fullWidth
                  multiline
                  rows="4"
                  showOnly={isFinished}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentTextFieldFormik
                  id="siu_investigation_summary_document_id"
                  label="Investigation Summary Document"
                  initialValues={{ type: 'siu_investigation_summary', exposure_ids: [0] }}
                  showOnly={isFinished}
                />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              {isFinished ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={disableSubmitting}
                  className={classes.button}
                >
                  Reopen
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={disableSubmitting}
                  className={classes.button}
                >
                  Set
                </Button>
              )}
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

FinishSIU.propTypes = {
  incident: PropTypes.object.isRequired,
  onFinish: requiredIf(PropTypes.func, (props) => !props.incident.siu_finished_datetime),
  onReopen: requiredIf(PropTypes.func, (props) => props.incident.siu_finished_datetime),
  onClose: PropTypes.func.isRequired,
};

function MarkLabelClaim(props) {
  const { incident, onSubmit, onClose, onRemove } = props;
  const classes = useStyles();
  const { userOrganization } = useCms();
  const { claim } = useClaim();

  const [isRemoving, setIsRemoving] = useState(false);
  const T__isMgmWcLabeledCriteriaEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.T__MGM_WC_LABELED_CRITERIA
  );
  const isUpdate = incident.labeled_criteria ? true : false;
  const possibleLabels = Object.keys(MGM_LABEL_DICT[claim.type]);

  const handleRemove = async () => {
    setIsRemoving(true);
    await onRemove();
    setIsRemoving(false);
  };

  return (
    <Formik
      initialValues={{
        labeled_criteria: incident.labeled_criteria || '',
      }}
      validationSchema={Yup.object().shape({
        labeled_criteria: Yup.string().oneOf(possibleLabels).required('Label is required'),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        if (incident.labeled_criteria === values['labeled_criteria']) {
          onClose();
        } else {
          onSubmit({ ...values, labeled_criteria: values['labeled_criteria'] }).catch(() =>
            formikProps.setSubmitting(false)
          );
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const disableSubmitting = isSubmitting || isRemoving;

        return (
          <CardDialog title="Claim Type" isDialog maxWidth="sm" onClose={onClose} fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="labeled_criteria"
                  select
                  label="Claim Type"
                  className={classes.textField}
                  fullWidth
                >
                  {possibleLabels.map((option) => (
                    <MenuItem key={option} value={option}>
                      {MGM_LABEL_DICT[claim.type][option]['desc']}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
            </Grid>
            <Grid container justify={isUpdate ? 'space-between' : 'flex-end'}>
              {isUpdate && !(T__isMgmWcLabeledCriteriaEnabled && claim.type === 'wc_claim') && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleRemove}
                  disabled={disableSubmitting}
                  className={classes.button}
                >
                  Remove
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={disableSubmitting}
                className={classes.button}
              >
                {isUpdate ? ' Update' : 'Set'}
              </Button>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

MarkLabelClaim.propTypes = {
  incident: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function MarkAsControlFile(props) {
  const { incident, onSubmit, onClose, onRemove } = props;
  const classes = useStyles();
  const { controlFileOptions } = useOrganization();

  const [isRemoving, setIsRemoving] = useState(false);
  const isUpdate = incident.is_control_file ? true : false;
  const possibleControlCriteria = Object.keys(controlFileOptions);

  const handleRemove = async () => {
    setIsRemoving(true);
    await onRemove();
    setIsRemoving(false);
  };

  return (
    <Formik
      initialValues={{
        control_file_report_status: incident.control_file_report_status || '',
        control_file_criteria: incident.control_file_criteria || [],
      }}
      validationSchema={Yup.object().shape({
        control_file_report_status: Yup.string(),
        control_file_criteria: Yup.array().of(Yup.string().oneOf(possibleControlCriteria)),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        if (
          incident.control_file_report_status === values['control_file_report_status'] &&
          incident.control_file_criteria === values['control_file_criteria']
        ) {
          onClose();
        } else {
          onSubmit({ ...values, control_file_criteria: values['control_file_criteria'].join(',') }).catch(() =>
            formikProps.setSubmitting(false)
          );
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const disableSubmitting = isSubmitting || isRemoving;

        return (
          <CardDialog title="Mark as Control File" isDialog maxWidth="sm" onClose={onClose} fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MultiSelectTextFieldFormik
                  id="control_file_criteria"
                  label="Control Criteria"
                  options={possibleControlCriteria}
                  renderValue={(selected) =>
                    selected.map((option) => controlFileOptions[option]['short_desc']).join('; ')
                  }
                  renderOption={(option) => controlFileOptions[option]['desc']}
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="control_file_report_status"
                  label="Report status"
                  className={classes.textField}
                  fullWidth
                  multiline
                  rows="4"
                />
              </Grid>
            </Grid>
            <Grid container justify={isUpdate ? 'space-between' : 'flex-end'}>
              {isUpdate && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleRemove}
                  disabled={disableSubmitting}
                  className={classes.button}
                >
                  Remove
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={disableSubmitting}
                className={classes.button}
              >
                {isUpdate ? ' Update' : 'Set'}
              </Button>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

MarkAsControlFile.propTypes = {
  incident: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function MarkAsQraDialog(props) {
  const { onSubmit, onClose, onRemove } = props;
  const classes = useStyles();
  const { claim } = useClaim();
  const { is_qra, qra_note } = claim;
  const isUpdate = is_qra ? true : false;

  return (
    <Formik
      initialValues={{ qra_note: qra_note || '' }}
      validationSchema={Yup.object().shape({ qra_note: Yup.string().required('Required') })}
      enableReinitialize
      onSubmit={(values, formikProps) => onSubmit(values.qra_note).catch(() => formikProps.setSubmitting(false))}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        return (
          <CardDialog title="Mark as QRA" isDialog maxWidth="xs" fullWidth onClose={onClose}>
            <TextFieldFormik id="qra_note" label="QRA Note" className={classes.textField} fullWidth />
            <Grid container justify={isUpdate ? 'space-between' : 'flex-end'}>
              {isUpdate && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onRemove}
                  disabled={isSubmitting}
                  className={classes.button}
                >
                  Remove
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className={classes.button}
              >
                {isUpdate ? ' Update' : 'Set'}
              </Button>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

MarkAsQraDialog.propTypes = {
  incident: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function MarkAsDeferredFileDialog(props) {
  const { incident, onSubmit, onClose, onRemove } = props;
  const classes = useStyles();

  const [isRemoving, setIsRemoving] = useState(false);
  const isUpdate = incident.is_deferred_file ? true : false;

  const handleRemove = async () => {
    setIsRemoving(true);
    await onRemove();
    setIsRemoving(false);
  };

  return (
    <Formik
      initialValues={{
        deferred_file_report_status: incident.deferred_file_report_status || '',
      }}
      validationSchema={Yup.object().shape({
        deferred_file_report_status: Yup.string(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        if (isUpdate && incident.deferred_file_report_status === values['deferred_file_report_status']) {
          return onClose();
        }

        try {
          await onSubmit({ ...values, deferred_file_report_status: values['deferred_file_report_status'] });
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        const disableSubmitting = isSubmitting || isRemoving;

        return (
          <CardDialog title="Mark as Misc" isDialog maxWidth="sm" onClose={onClose} fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="deferred_file_report_status"
                  label="Report Status"
                  className={classes.textField}
                  fullWidth
                  multiline
                  rows="4"
                />
              </Grid>
            </Grid>
            <Grid container justify={isUpdate ? 'space-between' : 'flex-end'}>
              {isUpdate && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleRemove}
                  disabled={disableSubmitting}
                  className={classes.button}
                >
                  Remove
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={disableSubmitting}
                className={classes.button}
              >
                {isUpdate ? ' Update' : 'Set'}
              </Button>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

MarkAsDeferredFileDialog.propTypes = {
  incident: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IncidentMoreActionsContainer;
