import React, { useState } from 'react';
import { Popover } from '@material-ui/core';

import DropDownIcon from '../../../icons/editor/DropDownIcon';

import ToolbarButton from './ToolbarButton';

export interface OptionProps {
  isActive: boolean;
  disabled: boolean;
  onClick: () => void;
  content: React.ReactNode;
}

export interface MultiOptionProps {
  options: OptionProps[];
  initial?: number;
}

const MultiOptions: React.FC<MultiOptionProps> = ({ options, initial = 0 }) => {
  const [selected, setSelected] = useState(initial);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handlePopoverTriggerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (index: number) => {
    options[index].onClick();
    setSelected(index);
    handleClose();
  };

  return (
    <>
      <ToolbarButton disabledHoverEffect onClick={handlePopoverTriggerClick}>
        <div className="inline-flex items-center">
          {options[selected].content}
          <DropDownIcon className="ml-4" size={12} />
        </div>
      </ToolbarButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div>
          {options.map(({ isActive, disabled, content }, index) => (
            <ToolbarButton key={index} isActive={isActive} disabled={disabled} onClick={() => handleOptionClick(index)}>
              {content}
            </ToolbarButton>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default MultiOptions;
