import React from 'react';

import cn from '~/Utils/cn';

interface StepIconProps {
  icon: React.ReactNode;
  active: boolean;
  completed: boolean;
}

const StepIconComponent: React.FC<StepIconProps> = ({ icon, active, completed }) => (
  <div
    className={cn(
      'box-border flex h-24 w-24 items-center justify-center rounded-full border border-solid border-slate-600 text-base text-slate-600',
      {
        'border-teal-600 bg-teal-600 text-white': active,
        'border-teal-600 text-teal-600': completed,
      }
    )}
  >
    {icon}
  </div>
);

export default StepIconComponent;
