import React from 'react';
import { Divider } from '@material-ui/core';

import { useClaim } from '~/components/ClaimContainer';
import { getRoleDescription } from '~/components/communications/ContactUtils';
import InvolvedLinks from '~/components/Contacts/ContactMiniCard/InvolvedLinks';
import RepresentativeLink from '~/components/Contacts/ContactMiniCard/RepresentativeLink';
import type { ContactFullModel } from '~/components/Contacts/types';
import Text from '~/components/core/TextComponents/Text';
import { useCms } from '~/components/hooks/useCms';
import useOrganization from '~/components/OrganizationContext';
import { isDatesDiffLessThan18Years } from '~/DateTimeUtils';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

interface IdentitySubHeaderPropsType {
  contact: ContactFullModel;
}

const IdentitySubHeader: React.FC<IdentitySubHeaderPropsType> = ({ contact }) => {
  const { claim } = useClaim();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const contactRoleDesc = getRoleDescription(contact, organizationContactRolesDict);

  const isMedicalBillProviderProfessionFieldFeatureEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MEDICAL_BILL_PROVIDER_PROFESSION_FIELD
  );

  return (
    <div>
      {!contact.is_company && (contact.sex || contact.date_of_birth) ? (
        <div className="flex">
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
            {contact.sex || ''}
          </Text>
          {contact.date_of_birth ? (
            <>
              {contact.sex ? <Divider orientation="vertical" variant="middle" flexItem /> : null}
              <Text
                variant={Text.VARIANTS.SM}
                weight={Text.WEIGHTS.REGULAR}
                colorVariant={Text.COLOR_VARIANTS.SECONDARY}
              >
                {contact.date_of_birth}
              </Text>
              {claim && isDatesDiffLessThan18Years(contact.date_of_birth, claim.incident.date_of_loss) ? (
                <>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Text variant={Text.VARIANTS.SM} colorVariant={Text.COLOR_VARIANTS.ERROR}>
                    Minor
                  </Text>
                </>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}

      <div className="flex">
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
          {contactRoleDesc}
        </Text>
        {isMedicalBillProviderProfessionFieldFeatureEnabled && contact.contact_extra?.profession && (
          <>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {MEDICAL_BILL_PROVIDER_PROFESSIONS[contact.contact_extra.profession].desc}
            </Text>
          </>
        )}
      </div>
      {claim && (
        <>
          <RepresentativeLink contact={contact} />
          <InvolvedLinks contact={contact} />
        </>
      )}
    </div>
  );
};

export default IdentitySubHeader;
