import React, { useEffect } from 'react';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Paper, Tab, Tabs } from '@material-ui/core';
import urljoin from 'url-join';

import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { useCms } from '../../hooks/useCms';

import PaymentApprovals from './PaymentApprovals';
import PaymentSubmissions from './PaymentSubmissions';

import { useStyles } from '../../../assets/styles';

const FinanceApprovalTab = () => {
  const classes = useStyles();
  const { setPageTitle } = useCms();
  const match = useRouteMatch();
  const location = useLocation();
  const { isApprovalPaymentsScreenEnabled, isPaymentsSubmissionScreenEnabled } = usePaymentsConfiguration();

  const possibleTabs = [
    {
      label: 'Payment Approvals',
      url: 'payment_approvals',
      component: PaymentApprovals,
      conditionFunc: () => isApprovalPaymentsScreenEnabled,
    },
    {
      label: 'Payment Submissions',
      url: 'payment_submissions',
      component: PaymentSubmissions,
      conditionFunc: () => isPaymentsSubmissionScreenEnabled,
    },
  ];

  const tabs = possibleTabs.filter((tab) => (tab.conditionFunc ? tab.conditionFunc() : true));

  const path = location.pathname.substring(match.url.length + 1);
  const breadcrumbs = path.split('/');
  let currTabValue = tabs.findIndex((tab) => breadcrumbs.includes(tab.url));
  currTabValue = currTabValue >= 0 ? currTabValue : 0;

  useEffect(() => {
    setPageTitle('Approvals', 'Approvals - Five Sigma CMS');
  }, [setPageTitle]);

  return (
    <>
      <div>
        <Paper className="bg-slate-200">
          <Tabs value={currTabValue} style={{ minHeight: 40 }} variant="scrollable">
            {tabs.map((tab) => (
              <Tab
                style={{
                  minHeight: 40,
                  padding: 0,
                }}
                key={tab.url}
                label={tab.label}
                component={Link}
                to={`${match.url}/${tab.url}`}
              />
            ))}
            ;
          </Tabs>
        </Paper>
      </div>
      <div className={classes.pageBody}>
        <Switch>
          <Redirect exact from={`${match.path}/`} to={urljoin(match.path, tabs[0].url)} />
          {tabs.map((tab) => (
            <Route key={tab.url} path={urljoin(match.path, tab.url)} render={() => <tab.component />} />
          ))}
          ;
          <Redirect to={match.path} />
        </Switch>
      </div>
    </>
  );
};

export default FinanceApprovalTab;
