import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { intersection } from 'lodash';

import { useClaim } from '~/components/ClaimContainer';
import { getSupportedContactRolesTypes } from '~/components/Contact';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { useCms } from '~/components/hooks/useCms';
import useOrganization from '~/components/OrganizationContext';
import { TextFieldFormik } from '~/components/TextFieldFormik';

import { FORMIK_DETAILS_FIELD_IDS } from '../../constants';
import { useContact } from '../../useContact';
import { getFullDetailsTabFieldPath } from '../../utils';

const RoleSelect = ({ disabled }) => {
  const { claim } = useClaim();
  const { userOrganization } = useCms();
  const { organizationContactRolesDict } = useOrganization();
  const { isPolicyContact, originalContact, setCurrentRole, specialRoleBehavior } = useContact();
  const { values, setFieldValue } = useFormikContext();

  const originalContactRoleMetadata = originalContact ? organizationContactRolesDict[originalContact.role] : null;
  const multiClaimRoles = Object.keys(organizationContactRolesDict).filter(
    (key) => organizationContactRolesDict[key]?.is_multi_claim
  );

  const onRoleSelect = (role) => {
    setFieldValue(getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.ROLE), role);
    setCurrentRole(role);
  };

  const getSortedRoles = () => {
    let contactRolesList = getSupportedContactRolesTypes(
      organizationContactRolesDict,
      Object.keys(organizationContactRolesDict),
      claim ? [claim.lob] : userOrganization.supported_claim_types
    );

    if (specialRoleBehavior) {
      contactRolesList = intersection(contactRolesList, specialRoleBehavior.acceptedRoles);
    }

    if (originalContactRoleMetadata?.is_multi_claim) {
      contactRolesList = intersection(contactRolesList, multiClaimRoles);
    }

    const sortedRoles = contactRolesList.filter((contactRole) => !organizationContactRolesDict[contactRole].is_system);
    sortedRoles.sort((a, b) => (organizationContactRolesDict[a].desc > organizationContactRolesDict[b].desc ? 1 : -1));

    return sortedRoles;
  };
  const sortedRoles = getSortedRoles();

  if (!getIn(values, getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.ROLE)) && sortedRoles.length === 1) {
    onRoleSelect(sortedRoles[0]);
  }
  const isMoiServiceProvider = getIn(
    values,
    getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.IS_MOI_SERVICE_PROVIDER)
  );

  return (
    <div className="grid grid-cols-2 gap-20">
      <TextFieldFormik
        id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.ROLE)}
        label="Role"
        select
        fullWidth
        showOnly={sortedRoles.length === 1 || isPolicyContact}
        onChange={(event) => onRoleSelect(event.target.value)}
        disabled={disabled || isMoiServiceProvider || specialRoleBehavior?.editingRoleDisabled}
      >
        {sortedRoles.map((contactRole) => (
          <MenuItem key={contactRole} value={contactRole}>
            {organizationContactRolesDict[contactRole].desc}
          </MenuItem>
        ))}
      </TextFieldFormik>
      {getIn(values, getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.ROLE)) === 'other' ? (
        <TextFieldFormik
          id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.ROLE_OTHER_DESC)}
          label="Role Description"
          fullWidth
          showOnly={isPolicyContact}
          disabled={disabled || isMoiServiceProvider}
        />
      ) : null}
    </div>
  );
};

RoleSelect.propTypes = {
  disabled: PropTypes.bool,
};

export default RoleSelect;
