import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '~/components/core/Atomic/MenuItem';

import LoadingIndicator from './LoadingIndicator';
import { TextFieldFormik } from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';

function OrganizationSelectFormik(props) {
  const { isLoading, isError, data: organizations } = useDataFetcher('/api/v1/organizations');
  const { id, label } = props;

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <TextFieldFormik id={id} label={label} select fullWidth>
      {organizations.map((organization) => (
        <MenuItem key={organization.id} value={organization}>
          {organization.name}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
}

OrganizationSelectFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default OrganizationSelectFormik;
