import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import MenuItem from '~/components/core/Atomic/MenuItem';
import TextFieldFormik from '~/components/TextFieldFormik';

const ChangeReason = ({ reasonFieldId, explanationFieldId, reasonsDict, disabled }) => {
  const { values } = useFormikContext();

  return (
    <div className="grid grid-cols-1 gap-20">
      <TextFieldFormik id={reasonFieldId} select label="Reason" fullWidth disabled={disabled}>
        {Object.keys(reasonsDict).map((reason) => (
          <MenuItem key={reason} value={reason}>
            {reasonsDict[reason]['desc']}
          </MenuItem>
        ))}
      </TextFieldFormik>
      {getIn(values, reasonFieldId) === 'other' ? (
        <TextFieldFormik id={explanationFieldId} label="Explanation" fullWidth disabled={disabled} />
      ) : null}
    </div>
  );
};

ChangeReason.propTypes = {
  className: PropTypes.string,
  reasonFieldId: PropTypes.string.isRequired,
  explanationFieldId: PropTypes.string.isRequired,
  reasonsDict: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default ChangeReason;
