import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import { isoUtcDateTimeToLocal, serverDateTimeToLocal } from '../../DateTimeUtils';
import { PET_PERILS_DICT } from '../../Types';
import { getBasicFnolValues, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useContacts } from '../ContactsContext';
import FnolHeader from '../Fnol/FnolHeader';
import { trackOldFnolClick } from '../Fnol/FnolScreen';
import LoadingDialog from '../LoadingDialog';
import { usePolicy } from '../PolicyContainer';

import {
  petIncidentDetailsFields,
  PetIncidentDetailsFormikInner,
  petIncidentDetailsValidationFields,
} from './OldPetIncidentDetails';
import { InvolvedPetFormik, involvedPetValidationScheme, petInvolvedFields } from './PetInvolvedCard';

import { useStyles } from '../../assets/styles';

function PetFnol({ onSubmit, onSubmitDraft, fnolDraft, subOrganizationId }) {
  const { contacts } = useContacts();
  const { policy } = usePolicy();
  const classes = useStyles();
  const [draftLastUpdate, setDraftLastUpdate] = useState(
    fnolDraft ? serverDateTimeToLocal(fnolDraft.last_updated) : undefined
  );

  const submitPetFnol = async (values) => {
    try {
      const res = await axios.post('/api/v1/pet_claims', {
        ...values,
        fnol_contacts_ids: contacts.map((contact) => contact.id),
      });
      onSubmit(res.data.id);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleSubmitDraft = async (values, setSubmitting) => {
    await onSubmitDraft(values);
    setDraftLastUpdate(isoUtcDateTimeToLocal(new Date()));
    setSubmitting(false);
  };

  const mapPropsToFormikValues = () => {
    const INITIAL_FNOL_VALUES = {
      ...getBasicFnolValues(),
      ...petIncidentDetailsFields,
    };

    const coveredPets = policy.covered_pets || [];
    let involvedPet = {
      ...petInvolvedFields,
      owner_contact_id: policy.insured_contact.id,
      owner_contact_full_name: policy.insured_contact.full_name,
    };

    if (coveredPets.length > 0) {
      involvedPet = {
        ...involvedPet,
        pet: { ...coveredPets[0].pet },
        covered_pet_id: coveredPets[0].id,
      };
    } else if (policy.is_manual) {
      involvedPet.covered_pet_id = 0;
    }

    let initialValues = {
      ...INITIAL_FNOL_VALUES,
      ...policy,
      policy_id: policy.id,
      is_manual_policy: !!policy.is_manual,
      reporter: 'Insured', // because we're setting the insured as primary_contact, hence we assume it's reported by the insured
      primary_contact_id: policy.insured_contact.id,
      reporter_id: policy.insured_contact.id,
      loss_location: { ...policy.insured_property_location },
      verified_insured_info: !!policy.is_manual,
      witnesses: [],
      peril: '',
      involved_pet: involvedPet,
    };

    return initialValues;
  };

  return (
    <Formik
      initialValues={{ ...mapPropsToFormikValues(), ...fnolDraft?.draft }}
      validationSchema={Yup.object().shape({
        ...petIncidentDetailsValidationFields,
        peril: Yup.string().required('Required').oneOf(Object.keys(PET_PERILS_DICT)),
        involved_pet: involvedPetValidationScheme,
      })}
      onSubmit={async (values, formikBag) => {
        const { setSubmitting } = formikBag;
        await submitPetFnol(values, setSubmitting);
        setSubmitting(false);
        trackOldFnolClick(policy, 'old_pet_fnol');
      }}
    >
      {(formikProps) => {
        const { setSubmitting, values, isSubmitting, handleSubmit } = formikProps;
        return (
          <>
            <FnolHeader policy={policy} claimType="pet_claim" subOrganizationId={subOrganizationId} />
            <Grid container alignItems="stretch">
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <PetIncidentDetailsFormikInner />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <PetPartyFormik />
                </div>
              </Grid>
            </Grid>
            <div className={classes.cardDivRow}>
              <div className={classes.buttonsContainer}>
                {!policy.is_manual && onSubmitDraft && (
                  <>
                    {draftLastUpdate && (
                      <Typography
                        className={classes.cardDivRow}
                        color="textSecondary"
                      >{`Draft saved on ${draftLastUpdate}`}</Typography>
                    )}
                    <Button
                      onClick={async () => {
                        setSubmitting(true);
                        await handleSubmitDraft(values, setSubmitting);
                      }}
                      variant="contained"
                      color="primary"
                      className={classes.leftButtonDialog}
                      disabled={isSubmitting}
                    >
                      Save Draft
                    </Button>
                  </>
                )}
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isSubmitting}>
                  Create FNOL
                </Button>
                {isSubmitting && <LoadingDialog isError={false} track="Submit Pet Fnol" />}
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

PetFnol.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitDraft: PropTypes.func.isRequired,
  fnolDraft: PropTypes.object,
  subOrganizationId: PropTypes.number,
};

function PetPartyFormik() {
  return (
    <CardDialog title="Involved Pet">
      <InvolvedPetFormik parentId="involved_pet" />
    </CardDialog>
  );
}

PetPartyFormik.propTypes = {};

export default PetFnol;
