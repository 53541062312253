import React from 'react';
import * as Sentry from '@sentry/react';
import type { ErrorBoundaryProps } from '@sentry/react/types/errorboundary';
import { isEmpty } from 'lodash';

import { FallbackComponent } from '~/components/core/SafeComponent/FallbackComponents';
import { FallbackErrorPage } from '~/components/core/SafeComponent/FallbackErrorPage';

export enum CriticalityLevel {
  HIGHEST = 'highest',
  HIGH = 'high',
  MEDIUM = 'medium',
}

export interface SafeComponentProps {
  fallbackComponent?: ErrorBoundaryProps['fallback'];
  location?: string;
  errorMetadata?: Record<string, string>;
  criticalityLevel?: CriticalityLevel;
}

const SafeComponent: React.FC<SafeComponentProps> = ({
  fallbackComponent,
  location,
  errorMetadata,
  criticalityLevel,
  children,
}) => {
  const beforeCapture =
    location || errorMetadata
      ? (scope: Sentry.Scope) => {
          const tags = errorMetadata || {};

          if (location) {
            tags.location = location;
          }

          if (criticalityLevel) {
            tags.criticalityLevel = criticalityLevel;
            if (criticalityLevel === 'highest') {
              scope.setLevel('fatal');
            }
          }

          if (!isEmpty(tags)) {
            scope.setTags(tags);
          }
        }
      : undefined;

  const fallback = fallbackComponent || <FallbackComponent />;

  return (
    <Sentry.ErrorBoundary fallback={fallback} beforeCapture={beforeCapture}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export const withSafeComponent = <T extends React.ComponentPropsWithRef<React.FC>>(
  WrappedComponent: React.FC<T> | React.ComponentType<T>,
  fallback?: SafeComponentProps['fallbackComponent'],
  config?: SafeComponentProps
): React.FC<T> => {
  const SafeWrappedComponent = (props: T) => (
    <SafeComponent
      fallbackComponent={fallback}
      errorMetadata={config?.errorMetadata}
      location={config?.location}
      criticalityLevel={config?.criticalityLevel}
    >
      <WrappedComponent {...props} />
    </SafeComponent>
  );
  return SafeWrappedComponent;
};

export const withSafeComponentPage = <T extends React.ComponentPropsWithRef<React.FC>>(
  WrappedComponent: React.FC<T> | React.ComponentType<T>,
  config?: SafeComponentProps
): React.FC<T> => {
  const SafeWrappedComponent = (props: T) => (
    <SafeComponent
      fallbackComponent={<FallbackErrorPage />}
      errorMetadata={config?.errorMetadata}
      location={config?.location}
      criticalityLevel={config?.criticalityLevel}
    >
      <WrappedComponent {...props} />
    </SafeComponent>
  );
  return SafeWrappedComponent;
};

export const SafeComponentPage: React.FC<SafeComponentProps> = ({
  location,
  errorMetadata,
  criticalityLevel,
  children,
}) => (
  <SafeComponent
    fallbackComponent={<FallbackErrorPage />}
    location={location}
    errorMetadata={errorMetadata}
    criticalityLevel={criticalityLevel}
  >
    {children}
  </SafeComponent>
);

export default SafeComponent;
