import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import CardDialog from '../CardDialog';
import GLFnolContainer from '../GL/GLFnolContainer';
import { usePositionLeftZenDesk } from '../hooks/useZenDesk';
import { TextFieldFormik } from '../TextFieldFormik';
import WCFnolContainer from '../WC/WCFnolContainer';

import { useStyles } from '../../assets/styles';

const lobDisplayDict = {
  gl: 'GL',
  wc: 'WC',
};

function MgmFnolScreen({ organization }) {
  const classes = useStyles();
  const [lob, setLob] = useState(undefined);

  usePositionLeftZenDesk();

  if (lob) {
    if (lob === 'gl') return <GLFnolContainer organization={organization} />;
    else if (lob === 'wc') return <WCFnolContainer organization={organization} />;
    else throw Error('Unknown LOB for MGM FNOL');
  }

  // Choosing between WC to GL
  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Formik
            initialValues={{ lob: '' }}
            validationSchema={Yup.object().shape({ lob: Yup.string().required('Required') })}
            onSubmit={(values) => setLob(values['lob'])}
          >
            {(formikProps) => {
              const { isSubmitting, handleSubmit } = formikProps;
              return (
                <CardDialog title="Select LOB" maxWidth="sm" width="400px">
                  <TextFieldFormik id="lob" label="Line of Business" select fullWidth>
                    {['wc', 'gl'].map((lob) => (
                      <MenuItem key={lob} value={lob}>
                        {lobDisplayDict[lob]}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                  <div className={classes.buttonsContainer}>
                    <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                      Select
                    </Button>
                  </div>
                </CardDialog>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}

MgmFnolScreen.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default MgmFnolScreen;
