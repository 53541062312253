import React from 'react';

import { Text } from '../../../TextComponents/index';
import { CardFrame } from '../CardBase';
import type { CardBaseExternalProps, CardType } from '../types';
import { CardTypeToStyleAttributesMap } from '../types';

interface ObjectCardProps extends CardBaseExternalProps {
  type: CardType;
}

const ObjectCardTypeToStyleAttributesMap = {
  ...CardTypeToStyleAttributesMap,
  filled: {
    shadow: false,
    border: false,
    backgroundColor: 'bg-blueSky-100',
  },
};

export const ObjectCard: React.FC<ObjectCardProps> = ({ type, children, action, title }) => {
  return (
    <CardFrame {...ObjectCardTypeToStyleAttributesMap[type]} paddingX={20} paddingY={20}>
      {title ? (
        <div className="flex w-full pb-12">
          <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.PRIMARY}>
            {title}
          </Text>
        </div>
      ) : null}
      <div className="row-auto flex justify-between">
        <div className="flex w-full gap-20 overflow-hidden">{children}</div>
        <div className="ml-20 flex items-center justify-center">{action}</div>
      </div>
    </CardFrame>
  );
};
