const MGM_WC_BODY_PART_CODE_DICT = {
  '10': {
    desc: 'MULTIPLE HEAD INJURY',
  },
  '11': {
    desc: 'SKULL',
  },
  '12': {
    desc: 'BRAIN',
  },
  '13': {
    desc: 'EAR(S)',
  },
  '14': {
    desc: 'EYE(S)',
  },
  '15': {
    desc: 'NOSE',
  },
  '16': {
    desc: 'TEETH',
  },
  '17': {
    desc: 'MOUTH',
  },
  '18': {
    desc: 'SOFT TISSUE HEAD',
  },
  '19': {
    desc: 'FACIAL BONES',
  },
  '20': {
    desc: 'MULTIPLE NECK INJURY',
  },
  '21': {
    desc: 'CERVICAL VERTIBRAE',
  },
  '22': {
    desc: 'CERVICAL DISC',
  },
  '23': {
    desc: 'CERVICAL SPINAL CORD',
  },
  '24': {
    desc: 'LARYNX',
  },
  '25': {
    desc: 'SOFT TISSUE NECK',
  },
  '26': {
    desc: 'TRACHEA',
  },
  '30': {
    desc: 'MULTIPLE UPPER EXTREMITIES',
  },
  '31': {
    desc: 'UPPER ARM (INC. CLAVICLE & SCAPULA)',
  },
  '32': {
    desc: 'ELBOW',
  },
  '33': {
    desc: 'FOREARM',
  },
  '34': {
    desc: 'WRIST',
  },
  '35': {
    desc: 'HAND',
  },
  '36': {
    desc: 'FINGER(S)',
  },
  '37': {
    desc: 'THUMB',
  },
  '38': {
    desc: 'SHOULDER(S)',
  },
  '39': {
    desc: 'WRIST(S) & HAND(S)',
  },
  '40': {
    desc: 'MULTIPLE TRUNK',
  },
  '41': {
    desc: 'UPPER BACK AREA (THORACIC AREA)',
  },
  '42': {
    desc: 'LOW BACK AREA (INC. LUMBAR & LUMBO-SACRAL)',
  },
  '43': {
    desc: 'VERTEBRAEL DISC',
  },
  '44': {
    desc: 'CHEST/RIBS/BREAST/STERNUM',
  },
  '45': {
    desc: 'SACRUM AND COCCYX',
  },
  '46': {
    desc: 'PELVIS',
  },
  '47': {
    desc: 'SPINAL CORD',
  },
  '48': {
    desc: 'INTERNAL ORGANS',
  },
  '49': {
    desc: 'HEART',
  },
  '50': {
    desc: 'MULTIPLE LOWER EXTREMITIES',
  },
  '51': {
    desc: 'HIP',
  },
  '52': {
    desc: 'UPPER LEG',
  },
  '53': {
    desc: 'KNEE(S)',
  },
  '54': {
    desc: 'LOWER LEG',
  },
  '55': {
    desc: 'ANKLE(S)',
  },
  '56': {
    desc: 'FOOT/FEET',
  },
  '57': {
    desc: 'TOE(S)',
  },
  '58': {
    desc: 'GREAT TOE',
  },
  '60': {
    desc: 'LUNGS',
  },
  '61': {
    desc: 'ABDOMEN (INC. GROIN/INGUINAL AREA)',
  },
  '62': {
    desc: 'BUTTOCKS',
  },
  '63': {
    desc: 'LUMBAR AND/OR SACRAL VERTEBRAE',
  },
  '65': {
    desc: 'INSUFF. INFO. TO PROPERLY IDENTIFY-UNCLASSIFIED',
  },
  '90': {
    desc: 'MULTIPLE BODY PARTS',
  },
  '91': {
    desc: 'BODY SYSTEMS & MULT. BODY SYSTEMS',
  },
  '100': {
    desc: 'HEAD (UNSPECIFIED)',
  },
  '140': {
    desc: 'FACE (UNSPECIFIED)',
  },
  '148': {
    desc: 'FACE (MULTIPLE PARTS)',
  },
  '150': {
    desc: 'SCALP',
  },
  '318': {
    desc: 'ARM(S)',
  },
  '420': {
    desc: 'BACK (MULTIPLE)',
  },
  '424': {
    desc: 'BACK (UNSPECIFIED)',
  },
  '500': {
    desc: 'LEFT SIDE',
  },
  '501': {
    desc: 'RIGHT SIDE',
  },
  '518': {
    desc: 'LEG(S)',
  },
  '890': {
    desc: 'SKIN',
  },
  M120: {
    desc: 'MI-Ear(s), Unspecified',
  },
  M130: {
    desc: 'MI-Eye(s), (inc. optic nerves, vision, eyelid)',
  },
  M144: {
    desc: 'MI-Mouth (inc. lips, tongue, throat, taste)',
  },
  M149: {
    desc: 'MI-Face, Not Classified Elsewhere & Forehead',
  },
  M310: {
    desc: 'MI-Arm(s), Above Wrist, Unspecified',
  },
  M319: {
    desc: 'MI-Arm, not elsewhere classified',
  },
  M350: {
    desc: 'MI-Fingertip(s) (no specific loss)',
  },
  M397: {
    desc: 'MI-Hand & Finger(s)',
  },
  M410: {
    desc: 'MI-Abdomen (inc. int. organs & inguinal hernia)',
  },
  M430: {
    desc: 'MI-Chest (ribs/sternum/thorax, pecs/int. organs)',
  },
  M440: {
    desc: 'MI-Hips (inc. pelvis, pelvic organs, buttocks)',
  },
  M510: {
    desc: 'MI-Leg(s) (above ankle), Unspecified',
  },
  M519: {
    desc: 'MI-Leg, Not Elsewhere Classified',
  },
  M550: {
    desc: 'MI-Toetips (inj. not resulting in specific loss)',
  },
  M700: {
    desc: 'MI-Multiple Parts (more than one major body part)',
  },
  M880: {
    desc: 'MI-Other Body Systems',
  },
} as const;

export default MGM_WC_BODY_PART_CODE_DICT;
