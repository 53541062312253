import type { AxiosResponse } from 'axios';
import axios from 'axios';

import { useTableauApiRoutes } from '~/components/ReportsPage/Hooks/useTableauApiRoutes';

interface TableauApi {
  deleteWorkbook: (p: { workbookId: string }) => Promise<AxiosResponse>;
  copyWorkbook: (p: CopyWorkbookProps) => Promise<AxiosResponse>;
}

interface CopyWorkbookProps {
  workbookId: string;
  workbookName: string;
  projectId: string;
}

export function useTableauApi(): TableauApi {
  const { workbookUrl } = useTableauApiRoutes();

  function deleteWorkbook({ workbookId }: { workbookId: string }) {
    const url = workbookUrl({ workbookId });
    return axios.delete(url);
  }

  function copyWorkbook({ workbookId, workbookName, projectId }: CopyWorkbookProps) {
    const url = workbookUrl({ workbookId });

    return axios.post(url, {
      workbook_name: workbookName,
      project_id: projectId,
    });
  }

  return {
    deleteWorkbook,
    copyWorkbook,
  };
}
