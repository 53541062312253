import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../../core/SvgIcon';

import colors from '../../../../assets/colors.module.scss';
import styles from './sparkle.module.scss';

// TODO: Fix 32 viewBox
const Sparkle = ({ iconColor = colors.brandHighlights, animated = true, ...props }) => (
  <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <g>
      <path
        className={animated ? styles.elementToFadeInAndOut : null}
        d="M10.105 21.3087L3.64749 18.9337C3.45761 18.8637 3.29377 18.7371 3.17806 18.571C3.06234 18.4049 3.00031 18.2074 3.00031 18.005C3.00031 17.8026 3.06234 17.6051 3.17806 17.439C3.29377 17.2729 3.45761 17.1463 3.64749 17.0762L10.105 14.7012C10.2394 14.652 10.3616 14.5741 10.4628 14.4728C10.564 14.3716 10.642 14.2495 10.6912 14.115L13.0662 7.6575C13.1363 7.46762 13.2629 7.30378 13.429 7.18807C13.595 7.07235 13.7926 7.01031 13.995 7.01031C14.1974 7.01031 14.3949 7.07235 14.561 7.18807C14.727 7.30378 14.8537 7.46762 14.9237 7.6575L17.2987 14.115C17.348 14.2495 17.4259 14.3716 17.5272 14.4728C17.6284 14.5741 17.7505 14.652 17.885 14.7012L24.3425 17.0762C24.5324 17.1463 24.6962 17.2729 24.8119 17.439C24.9276 17.6051 24.9897 17.8026 24.9897 18.005C24.9897 18.2074 24.9276 18.4049 24.8119 18.571C24.6962 18.7371 24.5324 18.8637 24.3425 18.9337L17.885 21.3087C17.7505 21.358 17.6284 21.4359 17.5272 21.5372C17.4259 21.6384 17.348 21.7605 17.2987 21.895L14.9237 28.3525C14.8537 28.5424 14.727 28.7062 14.561 28.8219C14.3949 28.9376 14.1974 28.9997 13.995 28.9997C13.7926 28.9997 13.595 28.9376 13.429 28.8219C13.2629 28.7062 13.1363 28.5424 13.0662 28.3525L10.6912 21.895C10.642 21.7605 10.564 21.6384 10.4628 21.5372C10.3616 21.4359 10.2394 21.358 10.105 21.3087Z"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 2V8"
        className={animated ? styles.elementToFadeInAndOutSmall : null}
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 9V13"
        className={animated ? styles.elementToFadeInAndOutSmallSec : null}
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 5H25"
        className={animated ? styles.elementToFadeInAndOutSmall : null}
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 11H30"
        className={animated ? styles.elementToFadeInAndOutSmallSec : null}
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);

Sparkle.propTypes = {
  iconColor: PropTypes.string,
  animated: PropTypes.bool,
};

export default Sparkle;
