export const COLUMN_KEYS = {
  request_date: 'request_date',
  claim_id_display: 'claim_id_display',
  requesting_user: 'requesting_user',
  financial_status_last_update: 'financial_status_last_update',
  exposure_label: 'exposure_label',
  payable_with_reserve_type: 'payable_with_reserve_type',
  request_status: 'request_status',
  financial_status: 'financial_status',
  amount: 'amount',
  actions: 'actions',
  select_finance: 'select_finance',
  select_row_button: 'select_row_button',
};
export const EXTENDABLE_COLUMN_KEYS = {
  sub_reserves: 'sub_reserves',
  issued_by: 'issued_by',
  payment_type: 'payment_type_desc',
  payees: 'payees',
  sent_to: 'sent_to',
  reference: 'reference',
  note: 'note',
  deductible_applied: 'deductible_applied',
  payment_method: 'payment_method',
};
