import { isInshurOrganization } from '../../../Utils';
import getOneIncMethodSpecificPaymentRequest from '../../OneInc/MethodSpecificPaymentRequest';

import getDefaultMethodSpecificPaymentRequest from './DefaultMethodSpecificPaymentRequest/DefaultMethodSpecificPaymentRequest';

const getMethodSpecificPaymentRequest = (paymentMethod, organization) => {
  const getMethodSpecificPaymentRequest =
    paymentMethod === 'one_inc' && !isInshurOrganization(organization)
      ? getOneIncMethodSpecificPaymentRequest
      : getDefaultMethodSpecificPaymentRequest;
  const {
    methodSpecificInitialValues,
    methodSpecificValidationSchema,
    PaymentDetailsComponent,
    MethodDetailsComponent,
    additionalFinanceTableMenuOptions,
  } = getMethodSpecificPaymentRequest();

  return {
    methodSpecificInitialValues,
    methodSpecificValidationSchema,
    PaymentDetailsComponent,
    MethodDetailsComponent,
    additionalFinanceTableMenuOptions,
  };
};

export default getMethodSpecificPaymentRequest;
