const AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST = [
  '0%',
  '10%',
  '20%',
  '30%',
  '40%',
  '50%',
  '60%',
  '70%',
  '80%',
  '90%',
  '100%',
] as const;

export default AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST;
