import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { noop } from 'lodash';

import Typography from '~/components/core/Atomic/Typography';
import cn from '~/Utils/cn';

import RadioButtonFormik from '../../RadioButtonFormik';

import { colorPalette, useStyles } from '../../../assets/styles';

export const POSITIONS = {
  START: 'start',
  END: 'end',
};

const RadioWithButtonWrapperFormik = ({
  id,
  className,
  icon,
  text,
  value,
  onClick,
  disabled,
  wrapperOverrideStyle = {},
  color,
  iconLocation = POSITIONS.START,
  labelClassName,
}) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const textColor = disabled ? colorPalette.text.disabled : colorPalette.text.primary;
  const marginFromSideProp = iconLocation === POSITIONS.START ? 'marginRight' : 'marginLeft';
  const iconWrapper = <span style={{ margin: 'auto 0', [marginFromSideProp]: '10px' }}>{icon}</span>;

  const label = (
    <div style={{ display: 'flex' }}>
      {iconLocation === POSITIONS.START && iconWrapper}
      <Typography display="block" variant="body1" style={{ color: textColor }} className={labelClassName}>
        {text}
      </Typography>
      {iconLocation === POSITIONS.END && iconWrapper}
    </div>
  );

  return (
    <div
      className={cn(classes.radioOrCheckboxWithIcon, className, { disabled })}
      onClick={() => {
        if (!disabled) {
          setFieldValue(id, value);
          onClick && onClick();
        }
      }}
      style={wrapperOverrideStyle}
    >
      <RadioButtonFormik
        id={id}
        label={label}
        optionValue={value}
        disabled={disabled}
        size="small"
        onChange={noop}
        onClick={onClick}
        color={color}
      />
    </div>
  );
};

RadioWithButtonWrapperFormik.POSITIONS = POSITIONS;

RadioWithButtonWrapperFormik.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  wrapperOverrideStyle: PropTypes.object,
  color: PropTypes.string,
  iconLocation: PropTypes.oneOf(Object.values(POSITIONS)),
  labelClassName: PropTypes.string,
};

export default RadioWithButtonWrapperFormik;
