import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';

import { ContactShowOnlyTextField } from '../../ContactTextFieldFormik';

import PrimaryPayeeIndicator from './PrimaryPayeeIndicator';

function PaymentPayeesShowOnly({ paymentRequest }) {
  function renderPayee(payee) {
    return (
      <>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <PrimaryPayeeIndicator isPrimaryPayee={payee.contact.id === paymentRequest.primary_payee_id} />
          <ContactShowOnlyTextField
            contactId={payee.contact.id}
            contactDisplayName={payee.contact.full_name}
            label={payee.payee_label ?? 'Payee'}
            showOnly
          />
        </span>
      </>
    );
  }

  return (
    <>
      {sortBy(paymentRequest.payees, ['id']).map((paymentPayee) => (
        <div key={paymentPayee.id}>{renderPayee(paymentPayee)}</div>
      ))}
    </>
  );
}

PaymentPayeesShowOnly.propTypes = {
  paymentRequest: PropTypes.object.isRequired,
};

export default PaymentPayeesShowOnly;
