import { useCallback } from 'react';
import { omit } from 'lodash';

import { isActionAllowed } from '../core/Permissions/PermissionUtils';
import { useCms } from '../hooks/useCms';

const useHasPermission = ({ action, verb }) => {
  const { userProfile, userOrganization } = useCms();
  return isActionAllowed({ action, verb, profile: userProfile, userOrganization });
};

const useHasMultiplePermissions = ({ actions, verb }) => {
  const { userProfile: profile, userOrganization } = useCms();

  const hasPermissions = {};
  if (!actions || !verb) {
    return hasPermissions;
  }

  for (let action of actions) {
    hasPermissions[action] = isActionAllowed({ action, verb, profile, userOrganization });
  }

  return hasPermissions;
};

/**
 *
 * @param {Object<string, string>} fieldsPermissionActions - permissions action required for each field
 * @param {string} verb - permission verb
 * @param {Array<string>=} forbiddenFields - fields that will be removed regardless of the user's permissions
 * @returns {function(*): PartialObject<object>}
 */
const useSanitizeByPermissions = ({ fieldsPermissionActions, verb, forbiddenFields = [] }) => {
  const permissionActions = new Set(Object.values(fieldsPermissionActions));
  const hasPermissionsDict = useHasMultiplePermissions({ actions: permissionActions, verb });
  return useCallback(
    (payload) => {
      const keysToOmit = Object.entries(fieldsPermissionActions)
        .filter(([, value]) => !hasPermissionsDict[value])
        .map(([key]) => key);

      return omit(payload, [...keysToOmit, ...forbiddenFields]);
    },
    [fieldsPermissionActions, forbiddenFields, hasPermissionsDict]
  );
};

export { useHasMultiplePermissions, useHasPermission, useSanitizeByPermissions };
