import React from 'react';
import PropTypes from 'prop-types';

import useCurrencyFormatter from '../../CurrencyFormatterContext';

const SumContainer = ({ sum, color, fontWeight }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  return (
    <span
      style={{
        color,
        fontWeight,
      }}
    >
      {currencyFormatter.format(sum)}
    </span>
  );
};

SumContainer.propTypes = {
  sum: PropTypes.number.isRequired,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
};

export default SumContainer;
