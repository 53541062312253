import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import useFetchAdjusters from '~/Adjuster/useFetchAdjusters';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../Utils';

import CardDialog from './CardDialog';
import CheckboxFormik from './CheckboxFormik';
import { Caption, LoadingSwitch } from './core';
import useOrganization from './OrganizationContext';
import { DatePickerTextFieldFormik, MultiSelectIdLabelTextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function OutOfOfficeDialog(props) {
  const { user, onClose, currentSharedUsersIds, onSubmit } = props;
  const { isTwilioEnabled } = useOrganization();

  return (
    <Formik
      initialValues={{
        should_forward_notification: false,
        forward_notification_user_ids: [],
        should_use_out_of_office_voice_mail: isTwilioEnabled,
        back_to_office_date: '',
      }}
      validationSchema={Yup.object().shape({
        should_forward_notification: Yup.boolean().required('Required'),
        forward_notification_user_ids: Yup.array().when('should_forward_notification', {
          is: true,
          then: Yup.array().min(1, 'Required'),
        }),
        should_use_out_of_office_voice_mail: Yup.boolean().required('Required'),
        back_to_office_date: Yup.date().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values) => {
        await onSubmit(values);
        onClose();
      }}
    >
      {() => {
        return (
          <CardDialog title="Out Of Office" isDialog onClose={onClose}>
            <OutOfOfficeInner onClose={onClose} currentSharedUsersIds={currentSharedUsersIds} user={user} />
          </CardDialog>
        );
      }}
    </Formik>
  );
}

OutOfOfficeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentSharedUsersIds: PropTypes.array,
  user: PropTypes.object.isRequired,
};

const OutOfOfficeInner = ({ user, onClose, currentSharedUsersIds }) => {
  const classes = useStyles();
  const { values, isSubmitting, handleSubmit } = useFormikContext();
  const { isLoadingPossibleAdjusters, isErrorPossibleAdjusters, possibleAdjustersWithDaSpecialist } = useFetchAdjusters(
    user.organization_id
  );
  const [possibleAdjustersIdLabel, setPossibleAdjustersIdLabel] = useState([]);
  const [isLoadingSharedUsers, setIsLoadingSharedUsers] = useState(false);

  const getSharedUserIds = useCallback(async () => {
    try {
      setIsLoadingSharedUsers(true);
      const response = await axios.get(`/api/v1/users/${user.id}/share_notifications`);
      setIsLoadingSharedUsers(false);

      return response.data;
    } catch (error) {
      await reportAxiosError(error);
    }
  }, [user]);

  useEffect(() => {
    const setPossibleAdjusters = async () => {
      if (isLoadingPossibleAdjusters) {
        return;
      }
      let sharedUserIds = currentSharedUsersIds;
      if (!sharedUserIds) {
        sharedUserIds = await getSharedUserIds();
      }

      let possibleAdjusters = possibleAdjustersWithDaSpecialist.filter((adjuster) => adjuster.id !== user.id);
      possibleAdjusters = possibleAdjusters.filter((adjuster) => !sharedUserIds.includes(adjuster.id));
      const options = possibleAdjusters.map((adjuster) => ({ id: adjuster.id, label: adjuster.username }));
      setPossibleAdjustersIdLabel(options);
    };
    setPossibleAdjusters();
  }, [getSharedUserIds, currentSharedUsersIds, possibleAdjustersWithDaSpecialist, isLoadingPossibleAdjusters, user.id]);

  return (
    <LoadingSwitch isLoading={isLoadingPossibleAdjusters || isLoadingSharedUsers} isError={isErrorPossibleAdjusters}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={6}>
          <CheckboxFormik id="should_forward_notification" label="Share notifications" />
          {values['should_forward_notification'] && (
            <div>
              <Caption variant={Caption.VARIANTS.LABEL}>
                Sharing with the selected users will end when you return to the office
              </Caption>
            </div>
          )}
        </Grid>
        <Grid item xs={6}>
          {values['should_forward_notification'] && (
            <MultiSelectIdLabelTextFieldFormik
              id="forward_notification_user_ids"
              select
              label="Adjuster"
              className={classes.textField}
              fullWidth
              options={possibleAdjustersIdLabel}
              renderValue={(selectedAdjusters) => selectedAdjusters.map((adjuster) => adjuster.label).join(', ')}
            />
          )}
        </Grid>
        {user.is_call_center_allowed && (
          <Grid item xs={6}>
            <CheckboxFormik id="should_use_out_of_office_voice_mail" label="Update my voicemail message" disabled />
          </Grid>
        )}
        <Grid item xs={6}>
          <DatePickerTextFieldFormik
            id="back_to_office_date"
            label="Back to office date"
            className={classes.textField}
            disabled={isSubmitting}
            disablePast
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.buttonsContainer}>
            <CancelButton onClick={onClose} />
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
              Set
            </Button>
          </div>
        </Grid>
      </Grid>
    </LoadingSwitch>
  );
};

OutOfOfficeInner.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentSharedUsersIds: PropTypes.array,
  user: PropTypes.object.isRequired,
};

export default OutOfOfficeDialog;
