import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import { FORMIK_COS_FIELD_IDS } from '~/components/Contacts/UpsertContact/Tabs/ContractOfService/constants';
import { getFullCOSPath } from '~/components/Contacts/UpsertContact/Tabs/ContractOfService/utils';
import Text from '~/components/core/TextComponents/Text';
import RadioButtonFormik from '~/components/RadioButtonFormik';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '~/components/TextFieldFormik';

const Discount = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const isPercentageDiscount = getIn(values, getFullCOSPath(FORMIK_COS_FIELD_IDS.PERCENTAGE_DISCOUNT));

  useEffect(() => {
    if (isPercentageDiscount) {
      setFieldValue(getFullCOSPath(FORMIK_COS_FIELD_IDS.MEDICAL_FIXED_DISCOUNT), 0);
    } else {
      setFieldValue(getFullCOSPath(FORMIK_COS_FIELD_IDS.MEDICAL_PERCENTAGE_DISCOUNT), 0);
    }
  }, [isPercentageDiscount, setFieldValue]);

  return (
    <div className="mb-32 grid grid-cols-2 gap-20">
      <Text variant="small" weight="regular" className="col-span-full">
        Contractual Discount
      </Text>
      <RadioButtonFormik
        id={getFullCOSPath(FORMIK_COS_FIELD_IDS.PERCENTAGE_DISCOUNT)}
        label="Percentage (%)"
        optionValue={true}
        disabled={disabled}
      />
      <RadioButtonFormik
        id={getFullCOSPath(FORMIK_COS_FIELD_IDS.PERCENTAGE_DISCOUNT)}
        label="Fixed Amount (USD)"
        optionValue={false}
        disabled={disabled}
      />
      {isPercentageDiscount && (
        <TextFieldFormik
          id={getFullCOSPath(FORMIK_COS_FIELD_IDS.MEDICAL_PERCENTAGE_DISCOUNT)}
          label="Contractual Discount - Percentage (%)"
          className="col-span-full"
          fullWidth
          disabled={disabled}
        />
      )}
      {!isPercentageDiscount && (
        <MonetaryValueTextFieldFormik
          id={getFullCOSPath(FORMIK_COS_FIELD_IDS.MEDICAL_FIXED_DISCOUNT)}
          label="Contractual Discount - fixed amount (USD)"
          allowNegative={false}
          className="col-span-full"
          fullWidth
          disabled={disabled}
        />
      )}
    </div>
  );
};

Discount.propTypes = {
  disabled: PropTypes.bool,
};

export default Discount;
