export type ChannelType = 'phone_call' | 'sms' | 'email' | 'physical_mail' | 'video_call' | 'vendor';
export type Direction = 'Incoming' | 'Outgoing';
export type RecipientType = 'to' | 'cc' | 'bcc';

export interface CommunicationIconSettings {
  bgColor: string;
  textColor: string;
  borderColor: string;
  iconClassName: string;
}

export const channelTypeToIconSettings: Record<ChannelType, Record<Direction, CommunicationIconSettings>> = {
  phone_call: {
    Incoming: {
      borderColor: 'border-green-700',
      bgColor: 'bg-green-100',
      textColor: 'text-green-700',
      iconClassName: 'fs-icon-PhoneIncomingIcon',
    },
    Outgoing: {
      borderColor: 'border-green-700',
      bgColor: 'bg-green-100',
      textColor: 'text-green-700',
      iconClassName: 'fs-icon-PhoneOutgoingIcon',
    },
  },
  physical_mail: {
    Incoming: {
      borderColor: 'border-blue-400',
      bgColor: 'bg-blue-100',
      textColor: 'text-blue-600',
      iconClassName: 'fs-icon-MailboxIncomingIcon',
    },
    Outgoing: {
      borderColor: 'border-blue-400',
      bgColor: 'bg-blue-100',
      textColor: 'text-blue-600',
      iconClassName: 'fs-icon-MailboxOutgoingIcon',
    },
  },
  video_call: {
    Incoming: {
      borderColor: 'border-teal-400',
      bgColor: 'bg-teal-200',
      textColor: 'text-teal-700',
      iconClassName: 'fs-icon-VideoCameraIcon',
    },
    Outgoing: {
      borderColor: 'border-teal-400',
      bgColor: 'bg-teal-200',
      textColor: 'text-teal-700',
      iconClassName: 'fs-icon-VideoCameraIcon',
    },
  },
  email: {
    Incoming: {
      borderColor: 'border-pink-400',
      bgColor: 'bg-pink-200',
      textColor: 'text-pink-800',
      iconClassName: 'fs-icon-EmailIncomingIcon',
    },
    Outgoing: {
      borderColor: 'border-pink-400',
      bgColor: 'bg-pink-200',
      textColor: 'text-pink-800',
      iconClassName: 'fs-icon-EmailOutgoingIcon',
    },
  },
  sms: {
    Incoming: {
      borderColor: 'border-orange-700',
      bgColor: 'bg-yellow-100',
      textColor: 'text-orange-700',
      iconClassName: 'fs-icon-MessageIncomingIcon',
    },
    Outgoing: {
      borderColor: 'border-orange-700',
      bgColor: 'bg-yellow-100',
      textColor: 'text-orange-700',
      iconClassName: 'fs-icon-MessageOutgoingIcon',
    },
  },
  vendor: {
    Incoming: {
      borderColor: 'border-orange-700',
      bgColor: 'bg-orange-100',
      textColor: 'text-orange-700',
      iconClassName: 'fs-icon-VendorIcon',
    },
    Outgoing: {
      borderColor: 'border-orange-700',
      bgColor: 'bg-orange-100',
      textColor: 'text-orange-700',
      iconClassName: 'fs-icon-VendorIcon',
    },
  },
};

export const channelTypeToErrorIconSettings: Record<ChannelType, CommunicationIconSettings> = {
  email: {
    borderColor: 'border-red-700',
    bgColor: 'bg-pink-100',
    textColor: 'text-red-700',
    iconClassName: 'fs-icon-EmailFailedIcon',
  },
  sms: {
    borderColor: 'border-red-700',
    bgColor: 'bg-pink-100',
    textColor: 'text-red-700',
    iconClassName: 'fs-icon-MessageFailedIcon',
  },
  phone_call: {
    borderColor: '',
    bgColor: '',
    textColor: '',
    iconClassName: '',
  },
  physical_mail: {
    borderColor: '',
    bgColor: '',
    textColor: '',
    iconClassName: '',
  },
  video_call: {
    borderColor: '',
    bgColor: '',
    textColor: '',
    iconClassName: '',
  },
  vendor: {
    borderColor: '',
    bgColor: '',
    textColor: '',
    iconClassName: '',
  },
};
