import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import RadioButtonFormik from '../../RadioButtonFormik';

import { ErrorHelperTextFormik } from './ErrorHelperTextFormik';

const YesNoQuestionFormik = ({ questionText, id, disabled, na_option }) => {
  return (
    <Grid container>
      <Grid item xs={7}>
        <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
          {questionText}
          <ErrorHelperTextFormik style={{ marginTop: 0, marginLeft: '8px' }} id={id} />
        </span>
      </Grid>
      <Grid item xs={5}>
        <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
          <RadioButtonFormik id={id} label="No" optionValue={false} disabled={disabled} size="small" />
          <RadioButtonFormik id={id} label="Yes" optionValue={true} disabled={disabled} size="small" />
          {na_option && <RadioButtonFormik id={id} label="N/A" optionValue="N/A" disabled={disabled} size="small" />}
        </div>
      </Grid>
    </Grid>
  );
};

YesNoQuestionFormik.propTypes = {
  questionText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  na_option: PropTypes.bool,
};

export default YesNoQuestionFormik;
