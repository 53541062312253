import React from 'react';
import PropTypes from 'prop-types';

import { useIncidentConfiguration } from '../../../../../hooks/useIncidentConfiguration';
import {
  getInitialSectionConfig,
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../../IncidentConfiguration/CustomFieldsContext';

const ACCIDENT_FOLLOW_UP_PATH = 'incident_details.auto_incident_additional.accident_follow_up';
const ACCIDENT_FOLLOW_UP_CONFIG_PATH =
  'incident_details.pre_defined_fields.auto_incident_additional.accident_follow_up';

const getInitialAccidentFollowUpSectionConfig = (incidentConfiguration, isFieldSupportedBySubtype) =>
  getInitialSectionConfig(incidentConfiguration, ACCIDENT_FOLLOW_UP_CONFIG_PATH, {}, isFieldSupportedBySubtype);
const getInitialAccidentFollowUpPredefinedFields = (incidentConfiguration, additionalFieldOmitFn) =>
  preparePreDefinedFields(getInitialAccidentFollowUpSectionConfig(incidentConfiguration), additionalFieldOmitFn);
const getInitialAccidentFollowUpInitialValues = (incidentConfiguration, additionalFieldOmitFn) =>
  getPredefinedFieldsEmptyFormikInitialValues(
    getInitialAccidentFollowUpPredefinedFields(incidentConfiguration, additionalFieldOmitFn)
  );
const getInitialAccidentFollowUpInitialValidationSchema = (incidentConfiguration, additionalFieldOmitFn) =>
  getAdditionalDataValidations(
    getInitialAccidentFollowUpPredefinedFields(incidentConfiguration, additionalFieldOmitFn)
  );

const InitialAccidentFollowUpFragment = ({ additionalFieldOmitFn }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInitialAccidentFollowUpPredefinedFields(incidentConfiguration, additionalFieldOmitFn);

  return (
    <>
      <PreDefinedField id="journey_purpose" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="weather_conditions" fields={preDefinedFields} inline />
      <PreDefinedField id="direction_ph_vehicle_was_going" fields={preDefinedFields} inline />
      <PreDefinedField id="first_party_way_sign_or_a_headlight" fields={preDefinedFields} inline />
      <PreDefinedField id="street_condition" fields={preDefinedFields} inline />
      <PreDefinedField id="was_ambulance_called" fields={preDefinedFields} inline />
      <PreDefinedField id="was_first_party_driver_using_the_phone" fields={preDefinedFields} inline />
      <PreDefinedField id="were_first_party_vehicle_lights_on" fields={preDefinedFields} inline />
      <PreDefinedField id="was_anyone_under_the_influence_of_drugs_alcohol" fields={preDefinedFields} inline />
    </>
  );
};

InitialAccidentFollowUpFragment.propTypes = {
  additionalFieldOmitFn: PropTypes.func,
};

export {
  ACCIDENT_FOLLOW_UP_PATH,
  getInitialAccidentFollowUpInitialValidationSchema,
  getInitialAccidentFollowUpInitialValues,
  getInitialAccidentFollowUpSectionConfig,
  InitialAccidentFollowUpFragment,
};
