import React, { useEffect } from 'react';

import CardDialog from '../../CardDialog';
import { useCms } from '../../hooks/useCms';

import QaResults from './QaResults';
import QaSavedQueries from './QaSavedQueries';

import { useStyles } from '../../../assets/styles';

const ClaimQaDashboard = () => {
  const classes = useStyles();

  const { setPageTitle } = useCms();

  useEffect(() => setPageTitle('Performance QA Center', 'Performance QA Center - Five Sigma CMS'), [setPageTitle]);

  return (
    <div className={classes.cardDivRow}>
      <CardDialog passThrough title="QA Review">
        <QaSavedQueries />
        <QaResults />
      </CardDialog>
    </div>
  );
};

export default ClaimQaDashboard;
