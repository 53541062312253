import { isEmpty } from 'lodash';

import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isFeatureEnabled } from '../../Utils';
import { useClaim } from '../ClaimContainer';
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';
import { usePolicy } from '../PolicyContainer';

import { useLob } from './useLob';

const useIncidentConfiguration = () => {
  const { userOrganization } = useCms();
  const { policy } = usePolicy();
  const { claim } = useClaim();
  const { lob } = useLob();
  const { subOrganizationEnabled } = useOrganization();
  const isConfigurableFnolEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);
  const lobIncidentConfigurationsDict = subOrganizationEnabled
    ? policy.sub_organization?.lob_incident_configurations_dict
    : userOrganization.lob_incident_configurations_dict;

  if (isConfigurableFnolEnabled && !isEmpty(claim?.incident_configuration)) {
    return { incidentConfiguration: claim.incident_configuration };
  }

  if (!isConfigurableFnolEnabled || isEmpty(lobIncidentConfigurationsDict) || !lob) {
    return { incidentConfiguration: {} };
  }

  const incidentConfiguration = lobIncidentConfigurationsDict[lob];

  return {
    incidentConfiguration,
  };
};

export { useIncidentConfiguration };
