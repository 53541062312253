import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect, getIn } from 'formik';

import LocationTextField from './Location';

import styles from '../assets/styles';

const LocationTextFieldFormik = connect(
  withStyles(styles)((props) => {
    const {
      classes,
      showOnly,
      onEdit,
      disabled,
      formik,
      id,
      label,
      locationOptions,
      additionalFieldsComponent,
      countriesConfigurationKey = 'loss_location',
    } = props;

    const { values, touched, errors, setFieldTouched, setFieldValue } = formik;

    return (
      <LocationTextField
        classes={classes}
        label={label}
        location={getIn(values, id)}
        onChangeLocation={(lossLocation) => {
          setFieldTouched(id, true);
          setFieldValue(id, lossLocation);
        }}
        showOnly={showOnly}
        onEdit={onEdit}
        disabled={disabled}
        error={getIn(errors, id) && getIn(touched, id)}
        helperText={getIn(errors, id) && getIn(touched, id) && getIn(errors, id)}
        locationOptions={locationOptions}
        additionalFieldsComponent={additionalFieldsComponent}
        countriesConfigurationKey={countriesConfigurationKey}
      />
    );
  })
);

LocationTextFieldFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  disabled: PropTypes.bool,
  locationOptions: PropTypes.array,
  additionalFieldsComponent: PropTypes.node,
  countriesConfigurationKey: PropTypes.string,
};

export default LocationTextFieldFormik;
