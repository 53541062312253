import React from 'react';

import useDataFetcher from '../../useDataFetcher';
import type { LegalActionApiResponse } from '../types';

import { useLegalActionsBackendRoutes } from './useLegalActionsBackendRoutes';

const LegalActionContext = React.createContext<{
  legalAction: LegalActionApiResponse | never;
  isLoading: boolean;
  isError: boolean;
  reloadLegalAction: () => Promise<void> | never;
}>({
  legalAction: {} as never,
  isLoading: true,
  isError: false,
  reloadLegalAction: undefined as never,
});

export const LegalActionContextProvider: React.FC<{ legalActionId: number }> = ({ children, legalActionId }) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalActionId);

  const {
    data: legalAction,
    isLoading,
    isError,
    reloadData: reloadLegalAction,
  } = useDataFetcher(backendRoutes.legalAction.base, {}, Boolean(legalActionId));

  return (
    <LegalActionContext.Provider
      value={{
        legalAction,
        isLoading,
        isError,
        reloadLegalAction,
      }}
    >
      {children}
    </LegalActionContext.Provider>
  );
};

export const useLegalAction = (): {
  legalAction: LegalActionApiResponse;
  isLoading: boolean;
  isError: boolean;
  reloadLegalAction: () => Promise<void>;
} => {
  return React.useContext(LegalActionContext);
};
