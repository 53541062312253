import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const CreditCardIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.75 15.75H18.75M11.25 15.75H12.75M2.25 9.08438H21.75M3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6V18C21.75 18.4142 21.4142 18.75 21 18.75H3C2.58579 18.75 2.25 18.4142 2.25 18V6C2.25 5.58579 2.58579 5.25 3 5.25Z"
      stroke={props.iconColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

CreditCardIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default CreditCardIcon;
