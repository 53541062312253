import React from 'react';

import { useClaim } from '../../../../ClaimContainer';
import { EditDocumentDialog } from '../../../../Documents/DocumentCard';
import type { DialogPropsWithLog } from '../../../Dialogs/DialogTypes';
import type { DocumentApiResponse } from '../../../types';

export const EditLegalActionDocument: React.FC<DialogPropsWithLog<DocumentApiResponse>> = ({
  onSubmitSuccess,
  onClose,
  log,
}) => {
  const { claim } = useClaim();

  const handleAfterUpdate = async () => {
    await onSubmitSuccess();
    onClose();
  };

  return (
    <EditDocumentDialog document={log} claim={claim} onCancel={onClose} open onUpdateDocument={handleAfterUpdate} />
  );
};
