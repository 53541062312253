export enum PartyType {
  PLAINTIFF = 'PLAINTIFF',
  DEFENDANT = 'DEFENDANT',
}

export interface LegalActionPartyApiResponse {
  id: number;
  type: PartyType;
  contact_id: number;
  contact_name: string;
  counsel_contact_id: number;
  counsel_name: string;
  file_number: string;
  date_of_representation: Date;
  end_date_of_representation: Date;
  is_carrier_party: boolean;
}

export interface LegalActionDateOfServiceApiResponse {
  id: number;
  date_of_service: Date;
  contact_id: number;
  contact_name: string;
  service_type: string;
  other_text?: string;
}

export interface DisputeResolutionProfessionalApiResponse {
  id: number;
  type: string;
  contact_id: number;
  contact_name: string;
  start_date: Date;
  end_date: Date;
}

export interface LegalActionApiResponse {
  id: number;
  is_claim_level: boolean;
  exposures: { id: number; coverage_type: string }[];
  display_name: string;
  priority: string;
  lawsuit_amount: number;
  complaint_allegations_summary: string;
  is_open: boolean;
  legal_action_parties: LegalActionPartyApiResponse[];
  lawsuit_type: string;
  nature_of_dispute: string;
  complaint_number: string;
  dates_of_service: LegalActionDateOfServiceApiResponse[];
  jurisdiction_state: string;
  venue: string;
  court: string;
  date_filled_in_court: Date;
  dispute_resolution_professionals: DisputeResolutionProfessionalApiResponse[];
  early_case_resolution_explored: string;
  alternative_dispute_resolution_explored: string;
  alternative_dispute_resolution_text?: string;
  nature_of_dispute_text: string;
  method_of_conclusion: string;
  method_of_conclusion_text: string;
  final_settlement_amount: number;
  close_date: Date;
  closing_user: string;
  reopen_reason: string;
  reopen_text: string;
}

export enum LegalActionLogType {
  log = 'log',
  pros_and_cons = 'pros_and_cons',
  negotiation = 'negotiation_log',
}

export interface LegalActionLogBase {
  id: number;
  created_at: Date;
  type: LegalActionLogType;
}

export interface DocumentApiResponse {
  id: number;
  document_name: string;
  document_date: Date;
  document_name_with_ext: string;
  claim_id: number;
  uploaded_by: string;
  document_type: string;
  document_date_received: Date;
  summary: string;
  datetime_updated: Date;
}

export interface LegalActionLogApiResponse extends LegalActionLogBase {
  date: Date;
  log_type: string;
  log_type_text: string;
  due_date: Date;
  counsel_update: string;
  next_action: string;
  status_of_litigation: string;
  type: LegalActionLogType.log;
  document: DocumentApiResponse;
  status_of_litigation_text: string;
}

export interface ProsAndConsApiResponse extends LegalActionLogBase {
  pro_con_type: string;
  rationale: string;
}

export interface NegotiationLogApiResponse extends LegalActionLogBase {
  date: Date;
  adverse_party_demand: number;
  offer: number;
  notes: string;
  adverse_party_contact_id: number;
  adverse_party_contact_name: string;
}

export type CurrencyFormatterType = { currencyFormatter: { format: (num: number) => string } };
