import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import useInterval from '~/components/hooks/useInterval';
import { reportAxiosError } from '~/Utils';

function CallInProgressParticipantsStatusUpdate({ communication, setParticipantsDetails }) {
  const updatesIntervalMilliSec = 1 * 1000;
  useInterval(async () => {
    try {
      const result = await axios.get(`/api/v1/communications/${communication.id}`);
      setParticipantsDetails(result.data.additional_conference_participants);
    } catch (error) {
      reportAxiosError(error);
    }
  }, updatesIntervalMilliSec);

  return <></>;
}

CallInProgressParticipantsStatusUpdate.propTypes = {
  communication: PropTypes.object.isRequired,
  setParticipantsDetails: PropTypes.func.isRequired,
};

export default CallInProgressParticipantsStatusUpdate;
