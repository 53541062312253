import React, { useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import { Grid, Slider } from '@mui/material';

import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import FileDropZone from '../../FileDropZone';

import { useStyles } from '../../../assets/styles';
import styles from './OrganizationLogo.module.scss';

export const OrganizationLogoUploadDialog = ({ onSave, onCancel }) => {
  const CANVAS_SIZE = 256;

  const classes = useStyles();
  const [image, setImage] = React.useState(undefined);
  const [scale, setScale] = React.useState(1);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const editor = useRef();

  const handleChangeScale = (_, newValue) => setScale(newValue);

  const validateImage = (file) => {
    let message = undefined;
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      message = 'Only JPG and PNG files are allowed';
    } else if (file.size > 100000) {
      message = 'File size must be less than 100KB';
    }
    setErrorMessage(message); // called even if undefined to reset previous errors
    return !message;
  };

  const handleFileSelect = (file) => {
    if (validateImage(file)) {
      setImage(file);
    }
  };

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      const base64String = editor.current.getImage().toDataURL().split('base64,')[1];
      await onSave(base64String, image.type);
    } catch (e) {
      await reportAxiosError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <CardDialog
      isDialog
      open
      title="Upload An Image"
      onClose={handleCancel}
      containerClassName={styles.logoUploadDialog}
      maxWidth="lg"
    >
      {!image && (
        <div className={styles.dropzone}>
          <FileDropZone
            onFileSelect={handleFileSelect}
            onCancelFileSelect={() => setImage(undefined)}
            file={image}
            errorMessage={errorMessage}
            error={!!errorMessage}
            uploadPercent={0}
            dropZoneOptions={{
              multiple: false,
              accept: 'image/*',
            }}
          />
        </div>
      )}

      {image && (
        <Grid container direction="column">
          <Grid container alignItems="center">
            <AvatarEditor
              ref={editor}
              image={image}
              width={CANVAS_SIZE}
              height={CANVAS_SIZE}
              border={2}
              borderRadius={500}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={scale}
              rotate={0}
            />
          </Grid>
          <Grid item className={styles.sliderContainer}>
            Scale
            <Slider aria-label="scale" value={scale} onChange={handleChangeScale} min={0.8} max={1.5} step={0.01} />
          </Grid>
          <div className={classes.buttonsContainer}>
            <CancelButton onClick={handleCancel} disabled={isSubmitting} />
            <Button variant="contained" color="primary" disabled={!image || isSubmitting} onClick={handleSave}>
              Save
            </Button>
          </div>
        </Grid>
      )}
    </CardDialog>
  );
};

OrganizationLogoUploadDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
