import { findInvolvedPerson } from './Utils/ClaimUtils';
import { reportErrorInProductionOrThrow } from './Utils';

export function getClaimantLocationInAutoIncident(claim, exposure) {
  const fullInvolvedPerson = findInvolvedPerson(claim, exposure.involved_person);

  if (fullInvolvedPerson.type === 'involved_driver') {
    return 'Driver';
  } else if (fullInvolvedPerson.type === 'involved_passenger') {
    return 'Passenger';
  } else if (
    fullInvolvedPerson.type === 'involved_non_motorist' &&
    fullInvolvedPerson.non_motorist_type === 'pedestrian'
  ) {
    return 'Pedestrian';
  } else if (
    fullInvolvedPerson.type === 'involved_non_motorist' &&
    fullInvolvedPerson.non_motorist_type === 'bicyclist'
  ) {
    return 'Bicyclist';
  } else if (fullInvolvedPerson.type === 'involved_person') {
    return 'Injured Person';
  } else {
    reportErrorInProductionOrThrow(`Unknown involved person type ${fullInvolvedPerson.type}`);
  }
}
