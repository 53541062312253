const DENY_MEDICAL_BILL_REASONS_DICT = {
  employer_not_covered: {
    desc: 'We do not administer Workers’ Compensation claims for this employer',
  },
  claim_name_not_found: {
    desc: 'We have no claim in our records under this name and/or social security number',
  },
  claim_date_not_found: {
    desc: 'We have no claim in our records under this date of injury',
  },
  denied: {
    desc: 'This claim has been denied',
  },
  documentation_needed: {
    desc: 'Please provide a HCFA 1500 or UB 92 for the attached statement and return to us',
  },
  invoice_needed: {
    desc: 'Please provide dictation and/or an invoice for supplies',
  },
  already_paid: {
    desc: 'Our records indicate that this bill was paid',
    format: 'Our records indicate that this bill was paid on {paid_on} by check number {check_number}',
  },
  pre_authorization_missing: {
    desc: 'Pre-authorization was not given for these treatments from this office',
  },
  charges_out_of_date_window: {
    desc: 'It cannot be determined if the insurer was billed for these charges within 90 days from the date of service',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default DENY_MEDICAL_BILL_REASONS_DICT;
