import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import { cleanEmptyValues, nullValuesToEmptyStrings } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';

const AdditionalInformationDialog = ({
  initialValues,
  onCancel,
  onSubmit,
  open,
  title,
  showOnly,
  additionalFieldOmitFn,
  AdditionalInformationFragment,
  AdditionalInformationFragmentInitialValues,
  AdditionalInformationFragmentValidationSchema,
}) => {
  if (!open) {
    return <></>;
  }

  return (
    <>
      <Formik
        initialValues={
          initialValues ? nullValuesToEmptyStrings(initialValues) : AdditionalInformationFragmentInitialValues
        }
        validationSchema={Yup.object().shape({
          ...AdditionalInformationFragmentValidationSchema,
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await onSubmit(cleanEmptyValues(values));
            setSubmitting(false);
          } catch (error) {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <CardDialog
            isDialog={true}
            title={title}
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
            footerActions={
              !showOnly && (
                <DialogFooterActions
                  disabled={isSubmitting}
                  onClickPrimary={handleSubmit}
                  onClickSecondary={onCancel}
                />
              )
            }
          >
            <Grid container spacing={2}>
              {AdditionalInformationFragment && (
                <AdditionalInformationFragment additionalFieldOmitFn={additionalFieldOmitFn} />
              )}
            </Grid>
          </CardDialog>
        )}
      </Formik>
    </>
  );
};

AdditionalInformationDialog.propTypes = {
  dialogAction: PropTypes.node,
  disableEditIdentity: PropTypes.bool,
  initialValues: PropTypes.object,
  isInsured: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
  AdditionalInformationFragment: PropTypes.node,
  AdditionalInformationFragmentInitialValues: PropTypes.object,
  AdditionalInformationFragmentValidationSchema: PropTypes.object,
  additionalFieldOmitFn: PropTypes.func,
};

export default AdditionalInformationDialog;
