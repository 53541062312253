import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';

import { RemoveIcon } from '../icons';
import OverflowTextWithToolTip from '../OverflowTextWithToolTip';

import { useStyles } from '../../assets/styles';

function OptionChips({ onChange, values, disabled = false, disabledChips }) {
  const classes = useStyles();

  return (
    <div className={classes.chipsContainer}>
      {values.map(([value, displayValue]) => (
        <Chip
          disabled={disabledChips?.includes(value) || disabled}
          key={['string', 'number'].includes(typeof value) ? value : value.key}
          className={classes.chip}
          style={{ width: 'max-content', height: '28px' }}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OverflowTextWithToolTip maxWidth="200px">{displayValue}</OverflowTextWithToolTip>
              {!(disabledChips?.includes(value) || disabled) ? (
                <IconButton style={{ padding: 4, fontSize: 5 }} title="Clear" onClick={() => onChange(value)}>
                  <RemoveIcon fontSize="small" />
                </IconButton>
              ) : null}
            </div>
          }
        />
      ))}
    </div>
  );
}

OptionChips.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.any.isRequired,
  disabledChips: PropTypes.array,
};

export default OptionChips;
