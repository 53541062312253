import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const NoteIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <path
      d="M8.99922 9.70312h5.99998c.4 0 .8-.3.8-.8s-.4-.7-.8-.7H8.99922c-.4 0-.8.40001-.8.80001s.4.69999.8.69999ZM8.99922 12.7016h5.99998c.4 0 .8-.3.8-.8s-.3-.8-.8-.8H8.99922c-.4 0-.8.3-.8.8s.4.8.8.8ZM11.9992 14.2969H8.99922c-.4 0-.8.3-.8.8s.3.8.8.8h2.99998c.4 0 .8-.3.8-.8s-.4-.8-.8-.8Z"
      fill={iconColor}
    />
    <path
      d="M20.6 3.4c-.3-.2-.7-.4-1.1-.4h-15c-.4 0-.8.2-1.1.4-.3.2-.4.7-.4 1.1v15c0 .4.2.8.4 1.1.2.3.7.4 1.1.4h10.2c.2 0 .4 0 .6-.1.1 0 .2-.1.3-.2.1 0 .1-.1.2-.1l4.8-4.8c.1-.1.1-.1.1-.2.1-.1.1-.2.2-.3.1-.2.1-.4.1-.6V4.5c0-.4-.2-.8-.4-1.1ZM4.5 4.5h15v9.8H15c-.4 0-.8.3-.8.8v4.5H4.5V4.5Zm13.9 11.3-2.7 2.7v-2.7h2.7Z"
      fill={iconColor}
    />
  </SvgIcon>
);

NoteIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default NoteIcon;
