import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '~/components/core/Atomic/MenuItem';

import { COUNTRY_TO_STATE_MAP } from '../Types';

import { TextFieldFormik } from './TextFieldFormik';

function UsStatesSelectTextFieldFormik(props) {
  const { id, label, ...rest } = props;

  return (
    <TextFieldFormik id={id} select label={label} {...rest}>
      {Object.keys(COUNTRY_TO_STATE_MAP['US'])
        .sort((s1, s2) => COUNTRY_TO_STATE_MAP['US'][s1].localeCompare(COUNTRY_TO_STATE_MAP['US'][s2]))
        .map((state) => (
          <MenuItem key={state} value={state}>
            {COUNTRY_TO_STATE_MAP['US'][state]}
          </MenuItem>
        ))}
    </TextFieldFormik>
  );
}

UsStatesSelectTextFieldFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default UsStatesSelectTextFieldFormik;
