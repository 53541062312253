import React from 'react';
import { Divider } from '@material-ui/core';

import VideoCommunicationPopover from '~/components/Contacts/ContactCommunications/Video/VideoCommunicationPopover';
import type { ContactSubDetailsPropsType } from '~/components/Contacts/ContactMiniCard/types';
import Text from '~/components/core/TextComponents/Text';
import useOrganization from '~/components/OrganizationContext';

const DyteVideo: React.FC<ContactSubDetailsPropsType> = ({ contact, onUpdate, actionsEnabled }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isDyteVideoEnabled } = useOrganization();

  return (
    <>
      {isDyteVideoEnabled && actionsEnabled ? (
        <>
          <Divider className="my-12" />
          <div className="flex justify-between">
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
              Video Calls
            </Text>
            <VideoCommunicationPopover contact={contact} onUpdate={onUpdate} />
          </div>
        </>
      ) : null}
    </>
  );
};

export default DyteVideo;
