const CLAIM_TYPES_CONFIGURATION = {
  auto_claim: {
    desc: 'Auto',
    is_ga: true,
  },
  travel_claim: {
    desc: 'Travel',
    is_ga: false,
  },
  wc_claim: {
    desc: 'WC',
    is_ga: false,
  },
  gl_claim: {
    desc: 'GL',
    is_ga: false,
  },
  bike_claim: {
    desc: 'Bike',
    is_ga: false,
  },
  custom_claim: {
    desc: 'Custom',
    is_ga: false,
  },
} as const;

export default CLAIM_TYPES_CONFIGURATION;
