import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useTableauOld from '~/components/hooks/useTableauOld';

const TableauView = ({ workbook, view, onLoad, onFilterApplied, options }) => {
  const { isLoading, tableauRef, exportTableauView } = useTableauOld({ workbook, view, onFilterApplied, options });

  useEffect(() => {
    if (!isLoading) {
      onLoad({ exportTableauView });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return <div ref={tableauRef} />;
};

TableauView.propTypes = {
  workbook: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  onFilterApplied: PropTypes.func.isRequired,
  options: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    hideTabs: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    onFirstInteractive: PropTypes.func,
  }),
};

TableauView.defaultProps = {
  options: null,
};

export default TableauView;
