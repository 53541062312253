import axios from 'axios';

import { reportAxiosError } from '../../../../../Utils';
import useOrganization from '../../../../OrganizationContext';
import useDataFetcher from '../../../../useDataFetcher';
import { useSysconfig } from '../../../SystemConfigurationScreen';

const ROUTES = {
  getConfiguration: (organizationId, isSysConfig) =>
    `/api/v1/claim_search/${isSysConfig ? 'configuration' : 'configuration_claim'}/${organizationId}`,
  manualReportTriggered: (organizationId, claimIdDisplay) =>
    `/api/v1/claim_search/${organizationId}/report/${claimIdDisplay}`,
  getClaimSearchScore: (organizationId) => (claimId) => `/api/v1/claim_search/${organizationId}/score/${claimId}`,
};

export const useClaimSearch = () => {
  const { organization } = useSysconfig();
  const isSysConfig = !!organization;
  const { organizationId, isClaimSearchEnabled, subOrganizationEnabled } = useOrganization();

  const { isLoading, isError, data, reloadData } = useDataFetcher(ROUTES.getConfiguration(organizationId, isSysConfig));

  const reportToClaimSearch = async (claimIdDisplay, subOrganizationId) => {
    try {
      const res = await axios.post(ROUTES.manualReportTriggered(organizationId, claimIdDisplay), {
        sub_organization_id: subOrganizationId,
      });
      return res.data;
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const validateIsReporting = (organizationId, subOrganizationId) => {
    if (subOrganizationEnabled) {
      return data?.configurations.find(
        (claimSearchConfiguration) => claimSearchConfiguration.sub_organization_id === subOrganizationId
      )?.is_reporting;
    }
    return data?.configurations.find(
      (claimSearchConfiguration) => claimSearchConfiguration.organization_id === organizationId
    )?.is_reporting;
  };

  return {
    isConfigurationExists: !!data?.id,
    isEnabled: isClaimSearchEnabled,
    isLoading,
    isError,
    claimSearchOrgConfig: data,
    reloadData,
    reportToClaimSearch,
    validateIsReporting,
    getClaimSearchScoreRoute: ROUTES.getClaimSearchScore(organizationId),
  };
};
