import React from 'react';

import ShowAllCollapsible from '~/components/core/Molecules/Collapsible/ShowAllCollapsible';
import { MainCard } from '~/components/core/NewDesignSystem/Cards/MainCard/MainCard';
import { PropertyDetails } from '~/components/core/NewDesignSystem/PropertyDetails/PropertyDetails';
import { PropertyDetailsRow } from '~/components/core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';

import { ApplyToPropertyDetails } from '../../components/ApplyToPropertyDetails';
import { CurrencyPropertyDetails } from '../../components/CurrencyPropertyDetails';
import { LegalActionEditButton } from '../../components/LegalActionEditButton';
import { PriorityPropertyDetails } from '../../components/PriorityPropertyDetails';
import { EditLegalActionSummaryDialog } from '../../Dialogs/EditLegalActionSummary/EditLegalActionSummaryDialog';
import { LEGAL_ACTION_FIELDS } from '../../FormFragments/FormFieldsDefinitions';
import { useLegalAction } from '../../hooks/useLegalAction';

export const SummaryCard: React.FC = () => {
  const { legalAction, reloadLegalAction } = useLegalAction();
  const [isEditSummaryDialogOpen, setIsEditSummaryDialogOpen] = React.useState(false);

  return (
    <>
      <MainCard
        type="elevated"
        title="Legal Action Summary"
        action={<LegalActionEditButton onClick={() => setIsEditSummaryDialogOpen(true)} />}
      >
        <ApplyToPropertyDetails legalAction={legalAction} />
        <PropertyDetails title={LEGAL_ACTION_FIELDS.displayName.label} text={legalAction.display_name} />
        <div className="flex w-full items-center justify-between">
          <PropertyDetailsRow>
            <PriorityPropertyDetails priority={legalAction.priority} />
            <CurrencyPropertyDetails
              title={LEGAL_ACTION_FIELDS.lawsuitAmount.label}
              value={legalAction.lawsuit_amount}
            />
          </PropertyDetailsRow>
        </div>
        <PropertyDetails
          title={LEGAL_ACTION_FIELDS.complaintAllegationsSummary.label}
          text=""
          textComponent={
            legalAction.complaint_allegations_summary.trim() ? (
              <ShowAllCollapsible visibleLines={1}>{legalAction.complaint_allegations_summary}</ShowAllCollapsible>
            ) : null
          }
        />
      </MainCard>
      {isEditSummaryDialogOpen ? (
        <EditLegalActionSummaryDialog
          legalAction={legalAction}
          onClose={() => setIsEditSummaryDialogOpen(false)}
          onSubmitSuccess={reloadLegalAction}
        />
      ) : null}
    </>
  );
};
