import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import InnerCard from '~/components/core/Cards/InnerCard';
import InvolvedInsurerFields from '~/components/Fnol/NewFnolUI/InvolvedParties/InvolvedInsurerFields';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';

import { getCoveredObjectDisplayName } from '../../../../../Utils';
import ConfiguredFields, { PreDefinedField } from '../../../../IncidentConfiguration/ConfiguredFields';
import { usePolicy } from '../../../../PolicyContainer';
import TextFieldFormik from '../../../../TextFieldFormik';

import { ENTITIES_TYPES } from './InvolvedGenericFragment';

import styles from './index.module.scss';

const GeneralDetailsFragment = ({ involvedParty, preDefinedFields }) => {
  const { policy } = usePolicy();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { values, handleChange, setFieldValue, initialValues } = useFormikContext();
  const involvedPartiesConfig = incidentConfiguration?.involved_parties || {};
  const involvedPartyConfig = involvedPartiesConfig[involvedParty];
  const partyType = involvedParty && involvedPartyConfig?.type;
  const isFirstParty = involvedParty && involvedPartyConfig?.is_first_party;
  const coveredObjects = policy?.covered_objects || [];
  const coveredDesc =
    incidentConfiguration?.policy?.covered_objects?.length > 0
      ? incidentConfiguration.policy.covered_objects[0].desc
      : '';
  const isManualPolicyOnFnol = policy.is_manual; // is_manual - appears only on fnol
  const shouldShowPropertyDescription =
    isEmpty(coveredObjects) || !isFirstParty || (initialValues.property_description && !values.covered_object_id);

  return (
    <Grid container className={styles.involvedContainer}>
      <InnerCard title="General Details" className={styles.innerCard} titleClassName={styles.genericTitle}>
        <Grid container spacing={1}>
          {policy && isFirstParty && !isManualPolicyOnFnol && !isEmpty(coveredObjects) && (
            <Grid item xs={12}>
              <TextFieldFormik
                id="covered_object_id"
                label={`Select ${coveredDesc}`}
                select
                fullWidth
                onChange={(e) => {
                  handleChange(e);
                  const coveredObjectId = e.target.value;
                  const coveredObject = coveredObjects.find((coveredObject) => coveredObject.id === coveredObjectId);

                  setFieldValue('covered_object_external_id', coveredObject.external_id);

                  if (partyType === ENTITIES_TYPES.PROPERTY) {
                    setFieldValue('property_description', coveredObject?.display_name || '');
                    setFieldValue('display_name', coveredObject?.display_name || '');
                    setFieldValue('owner_contact', coveredObject?.contact);
                    setFieldValue('owner_contact_id', coveredObject?.contact_id || '');
                    setFieldValue('owner_contact_full_name', coveredObject?.contact_full_name || '');
                  } else {
                    setFieldValue('contact', coveredObject?.contact);
                    setFieldValue('contact_id', coveredObject?.contact_id || '');
                    setFieldValue('contact_full_name', coveredObject?.contact_full_name || '');
                  }
                }}
              >
                {coveredObjects.map((coveredObject) => (
                  <MenuItem key={coveredObject.id} value={coveredObject.id}>
                    {getCoveredObjectDisplayName(coveredObject)}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Grid>
          )}
          {partyType === ENTITIES_TYPES.PERSON && (
            <>
              {((isFirstParty && isEmpty(coveredObjects)) || !isFirstParty) && (
                <PreDefinedField id="contact_id" fields={preDefinedFields} inline />
              )}
              <PreDefinedField id="relationship_to_policyholder" fields={preDefinedFields} inline />
              <PreDefinedField id="attorney_contact_id" fields={preDefinedFields} inline />
              <PreDefinedField id="attorney_reference_number" fields={preDefinedFields} inline />
              <PreDefinedField id="representative_contact_id" fields={preDefinedFields} inline />
            </>
          )}
          {partyType === ENTITIES_TYPES.PROPERTY && (
            <>
              {shouldShowPropertyDescription && (
                <PreDefinedField id="property_description" fields={preDefinedFields} inline />
              )}
              <PreDefinedField id="owner_contact_id" fields={preDefinedFields} inline />
              <PreDefinedField id="attorney_contact_id" fields={preDefinedFields} inline />
              <PreDefinedField id="attorney_reference_number" fields={preDefinedFields} inline />
            </>
          )}
          <InvolvedInsurerFields preDefinedFields={preDefinedFields} />
          <PreDefinedField id="injury_date" fields={preDefinedFields} inline />
          {involvedPartyConfig?.configured_fields && (
            <ConfiguredFields values={values} customFields={involvedPartyConfig.configured_fields} inline />
          )}
        </Grid>
      </InnerCard>
    </Grid>
  );
};

GeneralDetailsFragment.propTypes = {
  involvedParty: PropTypes.string,
  preDefinedFields: PropTypes.object.isRequired,
};

export { GeneralDetailsFragment };
