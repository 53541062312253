import React from 'react';

import { useClaim } from '~/components/ClaimContainer';
import PhoneCallCommunicationPopover from '~/components/Contacts/ContactCommunications/PhoneCall/PhoneCallCommunicationPopover';
import SmsCommunicationPopover from '~/components/Contacts/ContactCommunications/SMS/SmsCommunicationPopover';
import type { ContactSubDetailsPropsType } from '~/components/Contacts/ContactMiniCard/types';
import Caption from '~/components/core/TextComponents/Caption';
import Text from '~/components/core/TextComponents/Text';
import DisableableToolTip from '~/components/DisableableTooltip';
import { useCms } from '~/components/hooks/useCms';
import { MIXPANEL_EVENT_SOURCES } from '~/pocs/mixpanel';

const Emails: React.FC<ContactSubDetailsPropsType> = ({ contact, onUpdate, actionsEnabled }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useCms();
  const { claim } = useClaim();

  return (
    <>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
        Phones
      </Text>
      <div className={`${contact.phones.length > 1 ? 'grid grid-cols-2 items-center gap-x-20' : ''}`}>
        {contact.phones.map((contactPhone) => (
          <div className="my-4 flex items-center" key={contactPhone.id}>
            <div className="flex grow items-center">
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                {contactPhone.phone}
              </Text>
              <Caption variant={Caption.VARIANTS.LABEL} className="pl-4">
                {contactPhone.phone_type}
              </Caption>
            </div>
            {actionsEnabled ? (
              <div>
                <PhoneCallCommunicationPopover
                  contact={contact}
                  onUpdate={onUpdate}
                  communicationDetails={contactPhone}
                  mixpanelSource={MIXPANEL_EVENT_SOURCES.CONTACT_MINI_CARD_NEW_PHONE_CALL}
                  ignorePermissions={true}
                />
                {contactPhone.phone_type !== 'fax' && (
                  <DisableableToolTip
                    disabled={
                      !!(
                        user.is_sending_sms_allowed &&
                        contact.is_smsing_allowed === true &&
                        !contactPhone.standardized_phone
                      )
                    }
                    title="Illegal phone number"
                  >
                    <SmsCommunicationPopover
                      contact={contact}
                      onUpdate={onUpdate}
                      communicationDetails={contactPhone}
                      mixpanelSource={MIXPANEL_EVENT_SOURCES.CONTACT_MINI_CARD_NEW_SMS_COMMUNICATION_CLICKED}
                      ignorePermissions={true}
                      claimId={claim?.id}
                    />
                  </DisableableToolTip>
                )}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
};

export default Emails;
