import React from 'react';

import SuccessMessageDialog from '~/components/core/SuccessMessageDialog';
import { DeleteWarningDialog } from '~/components/ReportsPage/Dialogs/DeleteWarningDialog';
import { EditWarningDialog } from '~/components/ReportsPage/Dialogs/EditWarningDialog';
import { ShareToDialog } from '~/components/ReportsPage/Dialogs/ShareToDialog';
import type { ReportActionHandler } from '~/components/ReportsPage/ReportsActions/useReportsActions';
import type { Project, ReportAction } from '~/components/ReportsPage/types';

interface ReportsActionsProps {
  action?: ReportAction | null;
  onClose: () => void;
  onConfirm: ReportActionHandler;
  projectsToShare: Project[];
  isDrafts?: boolean;
  showSuccessMessage: boolean;
  onCloseSuccessMessage: () => void;
}

export const ReportsActions: React.FC<ReportsActionsProps> = ({
  action,
  onClose,
  onConfirm,
  projectsToShare,
  isDrafts = false,
  showSuccessMessage,
  onCloseSuccessMessage,
}) => {
  return (
    <>
      {action === 'edit-dialog' && (
        <EditWarningDialog
          onClose={onClose}
          isDraft={isDrafts}
          onConfirm={() => onConfirm({ action: 'edit', params: {} })}
        />
      )}

      {(action === 'delete-dialog' || action === 'delete') && (
        <DeleteWarningDialog onClose={onClose} onConfirm={() => onConfirm({ action: 'delete', params: {} })} />
      )}

      {(action === 'share-dialog' || action === 'share') && (
        <ShareToDialog
          onClose={onClose}
          projectsToShare={projectsToShare}
          onConfirm={({ projectId }) => onConfirm({ action: 'share', params: { projectId } })}
        />
      )}
      {showSuccessMessage && (
        <SuccessMessageDialog
          onClose={() => onCloseSuccessMessage()}
          title="Success!"
          subtitle="Your action has been completed successfully."
          open={showSuccessMessage}
        />
      )}
    </>
  );
};
