import React from 'react';

import SvgIcon from '../core/SvgIcon';

import { iconProptypesBase } from './propTypes';

const DashIcon = ({ iconColor = 'currentColor', ...props }) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} disableStrokeColor>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.76562 12C3.76562 11.5858 4.10141 11.25 4.51562 11.25H21.0156C21.4298 11.25 21.7656 11.5858 21.7656 12C21.7656 12.4142 21.4298 12.75 21.0156 12.75H4.51562C4.10141 12.75 3.76562 12.4142 3.76562 12Z"
        fill={iconColor}
      />
    </SvgIcon>
  );
};

DashIcon.propTypes = iconProptypesBase;

export default DashIcon;
