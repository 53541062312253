export const UNIT_FIELDS = {
  id: 'id',
  name: 'name',
  description: 'description',
  parentUnitId: 'parent_unit_id',
  leaderUserId: 'leader_user_id',
  members: 'members',
  unitType: 'unit_type',
};

export const ORG_UNIT_TYPE = {
  TOP_UNIT: 'TOP_UNIT',
  SUB_UNIT: 'SUB_UNIT',
};

export const ORG_UNIT_TYPE_TITLE = {
  TOP_UNIT: 'Top Unit',
  SUB_UNIT: 'Sub Unit',
};

export const PARENT_UNIT_CHANGE_WARNING_MESSAGE =
  'Changing the Parent Unit will lead to a corresponding shift in the entire organizational structure.';

export const DELETE_UNIT_WARNING_MESSAGE =
  'Deleting this unit will disconnect it from the organizational structure. The unit leader and all members will be automatically removed.';

export const DEACTIVATE_UNIT_WARNING_MESSAGE =
  'Deactivating a unit will restrict users from adding the unit to new configurations and workflows. Existing configurations and workflows will remain unaffected until you manually update them. To fully delete this unit, you must first disconnect this unit from all related configurations and workflows.';

export const EDIT_DEACTIVATED_UNIT_WARNING_MESSAGE =
  'This unit is deactivated, and cannot be used in new configurations and workflows. Existing configurations and workflows will remain unaffected until you manually update them.';

export const CHOOSING_NON_AFFILIATED_USER_AS_A_LEADER =
  "This user is not affiliated with any other units yet. As a leader here, this user will be added to the parent unit members' list.";

export const DIALOG_MODE = {
  ADD: 'ADD',
  ADD_TOP_ONLY: 'ADD_TOP_ONLY',
  EDIT: 'EDIT',
  DEACTIVATE: 'DEACTIVATE',
};

export const DIALOG_TITLE = {
  ADD: 'Add Unit',
  ADD_TOP_ONLY: 'Add Top-Level Unit ',
  EDIT: 'Edit Unit',
  DEACTIVATE: 'Deactivate Unit',
};

export const ORGANIZATION_UNITS_MIXPANEL_EVENTS = {
  ADD_UNIT_CLICKED: 'add_unit_clicked',
  EDIT_UNIT_CLICKED: 'edit_unit_clicked',
  DELETE_UNIT_CLICKED: 'delete_unit_clicked',
  UNIT_MEMBER_ADD_CLICK: 'unit_member_add_click',
  UNIT_MEMBER_REMOVE_CLICK: 'unit_member_remove_click',
  UNIT_MEMBERS_SELECT_ALL_CLICKED: 'unit_members_select_all_clicked',
  UNIT_MEMBERS_REMOVE_ALL_CLICKED: 'unit_members_remove_all_clicked',
  UNIT_ACTIVATED: 'unit_activated',
  UNIT_DEACTIVATED: 'unit_deactivated',
};
