import React from 'react';

import { FsButton, PropertyDetails, SecondaryCard } from '../../../core';
import { PropertyDetailsRow } from '../../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { ConfigurationValuePropertyDetails } from '../../components/ConfigurationValuePropertyDetails';
import { CurrencyPropertyDetails } from '../../components/CurrencyPropertyDetails';
import { DatePropertyDetails } from '../../components/DatePropertyDetails';
import { LEGAL_ACTION_FIELDS } from '../../FormFragments/FormFieldsDefinitions';
import { useLegalAction } from '../../hooks/useLegalAction';

export const ClosedLegalActionBar: React.FC<{
  onReopenButtonClick: () => void;
}> = ({ onReopenButtonClick }) => {
  const { legalAction } = useLegalAction();
  return (
    <SecondaryCard type="filled">
      <div className="flex w-full items-center">
        <div className="flex w-full">
          <div className="center mr-[36px] flex h-auto w-min items-center">
            <div className="h-min rounded-sm border-1 border-solid border-status-done bg-green-100 px-[8px] py-[2px]">
              Closed
            </div>
          </div>
          <div className="flex w-full max-w-4xl">
            <PropertyDetailsRow>
              <ConfigurationValuePropertyDetails
                title={LEGAL_ACTION_FIELDS.methodOfConclusion.label}
                value={legalAction.method_of_conclusion}
                optionsKey="methodOfConclusionOptions"
              />
              <CurrencyPropertyDetails
                title={LEGAL_ACTION_FIELDS.finalSettlementAmount.label}
                value={legalAction.final_settlement_amount}
              />
              <DatePropertyDetails title="Date" date={legalAction.close_date} />
              <PropertyDetails title="Closed by" text={legalAction.closing_user} />
              <PropertyDetails
                title={LEGAL_ACTION_FIELDS.methodOfConclusionText.label}
                text={legalAction.method_of_conclusion_text}
              />
            </PropertyDetailsRow>
          </div>
        </div>
        <FsButton
          className="min-w-fit"
          variant={FsButton.VARIANTS.contained}
          color={FsButton.COLORS.primary}
          onClick={onReopenButtonClick}
        >
          Reopen Legal Action
        </FsButton>
      </div>
    </SecondaryCard>
  );
};
