const MARSHMALLOW_SPEEDS_LIST = [
  '0',
  '1-10',
  '11-20',
  '21-30',
  '31-40',
  '41-50',
  '51-60',
  '61-70',
  '70+',
  'Not applicable',
] as const;

export default MARSHMALLOW_SPEEDS_LIST;
