import React from 'react';

import SvgIcon from '../core/SvgIcon';

import { iconProptypesBase } from './propTypes';

const EyeSlashIcon = ({ iconColor, ...props }) => (
  <SvgIcon stroke="none" {...props}>
    <path
      d="M10.0491 9.51185L6.68914 5.82185L3.36914 2.16185C3.17914 1.96185 2.86914 1.94185 2.65914 2.13185C2.45914 2.32185 2.43914 2.63185 2.62914 2.84185L3.83914 4.18185C1.57914 5.56185 0.589141 7.70185 0.539141 7.80185C0.479141 7.93185 0.479141 8.08185 0.539141 8.21185C0.629141 8.41185 2.72914 13.0119 7.98914 13.0119H8.04914C9.13914 13.0119 10.2291 12.7718 11.2291 12.3218L12.6091 13.8419C12.7091 13.9518 12.8391 14.0019 12.9791 14.0019C13.0991 14.0019 13.2191 13.9619 13.3191 13.8719C13.5291 13.6819 13.5391 13.3719 13.3491 13.1618L10.0291 9.51185H10.0491ZM6.04914 7.54185C6.09914 7.31185 6.20914 7.11185 6.32914 6.91185L8.91914 9.76185C8.63914 9.91185 8.32914 9.99185 7.99914 9.99185C7.56914 10.0018 7.19914 9.87185 6.85914 9.64185C6.52914 9.41185 6.26914 9.09185 6.12914 8.71185C5.97914 8.33185 5.95914 7.92185 6.04914 7.53185V7.54185ZM8.04914 12.0019C3.96914 12.0419 1.99914 8.86185 1.54914 8.00185C1.83914 7.45185 2.78914 5.91185 4.51914 4.92185L5.62914 6.15185C5.35914 6.49185 5.16914 6.88185 5.06914 7.31185C4.92914 7.90185 4.96914 8.51185 5.18914 9.08185C5.40914 9.65185 5.78914 10.1319 6.28914 10.4719C6.78914 10.8119 7.37914 11.0019 7.97914 11.0019C8.55914 11.0019 9.10914 10.8319 9.58914 10.5219L10.5091 11.5318C9.71914 11.8319 8.87914 12.0019 8.02914 12.0019H8.04914Z"
      fill={iconColor}
    />
    <path
      d="M15.4592 7.8C15.3692 7.6 13.2692 3 7.99918 3C7.51918 3.01 7.06918 3.04 6.61918 3.11C6.34918 3.16 6.15918 3.41 6.20918 3.69C6.25918 3.96 6.50918 4.15 6.78918 4.1C7.18918 4.03 7.57918 4.01 7.99918 4C12.0592 4 13.9892 7.14 14.4392 8C14.2192 8.4 13.6592 9.34 12.6992 10.2C12.4892 10.38 12.4792 10.7 12.6592 10.91C12.7592 11.02 12.8992 11.08 13.0292 11.08C13.1492 11.08 13.2692 11.04 13.3592 10.95C14.7892 9.67 15.4192 8.27 15.4492 8.21C15.5092 8.08 15.5092 7.93 15.4492 7.8H15.4592Z"
      fill={iconColor}
    />
    <path
      d="M9.47904 6.66303C9.76904 6.98303 9.94904 7.39303 9.98904 7.82303C10.009 8.08303 10.229 8.27303 10.489 8.27303C10.509 8.27303 10.519 8.27303 10.539 8.27303C10.819 8.24303 11.019 8.00303 10.989 7.73303C10.929 7.09303 10.659 6.47303 10.219 5.99303C9.78904 5.51303 9.19905 5.18303 8.55905 5.06303C8.28905 5.01303 8.02904 5.19303 7.97904 5.46303C7.92904 5.73303 8.10904 5.99303 8.37904 6.04303C8.79904 6.12303 9.18904 6.34303 9.47904 6.66303Z"
      fill={iconColor}
    />
  </SvgIcon>
);

EyeSlashIcon.propTypes = iconProptypesBase;

export default EyeSlashIcon;
