import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';

import { CONFIGURATION_FEATURES_NAMES, UK_DRIVER_LICENSES_TYPES } from '../../../Types';
import { capitalize, isFeatureEnabled, isInshurPolicy, isMarshmallowPolicy } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { localeDetails } from '../../CmsMain/globals';
import WithConfirm from '../../ConfirmModal';
import { ContactEntity } from '../../Contact';
import { FsButton, FsIconButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../core';
import { useCms } from '../../hooks/useCms';
import HoverActionField from '../../HoverActionField';
import { TrashIcon } from '../../icons';
import { involvedPersonFields, InvolvedPersonFragment, involvedPersonValidationFields } from '../../InvolvedPerson';
import InvolvedWrapper from '../../InvolvedWrapper';
import { usePolicy } from '../../PolicyContainer';
import RadioButtonFormik from '../../RadioButtonFormik';
import TextFieldFormik, { DatePickerTextFieldFormik } from '../../TextFieldFormik';
import UsStatesSelectTextFieldFormik from '../../UsStatesSelectTextFieldFormik';

import { useStyles } from '../../../assets/styles';

const spacing = 1;

const workDriveDetailsFields = {
  drive_type: '',
  drive_type_other_extra: '',
};

const driverFields = {
  ...involvedPersonFields,
  named_driver_id: '',
  license_number: '',
  license_issuing_state: '',
  license_issue_date: '',
  was_seat_belt_worn: '',
  insurer_contact_id: '',
  insurer_reference_number: '',
  is_work_related_accident: '',
  contact: {
    id: '',
    government_id: '',
  },
  work_drive_details: {
    ...workDriveDetailsFields,
  },
};

const driverValidationFields = {
  ...involvedPersonValidationFields,
  license_number: Yup.string().nullable().max(128, 'Limited to 128 characters'),
  license_issuing_state: Yup.string().nullable(),
  license_issue_date: Yup.date().nullable(),
};

// TODO: refactor the disallow thing
function DriverInvolvedFragment(props) {
  const {
    disabled, // Note is always enabled
    disableEditIdentity,
    isInsured,
    showOnly,
  } = props;

  const classes = useStyles();
  const { policy } = usePolicy();
  const { userOrganization } = useCms();
  const { values, setFieldValue } = useFormikContext();

  const namedDrivers = policy.named_drivers || [];

  function handleSelectNamedDriver(e) {
    const namedDriverId = e.target.value;
    setFieldValue('named_driver_id', namedDriverId);

    if (namedDriverId === 0) {
      setFieldValue('contact_id', '');
      setFieldValue('contact_full_name', '');
      setFieldValue('license_number', '');
      setFieldValue('license_issue_date', '');
      setFieldValue('license_issuing_state', '');
      return;
    }

    const namedDriver = namedDrivers.find((nd) => nd.id === namedDriverId);
    setFieldValue('contact_id', namedDriver.id);
    setFieldValue('contact_full_name', namedDriver.full_name);
    setFieldValue('license_number', namedDriver.contact_extra.driving_license_number || '');
    setFieldValue('license_issue_date', namedDriver.contact_extra.driving_license_issue_date || '');
    setFieldValue('license_issuing_state', namedDriver.contact_extra.driving_license_state || '');
  }

  let disableDriverEditing = disabled || disableEditIdentity;
  let isUnlistedDriver;
  if (isInsured) {
    isUnlistedDriver = values['named_driver_id'] === 0;
    disableDriverEditing = disableDriverEditing || !isUnlistedDriver;
  }

  const shouldShowState = localeDetails.locale.region === 'US';
  const isUk = localeDetails.locale.region === 'GB';

  return (
    <Grid container spacing={spacing}>
      {isInsured && !policy.is_manual && (
        <Grid item xs={12}>
          <TextFieldFormik
            id="named_driver_id"
            label="Named Driver"
            select
            fullWidth
            disabled={disabled || disableEditIdentity}
            className={classes.textField}
            onChange={handleSelectNamedDriver}
            showOnly={showOnly}
          >
            {namedDrivers.concat({ id: 0, full_name: 'Unlisted Driver' }).map((namedDriver) => (
              <MenuItem key={namedDriver.id} value={namedDriver.id}>
                {namedDriver.full_name}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
      )}
      <InvolvedPersonFragment
        disableEditIdentity={disableEditIdentity}
        disableEditContact={disableDriverEditing}
        contactAcceptedRoles={
          isInsured
            ? ['insured', 'named_driver', 'spouse', 'family_member', 'claimant', 'other']
            : ['claimant', 'other']
        }
        contactSearchProps={isInsured ? undefined : { newContactRole: 'claimant' }}
        showOnly={showOnly}
        personLabel="Driver"
        additionalAfterContactFields={
          <>
            {isUk && (
              <Grid item xs={4}>
                <TextFieldFormik
                  id="extra.license_type"
                  label="License Type"
                  fullWidth
                  className={classes.textField}
                  disabled={disabled}
                  showOnly={showOnly}
                  select
                >
                  {UK_DRIVER_LICENSES_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
            )}
            <Grid item xs={4}>
              <TextFieldFormik
                id="license_number"
                label="License number"
                fullWidth
                className={classes.textField}
                disabled={disabled || disableEditIdentity}
                showOnly={showOnly}
              />
            </Grid>
            {shouldShowState && (
              <Grid item xs={4}>
                <UsStatesSelectTextFieldFormik
                  id="license_issuing_state"
                  label="Issuing State"
                  fullWidth
                  className={classes.textField}
                  disabled={disabled}
                  showOnly={showOnly}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <DatePickerTextFieldFormik
                id="license_issue_date"
                label="Issue Date"
                className={classes.textField}
                fullWidth
                disabled={disabled}
                disableFuture
              />
            </Grid>
            {isInshurPolicy(policy) && isInsured && (
              <Grid item xs={12}>
                <WorkDriverBaseContainer disabled={disabled || showOnly} />
              </Grid>
            )}
            {(isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.UK_FNOL_FIELDS_MM_STYLE) ||
              isMarshmallowPolicy(policy)) &&
              isInsured &&
              isUnlistedDriver && (
                <>
                  <TextFieldFormik
                    id="extra.insurer"
                    label="Insurer"
                    fullWidth
                    className={classes.textField}
                    disabled={disabled}
                    showOnly={showOnly}
                  />
                  <Grid item xs={6} />
                </>
              )}
            <Grid item xs={12}>
              <YesNoQuestionFormik
                questionText="Was seat belt worn?"
                id="was_seat_belt_worn"
                disabled={disabled || showOnly}
              />
            </Grid>
          </>
        }
      />
    </Grid>
  );
}

DriverInvolvedFragment.propTypes = {
  disabled: PropTypes.bool,
  isInsured: PropTypes.bool,
  disableEditIdentity: PropTypes.bool,
  showOnly: PropTypes.bool,
};

function InvolvedDriverDialog(props) {
  const { dialogAction, disableEditIdentity, driver, onCancel, onSubmit, open, title, showOnly, isInsured } = props;
  const classes = useStyles();
  const { policy } = usePolicy();
  const namedDrivers = policy.named_drivers;
  if (!open) {
    return <></>;
  }

  let driverInitialValues = driver ? driver : { ...driverFields };

  if (isInsured) {
    if (driver) {
      const contact_id = driver.contact_id; // insured driver must be set
      const namedDriver = namedDrivers && namedDrivers.find((nd) => nd.id === contact_id);
      if (namedDriver) {
        driverInitialValues = {
          ...driverInitialValues,
          named_driver_id: namedDriver.id,
          license_number: namedDriver.contact_extra.driving_license_number || '',
          license_issue_date: namedDriver.contact_extra.driving_license_issue_date || '',
          license_issuing_state: namedDriver.contact_extra.driving_license_state || '',
          contact: {
            id: namedDriver.id,
            government_id: namedDriver.government_id || '',
          },
        };
      } else {
        driverInitialValues = { ...driverInitialValues, named_driver_id: 0 };
      }
    } else {
      driverInitialValues =
        namedDrivers && namedDrivers.length > 0
          ? {
              ...driverFields,
              contact_id: namedDrivers[0].id,
              contact_full_name: namedDrivers[0].full_name,
              named_driver_id: namedDrivers[0].id,
              license_number: namedDrivers[0].contact_extra.driving_license_number || '',
              license_issue_date: namedDrivers[0].contact_extra.driving_license_issue_date || '',
              license_issuing_state: namedDrivers[0].contact_extra.driving_license_state || '',
              contact: {
                id: namedDrivers[0].id,
                government_id: namedDrivers[0].government_id || '',
              },
            }
          : { ...driverFields, named_driver_id: 0 };
    }
  }

  return (
    <Formik
      initialValues={driverInitialValues}
      validationSchema={Yup.object().shape({ ...driverValidationFields })}
      onSubmit={(values, formikProps) => {
        onSubmit(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title={title}
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
            action={dialogAction}
          >
            <DriverInvolvedFragment
              disableEditIdentity={disableEditIdentity}
              showOnly={showOnly}
              isInsured={isInsured}
            />
            <div className={classes.buttonsContainer}>
              {!showOnly && (
                <>
                  <CancelButton disabled={isSubmitting} onClick={onCancel} />
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Save
                  </Button>
                </>
              )}
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

InvolvedDriverDialog.propTypes = {
  dialogAction: PropTypes.node,
  disableEditIdentity: PropTypes.bool,
  driver: PropTypes.object,
  isInsured: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

function AddDriverInvolvedDialog(props) {
  const { open, onCancel, onSaveDriverDetails, isInsured } = props;

  return (
    <InvolvedDriverDialog
      isInsured={isInsured}
      onCancel={onCancel}
      onSubmit={onSaveDriverDetails}
      open={open}
      title="Add Driver"
    />
  );
}

AddDriverInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveDriverDetails: PropTypes.func.isRequired,
  isInsured: PropTypes.bool,
};

function EditDriverInvolvedDialog(props) {
  const { open, driver, isInsured, onCancel, onDeleteDriver, onSaveDriverDetails } = props;

  const dialogAction = onDeleteDriver && (
    <WithConfirm title="Delete Driver?" primaryButtonName="Delete" shouldCloseOnPrimary={false}>
      <FsIconButton onClick={onDeleteDriver} icon={TrashIcon} />
    </WithConfirm>
  );

  return (
    <InvolvedDriverDialog
      disableEditIdentity={driver.is_locked}
      dialogAction={dialogAction}
      driver={driver}
      isInsured={isInsured}
      onCancel={onCancel}
      onSubmit={onSaveDriverDetails}
      open={open}
      title="Edit Driver"
    />
  );
}

EditDriverInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  driver: PropTypes.object.isRequired,
  isInsured: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveDriverDetails: PropTypes.func.isRequired,
  onDeleteDriver: PropTypes.func,
};

function ShowOnlyDriverInvolvedDialog(props) {
  const { driver, open, onCancel, isInsured } = props;

  return (
    <InvolvedDriverDialog
      driver={driver}
      isInsured={isInsured}
      onCancel={onCancel}
      open={open}
      title="Driver Details"
      showOnly
    />
  );
}

ShowOnlyDriverInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  driver: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  isInsured: PropTypes.bool,
};

function DriverDetails(props) {
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  const { classes, driver, disabled, isInsured, onSetDriverDetails, onSaveDriverDetails, onDeleteDriver, error } =
    props;

  const handleUpdateDriverDetails = async (values) => {
    await onSaveDriverDetails(values);
    setEditDialogOpen(false);
  };
  const handleAddDriver = async (values) => {
    await onSetDriverDetails(values);
    setAddDialogOpen(false);
  };
  const handleDeleteDriver = async () => {
    await onDeleteDriver();
    setEditDialogOpen(false);
  };

  return (
    <>
      <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
        <div className={classes.nested}>
          {driver ? (
            <InvolvedWrapper
              onEdit={() => setEditDialogOpen(true)}
              disabled={disabled}
              involved={driver}
              involvedType="person"
            >
              <div className={classes.containerCentered}>
                <ContactEntity
                  contactId={driver.contact_id === '' ? null : driver.contact_id}
                  contactDisplayName={driver.contact_full_name}
                />
                <Typography style={{ paddingLeft: '8px' }} display="inline" variant="caption">
                  Driver
                </Typography>
              </div>
            </InvolvedWrapper>
          ) : (
            <FsButton color="primary" disabled={disabled} onClick={() => setAddDialogOpen(true)}>
              Set Driver
            </FsButton>
          )}
          {error && <FormHelperText error>{error}</FormHelperText>}
        </div>

        {driver && (
          <EditDriverInvolvedDialog
            classes={classes}
            driver={driver}
            open={editDialogOpen}
            onSaveDriverDetails={handleUpdateDriverDetails}
            onDeleteDriver={onDeleteDriver && handleDeleteDriver}
            onCancel={() => setEditDialogOpen(false)}
            isInsured={isInsured}
          />
        )}
        <AddDriverInvolvedDialog
          open={addDialogOpen}
          onCancel={() => setAddDialogOpen(false)}
          onSaveDriverDetails={handleAddDriver}
          isInsured={isInsured}
        />
      </RestrictedPermissions>
    </>
  );
}

DriverDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isInsured: PropTypes.bool,
  driver: PropTypes.object,
  onSetDriverDetails: PropTypes.func.isRequired,
  onSaveDriverDetails: PropTypes.func.isRequired,
  onDeleteDriver: PropTypes.func,
  error: PropTypes.string,
};

function WorkDriverBaseContainer(props) {
  const { disabled } = props;
  const { setFieldValue, values } = useFormikContext();
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = React.useState(false);

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
            {'Was it a work drive ("Base")?'}
          </span>
        </Grid>
        <Grid item xs={6}>
          <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            <RadioButtonFormik
              id="is_work_related_accident"
              label="No"
              optionValue={false}
              disabled={disabled}
              size="small"
            />
            <RadioButtonFormik
              id="is_work_related_accident"
              label={
                // In case we have work_drive_details but the RadioButtonFormik is disabled - allow viewing with hover
                <HoverActionField
                  disabled={!disabled || !values.is_work_related_accident}
                  icon={VisibilityIcon}
                  onAction={() => setIsDetailsDialogOpen(true)}
                >
                  {'Yes >'}
                </HoverActionField>
              }
              optionValue={true}
              onClick={() => setIsDetailsDialogOpen(true)}
              onChange={() => {}} // do nothing on change, it will be simulated after approving details
              disabled={disabled}
              size="small"
            />
          </div>
        </Grid>
      </Grid>
      <WorkDriverBaseDetailsDialog
        open={isDetailsDialogOpen}
        showOnly={disabled}
        onCancel={() => setIsDetailsDialogOpen(false)}
        workDriveDetails={{ ...workDriveDetailsFields, ...values.work_drive_details }}
        onSubmitDetails={(newWorkDriveDetails) => {
          setFieldValue('work_drive_details', newWorkDriveDetails);
          setIsDetailsDialogOpen(false);
          setFieldValue('is_work_related_accident', true);
        }}
      />
    </>
  );
}

WorkDriverBaseContainer.propTypes = {
  disabled: PropTypes.bool,
};

function WorkDriverBaseDetailsDialog(props) {
  const { onSubmitDetails, workDriveDetails, showOnly, open, onCancel } = props;
  const classes = useStyles();

  const driveTypes = ['uber', 'lyft', 'yellow_cab', 'black_car', 'other'];

  return (
    <Formik
      initialValues={{ ...workDriveDetails }}
      validationSchema={Yup.object().shape({
        drive_type: Yup.string().required('Required'),
        drive_type_other_extra: Yup.string().when('drive_type', {
          is: 'other',
          then: Yup.string().required('Required'),
        }),
      })}
      enableReinitialize
      onSubmit={(values, formikBag) => {
        onSubmitDetails(values);
        formikBag.resetForm();
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title="Work Drive Details"
            fullWidth
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="drive_type"
                  label="Company Name"
                  className={classes.textField}
                  showOnly={showOnly}
                  fullWidth
                  select
                >
                  {driveTypes.map((option) => (
                    <MenuItem key={option} value={option}>
                      {capitalize(option.replace('_', ' '))}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              {values.drive_type === 'other' && (
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="drive_type_other_extra"
                    label="Other Company Name"
                    className={classes.textField}
                    showOnly={showOnly}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
            {!showOnly && (
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

WorkDriverBaseDetailsDialog.propTypes = {
  onSubmitDetails: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showOnly: PropTypes.bool,
  workDriveDetails: PropTypes.object.isRequired,
};

export { AddDriverInvolvedDialog, DriverDetails, driverFields, EditDriverInvolvedDialog, ShowOnlyDriverInvolvedDialog };
