import React, { useCallback, useEffect, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Button } from '@mui/material';
import axios from 'axios';

import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { COVERAGE_CONFIGURATION_LIMITS } from '../../../../Types';
import { isPolicyManuallyFilled, reportAxiosError } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import { useClaim } from '../../../ClaimContainer';
import WithConfirm from '../../../ConfirmModal';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../../core';
import CoveredEntityCoveragesTable from '../../../CoveredEntity';
import useCurrencyFormatter from '../../../CurrencyFormatterContext';
import { PencilIcon, TrashIcon } from '../../../icons';
import { CustomField } from '../../../IncidentConfiguration/ConfiguredFields';
import { CustomFieldsContextProvider } from '../../../IncidentConfiguration/CustomFieldsContext';
import InlineIconButton from '../../../InlineIconButton';
import useOrganization from '../../../OrganizationContext';
import { ShowOnlyTextField } from '../../../TextFieldFormik';

import EditCoveredEntityDialog from './EditCoveredEntityDialog/EditCoveredEntityDialog';
import CoveredEntityAccumulatedFinancialContainer from './CoveredEntityAccumulatedFinance';

import { useStyles } from '../../../../assets/styles';
import styles from './CoveredEntityCard.module.scss';

function CoveredEntityCard(props) {
  const {
    icon,
    coveredObjectTypeLabel,
    defaultFields,
    configuredFields,
    coveredObjectIdForPatchEndpoints,
    displayFieldsCount = 4,
    fetchCoveredEntityEndpoint,
    getPatchCoveredEntityEndpoint,
    getPatchCoveragesEndpoint,
    getPatchSharedCoveragesEndpoint,
    coveredObject,
    isFirstParty = false,
  } = props;
  const { claim, onAsyncClaimUpdate } = useClaim();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [showEditCoveredEntityModal, setShowEditCoveredEntityModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { coverageConfiguration } = useOrganization();
  const { currencyFormatter } = useCurrencyFormatter();
  const isManualPolicy = isPolicyManuallyFilled(claim);
  const isAutoClaim = claim?.lob === 'auto_claim';
  const isPetClaim = claim?.lob === 'pet_claim';
  const xs = isAutoClaim ? 3 : 4;
  const wasUnlisted = coveredObject?.was_unlisted || false;
  const policyPrefix = `${isManualPolicy ? '' : 'api_'}`;
  const coveredVehicleDisplayName = coveredObject?.vehicle?.display_name;
  const coveredObjectDisplayName = coveredObject?.display_name || coveredObject?.contact?.full_name;
  const cardHeaderTitle =
    (isAutoClaim ? coveredVehicleDisplayName : coveredObjectDisplayName) || coveredObjectTypeLabel;
  const [isLoadingCoveredEntity, setIsLoadingCoveredEntity] = useState(true);
  const [coveredEntity, setCoveredEntity] = useState();

  const fetchCoveredEntity = useCallback(async () => {
    try {
      const res = await axios.get(fetchCoveredEntityEndpoint);
      setCoveredEntity(res.data);
      setIsLoadingCoveredEntity(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  }, [fetchCoveredEntityEndpoint]);

  useEffect(() => {
    fetchCoveredEntity();
  }, [fetchCoveredEntity]);

  const coveredEntityTitle = (
    <span className={styles.cardTitleWrapper}>
      {icon}
      <span className={styles.cardTitleWrapperLabel}>{cardHeaderTitle}</span>
      {isFirstParty && (
        <span className="ml-12">
          <Chip size="small" label="First Party Involved" className={`${classes.chip} font-normal`} />
        </span>
      )}
    </span>
  );

  async function handleCoveredEntityUpdate(values) {
    setIsSaving(true);
    try {
      const url = getPatchCoveredEntityEndpoint
        ? getPatchCoveredEntityEndpoint()
        : `/api/v1/claims/${claim.id}/${policyPrefix}policy/covered_objects/${coveredObjectIdForPatchEndpoints}`;
      await axios.patch(url, values);
      await onAsyncClaimUpdate();
      await fetchCoveredEntity();
      setShowEditCoveredEntityModal(false);
    } catch (error) {
      reportAxiosError(error);
      setIsSaving(false);
      throw error;
    }
    setIsSaving(false);
  }

  async function handleUnlistCoveredVehicle() {
    setIsSaving(true);
    try {
      const url = `/api/v1/auto_claims/${claim.id}/${policyPrefix}policy/covered_vehicles/${coveredObject.id}/set_unlisted`;
      await axios.patch(url);
      await onAsyncClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSaving(false);
  }

  async function handleRemoveCoveredObject() {
    setIsSaving(true);
    try {
      const url = `/api/v1/claims/${claim.id}/${policyPrefix}policy/covered_objects/${coveredObject.id}`;
      await axios.delete(url);
      await onAsyncClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSaving(false);
  }

  const coveredEntityAction = (
    <div className={styles.actionsContainer}>
      <RestrictedPermissions
        action={isManualPolicy ? PERMISSION_ACTIONS.MANUAL_POLICY : PERMISSION_ACTIONS.API_POLICY}
        verb={PERMISSION_VERBS.WRITE}
      >
        <div className={styles.editIconWrapper}>
          <InlineIconButton
            icon={PencilIcon}
            className={styles.editIcon}
            onClick={() => setShowEditCoveredEntityModal(true)}
            tooltipTitle={coveredEntity ? `Edit ${coveredObjectTypeLabel}` : `Loading ${coveredObjectTypeLabel}...`}
            disabled={!coveredEntity}
          />
          {coveredObject && (
            <WithConfirm
              title={`Remove this ${coveredObjectTypeLabel}?`}
              contentText={`Are you sure you want to remove this ${coveredObjectTypeLabel} from the policy?`}
              primaryButtonName="Yes, Remove"
              shouldCloseOnPrimary
            >
              <InlineIconButton
                icon={TrashIcon}
                className={styles.deleteIcon}
                onClick={wasUnlisted ? handleUnlistCoveredVehicle : handleRemoveCoveredObject}
                tooltipTitle={`Remove ${coveredObjectTypeLabel} from policy`}
              />
            </WithConfirm>
          )}
        </div>
      </RestrictedPermissions>
    </div>
  );

  let accumulatedEntityFields = null;
  if (coveredEntity) {
    accumulatedEntityFields = (
      <>
        {isPetClaim && (
          <Grid item xs={xs}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={currencyFormatter.format(coveredEntity.deductible_per_policy_per_covered_entity)}
              label="Deductible per Listed Entity (per Policy)"
              showOnly
              fullWidth
              className={classes.textField}
            />
          </Grid>
        )}
        <Grid item xs={xs}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={
              coveredEntity.limit_per_policy_per_covered_entity !== null &&
              coverageConfiguration.limit_per_policy_per_covered_entity &&
              currencyFormatter.format(coveredEntity.limit_per_policy_per_covered_entity)
            }
            label={COVERAGE_CONFIGURATION_LIMITS.limit_per_policy_per_covered_entity.desc}
            showOnly
            disabled={!coverageConfiguration.limit_per_policy_per_covered_entity}
            fullWidth
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={xs}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={
              coveredEntity.limit_per_covered_entity !== null &&
              coverageConfiguration.limit_per_covered_entity &&
              currencyFormatter.format(coveredEntity.limit_per_covered_entity)
            }
            label={COVERAGE_CONFIGURATION_LIMITS.limit_per_covered_entity.desc}
            showOnly
            disabled={!coverageConfiguration.limit_per_covered_entity}
            fullWidth
            className={classes.textField}
          />
        </Grid>
      </>
    );
  }

  const allFieldConfigurations = defaultFields.concat(configuredFields);

  const allFields = allFieldConfigurations
    .map((field) => (
      <Grid item xs={xs} key={field.id} className={styles.detailsGridItem}>
        <CustomField
          field={field}
          readOnly
          disabled
          value={field.value}
          inline={false}
          idPrefix=""
          maxWidth={100}
          overFlowWithTooltip={true}
        />
      </Grid>
    ))
    .concat(accumulatedEntityFields);

  const showFields = allFields.filter((field, i) => i < displayFieldsCount);
  const hiddenFields = allFields.filter((field, i) => i >= displayFieldsCount);

  return (
    <CardDialog
      title={coveredEntityTitle}
      action={coveredEntityAction}
      headerStyle={{ padding: '0 0 24px' }}
      containerStyle={{ padding: '24px', marginBottom: '24px' }}
      contentStyle={{ padding: '0' }}
    >
      <CustomFieldsContextProvider customFieldsConfigurations={allFieldConfigurations}>
        <Grid container spacing={1} className={styles.detailsGridContainer}>
          {showFields}
          {hiddenFields.length > 0 && !expanded && (
            <Grid item xs={12}>
              <div className={styles.showAll}>
                <Button
                  className={`${classes.actionButton} ${classes.button}`}
                  variant="text"
                  color="primary"
                  onClick={() => setExpanded(true)}
                  endIcon={<ExpandMore />}
                >
                  Show All
                </Button>
              </div>
            </Grid>
          )}
          {expanded && hiddenFields}
          {hiddenFields.length > 0 && expanded && (
            <Grid item xs={12}>
              <div className={styles.showAll}>
                <Button
                  className={`${classes.actionButton} ${classes.button}`}
                  variant="text"
                  color="primary"
                  onClick={() => setExpanded(false)}
                  endIcon={<ExpandLess />}
                >
                  Show Less
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </CustomFieldsContextProvider>
      <div className={styles.separator} />
      <RestrictedPermissions
        action={isManualPolicy ? PERMISSION_ACTIONS.MANUAL_POLICY : PERMISSION_ACTIONS.API_POLICY}
        verb={PERMISSION_VERBS.WRITE}
      >
        <CoveredEntityCoveragesTable
          coveredEntity={coveredEntity}
          isLoadingCoveredEntity={isLoadingCoveredEntity}
          onCoverageUpdate={async (postValues, coverageKey) => {
            const url = getPatchCoveragesEndpoint
              ? getPatchCoveragesEndpoint(coverageKey)
              : `/api/v1/claims/${claim.id}/${
                  isManualPolicy ? '' : 'api_'
                }policy/covered_objects/${coveredObjectIdForPatchEndpoints}/coverage/${coverageKey}`;
            await axios.patch(url, postValues);
            await fetchCoveredEntity();
          }}
          onSharedCoverageUpdate={async (postValues, sharedCoverageKey) => {
            const url = getPatchSharedCoveragesEndpoint
              ? getPatchSharedCoveragesEndpoint(sharedCoverageKey)
              : `/api/v1/claims/${claim.id}/${
                  isManualPolicy ? '' : 'api_'
                }policy/covered_objects/${coveredObjectIdForPatchEndpoints}/shared_coverage/${sharedCoverageKey}`;
            await axios.patch(url, postValues);
            await fetchCoveredEntity();
          }}
        />
      </RestrictedPermissions>
      {isPetClaim && coveredEntity && coveredEntity.entity_id && (
        <CoveredEntityAccumulatedFinancialContainer coveredEntity={coveredEntity} />
      )}
      {showEditCoveredEntityModal && (
        <EditCoveredEntityDialog
          icon={icon}
          coveredObjectTypeLabel={coveredObjectTypeLabel}
          defaultFields={defaultFields}
          configuredFields={configuredFields}
          coveredEntity={coveredEntity}
          onSaveClick={(values) => handleCoveredEntityUpdate(values)}
          onClose={() => !isSaving && setShowEditCoveredEntityModal(false)}
          isSaving={isSaving}
          isUpdate
        />
      )}
    </CardDialog>
  );
}

CoveredEntityCard.propTypes = {
  icon: PropTypes.object.isRequired,
  coveredObjectTypeLabel: PropTypes.string.isRequired,
  defaultFields: PropTypes.array.isRequired,
  configuredFields: PropTypes.array.isRequired,
  displayFieldsCount: PropTypes.number,
  fetchCoveredEntityEndpoint: PropTypes.string.isRequired,
  coveredObjectIdForPatchEndpoints: requiredIf(
    PropTypes.string,
    (props) =>
      !props.getPatchCoveredEntityEndpoint || !props.getPatchCoveragesEndpoint || !props.getPatchSharedCoveragesEndpoint
  ),
  getPatchCoveredEntityEndpoint: PropTypes.func,
  getPatchCoveragesEndpoint: PropTypes.func,
  getPatchSharedCoveragesEndpoint: PropTypes.func,
  coveredObject: PropTypes.object,
  isFirstParty: PropTypes.bool,
};

export default CoveredEntityCard;
