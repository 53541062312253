import type {
  AlternativeDisputeResolutionFormValues,
  CloseLegalActionFormValues,
  DateOfServiceFormValues,
  DisputeResolutionProfessionalFormValues,
  JurisdictionInformationFormValues,
  LawsuitDetailsFormValues,
  LegalActionLogFormValues,
  LegalActionNegotiationLogFormValues,
  LegalActionPartyFormValues,
  LegalActionsSummaryFormValues,
  NewLegalActionValues,
  ProsAndConsFormValues,
  ReopenLegalActionFormValues,
} from './FormValuesTypes';

type FieldDefinitionObject<T> = { [key: string]: { key: keyof T; label: string } };

export const LEGAL_ACTION_PARTY_FIELDS: FieldDefinitionObject<LegalActionPartyFormValues> = {
  contact: { key: 'contact_id', label: 'Name' },
  counsel: { key: 'counsel_contact_id', label: 'Counsel' },
  fileNumber: { key: 'file_number', label: 'File Number' },
  dateOfRepresentation: { key: 'date_of_representation', label: 'Date of Representation' },
  endDateOfRepresentation: { key: 'end_date_of_representation', label: 'End Date of Representation' },
  isCarrierParty: { key: 'is_carrier_party', label: 'Carrier Involved Party' },
};
export const LEGAL_ACTION_FIELDS: FieldDefinitionObject<
  CloseLegalActionFormValues &
    LegalActionsSummaryFormValues &
    ReopenLegalActionFormValues &
    LawsuitDetailsFormValues &
    NewLegalActionValues
> = {
  isClaimLevel: { key: 'is_claim_level', label: 'Apply To' },
  exposures: { key: 'exposures', label: 'Exposures' },
  displayName: { key: 'display_name', label: 'Display Name for Legal Action' },
  priority: { key: 'priority', label: 'Priority' },
  lawsuitAmount: { key: 'lawsuit_amount', label: 'Lawsuit Amount' },
  complaintAllegationsSummary: { key: 'complaint_allegations_summary', label: 'Complaint Allegations Summary' },
  methodOfConclusion: { key: 'method_of_conclusion', label: 'Method of Conclusion' },
  methodOfConclusionText: { key: 'method_of_conclusion_text', label: 'Note' },
  finalSettlementAmount: { key: 'final_settlement_amount', label: 'Final Settlement Amount' },
  reopenReason: { key: 'reopen_reason', label: 'Reason for Reopening' },
  reopenReasonText: { key: 'reopen_text', label: 'Note' },
  lawsuitType: { key: 'lawsuit_type', label: 'Lawsuit Type' },
  complaintNumber: { key: 'complaint_number', label: 'Complaint Number' },
  natureOfDispute: { key: 'nature_of_dispute', label: 'Nature of Dispute' },
  natureOfDisputeText: { key: 'nature_of_dispute_text', label: 'Note' },
  legalActionPlaintiffs: { key: 'legal_action_plaintiffs', label: '' },
  legalActionDefendants: { key: 'legal_action_defendants', label: '' },
  datesOfService: { key: 'dates_of_service', label: '' },
  disputeResolutionProfessionals: { key: 'dispute_resolution_professionals', label: '' },
};

export const DATE_OF_SERVICE_FIELDS: FieldDefinitionObject<DateOfServiceFormValues> = {
  dateOfService: { key: 'date_of_service', label: 'Date of Service' },
  serviceType: { key: 'service_type', label: 'Service Type' },
  contact: { key: 'contact_id', label: 'Contact Name' },
  otherText: { key: 'other_text', label: 'Note' },
};
export const JURISDICTION_INFORMATION_FIELDS: FieldDefinitionObject<JurisdictionInformationFormValues> = {
  jurisdiction: { key: 'jurisdiction_state', label: 'Jurisdiction' },
  venue: { key: 'venue', label: 'Venue' },
  court: { key: 'court', label: 'Court' },
  dateFilledInCourt: { key: 'date_filled_in_court', label: 'Date Filed in Court' },
};
export const DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS: FieldDefinitionObject<DisputeResolutionProfessionalFormValues> = {
  type: { key: 'type', label: 'Type' },
  contact: { key: 'contact_id', label: 'Contact' },
  startDate: { key: 'start_date', label: 'Start Date' },
  endDate: { key: 'end_date', label: 'End Date' },
};
export const ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS: FieldDefinitionObject<AlternativeDisputeResolutionFormValues> = {
  adrExplored: { key: 'alternative_dispute_resolution_explored', label: 'ADR Explored' },
  earlyCaseResolutionExplored: { key: 'early_case_resolution_explored', label: 'Early Case Resolution Explored' },
  alternativeDisputeResolutionText: { key: 'alternative_dispute_resolution_text', label: 'Note' },
};

export const LEGAL_ACTION_LOG_FIELDS: FieldDefinitionObject<LegalActionLogFormValues> = {
  date: { key: 'date', label: 'Date' },
  logType: { key: 'log_type', label: 'Type' },
  logTypeText: { key: 'log_type_text', label: 'Log Type Note' },
  dueDate: { key: 'due_date', label: 'Due Date' },
  nextAction: { key: 'next_action', label: 'Plan of Next Action' },
  counselUpdate: { key: 'counsel_update', label: 'Counsel Update' },
  statusOfLitigation: { key: 'status_of_litigation', label: 'Status Of Legal Action' },
  statusOfLitigationText: { key: 'status_of_litigation_text', label: 'Status Of Legal Action Note' },
};

export const PROS_AND_CONS_FIELDS: FieldDefinitionObject<ProsAndConsFormValues> = {
  proConType: { key: 'pro_con_type', label: 'Type' },
  rationale: { key: 'rationale', label: 'Rationale' },
};

export const NEGOTIATION_LOG_FIELDS: FieldDefinitionObject<LegalActionNegotiationLogFormValues> = {
  adversePartyDemand: { key: 'adverse_party_demand', label: 'Adverse Party Demand' },
  offer: { key: 'offer', label: 'Claims Org Offer' },
  notes: { key: 'notes', label: 'Notes' },
  date: { key: 'date', label: 'Date' },
  adversePartyContact: { key: 'adverse_party_contact_id', label: 'Adverse Party Contact' },
};
