import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const CommunicationIcon: React.FC<IconProps> = ({ iconColor, className, size, disabled }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    disableStrokeColor
    fill={iconColor}
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.69 3.44A1.5 1.5 0 0 1 3.75 3h12a1.5 1.5 0 0 1 1.5 1.5v3h3a1.5 1.5 0 0 1 1.5 1.5v12a.75.75 0 0 1-1.221.583l-3.507-2.833H8.25a1.5 1.5 0 0 1-1.5-1.5v-2.816l-3.279 2.65A.75.75 0 0 1 2.25 16.5v-12c0-.398.158-.78.44-1.06Zm5.56 10.81v3h9.038a.75.75 0 0 1 .47.167l2.492 2.013V9h-3v3.75a1.5 1.5 0 0 1-1.5 1.5h-7.5Zm7.5-9.75h-12v10.43l2.491-2.013a.75.75 0 0 1 .472-.167h9.037V4.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default CommunicationIcon;
