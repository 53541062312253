import React from 'react';
import PropTypes from 'prop-types';

import Chip from '~/components/core/Atomic/Chip/Chip';

import { getExposuresLabels } from '../Utils';

import OverflowTextWithToolTip from './OverflowTextWithToolTip';
import { MultiSelectIdLabelTextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function ExposureMultiSelectTextFieldFormik({
  claim,
  showOnly,
  id = 'exposure_ids',
  shouldIncludeGeneral = true,
  ...rest
}) {
  const classes = useStyles();

  const exposureLabels = getExposuresLabels(claim, shouldIncludeGeneral);

  return (
    <MultiSelectIdLabelTextFieldFormik
      id={id}
      select
      label="Exposure Labels"
      className={classes.textField}
      fullWidth
      showOnly={showOnly}
      options={exposureLabels}
      renderValue={(selectedExposures) => (
        <span className={classes.chips}>
          {selectedExposures.map((exposure) => (
            <span key={exposure.id} style={{ padding: '4px' }}>
              <Chip
                size="small"
                label={<OverflowTextWithToolTip maxWidth="220px">{exposure.label}</OverflowTextWithToolTip>}
              />
            </span>
          ))}
        </span>
      )}
      {...rest}
    />
  );
}

ExposureMultiSelectTextFieldFormik.propTypes = {
  claim: PropTypes.object.isRequired,
  showOnly: PropTypes.bool,
  id: PropTypes.string,
  shouldIncludeGeneral: PropTypes.bool,
};

export default ExposureMultiSelectTextFieldFormik;
