import { useEffect, useState } from 'react';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import { omit, pick } from 'lodash';

import { reportAxiosError } from '../../Utils';

import { useCms } from './useCms';

const xactAnalysisContractorUrl = (organizationId, contactId) =>
  `/api/v1/xactanalysis/${organizationId}/contractors/by_contact_id/${contactId}`;

export const updateXactAnalysisContractor = async (organization, contactId, data) => {
  if (!isXactAnalysisIntegrationEnabled(organization) || Object.keys(data).length === 0) {
    return;
  }

  try {
    await axios.post(xactAnalysisContractorUrl(organization.id, contactId), data);
  } catch (error) {
    reportAxiosError(error);
  }
};

export const isXactAnalysisIntegrationEnabled = (organization) =>
  organization?.configuration?.is_xactanalysis_enabled || false;

const CONTRACTOR_PROP_NAMES = ['is_xactanalysis_contractor', 'carrier_id', 'xactnet_address'];

// TODO NGTPA-14379 - deprecate
export function splitXactAnalysisContractorPayload(mixedPayload) {
  return {
    contactPayload: omit(mixedPayload, ...CONTRACTOR_PROP_NAMES),
    xactAnalysisContractorPayload: pick(mixedPayload, ...CONTRACTOR_PROP_NAMES),
  };
}

const useXactAnalysisContractor = (
  contactId,
  isXactanalysisContractorFieldId = 'is_xactanalysis_contractor',
  carrierIdFieldId = 'carrier_id',
  xactnetAddressFieldId = 'xactnet_address'
) => {
  const { userOrganization } = useCms();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [isContractorFetched, setIsContractorFetched] = useState(false);
  const [isContractorLoading, setIsContractorLoading] = useState(false);
  const isXactAnalysisEnabled = isXactAnalysisIntegrationEnabled(userOrganization);

  const isXactanalysisContractor = getIn(values, isXactanalysisContractorFieldId);

  useEffect(() => {
    const fetchIsContactContractor = async () => {
      try {
        setIsContractorLoading(true);
        const { data } = await axios.get(xactAnalysisContractorUrl(userOrganization.id, contactId), {});

        if (data?.is_xactanalysis_contractor) {
          setFieldValue(carrierIdFieldId, data?.carrier_id);
          setFieldTouched(carrierIdFieldId, true);
          setFieldValue(xactnetAddressFieldId, data?.xactnet_address);
          setFieldTouched(xactnetAddressFieldId, true);
        }

        setFieldValue(isXactanalysisContractorFieldId, data?.is_xactanalysis_contractor || false);
        setIsContractorFetched(true);
      } catch (error) {
        reportAxiosError(error);
      }
      setIsContractorLoading(false);
    };

    if (isXactAnalysisEnabled && contactId && !isXactanalysisContractor && !isContractorFetched) {
      return fetchIsContactContractor();
    }
  }, [
    isXactAnalysisEnabled,
    userOrganization.id,
    contactId,
    isXactanalysisContractor,
    setFieldValue,
    isContractorFetched,
    setFieldTouched,
    values,
    isXactanalysisContractorFieldId,
    carrierIdFieldId,
    xactnetAddressFieldId,
  ]);

  return {
    isXactAnalysisEnabled,
    isContractorLoading,
  };
};

export default useXactAnalysisContractor;
