import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const NoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      d="M28.75 25.75C28.7488 26.9431 28.2743 28.087 27.4306 28.9306C26.587 29.7743 25.4431 30.2488 24.25 30.25H22.75V31.75C22.75 31.9489 22.671 32.1397 22.5303 32.2803C22.3897 32.421 22.1989 32.5 22 32.5C21.8011 32.5 21.6103 32.421 21.4697 32.2803C21.329 32.1397 21.25 31.9489 21.25 31.75V30.25H19.75C18.5569 30.2488 17.413 29.7743 16.5694 28.9306C15.7257 28.087 15.2512 26.9431 15.25 25.75C15.25 25.5511 15.329 25.3603 15.4697 25.2197C15.6103 25.079 15.8011 25 16 25C16.1989 25 16.3897 25.079 16.5303 25.2197C16.671 25.3603 16.75 25.5511 16.75 25.75C16.75 26.5456 17.0661 27.3087 17.6287 27.8713C18.1913 28.4339 18.9544 28.75 19.75 28.75H24.25C25.0456 28.75 25.8087 28.4339 26.3713 27.8713C26.9339 27.3087 27.25 26.5456 27.25 25.75C27.25 24.9544 26.9339 24.1913 26.3713 23.6287C25.8087 23.0661 25.0456 22.75 24.25 22.75H20.5C19.3065 22.75 18.1619 22.2759 17.318 21.432C16.4741 20.5881 16 19.4435 16 18.25C16 17.0565 16.4741 15.9119 17.318 15.068C18.1619 14.2241 19.3065 13.75 20.5 13.75H21.25V12.25C21.25 12.0511 21.329 11.8603 21.4697 11.7197C21.6103 11.579 21.8011 11.5 22 11.5C22.1989 11.5 22.3897 11.579 22.5303 11.7197C22.671 11.8603 22.75 12.0511 22.75 12.25V13.75H23.5C24.6931 13.7512 25.837 14.2257 26.6806 15.0694C27.5243 15.913 27.9988 17.0569 28 18.25C28 18.4489 27.921 18.6397 27.7803 18.7803C27.6397 18.921 27.4489 19 27.25 19C27.0511 19 26.8603 18.921 26.7197 18.7803C26.579 18.6397 26.5 18.4489 26.5 18.25C26.5 17.4544 26.1839 16.6913 25.6213 16.1287C25.0587 15.5661 24.2956 15.25 23.5 15.25H20.5C19.7044 15.25 18.9413 15.5661 18.3787 16.1287C17.8161 16.6913 17.5 17.4544 17.5 18.25C17.5 19.0456 17.8161 19.8087 18.3787 20.3713C18.9413 20.9339 19.7044 21.25 20.5 21.25H24.25C25.4431 21.2512 26.587 21.7257 27.4306 22.5694C28.2743 23.413 28.7488 24.5569 28.75 25.75Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default NoteIcon;
