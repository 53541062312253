import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { isQoverClaim } from '../../../Utils';
import { ContactShowOnlyTextField } from '../../ContactTextFieldFormik';
import TextFieldFormik, { ShowOnlyTextField } from '../../TextFieldFormik';

import MailToParagraph from './MailToParagraph';
import PaymentPayeesShowOnly from './PaymentPayeesShowOnly';
import PayToParagraph from './PayToParagraph';

import { useStyles } from '../../../assets/styles';

function VendorExpensePayee({ paymentRequest }) {
  const vendorPayee = paymentRequest.payees.find((payee) => payee.payee_type === 'vendor').contact;

  return (
    <ContactShowOnlyTextField
      contactId={vendorPayee.id}
      contactDisplayName={vendorPayee.full_name}
      label="Vendor"
      showOnly
    />
  );
}

VendorExpensePayee.propTypes = {
  paymentRequest: PropTypes.object.isrequierd,
};

function ExpensesPayeesFormikShowOnly(props) {
  const { claim, paymentRequest } = props;
  const { shouldUseMail, shouldUseIbanBankTransfer, expensePaymentTypes, isOneIncPayment } = usePaymentsConfiguration(
    paymentRequest.payment_method
  );
  const shouldUsePaymentRequestStoreForExpenses = isOneIncPayment;
  const { values } = useFormikContext();
  const expenseType = values.expense_type;
  const classes = useStyles();

  return (
    <div>
      <ShowOnlyTextField
        classes={classes}
        showOnlyValueComponent={expenseType ? expensePaymentTypes[expenseType]?.desc || expenseType : ''}
        label="Payment Type"
      />
      {shouldUsePaymentRequestStoreForExpenses ? (
        <PaymentPayeesShowOnly paymentRequest={paymentRequest} />
      ) : (
        <VendorExpensePayee paymentRequest={paymentRequest} />
      )}

      <PayToParagraph paymentRequest={paymentRequest} />

      {shouldUseIbanBankTransfer && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextFieldFormik id="iban" label="IBAN" className={classes.textFieldRow} showOnly fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik id="reference" label="Reference" className={classes.textFieldRow} showOnly fullWidth />
          </Grid>
        </Grid>
      )}

      {!isQoverClaim(claim) && (
        <TextFieldFormik id="invoice_number" label="Invoice #" fullWidth showOnly className={classes.textField} />
      )}

      {shouldUseMail && <MailToParagraph paymentRequest={paymentRequest} />}
    </div>
  );
}

ExpensesPayeesFormikShowOnly.propTypes = {
  claim: PropTypes.object.isRequired,
  paymentRequest: PropTypes.object.isRequired,
};

export default ExpensesPayeesFormikShowOnly;
