import React from 'react';

import { PartyType } from '../../types';

import { PartiesCard } from './Parties/PartiesCard';
import { AlternativeDisputeResolutionCard } from './AlternativeDisputeResolutionCard';
import { JurisdictionCard } from './JurisdictionCard';
import { LawsuitDetailsCard } from './LawsuitDetailsCard';
import { SummaryCard } from './SummaryCard';

export const InfoTab: React.FC = () => {
  return (
    <>
      <div className="flex w-1/2 flex-col gap-24">
        <SummaryCard />
        <LawsuitDetailsCard />
        <JurisdictionCard />
        <AlternativeDisputeResolutionCard />
      </div>
      <div className="flex w-1/2 flex-col gap-24">
        <PartiesCard partyType={PartyType.PLAINTIFF} />
        <PartiesCard partyType={PartyType.DEFENDANT} />
      </div>
    </>
  );
};
