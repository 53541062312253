import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import BulletListIcon from '../../../../icons/editor/BulletListIcon';
import ToolbarButton from '../ToolbarButton';

const BulletList: React.FC = () => {
  const { editor } = useCurrentEditor();

  const toggle = () => {
    editor?.chain().focus().toggleBulletList().run();
  };

  return (
    <ToolbarButton
      onClick={toggle}
      disabled={!editor?.can().toggleBulletList()}
      isActive={editor?.isActive('bulletList')}
    >
      <BulletListIcon />
    </ToolbarButton>
  );
};

export default BulletList;
