import React from 'react';

import cn from '~/Utils/cn';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import chatBackground from '../../../../assets/ChatBG.jpeg';

export const AiMessageContainer: React.FC<{ className: string }> = ({ children, className }) => {
  return (
    <div
      className={cn(className, 'bg-cover bg-center bg-no-repeat')}
      style={{
        backgroundImage: `url('${chatBackground}')`,
        backgroundBlendMode: 'soft-light, normal',
        backgroundColor: '#f8f8fef7',
      }}
    >
      <div className={cn('__HEADER_OVERLAY_1 h-full w-full', className)}>{children}</div>
    </div>
  );
};
