import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as Yup from 'yup';

import { COUNTRY_TO_STATE_MAP } from '../../Types';

const CustomFieldsContext = React.createContext({});

function CustomFieldsContextProvider(props) {
  const { children, customFieldsConfigurations } = props;

  const contextValues = {
    customFieldsConfigurations,
    additionalDataValidations: getAdditionalDataValidations(customFieldsConfigurations),
  };

  return <CustomFieldsContext.Provider value={contextValues}>{children}</CustomFieldsContext.Provider>;
}

const createStringValidation = (field) =>
  field.mandatory
    ? Yup.string()
        .transform((value) => value?.trim())
        .test('notEmpty', 'Required', (value) => !!value)
    : Yup.string().nullable();

const getAdditionalDataValidations = (customFieldsConfigurations) => {
  return _.chain(customFieldsConfigurations)
    .mapKeys((field) => field.id)
    .mapValues((field) => {
      switch (field.type) {
        case 'string':
          return createStringValidation(field);
        case 'multiline_string':
          return createStringValidation(field);
        case 'select':
          return field.mandatory
            ? Yup.string()
                .oneOf(field.options.map((o) => o.id))
                .required('Required')
            : Yup.string()
                .nullable()
                .oneOf([...field.options.map((o) => o.id), null]); // @see https://github.com/jquense/yup/issues/104
        case 'multiselect':
          return field.mandatory
            ? Yup.array()
                .of(Yup.string().oneOf(field.options.map((o) => o.id)))
                .min(1, 'Required')
            : Yup.array()
                .nullable()
                .of(Yup.string().oneOf([...field.options.map((o) => o.id), null]));
        case 'us_state_select':
          return field.mandatory
            ? Yup.string().oneOf(Object.keys(COUNTRY_TO_STATE_MAP['US'])).required('Required')
            : Yup.string()
                .nullable()
                .oneOf([...Object.keys(COUNTRY_TO_STATE_MAP['US']), null]); // @see https://github.com/jquense/yup/issues/104
        case 'date': {
          return field.mandatory
            ? field?.disableFuture
              ? Yup.date().nullable().max(new Date()).required('Required')
              : Yup.date().nullable().required('Required')
            : field?.disableFuture
            ? Yup.date().nullable().max(new Date())
            : Yup.date().nullable();
        }
        case 'year': {
          const yearValidator = (year) => year >= 1920 && year <= new Date().getFullYear() + 1;

          return field.mandatory
            ? Yup.mixed().test('is-valid', 'Valid year is required', yearValidator)
            : Yup.mixed()
                .nullable()
                .test(
                  'is-valid',
                  'Invalid year',
                  (year) => year === '' || year === undefined || year === null || yearValidator(year)
                );
        }
        case 'time': {
          const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/;
          return field.mandatory
            ? Yup.string().matches(regex, 'Invalid Time Format').required('Required')
            : Yup.string().nullable().matches(regex, 'Invalid Time Format');
        }
        case 'number': {
          return field.mandatory ? Yup.number().required('Required') : Yup.number().nullable();
        }
        case 'monetary': {
          return field.mandatory ? Yup.number().required('Required') : Yup.number().nullable();
        }
        case 'boolean':
          return field.mandatory ? Yup.bool().required('Required') : Yup.bool().nullable();
        case 'contact':
          return field.mandatory ? Yup.number().required('Required') : Yup.number().nullable();
        case 'yes_no_na_unknown': {
          const options = ['yes', 'no'];
          if (!field?.disable_na) options.push('na');
          if (!field?.disable_unknown) options.push('unknown');

          return field.mandatory
            ? Yup.string().nullable().oneOf(options).required('Required')
            : Yup.string()
                .nullable()
                .oneOf([...options, null]);
        }
        case 'location':
          return field.mandatory
            ? Yup.object().test(
                'location-validation',
                'Required',
                (value) => value?.country?.length > 0 && value?.city?.length > 0
              )
            : undefined;
        case 'pre_defined_fields':
          return;
        case 'pre_defined_fields_array':
          return;
        case 'make_ncic_code':
          return field.mandatory ? Yup.string().required('Required') : Yup.string();
        default:
          throw Error(`Unknown custom field type: ${field.type} - field id: ${field.id}`);
      }
    })
    .value();
};

CustomFieldsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  customFieldsConfigurations: PropTypes.array.isRequired,
};

const withCustomFields = (WrappedComponent) => {
  class withCustomFields extends React.Component {
    render() {
      return (
        <CustomFieldsContext.Consumer>
          {(customFieldsContext) => <WrappedComponent {...customFieldsContext} {...this.props} />}
        </CustomFieldsContext.Consumer>
      );
    }
  }

  withCustomFields.displayName = `withCustomFields(${getDisplayName(WrappedComponent)})`;
  return withCustomFields;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function useCustomFields() {
  return useContext(CustomFieldsContext);
}

export default useCustomFields;
export { CustomFieldsContextProvider, getAdditionalDataValidations, withCustomFields };
