import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const AlienIcon = (props) => (
  <SvgIcon fill="none" stroke="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M17 11C17 12.1046 15.6046 13.5 14.5 13.5C13.3954 13.5 13.5007 13.599 13.5007 12.4945C13.5007 11.3899 14.8962 9.99448 16.0007 9.99448C17.1053 9.99448 17 9.89543 17 11Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M8.00586 9.99805C9.11043 9.99805 10.5059 11.3935 10.5059 12.498C10.5059 13.6026 10.6049 13.4973 9.50034 13.4973C8.39577 13.4973 7.00034 12.1019 7.00034 10.9973C7.00034 9.89273 6.90129 9.99805 8.00586 9.99805Z"
      fill={colors.lobIconSecondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C10.0109 3 8.10322 3.79018 6.6967 5.1967C5.29018 6.60322 4.5 8.51088 4.5 10.5C4.5 12.5418 5.70336 15.1949 7.34932 17.3827C8.16211 18.463 9.05301 19.389 9.90079 20.0371C10.7726 20.7035 11.4991 21 12 21C12.5009 21 13.2274 20.7035 14.0992 20.0371C14.947 19.389 15.8379 18.463 16.6507 17.3827C18.2966 15.1949 19.5 12.5418 19.5 10.5C19.5 8.51088 18.7098 6.60322 17.3033 5.1967C15.8968 3.79018 13.9891 3 12 3ZM5.63604 4.13604C7.32387 2.44821 9.61305 1.5 12 1.5C14.3869 1.5 16.6761 2.44821 18.364 4.13604C20.0518 5.82387 21 8.11305 21 10.5C21 13.0145 19.5784 15.9863 17.8493 18.2845C16.9746 19.4471 15.9905 20.4794 15.0102 21.2288C14.0538 21.9598 12.9991 22.5 12 22.5C11.0009 22.5 9.94619 21.9598 8.98983 21.2288C8.00949 20.4794 7.02539 19.4471 6.15068 18.2845C4.42164 15.9863 3 13.0145 3 10.5C3 8.11305 3.94821 5.82387 5.63604 4.13604Z"
      fill={colors.lobIconMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8107 14.1857C10.5294 14.467 10.1478 14.625 9.75 14.625C8.75544 14.625 7.80161 14.2299 7.09835 13.5267C6.39509 12.8234 6 11.8696 6 10.875V10.5C6 10.1022 6.15804 9.72064 6.43934 9.43934C6.72065 9.15803 7.10218 9 7.5 9C8.49456 9 9.44839 9.39509 10.1517 10.0984C10.8549 10.8016 11.25 11.7554 11.25 12.75V13.125C11.25 13.5228 11.092 13.9044 10.8107 14.1857ZM8.15901 12.466C8.58097 12.8879 9.15326 13.125 9.75 13.125V12.75C9.75 12.1533 9.51295 11.581 9.09099 11.159C8.66903 10.7371 8.09674 10.5 7.5 10.5V10.875C7.5 11.4717 7.73705 12.044 8.15901 12.466Z"
      fill={colors.lobIconMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 10.5C15.9033 10.5 15.331 10.7371 14.909 11.159C14.4871 11.581 14.25 12.1533 14.25 12.75L14.25 13.125C14.8467 13.125 15.419 12.8879 15.841 12.466C16.2629 12.044 16.5 11.4717 16.5 10.875V10.5ZM13.8484 10.0983C14.5516 9.39509 15.5054 9 16.5 9C16.8978 9 17.2794 9.15804 17.5607 9.43934C17.842 9.72065 18 10.1022 18 10.5V10.875C18 11.8696 17.6049 12.8234 16.9016 13.5266C16.1984 14.2299 15.2446 14.625 14.25 14.625C13.8522 14.625 13.4706 14.467 13.1893 14.1857C12.908 13.9044 12.75 13.5228 12.75 13.125V12.75C12.75 11.7554 13.1451 10.8016 13.8484 10.0983Z"
      fill={colors.lobIconMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 17.25C9.75 16.8358 10.0858 16.5 10.5 16.5H13.5C13.9142 16.5 14.25 16.8358 14.25 17.25C14.25 17.6642 13.9142 18 13.5 18H10.5C10.0858 18 9.75 17.6642 9.75 17.25Z"
      fill={colors.lobIconMain}
    />
  </SvgIcon>
);

export default AlienIcon;
