import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const GeneralNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      d="M29.5 13H14.5C14.1022 13 13.7206 13.158 13.4393 13.4393C13.158 13.7206 13 14.1022 13 14.5V29.5C13 29.8978 13.158 30.2794 13.4393 30.5607C13.7206 30.842 14.1022 31 14.5 31H24.6897C24.8867 31.0006 25.082 30.9621 25.264 30.8866C25.446 30.8111 25.6112 30.7002 25.75 30.5603L30.5603 25.75C30.7002 25.6112 30.8111 25.446 30.8866 25.264C30.9621 25.082 31.0006 24.8867 31 24.6897V14.5C31 14.1022 30.842 13.7206 30.5607 13.4393C30.2794 13.158 29.8978 13 29.5 13ZM14.5 14.5H29.5V24.25H25C24.8011 24.25 24.6103 24.329 24.4697 24.4697C24.329 24.6103 24.25 24.8011 24.25 25V29.5H14.5V14.5ZM28.4397 25.75L25.75 28.4397V25.75H28.4397Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default GeneralNoteIcon;
