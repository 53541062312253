import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BlockIcon from '@material-ui/icons/Block';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import axios from 'axios';
import * as Twilio from 'twilio-client';

import { useStyles } from '~/assets/styles';
import CommunicationCardHeader from '~/components/communications/CommunicationCard/CommunicationCardHeader';
import { channelTypeToIconSettings } from '~/components/communications/CommunicationCard/constants';
import { displayRoleName } from '~/components/communications/ContactUtils';
import IncomingCallInProgressContainer from '~/components/communications/PhoneCall/IncomingCallInProgressContainer';
import OutgoingCallInProgressContainer from '~/components/communications/PhoneCall/OutgoingCallInProgressContainer';
import { ContactEntity } from '~/components/Contact';
import { Text } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import useOrganization from '~/components/OrganizationContext';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError, reportErrorInProductionOrThrow, reportTwilioError } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../CardDialog';
import ClaimLink from '../ClaimLink';
import mixpanel from '../CmsMain/mixpanel';
import { useCms } from '../hooks/useCms';
import { PhoneIcon } from '../icons/notifications';

import { NewPhoneCallCommunicationCard } from './PhoneCallCommunicationCard';

const TWILIO_ERRORS_TO_IGNORE = [31205, 31009, 31204, 31003, 31005, 31206, 31000];

function IncomingCallRecognizedDialog(props) {
  const { connection, onAcceptCall, onAcceptCallInSameTab, onRejectCall, callCanceled, onClose } = props;

  const classes = useStyles();
  const [showReturnPhoneCallCommunicationCard, setShowReturnPhoneCallCommunicationCard] = useState(false);
  const { organizationContactRolesDict } = useOrganization();
  const { userOrganization } = useCms();

  let claimId;
  let claimIdDisplay;
  let contactId;
  let contactFullName;
  let contactRole;
  let isCallCenterCall = false;

  if (connection.customParameters.has('is_call_center_call')) {
    isCallCenterCall = connection.customParameters.get('is_call_center_call') === 'true';
  }

  if (connection.customParameters.has('claim_id')) {
    // TODO: consider getting the communication instead of the params
    claimId = Number(connection.customParameters.get('claim_id'));
    claimIdDisplay = connection.customParameters.get('claim_id_display');
  }

  if (connection.customParameters.has('contact_id')) {
    contactId = Number(connection.customParameters.get('contact_id'));
    contactFullName = connection.customParameters.get('contact_full_name');
    contactRole = connection.customParameters.get('contact_role');
  }

  let dialogTitle = 'Incoming Phone Call';
  const iconSettings = channelTypeToIconSettings['phone_call']['Incoming'];

  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);

  return (
    <>
      <CardDialog title={dialogTitle} maxWidth="xs" fullWidth isDialog>
        {isNewUIEnabled ? (
          <>
            <div className="flex items-center justify-center">
              <div
                className={cn(
                  'box-content rounded p-2 text-3xl',
                  iconSettings.iconClassName,
                  iconSettings.textColor,
                  iconSettings.bgColor
                )}
              />
            </div>
            {contactId ? (
              <div className="flex items-center justify-center">
                <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} className="flex">
                  <ContactEntity classes={classes} contactId={contactId} contactDisplayName={contactFullName} />
                  <span style={{ marginLeft: '16px' }}>
                    <em>{displayRoleName(organizationContactRolesDict, contactRole)}</em>
                  </span>
                </Text>
              </div>
            ) : null}
          </>
        ) : (
          <CommunicationCardHeader
            channel="phone_call"
            direction="Incoming"
            contactId={contactId}
            contactFullName={contactFullName}
            contactRole={contactRole}
          />
        )}
        <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography display="block" variant="subtitle2">
            {isCallCenterCall ? connection.customParameters.get('from_number') : connection.parameters.From}
          </Typography>
        </div>
        <br />
        {isCallCenterCall && (
          <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Typography display="block" variant="subtitle2">
              {connection.customParameters.get('queue')} Queue Call
            </Typography>
          </div>
        )}
        {claimId && (
          <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <ClaimLink claimId={claimId} linkText={claimIdDisplay} openInNewTab />
          </div>
        )}
        <br />
        {callCanceled ? (
          <>
            <Typography display="block" align="center" variant="subtitle2" style={{ color: '#e65100' }}>
              No answer. Call Ended
            </Typography>
            <br />
            <div className={classes.container} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              {!isCallCenterCall && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setShowReturnPhoneCallCommunicationCard(true)}
                  disabled={connection.status() !== 'closed'}
                >
                  <PhoneIcon iconColor="white" className={classes.leftButtonIcon} />
                  Call Back
                </Button>
              )}
              <Button size="small" variant="contained" color="secondary" onClick={() => onClose()}>
                Close
              </Button>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center space-y-32">
            <>
              <div>
                {onAcceptCallInSameTab && (
                  <Button
                    className="w-[300px]"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => onAcceptCallInSameTab(connection)}
                    disabled={connection.status() !== 'pending'}
                  >
                    <PhoneIcon iconColor="white" className={classes.leftButtonIcon} />
                    Accept
                  </Button>
                )}
              </div>
              <Button
                className="w-[300px]"
                size="small"
                variant={onAcceptCallInSameTab ? 'outlined' : 'contained'}
                color="primary"
                onClick={() => onAcceptCall(connection)}
                disabled={connection.status() !== 'pending'}
              >
                <PhoneIcon iconColor={onAcceptCallInSameTab ? undefined : 'white'} className={classes.leftButtonIcon} />
                {onAcceptCallInSameTab ? (
                  <>
                    Accept And open in a new tab
                    <OpenInNewIcon fontSize="small" className={classes.rightButtonIcon} />
                  </>
                ) : (
                  'Accept'
                )}
              </Button>
              <div className="relative w-full">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-1 border-solid border-slate-600 px-2" />
                </div>
                <div className="relative flex justify-center">
                  <Text className="bg-white px-8" variant="small">
                    Or
                  </Text>
                </div>
              </div>
            </>

            <div>
              <DeclineCallButton
                onDeclineCall={() => onRejectCall(connection)}
                disabled={connection.status() !== 'pending'}
                isQueueCall={isCallCenterCall}
                shouldDisplay={!callCanceled}
              />
            </div>
          </div>
        )}
      </CardDialog>

      {showReturnPhoneCallCommunicationCard && (
        <NewPhoneCallCommunicationCard
          onCancel={() => setShowReturnPhoneCallCommunicationCard(false)}
          onNewPhoneCallCreated={() => setShowReturnPhoneCallCommunicationCard(false)}
          isNotClaimRelated
          notClaimRelatedCallPhoneNumber={connection.parameters.From}
        />
      )}
    </>
  );
}

IncomingCallRecognizedDialog.propTypes = {
  connection: PropTypes.object.isRequired,
  callCanceled: PropTypes.bool,
  onAcceptCall: PropTypes.func.isRequired,
  onAcceptCallInSameTab: PropTypes.func.isRequired,
  onRejectCall: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const DeclineCallButton = ({ onDeclineCall, isQueueCall, disabled, shouldDisplay }) => {
  if (!shouldDisplay) {
    return null;
  }

  return (
    <Button
      startIcon={<BlockIcon />}
      className="w-[300px]"
      size="small"
      variant="text"
      color="secondary"
      onClick={onDeclineCall}
      disabled={disabled}
    >
      {isQueueCall ? 'Decline and mark not available' : 'Decline'}
    </Button>
  );
};

DeclineCallButton.propTypes = {
  onDeclineCall: PropTypes.func.isRequired,
  isQueueCall: PropTypes.bool,
  disabled: PropTypes.bool,
  shouldDisplay: PropTypes.bool,
};

function CmsCallCenter() {
  const deviceRef = React.useRef(undefined);
  const [callConnection, setCallConnection] = React.useState(undefined);
  const [callStatus, setCallStatus] = React.useState(undefined);
  const [callCanceled, setCallCanceled] = React.useState(false);

  const { userOrganization, callInProgress, setCallInProgress, userReLogin } = useCms();

  const handleCallAccepted = (connection) => {
    // TODO: Report to backend - should not happen
    setCallStatus(connection.status());
  };

  const handleIncomingCall = (connection) => {
    setCallConnection(connection);

    if (!connection.customParameters.has('communication_id')) {
      connection.ignore();
      // TODO: Report to the backend
      return;
    }

    setCallStatus(connection.status());
    setCallCanceled(false);
  };

  const handleDisconnect = (connection) => {
    // This should not happen
    // TODO: report to backend?
    setCallStatus(connection.status());
    setCallConnection(undefined);
  };

  async function setupDevice(device) {
    try {
      const res = await axios.get('/api/v1/calls/twilio_access_token_io');
      const token = res.data;
      device.setup(token);
    } catch (error) {
      // Just 'random' 'something went wrong' error won't help the adjuster
      reportAxiosError(error, false);
    }
  }

  function acceptCall(callConnection, answerInSameTab = false) {
    const parentCallSid = callConnection.customParameters.get('ParentCallSid');
    const communicationId = callConnection.customParameters.get('communication_id');
    const claimId = callConnection.customParameters.get('claim_id');
    const currCallSid = callConnection.parameters.CallSid;

    if (answerInSameTab) {
      let newCallInProgress = {
        callSid: parentCallSid,
        currCallSid,
        communicationId,
        direction: 'Incoming',
      };

      if (claimId) {
        newCallInProgress['claimId'] = claimId;
      }

      setCallInProgress(newCallInProgress);
    } else {
      let acceptCallUrl = `/call_in_progress?callSid=${parentCallSid}&currCallSid=${currCallSid}&communicationId=${communicationId}`;

      if (claimId !== undefined) {
        acceptCallUrl += `&claimId=${claimId}`;
      }
      window.open(acceptCallUrl, '_blank');
    }

    callConnection.ignore();
    setCallConnection(undefined);
  }

  function acceptCallInSameTab(callConnection) {
    return acceptCall(callConnection, true);
  }

  function rejectCall(callConnection) {
    callConnection.reject();
    setCallConnection(undefined);
    mixpanel.track(MIXPANEL_EVENTS.INCOMING_PHONE_CALL_COMMUNICATION_DECLINED, {
      communicationId: callConnection.customParameters.get('communication_id'),
    });
    setTimeout(userReLogin, 5000); // Update the status in case it's becoming unavailable
  }

  async function handleCancel(connection) {
    setCallStatus(connection.status());
    try {
      const parentCallStatusRes = await axios.get(
        `/api/v1/calls/call_status/${connection.customParameters.get('ParentCallSid')}`
      );
      const status = parentCallStatusRes.data.status;
      if (status !== 'in-progress') {
        setCallCanceled(true);
      }
    } catch (error) {
      reportAxiosError(error);
    }
  }

  React.useEffect(() => {
    async function handleOffline(device) {
      if (!deviceRef.current) {
        // TODO: This shouldn't happen - may happen if we failed to setup the device
        // If we will try to setup the device again we may enter to infinite loop
        return;
      }

      deviceRef.current = undefined;
      await setupDevice(device);
    }

    const initializeCallCenter = async () => {
      const device = new Twilio.Device();

      await setupDevice(device);

      device.on('ready', (device) => (deviceRef.current = device));
      device.on('offline', handleOffline); // offline might happen due to token expiration
      device.on('cancel', handleCancel);
      device.on('incoming', handleIncomingCall);
      device.on('connect', handleCallAccepted);
      device.on('disconnect', handleDisconnect);
      device.on('error', function (error) {
        if (TWILIO_ERRORS_TO_IGNORE.includes(error.code)) {
          return;
        }

        reportTwilioError(error);
      });
    };

    initializeCallCenter();

    return () => {
      const device = deviceRef.current;
      deviceRef.current = undefined; // We don't want to reinit the device in handleOffline
      if (device) {
        device.destroy();
      }
    };
  }, []);

  if (callInProgress) {
    return <ActiveCallContainer />;
  }

  if (callConnection) {
    if (callStatus === 'pending' || (callStatus === 'closed' && callCanceled)) {
      return (
        <IncomingCallRecognizedDialog
          connection={callConnection}
          onAcceptCall={acceptCall}
          onAcceptCallInSameTab={
            isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PERFORMANCE_ENABLE_ANSWER_CALL_IN_SAME_TAB)
              ? acceptCallInSameTab
              : null
          }
          onRejectCall={rejectCall}
          onClose={() => setCallConnection(undefined)}
          callCanceled={callCanceled}
        />
      );
    }
  }

  return <></>;
}

function ActiveCallContainer() {
  const { callInProgress } = useCms();

  if (!callInProgress) {
    reportErrorInProductionOrThrow();
    return <></>;
  }

  if (callInProgress.direction === 'Incoming') {
    return <IncomingCallInProgressContainer />;
  } else if (callInProgress.direction === 'Outgoing') {
    return <OutgoingCallInProgressContainer />;
  } else {
    reportErrorInProductionOrThrow();
    return <></>;
  }
}

export default CmsCallCenter;
export { TWILIO_ERRORS_TO_IGNORE };
