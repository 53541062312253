import React from 'react';
import { BubbleMenu, useCurrentEditor } from '@tiptap/react';

import Button from '~/components/core/Atomic/Buttons/Button';
import { PencilIcon } from '~/components/icons';

import { useLinkModal } from './LinkModalContext';

const LinkBubbleMenu: React.FC = () => {
  const { editor } = useCurrentEditor();
  const { openModal } = useLinkModal();

  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu
      className="shadow-lg"
      editor={editor}
      shouldShow={({ editor, from, to }) => {
        // only show the bubble menu for links.
        return from === to && editor.isActive('link');
      }}
    >
      <Button onClick={openModal} variant="contained" color="primary">
        <PencilIcon iconColor="white" />
      </Button>
    </BubbleMenu>
  );
};

export default LinkBubbleMenu;
