import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { cleanEmptyValues } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';

import { useStyles } from '../../../../../assets/styles';

const EditGenericItems = ({
  onSaveItemDetails,
  onEditCancel,
  dialogFields,
  item,
  itemsValidationFields,
  ItemsFragment,
  itemName = 'item',
}) => {
  const classes = useStyles();

  const itemsInitialValues = {};
  dialogFields.map((key) => {
    itemsInitialValues[key] = item[key] || null;
  });

  return (
    <Formik
      initialValues={itemsInitialValues}
      validationSchema={Yup.object().shape(itemsValidationFields)}
      onSubmit={async (values, formikProps) => {
        try {
          await onSaveItemDetails(cleanEmptyValues(values));
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <>
            <CardDialog isDialog={true} title={`Edit ${itemName}`} outlinedCard>
              {ItemsFragment}
              <div className={classes.buttonsContainer}>
                <>
                  <CancelButton disabled={isSubmitting} onClick={onEditCancel} />
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Save
                  </Button>
                </>
              </div>
            </CardDialog>
          </>
        );
      }}
    </Formik>
  );
};

EditGenericItems.propTypes = {
  onSaveItemDetails: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  ItemsFragment: PropTypes.func,
  dialogFields: PropTypes.array,
  item: PropTypes.object,
  itemsValidationFields: PropTypes.object,
  itemName: PropTypes.string,
};

const AddGenericItems = ({
  onAddItem,
  onCancel,
  ItemsFragment,
  itemsInitialValues,
  itemsValidationFields,
  itemName = 'item',
}) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={itemsInitialValues}
      validationSchema={Yup.object().shape(itemsValidationFields)}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        try {
          await onAddItem(cleanEmptyValues(values));
          resetForm();
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <>
            <CardDialog isDialog={true} title={`Add ${itemName}`} outlinedCard>
              {ItemsFragment}
              <div className={classes.buttonsContainer}>
                <>
                  <CancelButton disabled={isSubmitting} onClick={onCancel} />
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Save
                  </Button>
                </>
              </div>
            </CardDialog>
          </>
        );
      }}
    </Formik>
  );
};

AddGenericItems.propTypes = {
  onAddItem: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  ItemsFragment: PropTypes.node,
  itemsInitialValues: PropTypes.object,
  itemsValidationFields: PropTypes.object,
  itemName: PropTypes.string,
};

export { AddGenericItems, EditGenericItems };
