import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../CardDialog';
import { withClaim } from '../ClaimContainer';

import DocumentsContainer from './DocumentsContainer';

function DocumentsScreen(props) {
  const { classes, claim, onAsyncClaimUpdate } = props;

  function handleNewDocumentCreated() {
    onAsyncClaimUpdate();
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <div className={classes.cardDivRow}>
          <CardDialog title="Documents">
            <DocumentsContainer
              classes={classes}
              claim={claim}
              onNewDocumentCreated={handleNewDocumentCreated}
              maxHeight={`${window.innerHeight - 375}px`} // TODO - derive from bars size
              autoPaginateRowsPerPage={25}
            />
          </CardDialog>
        </div>
      </Grid>
    </Grid>
  );
}

DocumentsScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  onAsyncClaimUpdate: PropTypes.func.isRequired,
};

export default withClaim(DocumentsScreen);
