import React from 'react';
import PropTypes from 'prop-types';

import Chip from '~/components/core/Atomic/Chip/Chip';

import { getIsCatSupportedForClaimType } from '../Utils';

import { useStyles } from '../assets/styles';

const IncidentMutualChips = ({ claim }) => {
  const classes = useStyles();
  return getIsCatSupportedForClaimType(claim) && claim.incident.is_cat ? (
    <Chip
      size="small"
      color="primary"
      label={`${claim.incident.cat_name} ${claim.incident.pcs_number ? ' - ' + claim.incident.pcs_number : ''}`}
      className={classes.chip}
    />
  ) : null;
};

IncidentMutualChips.propTypes = {
  claim: PropTypes.object.isRequired,
};

export default IncidentMutualChips;
