const DAMAGE_ASSESSMENT_CATEGORY_CODES_DICT = {
  ACC: 'ACCESSORIES - MOBILE HOME',
  ACT: 'ACOUSTICAL TREATMENTS',
  APS: 'APPLIANCES - SMALL',
  AWN: 'AWNINGS & PATIO COVERS',
  CAB: 'CABINETRY',
  CNC: 'CONCRETE & ASPHALT',
  CON: 'CONTENT MANIPULATION',
  DMO: 'GENERAL DEMOLITION',
  DOR: 'DOORS',
  DRY: 'DRYWALL',
  ELE: 'ELECTRICAL',
  ELS: 'ELECTRICAL - SPECIAL SYSTEMS',
  EQA: 'MISC. EQUIPMENT - AGRICULTURAL',
  EQC: 'MISC. EQUIPMENT - COMMERCIAL',
  EQU: 'HEAVY EQUIPMENT',
  EXC: 'EXCAVATION',
  FCC: 'FLOOR COVERING - CARPET',
  FCR: 'FLOOR COVERING - RESILIENT',
  FCS: 'FLOOR COVERING - STONE',
  FCT: 'FLOOR COVERING - CERAMIC TILE',
  FCV: 'FLOOR COVERING - VINYL',
  FCW: 'FLOOR COVERING - WOOD',
  FEE: 'PERMITS AND FEES',
  FEN: 'FENCING',
  FNC: 'FINISH CARPENTRY / TRIMWORK',
  FNH: 'FINISH HARDWARE',
  FPL: 'FIREPLACES',
  FPS: 'FIRE PROTECTION SYSTEMS',
  FRM: 'FRAMING & ROUGH CARPENTRY',
  FRN: 'FURNITURE - HOME & OFFICE',
  GLS: 'GLASS, GLAZING, & STOREFRONTS',
  HSW: 'HOUSEWARES',
  HVC: 'HEAT, VENT & AIR CONDITIONING',
  INM: 'INSULATION - MECHANICAL',
  INS: 'INSULATION',
  LAB: 'LABOR ONLY',
  LIT: 'LIGHT FIXTURES',
  MAS: 'MASONRY',
  MBL: 'MARBLE - CULTURED OR NATURAL',
  MPR: 'MOISTURE PROTECTION',
  MSD: 'MIRRORS & SHOWER DOORS',
  MSK: 'MOBILE HOMES, SKIRTING & SETUP',
  MTL: 'METAL STRUCTURES & COMPONENTS',
  ORI: 'ORNAMENTAL IRON',
  PLA: 'INTERIOR LATH & PLASTER',
  PLM: 'PLUMBING',
  PNL: 'PANELING & WOOD WALL FINISHES',
  PNT: 'PAINTING',
  POL: 'SWIMMING POOLS & SPAS',
  RFG: 'ROOFING',
  SCF: 'SCAFFOLDING',
  SDG: 'SIDING',
  SFG: 'SOFFIT, FASCIA, & GUTTER',
  SPE: 'SPECIALTY ITEMS',
  STJ: 'STEEL JOIST COMPONENTS',
  STL: 'STEEL COMPONENTS',
  STR: 'STAIRS',
  STU: 'STUCCO & EXTERIOR PLASTER',
  TBA: 'TOILET & BATH ACCESSORIES',
  TIL: 'TILE',
  TMB: 'TIMBER FRAMING',
  TMP: 'TEMPORARY REPAIRS',
  USR: 'USER DEFINED ITEMS',
  WDA: 'WINDOWS - ALUMINUM',
  WDP: 'WINDOWS - SLIDING PATIO DOORS',
  WDR: 'WINDOW REGLAZING & REPAIR',
  WDS: 'WINDOWS - SKYLIGHTS',
  WDT: 'WINDOW TREATMENT',
  WDV: 'WINDOWS - VINYL',
  WDW: 'WINDOWS - WOOD',
  WPR: 'WALLPAPER',
  WTR: 'WATER EXTRACTION & REMEDIATION',
  XST: 'EXTERIOR STRUCTURES',
} as const;

export default DAMAGE_ASSESSMENT_CATEGORY_CODES_DICT;
