import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';

import { PreDefinedField, preparePreDefinedFields } from '../../../../IncidentConfiguration/ConfiguredFields';

import { GenericSectionInnerCard } from './InvolvedGenericFragment';

const LOSS_OBJECT_ID = 'property_damage';

const PropertyLossFragment = ({ involvedParty, preDefinedFields }) => {
  const { values } = useFormikContext();
  const { incidentConfiguration } = useIncidentConfiguration();

  const isAllowedPropertyDamage =
    involvedParty && incidentConfiguration.involved_parties[involvedParty]?.is_allowed_property_damage;
  const propertyDamagePredefinedFields = preparePreDefinedFields(get(preDefinedFields, LOSS_OBJECT_ID, {}));
  const fragmentId = `involved_parties.${involvedParty}.${LOSS_OBJECT_ID}`;
  const sectionTitle = get(incidentConfiguration, `${fragmentId}.desc`, 'Damage Description');
  const isSectionActive = get(incidentConfiguration, `${fragmentId}.active`, true);

  return (
    <GenericSectionInnerCard
      isSectionActive={isSectionActive}
      sectionTitle={sectionTitle}
      isSectionAllowed={isAllowedPropertyDamage}
      preDefinedFields={preDefinedFields}
      enablingFieldId="is_property_damaged"
      enablingValue={values?.is_property_damaged}
      collapsibleTitle="Fill Damage Details"
    >
      <Grid container spacing={1}>
        <PreDefinedField
          id="location"
          fields={propertyDamagePredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="property_damages"
          fields={propertyDamagePredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="is_property_stolen"
          fields={propertyDamagePredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="property_damages_description"
          fields={propertyDamagePredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
        <PreDefinedField
          id="note"
          fields={propertyDamagePredefinedFields}
          inline
          gridXs={12}
          idPrefix={LOSS_OBJECT_ID}
        />
      </Grid>
    </GenericSectionInnerCard>
  );
};

PropertyLossFragment.propTypes = {
  involvedParty: PropTypes.string,
  preDefinedFields: PropTypes.object.isRequired,
};

export { PropertyLossFragment };
