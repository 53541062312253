import React from 'react';
import PropTypes from 'prop-types';

import styles from './RoundEmptyState.module.scss';

const RoundEmptyStateWrapper = ({ children }) => (
  <div className={styles.root}>
    <div className={styles.roundWrapperOuter}>
      <div className={styles.roundWrapperInner}>{children}</div>
    </div>
  </div>
);

RoundEmptyStateWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RoundEmptyStateWrapper;
