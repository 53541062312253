import React from 'react';

import { ErrorIcon } from '~/components/icons';
import cn from '~/Utils/cn';

export interface SkeletonTableProps {
  rowsCount: number;
  columnsCount: number;
  maxHeight?: string;
  isError?: boolean;
  cellClassName?: string;
}

const SkeletonTableCell: React.FC<{ cellClassName?: string }> = ({ cellClassName }) => (
  <div className={cn('flex h-20 w-full animate-pulse px-12', cellClassName)}>
    <div className="h-full w-full rounded-lg bg-slate-400" />
  </div>
);

const SkeletonTable: React.FC<SkeletonTableProps> = ({
  rowsCount,
  columnsCount,
  maxHeight,
  isError = false,
  cellClassName,
}) => {
  const height = maxHeight ? `h-[${maxHeight}]` : 'h-full';

  if (isError) {
    return <ErrorIcon size={30} />;
  }

  return (
    <div className={`w-full p-16 ${height}`} data-testid="skeleton-table">
      <div className="flex w-full justify-around p-16">
        <SkeletonTableCell cellClassName={cellClassName} />
      </div>
      {Array.from({ length: rowsCount }, (_, index) => (
        <div key={index} className="flex w-full justify-around p-16">
          {Array.from({ length: columnsCount }, (_, columnIndex) => (
            <SkeletonTableCell key={columnIndex} cellClassName={cellClassName} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SkeletonTable;
