const PET_PERILS_DICT = {
  accident: {
    desc: 'Accident',
  },
  illness: {
    desc: 'Illness',
  },
  wellness: {
    desc: 'Wellness',
  },
  injury: {
    desc: 'Injury',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default PET_PERILS_DICT;
