import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent } from '@material-ui/core';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { isUserAdjuster, isUserDaSpecialist, isUserFiveSigma, isUserPhoneRep, isUserReadOnly } from '~/UserUtils';
import { isFeatureEnabled, isProductionEnv } from '~/Utils';

import { CONFIGURATION_FEATURES_NAMES, CONSTANTS } from '../Types';

import { isActionAllowed, isPermissionsEnabled } from './core/Permissions/PermissionUtils';
import SkeletonTable from './core/Skeletons/SkeletonTable';
import { PAGINATION_LOCATION } from './core/Tables/SortableTable/utils';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import ClaimSearchContainer from './ClaimSearch';
import ClaimsTable from './ClaimsTable';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsRenderWrapper } from './core';
import DaSpecialistContainer from './DaSpecialistContainer';
import useDataFetcher from './useDataFetcher';
import UserClaimsNotificationsContainer from './UserClaimsNotificationsContainer';

function HomeScreenUserRecentClaimsContainer() {
  const { user, userOrganization, userProfile } = useCms();
  const permissionsEnabled = isPermissionsEnabled(userOrganization);

  const shouldPossibleSeeClaims =
    !isUserFiveSigma(user) && (permissionsEnabled || isUserAdjuster(user) || isUserReadOnly(user));
  if (!shouldPossibleSeeClaims) {
    return null;
  }

  const userCanAccessClaims = isActionAllowed({
    action: PERMISSION_ACTIONS.CLAIM,
    verb: PERMISSION_VERBS.READ,
    profile: userProfile,
    userOrganization,
  });

  if (!userCanAccessClaims) {
    return null;
  }

  return <VerifiedUserRecentClaimsContainer />;
}

function VerifiedUserRecentClaimsContainer() {
  const classes = useStyles();
  const {
    isLoading,
    isError,
    data: { claims } = { claims: [] },
  } = useDataFetcher('/api/v1/claims', {
    params: { rows_per_page: CONSTANTS.DEFAULT_CLAIMS_PER_PAGE, touched_only: true },
  });

  return (
    <PermissionsRenderWrapper action={PERMISSION_ACTIONS.CLAIM} verb={PERMISSION_VERBS.READ}>
      <div className={classes.cardDivRow}>
        <CardDialog
          title="Recent Claims"
          action={
            <div style={{ paddingRight: '10px' }} className={classes.buttonsContainer}>
              <Link className={classes.primaryLink} to="/claims">
                See all claims
              </Link>
            </div>
          }
        >
          {isLoading ? (
            <SkeletonTable rowsCount={4} columnsCount={7} maxHeight="45vh" isError={isError} />
          ) : (
            <ClaimsTable claims={claims} maxHeight="45vh" hideReportedDate trackingInfo="recent_claims_link_clicked" />
          )}
        </CardDialog>
      </div>
    </PermissionsRenderWrapper>
  );
}

function HomeScreenContainer() {
  const classes = useStyles();

  const { user, userOrganization, setPageTitle } = useCms();
  useEffect(() => {
    setPageTitle('Overview', 'Home — Five Sigma CMS');
  }, [setPageTitle]);

  const permissionsEnabled = isPermissionsEnabled(userOrganization);

  if (isUserFiveSigma(user)) {
    return (
      <Typography variant="h4">{`Please ${
        isProductionEnv() ? 'view as' : 'impersonate'
      } to use the system `}</Typography>
    );
  }

  // Should be before the isUserAdjuster check, daSpecialist is adjuster as well
  if (
    isUserDaSpecialist(user) &&
    !isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FIELD_INSPECTION_QUEUE)
  ) {
    return <DaSpecialistContainer />;
  }

  if (permissionsEnabled || isUserAdjuster(user) || isUserReadOnly(user)) {
    return (
      <>
        <PermissionsRenderWrapper action={PERMISSION_ACTIONS.NOTIFICATION} verb={PERMISSION_VERBS.READ}>
          <div className={classes.cardDivRow}>
            <UserClaimsNotificationsContainer
              maxHeight="35vh"
              showOpenInFullScreen
              paginationLocation={PAGINATION_LOCATION.BOTTOM}
            />
          </div>
        </PermissionsRenderWrapper>
        <HomeScreenUserRecentClaimsContainer />
      </>
    );
  }

  if (!permissionsEnabled && isUserPhoneRep(user)) {
    return (
      <>
        <Grid container alignItems="stretch">
          <Grid item md={6}>
            <div className={classes.cardDivRow}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item md={3} />
                    <Grid item md={6}>
                      <Button fullWidth size="large" variant="outlined" color="primary" href="/fnol">
                        <AddIcon className={classes.rightButtonIcon} />
                        Fnol
                      </Button>
                    </Grid>
                    <Grid item md={3} />
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
          <Grid item md={6} />
          <Grid item xs={12} md={6}>
            <div className={classes.cardDivRow}>
              <ClaimSearchContainer classes={classes} />
            </div>
          </Grid>
        </Grid>
        <HomeScreenUserRecentClaimsContainer />
      </>
    );
  }
}

export default HomeScreenContainer;
