import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const GLIcon = (props) => (
  <SvgIcon stroke="none" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00879 11.7694C4.28193 11.4342 4.63215 11.1531 5.04889 10.9557C6.62661 10.208 7.53553 9.26851 8.09546 8.20577C8.68349 7.08969 9.00023 5.61743 9.00023 3.66667C9.00023 3.43754 9.02592 3.21441 9.07457 3H13.0002C13.1328 3 13.26 3.05268 13.3538 3.14645C13.4476 3.24021 13.5002 3.36739 13.5002 3.5V7.16875C13.5002 12.4187 9.04398 14.1562 8.15648 14.45C8.05566 14.4873 7.9448 14.4873 7.84398 14.45C7.29618 14.2687 5.38872 13.5373 4.00879 11.7694Z"
      fill={colors.lobIconSecondary}
    />
    <path
      opacity="0.2"
      d="M8 13.975C7.13735 13.6893 3 12.0601 3 7.16875L3 3.5L13 3.5V7.16875C13 12.0601 8.86266 13.6893 8 13.975Z"
      stroke={colors.lobIconMain}
    />
    <path
      d="M2.5 7.16875V3.5C2.5 3.36739 2.55268 3.24021 2.64645 3.14645C2.74021 3.05268 2.86739 3 3 3H13C13.1326 3 13.2598 3.05268 13.3536 3.14645C13.4473 3.24021 13.5 3.36739 13.5 3.5V7.16875C13.5 12.4187 9.04375 14.1562 8.15625 14.45C8.05543 14.4873 7.94457 14.4873 7.84375 14.45C6.95625 14.1562 2.5 12.4187 2.5 7.16875Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default GLIcon;
