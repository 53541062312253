import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import cn from '~/Utils/cn';

import { SortableTable } from '../../../../core';
import { useCurrencyFormatter } from '../../../../CurrencyFormatterContext';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { PencilIcon, TrashIcon } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';

import {
  AddInvolvedPropertyItems,
  EditInvolvedPropertyItems,
  getInvolvedPropertyDefinedFields,
} from './InvolvedPropertyItemsDialog';

import { useStyles } from '../../../../../assets/styles';
import styles from './involvedPropertyParty.module.scss';

const DEFAULT_ID = 'property_items';
const DEFAULT_CONFIG_ID = 'involved_parties.involved_generic_property.property_items';

const InvolvedPropertyItems = ({ propertyItemsId = DEFAULT_ID, propertyItemsConfigPath = DEFAULT_CONFIG_ID }) => {
  const [openAddItemDialog, setOpenAddItemDialog] = useState(false);
  const [openEditItemDialog, setOpenEditItemDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { currencyFormatter } = useCurrencyFormatter();
  const { incidentConfiguration } = useIncidentConfiguration();
  const involvedPropertyDefinedFields = getInvolvedPropertyDefinedFields(
    incidentConfiguration,
    propertyItemsConfigPath
  );

  const actionsCell = ({ onDelete }) => ({
    id: 'actions',
    numeric: false,
    label: 'Actions',
    specialCell: (item) => (
      <div>
        <InlineIconButton
          icon={PencilIcon}
          tooltipTitle="Edit"
          className={cn(classes.textIcon, classes.marginedIcon, classes.hoverableNonFilledIcon)}
          onClick={() => {
            setCurrentItem(item);
            setOpenEditItemDialog(item);
          }}
          wrapWithSpan
          iconStyle={{ width: 16, height: 16 }}
        />
        {onDelete && (
          <InlineIconButton
            icon={TrashIcon}
            tooltipTitle="Delete"
            className={cn(classes.textIcon, classes.marginedIcon, classes.hoverableNonFilledIcon)}
            onClick={() => onDelete(item)}
            wrapWithSpan
            iconStyle={{ width: 16, height: 16 }}
          />
        )}
      </div>
    ),
  });

  InvolvedPropertyItems.propTypes = {
    propertyItemsId: PropTypes.string,
    propertyItemsConfigPath: PropTypes.string,
  };

  const onDeleteItem = (item) => {
    const updateItems = propertyItems.filter((currItem) => currItem !== item);
    setFieldValue(propertyItemsId, updateItems);
  };

  const columnsData = [
    { id: 'description', numeric: false, label: 'Item Description' },
    {
      id: 'category',
      numeric: false,
      label: 'Category',
      specialCell: (row) =>
        involvedPropertyDefinedFields?.category?.options?.find((option) => option?.id === row?.category)?.desc,
    },
    { id: 'quantity', numeric: false, label: 'Quantity' },
    { id: 'purchase_date', numeric: false, label: 'Purchase Date' },
    {
      id: 'purchase_price',
      numeric: true,
      label: 'Purchase Price',
      specialCell: (row) => row.purchase_price && currencyFormatter.format(row.purchase_price),
    },
    {
      id: 'claimed_amount',
      numeric: true,
      label: 'Claim Amount',
      specialCell: (row) => row.claimed_amount && currencyFormatter.format(row.claimed_amount),
    },
    { id: 'scheduled', numeric: false, label: 'Scheduled' },
    actionsCell({ onDelete: onDeleteItem }),
  ];

  const addItemButton = (text, onClick) => (
    <div className={classes.leftButtonsContainer}>
      <Button color="primary" onClick={onClick}>
        <AddIcon className={classes.leftButtonIcon} />
        {text}
      </Button>
    </div>
  );

  const propertyItems = getIn(values, propertyItemsId, []);

  return (
    <div>
      <div className={styles.titleWrapper}>
        <Grid item xs={12} className={styles.itemsTitleContainer}>
          <Typography className={styles.itemsTitle}>Items</Typography>
        </Grid>
      </div>
      {!isEmpty(propertyItems) && <SortableTable columns={columnsData} rows={propertyItems} stickyHeader />}
      {addItemButton('ADD ITEM', () => setOpenAddItemDialog(true))}
      {openAddItemDialog && (
        <AddInvolvedPropertyItems
          propertyItemsConfigPath={propertyItemsConfigPath}
          onAddItem={(values) => {
            const item = values;
            setFieldValue(propertyItemsId, [...propertyItems, item]);
            setOpenAddItemDialog(false);
          }}
          onCancel={() => {
            setOpenAddItemDialog(false);
            setCurrentItem({});
          }}
        />
      )}
      {openEditItemDialog && (
        <EditInvolvedPropertyItems
          propertyItemsConfigPath={propertyItemsConfigPath}
          onSaveItemDetails={(values) => {
            const item = values;
            const updatedItems = propertyItems.map((updateItem) => (updateItem !== currentItem ? updateItem : item));
            setFieldValue(propertyItemsId, updatedItems);
            setOpenEditItemDialog(false);
          }}
          onEditCancel={() => {
            setOpenEditItemDialog(false);
            setCurrentItem({});
          }}
          item={{
            ...currentItem,
          }}
        />
      )}
    </div>
  );
};

InvolvedPropertyItems.propTypes = {
  propertyItemsValues: PropTypes.object,
};

export { InvolvedPropertyItems };
