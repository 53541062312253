import React from 'react';
import PropTypes from 'prop-types';

import Typography from '~/components/core/Atomic/Typography';

import { EmptyStateIllustration } from '../../illustrations';

const Index = ({ title, subtitle, illustration = <EmptyStateIllustration />, buttonComponent, className }) => {
  const titleToShow = !title && !subtitle ? 'No Data' : title;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className={className}>
      <div style={{ marginBottom: '14px' }}>{illustration}</div>
      <div style={{ marginBottom: '14px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {titleToShow && (
          <Typography display="block" variant="h6">
            {titleToShow}
          </Typography>
        )}
        {subtitle && (
          <Typography
            display="block"
            variant="subtitle1"
            style={{ fontSize: '14px', fontWeight: 500, color: '#606060' }}
          >
            {subtitle}
          </Typography>
        )}
      </div>
      {buttonComponent}
    </div>
  );
};

Index.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  illustration: PropTypes.node,
  buttonComponent: PropTypes.node,
  className: PropTypes.string,
};

export default Index;
