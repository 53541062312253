import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const RecoveriesIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      d="M2.68934 4.93934C2.97064 4.65804 3.35217 4.5 3.75 4.5H8.74996C9.0723 4.50133 9.38574 4.60581 9.6444 4.79815L9.64619 4.79948L12.2507 6.74815C12.2521 6.74921 12.2541 6.74993 12.2559 6.75H20.25C20.6478 6.75 21.0294 6.90804 21.3107 7.18934C21.592 7.47065 21.75 7.85218 21.75 8.25V9.75C21.75 10.1642 21.4142 10.5 21 10.5C20.5858 10.5 20.25 10.1642 20.25 9.75V8.25H12.2531L12.25 8.24999C11.9277 8.24867 11.6143 8.14419 11.3556 7.95185L11.3538 7.95052L8.74935 6.00185C8.74786 6.0008 8.74592 6.00007 8.7441 6L3.75 6L3.75 18.75H10.5C10.9142 18.75 11.25 19.0858 11.25 19.5C11.25 19.9142 10.9142 20.25 10.5 20.25H3.75C3.35218 20.25 2.97065 20.092 2.68934 19.8107C2.40804 19.5294 2.25 19.1478 2.25 18.75V6C2.25 5.60217 2.40804 5.22064 2.68934 4.93934Z"
      fill={iconColor}
    />
    <path
      d="M13.229 11.0625C13.6432 11.0625 13.979 11.3983 13.979 11.8125V12.6941C14.4645 12.2971 15.0358 12.0151 15.65 11.8719C16.3982 11.6974 17.1803 11.7358 17.9079 11.9828C18.6354 12.2297 19.2792 12.6754 19.7666 13.2693C20.254 13.8631 20.5655 14.5816 20.6658 15.3433C20.766 16.105 20.6511 16.8796 20.3341 17.5794C20.017 18.2792 19.5104 18.8763 18.8716 19.3031C18.2328 19.7299 17.4873 19.9695 16.7194 19.9946C15.9515 20.0197 15.192 19.8295 14.5266 19.4453C14.1679 19.2382 14.045 18.7795 14.2521 18.4208C14.4592 18.0621 14.9179 17.9392 15.2766 18.1463C15.6995 18.3905 16.1823 18.5114 16.6703 18.4954C17.1584 18.4794 17.6322 18.3272 18.0382 18.0559C18.4443 17.7846 18.7662 17.4051 18.9678 16.9603C19.1693 16.5155 19.2423 16.0232 19.1786 15.5391C19.1149 15.0549 18.9169 14.5983 18.6071 14.2208C18.2973 13.8434 17.8881 13.5601 17.4257 13.4032C16.9633 13.2462 16.4662 13.2218 15.9906 13.3327C15.6689 13.4077 15.3657 13.5426 15.0963 13.729H15.2289C15.6431 13.729 15.9789 14.0648 15.9789 14.479C15.9789 14.8932 15.6431 15.229 15.2289 15.229H13.229C12.8148 15.229 12.479 14.8932 12.479 14.479V11.8125C12.479 11.3983 12.8148 11.0625 13.229 11.0625Z"
      fill={iconColor}
    />
  </SvgIcon>
);

RecoveriesIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default RecoveriesIcon;
