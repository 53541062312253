import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const ChecksIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      d="M21 4.5H3C2.2 4.5 1.5 5.1 1.5 5.9V16.6C1.5 17.4 2.2 18 3 18H21C21.8 18 22.5 17.4 22.5 16.6V5.9C22.5 5.1 21.8 4.5 21 4.5ZM21 16.5L3 16.6V6H21V16.5Z"
      fill={iconColor}
    />
    <path
      d="M5.2 9.8H13.4C13.8 9.8 14.2 9.5 14.2 9C14.2 8.5 13.9 8.2 13.4 8.2H5.2C4.8 8.3 4.5 8.6 4.5 9C4.5 9.4 4.8 9.8 5.2 9.8Z"
      fill={iconColor}
    />
    <path
      d="M5.2 12H13.4C13.8 12 14.2 11.7 14.2 11.2C14.2 10.7 13.9 10.4 13.4 10.4H5.2C4.8 10.4 4.4 10.7 4.4 11.2C4.4 11.7 4.8 12 5.2 12Z"
      fill={iconColor}
    />
    <path
      d="M5.2 14.3H8.2C8.6 14.3 9 14 9 13.5C9 13 8.7 12.7 8.2 12.7H5.2C4.8 12.7 4.4 13 4.4 13.5C4.4 14 4.8 14.3 5.2 14.3Z"
      fill={iconColor}
    />
    <path
      d="M15 14.9H15.2C15.1 15.2 14.9 15.4 14.8 15.6C14.7 15.7 14.8 15.9 14.9 15.9H15C15.1 15.9 15.2 15.9 15.2 15.8C15.3 15.6 15.5 15.3 15.8 14.8H19.9C20 14.8 20.1 14.7 20.1 14.6C20.1 14.5 20 14.4 19.9 14.4H16C16 14.3 16.1 14.2 16.1 14.2C16.4 14.2 16.8 14 17.3 13.5C17.4 13.8 17.6 14.1 17.9 14.2C18.2 14.3 18.5 14.1 18.6 13.9C18.8 14.1 19.1 14.2 19.8 14.2C19.9 14.2 20 14.1 20 14C20 13.9 19.9 13.8 19.8 13.8C19.1 13.8 18.9 13.6 18.9 13.5C18.9 13.4 18.8 13.3 18.7 13.3C18.6 13.3 18.5 13.4 18.5 13.5C18.3 13.8 18.1 13.9 18.1 13.9C18 13.9 17.8 13.5 17.7 13.2C17.7 13.1 17.6 13 17.4 13C17.3 13 17.3 13 17.2 13.1C16.9 13.5 16.6 13.8 16.4 13.9C16.8 13 17.1 12.1 16.8 11.7C16.7 11.5 16.5 11.4 16.2 11.4C15.8 11.4 15.5 11.8 15.3 12.4C15.1 13.1 15.2 13.9 15.6 14.3C15.5 14.4 15.5 14.6 15.4 14.7H15C14.9 14.7 14.8 14.8 14.8 14.9C14.8 15 14.9 14.9 15 14.9ZM15.9 12.1C16 11.7 16.2 11.5 16.3 11.5C16.4 11.5 16.4 11.5 16.4 11.6C16.5 11.8 16.3 12.6 15.9 13.5C15.8 13.1 15.8 12.6 15.9 12.1Z"
      fill={iconColor}
    />
  </SvgIcon>
);

ChecksIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default ChecksIcon;
