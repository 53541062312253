import PropTypes from 'prop-types';

import { localeDetails } from '~/components/CmsMain/globals';

import { COUNTRIES_DICT, LOCATION_TYPES_DICT } from '../../Types';
import { reportErrorInProductionOrThrow } from '../../Utils';
// import { localeDetails } from '../CmsMain/globals'
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';

import { useLob } from './useLob';
import { useSubOrganization } from './useSubOrganization';

const useGlobalAddresses = ({ countriesConfigurationKey }) => {
  const { subOrganizationEnabled } = useOrganization();
  const { userOrganization } = useCms();
  const { lob } = useLob();
  const { subOrganization } = useSubOrganization();

  let globalAddressesConfiguration;
  if (subOrganizationEnabled) {
    if (subOrganization && lob) {
      globalAddressesConfiguration = subOrganization.countries_configuration;
    }
  } else {
    globalAddressesConfiguration = userOrganization.countries_configurations;
  }

  let relevantConfig;
  if (globalAddressesConfiguration) {
    if (globalAddressesConfiguration.is_all_lobs) {
      relevantConfig = globalAddressesConfiguration.countries_configuration_for_locations;
    } else {
      relevantConfig = globalAddressesConfiguration.countries_configuration_for_locations.filter(
        (config) => config.lob === lob
      );
    }
  }

  return {
    countriesOptions: getCountriesOptions(countriesConfigurationKey, relevantConfig) || [],
    defaultCountry: getDefaultCountry(countriesConfigurationKey, relevantConfig),
  };
};

const getCountriesOptions = (countriesConfigurationKey, countriesConfigs) => {
  if (countriesConfigurationKey === 'contact_location') {
    // contact_location configuration is deprecated in global addresses v2
    return Object.keys(COUNTRIES_DICT);
  }

  if (!LOCATION_TYPES_DICT[countriesConfigurationKey]) {
    reportErrorInProductionOrThrow(
      `Unknown countries configuration key ${countriesConfigurationKey} when requesting countries options`
    );
  }

  const relevantCountriesConfig = countriesConfigs?.find(
    (config) => config.location_type === countriesConfigurationKey
  );
  switch (relevantCountriesConfig?.configuration_select) {
    case 'organization_country':
      return [localeDetails.locale.region];
    case 'multiple':
      return relevantCountriesConfig.selected_countries;
    case 'all':
    default:
      return Object.keys(COUNTRIES_DICT);
  }
};

const getDefaultCountry = (countriesConfigurationKey, countriesConfigs) => {
  const organizationCountry = localeDetails.locale.region;

  if (countriesConfigurationKey === 'contact_location') {
    // contact_location configuration is deprecated in global addresses v2
    return organizationCountry;
  }

  if (!LOCATION_TYPES_DICT[countriesConfigurationKey]) {
    reportErrorInProductionOrThrow(
      `Unknown countries configuration key ${countriesConfigurationKey} when requesting default country`
    );
  }

  const relevantCountriesConfig = countriesConfigs?.find(
    (config) => config.location_type === countriesConfigurationKey
  );
  if (
    relevantCountriesConfig?.configuration_select === 'multiple' &&
    !relevantCountriesConfig?.selected_countries.includes(organizationCountry)
  ) {
    return relevantCountriesConfig.selected_countries[0];
  }

  return organizationCountry;
};

useGlobalAddresses.propTypes = {
  countriesConfigurationKey: PropTypes.string,
};

export default useGlobalAddresses;
