import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const DyteDialogLoader: React.FC = () => {
  return (
    <div className="mt-40 flex items-center justify-center pt-40">
      <CircularProgress />
    </div>
  );
};

export default DyteDialogLoader;
