import React from 'react';
import PropTypes from 'prop-types';

const SuccessIllustration = ({ overrideStyle }) => (
  <svg width="107" height="68" viewBox="0 0 107 68" fill="none" xmlns="http://www.w3.org/2000/svg" {...overrideStyle}>
    <path
      d="M3.60589 29.8103C3.52232 29.8104 3.43955 29.794 3.36235 29.762C3.28514 29.73 3.21502 29.6831 3.15601 29.6239L0.186443 26.6543C0.0705298 26.5343 0.00638672 26.3736 0.00783655 26.2067C0.00928639 26.0399 0.07621 25.8803 0.194192 25.7623C0.312173 25.6443 0.471773 25.5774 0.638618 25.576C0.805463 25.5745 0.966202 25.6386 1.08622 25.7546L4.05578 28.7241C4.14475 28.8131 4.20533 28.9265 4.22988 29.0499C4.25442 29.1733 4.24182 29.3012 4.19366 29.4175C4.14551 29.5338 4.06397 29.6331 3.95935 29.7031C3.85473 29.773 3.73173 29.8103 3.60589 29.8103V29.8103Z"
      fill="#0CBC8B"
    />
    <path
      d="M0.636196 29.8103C0.510361 29.8103 0.387364 29.773 0.282744 29.7031C0.178125 29.6331 0.0965819 29.5338 0.0484305 29.4175C0.000279131 29.3012 -0.0123219 29.1733 0.0122199 29.0499C0.0367617 28.9265 0.097349 28.8131 0.186314 28.7241L3.15587 25.7546C3.27588 25.6386 3.43662 25.5745 3.60347 25.576C3.77031 25.5774 3.92991 25.6443 4.04789 25.7623C4.16587 25.8803 4.2328 26.0399 4.23425 26.2067C4.2357 26.3736 4.17155 26.5343 4.05564 26.6543L1.08608 29.6239C1.02709 29.6831 0.956969 29.73 0.87976 29.762C0.802551 29.794 0.719773 29.8104 0.636196 29.8103V29.8103Z"
      fill="#0CBC8B"
    />
    <path d="M88.6299 58.1931L87.7822 60.1375L92.9988 62.4116L93.8464 60.4673L88.6299 58.1931Z" fill="#0CBC8B" />
    <path d="M90.277 6.06447L85.3706 8.94751L86.4452 10.7763L91.3516 7.89323L90.277 6.06447Z" fill="#0CBC8B" />
    <path d="M8.51795 11.0018L7.86084 13.0186L13.2718 14.7816L13.9289 12.7648L8.51795 11.0018Z" fill="#0CBC8B" />
    <path d="M14.8526 44.8281L10.1919 48.0889L11.4073 49.8261L16.068 46.5653L14.8526 44.8281Z" fill="#0CBC8B" />
    <path d="M100.337 37.3527L100.142 39.4648L105.685 39.9758L105.88 37.8637L100.337 37.3527Z" fill="#0CBC8B" />
    <path
      d="M105.982 22.1597C105.898 22.1598 105.816 22.1434 105.738 22.1114C105.661 22.0794 105.591 22.0325 105.532 21.9733L102.562 19.0037C102.502 18.945 102.453 18.8748 102.42 18.7972C102.386 18.7195 102.369 18.636 102.368 18.5515C102.367 18.467 102.384 18.3832 102.416 18.305C102.448 18.2268 102.495 18.1558 102.555 18.096C102.614 18.0363 102.685 17.9891 102.764 17.9571C102.842 17.9251 102.926 17.909 103.01 17.9097C103.095 17.9104 103.178 17.928 103.256 17.9613C103.333 17.9947 103.403 18.0432 103.462 18.1039L106.432 21.0735C106.521 21.1625 106.581 21.2759 106.606 21.3993C106.63 21.5227 106.618 21.6506 106.57 21.7669C106.521 21.8831 106.44 21.9825 106.335 22.0524C106.231 22.1224 106.108 22.1597 105.982 22.1597V22.1597Z"
      fill="#0CBC8B"
    />
    <path
      d="M103.012 22.1597C102.886 22.1597 102.763 22.1224 102.659 22.0524C102.554 21.9825 102.473 21.8831 102.424 21.7669C102.376 21.6506 102.364 21.5227 102.388 21.3993C102.413 21.2759 102.473 21.1625 102.562 21.0735L105.532 18.1039C105.591 18.0432 105.661 17.9947 105.738 17.9613C105.816 17.928 105.9 17.9104 105.984 17.9097C106.069 17.909 106.152 17.9251 106.23 17.9571C106.309 17.9891 106.38 18.0363 106.44 18.096C106.499 18.1558 106.546 18.2268 106.578 18.305C106.61 18.3832 106.627 18.467 106.626 18.5515C106.625 18.636 106.608 18.7195 106.574 18.7972C106.541 18.8748 106.492 18.945 106.432 19.0037L103.462 21.9733C103.403 22.0324 103.333 22.0794 103.256 22.1114C103.179 22.1434 103.096 22.1598 103.012 22.1597V22.1597Z"
      fill="#0CBC8B"
    />
    <path
      opacity="0.4"
      d="M54.3988 67.6462C73.0788 67.6462 88.2219 52.5031 88.2219 33.8231C88.2219 15.1431 73.0788 0 54.3988 0C35.7188 0 20.5757 15.1431 20.5757 33.8231C20.5757 52.5031 35.7188 67.6462 54.3988 67.6462Z"
      fill="#0CBC8B"
    />
    <path
      d="M54.4085 57.4025C67.6133 57.4025 78.3179 46.959 78.3179 34.0763C78.3179 21.1935 67.6133 10.75 54.4085 10.75C41.2036 10.75 30.499 21.1935 30.499 34.0763C30.499 46.959 41.2036 57.4025 54.4085 57.4025Z"
      fill="#0CBC8B"
    />
    <path
      d="M49.1597 43.8083C48.9777 43.8084 48.7976 43.7696 48.6302 43.6941C48.4629 43.6186 48.3117 43.5081 48.1858 43.3692L40.809 35.2561C40.6838 35.1215 40.5852 34.962 40.5188 34.7868C40.4523 34.6117 40.4194 34.4243 40.422 34.2356C40.4246 34.0469 40.4625 33.8606 40.5337 33.6875C40.6049 33.5144 40.7078 33.358 40.8366 33.2272C40.9653 33.0964 41.1174 32.994 41.2838 32.9257C41.4503 32.8575 41.628 32.8248 41.8065 32.8296C41.985 32.8344 42.1609 32.8766 42.3239 32.9537C42.4869 33.0308 42.6338 33.1414 42.7561 33.2789L49.2291 40.3977L66.1519 24.3549C66.2836 24.2276 66.4378 24.1292 66.6056 24.0654C66.7735 24.0015 66.9516 23.9734 67.1298 23.9827C67.308 23.992 67.4827 24.0385 67.6437 24.1196C67.8048 24.2007 67.9491 24.3147 68.0682 24.455C68.1873 24.5953 68.279 24.7592 68.3378 24.9372C68.3967 25.1151 68.4216 25.3037 68.4111 25.4919C68.4006 25.6801 68.3549 25.8642 68.2766 26.0336C68.1984 26.2031 68.0892 26.3545 67.9553 26.479L50.0613 43.4428C49.8138 43.6782 49.4926 43.8085 49.1597 43.8083Z"
      fill="white"
    />
  </svg>
);

SuccessIllustration.propTypes = {
  overrideStyle: PropTypes.object,
};

export default SuccessIllustration;
