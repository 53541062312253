import React from 'react';
import PropTypes from 'prop-types';

import AutocompleteFormik from '~/components/AutocompleteFormik';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import { useCms } from '~/components/hooks/useCms';
import { CONFIGURATION_FEATURES_NAMES, MEDICAL_BILL_PROVIDER_PROFESSIONS } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import { FORMIK_DETAILS_FIELD_IDS } from '../../constants';
import { getFullDetailsTabFieldPath } from '../../utils';

const MedicalBillProvider = ({ disabled }) => {
  const { userOrganization } = useCms();
  const { currentRole: role } = useContact();

  const isMedicalBillProviderProfessionFieldFeatureEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MEDICAL_BILL_PROVIDER_PROFESSION_FIELD
  );

  if (!isMedicalBillProviderProfessionFieldFeatureEnabled || role !== 'medical_bill_provider') {
    return null;
  }
  return (
    <AutocompleteFormik
      id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.PROFESSION)}
      label="Profession"
      options={Object.keys(MEDICAL_BILL_PROVIDER_PROFESSIONS)}
      getOptionLabel={(key) => MEDICAL_BILL_PROVIDER_PROFESSIONS[key].desc}
      sortAlphabetic
      disabled={disabled}
    />
  );
};

MedicalBillProvider.propTypes = {
  disabled: PropTypes.bool,
};

export default MedicalBillProvider;
