import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import { CheckIcon, DashIcon } from '../../../icons';
import { Text } from '../../index';

import colors from '../../../../assets/colors.module.scss';
import styles from './ViewOnlyRadioButtonFormik.module.scss';

export const ViewOnlyRadioButtonFormik = ({ id, optionValue, label }) => {
  const { values } = useFormikContext();

  const checked = getIn(values, id) === optionValue;

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconContainer}>
        {checked ? <CheckIcon iconColor={colors.buttonSelected} /> : <DashIcon iconColor={colors.textDisabled} />}
      </div>
      <Text
        className={styles.label}
        variant={Text.VARIANTS.SM}
        weight={checked ? Text.WEIGHTS.SEMI_BOLD : Text.WEIGHTS.REGULAR}
        colorVariant={checked ? Text.COLOR_VARIANTS.PRIMARY : Text.COLOR_VARIANTS.DISABLED}
      >
        {label}
      </Text>
    </div>
  );
};

ViewOnlyRadioButtonFormik.propTypes = {
  id: PropTypes.string.isRequired,
  optionValue: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
};

export default ViewOnlyRadioButtonFormik;
