import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ReplyIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.68693 2.9797C5.49167 2.78444 5.17508 2.78444 4.97982 2.9797L1.64649 6.31303C1.45123 6.50829 1.45123 6.82488 1.64649 7.02014L4.97982 10.3535C5.17508 10.5487 5.49167 10.5487 5.68693 10.3535C5.88219 10.1582 5.88219 9.84163 5.68693 9.64637L2.70715 6.66659L5.68693 3.68681C5.88219 3.49154 5.88219 3.17496 5.68693 2.9797Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 13.3334C14.5 9.37527 11.2915 6.16675 7.33333 6.16675H2C1.72386 6.16675 1.5 6.39061 1.5 6.66675C1.5 6.94289 1.72386 7.16675 2 7.16675H7.33333C10.7392 7.16675 13.5 9.92756 13.5 13.3334V14.0001C13.5 14.2762 13.7239 14.5001 14 14.5001C14.2761 14.5001 14.5 14.2762 14.5 14.0001V13.3334Z"
    />
  </SvgIcon>
);

export default ReplyIcon;
