const NOTE_SUBJECT = {
  note: {
    display: 'Note',
    icon: 'note',
    is_manual: true,
  },
  email: {
    display: 'Email',
    icon: 'email',
    is_manual: true,
  },
  phone_call: {
    display: 'Phone Call',
    icon: 'call',
    is_manual: true,
  },
  physical_mail: {
    display: 'Physical Mail',
    icon: 'mail_outline',
    is_manual: true,
  },
  sms: {
    display: 'SMS',
    icon: 'message',
    is_manual: true,
  },
  vendor: {
    display: 'Vendor',
    icon: 'note',
    is_manual: true,
  },
  video_call: {
    display: 'Video Call',
    icon: 'videocam',
    is_manual: true,
  },
  assign_vendor: {
    display: 'Assign Vendor',
    icon: 'business_center',
    is_manual: true,
  },
  new_exposure: {
    display: 'New Loss',
    icon: 'note_add',
    is_manual: false,
  },
  new_expense: {
    display: 'New Expense',
    icon: 'note_add',
    is_manual: false,
  },
  close_claim: {
    display: 'Close Claim',
    icon: 'done_all',
    is_manual: false,
  },
  reopen_claim: {
    display: 'Reopen Claim',
    icon: 'unarchive',
    is_manual: false,
  },
  close_exposure: {
    display: 'Close Exposure',
    icon: 'done',
    is_manual: false,
  },
  reopen_exposure: {
    display: 'Reopen Exposure',
    icon: 'unarchive',
    is_manual: false,
  },
  adjust_reserve: {
    display: 'Adjust Reserve',
    icon: 'attach_money',
    is_manual: false,
  },
  payment: {
    display: 'Payment',
    icon: 'payment',
    is_manual: false,
  },
  recovery: {
    display: 'Recovery',
    icon: 'payment',
    is_manual: false,
  },
  document: {
    display: 'Document',
    icon: 'description',
    is_manual: false,
  },
  photos: {
    display: 'Gallery',
    icon: 'photo_library',
    is_manual: false,
  },
  coverage_decision: {
    display: 'Coverage Decision',
    icon: 'thumbs_up_down',
    is_manual: false,
  },
  moi_selection: {
    display: 'MOI Selection',
    icon: 'search',
    is_manual: false,
  },
  damage_assessment: {
    display: 'Damage Assessment',
    icon: 'search',
    is_manual: false,
  },
  mor_selection: {
    display: 'MOR Selection',
    icon: 'search',
    is_manual: false,
  },
  aob: {
    display: 'AOB',
    icon: 'description',
    is_manual: false,
  },
  claim_search: {
    display: 'ClaimSearch',
    icon: 'note',
    is_manual: false,
  },
} as const;

export default NOTE_SUBJECT;
