import React from 'react';
import { getIn, useFormikContext } from 'formik';

import OrgUnitSelect from './OrgUnitSelect';
import type { OrganizationUnit } from './types';

type OrgUnitSelectOnChangeHandler = (event: React.ChangeEvent<unknown>, value: OrganizationUnit | null) => void;

interface OrgUnitSelectTextFieldFormikProps {
  id: string;
  label: string;
  showOnly?: boolean;
  disabled?: boolean;
  onChange?: OrgUnitSelectOnChangeHandler;
  units: OrganizationUnit[];
  isLoading: boolean;
  isError: boolean;
}

const OrgUnitSelectTextFieldFormik: React.FC<OrgUnitSelectTextFieldFormikProps> = ({
  id,
  label,
  showOnly,
  disabled,
  onChange,
  units,
  isLoading,
  isError,
}) => {
  const { setFieldValue, setFieldTouched, values, errors, touched } = useFormikContext();
  const activeUnitsAndSelectedUnit = React.useMemo(() => units?.filter((unit) => unit.is_active), [units]);
  const selectedUnit = units?.find((unit) => unit.id === getIn(values, id));
  if (selectedUnit && !activeUnitsAndSelectedUnit.includes(selectedUnit)) {
    activeUnitsAndSelectedUnit.push(selectedUnit);
  }
  const defaultOnChange: OrgUnitSelectOnChangeHandler = (_, unit) => setFieldValue(id, unit ? unit.id : null);
  return (
    <OrgUnitSelect
      label={label}
      units={activeUnitsAndSelectedUnit}
      isLoading={isLoading}
      isError={isError}
      showOnly={showOnly}
      disabled={disabled}
      onChange={onChange || defaultOnChange}
      value={selectedUnit}
      textFieldProps={{
        error: getIn(errors, id) && getIn(touched, id),
        helperText: getIn(errors, id) && getIn(touched, id) && getIn(errors, id),
        onBlur: () => setFieldTouched(id, true),
      }}
    />
  );
};

export default OrgUnitSelectTextFieldFormik;
