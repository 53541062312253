import { useLegalActions } from '~/components/LegalActions/hooks/useLegalActions';

interface InvolvedPerson {
  id: number;
  contact: {
    id: number;
  };
  contact_id: number;
  attorney_contact_full_name: string;
  attorney_contact_id: number | null;
}

interface InvolvedAttorneyRecord {
  id: number;
  name: string;
}

interface InvolvedAttorneysResult {
  attorneys: InvolvedAttorneyRecord[];
  attorneyNames: string;
}

const getAttorneysResult = (attorneys: InvolvedAttorneyRecord[]): InvolvedAttorneysResult => {
  const attorneyNames = attorneys.map((attorney) => attorney.name).join(', ');
  return { attorneys, attorneyNames };
};

export const useInvolvedAttorneys = (involved: InvolvedPerson, exposureId: number): InvolvedAttorneysResult => {
  const { isLegalActionsFeatureEnabled, legalActions, isLegalActionsLoading } = useLegalActions();

  const attorneys: InvolvedAttorneyRecord[] = [];

  if (involved?.attorney_contact_full_name && involved?.attorney_contact_id) {
    attorneys.push({
      id: involved.attorney_contact_id,
      name: involved.attorney_contact_full_name,
    });
  }

  if (!isLegalActionsFeatureEnabled || isLegalActionsLoading) {
    return getAttorneysResult(attorneys);
  }

  if (isLegalActionsFeatureEnabled && legalActions && legalActions.length > 0) {
    const legalActionPartiesOfInvolvedContact = legalActions
      .filter(
        // keep only legal actions related to exposure or claim level
        (legalAction) =>
          legalAction.is_open &&
          (legalAction.is_claim_level || legalAction.exposures.some((exposure) => exposure.id === exposureId))
      )
      .filter((legalAction) =>
        // keep only legal actions related to the contact of the involved
        legalAction.legal_action_parties.some((party) => party.contact_id === involved.contact_id)
      )
      .flatMap((legalAction) =>
        legalAction.legal_action_parties.filter((party) => party.contact_id === involved.contact_id)
      );

    legalActionPartiesOfInvolvedContact.forEach((party) => {
      if (party.counsel_contact_id && attorneys.every((att) => att.id !== party.counsel_contact_id)) {
        attorneys.push({
          id: party.counsel_contact_id,
          name: party.counsel_name,
        });
      }
    });
  }

  return getAttorneysResult(attorneys);
};
