import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { difference, intersection, isEqual } from 'lodash';

import {
  getAllOrganizationRolesExcludingSystem,
  getAllOrganizationWideRoles,
} from '~/components/communications/ContactUtils';
import {
  ADVANCED_CONTACTS_SEARCH_FIELD_IDS,
  ALL_OPTIONS_VALUE,
} from '~/components/Contacts/OrganizationContactsScreen/organizationContactsUtils';
import useOrganization from '~/components/OrganizationContext';
import TextFieldFormik, { MultiSelectTextFieldFormik } from '~/components/TextFieldFormik';
import { isOrganizationUsOrg } from '~/Utils';

const OrganizationContactsAdvancedSearchFilters = ({ includeSingleClaimRoles = false }) => {
  const { organizationContactRolesDict, organizationMoiExpertises } = useOrganization();
  const organization = useOrganization();
  const { isSubmitting, setFieldValue, values } = useFormikContext();

  const getAcceptedRoles = useCallback(
    () =>
      includeSingleClaimRoles
        ? getAllOrganizationRolesExcludingSystem(organizationContactRolesDict)
        : getAllOrganizationWideRoles(organizationContactRolesDict),
    [includeSingleClaimRoles, organizationContactRolesDict]
  );

  const [acceptedRoles, setAcceptedRoles] = useState(getAcceptedRoles());

  const selectedRoles = getIn(values, ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ACCEPTED_ROLES);

  useEffect(() => {
    const newAcceptedRoles = getAcceptedRoles();
    if (newAcceptedRoles.length !== acceptedRoles.length || !isEqual(newAcceptedRoles.sort(), acceptedRoles.sort())) {
      setAcceptedRoles(newAcceptedRoles);

      if (difference(selectedRoles, newAcceptedRoles.concat([ALL_OPTIONS_VALUE])).length > 0) {
        setFieldValue(
          ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ACCEPTED_ROLES,
          intersection(selectedRoles, newAcceptedRoles.concat([ALL_OPTIONS_VALUE]))
        );
      }
    }
  }, [includeSingleClaimRoles, acceptedRoles, setFieldValue, selectedRoles, getAcceptedRoles]);

  return (
    <>
      <div className="mb-32 grid grid-cols-3 gap-32">
        <div>
          <TextFieldFormik
            id={ADVANCED_CONTACTS_SEARCH_FIELD_IDS.SEARCH}
            label="Name"
            className="mb-2 mt-2"
            fullWidth
            disabled={isSubmitting}
          />
        </div>
        <div>
          <MultiSelectTextFieldFormik
            id={ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ACCEPTED_ROLES}
            label="Contact Roles"
            disabled={isSubmitting}
            options={acceptedRoles}
            renderOption={(role) => organizationContactRolesDict[role]?.desc}
            renderValue={(selectedRoles) =>
              selectedRoles
                .map((selectedRole) =>
                  selectedRole === ALL_OPTIONS_VALUE ? 'Any role' : organizationContactRolesDict[selectedRole].desc
                )
                .join(', ')
            }
            className="mb-2 mt-2"
            fullWidth
            sortAlphabetic
            addAllOption
            allOptionValue={ALL_OPTIONS_VALUE}
          />
        </div>
        <div>
          <MultiSelectTextFieldFormik
            id={ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ACCEPTED_EXPERTISE}
            label="Contact Expertise"
            disabled={isSubmitting}
            options={Object.keys(organizationMoiExpertises)}
            renderOption={(expertise) => organizationMoiExpertises[expertise]?.desc}
            renderValue={(selectedExpertises) =>
              selectedExpertises
                .map((selectedExpertise) =>
                  selectedExpertise === ALL_OPTIONS_VALUE
                    ? 'Any Expertise'
                    : organizationMoiExpertises[selectedExpertise].desc
                )
                .join(', ')
            }
            className="mb-2 mt-2"
            fullWidth
            sortAlphabetic
            addAllOption
            allOptionValue={ALL_OPTIONS_VALUE}
          />
        </div>
      </div>
      <div className="mb-32 grid grid-cols-3 gap-32">
        {isOrganizationUsOrg(organization) ? (
          <div>
            <TextFieldFormik
              id={ADVANCED_CONTACTS_SEARCH_FIELD_IDS.SOCIAL_SECURITY_NUMBER}
              label="Social Security Number"
              fullWidth
              className="mb-2 mt-2"
              disabled={isSubmitting}
            />
          </div>
        ) : null}
        <div>
          <TextFieldFormik
            id={ADVANCED_CONTACTS_SEARCH_FIELD_IDS.ZIPCODE}
            label="Zipcode"
            fullWidth
            className="mb-2 mt-2"
            disabled={isSubmitting}
          />
        </div>
        <div>
          <TextFieldFormik
            id={ADVANCED_CONTACTS_SEARCH_FIELD_IDS.PHONE_NUMBER}
            label="Phone number"
            fullWidth
            className="mb-2 mt-2"
            disabled={isSubmitting}
          />
        </div>
      </div>
    </>
  );
};

OrganizationContactsAdvancedSearchFilters.propTypes = {
  includeSingleClaimRoles: PropTypes.bool,
};

export default OrganizationContactsAdvancedSearchFilters;
