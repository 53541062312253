import React from 'react';
import PropTypes from 'prop-types';

import Typography from '~/components/core/Atomic/Typography';

import CardDialog from './CardDialog';
import LoadingIndicator from './LoadingIndicator';

function LoadingDialog({ isError, onClose, track, text }) {
  return (
    <CardDialog isDialog trackAlt={`Loading (${track})`} noCardTitle onClose={onClose}>
      {text && <Typography variant="subtitle1">{text}</Typography>}
      <LoadingIndicator isError={isError} />
    </CardDialog>
  );
}

LoadingDialog.propTypes = {
  isError: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  text: PropTypes.string,
  track: PropTypes.string.isRequired,
};

export default LoadingDialog;
