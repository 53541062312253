import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '~/components/core/Atomic/Typography';
import cn from '~/Utils/cn';

import colors from '../../../../assets/colors.module.scss';

const VARIANTS = {
  REGULAR: 'regular',
  MEDIUM: 'medium',
  SEMI_BOLD: 'semi-bold',
  LABEL: 'label',
};

const WEIGHT_TO_NUM = {
  [VARIANTS.REGULAR]: 400,
  [VARIANTS.MEDIUM]: 500,
  [VARIANTS.SEMI_BOLD]: 600,
  [VARIANTS.LABEL]: 600,
};

const useStyles = makeStyles(() => ({
  root: ({ variant }) => ({
    fontWeight: WEIGHT_TO_NUM[variant],
    fontSize: 12,
    color: variant === VARIANTS.LABEL && colors.textSecondary,
  }),
}));

const Caption = ({ className, variant = VARIANTS.REGULAR, children }) => {
  const displayClasses = useStyles({ variant });

  return <Typography className={cn(displayClasses.root, className)}>{children}</Typography>;
};

Caption.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  children: PropTypes.node.isRequired,
};

Caption.VARIANTS = VARIANTS;

export default Caption;
