import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { serverDateToLocalMoment } from '../../DateTimeUtils';
import { BIKE_ACTIVITIES_DICT, BIKE_INCIDENTS_DICT, BIKE_LOCATION_TYPE_DICT, BIKE_LOCK_ATTRIBUTE } from '../../Types';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { ControlFileClaimSummaryChip, LitigationClaimSummaryChip, SiuClaimSummaryChip } from '../ClaimSummary';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { LossLocationShowOnly } from '../GlobalLossLocation';
import HoverChangeField, { SelectHoverChangeField } from '../HoverChangeField';
import IncidentMoreActionsContainer from '../IncidentMoreActionsContainer';
import IncidentMutualChips from '../IncidentMutualChips';
import IncidentTypeUpdateDialog from '../IncidentTypeUpdateDialog';
import PoliceReportField, { getPoliceInvolvementFields } from '../PoliceReportField';
import { ShowOnlyTextField } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function BikeIncidentDetailsCard({ claim, onUpdate, readOnly }) {
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const [showIncidentTypeUpdateDialog, setShowIncidentTypeUpdateDialog] = useState(false);

  const { incident } = claim;
  const disabled = readOnly || isFetching;

  const handleUpdateIncidentField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ incident: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentFields = async (updateObject) => {
    await handleUpdateFields({ incident: updateObject });
  };

  const handleUpdateClaimField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ [fieldName]: fieldValue });
  };

  const handleUpdateFields = async (updateObject) => {
    try {
      setIsFetching(true);
      await axios.patch(`/api/v1/bike_claims/${claim.id}`, updateObject);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsFetching(false);
  };

  const handleUpdateLitigationFields = async (values) => {
    const claimLitigationUrl = `/api/v1/claims/${claim.id}/litigation`;
    const method = !claim.is_in_first_party_litigation ? 'post' : 'put';
    try {
      await axios({ method, url: claimLitigationUrl, data: values });
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
    await onUpdate();
  };

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          !readOnly && (
            <IncidentMoreActionsContainer
              incident={incident}
              includeIsControlFile
              onUpdateFields={handleUpdateIncidentFields}
              includeAdvancedFirstPartyLitigation
              onChangeIsInFirstPartyLitigation={handleUpdateLitigationFields}
            />
          )
        }
      >
        {incident.is_control_file && <ControlFileClaimSummaryChip />}
        {claim.incident.is_siu && <SiuClaimSummaryChip />}
        {claim.is_in_first_party_litigation && !claim.is_litigation_closed && <LitigationClaimSummaryChip />}
        <IncidentMutualChips claim={claim} />

        <Grid container alignItems="stretch" spacing={1}>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={BIKE_INCIDENTS_DICT[incident.incident_type].desc}
              label="Incident Type"
              onEdit={disabled ? undefined : () => setShowIncidentTypeUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={
                BIKE_INCIDENTS_DICT[incident.incident_type].sub_types[incident.incident_sub_type]?.desc
              }
              label="Incident Sub-type"
              onEdit={disabled ? undefined : () => setShowIncidentTypeUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.reporter_contact_id}
              contactDisplayName={claim.reporter_contact_full_name}
              label="Reporter"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={serverDateToLocalMoment(claim.creation_date)}
              label="Reported On"
              width="100%"
            />
          </Grid>
          <Grid item xs={12}>
            <LossLocationShowOnly
              location={claim.incident.loss_location}
              label="Loss Location"
              disabled={disabled}
              onUpdate={async (newLossLocation) => await handleUpdateIncidentField('loss_location', newLossLocation)}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectHoverChangeField
              label="Bike Location"
              fieldId="bike_location_type"
              value={claim.incident.bike_location_type}
              keys={Object.keys(BIKE_LOCATION_TYPE_DICT)}
              displayValueFunc={(key) => BIKE_LOCATION_TYPE_DICT[key]['desc']}
              onUpdate={async (values) =>
                await handleUpdateIncidentField('bike_location_type', values.bike_location_type)
              }
              width="300px"
              overrideOnEdit
              disabled
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={BIKE_LOCATION_TYPE_DICT[claim.incident.bike_location_type]['desc']}
                label="Bike Location"
              />
            </SelectHoverChangeField>
          </Grid>
          <Grid item xs={6}>
            <SelectHoverChangeField
              label="Lock Attribute"
              fieldId="bike_secure_type"
              value={claim.incident.bike_secure_type}
              keys={Object.keys(BIKE_LOCK_ATTRIBUTE)}
              displayValueFunc={(key) => BIKE_LOCK_ATTRIBUTE[key]['desc']}
              onUpdate={async (values) => await handleUpdateIncidentField('bike_secure_type', values.bike_secure_type)}
              width="300px"
              overrideOnEdit
              disabled
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={BIKE_LOCK_ATTRIBUTE[claim.incident.bike_secure_type]['desc']}
                label="Lock Attribute"
              />
            </SelectHoverChangeField>
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={BIKE_ACTIVITIES_DICT[claim.incident.activity_description]?.desc}
              label="Occupation during the incident"
            />
          </Grid>
          <Grid item xs={12}>
            <PoliceReportField
              policeReportDetails={getPoliceInvolvementFields(incident)}
              onUpdate={async (values) => await handleUpdateFields({ incident: values })}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <HoverChangeField
              name="special_internal_note"
              value={claim.special_internal_note}
              label="Special Internal Note"
              onUpdate={(updatedValues) =>
                handleUpdateClaimField('special_internal_note', updatedValues['special_internal_note'])
              }
              fullWidth
              multiline
              rows={5}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.special_internal_note}
                label="Special Internal Note"
                maxHeight="5.3em" // ~ 3 line
                width="100%"
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={12}>
            <HoverChangeField
              name="description"
              value={claim.incident.description}
              label="Description"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('description', updatedValues['description'])
              }
              fullWidth
              multiline
              rows={8}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.description}
                label="Description"
                maxHeight="12em" // ~ 8 line
                width="100%"
              />
            </HoverChangeField>
          </Grid>
        </Grid>
      </CardDialog>

      {showIncidentTypeUpdateDialog && (
        <IncidentTypeUpdateDialog
          incidentTypesDict={BIKE_INCIDENTS_DICT}
          incidentType={claim.incident.incident_type}
          incidentSubType={claim.incident.incident_sub_type}
          onUpdate={async (values) => await handleUpdateFields({ incident: values })}
          onClose={() => setShowIncidentTypeUpdateDialog(false)}
        />
      )}
    </>
  );
}

BikeIncidentDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default BikeIncidentDetailsCard;
