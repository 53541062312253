import { get } from 'lodash';

import { reportErrorInProductionOrThrow } from '../../Utils';

/**
 * General tableau utils
 */

const TABLEAU_API_SCRIPT_SRC = 'https://public.tableau.com/javascripts/api/tableau-2.9.1.min.js';

const EXPORT_TYPES = {
  CSV: 'csv',
  EXCEL: 'excel',
  PDF: 'pdf',
};

const buildAuthUrl = (organizationId) => `/api/v1/organizations/${organizationId}/tableau_authentication`;

const buildViewUrl = ({ serverBaseUrl, site, workbook, view }) =>
  `${serverBaseUrl}/t/${site}/views/${workbook}/${view}`;

const addFilterEventListener = ({ tableauApiInstance, tableauVizInstance, onFilterApplied }) => {
  tableauVizInstance.addEventListener(tableauApiInstance.TableauEventName.FILTER_CHANGE, async (event) => {
    try {
      const filterName = get(event, ['$filterCaption']);
      const workbook = tableauVizInstance.getWorkbook();
      const activeSheet = await workbook.getActiveSheet();
      const filter = await activeSheet.getFilterAsync(filterName);
      const filteredValues = get(filter, ['$appliedValues']);
      await onFilterApplied(filterName, filteredValues);
    } catch (error) {
      reportErrorInProductionOrThrow(error);
    }
  });
};

export { addFilterEventListener, buildAuthUrl, buildViewUrl, EXPORT_TYPES, TABLEAU_API_SCRIPT_SRC };
