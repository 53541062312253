import React from 'react';

import { serverDateToLocal } from '../../../../../DateTimeUtils';
import { PropertyDetails } from '../../../../core';
import { PropertyDetailsRow } from '../../../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { DATE_OF_SERVICE_FIELDS } from '../../../FormFragments/FormFieldsDefinitions';
import type { Option } from '../../../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../../../hooks/useLegalActionsConfiguration';
import { getLabelByKey } from '../../../LegalActionsUtils';
import type { LegalActionDateOfServiceApiResponse } from '../../../types';

interface DateOfServiceContentProps {
  dateOfService: LegalActionDateOfServiceApiResponse;
  rows: 1 | 2;
}

export const DateOfServiceContent: React.FC<DateOfServiceContentProps> = ({ dateOfService, rows }) => {
  const { serviceTypeOptions } = useLegalActionsConfiguration();

  const contactComponent = (
    <PropertyDetails title={DATE_OF_SERVICE_FIELDS.contact.label} text={dateOfService.contact_name} />
  );
  const dateComponent = (
    <PropertyDetails
      title={DATE_OF_SERVICE_FIELDS.dateOfService.label}
      text={serverDateToLocal(dateOfService.date_of_service)}
    />
  );
  const serviceTypeComponent = (
    <PropertyDetails
      title={DATE_OF_SERVICE_FIELDS.serviceType.label}
      text={getLabelByKey(serviceTypeOptions as Option[], dateOfService.service_type)}
    />
  );

  return rows === 1 ? (
    <PropertyDetailsRow>
      {contactComponent}
      {dateComponent}
      {serviceTypeComponent}
    </PropertyDetailsRow>
  ) : (
    <>
      <div className="flex w-full">
        <PropertyDetailsRow>
          {contactComponent}
          {dateComponent}
        </PropertyDetailsRow>
      </div>
      <div className="flex w-full">
        <PropertyDetailsRow>
          {serviceTypeComponent}
          <PropertyDetails title={DATE_OF_SERVICE_FIELDS.otherText.label} text={dateOfService.other_text} />
        </PropertyDetailsRow>
      </div>
    </>
  );
};
