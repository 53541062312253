/**
 *  This file is contains the JS doc type definitions for the permission enums,
 *  and the classes used to instantiate them as a configuration object for the components in the profile configuration screens.
 *  When they are consumed as a permissions actions / verbs in the context of a permission restriction, all classes are treated the same way.
 */

/**
 * @typedef {Object} InnerSectionConfig
 * @property {string} id
 * @property {string} title
 */

/**
 * @typedef {Object.<string, InnerSectionConfig>} InnerSectionsConfig
 */

/**
 * @typedef {Object} PreconditionConfig
 * @property {SectionHeader|InnerSectionItem|string} field - permission action definition
 * @property {PermissionVerbEnumMember} needsToBeAtLeast - the minimal verb needed in the actions defined in 'id'
 * @property {PermissionVerbEnumMember} whenVerbIsEqualOrGreaterThan - the minimal verb for this action to trigger precondition check
 * @property {string} errorMessage - error message to display when precondition is not met
 */

/**
 * @typedef {Object} PermissionActionsEnumValueBaseConfig
 * @property {string} title
 * @property {string} subtitle
 * @property {string} description
 * @property {PermissionVerbEnumMember[]} allowedVerbs
 * @property {PreconditionConfig[]} [preconditions=[]]
 * @property {Object.<string, PermissionVerbEnumMember>} defaultProfiles
 */

/**
 * @typedef {Object} __SectionHeaderAdditional
 * @property {InnerSectionsConfig} innerSections
 * @property {number} sectionHeaderIndex
 */

/**
 * @typedef {PermissionActionsEnumValueBaseConfig & __SectionHeaderAdditional} SectionHeaderConfig
 */

/**
 * @typedef {Object} __InnerSectionItemAdditional
 * @property {SectionHeader} section
 * @property {InnerSectionConfig} innerSection
 * @property {number} index
 */

/**
 * @typedef {PermissionActionsEnumValueBaseConfig & __InnerSectionItemAdditional} InnerSectionItemConfig
 */

/**
 * PermissionActionsEnumValueBase class
 */
class PermissionActionsEnumValueBase {
  /**
   * @type {string}
   */
  id;
  /**
   * @type {string}
   */
  title;
  /**
   * @type {string}
   */
  subtitle;
  /**
   * @type {string}
   */
  description;
  /**
   * @type {PermissionVerbEnumMember[]}
   */
  allowedVerbs;
  /**
   * @type {boolean}
   */
  isSectionHeader;

  /**
   * @type {PreconditionConfig[]}
   */
  preconditions;

  /**
   * @type {Object.<string, PermissionVerbEnumMember>}
   */
  defaultProfiles;

  /**
   * @param {PermissionActionsEnumValueBaseConfig} config
   * @memberOf PermissionActionsEnumValueBase
   */
  constructor(config) {
    this.title = config.title;
    this.subtitle = config.subtitle;
    this.description = config.description;
    this.allowedVerbs = config.allowedVerbs?.map((verb) => verb.id);
    this.preconditions = config.preconditions ?? [];
    this.defaultProfiles = config.defaultProfiles ?? {};
  }
}

/**
 * SectionHeader class
 */
export class SectionHeader extends PermissionActionsEnumValueBase {
  /**
   * @type {number}
   */
  sectionHeaderIndex;
  /**
   * @type {InnerSectionsConfig}
   */
  innerSections;

  /**
   *
   * @param {SectionHeaderConfig} config
   * @memberOf SectionHeader
   */
  constructor(config) {
    super(config);

    this.isSectionHeader = true;
    this.sectionHeaderIndex = config.sectionHeaderIndex;
    this.innerSections = config.innerSections;
  }
}

export class InnerSectionItem extends PermissionActionsEnumValueBase {
  /**
   * @type {SectionHeader}
   */
  section;
  /**
   * @type {InnerSectionConfig}
   */
  innerSection;
  /**
   * @type {number}
   */
  index;

  /**
   * @param {InnerSectionItemConfig} config
   * @memberOf InnerSectionItem
   */
  constructor(config) {
    super(config);

    this.isSectionHeader = false;

    this.section = config.section;
    this.innerSection = config.innerSection;
    this.index = config.index;
  }
}

/**
 * @typedef PermissionVerbEnumMemberConfig
 * @property {string} displayName
 * @property {number} hierarchyIndex
 * @property {string[]} optionValue
 */

/**
 * PermissionActionsEnum class
 */
export class PermissionVerbEnumMember {
  /**
   * @type {string}
   */
  displayName;
  /**
   * @type {number}
   */
  hierarchyIndex;
  /**
   * @type {string[]}
   */
  optionValue;

  /**
   * @param {PermissionVerbEnumMemberConfig} config
   * @memberOf PermissionVerbEnumMember
   */
  constructor(config) {
    this.displayName = config.displayName;
    this.hierarchyIndex = config.hierarchyIndex;
    this.optionValue = config.optionValue;
  }

  /**
   * @param {PermissionVerbEnumMember} verb
   * @returns {boolean}
   */
  isEqualOrGreaterThan(verb) {
    return this.hierarchyIndex >= verb.hierarchyIndex;
  }

  /**
   * @param {PermissionVerbEnumMember} verb
   * @returns {boolean}
   */
  isEqualOrLessThan(verb) {
    return this.hierarchyIndex <= verb.hierarchyIndex;
  }
}

export class ConfigEnumBase {
  _setKeysOnChildren() {
    Object.keys(this).forEach((key) => (this[key].id = key));
  }
}

export class DefaultProfile {
  /**
   * @type {string}
   */
  id;
  /**
   * @type {string}
   */
  display_name;
  /**
   * @type {string}
   */
  defaults;
  /**
   * @type {boolean}
   */
  isSelectableInWizard;
  /**
   * @type {Object.<string, string>}
   */
  permissions = {};

  constructor(config) {
    this.display_name = config.display_name;
    this.defaults = config.defaults;
    this.description = config.description;
    this.isSelectableInWizard = config.isSelectableInWizard;
  }

  /**
   * @param {PERMISSION_ACTIONS} permissions
   */
  assignPermissions(permissions) {
    Object.values(permissions).forEach((permission) => {
      if (permission.defaultProfiles[this.id]) {
        this.permissions[permission.id] = permission.defaultProfiles[this.id].optionValue;
      }
    });
  }
}
