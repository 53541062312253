import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const NoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      d="M19.9375 20.5C19.9375 20.3508 19.9968 20.2077 20.1023 20.1023C20.2077 19.9968 20.3508 19.9375 20.5 19.9375H23.5C23.6492 19.9375 23.7923 19.9968 23.8977 20.1023C24.0032 20.2077 24.0625 20.3508 24.0625 20.5C24.0625 20.6492 24.0032 20.7923 23.8977 20.8977C23.7923 21.0032 23.6492 21.0625 23.5 21.0625H20.5C20.3508 21.0625 20.2077 21.0032 20.1023 20.8977C19.9968 20.7923 19.9375 20.6492 19.9375 20.5ZM31.5625 16.75V28.75C31.5625 29.0981 31.4242 29.4319 31.1781 29.6781C30.9319 29.9242 30.5981 30.0625 30.25 30.0625H13.75C13.4019 30.0625 13.0681 29.9242 12.8219 29.6781C12.5758 29.4319 12.4375 29.0981 12.4375 28.75V16.75C12.4375 16.4019 12.5758 16.0681 12.8219 15.8219C13.0681 15.5758 13.4019 15.4375 13.75 15.4375H17.6875V14.5C17.6875 13.953 17.9048 13.4284 18.2916 13.0416C18.6784 12.6548 19.203 12.4375 19.75 12.4375H24.25C24.797 12.4375 25.3216 12.6548 25.7084 13.0416C26.0952 13.4284 26.3125 13.953 26.3125 14.5V15.4375H30.25C30.5981 15.4375 30.9319 15.5758 31.1781 15.8219C31.4242 16.0681 31.5625 16.4019 31.5625 16.75ZM18.8125 15.4375H25.1875V14.5C25.1875 14.2514 25.0887 14.0129 24.9129 13.8371C24.7371 13.6613 24.4986 13.5625 24.25 13.5625H19.75C19.5014 13.5625 19.2629 13.6613 19.0871 13.8371C18.9113 14.0129 18.8125 14.2514 18.8125 14.5V15.4375ZM13.5625 16.75V20.7616C16.1455 22.1892 19.0487 22.9379 22 22.9375C24.9515 22.938 27.8547 22.189 30.4375 20.7606V16.75C30.4375 16.7003 30.4177 16.6526 30.3826 16.6174C30.3474 16.5823 30.2997 16.5625 30.25 16.5625H13.75C13.7003 16.5625 13.6526 16.5823 13.6174 16.6174C13.5823 16.6526 13.5625 16.7003 13.5625 16.75ZM30.4375 28.75V22.0347C27.8246 23.3674 24.9332 24.0623 22 24.0625C19.0669 24.0628 16.1754 23.3682 13.5625 22.0356V28.75C13.5625 28.7997 13.5823 28.8474 13.6174 28.8826C13.6526 28.9177 13.7003 28.9375 13.75 28.9375H30.25C30.2997 28.9375 30.3474 28.9177 30.3826 28.8826C30.4177 28.8474 30.4375 28.7997 30.4375 28.75Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default NoteIcon;
