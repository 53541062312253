import React from 'react';
import PropTypes from 'prop-types';

import CardDialog from '../../CardDialog';
import { FsButton, Heading, LoadingSwitch, Text } from '../../core';

import ErrorComponent from './ErrorComponent';

import styles from './additionalFinanceTableMenuOptions.module.scss';

const ResendEmailDialog = ({ onClose, isLoading, errorMessage, isError }) => {
  return (
    <CardDialog
      onClose={onClose}
      isDialog
      maxWidth="sm"
      fullWidth
      containerClassName={styles.resendEmailDialogContainer}
    >
      <LoadingSwitch
        isLoading={isLoading}
        isError={isError}
        ErrorComponent={errorMessage ? () => <ErrorComponent message={errorMessage} /> : undefined}
      >
        <Heading variant={Heading.TYPES.H1}>Communication was sent successfully</Heading>
        <Text
          variant={Text.VARIANTS.SM}
          colorVariant={Text.COLOR_VARIANTS.PRIMARY}
          weight={Text.WEIGHTS.REGULAR}
          className={styles.text}
        >
          The payment communication was resent successfully.
        </Text>
        <div className={styles.buttonContainer}>
          <FsButton
            variant={FsButton.VARIANTS.contained}
            color={FsButton.COLORS.primary}
            onClick={() => {
              onClose();
            }}
          >
            OK
          </FsButton>
        </div>
      </LoadingSwitch>
    </CardDialog>
  );
};

ResendEmailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default ResendEmailDialog;
