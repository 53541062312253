import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import CancelButton from '../Buttons/CancelButton';
import { FsButton } from '../FsWrappers';

import styles from './index.module.scss';

const DialogFooterActions = ({
  primaryLabel = 'Save',
  secondaryLabel = 'Cancel',
  onClickPrimary = noop,
  onClickSecondary = noop,
  disabled = false,
  primaryDisabled = false,
  primaryTooltip = undefined,
  buttonsOverride = undefined,
  showPrimary = true,
  showSecondary = true,
  ignorePermissionsSecondary = false,
}) => {
  return (
    <div className={styles.actionsContainer}>
      {buttonsOverride ? (
        buttonsOverride
      ) : (
        <>
          {showSecondary && (
            <CancelButton
              disabled={disabled}
              onClick={onClickSecondary}
              content={secondaryLabel}
              withMarginRight={showPrimary}
              ignorePermissions={ignorePermissionsSecondary}
            />
          )}
          {showPrimary && (
            <FsButton
              variant="contained"
              color="primary"
              disabled={disabled || primaryDisabled}
              tooltipText={primaryTooltip}
              onClick={onClickPrimary}
            >
              {primaryLabel}
            </FsButton>
          )}
        </>
      )}
    </div>
  );
};

DialogFooterActions.propTypes = {
  disabled: PropTypes.bool,
  primaryDisabled: PropTypes.bool,
  primaryTooltip: PropTypes.string,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
  buttonsOverride: PropTypes.node,
  showPrimary: PropTypes.bool,
  showSecondary: PropTypes.bool,
  ignorePermissionsSecondary: PropTypes.string,
};

export default DialogFooterActions;
