import React from 'react';

import { useLegalAction } from '~/components/LegalActions/hooks/useLegalAction';
import { PartyContent } from '~/components/LegalActions/LegalActionScreen/InfoTab/Parties/PartyContent';

import { ObjectCard } from '../../../../core';
import type { CardType } from '../../../../core/NewDesignSystem/Cards/types';
import { LegalActionItemMenu } from '../../../components/LegalActionItemMenu';
import { getPartyTypeLabel } from '../../../LegalActionsUtils';
import type { LegalActionPartyApiResponse } from '../../../types';
import { PartyType } from '../../../types';

interface PartyObjectCardProps {
  party: LegalActionPartyApiResponse;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onViewMore: () => void;
  onTerminateRepresentation: () => void;
}

export const PartyObjectCard: React.FC<PartyObjectCardProps> = ({
  party,
  onEditClick,
  onDeleteClick,
  onViewMore,
  onTerminateRepresentation,
}) => {
  const { legalAction } = useLegalAction();
  const cardType: CardType = party.is_carrier_party ? 'filled' : 'contained';
  const cardTitle = party.is_carrier_party ? 'Carrier Involved party' : undefined;
  const partyTypeLabel = getPartyTypeLabel(party.type);

  const isOnlyPlaintiff =
    legalAction?.legal_action_parties?.filter((party) => party.type === PartyType.PLAINTIFF).length === 1 &&
    party.type === PartyType.PLAINTIFF;

  return (
    <ObjectCard
      type={cardType}
      title={cardTitle}
      action={
        <LegalActionItemMenu
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          entityLabel={partyTypeLabel}
          onViewMore={onViewMore}
          disabledActions={isOnlyPlaintiff ? ['delete'] : []}
          additionalOptions={[
            {
              key: 'terminate',
              node: 'Terminate Representation',
              disabled: !party.date_of_representation,
              onClick: () => {
                onTerminateRepresentation();
              },
            },
          ]}
        />
      }
    >
      <PartyContent party={party} singleRow />
    </ObjectCard>
  );
};
