import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { isExpirationAfterEffectiveDT } from '../PolicyUtils';

import CardDialog from './CardDialog';
import WithConfirm from './ConfirmModal';
import { DatePickerTextFieldFormik, TimePickerTextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function UpdatePolicyEffectiveDateDialog(props) {
  const { policy, open, onSubmit, onClose } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        effective_date: policy.policy_effective_date,
        expiration_date: policy.policy_expiration_date,
        effective_time: policy.effective_time,
        expiration_time: policy.expiration_time,
      }}
      validationSchema={Yup.object().shape({
        effective_date: Yup.string().required('Required'),
        effective_time: Yup.string()
          .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
          .nullable(),
        expiration_date: Yup.string()
          .required('Required')
          .test(
            'expiration_after_effective_dt',
            'Expiration date must be after effective date',
            isExpirationAfterEffectiveDT
          ),
        expiration_time: Yup.string()
          .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
          .nullable(),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onSubmit(values)
          .then(() => formikProps.resetForm())
          .catch(() => formikProps.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <CardDialog
          isDialog={true}
          open={open}
          title="Edit Policy Effective Period"
          maxWidth="sm"
          onClose={onClose}
          preventClose={formikProps.isSubmitting}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DatePickerTextFieldFormik
                id="effective_date"
                label="Effective Date"
                className={classes.textField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TimePickerTextFieldFormik
                id="effective_time"
                label="Effective Time"
                className={classes.textField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerTextFieldFormik
                id="expiration_date"
                label="Expiration Date"
                className={classes.textField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TimePickerTextFieldFormik
                id="expiration_time"
                label="Expiration Time"
                className={classes.textField}
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            {policy.is_manually_created ? (
              <Button
                variant="contained"
                disabled={formikProps.isSubmitting}
                color="primary"
                onClick={() => formikProps.handleSubmit()}
              >
                Update
              </Button>
            ) : (
              <WithConfirm
                title="Update Policy?"
                contentText="This change will effect the policy and any claim related to it"
                primaryButtonName="Yes"
                shouldCloseOnPrimary={false}
              >
                <Button
                  variant="contained"
                  disabled={formikProps.isSubmitting}
                  color="primary"
                  onClick={() => formikProps.handleSubmit()}
                >
                  Update
                </Button>
              </WithConfirm>
            )}
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

UpdatePolicyEffectiveDateDialog.propTypes = {
  policy: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UpdatePolicyEffectiveDateDialog;
