import React from 'react';
import PropTypes from 'prop-types';

import { PENDING_COLOR } from '../ExposureUtils';

import SumContainer from './SumContainer';

const CELL_TYPES = {
  INDEMNITY_RESERVES: 'indemnity_reserve',
  EXPENSES_RESERVES: 'expenses_reserve',
  INDEMNITY_PAID_SUM: 'indemnity_paid_sum',
  EXPENSES_PAID_SUM: 'expenses_paid_sum',
  TOTAL_DEDUCTIBLE_AMOUNT: 'total_deductible_amount',
};

const TotalIncurredCell = ({ claim, cellType, totalSum }) => {
  const isExposurePaymentPending = (cellType, exposure) => {
    const indemnity = exposure.indemnity || {};
    const expenses = exposure.expenses || {};
    const generalExpenses = claim.general_expenses || {};

    switch (cellType) {
      case CELL_TYPES.INDEMNITY_PAID_SUM:
        return !!indemnity.pending_sum;

      case CELL_TYPES.INDEMNITY_RESERVES:
        return !!indemnity.reserve_change_request_pending;

      case CELL_TYPES.EXPENSES_PAID_SUM:
        return !!expenses.pending_sum || !!generalExpenses.pending_sum;

      case CELL_TYPES.EXPENSES_RESERVES:
        return !!expenses.reserve_change_request_pending || !!generalExpenses.reserve_change_request_pending;

      default:
        return false;
    }
  };

  const isPendingRequest = claim.exposures.some((exposure) => isExposurePaymentPending(cellType, exposure));

  return <SumContainer sum={totalSum} color={isPendingRequest ? PENDING_COLOR : undefined} fontWeight={700} />;
};

TotalIncurredCell.propTypes = {
  claim: PropTypes.object.isRequired,
  cellType: PropTypes.string.isRequired,
  totalSum: PropTypes.number.isRequired,
};

TotalIncurredCell.CELL_TYPES = CELL_TYPES;

export default TotalIncurredCell;
