import React from 'react';

import type { IconProps } from '~/components/icons/types';
import colors from '~/theme/tailwind/colors';

interface Props extends React.SVGProps<SVGSVGElement>, IconProps {
  disableStrokeColor?: boolean;
}
const SvgIcon: React.FC<Props> = ({
  iconColor = colors.slate['900'],
  size,
  disabled,
  children,
  disableStrokeColor = false,
  className,
  ...rest
}) => {
  const svgProps = {
    fill: iconColor,
    ...(disableStrokeColor
      ? {}
      : {
          stroke: iconColor,
        }),
    opacity: disabled ? 0.26 : 1,
    width: size || 16,
    height: size || 16,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...svgProps} className={className} {...rest}>
      {children}
    </svg>
  );
};

export default SvgIcon;
