import { useEffect, useState } from 'react';

const useScript = ({ id, scriptUrl, isModule = false }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');

    if (id) {
      script.id = id;
    }
    if (isModule) {
      script.type = 'module';
    }
    script.src = scriptUrl;
    script.async = true;
    script.onload = () => {
      setIsLoading(false);
      setIsLoaded(true);
    };

    script.onerror = () => {
      setIsError(true);
      setIsLoading(false);
    };

    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, [scriptUrl, id, isModule]);

  return {
    isLoading,
    isError,
    isLoaded,
  };
};

export default useScript;
