import React from 'react';
import { FieldArray } from 'formik';

import { AddItemButton, FormItemCard, SecondaryCard } from '../../../core';
import { DisputeResolutionProfessionalForm } from '../../FormFragments/DisputeResolutionProfessionalForm';
import { LEGAL_ACTION_FIELDS } from '../../FormFragments/FormFieldsDefinitions';
import type {
  DisputeResolutionProfessionalFormValues,
  NewLegalActionValues,
} from '../../FormFragments/FormValuesTypes';

import { DeleteIconButton } from './DeleteIconButton';

export const DisputeResolutionProfessionalFormCard: React.FC = () => {
  return (
    <SecondaryCard type="contained" title="Dispute Resolution Professionals" collapsible openByDefault>
      <FieldArray
        name={LEGAL_ACTION_FIELDS.disputeResolutionProfessionals.key}
        render={({ form, remove, push }) => {
          const disputeResolutionProfessionals = (form.values as NewLegalActionValues).dispute_resolution_professionals;

          return (
            <>
              {disputeResolutionProfessionals.map(
                (disputeResolutionProfessional: DisputeResolutionProfessionalFormValues, index: number) => {
                  const title = `Dispute Resolution Professional #${index + 1}`;

                  return (
                    <FormItemCard
                      key={title}
                      title={title}
                      action={<DeleteIconButton onClick={() => remove(index)} index={0} />}
                    >
                      <div className="flex w-full flex-col gap-20">
                        <DisputeResolutionProfessionalForm
                          arrayFieldPrefix={LEGAL_ACTION_FIELDS.disputeResolutionProfessionals.key}
                          index={index}
                        />
                      </div>
                    </FormItemCard>
                  );
                }
              )}
              <div>
                <AddItemButton
                  text="Add Dispute Resolution Professional"
                  variant="text"
                  onClick={() => {
                    push({});
                  }}
                />
              </div>
            </>
          );
        }}
      />
    </SecondaryCard>
  );
};
