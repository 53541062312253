import React from 'react';

import useDataFetcher from '../../useDataFetcher';

import { useLegalActionsBackendRoutes } from './useLegalActionsBackendRoutes';

export interface Option {
  key: string;
  description: string;
  is_other?: boolean;
  categories?: string[];
}

export interface LegalActionsConfigurationContextValue {
  priorityOptions?: Option[];
  lawsuitTypeOptions?: Option[];
  disputeResolutionProfessionalTypeOptions?: Option[];
  adrTypeOptions?: Option[];
  methodOfConclusionOptions?: Option[];
  reopenReasonOptions?: Option[];
  natureOfDisputeOptions?: Option[];
  serviceTypeOptions?: Option[];
  prosAndConsOptions?: Option[];
  partyTypeOptions?: Option[];
  earlyCaseResolutionExploredOptions?: Option[];
  statusOfLitigationOptions?: Option[];
  logTypeOptions?: Option[];
  isLoading?: boolean;
  isError?: boolean;
  reloadData?: () => void;
}

const LegalActionsConfigurationContext = React.createContext<LegalActionsConfigurationContextValue>({});

const sortOptions = (options: Option[] = []) => {
  const sorted = options
    .sort((a, b) => a.description.localeCompare(b.description))
    .filter((option) => !option.is_other);
  const other = options.find((option) => option.is_other);
  return [...sorted, ...(other ? [other] : [])];
};

export const LegalActionsConfigurationProvider: React.FC = ({ children }) => {
  const backendRoutes = useLegalActionsBackendRoutes();

  const {
    data: legalActionsConfiguration,
    isLoading,
    isError,
    reloadData,
  } = useDataFetcher(backendRoutes.legalActionsConfiguration);

  return (
    <LegalActionsConfigurationContext.Provider
      value={{
        priorityOptions: sortOptions(legalActionsConfiguration?.priority_options),
        lawsuitTypeOptions: sortOptions(legalActionsConfiguration?.lawsuit_type_options),
        disputeResolutionProfessionalTypeOptions: sortOptions(
          legalActionsConfiguration?.dispute_resolution_professional_type_options
        ),
        adrTypeOptions: sortOptions(legalActionsConfiguration?.adr_type_options),
        methodOfConclusionOptions: sortOptions(legalActionsConfiguration?.method_of_conclusion_options),
        reopenReasonOptions: sortOptions(legalActionsConfiguration?.reopen_reason_options),
        natureOfDisputeOptions: sortOptions(legalActionsConfiguration?.nature_of_dispute_options),
        serviceTypeOptions: sortOptions(legalActionsConfiguration?.service_types_options),
        prosAndConsOptions: sortOptions(legalActionsConfiguration?.pros_and_cons_options),
        partyTypeOptions: sortOptions(legalActionsConfiguration?.party_type_options),
        earlyCaseResolutionExploredOptions: sortOptions(
          legalActionsConfiguration?.early_case_resolution_explored_options
        ),
        statusOfLitigationOptions: sortOptions(legalActionsConfiguration?.status_of_litigation_options),
        logTypeOptions: sortOptions(legalActionsConfiguration?.log_type_options),
        isLoading,
        isError,
        reloadData,
      }}
    >
      {children}
    </LegalActionsConfigurationContext.Provider>
  );
};

export const useLegalActionsConfiguration = (): LegalActionsConfigurationContextValue => {
  return React.useContext(LegalActionsConfigurationContext);
};
