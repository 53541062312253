import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { flatten, noop, pickBy, uniq } from 'lodash';

import useCachedDataFetcher from '~/components/hooks/useCachedDataFetcher';
import { useCms } from '~/components/hooks/useCms';
import LoadingIndicator from '~/components/LoadingIndicator';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

const OrganizationContext = React.createContext({});

function OrganizationContextProvider(props) {
  const { children } = props;
  const { user, userOrganization } = useCms();
  const [showLoading, setShowLoading] = useState(false);
  const organizationId = props.organizationId || user.organization_id;
  const isCachedEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.PERFORMANCE_CACHE_OPERATIONAL_DETAILS
  );

  const {
    isLoading: isLoadingOperationalDetails,
    isError: isErrorOperationalDetails,
    data: operationalDetailsData,
    reloadData: reloadOperationalDetails,
  } = useCachedDataFetcher({
    url: `/api/v1/organizations/${organizationId}/operational_details`,
    shouldUseCache: isCachedEnabled,
    localCacheKey: `operational_details_${organizationId}`,
    shouldFetch: !props.operationalDetailsData,
  });

  const {
    isLoading: isLoadingContactRoles,
    isError: isErrorContactRoles,
    data: organizationContactRolesDict,
    reloadData: reloadContactRoles,
  } = useCachedDataFetcher({
    url: `/api/v1/contact_roles/organizations/${organizationId}/roles`,
    shouldUseCache: isCachedEnabled,
    localCacheKey: `contact_roles_${organizationId}`,
    shouldFetch: !props.organizationContactRolesDict,
  });

  const isLoading = isLoadingOperationalDetails || isLoadingContactRoles;
  const isError = isErrorOperationalDetails || isErrorContactRoles;

  useEffect(() => {
    // Set timeout to delay the loading component
    const timer = setTimeout(() => {
      if (isLoading) {
        setShowLoading(true);
      }
    }, 1000);

    // Cleanup timeout on component unmount
    return () => clearTimeout(timer);
  }, [isLoading]);

  let contextValues = { isLoading, isError };
  if (!isLoading && !isError) {
    contextValues = {
      ...contextValues,
      organizationId,
      ...prepareOperationalDetails(operationalDetailsData || props.operationalDetailsData),
      organizationContactRolesDict: organizationContactRolesDict || props.organizationContactRolesDict,
      reloadOperationalDetails,
      reloadContactRoles,
      fetchLobs: (sub_orgs_ids) => {
        try {
          const route = `/api/v1/organizations/${organizationId}/lobs`;
          return axios.get(route, {
            params: { sub_orgs_ids },
          });
        } catch (error) {
          return reportAxiosError(error);
        }
      },
    };
  }

  if (isLoading) {
    return showLoading ? <LoadingIndicator isError={isError} /> : null;
  }

  return <OrganizationContext.Provider value={contextValues}>{children}</OrganizationContext.Provider>;
}

OrganizationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  organizationId: PropTypes.number,
  operationalDetailsData: PropTypes.object,
  organizationContactRolesDict: PropTypes.object,
};

function prepareOperationalDetails(apiRetData) {
  const {
    google_places_api_key: googlePlacesApiKey,
    mor_configuration: morConfiguration,
    is_managed_repair_enabled,
    legacy_expense_payment_types: legacyExpensePaymentTypes,
    legacy_indemnity_payment_types: legacyIndemnityPaymentTypes,
    outgoing_physical_mail_method: outgoingPhysicalMailMethod,
    policy_search_config: policySearchConfig,
    supported_claim_types: supportedClaimTypes,
    supported_coverages: supportedCoverages,
    incident_types_dict: incidentTypesDict,
    injury_types_dict: injuryTypesDict,
    body_parts_dict: bodyPartsDict,
    is_body_parts_list: isBodyPartList,
    gl_source_codes_dict: glSourceCodesDict,
    gl_cause_codes_dict: glCauseCodesDict,
    insured_properties_and_locations: insuredPropertiesAndLocationsDict,
    home_causes_of_loss_dict: homeCausesOfLossDict,
    reopen_exposure_options: reopenExposureOptions,
    reopen_claim_options: reopenClaimOptions,
    incident_details_extra_fields: incidentDetailsExtraFields,
    exposures_closing_reasons_dict: exposuresClosingReasonsDict,
    document_types_dict: documentTypesDict,
    org_additional_configs: orgAdditionalConfigs,
    moi_options: moiOptions,
    fnol_submission_types: fnolSubmissionTypes,
    org_required_fields_for_close_claim: orgRequiredFieldsForCloseClaim,
    sub_reserves: subReserves,
    moi_options_order: moiOptionsOrder,
    litigation_status_dict: litigationStatusDict,
    eob_adjustment_reasons: eobAdjustmentReasons,
    sub_organizations_enabled: subOrganizationEnabled,
    sub_organizations: subOrganizations,
    is_twilio_enabled: isTwilioEnabled,
    is_dyte_video_initialized: isDyteVideoInitialized,
    is_dyte_video_enabled: isDyteVideoEnabled,
    vehicle_types_dict: vehicleTypesDict,
    close_claim_reasons: closeClaimReasons,
    is_general_email_queue_enabled: isGeneralEmailQueueEnabled,
    is_catastrophe_events_enabled: isCatastropheEventsEnabled,
    is_performance_qa_enabled: isPerformanceQaEnabled,
    org_tableau_configs: orgTableauConfigs,
    org_moi_expertises: organizationMoiExpertises,
    is_policy_api_enabled: isPolicyAPIEnabled,
    notifications_tokens: notificationsTokens,
    notification_usable_tokens_map: notificationUsableTokensMap,
    coverage_configuration: coverageConfiguration,
    is_navigator_enabled: isNavigatorEnabled,
    is_claim_search_enabled: isClaimSearchEnabled,
    is_xactanalysis_enabled: isXactAnalysisEnabled,
    is_notes_and_notifications_enabled: isNotesAndNotificationsEnabled,
    org_level_supported_lobs: orgLevelSupportedLobs,
    claim_numbering_scheme: claimNumberingScheme,
    lob_configuration_dict: lobConfigurationsDict,
    twilio_configuration: twilioConfiguration,
    control_file_options: controlFileOptions,
    email_domains: emailDomains,
    is_license_correlation_enabled: isLicenseCorrelationEnabled,
    tableau_accounts_configuration: tableauAccountsConfiguration,
    moi_configuration: moiConfiguration,
    is_ccc_enabled: isCccEnabled,
    payments_configuration: paymentsConfiguration,
    is_multiple_countries_enabled: isMultipleCountriesEnabled,
  } = apiRetData;

  return {
    googlePlacesApiKey,
    morConfiguration,
    managedRepairStatus: { is_managed_repair_enabled },
    outgoingPhysicalMailMethod,
    policySearchConfig,
    supportedClaimTypes,
    supportedCoverages,
    incidentTypesDict,
    injuryTypesDict,
    bodyPartsDict,
    isBodyPartList,
    glSourceCodesDict,
    glCauseCodesDict,
    reopenExposureOptions,
    reopenClaimOptions,
    insuredPropertiesAndLocationsDict,
    homeCausesOfLossDict,
    legacyExpensePaymentTypes,
    legacyIndemnityPaymentTypes,
    incidentDetailsExtraFields,
    exposuresClosingReasonsDict,
    documentTypesDict,
    orgAdditionalConfigs,
    moiOptions,
    fnolSubmissionTypes,
    orgRequiredFieldsForCloseClaim,
    subReserves,
    moiOptionsOrder,
    litigationStatusDict,
    eobAdjustmentReasons,
    subOrganizationEnabled,
    subOrganizations,
    isTwilioEnabled,
    isDyteVideoInitialized,
    isDyteVideoEnabled,
    vehicleTypesDict,
    closeClaimReasons,
    isGeneralEmailQueueEnabled,
    isCatastropheEventsEnabled,
    orgTableauConfigs,
    organizationMoiExpertises,
    isPolicyAPIEnabled,
    notificationsTokens,
    notificationUsableTokensMap,
    isPerformanceQaEnabled,
    coverageConfiguration,
    isNavigatorEnabled,
    isClaimSearchEnabled,
    isXactAnalysisEnabled,
    isNotesAndNotificationsEnabled,
    orgLevelSupportedLobs,
    claimNumberingScheme,
    lobConfigurationsDict,
    twilioConfiguration,
    controlFileOptions,
    emailDomains,
    isLicenseCorrelationEnabled,
    tableauAccountsConfiguration,
    moiConfiguration,
    isCccEnabled,
    paymentsConfiguration,
    isMultipleCountriesEnabled,
  };
}

const withOrganization = (WrappedComponent) => {
  class withOrganization extends React.Component {
    render() {
      return (
        <OrganizationContext.Consumer>
          {(organizationContext) => <WrappedComponent {...organizationContext} {...this.props} />}
        </OrganizationContext.Consumer>
      );
    }
  }

  withOrganization.displayName = `withOrganization(${getDisplayName(WrappedComponent)})`;
  return withOrganization;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

// Notice: possibleAdjustersWithDaSpecialist returns with removed users. remove them manually
function useOrganization() {
  return useContext(OrganizationContext);
}

function OrganizationContextUIOnlyProvider(props) {
  const { children, organizationValues, lobConfigurationsDict = {} } = props;

  const orgLevelSupportedLobs = organizationValues?.supported_lobs;
  const envType = organizationValues?.organization?.organization_type || 'operational';

  const fetchLobs = (sub_orgs_ids) => {
    let lobsToSend = [];
    if (organizationValues?.organization?.sub_organizations_enabled === false) {
      lobsToSend = orgLevelSupportedLobs;
    } else {
      const selectedSubOrgs =
        sub_orgs_ids && sub_orgs_ids.length
          ? organizationValues?.sub_organizations.filter(({ external_id }) => sub_orgs_ids.includes(external_id))
          : organizationValues?.sub_organizations;

      lobsToSend = uniq(flatten(selectedSubOrgs?.map(({ supported_lobs }) => supported_lobs.map(({ lob }) => lob))));
    }
    return { data: lobsToSend || [] };
  };

  const prepareCoverages = (coverages) => {
    return coverages?.map((coverage) => ({
      ...coverage,
      sub_organizations: coverage.sub_organization_ids.map((externalId) => {
        return organizationValues?.sub_organizations.find((subOrg) => subOrg.external_id === externalId);
      }),
    }));
  };

  const prepareSharedCoverages = (sharedCoverages, coverages) => {
    return prepareCoverages(sharedCoverages).map((sc) => ({
      ...sc,
      coverage_config_display_names: sc.coverage_config_ids
        .map((key) => {
          return coverages.find((c) => c.coverage_key === key)?.display_name;
        })
        .filter(Boolean),
    }));
  };

  const contextValues = {
    isLoading: false,
    isError: false,
    ...prepareOperationalDetails({
      ...organizationValues,
      supported_claim_types: fetchLobs(),
      org_level_supported_lobs: orgLevelSupportedLobs,
      lob_configuration_dict:
        envType === 'demo'
          ? lobConfigurationsDict
          : pickBy(lobConfigurationsDict, (value, _) => value?.env_type === 'production'),
    }),
    organizationContactRolesDict: {},
    subOrganizations: organizationValues?.sub_organizations?.map((subOrg) => ({ ...subOrg, id: subOrg.external_id })),
    coverages: prepareCoverages(organizationValues?.coverages),
    sharedCoverages: prepareSharedCoverages(organizationValues?.shared_coverages, organizationValues?.coverages),
    reloadOperationalDetails: noop,
    fetchLobs,
  };

  if (contextValues.organization) {
    contextValues.organization.sub_organizations = contextValues?.sub_organizations;
  }

  return <OrganizationContext.Provider value={contextValues}>{children}</OrganizationContext.Provider>;
}

OrganizationContextUIOnlyProvider.propTypes = {
  children: PropTypes.node.isRequired,
  organizationValues: PropTypes.object,
  lobConfigurationsDict: PropTypes.object,
};

export default useOrganization;
export {
  OrganizationContext,
  OrganizationContextProvider,
  OrganizationContextUIOnlyProvider,
  prepareOperationalDetails,
  withOrganization,
};
