import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { DAMAGE_ASSESSMENT_CATEGORY_CODES_DICT, DAMAGE_ASSESSMENT_EXCEPTION_CODES } from '../../Types';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import InlineIconButton from '../InlineIconButton';
import PlainTable from '../PlainTable';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function displayCategoryCode(categoryCode) {
  return `${categoryCode}-${DAMAGE_ASSESSMENT_CATEGORY_CODES_DICT[categoryCode]}`;
}

function DamageAssessmentReinspectionDialog(props) {
  const { cardDialogProps, claimId, exposure, damageAssessment, reinspectionReport, onCompleteReinspection } = props;
  const isReadOnly = !!reinspectionReport;

  const classes = useStyles();
  const [showAddExceptionDialog, setShowAddExceptionDialog] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [overwrites, setOverwrites] = React.useState([]);
  const [underwrites, setUnderwrites] = React.useState([]);
  const { currencyFormatter } = useCurrencyFormatter();

  React.useEffect(() => {
    // handle update of reinspectionReport
    if (reinspectionReport) {
      setOverwrites(reinspectionReport.overwrites);
      setUnderwrites(reinspectionReport.underwrites);
    }
  }, [reinspectionReport]);

  let columnData = [
    { id: 'area', label: 'Area/Room' },
    { id: 'category_code', label: 'Category Code', specialCell: (row) => displayCategoryCode(row.category_code) },
    { id: 'exception_code', label: 'Exception Code' },
    {
      id: 'amount',
      numeric: true,
      label: 'Exception Amount',
      specialCell: (row) => currencyFormatter.format(row.amount),
    },
    { id: 'note', label: 'Comment' },
  ];

  if (!isReadOnly) {
    columnData.push({
      // eslint-disable-next-line react/display-name
      id: 'remove_exception',
      disableSort: true,
      specialCell: (exception, isHover) =>
        isHover ? (
          <InlineIconButton
            icon={DeleteIcon}
            className={classes.textIcon}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveException(exception);
            }}
          />
        ) : (
          <div className={classes.textIconFiller} />
        ),
    });
  }

  function handleAddException(exceptionValues) {
    const { exception_type } = exceptionValues;
    const newException = { ...exceptionValues, id: uuidv4() };
    switch (exception_type) {
      case 'Overwrite':
        setOverwrites([...overwrites, newException]);
        break;
      case 'Underwrite':
        setUnderwrites([...underwrites, newException]);
        break;
      default:
        throw Error(`Unexpected exception_type: ${exception_type}`);
    }
    setShowAddExceptionDialog(false);
  }

  function handleRemoveException(exception) {
    const { exception_type } = exception;
    switch (exception_type) {
      case 'Overwrite':
        setOverwrites(overwrites.filter((overwrite) => overwrite.id !== exception.id));
        break;
      case 'Underwrite':
        setUnderwrites(underwrites.filter((underwrite) => underwrite.id !== exception.id));
        break;
      default:
        throw Error(`Unexpected exception_type: ${exception_type}`);
    }
  }

  function handleComplete() {
    setIsSubmitting(true);
    const reinspectionValues = { underwrites, overwrites };

    axios
      .post(
        `/api/v1/claims/${claimId}/exposures/${exposure.id}/damage_assessments/${damageAssessment.id}/reinspection`,
        reinspectionValues
      )
      .then((response) => {
        onCompleteReinspection(response.data);
      })
      .catch((error) => {
        reportAxiosError(error);
        setIsSubmitting(false);
      });
  }

  const grossEstimate = damageAssessment.amount;
  const totalOverwrite = overwrites.reduce((acc, overwrite) => acc + overwrite.amount, 0);
  const totalUnderwrite = underwrites.reduce((acc, underwrite) => acc + underwrite.amount, 0);

  return (
    <>
      <CardDialog title="Reinspection" isDialog maxWidth="md" fullWidth {...cardDialogProps}>
        <div className={classes.buttonsContainer}>
          {!isReadOnly && (
            <Button color="primary" onClick={() => setShowAddExceptionDialog(true)}>
              <AddIcon className={classes.leftButtonIcon} />
            </Button>
          )}
        </div>
        <div>
          <Typography display="block" variant="h6">
            Overwrites
          </Typography>
          <PlainTable rows={overwrites} columns={columnData} />
        </div>
        <div style={{ marginTop: '12px', marginBottom: '12px' }}>
          <Typography display="block" variant="h6">
            Underwrites
          </Typography>
          <PlainTable rows={underwrites} columns={columnData} />
        </div>
        <Divider variant="middle" />
        <div style={{ marginTop: '12px', marginBottom: '12px' }}>
          <Paper>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Gross Estimate: </strong>
                    {currencyFormatter.format(grossEstimate)}
                  </TableCell>
                  <TableCell>
                    <strong>Total Overwrite: </strong>
                    {currencyFormatter.format(totalOverwrite)}
                  </TableCell>
                  <TableCell>
                    <strong>Total Underwrite: </strong>
                    {currencyFormatter.format(totalUnderwrite)}
                  </TableCell>
                  <TableCell>
                    <strong>Total Exception Value: </strong>
                    {currencyFormatter.format(totalOverwrite + totalUnderwrite)}
                  </TableCell>
                  <TableCell>
                    <strong>Score: </strong>
                    {`${(((totalOverwrite + totalUnderwrite) / grossEstimate) * 100).toFixed(2)}%`}
                  </TableCell>
                  <TableCell>
                    <strong>Net Exception Variance: </strong>
                    {currencyFormatter.format(totalOverwrite - totalUnderwrite)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
        <div className={classes.buttonsContainer}>
          {!isReadOnly && (
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting || (underwrites.length === 0 && overwrites.length === 0)}
              onClick={handleComplete}
            >
              Complete Reinspection
            </Button>
          )}
        </div>
      </CardDialog>
      <AddDamageAssessmentExceptionDialog
        open={showAddExceptionDialog}
        onClose={() => setShowAddExceptionDialog(false)}
        onAddException={handleAddException}
      />
    </>
  );
}

DamageAssessmentReinspectionDialog.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  claimId: PropTypes.number.isRequired,
  exposure: PropTypes.object.isRequired,
  damageAssessment: PropTypes.object.isRequired,
  reinspectionReport: PropTypes.object,
  onCompleteReinspection: PropTypes.func.isRequired,
};

function AddDamageAssessmentExceptionDialog(props) {
  const classes = useStyles();

  const { onAddException, open, onClose } = props;
  const { currencyFormatter } = useCurrencyFormatter();

  return (
    <Formik
      initialValues={{
        exception_type: '',
        area: '',
        category_code: '',
        exception_code: '',
        amount: '',
        note: '',
      }}
      validationSchema={Yup.object().shape({
        exception_type: Yup.string().oneOf(['Overwrite', 'Underwrite']).required('Required'),
        area: Yup.string().required('Required'),
        category_code: Yup.string().required('Required'),
        exception_code: Yup.string().required('Required'),
        amount: Yup.number()
          .required('Required')
          .min(0.01, `Must be at least ${currencyFormatter.format(0.01)}`),
        note: Yup.string(),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        Promise.resolve(onAddException(values))
          .then(() => {
            formikProps.resetForm();
          })
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog title="Add Exception" isDialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
            <div>
              <TextFieldFormik
                id="exception_type"
                select
                label="Exception Type"
                className={classes.textField}
                fullWidth
              >
                {['Overwrite', 'Underwrite'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </div>
            <div>
              <TextFieldFormik id="area" label="Area / Room" className={classes.textField} fullWidth />
            </div>
            <div>
              <TextFieldFormik id="category_code" select label="Category Code" className={classes.textField} fullWidth>
                {Object.keys(DAMAGE_ASSESSMENT_CATEGORY_CODES_DICT).map((option) => (
                  <MenuItem key={option} value={option}>
                    {displayCategoryCode(option)}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </div>
            <div>
              <TextFieldFormik
                id="exception_code"
                select
                label="Exception Code"
                className={classes.textField}
                fullWidth
              >
                {DAMAGE_ASSESSMENT_EXCEPTION_CODES.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </div>
            <div>
              <MonetaryValueTextFieldFormik id="amount" label="Exception Amount" className={classes.textField} />
            </div>
            <div>
              <TextFieldFormik id="note" label="Comment" className={classes.textField} fullWidth />
            </div>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Add
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

AddDamageAssessmentExceptionDialog.propTypes = {
  onAddException: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default DamageAssessmentReinspectionDialog;
