import React, { useState } from 'react';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import { reportAxiosError } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import { useClaim } from '../../../ClaimContainer';
import { usePolicy } from '../../../PolicyContainer';
import PolicySearchContainer from '../../../PolicySearch';
import EditPolicyDialog from '../PolicyDetails/EditPolicyDialog/EditPolicyDialog';

import { useStyles } from '../../../../assets/styles';
import styles from './PolicyNotFound.module.scss';

const PolicyNotFound = () => {
  const [openEditPolicyDialog, setOpenEditPolicyDialog] = useState(false);
  const classes = useStyles();
  const { claim, onAsyncClaimUpdate } = useClaim();
  const [isAttachDisabled, setIsAttachDisabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    policy: { lob, type },
  } = usePolicy();

  async function handlePolicyUpdate(values) {
    setIsSaving(true);
    try {
      await axios.patch(`/api/v1/claims/${claim.id}/manual_policy`, { ...values, lob, type });
      await onAsyncClaimUpdate();
      setOpenEditPolicyDialog(false);
    } catch (error) {
      reportAxiosError(error);
      setIsSaving(false);
      throw error;
    }
    setIsSaving(false);
  }

  const handleManuallyAttachPolicy = async (policy) => {
    setIsAttachDisabled(true);
    try {
      await axios.post(`/api/v1/claims/${claim.id}/attach_policy`, { policy_id: policy.id });
      await onAsyncClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsAttachDisabled(false);
  };

  return (
    <>
      <Grid container className={styles.policyNotFoundPage}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography display="block" variant="h5" className={styles.pageHeader}>
            Policy
          </Typography>
          <CardDialog
            title="Attach claim to existing policy"
            headerStyle={{ padding: '0 0 24px' }}
            containerStyle={{ padding: '24px' }}
            contentStyle={{ padding: '0' }}
          >
            <PolicySearchContainer
              classes={classes}
              constSearchValues={{
                date_of_loss: claim.incident.date_of_loss,
                lob: claim.policy.lob,
                sub_org_id: claim.policy.sub_organization?.id,
              }}
              onSelectPolicy={handleManuallyAttachPolicy}
              policyTypes={[]}
              selectButtonText="Attach"
              disableSelectButton={isAttachDisabled}
              hideSubOrg
              hideDateOfLoss
              hideLob
              newDesign
              onSetManualPolicy={() => setOpenEditPolicyDialog(true)}
            />
          </CardDialog>
        </Grid>
      </Grid>
      {openEditPolicyDialog && (
        <EditPolicyDialog
          onSaveClick={(values) => handlePolicyUpdate(values)}
          onClose={() => !isSaving && setOpenEditPolicyDialog(false)}
          isSaving={isSaving}
          policyStatus="In Force"
          lob={lob}
          isEdit={false}
          policy={claim.policy}
        />
      )}
    </>
  );
};

export default PolicyNotFound;
