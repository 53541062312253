import { pickBy } from 'lodash';

import useOrganization from '../OrganizationContext';
import { useSysconfig } from '../SystemConfiguration/SystemConfigurationScreen';

const useLobConfiguration = () => {
  const { isLoading, isError, lobConfigurationsDict = {} } = useOrganization();
  const { organization = {}, allLobConfigurationsDict = {} } = useSysconfig();
  const isLobEnvTypeMatchOrg = (lob) =>
    organization?.organization_type === 'operational' ? lob?.env_type === 'production' : true;

  return {
    isLoading,
    isError,
    lobConfigurationsDict: { ...lobConfigurationsDict, ...pickBy(allLobConfigurationsDict, isLobEnvTypeMatchOrg) },
  };
};

export { useLobConfiguration };
