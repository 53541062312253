import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useClaim } from '~/components/ClaimContainer';
import { FsIconButton } from '~/components/core';
import { EditDocumentDialog } from '~/components/Documents/DocumentCard';
import { useCms } from '~/components/hooks/useCms';
import HoverActionField from '~/components/HoverActionField';
import { isClaimWriteDisabled } from '~/Utils/ClaimUtils';

import PencilIcon from '../icons/PencilIcon';

const EditDocumentContainer = (props) => {
  const { document, displayIcon, isIconButton } = props;
  const [showEditDocument, setShowEditDocument] = useState(false);
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user } = useCms();
  const disabled = !claim || isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true });

  return (
    <>
      {!disabled &&
        (isIconButton ? (
          <FsIconButton onClick={() => setShowEditDocument(true)}>
            <PencilIcon />
          </FsIconButton>
        ) : (
          <HoverActionField onAction={() => setShowEditDocument(true)} permanent={displayIcon} />
        ))}

      <EditDocumentDialog
        open={showEditDocument}
        claim={claim}
        onCancel={() => setShowEditDocument(false)}
        document={document}
        onUpdateDocument={async () => {
          await onAsyncClaimUpdate();
          setShowEditDocument(false);
        }}
      />
    </>
  );
};

EditDocumentContainer.propTypes = {
  displayIcon: PropTypes.bool,
  isIconButton: PropTypes.bool,
  document: PropTypes.object.isRequired,
};

export default EditDocumentContainer;
