import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { isTscPolicy } from '../../Utils';
import CardDialog from '../CardDialog';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { usePolicy } from '../PolicyContainer';
import RadioButtonFormik from '../RadioButtonFormik';

import DamageDialogContainer from './DamageDialogContainer';

import { useStyles } from '../../assets/styles';

function HomeDamagesDetailsContainer(props) {
  const { values, setFieldValue, disableNoOption } = props;

  return (
    <DamageDialogContainer
      damageId="home_damage"
      questionText="Was the home damaged?"
      customDialogTitle="Dwelling"
      values={values}
      setFieldValue={setFieldValue}
      disableNoOption={disableNoOption}
      CustomDetailsDialog={HomeDamagesDetailsDialog}
    />
  );
}

HomeDamagesDetailsContainer.propTypes = {
  values: PropTypes.object.isRequired, // from Formik
  setFieldValue: PropTypes.func.isRequired, // from Formik
  disableNoOption: PropTypes.bool,
};

function HomeDamagesDetailsDialog(props) {
  const { dialogTitle, onSubmitDetails, damageDetails, open, onCancel } = props;
  const { policy } = usePolicy();
  const classes = useStyles();
  const homeDamagedInitialValues = { multi_room_damaged: '', roof_damaged: '', exterior_damaged: '' };

  return (
    <Formik
      initialValues={{ ...homeDamagedInitialValues, ...damageDetails }}
      validationSchema={Yup.object().shape({
        multi_room_damaged: Yup.string().required('Required'),
        multi_floor_damaged: !isTscPolicy(policy) ? Yup.string().required('Required') : undefined,
        roof_damaged: Yup.string().required('Required'),
        exterior_damaged: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onSubmitDetails(values);
        return formikProps.resetForm();
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title={dialogTitle}
            fullWidth
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>
                  Multiple Rooms Damaged?
                </span>
                <ErrorHelperTextFormik style={{ marginTop: 0 }} id="multi_room_damaged" />
              </Grid>
              <Grid item xs={6}>
                <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                  <RadioButtonFormik id="multi_room_damaged" label="No" optionValue="no" />
                  <RadioButtonFormik id="multi_room_damaged" label="Yes" optionValue="yes" />
                  <RadioButtonFormik id="multi_room_damaged" label="Unknown" optionValue="unknown" />
                </div>
              </Grid>
              {!isTscPolicy(policy) && (
                <>
                  <Grid item xs={6}>
                    <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>
                      Multiple Floors / Levels Damaged?
                    </span>
                    <ErrorHelperTextFormik style={{ marginTop: 0 }} id="multi_floor_damaged" />
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                      <RadioButtonFormik id="multi_floor_damaged" label="No" optionValue="no" />
                      <RadioButtonFormik id="multi_floor_damaged" label="Yes" optionValue="yes" />
                      <RadioButtonFormik id="multi_floor_damaged" label="Unknown" optionValue="unknown" />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>Roof Damaged?</span>
                <ErrorHelperTextFormik style={{ marginTop: 0 }} id="roof_damaged" />
              </Grid>
              <Grid item xs={6}>
                <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                  <RadioButtonFormik id="roof_damaged" label="No" optionValue="no" />
                  <RadioButtonFormik id="roof_damaged" label="Yes" optionValue="yes" />
                  <RadioButtonFormik id="roof_damaged" label="Unknown" optionValue="unknown" />
                </div>
              </Grid>
              <Grid item xs={6}>
                <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>Exterior Damaged?</span>
                <ErrorHelperTextFormik style={{ marginTop: 0 }} id="exterior_damaged" />
              </Grid>
              <Grid item xs={6}>
                <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                  <RadioButtonFormik id="exterior_damaged" label="No" optionValue="no" />
                  <RadioButtonFormik id="exterior_damaged" label="Yes" optionValue="yes" />
                  <RadioButtonFormik id="exterior_damaged" label="Unknown" optionValue="unknown" />
                </div>
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

HomeDamagesDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  damageDetails: PropTypes.object.isRequired,
  onSubmitDetails: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default HomeDamagesDetailsContainer;
