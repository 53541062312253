import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import axios from 'axios';

import { isQoverClaim } from '../Utils';

import ClaimNotificationsCard from './ClaimNotificationsCard/ClaimNotificationsCard';
import { useClaim } from './ClaimContainer';

function ToggleSeenNotifications(props) {
  const { showOnlyCurrentUserNotifications, setShowOnlyCurrentUserNotifications } = props;

  return (
    <ToggleButtonGroup
      id="ToggleSeenNotifications"
      value={showOnlyCurrentUserNotifications}
      exclusive
      size="small"
      onChange={(event, newValue) => newValue !== null && setShowOnlyCurrentUserNotifications(newValue)}
    >
      <ToggleButton value={false}>
        Show &nbsp; <strong>all</strong>
      </ToggleButton>
      <ToggleButton value={true}>
        Show &nbsp; <strong>mine</strong>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

ToggleSeenNotifications.propTypes = {
  showOnlyCurrentUserNotifications: PropTypes.bool.isRequired,
  setShowOnlyCurrentUserNotifications: PropTypes.func.isRequired,
};

function ClaimNotificationsContainer({ maxHeight }) {
  const { claim, onClaimUpdate } = useClaim();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showOnlyCurrentUserNotifications, setShowOnlyCurrentUserNotifications] = React.useState(
    isQoverClaim(claim) ? false : true
  );

  const getUpdatedClaim = React.useCallback(async () => {
    const { data: updatedClaim } = await onClaimUpdate();
    setIsFirstLoad(false);
    return updatedClaim;
  }, [onClaimUpdate]);

  const getClaimNotifications = React.useCallback(
    async (
      shouldReturnDismissed,
      shouldReturnFutureReminders,
      pageNumber,
      resultsPerPage,
      sortByColumn,
      typeSubtypeFilters
    ) => {
      let updatedClaim = claim;
      if (!isFirstLoad) {
        updatedClaim = await getUpdatedClaim();
      }
      let params = {
        include_dismissed: shouldReturnDismissed ? 'only_dismissed' : 'not_dismissed',
        show_future_reminders: shouldReturnFutureReminders ? true : undefined,
        page_number: pageNumber ? pageNumber : undefined,
        results_per_page: resultsPerPage ? resultsPerPage : undefined,
        sort_by_column: sortByColumn ? sortByColumn : undefined,
        type_subtype_filters: typeSubtypeFilters ? typeSubtypeFilters : undefined,
      };
      let url = `/api/v1/claims/${claim.id}/notifications`;
      if (showOnlyCurrentUserNotifications) {
        url = '/api/v1/claims/claims_notifications';
        params = { ...params, claim_id: claim.id };
      }

      return axios.get(url, { params }).then((res) => ({ ...res.data, exposures: updatedClaim.exposures }));
    },
    [claim, getUpdatedClaim, isFirstLoad, showOnlyCurrentUserNotifications]
  );

  return (
    <ClaimNotificationsCard
      showSubOrg={false}
      getClaimNotifications={getClaimNotifications}
      AdditionalHeaderComponent={
        <ToggleSeenNotifications
          showOnlyCurrentUserNotifications={showOnlyCurrentUserNotifications}
          setShowOnlyCurrentUserNotifications={setShowOnlyCurrentUserNotifications}
        />
      }
      maxHeight={maxHeight}
      showRecipientUser
      paginateDismissed
    />
  );
}

ClaimNotificationsContainer.propTypes = {
  maxHeight: PropTypes.string,
};

export default ClaimNotificationsContainer;
