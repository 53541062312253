import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const RejectedIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon stroke="none" fill="none" {...props}>
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke={iconColor}
      strokeMiterlimit="10"
    />
    <path d="M3.75586 3.75781L12.2434 12.2453" stroke={iconColor} strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

RejectedIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default RejectedIcon;
