import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ReassignIcon = (props) => (
  <SvgIcon fill="none" {...props}>
    <path
      d="M8 10C9.38071 10 10.5 8.88071 10.5 7.5C10.5 6.11929 9.38071 5 8 5C6.61929 5 5.5 6.11929 5.5 7.5C5.5 8.88071 6.61929 10 8 10Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.98755 12.4625C4.36374 11.7215 4.93777 11.0992 5.64601 10.6645C6.35425 10.2297 7.16904 9.99963 8.00005 9.99963C8.83106 9.99963 9.64585 10.2297 10.3541 10.6645C11.0623 11.0992 11.6364 11.7215 12.0125 12.4625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.5 8L14 9.5L15.5 8" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 8L2 6.5L3.5 8" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14 9.49996V7.99996C14.0015 6.70363 13.583 5.4417 12.8073 4.40307C12.0316 3.36444 10.9403 2.60498 9.69693 2.23836C8.45352 1.87174 7.12481 1.91768 5.9097 2.36932C4.69458 2.82095 3.65841 3.65398 2.9563 4.74371"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 6.5V8C1.99859 9.29633 2.41706 10.5583 3.19277 11.5969C3.96848 12.6355 5.05971 13.395 6.30312 13.7616C7.54653 14.1282 8.87524 14.0823 10.0904 13.6306C11.3055 13.179 12.3416 12.346 13.0438 11.2563"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ReassignIcon;
