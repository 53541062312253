import React from 'react';
import { reduce, sortBy } from 'lodash';

import CardDialog from '../../../CardDialog';
import LoadingIndicator from '../../../LoadingIndicator';
import PlainTable from '../../../PlainTable';
import useDataFetcher from '../../../useDataFetcher';
import useYearMonthFilter from '../../../YearMonthFilter';

import DetailedQaResults from './DetailedQaResults';
import QaResultsLink from './QaResultsLink';

import { useStyles } from '../../../../assets/styles';

const QaResults = () => {
  const classes = useStyles();
  const [detailedResults, setDetailedResults] = React.useState();
  const { filterByMonth, yearFilter, monthFilter, yearFilterMonthFilterProps, YearMonthFilter } = useYearMonthFilter();
  const yearMonthFilterParams = { year: yearFilter, month: filterByMonth ? monthFilter + 1 : undefined };
  const {
    isLoading,
    isError,
    data: qaResults,
  } = useDataFetcher('/api/v1/claims/qa_summary', { params: { ...yearMonthFilterParams } });

  const columnData = [
    { id: 'adjuster', label: 'Adjuster' },
    // eslint-disable-next-line react/display-name
    {
      id: 'count',
      numeric: true,
      label: 'QA Count',
      specialCell: (entry) => (
        <QaResultsLink
          text={entry.count}
          onClick={() => setDetailedResults({ adjuster: entry.adjuster_id, ...yearMonthFilterParams })}
        />
      ),
    },
    { id: 'score', numeric: true, label: 'QA Score', specialCell: (entry) => entry.score.toFixed(2) },
    // eslint-disable-next-line react/display-name
    {
      id: 'fail_count',
      numeric: true,
      label: 'Failed Claims Count',
      specialCell: (entry) => (
        <QaResultsLink
          text={entry.fail_count}
          onClick={() =>
            setDetailedResults({ adjuster: entry.adjuster_id, is_passed: false, ...yearMonthFilterParams })
          }
        />
      ),
    },
    // { id: 'date_closed', numeric: false, label: 'Date Closed', nowrap: true, specialCell: entry => serverDateTimeToLocal(entry.date_closed) },
  ];

  const totalCount = reduce(qaResults, (sum, currQaResult) => currQaResult.count + sum, 0);

  const totalRow = {
    adjuster: 'Total',
    count: totalCount,
    score:
      reduce(qaResults, (sum, currQaResult) => currQaResult.score * currQaResult.count + sum, 0) / (totalCount || 1),
    fail_count: reduce(qaResults, (sum, currQaResult) => currQaResult.fail_count + sum, 0),
  };

  return (
    <>
      <div className={classes.cardDivRow}>
        <CardDialog title="Results Summary">
          <div style={{ marginBottom: '16px' }}>
            <YearMonthFilter {...yearFilterMonthFilterProps} />
          </div>
          {isLoading || isError ? (
            <LoadingIndicator isError={isError} />
          ) : (
            <PlainTable rows={[...sortBy(qaResults, ['adjuster']), totalRow]} columns={columnData} />
          )}
        </CardDialog>
      </div>
      {detailedResults && (
        <DetailedQaResults onClose={() => setDetailedResults(undefined)} searchCriteria={detailedResults} />
      )}
    </>
  );
};

export default QaResults;
