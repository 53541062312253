import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import { useIncidentConfiguration } from '../../../../../hooks/useIncidentConfiguration';
import {
  getInitialSectionConfig,
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../../IncidentConfiguration/CustomFieldsContext';

import styles from './index.module.scss';

const THEFT_FOLLOW_UP_PATH = 'incident_details.auto_incident_additional.theft_follow_up';
const THEFT_FOLLOW_UP_CONFIG_PATH = 'incident_details.pre_defined_fields.auto_incident_additional.theft_follow_up';

const getInitialTheftFollowUpSectionConfig = (incidentConfiguration, isFieldSupportedBySubtype) =>
  getInitialSectionConfig(incidentConfiguration, THEFT_FOLLOW_UP_CONFIG_PATH, {}, isFieldSupportedBySubtype);
const getInitialTheftFollowUpPredefinedFields = (incidentConfiguration, additionalFieldOmitFn) =>
  preparePreDefinedFields(getInitialTheftFollowUpSectionConfig(incidentConfiguration), additionalFieldOmitFn);
const getInitialTheftFollowUpInitialValues = (incidentConfiguration, additionalFieldOmitFn) =>
  getPredefinedFieldsEmptyFormikInitialValues(
    getInitialTheftFollowUpPredefinedFields(incidentConfiguration, additionalFieldOmitFn)
  );
const getInitialTheftFollowUpInitialValidationSchema = (incidentConfiguration, additionalFieldOmitFn) =>
  getAdditionalDataValidations(getInitialTheftFollowUpPredefinedFields(incidentConfiguration, additionalFieldOmitFn));

const InitialTheftFollowUpFragment = ({ additionalFieldOmitFn }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInitialTheftFollowUpPredefinedFields(incidentConfiguration, additionalFieldOmitFn);

  return (
    <>
      <Grid item xs={12}>
        <Typography display="block" variant="subtitle1">
          Theft Details
        </Typography>
      </Grid>
      <PreDefinedField id="discovered_the_loss_contact_id" fields={preDefinedFields} inline />
      <PreDefinedField id="crime_reference_number" fields={preDefinedFields} inline />
      <PreDefinedField id="date_noticed_vehicle_missing" fields={preDefinedFields} inline gridXs={7} />
      <PreDefinedField id="time_noticed_vehicle_missing" fields={preDefinedFields} inline gridXs={5} />
      <PreDefinedField id="date_vehicle_last_seen" fields={preDefinedFields} inline disableFuture gridXs={7} />
      <PreDefinedField id="time_vehicle_last_seen" fields={preDefinedFields} inline disableFuture gridXs={5} />
      <PreDefinedField id="vehicle_last_seen_location" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="were_belongings_stolen_from_vehicle" fields={preDefinedFields} inline />
      <PreDefinedField id="belongings_stolen" fields={preDefinedFields} inline />
      <PreDefinedField id="is_vehicle_for_sale" fields={preDefinedFields} inline />
      <PreDefinedField id="person_liable_for_the_theft_is_known" fields={preDefinedFields} inline />
      <PreDefinedField id="note" fields={preDefinedFields} inline />
      <Grid item xs={12}>
        <Typography className={styles.subHeader} display="block" variant="subtitle1">
          Vehicle Keys Security
        </Typography>
      </Grid>
      <PreDefinedField id="num_of_keys_received_on_purchase" fields={preDefinedFields} inline />
      <PreDefinedField id="current_num_of_keys" fields={preDefinedFields} inline />
      <PreDefinedField id="keys_location" fields={preDefinedFields} inline gridXs={12} />
      <PreDefinedField id="was_vehicle_found_with_keys" fields={preDefinedFields} inline />
      <Grid item xs={12}>
        <Typography className={styles.subHeader} display="block" variant="subtitle1">
          Vehicle Security
        </Typography>
      </Grid>
      <PreDefinedField id="vehicle_windows_closed_and_secured" fields={preDefinedFields} inline />
      <PreDefinedField id="vehicle_fitted_with_security_devices" fields={preDefinedFields} inline />
      <PreDefinedField id="security_devices_description" fields={preDefinedFields} inline />
      <PreDefinedField id="vehicle_fitted_with_tracking_devices" fields={preDefinedFields} inline />
      <PreDefinedField id="tracking_devices_description" fields={preDefinedFields} inline />
    </>
  );
};

InitialTheftFollowUpFragment.propTypes = {
  additionalFieldOmitFn: PropTypes.func,
};

export {
  getInitialTheftFollowUpInitialValidationSchema,
  getInitialTheftFollowUpInitialValues,
  getInitialTheftFollowUpSectionConfig,
  InitialTheftFollowUpFragment,
  THEFT_FOLLOW_UP_PATH,
};
