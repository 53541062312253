import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const AddNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      d="M29.8975 17.8525L24.6475 12.6025C24.5421 12.497 24.3991 12.4376 24.25 12.4375H15.25C14.9019 12.4375 14.5681 12.5758 14.3219 12.8219C14.0758 13.0681 13.9375 13.4019 13.9375 13.75V30.25C13.9375 30.5981 14.0758 30.9319 14.3219 31.1781C14.5681 31.4242 14.9019 31.5625 15.25 31.5625H28.75C29.0981 31.5625 29.4319 31.4242 29.6781 31.1781C29.9242 30.9319 30.0625 30.5981 30.0625 30.25V18.25C30.0624 18.1009 30.003 17.9579 29.8975 17.8525ZM24.8125 14.3575L28.1425 17.6875H24.8125V14.3575ZM28.75 30.4375H15.25C15.2003 30.4375 15.1526 30.4177 15.1174 30.3826C15.0823 30.3474 15.0625 30.2997 15.0625 30.25V13.75C15.0625 13.7003 15.0823 13.6526 15.1174 13.6174C15.1526 13.5823 15.2003 13.5625 15.25 13.5625H23.6875V18.25C23.6875 18.3992 23.7468 18.5423 23.8523 18.6477C23.9577 18.7532 24.1008 18.8125 24.25 18.8125H28.9375V30.25C28.9375 30.2997 28.9177 30.3474 28.8826 30.3826C28.8474 30.4177 28.7997 30.4375 28.75 30.4375ZM24.8125 24.25C24.8125 24.3992 24.7532 24.5423 24.6477 24.6477C24.5423 24.7532 24.3992 24.8125 24.25 24.8125H22.5625V26.5C22.5625 26.6492 22.5032 26.7923 22.3977 26.8977C22.2923 27.0032 22.1492 27.0625 22 27.0625C21.8508 27.0625 21.7077 27.0032 21.6023 26.8977C21.4968 26.7923 21.4375 26.6492 21.4375 26.5V24.8125H19.75C19.6008 24.8125 19.4577 24.7532 19.3523 24.6477C19.2468 24.5423 19.1875 24.3992 19.1875 24.25C19.1875 24.1008 19.2468 23.9577 19.3523 23.8523C19.4577 23.7468 19.6008 23.6875 19.75 23.6875H21.4375V22C21.4375 21.8508 21.4968 21.7077 21.6023 21.6023C21.7077 21.4968 21.8508 21.4375 22 21.4375C22.1492 21.4375 22.2923 21.4968 22.3977 21.6023C22.5032 21.7077 22.5625 21.8508 22.5625 22V23.6875H24.25C24.3992 23.6875 24.5423 23.7468 24.6477 23.8523C24.7532 23.9577 24.8125 24.1008 24.8125 24.25Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default AddNoteIcon;
