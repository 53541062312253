import React from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Link } from '@material-ui/core';

import { useStyles } from '~/assets/styles';
import mixpanel from '~/components/CmsMain/mixpanel';
import { FsButton, Heading, Text } from '~/components/core';
import { QuestionCircleIcon } from '~/components/icons';
import { OhNoIllustration } from '~/components/illustrations';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import cn from '~/Utils/cn';

const SupportButton: React.FC = () => {
  const classes = useStyles();

  return (
    <Link
      href="https://fivesigmahelp.zendesk.com/hc/en-us/requests/new"
      underline="none"
      color="inherit"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        mixpanel.track(MIXPANEL_EVENTS.SUPPORT_LINK_CLICKED);
      }}
    >
      <FsButton
        startIcon={<QuestionCircleIcon size={20} iconColor="currentColor" />}
        variant="contained"
        color="primary"
        className={cn(classes.actionButton, classes.button)}
        ignorePermissions
      >
        Contact Support
      </FsButton>
    </Link>
  );
};

export const FallbackErrorPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const links = [
    { label: 'Home Page', href: '/home' },
    { label: 'Claims Page', href: '/claims' },
    { label: 'Communications Page', href: '/communications' },
  ].filter(({ href }) => window.location.pathname !== href);

  const linkClass = cn(classes.primaryLink, 'underline');

  const isInAppContext = Boolean(history);

  return (
    <div
      className={cn('center m-auto flex h-full w-full flex-col items-center justify-center', {
        'mt-[120px]': !isInAppContext,
      })}
    >
      <div className="flex max-w-lg flex-col items-center justify-center gap-40 text-center">
        <OhNoIllustration />
        <div className="__TEXT__ flex w-max flex-col">
          <Heading className="mb-12" variant={Heading.TYPES.H1}>
            Oh no!
          </Heading>
          <Text variant={Text.VARIANTS.LG} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
            An error occurred while processing your request.
          </Text>
          <Text variant={Text.VARIANTS.LG} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
            We are working to fix the issue and apologize for the inconvenience.
          </Text>
        </div>
        {isInAppContext ? (
          <div className="__LINKS__ flex flex-col gap-20">
            <Text
              variant={Text.VARIANTS.LG}
              colorVariant={Text.COLOR_VARIANTS.SECONDARY}
              weight={Text.WEIGHTS.SEMI_BOLD}
            >
              You can also go to
            </Text>
            <div className="flex gap-4">
              {links.map(({ label, href }, index) => (
                <>
                  <Link key={label} href={href} className={linkClass}>
                    {label}
                  </Link>
                  {index !== links.length - 1 ? <Divider variant="middle" orientation="vertical" /> : null}
                </>
              ))}
            </div>
          </div>
        ) : null}
        {isInAppContext ? <SupportButton /> : null}
      </div>
    </div>
  );
};
