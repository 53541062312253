import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const InvolvedStructureIcon = ({ ...props }) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/sv" {...props}>
    <path
      stroke="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.1572 0.420612C7.38751 0.210345 7.68811 0.09375 8 0.09375C8.31189 0.09375 8.61249 0.210345 8.8428 0.420613C8.84312 0.420901 8.84343 0.421189 8.84375 0.421478L15.1011 6.10783C15.2246 6.22448 15.3234 6.36475 15.392 6.5202C15.4605 6.67566 15.4972 6.84326 15.4999 7.01313L15.5001 7.02324L15.5 14.2498C15.5 14.5813 15.3683 14.8993 15.1339 15.1337C14.8995 15.3681 14.5815 15.4998 14.25 15.4998H10.5C10.1685 15.4998 9.85054 15.3681 9.61612 15.1337C9.38169 14.8993 9.25 14.5813 9.25 14.2498V10.4998L6.75 10.4998V14.2498C6.75 14.5813 6.6183 14.8993 6.38388 15.1337C6.14946 15.3681 5.83152 15.4998 5.5 15.4998H1.75C1.41848 15.4998 1.10054 15.3681 0.866117 15.1337C0.631696 14.8993 0.5 14.5813 0.5 14.2498V7.01313C0.502748 6.84326 0.539539 6.67567 0.608047 6.5202C0.676556 6.36474 0.775478 6.22456 0.89899 6.10791L0.907713 6.09968L7.15625 0.421478C7.15657 0.421189 7.15688 0.420901 7.1572 0.420612ZM1.75592 7.01806C1.75426 7.01991 1.75291 7.022 1.75191 7.02428C1.75086 7.02664 1.75022 7.02917 1.75 7.03174V14.2498H5.5V10.4998C5.5 10.1683 5.6317 9.85034 5.86612 9.61592C6.10054 9.3815 6.41848 9.2498 6.75 9.2498H9.25C9.58152 9.2498 9.89946 9.3815 10.1339 9.61592C10.3683 9.85034 10.5 10.1683 10.5 10.4998V14.2498H14.25V7.03174C14.2498 7.02917 14.2491 7.02665 14.2481 7.02428C14.2471 7.022 14.2457 7.0199 14.2441 7.01806L8 1.34375L7.99846 1.34516L1.75592 7.01806Z"
      fill="#202020"
    />
  </SvgIcon>
);

InvolvedStructureIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default InvolvedStructureIcon;
