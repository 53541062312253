import React from 'react';
import { TextField } from '@material-ui/core';

import CardDialog from '~/components/CardDialog';
import MenuItem from '~/components/core/Atomic/MenuItem';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import { finalizeProjectName } from '~/components/ReportsPage/helpers/ReportsUtils';
import type { Project } from '~/components/ReportsPage/types';
import cn from '~/Utils/cn';

import Text from '../../core/TextComponents/Text';

interface ShareToDialogProps {
  onClose: () => void;
  projectsToShare: Project[];
  onConfirm: (params: { projectId: string }) => Promise<void>;
}

export const ShareToDialog: React.FC<ShareToDialogProps> = ({ projectsToShare = [], onClose, onConfirm }) => {
  const [selectedProject, setSelectedProjects] = React.useState<Project | undefined>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onProjectSelect = React.useCallback((project?: Project) => {
    setSelectedProjects(project);
  }, []);

  const onConfirmCallback = React.useCallback(async () => {
    if (!selectedProject) return;
    setIsSubmitting(true);
    await onConfirm({ projectId: selectedProject.id });
    setIsSubmitting(false);
  }, [onConfirm, selectedProject]);

  return (
    <CardDialog
      className={cn({ 'cursor-progress': isSubmitting })}
      open={true}
      onClose={onClose}
      title="Share To"
      isDialog
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions
          primaryLabel="Share"
          onClickPrimary={onConfirmCallback}
          onClickSecondary={onClose}
          primaryDisabled={!selectedProject}
          disabled={isSubmitting}
        />
      }
    >
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
        The workbook will be copied to the selected folder
      </Text>
      <TextField
        label="Share to"
        value={selectedProject?.name}
        onChange={(e) => onProjectSelect(projectsToShare.find((project) => project.id === e?.target?.value))}
        select
        placeholder="Share to"
        disabled={isSubmitting}
      >
        {projectsToShare.map((project) => (
          <MenuItem key={project.id} value={project.id} className="capitalize">
            {finalizeProjectName(project?.name)}
          </MenuItem>
        ))}
      </TextField>
    </CardDialog>
  );
};
