import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const TextAlignJustifyIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M3.05859 6C3.05859 5.80109 3.13761 5.61032 3.27826 5.46967C3.41892 5.32902 3.60968 5.25 3.80859 5.25H20.3086C20.5075 5.25 20.6983 5.32902 20.8389 5.46967C20.9796 5.61032 21.0586 5.80109 21.0586 6C21.0586 6.19891 20.9796 6.38968 20.8389 6.53033C20.6983 6.67098 20.5075 6.75 20.3086 6.75H3.80859C3.60968 6.75 3.41892 6.67098 3.27826 6.53033C3.13761 6.38968 3.05859 6.19891 3.05859 6ZM20.3086 9H3.80859C3.60968 9 3.41892 9.07902 3.27826 9.21967C3.13761 9.36032 3.05859 9.55109 3.05859 9.75C3.05859 9.94891 3.13761 10.1397 3.27826 10.2803C3.41892 10.421 3.60968 10.5 3.80859 10.5H20.3086C20.5075 10.5 20.6983 10.421 20.8389 10.2803C20.9796 10.1397 21.0586 9.94891 21.0586 9.75C21.0586 9.55109 20.9796 9.36032 20.8389 9.21967C20.6983 9.07902 20.5075 9 20.3086 9ZM20.3086 12.75H3.80859C3.60968 12.75 3.41892 12.829 3.27826 12.9697C3.13761 13.1103 3.05859 13.3011 3.05859 13.5C3.05859 13.6989 3.13761 13.8897 3.27826 14.0303C3.41892 14.171 3.60968 14.25 3.80859 14.25H20.3086C20.5075 14.25 20.6983 14.171 20.8389 14.0303C20.9796 13.8897 21.0586 13.6989 21.0586 13.5C21.0586 13.3011 20.9796 13.1103 20.8389 12.9697C20.6983 12.829 20.5075 12.75 20.3086 12.75ZM20.3086 16.5H3.80859C3.60968 16.5 3.41892 16.579 3.27826 16.7197C3.13761 16.8603 3.05859 17.0511 3.05859 17.25C3.05859 17.4489 3.13761 17.6397 3.27826 17.7803C3.41892 17.921 3.60968 18 3.80859 18H20.3086C20.5075 18 20.6983 17.921 20.8389 17.7803C20.9796 17.6397 21.0586 17.4489 21.0586 17.25C21.0586 17.0511 20.9796 16.8603 20.8389 16.7197C20.6983 16.579 20.5075 16.5 20.3086 16.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default TextAlignJustifyIcon;
