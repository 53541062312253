import React from 'react';

import { getAllSearchableContactRoles } from '~/components/communications/ContactUtils';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import { FormRow } from '~/components/LegalActions/components/FormRow';
import { NEGOTIATION_LOG_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import { useOrgNameLabel } from '~/components/LegalActions/hooks/useOrgNameLabel';
import useOrganization from '~/components/OrganizationContext';
import { DatePickerTextFieldFormik, MonetaryValueTextFieldFormik, TextFieldFormik } from '~/components/TextFieldFormik';

export const NegotiationLogForm = () => {
  const { organizationContactRolesDict } = useOrganization();
  const offerLabel = useOrgNameLabel('Offer');

  return (
    <>
      <FormRow>
        <DatePickerTextFieldFormik
          containerClassName="w-full"
          fullWidth
          id={NEGOTIATION_LOG_FIELDS.date.key}
          label={NEGOTIATION_LOG_FIELDS.date.label}
          nullIfEmpty
        />
        <ContactTextFieldFormik
          id="adverse_party_contact"
          label={NEGOTIATION_LOG_FIELDS.adversePartyContact.label}
          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
          fixedSearchResults
          fullWidth
          nullIfEmpty
        />
      </FormRow>
      <FormRow>
        <MonetaryValueTextFieldFormik
          id={NEGOTIATION_LOG_FIELDS.adversePartyDemand.key}
          label={NEGOTIATION_LOG_FIELDS.adversePartyDemand.label}
          fullWidth
        />
        <MonetaryValueTextFieldFormik id={NEGOTIATION_LOG_FIELDS.offer.key} label={offerLabel} fullWidth />
      </FormRow>
      <FormRow>
        <TextFieldFormik
          id={NEGOTIATION_LOG_FIELDS.notes.key}
          label={NEGOTIATION_LOG_FIELDS.notes.label}
          fullWidth
          multiline
          rows={1}
        />
      </FormRow>
    </>
  );
};
