import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';

interface Props {
  onStartVideoCall: () => void;
  onCancel: () => void;
}
const NewVideoCallStartCallCommunicationsActions: React.FC<Props> = ({ onStartVideoCall, onCancel }) => {
  return (
    <div className="flex justify-end">
      <CancelButton onClick={onCancel} />
      <Button variant="contained" color="primary" onClick={onStartVideoCall}>
        Start Call
      </Button>
    </div>
  );
};

export default NewVideoCallStartCallCommunicationsActions;
