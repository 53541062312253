import { isEqual, mapValues, pick } from 'lodash';

import { DETAILS_TAB_KEY } from './constants';

export const getFullFieldPath = (tabId, fieldId) => `${tabId}.${fieldId}`;

export const getFullDetailsTabFieldPath = (fieldId) => getFullFieldPath(DETAILS_TAB_KEY, fieldId);

export const getReasonValidationSchema = (originalContact, getValuesToCompare, getInitialValues) => {
  return (reason, ctx) => {
    if (reason) {
      return true;
    }

    // Disclaimer, not documented
    const values = ctx.from[0].value;

    const originalValuesToCompare = getValuesToCompare(pick(getInitialValues(originalContact), Object.keys(values)));
    const updatedValuesToCompare = getValuesToCompare(values);
    return isEqual(originalValuesToCompare, updatedValuesToCompare);
  };
};

export const getInitializedValues = (values) => mapValues(values, (value) => value ?? '');

// export const prepareTabPayload =
