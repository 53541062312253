import type { ReactNode } from 'react';
import React from 'react';

import Chip from '~/components/core/Atomic/Chip/Chip';

import { useTextOverflow } from '../../../hooks/useTextOverflow';
import FsTooltip from '../../FsWrappers/FsTooltip/FsTooltip';
import OverflowArrayTextDisplayWithTooltip from '../../OverflowArrayTextWithTooltip/OverflowArrayTextDisplayWithTooltip';
import Text from '../../TextComponents/Text';

type PossiblyUndefinedTextValue = string | string[] | null | undefined;

interface PropertyDetailsProps {
  title: string;
  text: PossiblyUndefinedTextValue;
  textIcon?: ReactNode;
  useChips?: boolean;
  textComponent?: ReactNode;
}

const VALUE_TEXT_PROPS = {
  variant: Text.VARIANTS.SM,
  colorVariant: Text.COLOR_VARIANTS.PRIMARY,
  weight: Text.WEIGHTS.REGULAR,
};

const getValueComponent = (text?: PossiblyUndefinedTextValue, textIcon?: ReactNode, useChips?: boolean) => {
  if (typeof text === 'string' && text) {
    return <StringValueComponent text={text} textIcon={textIcon} />;
  } else if (Array.isArray(text) && text.length > 0) {
    return (
      <OverflowArrayTextDisplayWithTooltip
        value={text}
        additionalLabelWidth={useChips ? 24 : undefined}
        renderItem={
          useChips
            ? (label) => (
                <span key={label} className="pr-[5px]">
                  <Chip label={label} size="small" />
                </span>
              )
            : undefined
        }
      />
    );
  }
  return <Text {...VALUE_TEXT_PROPS}>-</Text>;
};

export const PropertyDetails: React.FC<PropertyDetailsProps> = ({ title, text, textIcon, useChips, textComponent }) => {
  return (
    <div className="flex w-full flex-col gap-4 overflow-hidden">
      <Text variant={Text.VARIANTS.XS} colorVariant={Text.COLOR_VARIANTS.SECONDARY} weight={Text.WEIGHTS.SEMI_BOLD}>
        {title}
      </Text>
      {textComponent}
      {!textComponent ? getValueComponent(text, textIcon, useChips) : null}
    </div>
  );
};

const StringValueComponent: React.FC<Pick<PropertyDetailsProps, 'text' | 'textIcon'>> = ({ text, textIcon }) => {
  const { textContainerRef, isOverflowing } = useTextOverflow(text as string, textIcon ? 24 : 0);
  const textComponent = (
    <div className="row-auto flex gap-4" ref={textContainerRef}>
      {textIcon ? <div>{textIcon}</div> : null}
      <Text {...VALUE_TEXT_PROPS} className="overflow-hidden overflow-ellipsis whitespace-nowrap">
        {text ?? '-'}
      </Text>
    </div>
  );
  return isOverflowing ? (
    <FsTooltip title={text as string} placement="top">
      {textComponent}
    </FsTooltip>
  ) : (
    textComponent
  );
};
