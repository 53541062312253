import React from 'react';

const NUMBER_OF_CHILDREN_TO_WIDTH_CLASS: Record<number, string> = {
  1: 'w-full',
  2: 'w-1/2',
  3: 'w-1/3',
  4: 'w-1/4',
  5: 'w-1/5',
  6: 'w-1/6',
  7: 'w-1/7',
  8: 'w-1/8',
  9: 'w-1/9',
  10: 'w-1/10',
  11: 'w-1/11',
  12: 'w-1/12',
};

export const PropertyDetailsRow: React.FC = ({ children }) => {
  const numberOfChildren = React.Children.count(children);
  if (numberOfChildren === 0) {
    return null;
  }
  return (
    <>
      {React.Children.map(children, (child) => {
        return <div className={NUMBER_OF_CHILDREN_TO_WIDTH_CLASS[numberOfChildren]}>{child}</div>;
      })}
    </>
  );
};
