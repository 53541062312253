import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';

import { colorPalette, useStyles } from '~/assets/styles';
import { AddItemButton } from '~/components/core/NewDesignSystem/Buttons/AddItemButton';
import StatusChip from '~/components/core/StatusChip';
import { stringCmp } from '~/Utils';

import { compareDatesStrings, getDateFromServerDateTime, getLocalDateToday } from '../../../DateTimeUtils';
import { useClaim } from '../../ClaimContainer';
import { getClaimNotificationIcon } from '../../ClaimNotificationsCard/ClaimNotificationCardUtils';
import { getCommunicationChannelIconComponent } from '../../communications/CommunicationUtils';
import { ContactEntity } from '../../Contact';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions, SortableTable, TooltipIcon } from '../../core';
import { PAGINATION_LOCATION } from '../../core/Tables/SortableTable/utils';
import { useCms } from '../../hooks/useCms';
import { ErrorIcon } from '../../icons';
import useOrganization from '../../OrganizationContext';
import useDataFetcher from '../../useDataFetcher';

import MoiTableActions from './MoiTableActions';

const EXPOSURE_LABEL_PREFIX = 'Selected MOIs for Exposure';
const GENERAL_EXPOSURE_LABEL = "Claim's selected MOIs";

const MoiTable = ({
  rows = [],
  onEditClick = noop,
  onViewStatusDataClick = noop,
  shouldReloadCommunications = false,
  setShouldReloadCommunications = noop,
  readOnly = false,
  claimIdDisplayWithExposureSuffix,
}) => {
  const classes = useStyles();
  const { moiOptions } = useOrganization();
  const { claim } = useClaim();
  const { userOrganization } = useCms();
  const claimLevelMoiEnabled = userOrganization?.configuration?.moi_configuration?.claim_level_moi_enabled;

  const moiIds = rows.map(({ id }) => id);
  const {
    isLoading,
    isError,
    data: communications = {},
    reloadData: reloadCommunicationsData,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/method_of_inspection/communication_channels`, {
    params: { moi_ids: moiIds },
  });
  const greyedOutRowsIds = useMemo(
    () => rows.filter((row) => ['rejected', 'called_off'].includes(row.status)).map((row) => row.id),
    [rows]
  );
  const boldRowsIds = useMemo(
    () => rows?.filter((row) => claimLevelMoiEnabled && row?.is_claim_level)?.map((row) => row.id),
    [rows, claimLevelMoiEnabled]
  );

  useEffect(() => {
    const reloadCommunications = async () => {
      if (shouldReloadCommunications) {
        await reloadCommunicationsData();
        setShouldReloadCommunications(false);
      }
    };

    return reloadCommunications();
  }, [reloadCommunicationsData, setShouldReloadCommunications, shouldReloadCommunications]);

  const getMappedStatus = (status) => {
    const map = {
      completed: 'for_review',
      accepted: 'in_progress',
    };
    return (map[status] ? map[status] : status).toUpperCase();
  };

  const getMoiColumnComponent = (moiRecord, column) => {
    const baseMethodTitle =
      moiRecord?.moi_method && !moiRecord?.method
        ? moiRecord?.moi_method?.display_name
        : moiOptions?.default[moiRecord?.method]?.title;

    switch (column) {
      case 'method':
        return (
          <>{claimLevelMoiEnabled && moiRecord?.is_claim_level ? `General ${baseMethodTitle}` : baseMethodTitle}</>
        );
      case 'assigned_to':
        return (
          <>
            {moiRecord?.contact?.full_name ? (
              <ContactEntity contactId={moiRecord.contact_id} contactDisplayName={moiRecord.contact.full_name} />
            ) : (
              moiRecord?.user?.username || ''
            )}
          </>
        );
      case 'datetime':
        return <>{moiRecord?.datetime && getDateFromServerDateTime(moiRecord.datetime)}</>;
      case 'due_datetime':
        return (
          <div className={classes.spaceAroundContainer}>
            {moiRecord?.due_datetime && getDateFromServerDateTime(moiRecord.due_datetime)}
            {compareDatesStrings(moiRecord.due_datetime, getLocalDateToday()) < 0 && moiRecord.status === 'pending' && (
              <TooltipIcon placement="top" arrow title="The due date has passed">
                <ErrorIcon size={14} className={classes.rightButtonIcon} iconColor={colorPalette.error} />
              </TooltipIcon>
            )}
          </div>
        );
      case 'completion_datetime':
        return <>{moiRecord?.completion_datetime && getDateFromServerDateTime(moiRecord.completion_datetime)}</>;
      case 'status':
        return (
          <>
            {moiRecord?.status && (
              <StatusChip withIcon status={StatusChip.STATUSES[getMappedStatus(moiRecord.status)]} />
            )}
          </>
        );
      case 'communication':
        if (moiRecord.assignment_communication_type && moiRecord.assignment_communication_subtype) {
          if (moiRecord.assignment_communication_type === 'communication') {
            return getCommunicationChannelIconComponent(
              moiRecord.assignment_communication_subtype,
              false,
              'Assignment sent by '
            );
          }
          if (moiRecord.assignment_communication_type === 'notification') {
            return getClaimNotificationIcon(
              { type: moiRecord.assignment_communication_subtype },
              'Assignment sent by '
            );
          }
        }
        return (
          !isLoading &&
          !isError &&
          !isEmpty(communications) &&
          communications[moiRecord?.id] &&
          getCommunicationChannelIconComponent(communications[moiRecord.id])
        );
      case 'actions':
        return (
          <MoiTableActions
            moiRecord={moiRecord}
            onViewStatusDataClick={onViewStatusDataClick}
            readOnly={readOnly}
            onEditClick={onEditClick}
            claimIdDisplayWithExposureSuffix={claimIdDisplayWithExposureSuffix}
          />
        );
      default:
        return null;
    }
  };

  const getMethodColumnCmpFunc = () => {
    return (a, b) =>
      stringCmp(
        a?.moi_method?.display_name || moiOptions?.default[a?.method]?.title || '',
        b?.moi_method?.display_name || moiOptions?.default[b?.method]?.title || ''
      );
  };

  const getAssignedToColumnCmpFunc = () => {
    return (a, b) =>
      stringCmp(a.contact?.full_name || a.user?.username || '', b.contact?.full_name || b.user?.username || '');
  };

  const getStatusColumnCmpFunc = () => (a, b) => stringCmp(a?.status || '', b?.status || '');

  const columns = [
    {
      id: 'method',
      numeric: false,
      label: 'Method',
      specialCell: (moiRecord) => getMoiColumnComponent(moiRecord, 'method'),
      specialCmpFunc: getMethodColumnCmpFunc(),
    },
    {
      id: 'assigned_to',
      numeric: false,
      label: 'Assigned  To',
      specialCell: (moiRecord) => getMoiColumnComponent(moiRecord, 'assigned_to'),
      specialCmpFunc: getAssignedToColumnCmpFunc(),
    },
    {
      id: 'datetime',
      numeric: false,
      label: 'Assignment Date',
      specialCell: (moiRecord) => getMoiColumnComponent(moiRecord, 'datetime'),
    },
    {
      id: 'due_datetime',
      numeric: false,
      label: 'Due Date',
      specialCell: (moiRecord) => getMoiColumnComponent(moiRecord, 'due_datetime'),
    },
    {
      id: 'completion_datetime',
      numeric: false,
      label: 'Closing Date',
      specialCell: (moiRecord) => getMoiColumnComponent(moiRecord, 'completion_datetime'),
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      specialCell: (moiRecord) => getMoiColumnComponent(moiRecord, 'status'),
      specialCmpFunc: getStatusColumnCmpFunc(),
    },
    {
      id: 'communication',
      numeric: false,
      specialCell: (moiRecord) => getMoiColumnComponent(moiRecord, 'communication'),
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      specialCell: (moiRecord) => getMoiColumnComponent(moiRecord, 'actions'),
    },
  ];

  return (
    <SortableTable
      rows={rows}
      columns={columns}
      defaultOrderColumn={columns.findIndex((column) => column.id === 'datetime')}
      order="desc"
      stickyHeader
      greyedOutRowsIds={greyedOutRowsIds}
      boldRowsIds={boldRowsIds}
      autoPaginateRowsPerPage={5}
      paginationLocation={PAGINATION_LOCATION.BOTTOM}
    />
  );
};

MoiTable.propTypes = {
  rows: PropTypes.array,
  onEditClick: PropTypes.func,
  onViewStatusDataClick: PropTypes.func,
  shouldReloadCommunications: PropTypes.bool,
  setShouldReloadCommunications: PropTypes.func,
  readOnly: PropTypes.bool,
  claimIdDisplayWithExposureSuffix: PropTypes.string,
};

const MoiTableCard = ({
  moiRecords,
  exposureLabel,
  onAddClick = noop,
  readOnly = false,
  onEditClick = noop,
  onViewStatusDataClick = noop,
  shouldReloadCommunications = false,
  setShouldReloadCommunications = noop,
  claimIdDisplayWithExposureSuffix,
}) => {
  const classes = useStyles();

  return (
    <div style={{ height: '100%' }}>
      {exposureLabel ? `${EXPOSURE_LABEL_PREFIX} ${exposureLabel}` : GENERAL_EXPOSURE_LABEL}
      {!readOnly && (
        <div className={classes.buttonsContainer}>
          <RestrictedPermissions action={PERMISSION_ACTIONS.MOI} verb={PERMISSION_VERBS.WRITE}>
            <AddItemButton text="Add MOI" onClick={onAddClick} />
          </RestrictedPermissions>
        </div>
      )}
      <MoiTable
        rows={moiRecords}
        onAddClick={onAddClick}
        onEditClick={onEditClick}
        readOnly={readOnly}
        onViewStatusDataClick={onViewStatusDataClick}
        shouldReloadCommunications={shouldReloadCommunications}
        setShouldReloadCommunications={setShouldReloadCommunications}
        claimIdDisplayWithExposureSuffix={claimIdDisplayWithExposureSuffix}
      />
    </div>
  );
};

MoiTableCard.propTypes = {
  moiRecords: PropTypes.arrayOf(PropTypes.object).isRequired,
  exposureLabel: PropTypes.string,
  onAddClick: PropTypes.func,
  readOnly: PropTypes.bool,
  onEditClick: PropTypes.func,
  onViewStatusDataClick: PropTypes.func,
  shouldReloadCommunications: PropTypes.bool,
  setShouldReloadCommunications: PropTypes.func,
  claimIdDisplayWithExposureSuffix: PropTypes.string,
};

export default MoiTableCard;
