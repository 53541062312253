import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { keyBy } from 'lodash';

import Typography from '~/components/core/Atomic/Typography';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { isMgmClaim, isRwClaim } from '../../../Utils';

import { getPaymentMethodDisplayName } from './PaymentMethodUtils';

import styles from './PaymentRequestContainer.module.scss';

const PaymentMethodSelectorFormik = ({ claim, onPaymentMethodSelection }) => {
  const { values } = useFormikContext();
  const { enabledPaymentMethodConfigurations } = usePaymentsConfiguration(values.payment_method);

  if (isMgmClaim(claim) || isRwClaim(claim) || enabledPaymentMethodConfigurations.length < 2) {
    return null;
  }

  const optionsDict = keyBy(enabledPaymentMethodConfigurations, 'payment_method');

  const selectionOptions = Object.entries(optionsDict).map(([method, paymentMethodConfig]) => ({
    optionValue: method,
    text: getPaymentMethodDisplayName(paymentMethodConfig),
  }));

  return (
    <>
      <Typography className={styles.sectionTitle}>Payment Method</Typography>
      <div className={styles.paymentMethodContainer}>
        <RadioButtonGroupFormik
          id="payment_method"
          options={selectionOptions}
          onClick={(value) => onPaymentMethodSelection(value, optionsDict[value])}
          btnClassName={styles.paymentMethodButton}
        />
      </div>
    </>
  );
};

PaymentMethodSelectorFormik.propTypes = {
  claim: PropTypes.object.isRequired,
  onPaymentMethodSelection: PropTypes.func.isRequired,
};

export default PaymentMethodSelectorFormik;
