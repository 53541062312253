import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import { FONT_FAMILY_OPTIONS_DEFAULT } from './constants';
import FontComponent from './FontComponent';

const FontFamilyComponent: React.FC = () => {
  const { editor } = useCurrentEditor();
  const setFontFamily = (value: string) => {
    if (value) {
      editor?.chain().setFontFamily(value).focus().run();
    } else {
      editor?.chain().unsetFontFamily().focus().run();
    }
  };

  return (
    <FontComponent
      options={FONT_FAMILY_OPTIONS_DEFAULT}
      defaultOption="Font"
      containerClassName="w-[80px]"
      isActiveFunc={(value) => !!editor?.isActive('textStyle', { fontFamily: value })}
      isEnabledFunc={() => !!editor?.can().setFontFamily(FONT_FAMILY_OPTIONS_DEFAULT[0].value)}
      handleChange={setFontFamily}
    />
  );
};

export default FontFamilyComponent;
