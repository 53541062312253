import axios from 'axios';
import type { FormikContextType } from 'formik/dist/types';
import { noop } from 'lodash';
import type { BaseSchema } from 'yup';
import * as Yup from 'yup';

import { onNextWizardValidation } from '~/components/Admin/WizardUtils/Utils';
import type { StepProps } from '~/components/core/Stepper/Step';
import type {
  MoiFormikValues,
  MoiMethod,
  MoiStepKeys,
  MoiStepProps,
} from '~/components/exposures/moi/MoiStepper/types';
import { GENERAL_EXPOSURE_FAKE_ID } from '~/components/exposures/moi/MoiUtils';
import MOI_STATUSES from '~/server_shared/generated-types/MOI_STATUSES';
import { reportAxiosError } from '~/Utils';

import AssigneeStep from './AssigneeStep';
import AssignmentProgressStep from './AssignmentProgressStep';
import SelectMethodStep from './SelectMethodStep';

export interface MoiStepConfig extends Omit<StepProps, 'children'> {
  key: MoiStepKeys;
  Content: React.FC<MoiStepProps>;
  validationSchema: BaseSchema;
}

interface MoiStepsGetterParams {
  formikContext?: FormikContextType<MoiFormikValues>;
  moiMethodsById?: { [key: string]: MoiMethod };
  claim_id?: number;
}

export const getAssignmentProgressStepConfig = ({ formikContext }: MoiStepsGetterParams): MoiStepConfig => {
  return {
    key: 'assignment_progress',
    label: 'Assignment Progress',
    Content: AssignmentProgressStep,
    onNext: () => onNextWizardValidation(formikContext),
    onBack: noop,
    disableNext: false,
    disableBack: false,
    validationSchema: Yup.object().shape({
      assignment_date: Yup.date().required('Required'),
      due_datetime: Yup.date().nullable().min(Yup.ref('assignment_date'), 'must be after assignment date'),
      completion_datetime: Yup.date().min(Yup.ref('assignment_date'), 'must be after assignment date'),
      special_instructions: Yup.string().nullable(),
      status: Yup.string().oneOf(Object.keys(MOI_STATUSES)).required('Required'),
      reject_reason: Yup.string()
        .nullable()
        .when('status', { is: 'rejected', then: Yup.string().required('Required') }),
      scheduling_inspection_contact_id: Yup.number().nullable(),
      note: Yup.string().nullable().typeError('Must be a string'),
      inspection_location: Yup.object().nullable(),
    }),
  };
};

const MOI_STEPS = ({ formikContext, moiMethodsById, claim_id }: MoiStepsGetterParams): MoiStepConfig[] => {
  return [
    {
      key: 'assignment',
      label: 'Assignment',
      Content: SelectMethodStep,
      onNext: () => onNextWizardValidation(formikContext),
      onBack: noop,
      disableNext: false,
      disableBack: false,
      validationSchema: Yup.object().shape({
        moi_method_id: Yup.number()
          .nullable()
          .when('method', { is: undefined, then: Yup.number().required('Required') }),
        exposure_ids: Yup.array().of(Yup.number()).required('Required').min(1, 'Required'),
      }),
    },
    {
      key: 'assignee',
      label: 'Assignee',
      Content: AssigneeStep,
      onNext: async () => {
        if (formikContext?.values.selectedVendor) {
          try {
            formikContext.setSubmitting(true);
            const { data } = await axios.post(`/api/v1/claims/${claim_id}/method_of_inspection/select_vendor`, {
              appraiser_name: formikContext.values.selectedVendor.name,
              appraiser_code: formikContext.values.selectedVendor.appraiser_code,
              phone_number: formikContext.values.selectedVendor.phone_number,
              address: {
                address_line: formikContext.values.selectedVendor.address.address_line,
                city: formikContext.values.selectedVendor.address.city,
                state: formikContext.values.selectedVendor.address.state,
                postal_code: formikContext.values.selectedVendor.address.postal_code,
              },
              radius: formikContext.values.vendor_distance,
              name: formikContext.values.vendor_name,
              vendor_type: formikContext.values.vendor_type,
              moi_method_key: moiMethodsById?.[formikContext.values.moi_method_id]?.key,
              exposure_ids: formikContext.values.exposure_ids?.includes(GENERAL_EXPOSURE_FAKE_ID)
                ? []
                : formikContext.values.exposure_ids,
              method_specific: formikContext.values.method_specific,
            });

            formikContext.setFieldValue('contact_id', data.contact_id);
          } catch (err) {
            await reportAxiosError(err);
          } finally {
            formikContext?.setSubmitting(false);
          }
        }
        return onNextWizardValidation(formikContext);
      },
      onBack: noop,
      disableNext:
        (formikContext?.values?.assignee_type === 'appraiser' && !formikContext?.values?.selectedVendor) ||
        formikContext?.isSubmitting ||
        !formikContext?.values?.assignee_type,
      disableBack: false,
      validationSchema: Yup.object().shape({
        assignee_type: Yup.string().oneOf(['internal_user', 'service_provider', 'appraiser']).required('Required'),
        user_id: Yup.string()
          .nullable()
          .when('assignee_type', { is: 'internal_user', then: Yup.string().required('Required') }),
        contact_id: Yup.number()
          .nullable()
          .when('assignee_type', (assigneeType, schema) => {
            if (['service_provider', 'appraiser'].includes(assigneeType)) {
              return schema.required('Required');
            }
            return schema;
          }),
      }),
    },
    getAssignmentProgressStepConfig({ formikContext }),
  ];
};

export default MOI_STEPS;
