import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { LEGAL_ACTION_SUMMARY_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { LegalActionsSummaryFormValues } from '../../FormFragments/FormValuesTypes';
import { LegalActionsSummaryForm } from '../../FormFragments/LegalActionsSummaryForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const EditLegalActionSummaryDialog: React.FC<DialogPropsWithLegalAction> = ({
  legalAction,
  onClose,
  onSubmitSuccess,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: LegalActionsSummaryFormValues) => {
    try {
      await axios.patch(backendRoutes.legalAction.base, values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<LegalActionsSummaryFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: {
          is_claim_level: legalAction.is_claim_level,
          exposures: legalAction.exposures.map((exposure) => exposure.id),
          display_name: legalAction.display_name,
          priority: legalAction.priority,
          lawsuit_amount: legalAction.lawsuit_amount,
          complaint_allegations_summary: legalAction.complaint_allegations_summary,
        },
        validationSchema: Yup.object().shape(LEGAL_ACTION_SUMMARY_VALIDATION_SCHEMA),
      }}
      title="Edit Legal Action Summary"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <LegalActionsSummaryForm />
      </div>
    </FormDialog>
  );
};
