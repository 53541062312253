import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import cn from '~/Utils/cn';

import { FsMenuItem, Text } from '../../../../core';
import ArrayFieldFormik from '../../../../core/Formik/NestedFieldFormik/ArrayFieldFormik';
import { PlusIcon } from '../../../../icons';
import { ALL_LICENSES_FIELD_ID, getStateName, OTHERS_FIELD_ID, RESIDENCY_FIELD_ID, stateCodesArray } from '../utils';

import { LicenseExpirationWarningIcon } from './LicenseExpirationWarningIcon';
import { LicensesExpirationAlertBanner } from './LicensesExpirationAlertBanner';

import colors from '../../../../../assets/colors.module.scss';
import styles from './Licenses.module.scss';

const STATE_OPTIONS_AS_CHILDREN = stateCodesArray.map((stateCode) => (
  <FsMenuItem key={stateCode} value={stateCode}>
    {getStateName(stateCode)}
  </FsMenuItem>
));

export const AdjusterLicenses = () => {
  const { values } = useFormikContext();
  const hasLicenses = !!getIn(values, ALL_LICENSES_FIELD_ID).length;
  const arrayFieldConfig = [
    {
      id: 'state',
      label: 'State',
      type: 'text',
      additionalProps: {
        select: true,
        children: STATE_OPTIONS_AS_CHILDREN,
      },
      disableEditAfterInitialSet: true,
    },
    {
      id: 'license_id',
      label: 'License ID',
      type: 'text',
      disableEditAfterInitialSet: true,
    },
    {
      id: 'effective_date',
      label: 'Effective Date',
      type: 'date',
    },
    {
      id: 'expiration_date',
      label: 'Expiration Date',
      type: 'date',
    },
    {
      id: 'expiration_date',
      type: 'custom',
      customComponent: LicenseExpirationWarningIcon,
      containerClassName: cn(styles.arrayItemContainer, styles.icon),
    },
  ];

  const isResidencyLicenseSet =
    getIn(values, RESIDENCY_FIELD_ID).filter((license) => license.is_residency_license).length > 0;

  return (
    <div className={styles.licensesContainer}>
      <CollapsibleWrapper
        title="Licenses"
        variant={CollapsibleWrapper.VARIANT.default}
        backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.LIST}
        actionCard
        noDivider
        noBorder
        defaultState={hasLicenses}
      >
        <div className={styles.alertContainer}>
          <LicensesExpirationAlertBanner />
        </div>
        <div className={styles.licensesSectionContainer}>
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
            Resident License
          </Text>
          <ArrayFieldFormik
            innerObjectConfig={arrayFieldConfig}
            initialNewItemValues={{
              license_id: '',
              effective_date: '',
              expiration_date: '',
              state: '',
              is_residency_license: true,
            }}
            fieldId={RESIDENCY_FIELD_ID}
            allowDeletingInitialValues
            addNewItemButtonText={!isResidencyLicenseSet && <PlusButtonLabel isResidency />}
          />
        </div>
        <div className={styles.licensesSectionContainer}>
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
            Non-Resident Licenses
          </Text>
          <ArrayFieldFormik
            innerObjectConfig={arrayFieldConfig}
            initialNewItemValues={{
              license_id: '',
              effective_date: '',
              expiration_date: '',
              state: '',
              is_residency_license: false,
            }}
            fieldId={OTHERS_FIELD_ID}
            addNewItemButtonText={<PlusButtonLabel disabled={!isResidencyLicenseSet} />}
            allowDeletingInitialValues
            disabled={!isResidencyLicenseSet}
          />
        </div>
      </CollapsibleWrapper>
    </div>
  );
};

const PlusButtonLabel = ({ isResidency, disabled }) => (
  <div className={styles.plusButtonLabelContainer}>
    <div className={styles.iconContainer}>
      <PlusIcon iconColor={disabled ? undefined : colors.buttonLink} disabled={disabled} />
    </div>
    <span>{isResidency ? 'Add Resident License' : 'Add License'}</span>
  </div>
);

PlusButtonLabel.propTypes = {
  isResidency: PropTypes.bool,
  disabled: PropTypes.bool,
};
