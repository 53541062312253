import type { ReactNode } from 'react';
import React from 'react';

import useNavigationBlocker from './useNavigationBlocker';

interface Props {
  children: ReactNode;
  shouldPreventNavigation: (pathname: string) => boolean;
  getConfirmationMessage: (pathname: string) => string;
}

const NavigationBlocker: React.FC<Props> = ({ children, shouldPreventNavigation, getConfirmationMessage }) => {
  useNavigationBlocker(shouldPreventNavigation, getConfirmationMessage);

  return <>{children}</>;
};

export default NavigationBlocker;
