import React from 'react';

import SvgIcon from '../core/SvgIcon';

const WarningIcon = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path d="M8 6.5V9" stroke="inherit" fill="none" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7.13788 2.50004L1.63788 12C1.55026 12.1518 1.50406 12.3239 1.50391 12.4992C1.50375 12.6744 1.54965 12.8466 1.637 12.9985C1.72435 13.1504 1.85009 13.2767 2.00162 13.3647C2.15315 13.4528 2.32515 13.4994 2.50038 13.5H13.5004C13.6756 13.4994 13.8476 13.4528 13.9992 13.3647C14.1507 13.2767 14.2764 13.1504 14.3638 12.9985C14.4511 12.8466 14.497 12.6744 14.4969 12.4992C14.4967 12.3239 14.4505 12.1518 14.3629 12L8.86288 2.50004C8.77592 2.34808 8.65036 2.22179 8.4989 2.13396C8.34744 2.04613 8.17547 1.99988 8.00038 1.99988C7.8253 1.99988 7.65333 2.04613 7.50187 2.13396C7.35041 2.22179 7.22485 2.34808 7.13788 2.50004V2.50004Z"
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6875 11.25C8.6875 11.6297 8.3797 11.9375 8 11.9375C7.6203 11.9375 7.3125 11.6297 7.3125 11.25C7.3125 10.8703 7.6203 10.5625 8 10.5625C8.3797 10.5625 8.6875 10.8703 8.6875 11.25Z"
      fill="inherit"
      stroke="inherit"
      strokeWidth="0.125"
    />
  </SvgIcon>
);

export default WarningIcon;
