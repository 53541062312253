import React from 'react';
import PropTypes from 'prop-types';

import { useCms } from '../../hooks/useCms';

import styles from './OrganizationLogo.module.scss';

const EMPTY_PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const OrganizationLogo = ({ base64Image, onNoLogo, overrideCache = false }) => {
  const { user } = useCms();
  const organizationId = user.organization_id;

  const overrideCacheQuery = overrideCache ? `?v=${Math.random()}` : '';
  const url = base64Image ?? `/api/v1/organizations/${organizationId}/logo${overrideCacheQuery}`;

  return (
    <img
      src={url}
      className={styles.logo}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = EMPTY_PIXEL;
        if (onNoLogo) {
          onNoLogo();
        }
      }}
    />
  );
};

OrganizationLogo.propTypes = {
  base64Image: PropTypes.string,
  onNoLogo: PropTypes.func,
  overrideCache: PropTypes.bool,
};

export default OrganizationLogo;
