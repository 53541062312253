import React from 'react';
import { Divider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import { useLob } from '~/components/hooks/useLob';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { isoDateToUs, timeToLocalTime } from '../../../../DateTimeUtils';
import { getLobDescription, getLobIcon } from '../../../../Utils/lobUtils';
import { ContactEntity } from '../../../Contact';
import { usePolicy } from '../../../PolicyContainer';

import { useStyles } from '../../../../assets/styles';
import styles from './index.module.scss';

const DividerCell = () => (
  <TableCell className={styles.dividerCell}>
    <Divider orientation="vertical" variant="middle" className={styles.divider} />
  </TableCell>
);

export const getEffectiveDateText = (policy) =>
  isoDateToUs(policy.policy_effective_date) + ' ' + timeToLocalTime(policy.effective_time);

export const getExpirationDateText = (policy) =>
  isoDateToUs(policy.policy_expiration_date) + ' ' + timeToLocalTime(policy.expiration_time);

const FnolHeader = () => {
  const classes = useStyles();
  const { policy } = usePolicy();
  const { lob } = useLob();
  const { lobConfigurationsDict } = useLobConfiguration();
  const subOrganization = policy?.sub_organization;
  const lobName = getLobDescription(lob, lobConfigurationsDict);

  return (
    <Table>
      <TableBody className={styles.fnolHeader}>
        <TableRow className={styles.fnolHeaderRow}>
          <TableCell className={styles.tableCell}>
            <div className={styles.headline}>
              <span className={styles.lobIcon}>{getLobIcon({ lob, lobConfigurationsDict })}</span>
              <span>
                {lobName}
                {' Policy Number'}
                <div className={styles.policyNumber}>
                  {policy.is_manual ? 'Policy Not Found' : policy.policy_number}
                </div>
              </span>
            </div>
          </TableCell>
          {subOrganization && (
            <>
              <DividerCell />
              <TableCell className={styles.tableCell}>
                <div className={styles.headline}>Sub Organization</div>
                <div>{subOrganization.name}</div>
              </TableCell>
            </>
          )}
          <DividerCell />
          <TableCell className={styles.tableCell}>
            <div className={styles.headline}>Policyholder</div>
            <ContactEntity
              classes={classes}
              contactId={policy.insured_contact.id}
              contactDisplayName={policy.insured_contact.full_name}
            />
          </TableCell>
          {!policy.is_manual && (
            <>
              <DividerCell />
              <TableCell className={styles.tableCell}>
                <div className={styles.headline}>Effective Date</div>
                <div>{getEffectiveDateText(policy)}</div>
              </TableCell>
              <DividerCell />
              <TableCell className={styles.tableCell}>
                <div className={styles.headline}>Expiration Date</div>
                <div>{getExpirationDateText(policy)}</div>
              </TableCell>
            </>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default FnolHeader;
