import { useEffect } from 'react';

import { reportAxiosError } from '../../Utils';
import { useCms } from '../hooks/useCms';

import useScript from './useScript';

const SCRIPT_URL = 'https://static.zdassets.com/ekr/snippet.js?key=6700de42-6a27-4b95-89b6-edc4669cb5de';
const SCRIPT_ID = 'ze-snippet';

const defaultWidgetSettings = {
  position: { horizontal: 'right', vertical: 'bottom' },
};

const ZenDesk = () => {
  const { user } = useCms();

  const { isLoaded } = useScript({
    id: SCRIPT_ID,
    scriptUrl: SCRIPT_URL,
  });

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (!window?.zE) {
      return;
    }

    window.zESettings = {
      webWidget: defaultWidgetSettings,
    };

    try {
      window?.zE('webWidget', 'prefill', {
        name: { value: `${user.first_name} ${user.last_name}` },
        email: { value: user.email },
      });
    } catch (error) {
      reportAxiosError(error);
    }
  }, [user, isLoaded]);

  return null;
};
export const usePositionLeftZenDesk = () => {
  useEffect(() => {
    if (!window?.zE) {
      return;
    }
    try {
      window?.zE('webWidget', 'updateSettings', {
        position: {
          horizontal: 'left',
        },
      });
    } catch (error) {
      reportAxiosError(error);
    }

    return () => {
      try {
        window?.zE('webWidget', 'updateSettings', defaultWidgetSettings);
      } catch (error) {
        reportAxiosError(error);
      }
    };
  }, []);
};
export default ZenDesk;
