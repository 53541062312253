import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';

import AdjusterSelectTextFieldFormik from '../../Adjuster/AdjusterSelectTextFieldFormik';
import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { getClaimCoverageTypesDict, isFeatureEnabled, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { useCms } from '../hooks/useCms';
import LoadingDialog from '../LoadingDialog';
import useOrganization from '../OrganizationContext';
import TextFieldFormik from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { ChooseOwnerAssignment } from './addExposure/ChooseOwnerAssignment';

import { useStyles } from '../../assets/styles';
import addExposuresStyles from './addExposure/addExposures.module.scss';

const exposureFields = {
  coverage_type: '',
  handling_adjuster_id: '',
  injured_party_id: '',
  involved_person_id: '',
  involved_property_id: '',
  involved_property_name: '',
  involved_property_contact_id: '',
  should_use_assignment_rules: '',
};

function AddExposureFormikInnerDialog(props) {
  const {
    open,
    isSubmitting,
    handleSubmit,
    handleReset,
    values,
    setFieldValue,
    setValues,
    onCancel,
    newExposureStore,
    onUpdateNewExposureStore,
  } = props;
  const { claim } = useClaim();
  const classes = useStyles();

  const {
    isLoading: isLoadingPossibleCoverages,
    isError: isErrorPossibleCoverages,
    data: possibleCoveragesRet,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/exposures/coverages/possible_coverages`);

  const { isLoading } = useOrganization();
  const { userOrganization } = useCms();

  const isAssignmentsEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.ASSIGNMENTS_CONFIGURATION
  );
  const shouldHideHandlingAdjustersDropdown = isAssignmentsEnabled && values.should_use_assignment_rules !== false;

  const coverage_type = values.coverage_type;

  useEffect(() => {
    async function fetchNewExposureStore() {
      try {
        onUpdateNewExposureStore((prevExposureStore) => ({ ...prevExposureStore, isFetching: true }));
        const newExposureInvolvedInfoRes = await axios.get(
          `/api/v1/claims/${claim.id}/exposures/coverages/${coverage_type}/new_exposure_info`
        );
        setValues({ ...exposureFields, coverage_type });

        const newExposureInvolvedInfoData = newExposureInvolvedInfoRes.data;

        // If there is only one possible involved property choose it
        if (
          newExposureInvolvedInfoData.involved_property.required &&
          !newExposureInvolvedInfoData.involved_property.custom_possible &&
          newExposureInvolvedInfoData.possible_involved_properties.length === 1
        ) {
          setFieldValue('involved_property_id', newExposureInvolvedInfoData.possible_involved_properties[0].id);
        }

        setFieldValue('handling_adjuster_id', newExposureInvolvedInfoData.default_handling_adjuster_id || '');

        onUpdateNewExposureStore(newExposureInvolvedInfoData);
      } catch (error) {
        reportAxiosError(error);
      }
    }

    if (coverage_type) {
      fetchNewExposureStore();
    }

    return () => onUpdateNewExposureStore({});
  }, [coverage_type, claim.id, onUpdateNewExposureStore, setValues, setFieldValue]);

  function buildCoverageTypesDict() {
    let coverageTypesDict = { ...getClaimCoverageTypesDict(claim) };

    if (claim.type === 'home_claim' && Object.keys(coverageTypesDict).includes('coverage_e')) {
      const coverage_e_text = coverageTypesDict['coverage_e'];
      delete coverageTypesDict['coverage_e'];
      coverageTypesDict['coverage_e_bi'] = `${coverage_e_text} - BI`;
      coverageTypesDict['coverage_e_pd'] = `${coverage_e_text} - PD`;
    }

    return coverageTypesDict;
  }

  const isStoreReady = newExposureStore && !newExposureStore.isFetching;
  const isDisabled = isSubmitting || !isStoreReady || isLoading;

  const coverageTypesDict = buildCoverageTypesDict();

  if (!open) {
    return <></>;
  }

  const onClose = () => {
    handleReset();
    onCancel();
  };

  if (isLoadingPossibleCoverages || isErrorPossibleCoverages) {
    return <LoadingDialog isError={isErrorPossibleCoverages} onClose={onClose} track="Add Exposure" />;
  }

  const possibleCoverages = possibleCoveragesRet.possible_coverages;

  return (
    <CardDialog title="Add Exposure" isDialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <Grid container spacing={1}>
        {claim.is_closed && <Typography>This will result in reopening the claim</Typography>}
        <Grid item xs={12}>
          <TextFieldFormik
            id="coverage_type"
            select
            label="Coverage"
            fullWidth
            className={classes.textField}
            disabled={isDisabled}
          >
            {possibleCoverages.sort().map((coverage_key) => (
              <MenuItem key={coverage_key} value={coverage_key}>
                {coverageTypesDict[coverage_key]}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
        {newExposureStore.involved_person?.supported && (
          <Grid item xs={12}>
            <TextFieldFormik
              id="involved_person_id"
              select
              label="Involved Person"
              fullWidth
              className={classes.textField}
              disabled={isDisabled}
              clearable={newExposureStore.involved_person?.one_or_the_other}
            >
              {newExposureStore.possible_involved_persons.map((involved_person) => (
                <MenuItem key={involved_person.id} value={involved_person.id}>
                  {involved_person.contact.full_name}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        )}
        {newExposureStore.involved_property?.supported && (
          <Grid item xs={12}>
            <TextFieldFormik
              id="involved_property_id"
              select
              label="Involved Property"
              fullWidth
              className={classes.textField}
              disabled={isDisabled}
              clearable={newExposureStore.involved_property?.one_or_the_other}
            >
              {newExposureStore.possible_involved_properties.map((involved_property) => (
                <MenuItem key={involved_property.id} value={involved_property.id}>
                  {involved_property.display_name}
                </MenuItem>
              ))}
              {newExposureStore.involved_property.custom_possible && (
                <MenuItem key={0} value={0}>
                  Not a Vehicle
                </MenuItem>
              )}
            </TextFieldFormik>
          </Grid>
        )}
        {values.involved_property_id === 0 && (
          <>
            <Grid item xs={12}>
              <TextFieldFormik
                id="involved_property_name"
                label="Property"
                fullWidth
                className={classes.textField}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <ContactTextFieldFormik
                id="involved_property_contact"
                label="Claimant"
                acceptedRoles={['claimant']}
                className={classes.textField}
                fixedSearchResults
                fullWidth
              />
            </Grid>
          </>
        )}
        {isAssignmentsEnabled ? (
          <ChooseOwnerAssignment formikId="should_use_assignment_rules" disabled={isDisabled} />
        ) : null}
        {shouldHideHandlingAdjustersDropdown ? null : (
          <Grid item xs={12}>
            <div className={isAssignmentsEnabled ? addExposuresStyles.handlingAdjusterContainer : null}>
              <AdjusterSelectTextFieldFormik
                id="handling_adjuster_id"
                label="Handling Adjuster"
                className={classes.textField}
                disabled={isDisabled}
                fullWidth
                extraValues={
                  claim.handling_adjuster
                    ? [
                        {
                          id: 'claim_owner',
                          username: `File Owner: ${claim.handling_adjuster}`,
                        },
                      ]
                    : []
                }
                checkLicenses
              />
            </div>
          </Grid>
        )}
      </Grid>
      <div>
        <div className={classes.buttonsContainer}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isDisabled}>
            Add Exposure
          </Button>
        </div>
      </div>
    </CardDialog>
  );
}

AddExposureFormikInnerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  newExposureStore: PropTypes.object.isRequired,
  onUpdateNewExposureStore: PropTypes.func.isRequired,
};

function AddExposureDialog(props) {
  const { open, onCancel, onAddExposure } = props;

  const { claim } = useClaim();
  const { userOrganization } = useCms();
  const isAssignmentsEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.ASSIGNMENTS_CONFIGURATION
  );

  const [newExposureStore, setNewExposureStore] = useState({});

  const getPartyValidation = (settings, isPerson) => {
    if (!settings?.supported) {
      return undefined;
    }
    const otherPartyId = isPerson ? 'involved_property_id' : 'involved_person_id';

    if (settings.one_or_the_other) {
      return Yup.number().when(otherPartyId, {
        is: (value) => !value,
        then: Yup.number().required('Either person or property is required'),
        otherwise: Yup.number().test(
          'one-or-other-check',
          'Person and property cannot both be selected',
          (partyId) => !partyId
        ),
      });
    }

    if (settings.required) {
      return Yup.number().required('Required');
    }

    return Yup.number();
  };

  return (
    <Formik
      initialValues={{ ...exposureFields }}
      validationSchema={Yup.object().shape(
        {
          coverage_type: Yup.string().required('Required'),
          involved_person_id: getPartyValidation(newExposureStore.involved_person, true),
          involved_property_id: getPartyValidation(newExposureStore.involved_property, false),
          involved_property_name: _.get(newExposureStore, 'involved_property.custom_possible')
            ? Yup.string().when('involved_property_id', {
                is: 0,
                then: Yup.string().required('Required'),
              })
            : undefined,
          involved_property_contact_id: _.get(newExposureStore, 'involved_property.custom_possible')
            ? Yup.number().when('involved_property_id', {
                is: 0,
                then: Yup.number().required('Required'),
              })
            : undefined,
          should_use_assignment_rules: Yup.boolean().test('is_required', 'Required', (value) => {
            if (isAssignmentsEnabled) {
              return value !== undefined;
            }
            return true;
          }),
          handling_adjuster_id: Yup.string().when('should_use_assignment_rules', {
            is: (should_use_assignment_rules) =>
              !should_use_assignment_rules ||
              !isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ASSIGNMENTS_CONFIGURATION),
            then: Yup.string().required('Required'),
          }),
          // below is due to the cyclical dependency
        },
        [['involved_person_id', 'involved_property_id']]
      )}
      enableReinitialize
      onSubmit={(values, formikBag) => {
        axios
          .post(`/api/v1/claims/${claim.id}/exposures`, values)
          .then((response) => {
            Promise.resolve(onAddExposure(response.id)).then(() => formikBag.resetForm());
          })
          .catch((error) => {
            formikBag.setSubmitting(false);
            reportAxiosError(error);
          });
      }}
    >
      {(formikProps) => (
        <AddExposureFormikInnerDialog
          open={open}
          onCancel={onCancel}
          newExposureStore={newExposureStore}
          onUpdateNewExposureStore={setNewExposureStore}
          {...formikProps}
        />
      )}
    </Formik>
  );
}

AddExposureDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onAddExposure: PropTypes.func.isRequired,
};

export { AddExposureDialog };
