import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';
import axios from 'axios';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import cn from '~/Utils/cn';

import { ROLES_TYPES_DICT } from '../../../../../Types';
import { reportAxiosError, stringCmp } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { SortableTable, TooltipIcon } from '../../../../core';
import { CheckIcon, PencilIcon as OurEditIcon, UploadIcon, WarningCircleIcon, WarningIcon } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';
import LoadingIndicator from '../../../../LoadingIndicator';
import OutOfOfficeDialog from '../../../../OutOfOfficeDialog';
import OverflowTextWithToolTip from '../../../../OverflowTextWithToolTip';
import UploadFileDialog from '../../../../UploadFileDialog';
import useDataFetcher from '../../../../useDataFetcher';
import { ReassignContainer } from '../../../Reassignment';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { isOrgHierarchyWorkflowsFeatureEnabled } from '../../../Users/OrganizationUnits/Utils';
import { UploadDialogContent } from '../../../Users/UploadDialogContent';
import { AdjusterEditDialog } from '../Dialogs/AdjusterEditDialog';
import { getStateName, isLicensesEnabled } from '../utils';

import { useStyles } from '../../../../../assets/styles';
import colors from '../../../../../assets/colors.module.scss';
import styles from './OrganizationAdjusters.module.scss';

export function OrganizationAdjusters({ adjusters, isError, isLoading, reloadAdjusters, filterFunc }) {
  const classes = useStyles();
  const { organization } = useSysconfig();
  const isOrgHierarchyEnabled = isOrgHierarchyWorkflowsFeatureEnabled({ organization });
  const [isUploadLicensesDialogOpen, setIsUploadLicensesDialogOpen] = useState(false);
  const [showOutOfOfficeDialog, setShowOutOfOfficeDialog] = useState(false);
  const [outOfOfficeUser, setOutOfOfficeUser] = useState(null);
  const licensesRoute = `/sysconfig/api/v1/organizations/${organization.id}/licenses`;
  const allAdjustersIncludingDeactivated = adjusters;
  const allActiveAdjusters = adjusters?.filter(
    (adjuster) => ROLES_TYPES_DICT[adjuster.role.role_type].is_adjuster && !adjuster.is_removed
  );

  const filteredAdjusters = adjusters?.filter((adjuster) => {
    return filterFunc ? filterFunc(adjuster) : true;
  });

  const visibleAdjusters = filteredAdjusters
    ?.filter((adjuster) => !ROLES_TYPES_DICT[adjuster.role.role_type].is_hidden)
    .map((adjuster) => {
      if (adjuster.is_removed) {
        return {
          ...adjuster,
          username: `${adjuster.username} (Deactivated)`,
          styles: { color: '#00000061', pointerEvents: 'none' },
        };
      }

      return adjuster;
    });

  const [editingAdjuster, setEditingAdjuster] = useState(undefined);

  const handleEditAdjusterSave = async (values) => {
    try {
      await axios.patch(`/sysconfig/api/v1/organizations/${organization.id}/users/${editingAdjuster.id}`, values);
      await reloadAdjusters();
      setEditingAdjuster(undefined);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const handleSetOutOfOffice = async (values) => {
    try {
      await axios.post(`/api/v1/users/${outOfOfficeUser.id}/out_of_office`, values);
      await reloadAdjusters();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const openOutOfOfficeDialog = (user) => {
    setOutOfOfficeUser(user);
    setShowOutOfOfficeDialog(true);
  };

  const closeOutOfOfficeDialog = () => {
    setShowOutOfOfficeDialog(false);
    setOutOfOfficeUser(null);
  };

  const handleToggleOutOfOffice = async (user, isOutOfOffice) => {
    try {
      if (isOutOfOffice) {
        openOutOfOfficeDialog(user);
      } else {
        await axios.delete(`/api/v1/users/${user.id}/out_of_office`);
        await reloadAdjusters();
      }
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const {
    isLoading: isLoadingLatestReassignmentJobs,
    isError: isErrorLatestReassignmentJobs,
    data: reassignmentJobs,
    reloadData: reloadReassignmentJobs,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/users/get_latest_reassignment_jobs`);

  if (isLoadingLatestReassignmentJobs || isLoading || isError || isErrorLatestReassignmentJobs) {
    return <LoadingIndicator isError={isError || isErrorLatestReassignmentJobs} />;
  }
  const usersWithReassignmentJob = _.keyBy(reassignmentJobs, 'reassigned_user_id');

  const infIfNeg = (amount) => (amount < 0 ? 'INF' : amount);

  const yesOption = <CheckIcon width={18} height={18} iconColor={colors.textDisabled} />;

  const isEmailNotificationAssignment = (row) => !!row.extra_settings.email_notification_assignment;

  const handleUploadLicenses = async () => {
    setIsUploadLicensesDialogOpen(false);
    await reloadAdjusters();
  };

  const handleUploadError = async (error) => {
    await reportAxiosError(error);
  };

  const onCsvTemplateClick = () => {
    const link = document.createElement('a');
    link.download = 'licenses_template.csv';
    link.href = licensesRoute;
    link.click();
  };

  const getLimitsColumns = () => {
    if (organization.are_payment_limits_split) {
      return [
        {
          id: 'indemnity_reserves_limit',
          numeric: true,
          label: 'Indemnity Reserves',
          specialCell: (row) => infIfNeg(row.indemnity_reserves_limit),
        },
        {
          id: 'indemnity_payments_limit',
          numeric: true,
          label: 'Indemnity Payments',
          specialCell: (row) => infIfNeg(row.indemnity_payments_limit),
        },
        {
          id: 'expenses_reserves_limit',
          numeric: true,
          label: 'Expenses Reserves',
          specialCell: (row) => infIfNeg(row.expenses_reserves_limit),
        },
        {
          id: 'expenses_payments_limit',
          numeric: true,
          label: 'Expenses Payments',
          specialCell: (row) => infIfNeg(row.expenses_payments_limit),
        },
      ];
    } else {
      return [
        {
          id: 'reserves_limit',
          numeric: true,
          label: 'Reserves',
          specialCell: (row) => infIfNeg(row.reserves_limit),
        },
        {
          id: 'payments_limit',
          numeric: true,
          label: 'Payments',
          specialCell: (row) => infIfNeg(row.payments_limit),
        },
      ];
    }
  };

  const getWarningIconForAdjusterLicenses = ({ adjuster }) => {
    if (!adjuster?.licenses) return null;

    if (adjuster.expiredLicenses > 0) {
      return (
        <TooltipIcon title="Expired licenses">
          <WarningCircleIcon iconColor={colors.statusFailed} />
        </TooltipIcon>
      );
    }

    if (adjuster.expiringLicenses > 0) {
      return (
        <TooltipIcon title="Licenses are about to expire">
          <WarningIcon iconColor={colors.statusWarning} />
        </TooltipIcon>
      );
    }

    return null;
  };

  const columnData = [
    { id: 'username', numeric: false, label: 'Name' },
    { id: 'title', numeric: false, label: 'Title' },
    ...(isLicensesEnabled(organization)
      ? [
          {
            id: 'licenses',
            numeric: false,
            label: 'Licenses',
            disableSort: true,
            specialCell: (row) => {
              const states = row.licenses.map((license) => getStateName(license.state)).join(', ');
              return (
                <div className={styles.licensesRow}>
                  {getWarningIconForAdjusterLicenses({ adjuster: row })}
                  <OverflowTextWithToolTip maxWidth={160}>{states}</OverflowTextWithToolTip>
                </div>
              );
            },
          },
        ]
      : []),
    ...(isOrgHierarchyEnabled ? [] : [{ id: 'supervisor_name', numeric: false, label: 'Supervisor' }]),
    ...getLimitsColumns(),
    {
      id: 'is_org_level_supervisor',
      numeric: false,
      label: 'Is Org-Level Supervisor',
      specialCell: (row) => (row.is_org_level_supervisor ? yesOption : ''),
    },
    {
      id: 'extra_settings.email_notification_assignment',
      numeric: false,
      label: 'Send email about assignments',
      specialCell: (user) => (isEmailNotificationAssignment(user) ? yesOption : ''),
      specialCmpFunc: (user1, user2) => isEmailNotificationAssignment(user2) - isEmailNotificationAssignment(user1),
    },
    {
      id: 'is_out_of_office',
      numeric: false,
      label: 'Out of Office',
      specialCell: (user) => (
        <Switch
          value={user.is_out_of_office}
          checked={user.is_out_of_office}
          onChange={() => handleToggleOutOfOffice(user, !user.is_out_of_office)}
          className={classes.formsSwitch}
          size="small"
          disabled={user.is_removed}
        />
      ),
    },
  ];

  columnData.push({
    id: 'actions',
    label: 'Actions',
    width: '100px',
    disableSort: true,
    specialCell: (user) => (
      <div style={{ display: 'flex' }}>
        <ReassignContainer
          adjuster={user}
          organization={organization}
          onReassign={reloadAdjusters}
          allAdjusters={allAdjustersIncludingDeactivated.filter((adjuster) => adjuster !== user)}
          visibleAdjusters={allActiveAdjusters
            .filter((adjuster) => adjuster !== user)
            .sort((option1, option2) => stringCmp(option1.username, option2.username))}
          reassignmentJob={usersWithReassignmentJob[user.id]}
          reloadReassignmentJobs={reloadReassignmentJobs}
        />
        <InlineIconButton
          tooltipTitle="Edit"
          icon={OurEditIcon}
          className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
          onClick={(e) => {
            e.stopPropagation();
            setEditingAdjuster(user);
          }}
          wrapWithSpan
          disabled={user.is_removed}
        />
      </div>
    ),
  });

  return (
    <>
      <div className={cn(styles.organizationAdjusters)}>
        <CardDialog
          title="Adjusters"
          action={
            isLicensesEnabled(organization) ? (
              <div className={classes.buttonsContainer}>
                <Button color="primary" onClick={() => setIsUploadLicensesDialogOpen(true)}>
                  <UploadIcon size={16} iconColor={colors.buttonLink} className={classes.leftButtonIcon} />
                  Update Licenses
                </Button>
              </div>
            ) : null
          }
        >
          <SortableTable
            columns={columnData}
            rows={visibleAdjusters}
            maxHeight="50vh"
            stickyHeader
            autoPaginateRowsPerPage={15}
          />
        </CardDialog>
      </div>
      {editingAdjuster && (
        <AdjusterEditDialog
          user={editingAdjuster}
          onSaveAdjusterDetails={handleEditAdjusterSave}
          onEditCancel={() => setEditingAdjuster(undefined)}
        />
      )}
      {showOutOfOfficeDialog && (
        <OutOfOfficeDialog user={outOfOfficeUser} onSubmit={handleSetOutOfOffice} onClose={closeOutOfOfficeDialog} />
      )}
      {isUploadLicensesDialogOpen ? (
        <UploadFileDialog
          open={isUploadLicensesDialogOpen}
          title="Import Licenses For Multiple Users"
          onClose={() => setIsUploadLicensesDialogOpen(false)}
          onUpload={handleUploadLicenses}
          onUploadError={handleUploadError}
          postUrl={licensesRoute}
          upperExplanation={
            <UploadDialogContent
              note={`The licenses you add will replace any of the adjuster's previously existing licenses.
              Dates must be in ISO format (YYYY-MM-DD)
              `}
              classes={classes}
              templateTooltip="Use this template and fill in the required fields to import multiple users’ details."
              onCsvTemplateClick={onCsvTemplateClick}
            />
          }
          openDialogError={false}
        />
      ) : null}
    </>
  );
}

OrganizationAdjusters.propTypes = {
  adjusters: PropTypes.array,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  reloadAdjusters: PropTypes.func,
  filterFunc: PropTypes.func,
};
const noop = () => {};
OrganizationAdjusters.defaultProps = {
  adjusters: [],
  isError: false,
  isLoading: false,
  reloadAdjusters: noop,
  filterFunc: undefined,
};
