import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const VendorIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon viewBox="0 0 17 16" fill="none" stroke="none" {...props}>
    <path
      d="M3.5 8.7251V13.0001C3.5 13.1327 3.55268 13.2599 3.64645 13.3537C3.74021 13.4474 3.86739 13.5001 4 13.5001H13C13.1326 13.5001 13.2598 13.4474 13.3536 13.3537C13.4473 13.2599 13.5 13.1327 13.5 13.0001V8.7251"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path
      d="M3.875 2.5H13.125C13.2335 2.50089 13.3388 2.5366 13.4254 2.60186C13.5121 2.66713 13.5755 2.75849 13.6062 2.8625L14.5 6H2.5L3.39375 2.8625C3.42455 2.75849 3.48794 2.66713 3.57458 2.60186C3.66122 2.5366 3.76653 2.50089 3.875 2.5V2.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path
      d="M6.5 6V7C6.5 7.53043 6.28929 8.03914 5.91421 8.41421C5.53914 8.78929 5.03043 9 4.5 9C3.96957 9 3.46086 8.78929 3.08579 8.41421C2.71071 8.03914 2.5 7.53043 2.5 7V6"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path
      d="M10.5 6V7C10.5 7.53043 10.2893 8.03914 9.91421 8.41421C9.53914 8.78929 9.03043 9 8.5 9C7.96957 9 7.46086 8.78929 7.08579 8.41421C6.71071 8.03914 6.5 7.53043 6.5 7V6"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path
      d="M14.5 6V7C14.5 7.53043 14.2893 8.03914 13.9142 8.41421C13.5391 8.78929 13.0304 9 12.5 9C11.9696 9 11.4609 8.78929 11.0858 8.41421C10.7107 8.03914 10.5 7.53043 10.5 7V6"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
  </SvgIcon>
);

VendorIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default VendorIcon;
