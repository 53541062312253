import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';

import { MARSHMALLOW_FAULTS_LIST, MARSHMALLOW_JOURNEY_PURPOSE_LIST, MARSHMALLOW_SPEEDS_LIST } from '../../../Types';
import CheckboxFormik from '../../CheckboxFormik';
import { TextFieldFormik } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

function MarshmallowFollowupDetails({ incidentType, incidentSubType, disabled }) {
  const classes = useStyles();
  const fieldsSubKey = 'marshmallow_extra';
  const { values } = useFormikContext();

  const commonComponent = (
    <>
      <Grid item xs={6}>
        <TextFieldFormik
          id={`${fieldsSubKey}.ph_fault_assessment`}
          select
          label="PH Fault Assessment"
          className={classes.textField}
          fullWidth
        >
          {MARSHMALLOW_FAULTS_LIST.map((purpose) => (
            <MenuItem key={purpose} value={purpose}>
              {purpose}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id={`${fieldsSubKey}.claim_handler_fault_assessment`}
          select
          label="Claim Handler Fault Assessment"
          className={classes.textField}
          fullWidth
        >
          {MARSHMALLOW_FAULTS_LIST.map((purpose) => (
            <MenuItem key={purpose} value={purpose}>
              {purpose}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={6}>
        <CheckboxFormik id={`${fieldsSubKey}.is_indemnity_review_needed`} label="Indemnity review needed" />
      </Grid>
      <Grid item xs={6}>
        <CheckboxFormik id={`${fieldsSubKey}.is_fraud_review_needed`} label="Fraud review needed" />
      </Grid>
      {(values[fieldsSubKey].is_indemnity_review_needed || values[fieldsSubKey].is_fraud_review_needed) && (
        <Grid item xs={12}>
          <TextFieldFormik
            id={`${fieldsSubKey}.review_concern_desc`}
            label="Concern Description"
            className={classes.textField}
            disabled={disabled}
            fullWidth
          />
        </Grid>
      )}
    </>
  );

  let incidentSpecificComponents = null;
  if (!(incidentType && incidentSubType)) {
    return commonComponent;
  }

  if (incidentType === 'accident') {
    incidentSpecificComponents = (
      <>
        <Grid item xs={6}>
          <TextFieldFormik
            id={`${fieldsSubKey}.journey_purpose`}
            select
            label="Journey Purpose"
            className={classes.textField}
            fullWidth
          >
            {MARSHMALLOW_JOURNEY_PURPOSE_LIST.map((purpose) => (
              <MenuItem key={purpose} value={purpose}>
                {purpose}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormik
            id={`${fieldsSubKey}.vehicle_speed`}
            select
            label="Speed Vehicle Was Going On"
            className={classes.textField}
            fullWidth
          >
            {MARSHMALLOW_SPEEDS_LIST.map((purpose) => (
              <MenuItem key={purpose} value={purpose}>
                {purpose}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
        <Grid item xs={12}>
          <YesNoQuestionFormik
            questionText="Was driver using the phone?"
            id={`${fieldsSubKey}.was_driver_using_the_phone`}
          />
        </Grid>
        <Grid item xs={12}>
          <YesNoQuestionFormik
            questionText="Was anyone under the influence of drugs/alcohol?"
            id={`${fieldsSubKey}.was_anyone_under_drugs`}
          />
        </Grid>
      </>
    );
  } else if (incidentType === 'fire') {
    incidentSpecificComponents = (
      <>
        <Grid item xs={12}>
          <YesNoQuestionFormik
            questionText="Were any hazardous goods being carried?"
            id={`${fieldsSubKey}.were_hazardous_carried`}
          />
        </Grid>
      </>
    );
  } else if (incidentType === 'theft') {
    incidentSpecificComponents = (
      <>
        <Grid item xs={6}>
          <TextFieldFormik
            id={`${fieldsSubKey}.keys_place_during_theft`}
            label="Keys Place During Theft"
            className={classes.textField}
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <YesNoQuestionFormik
            questionText="Were any belongings stolen from the vehicle?"
            id={`${fieldsSubKey}.were_belongings_stolen_from_vehicle`}
          />
        </Grid>
        <Grid item xs={6} />
        {getIn(values, `${fieldsSubKey}.were_belongings_stolen_from_vehicle`) && (
          <Grid item xs={12}>
            <TextFieldFormik
              id={`${fieldsSubKey}.belongings_stolen`}
              label="Belongings Stolen"
              className={classes.textField}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <YesNoQuestionFormik questionText="Was the vehicle locked?" id={`${fieldsSubKey}.was_vehicle_locked`} />
        </Grid>
        <Grid item xs={12}>
          <YesNoQuestionFormik
            questionText="Were the windows closed and secured?"
            id={`${fieldsSubKey}.were_windows_closed_and_secured`}
          />
        </Grid>
        <Grid item xs={12}>
          <YesNoQuestionFormik
            questionText="Is the car fitted with security devices?"
            id={`${fieldsSubKey}.car_fitted_with_security_devices`}
          />
        </Grid>

        {getIn(values, `${fieldsSubKey}.car_fitted_with_security_devices`) && (
          <Grid item xs={12}>
            <TextFieldFormik
              id={`${fieldsSubKey}.security_devices`}
              label="Security Devices Details"
              className={classes.textField}
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <YesNoQuestionFormik
            questionText="Is the car fitted with any tracking devices?"
            id={`${fieldsSubKey}.car_fitted_with_tracking_devices`}
          />
        </Grid>
        <Grid item xs={12}>
          <YesNoQuestionFormik questionText="Is the vehicle for sale?" id={`${fieldsSubKey}.is_vehicle_for_sale`} />
        </Grid>

        {['theft_vehicle_not_recovered', 'theft_vehicle_recovered'].includes(incidentSubType) && (
          <Grid item xs={12}>
            <YesNoQuestionFormik
              questionText="Was the car in an accident?"
              id={`${fieldsSubKey}.car_was_in_an_accident`}
            />
          </Grid>
        )}
      </>
    );
  }

  return (
    <>
      {commonComponent}
      {incidentSpecificComponents}
    </>
  );
}

MarshmallowFollowupDetails.propTypes = {
  incidentType: PropTypes.string,
  incidentSubType: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MarshmallowFollowupDetails;
