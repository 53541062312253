import PropTypes from 'prop-types';

export const ArrayFieldFormPropTypes = {
  arrayFieldPrefix: PropTypes.string,
  index: PropTypes.number,
};

export const getArrayFieldKey = (fieldKey: string, arrayFieldPrefix: string, index: number): string => {
  if (arrayFieldPrefix) {
    return `${arrayFieldPrefix}.${index}.${fieldKey}`;
  }
  return fieldKey;
};
