import React from 'react';

import { ReportsTabLayout } from '~/components/ReportsPage/Tabs/ReportsTabLayout';

export const MyDraftsTab: React.FC = () => {
  return (
    <ReportsTabLayout
      title="Unpublished Reports and Dashboards"
      subTitle="Here you can view and manage all unpublished reports and dashboards"
      isDrafts={true}
    />
  );
};
