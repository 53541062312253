import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import type { LegalActionApiResponse } from '~/components/LegalActions/types';

import { PERMISSION_ACTIONS, RestrictedPermissions } from '../../core';
import { NewLegalActionDialog } from '../Dialogs/NewLegalAction/NewLegalActionDialog';
import { LegalActionsConfigurationProvider } from '../hooks/useLegalActionsConfiguration';
import { LegalActionScreen } from '../LegalActionScreen/LegalActionScreen';

import { LegalActionsList } from './LegalActionsList';

export const LegalActionsTab: React.FC<{ onNewLegalAction: () => Promise<LegalActionApiResponse[]> }> = ({
  onNewLegalAction,
}) => {
  const match = useRouteMatch<{ claimId: string }>();
  const history = useHistory();

  const [isNewLegalActionDialogOpen, setIsNewLegalActionDialogOpen] = React.useState(false);

  const handleNewLegalAction = React.useCallback(
    async (legalActionId: number) => {
      await onNewLegalAction();
      history.push(`/claims/${match.params.claimId}/legal_actions/${legalActionId}`);
    },
    [history, match.params.claimId, onNewLegalAction]
  );

  return (
    <div className="p-24">
      <RestrictedPermissions action={PERMISSION_ACTIONS.LEGAL_ACTIONS}>
        <LegalActionsConfigurationProvider>
          <Switch>
            <Route
              path={`${match.path}/:legalActionId`}
              render={(routerProps) => {
                const legalActionId = Number(routerProps.match.params.legalActionId);
                return (
                  <LegalActionScreen
                    legalActionId={legalActionId}
                    isNewLegalActionDialogOpen={isNewLegalActionDialogOpen}
                    setIsNewLegalActionDialogOpen={setIsNewLegalActionDialogOpen}
                  />
                );
              }}
            />
            <Route
              path={match.path}
              render={() => <LegalActionsList setIsNewLegalActionDialogOpen={setIsNewLegalActionDialogOpen} />}
            />
          </Switch>
          {isNewLegalActionDialogOpen && (
            <NewLegalActionDialog
              onSubmitSuccess={handleNewLegalAction}
              onClose={() => setIsNewLegalActionDialogOpen(false)}
            />
          )}
        </LegalActionsConfigurationProvider>
      </RestrictedPermissions>
    </div>
  );
};
