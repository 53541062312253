import React from 'react';

import { FsTooltip, Text } from '../../../../core';

import additionalPaymentVerificationDialogStyles from './additionalPaymentVerificationDialog.module.scss';

const FIELDS_WITH_GRID_5 = ['entity_number', 'type', 'name'];
const SIMPLE_STRING_FIELDS = ['source', 'entity_number', 'type', 'source_list_url', 'source_information_url'];
const ARRAY_FIELDS = ['dates_of_birth', 'places_of_birth'];

export const COMPLIANCE_FIELDS = {
  source: 'Source',
  entity_number: 'Entity Number',
  type: 'Type',
  name: 'Name',
  source_list_url: 'Source List URL',
  source_information_url: 'Source Information URL',
  dates_of_birth: 'Dates of Birth',
  places_of_birth: 'Places of Birth',
  addresses: 'Addresses',
};

const getStringComplianceValue = (filedName, complianceResult) => {
  return (
    <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
      {complianceResult[filedName]}
    </Text>
  );
};

const getNameFieldComplianceValue = (filedName, complianceResult) => {
  const alternativeNames = complianceResult.alt_names;
  const arrayLength = alternativeNames.length;

  return (
    <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
      {complianceResult[filedName]}
      {arrayLength > 0 ? (
        <FsTooltip title={alternativeNames.join(', ')} placement="top">
          <span className={additionalPaymentVerificationDialogStyles.complianceFieldTooltipSpan}> +{arrayLength}</span>
        </FsTooltip>
      ) : null}
    </Text>
  );
};

const getArrayComplianceValueAsString = (filedName, complianceResult) => {
  return (
    <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
      {complianceResult[filedName].join(' / ')}
    </Text>
  );
};

const getAddressesFieldComplianceValue = (complianceResult) => {
  const addresses = complianceResult.addresses;
  const addressesLength = addresses.length;

  const convertAddressToString = (address) => {
    const values = Object.values(address).filter((value) => value !== null && value !== undefined);
    return values.join(', ');
  };

  const getTooltipAddressesString = (addresses) => {
    const addressesStrings = addresses.map((address) => convertAddressToString(address));
    return addressesStrings.join(' / ');
  };

  return (
    <>
      {addressesLength > 0 ? (
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {convertAddressToString(addresses[0])}
          {addressesLength > 1 ? (
            <FsTooltip title={getTooltipAddressesString(addresses.slice(1))} placement="top">
              <span className={additionalPaymentVerificationDialogStyles.complianceFieldTooltipSpam}>
                {' '}
                +{addressesLength - 1}
              </span>
            </FsTooltip>
          ) : null}
        </Text>
      ) : null}
    </>
  );
};

export const getOfacCellValue = (fieldName, complianceResult) => {
  if (SIMPLE_STRING_FIELDS.includes(fieldName)) {
    return getStringComplianceValue(fieldName, complianceResult);
  } else if (ARRAY_FIELDS.includes(fieldName)) {
    return getArrayComplianceValueAsString(fieldName, complianceResult);
  } else if (fieldName === 'addresses') {
    return getAddressesFieldComplianceValue(complianceResult);
  } else if (fieldName === 'name') {
    return getNameFieldComplianceValue(fieldName, complianceResult);
  }
  return <></>;
};

export const getGridSizeByOfacFieldName = (fieldName) => {
  return FIELDS_WITH_GRID_5.includes(fieldName) ? 5 : 12;
};
