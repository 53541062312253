import React from 'react';
import PropTypes from 'prop-types';

import { isFeatureEnabled } from '../../../Utils';
import { useCms } from '../../hooks/useCms';
import { useSysconfig } from '../../SystemConfiguration/SystemConfigurationScreen';

const sharedPropTypes = {
  children: PropTypes.node.isRequired,
  featureFlag: PropTypes.string.isRequired,
  organization: PropTypes.object,
};

const FeatureFlagSwitch = ({ children, featureFlag, organization, shouldBeEnabled }) => {
  const { organization: sysConfigOrganization } = useSysconfig();
  const { userOrganization } = useCms();

  const currentOrganization = organization ? organization : sysConfigOrganization ?? userOrganization;
  const featureEnabled = isFeatureEnabled(currentOrganization, featureFlag);

  if ((shouldBeEnabled && featureEnabled) || (!shouldBeEnabled && !featureEnabled)) {
    return <>{children}</>;
  }

  return null;
};

FeatureFlagSwitch.propTypes = {
  children: PropTypes.node.isRequired,
  featureFlag: PropTypes.string.isRequired,
  organization: PropTypes.object,
  shouldBeEnabled: PropTypes.bool.isRequired,
};

export const FeatureEnabled = ({ children, featureFlag, organization }) => (
  <FeatureFlagSwitch featureFlag={featureFlag} organization={organization} shouldBeEnabled={true}>
    {children}
  </FeatureFlagSwitch>
);

FeatureEnabled.propTypes = sharedPropTypes;

export const FeatureDisabled = ({ children, featureFlag, organization }) => (
  <FeatureFlagSwitch featureFlag={featureFlag} organization={organization} shouldBeEnabled={false}>
    {children}
  </FeatureFlagSwitch>
);

FeatureDisabled.propTypes = sharedPropTypes;
