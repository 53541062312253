import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const FlagIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4.5C3 4.08579 3.33579 3.75 3.75 3.75H21C21.2883 3.75 21.5511 3.91526 21.676 4.17511C21.8009 4.43496 21.7658 4.74339 21.5857 4.96852L17.4605 10.125L21.5857 15.2815C21.7658 15.5066 21.8009 15.815 21.676 16.0749C21.5511 16.3347 21.2883 16.5 21 16.5H4.5V20.25C4.5 20.6642 4.16421 21 3.75 21C3.33579 21 3 20.6642 3 20.25V4.5ZM4.5 15H19.4395L15.9143 10.5935C15.6952 10.3196 15.6952 9.93039 15.9143 9.65648L19.4395 5.25H4.5V15Z"
      fill={iconColor}
    />
  </SvgIcon>
);

FlagIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default FlagIcon;
