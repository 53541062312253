import AnomalousPaymentBanner from '~/components/exposures/PaymentRequestContainer/MakePaymentRequestDialog/AnomalousPaymentBanner';

import { COUNTRY_TO_STATE_MAP } from '../../../Types';
import { isQoverClaim } from '../../../Utils';

export const attachedDocumentsMaxHeight = '200px';

export function getPayeeContacts(values, paymentRequestStoreData, claim, options = {}) {
  const { shouldIncludeOnlyChosen = true, ignoreNonPrimaryPayeeCandadiages = false } = options;
  let chosenPayeeContacts = [];

  paymentRequestStoreData.forEach((paymentRequestStoreDataEntry) => {
    if (ignoreNonPrimaryPayeeCandadiages && paymentRequestStoreDataEntry.can_be_primary === false) {
      return;
    }
    switch (paymentRequestStoreDataEntry.type) {
      case 'optional_known_contact':
        if (values[paymentRequestStoreDataEntry.field_name] || !shouldIncludeOnlyChosen) {
          const contact =
            paymentRequestStoreDataEntry.contact ||
            claim.contacts.find((contact) => paymentRequestStoreDataEntry.contact_id === contact.id);
          chosenPayeeContacts.push(contact);
        }
        break;
      case 'contact_search_single': {
        const contactEntry = values[paymentRequestStoreDataEntry.field_name];
        if (contactEntry.contact) {
          chosenPayeeContacts.push(contactEntry.contact);
        } else if (contactEntry.contact_id) {
          const contact =
            contactEntry.contact || claim.contacts.find((contact) => contactEntry.contact_id === contact.id);
          chosenPayeeContacts.push(contact);
        }
        break;
      }
      case 'contact_search': {
        const contactEntriesList = values[paymentRequestStoreDataEntry.field_name] || [];
        contactEntriesList.forEach((contactEntry) => {
          if (contactEntry.contact) {
            chosenPayeeContacts.push(contactEntry.contact);
          } else if (contactEntry.contact_id) {
            const contact =
              contactEntry.contact || claim.contacts.find((contact) => contactEntry.contact_id === contact.id);
            chosenPayeeContacts.push(contact);
          }
        });
        break;
      }
      default:
        throw Error(`Unknown paymentRequestStoreDataEntry type: ${paymentRequestStoreDataEntry.type}`);
    }
  });

  return chosenPayeeContacts;
}

export const PAY_TO_LENGTH_VALIDATION_FIELDS = [
  { fieldKey: 'full_name', description: 'full name', maxCharsKey: 'full_name_max_length' },
  { fieldKey: 'city', description: 'city', maxCharsKey: 'city_max_length' },
  { fieldKey: 'street_address1', description: 'street address 1', maxCharsKey: 'address1_max_length' },
  { fieldKey: 'street_address2', description: 'street address 2', maxCharsKey: 'address2_max_length' },
  {
    fieldKey: 'address_line1',
    description: 'address line 1',
    maxCharsKey: 'address_line1_max_length',
    joinedFieldsList: ['street_address1', 'street_address2'],
  },
  {
    fieldKey: 'address_line2',
    description: 'address line 2',
    maxCharsKey: 'address_line2_max_length',
    joinedFieldsList: ['city', 'county', 'state', 'zipcode'],
  },
  { fieldKey: 'pay_to_line', description: 'pay to line', maxCharsKey: 'pay_to_line_max_length' },
];

export const isStateCountry = (country) => country in COUNTRY_TO_STATE_MAP;

export const getCharsLimitValidationMessage = (maxLength) => `Max ${maxLength} characters`;

function getAdjustReserveDetails({
  isSubReservesConfigEnabled,
  isMultipleSubReservePaymentsEnabled,
  payableWithReserve,
  paymentAmount,
  amountWithoutDeductible,
  subReserveAmounts = null,
  subReserveKeyForAdjustReserveDeductible = null,
}) {
  const deductibleAmount = paymentAmount - amountWithoutDeductible;
  const deductibleConsidered = deductibleAmount > 0;
  if (isSubReservesConfigEnabled) {
    const subReserveKeysAboveReserve = [];
    subReserveAmounts?.forEach(({ type_key, amount }) => {
      if (!type_key || typeof amount !== 'number') {
        return;
      }
      let consideredAmount = amount;
      let subReserveDeductibleAmount = 0;
      if (subReserveKeyForAdjustReserveDeductible === type_key || !isMultipleSubReservePaymentsEnabled) {
        subReserveDeductibleAmount = deductibleAmount;
        consideredAmount += deductibleAmount;
      }
      if (consideredAmount > (payableWithReserve?.sub_reserves?.[type_key] || 0))
        subReserveKeysAboveReserve.push({
          type_key,
          subReserveDeductibleAmount,
          subReservePaymentConsideredAmount: consideredAmount,
        });
    });
    return {
      shouldAdjust: subReserveKeysAboveReserve.length > 0,
      subReserveKeysAboveReserve,
      deductibleConsidered,
    };
  }
  return {
    shouldAdjust: paymentAmount > payableWithReserve.reserve,
    subReserveKeysAboveReserve: [],
    deductibleConsidered,
  };
}

export const getPaymentsAboveReserveIssuesAndSeverities = ({
  isSubReservesConfigEnabled,
  isMultipleSubReservePaymentsEnabled,
  payableWithReserve,
  paymentAmount,
  amountWithoutDeductible,
  subReserveAmounts = null,
  subReserveKeyForAdjustReserveDeductible = null,
  shouldDisablePaymentExceedingReserve,
  subReservesConfigMap,
  currencyFormatter,
}) => {
  const issuesAndSeverities = [];
  const { shouldAdjust, subReserveKeysAboveReserve, deductibleConsidered } = getAdjustReserveDetails({
    isSubReservesConfigEnabled,
    isMultipleSubReservePaymentsEnabled,
    payableWithReserve,
    paymentAmount,
    amountWithoutDeductible,
    subReserveAmounts,
    subReserveKeyForAdjustReserveDeductible,
  });
  if (shouldAdjust) {
    const paymentAboveReserveSeverity = shouldDisablePaymentExceedingReserve
      ? AnomalousPaymentBanner.IssueSeverity.ERROR
      : AnomalousPaymentBanner.IssueSeverity.WARNING;

    if (subReserveKeysAboveReserve.length > 0) {
      subReserveKeysAboveReserve?.forEach(
        ({ type_key, subReserveDeductibleAmount, subReservePaymentConsideredAmount }) => {
          issuesAndSeverities.push({
            issue: `Payment ${subReserveDeductibleAmount > 0 ? 'including deductible ' : ''}(${currencyFormatter.format(
              subReservePaymentConsideredAmount
            )}) for Sub-reserve ${
              subReservesConfigMap.get(type_key).desc
            } is above the reserve ${currencyFormatter.format(payableWithReserve?.sub_reserves?.[type_key] || 0)}.`,
            severity: paymentAboveReserveSeverity,
          });
        }
      );
    } else {
      issuesAndSeverities.push({
        issue: `${
          deductibleConsidered
            ? 'Payment including deductible (' + currencyFormatter.format(paymentAmount) + ')'
            : 'Payment'
        } exceeds reserves (Current reserve: ${currencyFormatter.format(payableWithReserve.reserve)}).`,
        severity: paymentAboveReserveSeverity,
      });
    }
  }
  return issuesAndSeverities;
};

export const getReferenceInitialValue = (claim, payableType, prefillIndemnityReferenceWithClaimNumber) => {
  if ((prefillIndemnityReferenceWithClaimNumber && payableType === 'indemnity') || isQoverClaim(claim)) {
    return claim.claim_id_display;
  }
  return '';
};

export const shouldUseInvoiceForPaymentReferenceInitialValue = (
  payableType,
  prefillExpensesReferenceWithInvoiceNumber
) => {
  return prefillExpensesReferenceWithInvoiceNumber && payableType === 'expenses';
};

export const shouldDisablePayment = ({
  shouldDisablePaymentExceedingReserve,
  issuesAndSeveritiesForPaymentsAboveReserves,
  isSubmitting,
  checkIsExceedsClosedExposurePaymentLimit,
  isLoadingPaymentIssues,
  isPaymentIssuePreventingPayment,
}) => {
  return (
    (shouldDisablePaymentExceedingReserve && issuesAndSeveritiesForPaymentsAboveReserves.length > 0) ||
    isSubmitting ||
    checkIsExceedsClosedExposurePaymentLimit() ||
    isLoadingPaymentIssues ||
    isPaymentIssuePreventingPayment
  );
};

export const getAnomalousPaymentBannerProps = ({
  checkIsExceedsUserAuthority,
  checkIsExceedsClosedExposurePaymentLimit,
  issuesAndSeveritiesForPaymentsAboveReserves,
  policyLimitBreaches,
  isPaymentIssuePreventingPayment,
}) => {
  const issuesAndSeverities = [];
  if (checkIsExceedsUserAuthority()) {
    issuesAndSeverities.push({
      issue: 'Payment exceeds authority level.',
      severity: AnomalousPaymentBanner.IssueSeverity.WARNING,
    });
  }
  if (checkIsExceedsClosedExposurePaymentLimit()) {
    issuesAndSeverities.push({
      issue: 'Payment exceeds limit for the closed exposure payment',
      severity: AnomalousPaymentBanner.IssueSeverity.ERROR,
    });
  }

  issuesAndSeverities.push(...issuesAndSeveritiesForPaymentsAboveReserves);

  if (policyLimitBreaches) {
    policyLimitBreaches.forEach((plb) => {
      issuesAndSeverities.push({
        issue: plb.reason,
        severity: isPaymentIssuePreventingPayment
          ? AnomalousPaymentBanner.IssueSeverity.ERROR
          : AnomalousPaymentBanner.IssueSeverity.WARNING,
      });
    });
  }

  return {
    issuesAndSeverities,
  };
};
