import React from 'react';
import PropTypes from 'prop-types';

import { useClaim } from '../../../components/ClaimContainer';
import { FsTooltip, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../../components/core';
import useCurrencyFormatter from '../../../components/CurrencyFormatterContext';
import HoverActionField from '../../../components/HoverActionField';
import TooltipLock from '../../../components/TooltipLock';
import PencilIcon from '../../icons/PencilIcon';

import DeductibleDialog from './DeductibleDialog';

function DeductibleContainer({ exposure }) {
  const { claim, onClaimUpdate } = useClaim();
  const [showDeductibleDialog, setShowDeductibleDialog] = React.useState();

  if (!exposure.is_coverage_supports_deductible) {
    return <TooltipLock title={"Coverage doesn't support deductible"} />;
  }

  const isDeductiblePending = exposure.is_deductible_pending;
  const isDeductiblePaid = exposure.was_deductible_paid;
  const isDeductibleLocked = isDeductiblePending || isDeductiblePaid;

  return (
    <>
      <RestrictedPermissions action={PERMISSION_ACTIONS.INDEMNITY_PAYMENT} verb={PERMISSION_VERBS.WRITE}>
        <HoverActionField
          onAction={() => setShowDeductibleDialog(true)}
          icon={PencilIcon}
          permanent
          disabled={isDeductibleLocked}
          tooltipTitle={isDeductibleLocked ? 'Deductible applied' : undefined}
          actionAriaLabel="Set deductible"
        >
          <DeductibleDisplay exposure={exposure} />
        </HoverActionField>
      </RestrictedPermissions>
      {showDeductibleDialog && (
        <DeductibleDialog
          exposure={exposure}
          claim={claim}
          onUpdate={onClaimUpdate}
          onClose={() => setShowDeductibleDialog(false)}
        />
      )}
    </>
  );
}

DeductibleContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
};

function DeductibleDisplay({ exposure }) {
  const { currencyFormatter } = useCurrencyFormatter();

  if (!exposure.is_deductible_waived && !exposure.deductible_amount) {
    return <em>Set</em>;
  }

  let deductibleClassName = '';

  const tooltipText = exposure.deductible_reason;
  let displayText;
  if (exposure.is_deductible_waived) {
    displayText = 'Waived';
  } else {
    displayText = currencyFormatter.format(exposure.deductible_amount);
    if (exposure.was_deductible_paid) {
      deductibleClassName = 'text-status-done';
    } else if (exposure.is_deductible_pending) {
      deductibleClassName = 'text-status-pending';
    } else {
      deductibleClassName = 'text-status-failed';
    }
  }

  return (
    <FsTooltip title={tooltipText} disabled={!tooltipText}>
      <span className={deductibleClassName}>{displayText}</span>
    </FsTooltip>
  );
}

DeductibleDisplay.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export default DeductibleContainer;
