import axios from 'axios';
import { concat } from 'lodash';

import { reportAxiosError } from '../../../Utils';
import { useCms } from '../../hooks/useCms';
import useDataFetcher from '../../useDataFetcher';
import { answerStringToBool } from '../Card/utils';

const usePerformanceQA = ({ claimId, exposureId }) => {
  const {
    user: { id: userId },
    userOrganization: { id: organizationId },
  } = useCms();

  const {
    isLoading,
    isError,
    data = {
      form: {
        questions: [],
        custom_questions: [],
      },
      answers: [],
      automatic_answers: [],
    },
  } = useDataFetcher(routes.form({ organizationId, userId }), {
    params: { exposure_id: exposureId, claim_id: claimId, form_if_doesnt_exist: true },
  });

  const handleSubmit = async (values, isDraft) => {
    try {
      await axios.post(
        routes.form({ organizationId, userId }),
        {
          answers: convertAnswers(data.form, values),
          adjuster_reviewed_id: values.adjuster_reviewed_id,
          form_id: data.form.id,
          id: data.id,
        },
        {
          params: {
            exposure_id: exposureId,
            claim_id: claimId,
            is_draft: isDraft,
          },
        }
      );
    } catch (error) {
      await reportAxiosError(error);

      throw error;
    }
  };

  const initialAnswers = convertInitialAnswers(data.answers);

  return {
    isLoading,
    isError,
    reviewedAdjuster: {
      id: data.adjuster_reviewed_id,
      name: data.adjuster_reviewed,
    },
    reviewer: data.qa_reviewer,
    reviewSubmissionDatetime: data.review_submitted_datetime,
    isReviewed: !!data.review_submitted_datetime,
    form: {
      questions: concat(data.form.questions, data.form.custom_questions),
      answers: { ...initialAnswers },
    },
    getAutomaticAnswersDict: () => getAutomaticAnswersDict(data.automatic_answers),
    submit: handleSubmit,
  };
};

const convertInitialAnswers = (answers = []) => {
  const res = {};

  answers.forEach(({ question_id, answer }) => {
    res[question_id] = answer;
  });

  return res;
};

const convertAnswers = ({ custom_questions = {}, questions = {} } = {}, { answers = {} } = {}) => [
  ...getConvertedQuestions({ answers, questions }),
  ...getConvertedQuestions({ answers, questions: custom_questions }),
];

const getConvertedQuestions = ({ answers, questions }) => {
  const res = [];

  questions.forEach(({ id, question_id }) => {
    const answer = answerStringToBool(answers[question_id]);

    if (answer !== undefined) {
      res.push({
        id,
        question_id,
        answer,
      });
    }
  });

  return res;
};

const getAutomaticAnswersDict = (answers = []) => {
  const res = {};

  answers.forEach(({ question_id, ...answerProps }) => {
    res[question_id] = {
      ...answerProps,
    };
  });

  return res;
};

const routes = {
  form: ({ organizationId, userId }) => `/api/v1/qa_queries/${organizationId}/${userId}/performance_qa_form/form`,
};

export default usePerformanceQA;
