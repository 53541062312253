import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const AdvancedSearchIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6C3 5.58579 3.33579 5.25 3.75 5.25H20.25C20.6642 5.25 21 5.58579 21 6C21 6.41421 20.6642 6.75 20.25 6.75H3.75C3.33579 6.75 3 6.41421 3 6Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 11.5858 3.33579 11.25 3.75 11.25H10.5C10.9142 11.25 11.25 11.5858 11.25 12C11.25 12.4142 10.9142 12.75 10.5 12.75H3.75C3.33579 12.75 3 12.4142 3 12Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 18C3 17.5858 3.33579 17.25 3.75 17.25H12C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75H3.75C3.33579 18.75 3 18.4142 3 18Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 11.25C16.0074 11.25 15 12.2574 15 13.5C15 14.7426 16.0074 15.75 17.25 15.75C18.4926 15.75 19.5 14.7426 19.5 13.5C19.5 12.2574 18.4926 11.25 17.25 11.25ZM13.5 13.5C13.5 11.4289 15.1789 9.75 17.25 9.75C19.3211 9.75 21 11.4289 21 13.5C21 15.5711 19.3211 17.25 17.25 17.25C15.1789 17.25 13.5 15.5711 13.5 13.5Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8412 15.0912C19.1341 14.7983 19.609 14.7983 19.9019 15.0912L22.2803 17.4697C22.5732 17.7626 22.5732 18.2374 22.2803 18.5303C21.9874 18.8232 21.5126 18.8232 21.2197 18.5303L18.8412 16.1519C18.5483 15.859 18.5483 15.3841 18.8412 15.0912Z"
      fill={iconColor}
    />
  </SvgIcon>
);

AdvancedSearchIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default AdvancedSearchIcon;
