const getColumnPadding = (column) => {
  if (column.disablePadding) {
    return { padding: 0 };
  }

  if (column.leftPaddingOnly) {
    return { paddingRight: 0 };
  }

  if (column.rightPaddingOnly) {
    return { paddingLeft: 0 };
  }

  return {};
};

const PAGINATION_LOCATION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const expandActionColumn = {
  id: 'expandedActionColumn',
  label: '',
  disableSort: true,
};

export { expandActionColumn, getColumnPadding, PAGINATION_LOCATION };
