import React from 'react';

const LegalActionsEmptyStateIllustration = () => {
  return (
    <svg width="240" height="198" viewBox="0 0 240 198" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M238.404 134.832C231.876 101.969 193.633 75.7041 186.174 41.4585C172.617 -2.11119 127.932 -8.99964 98.8951 9.92265C52.886 39.9053 101.968 47.3457 11.7593 118.283C-10.5153 135.798 -3.41759 213.117 66.657 195.373C104.368 185.824 115.832 184.484 132.795 182.911C175.55 178.948 249.362 189.997 238.404 134.832Z"
        fill="#EFFAFC"
      />
      <g filter="url(#filter0_d_589_50241)">
        <path
          d="M191.13 40H47.8701C44.352 40 41.5 42.861 41.5 46.3902V151.61C41.5 155.139 44.352 158 47.8701 158H191.13C194.648 158 197.5 155.139 197.5 151.61V46.3902C197.5 42.861 194.648 40 191.13 40Z"
          fill="white"
        />
        <path
          d="M197.5 46.4058V52.8515H41.5V46.4058C41.5 44.7069 42.1711 43.0775 43.3658 41.8762C44.5604 40.6749 46.1806 40 47.8701 40H191.13C192.819 40 194.44 40.6749 195.634 41.8762C196.829 43.0775 197.5 44.7069 197.5 46.4058Z"
          fill="#EDEDED"
        />
      </g>
      <path
        d="M85.7832 121.656C85.7832 120.552 86.6786 119.656 87.7832 119.656H109.818C110.922 119.656 111.818 120.552 111.818 121.656V125.567H85.7832V121.656Z"
        fill="#305A7F"
      />
      <path
        d="M77.5 129.932C77.5 128.827 78.3954 127.932 79.5 127.932H118.102C119.206 127.932 120.102 128.827 120.102 129.932V135.025H77.5V129.932Z"
        fill="#305A7F"
      />
      <path
        d="M118.208 76.5437L107.897 86.8439C107.575 87.1658 107.395 87.6023 107.395 88.0574C107.395 88.5125 107.575 88.9489 107.897 89.2709L119.926 101.288C120.249 101.609 120.686 101.79 121.141 101.79C121.597 101.79 122.034 101.609 122.356 101.288L125.082 98.5732L153.917 130.331C154.567 130.947 155.432 131.286 156.328 131.275C157.224 131.263 158.08 130.902 158.714 130.269C159.348 129.636 159.709 128.781 159.721 127.885C159.732 126.99 159.393 126.126 158.776 125.477L129.949 93.7107L132.675 90.9876C132.997 90.6657 133.178 90.2292 133.178 89.7741C133.178 89.319 132.997 88.8825 132.675 88.5606L120.646 76.5437C120.486 76.3831 120.296 76.2557 120.087 76.1687C119.878 76.0817 119.654 76.037 119.427 76.037C119.2 76.037 118.976 76.0817 118.767 76.1687C118.558 76.2557 118.368 76.3831 118.208 76.5437Z"
        fill="#C2D5E7"
      />
      <rect
        x="97.5"
        y="92.9749"
        width="7.03551"
        height="26.1319"
        rx="3.51775"
        transform="rotate(-45 97.5 92.9749)"
        fill="#32C7CA"
      />
      <rect
        x="119.5"
        y="70.9749"
        width="7.03551"
        height="26.1319"
        rx="3.51775"
        transform="rotate(-45 119.5 70.9749)"
        fill="#32C7CA"
      />
      <defs>
        <filter
          id="filter0_d_589_50241"
          x="31.5"
          y="34"
          width="176"
          height="138"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_589_50241" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_589_50241" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default LegalActionsEmptyStateIllustration;
