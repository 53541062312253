const BIKE_LOCATION_TYPE_DICT = {
  outside: {
    desc: 'Outside',
  },
  in_my_home: {
    desc: 'In My House',
  },
  in_private_locked_room: {
    desc: 'In Private Locked Room',
  },
  in_common_room: {
    desc: 'In Common Room',
  },
  with_insured: {
    desc: 'With Insured',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default BIKE_LOCATION_TYPE_DICT;
