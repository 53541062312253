import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { DisputeResolutionProfessionalForm } from '../../FormFragments/DisputeResolutionProfessionalForm';
import { DISPUTE_RESOLUTION_PROFESSIONAL_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { DisputeResolutionProfessionalFormValues } from '../../FormFragments/FormValuesTypes';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const AddDisputeResolutionProfessionalDialog: React.FC<DialogPropsWithLegalAction> = ({
  onClose,
  onSubmitSuccess,
  legalAction,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: DisputeResolutionProfessionalFormValues) => {
    try {
      await axios.post(backendRoutes.legalAction.dispute_resolution_professionals, values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<DisputeResolutionProfessionalFormValues>
      title="Add Dispute Resolution Professional"
      onClose={onClose}
      maxWidth="sm"
      formikConfig={{
        onSubmit: handleSubmit,
        validationSchema: Yup.object().shape(DISPUTE_RESOLUTION_PROFESSIONAL_VALIDATION_SCHEMA),
        initialValues: {
          type: '',
          contact_id: null,
          start_date: undefined,
          end_date: undefined,
        },
      }}
    >
      <div className="flex w-full flex-col gap-20">
        <DisputeResolutionProfessionalForm />
      </div>
    </FormDialog>
  );
};
