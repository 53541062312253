import React from 'react';
import PropTypes from 'prop-types';

import { PreDefinedField } from '~/components/IncidentConfiguration/ConfiguredFields';

const InvolvedInsurerFields = ({ showOnly, preDefinedFields }) => {
  return (
    <>
      <PreDefinedField id="insurer_contact_id" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="insurer_reference_number" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="insurer_policy_number" fields={preDefinedFields} inline readOnly={showOnly} />
    </>
  );
};

InvolvedInsurerFields.propTypes = {
  showOnly: PropTypes.bool,
  preDefinedFields: PropTypes.object.isRequired,
};

export default InvolvedInsurerFields;
