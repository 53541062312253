import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const EyeIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" disableStrokeColor iconColor={iconColor} {...props}>
    <path
      d="M23.1897 11.7C23.0597 11.41 19.8997 4.5 11.9997 4.5C4.09969 4.5 0.949688 11.4 0.809688 11.7C0.719687 11.89 0.719687 12.12 0.809688 12.31C0.939687 12.6 4.09969 19.51 11.9997 19.51C19.8997 19.51 23.0497 12.61 23.1897 12.31C23.2797 12.12 23.2797 11.89 23.1897 11.7ZM11.9997 18.01C5.89969 18.01 3.00969 13.29 2.33969 12.01C3.00969 10.75 5.95969 6.01 11.9997 6.01C18.0397 6.01 20.9897 10.73 21.6597 12.01C20.9897 13.29 18.0997 18.01 11.9997 18.01Z"
      fill={iconColor}
    />
    <path
      d="M12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5ZM12 15C10.35 15 9 13.65 9 12C9 10.35 10.35 9 12 9C13.65 9 15 10.35 15 12C15 13.65 13.65 15 12 15Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default EyeIcon;
