import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const LossReportIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.18934 2.68934C4.47064 2.40804 4.85217 2.25 5.25 2.25H14.25C14.4489 2.25 14.6397 2.32902 14.7803 2.46967L20.0303 7.71967C20.171 7.86032 20.25 8.05109 20.25 8.25V20.25C20.25 20.6478 20.092 21.0294 19.8107 21.3107C19.5294 21.592 19.1478 21.75 18.75 21.75H5.25C4.85218 21.75 4.47065 21.592 4.18934 21.3107C3.90804 21.0294 3.75 20.6478 3.75 20.25V3.75C3.75 3.35217 3.90804 2.97064 4.18934 2.68934ZM13.9393 3.75L5.25 3.75L5.25 20.25H18.75V8.56066L13.9393 3.75Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.92211 9.93621C7.20618 9.63475 7.68085 9.62065 7.98231 9.90472L10.8965 12.6508L12.0965 11.5201C12.3853 11.2479 12.8363 11.2479 13.1252 11.5201L16.9823 15.1547C17.2838 15.4388 17.2979 15.9135 17.0138 16.2149C16.7297 16.5164 16.2551 16.5305 15.9536 16.2464L12.6108 13.0965L11.4109 14.2272C11.122 14.4994 10.6711 14.4994 10.3822 14.2272L6.95361 10.9964C6.65215 10.7123 6.63805 10.2377 6.92211 9.93621Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.468 12.7006C16.8822 12.7006 17.218 13.0363 17.218 13.4506V15.7006C17.218 16.1148 16.8822 16.4506 16.468 16.4506H14.218C13.8037 16.4506 13.468 16.1148 13.468 15.7006C13.468 15.2863 13.8037 14.9506 14.218 14.9506H15.718V13.4506C15.718 13.0363 16.0537 12.7006 16.468 12.7006Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.25 2.25C14.6642 2.25 15 2.58579 15 3V7.5H19.5C19.9142 7.5 20.25 7.83579 20.25 8.25C20.25 8.66421 19.9142 9 19.5 9H14.25C13.8358 9 13.5 8.66421 13.5 8.25V3C13.5 2.58579 13.8358 2.25 14.25 2.25Z"
      fill={iconColor}
    />
  </SvgIcon>
);

LossReportIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default LossReportIcon;
