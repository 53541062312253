import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';

import MenuItem from '~/components/core/Atomic/MenuItem';
import GeneralRecoveryDialog from '~/components/exposures/GeneralRecoveryDialog';
import { useLegalActions } from '~/components/LegalActions/hooks/useLegalActions';

import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isExposureFinancialOnly, isFeatureEnabled } from '../../Utils';
import { useClaim } from '../ClaimContainer';
import { FsMenuItem } from '../core';
import DisableableToolTip from '../DisableableTooltip';
import { ExposureGenericTransactionsSummaryContainer } from '../GenericTransactionSummary';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import { ThreeDotsIcon } from '../icons';

import CloseExposureDialog from './CloseExposureDialog';
import LitigationReferralAttorneySelectDialog, {
  SendLitigationReferralEmailDialog,
} from './ExposureLitigationReferral';
import ExposureShowMoreDialog from './ExposureShowMoreDialog';
import { useExposureClosingDisabledReason } from './ExposureUtils';
import { ExposureLitigationStatusDialog } from './LitigationStatusDialog';
import RecoveryMenuItemContainer, { RecoveryDialogContainer } from './Recovery';
import ReopenExposureDialog from './ReopenExposureDialog';

function ExposuresMoreActionsContainer(props) {
  const { exposure } = props;
  const { claim, onClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const [anchorEl, setAnchorEl] = useState(null);

  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const [showPostRecoveryDialog, setShowPostRecoveryDialog] = useState(false);
  const [showExposureShowMore, setExposureShowShowMore] = useState(false);
  const [showCloseExposureDialog, setShowCloseExposureDialog] = useState(false);
  const [showReopenExposureDialog, setShowReopenExposureDialog] = useState(false);
  const [openLitigationStatusDialog, setOpenLitigationStatusDialog] = useState(false);
  const [openSelectAttorneyDialog, setOpenSelectAttorneyDialog] = useState(false);
  const [chosenAttorney, setChosenAttorney] = useState(undefined);
  const [openDefenseLitigationReferralDialog, setOpenDefenseLitigationReferralDialog] = useState(false);

  const { isLegalActionsFeatureEnabled } = useLegalActions();

  const isClosingClaimWithOpenExposures = isExposureFinancialOnly(userOrganization);
  const isDefenseAttorneyRequired = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DEFENSE_ATTORNEY);

  const getEmptyEmailPreviewState = () => {
    return { recoveryDialogOpen: false, recoveryType: null, recoveryDialogType: null };
  };
  const [recoveryDialogState, setRecoveryDialogState] = useState(getEmptyEmailPreviewState());
  const { recoveryDialogOpen, recoveryType, recoveryDialogType } = recoveryDialogState;

  const readOnly = user.role.is_view_only;

  const closingExposureDisableReason = useExposureClosingDisabledReason([exposure]);

  return (
    <>
      <HoverActionField
        icon={ThreeDotsIcon}
        onAction={(event) => setAnchorEl(event.currentTarget)}
        permanent
        ignorePermissions
        actionAriaLabel="More actions"
      />
      <Menu
        PaperProps={{
          style: {
            width: 200,
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {!exposure.isGeneralExpenses && (
          <span>
            <RecoveryMenuItemContainer
              exposure={exposure}
              recoveryType="subro"
              onClose={() => setAnchorEl(null)}
              onItemClick={(dialogType) => {
                setRecoveryDialogState({
                  recoveryDialogOpen: true,
                  recoveryType: 'subro',
                  recoveryDialogType: dialogType,
                });
                setAnchorEl(null);
              }}
              disabled={readOnly}
            />
          </span>
        )}
        {!exposure.isGeneralExpenses && (
          <span>
            <RecoveryMenuItemContainer
              exposure={exposure}
              recoveryType="salvage"
              onClose={() => setAnchorEl(null)}
              onItemClick={(dialogType) => {
                setRecoveryDialogState({
                  recoveryDialogOpen: true,
                  recoveryType: 'salvage',
                  recoveryDialogType: dialogType,
                });
                setAnchorEl(null);
              }}
              disabled={readOnly}
            />
          </span>
        )}
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.GENERAL_RECOVERIES) && (
          <MenuItem
            onClick={() => {
              setShowPostRecoveryDialog(true);
              setAnchorEl(null);
            }}
          >
            Post recovery
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setShowTransactionHistory(true);
            setAnchorEl(null);
          }}
        >
          Transaction history
        </MenuItem>

        {!exposure.isGeneralExpenses &&
          !isClosingClaimWithOpenExposures &&
          (exposure.is_closed ? (
            <FsMenuItem
              onClick={() => {
                setShowReopenExposureDialog(true);
                setAnchorEl(null);
              }}
              disabled={readOnly}
            >
              Reopen exposure
            </FsMenuItem>
          ) : (
            <DisableableToolTip disabled={!!closingExposureDisableReason} title={closingExposureDisableReason}>
              <FsMenuItem
                disabled={!!closingExposureDisableReason || readOnly}
                onClick={() => {
                  setShowCloseExposureDialog(true);
                  setAnchorEl(null);
                }}
              >
                Close exposure
              </FsMenuItem>
            </DisableableToolTip>
          ))}

        {exposure.can_be_marked_as_in_litigation && !isLegalActionsFeatureEnabled && (
          <FsMenuItem
            disabled={readOnly || exposure.is_close}
            onClick={() => {
              setOpenLitigationStatusDialog(true);
              setAnchorEl(null);
            }}
          >
            {exposure.is_in_litigation ? 'Update litigation status' : 'Mark as in litigation'}
          </FsMenuItem>
        )}

        {!exposure.isGeneralExpenses &&
          !isLegalActionsFeatureEnabled &&
          (isDefenseAttorneyRequired
            ? exposure.can_be_marked_as_in_litigation && (
                <FsMenuItem
                  disabled={readOnly || exposure.is_close || !exposure.is_in_litigation}
                  tooltipText={!exposure.is_in_litigation ? '' : 'First mark as litigation'}
                  onClick={() => {
                    setOpenDefenseLitigationReferralDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  Litigation referral
                </FsMenuItem>
              )
            : claim.type === 'auto_claim' && (
                <FsMenuItem
                  disabled={readOnly}
                  onClick={() => {
                    setOpenSelectAttorneyDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  Litigation referral
                </FsMenuItem>
              ))}

        {!exposure.isGeneralExpenses && (
          <MenuItem
            onClick={() => {
              setExposureShowShowMore(true);
              setAnchorEl(null);
            }}
          >
            Show more
          </MenuItem>
        )}
      </Menu>

      {showTransactionHistory && (
        <ExposureGenericTransactionsSummaryContainer
          exposure={exposure}
          claim={claim}
          onUpdate={onClaimUpdate}
          isDialog
          onClose={() => setShowTransactionHistory(false)}
        />
      )}
      {showPostRecoveryDialog && (
        <GeneralRecoveryDialog
          claimId={claim.id}
          exposureIdOrNullIfGeneralExpenses={exposure.isGeneralExpenses ? null : exposure.id}
          onClose={() => setShowPostRecoveryDialog(false)}
          onUpdate={onClaimUpdate}
        />
      )}

      {showCloseExposureDialog && (
        <CloseExposureDialog
          exposure={exposure}
          claim={claim}
          onUpdate={onClaimUpdate}
          onClose={() => setShowCloseExposureDialog(false)}
        />
      )}

      {showReopenExposureDialog && (
        <ReopenExposureDialog
          exposure={exposure}
          claim={claim}
          onUpdate={onClaimUpdate}
          onClose={() => setShowReopenExposureDialog(false)}
        />
      )}

      {recoveryDialogOpen && (
        <RecoveryDialogContainer
          exposure={exposure}
          onClose={() => setRecoveryDialogState(getEmptyEmailPreviewState())}
          recoveryType={recoveryType}
          dialogType={recoveryDialogType}
        />
      )}

      {openLitigationStatusDialog && (
        <ExposureLitigationStatusDialog
          exposure={exposure}
          onUpdate={onClaimUpdate}
          onClose={() => setOpenLitigationStatusDialog(false)}
        />
      )}

      {openSelectAttorneyDialog && (
        <LitigationReferralAttorneySelectDialog
          onCancel={() => setOpenSelectAttorneyDialog(false)}
          onSelectAttorney={(attorneyContact) => {
            setChosenAttorney(attorneyContact);
            setOpenSelectAttorneyDialog(false);
          }}
        />
      )}

      {chosenAttorney && (
        <SendLitigationReferralEmailDialog
          exposure={exposure}
          contact={chosenAttorney}
          onClose={() => setChosenAttorney(undefined)}
        />
      )}

      {openDefenseLitigationReferralDialog && (
        <SendLitigationReferralEmailDialog
          onClose={() => setOpenDefenseLitigationReferralDialog(false)}
          contact={exposure.defense_attorney_contact}
          exposure={exposure}
        />
      )}

      {showExposureShowMore && (
        <ExposureShowMoreDialog exposure={exposure} onClose={() => setExposureShowShowMore(false)} />
      )}
    </>
  );
}

ExposuresMoreActionsContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export default ExposuresMoreActionsContainer;
