import * as Yup from 'yup';

function addMethodsToYup() {
  // can be used to check uniqueness of a nested object in an array parent. use example:
  // Yup.array().of(Yup.object().shape({
  //   id: Yup.string(),
  // }).uniqueInParent('id', 'Id must be unique'))
  Yup.addMethod(Yup.object, 'uniqueInParent', function (fieldName, message) {
    return this.test('uniqueInParent', message, function (value) {
      if (!value || !value[fieldName]) {
        return true;
      }

      const matchingValuesInParent = this.parent.filter((v) => v[fieldName] === value[fieldName]);

      if (matchingValuesInParent.length > 1) {
        return false;
      }

      return true;
    });
  });
}

export default addMethodsToYup;
