import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const InternalCommunicationIcon = ({ ...props }) => (
  <SvgIcon viewBox="0 0 18 19" stroke="none" {...props}>
    <path d="M15.1178 2.53493C15.3077 2.46128 15.5141 2.44061 15.7149 2.47511C15.9157 2.50961 16.1034 2.59799 16.2579 2.73083C16.4123 2.86365 16.5279 3.03594 16.5921 3.2293C16.6563 3.42266 16.6668 3.62982 16.6224 3.82868L16.6217 3.83183L13.978 15.3771C13.9343 15.5673 13.8419 15.7432 13.7099 15.8869C13.5778 16.0306 13.4107 16.1374 13.2249 16.197C13.0391 16.2566 12.841 16.2668 12.65 16.2267C12.4594 16.1866 12.2825 16.0978 12.1366 15.9688C12.1363 15.9686 12.136 15.9683 12.1357 15.9681L5.92904 10.507L2.32912 9.78433L2.3268 9.78386C1.24551 9.56206 1.07935 8.04748 2.13615 7.63333L15.1155 2.53583L15.1178 2.53493ZM2.55017 8.67938C2.55026 8.68005 2.55038 8.68069 2.55051 8.68128C2.55105 8.68141 2.55163 8.68155 2.55225 8.68168C2.55204 8.68163 2.55245 8.68172 2.55225 8.68168L6.2982 9.43368C6.39498 9.45311 6.48495 9.49766 6.55906 9.56287L12.8802 15.1246L12.8814 15.1257L15.5244 3.58386L2.55017 8.67938ZM2.54462 8.67943C2.54447 8.67936 2.54461 8.67943 2.54462 8.67943V8.67943Z" />
    <path d="M16.213 2.74411C16.3949 2.99595 16.3382 3.34756 16.0864 3.52946L6.51684 10.4412C6.265 10.6231 5.91338 10.5664 5.73149 10.3145C5.54959 10.0627 5.60629 9.71107 5.85814 9.52918L15.4277 2.61746C15.6795 2.43556 16.0311 2.49227 16.213 2.74411Z" />
    <path d="M6.18749 9.42268C6.49815 9.42268 6.74999 9.67452 6.74999 9.98518L6.74999 14.5616L6.75301 14.5585L8.94677 12.3648C9.16644 12.1451 9.52259 12.1451 9.74226 12.3648C9.96194 12.5844 9.96194 12.9406 9.74227 13.1603L7.54978 15.3528C7.39351 15.5107 7.19395 15.6189 6.97626 15.6636C6.75774 15.7085 6.53081 15.6873 6.32432 15.6029C6.11783 15.5185 5.94109 15.3746 5.81657 15.1896C5.69204 15.0045 5.62536 14.7865 5.62499 14.5635L5.62499 9.98518C5.62499 9.67452 5.87683 9.42268 6.18749 9.42268Z" />
  </SvgIcon>
);

InternalCommunicationIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default InternalCommunicationIcon;
