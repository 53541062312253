import React from 'react';
import PropTypes from 'prop-types';

const EmptyReportsIllustration = ({ props }) => (
  <svg width="239" height="198" viewBox="0 0 239 198" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M237.904 134.832C231.376 101.969 193.133 75.7041 185.674 41.4585C172.117 -2.11119 127.432 -8.99964 98.3951 9.92265C52.386 39.9053 101.468 47.3457 11.2593 118.283C-11.0153 135.798 -3.91759 213.117 66.157 195.373C103.868 185.824 115.332 184.484 132.295 182.911C175.05 178.948 248.862 189.997 237.904 134.832Z"
      fill="#EFFAFC"
    />
    <g filter="url(#filter0_d_197_619)">
      <path
        d="M191.63 40H48.3701C44.852 40 42 42.861 42 46.3902V151.61C42 155.139 44.852 158 48.3701 158H191.63C195.148 158 198 155.139 198 151.61V46.3902C198 42.861 195.148 40 191.63 40Z"
        fill="white"
      />
      <path
        d="M198 46.4058V52.8515H42V46.4058C42 44.7069 42.6711 43.0775 43.8658 41.8762C45.0604 40.6749 46.6806 40 48.3701 40H191.63C193.319 40 194.94 40.6749 196.134 41.8762C197.329 43.0775 198 44.7069 198 46.4058Z"
        fill="#EDEDED"
      />
    </g>
    <circle cx="84.5" cy="89.5" r="22.5" fill="#305A7F" />
    <rect x="129" y="74" width="50" height="6" rx="3" fill="#305A7F" />
    <rect x="129" y="86" width="50" height="6" rx="3" fill="#305A7F" />
    <rect x="129" y="98" width="50" height="6" rx="3" fill="#305A7F" />
    <rect x="62" y="132" width="117" height="6" rx="3" fill="#305A7F" />
    <rect x="62" y="131" width="61" height="8" rx="4" fill="#32C7CA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84 87.9998H111C110.688 74.2345 98.982 63.168 84.5872 63.168C84.3909 63.168 84.1952 63.17 84 63.1741V87.9998Z"
      fill="#FF6B99"
    />
    <defs>
      <filter
        id="filter0_d_197_619"
        x="32"
        y="34"
        width="176"
        height="138"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_197_619" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_197_619" result="shape" />
      </filter>
    </defs>
  </svg>
);

EmptyReportsIllustration.propTypes = {
  props: PropTypes.object,
};

export default EmptyReportsIllustration;
