import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const InvolvedPetIcon = ({ ...props }) => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    stroke="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3404 6.25C16.1304 6.25 15.1504 7.23 15.1504 8.44C15.1504 9.65 16.1304 10.63 17.3404 10.63C18.5504 10.63 19.5304 9.65 19.5304 8.44C19.5304 7.23 18.5504 6.25 17.3404 6.25ZM17.3404 9.37C16.8204 9.37 16.4004 8.95 16.4004 8.43C16.4004 7.91 16.8204 7.49 17.3404 7.49C17.8604 7.49 18.2804 7.91 18.2804 8.43C18.2804 8.95 17.8604 9.37 17.3404 9.37Z"
      fill="black"
    />
    <path
      d="M6.4093 8.44C6.4093 7.23 5.4293 6.25 4.2193 6.25C3.0093 6.25 2.0293 7.23 2.0293 8.44C2.0293 9.65 3.0093 10.63 4.2193 10.63C5.4293 10.63 6.4093 9.65 6.4093 8.44ZM4.2193 9.38C3.6993 9.38 3.2793 8.96 3.2793 8.44C3.2793 7.92 3.6993 7.5 4.2193 7.5C4.7393 7.5 5.1593 7.92 5.1593 8.44C5.1593 8.96 4.7393 9.38 4.2193 9.38Z"
      fill="black"
    />
    <path
      d="M7.9693 6.87219C9.1793 6.87219 10.1593 5.89219 10.1593 4.68219C10.1593 3.47219 9.1793 2.49219 7.9693 2.49219C6.7593 2.49219 5.7793 3.47219 5.7793 4.68219C5.7793 5.89219 6.7593 6.87219 7.9693 6.87219V6.87219ZM7.9693 3.75219C8.4893 3.75219 8.9093 4.17219 8.9093 4.69219C8.9093 5.21219 8.4893 5.63219 7.9693 5.63219C7.4493 5.63219 7.0293 5.21219 7.0293 4.69219C7.0293 4.17219 7.4493 3.75219 7.9693 3.75219V3.75219Z"
      fill="black"
    />
    <path
      d="M13.5904 6.87219C14.8004 6.87219 15.7804 5.89219 15.7804 4.68219C15.7804 3.47219 14.8004 2.49219 13.5904 2.49219C12.3804 2.49219 11.4004 3.47219 11.4004 4.68219C11.4004 5.89219 12.3804 6.87219 13.5904 6.87219V6.87219ZM13.5904 3.75219C14.1104 3.75219 14.5304 4.17219 14.5304 4.69219C14.5304 5.21219 14.1104 5.63219 13.5904 5.63219C13.0704 5.63219 12.6504 5.21219 12.6504 4.69219C12.6504 4.17219 13.0704 3.75219 13.5904 3.75219V3.75219Z"
      fill="black"
    />
    <path
      d="M15.4001 11.6275C14.7601 11.2675 14.2901 10.6775 14.0901 9.9675C13.8901 9.2575 13.4501 8.6175 12.8501 8.1675C11.6701 7.2775 9.90014 7.2775 8.71014 8.1675C8.12014 8.6175 7.68014 9.2475 7.47014 9.9675C7.27014 10.6775 6.80014 11.2675 6.16014 11.6175C5.55014 11.9475 5.07014 12.4775 4.79014 13.1075C4.51014 13.7375 4.45014 14.4575 4.62014 15.1275C4.79014 15.7975 5.18014 16.3975 5.73014 16.8275C6.27014 17.2475 6.95014 17.4875 7.64014 17.4875C8.06014 17.4875 8.47014 17.4075 8.86014 17.2475C10.0801 16.7475 11.4601 16.7475 12.6801 17.2475C13.0701 17.4075 13.4801 17.4875 13.9001 17.4875C14.5901 17.4875 15.2701 17.2475 15.8201 16.8275C16.3601 16.3975 16.7601 15.7975 16.9201 15.1275C17.0901 14.4575 17.0301 13.7375 16.7501 13.1075C16.4701 12.4775 15.9901 11.9475 15.3801 11.6175L15.4001 11.6275ZM15.7201 14.8275C15.6201 15.2275 15.3801 15.5875 15.0601 15.8475C14.5301 16.2575 13.7901 16.3475 13.1801 16.0975C12.4201 15.7775 11.6201 15.6175 10.7901 15.6175C9.96014 15.6175 9.16014 15.7775 8.40014 16.0975C7.79014 16.3575 7.05014 16.2575 6.52014 15.8475C6.19014 15.5975 5.96014 15.2275 5.86014 14.8275C5.76014 14.4275 5.79014 13.9975 5.96014 13.6175C6.13014 13.2375 6.42014 12.9175 6.79014 12.7175C7.72014 12.1975 8.40014 11.3375 8.69014 10.3175C8.82014 9.8675 9.10014 9.4575 9.48014 9.1775C10.2301 8.6075 11.3601 8.6075 12.1101 9.1775C12.4901 9.4575 12.7701 9.8675 12.9001 10.3175C13.1901 11.3475 13.8701 12.1975 14.8001 12.7175C15.1601 12.9175 15.4601 13.2375 15.6201 13.6175C15.7901 13.9975 15.8201 14.4275 15.7201 14.8275V14.8275Z"
      fill="black"
    />
  </SvgIcon>
);

InvolvedPetIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default InvolvedPetIcon;
