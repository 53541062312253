import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const LobContext = createContext({});

const LobContextProvider = ({ lob, claimType, children }) => {
  return <LobContext.Provider value={{ lob, claimType }}>{children}</LobContext.Provider>;
};

LobContextProvider.propTypes = {
  lob: PropTypes.string.isRequired,
  claimType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const useLob = () => {
  const lobContext = useContext(LobContext);
  return { lob: lobContext.lob, claimType: lobContext.claimType };
};

export { LobContextProvider, useLob };
