import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { DateOfServiceForm } from '../../FormFragments/DateOfServiceForm';
import { DATE_OF_SERVICE_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { DateOfServiceFormValues } from '../../FormFragments/FormValuesTypes';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';
import { dateOfServiceInitialValues } from '../InitialValues';

export const AddDateOfServiceDialog: React.FC<DialogPropsWithLegalAction> = ({
  onClose,
  onSubmitSuccess,
  legalAction,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: DateOfServiceFormValues) => {
    try {
      await axios.post(backendRoutes.legalAction.dates_of_service, values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<DateOfServiceFormValues>
      title="Add Date of Service"
      onClose={onClose}
      maxWidth="sm"
      formikConfig={{
        onSubmit: handleSubmit,
        validationSchema: Yup.object().shape(DATE_OF_SERVICE_VALIDATION_SCHEMA),
        initialValues: dateOfServiceInitialValues,
      }}
    >
      <div className="flex w-full flex-col gap-20">
        <DateOfServiceForm />
      </div>
    </FormDialog>
  );
};
