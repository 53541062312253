import React from 'react';
import { Link, Popover } from '@material-ui/core';

import mixpanel from '~/components/CmsMain/mixpanel';
import { Text } from '~/components/core';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { useCms } from '~/components/hooks/useCms';
import { QuestionCircleIcon } from '~/components/icons';
import FileIcon from '~/components/icons/FileIcon';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { isHospitalityUser, isMgmUser, isRwUser } from '~/Utils';

interface Props {
  onClose: () => void;
  anchorEl: Element;
}

const SupportMenu: React.FC<Props> = ({ onClose, anchorEl }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useCms();

  const linkClassName = 'flex items-center p-16 text-slate-800 gap-4';
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className="flex flex-col gap-12 rounded-md p-4">
        {!isMgmUser(user) && !isRwUser(user) && !isHospitalityUser(user) && (
          <MenuItem className={linkClassName}>
            <FileIcon size={20} iconColor="currentColor" />
            <Link
              href="https://docs.fivesigmalabs.com/90QdzjlkkzeSKsiP9PE0/"
              underline="none"
              color="inherit"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                mixpanel.track(MIXPANEL_EVENTS.DOCUMENTATION_LINK_CLICKED);
              }}
            >
              <Text
                variant={Text.VARIANTS.SM}
                weight={Text.WEIGHTS.REGULAR}
                colorVariant={Text.COLOR_VARIANTS.SECONDARY}
              >
                Documentation
              </Text>
            </Link>
          </MenuItem>
        )}
        <MenuItem className={linkClassName}>
          <QuestionCircleIcon size={20} iconColor="currentColor" />
          <Link
            href="https://fivesigmahelp.zendesk.com/hc/en-us/requests/new"
            underline="none"
            color="inherit"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              mixpanel.track(MIXPANEL_EVENTS.SUPPORT_LINK_CLICKED);
            }}
          >
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
              Support
            </Text>
          </Link>
        </MenuItem>
      </div>
    </Popover>
  );
};

export default SupportMenu;
