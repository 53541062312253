import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import useIsConfigurationFieldSupportedBySubtype from '~/components/hooks/useIsConfigurationFieldSupportedBySubtype';
import { useLob } from '~/components/hooks/useLob';

import { useClaim } from '../../../ClaimContainer';
import { FsMenu } from '../../../core';
import AdditionalInformationDialog from '../AdditionalInformation/AdditionalInformationDialog';
import {
  getInitialAccidentFollowUpInitialValidationSchema,
  getInitialAccidentFollowUpInitialValues,
  getInitialAccidentFollowUpSectionConfig,
  InitialAccidentFollowUpFragment,
} from '../AdditionalInformation/Fragments/InitialAccidentFollowUpFragment';
import {
  getInitialTheftFollowUpInitialValidationSchema,
  getInitialTheftFollowUpInitialValues,
  getInitialTheftFollowUpSectionConfig,
  InitialTheftFollowUpFragment,
} from '../AdditionalInformation/Fragments/InitialTheftFollowUpFragment';
import {
  getPoliceFireDepartmentInvolvementInitialValues,
  getPoliceFireDepartmentInvolvementSectionConfig,
  getPoliceFireDepartmentInvolvementValidationSchema,
  PoliceFireDepartmentInvolvementFragment,
} from '../AdditionalInformation/Fragments/PoliceFireDepartmentInvolvementFragment';
import {
  getIsSupplementaryInformationSectionActive,
  getSupplementaryInformationInitialValues,
  getSupplementaryInformationValidationSchema,
  SupplementaryInformationFragment,
} from '../AdditionalInformation/Fragments/SupplementaryInformationFragment';

function IncidentMoreDetailsMenu(props) {
  const { incident, onUpdateFields } = props;
  const { isFieldSupportedBySubtype } = useIsConfigurationFieldSupportedBySubtype();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { lob } = useLob();
  const [isUpdating, setIsUpdating] = useState(false);
  const [openInitialTheftFollowUp, setOpenInitialTheftFollowUp] = useState(false);
  const [openSupplementaryInformation, setOpenSupplementaryInformation] = useState(false);
  const [openInitialAccidentFollowUp, setOpenInitialAccidentFollowUp] = useState(false);

  const [openPoliceFireDepartmentInvolvement, setOpenPoliceFireDepartmentInvolvement] = useState(false);

  const { claim } = useClaim();

  const currentSupplementaryInformation = {
    was_replacement_car_rented: incident?.was_replacement_car_rented,
    replacement_car_rental_date: incident?.replacement_car_rental_date,
    are_convictions_or_claims_in_the_last_5_years: incident?.are_convictions_or_claims_in_the_last_5_years,
    convictions_or_claim_description: incident?.convictions_or_claim_description,
    did_anyone_take_photos_or_video: incident?.did_anyone_take_photos_or_video,
    was_cctv_footage: incident?.was_cctv_footage,
    can_take_photos_of_damage: incident?.can_take_photos_of_damage,
    other_policy_description: incident?.other_policy_description,
    photographer_contact_id: incident?.photographer_contact_id,
    photographer_contact_full_name: incident?.photographer_contact_full_name,
    is_other_policy_covering: incident?.is_other_policy_covering,
    is_notification_only: incident?.is_notification_only,
    is_policyholder_occupation_right: incident?.is_policyholder_occupation_right,
    policyholder_current_occupation: incident?.policyholder_current_occupation,
    does_policyholder_second_occupation_exist: incident?.does_policyholder_second_occupation_exist,
    policyholder_second_occupation: incident?.policyholder_second_occupation,
    configured_fields_values: { ...incident?.configured_fields_values },
  };
  const currentPoliceFireDepartmentInvolvement = {
    were_police_involved: incident?.were_police_involved,
    police_crime_reference_number: incident?.police_crime_reference_number,
    police_department_contact_id: incident?.police_department_contact_id,
    police_report: incident?.police_report,
    did_speak_with_officer: incident?.did_speak_with_officer,
    was_ticket_received: incident?.was_ticket_received,
    violation_title: incident?.violation_title,
    police_description: incident?.police_description,
    were_fire_department_involved: incident?.were_fire_department_involved,
    fire_department_description: incident?.fire_department_description,
    were_any_hazardous_good_being_carried: incident?.were_any_hazardous_good_being_carried,
    pending_prosecutions_or_investigations_exists: incident?.pending_prosecutions_or_investigations_exists,
  };
  const currentTheftFollowUp = {
    date_vehicle_last_seen: incident?.date_vehicle_last_seen,
    time_vehicle_last_seen: incident?.time_vehicle_last_seen,
    vehicle_last_seen_location: incident?.vehicle_last_seen_location,
    keys_location: incident?.keys_location,
    vehicle_windows_closed_and_secured: incident?.vehicle_windows_closed_and_secured,
    vehicle_fitted_with_security_devices: incident?.vehicle_fitted_with_security_devices,
    security_devices_description: incident?.security_devices_description,
    vehicle_fitted_with_tracking_devices: incident?.vehicle_fitted_with_tracking_devices,
    tracking_devices_description: incident?.tracking_devices_description,
    is_vehicle_for_sale: incident?.is_vehicle_for_sale,
    were_belongings_stolen_from_vehicle: incident?.were_belongings_stolen_from_vehicle,
    belongings_stolen: incident?.belongings_stolen,
    discovered_the_loss_contact_id: incident?.discovered_the_loss_contact_id,
    crime_reference_number: incident?.crime_reference_number,
    num_of_keys_received_on_purchase: incident?.num_of_keys_received_on_purchase,
    current_num_of_keys: incident?.current_num_of_keys,
    was_vehicle_found_with_keys: incident?.was_vehicle_found_with_keys,
    date_noticed_vehicle_missing: incident?.date_noticed_vehicle_missing,
    time_noticed_vehicle_missing: incident?.time_noticed_vehicle_missing,
    person_liable_for_the_theft_is_known: incident?.person_liable_for_the_theft_is_known,
    note: incident?.note,
  };
  const currentAccidentFollowUp = {
    journey_purpose: incident?.journey_purpose,
    weather_conditions: incident?.weather_conditions,
    direction_ph_vehicle_was_going: incident?.direction_ph_vehicle_was_going,
    was_ambulance_called: incident?.was_ambulance_called,
    first_party_way_sign_or_a_headlight: incident?.first_party_way_sign_or_a_headlight,
    street_condition: incident?.street_condition,
    was_first_party_driver_using_the_phone: incident?.was_first_party_driver_using_the_phone,
    were_first_party_vehicle_lights_on: incident?.were_first_party_vehicle_lights_on,
    was_anyone_under_the_influence_of_drugs_alcohol: incident?.was_anyone_under_the_influence_of_drugs_alcohol,
  };

  const handleUpdatingFields = async (fieldsValues) => {
    setIsUpdating(true);

    try {
      await onUpdateFields(fieldsValues);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleInitialTheftFollowUp = () => {
    setOpenInitialTheftFollowUp(true);
  };

  const handleSupplementaryInformation = () => {
    setOpenSupplementaryInformation(true);
  };

  const handlePoliceFireDepartmentInvolvement = () => {
    setOpenPoliceFireDepartmentInvolvement(true);
  };

  const handleInitialAccidentFollowUp = () => {
    setOpenInitialAccidentFollowUp(true);
  };

  const newUIGeneralAdditionalInfo = [
    {
      id: 'supplementary_information',
      label: 'Supplementary Information',
      onClick: handleSupplementaryInformation,
      disabledFunc: () => false,
      displayFunc: () => getIsSupplementaryInformationSectionActive(incidentConfiguration),
    },
  ];

  let newUIAutoAdditionalInfo = [
    {
      id: 'police_and_fire_department_involvement',
      label: 'Police and Fire Department Involvement',
      onClick: handlePoliceFireDepartmentInvolvement,
      disabledFunc: () =>
        !isFieldSupportedBySubtype(getPoliceFireDepartmentInvolvementSectionConfig(incidentConfiguration)),
      displayFunc: () => getPoliceFireDepartmentInvolvementSectionConfig(incidentConfiguration)?.active,
    },
    {
      id: 'theft_follow_up',
      label: 'Theft Follow-Up',
      onClick: handleInitialTheftFollowUp,
      disabledFunc: () => !isFieldSupportedBySubtype(getInitialTheftFollowUpSectionConfig(incidentConfiguration)),
      displayFunc: () => getInitialTheftFollowUpSectionConfig(incidentConfiguration)?.active,
    },
    {
      id: 'accident_follow_up',
      label: 'Accident Follow-Up',
      onClick: handleInitialAccidentFollowUp,
      disabledFunc: () => !isFieldSupportedBySubtype(getInitialAccidentFollowUpSectionConfig(incidentConfiguration)),
      displayFunc: () => getInitialAccidentFollowUpSectionConfig(incidentConfiguration)?.active,
    },
  ];

  const getNewUIAdditionalInfo = () => {
    switch (claim.type) {
      case 'auto_claim':
        return [...newUIGeneralAdditionalInfo, ...newUIAutoAdditionalInfo];
      default:
        return newUIGeneralAdditionalInfo;
    }
  };

  return (
    <>
      {getNewUIAdditionalInfo()?.some(({ displayFunc }) => displayFunc()) ? (
        <FsMenu
          buttonTitle="More Details"
          items={getNewUIAdditionalInfo()?.map(({ id, label, onClick, disabledFunc, displayFunc }) => ({
            id,
            label,
            onClick,
            disabled: isUpdating,
            disabledFunc,
            displayFunc,
          }))}
          ignorePermissions
        />
      ) : null}
      {openPoliceFireDepartmentInvolvement && (
        <AdditionalInformationDialog
          initialValues={currentPoliceFireDepartmentInvolvement}
          open={openPoliceFireDepartmentInvolvement}
          onCancel={() => setOpenPoliceFireDepartmentInvolvement(false)}
          onSubmit={async (values) => {
            await handleUpdatingFields(values);
            setOpenPoliceFireDepartmentInvolvement(false);
          }}
          title="Police and Fire Department Involvement"
          additionalFieldOmitFn={(field) => isFieldSupportedBySubtype(field)}
          AdditionalInformationFragment={PoliceFireDepartmentInvolvementFragment}
          AdditionalInformationFragmentInitialValues={getPoliceFireDepartmentInvolvementInitialValues(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
          AdditionalInformationFragmentValidationSchema={getPoliceFireDepartmentInvolvementValidationSchema(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
        />
      )}
      {openInitialTheftFollowUp && (
        <AdditionalInformationDialog
          initialValues={currentTheftFollowUp}
          open={openInitialTheftFollowUp}
          onCancel={() => setOpenInitialTheftFollowUp(false)}
          onSubmit={async (values) => {
            await handleUpdatingFields(values);
            setOpenInitialTheftFollowUp(false);
          }}
          title="Theft Follow Up"
          additionalFieldOmitFn={(field) => isFieldSupportedBySubtype(field)}
          AdditionalInformationFragment={InitialTheftFollowUpFragment}
          AdditionalInformationFragmentInitialValues={getInitialTheftFollowUpInitialValues(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
          AdditionalInformationFragmentValidationSchema={getInitialTheftFollowUpInitialValidationSchema(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
        />
      )}
      {openSupplementaryInformation && (
        <AdditionalInformationDialog
          initialValues={currentSupplementaryInformation}
          open={openSupplementaryInformation}
          onCancel={() => setOpenSupplementaryInformation(false)}
          onSubmit={async (values) => {
            await handleUpdatingFields(values);
            setOpenSupplementaryInformation(false);
          }}
          title="Supplementary Information"
          additionalFieldOmitFn={(field) => isFieldSupportedBySubtype(field)}
          AdditionalInformationFragment={SupplementaryInformationFragment}
          AdditionalInformationFragmentInitialValues={getSupplementaryInformationInitialValues(
            incidentConfiguration,
            lob,
            isFieldSupportedBySubtype
          )}
          AdditionalInformationFragmentValidationSchema={getSupplementaryInformationValidationSchema(
            incidentConfiguration,
            lob,
            isFieldSupportedBySubtype
          )}
        />
      )}
      {openInitialAccidentFollowUp && (
        <AdditionalInformationDialog
          initialValues={currentAccidentFollowUp}
          open={openInitialAccidentFollowUp}
          onCancel={() => setOpenInitialAccidentFollowUp(false)}
          onSubmit={async (values) => {
            await handleUpdatingFields(values);
            setOpenInitialAccidentFollowUp(false);
          }}
          title="Initial Accident Follow Up"
          additionalFieldOmitFn={(field) => isFieldSupportedBySubtype(field)}
          AdditionalInformationFragment={InitialAccidentFollowUpFragment}
          AdditionalInformationFragmentInitialValues={getInitialAccidentFollowUpInitialValues(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
          AdditionalInformationFragmentValidationSchema={getInitialAccidentFollowUpInitialValidationSchema(
            incidentConfiguration,
            isFieldSupportedBySubtype
          )}
        />
      )}
    </>
  );
}

IncidentMoreDetailsMenu.propTypes = {
  incident: PropTypes.object.isRequired,
  onUpdateFields: PropTypes.func.isRequired,
};

export default IncidentMoreDetailsMenu;
