import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios';
import { FieldArray, Formik, getIn } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { LIGHT_DUTY_POSITIONS_DICT, LIGHT_DUTY_STATUSES_DICT } from '../../Types';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import PlainTable from '../PlainTable';
import TextFieldFormik, { DatePickerTextFieldFormik, ShowOnlyTextField } from '../TextFieldFormik';

import { LightDutyTrackFormFormik } from './LightDutyTrackForm';

import { useStyles } from '../../assets/styles';

function LightDutyTrackFormik() {
  const [showDialog, setShowDialog] = useState(false);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();
  const involvedPerson = claim.employee_party.involved_person;
  const fieldName = 'light_duty_statuses';

  const handleUpdateLightDutyStatuses = async (statuses) => {
    try {
      await axios.put(
        `/api/v1/wc_claims/${claim.id}/involved_employees_light_duty_statuses/${involvedPerson.id}`,
        statuses
      );
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const currentStatuses = involvedPerson[fieldName] || [];

  const columnData = [
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      specialCell: (status) => LIGHT_DUTY_STATUSES_DICT[status.status],
    },
    {
      id: 'position',
      numeric: false,
      label: 'Position',
      specialCell: (status) => status.position_other_text ?? LIGHT_DUTY_POSITIONS_DICT[status.position],
    },
    {
      id: 'effective_date',
      numeric: false,
      label: 'Effective Date',
      specialCell: (status) => status.effective_date,
    },
    {
      id: 'next_appointment_date',
      numeric: false,
      label: 'Next appointment Date',
      specialCell: (status) => status.next_appointment_date,
    },
    {
      id: 'days',
      numeric: false,
      label: 'Days',
      specialCell: (status) => getDateDifference(status.next_appointment_date, status.effective_date),
    },
    {
      id: 'restrictions',
      numeric: false,
      label: 'Restrictions',
      specialCell: (status) => status.restrictions,
    },
    {
      id: 'note',
      numeric: false,
      label: 'Note',
      specialCell: (status) => status.note,
    },
  ];

  return (
    <>
      {currentStatuses.length === 0 ? (
        <ShowOnlyTextField classes={classes} showOnlyValueComponent="No Light Duty Status" />
      ) : (
        <PlainTable columns={columnData} rows={currentStatuses} />
      )}
      {showDialog && (
        <LightDutyTrackDialog
          existingStatuses={involvedPerson[fieldName] || []}
          onCancel={() => setShowDialog(false)}
          onUpdate={({ statuses }) => {
            handleUpdateLightDutyStatuses(statuses).then(() => setShowDialog(false));
          }}
        />
      )}
      {showFormDialog && (
        <LightDutyTrackFormFormik
          claim={claim}
          onCancel={() => setShowFormDialog(false)}
          onDocumentCreated={async () => {
            await onClaimUpdate();
            setShowFormDialog(false);
          }}
        />
      )}
      <div style={{ display: 'flex', marginTop: '15px' }}>
        <Button
          variant="contained"
          className={classes.leftButtonIcon}
          color="primary"
          onClick={() => setShowDialog(true)}
        >
          Edit
        </Button>
        {currentStatuses.length > 0 && (
          <Button variant="contained" color="primary" onClick={() => setShowFormDialog(true)}>
            Generate Form
          </Button>
        )}
      </div>
    </>
  );
}

function LightDutyTrackDialog({ onUpdate, existingStatuses, onCancel, showOnly }) {
  const statuses = Object.keys(LIGHT_DUTY_STATUSES_DICT);
  const positions = Object.keys(LIGHT_DUTY_POSITIONS_DICT);
  const classes = useStyles();
  const [showOtherPositionTextField, setShowOtherPositionTextField] = useState([]);
  const [dateDifferences, setDateDifferences] = useState([]);

  return (
    <Formik
      initialValues={{ statuses: existingStatuses }}
      validationSchema={Yup.object().shape({
        statuses: Yup.array().of(
          Yup.object().shape({
            status: Yup.string().required('Required').oneOf(statuses),
            effective_date: Yup.date().required('Required'),
            note: Yup.string(),
            position: Yup.string().required('Required'),
            position_other_text: Yup.string().when('position', {
              is: 'other',
              then: Yup.string().required('Required'),
              otherwise: Yup.string().strip(),
            }),
            next_appointment_date: Yup.date().required('Required'),
            restrictions: Yup.string().nullable(),
          })
        ),
      })}
      onSubmit={onUpdate}
    >
      {(formikProps) => {
        const { isSubmitting, values, handleSubmit, setFieldValue } = formikProps;

        return (
          <CardDialog title="Light Duty Track" isDialog maxWidth="lg" onClose={onCancel} fullWidth>
            <>
              <FieldArray
                name="statuses"
                render={({ remove, push }) => (
                  <>
                    {getIn(values, 'statuses').map((item, idx) => {
                      dateDifferences[idx] = getDateDifference(item['next_appointment_date'], item['effective_date']);
                      return (
                        <Grid container key={item.id} spacing={2}>
                          <Grid item xs={1}>
                            <TextFieldFormik
                              id={`statuses.${idx}.status`}
                              label="Status"
                              fullWidth
                              multiline
                              className={classes.textField}
                              showOnly={showOnly}
                              select
                            >
                              {statuses.map((status) => (
                                <MenuItem key={status} value={status}>
                                  {LIGHT_DUTY_STATUSES_DICT[status]}
                                </MenuItem>
                              ))}
                            </TextFieldFormik>
                          </Grid>
                          <Grid item xs={1}>
                            <TextFieldFormik
                              id={`statuses.${idx}.position`}
                              label="Position"
                              fullWidth
                              multiline
                              className={classes.textField}
                              showOnly={showOnly}
                              select
                              onChange={(e) => {
                                const newPosition = e.target.value;
                                setFieldValue(`statuses.${idx}.position`, newPosition);
                                if (newPosition === 'other') {
                                  setShowOtherPositionTextField((prevState) => {
                                    const newState = [...prevState];
                                    newState[idx] = true;
                                    return newState;
                                  });
                                  setFieldValue(`statuses.${idx}.position_other_text`, '');
                                } else {
                                  setFieldValue(`statuses.${idx}.position_other_text`, undefined);
                                  setShowOtherPositionTextField((prevState) => {
                                    const newState = [...prevState];
                                    newState[idx] = false;
                                    return newState;
                                  });
                                }
                              }}
                            >
                              {positions.map((position) => (
                                <MenuItem key={position} value={position}>
                                  {LIGHT_DUTY_POSITIONS_DICT[position]}
                                </MenuItem>
                              ))}
                            </TextFieldFormik>
                          </Grid>
                          {(showOtherPositionTextField[idx] || item['position_other_text']) && (
                            <Grid item xs={1}>
                              <TextFieldFormik
                                id={`statuses.${idx}.position_other_text`}
                                label="Other Position"
                                fullWidth
                                className={classes.textField}
                                showOnly={showOnly}
                              />
                            </Grid>
                          )}
                          <Grid item xs={2}>
                            <DatePickerTextFieldFormik
                              id={`statuses.${idx}.effective_date`}
                              label="Effective Date"
                              className={classes.textField}
                              fullWidth
                              disabled={showOnly}
                              disablePast={false}
                              onChange={(newDate) => {
                                setFieldValue(`statuses.${idx}.effective_date`, newDate?.format('YYYY-MM-DD'));

                                setDateDifferences((prevState) => {
                                  const newState = [...prevState];
                                  newState[idx] = getDateDifference(item['next_appointment_date'], newDate);
                                  return newState;
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <DatePickerTextFieldFormik
                              id={`statuses.${idx}.next_appointment_date`}
                              label="Next Appointment Date"
                              className={classes.textField}
                              fullWidth
                              disabled={showOnly}
                              disablePast={false}
                              onChange={(newDate) => {
                                setFieldValue(`statuses.${idx}.next_appointment_date`, newDate?.format('YYYY-MM-DD'));

                                setDateDifferences((prevState) => {
                                  const newState = [...prevState];
                                  newState[idx] = getDateDifference(newDate, item['effective_date']);
                                  return newState;
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <ShowOnlyTextField
                              classes={classes}
                              showOnlyValueComponent={dateDifferences[idx]}
                              label="Days"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextFieldFormik
                              id={`statuses.${idx}.restrictions`}
                              label="Restrictions"
                              fullWidth
                              multiline
                              className={classes.textField}
                              showOnly={showOnly}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextFieldFormik
                              id={`statuses.${idx}.note`}
                              label="note"
                              fullWidth
                              multiline
                              className={classes.textField}
                              showOnly={showOnly}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton onClick={() => remove(idx)}>
                              <CancelIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <div className={classes.buttonsContainer}>
                      <Button
                        color="primary"
                        disabled={showOnly}
                        onClick={() =>
                          push({
                            status: '',
                            effective_date: '',
                            note: '',
                            position: '',
                            next_appointment_date: '',
                            restrictions: '',
                            id: `new_${uuidv4()}`,
                          })
                        }
                      >
                        <AddIcon />
                        Add
                      </Button>
                    </div>
                  </>
                )}
              />
              <div className={classes.buttonsContainer}>
                <CancelButton disabled={showOnly} onClick={onCancel} />
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

LightDutyTrackDialog.propTypes = {
  existingStatuses: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
};

const getDateDifference = (nextAppointmentDate, effectiveDate) => {
  const parsedEffectiveDate = effectiveDate ? new Date(effectiveDate) : null;
  parsedEffectiveDate?.setHours(0, 0, 0, 0);
  const parsedNextAppointmentDate = nextAppointmentDate ? new Date(nextAppointmentDate) : null;
  parsedNextAppointmentDate?.setHours(0, 0, 0, 0);
  const differenceInTime =
    parsedEffectiveDate && parsedNextAppointmentDate
      ? parsedNextAppointmentDate.getTime() - parsedEffectiveDate.getTime()
      : null;
  const daysDifference = differenceInTime !== null ? Math.floor(differenceInTime / (1000 * 3600 * 24)) : null;

  return daysDifference !== null ? Math.max(daysDifference + 1, 0) : 0;
};

function LightDutyTrackCard() {
  return (
    <CardDialog title="Light Duty Track">
      <LightDutyTrackFormik />
    </CardDialog>
  );
}

export { LightDutyTrackCard, LightDutyTrackFormik };
