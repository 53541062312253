import React from 'react';
import type { FormikValues } from 'formik';
import { useFormikContext } from 'formik';

import Editor from '~/components/core/Editor';
import type { DisplayConfigProps, SuggestionOption } from '~/components/core/Editor/types';
import Text from '~/components/core/TextComponents/Text';

interface EditorFormikProps {
  id: string;
  label?: React.ReactNode;
  disabled?: boolean;
  showOnly?: boolean;
  autoFocus?: boolean;
  maxHeight?: string;
  displayConfig?: DisplayConfigProps;
  suggestionOptions?: SuggestionOption[];
  classNameEditor?: string;
}

const EditorFormik: React.FC<EditorFormikProps> = ({
  id,
  label,
  disabled,
  showOnly,
  autoFocus,
  maxHeight,
  displayConfig,
  suggestionOptions,
  classNameEditor,
}) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const content = values[id];

  const handleChange = (value: string) => {
    setFieldValue(id, value);
  };

  return (
    <div>
      {label && (
        <Text className="pt-12" variant={Text.VARIANTS.SM} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
          {label}
        </Text>
      )}
      {!showOnly ? (
        <div className="py-12">
          <Editor
            className={classNameEditor}
            disabled={disabled}
            onChange={handleChange}
            autoFocus={autoFocus}
            displayConfig={displayConfig}
            content={content}
            suggestionOptions={suggestionOptions}
          />
        </div>
      ) : (
        <div style={{ maxHeight, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </div>
  );
};

export default EditorFormik;
