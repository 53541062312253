import React from 'react';

import ShowAllCollapsible from '~/components/core/Molecules/Collapsible/ShowAllCollapsible';
import { DocumentNote } from '~/components/Notes/NoteContent/DocumentNote';
import type { NoteContentProps } from '~/components/Notes/NoteContent/types';
import type { NotePartProps } from '~/components/Notes/NoteMiniCard';

const DocumentsNote: React.FC<NoteContentProps> = ({ note, isExpended, onCollapseClick, isCollapsible, maxRows }) => {
  return isCollapsible ? (
    <ShowAllCollapsible
      visibleLines={maxRows || 1}
      showAsInlineButton
      isOpen={isExpended}
      onCollapseClick={onCollapseClick}
    >
      <DocumentsNoteInner note={note} />
    </ShowAllCollapsible>
  ) : (
    <DocumentsNoteInner note={note} />
  );
};

const DocumentsNoteInner: React.FC<NotePartProps> = ({ note }) => {
  if (!note.documents) {
    return <></>;
  }
  return (
    <>
      {note.documents.map((document, i) => (
        <DocumentNote document={document} key={i} isCollapsible={false} />
      ))}
    </>
  );
};

export default DocumentsNote;
