import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { AccountLockOutline } from 'mdi-material-ui';

import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { reportAxiosError } from '../Utils';

import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import InlineIconButton from './InlineIconButton';
import { TextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

// TODO - New UI - Add indication in the bar if the access is limited
function LimitClaimAccessContainer() {
  const [showLimitAccessDialog, setShowLimitAccessDialog] = useState(false);
  const { claim } = useClaim();

  return (
    <>
      <InlineIconButton
        defaultColor="inherit"
        iconStyle={claim.is_access_limited ? { color: 'green' } : undefined}
        onClick={() => setShowLimitAccessDialog(true)}
        icon={AccountLockOutline}
        tooltipTitle="Lock this claim for external users"
      />

      {showLimitAccessDialog && <LimitAccessDialog claim={claim} onClose={() => setShowLimitAccessDialog(false)} />}
    </>
  );
}

function LimitAccessDialog({ onClose }) {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();

  async function handleUpdateClaimAccess(shouldLimitedAccess) {
    if (shouldLimitedAccess === claim.is_access_limited) {
      return;
    }

    try {
      await axios.post(`/api/v1/claims/${claim.id}/claim_access/${shouldLimitedAccess ? 'limit' : 'open'}`);
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  return (
    <Formik
      initialValues={{ limit_claim_access: claim.is_access_limited }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleUpdateClaimAccess(values.limit_claim_access);
          onClose();
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CardDialog
          title="Limit Claim Access"
          isDialog
          maxWidth="xs"
          fullWidth
          onClose={onClose}
          preventClose={isSubmitting}
        >
          <TextFieldFormik
            id="limit_claim_access"
            select
            label="Limit Claim Access"
            className={classes.textField}
            fullWidth
          >
            {['Yes', 'No'].map((option) => (
              <MenuItem key={option} value={option === 'Yes'}>
                {option}
              </MenuItem>
            ))}
          </TextFieldFormik>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Update
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

LimitAccessDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { LimitAccessDialog };
export default LimitClaimAccessContainer;
