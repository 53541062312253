import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '../../components/core';
import { isPermissionsEnabled } from '../../components/core/Permissions/PermissionUtils';
import { useCms } from '../../components/hooks/useCms';
import { useHasPermission } from '../../components/hooks/useHasPermission';
import useOrganization from '../../components/OrganizationContext';
import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isDashboardViewer, isFeatureEnabled, isReportViewer } from '../../Utils';

export const useReportsDashboardsPage = () => {
  const { user, userOrganization } = useCms();
  const { orgAdditionalConfigs } = useOrganization();

  const isPermissionsFeatureEnabled = isPermissionsEnabled(userOrganization);

  const isTableauAccountFeatureEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.TABLEAU_ACCOUNTS
  );

  const userHasManagementPermissions = useHasPermission({
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  });

  const userHasAdjusterDashboardPermissions = useHasPermission({
    action: PERMISSION_ACTIONS.ADJUSTER_DASHBOARD,
    verb: PERMISSION_VERBS.READ,
  });

  const hasReportsPermission = userHasManagementPermissions;
  const hasDashboardsPermission = userHasManagementPermissions || userHasAdjusterDashboardPermissions;

  const hasOldReportsPermission = isReportViewer(orgAdditionalConfigs, user);
  const hasOldDashboardsPermission = isDashboardViewer(orgAdditionalConfigs, user);

  return {
    isPermissionsFeatureEnabled,
    isTableauAccountFeatureEnabled,
    hasReportsPermission,
    hasDashboardsPermission,
    hasOldReportsPermission,
    hasOldDashboardsPermission,
  };
};
