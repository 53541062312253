import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel } from '@material-ui/core';

import Radio from '~/components/core/Atomic/Radio';
import Typography from '~/components/core/Atomic/Typography';
import cn from '~/Utils/cn';

import { useStyles } from '../../assets/styles';

const POSITIONS = {
  START: 'start',
  END: 'end',
};

const RadioWithButtonWrapper = ({
  className,
  icon,
  text,
  value,
  disabled,
  wrapperOverrideStyle = {},
  color,
  checked,
  iconLocation = POSITIONS.START,
  onChange,
}) => {
  const classes = useStyles();

  const marginFromSideProp = iconLocation === POSITIONS.START ? 'marginRight' : 'marginLeft';
  const iconWrapper = <span style={{ [marginFromSideProp]: '10px' }}>{icon}</span>;

  const label = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon && iconLocation === POSITIONS.START && iconWrapper}
      <Typography display="block" variant="body1">
        {text}
      </Typography>
      {icon && iconLocation === POSITIONS.END && iconWrapper}
    </div>
  );

  return (
    <div
      className={cn(classes.radioOrCheckboxWithIcon, className, { disabled })}
      style={wrapperOverrideStyle}
      onClick={() => !disabled && onChange(value)}
    >
      <FormControl>
        <FormControlLabel
          value=""
          control={
            <Radio
              color={color || 'primary'}
              checked={checked}
              onChange={() => !disabled && onChange(value)}
              disabled={disabled}
              size="small"
            />
          }
          label={label}
          labelPlacement="end"
        />
      </FormControl>
    </div>
  );
};

RadioWithButtonWrapper.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  text: PropTypes.string.isRequired,
  value: PropTypes.node,
  disabled: PropTypes.bool,
  wrapperOverrideStyle: PropTypes.object,
  color: PropTypes.string,
  checked: PropTypes.bool,
  iconLocation: PropTypes.oneOf(Object.values(POSITIONS)),
  onChange: PropTypes.func.isRequired,
};

RadioWithButtonWrapper.POSITIONS = POSITIONS;

export default RadioWithButtonWrapper;
