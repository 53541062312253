import React from 'react';
import PropTypes from 'prop-types';

import Chip from '~/components/core/Atomic/Chip/Chip';
import cn from '~/Utils/cn';

import {
  CalledOffIcon,
  CheckIcon,
  ClockIcon,
  EyeIcon,
  InProgressIcon,
  RejectedIcon,
  WarningCircleIcon,
} from '../../icons';

import styles from './statusChip.module.scss';

const STATUSES = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN PROGRESS',
  PENDING: 'PENDING',
  DONE: 'DONE',
  REJECTED: 'REJECTED',
  CALLED_OFF: 'CALLED OFF',
  NOT_ACTIVE: 'NOT ACTIVE',
  ACTIVE: 'ACTIVE',
  SUBMITTED: 'SUBMITTED',
  ISSUED: 'ISSUED',
  SUCCESS: 'SUCCESS',
  STOPPED: 'STOPPED',
  CANCELLED: 'CANCELLED',
  PENDING_APPROVAL: 'PENDING APPROVAL',
  CANCEL_PENDING: 'CANCEL PENDING',
  FOR_REVIEW: 'PENDING REVIEW',
  ERROR: 'ERROR',
};

const ICONS = {
  [STATUSES.PENDING]: ClockIcon,
  [STATUSES.DONE]: CheckIcon,
  [STATUSES.REJECTED]: RejectedIcon,
  [STATUSES.CALLED_OFF]: CalledOffIcon,
  [STATUSES.NOT_ACTIVE]: WarningCircleIcon,
  [STATUSES.IN_PROGRESS]: InProgressIcon,
  [STATUSES.ACTIVE]: CheckIcon,
  [STATUSES.SUBMITTED]: CheckIcon,
  [STATUSES.ISSUED]: CheckIcon,
  [STATUSES.SUCCESS]: CheckIcon,
  [STATUSES.STOPPED]: RejectedIcon,
  [STATUSES.ERROR]: RejectedIcon,
  [STATUSES.CANCELLED]: CalledOffIcon,
  [STATUSES.PENDING_APPROVAL]: ClockIcon,
  [STATUSES.CANCEL_PENDING]: ClockIcon,
  [STATUSES.FOR_REVIEW]: EyeIcon,
};

const StatusChip = ({ status = STATUSES.OPEN, withIcon = false }) => {
  const className = cn(styles.statusChip, {
    [styles.statusChipOpen]: status === STATUSES.OPEN,
    [styles.statusChipClosed]: status === STATUSES.CLOSED,
    [styles.statusChipCompleted]: status === STATUSES.COMPLETED,
    [styles.statusChipInProgress]: status === STATUSES.IN_PROGRESS,
    [styles.statusChipPending]: status === STATUSES.PENDING,
    [styles.statusChipDone]: status === STATUSES.DONE,
    [styles.statusChipRejected]: status === STATUSES.REJECTED,
    [styles.statusChipCalledOff]: status === STATUSES.CALLED_OFF,
    [styles.statusChipNotActive]: status === STATUSES.NOT_ACTIVE,
    [styles.statusChipActive]: status === STATUSES.ACTIVE,
    [styles.statusChipSubmitted]: status === STATUSES.SUBMITTED,
    [styles.statusChipIssued]: status === STATUSES.ISSUED,
    [styles.statusChipSuccess]: status === STATUSES.SUCCESS,
    [styles.statusChipStopped]: status === STATUSES.STOPPED,
    [styles.statusChipCancelled]: status === STATUSES.CANCELLED,
    [styles.statusChipPendingApproval]: status === STATUSES.PENDING_APPROVAL,
    [styles.statusChipCancelPending]: status === STATUSES.CANCEL_PENDING,
    [styles.statusChipForReview]: status === STATUSES.FOR_REVIEW,
    [styles.statusChipError]: status === STATUSES.ERROR,
  });
  const StatusIcon = ICONS[status];
  const icon = withIcon && StatusIcon ? <StatusIcon size={14} iconColor="rgba(0, 0, 0, 0.54)" /> : undefined;

  return (
    <div className={className}>
      <Chip icon={icon} label={status} classes={{ root: styles.chip }} />
    </div>
  );
};

StatusChip.STATUSES = STATUSES;

StatusChip.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUSES)),
  withIcon: PropTypes.bool,
};

export default StatusChip;
