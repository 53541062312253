import React from 'react';
import PropTypes from 'prop-types';

import { useLobConfiguredFields } from '~/components/hooks/useLobConfiguredFields';

import { useClaim } from '../ClaimContainer';
import { ShowOnlyTextField } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const CoveredEntityField = ({ exposure }) => {
  const { claim } = useClaim();
  const classes = useStyles();

  const coveredObjects = claim.policy.covered_objects;
  const coveredObjectForExposure = coveredObjects?.find((coveredObject) => {
    return exposure.covered_entity_id && coveredObject.covered_entity_id === exposure.covered_entity_id;
  });

  const { coveredObjectTypeLabel, defaultFields } = useLobConfiguredFields(coveredObjectForExposure, false);

  let coveredObjectName = '';
  const nameField = defaultFields?.[0];
  if (nameField.id === 'display_name') {
    coveredObjectName = nameField.value;
  } else if (nameField.id === 'contact_id') {
    coveredObjectName = coveredObjectForExposure.contact.full_name;
  }

  return (
    <ShowOnlyTextField label={coveredObjectTypeLabel} classes={classes} showOnlyValueComponent={coveredObjectName} />
  );
};

CoveredEntityField.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export default CoveredEntityField;
