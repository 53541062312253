import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '~/components/core/Atomic/Tooltip';

import { ThumbDownIcon, ThumbUpIcon } from '../../components/icons';
import { COVERAGE_DECISION_DICT } from '../../Types';
import HoverActionField from '../HoverActionField';

import CoverageDecisionCard from './CoverageDecisionCard';

function CoverageDecisionContainer({ exposures, coverageDecision, readOnly, onUpdateCoverageDecision, tooltipTitle }) {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(undefined);

  const handleUpdateCoverageDecision = async (values) => {
    await onUpdateCoverageDecision(values);
    setPopoverAnchorEl(undefined);
  };

  return (
    <>
      <HoverActionField
        onAction={(e) => setPopoverAnchorEl(e.currentTarget)}
        disabled={readOnly}
        permanent={!coverageDecision}
        actionAriaLabel="Set coverage decision"
      >
        {coverageDecision ? (
          coverageDecision.is_coverage_confirmed ? (
            <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
              <Tooltip title={tooltipTitle || COVERAGE_DECISION_DICT[coverageDecision.coverage_decision]}>
                <ThumbUpIcon />
              </Tooltip>
              {coverageDecision.coverage_decision === 'partial_coverage' && <span>&nbsp;&nbsp;Partial</span>}
            </span>
          ) : (
            <Tooltip title={`Denied - ${coverageDecision.reason}`}>
              <ThumbDownIcon />
            </Tooltip>
          )
        ) : (
          <em>Pending Investigation</em>
        )}
      </HoverActionField>
      <CoverageDecisionCard
        cardDialogProps={{
          isPopover: true,
          open: Boolean(popoverAnchorEl),
          anchorEl: popoverAnchorEl,
        }}
        exposures={exposures}
        coverageDecision={coverageDecision}
        onCancel={() => setPopoverAnchorEl(undefined)}
        onDecideCoverage={handleUpdateCoverageDecision}
      />
    </>
  );
}

CoverageDecisionContainer.propTypes = {
  exposures: PropTypes.array.isRequired,
  coverageDecision: PropTypes.object,
  readOnly: PropTypes.bool,
  onUpdateCoverageDecision: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string,
};

export default CoverageDecisionContainer;
