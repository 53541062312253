import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';

import { isoDateToUs } from '../../DateTimeUtils';
import HoverChangeField, { SelectHoverChangeField } from '../HoverChangeField';
import TextFieldFormik, {
  DatePickerTextFieldFormik,
  MultiSelectTextFieldFormik,
  NumericTextFieldFormik,
  ShowOnlyTextField,
  TimePickerTextFieldFormik,
} from '../TextFieldFormik';

import useCustomFields, { CustomFieldsContextProvider } from './CustomFieldsContext';

import { useStyles } from '../../assets/styles';

const CustomFields = ({ values, customFields, onFieldUpdate, readOnly = false, inline = false }) => {
  return (
    <CustomFieldsContextProvider customFieldsConfigurations={customFields}>
      <Grid container spacing={1}>
        {customFields.map((field) => (
          <Grid item xs={6} key={field.id}>
            <CustomField
              field={field}
              value={values[field.id]}
              onUpdate={onFieldUpdate}
              readOnly={readOnly}
              inline={inline}
            />
          </Grid>
        ))}
      </Grid>
    </CustomFieldsContextProvider>
  );
};

CustomFields.propTypes = {
  values: PropTypes.object.isRequired,
  customFields: PropTypes.array.isRequired,
  onFieldUpdate: requiredIf(PropTypes.func, (props) => !props.inline),
  readOnly: PropTypes.bool,
  inline: PropTypes.bool,
};

const customFieldsBasePropTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.any,
  onUpdate: requiredIf(PropTypes.func, (props) => !props.inline),
  readOnly: PropTypes.bool.isRequired,
  inline: PropTypes.bool.isRequired,
};

const CustomField = ({ field, value, onUpdate, readOnly, inline }) => {
  const onFieldUpdate = (updatedValues) => onUpdate(field.id, updatedValues[field.id]);

  const baseFields = {
    field,
    value,
    readOnly,
    inline,
    onUpdate: onFieldUpdate,
  };

  switch (field.type) {
    case 'string':
      return <TextCustomField {...baseFields} />;
    case 'select':
      return <SelectCustomField {...baseFields} />;
    case 'multiselect':
      return <SelectCustomField isMultiSelect {...baseFields} />;
    case 'date': {
      return <DateCustomField {...baseFields} />;
    }
    case 'time': {
      return <TimeCustomField {...baseFields} />;
    }
    case 'number': {
      return <NumericCustomField {...baseFields} />;
    }
    case 'boolean':
      return <BoolCustomField additionalChildrenProps={{ width: '300px' }} {...baseFields} />;
    default:
      return <></>;
  }
};

CustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const TextCustomField = ({ field, value, onUpdate, readOnly, inline }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();

  if (inline) {
    return (
      <TextFieldFormik
        id={`additional_data.${field.id}`}
        label={field.desc}
        className={classes.textField}
        fullWidth
        showOnly={readOnly}
      />
    );
  }

  const readOnlyField = <ShowOnlyTextField classes={classes} showOnlyValueComponent={value} label={field.desc} />;

  if (readOnly) {
    return readOnlyField;
  }

  return (
    <HoverChangeField
      name={field.id}
      value={value ?? ''}
      label={field.desc}
      onUpdate={onUpdate}
      fullWidth
      multiline
      showOnly
      rows={3}
      validationSchema={additionalDataValidations[field.id]}
    >
      {readOnlyField}
    </HoverChangeField>
  );
};

TextCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const SelectCustomField = ({ field, value, onUpdate, isMultiSelect = false, readOnly, inline }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();

  if (inline) {
    if (isMultiSelect) {
      const displayValueFunc = (key) => field.options.find((o) => o.id === key).desc;
      return (
        <MultiSelectTextFieldFormik
          id={`additional_data.${field.id}`}
          label={field.desc}
          className={classes.textField}
          options={field.options.map((o) => o.id)}
          renderValue={(selected) => selected.map((key) => displayValueFunc(key)).join(', ')}
          renderOption={displayValueFunc}
          fullWidth
          showOnly={readOnly}
        />
      );
    } else {
      return (
        <TextFieldFormik
          id={`additional_data.${field.id}`}
          label={field.desc}
          className={classes.textField}
          fullWidth
          select
          showOnly={readOnly}
        >
          {field.options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      );
    }
  }

  const selectProps = {
    value: value || (isMultiSelect ? [] : null),
    label: field.desc,
    fieldId: field.id,
    keys: field.options.map((o) => o.id),
    onUpdate,
    overrideOnEdit: true,
    isMultiSelect,
    validationSchema: additionalDataValidations[field.id],
  };

  const readOnlyProps = {
    classes,
    label: field.desc,
  };

  if (isMultiSelect) {
    selectProps.displayValueFunc = (id) => field.options.find((o) => o.id === id).desc;
    readOnlyProps.showOnlyValueComponent = value
      ? value.map((v) => field.options.find((o) => o.id === v).desc).join(', ')
      : '';
  } else {
    selectProps.displayValueFunc = (id) => field.options.find((o) => o.id === id).desc;
    readOnlyProps.showOnlyValueComponent = value ? field.options.find((o) => o.id === value).desc : '';
  }

  const readOnlyField = <ShowOnlyTextField {...readOnlyProps} />;

  if (readOnly) {
    return readOnlyField;
  }

  return <SelectHoverChangeField {...selectProps}>{readOnlyField}</SelectHoverChangeField>;
};

SelectCustomField.propTypes = {
  ...customFieldsBasePropTypes,
  isMultiSelect: PropTypes.bool,
};

const DateCustomField = ({ field, value, onUpdate, readOnly, inline }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();

  if (inline && !readOnly) {
    return (
      <DatePickerTextFieldFormik
        id={`additional_data.${field.id}`}
        label={field.desc}
        className={classes.textField}
        fullWidth
      />
    );
  }

  return (
    <SpecialCustomField
      specialType="date"
      field={field}
      value={value && isoDateToUs(value)}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      readOnly={readOnly}
      inline={inline}
    />
  );
};

DateCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const TimeCustomField = ({ field, value, onUpdate, readOnly, inline }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();

  if (inline && !readOnly) {
    return (
      <TimePickerTextFieldFormik
        id={`additional_data.${field.id}`}
        label={field.desc}
        className={classes.textField}
        fullWidth
      />
    );
  }

  return (
    <SpecialCustomField
      specialType="time"
      field={field}
      value={value}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      readOnly={readOnly}
      inline={inline}
    />
  );
};

TimeCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const NumericCustomField = ({ field, value, onUpdate, readOnly, inline }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();

  if (inline && !readOnly) {
    return (
      <NumericTextFieldFormik
        id={`additional_data.${field.id}`}
        label={field.desc}
        className={classes.textField}
        fullWidth
      />
    );
  }

  return (
    <SpecialCustomField
      specialType="numeric"
      field={field}
      value={value}
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      readOnly={readOnly}
      inline={inline}
    />
  );
};

NumericCustomField.propTypes = {
  ...customFieldsBasePropTypes,
};

const SpecialCustomField = ({ field, value, onUpdate, specialType, validationSchema, readOnly }) => {
  const classes = useStyles();

  const readOnlyField = <ShowOnlyTextField classes={classes} showOnlyValueComponent={value} label={field.desc} />;

  if (readOnly) {
    return readOnlyField;
  }

  return (
    <HoverChangeField
      name={field.id}
      value={value ?? ''}
      label={field.desc}
      specialFieldType={specialType}
      onUpdate={onUpdate}
      validationSchema={validationSchema}
    >
      {readOnlyField}
    </HoverChangeField>
  );
};

SpecialCustomField.propTypes = {
  ...customFieldsBasePropTypes,
  specialType: PropTypes.oneOf(['date', 'time', 'numeric']).isRequired,
  validationSchema: PropTypes.object,
};

const BoolCustomField = ({ field, value, onUpdate, additionalChildrenProps, readOnly, inline }) => {
  const classes = useStyles();
  const { additionalDataValidations } = useCustomFields();

  if (inline && !readOnly) {
    return <YesNoQuestionFormik questionText={field.desc} id={`additional_data.${field.id}`} disabled={readOnly} />;
  }

  const fieldText = (() => {
    if (value === undefined || value === null) {
      return '';
    }

    return value ? 'Yes' : 'No';
  })();

  const readOnlyField = <ShowOnlyTextField classes={classes} showOnlyValueComponent={fieldText} label={field.desc} />;

  if (readOnly) {
    return readOnlyField;
  }

  return (
    <HoverChangeField
      name={field.id}
      value={value ?? ''}
      label={field.desc}
      specialFieldType="bool"
      onUpdate={onUpdate}
      validationSchema={additionalDataValidations[field.id]}
      {...additionalChildrenProps}
    >
      {readOnlyField}
    </HoverChangeField>
  );
};

BoolCustomField.propTypes = {
  ...customFieldsBasePropTypes,
  additionalChildrenProps: PropTypes.object,
};

export default CustomFields;
