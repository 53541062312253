import axios from 'axios';

import { reportAxiosError } from '../../../../../Utils';
import useOrganization from '../../../../OrganizationContext';
import useDataFetcher from '../../../../useDataFetcher';

const ROUTES = {
  enableXactAnalysis: (organizationId) => `/api/v1/xactanalysis/${organizationId}/enable`,
  configurations: (organizationId) => `/api/v1/xactanalysis/${organizationId}/configurations`,
  configById: (organizationId, configId) => `/api/v1/xactanalysis/${organizationId}/configurations/${configId}`,
};

/**
 * @returns {{
 *   isEnabled: boolean,
 *   isLoading: boolean,
 *   isError: boolean,
 *   xactAnalysisOrgConfigs: XactAnalysisConfig[],
 *   reloadData: () => undefined,
 *   enableXactAnalysis: () => undefined,
 *   upsertXactAnalysisConfig: (config: XactAnalysisConfig) => XactAnalysisConfig,
 * }}
 */
export const useXactAnalysis = () => {
  const { organizationId, reloadOperationalDetails, isXactAnalysisEnabled } = useOrganization();

  const { isLoading, isError, data, reloadData } = useDataFetcher(ROUTES.configurations(organizationId));

  const enableXactAnalysis = async () => {
    try {
      await axios.post(ROUTES.enableXactAnalysis(organizationId));
      await reloadOperationalDetails();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const upsertXactAnalysisConfig = async (config) => {
    try {
      if (config?.id) {
        await axios.patch(ROUTES.configById(organizationId, config.id), config);
      } else {
        const response = await axios.post(ROUTES.configurations(organizationId), config);
        config = response.data;
      }
      await reloadData();
      return config;
    } catch (error) {
      reportAxiosError(error);
    }
  };

  return {
    isEnabled: isXactAnalysisEnabled,
    isLoading,
    isError,
    xactAnalysisOrgConfigs: data || [],
    reloadData,
    enableXactAnalysis,
    upsertXactAnalysisConfig,
  };
};
