import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const VideoIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.25a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-2.349l3.334 2.223A.75.75 0 0 0 24 16.5v-9a.75.75 0 0 0-1.166-.624L19.5 9.099V6.75a1.5 1.5 0 0 0-1.5-1.5H3Zm15 5.24a.8.8 0 0 0 0 .022v2.976a.8.8 0 0 0 0 .022v3.74H3V6.75h15v3.74Zm1.5 2.609V10.9l3-2V15.1l-3-2Z"
        fill={iconColor}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={iconColor} d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

VideoIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default VideoIcon;
