import React from 'react';
import * as Yup from 'yup';

import styles from './WizardUtils.module.scss';

export const onNextWizardValidation = async (formikContext) => {
  const { validateForm, setTouched } = formikContext;
  // checks for errors, then sets all erroneous fields as touched
  // if returns false, the step will not advance
  const errors = await validateForm();
  const mapAllErrors = (obj) => {
    if (obj && typeof obj === 'object') {
      return Object.fromEntries(Object.keys(obj).map((key) => [key, mapAllErrors(obj[key])]));
    } else {
      return true;
    }
  };
  await setTouched(mapAllErrors(errors));
  return Object.keys(errors).length === 0;
};

export const lobIdentifierValidation = () => {
  return Yup.string()
    .required('Required')
    .max(5, 'Not more than 5 symbols')
    .matches(/^[A-Z]*$/, 'Upper Case only');
};

export const coverageConfigurationValidation = () => {
  return Yup.array().min(1, 'At least 1 coverage has to be created.');
};

export const subOrgIdentifierValidation = (subOrgsClaimIdentifiers) => {
  return Yup.string()
    .required('Required')
    .max(4, 'Identifier must be up to four characters')
    .matches(/^[A-Z]*$/, 'Identifier must consist of uppercase letters')
    .test(
      'is-unique-per-org',
      'Sub Organization claim identifier has to be unique across the organization',
      (value) => !subOrgsClaimIdentifiers.includes(value)
    );
};

export const textWithIcon = (text, icon, position) => (
  <span className={styles.buttonWithIcon}>
    {position === POSITIONS.START && icon}
    {text}
    {position === POSITIONS.END && icon}
  </span>
);

export const POSITIONS = {
  START: 'start',
  END: 'end',
};
