const MGM_GL_COVERAGE_DECISION_DICT = {
  mgm_claim_to_resolve: {
    desc: 'Claim to resolve',
    decision: 'confirmed',
  },
  mgm_claim_to_compromise: {
    desc: 'Claim to compromise',
    decision: 'confirmed',
  },
  mgm_claim_to_deny: {
    desc: 'Claim to deny',
    decision: 'denied',
  },
  mgm_claim_to_tender: {
    desc: 'Claim to tender',
    decision: 'confirmed',
  },
} as const;

export default MGM_GL_COVERAGE_DECISION_DICT;
