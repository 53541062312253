import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../CardDialog';
import { getAllClaimRelatedRoles } from '../communications/ContactUtils';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { InvolvedPersonsListDetails } from '../InvolvedPerson';
import { InvolvedPropertiesListDetails } from '../InvolvedProperty';
import useOrganization from '../OrganizationContext';

import DamageDialogContainer from './DamageDialogContainer';

import { useStyles } from '../../assets/styles';

function BiDetailsDialog(props) {
  const { dialogTitle, onSubmitDetails, damageDetails, open, onCancel, injuredPartyTitle } = props;
  const classes = useStyles();
  const biPdInitialValues = { injured_parties: [] };
  const { organizationContactRolesDict } = useOrganization();

  return (
    <Formik
      initialValues={{ ...biPdInitialValues, ...damageDetails }}
      validationSchema={Yup.object().shape({
        injured_parties: Yup.array().required('Required').min(1, 'At least one injured is required'), // the inner component performs the validation, so no need of tell array shape
      })}
      enableReinitialize
      onSubmit={(values, formikBag) => {
        onSubmitDetails(values);
        formikBag.resetForm();
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps;
        const injuredInvolvedPersons = values.injured_parties;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title={dialogTitle}
            fullWidth
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <InvolvedPersonsListDetails
                involvedPersons={injuredInvolvedPersons}
                onSaveInvolvedPersonDetails={(person, idx) =>
                  setFieldValue(
                    'injured_parties',
                    injuredInvolvedPersons.map((injuredPerson, currIdx) => (idx === currIdx ? person : injuredPerson))
                  )
                }
                onAddInvolvedPerson={(person) => setFieldValue('injured_parties', [...injuredInvolvedPersons, person])}
                onDeleteInvolvedPerson={(_, idx) =>
                  setFieldValue(
                    'injured_parties',
                    injuredInvolvedPersons.filter((_, currIdx) => idx !== currIdx)
                  )
                }
                contactAcceptedRoles={getAllClaimRelatedRoles(organizationContactRolesDict).filter(
                  (role) => role !== 'witness'
                )}
                personLabel={injuredPartyTitle}
                allowDelete={true}
              />
              <ErrorHelperTextFormik id="injured_parties" />
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

BiDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  damageDetails: PropTypes.object.isRequired,
  onSubmitDetails: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  injuredPartyTitle: PropTypes.string,
};

function PdDetailsDialog(props) {
  const { dialogTitle, onSubmitDetails, damageDetails, open, onCancel, propertyLabel } = props;
  const classes = useStyles();
  const biPdInitialValues = { damaged_properties: [] };

  return (
    <Formik
      initialValues={{ ...biPdInitialValues, ...damageDetails }}
      validationSchema={Yup.object().shape({
        damaged_properties: Yup.array().required('Required').min(1, 'At least one property is required'), // the inner component performs the validation, so no need of tell array shape
      })}
      enableReinitialize
      onSubmit={(values, formikBag) => {
        onSubmitDetails(values);
        formikBag.resetForm();
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps;
        const damagedProperties = values.damaged_properties;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title={dialogTitle}
            fullWidth
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <InvolvedPropertiesListDetails
                involvedProperties={damagedProperties}
                onSaveInvolvedPropertyDetails={(property, idx) =>
                  setFieldValue(
                    'damaged_properties',
                    damagedProperties.map((damagedProperty, currIdx) => (idx === currIdx ? property : damagedProperty))
                  )
                }
                onAddInvolvedProperty={(property) =>
                  setFieldValue('damaged_properties', [...damagedProperties, property])
                }
                onDeleteInvolvedProperty={(_, idx) =>
                  setFieldValue(
                    'damaged_properties',
                    damagedProperties.filter((_, currIdx) => idx !== currIdx)
                  )
                }
                propertyLabel={propertyLabel}
                allowDelete={true}
              />
              <ErrorHelperTextFormik id="damaged_properties" />
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

PdDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  damageDetails: PropTypes.object.isRequired,
  onSubmitDetails: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  propertyLabel: PropTypes.string,
};

function BiDamagesContainer(props) {
  const { values, setFieldValue } = props;
  return (
    <DamageDialogContainer
      damageId="someone_injured"
      questionText="Was someone injured?"
      customDialogTitle="BI"
      values={values}
      setFieldValue={setFieldValue}
      CustomDetailsDialog={BiDetailsDialog}
      customDialogProps={{ injuredPartyTitle: 'Injured' }}
    />
  );
}

BiDamagesContainer.propTypes = {
  values: PropTypes.object.isRequired, // from Formik
  setFieldValue: PropTypes.func.isRequired, // from Formik
};

function PdDamagesContainer(props) {
  const { values, setFieldValue } = props;

  return (
    <DamageDialogContainer
      damageId="property_damaged"
      questionText={"Was someone else's property damaged?"}
      customDialogTitle="3rd Party Property"
      values={values}
      setFieldValue={setFieldValue}
      CustomDetailsDialog={PdDetailsDialog}
      customDialogProps={{ propertyLabel: 'Damaged Property' }}
    />
  );
}

PdDamagesContainer.propTypes = {
  values: PropTypes.object.isRequired, // from Formik
  setFieldValue: PropTypes.func.isRequired, // from Formik
};

function BiPdDamagesContainer(props) {
  const { values, setFieldValue } = props;

  return (
    <>
      <BiDamagesContainer values={values} setFieldValue={setFieldValue} />
      <PdDamagesContainer values={values} setFieldValue={setFieldValue} />
    </>
  );
}

BiPdDamagesContainer.propTypes = {
  values: PropTypes.object.isRequired, // from Formik
  setFieldValue: PropTypes.func.isRequired, // from Formik
};

export { BiDamagesContainer, BiPdDamagesContainer, PdDamagesContainer };
