import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const WCIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3022 13.668C10.2084 13.6482 10.1057 13.6186 10 13.5741V4.51748C10.3558 4.41758 10.658 4.53017 10.8538 4.66969C10.9357 4.64624 11.0083 4.63426 11.0645 4.62757C11.203 4.61109 11.3244 4.61795 11.38 4.62142L11.4045 4.62296L11.4325 4.61742L11.4432 4.61516L11.4432 4.61516C11.4904 4.60514 11.6363 4.57412 11.783 4.56956C11.9284 4.56505 12.1582 4.58152 12.3936 4.70476C12.5618 4.57629 12.772 4.49999 13 4.49999C13.5523 4.49999 14 4.9477 14 5.49999C14 6.24082 14.1185 7.52911 14.2458 8.90221L14.25 8.94794L14.25 8.94796L14.25 8.94796C14.3715 10.2591 14.5 11.6452 14.5 12.5C14.5 13.0523 14.0523 13.5 13.5 13.5C13.3546 13.5 13.2165 13.4689 13.0918 13.4131C12.8751 13.62 12.5682 13.7276 12.2492 13.6787C12.0775 13.6524 11.9228 13.5839 11.7939 13.4854C11.6257 13.6139 11.4154 13.6903 11.1873 13.6903L11.1831 13.6904L11.1702 13.6908L11.1583 13.6914C11.0182 13.7698 10.8633 13.834 10.7095 13.8611C10.5687 13.8223 10.4282 13.7506 10.3022 13.668Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M13.5 4.5H2.5C2.22386 4.5 2 4.72386 2 5V13C2 13.2761 2.22386 13.5 2.5 13.5H13.5C13.7761 13.5 14 13.2761 14 13V5C14 4.72386 13.7761 4.5 13.5 4.5Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.5 6.5V4.5C5.5 3.83696 5.76339 3.20107 6.23223 2.73223C6.70107 2.26339 7.33696 2 8 2C8.66304 2 9.29893 2.26339 9.76777 2.73223C10.2366 3.20107 10.5 3.83696 10.5 4.5V6.5"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default WCIcon;
