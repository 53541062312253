import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import { serverDateToLocal } from '~/DateTimeUtils';

import { Heading, Label, Text } from '../../../../core';
import { TextFieldFormik } from '../../../../TextFieldFormik';

import styles from './additionalPaymentVerificationDialog.module.scss';

const DuplicatePaymentsDetailsFormikFragment = ({ checkDetails, responseFieldId, showReasonInput }) => {
  const { duplicatePaymentsLevelOfVerification } = usePaymentsConfiguration(); // No payment method passed because it is not relevant

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('force_duplicate_request', true);
  }, [setFieldValue]);

  return (
    <div className={styles.specificCheckContainer}>
      <div className={styles.internalContainer}>
        <Heading variant={Heading.TYPES.H4}>{checkDetails.issue_title}</Heading>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {checkDetails.issue_dialog_details}
        </Text>
      </div>
      <div className={styles.internalContainer}>
        {checkDetails['details']?.map((payment) => (
          <InnerCard key={payment.id} className={styles.row}>
            <Grid container spacing={1}>
              <Grid item xs={3} className={styles.cell}>
                <Label variant={Label.VARIANTS.SEMI_BOLD} className={styles.header}>
                  Creation date
                </Label>
                <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                  {serverDateToLocal(payment.request_date)}
                </Text>
              </Grid>
              <Grid item xs={4} className={styles.cell}>
                <Label variant={Label.VARIANTS.SEMI_BOLD} className={styles.header}>
                  Requested by
                </Label>
                <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                  {payment.requested_by}
                </Text>
              </Grid>
              {duplicatePaymentsLevelOfVerification === 'claim' && (
                <Grid item xs={5} className={styles.cell}>
                  <Label variant={Label.VARIANTS.SEMI_BOLD} className={styles.header}>
                    Exposure
                  </Label>
                  <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                    {payment.exposure_desc}
                  </Text>
                </Grid>
              )}
            </Grid>
          </InnerCard>
        ))}
      </div>
      {showReasonInput ? (
        <div className={styles.internalContainer}>
          <TextFieldFormik id={responseFieldId} label="Duplicate override Reason" fullWidth />
        </div>
      ) : null}
    </div>
  );
};
DuplicatePaymentsDetailsFormikFragment.propTypes = {
  checkDetails: PropTypes.array.isRequired,
  responseFieldId: PropTypes.string.isRequired,
  showReasonInput: PropTypes.bool.isRequired,
};

export default DuplicatePaymentsDetailsFormikFragment;
