const COVERAGE_CONFIGURATION_LIMITS = {
  limit_per_claim: {
    desc: 'Limit per Claim',
  },
  limit_per_policy: {
    desc: 'Limit per Policy',
  },
  limit_per_policy_per_covered_entity: {
    desc: 'Policy limit per entity',
  },
  limit_per_covered_entity: {
    desc: 'Lifetime Limit',
  },
} as const;

export default COVERAGE_CONFIGURATION_LIMITS;
