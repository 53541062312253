const INDEPENDENT_MEDICAL_SPECIALISTS_DICT = {
  chiropractic: {
    desc: 'Chiropractic',
  },
  orthopedic: {
    desc: 'Orthopedic',
  },
  acupuncture: {
    desc: 'Acupuncture',
  },
  internal_medicine: {
    desc: 'Internal Medicine',
  },
  pain_management: {
    desc: 'Pain Management',
  },
  psychological: {
    desc: 'Psychological',
  },
  psychiatric: {
    desc: 'Psychiatric',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default INDEPENDENT_MEDICAL_SPECIALISTS_DICT;
