import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const PlusIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 12 12" stroke="none" {...props}>
    <path
      fill={iconColor}
      fillRule="evenodd"
      d="M6 0a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 6 0Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

PlusIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default PlusIcon;
