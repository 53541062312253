import React from 'react';
import PropTypes from 'prop-types';

import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { serverDateToLocal } from '../../../DateTimeUtils';
import { MGM_LABEL_DICT } from '../../../Types';
import CardDialog from '../../CardDialog';
import { ShowOnlyTextField } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

function MgmPolicyDetails(props) {
  const { claim } = props;
  const classes = useStyles();
  const { policy, incident, umbrella_policy, secondary_policy } = claim;

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <div className={classes.cardDivRow}>
            <CardDialog
              className={classes.CardDialog}
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Policy Details</span>
                  {incident.labeled_criteria && (
                    <Chip
                      size="small"
                      color="primary"
                      label={MGM_LABEL_DICT[claim.type][incident.labeled_criteria]?.desc}
                      className={classes.chip}
                    />
                  )}
                  {incident.is_multi_loss && (
                    <Chip
                      size="small"
                      color="primary"
                      label={`Multi Loss Incident ${incident.multi_loss_name}`}
                      className={classes.chip}
                    />
                  )}
                </div>
              }
            >
              <MgmPolicyRenderer policy={policy} />
            </CardDialog>
          </div>
        </Grid>
        {incident.is_umbrella && (
          <Grid item xs={6}>
            <div className={classes.cardDivRow}>
              <CardDialog className={classes.CardDialog} title="Umbrella Policy Details">
                <MgmPolicyRenderer policy={umbrella_policy} />
              </CardDialog>
            </div>
          </Grid>
        )}
        {secondary_policy && (
          <Grid item xs={6}>
            <div className={classes.cardDivRow}>
              <CardDialog className={classes.CardDialog} title="Excess Policy">
                <MgmPolicyRenderer policy={secondary_policy} />
              </CardDialog>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

MgmPolicyDetails.propTypes = {
  claim: PropTypes.object.isRequired,
};

function MgmPolicyRenderer({ policy }) {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <ShowOnlyTextField
          label="Effective date"
          classes={classes}
          showOnlyValueComponent={serverDateToLocal(policy.policy_effective_date)}
        />
      </Grid>
      <Grid item xs={6}>
        <ShowOnlyTextField
          label="Expiration date"
          classes={classes}
          showOnlyValueComponent={serverDateToLocal(policy.policy_expiration_date)}
        />
      </Grid>
      <Grid item xs={6}>
        <ShowOnlyTextField label="Carrier" classes={classes} showOnlyValueComponent={policy.underwriting_company} />
      </Grid>
      <Grid item xs={6}>
        <ShowOnlyTextField label="Policy Number" classes={classes} showOnlyValueComponent={policy.policy_number} />
      </Grid>
      <Grid item xs={6}>
        <ShowOnlyTextField label="Limits" classes={classes} showOnlyValueComponent={policy.policy_limit_description} />
      </Grid>
      {policy.sir && (
        <Grid item xs={6}>
          <ShowOnlyTextField label="MGM's Retention" classes={classes} showOnlyValueComponent={policy.sir} />
        </Grid>
      )}
      {policy.nv_retention && (
        <Grid item xs={6}>
          <ShowOnlyTextField label="NV Retention" classes={classes} showOnlyValueComponent={policy.nv_retention} />
        </Grid>
      )}
      {policy.ms_retention && (
        <Grid item xs={6}>
          <ShowOnlyTextField label="MS Retention" classes={classes} showOnlyValueComponent={policy.ms_retention} />
        </Grid>
      )}
      {policy.reporting_threshold && (
        <Grid item xs={6}>
          <ShowOnlyTextField
            label="Reporting Threshold"
            classes={classes}
            showOnlyValueComponent={policy.reporting_threshold}
          />
        </Grid>
      )}
      {policy.attorney_rate_cap && (
        <Grid item xs={6}>
          <ShowOnlyTextField
            label="Attorney rate cap"
            classes={classes}
            showOnlyValueComponent={<span style={{ whiteSpace: 'pre-wrap' }}>{policy.attorney_rate_cap}</span>}
          />
        </Grid>
      )}
      {policy.ded && (
        <Grid item xs={6}>
          <ShowOnlyTextField label="Deductible" classes={classes} showOnlyValueComponent={policy.ded} />
        </Grid>
      )}
    </Grid>
  );
}

MgmPolicyRenderer.propTypes = {
  policy: PropTypes.object.isRequired,
};

export { MgmPolicyDetails };
