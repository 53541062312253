export const ADVANCED_CONTACTS_SEARCH_FIELD_IDS = {
  SEARCH: 'search',
  ACCEPTED_ROLES: 'accepted_roles',
  ACCEPTED_EXPERTISE: 'accepted_expertise',
  SOCIAL_SECURITY_NUMBER: 'social_security_number',
  ZIPCODE: 'zipcode',
  PHONE_NUMBER: 'phone_number',
};
export const ALL_OPTIONS_VALUE = 'All';

export const CONTACTS_PER_PAGE = 20;
