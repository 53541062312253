import React from 'react';

import { useTableauData } from '~/components/ReportsPage/Hooks/useTableauData';
import type { ITableauData } from '~/components/ReportsPage/types';

export const TableauContext = React.createContext<ITableauData>({
  projects: [],
  workbooksMap: {},
  users: [],
  me: undefined,
  adminUser: undefined,
  isLoading: true,
  isError: false,
  reloadAll: Promise.resolve,
});

export const TableauContextProvider: React.FC = ({ children }) => {
  const { users, me, adminUser, projects, workbooksMap, isLoading, isError, reloadAll } = useTableauData();

  return (
    <TableauContext.Provider value={{ users, me, adminUser, projects, workbooksMap, isLoading, isError, reloadAll }}>
      {children}
    </TableauContext.Provider>
  );
};

export const useTableau = (): ITableauData => {
  return React.useContext(TableauContext);
};
