import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const LinkIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M7.85352 11.25H16.8535C17.0524 11.25 17.2432 11.329 17.3838 11.4697C17.5245 11.6103 17.6035 11.8011 17.6035 12C17.6035 12.1989 17.5245 12.3897 17.3838 12.5303C17.2432 12.671 17.0524 12.75 16.8535 12.75H7.85352C7.6546 12.75 7.46384 12.671 7.32319 12.5303C7.18253 12.3897 7.10352 12.1989 7.10352 12C7.10352 11.8011 7.18253 11.6103 7.32319 11.4697C7.46384 11.329 7.6546 11.25 7.85352 11.25ZM10.1035 15.75H6.35352C5.35895 15.75 4.40513 15.3549 3.70187 14.6517C2.9986 13.9484 2.60352 12.9946 2.60352 12C2.60352 11.0054 2.9986 10.0516 3.70187 9.34835C4.40513 8.64509 5.35895 8.25 6.35352 8.25H10.1035C10.3024 8.25 10.4932 8.17098 10.6338 8.03033C10.7745 7.88968 10.8535 7.69891 10.8535 7.5C10.8535 7.30109 10.7745 7.11032 10.6338 6.96967C10.4932 6.82902 10.3024 6.75 10.1035 6.75H6.35352C4.96113 6.75 3.62577 7.30312 2.6412 8.28769C1.65664 9.27226 1.10352 10.6076 1.10352 12C1.10352 13.3924 1.65664 14.7277 2.6412 15.7123C3.62577 16.6969 4.96113 17.25 6.35352 17.25H10.1035C10.3024 17.25 10.4932 17.171 10.6338 17.0303C10.7745 16.8897 10.8535 16.6989 10.8535 16.5C10.8535 16.3011 10.7745 16.1103 10.6338 15.9697C10.4932 15.829 10.3024 15.75 10.1035 15.75ZM18.3535 6.75H14.6035C14.4046 6.75 14.2138 6.82902 14.0732 6.96967C13.9325 7.11032 13.8535 7.30109 13.8535 7.5C13.8535 7.69891 13.9325 7.88968 14.0732 8.03033C14.2138 8.17098 14.4046 8.25 14.6035 8.25H18.3535C19.3481 8.25 20.3019 8.64509 21.0052 9.34835C21.7084 10.0516 22.1035 11.0054 22.1035 12C22.1035 12.9946 21.7084 13.9484 21.0052 14.6517C20.3019 15.3549 19.3481 15.75 18.3535 15.75H14.6035C14.4046 15.75 14.2138 15.829 14.0732 15.9697C13.9325 16.1103 13.8535 16.3011 13.8535 16.5C13.8535 16.6989 13.9325 16.8897 14.0732 17.0303C14.2138 17.171 14.4046 17.25 14.6035 17.25H18.3535C19.7459 17.25 21.0813 16.6969 22.0658 15.7123C23.0504 14.7277 23.6035 13.3924 23.6035 12C23.6035 10.6076 23.0504 9.27226 22.0658 8.28769C21.0813 7.30312 19.7459 6.75 18.3535 6.75Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default LinkIcon;
