import React from 'react';
import { noop } from 'lodash';

import { Text } from '~/components/core';
import { ChevronLeftIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';

interface BackButtonProps {
  text?: string;
  onClick?: () => void;
}
export const BackButton: React.FC<BackButtonProps> = ({ text = 'Back', onClick = noop }) => {
  return (
    <div className="flex w-fit cursor-pointer gap-4" onClick={onClick}>
      <ChevronLeftIcon size={20} iconColor={colors.blue[600]} className="mt-2" />
      <Text
        variant={Text.VARIANTS.SM}
        colorVariant={Text.COLOR_VARIANTS.LINK}
        weight={Text.WEIGHTS.MEDIUM}
        className="underline hover:text-blue-400"
      >
        {text}
      </Text>
    </div>
  );
};
