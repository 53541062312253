import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const ReopenNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 13.1875H29.5C29.8481 13.1875 30.1819 13.3258 30.4281 13.5719C30.6742 13.8181 30.8125 14.1519 30.8125 14.5V29.5C30.8125 29.8481 30.6742 30.1819 30.4281 30.4281C30.1819 30.6742 29.8481 30.8125 29.5 30.8125H14.5C14.1519 30.8125 13.8181 30.6742 13.5719 30.4281C13.3258 30.1819 13.1875 29.8481 13.1875 29.5V14.5C13.1875 14.1519 13.3258 13.8181 13.5719 13.5719C13.8181 13.3258 14.1519 13.1875 14.5 13.1875ZM29.5 14.3125H14.5C14.4503 14.3125 14.4026 14.3323 14.3674 14.3674C14.3323 14.4026 14.3125 14.4503 14.3125 14.5V24.4375H17.1897C17.3621 24.4372 17.5329 24.471 17.6922 24.5369C17.8514 24.6029 17.9961 24.6998 18.1178 24.8219L19.9281 26.6331C19.9633 26.668 20.0108 26.6875 20.0603 26.6875H23.9397C23.9893 26.6873 24.0369 26.6674 24.0719 26.6322L25.8822 24.8219C26.0039 24.6998 26.1486 24.6029 26.3078 24.5369C26.4671 24.471 26.6379 24.4372 26.8103 24.4375H29.6875V14.5C29.6875 14.4503 29.6677 14.4026 29.6326 14.3674C29.5974 14.3323 29.5497 14.3125 29.5 14.3125ZM14.3674 29.6326C14.4026 29.6677 14.4503 29.6875 14.5 29.6875H29.5C29.5497 29.6875 29.5974 29.6677 29.6326 29.6326C29.6677 29.5974 29.6875 29.5497 29.6875 29.5V25.5625H26.8103C26.7607 25.5627 26.7131 25.5826 26.6781 25.6178L24.8678 27.4281C24.7461 27.5502 24.6014 27.6471 24.4422 27.7131C24.2829 27.779 24.1121 27.8128 23.9397 27.8125H20.0603C19.8879 27.8128 19.7171 27.779 19.5578 27.7131C19.3986 27.6471 19.2539 27.5502 19.1322 27.4281L17.3219 25.6169C17.2867 25.582 17.2392 25.5625 17.1897 25.5625H14.3125V29.5C14.3125 29.5497 14.3323 29.5974 14.3674 29.6326Z"
      fill={iconColor}
    />
    <path
      d="M18.5001 20.5584C18.4975 20.4127 18.5516 20.2716 18.651 20.165L21.651 17.165C21.7564 17.0597 21.8994 17.0005 22.0485 17.0005C22.1975 17.0005 22.3405 17.0597 22.446 17.165L25.446 20.165C25.5453 20.2716 25.5994 20.4127 25.5968 20.5584C25.5943 20.7041 25.5352 20.8432 25.4322 20.9462C25.3291 21.0493 25.1901 21.1083 25.0444 21.1109C24.8986 21.1135 24.7576 21.0594 24.651 20.96L22.611 18.9209V25.0625C22.611 25.2117 22.5517 25.3548 22.4462 25.4602C22.3407 25.5657 22.1977 25.625 22.0485 25.625C21.8993 25.625 21.7562 25.5657 21.6507 25.4602C21.5452 25.3548 21.486 25.2117 21.486 25.0625V18.9209L19.446 20.96C19.3393 21.0594 19.1983 21.1135 19.0526 21.1109C18.9069 21.1083 18.7678 21.0493 18.6648 20.9462C18.5617 20.8432 18.5027 20.7041 18.5001 20.5584Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default ReopenNoteIcon;
