import React from 'react';

import { FsIconButton } from '~/components/core';
import colors from '~/theme/tailwind/colors';

export const SidebarDialogActionButton: React.FC<{
  onClick: () => void;
  tooltipText: string;
  icon: React.FC<{ className?: string; iconColor?: string }>;
}> = ({ onClick, tooltipText, icon }) => {
  const Icon = icon;
  return (
    <FsIconButton onClick={onClick} tooltipText={tooltipText} className="bg-white bg-opacity-10">
      <Icon className="text-white" iconColor={colors.aiV2.grey[100]} />
    </FsIconButton>
  );
};
