import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../CardDialog';
import { TextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function PoliceReportDialog(props) {
  const { dialogTitle, onSubmitDetails, damageDetails, open, onCancel } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ ...damageDetails }}
      validationSchema={Yup.object().shape({
        police_report_number: Yup.string(),
      })}
      enableReinitialize
      onSubmit={(values, formikBag) => {
        onSubmitDetails(values);
        formikBag.resetForm();
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title={dialogTitle}
            fullWidth
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="police_report_number"
                  label="Police Report Number"
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

PoliceReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  damageDetails: PropTypes.object.isRequired,
  onSubmitDetails: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PoliceReportDialog;
