import React from 'react';

import SvgIcon from '../core/SvgIcon';

const UploadIcon = (props) => (
  <SvgIcon viewBox="0 0 12 12" stroke="none" {...props}>
    <path d="M5.64645 0.146447C5.84171 -0.0488155 6.15829 -0.0488155 6.35355 0.146447L8.97855 2.77145C9.17382 2.96671 9.17382 3.28329 8.97855 3.47855C8.78329 3.67382 8.46671 3.67382 8.27145 3.47855L6 1.20711L3.72855 3.47855C3.53329 3.67382 3.21671 3.67382 3.02145 3.47855C2.82618 3.28329 2.82618 2.96671 3.02145 2.77145L5.64645 0.146447Z" />
    <path d="M6 7.45058e-09C6.27614 7.45058e-09 6.5 0.223858 6.5 0.5V7.5C6.5 7.77614 6.27614 8 6 8C5.72386 8 5.5 7.77614 5.5 7.5V0.5C5.5 0.223858 5.72386 7.45058e-09 6 7.45058e-09Z" />
    <path d="M0.5 7C0.776142 7 1 7.22386 1 7.5V11H11V7.5C11 7.22386 11.2239 7 11.5 7C11.7761 7 12 7.22386 12 7.5V11C12 11.2652 11.8946 11.5196 11.7071 11.7071C11.5196 11.8946 11.2652 12 11 12H1C0.734784 12 0.48043 11.8946 0.292893 11.7071C0.105357 11.5196 0 11.2652 0 11V7.5C0 7.22386 0.223858 7 0.5 7Z" />
  </SvgIcon>
);

export default UploadIcon;
