import React from 'react';
import { MenuItem as MuiMenuItem } from '@material-ui/core';

export interface MenuItemProps {
  className?: string;
  selected?: boolean;
  component?: React.ElementType | undefined;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  disabled?: boolean;
  children: React.ReactNode;
  value?: string | number | readonly string[] | undefined;
}

const MenuItem: React.VFC<MenuItemProps> = ({ children, ...menuItemProps }) => {
  return <MuiMenuItem {...menuItemProps}>{children}</MuiMenuItem>;
};

export default MenuItem;
