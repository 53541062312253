import React from 'react';

import { MoreDetailsDialog } from '~/components/LegalActions/Dialogs/MoreDetailsDialog';
import { PartyContent } from '~/components/LegalActions/LegalActionScreen/InfoTab/Parties/PartyContent';
import type { LegalActionPartyApiResponse } from '~/components/LegalActions/types';

export const PartyMoreDetailsDialog: React.FC<{ party: LegalActionPartyApiResponse; onClose: () => void }> = ({
  party,
  onClose,
}) => {
  return (
    <MoreDetailsDialog title="View More Details" onClose={onClose}>
      <div className="flex w-full flex-col gap-20">
        <PartyContent party={party} />
      </div>
    </MoreDetailsDialog>
  );
};
