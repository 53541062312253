import React from 'react';
import PropTypes from 'prop-types';

import Typography from '~/components/core/Atomic/Typography';

import CardDialog from '../../../CardDialog';
import { useLob } from '../../../hooks/useLob';

import {
  AutoInvolvedPartiesCardFormik,
  autoInvolvedPartiesInitialValues,
  buildAutoInvolvedParties,
  getAutoInvolvedPartiesValidationSchema,
} from './AutoParties';
import {
  buildGeneralInvolvedParties,
  GeneralInvolvedPartiesCardFormik,
  generalInvolvedPartiesInitialValues,
  getGeneralInvolvedPartiesValidationSchema,
} from './GeneralClaimParties';

const INVOLVED_PARTIES_DESCRIPTION = 'Add all involved parties of the incident.';

const getLobSpecificPartiesInitialValues = (claimType) => {
  switch (claimType) {
    case 'auto_claim':
      return autoInvolvedPartiesInitialValues;
    case 'general_claim':
      return generalInvolvedPartiesInitialValues;
    default:
      return {};
  }
};

const getLobSpecificPartiesValidationSchema = (claimType, partiesStore) => {
  switch (claimType) {
    case 'auto_claim':
      return getAutoInvolvedPartiesValidationSchema(partiesStore);
    case 'general_claim':
      return getGeneralInvolvedPartiesValidationSchema();
    default:
      return {};
  }
};

const getLobSpecificParties = ({ claimType, partiesStore, onChangePartiesStore }) => {
  switch (claimType) {
    case 'auto_claim':
      return <AutoInvolvedPartiesCardFormik partiesStore={partiesStore} onChangePartiesStore={onChangePartiesStore} />;
    case 'general_claim':
      return <GeneralInvolvedPartiesCardFormik />;
    default:
      return <></>;
  }
};

const buildLobSpecificParties = (claimType) => {
  switch (claimType) {
    case 'auto_claim':
      return buildAutoInvolvedParties;
    case 'general_claim':
      return buildGeneralInvolvedParties;
    default:
      return {};
  }
};

const InvolvedPartiesCardFormik = ({ partiesStore, onChangePartiesStore }) => {
  const { claimType } = useLob();

  return (
    <CardDialog title="Involved Parties" containerStyle={{ borderRadius: '6px' }}>
      <Typography display="block" variant="body1" color="textSecondary">
        {INVOLVED_PARTIES_DESCRIPTION}
      </Typography>
      {getLobSpecificParties({ claimType, partiesStore, onChangePartiesStore })}
    </CardDialog>
  );
};

InvolvedPartiesCardFormik.propTypes = {
  partiesStore: PropTypes.object.isRequired,
  onChangePartiesStore: PropTypes.func.isRequired,
};

export {
  buildLobSpecificParties,
  getLobSpecificPartiesInitialValues,
  getLobSpecificPartiesValidationSchema,
  InvolvedPartiesCardFormik,
};
