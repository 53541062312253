const MGM_GL_COMPENSATION_DICT = {
  slot_pay: {
    desc: 'Slot Play Voucher',
  },
  free_room: {
    desc: 'Room Comp',
  },
  folio_discount: {
    desc: 'Discount on Folio',
  },
  fb_credit: {
    desc: 'F&B credit',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default MGM_GL_COMPENSATION_DICT;
