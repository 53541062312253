import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import { useClaim } from '~/components/ClaimContainer';
import {
  CLAIM_DOC_OR_UPLOAD_OPTIONS,
  DOCUMENT_FIELD_IDS,
} from '~/components/Contacts/UpsertContact/UploadDocument/constants';
import AlertBanner from '~/components/core/AlertBanner';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import DocumentTextFieldFormik from '~/components/Documents/DocumentTextFieldFormik';
import FileDropZone from '~/components/FileDropZone';
import RadioButtonFormik from '~/components/RadioButtonFormik';

const UploadDocument = (props) => {
  const {
    documentFieldKey,
    documentType,
    documentFieldLabel,
    alertBannerNote,
    alertBannerType,
    getFullPath,
    disabled,
    showOnly,
    documentUploadPercent = 0,
  } = props;
  const { claim } = useClaim(); // might not be in claim context
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();

  if (showOnly) {
    return null;
  }

  return (
    <div className="col-span-full">
      {alertBannerNote ? (
        <div className="mb-32">
          <AlertBanner alertType={alertBannerType} note={alertBannerNote} withIcon />
        </div>
      ) : null}
      {claim ? (
        <div className="inline-flex items-center">
          <RadioButtonFormik
            id={getFullPath(DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE)}
            label="Upload File"
            optionValue={CLAIM_DOC_OR_UPLOAD_OPTIONS.UPLOAD_FILE}
            disabled={disabled}
          />
          <RadioButtonFormik
            id={getFullPath(DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE)}
            label="Claim Document"
            optionValue={CLAIM_DOC_OR_UPLOAD_OPTIONS.CLAIM_DOCUMENT}
            disabled={disabled}
          />
          <ErrorHelperTextFormik
            style={{ marginTop: 0 }}
            id={getFullPath(DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE)}
          />
        </div>
      ) : null}
      {getIn(values, getFullPath(DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE)) ===
      CLAIM_DOC_OR_UPLOAD_OPTIONS.CLAIM_DOCUMENT ? (
        <DocumentTextFieldFormik
          id={getFullPath(documentFieldKey)}
          label={documentFieldLabel}
          initialValues={{ type: documentType }}
          disabled={disabled}
        />
      ) : null}
      {getIn(values, getFullPath(DOCUMENT_FIELD_IDS.CLAIM_DOCUMENT_OR_UPLOAD_FILE)) ===
      CLAIM_DOC_OR_UPLOAD_OPTIONS.UPLOAD_FILE ? (
        <FileDropZone
          disabled={disabled}
          onFileSelect={(file) => {
            setFieldValue(getFullPath(DOCUMENT_FIELD_IDS.FILE), file);
            setFieldTouched(getFullPath(DOCUMENT_FIELD_IDS.FILE), true);
            setFieldValue(
              getFullPath(DOCUMENT_FIELD_IDS.FILE_LAST_MODIFIED),
              new Date(file.lastModified).toISOString()
            );
            setFieldValue(getFullPath(DOCUMENT_FIELD_IDS.FILE_SIZE), file.size);
            setFieldTouched(getFullPath(DOCUMENT_FIELD_IDS.FILE_SIZE), true);
          }}
          onCancelFileSelect={() => {
            setFieldValue(getFullPath(DOCUMENT_FIELD_IDS.FILE), null);
            setFieldValue(getFullPath(DOCUMENT_FIELD_IDS.FILE_SIZE), null);
            setFieldValue(getFullPath(DOCUMENT_FIELD_IDS.FILE_LAST_MODIFIED), null);
          }}
          uploadPercent={documentUploadPercent}
          file={getIn(values, getFullPath(DOCUMENT_FIELD_IDS.FILE))}
          error={
            getIn(errors, getFullPath(DOCUMENT_FIELD_IDS.FILE_SIZE)) &&
            getIn(touched, getFullPath(DOCUMENT_FIELD_IDS.FILE_SIZE))
          }
          errorMessage={getIn(errors, getFullPath(DOCUMENT_FIELD_IDS.FILE_SIZE))}
        />
      ) : null}
    </div>
  );
};

UploadDocument.propTypes = {
  documentFieldKey: PropTypes.string.isRequired,
  documentFieldLabel: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  alertBannerNote: PropTypes.string,
  alertBannerType: PropTypes.string,
  getFullPath: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
  documentUploadPercent: PropTypes.number,
};

export default UploadDocument;
