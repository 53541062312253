import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import EmptyState from '~/components/core/EmptyState';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import WithConfirm from '../../ConfirmModal';
import { ContactEntity } from '../../Contact';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsButtonWrapper, SortableTable, Text } from '../../core';
import FsButton from '../../core/FsWrappers/FsButton/FsButton';
import { PencilIcon, TrashIcon } from '../../icons';
import InlineIconButton from '../../InlineIconButton';

import { useStyles } from '../../../assets/styles';

const ExternalClaimNumbersTable = ({ rows = [], onAddClick = noop, onEditClick = noop, onDeleteClick = noop }) => {
  const classes = useStyles();

  const columns = [
    {
      id: 'external_claim_number',
      numeric: false,
      label: 'External Claim Number',
    },
    {
      id: 'external_contact',
      numeric: false,
      label: 'External Contact',
      specialCell: (record) =>
        record?.external_contact_full_name && (
          <ContactEntity
            contactId={record.external_contact_id}
            contactDisplayName={record.external_contact_full_name}
          />
        ),
    },
    {
      id: 'description',
      numeric: false,
      label: 'Description',
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      specialCell: (record) => (
        <div className="flex items-center justify-start">
          <PermissionsButtonWrapper verb={PERMISSION_VERBS.WRITE} action={PERMISSION_ACTIONS.EXTERNAL_CLAIM_NUMBER}>
            <InlineIconButton
              icon={PencilIcon}
              tooltipTitle="Edit"
              className={classes.hoverableNonFilledIcon}
              onClick={() => onEditClick(record)}
              ignorePermissions
            />
          </PermissionsButtonWrapper>
          <span className="p-4" />
          <PermissionsButtonWrapper verb={PERMISSION_VERBS.WRITE} action={PERMISSION_ACTIONS.EXTERNAL_CLAIM_NUMBER}>
            <WithConfirm
              centerDialog
              newSecondaryButton
              title={`Delete ${record.external_claim_number}?`}
              contentText="Are you sure you want to delete this external claim number?"
              primaryButtonName="Yes, Delete"
              shouldCloseOnPrimary={true}
            >
              <InlineIconButton
                icon={TrashIcon}
                tooltipTitle="Delete"
                className={classes.hoverableNonFilledIcon}
                onClick={async () => {
                  await onDeleteClick(record.id);
                }}
                ignorePermissions
              />
            </WithConfirm>
          </PermissionsButtonWrapper>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between">
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
          {`${rows?.length > 0 ? rows?.length : ''} External Claim Number${rows?.length > 1 ? 's' : ''}`}
        </Text>
        <PermissionsButtonWrapper verb={PERMISSION_VERBS.WRITE} action={PERMISSION_ACTIONS.EXTERNAL_CLAIM_NUMBER}>
          <FsButton color={FsButton.COLORS.primary} onClick={() => onAddClick()} ignorePermissions>
            <AddIcon className={classes.rightButtonIcon} />
            Add External Claim Number
          </FsButton>
        </PermissionsButtonWrapper>
      </div>
      <SortableTable
        rows={rows}
        columns={columns}
        order="desc"
        stickyHeader
        emptyStateComponent={<EmptyState subtitle="No External Claim Numbers Exists" />}
      />
    </>
  );
};

ExternalClaimNumbersTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default ExternalClaimNumbersTable;
