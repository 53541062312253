import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { FieldArray, Formik, getIn, useFormikContext } from 'formik';
import _, { capitalize, isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { compareDatesStrings, isoDateToUs } from '../../DateTimeUtils';
import { CONFIGURATION_FEATURES_NAMES, CPT_CODES_PLACE_OF_SERVICES } from '../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../Utils';
import { useFetchClaim } from '../../Utils/ClaimUtils';
import AutocompleteFormik from '../AutocompleteFormik';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ClaimLink from '../ClaimLink';
import { localeDetails } from '../CmsMain/globals';
import { getAllSearchableContactRoles } from '../communications/ContactUtils';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { CurrencyFormatterContextProvider, useCurrencyFormatter } from '../CurrencyFormatterContext';
import DocumentTextFieldFormik from '../Documents/DocumentTextFieldFormik';
import { useCms } from '../hooks/useCms';
import PencilIcon from '../icons/PencilIcon';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';
import {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
} from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import PaymentRequestContainer from './PaymentRequestContainer/PaymentRequestContainer';

import { useStyles } from '../../assets/styles';

const actionValues = {
  action: 'deny',
  reason: '',
  paid_on: '',
  check_number: '',
  eob_extra_explanation: '',
};

const initialValues = {
  record_document_id: '',
  payment_contact_id: '',
  service: '',
  medical_treatments: [],
  request_currency: 'USD',
  ...actionValues,
};

const getInitialValues = (managedPipPayment, draftValues = {}) => {
  if (Object.keys(draftValues).length > 0) {
    return draftValues;
  }

  return managedPipPayment ? { ...initialValues, ...managedPipPayment, ...actionValues } : initialValues;
};

function MedicalBillAdjudicationDialog({
  title,
  claimId,
  managedPipPayment,
  onSubmit,
  onClose,
  viewOnly,
  medicalBillPdfUrl,
  onSaveDraft,
  draftValues,
  exposure,
}) {
  const [duplicatePaymentWarning, setDuplicatePaymentWarning] = useState({});
  const isStateRequired = localeDetails.locale.region === 'US';
  const { claim } = useClaim();

  return (
    <>
      <Formik
        initialValues={{
          ...getInitialValues(managedPipPayment, draftValues),
          request_currency: claim?.policy?.policy_currency || localeDetails.currency,
        }}
        validationSchema={Yup.object().shape({
          record_document_id: Yup.number().required('Required'),
          payment_contact_id: Yup.number().required('Required'),
          payment_contact: Yup.object().test(
            'is-valid',
            `Provider must contain address1, city, postal code and ${isStateRequired ? 'state' : 'country'}`,
            (value) =>
              !value ||
              (value.street_address1 && value.city && (isStateRequired ? value.state : value.country) && value.zipcode)
          ),
          action: Yup.string().required('Required'),
          medical_treatments: Yup.array().when('action', {
            is: 'pay',
            then: Yup.array().min(1, 'At least one medical treatment required'),
          }),
          reason: Yup.string().when('action', {
            is: 'deny',
            then: Yup.string().required('Required'),
          }),
          paid_on: Yup.date().when('reason', {
            is: 'already_paid',
            then: Yup.date().required('Required'),
          }),
          check_number: Yup.string().when('reason', {
            is: 'already_paid',
            then: Yup.string().required('Required'),
          }),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await onSubmit({
              ...values,
              start_date_of_service: values.medical_treatments.sort((mt1, mt2) =>
                compareDatesStrings(mt1.start_date_of_service, mt2.start_date_of_service)
              )[0]?.start_date_of_service,
              end_date_of_service: values.medical_treatments.sort((mt1, mt2) =>
                compareDatesStrings(mt1.end_date_of_service, mt2.end_date_of_service)
              )[values.medical_treatments.length - 1]?.end_date_of_service,
              amount_submitted: _.sum(values.medical_treatments.map((mt) => mt.amount_submitted)),
              amount_considered: _.sum(values.medical_treatments.map((mt) => mt.amount_considered)),
            });
          } catch (error) {
            reportAxiosError(error);
            setSubmitting(false);
          }
        }}
      >
        {({ values }) => (
          <CurrencyFormatterContextProvider currency={values.request_currency}>
            <MedicalBillAdjudicationDialogInner
              title={title}
              claimId={claimId}
              onClose={onClose}
              viewOnly={viewOnly}
              medicalBillPdfUrl={medicalBillPdfUrl}
              onSubmit={onSubmit}
              onSaveDraft={onSaveDraft}
              draftValues={draftValues}
              exposure={exposure}
            />
            {duplicatePaymentWarning.claim_id && (
              <DuplicatePaymentWarning
                duplicatePaymentWarning={duplicatePaymentWarning}
                onCancel={() => setDuplicatePaymentWarning('')}
                onSubmit={() => {}}
              />
            )}
          </CurrencyFormatterContextProvider>
        )}
      </Formik>
    </>
  );
}

MedicalBillAdjudicationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  claimId: PropTypes.number.isRequired,
  managedPipPayment: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  medicalBillPdfUrl: PropTypes.string.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  draftValues: PropTypes.object,
  exposure: PropTypes.object.isRequired,
};

function MedicalBillAdjudicationDialogInner(props) {
  const { title, claimId, onClose, viewOnly, onSaveDraft } = props;
  const classes = useStyles();
  const [claim, isLoadingClaim, isClaimError] = useFetchClaim(claimId);
  const { values, setFieldValue, isSubmitting, handleSubmit } = useFormikContext();
  const { userOrganization } = useCms();
  const [medicalBillUrl, setMedicalBillUrl] = React.useState();
  const recordDocumentId = values.record_document_id;

  const [showNewMedicalTreatment, setShowNewMedicalTreatment] = useState(false);
  const [medicalTreatmentToEdit, setMedicalTreatmentToEdit] = useState(undefined);
  const { organizationContactRolesDict } = useOrganization();
  const acceptedRoles = getAllSearchableContactRoles(organizationContactRolesDict);

  const isDraftFeatureEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MEDICAL_BILL_ADJUDICATION_DRAFT
  );

  const currAction = values['action'];

  React.useEffect(() => {
    setMedicalBillUrl(`/api/v1/claims/${claim.id}/documents/${recordDocumentId}`);
  }, [setMedicalBillUrl, recordDocumentId, claim.id]);

  const lastMedicalTreatment =
    values['medical_treatments'] &&
    values['medical_treatments'].length > 0 &&
    values['medical_treatments'].slice(-1)[0];

  return (
    <CardDialog title={title} isDialog onClose={onClose} maxWidth="xl" fullWidth>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <object key={medicalBillUrl} data={medicalBillUrl} style={{ height: '95vh', width: '100%' }}>
            Medical Bill
          </object>
        </Grid>
        <Grid item md={6}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DocumentTextFieldFormik id="record_document_id" label="Record" />
            </Grid>
            <Grid item xs={6}>
              {isArray(userOrganization.configuration?.supported_currencies) &&
              userOrganization.configuration.supported_currencies.length > 1 ? (
                <div>
                  <TextFieldFormik
                    id="request_currency"
                    label="Currency"
                    className={classes.textField}
                    fullWidth
                    select
                  >
                    {userOrganization.configuration?.supported_currencies.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </div>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <ContactTextFieldFormik
                id="payment_contact"
                label="Provider"
                className={classes.textField}
                acceptedRoles={acceptedRoles}
                outOfContactsContextSearch
                fixedSearchResults
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik id="service" label="Provider Type" fullWidth />
            </Grid>
            <div className={classes.inputContainer} style={{ marginTop: '12px' }}>
              <Button
                color="primary"
                onClick={() => setShowNewMedicalTreatment(true)}
                disabled={!values.payment_contact || showNewMedicalTreatment || medicalTreatmentToEdit}
              >
                <AddIcon className={classes.leftButtonIcon} />
                Add New
              </Button>
            </div>
            <>
              {showNewMedicalTreatment && (
                <div style={{ marginTop: '12px' }}>
                  <MedicalTreatmentDialog
                    title="New Medical Treatment"
                    onClose={() => setShowNewMedicalTreatment(false)}
                    onSubmit={(vals) => {
                      setFieldValue('medical_treatments', [
                        ...(values?.medical_treatments || []),
                        { id: uuidv4(), ...vals },
                      ]);
                      setShowNewMedicalTreatment(false);
                    }}
                    initialValuesOverride={
                      lastMedicalTreatment
                        ? {
                            start_date_of_service: lastMedicalTreatment.start_date_of_service,
                            end_date_of_service: lastMedicalTreatment.end_date_of_service,
                            place_of_service: lastMedicalTreatment.place_of_service,
                          }
                        : undefined
                    }
                  />
                </div>
              )}
              {medicalTreatmentToEdit && (
                <div style={{ marginTop: '12px' }}>
                  <MedicalTreatmentDialog
                    title="Edit Medical Treatment"
                    medicalTreatment={values['medical_treatments'].find((x) => x.id === medicalTreatmentToEdit.id)}
                    onClose={() => setMedicalTreatmentToEdit(undefined)}
                    onSubmit={(vals) => {
                      setFieldValue('medical_treatments', [
                        ...values['medical_treatments'].map((x) =>
                          x.id === medicalTreatmentToEdit.id ? { id: x.id, ...vals } : x
                        ),
                      ]);
                      setMedicalTreatmentToEdit(undefined);
                    }}
                  />
                </div>
              )}
              <div style={{ marginTop: '24px' }}>
                <MedicalTreatmentsTable
                  medicalTreatments={values['medical_treatments']}
                  actionSpecialCellFunc={(medicalTreatment) => (
                    <>
                      {!viewOnly && (
                        <IconButton
                          onClick={() => setMedicalTreatmentToEdit(medicalTreatment)}
                          disabled={showNewMedicalTreatment || medicalTreatmentToEdit}
                        >
                          <PencilIcon />
                        </IconButton>
                      )}
                    </>
                  )}
                />
                <ErrorHelperTextFormik id="medical_treatments" />
              </div>
            </>
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik id="action" label="Action" fullWidth select>
              {['pay', 'deny', 'defer'].map((action) => (
                <MenuItem key="action" value={action} onClick={() => setFieldValue('reason', '')}>
                  {capitalize(action)}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>

          {currAction && (
            <>
              {currAction === 'deny' && (
                <>
                  <Grid item xs={12}>
                    <TextFieldFormik id="reason" label="Reason" fullWidth />
                  </Grid>
                </>
              )}
              <Grid item xs={6} />
              <Grid item xs={12}>
                <TextFieldFormik id="eob_extra_explanation" label="EOB Text" multiline fullWidth />
              </Grid>
              <div className={classes.buttonsContainer}>
                {isDraftFeatureEnabled && (
                  <Button
                    className={classes.leftButtonDialog}
                    variant="contained"
                    onClick={() => onSaveDraft(values)}
                    disabled={isSubmitting || isLoadingClaim || isClaimError}
                    color="secondary"
                  >
                    Save As Draft
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isSubmitting || isLoadingClaim || isClaimError}
                  color="primary"
                >
                  {currAction === 'pay' ? 'Proceed to payment' : 'Set'}
                </Button>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </CardDialog>
  );
}

MedicalBillAdjudicationDialogInner.propTypes = {
  title: PropTypes.string.isRequired,
  claimId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  medicalBillPdfUrl: PropTypes.string.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  draftValues: PropTypes.object,
  exposure: PropTypes.object,
};

function MedicalTreatmentsTable({ medicalTreatments, actionSpecialCellFunc }) {
  const { currencyFormatter } = useCurrencyFormatter();
  let columns = [
    {
      id: 'dates_of_service',
      label: 'Dates of Service',
      specialCell: (pipManagedPayment) =>
        pipManagedPayment.start_date_of_service &&
        pipManagedPayment.end_date_of_service &&
        `${isoDateToUs(pipManagedPayment.start_date_of_service)} - ${isoDateToUs(
          pipManagedPayment.end_date_of_service
        )}`,
    },
    {
      id: 'amount_submitted',
      label: 'Amount Billed',
      specialCell: (medicalTreatment) => currencyFormatter.format(medicalTreatment.amount_submitted),
    },
    { id: 'units', label: 'Units' },
    { id: 'transaction_type', label: 'ICD 10' },
    { id: 'cpt_code', label: 'CPT Code' },
    {
      id: 'modifier',
      label: 'Modifiers',
      specialCell: ({ modifier, additional_modifiers }) =>
        additional_modifiers
          .map((additionalModifier) => additionalModifier.modifier)
          .concat([modifier])
          .filter((value) => !!value)
          .join(' ,'),
    },
    {
      id: 'place_of_service',
      label: 'Place of Service',
      specialCell: (medicalTreatment) => CPT_CODES_PLACE_OF_SERVICES[medicalTreatment.place_of_service]?.desc,
    },
    {
      id: 'amount_allowed',
      label: 'Usual Customary Amount',
      specialCell: (medicalTreatment) => currencyFormatter.format(medicalTreatment.amount_allowed),
    },
    {
      id: 'amount_saved',
      label: 'Amount Saved',
      specialCell: (medicalTreatment) => currencyFormatter.format(medicalTreatment.amount_saved),
    },
    {
      id: 'amount_considered',
      label: 'Amount to Pay',
      specialCell: (medicalTreatment) => currencyFormatter.format(medicalTreatment.amount_considered),
    },
  ];

  if (actionSpecialCellFunc) {
    columns.push({ id: 'action', label: '', specialCell: actionSpecialCellFunc });
  }

  return <PlainTable columns={columns} rows={medicalTreatments} />;
}

MedicalTreatmentsTable.propTypes = {
  medicalTreatments: PropTypes.array,
  actionSpecialCellFunc: PropTypes.func,
};

function MedicalTreatmentDialog({ title, medicalTreatment, onSubmit, onClose, initialValuesOverride }) {
  const initialValues = {
    start_date_of_service: '',
    end_date_of_service: null,
    cpt_code: '',
    modifier: '',
    additional_modifiers: [],
    amount_submitted: '',
    units: 1,
    amount_allowed: 0,
    adjustment_reason: '',
    amount_allowed_edited_manually: false,
    place_of_service: '',
    was_calculated: false,
    explanation: [],
    record_document_id: '',
    amount_considered: '',
  };

  return (
    <Formik
      initialValues={
        medicalTreatment ? { ...initialValues, ...medicalTreatment } : { ...initialValues, ...initialValuesOverride }
      }
      validationSchema={Yup.object().shape({
        start_date_of_service: Yup.date().required('Required'),
        end_date_of_service: Yup.date()
          .required('Required')
          .when('start_date_of_service', {
            is: (val) => !!val,
            then: Yup.date().min(Yup.ref('start_date_of_service'), "Can't be before the start date"),
          }),
        cpt_code: Yup.string().required('Required').max(7, 'Limited to 7 characters'),
        amount_submitted: Yup.number().required('Required'),
        adjustment_reason: Yup.string().required('Required'),
        amount_considered: Yup.number().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      <MedicalTreatmentDialogInner title={title} onClose={onClose} />
    </Formik>
  );
}

MedicalTreatmentDialog.propTypes = {
  title: PropTypes.string.isRequired,
  medicalTreatment: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValuesOverride: PropTypes.object,
};

function DuplicatePaymentWarning({ duplicatePaymentWarning, onSubmit, onCancel }) {
  const classes = useStyles();

  const { claim_id, display_id, payment_date } = duplicatePaymentWarning;

  const warning = `This payment has similar parameters to a payment that has been issued in claim ${display_id} on ${payment_date}
  to the same payee for the same amount of money and date range.
  Are you sure you want to proceed and complete the payment?`;

  return (
    <CardDialog open={duplicatePaymentWarning} isDialog title="This seems like a duplicate payment" subheader={warning}>
      <ClaimLink claimId={claim_id} linkText="Similar Payment Claim" openInNewTab={true} />
      <div className={classes.buttonsContainer}>
        <CancelButton onClick={onCancel} content="No" />
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Yes
        </Button>
      </div>
    </CardDialog>
  );
}

DuplicatePaymentWarning.propTypes = {
  duplicatePaymentWarning: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function MedicalTreatmentDialogInner({ title, onClose }) {
  const { values, setFieldValue, isSubmitting, handleSubmit, setFieldTouched, errors } = useFormikContext();
  const [isCalculating, setIsCalculating] = useState(false);
  const classes = useStyles();

  // NOTE: the below code has multiple downstream calculated and hence some field changes might lead to a cascading effect
  // NOTE: when first loading an existing treatment, we don't want to nullify calculations as nothing changed
  // NOTE: for every useEffect, we eject if didMountRef.current === false and the last useEffect sets it to true
  const didMountRef = React.useRef(false);

  const {
    cpt_code: cptCode,
    modifier,
    additional_modifiers: additionalModifiers,
    amount_allowed_edited_manually: amountAllowedEditedManually,
  } = values;

  const isCptCodeAvailable = cptCode && cptCode.length >= 5;
  const {
    isLoading: isLoadingCptCodeModifiers,
    isError: isErrorCptCodeModifiers,
    data: modifiersEntriesByCptCode,
  } = useDataFetcher(
    '/api/v1/wc_claims/medical_bill_adjudication/cpt_code_modifiers',
    isCptCodeAvailable ? { params: { cpt_code: cptCode } } : undefined
  );
  const {
    isLoading,
    isError,
    data: modifiersEntries,
  } = useDataFetcher('/api/v1/wc_claims/medical_bill_adjudication/cpt_code_modifiers');

  const explanation = values.explanation;

  async function calculateAmountAllowed(startDateOfService, endDateOfService, cptCode, modifier, additionalModifiers) {
    try {
      const params = {
        start_date_of_service: startDateOfService,
        end_date_of_service: endDateOfService,
        cpt_code: cptCode,
        modifier,
      };

      additionalModifiers.forEach(({ modifier }, idx) => (params[`modifier_${idx + 2}`] = modifier));

      setIsCalculating(true);
      const { data } = await axios.get('/api/v1/wc_claims/medical_bill_adjudication/medical_treatment_amount_allowed', {
        params: { ...params },
      });
      const allowedAmount = data.amount_allowed || 0;
      const explanation = data.explanation || [];
      setFieldValue('was_calculated', true);
      setFieldValue('amount_allowed', allowedAmount);
      setFieldValue('amount_saved', Math.max(values['amount_submitted'] - allowedAmount, 0));
      setFieldValue('amount_allowed_edited_manually', false);
      setFieldValue('explanation', explanation);
      setFieldTouched('amount_allowed', false);
    } catch (exception) {
      reportAxiosError(exception);
    } finally {
      setIsCalculating(false);
    }
  }

  useEffect(() => {
    if (!didMountRef.current) {
      return;
    }

    setFieldValue('modifier', '');
  }, [cptCode, setFieldValue]);

  useEffect(() => {
    if (!didMountRef.current) {
      return;
    }

    setFieldValue('explanation', []);
  }, [cptCode, modifier, setFieldValue]);

  useEffect(() => {
    if (!didMountRef.current) {
      return;
    }

    const newAmountSaved = Math.max(values.amount_submitted - values.amount_allowed, 0);
    setFieldValue('amount_allowed', values.amount_allowed);
    setFieldValue('amount_saved', newAmountSaved);
  }, [values.amount_allowed, values.amount_submitted, setFieldValue]);

  useEffect(() => {
    if (!didMountRef.current) {
      return;
    }

    if (values.start_date_of_service && !errors.start_date_of_service) {
      setFieldValue('end_date_of_service', values.start_date_of_service);
    }
  }, [values.start_date_of_service, errors.start_date_of_service, setFieldValue]);

  // NOTE: must be the last useEffect to mark everything finished mounting (see comment regarding didMountRef)
  useEffect(() => {
    didMountRef.current = true;
  }, []);

  const ADDITIONAL_MODIFIERS_FIELD_NAME = 'additional_modifiers';

  return (
    <CardDialog title={title} maxWidth="sm" outlinedCard>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <DatePickerTextFieldFormik
            id="start_date_of_service"
            label="Start Date of Service"
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <DatePickerTextFieldFormik
            id="end_date_of_service"
            label="End Date of Service"
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <MonetaryValueTextFieldFormik
            id="amount_submitted"
            label="Amount Billed"
            allowNegative={false}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormik id="units" label="Units" className={classes.textField} fullWidth />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteFormik
            id="place_of_service"
            label="Place of Service"
            options={Object.keys(CPT_CODES_PLACE_OF_SERVICES)}
            getOptionLabel={(option) =>
              `${CPT_CODES_PLACE_OF_SERVICES[option]['code']} - ${CPT_CODES_PLACE_OF_SERVICES[option]['desc']}`
            }
            sortAlphabetic
            autoSelect
            autoHighlight
            autoComplete
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormik id="transaction_type" label="ICD 10" className={classes.textField} fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormik id="cpt_code" label="CPT Code" fullWidth />
          {/* <CptAutoCompleteFormik disabled={!areDatesOfServiceAvailable} /> */}
        </Grid>
        {/* we are waiting for the cpt code modifiers to load to prevent a react warning when loading draft */}
        {modifiersEntriesByCptCode && (
          <Grid item xs={4}>
            <CptCodeModifierFormik
              id="modifier"
              modifiersEntries={modifiersEntriesByCptCode}
              disabled={!isCptCodeAvailable}
              isLoading={isLoadingCptCodeModifiers}
              isError={isErrorCptCodeModifiers}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FieldArray
            name={ADDITIONAL_MODIFIERS_FIELD_NAME}
            render={({ remove, push }) => (
              <>
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={
                    values[ADDITIONAL_MODIFIERS_FIELD_NAME].length < 3 ? (
                      <Button color="primary" onClick={() => push({ modifier: '', id: `new_${uuidv4()}` })}>
                        <AddIcon />
                        Add Modifier
                      </Button>
                    ) : null
                  }
                  label="Additional Modifiers"
                />
                {getIn(values, ADDITIONAL_MODIFIERS_FIELD_NAME).map((item, idx) => {
                  return (
                    <React.Fragment key={item.id}>
                      <Grid container spacing={1}>
                        <Grid item xs={11}>
                          <CptCodeModifierFormik
                            id={`${ADDITIONAL_MODIFIERS_FIELD_NAME}.${idx}.modifier`}
                            modifiersEntries={modifiersEntries}
                            isLoading={isLoading}
                            isError={isError}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton onClick={() => remove(idx)}>
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <MonetaryValueTextFieldFormik
            id="amount_allowed"
            label="Usual Customary Amount"
            className={classes.textField}
            fullWidth
            disabled={!values.was_calculated}
            showOnly={!amountAllowedEditedManually}
            onEdit={() => setFieldValue('amount_allowed_edited_manually', true)}
          />
          <ErrorHelperTextFormik id="amount_allowed" />
        </Grid>
        <Grid item xs={6}>
          <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
            {explanation.length > 0 && (
              <Tooltip
                title={
                  <>
                    {explanation.map(({ title, text }) => (
                      <Typography display="block" key={title} variant="caption">
                        {text}
                      </Typography>
                    ))}
                  </>
                }
              >
                <InfoIcon size={20} />
              </Tooltip>
            )}
            <Button
              onClick={() =>
                calculateAmountAllowed(
                  values.start_date_of_service,
                  values.end_date_of_service,
                  cptCode,
                  modifier,
                  additionalModifiers
                )
              }
              disabled={!cptCode || isCalculating || !values.start_date_of_service || !values.end_date_of_service}
            >
              Calculate
            </Button>
          </div>
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormik id="adjustment_reason" label="Adjustment Reason" className={classes.textField} fullWidth />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <MonetaryValueTextFieldFormik
            id="amount_considered"
            label="Amount to Pay"
            allowNegative={false}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <div className={classes.buttonsContainer}>
        <CancelButton disabled={isSubmitting} onClick={onClose} />
        <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
          Save
        </Button>
      </div>
    </CardDialog>
  );
}

MedicalTreatmentDialogInner.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

function PaymentDialog({ claim, exposure, amount, provider, reason, onSubmit, onClose }) {
  const medicalExposure = exposure;

  return (
    <>
      {medicalExposure && (
        <PaymentRequestContainer
          claim={claim}
          exposure={medicalExposure}
          payableWithReserve={medicalExposure.indemnity}
          payableType="indemnity"
          cardDialogProps={{
            isDialog: true,
            maxWidth: 'sm',
            fullWidth: true,
          }}
          onUpdate={onSubmit}
          onClose={onClose}
          overrideInitialValues={{
            amount,
            should_include_provider: true,
            primary_payee_id: provider.id,
            primary_payee: provider,
            payee: [],
            primary_payee_full_name: provider.full_name,
            mail_to_contact_id: provider.id,
            mail_to_contact: provider,
            mail_to_contact_full_name: provider.full_name,
            payment_reason: reason,
          }}
          overridePaymentRequestStore={[
            {
              type: 'contact_search',
              contact: provider,
              contact_id: provider.id,
              contact_full_name: provider.full_name,
              payee_type: '',
              field_name: 'payee',
              label: 'Payee',
            },
          ]}
        />
      )}
      {!medicalExposure && (
        <CardDialog isDialog title="Error" onClose={onClose}>
          Claim exposures do not meet the requirements.
        </CardDialog>
      )}
    </>
  );
}

PaymentDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  provider: PropTypes.object.isRequired,
  reason: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function CptAutoCompleteFormik({ disabled }) {
  const [possibleOptionsDict, setPossibleOptionsDict] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext();

  function getOptionLabel(option) {
    if (!option || !possibleOptionsDict[option]) {
      return '';
    }

    const cptEntry = possibleOptionsDict[option];

    let label = cptEntry.cpt_code;
    if (cptEntry.modifier) {
      label += `-${cptEntry.modifier}`;
    }

    label += ` - ${cptEntry.description}`;

    return label;
  }

  function filterOption(options) {
    return options;
  }

  async function handleInputChange(_event, newVal) {
    setFieldValue('cpt_code', newVal);
    setInputValue(newVal);
    if (!newVal || newVal.length < 5) {
      setPossibleOptionsDict({});
      return;
    }

    setIsLoading(true);

    try {
      if (values.start_date_of_service && values.end_date_of_service) {
        const results = await axios.get('/api/v1/wc_claims/medical_bill_adjudication/cpt_codes_search', {
          params: {
            search: newVal,
            start_date_of_service: values.start_date_of_service,
            end_date_of_service: values.end_date_of_service,
          },
        });
        setPossibleOptionsDict(results.data);
      }
    } catch (error) {
      reportAxiosError(error);
    }

    setIsLoading(false);
  }

  return (
    <Autocomplete
      id="cpt_code"
      options={Object.keys(possibleOptionsDict)}
      renderOption={getOptionLabel}
      loading={isLoading}
      inputValue={inputValue}
      filterOptions={filterOption}
      onInputChange={handleInputChange}
      onChange={(e, newVal) => {
        if (!newVal) {
          setFieldValue('cpt_code', '');
          return;
        }
        const cptCodeEntry = possibleOptionsDict[newVal];
        setFieldValue('cpt_code', cptCodeEntry.cpt_code);
        setFieldValue('modifier', cptCodeEntry.modifier);
      }}
      autoSelect
      autoHighlight
      autoComplete
      renderInput={(params) => (
        <TextField
          label="CPT Code"
          {...params}
          InputProps={{
            ...params.InputProps,
            // margin: 'dense' // to match other TextField components
          }}
          inputProps={{
            ...params.inputProps,
            value: getIn(values, 'cpt_code'),
          }}
          disabled={disabled}
          error={getIn(errors, 'cpt_code') && getIn(touched, 'cpt_code')}
          helperText={getIn(errors, 'cpt_code') && getIn(touched, 'cpt_code') && getIn(errors, 'cpt_code')}
          onBlur={() => setFieldTouched('cpt_code', true)}
        />
      )}
      clearOnBlur={false}
    />
  );
}

CptAutoCompleteFormik.propTypes = {
  disabled: PropTypes.bool,
};

function CptCodeModifierFormik({ id, modifiersEntries, disabled, isLoading, isError }) {
  const modifierToModifierEntryMap = _.keyBy(modifiersEntries, 'modifier');
  const modifiersList = !modifiersEntries ? [] : _.uniq(modifiersEntries.map((entry) => entry.modifier));

  return (
    <AutocompleteFormik
      id={id}
      label="Modifier"
      disabled={disabled || isLoading || isError}
      options={modifiersList}
      getOptionLabel={(option) => {
        const modifierEntry = modifierToModifierEntryMap[option];
        return `${modifierEntry?.modifier}${modifierEntry?.description ? ` - ${modifierEntry?.description}` : ''}`;
      }}
      sortAlphabetic
      fullWidth
      autoSelect
      autoHighlight
      autoComplete
    />
  );
}

CptCodeModifierFormik.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  modifiersEntries: PropTypes.array,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default MedicalBillAdjudicationDialog;
