import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect, getIn } from 'formik';

import { ErrorHelperTextFormik } from './core/Formik/ErrorHelperTextFormik';

const styles = (theme) => ({
  toggleContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(1)}px 0`,
  },
});

const ToggleButtonGroupFormik = withStyles(styles)(
  connect((props) => {
    const { classes, formik, id, children, showOnly, size, onChange } = props;
    const { values, setFieldValue } = formik;

    if (showOnly) {
      const currValue = getIn(values, id);
      let node;
      React.Children.forEach(children, (toggleButton) => {
        if (toggleButton.props.value === currValue) {
          node = toggleButton.props.children;
        }
      });
      return node;
    }

    const handleChange = (event, value) => {
      setFieldValue(id, value);
      if (onChange) {
        onChange(value);
      }
    };

    return (
      <>
        <div className={classes.toggleContainer}>
          <ToggleButtonGroup value={getIn(values, id)} exclusive onChange={handleChange} size={size}>
            {children}
          </ToggleButtonGroup>
        </div>
        <ErrorHelperTextFormik id={id} />
      </>
    );
  })
);

ToggleButtonGroupFormik.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  showOnly: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default ToggleButtonGroupFormik;
