import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const SearchNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.875 13.75C16.94 13.75 13.75 16.94 13.75 20.875C13.75 24.81 16.94 28 20.875 28C24.81 28 28 24.81 28 20.875C28 16.94 24.81 13.75 20.875 13.75ZM12.25 20.875C12.25 16.1115 16.1115 12.25 20.875 12.25C25.6385 12.25 29.5 16.1115 29.5 20.875C29.5 25.6385 25.6385 29.5 20.875 29.5C16.1115 29.5 12.25 25.6385 12.25 20.875Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9134 25.9134C26.2063 25.6205 26.6812 25.6205 26.9741 25.9134L31.5303 30.4697C31.8232 30.7626 31.8232 31.2374 31.5303 31.5303C31.2374 31.8232 30.7626 31.8232 30.4697 31.5303L25.9134 26.9741C25.6205 26.6812 25.6205 26.2063 25.9134 25.9134Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default SearchNoteIcon;
