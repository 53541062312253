import React from 'react';
import { getIn, useFormikContext } from 'formik';

import AlertBanner from '~/components/core/AlertBanner';

import { LICENSE_EXPIRATION_STATUS } from '../consts';
import { getLicenseStatus, getStateName, OTHERS_FIELD_ID, RESIDENCY_FIELD_ID } from '../utils';

export const LicensesExpirationAlertBanner = () => {
  const { values } = useFormikContext();

  const expiringLicenses = [
    ...getIn(values, RESIDENCY_FIELD_ID)
      .filter((license) => getLicenseStatus(license.expiration_date) !== LICENSE_EXPIRATION_STATUS.VALID)
      .map((license) => getStateName(license.state)),
    ...getIn(values, OTHERS_FIELD_ID)
      .filter((license) => getLicenseStatus(license.expiration_date) !== LICENSE_EXPIRATION_STATUS.VALID)
      .map((license) => getStateName(license.state)),
  ];
  const getWarningText = () => {
    if (expiringLicenses.length === 0) return null;

    return `The following licenses are expired or about to expire soon: ${expiringLicenses.join(', ')}.`;
  };

  const warningText = getWarningText();

  return warningText ? (
    <AlertBanner withIcon={true} title="Please note" note={warningText} alertType={AlertBanner.ALERT_TYPES.WARNING} />
  ) : null;
};
