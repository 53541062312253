import _ from 'lodash';

import { CONFIGURATION_FEATURES_NAMES } from '../../../../Types';
import { isFeatureEnabled } from '../../../../Utils';

import { ORG_UNIT_TYPE, UNIT_FIELDS } from './consts';

export const isOrgUnitsConfigurationFeatureEnabled = ({ organization }) => {
  return isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.ORG_HIERARCHY_CONFIGURATION);
};

export const isOrgHierarchyWorkflowsFeatureEnabled = ({ organization }) => {
  return isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.ORG_HIERARCHY_WORKFLOWS);
};

export const buildUserToUnitDict = ({ units = [] }) =>
  units.reduce((acc, unit) => {
    unit.members.forEach((member) => {
      acc[member.member_user_id] = unit;
    });
    return acc;
  }, {});

export const buildLeaderToUnitsDict = ({ units = [] }) =>
  units.reduce((acc, unit) => {
    if (!acc[unit.leader_user_id]) {
      acc[unit.leader_user_id] = [];
    }
    acc[unit.leader_user_id].push(unit);
    return acc;
  }, {});

export const buildUnitsToSubUnitsDict = ({ units = [] }) =>
  units.reduce((acc, unit) => {
    if (!unit.parent_unit_id) return acc;

    if (!acc[unit.parent_unit_id]) {
      acc[unit.parent_unit_id] = [];
    }
    acc[unit.parent_unit_id].push(unit);
    return acc;
  }, {});

export const getSubUnits = ({ unitId, unitsDict }) =>
  Object.values(unitsDict).filter((unit) => unit.parent_unit_id === unitId);

export const isUserLeaderOfSubUnit = ({ userId, unitId, unitsDict }) => {
  const subUnits = getSubUnits({ unitId, unitsDict });

  return subUnits.some((subUnit) => subUnit.leader_user_id === userId);
};

export const isUserLeaderOfParentUnits = ({ userId, parentUnitId, unitsDict }) => {
  const parentUnit = unitsDict[parentUnitId];
  if (!parentUnit) return false;
  if (parentUnit.leader_user_id === userId) return true;

  return isUserLeaderOfParentUnits({ userId, parentUnitId: parentUnit.parent_unit_id, unitsDict });
};

export const isUserLeaderOfParentUnit = ({ userId, parentUnitId, unitsDict }) => {
  const parentUnit = unitsDict[parentUnitId];
  if (!parentUnit) return false;
  return parentUnit.leader_user_id === userId;
};

export const isUserLeaderOfTwoParentUnits = ({ userId, parentUnitId, unitsDict }) => {
  if (!isUserLeaderOfParentUnit({ userId, parentUnitId, unitsDict })) return false;

  const parentUnit = unitsDict[parentUnitId];
  if (!parentUnit) return false;
  return isUserLeaderOfParentUnit({ userId, parentUnitId: parentUnit.parent_unit_id, unitsDict });
};

export const prepareDataForSave = ({ values }) => {
  const data = _.omit(values, [UNIT_FIELDS.members, UNIT_FIELDS.unitType]);
  data.member_user_ids = values.members;
  data.parent_unit_id = values.parent_unit_id ? values.parent_unit_id : null;
  return data;
};

export const getUnitMembersIds = ({ unit }) => {
  return unit?.members?.map((member) => member.member_user_id) || [];
};
export const getUnitMembersNames = ({ unit }) => {
  return unit?.members?.map((member) => member.member_user_name) || [];
};
export const getUnitType = ({ unit }) => {
  if (!unit) return null;
  return unit?.[UNIT_FIELDS.parentUnitId] ? ORG_UNIT_TYPE.SUB_UNIT : ORG_UNIT_TYPE.TOP_UNIT;
};

export const buildSearchOption = ({ id, entryTitle, entrySubtitle, secondaryAction, isDisabled = false }) => ({
  id,
  entryTitle,
  entrySubtitle,
  secondaryAction,
  isDisabled,
});

export const getProfileName = ({ user }) => {
  return user?.profile?.display_name || '';
};

export const getSubordinatesRecursive = ({ unitId, unitsDict }) => {
  const unit = unitsDict[unitId];
  if (!unit) return [];

  const members = unit.members || [];
  const subUnits = getSubUnits({ unitId, unitsDict });
  const subordinates = subUnits.reduce((acc, subUnit) => {
    const subUnitMembers = getSubordinatesRecursive({ unitId: subUnit.id, unitsDict });
    return [...acc, ...subUnitMembers];
  }, []);

  return [...new Set([...members, ...subordinates])];
};
