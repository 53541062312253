import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import useOrganization from './OrganizationContext';

const ContactsContext = React.createContext({});

function ContactsContextProvider(props) {
  const { contacts, contactsOrganizationId, onAddContact, rolesClaimType, children } = props;
  const { organizationContactRolesDict } = useOrganization();

  const searchContacts = (searchStr) => {
    return contacts.filter(
      (contact) =>
        contact.full_name.toLowerCase().includes(searchStr.trim().toLowerCase()) ||
        organizationContactRolesDict[contact.role]?.['desc'].toLowerCase().includes(searchStr.trim().toLowerCase())
    );
  };

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        onAddContact,
        searchContacts,
        contactsOrganizationId,
        rolesClaimType,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
}

ContactsContextProvider.propTypes = {
  contacts: PropTypes.array.isRequired,
  contactsOrganizationId: PropTypes.number.isRequired,
  onAddContact: PropTypes.func.isRequired,
  rolesClaimType: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function withContacts(WrappedComponent) {
  class withContacts extends React.Component {
    render() {
      return (
        <ContactsContext.Consumer>
          {(contactsContext) => <WrappedComponent {...contactsContext} {...this.props} />}
        </ContactsContext.Consumer>
      );
    }
  }

  withContacts.displayName = `withContacts(${getDisplayName(WrappedComponent)})`;
  return withContacts;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function useContacts() {
  const contactsContext = useContext(ContactsContext);
  return { ...contactsContext };
}

export default ContactsContextProvider;
export { useContacts, withContacts };
