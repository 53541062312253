import type { ReactElement } from 'react';
import React from 'react';

import ThreeDotsMenu from '~/components/core/ThreeDotsMenu';

import { useLegalAction } from '../hooks/useLegalAction';
import { getLegalActionStateProps } from '../LegalActionsUtils';

import type { LegalActionStateProps } from './LegalActionsCompoentsTypes';

interface ObjectCardMenuProps<T = null> extends LegalActionStateProps {
  onEditClick?: () => void;
  onDeleteClick?: (item?: T) => Promise<void> | void;
  onViewMore?: () => void;
  additionalOptions?: { key: string; node: string; onClick: () => void; disabled?: boolean }[];
  entityLabel: string;
  disabledActions?: string[];
}

export const LegalActionItemMenu = <T = null,>({
  onEditClick,
  onDeleteClick,
  entityLabel,
  onViewMore,
  ignoreLegalActionState,
  additionalOptions,
  disabledActions,
}: ObjectCardMenuProps<T>): ReactElement => {
  const { legalAction } = useLegalAction();
  const { isDisabled: shouldDisableEditAndDelete, tooltipText } = getLegalActionStateProps(
    legalAction,
    ignoreLegalActionState
  );

  const options = [];

  if (onEditClick) {
    options.push({
      key: 'edit',
      node: `Edit ${entityLabel}`,
      onClick: onEditClick,
      disabled: shouldDisableEditAndDelete,
      tooltipText,
    });
  }

  if (onDeleteClick) {
    options.push({
      key: 'delete',
      node: `Delete ${entityLabel}`,
      onClick: onDeleteClick,
      disabled: shouldDisableEditAndDelete,
      withConfirmProps: {
        title: `Delete ${entityLabel}`,
        contentText: `This can't be undone. Are you sure you want to delete this ${entityLabel}?`,
        primaryButtonName: 'Yes',
        shouldCloseOnPrimary: true,
      },
      tooltipText,
    });
  }

  if (onViewMore) {
    options.push({ key: 'viewMore', node: 'View more Details', onClick: onViewMore, ignorePermissions: true });
  }

  if (additionalOptions) {
    additionalOptions.forEach((option) => {
      options.push({
        ...option,
        tooltipText,
        shouldDisableEditAndDelete,
      });
    });
  }

  if (disabledActions) {
    options.forEach((option) => {
      option.disabled = disabledActions.includes(option.key);
    });
  }

  return <ThreeDotsMenu ignorePermission options={options} />;
};
