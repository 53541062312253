import React from 'react';

import { PropertyDetails } from '../../../../core';
import { PropertyDetailsRow } from '../../../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import useOrganization from '../../../../OrganizationContext';
import { DatePropertyDetails } from '../../../components/DatePropertyDetails';
import { MoreDetailsDialog } from '../../../Dialogs/MoreDetailsDialog';
import type { DocumentApiResponse } from '../../../types';
import type { LogMoreDetailsDialogProps } from '../../LegalActionScreenTypes';

export const DocumentMoreDetails: React.FC<LogMoreDetailsDialogProps<DocumentApiResponse>> = ({ log, onClose }) => {
  const { documentTypesDict } = useOrganization() as { documentTypesDict: Record<string, { desc: string }> };

  return (
    <MoreDetailsDialog onClose={onClose} title="Document Details">
      <PropertyDetailsRow>
        <PropertyDetails title="Document Type" text={documentTypesDict[log.document_name]?.desc} />
        <PropertyDetails title="Document Name" text={log.document_name} />
      </PropertyDetailsRow>
      <PropertyDetailsRow>
        <DatePropertyDetails title="Document Date" date={log.document_date} />
        <DatePropertyDetails title="Date Recieved" date={log.document_date_received} />
      </PropertyDetailsRow>
      <PropertyDetailsRow>
        <PropertyDetails title="Uploaded By" text={log.uploaded_by} />
        <DatePropertyDetails title="Last Updated Date" date={log.datetime_updated} />
      </PropertyDetailsRow>
      <PropertyDetailsRow>
        <PropertyDetails title="Summary" text={log.summary} />
      </PropertyDetailsRow>
    </MoreDetailsDialog>
  );
};
