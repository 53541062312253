import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ThreeDotsIcon = (props) => (
  <SvgIcon {...props}>
    <circle cx="8" cy="3" r="0.5" />
    <circle cx="8" cy="8" r="0.5" />
    <circle cx="8" cy="13" r="0.5" />
  </SvgIcon>
);

export default ThreeDotsIcon;
