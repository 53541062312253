import * as Yup from 'yup';

export const NO_UPDATED_PAYEE_DETAILS_ERROR_ID = 'no_updated_payee_details_error_id';

function isAnyDetailsUpdated(newPayeesDetails, previousPayeesDetails) {
  let isAnyDetailsUpdatedFlag = false;

  Object.keys(newPayeesDetails).forEach((contactId) => {
    const { email_id: updatedEmailId, phone_id: updatedPhoneId } = newPayeesDetails[contactId];
    const { email_id: previousEmailId, phone_id: previousPhoneNumber } = previousPayeesDetails[contactId];

    if (
      (previousEmailId && updatedEmailId !== previousEmailId) ||
      (previousPhoneNumber && updatedPhoneId !== previousPhoneNumber)
    ) {
      isAnyDetailsUpdatedFlag = true;
    }
  });
  return isAnyDetailsUpdatedFlag;
}

export const getPayeeDetailsValidationSchema = (payees, initialValues) => {
  const contactsShape = {};

  payees.forEach(({ contact_id, payee_phone, payee_email }) => {
    contactsShape[contact_id] = Yup.object().shape({
      email_id: payee_email?.id ? Yup.number().required('Required') : null,
      phone_id: payee_phone?.id ? Yup.number().required('Required') : null,
    });
  });

  return Yup.object()
    .shape(contactsShape)
    .test('at_least_one_changed', 'At least one field must be changed', (values, context) => {
      if (!isAnyDetailsUpdated(values, initialValues)) {
        return context.createError({
          path: NO_UPDATED_PAYEE_DETAILS_ERROR_ID,
          message: 'At least one email address or phone number must be updated',
        });
      }
    });
};

export const getPayeeDetailsInitialValues = (payees, contactsDict) => {
  const initialValues = {};

  payees.forEach(({ contact_id, payee_email, payee_phone }) => {
    const contact = contactsDict[contact_id];

    initialValues[contact_id] = {
      email_id: contact?.emails.find(({ id }) => id === payee_email?.id) ? payee_email.id : '',
      phone_id: contact?.phones.find(({ id }) => id === payee_phone?.id) ? payee_phone.id : '',
    };
  });

  return initialValues;
};
