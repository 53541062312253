import React, { useEffect, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Checkbox, Link } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import { Formik } from 'formik';
import { UndoVariant } from 'mdi-material-ui';
import moment from 'moment';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import DatePickerField from '~/components/core/Molecules/Fields/DatePickerField/DatePickerField';

import { serverDateTimeToLocal, serverDateToLocal } from '../DateTimeUtils';
import { CONSTANTS } from '../Types';
import {
  getContactPreferredPhoneNumberOrNull,
  getPostalCodeYupTestParams,
  locationToLocationFullString,
  reportAxiosError,
  stringCmp,
} from '../Utils';
import { getFirstPartyVehicleFromClaim, useFetchClaim } from '../Utils/ClaimUtils';

import { FillContactAddressLinkLocationInner } from './Auto/AutoInvolved/VehicleDetails';
import DamageAssessmentContainer from './exposures/DamageAssessmentContainer';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { ClaimContextProvider } from './ClaimContainer';
import ClaimLink from './ClaimLink';
import ClaimSearchContainer from './ClaimSearch';
import WithConfirm from './ConfirmModal';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from './ContactTextFieldFormik';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsRenderWrapper, SortableTable } from './core';
import HoverActionField from './HoverActionField';
import InlineIconButton from './InlineIconButton';
import {
  default as InvolvedPropertyHoverInfo,
  default as InvolvedPropertyInfoTitle,
} from './InvolvedPropertyHoverInfo';
import LoadingIndicator from './LoadingIndicator';
import LocationTextField, { getLocationFields, LocationFormikInner } from './Location';
import OverflowTextWithToolTip from './OverflowTextWithToolTip';
import { ShowOnlyTextField } from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';
import UserClaimsNotificationsContainer from './UserClaimsNotificationsContainer';

import { useStyles } from '../assets/styles';

function DaSpecialistContainer() {
  const { user } = useCms();

  const classes = useStyles();
  const {
    isLoading,
    isError,
    data: activeDaRequests,
    reloadData,
  } = useDataFetcher(`/api/v1/auto_claims/da_requests?adjuster=${user.id}&status=active`);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const initialDetailsDialogState = { openDialog: false, isFieldRequest: undefined, daRequest: undefined };
  const [showDetailsDialog, setShowDetailsDialog] = useState(initialDetailsDialogState);
  const initialNonActiveRequestsDialogState = { openDialog: false, isFieldRequest: undefined, status: undefined };
  const [showNonActiveRequests, setShowNonActiveRequests] = useState(initialNonActiveRequestsDialogState);

  const disabled = isLoading || isSubmitting;

  async function handleDismissing(url, params) {
    try {
      setIsSubmitting(true);
      await axios({ method: 'post', url, data: params });
      await reloadData();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  async function dismissRequest(request, is_complete) {
    await handleDismissing(
      `/api/v1/auto_claims/${request.claim_id}/exposures/${request.exposure.id}/da_requests/${request.id}/dismiss`,
      { is_complete }
    );
  }

  const getActiveRequestsColumns = (isFieldRequest) =>
    getDaRequestsColumns(
      false,
      reloadData,
      disabled,
      ActiveRequestActionContainer,
      { dismissRequest: async (request, isComplete) => await dismissRequest(request, isComplete) },
      (request) => setShowDetailsDialog({ openDialog: true, isFieldRequest, daRequest: request }),
      isFieldRequest
    );

  const daFieldRequestsColumns = getActiveRequestsColumns(true);
  const daDeskRequestsColumns = getActiveRequestsColumns(false);

  if (isLoading && !activeDaRequests) {
    return <LoadingIndicator isError={isError} />;
  }

  const activeFieldDaRequests = activeDaRequests.da_requests.filter((daRequest) => daRequest.is_field_request === true);
  const activeDeskDaRequests = activeDaRequests.da_requests.filter((daRequest) => daRequest.is_field_request === false);

  return (
    <>
      <Grid container>
        <Grid item xs={5}>
          <div className={classes.cardDivRow}>
            <ClaimSearchContainer classes={classes} />
          </div>
        </Grid>
        <Grid item xs={7}>
          <PermissionsRenderWrapper action={PERMISSION_ACTIONS.NOTIFICATION} verb={PERMISSION_VERBS.READ}>
            <div className={classes.cardDivRow}>
              <UserClaimsNotificationsContainer removeInsuredName />
            </div>
          </PermissionsRenderWrapper>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.cardDivRow}>
            <CardDialog title="Field Inspection Assignments Queue">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link
                  href=""
                  style={{ margin: 0, textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowNonActiveRequests({ openDialog: true, isFieldRequest: true, status: 'dismissed' });
                  }}
                >
                  {'< See dismissed assignments'}
                </Link>
                <Link
                  href=""
                  style={{ margin: 0, textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowNonActiveRequests({ openDialog: true, isFieldRequest: true, status: 'completed' });
                  }}
                >
                  {'See completed assignments >'}
                </Link>
              </div>
              <div>
                <br />
              </div>
              <SortableTable
                columns={daFieldRequestsColumns}
                defaultOrderColumn={0}
                order="desc"
                rows={activeFieldDaRequests}
                maxHeight="30vh"
                stickyHeader
              />
            </CardDialog>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.cardDivRow}>
            <CardDialog title="Desk Inspection Assignments Queue">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link
                  href=""
                  style={{ margin: 0, textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowNonActiveRequests({ openDialog: true, isFieldRequest: false, status: 'dismissed' });
                  }}
                >
                  {'< See dismissed assignments'}
                </Link>
                <Link
                  href=""
                  style={{ margin: 0, textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowNonActiveRequests({ openDialog: true, isFieldRequest: false, status: 'completed' });
                  }}
                >
                  {'See completed assignments >'}
                </Link>
              </div>
              <div>
                <br />
              </div>
              <SortableTable
                columns={daDeskRequestsColumns}
                defaultOrderColumn={0}
                order="desc"
                rows={activeDeskDaRequests}
                maxHeight="30vh"
                stickyHeader
              />
            </CardDialog>
          </div>
        </Grid>
      </Grid>

      <ViewOnlyRequestsDialog
        user={user}
        requestStates={showNonActiveRequests.status}
        open={showNonActiveRequests.openDialog}
        isFieldRequest={showNonActiveRequests.isFieldRequest}
        onClose={() => setShowNonActiveRequests(initialNonActiveRequestsDialogState)}
        onUpdate={reloadData}
        handleDismissing={handleDismissing}
        onShowRequestDetails={(request) =>
          setShowDetailsDialog({
            openDialog: true,
            isFieldRequest: showNonActiveRequests.isFieldRequest,
            daRequest: request,
          })
        }
        disabled={disabled}
      />
      <DaRequestSummaryDialog
        open={showDetailsDialog.openDialog}
        daRequest={showDetailsDialog.daRequest}
        isFieldRequest={showDetailsDialog.isFieldRequest}
        onClose={() => setShowDetailsDialog(initialDetailsDialogState)}
      />
    </>
  );
}

function ActiveRequestActionContainer({ request, dismissRequest, disabled }) {
  return request.exposure.damage_assessment && request.exposure.damage_assessment.is_estimation_report_exists ? (
    <Tooltip title="Complete">
      <Checkbox
        checked={request.datetime_dismissed}
        onClick={async () => await dismissRequest(request, true)}
        disabled={disabled}
      />
    </Tooltip>
  ) : (
    <WithConfirm title="Dismiss Request?" primaryButtonName="Dismiss" shouldCloseOnPrimary={false}>
      <IconButton onClick={async () => await dismissRequest(request, false)} disabled={disabled}>
        <Tooltip title="Dismiss">
          <DeleteIcon />
        </Tooltip>
      </IconButton>
    </WithConfirm>
  );
}

ActiveRequestActionContainer.propTypes = {
  request: PropTypes.object.isRequired,
  dismissRequest: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

function NonActiveRequestActionContainer({ request, onUndismissRequest, disabled }) {
  return (
    <InlineIconButton
      icon={UndoVariant}
      onClick={async () => await onUndismissRequest(request, false)}
      disabled={disabled}
    />
  );
}

NonActiveRequestActionContainer.propTypes = {
  request: PropTypes.object.isRequired,
  onUndismissRequest: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

function ViewOnlyRequestsDialog({
  user,
  requestStates,
  open,
  onUpdate,
  onClose,
  onShowRequestDetails,
  isFieldRequest,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldReloadAfterUndismiss, setShouldReloadAfterUndismiss] = useState(false);
  const [page, setPage] = useState(0);
  const {
    isLoading,
    isError,
    data: daRequests,
    reloadData,
  } = useDataFetcher(open ? '/api/v1/auto_claims/da_requests' : undefined, {
    params: {
      adjuster: user.id,
      status: requestStates,
      page_number: page + 1, // page in the server is 1-based
      results_per_page: CONSTANTS.DA_REQUESTS_PER_PAGE,
    },
  });

  useEffect(() => {
    // This is used to reload the data only after the page has change to zero,
    // Otherwise we could call a page that doesn't exists anymore (if dismissing last request of the last page)
    reloadData();
    setShouldReloadAfterUndismiss(false);
  }, [shouldReloadAfterUndismiss, reloadData]);

  async function handleUndismissNoThrow(request) {
    try {
      setIsSubmitting(true);
      await axios.post(
        `/api/v1/auto_claims/${request.claim_id}/exposures/${request.exposure.id}/da_requests/${request.id}/undismiss`
      );
      setPage(0);
      setShouldReloadAfterUndismiss(true);
      onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSubmitting(false);
  }

  const disabled = isLoading || isSubmitting;

  const viewOnlyDaRequestsColumns = getDaRequestsColumns(
    true,
    reloadData,
    disabled,
    NonActiveRequestActionContainer,
    { onUndismissRequest: async (request) => await handleUndismissNoThrow(request, false) },
    (request) => onShowRequestDetails(request),
    isFieldRequest
  );

  if (!open) {
    return null;
  }

  const capitalizedStatus = requestStates[0].toUpperCase() + requestStates.slice(1).toLowerCase();

  return (
    <CardDialog
      title={`${capitalizedStatus} Requests`}
      isDialog
      onClose={() => {
        setPage(0);
        onClose();
      }}
      maxWidth="xl"
      fullWidth
    >
      {isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : (
        <SortableTable
          columns={viewOnlyDaRequestsColumns}
          order="desc"
          rows={daRequests.da_requests}
          paginationProps={{
            page,
            rowsPerPage: CONSTANTS.DA_REQUESTS_PER_PAGE,
            onChangePage: (event, newPage) => setPage(newPage),
            count: daRequests.count || 0,
            rowsPerPageOptions: [CONSTANTS.DA_REQUESTS_PER_PAGE],
          }}
          disableSortByUser
          keepRowsOrder
        />
      )}
    </CardDialog>
  );
}

ViewOnlyRequestsDialog.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onShowRequestDetails: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isFieldRequest: requiredIf(PropTypes.bool, (props) => props.open),
  requestStates: requiredIf(PropTypes.oneOf(['completed', 'dismissed']), (props) => props.open),
};

function getDaRequestsColumns(
  showOnly,
  onUpdate,
  disabled,
  ActionComponent,
  actionComponentAdditionalProps,
  onShowRequestDetails,
  isFieldRequest
) {
  let daRequestsColumns = [
    { id: 'id', numeric: true, disablePadding: true, label: '#', width: '40px' },
    {
      id: 'creation_date',
      numeric: false,
      label: 'Date',
      width: '95px',
      specialCell: (request) => serverDateToLocal(request.creation_date),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'claim',
      label: 'Claim',
      width: '150px',
      specialCell: (request) => <ClaimLink claimId={request.claim_id} linkText={request.claim_id_display} />,
      specialCmpFunc: (row1, row2) => row1.claim_id - row2.claim_id,
    },
    {
      id: 'exposure_claim_internal_id',
      numeric: true,
      align: 'center',
      width: '40px',
      disableSort: true,
      disablePadding: true,
      label: <span style={{ whiteSpace: 'nowrap' }}>Exp. #</span>,
    },
    {
      id: 'exposure',
      numeric: false,
      label: 'Exposure',
      // eslint-disable-next-line react/display-name
      specialCell: (request) => (
        <>
          {request.exposure.coverage_type_desc}
          &nbsp;-&nbsp;
          <InvolvedPropertyInfoTitle claimId={request.claim_id} involvedProperty={request.involved_vehicle} />
        </>
      ),
      specialCmpFunc: (row1, row2) => row1.exposure.coverage_type - row2.exposure.coverage_type,
    },
  ];

  if (isFieldRequest) {
    daRequestsColumns = daRequestsColumns.concat([
      {
        id: 'scheduling_inspection_contact',
        numeric: false,
        label: 'Scheduling Contact',
        // eslint-disable-next-line react/display-name
        specialCell: (request) => (
          <SchedulingInspectionContactContainer daRequest={request} onUpdate={onUpdate} showOnly={showOnly} />
        ),
        specialCmpFunc: (request1, request2) =>
          stringCmp(
            request1.scheduling_inspection_contact ? request1.scheduling_inspection_contact.full_name : '',
            request2.scheduling_inspection_contact ? request2.scheduling_inspection_contact.full_name : ''
          ),
      },
      {
        id: 'inspection_location',
        label: 'Inspection Location',
        leftPaddingOnly: true,
        disableSort: true,
        // eslint-disable-next-line react/display-name
        specialCell: (request) => (
          <InspectionLocationContainer
            request={request}
            claimId={request.claim_id}
            exposureId={request.exposure.id}
            inspectionLocation={request.inspection_location}
            onUpdate={onUpdate}
            showOnly={showOnly}
          />
        ),
      },
      {
        id: 'inspection_date',
        label: 'Inspection Date',
        leftPaddingOnly: true,
        specialCell:
          // eslint-disable-next-line react/display-name
          (request) => (
            <InspectionDateContainer
              requestId={request.id}
              claimId={request.claim_id}
              exposureId={request.exposure.id}
              inspectionDate={request.inspection_date}
              onUpdate={onUpdate}
              showOnly={showOnly}
            />
          ),
      },
    ]);
  }

  daRequestsColumns = daRequestsColumns.concat([
    {
      id: 'damage_assessment',
      label: 'Damage Assessment',
      disableSort: true,
      // eslint-disable-next-line react/display-name
      specialCell: (request) =>
        ((request.inspection_date && request.inspection_location) || !isFieldRequest) && (
          // We need the claim context to add docs to the claim
          <DamageAssessmentContainer
            claimId={request.claim_id}
            exposure={request.exposure}
            onUpdate={onUpdate}
            viewOnly={showOnly}
          />
        ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'action',
      label: 'Action',
      disablePadding: true,
      disableSort: true,
      width: 25,
      specialCell: (request) => (
        <ActionComponent request={request} disabled={disabled} {...actionComponentAdditionalProps} />
      ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'view_details',
      disableSort: true,
      width: 15,
      specialCell: (request) => (
        <InlineIconButton icon={VisibilityIcon} onClick={() => onShowRequestDetails(request)} disabled={disabled} />
      ),
    },
  ]);

  return daRequestsColumns;
}

function InspectionDateContainer(props) {
  const { requestId, claimId, exposureId, inspectionDate, onUpdate, showOnly } = props;
  const [openDateDialog, setOpenDateDialog] = useState(false);

  const handleInspectionDateUpdate = async (newDate) => {
    // DateTimePicker calls onClose before onChange, so we open it again until update finises
    setOpenDateDialog(true);
    await updateDaRequestNoThrow(claimId, exposureId, requestId, { inspection_date: newDate.toISOString() }, onUpdate);
    setOpenDateDialog(false);
  };

  return openDateDialog ? (
    <DatePickerField
      format={`${moment.localeData().longDateFormat('L')} ${moment.localeData().longDateFormat('LT')}`}
      value={inspectionDate ? serverDateTimeToLocal(inspectionDate) : ''}
      open={openDateDialog}
      onClose={() => setOpenDateDialog(false)}
      onChange={async (date) => await handleInspectionDateUpdate(date)}
      disablePast
      isDateTimeSelection
    />
  ) : (
    <HoverActionField disabled={showOnly} onAction={() => setOpenDateDialog(true)}>
      {inspectionDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{serverDateTimeToLocal(inspectionDate)}</span>
      ) : showOnly ? (
        ''
      ) : (
        <em>Schedule visit</em>
      )}
    </HoverActionField>
  );
}

InspectionDateContainer.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposureId: PropTypes.number.isRequired,
  inspectionDate: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  requestId: PropTypes.number.isRequired,
  showOnly: PropTypes.bool,
};

function SchedulingInspectionContactContainer({ daRequest, onUpdate, showOnly, claimId }) {
  const [isEditingContact, setIsEditingContact] = useState(false);

  async function handleUpdateContact(values) {
    try {
      await axios.patch(
        `/api/v1/auto_claims/${daRequest.claim_id}/exposures/${daRequest.exposure.id}/involved_vehicle/scheduling_inspection_contact`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  return (
    <>
      <ContactShowOnlyTextField
        label=""
        contactId={daRequest.scheduling_inspection_contact_id}
        contactDisplayName={
          daRequest.scheduling_inspection_contact_id ? daRequest.scheduling_inspection_contact.full_name : ''
        }
        onEdit={!showOnly ? () => setIsEditingContact(true) : undefined}
        claimId={claimId}
      />

      {isEditingContact && (
        <SchedulingInspectionContactPickerDialog
          daRequest={daRequest}
          onSubmit={handleUpdateContact}
          onClose={() => setIsEditingContact(false)}
        />
      )}
    </>
  );
}

SchedulingInspectionContactContainer.propTypes = {
  daRequest: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
  claimId: PropTypes.number,
};

function SchedulingInspectionContactPickerDialog({ daRequest, onSubmit, onClose }) {
  const [claim, isLoading, isError, reloadClaim] = useFetchClaim(daRequest.claim_id);
  const classes = useStyles();

  let isVehicleInvolvedFirstParty;
  if (!(isLoading || isError)) {
    const firstPartyVehicle = getFirstPartyVehicleFromClaim(claim);
    isVehicleInvolvedFirstParty =
      firstPartyVehicle && firstPartyVehicle.involved_vehicle.id === daRequest.exposure.involved_property.id;
  }

  return (
    <Formik
      initialValues={{ scheduling_inspection_contact_id: daRequest.scheduling_inspection_contact_id }}
      validationSchema={Yup.object().shape({ scheduling_inspection_contact_id: Yup.number().required('Required') })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values);
          onClose();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit }) => (
        <CardDialog isDialog title="Edit Scheduling Contact" onClose={onClose} open maxWidth="sm">
          {isLoading || isError ? (
            <LoadingIndicator isError={isError} />
          ) : (
            <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
              <ContactTextFieldFormik
                id="scheduling_inspection_contact"
                label="Scheduling Contact"
                disabled={isSubmitting}
                acceptedRoles={
                  isVehicleInvolvedFirstParty
                    ? ['insured', 'named_driver', 'spouse', 'family_member', 'body_shop', 'tow_lot', 'other']
                    : ['claimant', 'body_shop', 'tow_lot', 'other']
                }
                contactSearchProps={{ newContactRole: 'tow_lot' }}
                fullWidth
                fixedSearchResults
              />

              <div className={classes.buttonsContainer}>
                <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
                  Save
                </Button>
              </div>
            </ClaimContextProvider>
          )}
        </CardDialog>
      )}
    </Formik>
  );
}

SchedulingInspectionContactPickerDialog.propTypes = {
  daRequest: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function InspectionLocationContainer(props) {
  const { request, claimId, exposureId, inspectionLocation, onUpdate, showOnly } = props;

  const handleInspectionLocationUpdate = async (newLocation) => {
    try {
      await axios.patch(
        `/api/v1/auto_claims/${claimId}/exposures/${exposureId}/involved_vehicle/inspection_location`,
        newLocation
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <InspectionLocationPicker
      placeHolder="Set Location"
      inspectionLocation={inspectionLocation}
      schedulingInspectionContact={request.scheduling_inspection_contact}
      onUpdate={handleInspectionLocationUpdate}
      showOnly={showOnly}
    />
  );
}

InspectionLocationContainer.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposureId: PropTypes.number.isRequired,
  inspectionLocation: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  showOnly: PropTypes.bool,
};

function InspectionLocationPicker(props) {
  const { inspectionLocation, schedulingInspectionContact, onUpdate, showOnly, placeHolder } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  const classes = useStyles();
  const fieldText = inspectionLocation ? (
    locationToLocationFullString(inspectionLocation)
  ) : showOnly ? (
    ''
  ) : (
    <em>{placeHolder}</em>
  );

  return (
    <>
      <HoverActionField disabled={showOnly} onAction={() => setDialogOpen(true)}>
        <OverflowTextWithToolTip maxWidth="230px">{fieldText}</OverflowTextWithToolTip>
      </HoverActionField>

      <Formik
        initialValues={{ ...getLocationFields(''), ...inspectionLocation }}
        validationSchema={Yup.object().shape({
          zipcode: Yup.string().test(...getPostalCodeYupTestParams(false)),
        })}
        onSubmit={async (values, formikProps) => {
          try {
            await onUpdate(values);
            setDialogOpen(false);
          } catch {
            formikProps.setSubmitting(false);
          }
        }}
        enableReinitialize
      >
        {(formikProps) => {
          const { isSubmitting } = formikProps;
          const additionalFieldsComponent = schedulingInspectionContact ? (
            <FillContactAddressLinkLocationInner contact={schedulingInspectionContact} linkText="Use Contact Address" />
          ) : undefined;

          return (
            <CardDialog
              isDialog
              title="Inspection Location"
              onClose={() => setDialogOpen(false)}
              preventClose={isSubmitting}
              open={dialogOpen}
              maxWidth="sm"
            >
              <LocationFormikInner
                {...formikProps}
                classes={classes}
                additionalFieldsComponent={additionalFieldsComponent}
                onCancel={() => setDialogOpen(false)}
                countriesConfigurationKey="vehicle_location"
              />
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

InspectionLocationPicker.propTypes = {
  schedulingInspectionContact: PropTypes.object,
  inspectionLocation: PropTypes.object,
  onUpdate: PropTypes.func,
  showOnly: PropTypes.bool,
  placeHolder: PropTypes.string,
};

async function updateDaRequestNoThrow(claimId, exposureId, requestId, patchData, updateCallBack) {
  try {
    await axios.patch(`/api/v1/auto_claims/${claimId}/exposures/${exposureId}/da_requests/${requestId}`, patchData);
    await updateCallBack();
  } catch (error) {
    reportAxiosError(error);
  }
}

function DaRequestSummaryDialog(props) {
  const { open, daRequest, onClose, isFieldRequest, isFromFieldInspectionQueue } = props;
  const classes = useStyles();
  if (!open) {
    return <></>;
  }

  const dialogAction = (
    <IconButton onClick={() => window.print()}>
      <PrintIcon />
    </IconButton>
  );

  return (
    <CardDialog
      isDialog={true}
      open={open}
      title="View Assignment"
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      action={dialogAction}
      closeOnBackdropClick
    >
      <Grid container>
        <Grid item xs={4}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={<ClaimLink claimId={daRequest.claim_id} linkText={daRequest.claim_id_display} />}
            label="Claim"
          />
        </Grid>
        <Grid item xs={4}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={`${daRequest.exposure_claim_internal_id} - ${daRequest.exposure.coverage_type_desc}`}
            label="Exposure"
          />
        </Grid>
        <Grid item xs={4}>
          <ShowOnlyTextField classes={classes} showOnlyValueComponent={daRequest.policy_number} label="Policy Number" />
        </Grid>
        <Grid item xs={12}>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <DirectionsCarIcon />
            &nbsp;
            <InvolvedPropertyHoverInfo
              claimId={daRequest.claim_id}
              involvedProperty={daRequest.involved_vehicle}
              hideOwner
            />
          </span>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: '25px' }}>
          <Typography>
            <strong>Vin</strong>&nbsp;&nbsp;{daRequest.involved_vehicle.vehicle.vin}
          </Typography>
          <Typography>
            <strong>Plate #</strong>&nbsp;&nbsp;{daRequest.involved_vehicle.vehicle.plate_number}
          </Typography>
          {daRequest.involved_vehicle.owner_contact && (
            <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <Typography display="inline">
                <strong>Owner</strong>&nbsp;&nbsp;
              </Typography>
              {daRequest.involved_vehicle.owner_contact.full_name}
              {daRequest.involved_vehicle.owner_contact.phones.length > 0 && (
                <span>{`, ${getContactPreferredPhoneNumberOrNull(daRequest.involved_vehicle.owner_contact)}`}</span>
              )}
              {daRequest.involved_vehicle.owner_contact.emails.length > 0 && (
                <span>{`, ${daRequest.involved_vehicle.owner_contact.emails[0].email}`}</span>
              )}
            </div>
          )}
          {daRequest.involved_vehicle.owner_contact && (
            <div>
              <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography display="inline">
                  <strong>Owner Address</strong>&nbsp;&nbsp;
                </Typography>
                <span>{daRequest.involved_vehicle.owner_contact.full_address}</span>
              </div>
            </div>
          )}
        </Grid>
        <br />
        <Grid item xs={6}>
          <ShowOnlyTextField classes={classes} showOnlyValueComponent={daRequest.date_of_loss} label="Date of Loss" />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={daRequest.incident_description}
            label="Incident Description"
          />
        </Grid>
        <Grid item xs={12}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={daRequest.involved_vehicle.damages.join(', ')}
            label="Damage Areas"
          />
        </Grid>
        <Grid item xs={12}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={daRequest.involved_vehicle.damage_description}
            label="Damage Description"
          />
        </Grid>

        {isFieldRequest && (
          <>
            <Grid item xs={12}>
              <ShowOnlyTextField
                classes={classes}
                label="Contact For Scheduling"
                showOnlyValueComponent={
                  daRequest.scheduling_inspection_contact && (
                    <span>
                      {daRequest.scheduling_inspection_contact.full_name}
                      {daRequest.scheduling_inspection_contact.phones.length > 0 && (
                        <span>{`, ${getContactPreferredPhoneNumberOrNull(
                          daRequest.scheduling_inspection_contact
                        )}`}</span>
                      )}
                      {daRequest.scheduling_inspection_contact.emails.length > 0 && (
                        <span>{`, ${daRequest.scheduling_inspection_contact.emails[0].email}`}</span>
                      )}
                    </span>
                  )
                }
              />
            </Grid>
            {!isFromFieldInspectionQueue && (
              <Grid item xs={12}>
                <LocationTextField
                  classes={classes}
                  label="Inspection Location"
                  location={daRequest.inspection_location}
                  showOnly
                  countriesConfigurationKey="vehicle_location"
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  daRequest.inspection_date ? serverDateTimeToLocal(daRequest.inspection_date) : ''
                }
                label="Inspection Date"
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <ShowOnlyTextField label="Adjuster" classes={classes} showOnlyValueComponent={daRequest.adjuster_name} />
        </Grid>
        {!isFromFieldInspectionQueue && (
          <Grid item xs={12}>
            <ShowOnlyTextField
              label="Instructions"
              classes={classes}
              showOnlyValueComponent={daRequest.instructions}
              className={classes.textField}
              fullWidth
              multiline
            />
          </Grid>
        )}
      </Grid>
    </CardDialog>
  );
}

DaRequestSummaryDialog.propTypes = {
  daRequest: requiredIf(PropTypes.object, (props) => props.open),
  isFieldRequest: requiredIf(PropTypes.bool, (props) => props.open),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  isFromFieldInspectionQueue: PropTypes.bool,
};

export default DaSpecialistContainer;
export {
  ActiveRequestActionContainer,
  DaRequestSummaryDialog,
  InspectionDateContainer,
  NonActiveRequestActionContainer,
  SchedulingInspectionContactContainer,
  SchedulingInspectionContactPickerDialog,
  ViewOnlyRequestsDialog,
};
