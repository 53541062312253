import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const AllDoneNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      d="M24.0261 18.0359L15.626 26.2859C15.4858 26.4239 15.2969 26.5013 15.1001 26.5013C14.9033 26.5013 14.7145 26.4239 14.5742 26.2859L10.9742 22.7506C10.9039 22.6816 10.848 22.5993 10.8095 22.5087C10.7711 22.418 10.7509 22.3206 10.75 22.2221C10.7492 22.1236 10.7678 22.0259 10.8047 21.9346C10.8416 21.8432 10.8962 21.7601 10.9653 21.6898C11.0343 21.6196 11.1166 21.5637 11.2072 21.5252C11.2979 21.4867 11.3953 21.4665 11.4938 21.4657C11.5923 21.4649 11.69 21.4835 11.7813 21.5204C11.8727 21.5573 11.9558 21.6119 12.026 21.6809L15.1001 24.6997L22.9751 16.9653C23.1171 16.8258 23.3087 16.7485 23.5077 16.7502C23.6062 16.7511 23.7036 16.7714 23.7943 16.8099C23.8851 16.8484 23.9673 16.9044 24.0364 16.9747C24.1054 17.045 24.16 17.1282 24.1969 17.2196C24.2338 17.311 24.2523 17.4087 24.2515 17.5073C24.2506 17.6058 24.2303 17.7032 24.1918 17.7939C24.1533 17.8846 24.0973 17.9669 24.027 18.0359H24.0261ZM33.0354 16.9747C32.9664 16.9043 32.8842 16.8483 32.7934 16.8097C32.7027 16.7712 32.6053 16.7509 32.5067 16.75C32.4082 16.7492 32.3104 16.7677 32.219 16.8047C32.1276 16.8416 32.0444 16.8962 31.9742 16.9653L24.0992 24.6997L22.3339 22.9653C22.1919 22.826 22.0004 22.7487 21.8014 22.7505C21.6025 22.7524 21.4124 22.8332 21.2731 22.9752C21.1337 23.1171 21.0565 23.3087 21.0583 23.5076C21.0602 23.7065 21.141 23.8966 21.2829 24.0359L23.5732 26.2859C23.7135 26.4239 23.9024 26.5013 24.0992 26.5013C24.2959 26.5013 24.4848 26.4239 24.6251 26.2859L33.0251 18.0359C33.0955 17.967 33.1517 17.8848 33.1903 17.7941C33.2289 17.7034 33.2493 17.606 33.2502 17.5075C33.2512 17.4089 33.2327 17.3111 33.1959 17.2197C33.159 17.1283 33.1045 17.045 33.0354 16.9747Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default AllDoneNoteIcon;
