import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ManagementIcon = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path d="M11.875 2.5V6.875H16.25" fill="none" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.49112 2.24112C3.72554 2.0067 4.04348 1.875 4.375 1.875H11.875C12.0408 1.875 12.1997 1.94085 12.3169 2.05806L16.6919 6.43306C16.8092 6.55027 16.875 6.70924 16.875 6.875V16.875C16.875 17.2065 16.7433 17.5245 16.5089 17.7589C16.2745 17.9933 15.9565 18.125 15.625 18.125H11.875C11.5298 18.125 11.25 17.8452 11.25 17.5C11.25 17.1548 11.5298 16.875 11.875 16.875H15.625V7.13388L11.6161 3.125L4.375 3.125L4.375 10C4.375 10.3452 4.09518 10.625 3.75 10.625C3.40482 10.625 3.125 10.3452 3.125 10V3.125C3.125 2.79348 3.2567 2.47554 3.49112 2.24112Z"
      stroke="none"
    />
    <path
      d="M7.67188 15.7959C8.70741 15.7959 9.54688 14.9564 9.54688 13.9209C9.54688 12.8854 8.70741 12.0459 7.67188 12.0459C6.63634 12.0459 5.79688 12.8854 5.79688 13.9209C5.79688 14.9564 6.63634 15.7959 7.67188 15.7959Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.84766 11.4648C9.94661 11.556 10.0404 11.6497 10.1289 11.7461L11.1953 11.8984C11.3691 12.2002 11.503 12.5232 11.5938 12.8594L10.9453 13.7227C10.9453 13.7227 10.957 13.9883 10.9453 14.1211L11.5938 14.9844C11.5034 15.3207 11.3695 15.6437 11.1953 15.9453L10.1289 16.0977C10.1289 16.0977 9.94531 16.2891 9.84766 16.3789L9.69531 17.4453C9.39355 17.6191 9.07055 17.753 8.73438 17.8438L7.87109 17.1953C7.73854 17.207 7.60521 17.207 7.47266 17.1953L6.60938 17.8438C6.27306 17.7534 5.95 17.6195 5.64844 17.4453L5.49609 16.3789C5.39974 16.2878 5.30599 16.194 5.21484 16.0977L4.14844 15.9453C3.97468 15.6436 3.84075 15.3206 3.75 14.9844L4.39844 14.1211C4.39844 14.1211 4.38672 13.8555 4.39844 13.7227L3.75 12.8594C3.84033 12.5231 3.97428 12.2 4.14844 11.8984L5.21484 11.7461C5.30599 11.6497 5.39974 11.556 5.49609 11.4648L5.64844 10.3984C5.9502 10.2247 6.2732 10.0908 6.60938 10L7.47266 10.6484C7.60521 10.6367 7.73854 10.6367 7.87109 10.6484L8.73438 10C9.07069 10.0903 9.39375 10.2243 9.69531 10.3984L9.84766 11.4648Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default ManagementIcon;
