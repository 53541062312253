import moment from 'moment-timezone';

// most of the function below will return the current DateTime when given 'undefined', hiding many bugs
function verifyParam(p) {
  if (!p) {
    throw Error('Empty param given');
  }
}

export function getLocalDateToday() {
  return moment().format('YYYY-MM-D');
}

export function get24HoursFormatFromTimeString(timeString) {
  return moment(timeString, 'HH:mm:ss.sss').format('HH:mm');
}

export function getAmericanDateFormat(date) {
  return moment(date).format('MM/DD/YYYY');
}

export function getDateFromServerDateTime(date) {
  return moment(date).format('L');
}

export function getLocalDateTime(date) {
  return moment(date).format('YYYY-MM-D');
}

export function isoDateToUs(isoDate) {
  verifyParam(isoDate);
  return moment.utc(isoDate).format('L');
}

export function isoUtcDateTimeToLocal(isoDate) {
  verifyParam(isoDate);
  return moment.utc(isoDate).local().format('L LT');
}

export function isoDateTimeToLocal(isoDate) {
  verifyParam(isoDate);
  return moment(isoDate).local().format('L LT');
}

export function fullDateTimeString(isoDateTime) {
  verifyParam(isoDateTime);
  return moment(isoDateTime).format('MMMM Do YYYY, h:mm A');
}

export function timeToLocalTime(time) {
  verifyParam(time);
  // pretending a fictive date before the time for moment to work
  return moment.utc('2018-01-01 ' + time).format('LT');
}

export function serverDateToLocal(serverDate) {
  verifyParam(serverDate);
  return moment.utc(serverDate).local().format('L');
}

export function serverDateTimeToLocal(serverDateTime) {
  verifyParam(serverDateTime);
  return moment.utc(serverDateTime).local().format('L LT');
}

export function serverDateTimeToLocalMoment(serverDateTime) {
  verifyParam(serverDateTime);
  return moment.utc(serverDateTime).local();
}

export function serverDateToLocalMoment(serverDate, showTimeZone = true) {
  verifyParam(serverDate);
  if (moment.locale() === 'en') {
    return moment.utc(serverDate).tz('America/New_York').format('L') + (showTimeZone ? ' EST' : '');
  }

  return moment.utc(serverDate).local().format('L');
}

// export function serverTimeToLocal(serverTime) {
//   verifyParam(serverTime);
//   // pretending a fictive date before the time for moment to work
//   return moment.utc('2018-01-01 ' + serverTime).local().format('HH:mm');
// }

// export function localTImeToUtcTime(localTime){
//   verifyParam(localTime);
//   return moment.local('2018-01-01 ' + localTime).utc();
// }

export function serverDateTimeToLocalDate(serverDateTime) {
  verifyParam(serverDateTime);
  return moment.utc(serverDateTime).local().format('YYYY-MM-DD');
}

// export function serverDateTimeToLocalTime(serverDateTime){
//   verifyParam(serverDateTime);
//   return moment.utc(serverDateTime).local().format('HH:mm');
// }

export function localTimeToViewTime(localTime) {
  verifyParam(localTime);
  return moment.utc('2018-01-01 ' + localTime).format('LT');
}

// export function localDateToServer(localDate) {
//   verifyParam(localDate);
//   return moment(localDate).toISOString();
// }

// export function localDateTimeToServer(localDateTime) {
//   verifyParam(localDateTime);
//   return moment(localDateTime).toISOString();
// }

export function compareDatesStrings(date1, date2) {
  if (moment(date1).isBefore(moment(date2))) return -1;
  if (moment(date1).isAfter(moment(date2))) return 1;
  return 0;
}

export function datesYearsDiff(date1, date2) {
  if (!date1 || !date2) {
    return undefined;
  }
  return moment(date1).diff(date2, 'years', true);
}

export function isDatesDiffLessThan18Years(date1, date2) {
  if (!date1 || !date2) {
    return false;
  }
  return Math.abs(datesYearsDiff(date1, date2)) < 18;
}

export const datesDiffFromUtcNow = (dateUtc, unitOfTime = 'seconds') => {
  if (!dateUtc) {
    return;
  }

  return moment.utc().diff(dateUtc, unitOfTime, true);
};

export const convertSecondsToHours = (seconds) => Math.round(seconds / 60 / 60);
