import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import MinimizeIcon from '@material-ui/icons/Minimize';
import { ArrowExpand } from 'mdi-material-ui';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';

import CardDialog from '../../../CardDialog';
import OverflowTextWithToolTip from '../../../OverflowTextWithToolTip';

const ClaimQaFormCardFloating = ({ children, onClose, title, subtitle, onChangeCardSize }) => (
  <div style={{ width: '50vw', position: 'fixed', bottom: '15px', right: '25px', zIndex: 2 }} elevation={6}>
    <Paper style={{ width: '100%', height: '100%' }}>
      <CardDialog
        title={
          <OverflowTextWithToolTip maxWidth={600}>
            {title}, {subtitle}
          </OverflowTextWithToolTip>
        }
        onClose={onClose}
        height="100%"
        titleVariant="subtitle1"
        contentStyle={{ height: '27vh', overflow: 'auto' }}
        action={
          <>
            <IconButton onClick={() => onChangeCardSize('minimized')} size="small">
              <MinimizeIcon />
            </IconButton>
            <IconButton onClick={() => onChangeCardSize('full')} size="small">
              <ArrowExpand />
            </IconButton>
          </>
        }
      >
        {children}
      </CardDialog>
    </Paper>
  </div>
);

ClaimQaFormCardFloating.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.object.isRequired,
  onChangeCardSize: PropTypes.func.isRequired,
};

export default ClaimQaFormCardFloating;
