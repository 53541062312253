import React from 'react';

import MenuItem from '~/components/core/Atomic/MenuItem';
import type { SuggestionOption } from '~/components/core/Editor/types';
import TextWithMdBold from '~/components/TextWithBold/TextWithMDBold';

interface SuggestionMenuItemProps {
  label: string;
  id: string;
  subItems: undefined | SuggestionOption[];
  handleSelectItem: ({ id, label }: { id: string; label: string }) => void;
}

const SuggestionMenuItem: React.FC<SuggestionMenuItemProps> = ({ label, id, subItems, handleSelectItem }) => {
  return subItems ? null : (
    <MenuItem
      key={id}
      onClick={() => {
        handleSelectItem({ id, label });
      }}
    >
      <TextWithMdBold text={label} />
    </MenuItem>
  );
};

export default SuggestionMenuItem;
