const AUTO_COVERAGES_DICT = {
  coverage_physical_damage: {
    desc: 'PD',
  },
  coverage_physical_damage_subro: {
    desc: 'PD - Subro',
    coverage_alias: 'coverage_physical_damage',
  },
  coverage_pip: {
    desc: 'PIP',
    is_deduction_exist: true,
    is_limit_per_exposure: true,
  },
  coverage_apip: {
    desc: 'APIP',
    is_deduction_exist: true,
    is_limit_per_exposure: true,
  },
  coverage_medpay: {
    desc: 'MedPay',
    is_limit_per_exposure: true,
  },
  coverage_adb: {
    desc: 'ADB',
  },
  coverage_obel: {
    desc: 'OBEL',
    is_limit_per_exposure: true,
  },
  coverage_bodily_injury: {
    desc: 'BI',
    is_differ_per_person_and_accident: true,
  },
  coverage_bodily_injury_loss_transfer: {
    desc: 'BI - Loss Transfer',
    is_limit_per_exposure: true,
    is_outside_of_policy: true,
  },
  coverage_collision: {
    desc: 'Collision',
    is_deduction_exist: true,
    is_limit_exists: false,
    boolean_option: true,
  },
  coverage_comprehensive: {
    desc: 'Comprehensive',
    is_deduction_exist: true,
    is_limit_exists: false,
    boolean_option: true,
  },
  coverage_um: {
    desc: 'UM',
    is_differ_per_person_and_accident: true,
  },
  coverage_uim: {
    desc: 'UIM',
    is_differ_per_person_and_accident: true,
  },
  coverage_tow: {
    desc: 'TOW',
  },
  coverage_rental: {
    desc: 'Rental',
    extra_desc: 'Per day',
    should_check_limit: false,
  },
  coverage_glass: {
    desc: 'Glass',
    boolean_option: true,
    is_limit_exists: false,
  },
  coverage_ssl: {
    desc: 'SSL',
    boolean_option: true,
    is_limit_exists: false,
    disable_adding: true,
  },
  coverage_eco_xpl: {
    desc: 'ECO/XPL',
    should_check_limit: false,
    is_outside_of_policy: true,
  },
  coverage_own_damage: {
    desc: 'Own Damage',
    should_check_limit: false,
    is_outside_of_policy: true,
  },
  coverage_tp_pd: {
    desc: 'TP PD',
    should_check_limit: false,
    is_outside_of_policy: true,
  },
  coverage_tp_pi: {
    desc: 'TP PI',
    should_check_limit: false,
    is_outside_of_policy: true,
  },
  coverage_storage_and_recovery: {
    desc: 'Storage & Recovery',
    should_check_limit: false,
    is_outside_of_policy: true,
  },
  coverage_tp_hire_expenses: {
    desc: 'TP Hire Expenses',
    should_check_limit: false,
    is_outside_of_policy: true,
  },
  coverage_tp_injury_expenses: {
    desc: 'TP Injury Expenses',
    should_check_limit: false,
    is_outside_of_policy: true,
  },
  coverage_tp_miscellaneous_expenses: {
    desc: 'Misc. Expenses',
    should_check_limit: false,
    is_outside_of_policy: true,
  },
  coverage_bodily_injury_uk: {
    desc: 'BI',
    boolean_option: true,
    is_limit_exists: false,
    is_deduction_exist: true,
  },
  coverage_physical_damage_uk: {
    desc: 'PD',
    boolean_option: true,
    is_limit_exists: false,
    is_deduction_exist: true,
  },
  coverage_accidental_damage: {
    desc: 'AD',
    boolean_option: true,
    is_limit_exists: false,
    is_deduction_exist: true,
  },
  coverage_fire: {
    desc: 'Fire',
    boolean_option: true,
    is_limit_exists: false,
    is_deduction_exist: true,
  },
  coverage_theft: {
    desc: 'Theft',
    boolean_option: true,
    is_limit_exists: false,
    is_deduction_exist: true,
  },
  coverage_windscreen: {
    desc: 'Windscreen',
    boolean_option: true,
    is_limit_exists: false,
    is_deduction_exist: true,
  },
  coverage_public_liability: {
    desc: 'Public Liability',
    boolean_option: true,
    is_limit_exists: false,
    is_deduction_exist: true,
  },
  coverage_pi: {
    desc: 'PI',
    should_check_limit: false,
    is_outside_of_policy: true,
  },
} as const;

export default AUTO_COVERAGES_DICT;
