import * as Yup from 'yup';

import type { Option } from '../hooks/useLegalActionsConfiguration';
import { isOptionOther } from '../LegalActionsUtils';
import type { LegalActionApiResponse } from '../types';

import {
  DATE_OF_SERVICE_FIELDS,
  DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS,
  JURISDICTION_INFORMATION_FIELDS,
  LEGAL_ACTION_FIELDS,
  LEGAL_ACTION_LOG_FIELDS,
  LEGAL_ACTION_PARTY_FIELDS,
  NEGOTIATION_LOG_FIELDS,
  PROS_AND_CONS_FIELDS,
} from './FormFieldsDefinitions';
import type { LegalActionPartyFormValues } from './FormValuesTypes';

type PartialYupSchema = Record<string, Yup.BaseSchema>;

export const LEGAL_ACTION_SUMMARY_VALIDATION_SCHEMA = {
  [LEGAL_ACTION_FIELDS.isClaimLevel.key]: Yup.boolean().required('Required'),
  [LEGAL_ACTION_FIELDS.exposures.key]: Yup.array().when(LEGAL_ACTION_FIELDS.isClaimLevel.key, {
    is: false,
    then: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one exposure must be selected for an exposure level legal action'),
  }),
  [LEGAL_ACTION_FIELDS.displayName.key]: Yup.string().required('Required'),
  [LEGAL_ACTION_FIELDS.priority.key]: Yup.string().required('Required'),
  [LEGAL_ACTION_FIELDS.lawsuitAmount.key]: Yup.number().nullable().required('Required'),
  [LEGAL_ACTION_FIELDS.complaintAllegationsSummary.key]: Yup.string().required('Required'),
};
export const CLOSE_LEGAL_ACTION_VALIDATION_SCHEMA = (methodOfConclusionOptions: Option[]): PartialYupSchema => {
  return {
    [LEGAL_ACTION_FIELDS.methodOfConclusion.key]: Yup.string().required('Required'),
    [LEGAL_ACTION_FIELDS.methodOfConclusionText.key]: Yup.string().when(LEGAL_ACTION_FIELDS.methodOfConclusion.key, {
      is: (option: string) => isOptionOther(methodOfConclusionOptions, option),
      then: Yup.string().required('Required'),
    }),
    [LEGAL_ACTION_FIELDS.finalSettlementAmount.key]: Yup.number().required(),
  };
};
export const REOPEN_LEGAL_ACTION_VALIDATION_SCHEMA = (reopenReasonOptions: Option[]): PartialYupSchema => ({
  [LEGAL_ACTION_FIELDS.reopenReason.key]: Yup.string().required('Required'),
  [LEGAL_ACTION_FIELDS.reopenReasonText.key]: Yup.string().when(LEGAL_ACTION_FIELDS.methodOfConclusion.key, {
    is: (option: string) => isOptionOther(reopenReasonOptions, option),
    then: Yup.string().required('Required'),
  }),
});

export const LEGAL_ACTION_PARTY_VALIDATION_SCHEMA = (legalAction?: LegalActionApiResponse): PartialYupSchema => {
  return {
    [LEGAL_ACTION_PARTY_FIELDS.contact.key]: Yup.string().when(LEGAL_ACTION_PARTY_FIELDS.isCarrierParty.key, {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .nullable()
        .required('Required')
        .test({
          name: 'Test contact unique',
          message: 'Contact already attached as a party for this legal action',
          test(value, context) {
            if (legalAction) {
              return !legalAction.legal_action_parties
                .filter((party) => party.id !== context.parent.id)
                .some((party) => String(party.contact_id) === String(value));
            }

            return (
              [
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...this.options.from[1].value[LEGAL_ACTION_FIELDS.legalActionPlaintiffs.key],
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...this.options.from[1].value[LEGAL_ACTION_FIELDS.legalActionDefendants.key],
              ].filter((party: LegalActionPartyFormValues) => String(party.contact_id) === String(value)).length === 1
            );
          },
        }),
    }),
    [LEGAL_ACTION_PARTY_FIELDS.counsel.key]: Yup.string().nullable(),
    [LEGAL_ACTION_PARTY_FIELDS.fileNumber.key]: Yup.string().nullable(),
    [LEGAL_ACTION_PARTY_FIELDS.dateOfRepresentation.key]: Yup.date().nullable(),
    [LEGAL_ACTION_PARTY_FIELDS.isCarrierParty.key]: Yup.boolean(),
  };
};

export const LEGAL_ACTION_PARTY_TERMINATE_VALIDATION_SCHEMA = {
  [LEGAL_ACTION_PARTY_FIELDS.endDateOfRepresentation.key]: Yup.date().required('Required'),
};

export const DATE_OF_SERVICE_VALIDATION_SCHEMA: PartialYupSchema = {
  [DATE_OF_SERVICE_FIELDS.dateOfService.key]: Yup.date().required('This field is required'),
  [DATE_OF_SERVICE_FIELDS.serviceType.key]: Yup.string().required('This field is required'),
  [DATE_OF_SERVICE_FIELDS.contact.key]: Yup.string().nullable().required('This field is required'),
};
export const JURISDICTION_INFORMATION_VALIDATION_SCHEMA: PartialYupSchema = {
  [JURISDICTION_INFORMATION_FIELDS.jurisdiction.key]: Yup.string().nullable(),
  [JURISDICTION_INFORMATION_FIELDS.venue.key]: Yup.string().nullable(),
  [JURISDICTION_INFORMATION_FIELDS.court.key]: Yup.string().nullable(),
  [JURISDICTION_INFORMATION_FIELDS.dateFilledInCourt.key]: Yup.date().nullable(),
};
export const DISPUTE_RESOLUTION_PROFESSIONAL_VALIDATION_SCHEMA: PartialYupSchema = {
  [DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.type.key]: Yup.string().required('This field is required'),
  [DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.contact.key]: Yup.string().required('This field is required'),
  [DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.startDate.key]: Yup.date().required('This field is required'),
  [DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.endDate.key]: Yup.date().when(
    DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.startDate.key,
    {
      is: (val: Date) => Boolean(val),
      then: Yup.date().min(Yup.ref(DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.startDate.key), 'Must be after start date'),
    }
  ),
};

export const NEW_LEGAL_ACTION_VALIDATION_SCHEMA: PartialYupSchema = {
  ...LEGAL_ACTION_SUMMARY_VALIDATION_SCHEMA,
  ...JURISDICTION_INFORMATION_VALIDATION_SCHEMA,
  [LEGAL_ACTION_FIELDS.legalActionPlaintiffs.key]: Yup.array()
    .of(Yup.object().shape(LEGAL_ACTION_PARTY_VALIDATION_SCHEMA()))
    .test({
      message: 'At least one plaintiff is required',
      test: (arr) => Boolean(arr?.length),
    })
    .min(1),
  [LEGAL_ACTION_FIELDS.legalActionDefendants.key]: Yup.array().of(
    Yup.object().shape(LEGAL_ACTION_PARTY_VALIDATION_SCHEMA())
  ),
  [LEGAL_ACTION_FIELDS.datesOfService.key]: Yup.array().of(Yup.object().shape(DATE_OF_SERVICE_VALIDATION_SCHEMA)),
  [LEGAL_ACTION_FIELDS.disputeResolutionProfessionals.key]: Yup.array().of(
    Yup.object().shape(DISPUTE_RESOLUTION_PROFESSIONAL_VALIDATION_SCHEMA)
  ),
};

export const LEGAL_ACTION_LOG_VALIDATION_SCHEMA: PartialYupSchema = {
  [LEGAL_ACTION_LOG_FIELDS.date.key]: Yup.date().nullable().required('Required'),
  [LEGAL_ACTION_LOG_FIELDS.logType.key]: Yup.string().nullable().required('Required'),
  [LEGAL_ACTION_LOG_FIELDS.statusOfLitigation.key]: Yup.string().nullable().required('Required'),
  [LEGAL_ACTION_LOG_FIELDS.dueDate.key]: Yup.date().nullable(),
  [LEGAL_ACTION_LOG_FIELDS.nextAction.key]: Yup.string().nullable(),
  [LEGAL_ACTION_LOG_FIELDS.counselUpdate.key]: Yup.string().nullable(),
};

export const PROS_AND_CONS_VALIDATION_SCHEMA: PartialYupSchema = {
  [PROS_AND_CONS_FIELDS.proConType.key]: Yup.string().nullable().required('Required'),
  [PROS_AND_CONS_FIELDS.rationale.key]: Yup.string().nullable().required('Required'),
};

export const NEGOTIATION_LOG_VALIDATION_SCHEMA: PartialYupSchema = {
  [NEGOTIATION_LOG_FIELDS.date.key]: Yup.date().nullable().required('Required'),
  [NEGOTIATION_LOG_FIELDS.adversePartyDemand.key]: Yup.number().nullable(),
  [NEGOTIATION_LOG_FIELDS.offer.key]: Yup.number().nullable(),
  [NEGOTIATION_LOG_FIELDS.notes.key]: Yup.string().nullable(),
  [NEGOTIATION_LOG_FIELDS.adversePartyContact.key]: Yup.string().nullable().required('Required'),
};
