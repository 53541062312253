const CONTACT_FINANCE_CHANGE_REASONS = {
  typo: {
    desc: 'Correcting spelling / typing error',
  },
  addition: {
    desc: 'Adding missing information',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default CONTACT_FINANCE_CHANGE_REASONS;
