import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@material-ui/core';

import { FsMenuItem } from '~/components/core/FsWrappers';

import WithConfirm from '../ConfirmModal';
import { ThreeDotsIcon } from '../icons';
import InlineIconButton from '../InlineIconButton';

import { useStyles } from '../../assets/styles';

const ThreeDotsMenu = ({ options, onClick, ignorePermission, stopPropagation, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  return (
    <>
      <InlineIconButton
        icon={ThreeDotsIcon}
        ignorePermissions={ignorePermission}
        className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
        onClick={(event) => {
          if (stopPropagation) {
            event.stopPropagation();
          }
          if (onClick) {
            onClick();
          }
          setAnchorEl(event.currentTarget);
        }}
        wrapWithSpan
        disabled={disabled}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {options.map(
          ({ onClick, onOpenConfirmModal, disabled, node, key, withConfirmProps, ignorePermissions, tooltipText }) => {
            const withConfirmPropsWithCloseMenu = {
              ...(withConfirmProps || {}),
              postOnClick: (...args) => {
                withConfirmProps.postOnClick && withConfirmProps.postOnClick(...args);
                setAnchorEl(null);
              },
              onSecondaryBtnClick: (...args) => {
                withConfirmProps.onSecondaryBtnClick && withConfirmProps.onSecondaryBtnClick(...args);
                setAnchorEl(null);
              },
            };

            const MenuItemComponent = (
              <FsMenuItem
                key={key}
                onClick={async (e) => {
                  if (withConfirmProps) {
                    return;
                  }
                  await onClick(e);
                  setAnchorEl(null);
                }}
                disabled={disabled}
                ignorePermissions={ignorePermissions}
                tooltipText={tooltipText}
              >
                {node}
              </FsMenuItem>
            );

            return withConfirmProps ? (
              <WithConfirm
                {...withConfirmPropsWithCloseMenu}
                onOpenConfirmModal={withConfirmPropsWithCloseMenu.onOpenConfirmModal ?? onOpenConfirmModal}
              >
                <div
                  onClick={async (e) => {
                    await onClick(e);
                    setAnchorEl(null);
                  }}
                >
                  {MenuItemComponent}
                </div>
              </WithConfirm>
            ) : (
              MenuItemComponent
            );
          }
        )}
      </Menu>
    </>
  );
};

ThreeDotsMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      onOpenConfirmModal: PropTypes.func,
      disabled: PropTypes.bool,
      node: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired,
      withConfirmProps: PropTypes.object,
      ignorePermissions: PropTypes.bool,
      tooltipText: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func,
  ignorePermission: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ThreeDotsMenu;
