const TWILIO_ADJUSTER_STATUS_DICT = {
  available: {
    desc: 'Available',
    can_be_chosen_manually: true,
    can_accept_direct_incoming_calls: true,
    can_accept_queue_calls: true,
    is_required_for_queues_initialization: false,
  },
  available_for_queues_only: {
    desc: 'Available For Queues Only',
    can_be_chosen_manually: true,
    can_accept_direct_incoming_calls: false,
    can_accept_queue_calls: true,
    is_required_for_queues_initialization: true,
  },
  available_for_direct_calls_only: {
    desc: 'Available For Direct Calls Only',
    can_be_chosen_manually: true,
    can_accept_direct_incoming_calls: true,
    can_accept_queue_calls: false,
    is_required_for_queues_initialization: true,
  },
  not_available: {
    desc: 'Not Available',
    can_be_chosen_manually: true,
    can_accept_direct_incoming_calls: false,
    can_accept_queue_calls: false,
    is_required_for_queues_initialization: false,
  },
  in_direct_call: {
    desc: 'In Direct Call',
    in_call_status: true,
    can_accept_direct_incoming_calls: false,
    can_accept_queue_calls: false,
    is_required_for_queues_initialization: false,
  },
  in_queue_call: {
    desc: 'In Queue Call',
    in_call_status: true,
    can_accept_direct_incoming_calls: false,
    can_accept_queue_calls: false,
    is_required_for_queues_initialization: true,
  },
  wrap_up: {
    desc: 'Wrap Up',
    can_be_chosen_manually: false,
    can_accept_direct_incoming_calls: false,
    can_accept_queue_calls: false,
    is_required_for_queues_initialization: true,
  },
} as const;

export default TWILIO_ADJUSTER_STATUS_DICT;
