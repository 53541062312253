import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';

interface ExpendCollapseAllButtonsProps {
  setForceExpendCollapse: (shouldExpendAll: boolean) => void;
}

const ExpendCollapseAllButtons: React.FC<ExpendCollapseAllButtonsProps> = ({ setForceExpendCollapse }) => {
  return (
    <div className="flex gap-4">
      <Button color="primary" onClick={() => setForceExpendCollapse(true)}>
        Expand All
      </Button>
      <Button color="primary" onClick={() => setForceExpendCollapse(false)}>
        Collapse All
      </Button>
    </div>
  );
};

export default ExpendCollapseAllButtons;
