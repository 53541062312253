import React, { Fragment, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import axios from 'axios';
import _ from 'lodash';

import { useStyles } from '~/assets/styles';
import ContactMiniCard from '~/components/Contacts/ContactMiniCard';
import { EditContactDialog } from '~/components/Contacts/UpsertContact/Edit/EditContactDialog';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core';
import { useRestrictedPermissions } from '~/components/core/Permissions/RestrictedPermissions';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import { reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import PermissionsButtonWrapper from './core/Permissions/PermissionsButtonWrapper';
import { useCms } from './hooks/useCms';
import { useClaim } from './ClaimContainer';
import { contactPopulate } from './ContactUtils';
import { ContactIcon } from './icons';
import OverflowTextWithToolTip from './OverflowTextWithToolTip';

function ContactEntity(props) {
  const {
    contactId,
    contactDisplayName,
    hideDisplayName,
    onContactUpdate,
    maxWidth,
    shallRefetchOnContactIdChange = false,
    claimId,
  } = props;

  const [contactAnchorEl, setContactAnchorEl] = useState(undefined);
  const [editContactOpen, setEditContactOpen] = useState(undefined);

  const [contact, setContact] = useState(undefined);

  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user } = useCms();

  const classes = useStyles();

  const { userHasContextPermissions } = useRestrictedPermissions();
  const hasContactReadPermission = useHasPermission({
    action: PERMISSION_ACTIONS.CONTACT,
    verb: PERMISSION_VERBS.READ,
  });

  const handleButtonClick = (e) => {
    e.preventDefault();

    if (!hasContactReadPermission) {
      return;
    }

    fetchContactDetails();
    setContactAnchorEl(e.currentTarget);
  };

  const updateClaimIfPossible = () => {
    if (onAsyncClaimUpdate) {
      onAsyncClaimUpdate();
    }
  };

  const handleContactUpdate = async () => {
    updateClaimIfPossible();

    const contact = await fetchContactDetails();
    // contact === undefined if fetchContactDetails failed fetching
    if (onContactUpdate && contact !== undefined) {
      await onContactUpdate(contact);
    }
  };

  const fetchContactDetails = async () => {
    try {
      const contact = await getContact(contactId, claim);
      setContact(contact);
      return contact;
    } catch (error) {
      reportAxiosError(error);
    }
  };

  React.useEffect(() => {
    if (!contact && !contactDisplayName) {
      fetchContactDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    shallRefetchOnContactIdChange && fetchContactDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  const closePopover = () => setContactAnchorEl(undefined);

  const contactText = contact ? contact.full_name : contactDisplayName;

  return (
    <Fragment>
      <span className={classes.containerCentered}>
        <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.READ}>
          <ContactIcon
            className={cn(classes.leftButtonIcon, {
              [classes.hoverableIcon]: userHasContextPermissions,
              [classes.hoverableIconDisabled]: !userHasContextPermissions,
            })}
            onClick={handleButtonClick}
            disabled={user.role.is_view_only && !claim}
          />
        </PermissionsButtonWrapper>
        <OverflowTextWithToolTip maxWidth={maxWidth ? maxWidth : '150px'}>
          {!hideDisplayName && contactText}
        </OverflowTextWithToolTip>
      </span>
      <Popover
        key={contact && contact.id} // force creation of new popover once contact is loaded (to fit dimensions)
        open={Boolean(contactAnchorEl)}
        anchorEl={contactAnchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {!contact ? (
          <Card>
            <CircularProgress />
          </Card>
        ) : (
          <ContactMiniCard
            contact={contact}
            onEdit={() => setEditContactOpen(true)}
            onClose={closePopover}
            onUpdate={() => updateClaimIfPossible()}
            claimId={claimId}
          />
        )}
      </Popover>
      {
        // the following dialog can only be called from the ContactMiniCard which is rendered only after fetchContactDetails returns
      }
      {contact && editContactOpen && (
        <EditContactDialog
          contact={contact}
          onClose={() => setEditContactOpen(false)}
          onContactUpdate={handleContactUpdate}
        />
      )}
    </Fragment>
  );
}

ContactEntity.propTypes = {
  contactId: PropTypes.number.isRequired,
  hideDisplayName: PropTypes.bool,
  contactDisplayName: requiredIf(PropTypes.string, (props) => !props.hideDisplayName),
  onContactUpdate: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  claimId: PropTypes.number,
  shallRefetchOnContactIdChange: PropTypes.bool,
};

async function getContact(contactId, claim = null) {
  const isClaimContact = claim && claim.contacts.map((contact) => contact.id).includes(contactId);
  const result = await axios.get(
    isClaimContact ? `/api/v1/claims/${claim.id}/contacts/${contactId}` : `/api/v1/contacts/${contactId}`
  );
  const contact = contactPopulate(result.data);

  if (isClaimContact) {
    const res = await axios.get(`/api/v1/claims/${claim.id}/contacts/${contactId}/exposure_labels`);
    const exposureLabels = res.data;
    contact.exposure_ids = exposureLabels.map((exposureLabel) => exposureLabel.exposure_id);
    contact.immutable_exposure_ids = exposureLabels
      .filter((exposureLabel) => exposureLabel.is_immutable)
      .map((exposureLabel) => exposureLabel.exposure_id);
  }

  return contact;
}

function getSupportedContactRolesTypes(organizationRolesDict, initialRolesList, claimTypesList) {
  let rolesList = [...initialRolesList];
  rolesList = rolesList.filter(
    (roleType) =>
      organizationRolesDict[roleType] &&
      (organizationRolesDict[roleType].lobs.length > 0
        ? _.intersection(organizationRolesDict[roleType].lobs, claimTypesList).length !== 0
        : true)
  );

  rolesList = rolesList.filter(
    (roleType) =>
      organizationRolesDict[roleType] &&
      (organizationRolesDict[roleType].excluded_claim_types
        ? _.difference(claimTypesList, organizationRolesDict[roleType].excluded_claim_types).length !== 0
        : true)
  );

  return rolesList;
}

export { ContactEntity, getContact, getSupportedContactRolesTypes };
