const ROLES_TYPES_DICT = {
  system: {
    desc: 'System',
    is_adjuster: false,
    is_hidden: true,
    can_view_sensitive_docs: true,
  },
  service_account: {
    desc: 'Service Account',
    is_adjuster: false,
    is_hidden: true,
    can_view_sensitive_docs: true,
  },
  vendor_service_account: {
    desc: 'Vendor Service Account',
    is_adjuster: false,
    is_hidden: true,
    can_view_sensitive_docs: true,
  },
  claim_rep: {
    desc: 'Claim Rep',
    is_adjuster: true,
    can_view_sensitive_docs: true,
  },
  claim_rep_nonsensitive: {
    desc: 'Claim Rep - Nonsensitive',
    is_adjuster: true,
  },
  field_rep: {
    desc: 'Field Rep',
    is_adjuster: true,
  },
  phone_rep: {
    desc: 'Phone Rep',
    is_adjuster: false,
  },
  read_only: {
    desc: 'External User',
    is_adjuster: true,
  },
  it_manager: {
    desc: 'IT Manager',
    is_adjuster: false,
  },
  osha_rep: {
    desc: 'OSHA Rep',
    is_adjuster: false,
  },
} as const;

export default ROLES_TYPES_DICT;
