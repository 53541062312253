const TREATMENT_SURGERIES_DICT = {
  no_treatment: {
    desc: 'No treatment',
  },
  chiropractic_treatment: {
    desc: 'Chiropractic Treatment',
  },
  'Acupuncture treatment': {
    desc: 'Acupuncture Treatment',
  },
  physical_therapy: {
    desc: 'Physical Therapy',
  },
  injections: {
    desc: ' Injections',
  },
  arthroscopic_surgery: {
    desc: 'Arthroscopic Surgery',
  },
  cervical_discectomy: {
    desc: 'Cervical Discectomy',
  },
  lumbar_discectomy: {
    desc: ' Lumbar Discectomy',
  },
  other_discectomy: {
    desc: 'Other Discectomy',
  },
  other_surgery: {
    desc: 'Other Surgery',
  },
} as const;

export default TREATMENT_SURGERIES_DICT;
