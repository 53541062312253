import React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';

import type { CheckboxProps, LabelPosition } from '~/components/core/Atomic/Checkboxes/types';
import colors from '~/theme/tailwind/colors';
import cn from '~/Utils/cn';

const LabelPositionToClassMap: Record<LabelPosition, string> = {
  top: 'flex-col',
  bottom: 'flex-col-reverse',
  right: 'flex-row-reverse',
  left: 'flex-row',
};

const Checkbox: React.FC<CheckboxProps> = ({ ...checkboxProps }) => {
  const primaryColor = colors.teal[700];
  const style = !checkboxProps.color || checkboxProps.color === 'primary' ? { color: primaryColor } : {};
  const labelPosition = checkboxProps.labelPosition || 'right';
  const muiCheckboxPropsOnly = { ...checkboxProps };
  delete muiCheckboxPropsOnly.labelPosition;
  const CheckboxComponent = () => <MuiCheckbox {...muiCheckboxPropsOnly} style={style} />;

  if (checkboxProps.label) {
    const labelClass = LabelPositionToClassMap[labelPosition];
    return (
      <span className={cn('flex items-center', labelClass)}>
        <span>{checkboxProps.label}</span>
        <CheckboxComponent />
      </span>
    );
  }
  return <CheckboxComponent />;
};

export default Checkbox;
