const ORGANIZATION_TYPES = {
  operational: {
    desc: 'Operational',
    isDefault: true,
  },
  demo: {
    desc: 'Demo',
  },
} as const;

export default ORGANIZATION_TYPES;
