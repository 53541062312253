import React from 'react';

import { serverDateToLocal } from '../../../../../DateTimeUtils';
import { PropertyDetails } from '../../../../core';
import { PropertyDetailsRow } from '../../../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS } from '../../../FormFragments/FormFieldsDefinitions';
import type { Option } from '../../../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../../../hooks/useLegalActionsConfiguration';
import { getLabelByKey } from '../../../LegalActionsUtils';
import type { DisputeResolutionProfessionalApiResponse } from '../../../types';

interface DisputeResolutionProfessionalContentProps {
  drp: DisputeResolutionProfessionalApiResponse;
  rows: 1 | 2;
}

export const DisputeResolutionProfessionalContent: React.FC<DisputeResolutionProfessionalContentProps> = ({
  drp,
  rows,
}) => {
  const { disputeResolutionProfessionalTypeOptions } = useLegalActionsConfiguration();

  const typeComponent = (
    <PropertyDetails
      title={DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.type.label}
      text={getLabelByKey(disputeResolutionProfessionalTypeOptions as Option[], drp.type)}
    />
  );
  const contactComponent = (
    <PropertyDetails title={DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.contact.label} text={drp.contact_name} />
  );
  const startDateComponent = (
    <PropertyDetails
      title={DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.startDate.label}
      text={serverDateToLocal(drp.start_date)}
    />
  );

  return rows === 1 ? (
    <PropertyDetailsRow>
      {typeComponent}
      {contactComponent}
      {startDateComponent}
    </PropertyDetailsRow>
  ) : (
    <>
      <div className="flex w-full">
        <PropertyDetailsRow>
          {typeComponent}
          {contactComponent}
        </PropertyDetailsRow>
      </div>
      <div className="flex w-full">
        <PropertyDetailsRow>
          {startDateComponent}
          <PropertyDetails
            title={DISPUTE_RESOLUTION_PROFESSIONAL_FIELDS.endDate.label}
            text={drp.end_date ? serverDateToLocal(drp.end_date) : ''}
          />
        </PropertyDetailsRow>
      </div>
    </>
  );
};
