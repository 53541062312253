import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { isExpirationAfterEffectiveDT } from '../../PolicyUtils';
import { isBoostOrganization } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';
import {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  TextFieldFormik,
  TimePickerTextFieldFormik,
} from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const petPolicyDetailsInitialValues = {
  policy_number: '',
  policy_effective_date: '',
  effective_time: '',
  policy_expiration_date: '',
  expiration_time: '',
  underwriting_company: '',
  insured_contact_id: '',
  policy_limit: '',
  limit_lifetime: '',
  limit_per_incident: '',
};

function getPolicyInitialValues(policy) {
  let policyInitialValues = {};
  for (const key of Object.keys(petPolicyDetailsInitialValues)) {
    policyInitialValues[key] = policy[key];
  }
  return policyInitialValues;
}

function PetManualPolicyDetailsDialog(props) {
  const { onClose, onSubmitPolicy, isExistingPolicy } = props;
  const { claim } = useClaim();
  const { userOrganization } = useCms();
  const classes = useStyles();
  const isBoost = isBoostOrganization(userOrganization);
  const { coverageConfiguration } = useOrganization();

  const newPetPolicyFormikInner = (formikProps) => {
    const { isSubmitting, handleSubmit } = formikProps;

    return (
      <CardDialog isDialog={true} title="Policy Details" maxWidth="sm" onClose={onClose} preventClose={isSubmitting}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextFieldFormik id="policy_number" label="Policy Number" fullWidth className={classes.textField} />
          </Grid>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="insured_contact"
              label="Insured"
              className={classes.textField}
              acceptedRoles={['insured']}
              fullWidth
              fixedSearchResults
              claimId={claim.Id}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="policy_effective_date"
              label="Effective Date"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="effective_time"
              label="Effective Time"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="policy_expiration_date"
              label="Expiration Date"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="expiration_time"
              label="Expiration Time"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          {coverageConfiguration.limit_per_claim && (
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik id="limit_per_claim" label="Limit per Claim" fullWidth />
            </Grid>
          )}
          {isBoost && (
            <>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="policy_limit"
                  label="Policy Limit"
                  allowNegative={false}
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="limit_per_incident"
                  label="Policy Limit Pet Incident"
                  allowNegative={false}
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="limit_lifetime"
                  label="Policy Limit Lifetime"
                  allowNegative={false}
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                {isExistingPolicy ? 'Update' : 'Create'}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardDialog>
    );
  };

  return (
    <Formik
      initialValues={
        isExistingPolicy
          ? {
              ...getPolicyInitialValues(claim.policy),
              type: 'pet_policy',
              insured_contact_id: claim.policy.insured_contact_id,
            }
          : {
              ...petPolicyDetailsInitialValues,
              type: 'pet_policy',
              insured_contact_id: claim.policy.insured_contact_id,
            }
      }
      validationSchema={Yup.object().shape({
        policy_number: Yup.string().required('Required'),
        policy_effective_date: Yup.string().required('Required'),
        effective_time: Yup.string()
          .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
          .nullable(),
        policy_expiration_date: Yup.string()
          .required('Required')
          .test(
            'expiration_after_effective_dt',
            'Expiration date must be after effective date',
            isExpirationAfterEffectiveDT
          ),
        expiration_time: Yup.string()
          .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
          .nullable(),
        insured_contact_id: Yup.number().required('Required'),
        policy_limit: isBoost ? Yup.number().required('Required') : undefined,
        limit_lifetime: isBoost ? Yup.number().required('Required') : undefined,
        limit_per_incident: isBoost ? Yup.number().required('Required') : undefined,
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmitPolicy(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => newPetPolicyFormikInner(formikProps)}
    </Formik>
  );
}

PetManualPolicyDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmitPolicy: PropTypes.func.isRequired,
  isExistingPolicy: PropTypes.bool,
};

export default PetManualPolicyDetailsDialog;
