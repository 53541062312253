import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, omit } from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';

import {
  getConfiguredFieldsEmptyFormikInitialValues,
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';

import { BodilyInjuryFragment } from './BodilyInjuryFragment';
import { GeneralDetailsFragment } from './GeneralDetailsFragment';
import { PecuniaryLossItemsSectionFragment } from './PecuniaryLossItemsSectionFragment';
import { PropertyLossFragment } from './PropertyLossFragment';
import { RelatedPropertyLossFragment } from './RelatedPropertyLossFragment';

import styles from './index.module.scss';

const ENTITIES_TYPES = {
  PERSON: 'person',
  PROPERTY: 'property',
};

const getInvolvedGenericDefinedFields = (incidentConfiguration, involvedParty) => {
  const involvedParties = get(incidentConfiguration, 'involved_parties', {});
  const involvedPartyFields = get(involvedParties, involvedParty, {});
  const relatedPropertyLossFields = get(involvedPartyFields, 'related_property_loss', {});
  const propertyDamageFields = get(involvedPartyFields, 'property_damage', {});
  const bodilyInjuryLossFields = get(involvedPartyFields, 'bodily_injury_loss', {});
  const pecuniary_loss = get(involvedPartyFields, 'pecuniary_loss', {});
  return {
    ...preparePreDefinedFields(involvedPartyFields),
    related_property_loss: preparePreDefinedFields(relatedPropertyLossFields),
    property_damage: preparePreDefinedFields(propertyDamageFields),
    bodily_injury_loss: preparePreDefinedFields(bodilyInjuryLossFields),
    pecuniary_loss: preparePreDefinedFields(pecuniary_loss),
  };
};

const involvedGenericFields = (incidentConfiguration, involvedParty) => {
  const fields = getInvolvedGenericDefinedFields(incidentConfiguration, involvedParty);
  const configuredFields = get(incidentConfiguration, `involved_parties.${involvedParty}.configured_fields`, []);

  return {
    ...getPredefinedFieldsEmptyFormikInitialValues(fields),
    configured_fields_values: configuredFields && getConfiguredFieldsEmptyFormikInitialValues(configuredFields),
    related_property_loss: {
      ...getPredefinedFieldsEmptyFormikInitialValues(fields?.related_property_loss),
      items: [],
    },
    property_damage: getPredefinedFieldsEmptyFormikInitialValues(fields?.property_damage),
    bodily_injury_loss: {
      ...getPredefinedFieldsEmptyFormikInitialValues(fields?.bodily_injury_loss),
      bodily_injuries: [],
    },
    pecuniary_loss: {
      losses: [],
    },
  };
};

const getInvolvedGenericValidationFields = (incidentConfiguration, involvedParty, policy) => {
  const isManualPolicyOnFnol = policy.is_manual; // is_manual - appears only on fnol
  const isCovered =
    policy &&
    !isManualPolicyOnFnol &&
    get(incidentConfiguration, `involved_parties.${involvedParty}.is_first_party`, false) &&
    !isEmpty(policy?.covered_objects);
  const loss_object_fields = ['related_property_loss', 'property_damage', 'bodily_injury_loss', 'pecuniary_loss'];
  const fields = getInvolvedGenericDefinedFields(incidentConfiguration, involvedParty);
  const fieldsWithoutLossObjects = omit(fields, loss_object_fields);

  return {
    ...getAdditionalDataValidations(fieldsWithoutLossObjects),
    related_property_loss: Yup.object()
      .nullable()
      .when('is_related_property_loss', {
        is: 'yes',
        then: Yup.object().shape({
          ...getAdditionalDataValidations(fields?.related_property_loss),
        }),
      }),
    property_damage: Yup.object()
      .nullable()
      .when('is_property_damaged', {
        is: 'yes',
        then: Yup.object().shape({
          ...getAdditionalDataValidations(fields?.property_damage),
        }),
      }),
    bodily_injury_loss: Yup.object()
      .nullable()
      .when('is_injured', {
        is: 'yes',
        then: Yup.object().shape({
          ...getAdditionalDataValidations(fields?.bodily_injury_loss),
        }),
      }),
    pecuniary_loss: Yup.object()
      .nullable()
      .when('is_pecuniary_loss', {
        is: 'yes',
        then: Yup.object().shape({
          ...getAdditionalDataValidations(fields?.pecuniary_loss),
        }),
      }),
    covered_object_id: isCovered
      ? Yup.number()
          .nullable()
          .when('property_description', {
            is: (value) => !!value,
            then: Yup.number().nullable(),
            otherwise: Yup.number().nullable().required('Select a covered object'),
          })
      : undefined,
  };
};

const GenericSectionInnerCard = ({
  sectionTitle,
  isSectionAllowed,
  isSectionActive,
  preDefinedFields,
  enablingFieldId,
  enablingValue,
  collapsibleTitle,
  children,
}) => {
  if (!isSectionAllowed || !isSectionActive) {
    return <></>;
  }

  return (
    <Grid container className={styles.involvedContainer}>
      <InnerCard title={sectionTitle} className={styles.innerCard} titleClassName={styles.genericTitle}>
        <PreDefinedField id={enablingFieldId} fields={preDefinedFields} inline />
        {children && (
          <CollapsibleWrapper
            title={collapsibleTitle}
            actionCard
            isCardShadow
            noBorderLine
            disabled={enablingValue === '' || enablingValue === 'no' || enablingValue === 'unknown'}
          >
            {children}
          </CollapsibleWrapper>
        )}
      </InnerCard>
    </Grid>
  );
};

GenericSectionInnerCard.propTypes = {
  sectionTitle: PropTypes.string,
  collapsibleTitle: PropTypes.string,
  children: PropTypes.node,
  preDefinedFields: PropTypes.node,
  isSectionAllowed: PropTypes.bool,
  isSectionActive: PropTypes.bool,
  enablingFieldId: PropTypes.string,
  enablingValue: PropTypes.string,
};

const InvolvedGenericFragment = ({ involvedParty }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInvolvedGenericDefinedFields(incidentConfiguration, involvedParty);

  return (
    <Grid container spacing={1}>
      <GeneralDetailsFragment involvedParty={involvedParty} preDefinedFields={preDefinedFields} />

      <PropertyLossFragment involvedParty={involvedParty} preDefinedFields={preDefinedFields} />

      <BodilyInjuryFragment involvedParty={involvedParty} preDefinedFields={preDefinedFields} />

      <RelatedPropertyLossFragment involvedParty={involvedParty} preDefinedFields={preDefinedFields} />

      <PecuniaryLossItemsSectionFragment involvedParty={involvedParty} preDefinedFields={preDefinedFields} />
    </Grid>
  );
};

InvolvedGenericFragment.propTypes = {
  showOnly: PropTypes.bool,
  involvedParty: PropTypes.string,
};

export {
  ENTITIES_TYPES,
  GenericSectionInnerCard,
  getInvolvedGenericValidationFields,
  involvedGenericFields,
  InvolvedGenericFragment,
};
