import React from 'react';

import { getNamesOfMissingEmailsFromRecipients } from '~/components/communications/EmailCommunicationCard/utils';
import Typography from '~/components/core/Atomic/Typography';

const ContactsValidEmailWarning = (ccContacts) => {
  const removedEmails = getNamesOfMissingEmailsFromRecipients(ccContacts);

  return (
    <>
      {removedEmails.length > 0 && (
        <span>
          <Typography variant="body2" style={{ color: 'red' }}>
            Email address no longer in use for contact{`${removedEmails.length > 1 ? 's' : ''}`}:{' '}
            {removedEmails.join(', ')}
          </Typography>
        </span>
      )}
    </>
  );
};

export default ContactsValidEmailWarning;
