import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const PhoneNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      d="M26.5 31.0032C22.89 31.0032 19.5 29.6032 16.95 27.0532C14.4 24.5032 13 21.1132 13 17.5032C13 16.2232 13.47 14.9932 14.31 14.0332C15.15 13.0732 16.32 12.4532 17.58 12.2932C17.9 12.2532 18.23 12.3132 18.52 12.4832C18.8 12.6432 19.03 12.9032 19.15 13.2032L21.03 17.5932C21.13 17.8132 21.17 18.0632 21.15 18.3032C21.13 18.5432 21.05 18.7832 20.92 18.9932L19.35 21.3732C20.05 22.8032 21.22 23.9732 22.65 24.6732L24.99 23.1032C25.19 22.9632 25.44 22.8832 25.69 22.8532C25.94 22.8332 26.2 22.8732 26.43 22.9732L30.81 24.8532C31.1 24.9732 31.36 25.2032 31.52 25.4832C31.68 25.7632 31.75 26.0932 31.71 26.4132C31.55 27.6832 30.93 28.8432 29.97 29.6832C29.01 30.5232 27.78 30.9932 26.5 30.9932V31.0032ZM17.77 13.7832C16.87 13.8932 16.04 14.3432 15.44 15.0232C14.84 15.7032 14.51 16.5832 14.5 17.5032C14.5 20.7132 15.75 23.7232 18.01 25.9832C20.28 28.2532 23.29 29.4932 26.5 29.4932C27.41 29.4932 28.29 29.1632 28.98 28.5632C29.66 27.9632 30.11 27.1332 30.22 26.2232L25.83 24.3432L23.49 25.9132C23.28 26.0532 23.03 26.1432 22.77 26.1632C22.51 26.1832 22.25 26.1332 22.02 26.0232C20.27 25.1832 18.85 23.7632 18.01 22.0232C17.9 21.7932 17.85 21.5332 17.87 21.2832C17.89 21.0332 17.97 20.7832 18.11 20.5632L19.68 18.1832L17.79 13.7932L17.77 13.7832Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default PhoneNoteIcon;
