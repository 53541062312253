import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ReferIcon = (props) => (
  <SvgIcon fill="none" {...props}>
    <path
      d="M13.1437 2.24373L1.49375 5.52498C1.39458 5.55209 1.30618 5.60912 1.24058 5.68829C1.17499 5.76746 1.1354 5.86492 1.1272 5.96741C1.119 6.06989 1.1426 6.17241 1.19477 6.261C1.24694 6.34959 1.32515 6.41994 1.41875 6.46248L6.76875 8.99373C6.87355 9.04226 6.95771 9.12643 7.00625 9.23123L9.5375 14.5812C9.58003 14.6748 9.65039 14.753 9.73898 14.8052C9.82757 14.8574 9.93009 14.881 10.0326 14.8728C10.1351 14.8646 10.2325 14.825 10.3117 14.7594C10.3909 14.6938 10.4479 14.6054 10.475 14.5062L13.7562 2.85623C13.7811 2.77122 13.7827 2.68108 13.7607 2.59527C13.7387 2.50945 13.6941 2.43113 13.6315 2.36849C13.5689 2.30586 13.4905 2.26123 13.4047 2.23928C13.3189 2.21733 13.2288 2.21887 13.1437 2.24373V2.24373Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.93127 9.06877L9.75627 6.24377" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default ReferIcon;
