import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const DocumentNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      d="M18.25 22.75C18.25 22.3358 18.5858 22 19 22H25C25.4142 22 25.75 22.3358 25.75 22.75C25.75 23.1642 25.4142 23.5 25 23.5H19C18.5858 23.5 18.25 23.1642 18.25 22.75Z"
      fill={iconColor}
    />
    <path
      d="M19 25C18.5858 25 18.25 25.3358 18.25 25.75C18.25 26.1642 18.5858 26.5 19 26.5H25C25.4142 26.5 25.75 26.1642 25.75 25.75C25.75 25.3358 25.4142 25 25 25H19Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.25 12.25C14.8522 12.25 14.4706 12.408 14.1893 12.6893C13.908 12.9706 13.75 13.3522 13.75 13.75V30.25C13.75 30.6478 13.908 31.0294 14.1893 31.3107C14.4706 31.592 14.8522 31.75 15.25 31.75H28.75C29.1478 31.75 29.5294 31.592 29.8107 31.3107C30.092 31.0294 30.25 30.6478 30.25 30.25V18.25C30.25 18.0486 30.1706 17.8658 30.0415 17.7311L30.0303 17.7197L24.7803 12.4697L24.7689 12.4585C24.6342 12.3294 24.4514 12.25 24.25 12.25H15.25ZM23.5 13.75H15.25V30.25H28.75V19H24.25C23.8358 19 23.5 18.6642 23.5 18.25V13.75ZM27.6893 17.5L25 14.8107V17.5H27.6893Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default DocumentNoteIcon;
