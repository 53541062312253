import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const SubOrganizationContext = createContext({});

const SubOrganizationContextProvider = ({ subOrganization, children }) => {
  return <SubOrganizationContext.Provider value={{ subOrganization }}>{children}</SubOrganizationContext.Provider>;
};

SubOrganizationContextProvider.propTypes = {
  subOrganization: PropTypes.object,
  children: PropTypes.node.isRequired,
};

const useSubOrganization = () => {
  const subOrganizationContext = useContext(SubOrganizationContext);
  return { subOrganization: subOrganizationContext.subOrganization };
};

export { SubOrganizationContextProvider, useSubOrganization };
