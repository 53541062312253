import React from 'react';

import { Text } from '~/components/core/TextComponents';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import cn from '~/Utils/cn';

import { typeToSettings } from './constants';
import type { MinimizedBarProps } from './types';

const MinimizedBar: React.FC<MinimizedBarProps> = ({ type, onClick, children }) => {
  const settings = typeToSettings[type];
  const IconComponent = settings.icon;

  return (
    <button
      className={cn(
        'inline-flex h-[48px] w-[340px] cursor-pointer items-center rounded-lg border-none',
        settings.bgColor
      )}
      onClick={onClick}
      data-testid={`minimized-bar-${type.toLowerCase()}`}
    >
      {IconComponent ? (
        <IconComponent
          iconColor="currentColor"
          className={cn('m-4 box-content rounded-lg bg-white p-2', settings.textColor)}
          size={24}
        />
      ) : settings?.iconClassName ? (
        <div
          className={cn('m-4 box-content rounded-lg bg-white p-2 text-3xl', settings.iconClassName, settings.textColor)}
        />
      ) : null}
      {children ? (
        <Text
          className="ml-4 inline-flex items-center text-white"
          variant={Text.VARIANTS.SM}
          weight={Text.WEIGHTS.REGULAR}
        >
          <OverflowTextWithToolTip textWrapperClassName="w-[240px] text-start">{children}</OverflowTextWithToolTip>
        </Text>
      ) : null}
    </button>
  );
};

export default MinimizedBar;
