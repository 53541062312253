import React from 'react';

import { getCommunicationChannelIconComponent } from '~/components/communications/CommunicationUtils';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';

import type { ChannelType } from './constants';

interface CommunicationIdentifierSectionProps {
  channel: ChannelType;
  communicationIdentifier: string;
}

const CommunicationIdentifierSection: React.FC<CommunicationIdentifierSectionProps> = ({
  channel,
  communicationIdentifier,
}) => (
  <div className="flex items-center">
    {getCommunicationChannelIconComponent(channel)}
    <OverflowTextWithToolTip maxWidth="200px">&nbsp;{communicationIdentifier}</OverflowTextWithToolTip>
  </div>
);

export default CommunicationIdentifierSection;
