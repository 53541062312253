import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import EmptyState from '~/components/core/EmptyState';
import { compareDatesStrings, serverDateTimeToLocal } from '~/DateTimeUtils';
import { reportAxiosError, stringCmp } from '~/Utils';

import ViewCommunicationCardContainer from './communications/ViewCommunicationCardContainer';
import SkeletonTable from './core/Skeletons/SkeletonTable';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { MultiSelectFilter, SortableTable } from './core';
import { AllDoneIllustration } from './illustrations';
import useOrganization from './OrganizationContext';
import useDataFetcher from './useDataFetcher';

const GeneralEmailCommunicationsTab = () => {
  const classes = useStyles();
  const { setPageTitle, user, userOrganization } = useCms();
  const { emailDomains } = useOrganization();
  const {
    isLoading,
    isError,
    data: unclassifiedEmails = [],
    reloadData,
  } = useDataFetcher(`/api/v1/organizations/${user.organization_id}/unclassified_emails`, {});
  const [communicationId, setCommunicationId] = useState();
  const [selectedUnclassifiedEmailId, setSelectedUnclassifiedEmailId] = useState();
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [emailsToShow, setEmailsToShow] = useState([]);

  const isSubOrgDomainsEnabled = userOrganization.is_suborgs_domains_enabled;
  const emailDomainsStrings = emailDomains?.map((emailDomain) => emailDomain.domain);

  const setDisplayedId = useCallback(
    (id) => {
      const unclassifiedEmail = unclassifiedEmails.find((unclassifiedEmail) => unclassifiedEmail.id === id);
      setSelectedUnclassifiedEmailId(unclassifiedEmail.id);
      setCommunicationId(unclassifiedEmail.communication_id);
    },
    [unclassifiedEmails]
  );

  const updateUnclassifiedEmails = useCallback((data) => {
    setSelectedUnclassifiedEmailId(undefined);
    setCommunicationId(undefined);
    !isEmpty(data) && data.sort((a, b) => compareDatesStrings(a?.arrival_date, b?.arrival_date));
  }, []);

  const reportAndReload = async (error) => {
    reportAxiosError(error);
    await reloadData();
  };

  const onUpdate = async () => {
    try {
      await reloadData();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `/api/v1/organizations/${user.organization_id}/unclassified_emails/${selectedUnclassifiedEmailId}`
      );
    } catch (error) {
      reportAndReload(error);
    }
  };

  const handleRefer = async (user_id) => {
    try {
      await axios.post(
        `/api/v1/organizations/${user.organization_id}/unclassified_emails/${selectedUnclassifiedEmailId}/refer_to_user/${user_id}`
      );
    } catch (error) {
      reportAndReload(error);
    }
  };

  const handleAttach = async (claim_id, attachExtraParams) => {
    try {
      await axios.post(
        `/api/v1/organizations/${user.organization_id}/unclassified_emails/${selectedUnclassifiedEmailId}/attach_to_claim/${claim_id}`,
        attachExtraParams
      );
    } catch (error) {
      reportAndReload(error);
    }
  };

  const handleForward = async (data) => {
    try {
      await axios.post(
        `/api/v1/organizations/${user.organization_id}/unclassified_emails/${selectedUnclassifiedEmailId}/forward`,
        data
      );
    } catch (error) {
      reportAndReload(error);
      throw error;
    }
  };

  const handleReply = async (data) => {
    try {
      await axios.post(
        `/api/v1/organizations/${user.organization_id}/unclassified_emails/${selectedUnclassifiedEmailId}/reply`,
        data
      );
    } catch (error) {
      reportAndReload(error);
    }
  };

  useEffect(
    () => setPageTitle('General Mail Classification', 'General Mail Classification - Five Sigma CMS'),
    [setPageTitle]
  );

  useEffect(() => {
    let filteredUnclassifiedEmails = unclassifiedEmails || [];

    if (isSubOrgDomainsEnabled && selectedDomains.length > 0 && unclassifiedEmails) {
      filteredUnclassifiedEmails = unclassifiedEmails.filter((unclassifiedEmail) =>
        selectedDomains.includes(unclassifiedEmail.email_domain.domain)
      );
    }
    setEmailsToShow(filteredUnclassifiedEmails);
    updateUnclassifiedEmails(filteredUnclassifiedEmails);
  }, [unclassifiedEmails, updateUnclassifiedEmails, selectedDomains, isSubOrgDomainsEnabled]);

  const columnData = [
    {
      id: 'contact',
      numeric: false,
      label: 'Contact',
      overflow: true,
      maxWidthPercentage: 15,
      specialCell: (comm) =>
        comm.contact
          ? comm.contact.full_name +
            (comm.cc_contacts_ids && comm.cc_contacts_ids.length > 0 ? ` (+${comm.cc_contacts_ids.length})` : '')
          : comm.specific_identifier,
      specialCmpFunc: (comm1, comm2) =>
        stringCmp(
          comm1.contact?.full_name || comm1.specific_identifier || '',
          comm2.contact?.full_name || comm2.specific_identifier || ''
        ),
    },
    {
      id: 'subject',
      numeric: false,
      label: 'Subject',
      maxWidthPercentage: 25,
      overflow: true,
      specialCell: (comm) => comm.subject,
    },
    {
      id: 'arrival_date',
      numeric: false,
      rightPaddingOnly: true,
      label: 'Time',
      nowrap: true,
      specialCell: (comm) => serverDateTimeToLocal(comm.arrival_date),
    },
  ];

  if (isSubOrgDomainsEnabled) {
    columnData.push({
      id: 'email_domain',
      numeric: false,
      label: 'Email Domain',
      specialCell: (comm) => comm.email_domain?.domain,
    });
  }

  const orderByColumnIdx = columnData.findIndex((column) => column.id === 'arrival_date');
  const WarningMessage = () => (
    <Grid container direction="row" style={{ paddingBottom: '20px' }}>
      <Grid item xs={12}>
        <AlertBanner
          alertType={AlertBanner.ALERT_TYPES.INFO}
          note="Please Note - Any action on an email will delete it from the queue."
          withIcon
        />
      </Grid>
    </Grid>
  );

  const displayLoading = isLoading && isEmpty(unclassifiedEmails);

  return (
    <div className={classes.pageBody}>
      <Grid container direction="row" style={{ padding: '20px' }}>
        {!isEmpty(unclassifiedEmails) && <WarningMessage />}
        {isSubOrgDomainsEnabled && (
          <Grid item xs={12}>
            <MultiSelectFilter
              label="Email Domain"
              value={selectedDomains}
              onChange={setSelectedDomains}
              options={emailDomainsStrings}
              withOptionChips
            />
          </Grid>
        )}
        <Grid item xs={communicationId ? 8 : 12}>
          <CardDialog noCardTitle>
            {(displayLoading || isError) && (
              <SkeletonTable
                rowsCount={15}
                columnsCount={columnData.length}
                maxHeight={`${window.innerHeight - 300}px`}
                isError={isError}
              />
            )}
            {!displayLoading && (
              <SortableTable
                rows={emailsToShow}
                columns={columnData}
                onRowClick={setDisplayedId}
                selectedRowsIds={[selectedUnclassifiedEmailId]}
                maxHeight={`${window.innerHeight - 300}px`}
                autoPaginateRowsPerPage={15}
                defaultOrderColumn={orderByColumnIdx}
                order="desc"
                stickyHeader
                emptyStateComponent={
                  <EmptyState
                    title="All Done!"
                    subtitle="No new mails were received."
                    illustration={<AllDoneIllustration />}
                  />
                }
              />
            )}
          </CardDialog>
        </Grid>
        {communicationId && (
          <Grid item xs={4}>
            <CardDialog
              headerStyle={{ padding: '0' }}
              contentStyle={{ padding: '8px 0 0 0' }}
              containerStyle={{ marginLeft: '20px', maxHeight: `${window.innerHeight - 248}px`, overflow: 'auto' }}
              noCardTitle
            >
              <ViewCommunicationCardContainer
                key={communicationId}
                communicationId={communicationId}
                onUpdate={onUpdate}
                displayAttachClaim={false}
                displayGeneralQueueActions
                onDelete={handleDelete}
                onRefer={handleRefer}
                onAttach={handleAttach}
                onForward={handleForward}
                onReply={handleReply}
              />
            </CardDialog>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default GeneralEmailCommunicationsTab;
