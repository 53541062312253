import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const DaReinspectionIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 12C11.25 11.5858 11.5858 11.25 12 11.25H20.25C20.6642 11.25 21 11.5858 21 12C21 12.4142 20.6642 12.75 20.25 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 6C11.25 5.58579 11.5858 5.25 12 5.25H20.25C20.6642 5.25 21 5.58579 21 6C21 6.41422 20.6642 6.75 20.25 6.75H12C11.5858 6.75 11.25 6.41422 11.25 6Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 18C11.25 17.5858 11.5858 17.25 12 17.25H20.25C20.6642 17.25 21 17.5858 21 18C21 18.4142 20.6642 18.75 20.25 18.75H12C11.5858 18.75 11.25 18.4142 11.25 18Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.17635 3.99156C9.45716 4.29606 9.43795 4.77054 9.13345 5.05135L5.88032 8.05135C5.5928 8.3165 5.14978 8.31618 4.86264 8.05062L3.24076 6.55062C2.93667 6.26937 2.91814 5.79486 3.19939 5.49077C3.48063 5.18667 3.95515 5.16814 4.25924 5.44939L5.37261 6.4791L8.11656 3.94866C8.42106 3.66785 8.89554 3.68706 9.17635 3.99156Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.17635 9.99156C9.45716 10.2961 9.43795 10.7705 9.13345 11.0514L5.88032 14.0513C5.5928 14.3165 5.14978 14.3162 4.86264 14.0506L3.24076 12.5506C2.93667 12.2694 2.91814 11.7949 3.19939 11.4908C3.48063 11.1867 3.95515 11.1681 4.25924 11.4494L5.37261 12.4791L8.11656 9.94866C8.42106 9.66785 8.89554 9.68706 9.17635 9.99156Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.17635 15.9916C9.45716 16.2961 9.43795 16.7705 9.13345 17.0513L5.88032 20.0513C5.5928 20.3165 5.14978 20.3162 4.86264 20.0506L3.24076 18.5506C2.93667 18.2694 2.91814 17.7949 3.19939 17.4908C3.48063 17.1867 3.95515 17.1681 4.25924 17.4494L5.37261 18.4791L8.11656 15.9487C8.42106 15.6679 8.89554 15.6871 9.17635 15.9916Z"
      fill={iconColor}
    />
  </SvgIcon>
);

DaReinspectionIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default DaReinspectionIcon;
