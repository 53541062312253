const VEHICLE_DAMAGED_CAR_PARTS_DICT = {
  'Total loss': 'Total loss',
  'Airbags deployed': 'Airbags deployed',
  'Front bumper': 'Front bumper',
  'Front grille': 'Front grille',
  'Driver-side head lights': 'Driver-side head lights',
  'Passenger-side head lights': 'Passenger-side head lights',
  Hood: 'Hood',
  Windshield: 'Windshield',
  'Driver-side fender': 'Driver-side fender',
  'Passenger-side fender': 'Passenger-side fender',
  'Front driver-side door': 'Front driver-side door',
  'Front passenger-side door': 'Front passenger-side door',
  'Back driver-side door': 'Back driver-side door',
  'Back passenger-side door': 'Back passenger-side door',
  Trunk: 'Trunk',
  'Driver-side taillights': 'Driver-side taillights',
  'Passenger-side taillights': 'Passenger-side taillights',
  'Rear bumper': 'Rear bumper',
  'Driver-side quarter panel': 'Driver-side quarter panel',
  'Passenger-side quarter panel': 'Passenger-side quarter panel',
  Roof: 'Roof',
  'Undercarriage / Suspension': 'Undercarriage / Suspension',
} as const;

export default VEHICLE_DAMAGED_CAR_PARTS_DICT;
