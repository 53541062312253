import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import { connect, FieldArray, getIn, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import useOrganization from '../../OrganizationContext';
import TextFieldFormik from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

function BodilyInjuriesFragment({ label, injuriesFieldName, showOnly }) {
  const { injuryTypesDict, bodyPartsDict, isBodyPartList } = useOrganization();
  const { values } = useFormikContext();
  const classes = useStyles();

  const injuryTypeKeys = Object.keys(injuryTypesDict);

  return (
    <Fragment>
      <Typography display="block" variant="caption">
        {label}
      </Typography>
      <FieldArray
        name={injuriesFieldName}
        render={({ remove, push }) => (
          <Fragment>
            {getIn(values, injuriesFieldName).map((item, idx) => {
              const injuryType = injuryTypesDict[item.injury_type];

              return (
                <Fragment key={item.id}>
                  <Grid container spacing={1}>
                    <Grid item xs={11} container spacing={1}>
                      <Grid item xs={6}>
                        <TextFieldFormik
                          className={classes.textField}
                          id={`${injuriesFieldName}.${idx}.injury_type`}
                          label="Injury Type"
                          showOnly={showOnly}
                          select
                          fullWidth
                        >
                          {injuryTypeKeys.map((injuryType) => (
                            <MenuItem key={injuryType} value={injuryType}>
                              {injuryTypesDict[injuryType].injury_type_desc}
                            </MenuItem>
                          ))}
                        </TextFieldFormik>
                      </Grid>
                      {/*Assuming it can be should_ask_body_part or should_ask_desc_part but not both*/}
                      {injuryType &&
                        injuryType.should_ask_body_part &&
                        (!isBodyPartList ? (
                          <Grid item xs={6}>
                            <TextFieldFormik
                              className={classes.textField}
                              id={`${injuriesFieldName}.${idx}.body_part`}
                              label="Body Part"
                              showOnly={showOnly}
                              fullWidth
                            />
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs={getIn(values, `${injuriesFieldName}.${idx}.body_part`) === 'other' ? 2 : 6}>
                              <TextFieldFormik
                                id={`${injuriesFieldName}.${idx}.body_part`}
                                className={classes.textField}
                                label="Body Part"
                                showOnly={showOnly}
                                fullWidth
                                select
                              >
                                {Object.keys(bodyPartsDict).map((body_part) => (
                                  <MenuItem key={body_part} value={body_part}>
                                    {bodyPartsDict[body_part]['desc']}
                                  </MenuItem>
                                ))}
                              </TextFieldFormik>
                            </Grid>
                            {getIn(values, `${injuriesFieldName}.${idx}.body_part`) === 'other' && (
                              <Grid item xs={4}>
                                <TextFieldFormik
                                  className={classes.textField}
                                  id={`${injuriesFieldName}.${idx}.other_body_part`}
                                  label="Other Body Part"
                                  showOnly={showOnly}
                                  fullWidth
                                />
                              </Grid>
                            )}
                          </>
                        ))}
                      {injuryType && injuryType.should_ask_desc_part && (
                        <Grid item xs={6}>
                          <TextFieldFormik
                            className={classes.textField}
                            id={`${injuriesFieldName}.${idx}.description`}
                            label="Description"
                            showOnly={showOnly}
                            fullWidth
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      {!showOnly && (
                        <IconButton onClick={() => remove(idx)}>
                          <CancelIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}
            {!showOnly && (
              <IconButton
                onClick={() =>
                  push({ injury_type: '', body_part: '', other_body_part: '', description: '', id: `new_${uuidv4()}` })
                }
              >
                <AddIcon />
              </IconButton>
            )}
          </Fragment>
        )}
      />
    </Fragment>
  );
}

BodilyInjuriesFragment.propTypes = {
  label: PropTypes.string.isRequired,
  injuriesFieldName: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
};

const BodilyInjuriesFragmentFormik = connect(BodilyInjuriesFragment);

export default BodilyInjuriesFragmentFormik;
