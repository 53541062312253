import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';

import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import SendEmailCommunicationCardContainer from '../communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { contactPopulate } from '../ContactUtils';
import LoadingDialog from '../LoadingDialog';
import useDataFetcher from '../useDataFetcher';

function LitigationReferralAttorneySelectDialog(props) {
  const { onCancel, onSelectAttorney } = props;
  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        attorney_contact: '',
      }}
      validationSchema={Yup.object().shape({
        attorney_contact: Yup.object()
          .required('Required')
          .test(
            'is-email-exists',
            'Contact must contain at least one email address',
            (contact) => contact && contact.emails && contact.emails.length > 0
          )
          .test(
            'is-emailing-allowed',
            'Sending emails to this contact was marked as not allowed',
            (contact) => contact && contact.is_emailing_allowed !== false
          ),
      })}
      onSubmit={(values) => onSelectAttorney(values['attorney_contact'])}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        return (
          <CardDialog isDialog title="Select Attorney" maxWidth="sm" onClose={onCancel} preventClose={isSubmitting}>
            <ContactTextFieldFormik
              id="attorney_contact"
              label="Attorney"
              className={classes.textField}
              acceptedRoles={['vendor']}
              fixedSearchResults
              fullWidth
            />
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Select
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

LitigationReferralAttorneySelectDialog.propTypes = {
  onSelectAttorney: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function SendLitigationReferralEmailDialog(props) {
  const { onClose, exposure, contact } = props;
  const { claim, onAsyncClaimUpdate } = useClaim();

  const path = exposure
    ? `/api/v1/claims/${claim.id}/exposures/${exposure.id}/defense_litigation_referral_email_text`
    : `/api/v1/claims/${claim.id}/defense_litigation_referral_email_text`;
  const { isLoading, isError, data: emailTitleAndBody } = useDataFetcher(path);

  // In case the contact is not full and doesn't contain email
  const {
    isLoading: isLoadingContact,
    isError: isErrorContact,
    data: contactToSendEmail,
  } = useDataFetcher(`/api/v1/contacts/${contact.id}`);

  if (isLoading || isError) {
    return <LoadingDialog track="Send litigation referral email" isError={isError} onClose={onClose} />;
  }

  if (isLoadingContact || isErrorContact) {
    return <LoadingDialog track="Send litigation referral email" isError={isErrorContact} onClose={onClose} />;
  }

  const { email_title: emailTitle, email_body: emailBody } = emailTitleAndBody;

  return (
    <SendEmailCommunicationCardContainer
      contact={contactPopulate(contactToSendEmail)}
      onClose={onClose}
      onSendEmail={async () => {
        await onAsyncClaimUpdate();
        onClose();
      }}
      emailTitle={emailTitle}
      emailText={emailBody}
      summary={`${contact.full_name} was assigned to handle litigation for this ${exposure ? 'exposure' : 'claim'}`}
      attachments={claim.documents
        .filter((document) => (exposure ? document.exposure_ids.includes(exposure.id) : true))
        .map((document) => ({ ...document, attachment_filename: document.document_name_with_ext }))}
      exposureIds={exposure ? [exposure.id] : [0]}
    />
  );
}

SendLitigationReferralEmailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  exposure: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
};

export default LitigationReferralAttorneySelectDialog;
export { SendLitigationReferralEmailDialog };
