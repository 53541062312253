import React, { Fragment, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, getIn, useFormikContext } from 'formik';
import { get } from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import LoadingDialog from '~/components/LoadingDialog';

import { isHospitalityUser, isMgmOrganization, isRwOrganization, reportAxiosError } from '../Utils';

import BodilyInjuriesFragmentFormik from './Auto/AutoInvolved/BodilyInjuries';
import { localeDetails } from './CmsMain/globals';
import IcdCodesFieldContainer from './exposures/IcdCodesContainer';
import { useCms } from './hooks/useCms';
import LegalVenueContainer from './Navigator/LegalVenueContainer';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import WithConfirm from './ConfirmModal';
import { ContactEntity } from './Contact';
import ContactTextFieldFormik from './ContactTextFieldFormik';
import {
  FsButton,
  FsIconButton,
  PERMISSION_ACTIONS,
  PERMISSION_VERBS,
  PermissionsButtonWrapper,
  RestrictedPermissions,
} from './core';
import { GovernmentIdFieldHover, governmentIdValidationSchema } from './GovernmentId';
import { TrashIcon } from './icons';
import InvolvedWrapper from './InvolvedWrapper';
import useOrganization from './OrganizationContext';
import { TextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

const REPRESENTATIVE_CONTACT_TYPES = ['guardian', 'conservator', 'power_of_attorney', 'other'];

const involvedPersonFields = {
  contact_id: '',
  contact_full_name: '',
  was_ambulance_needed: '',
  government_id: '',
  injuries: [],
  attorney_contact_id: '',
  attorney_contact_full_name: '',
  representative_contact_id: '',
  representative_contact_full_name: '',
  attorney_reference_number: '',
  note: '',
  is_medicare_eligible: null,
  medicare_number: '',
  hospitals: [],
  was_hospital_attended: null,
  icd_codes: [],
  legal_venue_state: '',
  legal_venue_reason: '',
  insurer_contact_id: '',
  insurer_reference_number: '',
  insurer_policy_number: '',
  injury_date: '',
};

const involvedPersonValidationFields = {
  government_id: governmentIdValidationSchema,
  contact_id: Yup.number().required('Required'),
  injuries: Yup.array().of(
    Yup.object().shape({
      body_part: Yup.string().nullable().max(128, 'Limited to 128 characters'),
      other_body_part: Yup.string().nullable().max(128, 'Limited to 128 characters'),
      description: Yup.string().nullable().max(128, 'Limited to 128 characters'),
    })
  ),
  medicare_number: Yup.string()
    .nullable()
    .when('is_medicare_eligible', {
      is: true,
      then: Yup.string().length(13, 'Medicare number should be 13 characters. e.g.: 1EG4-TE5-MK73'),
    }),
  hospitals: Yup.array().when('was_hospital_attended', {
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          hospital_name: Yup.string().required('Hospital Name must be filled'),
          hospital_id: Yup.number().required('Choosing a hospital from the list is required'),
        })
      )
      .min(1, 'At least one hospital should inserted if was hospital attended is marked yes')
      .max(4),
  }),
  injury_date: Yup.date().nullable().max(new Date()),
};

function InvolvedPersonDialog(props) {
  const {
    dialogAction,
    disableEditIdentity,
    person,
    onCancel,
    onSubmit,
    open,
    showOnly,
    title,
    personLabel,
    contactSearchProps,
    CustomInvolvedPersonFragment,
    customInitialFields,
    customValidationFields,
    contactAcceptedRoles,
  } = props;

  if (!open) {
    return <></>;
  }

  const personInitialValues = person
    ? person
    : customInitialFields
    ? { ...customInitialFields }
    : { ...involvedPersonFields };
  const validationFields = customValidationFields ? customValidationFields : involvedPersonValidationFields;

  return (
    <Formik
      initialValues={personInitialValues}
      validationSchema={Yup.object().shape({ ...validationFields })}
      onSubmit={(values, formikProps) => {
        onSubmit(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title={title}
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
            action={dialogAction}
            closeOnBackdropClick={showOnly}
            footerActions={
              !showOnly && (
                <DialogFooterActions
                  disabled={isSubmitting}
                  onClickPrimary={handleSubmit}
                  onClickSecondary={onCancel}
                />
              )
            }
          >
            {CustomInvolvedPersonFragment ? (
              <CustomInvolvedPersonFragment
                contactSearchProps={contactSearchProps}
                disableEditIdentity={disableEditIdentity}
                contactAcceptedRoles={contactAcceptedRoles}
                showOnly={showOnly}
                personLabel={personLabel}
                {...formikProps}
              />
            ) : (
              <InvolvedPersonFragment
                contactSearchProps={contactSearchProps}
                contactAcceptedRoles={contactAcceptedRoles}
                disableEditIdentity={disableEditIdentity}
                showOnly={showOnly}
                personLabel={personLabel}
                {...formikProps}
              />
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

InvolvedPersonDialog.propTypes = {
  contactSearchProps: PropTypes.object,
  dialogAction: PropTypes.node,
  disableEditIdentity: PropTypes.bool,
  person: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
  personLabel: PropTypes.string,
  CustomInvolvedPersonFragment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  customInitialFields: PropTypes.object,
  customValidationFields: PropTypes.object,
  contactAcceptedRoles: requiredIf(
    PropTypes.array,
    (props) => !props.showOnly && !props.CustomInvolvedPersonFragment && !props.shouldCloseOnPrimary
  ),
};

function InvolvedPersonFragment(props) {
  const {
    contactSearchProps,
    disableEditIdentity,
    disableEditContact,
    showOnly,
    personLabel,
    additionalAfterContactFields,
    contactAcceptedRoles,
    parentId,
    ContactFieldOverride,
    hideInjuries,
    additionalAtEndOfDialog,
  } = props;

  let idPrefix = '';
  if (parentId) {
    idPrefix = parentId + '.';
  }

  const { values, setFieldValue } = useFormikContext();

  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();
  const { user, userOrganization } = useCms();
  const { claim, onClaimUpdate } = useClaim();

  const isUsRegion = localeDetails.locale.region === 'US';

  const governmentIdElementId = `${idPrefix}contact.government_id`;
  const governmentIdValue = get(values, governmentIdElementId, '');
  const onGovernmentIdUpdate = async ({ government_id }) => {
    try {
      setFieldValue(governmentIdElementId, government_id);
      await axios.patch(`/api/v1/contacts/${values.contact.id}`, { government_id });
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const involvedPersonWithUpdatedIcdCodesIfNotInFNOL = claim?.incident.involved_persons.find(
    (person) => person.id === values.id
  );

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12}>
        {ContactFieldOverride ? (
          <ContactFieldOverride />
        ) : (
          <ContactTextFieldFormik
            id={`${idPrefix}contact`}
            label={personLabel || 'Contact'}
            className={classes.textField}
            disabled={disableEditIdentity || disableEditContact}
            showOnly={showOnly}
            acceptedRoles={contactAcceptedRoles}
            contactSearchProps={contactSearchProps}
            fixedSearchResults
            fullWidth
          />
        )}
      </Grid>
      {additionalAfterContactFields}
      <Grid item xs={12}>
        <YesNoQuestionFormik
          questionText="Was an ambulance needed?"
          id={`${idPrefix}was_ambulance_needed`}
          disabled={showOnly}
        />
      </Grid>
      <GovernmentIdFieldHover
        id={governmentIdElementId}
        value={governmentIdValue}
        disabled={showOnly || !values?.contact?.id}
        onHoverUpdate={onGovernmentIdUpdate}
      />
      {(isMgmOrganization(userOrganization) || isRwOrganization(userOrganization) || isHospitalityUser(user)) &&
        isUsRegion && (
          <>
            <Grid item xs={12}>
              <YesNoQuestionFormik
                questionText="Medicare Eligible?"
                id={`${idPrefix}is_medicare_eligible`}
                disabled={showOnly}
              />
            </Grid>
            {getIn(values, `${idPrefix}is_medicare_eligible`) && (
              <Grid item xs={12}>
                <TextFieldFormik
                  id={`${idPrefix}medicare_number`}
                  label="Medicare Number"
                  fullWidth
                  className={classes.textField}
                  disabled={disableEditIdentity}
                  showOnly={showOnly}
                />
              </Grid>
            )}
          </>
        )}
      <Grid item xs={6}>
        <ContactTextFieldFormik
          id={`${idPrefix}attorney_contact`}
          label="Attorney"
          className={classes.textField}
          showOnly={showOnly}
          acceptedRoles={['attorney']}
          fixedSearchResults
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id={`${idPrefix}attorney_reference_number`}
          label="Attorney Reference Number"
          className={classes.textField}
          showOnly={showOnly}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <ContactTextFieldFormik
          id={`${idPrefix}representative_contact`}
          label="Representative"
          className={classes.textField}
          showOnly={showOnly}
          acceptedRoles={REPRESENTATIVE_CONTACT_TYPES}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        {values.representative_contact && (
          <Typography variant="caption">
            ({organizationContactRolesDict[values.representative_contact.role].desc})
          </Typography>
        )}
      </Grid>
      {!hideInjuries && (
        <Grid item xs={12}>
          <BodilyInjuriesFragmentFormik
            label="Injuries"
            injuriesFieldName={`${idPrefix}injuries`}
            showOnly={showOnly}
          />
        </Grid>
      )}
      {isUsRegion && involvedPersonWithUpdatedIcdCodesIfNotInFNOL && (
        <Grid item xs={12}>
          <IcdCodesFieldContainer
            viewOnly={showOnly}
            onUpdate={onClaimUpdate}
            involvedPerson={involvedPersonWithUpdatedIcdCodesIfNotInFNOL}
            claimId={claim?.id}
          />
        </Grid>
      )}
      {isUsRegion && <LegalVenueContainer showOnly={showOnly} idPrefix={idPrefix} />}
      <Grid item xs={12}>
        <TextFieldFormik
          id={`${idPrefix}note`}
          label="Note"
          fullWidth
          multiline
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      {additionalAtEndOfDialog}
    </Grid>
  );
}

InvolvedPersonFragment.propTypes = {
  additionalAfterContactFields: PropTypes.object,
  ContactFieldOverride: PropTypes.func,
  contactAcceptedRoles: requiredIf(PropTypes.array, (props) => !props.showOnly),
  contactSearchProps: PropTypes.object,
  disableEditIdentity: PropTypes.bool,
  disableEditContact: PropTypes.bool,
  showOnly: PropTypes.bool,
  personLabel: PropTypes.string,
  parentId: PropTypes.string,
  hideInjuries: PropTypes.bool,
  additionalAtEndOfDialog: PropTypes.object,
};

function EditInvolvedPersonDialog(props) {
  const {
    open,
    involvedPerson,
    onCancel,
    contactAcceptedRoles,
    contactSearchProps,
    onSaveInvolvedDetails,
    onDeleteInvolvedPerson,
    personLabel,
    CustomInvolvedPersonFragment,
    customInitialFields,
    customValidationFields,
  } = props;

  const dialogAction = onDeleteInvolvedPerson && (
    <PermissionsButtonWrapper verb={PERMISSION_VERBS.FULL} action={PERMISSION_ACTIONS.CONTACT}>
      <WithConfirm title={`Delete ${personLabel}?`} primaryButtonName="Delete" shouldCloseOnPrimary={false}>
        <FsIconButton onClick={onDeleteInvolvedPerson} icon={TrashIcon} />
      </WithConfirm>
    </PermissionsButtonWrapper>
  );

  return (
    <InvolvedPersonDialog
      contactSearchProps={contactSearchProps}
      contactAcceptedRoles={contactAcceptedRoles}
      disableEditIdentity={involvedPerson.is_locked}
      dialogAction={dialogAction}
      person={involvedPerson}
      onCancel={onCancel}
      onSubmit={onSaveInvolvedDetails}
      open={open}
      title={`Edit ${personLabel}`}
      personLabel={personLabel}
      CustomInvolvedPersonFragment={CustomInvolvedPersonFragment}
      customInitialFields={customInitialFields}
      customValidationFields={customValidationFields}
    />
  );
}

EditInvolvedPersonDialog.propTypes = {
  open: PropTypes.bool,
  involvedPerson: requiredIf(PropTypes.object, (props) => props.open),
  onCancel: PropTypes.func.isRequired,
  onSaveInvolvedDetails: PropTypes.func.isRequired,
  onDeleteInvolvedPerson: PropTypes.func,
  personLabel: PropTypes.string.isRequired,
  CustomInvolvedPersonFragment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  customInitialFields: PropTypes.object,
  customValidationFields: PropTypes.object,
  contactAcceptedRoles: requiredIf(PropTypes.array, (props) => !props.CustomInvolvedPersonFragment),
  contactSearchProps: PropTypes.object,
};

function InvolvedPersonSummary(props) {
  const {
    onFetchInvolvedDetails,
    onSaveInvolvedDetails,
    involvedPerson,
    contactAcceptedRoles,
    onDeleteInvolvedPerson,
    personLabel,
    CustomInvolvedPersonFragment,
    customInitialFields,
    customValidationFields,
    disabled,
  } = props;
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const classes = useStyles();
  const [involvedFetched, setInvolvedFetched] = React.useState();

  const openEditDialog = async () => {
    setEditDialogOpen(true);
    // assume that involvedPerson is the actual object if onFetchInvolvedDetails not passed, otherwise it is a header
    if (onFetchInvolvedDetails) {
      try {
        const personFullDetails = await onFetchInvolvedDetails();
        setInvolvedFetched(personFullDetails);
      } catch {
        setEditDialogOpen(false);
      }
    } else {
      setInvolvedFetched(involvedPerson);
    }
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setInvolvedFetched(undefined);
  };

  return (
    <>
      <div className={classes.nested}>
        <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
          <InvolvedWrapper onEdit={openEditDialog} disabled={disabled} involved={involvedPerson} involvedType="person">
            <div className={classes.containerCentered}>
              <ContactEntity
                contactId={involvedPerson.contact_id === '' ? null : involvedPerson.contact_id}
                contactDisplayName={involvedPerson.contact_full_name}
              />
              <Typography style={{ paddingLeft: '8px' }} display="inline" variant="caption">
                {personLabel}
              </Typography>
            </div>
          </InvolvedWrapper>
        </RestrictedPermissions>
      </div>

      {editDialogOpen &&
        (!involvedFetched ? (
          <LoadingDialog isError={false} track="EditInvolvedPersonDialog" />
        ) : (
          <EditInvolvedPersonDialog
            open
            involvedPerson={involvedFetched}
            personLabel={personLabel}
            onSaveInvolvedDetails={async (involvedPerson) => {
              await onSaveInvolvedDetails(involvedPerson);
              closeEditDialog();
            }}
            onDeleteInvolvedPerson={
              onDeleteInvolvedPerson &&
              (async () => {
                await onDeleteInvolvedPerson();
                closeEditDialog();
              })
            }
            onCancel={closeEditDialog}
            CustomInvolvedPersonFragment={CustomInvolvedPersonFragment}
            customInitialFields={customInitialFields}
            customValidationFields={customValidationFields}
            contactAcceptedRoles={contactAcceptedRoles}
          />
        ))}
    </>
  );
}

InvolvedPersonSummary.propTypes = {
  involvedPerson: PropTypes.object.isRequired,
  personLabel: PropTypes.string.isRequired,
  onFetchInvolvedDetails: PropTypes.func,
  onSaveInvolvedDetails: PropTypes.func.isRequired,
  onDeleteInvolvedPerson: PropTypes.func,
  CustomInvolvedPersonFragment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  customInitialFields: PropTypes.object,
  customValidationFields: PropTypes.object,
  contactAcceptedRoles: requiredIf(PropTypes.array, (props) => !props.disabled),
  disabled: PropTypes.bool,
};

// TODO: Add disabled option (not used now)
function InvolvedPersonsListDetails(props) {
  const {
    onAddInvolvedPerson,
    contactAcceptedRoles,
    contactSearchProps,
    involvedPersons,
    onFetchInvolvedPersonDetails,
    onSaveInvolvedPersonDetails,
    onDeleteInvolvedPerson,
    allowDelete,
    personLabel,
    disabled,
    CustomInvolvedPersonFragment,
    customInitialFields,
    customValidationFields,
    disableAddingProperty,
  } = props;

  const [addInvolvedPersonDialogOpen, setAddInvolvedPersonDialogOpen] = useState(false);

  const classes = useStyles();
  return (
    <>
      {involvedPersons.map((involvedPerson, index) => (
        <Fragment key={index}>
          <InvolvedPersonSummary
            involvedPerson={involvedPerson}
            personLabel={personLabel}
            onFetchInvolvedDetails={
              onFetchInvolvedPersonDetails ? async () => await onFetchInvolvedPersonDetails(involvedPerson) : undefined
            }
            onSaveInvolvedDetails={(newInvolvedPerson) => onSaveInvolvedPersonDetails(newInvolvedPerson, index)}
            onDeleteInvolvedPerson={allowDelete ? () => onDeleteInvolvedPerson(involvedPerson, index) : null}
            CustomInvolvedPersonFragment={CustomInvolvedPersonFragment}
            customInitialFields={customInitialFields}
            customValidationFields={customValidationFields}
            disabled={disabled}
            contactAcceptedRoles={contactAcceptedRoles}
          />
        </Fragment>
      ))}

      {!disableAddingProperty && (
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
              <FsButton
                color={FsButton.COLORS.primary}
                disabled={disabled}
                onClick={() => setAddInvolvedPersonDialogOpen(true)}
              >
                <AddIcon className={classes.rightButtonIcon} />
                Add {personLabel}
              </FsButton>
            </RestrictedPermissions>
          </Grid>
        </Grid>
      )}

      <InvolvedPersonDialog
        contactSearchProps={contactSearchProps}
        open={addInvolvedPersonDialogOpen}
        onCancel={() => setAddInvolvedPersonDialogOpen(false)}
        onSubmit={async (involvedPerson) => {
          await onAddInvolvedPerson(involvedPerson);
          setAddInvolvedPersonDialogOpen(false);
        }}
        title={`Add ${personLabel}`}
        personLabel={personLabel}
        CustomInvolvedPersonFragment={CustomInvolvedPersonFragment}
        customValidationFields={customValidationFields}
        customInitialFields={customInitialFields}
        contactAcceptedRoles={contactAcceptedRoles}
      />
    </>
  );
}

InvolvedPersonsListDetails.propTypes = {
  involvedPersons: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onFetchInvolvedPersonDetails: PropTypes.func,
  onSaveInvolvedPersonDetails: PropTypes.func.isRequired,
  onAddInvolvedPerson: requiredIf(PropTypes.func, (props) => !props.disableAddingProperty),
  onDeleteInvolvedPerson: requiredIf(PropTypes.func, (props) => props.allowDelete),
  personLabel: PropTypes.string.isRequired,
  allowDelete: PropTypes.bool,
  CustomInvolvedPersonFragment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  contactAcceptedRoles: requiredIf(PropTypes.array, (props) => !props.CustomInvolvedPersonFragment),
  contactSearchProps: PropTypes.object,
  customInitialFields: PropTypes.object,
  customValidationFields: PropTypes.object,
  disableAddingProperty: PropTypes.bool,
};

export {
  EditInvolvedPersonDialog,
  involvedPersonFields,
  InvolvedPersonFragment,
  InvolvedPersonsListDetails,
  InvolvedPersonSummary,
  involvedPersonValidationFields,
  REPRESENTATIVE_CONTACT_TYPES,
};
export default InvolvedPersonDialog;
