import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import LinkIcon from '~/components/icons/editor/LinkIcon';

import ToolbarButton from '../ToolbarButton';

import { useLinkModal } from './LinkModalContext';

const Link: React.FC = () => {
  const { editor } = useCurrentEditor();
  const { openModal } = useLinkModal();

  return (
    <ToolbarButton
      onClick={() => openModal()}
      disabled={
        !editor?.can().setLink({
          href: '',
        })
      }
      isActive={editor?.isActive('link')}
    >
      <LinkIcon />
    </ToolbarButton>
  );
};

export default Link;
