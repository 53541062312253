import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';

import { reportAxiosError } from '../../../../Utils';
import { AlternativeDisputeResolutionForm } from '../../FormFragments/AlternativeDisputeResolutionForm';
import type { AlternativeDisputeResolutionFormValues } from '../../FormFragments/FormValuesTypes';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const AlternativeDisputeResolutionDialog: React.FC<DialogPropsWithLegalAction> = ({
  legalAction,
  onClose,
  onSubmitSuccess,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: AlternativeDisputeResolutionFormValues) => {
    try {
      await axios.patch(backendRoutes.legalAction.alternative_dispute_resolution, values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<AlternativeDisputeResolutionFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: pick(legalAction, [
          'alternative_dispute_resolution_explored',
          'early_case_resolution_explored',
          'alternative_dispute_resolution_text',
        ]) as AlternativeDisputeResolutionFormValues,
      }}
      title="Edit Alternative Dispute Resolution"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <AlternativeDisputeResolutionForm />
      </div>
    </FormDialog>
  );
};
