import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { isoDateToUs, timeToLocalTime } from '../../../../DateTimeUtils';
import {
  getColorForPolicyStatus,
  getPolicyNumberPrefix,
  getPolicyStatus,
  getPolicyStatusConsiderTimeRanges,
} from '../../../../PolicyUtils';
import { COUNTRIES_DICT } from '../../../../Types';
import { isLocaleRegionIsUs, isMarshmallowPolicy, isPolicyManuallyFilled, reportAxiosError } from '../../../../Utils';
import { getLobIcon } from '../../../../Utils/lobUtils';
import CardDialog from '../../../CardDialog';
import { ContactShowOnlyTextField } from '../../../ContactTextFieldFormik';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../../core';
import useCurrencyFormatter from '../../../CurrencyFormatterContext';
import { FilesIcon, PencilIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import { ShowOnlyTextField } from '../../../TextFieldFormik';

import EditPolicyDialog from './EditPolicyDialog/EditPolicyDialog';
import NamedDriversWrapper from './NamedDriversWrapper';
import PolicyCustomFields from './PolicyCustomFields';

import { useStyles } from '../../../../assets/styles';
import styles from './PolicyDetailsCard.module.scss';

function PolicyDetailsCard(props) {
  const classes = useStyles();
  const [openEditPolicyDialog, setOpenEditPolicyDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { claim, onClaimUpdate } = props;
  const { policy } = claim;
  const { configured_fields_values: configuredFieldsValues } = policy;
  const { incidentConfiguration } = useIncidentConfiguration();

  const title = 'Policy Details';
  const { currencyFormatter } = useCurrencyFormatter();
  const { lobConfigurationsDict = {} } = useLobConfiguration();
  const policyLobIcon = getLobIcon({ lob: policy.lob.replace('_policy', '_claim'), lobConfigurationsDict });

  const isManualPolicy = isPolicyManuallyFilled(claim);
  const policyURLSuffix = isManualPolicy ? 'manual_policy' : 'api_policy';

  let documentUrl = policy.policy_document_url;
  if (documentUrl && !documentUrl.startsWith('http')) {
    documentUrl = 'https://' + documentUrl;
    try {
      new URL(documentUrl);
    } catch (err) {
      documentUrl = null;
    }
  }

  const action = (
    <div>
      <div className={styles.actionsContainer}>
        <Tooltip title={policy.policy_document_url ? 'Policy Document' : 'Document link was not supplied'}>
          <span>
            <Button
              className={styles.downloadPolicyButton}
              disabled={!policy.policy_document_url}
              onClick={() => documentUrl && window.open(documentUrl, '_blank')}
              startIcon={<FilesIcon className={classes.hoverableNonStrokedIcon} />}
            >
              Policy Document
            </Button>
          </span>
        </Tooltip>
        <div className={styles.separator} />
        <div className={styles.editIconWrapper}>
          <RestrictedPermissions
            action={isManualPolicy ? PERMISSION_ACTIONS.MANUAL_POLICY : PERMISSION_ACTIONS.API_POLICY}
            verb={PERMISSION_VERBS.WRITE}
          >
            <InlineIconButton
              icon={PencilIcon}
              className={styles.editIcon}
              onClick={() => setOpenEditPolicyDialog(true)}
              tooltipTitle="Edit Policy"
            />
          </RestrictedPermissions>
        </div>
      </div>
    </div>
  );

  let policyStatus = policy.policy_status;
  if (policyStatus !== 'void') {
    const policyStatusDuringLoss = getPolicyStatusConsiderTimeRanges({
      policy,
      date_of_loss: claim?.incident?.date_of_loss,
      time_of_loss: claim?.incident?.time_of_loss,
      reported_date: claim?.reported_date,
    });
    policyStatus = getPolicyStatus(policyStatusDuringLoss, true);
  }

  let cancellationDate = '';
  if (policy.is_cancelled) {
    if (policy.cancelled_date) {
      cancellationDate += isoDateToUs(policy.cancelled_date);
      if (policy.cancelled_time) {
        cancellationDate += ' ' + timeToLocalTime(policy.cancelled_time);
      }
    }
  }

  async function handlePolicyUpdate(values) {
    setIsSaving(true);
    try {
      const url = `/api/v1/claims/${claim.id}/${policyURLSuffix}`;
      await axios.patch(url, values);
      await onClaimUpdate();
      setOpenEditPolicyDialog(false);
    } catch (error) {
      reportAxiosError(error);
      setIsSaving(false);
      throw error;
    }
    setIsSaving(false);
  }

  return (
    <>
      <CardDialog
        title={title}
        action={action}
        headerStyle={{ padding: '0 0 24px' }}
        containerStyle={{ padding: '24px' }}
        contentStyle={{ padding: '0' }}
      >
        <Grid container spacing={1} className={styles.policyDetailsGridContainer}>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label={
                <span className={styles.policyNumberLabel}>
                  <span className={styles.policyNumberIcon}>{policyLobIcon}</span>
                  {getPolicyNumberPrefix(policy.lob, lobConfigurationsDict) + ' '}
                  Policy Number
                </span>
              }
              showOnlyValueComponent={<span className={styles.policyNumber}>{policy.policy_number}</span>}
            />
          </Grid>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label="Status"
              showOnlyValueComponent={
                <span className={styles.policyStatus} style={{ color: getColorForPolicyStatus(policyStatus) }}>
                  {policyStatus}
                </span>
              }
            />
          </Grid>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ContactShowOnlyTextField
              classes={classes}
              label="Policyholder"
              contactId={policy.insured_contact_id}
              contactDisplayName={policy.insured_contact_full_name}
              shallRefetchOnContactIdChange
            />
          </Grid>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label="Effective Date"
              showOnlyValueComponent={
                isoDateToUs(policy.policy_effective_date) + ' ' + timeToLocalTime(policy.effective_time)
              }
            />
          </Grid>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label="Expiration Date"
              showOnlyValueComponent={
                isoDateToUs(policy.policy_expiration_date) + ' ' + timeToLocalTime(policy.expiration_time)
              }
            />
          </Grid>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField classes={classes} label="Cancellation Date" showOnlyValueComponent={cancellationDate} />
          </Grid>

          {policy?.is_claims_made && (
            <>
              <Grid item xs={4} className={styles.policyDetailsGridItem}>
                <ShowOnlyTextField classes={classes} label="Claims Made" showOnlyValueComponent="Yes" />
              </Grid>
              <Grid item xs={4} className={styles.policyDetailsGridItem}>
                <ShowOnlyTextField
                  classes={classes}
                  label="Retroactive Date"
                  showOnlyValueComponent={
                    policy?.retroactive_date
                      ? isoDateToUs(policy?.retroactive_date) + ' ' + timeToLocalTime(policy.retroactive_time)
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4} className={styles.policyDetailsGridItem}>
                <ShowOnlyTextField
                  classes={classes}
                  label="Days of Runoff Provision"
                  showOnlyValueComponent={policy.runoff_provision}
                />
              </Grid>
            </>
          )}

          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField classes={classes} label="Policy Type" showOnlyValueComponent={policy.policy_type} />
          </Grid>
          {isLocaleRegionIsUs() && (
            <Grid item xs={4} className={styles.policyDetailsGridItem}>
              <ShowOnlyTextField classes={classes} label="Policy State" showOnlyValueComponent={policy.policy_state} />
            </Grid>
          )}
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label="Policy Currency"
              showOnlyValueComponent={policy.policy_currency}
            />
          </Grid>

          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label="Policy Country"
              showOnlyValueComponent={COUNTRIES_DICT[policy.policy_country]}
            />
          </Grid>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label="Policy Limit"
              showOnlyValueComponent={policy.limit_per_policy ? currencyFormatter.format(policy.limit_per_policy) : ''}
            />
          </Grid>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label="Claim Limit"
              showOnlyValueComponent={policy.limit_per_claim ? currencyFormatter.format(policy.limit_per_claim) : ''}
            />
          </Grid>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label="Renewal Date"
              showOnlyValueComponent={policy.renewal_date ? isoDateToUs(policy.renewal_date) : ''}
            />
          </Grid>
          <Grid item xs={4} className={styles.policyDetailsGridItem}>
            <ShowOnlyTextField
              classes={classes}
              label="Last Modified Date"
              showOnlyValueComponent={policy.last_update_date ? isoDateToUs(policy.last_update_date) : ''}
            />
          </Grid>
        </Grid>

        {(policy.custom_fields?.length > 0 ||
          incidentConfiguration?.policy?.configured_fields?.length > 0 ||
          policy.underwriting_company ||
          isMarshmallowPolicy(policy)) && (
          <PolicyCustomFields
            customFields={policy.custom_fields ?? []}
            configuredFieldsValues={configuredFieldsValues ?? {}}
            configuredFieldsConfiguration={incidentConfiguration?.policy?.configured_fields ?? []}
            policy={policy}
          />
        )}

        {policy.lob === 'auto_policy' && (
          <NamedDriversWrapper claim={claim} onClaimUpdate={onClaimUpdate} namedDrivers={policy.named_drivers} />
        )}
      </CardDialog>

      {openEditPolicyDialog && (
        <EditPolicyDialog
          onSaveClick={(values) => handlePolicyUpdate(values)}
          onClose={() => !isSaving && setOpenEditPolicyDialog(false)}
          isSaving={isSaving}
          policyStatus={policyStatus}
          policy={policy}
          lob={policy.lob}
        />
      )}
    </>
  );
}

PolicyDetailsCard.propTypes = {
  claim: PropTypes.object.isRequired,
  onClaimUpdate: PropTypes.func.isRequired,
};

export default PolicyDetailsCard;
