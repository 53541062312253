import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const RecommendedNotificationIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon fill="none" stroke={iconColor} {...props}>
    <path
      d="M2 6.5H5V13H2C1.86739 13 1.74021 12.9473 1.64645 12.8536C1.55268 12.7598 1.5 12.6326 1.5 12.5V7C1.5 6.86739 1.55268 6.74021 1.64645 6.64645C1.74021 6.55268 1.86739 6.5 2 6.5V6.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 6.5L7.5 1.5C8.03043 1.5 8.53914 1.71071 8.91421 2.08579C9.28929 2.46086 9.5 2.96957 9.5 3.5V5H13.3687C13.5105 4.99965 13.6508 5.02965 13.78 5.08798C13.9092 5.14631 14.0245 5.23162 14.118 5.33818C14.2116 5.44475 14.2812 5.57009 14.3223 5.70581C14.3634 5.84152 14.375 5.98445 14.3562 6.125L13.6062 12.125C13.5759 12.3659 13.459 12.5875 13.2773 12.7485C13.0956 12.9095 12.8615 12.9989 12.6187 13H5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

RecommendedNotificationIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default RecommendedNotificationIcon;
