import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const TextAlignLeftIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M3 6.5C3 6.30109 3.07902 6.11032 3.21967 5.96967C3.36032 5.82902 3.55109 5.75 3.75 5.75H20.25C20.4489 5.75 20.6397 5.82902 20.7803 5.96967C20.921 6.11032 21 6.30109 21 6.5C21 6.69891 20.921 6.88968 20.7803 7.03033C20.6397 7.17098 20.4489 7.25 20.25 7.25H3.75C3.55109 7.25 3.36032 7.17098 3.21967 7.03033C3.07902 6.88968 3 6.69891 3 6.5ZM3.75 11H15.75C15.9489 11 16.1397 10.921 16.2803 10.7803C16.421 10.6397 16.5 10.4489 16.5 10.25C16.5 10.0511 16.421 9.86032 16.2803 9.71967C16.1397 9.57902 15.9489 9.5 15.75 9.5H3.75C3.55109 9.5 3.36032 9.57902 3.21967 9.71967C3.07902 9.86032 3 10.0511 3 10.25C3 10.4489 3.07902 10.6397 3.21967 10.7803C3.36032 10.921 3.55109 11 3.75 11ZM20.25 13.25H3.75C3.55109 13.25 3.36032 13.329 3.21967 13.4697C3.07902 13.6103 3 13.8011 3 14C3 14.1989 3.07902 14.3897 3.21967 14.5303C3.36032 14.671 3.55109 14.75 3.75 14.75H20.25C20.4489 14.75 20.6397 14.671 20.7803 14.5303C20.921 14.3897 21 14.1989 21 14C21 13.8011 20.921 13.6103 20.7803 13.4697C20.6397 13.329 20.4489 13.25 20.25 13.25ZM15.75 17H3.75C3.55109 17 3.36032 17.079 3.21967 17.2197C3.07902 17.3603 3 17.5511 3 17.75C3 17.9489 3.07902 18.1397 3.21967 18.2803C3.36032 18.421 3.55109 18.5 3.75 18.5H15.75C15.9489 18.5 16.1397 18.421 16.2803 18.2803C16.421 18.1397 16.5 17.9489 16.5 17.75C16.5 17.5511 16.421 17.3603 16.2803 17.2197C16.1397 17.079 15.9489 17 15.75 17Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default TextAlignLeftIcon;
