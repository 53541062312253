import React from 'react';

import { serverDateToLocal } from '../../../../../DateTimeUtils';
import { PropertyDetails } from '../../../../core';
import { PropertyDetailsRow } from '../../../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { ConfigurationValuePropertyDetails } from '../../../components/ConfigurationValuePropertyDetails';
import { DocumentPropertyDetails } from '../../../components/Document/DocumentPropertyDetails';
import { MoreDetailsDialog } from '../../../Dialogs/MoreDetailsDialog';
import { LEGAL_ACTION_LOG_FIELDS } from '../../../FormFragments/FormFieldsDefinitions';
import type { LegalActionLogApiResponse } from '../../../types';

export const LegalActionLogMoreDetails: React.FC<{ log: LegalActionLogApiResponse; onClose: () => void }> = ({
  log,
  onClose,
}) => {
  return (
    <MoreDetailsDialog onClose={onClose} title="Legal Action Log">
      <PropertyDetailsRow>
        <PropertyDetails title={LEGAL_ACTION_LOG_FIELDS.date.label} text={serverDateToLocal(log.date)} />
        <PropertyDetails
          title={LEGAL_ACTION_LOG_FIELDS.dueDate.label}
          text={log.due_date ? serverDateToLocal(log.date) : ''}
        />
      </PropertyDetailsRow>
      <ConfigurationValuePropertyDetails
        title={LEGAL_ACTION_LOG_FIELDS.logType.label}
        value={log.log_type}
        optionsKey="logTypeOptions"
        otherTextTitle={LEGAL_ACTION_LOG_FIELDS.logTypeText.label}
        otherTextValue={log.log_type_text}
      />
      <ConfigurationValuePropertyDetails
        title={LEGAL_ACTION_LOG_FIELDS.statusOfLitigation.label}
        value={log.status_of_litigation}
        optionsKey="statusOfLitigationOptions"
        otherTextTitle={LEGAL_ACTION_LOG_FIELDS.statusOfLitigationText.label}
        otherTextValue={log.status_of_litigation_text}
      />
      <PropertyDetailsRow>
        <PropertyDetails title={LEGAL_ACTION_LOG_FIELDS.counselUpdate.label} text={log.counsel_update} />
        <DocumentPropertyDetails title="Counsel Report" document={log.document} />
      </PropertyDetailsRow>
      <PropertyDetailsRow>
        <PropertyDetails title={LEGAL_ACTION_LOG_FIELDS.nextAction.label} text={log.next_action} />
      </PropertyDetailsRow>
    </MoreDetailsDialog>
  );
};
