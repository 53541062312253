import React from 'react';
import PropTypes from 'prop-types';
import RateReviewIcon from '@material-ui/icons/RateReview';

import InlineIconButton from '../../InlineIconButton';

import ClaimQAMenu from './Menu';

const ClaimQAContainer = ({ onRequestExposureQa }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      <InlineIconButton
        defaultColor="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        icon={RateReviewIcon}
        tooltipTitle="QA"
      />
      {anchorEl && (
        <ClaimQAMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onSelectExposure={onRequestExposureQa} />
      )}
    </>
  );
};

ClaimQAContainer.propTypes = {
  onRequestExposureQa: PropTypes.func.isRequired,
};

export default ClaimQAContainer;
