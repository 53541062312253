import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import RadioButtonFormik from '../RadioButtonFormik';

function DamageDialogContainer(props) {
  const {
    CustomDetailsDialog,
    customDialogProps,
    damageId,
    questionText,
    customDialogTitle,
    values,
    setFieldValue,
    helperText,
    onOptionClick,
    dialogForNo,
    disableNoOption,
    disabled,
  } = props;
  const [showDamageDetailsDialog, setShowDamageDetailsDialog] = useState(false);
  const isDamagedId = `${damageId}.is_damaged`;
  const [valueToSetUponDialog, setValueToSetUponDialog] = useState(getIn(values, isDamagedId));

  const damageData = getIn(values, damageId);

  const handleDialogOptionClick = () => {
    if (!CustomDetailsDialog) {
      return;
    }

    setShowDamageDetailsDialog(true);
  };

  const handleOnChangeDialogOption = (_id, value) => {
    if (!CustomDetailsDialog) {
      setFieldValue(isDamagedId, value);

      // If there is a custom dialog - only in case of submit we call onOptionClick
      if (onOptionClick) {
        onOptionClick(value);
      }
      return;
    }

    setValueToSetUponDialog(value);
  };

  const handleDialogSubmit = (details) => {
    setFieldValue(damageId, { ...damageData, is_damaged: valueToSetUponDialog, damage_details: details });

    if (onOptionClick) {
      onOptionClick(valueToSetUponDialog);
    }

    setShowDamageDetailsDialog(false);
  };

  return (
    <>
      <Grid item xs={8}>
        <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>{questionText}</span>
        {helperText}
        <ErrorHelperTextFormik style={{ marginTop: 0 }} id={isDamagedId} />
      </Grid>
      <Grid item xs={4}>
        <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
          {!dialogForNo ? (
            <>
              <RadioButtonFormik
                id={isDamagedId}
                label="No"
                optionValue="no"
                onChange={() => {
                  setFieldValue(isDamagedId, 'no');
                  if (onOptionClick) {
                    onOptionClick('no');
                  }
                }}
                disabled={disabled || disableNoOption}
              />
              <RadioButtonFormik
                id={isDamagedId}
                label={CustomDetailsDialog ? 'Yes >' : 'Yes'}
                optionValue="yes"
                onChange={handleOnChangeDialogOption}
                onClick={handleDialogOptionClick}
                disabled={disabled}
              />
            </>
          ) : (
            <>
              <RadioButtonFormik
                id={isDamagedId}
                label="Yes"
                optionValue="yes"
                onChange={() => {
                  setFieldValue(isDamagedId, 'yes');
                  if (onOptionClick) {
                    onOptionClick('yes');
                  }
                }}
                disabled={disabled}
              />
              <RadioButtonFormik
                id={isDamagedId}
                label={CustomDetailsDialog ? 'No >' : 'No'}
                optionValue="no"
                disabled={disabled || disableNoOption}
                onChange={handleOnChangeDialogOption}
                onClick={handleDialogOptionClick}
              />
            </>
          )}
        </div>
      </Grid>
      {CustomDetailsDialog && (
        <CustomDetailsDialog
          {...customDialogProps}
          dialogTitle={customDialogTitle}
          damageDetails={damageData.damage_details}
          open={showDamageDetailsDialog}
          onSubmitDetails={handleDialogSubmit}
          onCancel={() => {
            setShowDamageDetailsDialog(false);
          }}
        />
      )}
    </>
  );
}

DamageDialogContainer.propTypes = {
  damageId: PropTypes.string.isRequired,
  CustomDetailsDialog: PropTypes.func,
  customDialogTitle: PropTypes.string,
  customDialogProps: PropTypes.object,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  questionText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  setFieldValue: PropTypes.func.isRequired, // from Formik
  values: PropTypes.object.isRequired, // from Formik
  onOptionClick: PropTypes.func,
  dialogForNo: PropTypes.bool,
  disableNoOption: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DamageDialogContainer;
