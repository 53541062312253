import {
  DeductibleFinanceTableAdditionalMenuItem,
  deductibleFinanceTableAdditionalMenuItemCondition,
} from '~/components/exposures/PaymentRequestContainer/MakePaymentRequestDialog/DeductibleUtils';

import PaymentDetailsMenuOptions from './PaymentDetailsMenuOptions';

const defaultAdditionalFinanceMenuOptions = [
  {
    id: 'additional_payment_details',
    condition: (financeRecord) =>
      financeRecord.payment_request.financial_request_extra?.custom_fields?.length > 0 ||
      financeRecord.payment_request?.external_payment_id,
    MenuItemComponent: PaymentDetailsMenuOptions,
  },
  {
    id: 'apply_deductible',
    condition: (financeRecord) => deductibleFinanceTableAdditionalMenuItemCondition(financeRecord),
    MenuItemComponent: DeductibleFinanceTableAdditionalMenuItem,
  },
];

export default defaultAdditionalFinanceMenuOptions;
