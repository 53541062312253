const PET_COVERAGE_DICT = {
  coverage_pet_accident_and_illness: {
    desc: 'Accident and Illness',
  },
  coverage_pet_cancer: {
    desc: 'Cancer Coverage',
  },
  coverage_pet_mortality_benefit: {
    desc: 'Mortality Benefit',
  },
  coverage_pet_prescription_drug: {
    desc: 'Prescription Drug',
  },
  coverage_pet_dental: {
    desc: 'Dental Coverage',
  },
  coverage_pet_theft_or_straying: {
    desc: 'Loss Due to Theft or Straying',
  },
  coverage_pet_vacation_cancellation: {
    desc: 'Vacation Cancellation',
  },
  coverage_pet_wellness: {
    desc: 'Wellness',
  },
  coverage_pet_accident_only: {
    desc: 'Accident Only',
  },
  coverage_pet_advertising_and_reward: {
    desc: 'Advertising and Reward',
  },
  coverage_pet_alternative_therapies: {
    desc: 'Alternative Therapies',
  },
  coverage_pet_boarding_kennel_and_cattery_fee: {
    desc: 'Boarding Kennel and Cattery Fee',
  },
  coverage_pet_deluxe_prescription_drug: {
    desc: 'Deluxe Prescription Drug Coverage',
  },
  coverage_pet_prescription_food: {
    desc: 'Prescription Food',
  },
} as const;

export default PET_COVERAGE_DICT;
