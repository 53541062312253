import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Text } from '~/components/core';
import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';

import TextFieldFormik, { ShowOnlyTextField } from '../../../../TextFieldFormik';

import { COMPLIANCE_FIELDS, getGridSizeByOfacFieldName, getOfacCellValue } from './additionalPaymentVerificationUtils';

import { useStyles } from '../../../../../assets/styles';
import styles from './additionalPaymentVerificationDialog.module.scss';

const OfacDetailsFormikFragment = ({ checkDetails, responseFieldId, showReasonInput, isOfacRequestFailed }) => {
  return (
    <>
      {isOfacRequestFailed ? (
        <div />
      ) : (
        <div className={styles.specificCheckContainer}>
          <div className={styles.internalContainer}>
            <Heading variant={Heading.TYPES.H4}>{checkDetails.issue_title}</Heading>
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              {checkDetails.issue_dialog_details}
            </Text>
          </div>
          <OfacDetailsContainer checkDetailsList={checkDetails['details']} />
          {showReasonInput ? (
            <div>
              <TextFieldFormik className={styles.reason} id={responseFieldId} label="OFAC override reason" fullWidth />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

OfacDetailsFormikFragment.propTypes = {
  checkDetails: PropTypes.array,
  responseFieldId: PropTypes.string,
  showReasonInput: PropTypes.bool,
  isOfacRequestFailed: PropTypes.bool,
};

const OfacDetailsContainer = ({ checkDetailsList }) => {
  const checkDetailsListLength = checkDetailsList?.length || 0;

  if (checkDetailsListLength === 0) {
    return <AlertBanner note="Missing OFAC details" alertType={AlertBanner.ALERT_TYPES.WARNING} />;
  } else if (checkDetailsListLength === 1) {
    return <OfacDetailsInner complianceResult={checkDetailsList[0]} title="OFAC Details" />;
  } else if (checkDetailsListLength > 1) {
    return (
      <>
        {checkDetailsList.map((complianceResult) => (
          <CollapsibleWrapper title={`OFAC details - ${complianceResult.name}`} actionCard key={complianceResult.id}>
            <OfacDetailsInner complianceResult={complianceResult} />
          </CollapsibleWrapper>
        ))}
      </>
    );
  }
};

OfacDetailsContainer.propTypes = {
  checkDetailsList: PropTypes.array,
};

const OfacDetailsInner = ({ complianceResult, title }) => {
  const classes = useStyles();

  return (
    <InnerCard className={styles.row} title={title}>
      <Grid container spacing={1}>
        {Object.keys(COMPLIANCE_FIELDS).map((complianceFieldName) => (
          <Grid
            item
            xs={getGridSizeByOfacFieldName(complianceFieldName)}
            className={styles.cell}
            key={complianceFieldName}
          >
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={getOfacCellValue(complianceFieldName, complianceResult)}
              label={COMPLIANCE_FIELDS[complianceFieldName]}
            />
          </Grid>
        ))}
      </Grid>
    </InnerCard>
  );
};

OfacDetailsInner.propTypes = {
  complianceResult: PropTypes.object,
  title: PropTypes.string,
};

export default OfacDetailsFormikFragment;
