import React from 'react';

import FormikToggleDirection from '~/components/communications/CommunicationCard/FormikToggleDirection';
import NonViewCommunicationCardHeaderContactDetails from '~/components/communications/CommunicationCard/NonViewCommunicationCardHeaderContactDetails';
import SelectDirectionFormik from '~/components/communications/CommunicationCard/SelectDirectionFormik';
import ViewCommunicationCardHeaderContactDetails from '~/components/communications/CommunicationCard/ViewCommunicationCardHeaderContactDetails';
import {
  getCommunicationChannelIconComponent,
  getCommunicationDirectionIconComponent,
} from '~/components/communications/CommunicationUtils';
import type { ContactFullModel } from '~/components/Contacts/types';
import { useCms } from '~/components/hooks/useCms';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportErrorInProductionOrThrow } from '~/Utils';
import cn from '~/Utils/cn';

import type { ChannelType, Direction } from './constants';

interface CommunicationCardHeaderProps {
  communicationId?: number;
  communicationClaimId?: number;
  channel: ChannelType;
  direction?: Direction;
  contactId?: number;
  contactFullName?: string;
  contactRole?: string;
  isDynamicContact?: boolean;
  onSelectContact?: (contact: ContactFullModel) => void;
  dynamicContactLabel?: string;
  isView?: boolean;
  outOfContactsContextSearch?: boolean;
  onCommunicationUpdate?: () => void;
}

const CommunicationCardHeader: React.FC<CommunicationCardHeaderProps> = ({
  communicationId,
  channel,
  direction,
  contactId,
  contactFullName,
  contactRole,
  isDynamicContact,
  onSelectContact,
  dynamicContactLabel,
  isView,
  communicationClaimId,
  onCommunicationUpdate,
  outOfContactsContextSearch = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);

  if (isView && !communicationId) {
    reportErrorInProductionOrThrow('CommunicationCardHeader: communicationId is required in view mode');
    return null;
  }

  return (
    <>
      {!isNewUIEnabled && (
        <div className="flex justify-center">
          {getCommunicationChannelIconComponent(channel)}
          {direction ? (
            getCommunicationDirectionIconComponent(direction)
          ) : (
            // assume isDocument - otherwise, it's just a bug we don't have direction
            <div className="flex items-center justify-center">
              <FormikToggleDirection />
            </div>
          )}
        </div>
      )}
      {isNewUIEnabled && !direction && <SelectDirectionFormik />}
      <div
        className={cn('flex items-center', {
          ['mb-12']: isNewUIEnabled,
          ['justify-center']: !isNewUIEnabled,
        })}
      >
        {isView && communicationId ? (
          <ViewCommunicationCardHeaderContactDetails
            communicationId={communicationId}
            communicationClaimId={communicationClaimId}
            communicationChannel={channel}
            contactId={contactId}
            contactFullName={contactFullName}
            contactRole={contactRole}
            enableAttachToContact={!contactId && !!communicationClaimId}
            onAttachToContact={onCommunicationUpdate}
          />
        ) : (
          <NonViewCommunicationCardHeaderContactDetails
            enableChoosingContact={isDynamicContact}
            contactId={contactId}
            contactFullName={contactFullName}
            contactRole={contactRole}
            onSelectContact={onSelectContact}
            outOfContactsContextSearch={outOfContactsContextSearch}
            dynamicContactLabel={dynamicContactLabel}
          />
        )}
      </div>
    </>
  );
};

export default CommunicationCardHeader;
