const BIKE_ACTIVITIES_DICT = {
  claimant_at_home: {
    desc: "At Home (or someone else's house)",
  },
  claimant_at_work: {
    desc: 'At Work',
  },
  claimant_pleasure_ride: {
    desc: 'Pleasure Ride',
  },
  claimant_was_shopping: {
    desc: 'Shopping',
  },
  claimant_delivering: {
    desc: 'Delivering',
  },
  other_activity: {
    desc: 'Other',
  },
} as const;

export default BIKE_ACTIVITIES_DICT;
