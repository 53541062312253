const CONTACT_COMMUNICATION_CHANGE_REASONS = {
  typo: {
    desc: 'Correcting spelling / typing error',
  },
  not_in_use: {
    desc: 'Communication no longer in use',
  },
  wrong: {
    desc: 'Incorrect communication details',
  },
  update: {
    desc: 'Communication has changed',
  },
  addition: {
    desc: 'Adding missing communication information',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default CONTACT_COMMUNICATION_CHANGE_REASONS;
