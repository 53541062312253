import React from 'react';

import * as userUtils from '../../UserUtils';
import { isPermissionsEnabled } from '../core/Permissions/PermissionUtils';
import { useCms } from '../hooks/useCms';

const useCurrentUserRoles = () => {
  const { user, userOrganization } = useCms();
  const permissionsEnabled = isPermissionsEnabled(userOrganization);

  const overrides = React.useMemo(
    () => ({
      isUserPhoneRep: permissionsEnabled ? false : userUtils.isUserPhoneRep(user),
      isUserAdjuster: permissionsEnabled ? true : userUtils.isUserAdjuster(user),
    }),
    [user, permissionsEnabled]
  );
  const overridenKeys = Object.keys(overrides);

  const userUtilsResults = React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries(userUtils)
          .filter(([methodName]) => methodName.startsWith('isUser'))
          .filter(([methodName]) => !overridenKeys.includes(methodName))
          .map(([methodName, method]) => [methodName, method(user)])
      ),
    [overridenKeys, user]
  );

  return { ...userUtilsResults, ...overrides };
};

export default useCurrentUserRoles;
