import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import Text from '~/components/core/TextComponents/Text';

import CardDialog from '../../../components/CardDialog';
import useCurrencyFormatter from '../../../components/CurrencyFormatterContext';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../../../components/TextFieldFormik';
import { reportAxiosError } from '../../../Utils';

function DeductibleDialog({ claim, exposure, onUpdate, onClose }) {
  const isInitialSet = exposure.deductible_amount === null && !exposure.is_deductible_waived;
  const { currencyFormatter } = useCurrencyFormatter();

  const handleSetDeductible = async (values, formikProps) => {
    try {
      await axios.put(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/deductible`, {
        is_deductible_waived: values.is_deductible_waived,
        deductible_amount: values.is_deductible_waived ? null : values.deductible_amount,
        deductible_reason: values.deductible_reason,
      });
      await onUpdate();
      onClose();
    } catch (error) {
      reportAxiosError(error);
      formikProps.setIsSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        deductible_amount: exposure.deductible_amount ?? exposure.policy_deductible ?? '',
        is_deductible_waived: exposure.is_deductible_waived ?? false,
        deductible_reason: exposure.deductible_reason ?? '',
      }}
      validationSchema={Yup.object().shape({
        deductible_amount: Yup.number().when('is_deductible_waived', {
          is: false,
          then: Yup.number()
            .required('Required')
            .min(0.01, `Must be at least ${currencyFormatter.format(0.01)}`),
        }),
        is_deductible_waived: Yup.boolean(),
        deductible_reason: Yup.string(),
      })}
      onSubmit={handleSetDeductible}
    >
      {() => (
        <DeductibleDialogFormikInner isInitialSet={isInitialSet} claim={claim} exposure={exposure} onClose={onClose} />
      )}
    </Formik>
  );
}

DeductibleDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function DeductibleDialogFormikInner({ isInitialSet, exposure, onClose }) {
  const { values, initialValues, setFieldValue, isSubmitting, handleSubmit } = useFormikContext();
  const { currencyFormatter } = useCurrencyFormatter();
  return (
    <CardDialog
      title={`${isInitialSet ? 'Set' : 'Edit'} Deductible for ${exposure.label_text}`}
      isDialog
      open
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      footerActions={
        <DialogFooterActions onClickPrimary={handleSubmit} onClickSecondary={onClose} disabled={isSubmitting} />
      }
    >
      <div className="flex flex-col space-y-20">
        {exposure.policy_deductible && (
          <>
            {values.deductible_amount > exposure.policy_deductible && (
              <div>
                <AlertBanner
                  alertType={AlertBanner.ALERT_TYPES.WARNING}
                  note="The deductible amount exceeds the total deductible in the policy."
                  withIcon
                />
              </div>
            )}
            <div className="flex flex-row">
              <Text>Defined deductible in policy:&nbsp;</Text>
              <Text weight={Text.WEIGHTS.REGULAR}>{currencyFormatter.format(exposure.policy_deductible)}</Text>
            </div>
          </>
        )}
        <div>
          <RadioButtonGroupFormik
            id="is_deductible_waived"
            label="Select"
            direction="row"
            sizeButtonsEvenly
            options={[
              { optionValue: false, text: 'Set Amount' },
              { optionValue: true, text: 'Waive Deductible' },
            ]}
            onClick={(newIsDeductibleWaived) => {
              if (newIsDeductibleWaived) {
                setFieldValue('deductible_amount', '');
              } else {
                setFieldValue('deductible_amount', initialValues.deductible_amount);
              }
            }}
            btnClassName="bg-slate-100"
          />
        </div>
        <div>
          <MonetaryValueTextFieldFormik
            id="deductible_amount"
            label="Amount"
            disabled={values['is_deductible_waived']}
            fullWidth
          />
        </div>
        <div>
          <TextFieldFormik id="deductible_reason" label="Reason" fullWidth />
        </div>
      </div>
    </CardDialog>
  );
}

DeductibleDialogFormikInner.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  isInitialSet: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeductibleDialog;
