import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { SUPERVISOR_APPROVAL_STATUS_DICT } from '../../../Types';
import { capitalize } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { Text } from '../../core';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../../TextFieldFormik';

import PaymentRequestDisplay from './PaymentRequestDisplay';

import { useStyles } from '../../../assets/styles';

export default function PaymentRequestDecisionDialog(props) {
  const { paymentRequest, cardDialogProps, onClose, claim, exposure, payableType, payableWithReserve } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        ...paymentRequest,
      }}
      enableReinitialize
      onSubmit={() => {
        throw Error('PaymentRequestDecisionDialog should not be submittable');
      }}
    >
      {(formikProps) => {
        const { values } = formikProps;

        return (
          <CardDialog
            title={`${capitalize(payableType)} Payment Request Decision`}
            {...cardDialogProps}
            onClose={onClose}
          >
            <PaymentRequestDisplay
              paymentRequest={paymentRequest}
              exposure={exposure}
              claim={claim}
              payableType={payableType}
              payableWithReserve={payableWithReserve}
            />

            <div>
              <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
                Decision: {SUPERVISOR_APPROVAL_STATUS_DICT[values['request_status']].desc}
              </Text>
            </div>
            <div>
              <MonetaryValueTextFieldFormik
                id="decision_amount"
                label="Amount Approved"
                className={classes.textField}
                showOnly
              />
            </div>
            <div className={classes.inputContainer}>
              <TextFieldFormik
                id="decision_note"
                label="Decision Note"
                rows="2"
                className={classes.textField}
                fullWidth
                multiline
                showOnly
              />
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}
PaymentRequestDecisionDialog.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  paymentRequest: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  claim: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  exposure: PropTypes.object.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
};
