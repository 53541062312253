import React from 'react';

import { contactPopulate } from '~/components/ContactUtils';
import { locationToLocationFullString } from '~/Utils';

import { getLobDescription } from './Utils/lobUtils';

import { colorPalette } from './assets/styles';

// This class contains comparison functions for determining whether a policy is in effect at time of loss.
// Any change to these functions hould be mirrored in the BE equivalent - policies_manager.py - PolicyStatusesDuringLoss + get_policy_status_during_loss
export const policyStatusesDuringLoss = {
  policyInForce: 0,
  beforeEffectiveTime: 1,
  afterExpirationTime: 2,
  afterCancelledTime: 3,
  policyVoided: 4,
  policyMissingEnforcementDetails: 5,
  policyCancelled: 6,
  reportedDateAfterExpirationRunoff: 7,
  dateOfLossBeforeRetroactiveDate: 8,
};

// any change to this specific function should be mirrored in BE equivalent - policies_manager.py::get_policy_status_considering_time_ranges
export const getPolicyStatusConsiderTimeRanges = ({ policy, date_of_loss, time_of_loss, reported_date }) => {
  if (!policy.is_claims_made) {
    return getPolicyStatusDuringLoss(policy, date_of_loss, time_of_loss);
  } else {
    return getClaimsMadePolicyStatusDuringReport(policy, date_of_loss, time_of_loss, reported_date);
  }
};

export const getPolicyStatusDuringLoss = (
  policy,
  dateOfLoss,
  timeOfLoss = undefined,
  overidePolicyEffectiveDatetime = undefined,
  overidePolicyExpirationDatetime = undefined
) => {
  const dtol = Date.parse(`${dateOfLoss}T${timeOfLoss || '00:00:00'}`);
  const policyEffectiveDatetime = Date.parse(overidePolicyEffectiveDatetime || policy.policy_effective_datetime);
  const policyExpirationDatetime = Date.parse(overidePolicyExpirationDatetime || policy.policy_expiration_datetime);
  const policyCancellationDatetime = Date.parse(policy.cancelled_datetime);

  if (policy.is_cancelled && !policyCancellationDatetime) {
    return policyStatusesDuringLoss.policyCancelled;
  } else if (policy.is_cancelled && policyCancellationDatetime <= dtol && dtol < policyExpirationDatetime) {
    return policyStatusesDuringLoss.afterCancelledTime;
  }

  if (policyEffectiveDatetime > dtol) {
    return policyStatusesDuringLoss.beforeEffectiveTime;
  } else if (dtol >= policyExpirationDatetime) {
    return policyStatusesDuringLoss.afterExpirationTime;
  } else {
    return policyStatusesDuringLoss.policyInForce;
  }
};

export const getClaimsMadePolicyStatusDuringReport = (policy, dateOfLoss, timeOfLoss, reportedDate) => {
  const dateOfLossDatetime = Date.parse(`${dateOfLoss}T${timeOfLoss || '00:00:00'}`);
  const effectiveDatetime = Date.parse(policy.policy_effective_datetime);
  const expirationDatetime = Date.parse(policy.policy_expiration_datetime);
  const cancellationDatetime = Date.parse(policy.cancelled_datetime);
  const retroactiveDatetime = Date.parse(policy.retroactive_datetime);
  const reportedDatetime = Date.parse(reportedDate);
  const reportedDateEndOfDayDatetime = new Date(reportedDatetime).setDate(new Date(reportedDatetime).getDate() + 1);
  const expirationDatetimeWithRunoff = new Date(expirationDatetime).setDate(
    new Date(expirationDatetime).getDate() + (policy?.runoff_provision || 0)
  );

  if (effectiveDatetime > reportedDateEndOfDayDatetime) {
    return policyStatusesDuringLoss.beforeEffectiveTime;
  }
  if (reportedDatetime > expirationDatetimeWithRunoff) {
    return policyStatusesDuringLoss.reportedDateAfterExpirationRunoff;
  }

  if (retroactiveDatetime > dateOfLossDatetime) {
    return policyStatusesDuringLoss.dateOfLossBeforeRetroactiveDate;
  }
  if (dateOfLossDatetime > expirationDatetime) {
    return policyStatusesDuringLoss.afterExpirationTime;
  }

  if (policy.is_cancelled) {
    if (!cancellationDatetime) {
      return policyStatusesDuringLoss.policyCancelled;
    }

    const cancellationDatetimeWithRunoff = new Date(cancellationDatetime).setDate(
      new Date(cancellationDatetime).getDate() + (policy?.runoff_provision || 0)
    );

    if (reportedDatetime > cancellationDatetimeWithRunoff) {
      return policyStatusesDuringLoss.afterCancelledTime;
    }
    if (dateOfLossDatetime > cancellationDatetime) {
      return policyStatusesDuringLoss.afterCancelledTime;
    }
  }

  return policyStatusesDuringLoss.policyInForce;
};

const checkIsClaimsMade = (obj) => obj?.supported_lobs?.some((supportedLob) => supportedLob?.is_claims_made);

export const getIsClaimsMadePolicy = (userOrganization) => {
  if (!userOrganization) {
    return false;
  }

  if (!userOrganization?.sub_organizations_enabled) {
    return checkIsClaimsMade(userOrganization) || false;
  }

  return userOrganization.sub_organizations?.some((subOrg) => checkIsClaimsMade(subOrg)) || false;
};

export const getCurrentSupportedLobIsClaimsMade = ({
  userOrganization,
  currSubOrgId = undefined,
  currLob = undefined,
}) => {
  if (!userOrganization) {
    return false;
  }

  if (!userOrganization?.sub_organizations_enabled) {
    return (
      userOrganization.supported_lobs?.find((supportedLob) => supportedLob?.lob === currLob)?.is_claims_made || false
    );
  }
  return (
    userOrganization.sub_organizations
      ?.find((subOrg) => subOrg.id === currSubOrgId)
      ?.supported_lobs.find((supportedLob) => supportedLob?.lob === currLob)?.is_claims_made || false
  );
};

export const getPolicyStatus = (policyStatusDuringLoss, shortMessage = false) => {
  let policyStatus = 'In Force';
  if (
    [policyStatusesDuringLoss.afterCancelledTime, policyStatusesDuringLoss.policyCancelled].includes(
      policyStatusDuringLoss
    )
  ) {
    policyStatus = 'Cancelled';
  } else if (
    [
      policyStatusesDuringLoss.afterExpirationTime,
      policyStatusesDuringLoss.beforeEffectiveTime,
      policyStatusesDuringLoss.reportedDateAfterExpirationRunoff,
      policyStatusesDuringLoss.dateOfLossBeforeRetroactiveDate,
    ].includes(policyStatusDuringLoss)
  ) {
    policyStatus = shortMessage ? 'Not In Force' : 'Not In Force at Time of Loss';
  }
  return policyStatus;
};

const POLICY_ISSUES_NOTES = {
  CANCELLED: 'The selected policy was canceled. Please verify the policy selection.',
  EXPIRED: 'The selected policy expired. Please verify the policy selection.',
  INEFFECTIVE: 'The selected policy was not in force at the date of loss. Please verify the policy selection.',
  AFTER_REPORTED_DATE: 'The selected policy is not in force. Please verify the policy selection.',
  BEFORE_RETROACTIVE_DATE: 'Note: Date of loss is prior to the retrospective date.',
};

export const getPolicyStatusText = (policyStatusDuringLoss, policy) => {
  if (
    [policyStatusesDuringLoss.afterCancelledTime, policyStatusesDuringLoss.policyCancelled].includes(
      policyStatusDuringLoss
    )
  ) {
    return POLICY_ISSUES_NOTES.CANCELLED;
  } else if (!policy.is_manual && policyStatusDuringLoss === policyStatusesDuringLoss.afterExpirationTime) {
    return POLICY_ISSUES_NOTES.EXPIRED;
  } else if (!policy.is_manual && policyStatusDuringLoss === policyStatusesDuringLoss.beforeEffectiveTime) {
    return POLICY_ISSUES_NOTES.INEFFECTIVE;
  } else if (
    !policy.is_manual &&
    policyStatusDuringLoss === policyStatusesDuringLoss.reportedDateAfterExpirationRunoff
  ) {
    return POLICY_ISSUES_NOTES.AFTER_REPORTED_DATE;
  } else if (!policy.is_manual && policyStatusDuringLoss === policyStatusesDuringLoss.dateOfLossBeforeRetroactiveDate) {
    return POLICY_ISSUES_NOTES.BEFORE_RETROACTIVE_DATE;
  } else {
    return null;
  }
};

export const getColorForPolicyStatus = (policyStatus) => {
  policyStatus = policyStatus.toLowerCase();
  if (policyStatus === 'in force') {
    return colorPalette.success;
  } else if (policyStatus === 'not in force') {
    return colorPalette.status.pending;
  } else if (policyStatus === 'cancelled') {
    return colorPalette.text.disabled;
  } else if (policyStatus === 'void') {
    return colorPalette.error;
  }
  return colorPalette.text.primary;
};

export const getPolicyNumberPrefix = (lob, lobConfigurationsDict) => {
  const claim_lob = lob.replace('_policy', '_claim');
  return getLobDescription(claim_lob, lobConfigurationsDict);
};

export const getPolicyStatusSpan = (policyStatusDuringLoss, policy) => {
  const policyStatusText = getPolicyStatusText(policyStatusDuringLoss, policy);
  if (policyStatusText) {
    return (
      <span style={{ color: 'red' }}>
        <strong>{policyStatusText}</strong>
      </span>
    );
  } else {
    return <></>;
  }
};

export const getPolicyIssueNote = ({ values, policy }) => {
  if (!values?.date_of_loss && !values?.reported_date) {
    return null;
  }

  if (!policy?.is_claims_made && !values?.date_of_loss) {
    return null;
  }

  const policyStatusDuringLoss = getPolicyStatusConsiderTimeRanges({
    policy,
    date_of_loss: values?.date_of_loss,
    time_of_loss: values?.time_of_loss,
    reported_date: values?.reported_date,
  });

  return getPolicyStatusText(policyStatusDuringLoss, policy);
};

export function isExpirationAfterEffectiveDT(_, testContext) {
  const effective_date = testContext.parent.effective_date || testContext.parent.policy_effective_date;
  const effective_time = testContext.parent.effective_time || '00:00';
  const expiration_date = testContext.parent.expiration_date || testContext.parent.policy_expiration_date;
  const expiration_time = testContext.parent.expiration_time || '00:00';
  return Date.parse(`${effective_date}T${effective_time}`) < Date.parse(`${expiration_date}T${expiration_time}`);
}

export function isCancelledAfterEffectiveDT(_, testContext) {
  const effective_date = testContext.parent.effective_date || testContext.parent.policy_effective_date;
  const effective_time = testContext.parent.effective_time || '00:00';
  const cancelled_date = testContext.parent.cancelled_date || testContext.parent.policy_cancelled_date;
  const cancelled_time = testContext.parent.cancelled_time || '00:00';
  if (!cancelled_date) {
    return true;
  }
  return Date.parse(`${effective_date}T${effective_time}`) <= Date.parse(`${cancelled_date}T${cancelled_time}`);
}

export function isRetroactiveBeforeEffectiveDT(_, testContext) {
  const effective_date = testContext.parent.effective_date || testContext.parent.policy_effective_date;
  const effective_time = testContext.parent.effective_time || '00:00';
  const retroactive_date = testContext.parent.retroactive_date;
  const retroactive_time = testContext.parent.retroactive_time || '00:00';
  return Date.parse(`${effective_date}T${effective_time}`) >= Date.parse(`${retroactive_date}T${retroactive_time}`);
}

export const policyPopulate = (policy) => ({
  ...policy,
  underwriting_company: policy.underwriting_company || '',
  policy_effective_date: policy.policy_effective_date || '',
  policy_expiration_date: policy.policy_expiration_date || '',
  insured_contact:
    policy.insured_contact_id && policy.insured_contact ? contactPopulate(policy.insured_contact) : undefined, // we sometimes pass insured_contact and sometimes ContactPopulate(insured_contact)
  insured_property_full_address:
    policy.insured_property_full_address ||
    (policy.insured_property_location ? locationToLocationFullString(policy.insured_property_location) : undefined),
  insured_address: policy.insured_contact ? policy.insured_contact.full_address : undefined,
});
