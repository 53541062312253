import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { DisputeResolutionProfessionalForm } from '../../FormFragments/DisputeResolutionProfessionalForm';
import { DISPUTE_RESOLUTION_PROFESSIONAL_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { DisputeResolutionProfessionalFormValues } from '../../FormFragments/FormValuesTypes';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { DisputeResolutionProfessionalApiResponse } from '../../types';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export interface EditDisputeResolutionProfessionalDialogProps extends DialogPropsWithLegalAction {
  disputeResolutionProfessional: DisputeResolutionProfessionalApiResponse;
}

export const EditDisputeResolutionProfessionalDialog: React.FC<EditDisputeResolutionProfessionalDialogProps> = ({
  onClose,
  onSubmitSuccess,
  legalAction,
  disputeResolutionProfessional,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: DisputeResolutionProfessionalFormValues) => {
    try {
      await axios.put(
        backendRoutes.legalAction.dispute_resolution_professional(disputeResolutionProfessional.id),
        values
      );
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<DisputeResolutionProfessionalFormValues>
      title="Edit Dispute Resolution Professional"
      onClose={onClose}
      maxWidth="sm"
      formikConfig={{
        onSubmit: handleSubmit,
        validationSchema: Yup.object().shape(DISPUTE_RESOLUTION_PROFESSIONAL_VALIDATION_SCHEMA),
        initialValues: pick(disputeResolutionProfessional, [
          'type',
          'contact_id',
          'start_date',
          'end_date',
        ] as (keyof DisputeResolutionProfessionalFormValues)[]),
      }}
    >
      <div className="flex w-full flex-col gap-20">
        <DisputeResolutionProfessionalForm />
      </div>
    </FormDialog>
  );
};
