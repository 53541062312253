import React from 'react';

import CardDialog from '../../CardDialog';

export interface MoreDetailsDialogProps {
  onClose: () => void;
  title: string;
}

export const MoreDetailsDialog: React.FC<MoreDetailsDialogProps> = ({ onClose, title, children }) => {
  return (
    <CardDialog isDialog open title={title} maxWidth="sm" fullWidth onClose={onClose}>
      <div className="flex w-full flex-col gap-12">
        {React.Children.map(children, (child) => (
          <div className="flex w-full">{child}</div>
        ))}
      </div>
    </CardDialog>
  );
};
