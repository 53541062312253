import React from 'react';

import cn from '~/Utils/cn';

import Text from '../TextComponents/Text';

import type { SummarySectionProps } from './SummarySection';

export interface SummaryCardProps {
  children: React.ReactElement<SummarySectionProps>[];
  title: string;
  className?: string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ title, children, className }) => {
  return (
    <div className={cn('w-[185px] rounded-md border border-solid border-slate-500 bg-slate-100 p-20', className)}>
      <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD} className="mb-20 uppercase">
        {title}
      </Text>
      {children}
    </div>
  );
};

const summaryCardDefaultProps = {
  title: 'Summary',
};

SummaryCard.defaultProps = summaryCardDefaultProps;

export default SummaryCard;
