import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import colors from '~/assets/colors.module.scss';
import Typography from '~/components/core/Atomic/Typography';
import cn from '~/Utils/cn';

const SIZE_TYPES = {
  H1: 'h1',
  H2: 'h2',
  H2_CAPS: 'h2_caps',
  H3: 'h3',
  H4: 'h4',
};

const SIZE_TO_PX = {
  [SIZE_TYPES.H1]: 20,
  [SIZE_TYPES.H2]: 18,
  [SIZE_TYPES.H2_CAPS]: 18,
  [SIZE_TYPES.H3]: 16,
  [SIZE_TYPES.H4]: 14,
};

const COLOR_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DISABLED: 'disabled',
  BLACK: 'black',
};

const COLOR_VARIANT_TO_COLOR = {
  [COLOR_VARIANTS.PRIMARY]: colors.textPrimary,
  [COLOR_VARIANTS.SECONDARY]: colors.textSecondary,
  [COLOR_VARIANTS.DISABLED]: colors.textDisabled,
  [COLOR_VARIANTS.BLACK]: 'black',
};

const useStyles = makeStyles(() => ({
  root: ({ variant, colorVariant }) => ({
    fontWeight: 600,
    fontSize: SIZE_TO_PX[variant],
    textTransform: variant === SIZE_TYPES.H2_CAPS ? 'uppercase' : 'none',
    color: COLOR_VARIANT_TO_COLOR[colorVariant],
  }),
}));

const Heading = ({ className, variant = SIZE_TYPES.H2, colorVariant = COLOR_VARIANTS.PRIMARY, children }) => {
  const displayClasses = useStyles({ variant, colorVariant });

  return <Typography className={cn(displayClasses.root, className)}>{children}</Typography>;
};

Heading.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(SIZE_TYPES)),
  colorVariant: PropTypes.oneOf(Object.values(COLOR_VARIANTS)),
  children: PropTypes.node.isRequired,
};

Heading.TYPES = SIZE_TYPES;
Heading.COLOR_VARIANTS = COLOR_VARIANTS;

export default Heading;
