import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const UnderlineIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M18.75 21.5C18.75 21.6989 18.671 21.8897 18.5303 22.0303C18.3897 22.171 18.1989 22.25 18 22.25H6C5.80109 22.25 5.61032 22.171 5.46967 22.0303C5.32902 21.8897 5.25 21.6989 5.25 21.5C5.25 21.3011 5.32902 21.1103 5.46967 20.9697C5.61032 20.829 5.80109 20.75 6 20.75H18C18.1989 20.75 18.3897 20.829 18.5303 20.9697C18.671 21.1103 18.75 21.3011 18.75 21.5ZM12 19.25C13.5908 19.2483 15.1159 18.6156 16.2407 17.4907C17.3656 16.3659 17.9983 14.8408 18 13.25V5.75C18 5.55109 17.921 5.36032 17.7803 5.21967C17.6397 5.07902 17.4489 5 17.25 5C17.0511 5 16.8603 5.07902 16.7197 5.21967C16.579 5.36032 16.5 5.55109 16.5 5.75V13.25C16.5 14.4435 16.0259 15.5881 15.182 16.432C14.3381 17.2759 13.1935 17.75 12 17.75C10.8065 17.75 9.66193 17.2759 8.81802 16.432C7.97411 15.5881 7.5 14.4435 7.5 13.25V5.75C7.5 5.55109 7.42098 5.36032 7.28033 5.21967C7.13968 5.07902 6.94891 5 6.75 5C6.55109 5 6.36032 5.07902 6.21967 5.21967C6.07902 5.36032 6 5.55109 6 5.75V13.25C6.00174 14.8408 6.63444 16.3659 7.75928 17.4907C8.88412 18.6156 10.4092 19.2483 12 19.25Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default UnderlineIcon;
