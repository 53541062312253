import React from 'react';
import { noop } from 'lodash';

import { TableauEmbedViewer } from '~/components/ReportsPage/EmbedComponents/TableauEmbedViewer';
import { useTableauConfiguration } from '~/components/ReportsPage/Hooks/useTableauConfiguration';

interface ITableauEmbedSession {
  token?: string;
  onReady: (e: never) => void;
}

/**
 * TableauEmbedSession - This is just a dummy component that renders TableauEmbedViewer with the jwt token to
 * initialize the Tableau session cookie so getting preview images will be possible without using the Embed component.
 * this component is invisible.
 * @param token
 * @param onReady
 * @constructor
 */
export const TableauEmbedSession: React.FC<ITableauEmbedSession> = ({ token = '', onReady = noop }) => {
  const { auth_workbook = '', auth_view = '' } = useTableauConfiguration();

  if (!token) return null;

  return (
    <TableauEmbedViewer
      workbook={auth_workbook}
      view={auth_view}
      token={token}
      options={{ onFirstInteractive: onReady, height: 0, width: 0 }}
    />
  );
};
