import React from 'react';

import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core';
import { useHasPermission } from '~/components/hooks/useHasPermission';

interface IReportsPermissions {
  hasCreatorPermission: boolean;
  hasManagementSuitePermission: boolean;
  hasAdjusterSuitePermission: boolean;
  hasGeneralSuitePermission: boolean;
  allowedProjects: string[];
}

export const useReportsPermissions = (): IReportsPermissions => {
  const hasManagementSuitePermission = useHasPermission({
    action: PERMISSION_ACTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.READ,
  });

  const hasAdjusterSuitePermission = useHasPermission({
    action: PERMISSION_ACTIONS.ADJUSTER_DASHBOARD,
    verb: PERMISSION_VERBS.READ,
  });

  const hasGeneralSuitePermission = useHasPermission({
    action: PERMISSION_ACTIONS.VIEW_GENERAL_REPORTS_SUITE,
    verb: PERMISSION_VERBS.READ,
  });

  const hasCreatorPermission = useHasPermission({
    action: PERMISSION_ACTIONS.CREATE_REPORTS_AND_DASHBOARDS,
    verb: PERMISSION_VERBS.WRITE,
  });

  const allowedProjects = React.useMemo(() => {
    const projects = [];
    if (hasManagementSuitePermission) {
      projects.push('management');
    }
    if (hasAdjusterSuitePermission) {
      projects.push('adjuster');
    }
    if (hasGeneralSuitePermission) {
      projects.push('general');
    }
    return projects;
  }, [hasManagementSuitePermission, hasAdjusterSuitePermission, hasGeneralSuitePermission]);

  return {
    hasManagementSuitePermission,
    hasAdjusterSuitePermission,
    hasGeneralSuitePermission,
    hasCreatorPermission,
    allowedProjects,
  };
};
