import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { serverDateTimeToLocalDate } from '../DateTimeUtils';
import { reportAxiosError } from '../Utils';
import { useFetchClaim } from '../Utils/ClaimUtils';

import { getAllSearchableContactRoles } from './communications/ContactUtils';
import { DocumentCoreFields, DocumentCoreFieldsInitialValues } from './Documents/DocumentCard';
import CardDialog from './CardDialog';
import CheckboxFormik from './CheckboxFormik';
import { ClaimContextProvider } from './ClaimContainer';
import ClaimSelectTextFieldFormik from './ClaimSelectTextFieldFormik';
import WithConfirm from './ConfirmModal';
import ContactTextFieldFormik from './ContactTextFieldFormik';
import ExposureMultiSelectTextFieldFormik from './ExposureMultiSelectTextFieldFormik';
import LoadingIndicator from './LoadingIndicator';
import useOrganization from './OrganizationContext';

import { useStyles } from '../assets/styles';

function FileClassificationContainer(props) {
  const { fileClassificationRequest, open, onClose, onUpdate } = props;
  const [isDismissing, setIsDismissing] = useState(false);
  const classes = useStyles();

  const { file_extra: fileExtra } = fileClassificationRequest;

  const fileNotificationResourcePrefix = `/api/v1/claims/file_classification_requests/${fileClassificationRequest.id}`;

  const handleDismissFileClassificationRequest = async () => {
    try {
      setIsDismissing(true);
      await axios.post(`${fileNotificationResourcePrefix}/dismiss`);
      await onUpdate();
      await onClose();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsDismissing(false);
  };

  return (
    <Formik
      initialValues={{
        ...DocumentCoreFieldsInitialValues,
        document_date: serverDateTimeToLocalDate(fileClassificationRequest.stored_file.datetime_uploaded),
        should_upload_document: false,
        claim_id: fileExtra.claim_id || '',
        claim_id_display: fileExtra.claim_id_display || '',
        exposure_ids: [],
        contact_id: '',
        contact_full_name: '',
        classification_type: 'physical_mail_communication',
      }}
      validationSchema={Yup.object().shape({
        claim_id: Yup.string().required('Required'),
        contact_id: Yup.number().required('Required'),
        document_name: Yup.string().when('should_upload_document', {
          is: true,
          then: Yup.string().required('Required'),
        }),
        document_date: Yup.date().when('should_upload_document', { is: true, then: Yup.date().required('Required') }),
        document_date_received: Yup.date()
          .nullable()
          .when('document_date', {
            is: (val) => !!val,
            then: Yup.date().nullable().min(Yup.ref('document_date'), "Can't be before document creation date"),
          }),
        type: Yup.string().when('should_upload_document', { is: true, then: Yup.string().required('Required') }),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await axios.post(`${fileNotificationResourcePrefix}/classify`, values);
          await onUpdate();
          onClose();
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { values, isSubmitting } = formikProps;
        const disableActions = isSubmitting || isDismissing;

        return (
          <CardDialog
            isDialog
            title="Classify Physical Mail"
            open={open}
            onClose={onClose}
            maxWidth="xl"
            action={
              <WithConfirm
                title="Delete Physical Mail?"
                primaryButtonName="Delete"
                contentText={
                  'This item was not classified. After 7 days this item will be deleted permanently from the "Recently handled" queue. Would you like to proceed and delete the file?'
                }
                shouldCloseOnPrimary
              >
                <IconButton onClick={handleDismissFileClassificationRequest} disabled={disableActions}>
                  <DeleteIcon />
                </IconButton>
              </WithConfirm>
            }
            preventClose={disableActions}
            fullWidth
          >
            <Grid container spacing={1}>
              <Grid item md={3}>
                <div style={{ marginRight: '16px' }}>
                  <a href={`${fileNotificationResourcePrefix}/stored_file`} target="_blank" rel="noopener noreferrer">
                    Open File in New Tab
                  </a>
                  <div className={classes.cardDivRowInternal}>
                    <ClaimSelectTextFieldFormik id="claim_id" label="Claim" disabled={isSubmitting} />
                  </div>
                  {values.claim_id && <ClaimSpecificFragment disabled={disableActions} />}
                </div>
              </Grid>
              <Grid item md={9}>
                <object
                  data={`${fileNotificationResourcePrefix}/stored_file`}
                  style={{ height: '85vh', width: '100%' }}
                >
                  Physical Mail PDF
                </object>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

FileClassificationContainer.propTypes = {
  fileClassificationRequest: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

function ClaimSpecificFragment() {
  const { values, isSubmitting, handleSubmit } = useFormikContext();
  const [claim, isLoading, isError, reloadClaim] = useFetchClaim(values.claim_id);
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <Grid item xs={12}>
        <ContactTextFieldFormik
          id="contact"
          label="From"
          disabled={isSubmitting}
          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
          fullWidth
          fixedSearchResults
        />
      </Grid>
      <Grid item xs={12}>
        <ExposureMultiSelectTextFieldFormik claim={claim} disabled={isSubmitting} />
      </Grid>
      <CheckboxFormik id="should_upload_document" label="Upload Document To Claim" />
      {values.should_upload_document && <DocumentCoreFields classes={classes} claim={claim} hideExposuresLabels />}
      <div className={classes.buttonsContainer}>
        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
          {values.should_upload_document ? 'Classify & Upload Document' : 'Classify'}
        </Button>
      </div>
    </ClaimContextProvider>
  );
}

export default FileClassificationContainer;
