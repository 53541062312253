import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import CheckboxFormik from '~/components/CheckboxFormik';
import useOrganization from '~/components/OrganizationContext';
import { MultiSelectTextFieldFormik, usePrevious } from '~/components/TextFieldFormik';

import { FORMIK_DETAILS_FIELD_IDS } from '../../constants';
import { useContact } from '../../useContact';
import { getFullDetailsTabFieldPath } from '../../utils';

const MoiExpertise = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const { organizationContactRolesDict } = useOrganization();
  const { currentRole, setCurrentIsMoiServiceProvider } = useContact();
  const { organizationMoiExpertises } = useOrganization();

  const currentRoleMetadata = currentRole ? organizationContactRolesDict[currentRole] : null;

  const isMoiServiceProvider = getIn(
    values,
    getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.IS_MOI_SERVICE_PROVIDER)
  );
  const prevRoleRef = usePrevious(currentRole);

  const handleIsMoiServiceProvider = useCallback(
    (isMoiServiceProvider) => {
      setCurrentIsMoiServiceProvider(isMoiServiceProvider);

      setFieldValue(getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.IS_MOI_SERVICE_PROVIDER), isMoiServiceProvider);
      setFieldValue(getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.MOI_EXPERTISES), []);
    },
    [setCurrentIsMoiServiceProvider, setFieldValue]
  );

  useEffect(() => {
    if (prevRoleRef && prevRoleRef !== currentRole && !currentRoleMetadata?.is_moi_enabled) {
      handleIsMoiServiceProvider(false);
    }
  }, [currentRole, setFieldValue, currentRoleMetadata, handleIsMoiServiceProvider, prevRoleRef]);

  return (
    <>
      <div className="grid grid-cols-1 gap-20">
        <CheckboxFormik
          id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.IS_MOI_SERVICE_PROVIDER)}
          label="MOI Service Provider"
          fullWidth
          disabled={disabled || isEmpty(currentRole) || !currentRoleMetadata?.is_moi_enabled}
          onChange={(event) => handleIsMoiServiceProvider(event.target.checked)}
        />
        <MultiSelectTextFieldFormik
          id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.MOI_EXPERTISES)}
          label="Expertise"
          disabled={disabled || !isMoiServiceProvider}
          fullWidth
          renderOption={(option) => organizationMoiExpertises[option]['desc']}
          options={Object.keys(organizationMoiExpertises).sort()}
          renderValue={() => ''}
          withOptionChips
        />
      </div>
    </>
  );
};

MoiExpertise.propTypes = {
  disabled: PropTypes.bool,
};

export default MoiExpertise;
