import React from 'react';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import { DatePickerTextFieldFormik } from '../../../TextFieldFormik';

import { useStyles } from '../../../../assets/styles';

export function MgmCompensationDatesFormikInner() {
  const classes = useStyles();
  return (
    <Grid container xs={12} style={{ marginTop: '10px', marginBottom: '10px' }} spacing={1}>
      <Grid item xs={12}>
        <Typography display="block" style={{ marginTop: '5px' }}>
          Compensation Period
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <DatePickerTextFieldFormik
          id="request_extra.compensation_period.compensation_start_date"
          label="Start Date"
          className={classes.textField}
          disableFuture
          fullWidth
          clearable
        />
      </Grid>
      <Grid item xs={6}>
        <DatePickerTextFieldFormik
          id="request_extra.compensation_period.compensation_end_date"
          label="End Date"
          className={classes.textField}
          disableFuture
          fullWidth
          clearable
        />
      </Grid>
    </Grid>
  );
}

export default MgmCompensationDatesFormikInner;
