import { isExposureFinancialOnly } from '../../../Utils';

export function adjustColumnByClaim(columnData, claim, userOrganization) {
  if (['gl_claim', 'general_claim'].includes(claim.type)) {
    columnData = columnData.filter((col) => col.id !== 'mor');
  }

  if (claim.type !== 'general_claim') {
    columnData = columnData.filter((col) => col.id !== 'deductible');
  }

  if (['wc_claim'].includes(claim.type)) {
    columnData = columnData.filter((col) => col.id !== 'expenses_paid_sum' && col.id !== 'expenses_reserve');
    columnData.find((col) => col.id === 'indemnity_reserve').label = 'Reserve';
    columnData.find((col) => col.id === 'indemnity_paid_sum').label = 'Paid';
  }

  if (isExposureFinancialOnly(userOrganization)) {
    columnData = columnData.filter((col) => col.id !== 'coverage_decision');
  }

  return columnData;
}
