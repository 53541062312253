import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import { Formik } from 'formik';
import { TextBoxCheckOutline } from 'mdi-material-ui';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../../Utils';
import { useFetchClaim } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import { ClaimContextProvider, useClaim } from '../ClaimContainer';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import PencilIcon from '../icons/PencilIcon';
import LoadingDialog from '../LoadingDialog';
import { ShowOnlyTextField } from '../TextFieldFormik';

import TravelAssessmentDocumentsContainer from './AssessmentDocumentsContainer';
import { FormikEstimationDocuments } from './EstimationDocumentsContainer';
import { isExposureWriteDisabled } from './ExposureUtils';

import { useStyles } from '../../assets/styles';

function TravelDamageAssessmentContainer(props) {
  const { claimId, exposure, viewOnly, iconOnly, onUpdate } = props;

  const { claim, onClaimUpdate } = useClaim();
  const { user } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { damage_assessment } = exposure;
  const newDamageAssessmentInitialState =
    !damage_assessment || damage_assessment.amount === null || damage_assessment.amount === undefined;
  const [newDamageAssessment, setNewDamageAssessment] = useState(newDamageAssessmentInitialState);

  const viewOnlyDamageAssessment = viewOnly || isExposureWriteDisabled(exposure, user);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewDamageAssessment(newDamageAssessmentInitialState);
  };

  function onUpdateIfExists() {
    if (onUpdate) {
      return onUpdate();
    } else {
      return Promise.resolve();
    }
  }

  const onSubmitDamageAssessment = async (values) => {
    try {
      await axios.post(`/api/v1/claims/${claimId}/exposures/${exposure.id}/damage_assessments`, values);
      if (claim) await onClaimUpdate();
      await onUpdateIfExists();
      setNewDamageAssessment(values.amount === null || values.amount === undefined);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
    setIsDialogOpen(false);
  };

  if (!damage_assessment && viewOnlyDamageAssessment) {
    return <></>;
  }

  return (
    <>
      <HoverActionField
        icon={viewOnlyDamageAssessment ? InfoIcon : PencilIcon}
        onAction={() => setIsDialogOpen(true)}
        permanent={!damage_assessment}
      >
        {damage_assessment ? (
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            <TextBoxCheckOutline />
            {!iconOnly && damage_assessment.amount !== null && (
              <>&nbsp;{currencyFormatter.format(damage_assessment.amount)}</>
            )}
          </span>
        ) : (
          <em>Set</em>
        )}
      </HoverActionField>
      {isDialogOpen && (
        <TravelDamageAssessmentCard
          claimId={claimId}
          exposure={exposure}
          isNewDamageAssessment={newDamageAssessment}
          damageAssessment={damage_assessment}
          cardDialogProps={{
            isDialog: true,
            open: isDialogOpen,
            closeOnBackdropClick: damage_assessment && !newDamageAssessment,
          }}
          viewOnly={viewOnlyDamageAssessment}
          onSubmitDamageAssessment={onSubmitDamageAssessment}
          onSelectNewDamageAssessment={() => setNewDamageAssessment(true)}
          onCancel={handleCloseDialog}
        />
      )}
    </>
  );
}

TravelDamageAssessmentContainer.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposure: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool,
  iconOnly: PropTypes.bool,
  onUpdate: PropTypes.func,
};

const travelDamageAssessmentFields = {
  proof_documents_ids: [],
  assessment_documents_data: [],
};

function TravelDamageAssessmentCard(props) {
  const {
    claimId,
    exposure,
    isNewDamageAssessment,
    damageAssessment,
    viewOnly,
    onSubmitDamageAssessment,
    onSelectNewDamageAssessment,
    cardDialogProps,
    onCancel,
  } = props;
  const classes = useStyles();

  const [claim, isLoading, isError, reloadClaim] = useFetchClaim(claimId);
  const { currencyFormatter } = useCurrencyFormatter();

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} track="Travel Damage Assessment - Loading Claim" />;
  }

  let initialValues = damageAssessment ? { ...damageAssessment } : { ...travelDamageAssessmentFields };

  const totalAssessmentAmount = (documentsData) => {
    return documentsData ? documentsData.reduce((partialSum, el) => partialSum + parseInt(el.amount), 0) : 0;
  };

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, formikProps) => {
          const valuesWithAmount = { ...values, amount: totalAssessmentAmount(values['assessment_documents_data']) };
          try {
            await onSubmitDamageAssessment(valuesWithAmount);
            formikProps.resetForm();
          } catch {
            formikProps.setSubmitting(false);
          }
        }}
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps;
          return (
            <CardDialog
              title="Damage Assessment"
              maxWidth="lg"
              onClose={() => {
                formikProps.handleReset();
                onCancel();
              }}
              preventClose={isSubmitting}
              {...cardDialogProps}
            >
              <Grid container alignItems="center" spacing={1}>
                <h3>Proof</h3>
                <Grid item xs={12}>
                  <FormikEstimationDocuments
                    claim={claim}
                    exposureId={exposure.id}
                    showOnly={!isNewDamageAssessment}
                    id="proof_documents_ids"
                    maxHeight="300px"
                    disableViewPhotos
                    disableAddingEstimation
                    disableUploadPhoto
                    disableAddPhoto
                    disableDocumentUploadDate
                  />
                  <ErrorHelperTextFormik id="proof_documents_ids" />
                </Grid>
                <h3>Assessment</h3>
                <Grid item xs={12}>
                  <TravelAssessmentDocumentsContainer
                    showOnly={!isNewDamageAssessment}
                    id="assessment_documents_data"
                    maxHeight="300px"
                    assessmentDocumentsMetaData={values['assessment_documents_data']}
                    onUpdateAssessmentDocumentMetaData={(assessmentDocumentMetaData) =>
                      setFieldValue('assessment_documents_data', assessmentDocumentMetaData)
                    }
                  />
                  <ErrorHelperTextFormik id="assessment_documents_data" />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12}>
                  <ShowOnlyTextField
                    id="total_amount"
                    label="Total Amount"
                    showOnlyValueComponent={currencyFormatter.format(
                      totalAssessmentAmount(values['assessment_documents_data'])
                    )}
                    className={classes.textField}
                    classes={classes}
                  />
                </Grid>
              </Grid>
              <div className={classes.buttonsContainer}>
                <CancelButton disabled={isSubmitting} onClick={onCancel} />
                {!isNewDamageAssessment ? (
                  <>
                    {!viewOnly && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={onSelectNewDamageAssessment}
                      >
                        Update Damage Assessment
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {!viewOnly && (
                      <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                        Set Damage Assessment
                      </Button>
                    )}
                  </>
                )}
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </ClaimContextProvider>
  );
}

TravelDamageAssessmentCard.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposure: PropTypes.object.isRequired,
  damageAssessment: PropTypes.object,
  isNewDamageAssessment: PropTypes.bool.isRequired,
  cardDialogProps: PropTypes.object.isRequired,
  onSubmitDamageAssessment: PropTypes.func.isRequired,
  onSelectNewDamageAssessment: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

export default TravelDamageAssessmentContainer;
