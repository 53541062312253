import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';

import { reportAxiosError } from '../../../../Utils';
import type { JurisdictionInformationFormValues } from '../../FormFragments/FormValuesTypes';
import { JurisdictionInformationForm } from '../../FormFragments/JurisdictionInformationForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const EditJurisdictionInformationDialog: React.FC<DialogPropsWithLegalAction> = ({
  legalAction,
  onClose,
  onSubmitSuccess,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: JurisdictionInformationFormValues) => {
    try {
      await axios.patch(backendRoutes.legalAction.jurisdiction, values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<JurisdictionInformationFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: pick(legalAction, [
          'jurisdiction_state',
          'venue',
          'court',
          'date_filled_in_court',
        ]) as JurisdictionInformationFormValues,
      }}
      title="Edit Jurisdiction Information"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <JurisdictionInformationForm />
      </div>
    </FormDialog>
  );
};
