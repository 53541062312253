const BIKE_ANTI_THEFT_DICT = {
  none: {
    desc: 'None',
  },
  gps: {
    desc: 'GPS',
  },
  gps_and_anti_theft: {
    desc: 'GPS & anti theft',
  },
} as const;

export default BIKE_ANTI_THEFT_DICT;
