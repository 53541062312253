import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';

import { serverDateToLocalMoment } from '../../DateTimeUtils';
import { getPolicyStatusDuringLoss, getPolicyStatusSpan } from '../../PolicyUtils';
import { CONFIGURATION_FEATURES_NAMES, MARSHMALLOW_WEATHER_CONDITIONS_DICT } from '../../Types';
import { isFeatureEnabled, isMarshmallowPolicy, isTscPolicy, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { ControlFileClaimSummaryChip, DeferredFileClaimSummaryChip, SiuClaimSummaryChip } from '../ClaimSummary';
import MarshmallowFnolPolicyVerificationsContainer from '../ClientSpecific/Marshmallow/MarshmallowFnolPolicyVerification';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import {
  CustomIncidentDetailsFieldEditor,
  CustomIncidentDetailsFieldForm,
  DateOfLossFormikPicker,
} from '../Fnol/IncidentDetailsCard';
import InsuredInfoVerificationCard from '../Fnol/InsuredInfoVerificationCard';
import { LossLocationShowOnly, LossLocationTextFieldFormik } from '../GlobalLossLocation';
import { useCms } from '../hooks/useCms';
import HoverChangeField from '../HoverChangeField';
import IncidentMoreActionsContainer from '../IncidentMoreActionsContainer';
import IncidentMutualChips from '../IncidentMutualChips';
import IncidentTypeUpdateDialog from '../IncidentTypeUpdateDialog';
import useOrganization from '../OrganizationContext';
import PoliceReportField, { getPoliceInvolvementFields } from '../PoliceReportField';
import { usePolicy } from '../PolicyContainer';
import TextFieldFormik, { ShowOnlyTextField, TimePickerTextFieldFormik } from '../TextFieldFormik';

import AutoFireDepartmentField from './AutoFireDepartmentField';

import { useStyles } from '../../assets/styles';

const autoIncidentDetailsFields = {
  date_of_loss: '',
  time_of_loss: '',
  incident_type: '',
  incident_sub_type: '',
  liability_indicator: '',
  liability_summary: '',
  reporter_contact_id: '',
  primary_contact_id: '',
  loss_location: null,
  description: '',
  special_internal_note: '',
  is_cat: '',
  is_siu: '',
  siu_report_status: '',
  were_police_involved: '',
  police_department: '',
  police_report: '',
  did_speak_with_officer: '',
  was_ticket_received: '',
  violation_title: '',
  were_fire_department_involved: null,
  fire_department_description: '',
};

const autoIncidentDetailsValidationFields = {
  date_of_loss: Yup.date().required('Required'),
  time_of_loss: Yup.string()
    .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
    .nullable(),
  incident_type: Yup.string().required('Required'),
  incident_sub_type: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  liability_indicator: Yup.string(),
  liability_summary: Yup.string(),
  were_police_involved: Yup.boolean().nullable().required('Required'),
  were_fire_department_involved: Yup.boolean().nullable(),
  fire_department_description: Yup.string().nullable(),
  loss_location: Yup.object().nullable().required('Required'), // we rely on LossLocationTextFieldFormik to pass only reliable values
};

const spacing = 1;
const CLAIM_TYPE = 'auto_claim';

function AutoIncidentDetailsFormikInner() {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { incidentDetailsExtraFields } = useOrganization();
  const { policy } = usePolicy();
  const { userOrganization } = useCms();

  const [showInsuredVerificationCard, setShowInsuredVerificationCard] = useState(false);
  const insuredDetailsVerified = getIn(values, 'verified_insured_info');

  const policyStatusDuringLoss = values.date_of_loss
    ? getPolicyStatusDuringLoss(policy, values.date_of_loss, values.time_of_loss)
    : null;

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            {insuredDetailsVerified ? (
              <Button onClick={() => setShowInsuredVerificationCard(true)}>Insured info is verified &gt;</Button>
            ) : (
              <div>
                <Button color="secondary" onClick={() => setShowInsuredVerificationCard(true)}>
                  <ErrorIcon />
                  Verify insured info &gt;
                </Button>
                <ErrorHelperTextFormik id="verified_insured_info" />
              </div>
            )}
            {(isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.UK_FNOL_FIELDS_MM_STYLE) ||
              isMarshmallowPolicy(policy)) && (
              <MarshmallowFnolPolicyVerificationsContainer
                fnolMarshmallowValues={values.marshmallow_extra}
                isVerified={!!values.verified_policy_info}
                verificationFormikId="verified_policy_info"
                onSubmitVerification={(verificationDetails) => {
                  setFieldValue('verified_policy_info', true);
                  setFieldValue('marshmallow_extra', { ...values.marshmallow_extra, ...verificationDetails });
                }}
              />
            )}
          </span>
        }
      >
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            <DateOfLossFormikPicker />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik id="time_of_loss" label="Time of Loss" fullWidth className={classes.textField} />
          </Grid>

          {getPolicyStatusSpan(policyStatusDuringLoss, policy)}

          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="reporter_contact"
              label="Reporter"
              className={classes.textField}
              acceptedRoles={[
                'insured',
                'named_driver',
                'agent',
                'claimant',
                'spouse',
                'family_member',
                'public_adjuster',
                'insurer',
                'attorney',
                'other',
              ]}
              contactSearchProps={{ newContactRole: 'claimant' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="primary_contact"
              label="Preferred Contact"
              acceptedRoles={[
                'insured',
                'named_driver',
                'spouse',
                'family_member',
                'public_adjuster',
                'attorney',
                'agent',
                'other',
              ]}
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LossLocationTextFieldFormik
              id="loss_location"
              label="Loss Location"
              defaultValues={!policy.is_manual && isTscPolicy(policy) ? { state: 'NY' } : undefined}
              secondaryColorForSetButton
              withIsHighway
            />
          </Grid>
          <Grid item xs={12}>
            <PoliceReportField
              policeReportDetails={getPoliceInvolvementFields(values)}
              onUpdate={(values) => Object.keys(values).forEach((key) => setFieldValue(key, values[key]))}
            />
            <ErrorHelperTextFormik style={{ marginTop: 0 }} id="were_police_involved" />
          </Grid>
          <Grid item xs={12}>
            <AutoFireDepartmentField
              wereFireDepartmentInvolved={values.were_fire_department_involved}
              onUpdate={(values) => Object.keys(values).forEach((key) => setFieldValue(key, values[key]))}
            />
          </Grid>
          {(isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.UK_FNOL_FIELDS_MM_STYLE) ||
            isMarshmallowPolicy(policy)) && (
            <>
              <YesNoQuestionFormik
                questionText="Customer has proactively asked if it can be notification only?"
                id="marshmallow_extra.is_notification_only"
              />
              <YesNoQuestionFormik questionText="Was there any CCTV footage?" id="marshmallow_extra.was_cctv_footage" />
              <Grid item xs={6}>
                <TextFieldFormik
                  id="marshmallow_extra.weather_conditions"
                  label="Weather Conditions"
                  className={classes.textField}
                  fullWidth
                  select
                >
                  {Object.keys(MARSHMALLOW_WEATHER_CONDITIONS_DICT).map((type) => (
                    <MenuItem key={type} value={type}>
                      {MARSHMALLOW_WEATHER_CONDITIONS_DICT[type].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
            </>
          )}
          {incidentDetailsExtraFields[CLAIM_TYPE]?.map((customFieldProps) => (
            <CustomIncidentDetailsFieldForm key={customFieldProps.field_name} {...customFieldProps} />
          ))}
          <Grid item xs={12}>
            <TextFieldFormik
              id="special_internal_note"
              label="Special Internal Note"
              className={classes.textField}
              fullWidth
              multiline
              rows="3"
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik
              id="description"
              label="Description"
              className={classes.textField}
              fullWidth
              multiline
              rows="8"
            />
          </Grid>
        </Grid>
      </CardDialog>
      {showInsuredVerificationCard && (
        <InsuredInfoVerificationCard
          insuredContactId={values.insured_contact.id}
          onDetailsVerified={() => setFieldValue('verified_insured_info', true)}
          onClose={() => setShowInsuredVerificationCard(false)}
        />
      )}
    </>
  );
}

function AutoIncidentDetailsCard(props) {
  const classes = useStyles();
  const { incidentDetailsExtraFields } = useOrganization();
  const { claim, onUpdate, readOnly } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [showIncidentTypeUpdateDialog, setShowIncidentTypeUpdateDialog] = useState(false);

  const {
    incident,
    claim_extra: { custom_extra: customExtra },
  } = claim;
  const disabled = readOnly || isFetching;

  const handleCustomExtraField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ custom_extra: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ incident: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentFields = async (updateObject) => {
    await handleUpdateFields({ incident: updateObject });
  };

  const handleUpdateClaimField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ [fieldName]: fieldValue });
  };

  const handleUpdateFields = async (updateObject) => {
    // TODO - Add api to update only the incident
    try {
      setIsFetching(true);
      await axios.patch(`/api/v1/auto_claims/${claim.id}`, updateObject);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsFetching(false);
  };

  const autoIncidentsDict = claim.incident_types_dict;

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          !readOnly && <IncidentMoreActionsContainer incident={incident} onUpdateFields={handleUpdateIncidentFields} />
        }
      >
        <IncidentMutualChips claim={claim} />
        {incident.is_siu && <SiuClaimSummaryChip />}
        {incident.is_control_file && <ControlFileClaimSummaryChip />}
        {incident.is_deferred_file && <DeferredFileClaimSummaryChip />}
        {incident.is_uw && <Chip size="small" color="primary" label="UW" className={classes.chip} />}
        <Grid container alignItems="stretch" spacing={1}>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={incident.incident_type_desc}
              label="Incident type"
              onEdit={disabled ? undefined : () => setShowIncidentTypeUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={incident.incident_sub_type_desc}
              label="Incident sub-type"
              onEdit={disabled ? undefined : () => setShowIncidentTypeUpdateDialog(true)}
            />
          </Grid>
          <Grid item xs={12}>
            <LossLocationShowOnly
              location={claim.incident.loss_location}
              label="Loss Location"
              disabled={disabled}
              onUpdate={async (newLossLocation) => await handleUpdateIncidentField('loss_location', newLossLocation)}
              withIsHighway
            />
          </Grid>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={claim.reporter_contact_id}
              contactDisplayName={claim.reporter_contact_full_name}
              label="Reporter"
              fullWidth
              showOnly
            />
          </Grid>
          <Grid item xs={6}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={serverDateToLocalMoment(claim.creation_date)}
              label="Reported On"
              width="100%"
            />
          </Grid>
          <Grid item xs={12}>
            <PoliceReportField
              policeReportDetails={getPoliceInvolvementFields(incident)}
              onUpdate={async (values) => await handleUpdateFields({ incident: values })}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <AutoFireDepartmentField
              wereFireDepartmentInvolved={incident.were_fire_department_involved}
              fireDepartmentDescription={incident.fire_department_description}
              onUpdate={async (values) => await handleUpdateFields({ incident: values })}
              disabled={disabled}
            />
          </Grid>
          {incidentDetailsExtraFields[CLAIM_TYPE]?.map((customFieldProps) => {
            return (
              <Grid item xs={12} key={customFieldProps.field_name}>
                <CustomIncidentDetailsFieldEditor
                  {...customFieldProps}
                  onUpdate={handleCustomExtraField}
                  disabled={disabled}
                  currentValues={customExtra || {}}
                />
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <HoverChangeField
              name="special_internal_note"
              value={claim.special_internal_note}
              label="Special Internal Note"
              onUpdate={(updatedValues) =>
                handleUpdateClaimField('special_internal_note', updatedValues['special_internal_note'])
              }
              fullWidth
              multiline
              rows={5}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.special_internal_note}
                label="Special Internal Note"
                maxHeight="5.3em" // ~ 3 line
                width="100%"
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={12}>
            <HoverChangeField
              name="description"
              value={claim.incident.description}
              label="Description"
              onUpdate={async (updatedValues) =>
                await handleUpdateIncidentField('description', updatedValues['description'])
              }
              fullWidth
              multiline
              rows={8}
              width="450px"
              showOnly
              disabled={disabled}
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={claim.incident.description}
                label="Description"
                maxHeight="12em" // ~ 8 line
                width="100%"
              />
            </HoverChangeField>
          </Grid>
        </Grid>
      </CardDialog>

      {showIncidentTypeUpdateDialog && (
        <IncidentTypeUpdateDialog
          incidentTypesDict={autoIncidentsDict}
          incidentType={claim.incident.incident_type}
          incidentSubType={claim.incident.incident_sub_type}
          onUpdate={async (values) => await handleUpdateFields({ incident: values })}
          onClose={() => setShowIncidentTypeUpdateDialog(false)}
        />
      )}
    </>
  );
}

AutoIncidentDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  // onUpdate is not required, but we disallow onEdit if
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool,
};

export {
  AutoIncidentDetailsCard,
  autoIncidentDetailsFields,
  AutoIncidentDetailsFormikInner,
  autoIncidentDetailsValidationFields,
};
