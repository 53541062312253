import useDataFetcher from '~/components/useDataFetcher';

interface PolicyLimitBreach {
  reason: string;
}

interface FetchPaymentPossibilityRet {
  isLoadingPaymentIssues: boolean;
  isErrorPaymentIssues: boolean;
  policyLimitBreaches: PolicyLimitBreach[];
  isPaymentIssuePreventingPayment: boolean | null;
}

export default function useFetchPaymentPossibility(
  claimId: number,
  exposureIdOrNull: number | null,
  payableType: string,
  amount: number
): FetchPaymentPossibilityRet {
  const isIndemnity = payableType === 'indemnity';

  const {
    isLoading: isLoadingPaymentIssues,
    isError: isErrorPaymentIssues,
    data: {
      is_valid: paymentIsValid,
      policy_limit_breaches: policyLimitBreaches,
      approve_option: paymentIssuesApproveOption,
    } = {
      is_valid: null,
      policy_limit_breaches: [],
      approve_option: null,
    },
  } = useDataFetcher(
    isIndemnity ? `/api/v1/claims/${claimId}/exposures/${exposureIdOrNull}/indemnity/payment_possibility` : null,
    { params: { amount, should_include_pending_payments: false } },
    isIndemnity
  );
  const isPaymentIssuePreventingPayment = !paymentIsValid && paymentIssuesApproveOption === 'none';

  return { isLoadingPaymentIssues, isErrorPaymentIssues, policyLimitBreaches, isPaymentIssuePreventingPayment };
}
