import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const CommunicationsIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.68934 3.43934C2.97064 3.15804 3.35217 3 3.75 3H15.75C16.1478 3 16.5294 3.15804 16.8107 3.43934C17.092 3.72065 17.25 4.10218 17.25 4.5V7.5H20.25C20.6478 7.5 21.0294 7.65804 21.3107 7.93934C21.592 8.22065 21.75 8.60218 21.75 9V21C21.75 21.289 21.584 21.5522 21.3232 21.6768C21.0625 21.8013 20.7534 21.765 20.5286 21.5833L17.0223 18.75H8.25C7.85218 18.75 7.47065 18.592 7.18934 18.3107C6.90804 18.0294 6.75 17.6478 6.75 17.25V14.434L3.47139 17.0833C3.24663 17.265 2.93752 17.3013 2.67677 17.1768C2.41601 17.0522 2.25 16.789 2.25 16.5V4.5C2.25 4.10217 2.40804 3.72064 2.68934 3.43934ZM8.25 14.25V17.25H17.2875C17.4591 17.25 17.6254 17.3088 17.7589 17.4167L20.25 19.4297V9H17.25V12.75C17.25 13.1478 17.092 13.5294 16.8107 13.8107C16.5294 14.092 16.1478 14.25 15.75 14.25H8.25ZM15.75 4.5H3.75L3.75 14.9297L6.24111 12.9167C6.37455 12.8088 6.54093 12.75 6.7125 12.75H15.75L15.75 4.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);

CommunicationsIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default CommunicationsIcon;
