import React from 'react';
import { Divider, TableCell } from '@material-ui/core';

import styles from './claimPageBar.module.scss';

const DividerCell = () => (
  <TableCell className={styles.dividerCell}>
    <Divider orientation="vertical" variant="middle" className={styles.divider} />
  </TableCell>
);

export default DividerCell;
