import React, { useState } from 'react';
import axios from 'axios';
import type { FormikHelpers, FormikValues } from 'formik';
import { Formik } from 'formik';
import { chain, flatMap, isEmpty, isEqual, pickBy, reduce } from 'lodash';
import * as Yup from 'yup';

import { useClaim } from '~/components/ClaimContainer';
import type { ContactFullModel } from '~/components/Contacts/types';
import type {
  InitialValuesType,
  SpecialValidationRequirementsType,
  Tab,
} from '~/components/Contacts/UpsertContact/types';
import { useContactTabs } from '~/components/Contacts/UpsertContact/useContactTabs';
import WarningDuplicateContactsDialog from '~/components/Contacts/UpsertContact/WarningDuplicateContactsDialog';
import { PERMISSION_VERBS } from '~/components/core';
import { useSanitizeByPermissions } from '~/components/hooks/useHasPermission';
import { reportAxiosError } from '~/Utils';

interface UpsertContactFormikPropType {
  contact?: ContactFullModel;
  onClose: () => void;
  onSubmit: (payload: Record<string, unknown>) => Promise<void>;
  onPreparePayload?: (payload: Record<string, unknown>) => void;
  specialValidationRequirements?: SpecialValidationRequirementsType;
  initialValues?: InitialValuesType;
  dialogInnerComponent: React.ReactElement;
}

const UpsertContactFormik: React.FC<UpsertContactFormikPropType> = ({
  contact: existingContact,
  onClose,
  onSubmit,
  onPreparePayload,
  specialValidationRequirements = {},
  initialValues,
  dialogInnerComponent,
}) => {
  const { claim } = useClaim();
  const { allTabs, filteredTabs } = useContactTabs();
  const [duplicateData, setDuplicateData] = useState(null);
  const [ignoreDuplicates, setIgnoreDuplicates] = useState(false);
  const isEditing = !!existingContact;

  const sanitizePayload = useSanitizeByPermissions({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fieldsPermissionActions: reduce(
      allTabs,
      (result, tab) => ({
        ...result,
        ...(tab.fieldsPermissionActions || {}),
      }),
      {}
    ),
    verb: PERMISSION_VERBS.WRITE,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    forbiddenFields: flatMap(allTabs, (tab) => tab.forbiddenFields || []),
  });

  const getInitialValues = () => {
    return chain(allTabs)
      .keyBy('tabKey')
      .mapValues((tab) => tab.getInitialValues(existingContact, initialValues))
      .value();
  };

  const getValidationSchema = () => {
    const validations = chain(allTabs)
      .keyBy('tabKey')
      .mapValues((tab) => Yup.object().shape(tab.getValidationSchema(specialValidationRequirements)))
      .value();

    return Yup.object().shape(validations);
  };

  const wereTabValuesUnchanged = (tab: Tab, values1: FormikValues, values2: FormikValues) => {
    const formattedValues1 = tab.getValuesToCompare(values1);
    const formattedValues2 = tab.getValuesToCompare(values2);

    return isEqual(formattedValues1, formattedValues2);
  };

  const prepareTabPayload = async (tab: Tab, values: FormikValues) => {
    const initialTabValues = tab.getInitialValues(existingContact);
    if (isEditing && wereTabValuesUnchanged(tab, initialTabValues, values[tab.tabKey])) {
      return {};
    }

    if (tab.getPreparedPayload) {
      return await tab.getPreparedPayload(values[tab.tabKey]);
    } else {
      if (isEditing) {
        return pickBy(values[tab.tabKey], (value, key) => !isEqual(value, initialTabValues[key]));
      } else {
        return values[tab.tabKey];
      }
    }
  };

  const preparePayload = async (filteredTabs: Tab[], values: FormikValues, claim: { id: number }) => {
    const payloadPromises = filteredTabs.map(async (tab) => await prepareTabPayload(tab, values));
    const tabPayloads = await Promise.all(payloadPromises);
    const tabs = filteredTabs.map((tab, index) => ({ tabKey: tab.tabKey, payload: tabPayloads[index] }));

    const payload = chain(tabs)
      .keyBy('tabKey')
      .mapValues('payload')
      .pickBy((tabPayload) => !isEmpty(tabPayload))
      .value();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    payload.claim_id = claim?.id;

    const sanitizedPayload = sanitizePayload(payload);

    if (onPreparePayload) {
      return onPreparePayload(sanitizedPayload);
    } else {
      return sanitizedPayload;
    }
  };

  const checkDuplicates = async (tabs: Tab[], values: FormikValues) => {
    if (ignoreDuplicates) {
      return { has_duplicates: false };
    }

    const params = reduce(
      tabs,
      (result, tab) => ({
        ...result,
        ...(tab.getDuplicateCheckParams ? tab.getDuplicateCheckParams(values) : {}),
      }),
      {}
    );

    const response = await axios.get(`/api/v1/contacts/duplicates`, { params });

    return response.data;
  };

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<FormikValues>) => {
    try {
      const duplicateResponse = await checkDuplicates(filteredTabs, values);
      if (duplicateResponse.has_duplicates) {
        setDuplicateData(duplicateResponse);
      } else {
        const payload = await preparePayload(filteredTabs, values, claim);
        await onSubmit(payload);
        onClose();
      }
    } catch (error) {
      setSubmitting(false);
      await reportAxiosError(error);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={getValidationSchema()}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ submitForm, isSubmitting }) => {
        const submitFormIgnoreDups = async () => {
          setIgnoreDuplicates(true);
          await submitForm();
        };

        return (
          <>
            {dialogInnerComponent}
            {duplicateData ? (
              <WarningDuplicateContactsDialog
                duplicates={duplicateData}
                onCreateDuplicate={submitFormIgnoreDups}
                onCancel={() => setDuplicateData(null)}
                disabled={isSubmitting}
              />
            ) : null}
          </>
        );
      }}
    </Formik>
  );
};

export default UpsertContactFormik;
