import React from 'react';
import { get } from 'lodash';

import { useStyles } from '~/assets/styles';
import AdjusterSection from '~/components/communications/CommunicationCard/AdjusterSection';
import AttachToContactSection from '~/components/communications/CommunicationCard/AttachToContactSection';
import CommunicationCardHeader from '~/components/communications/CommunicationCard/CommunicationCardHeader';
import CommunicationDateTimeSection from '~/components/communications/CommunicationCard/CommunicationDateTimeSection';
import CommunicationIdentifierSection from '~/components/communications/CommunicationCard/CommunicationIdentifierSection';
import type { ChannelType, Direction } from '~/components/communications/CommunicationCard/constants';
import ContactSection from '~/components/communications/CommunicationCard/ContactSection';
import { getCommunicationIconSettings } from '~/components/communications/CommunicationCard/utils';
import type { ContactBaseModel } from '~/components/Contacts/types';
import { Caption, Heading, Text } from '~/components/core';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { useCms } from '~/components/hooks/useCms';
import { serverDateTimeToLocal } from '~/DateTimeUtils';
import { CONFIGURATION_FEATURES_NAMES, RECIPIENTS_TYPES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';
import cn from '~/Utils/cn';

import type { Communication } from '../types';

import type { CommunicationAdditionalHeaderProp } from './types';

interface CommunicationFullHeaderProps {
  communication: Communication;
  contact: ContactBaseModel;
  communicationIdentifier: string;
  additionalHeaderProp?: CommunicationAdditionalHeaderProp;
  communicationClaimId?: number;
  channel: ChannelType;
  direction: Direction;
  isView?: boolean;
  onCommunicationUpdate?: () => void;
}

const CommunicationFullHeader: React.FC<CommunicationFullHeaderProps> = ({
  communication,
  contact,
  communicationIdentifier,
  additionalHeaderProp,
  communicationClaimId,
  channel,
  direction,
  isView,
  onCommunicationUpdate,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);
  const adjusterRecipientType = communication?.adjuster_recipient_type;

  if (isNewUIEnabled && isView) {
    const settings = getCommunicationIconSettings(communication, channel, direction);
    const isContactAttached = !!contact?.id;

    return (
      <div className="pb-20">
        <div className={cn('flex justify-between rounded-lg border border-solid p-12', settings.borderColor)}>
          <div className="flex items-center">
            <div
              className={cn(
                'm-4 mr-20 box-content rounded-lg p-4 text-3xl',
                settings.iconClassName,
                settings.textColor,
                settings.bgColor
              )}
            />
            <div>
              {isContactAttached ? (
                <ContactSection
                  contactId={contact.id}
                  contactRole={contact?.role}
                  contactFullName={contact?.full_name}
                />
              ) : (
                <AttachToContactSection
                  communicationId={communication?.id}
                  communicationClaimId={communicationClaimId}
                  communicationChannel={channel}
                  onAttachToContact={onCommunicationUpdate}
                />
              )}
              <CommunicationIdentifierSection channel={channel} communicationIdentifier={communicationIdentifier} />
              <AdjusterSection
                internalEmailAddress={communication?.internal_email_address}
                adjusterRecipientType={communication?.adjuster_recipient_type}
                adjuster={communication?.adjuster}
                direction={direction}
              />
            </div>
          </div>
          <CommunicationDateTimeSection communicationDateTime={communication.datetime} />
        </div>
      </div>
    );
  }

  const adjusterRecipientTypeDesc = get(RECIPIENTS_TYPES, adjusterRecipientType || '', '');
  const withAdjusterRecipientType = !!adjusterRecipientTypeDesc && adjusterRecipientType !== 'to';

  return (
    <>
      <CommunicationCardHeader
        communicationId={communication?.id}
        communicationClaimId={communicationClaimId}
        channel={channel}
        direction={direction}
        contactId={contact?.id}
        contactFullName={contact?.full_name}
        contactRole={contact?.role}
        isView={isView}
        onCommunicationUpdate={onCommunicationUpdate}
        {...additionalHeaderProp}
      />
      {isView ? (
        <>
          <div className="flex items-center justify-center">
            <Text variant={Text.VARIANTS.SM}>{communicationIdentifier}</Text>
          </div>
          <div className="flex items-center justify-center">
            <Heading variant={Heading.TYPES.H3}>{serverDateTimeToLocal(communication.datetime)}</Heading>
          </div>
          <div className="flex items-center justify-center">
            <Tooltip
              title={communication?.internal_email_address || ''}
              disabled={!communication?.internal_email_address}
            >
              <span className={classes.containerCentered}>
                {`${direction === 'Outgoing' ? 'from:' : 'to:'} ${communication.adjuster} `} &nbsp;
                {withAdjusterRecipientType ? (
                  <Caption variant={Caption.VARIANTS.LABEL}>({adjusterRecipientTypeDesc})</Caption>
                ) : null}
              </span>
            </Tooltip>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CommunicationFullHeader;
