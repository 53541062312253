import React from 'react';
import { FieldArray, useFormikContext } from 'formik';

import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';

import CheckboxFormik from '../../../CheckboxFormik';
import { AddItemButton, FormItemCard, MainCard } from '../../../core';
import { LEGAL_ACTION_FIELDS, LEGAL_ACTION_PARTY_FIELDS } from '../../FormFragments/FormFieldsDefinitions';
import type { LegalActionPartyFormValues, NewLegalActionValues } from '../../FormFragments/FormValuesTypes';
import { PartyForm } from '../../FormFragments/PartyForm';
import { getPartyTypeLabel } from '../../LegalActionsUtils';
import { PartyType } from '../../types';
import { partyInitialValues } from '../InitialValues';

import { DeleteIconButton } from './DeleteIconButton';

const partyTypeToFieldMap = {
  [PartyType.PLAINTIFF]: LEGAL_ACTION_FIELDS.legalActionPlaintiffs.key,
  [PartyType.DEFENDANT]: LEGAL_ACTION_FIELDS.legalActionDefendants.key,
};

export const PartiesFormCard: React.FC<{ partyType: PartyType }> = ({ partyType }) => {
  const partyTypeLabel = getPartyTypeLabel(partyType);
  const fieldKey = partyTypeToFieldMap[partyType];

  const { errors } = useFormikContext<NewLegalActionValues>();

  return (
    <MainCard type="contained" title={`${partyTypeLabel} Info`}>
      {typeof errors[fieldKey as keyof NewLegalActionValues] === 'string' ? (
        <ErrorHelperTextFormik id={fieldKey} />
      ) : null}
      <FieldArray
        name={fieldKey}
        render={({ form, remove, push }) => {
          const parties: LegalActionPartyFormValues[] = form.values[fieldKey].filter(
            (party: LegalActionPartyFormValues) => party.type === partyType
          );

          const partiesHaveCarrierInvolvedParty = Object.values(partyTypeToFieldMap)
            .reduce((arr, key) => [...arr, ...form.values[key]], [] as LegalActionPartyFormValues[])
            .some((party: LegalActionPartyFormValues) => party.is_carrier_party);

          return (
            <>
              {parties.map((party: LegalActionPartyFormValues, index: number) => {
                const title = `${partyTypeLabel} #${index + 1}`;

                const isCarrierParty = party[LEGAL_ACTION_PARTY_FIELDS.isCarrierParty.key];
                const shouldShowCarrierInvolvedParty = !partiesHaveCarrierInvolvedParty || isCarrierParty;

                const titleAction = shouldShowCarrierInvolvedParty ? (
                  <CheckboxFormik
                    checkboxStyle={{ padding: '0 9px' }}
                    id={`${fieldKey}.${index}.${LEGAL_ACTION_PARTY_FIELDS.isCarrierParty.key}`}
                    label="Carrier Involved Party"
                  />
                ) : undefined;

                return (
                  <FormItemCard
                    key={title}
                    title={title}
                    titleAction={titleAction}
                    action={<DeleteIconButton onClick={() => remove(index)} index={0} />}
                  >
                    <div className="flex w-full flex-col gap-20">
                      <PartyForm
                        partyType={partyType}
                        index={index}
                        arrayFieldPrefix={fieldKey}
                        hideCarrierInvolvedParty
                      />
                    </div>
                  </FormItemCard>
                );
              })}
              <div>
                <AddItemButton
                  text={`Add ${partyTypeLabel}`}
                  variant="text"
                  onClick={() => {
                    push({
                      type: partyType,
                      ...partyInitialValues,
                    });
                  }}
                />
              </div>
            </>
          );
        }}
      />
    </MainCard>
  );
};
