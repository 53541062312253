import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Formik, getIn, useFormikContext } from 'formik';
import _, { get, isEmpty } from 'lodash';
import * as Yup from 'yup';

import AddressAutocompleteFormik from '~/components/core/AddressAutocomplete/AddressAutocompleteFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { COUNTRY_TO_STATE_MAP, UK_COUNTRIES_DICT } from '../Types';
import { getPostalCodeYupTestParams, isTscPolicy, locationToLocationFullString, reportAxiosError } from '../Utils';

import { ErrorHelperTextFormik } from './core/Formik/ErrorHelperTextFormik';
import useGlobalAddresses from './hooks/useGlobalAddresses';
import CardDialog from './CardDialog';
import CheckboxFormik from './CheckboxFormik';
import { FsIconButton } from './core';
import CountryAutocompleteFormik from './CountryAutocompleteFormik';
import { CloseIcon, PencilIcon } from './icons';
import OverflowTextWithToolTip from './OverflowTextWithToolTip';
import { usePolicy } from './PolicyContainer';
import StateAutocompleteFormik from './StateAutocompleteFormik';
import { ShowOnlyTextField, TextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

const getLossLocationFields = (defaultCountry) => {
  return {
    address1: '',
    address2: '',
    city: '',
    county: '',
    state: '',
    zipcode: '',
    country: defaultCountry,
    description: '',
    is_highway: false,
    country_in_uk: '',
  };
};

const lossLocationValidationSchema = (policy, countries, dropRequiredValidation) => ({
  address1: Yup.string(),
  address2: Yup.string().nullable(),
  city: isTscPolicy(policy) || dropRequiredValidation ? Yup.string() : Yup.string().required('Required'),
  zipcode: Yup.string().when(['country', 'country_in_uk'], (country, country_in_uk, currSchema) => {
    const validator = currSchema.test(...getPostalCodeYupTestParams(false, country));
    if (country === 'GB' && !country_in_uk && !dropRequiredValidation) {
      return validator.required('Either zipcode or UK country is required');
    }
    return validator;
  }),
  county: Yup.string(),
  state: Yup.string().when('country', (country, currSchema) =>
    country === 'US' && !dropRequiredValidation ? currSchema.required('Required') : undefined
  ),
  country: Yup.string().oneOf(countries, 'Country is not supported for loss location').required('Required'),
  country_in_uk: Yup.string().when(['country', 'zipcode'], (country, zipcode, currSchema) => {
    if (country === 'GB' && !zipcode && !dropRequiredValidation) {
      return currSchema.required('Either zipcode or UK country is required');
    }
  }),
});

function LossLocationDialog({
  location,
  onCancel,
  onSave,
  withIsHighway,
  label,
  dropRequiredValidation = false,
  countriesConfigurationKey = 'loss_location',
}) {
  const { policy } = usePolicy();

  const classes = useStyles();

  const { countriesOptions, defaultCountry } = useGlobalAddresses({ countriesConfigurationKey });

  const locationWithoutNulls = _.pickBy(location, (val) => val !== null); // to avoid weird Yup errors without making all fields .nullable()
  const initialValues = { ...getLossLocationFields(defaultCountry), ...locationWithoutNulls };

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      validationSchema={Yup.object().shape(
        lossLocationValidationSchema(policy, countriesOptions, dropRequiredValidation),
        [['zipcode', 'country_in_uk']]
      )}
      onSubmit={async (values) => {
        try {
          await onSave(values);
        } catch (error) {
          reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleSubmit, setFieldValue } = formikProps;
        const isCountryUS = values.country === 'US';
        const shouldShowUKCountry = values.country === 'GB';

        return (
          <CardDialog title={label ? label : 'Loss Location'} isDialog onClose={onCancel} preventClose={isSubmitting}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CountryAutocompleteFormik
                  id="country"
                  label="Country"
                  fullWidth
                  country={values.country}
                  onChange={(_, newVal) => {
                    setFieldValue('country', newVal ? newVal : '');
                    setFieldValue('state', '');
                  }}
                  countriesConfigurationKey={countriesConfigurationKey}
                />
              </Grid>
              {withIsHighway && (
                <Grid item xs={12}>
                  <CheckboxFormik
                    id="is_highway"
                    label="Location is a highway"
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextFieldFormik id="description" label="Description" fullWidth className={classes.textField} />
              </Grid>
              {!values.is_highway && (
                <>
                  <Grid item xs={12}>
                    <AddressAutocompleteFormik
                      id="address1"
                      label="Address 1"
                      fullWidth
                      className={classes.textField}
                      helperText={!isCountryUS && 'Street name & number, P.O. box, C/O, etc.'}
                      streetAddress1FieldId="address1"
                      countyFieldId="county"
                      countryFieldId="country"
                      zipcodeFieldId="zipcode"
                      cityFieldId="city"
                      stateFieldId="state"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="address2"
                      label="Address 2"
                      fullWidth
                      className={classes.textField}
                      helperText={!isCountryUS && 'Apartment, suite, unit, building name, floor, etc.'}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={isCountryUS && countriesConfigurationKey === 'vehicle_location' ? 12 : 6}>
                <TextFieldFormik id="city" label="City" fullWidth className={classes.textField} />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik id="zipcode" label="Postal Code" className={classes.textField} fullWidth />
              </Grid>
              {isCountryUS && countriesConfigurationKey === 'vehicle_location' && (
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="zipcode_extension"
                    label="Zip Code Extension"
                    className={classes.textField}
                    fullWidth
                  />
                </Grid>
              )}
              {isCountryUS && (
                <Grid item xs={6}>
                  <TextFieldFormik id="county" label="County" className={classes.textField} fullWidth />
                </Grid>
              )}
              <Grid item xs={6}>
                <StateFieldFormik id="state" fullWidth className={classes.textField} country={values.country} />
              </Grid>
              {shouldShowUKCountry && (
                <Grid item xs={6}>
                  <TextFieldFormik id="country_in_uk" select label="UK Country" fullWidth className={classes.textField}>
                    {Object.keys(UK_COUNTRIES_DICT)
                      .sort((s1, s2) => UK_COUNTRIES_DICT[s1].localeCompare(UK_COUNTRIES_DICT[s2]))
                      .map((country) => (
                        <MenuItem key={country} value={country}>
                          {UK_COUNTRIES_DICT[country]}
                        </MenuItem>
                      ))}
                  </TextFieldFormik>
                </Grid>
              )}
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

LossLocationDialog.propTypes = {
  location: PropTypes.object,
  label: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  withIsHighway: PropTypes.bool,
  dropRequiredValidation: PropTypes.bool,
  countriesConfigurationKey: PropTypes.string,
};

function lossLocationToString(location) {
  let locationCopy = { ...location };
  if (location.is_highway) {
    locationCopy.description = location.description + ' (Highway)';
    locationCopy.address1 = undefined;
    locationCopy.address2 = undefined;
  }

  if (location.country !== 'US') {
    locationCopy.zipcode_extension = undefined;
  }

  if (!['US', 'AU'].includes(location.country)) {
    locationCopy.state = undefined;
  }

  if (location.country !== 'GB') {
    locationCopy.country_in_uk = undefined;
  }

  let locationString = locationToLocationFullString(locationCopy);

  let description = location.description;
  if (location.is_highway) {
    description = [description, '(Highway)'].join(' ');
  }
  if (description) {
    locationString = description + ', ' + locationString;
  }

  return locationString;
}

function LossLocationTextFieldFormik({
  id,
  label,
  defaultValues,
  secondaryColorForSetButton,
  withIsHighway,
  flatten = false,
  dropRequiredValidation = false,
  disabled = false,
  countriesConfigurationKey = 'loss_location',
  withClearButton = false,
}) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const classes = useStyles();
  const { values, touched, errors, setFieldValue } = useFormikContext();
  const { defaultCountry } = useGlobalAddresses({ countriesConfigurationKey });

  const locationValues = get(values, id);
  const location = { ...defaultValues, ...locationValues };

  return (
    <>
      {locationValues || flatten ? (
        <div className={classes.containerCentered}>
          <TextField
            label={label}
            value={lossLocationToString(location)}
            fullWidth
            disabled={disabled}
            className={classes.textField}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    <FsIconButton
                      size="small"
                      onClick={() => setIsDialogOpen(true)}
                      icon={PencilIcon}
                      disabled={disabled}
                    />
                    {withClearButton && !(disabled || isEmpty(locationValues)) && (
                      <FsIconButton size="small" onClick={() => setFieldValue(id, {})} tooltipText="Clear">
                        <CloseIcon size={12} />
                      </FsIconButton>
                    )}
                  </>
                </InputAdornment>
              ),
            }}
            error={getIn(errors, id) && getIn(touched, id)}
            helperText={getIn(errors, id) && getIn(touched, id) && getIn(errors, id)}
          />
        </div>
      ) : (
        <>
          <Button color={secondaryColorForSetButton ? 'secondary' : undefined} onClick={() => setIsDialogOpen(true)}>
            Set Loss Location
          </Button>
          <ErrorHelperTextFormik id={id} />
        </>
      )}
      {isDialogOpen && (
        <LossLocationDialog
          location={{ ...getLossLocationFields(defaultCountry), ...location }}
          label={label}
          defaultValues={defaultValues}
          onCancel={() => setIsDialogOpen(false)}
          onSave={(newLocation) => {
            setFieldValue(id, newLocation);
            setIsDialogOpen(false);
          }}
          withIsHighway={withIsHighway}
          dropRequiredValidation={dropRequiredValidation}
          countriesConfigurationKey={countriesConfigurationKey}
        />
      )}
    </>
  );
}

LossLocationTextFieldFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  secondaryColorForSetButton: PropTypes.bool,
  withIsHighway: PropTypes.bool,
  flatten: PropTypes.bool,
  dropRequiredValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  countriesConfigurationKey: PropTypes.string,
  withClearButton: PropTypes.bool,
};

function LossLocationShowOnly(props) {
  const { location, label, disabled, onUpdate, withIsHighway, dropRequiredValidation = false } = props;
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const myRef = useRef();

  const classes = useStyles();

  return (
    <>
      <div className={classes.containerCentered} ref={myRef}>
        <ShowOnlyTextField
          label={label}
          showOnlyValueComponent={
            <OverflowTextWithToolTip maxWidth={myRef.current ? myRef?.current?.offsetWidth - 30 : undefined}>
              {lossLocationToString(location)}
            </OverflowTextWithToolTip>
          }
          fullWidth
          classes={classes}
          className={classes.textField}
          disabled={disabled}
          onEdit={!disabled ? () => setIsDialogOpen(true) : undefined}
          doNotRenderInTypography
        />
      </div>
      {isDialogOpen && (
        <LossLocationDialog
          location={location}
          onCancel={() => setIsDialogOpen(false)}
          onSave={async (newLocation) => {
            await onUpdate(newLocation);
            setIsDialogOpen(false);
          }}
          withIsHighway={withIsHighway}
          dropRequiredValidation={dropRequiredValidation}
        />
      )}
    </>
  );
}

LossLocationShowOnly.propTypes = {
  location: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  withIsHighway: PropTypes.bool,
  dropRequiredValidation: PropTypes.bool,
};

function StateFieldFormik(props) {
  const { id, label, disabled, country } = props;
  const classes = useStyles();

  const isStateCountry = country in COUNTRY_TO_STATE_MAP;

  return isStateCountry ? (
    <StateAutocompleteFormik
      id={id}
      label={label || 'State'}
      statesDict={COUNTRY_TO_STATE_MAP[country]}
      disabled={disabled}
    />
  ) : (
    <TextFieldFormik
      id={id}
      label={label || 'State\\province\\region'}
      disabled={disabled}
      fullWidth
      className={classes.textField}
    />
  );
}

StateFieldFormik.propTypes = {
  id: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export { LossLocationShowOnly, LossLocationTextFieldFormik, lossLocationToString, StateFieldFormik };
