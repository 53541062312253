import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useFormikContext } from 'formik';

import CheckboxFormik from '~/components/CheckboxFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';

const PhoneCallRecordingToggle = ({ communication, setCommunication }) => {
  const { values, setFieldValue } = useFormikContext();
  const { is_recording_allowed: isRecordingAllowed } = values;

  const [isUpdatingRecording, setIsUpdatingRecording] = useState(false);
  let recordingButtonTitle;
  let updateRecordingApi;
  const recordingStatus = communication.recording_status;

  useEffect(() => {
    if (recordingStatus !== null && isRecordingAllowed === false) {
      setFieldValue('is_recording_allowed', true);
    }
  }, [isRecordingAllowed, recordingStatus, setFieldValue]);

  switch (recordingStatus) {
    case null:
      recordingButtonTitle = 'Start Recording';
      updateRecordingApi = 'start_recording';
      break;
    case 'started':
    case 'resumed':
      recordingButtonTitle = 'Pause Recording';
      updateRecordingApi = 'pause_recording';
      break;
    case 'paused':
      recordingButtonTitle = 'Resume Recording';
      updateRecordingApi = 'resume_recording';
      break;
    default:
      reportErrorInProductionOrThrow(`Unknown Recording status ${recordingStatus}`);
  }

  const handleToggleRecording = async () => {
    setIsUpdatingRecording(true);
    try {
      const communicationRes = await axios.post(`/api/v1/calls/${communication.id}/${updateRecordingApi}`);
      setCommunication(communicationRes.data);
    } catch (error) {
      reportAxiosError(error);
    }
    setIsUpdatingRecording(false);
  };

  return (
    <Grid container spacing={1}>
      {recordingStatus === null ? (
        <Grid item xs={12}>
          <CheckboxFormik id="is_recording_allowed" label="Receive end user permission to record the call" />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleToggleRecording}
          disabled={!isRecordingAllowed || isUpdatingRecording}
        >
          {recordingButtonTitle}
        </Button>
      </Grid>
    </Grid>
  );
};

PhoneCallRecordingToggle.propTypes = {
  communication: PropTypes.object.isRequired,
  setCommunication: PropTypes.func.isRequired,
};

export default PhoneCallRecordingToggle;
