import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useTableau } from '~/components/ReportsPage/Context/TableauContext';
import { removeLastSegment, sortProjects } from '~/components/ReportsPage/helpers/ReportsUtils';
import { useReportsPermissions } from '~/components/ReportsPage/Hooks/useReportsPermissions';
import { useTableauApi } from '~/components/ReportsPage/Hooks/useTableauApi';
import type { Project, ReportAction } from '~/components/ReportsPage/types';
import { reportAxiosError } from '~/Utils';

export type ReportActionHandler = ({
  action,
  params,
}: {
  action: ReportAction;
  params: { id?: string; projectId?: string };
}) => Promise<void>;

interface IReportsActions {
  action: ReportAction | null;
  onClose: () => void;
  onActionHandler: ReportActionHandler;
  allowedProjectsToShare: Project[];
  showSuccessMessage: boolean;
  onCloseSuccessMessage: () => void;
  isLoading: boolean;
}

const REPORTS_BASE_URL = '/reports_v2';

export const useReportsActions = (): IReportsActions => {
  const history = useHistory();
  const match = useRouteMatch();
  const [internalAction, setInternalAction] = React.useState<ReportAction | null>(null);
  const [selectedWorkbookId, setSelectedWorkbookId] = React.useState<string | null>(null);
  const { deleteWorkbook, copyWorkbook } = useTableauApi();
  const { reloadAll, workbooksMap, projects } = useTableau();
  const { allowedProjects } = useReportsPermissions();
  const allowedProjectsToShare: Project[] = React.useMemo(() => {
    const filtered = projects.filter((project) => allowedProjects.includes(project.name));
    return sortProjects({ projects: filtered });
  }, [projects, allowedProjects]);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const onEdit = React.useCallback(() => {
    if (!selectedWorkbookId) return;
    const isPathContainsId = match.url.includes(selectedWorkbookId || '');
    const path = isPathContainsId ? match.url : `${match.url}/${selectedWorkbookId}`;
    history.push(`${path}/edit`);
  }, [history, match, selectedWorkbookId]);

  const onDelete = React.useCallback(async () => {
    if (!selectedWorkbookId) return;
    try {
      setIsLoading(true);
      await deleteWorkbook({ workbookId: selectedWorkbookId });
      setIsLoading(false);
      const shouldGoBack = match.url.includes(selectedWorkbookId || '');
      const path = shouldGoBack ? removeLastSegment(match.url) : match.url;
      history.push(path);
      await reloadAll();
      if (!shouldGoBack) setShowSuccessMessage(true);
    } catch (error) {
      setIsLoading(false);
      setShowSuccessMessage(false);
      reportAxiosError(error);
    }
  }, [history, match, deleteWorkbook, reloadAll, selectedWorkbookId]);

  const onShare = React.useCallback(
    async ({ workbookName, projectId }) => {
      if (!selectedWorkbookId) return;
      try {
        setIsLoading(true);
        await copyWorkbook({ workbookId: selectedWorkbookId, workbookName, projectId });
        await reloadAll();
        setIsLoading(false);
        setShowSuccessMessage(true);
      } catch (error) {
        setIsLoading(false);
        setShowSuccessMessage(false);
        reportAxiosError(error);
      }
    },
    [copyWorkbook, selectedWorkbookId, reloadAll]
  );

  const onClose = React.useCallback(() => {
    setInternalAction(null);
  }, [setInternalAction]);

  const onActionHandler = React.useCallback(
    async ({ action, params }) => {
      setInternalAction(action);
      if (params?.id) {
        setSelectedWorkbookId(params?.id);
      }
      switch (action) {
        case 'create':
          history.push(`${REPORTS_BASE_URL}/create`);
          break;
        case 'view':
          history.push(`${match.url}/${params.id}`);
          break;
        case 'edit':
          onEdit();
          break;
        case 'delete':
          await onDelete();
          setInternalAction(null);
          break;
        case 'share':
          await onShare({
            workbookId: selectedWorkbookId,
            workbookName: selectedWorkbookId ? workbooksMap[selectedWorkbookId]?.name : 'copy of workbook',
            projectId: params?.projectId,
          });
          setInternalAction(null);
          break;
        default:
          return;
      }
    },
    [history, match, onEdit, onDelete, onShare, selectedWorkbookId, workbooksMap]
  );

  return {
    action: internalAction,
    onClose,
    onActionHandler,
    allowedProjectsToShare,
    showSuccessMessage,
    onCloseSuccessMessage: () => setShowSuccessMessage(false),
    isLoading,
  };
};
