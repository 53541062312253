import React from 'react';
import { useFormikContext } from 'formik';
import { findKey, get, pick } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import {
  BODILY_INJURY_FIELDS,
  PROPERTY_DAMAGE_FIELDS,
} from '~/components/Fnol/NewFnolUI/InvolvedParties/GeneralClaimParties/Generallnvolved';

import { cleanEmptyValues } from '../../../../../Utils';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { cleanConfiguredFieldsFormikValuesBeforeSend } from '../../../../IncidentConfiguration/ConfiguredFields';
import { involvedGenericFields } from '../InvolvedGenericParty/InvolvedGenericFragment';

import GeneralInvolvedPartiesCard from './GeneralInvolvedPartiesCard';

const FIRST_PARTY_VALUES = 'first_party_values';
const THIRD_PARTY_VALUES = 'third_party_values';

const getGeneralInvolvedPartiesValidationSchema = () => {
  return {
    [FIRST_PARTY_VALUES]: Yup.array()
      .of(
        Yup.object().shape({
          involved_details_filled: Yup.boolean().oneOf([true], 'Required'),
        })
      )
      .min(1, 'At least one first party is required'),
  };
};

const generalInvolvedPartiesInitialValues = {
  first_party_values: [],
  third_party_values: [],
};

const buildGeneralInvolvedParties = ({ values }) => {
  const involvedParties = [];

  values?.first_party_values.forEach((party) => {
    involvedParties.push({
      ...cleanEmptyValues(party),
      external_id: uuidv4(),
      type: party.type,
      is_first_party: true,
      property_damage: cleanEmptyValues(get(party, 'property_damage', {})),
      related_property_loss: cleanEmptyValues(get(party, 'related_property_loss', {})),
      bodily_injury_loss: cleanEmptyValues(get(party, 'bodily_injury_loss', {})),
      pecuniary_loss: cleanEmptyValues(get(party, 'pecuniary_loss', {})),
    });
  });

  values?.third_party_values.forEach((party) => {
    if (getInvolvedGenericNameIfExist(party)) {
      involvedParties.push({
        ...cleanEmptyValues(party),
        external_id: uuidv4(),
        type: party.type,
        contact_id: party.contact_id,
        bodily_injuries: party?.injuries || [],
        is_first_party: false,
        related_property_loss: cleanEmptyValues(get(party, 'related_property_loss', {})),
        bodily_injury_loss: cleanEmptyValues(get(party, 'bodily_injury_loss', {})),
        pecuniary_loss: cleanEmptyValues(get(party, 'pecuniary_loss', {})),
      });
    }
  });
  return involvedParties;
};

const adjustGeneralInvolvedProperty = (genericPropertyInvolved) => {
  return {
    ...genericPropertyInvolved,
    property_damage: {
      ...pick(genericPropertyInvolved, PROPERTY_DAMAGE_FIELDS),
      property_damages: genericPropertyInvolved?.damages || [],
      property_damages_description: genericPropertyInvolved?.damages_description,
      is_property_stolen: genericPropertyInvolved?.is_stolen,
    },
    related_property_loss: {
      ...genericPropertyInvolved?.related_property_loss,
      damages: genericPropertyInvolved?.related_property_loss?.damages || [],
    },
    pecuniary_loss: {
      ...genericPropertyInvolved?.pecuniary_loss,
    },
  };
};

const adjustInvolvedPerson = (involvedPerson) => {
  return {
    ...involvedPerson,
    bodily_injury_loss: {
      ...pick(involvedPerson, BODILY_INJURY_FIELDS),
      bodily_injuries: involvedPerson.injuries || [],
    },
    related_property_loss: {
      ...involvedPerson?.related_property_loss,
      damages: involvedPerson?.related_property_loss?.damages || [],
    },
    pecuniary_loss: {
      ...involvedPerson?.pecuniary_loss,
    },
  };
};

const GeneralInvolvedPartiesCardFormik = () => {
  const { setFieldValue, values } = useFormikContext();
  const firstPartyValues = values[FIRST_PARTY_VALUES];
  const thirdPartyValues = values[THIRD_PARTY_VALUES];
  const { incidentConfiguration } = useIncidentConfiguration();

  const incidentConfigParties = incidentConfiguration?.involved_parties || {};

  const handleAddParty = (partyTypeKey) => {
    const partyIncidentConfig = incidentConfigParties[partyTypeKey];
    const isFirstParty = partyIncidentConfig?.is_first_party;
    const newValues = [
      ...(isFirstParty ? firstPartyValues : thirdPartyValues),
      {
        ...involvedGenericFields(incidentConfiguration, partyTypeKey),
        id: uuidv4(),
        type: partyTypeKey,
        general_claim_involved_type: partyTypeKey,
        covered_object_id: '',
        covered_object_external_id: '',
        involved_details_filled: false,
      },
    ];
    setFieldValue(isFirstParty ? FIRST_PARTY_VALUES : THIRD_PARTY_VALUES, newValues);
  };

  if (firstPartyValues.length === 0) {
    const incidentConfigFirstPartyKey = findKey(
      incidentConfigParties,
      (party) => party?.active && party?.is_first_party
    );
    handleAddParty(incidentConfigFirstPartyKey);
  }

  const handleSaveParty = async (partyValues, partyTypeKey) => {
    const partyIncidentConfig = incidentConfigParties[partyTypeKey];
    const isFirstParty = partyIncidentConfig?.is_first_party;
    const configuredFields = get(incidentConfiguration, `involved_parties.${partyTypeKey}.configured_fields`, []);
    const cleanConfiguredFields = cleanConfiguredFieldsFormikValuesBeforeSend(
      configuredFields,
      get(partyValues, 'configured_fields_values')
    );
    const fieldArrayName = isFirstParty ? FIRST_PARTY_VALUES : THIRD_PARTY_VALUES;
    partyValues = { ...partyValues, involved_details_filled: true, configured_fields_values: cleanConfiguredFields };
    const curIdx = values[fieldArrayName].findIndex((party) => party.id === partyValues.id);
    await Promise.resolve(setFieldValue(`${fieldArrayName}[${curIdx}]`, partyValues));
  };

  const handleDeleteFirstParty = async (partyValues) => {
    const newFirstPartyValues = values.first_party_values.filter((party, _) => party.id !== partyValues.id);
    await Promise.resolve(setFieldValue(FIRST_PARTY_VALUES, newFirstPartyValues));
  };

  const handleDeleteThirdParty = async (partyValues) => {
    const newThirdPartyValues = values.third_party_values.filter((party, _) => party.id !== partyValues.id);
    await Promise.resolve(setFieldValue(THIRD_PARTY_VALUES, newThirdPartyValues));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <GeneralInvolvedPartiesCard
          firstPartyValues={firstPartyValues}
          thirdPartyValues={thirdPartyValues}
          onFetchInvolvedDetails={(involvedHeader) => involvedHeader}
          onAddParty={handleAddParty}
          onSaveParty={handleSaveParty}
          onDeleteFirstParty={handleDeleteFirstParty}
          onDeleteThirdParty={handleDeleteThirdParty}
          isFnol
        />
      </Grid>
    </Grid>
  );
};

const getInvolvedGenericNameIfExist = (involvedGeneric) => {
  const contactFullName = involvedGeneric?.contact_full_name ?? involvedGeneric?.owner_contact_full_name;
  const displayName =
    involvedGeneric?.display_name ?? involvedGeneric.property_name ?? involvedGeneric.property_description;
  return displayName || contactFullName;
};

export {
  adjustGeneralInvolvedProperty,
  adjustInvolvedPerson,
  buildGeneralInvolvedParties,
  FIRST_PARTY_VALUES,
  GeneralInvolvedPartiesCardFormik,
  generalInvolvedPartiesInitialValues,
  getGeneralInvolvedPartiesValidationSchema,
  getInvolvedGenericNameIfExist,
};
