import React from 'react';

import SvgIcon from '../core/SvgIcon';

const PolicyIcon = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 32 32" fill="black" stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3.25C19.4142 3.25 19.75 3.58579 19.75 4V10.25H26C26.4142 10.25 26.75 10.5858 26.75 11C26.75 11.4142 26.4142 11.75 26 11.75H19C18.5858 11.75 18.25 11.4142 18.25 11V4C18.25 3.58579 18.5858 3.25 19 3.25Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3.25C6.53587 3.25 6.09075 3.43438 5.76256 3.76256C5.43438 4.09075 5.25 4.53587 5.25 5V16C5.25 16.4142 5.58579 16.75 6 16.75C6.41421 16.75 6.75 16.4142 6.75 16V5C6.75 4.9337 6.77634 4.87011 6.82322 4.82322C6.87011 4.77634 6.9337 4.75 7 4.75H18.6893L25.25 11.3107V27C25.25 27.0663 25.2237 27.1299 25.1768 27.1768C25.1299 27.2237 25.0663 27.25 25 27.25H19C18.5858 27.25 18.25 27.5858 18.25 28C18.25 28.4142 18.5858 28.75 19 28.75H25C25.4641 28.75 25.9092 28.5656 26.2374 28.2374C26.5656 27.9092 26.75 27.4641 26.75 27V11C26.75 10.8011 26.671 10.6103 26.5303 10.4697L19.5303 3.46967C19.3897 3.32902 19.1989 3.25 19 3.25H7ZM7.75 19.375V22.2727C7.75 26.1713 10.911 27.5771 11.8125 27.8977C12.714 27.5771 15.875 26.1713 15.875 22.2727V19.375H7.75ZM6.59781 18.2228C6.82051 18.0001 7.12255 17.875 7.4375 17.875H16.1875C16.5024 17.875 16.8045 18.0001 17.0272 18.2228C17.2499 18.4455 17.375 18.7476 17.375 19.0625V22.2727C17.375 27.3821 13.0354 29.0723 12.1945 29.3526C11.9474 29.4401 11.6776 29.4401 11.4305 29.3526C10.5896 29.0723 6.25 27.3821 6.25 22.2727V19.0625C6.25 18.7476 6.37511 18.4455 6.59781 18.2228ZM14.4984 21.4396C14.8079 21.7149 14.8357 22.1889 14.5604 22.4984L11.8922 25.4984C11.7498 25.6585 11.5458 25.7501 11.3315 25.75C11.1172 25.7499 10.9132 25.6582 10.771 25.498L9.43916 23.998C9.16415 23.6882 9.1923 23.2142 9.50204 22.9392C9.81179 22.6641 10.2858 22.6923 10.5608 23.002L11.3323 23.8709L13.4396 21.5016C13.7149 21.1921 14.1889 21.1643 14.4984 21.4396Z"
    />
  </SvgIcon>
);

export default PolicyIcon;
