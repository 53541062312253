import React, { useState } from 'react';
import reactRequiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import HistoryIcon from '@material-ui/icons/History';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportErrorInProductionOrThrow } from '../Utils';

import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import ClaimLink from './ClaimLink';
import { getClaimsTableColumnData } from './ClaimsTable';
import LoadingDialog from './LoadingDialog';
import LoadingIndicator from './LoadingIndicator';
import PlainTable from './PlainTable';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

function RelatedClaimsContainer() {
  const { claim } = useClaim();
  const classes = useStyles();

  const [showRelatedClaimDialog, setShowRelatedClaimDialog] = useState(false);

  if (!claim) {
    reportErrorInProductionOrThrow();
  }

  return (
    <>
      <CardDialog noCardTitle>
        <Button color="primary" onClick={() => setShowRelatedClaimDialog(true)}>
          <HistoryIcon className={classes.leftButtonIcon} />
          Claims History
        </Button>
      </CardDialog>
      {showRelatedClaimDialog && (
        <RelatedClaimsCardDialog claim={claim} isDialog onClose={() => setShowRelatedClaimDialog(false)} />
      )}
    </>
  );
}

function RelatedClaimsCardDialog({ claim, isDialog, onClose }) {
  const { isLoading, isError, data: relatedClaims } = useDataFetcher(`/api/v1/claims/${claim.id}/related_claims`);

  const classes = useStyles();

  if (isLoading || isError) {
    if (isDialog) return <LoadingDialog track="Loading claims history" isError={isError} onClose={onClose} />;
    else return <LoadingIndicator isError={isError} />;
  }

  if (relatedClaims.claims.length === 0 && relatedClaims.external_claims.rows.length === 0) {
    return <CardDialog isDialog={isDialog} title="No related claims were found" onClose={onClose} />;
  }

  const isExternalClaims = relatedClaims.external_claims.rows && relatedClaims.external_claims.rows.length > 0;

  let columnData = [
    ...getClaimsTableColumnData(true),
    // eslint-disable-next-line react/display-name, react/prop-types
    {
      id: 'select_button',
      numeric: false,
      label: '',
      specialCell: (claim) => <ClaimLink openInNewTab claimId={claim.id} linkText="Go to claim" />,
    },
  ];

  return (
    <CardDialog isDialog={isDialog} title="Related Claims" onClose={onClose} maxWidth="lg" fullWidth>
      <Grid container spacing={2} style={{ marginTop: '8px' }}>
        {isExternalClaims && (
          <Grid item xs={12}>
            <CardDialog title={relatedClaims.external_claims.title}>
              <PlainTable
                columns={relatedClaims.external_claims.columns.map((column) => ({
                  id: column,
                  label: _.startCase(column),
                }))}
                rows={relatedClaims.external_claims.rows}
              />
            </CardDialog>
          </Grid>
        )}
        <Grid item xs={12}>
          <CardDialog title={isExternalClaims ? 'Claims in Five Sigma' : ''}>
            <PlainTable classes={classes} columns={columnData} rows={relatedClaims.claims} />
          </CardDialog>
        </Grid>
      </Grid>
    </CardDialog>
  );
}

RelatedClaimsCardDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  isDialog: PropTypes.bool,
  onClose: reactRequiredIf(PropTypes.func, (props) => props.isDialog),
};

export { RelatedClaimsCardDialog };
export default RelatedClaimsContainer;
