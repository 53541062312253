import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { useClaim } from '~/components/ClaimContainer';
import InnerTabs from '~/components/core/Layout/InnerTabs/InnerTabs';
import useDataFetcher from '~/components/useDataFetcher';
import { formatSortOrder, reportAxiosError } from '~/Utils';

import CardDialog from '../../CardDialog';

import SummaryTab from './SummaryTab/SummaryTab';
import PaymentsTab from './PaymentsTab';

import styles from '../../SystemConfiguration/systemConfiguration.module.scss';

const FINANCES_PER_PAGE = 10;

const FinancesScreen = ({ isTabActive = true }) => {
  const { claim } = useClaim();
  const financeDataFetch = useDataFetcher(`/api/v1/claims/${claim.id}/finances/summary`);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [finances, setFinances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisablePagination, setIsDisablePagination] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(0);
  const [totalFinancesCount, setTotalFinancesCount] = useState(0);
  const [sortByColumn, setSortByColumn] = useState({ id: 'request_date', order: 'desc' });

  const handleFiltersChange = (filters) => {
    setPage(0);
    setSelectedFilters(filters);
  };

  const getFinances = useCallback(async () => {
    const params = {
      page_number: page + 1,
      results_per_page: FINANCES_PER_PAGE,
      sort_by_column: formatSortOrder(sortByColumn),
      ...selectedFilters,
    };

    try {
      setIsDisablePagination(true);

      const res = await axios.get(`/api/v1/claims/${claim.id}/finances`, { params });
      setFinances(res.data.finances);
      setTotalFinancesCount(res.data.count);
      setIsLoading(false);
    } catch (err) {
      reportAxiosError(err);
      setIsError(true);
    }
    setIsDisablePagination(false);
  }, [page, sortByColumn, selectedFilters, claim]);

  useEffect(() => {
    getFinances();
  }, [getFinances]);

  const tabs = [
    {
      label: 'Summary',
      url: 'summary',
      component: SummaryTab,
      props: { ...financeDataFetch },
    },
    {
      label: 'Payments',
      url: 'payments',
      component: PaymentsTab,
      props: {
        selectedFilters,
        onFiltersChange: handleFiltersChange,
        finances,
        isLoading,
        isError,
        page,
        setPage,
        totalFinancesCount,
        sortByColumn,
        setSortByColumn,
        isDisablePagination,
        financesPerPage: FINANCES_PER_PAGE,
      },
    },
  ];

  return isTabActive ? (
    <CardDialog>
      <InnerTabs tabs={tabs} renderContainerClassName={styles.innerTabsContainer} />
    </CardDialog>
  ) : null;
};

FinancesScreen.propTypes = {
  isTabActive: PropTypes.bool,
};

export default FinancesScreen;
