import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { isHospitalityUser, isMgmClaim, isRwClaim, reportAxiosError } from '~/Utils';

import { getAllSearchableContactRoles } from './communications/ContactUtils';
import SendEmailCommunicationCardContainer from './communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from './ContactTextFieldFormik';
import { contactPopulate } from './ContactUtils';
import HoverActionField from './HoverActionField';
import InlineIconButton from './InlineIconButton';
import LoadingDialog from './LoadingDialog';
import useOrganization from './OrganizationContext';
import TextFieldFormik, { ShowOnlyTextField } from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';

function ExternalCarrierReferralContainer({ onUpdate, disabled, track, title }) {
  const { claim } = useClaim();
  const { user } = useCms();
  const classes = useStyles();
  const [showReferralDialog, setShowReferralDialog] = useState(false);
  const [referralToEdit, setReferralToEdit] = useState(undefined);
  const [showSendReferralEmailDialog, setShowSendReferralEmailDialog] = useState(false);

  if (!isMgmClaim(claim) && !isRwClaim(claim)) {
    if (isHospitalityUser(user)) {
      return null;
    }
    throw Error('MGM / RW only component');
  }

  const handleReferralSent = async (values, isEdit) => {
    try {
      if (isEdit) {
        await axios.patch(`/api/v1/claims/${claim.id}/external_carrier_referrals/${values.id}`, values);
      } else {
        await axios.post(`/api/v1/claims/${claim.id}/external_carrier_referrals`, values);
      }
      await onUpdate();
      setShowReferralDialog(false);
      setReferralToEdit(undefined);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <div style={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body2">{title}</Typography>
          <InlineIconButton
            className={classes.inlineEditIcon}
            icon={AddIcon}
            onClick={() => setShowReferralDialog(true)}
          />
          <Button
            variant="outlined"
            color="primary"
            className={classes.inlineEditIcon}
            onClick={() => setShowSendReferralEmailDialog(true)}
          >
            Send Referral
          </Button>
        </div>
        {claim?.external_referrals.length > 0 && (
          <Grid item xs={12}>
            <List dense>
              {claim.external_referrals.map((referral) => (
                <ListItem key={referral.id}>
                  <HoverActionField icon={EditIcon} onAction={() => setReferralToEdit(referral)} fullWidth>
                    <Grid container>
                      <Grid item xs={4}>
                        <ContactShowOnlyTextField
                          label="Carrier"
                          contactId={referral.external_carrier_id}
                          contactDisplayName={referral.external_carrier_full_name}
                        />
                      </Grid>
                      {referral.external_adjuster_id && (
                        <Grid item xs={4}>
                          <ContactShowOnlyTextField
                            label="Adjuster"
                            contactId={referral.external_adjuster_id}
                            contactDisplayName={referral.external_adjuster_full_name}
                          />
                        </Grid>
                      )}
                      {referral.carrier_claim_number && (
                        <Grid item xs={4}>
                          <ShowOnlyTextField
                            classes={classes}
                            showOnlyValueComponent={referral.carrier_claim_number}
                            label="Ref."
                          />
                        </Grid>
                      )}
                    </Grid>
                  </HoverActionField>
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>
      {showReferralDialog && (
        <ExternalReferralDialog
          onClose={() => setShowReferralDialog(false)}
          onSubmit={handleReferralSent}
          disabled={disabled}
        />
      )}
      {referralToEdit && (
        <ExternalReferralDialog
          referral={referralToEdit}
          onClose={() => setReferralToEdit(undefined)}
          onSubmit={(values) => handleReferralSent(values, true)}
          disabled={disabled}
        />
      )}
      {showSendReferralEmailDialog && (
        <SendExternalReferralEmailDialog
          onClose={() => setShowSendReferralEmailDialog(false)}
          track={track}
          summary={title}
        />
      )}
    </>
  );
}
ExternalCarrierReferralContainer.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  track: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

function ExternalReferralDialog({ referral, onClose, onSubmit, disabled }) {
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <Formik
      initialValues={
        referral
          ? referral
          : {
              carrier_claim_number: '',
              external_adjuster_id: '',
              external_carrier_id: '',
              communication_id: '',
            }
      }
      validationSchema={Yup.object().shape({
        carrier_claim_number: Yup.string().max(128).nullable(),
        external_adjuster_id: Yup.number().nullable(),
        external_carrier_id: Yup.number().required('Required'),
      })}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <>
            <CardDialog title="External Carrier Referral" isDialog onClose={() => onClose()} maxWidth="lg">
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <ContactTextFieldFormik
                    id="external_carrier"
                    label="External Carrier"
                    acceptedRoles={['risk_carrier', 'insurer', 'carrier', 'other']}
                    className={classes.textField}
                    fullWidth
                    fixedSearchResults
                  />
                </Grid>
                <Grid item xs={4}>
                  <ContactTextFieldFormik
                    id="external_adjuster"
                    label="External Adjuster"
                    acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
                    className={classes.textField}
                    fullWidth
                    fixedSearchResults
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldFormik
                    id="carrier_claim_number"
                    label="Carrier Claim Number"
                    className={classes.textField}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting || disabled}>
                  Save
                </Button>
              </div>
            </CardDialog>
          </>
        );
      }}
    </Formik>
  );
}
ExternalReferralDialog.propTypes = {
  referral: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

function SendExternalReferralEmailDialog({ onClose, track, summary }) {
  const { claim, onAsyncClaimUpdate } = useClaim();

  const {
    isLoading,
    isError,
    data: emailData,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/external_carrier_referral_email`);

  if (isLoading || isError) {
    return <LoadingDialog track={track} isError={isError} onClose={onClose} />;
  }

  const ccContacts =
    emailData.contacts.length > 1
      ? emailData.contacts.slice(1).map((contact) => ({
          contact_id: contact.id,
          contact,
          email_id: contact.emails?.[0].id,
          id: uuidv4(),
        }))
      : [];

  return (
    <SendEmailCommunicationCardContainer
      contact={emailData.contacts.length > 0 && contactPopulate(emailData.contacts[0])}
      cc_contacts={ccContacts}
      onClose={onClose}
      onSendEmail={async () => {
        await onAsyncClaimUpdate();
        onClose();
      }}
      emailTitle={emailData.email_title}
      emailText={emailData.email_body_html}
      summary={summary}
      exposureIds={claim.exposures.length > 0 ? [claim.exposures[0].id] : [0]}
    />
  );
}
SendExternalReferralEmailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  track: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};

export { ExternalCarrierReferralContainer };
