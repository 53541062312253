const COMMUNICATIONS_AI = {
  sentiment: ['positive', 'neutral', 'negative'],
  urgency: ['low', 'medium', 'high'],
  status: {
    IN_PROGRESS: 'in-progress',
    COMPLETE: 'complete',
    ERROR: 'error',
    PARTIAL_ERROR: 'partial-error',
  },
} as const;

export default COMMUNICATIONS_AI;
