import React from 'react';
import * as PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import { connect, getIn } from 'formik';

const ErrorHelperTextFormik = connect((props) => {
  const { formik, id, withoutChildren } = props;
  const { errors, touched } = formik;

  let errorMsg = getIn(errors, id);
  const isErrorOfChildrenOnly = errorMsg && typeof errorMsg !== 'string';

  if (isErrorOfChildrenOnly) {
    // we probably have a nested validation and error is an array of error messages
    errorMsg = JSON.stringify(errorMsg);
  }

  if (!(getIn(errors, id) && getIn(touched, id))) {
    return <></>;
  }

  if ((isErrorOfChildrenOnly && !withoutChildren) || !isErrorOfChildrenOnly) {
    return (
      <FormHelperText {...props} error>
        {errorMsg}
      </FormHelperText>
    );
  }

  return <></>;
});

ErrorHelperTextFormik.propTypes = {
  id: PropTypes.string.isRequired,
  withoutChildren: PropTypes.bool,
};
export { ErrorHelperTextFormik };
