import * as Yup from 'yup';

export const SMS_FORM_FORMIK_IDS = {
  MESSAGE_CONTENT: 'message_content',
  CONTACT_ID: 'contact_id',
  CONTACT: 'contact',
  PHONE_ID: 'phone_id',
  EXPOSURE_IDS: 'exposure_ids',
  SUMMARY: 'summary',
  ADD_FOLLOW_UP_REMINDER: 'add_follow_up_reminder',
};

export const smsMessageValidation = Yup.object().shape({
  [SMS_FORM_FORMIK_IDS.MESSAGE_CONTENT]: Yup.string().required('Required'),
  [SMS_FORM_FORMIK_IDS.CONTACT_ID]: Yup.number().required('Required'),
  [SMS_FORM_FORMIK_IDS.CONTACT]: Yup.object()
    .test(
      'is-phone-exists',
      'Contact must contain at least one phone number',
      (contact) => contact && contact.phones && contact.phones.length > 0
    )
    .test(
      'is-smsing-allowed',
      'Sending text messages to this contact is not permitted',
      (contact) => contact && contact.is_smsing_allowed !== false
    ),
  [SMS_FORM_FORMIK_IDS.PHONE_ID]: Yup.number().required('Required'),
  [SMS_FORM_FORMIK_IDS.EXPOSURE_IDS]: Yup.array().required('Required').min(1, 'Required'),
  [SMS_FORM_FORMIK_IDS.SUMMARY]: Yup.string(),
  [SMS_FORM_FORMIK_IDS.ADD_FOLLOW_UP_REMINDER]: Yup.boolean(),
});

export const MAX_SMS_LENGTH = 1400; // Twilio limit is 1600, but it includes \n\r characters, so we take a (big) safety

export function getAttachmentFileUrl(attachment) {
  return !attachment.document
    ? `/api/v1/communications/sms/${attachment.communication_id}/attachments/${attachment.id}/stored_file`
    : `/api/v1/claims/${attachment.document.claim_id}/documents/${attachment.document.id}`;
}

export const smsCommunicationToFormikValues = (communication) => ({
  summary: communication.summary,
  message_content: communication.message,
});

export function getSmsCommunicationSpecificIdentifier(communication) {
  // communication.specific_identifier isn't set for all of sms communications, as some time ago
  // each SMS communication had a contact
  return communication.specific_identifier || communication.contact_phone?.phone;
}
