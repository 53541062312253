import { useTableauConfiguration } from '~/components/ReportsPage/Hooks/useTableauConfiguration';

type EmbedUrlProps = {
  workbook?: string;
  view?: string;
  queryParams?: string;
  size?: string;
};

type EmbedCreatorUrlProps = {
  datasourceName: string;
};

interface TableauEmbedRoutes {
  baseUrl?: string;
  getEmbedUrl: (p: EmbedUrlProps) => string;
  getEmbedCreatorUrl: (p: EmbedCreatorUrlProps) => string;
  getImageUrl: (p: EmbedUrlProps) => string;
}

export const useTableauEmbedRoutes = (): TableauEmbedRoutes => {
  const { host_url, target_site } = useTableauConfiguration();
  const baseUrl = `${host_url}/t/${target_site}`;
  const getEmbedUrl = ({ workbook, view, queryParams }: EmbedUrlProps): string => {
    return `${baseUrl}/views/${workbook}/${view}${queryParams ? '?' : ''}${queryParams ?? ''}`;
  };

  const getEmbedCreatorUrl = ({ datasourceName }: EmbedCreatorUrlProps): string => {
    return `${baseUrl}/authoringNewWorkbook/${crypto.randomUUID()}/${datasourceName}`;
  };

  const getImageUrl = ({ workbook, view, size }: EmbedUrlProps): string => {
    return `${baseUrl}/views/${workbook}/${view}.png${size ? '?:size=' : ''}${size ?? ''}`;
  };

  return {
    baseUrl,
    getEmbedUrl,
    getEmbedCreatorUrl,
    getImageUrl,
  };
};
