import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import UnderlineIcon from '../../../../icons/editor/UnderlineIcon';
import ToolbarButton from '../ToolbarButton';

const Underline: React.FC = () => {
  const { editor } = useCurrentEditor();

  const toggle = () => {
    editor?.chain().focus().toggleUnderline().run();
  };

  return (
    <ToolbarButton
      onClick={toggle}
      disabled={!editor?.can().toggleUnderline()}
      isActive={editor?.isActive('underline')}
    >
      <UnderlineIcon />
    </ToolbarButton>
  );
};

export default Underline;
