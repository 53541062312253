import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { Caption, Heading } from '../../../core';
import ConfigPanel from '../../../core/ConfigPanel/ConfigPanel';
import { useCurrencyFormatter } from '../../../CurrencyFormatterContext';
import { useCms } from '../../../hooks/useCms';
import useDataFetcher from '../../../useDataFetcher';

import styles from '../financeApprovals.module.scss';

const CurrentBalances = ({ setIsLoading, setIsError, filters, selectedPayments, shouldReload, onReload }) => {
  const { userOrganization } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();
  const filteredSubOrganizationId = filters?.sub_organization_id;

  const {
    isLoading,
    isError,
    data: organizationBalances,
    reloadData: reloadBalances,
  } = useDataFetcher(`/api/v1/organizations/${userOrganization.id}/balances`);

  useEffect(() => {
    setIsError && setIsError(isError);
  }, [isError, setIsError]);

  useEffect(() => {
    setIsLoading && setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (shouldReload && !isLoading) {
      // noinspection JSIgnoredPromiseFromCall
      reloadBalances();
      onReload();
    }
  }, [shouldReload, isLoading, reloadBalances, onReload]);

  if (_.isEmpty(organizationBalances)) {
    return <div />;
  }

  const getFilteredPayments = (subOrganization) => {
    if (!subOrganization) {
      return selectedPayments;
    }

    return selectedPayments.filter((payment) => payment.sub_organization_id === subOrganization.id);
  };

  const getPaymentsSum = (payments) => {
    return _.sumBy(payments, (p) => p.amount);
  };

  const getSumText = (organizationBalance) => {
    const filteredPayments = getFilteredPayments(organizationBalance.sub_organization);

    if (_.isEmpty(filteredPayments)) {
      return currencyFormatter.format(organizationBalance.pending_submission_sum);
    }

    const selectedSum = getPaymentsSum(filteredPayments);
    return `${currencyFormatter.format(
      organizationBalance.pending_submission_sum + selectedSum
    )} (${currencyFormatter.format(organizationBalance.pending_submission_sum)} + ${currencyFormatter.format(
      selectedSum
    )})`;
  };

  return (
    <Grid container spacing={3} className={styles.balances}>
      {organizationBalances.map((organizationBalance) => (
        <>
          {(!filteredSubOrganizationId ||
            !organizationBalance.sub_organization ||
            organizationBalance.sub_organization.id === filteredSubOrganizationId) && (
            <Grid item xs={12} key={`organizationBalance_${organizationBalance.id}`}>
              {organizationBalance.sub_organization && (
                <Caption variant={Caption.VARIANTS.LABEL}>{organizationBalance.sub_organization.name}</Caption>
              )}
              <Grid container spacing={3}>
                {organizationBalance.balances.map((balance) => (
                  <BalanceCard key={`balance_${balance.balance_id}`} balance={balance} />
                ))}
                <Card
                  header="Sum of pending and selected payments"
                  description={getSumText(organizationBalance)}
                  className={styles.sum}
                />
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

CurrentBalances.propTypes = {
  setIsLoading: PropTypes.func,
  setIsError: PropTypes.func,
  filters: PropTypes.object.isRequired,
  selectedPayments: PropTypes.array,
  shouldReload: PropTypes.bool,
  onReload: PropTypes.func,
};

const BalanceCard = ({ balance }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  return <Card header={balance.balance_desc} description={currencyFormatter.format(balance.amount)} />;
};

BalanceCard.propTypes = {
  balance: PropTypes.object.isRequired,
};

const Card = ({ header = '', description = '', className = '' }) => {
  return (
    <Grid item xs={3}>
      <ConfigPanel
        className={className}
        mainActionChild={
          <div>
            <Caption variant={Caption.VARIANTS.LABEL}>{header}</Caption>
            <Heading variant={Heading.TYPES.H2}>{description}</Heading>
          </div>
        }
      />
    </Grid>
  );
};

Card.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
};

export default CurrentBalances;
