import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Typography from '~/components/core/Atomic/Typography';

import { COUNTRIES_DICT } from '../../../../Types';
import { getAllSearchableContactRoles } from '../../../communications/ContactUtils';
import ContactTextFieldFormik from '../../../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';
import useOrganization from '../../../OrganizationContext';

function MailToFormikInner(props) {
  const { showOnly } = props;
  const { values } = useFormikContext();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <>
      <ContactTextFieldFormik
        id="mail_to_contact"
        label="Mail To"
        acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
        showOnly={showOnly}
        fixedSearchResults
      />
      {showOnly && <ErrorHelperTextFormik id="mail_to_contact" />}
      {values['mail_to_contact'] && (
        <>
          <Typography style={{ marginTop: '8px' }} variant="caption" display="block">
            {values['mail_to_contact'].street_address1 && <div>{values['mail_to_contact'].street_address1}</div>}
            {values['mail_to_contact'].street_address2 && <div>{values['mail_to_contact'].street_address2}</div>}
            <div>
              {values['mail_to_contact'].city}
              {values['mail_to_contact'].state ? `, ${values['mail_to_contact'].state}` : ''}
              {values['mail_to_contact'].zipcode ? `, ${values['mail_to_contact'].zipcode}` : ''}
            </div>
            <div>{COUNTRIES_DICT[values['mail_to_contact'].country]}</div>
          </Typography>
        </>
      )}
    </>
  );
}
MailToFormikInner.propTypes = {
  showOnly: PropTypes.bool,
};

export default MailToFormikInner;
