import React from 'react';

import SvgIcon from '../core/SvgIcon';

const RemoveIcon = (props) => (
  <SvgIcon size="16" viewBox="0 0 14 15" stroke="none" fillOpacity="0.23" {...props}>
    <path d="M6.99992 0.833008C3.31325 0.833008 0.333252 3.81301 0.333252 7.49967C0.333252 11.1863 3.31325 14.1663 6.99992 14.1663C10.6866 14.1663 13.6666 11.1863 13.6666 7.49967C13.6666 3.81301 10.6866 0.833008 6.99992 0.833008ZM10.3333 9.89301L9.39325 10.833L6.99992 8.43967L4.60659 10.833L3.66659 9.89301L6.05992 7.49967L3.66659 5.10634L4.60659 4.16634L6.99992 6.55967L9.39325 4.16634L10.3333 5.10634L7.93992 7.49967L10.3333 9.89301Z" />
  </SvgIcon>
);

export default RemoveIcon;
