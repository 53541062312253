import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { get } from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import { cleanEmptyValues } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import {
  getPredefinedFieldsEmptyFormikInitialValues,
  PreDefinedField,
  preparePreDefinedFields,
} from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';

const PROPERTY_ITEMS_CONFIG_PATH = 'involved_parties.involved_generic_property.property_items';

const getInitialPropertyItemsPredefinedFields = (
  incidentConfiguration,
  propertyItemsConfigPath = PROPERTY_ITEMS_CONFIG_PATH
) => preparePreDefinedFields(get(incidentConfiguration, propertyItemsConfigPath, {}));
const getPropertyItemsInitialValues = (incidentConfiguration, propertyItemsConfigPath) =>
  getPredefinedFieldsEmptyFormikInitialValues(
    getInitialPropertyItemsPredefinedFields(incidentConfiguration, propertyItemsConfigPath)
  );

const getInvolvedPropertyDefinedFields = (
  incidentConfiguration,
  propertyItemsConfigPath = PROPERTY_ITEMS_CONFIG_PATH
) => {
  const preDefinedFields = {
    ...get(incidentConfiguration, propertyItemsConfigPath),
  };

  return preparePreDefinedFields(preDefinedFields);
};

const getInvolvedPropertyValidationFields = (incidentConfiguration, propertyItemsConfigPath) => ({
  ...getAdditionalDataValidations(getInvolvedPropertyDefinedFields(incidentConfiguration, propertyItemsConfigPath)),
});

const InvolvedPropertyItemsFragment = ({ propertyItemsConfigPath = PROPERTY_ITEMS_CONFIG_PATH }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInvolvedPropertyDefinedFields(incidentConfiguration, propertyItemsConfigPath);

  return (
    <Grid container spacing={1}>
      <PreDefinedField id="description" fields={preDefinedFields} inline />
      <PreDefinedField id="category" fields={preDefinedFields} inline />
      <PreDefinedField id="quantity" fields={preDefinedFields} inline decimalScale={0} />
      <PreDefinedField id="purchase_date" fields={preDefinedFields} inline />
      <PreDefinedField id="purchase_price" fields={preDefinedFields} inline />
      <PreDefinedField id="claimed_amount" fields={preDefinedFields} inline />
      <PreDefinedField id="scheduled" fields={preDefinedFields} inline />
    </Grid>
  );
};

InvolvedPropertyItemsFragment.propTypes = {
  propertyItemsConfigPath: PropTypes.string,
};

const EditInvolvedPropertyItems = ({ onSaveItemDetails, onEditCancel, item, propertyItemsConfigPath }) => {
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <Formik
      initialValues={{
        description: item.description,
        category: item.category,
        quantity: item.quantity,
        purchase_date: item.purchase_date,
        purchase_price: item.purchase_price,
        claimed_amount: item.claimed_amount,
        scheduled: item.scheduled,
      }}
      validationSchema={Yup.object().shape({
        ...getInvolvedPropertyValidationFields(incidentConfiguration, propertyItemsConfigPath),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSaveItemDetails(cleanEmptyValues(values));
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <>
            <CardDialog
              isDialog={true}
              title="Add Item"
              outlinedCard
              footerActions={
                <DialogFooterActions
                  disabled={isSubmitting}
                  onClickPrimary={handleSubmit}
                  onClickSecondary={onEditCancel}
                />
              }
            >
              <InvolvedPropertyItemsFragment propertyItemsConfigPath={propertyItemsConfigPath} />
            </CardDialog>
          </>
        );
      }}
    </Formik>
  );
};

EditInvolvedPropertyItems.propTypes = {
  onSaveItemDetails: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  propertyItemsConfigPath: PropTypes.string,
};

const AddInvolvedPropertyItems = ({ onAddItem, onCancel, propertyItemsConfigPath }) => {
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <Formik
      initialValues={getPropertyItemsInitialValues(incidentConfiguration, propertyItemsConfigPath)}
      validationSchema={Yup.object().shape({
        ...getInvolvedPropertyValidationFields(incidentConfiguration, propertyItemsConfigPath),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        try {
          await onAddItem(cleanEmptyValues(values));
          resetForm();
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <>
            <CardDialog
              isDialog={true}
              title="Add Item"
              outlinedCard
              footerActions={
                <DialogFooterActions
                  disabled={isSubmitting}
                  onClickPrimary={handleSubmit}
                  onClickSecondary={onCancel}
                />
              }
            >
              <InvolvedPropertyItemsFragment propertyItemsConfigPath={propertyItemsConfigPath} />
            </CardDialog>
          </>
        );
      }}
    </Formik>
  );
};

AddInvolvedPropertyItems.propTypes = {
  onAddItem: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  propertyItemsConfigPath: PropTypes.string,
};

export {
  AddInvolvedPropertyItems,
  EditInvolvedPropertyItems,
  getInvolvedPropertyDefinedFields,
  InvolvedPropertyItemsFragment,
};
