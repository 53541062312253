import React from 'react';

import { Text } from '~/components/core';
import { finalizeProjectName } from '~/components/ReportsPage/helpers/ReportsUtils';

interface ProjectContainerProps {
  name: string;
}
export const ProjectContainer: React.FC<ProjectContainerProps> = ({ children, name }) => {
  const hasWorkbooks = React.Children.count(children) > 0;
  return (
    <section className="--PROJECT-- flex flex-col gap-20 bg-slate-200 pb-20 pl-40 pt-20">
      <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD} className="capitalize">
        {finalizeProjectName(name)}
      </Text>
      {!hasWorkbooks && (
        <Text variant={Text.VARIANTS.SM} className="h-[100px]">
          No dashboards and reports created yet.
        </Text>
      )}
      {hasWorkbooks && <div className="--WORKBOOKS-CONTAINER-- flex flex-wrap gap-20 bg-slate-200">{children}</div>}
    </section>
  );
};
