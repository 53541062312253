import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import useCip from '~/components/hooks/useCip';
import useItrak from '~/components/hooks/useItrak';

import { getBasicFnolValues, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useContacts } from '../ContactsContext';
import FnolDocumentsFormik from '../Fnol/FnolDocumentsFormik';
import { trackOldFnolClick } from '../Fnol/FnolScreen';
import { involvedPersonFields, InvolvedPersonFragment, involvedPersonValidationFields } from '../InvolvedPerson';
import LoadingDialog from '../LoadingDialog';
import useOrganization from '../OrganizationContext';
import { usePolicy } from '../PolicyContainer';
import { WitnessesSummary } from '../Witness';

import {
  GLIncidentDetailsCardFormik,
  glIncidentDetailsFields,
  glIncidentDetailsValidationFields,
} from './GLIncidentDetails';

import { useStyles } from '../../assets/styles';

function GLFnol(props) {
  const { organization, searchValues, onSubmit } = props;
  const { contacts } = useContacts();
  const { policy } = usePolicy();
  const { incidentTypesDict, glSourceCodesDict, glCauseCodesDict } = useOrganization();
  const {
    ItrakComponent,
    isItrakAutofillEnabled,
    itrakContactsList: itrakContactsList,
    ItrakInvolvedPersonSelect,
    ItrakUploadFiles,
  } = useItrak({ claimType: useItrak.CLAIM_TYPE.gl_claim });
  const {
    CipComponent,
    isCipAutofillEnabled,
    cipContactsList: cipContactsList,
    CipInvolvedPersonSelect,
    CipUploadFiles,
  } = useCip({ claimType: useCip.CLAIM_TYPE.gl_claim });
  const glIncidentTypesDict = incidentTypesDict.gl_claim;
  const involvedPersonSelect = isItrakAutofillEnabled ? ItrakInvolvedPersonSelect : CipInvolvedPersonSelect;

  const classes = useStyles();

  const submitGLFnol = async (values) => {
    try {
      const res = await axios.post('/api/v1/gl_claims', {
        ...values,
        fnol_contacts_ids: isItrakAutofillEnabled
          ? itrakContactsList.map((contact) => contact.id)
          : isCipAutofillEnabled
          ? cipContactsList.map((contact) => contact.id)
          : contacts.map((contact) => contact.id),
      });
      onSubmit(res.data.id);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const mapPropsToFormikValues = () => {
    return {
      ...getBasicFnolValues(),
      ...glIncidentDetailsFields,
      ...policy,
      policy_id: policy.id,
      is_manual_policy: !!policy.is_manual,

      was_there_physical_evidence: '',
      physical_evidence_location: '',
      date_of_loss: searchValues.incident_date,
      property: searchValues.property_name,
      involved_claimant: {
        ...involvedPersonFields,
        involved_person_filled: true, // if no integration, don't wait for "employee filling", do manually
      },
      organization_id: organization.id,
      claim_subtype: '',
      witnesses: [],
      stored_files_ids: [],
    };
  };

  return (
    <Formik
      initialValues={mapPropsToFormikValues()}
      validationSchema={Yup.object().shape({
        ...glIncidentDetailsValidationFields(glIncidentTypesDict, glSourceCodesDict, glCauseCodesDict),
        involved_claimant: Yup.object().shape({
          ...involvedPersonValidationFields,
          involved_person_filled: Yup.boolean().oneOf([true], 'Must select one claimant'),
        }),
        claim_subtype: Yup.string().required('Required').oneOf(Object.keys(glIncidentTypesDict)),
        stored_files_ids: Yup.array().of(Yup.number()),
      })}
      onSubmit={async (values, formikBag) => {
        const { setSubmitting } = formikBag;
        await submitGLFnol(values, setSubmitting);
        setSubmitting(false);
        trackOldFnolClick(policy, 'old_gl_fnol');
      }}
    >
      {(formikProps) => {
        const { setFieldValue, values, isSubmitting, handleSubmit } = formikProps;
        return (
          <>
            <Grid container>
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <GLIncidentDetailsCardFormik
                    ItrakComponent={ItrakComponent}
                    CipComponent={isCipAutofillEnabled && !isItrakAutofillEnabled ? CipComponent : null}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <CardDialog title="Claimant Details" outlinedCard>
                    <InvolvedPersonFragment
                      contactSearchProps={{ newContactRole: 'claimant' }}
                      contactAcceptedRoles={['claimant']}
                      personLabel="Claimant"
                      parentId="involved_claimant"
                      ContactFieldOverride={involvedPersonSelect}
                    />
                  </CardDialog>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <WitnessesSummary
                    classes={classes}
                    witnesses={values['witnesses']}
                    onAddWitness={(witness) =>
                      setFieldValue('witnesses', [...values['witnesses'], { ...witness, id: uuidv4() }])
                    }
                    onEditWitness={(witness) =>
                      setFieldValue(
                        'witnesses',
                        values['witnesses'].map((w) => (w.id === witness.id ? witness : w))
                      )
                    }
                    onDeleteWitness={(id) =>
                      setFieldValue(
                        'witnesses',
                        values['witnesses'].filter((w) => w.id !== id)
                      )
                    }
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.cardDivRow}>
                  <ItrakUploadFiles />
                  <CipUploadFiles />
                  <FnolDocumentsFormik organization={organization} />
                </div>
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <div className={classes.cardDivRow}>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isSubmitting}>
                  Create FNOL
                </Button>
                {isSubmitting && <LoadingDialog isError={false} track="Submit GL fnol" />}
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

GLFnol.propTypes = {
  organization: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  searchValues: PropTypes.object.isRequired,
  claimValues: PropTypes.object,
};

export default GLFnol;
