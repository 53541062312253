import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, List, ListItem, ListItemText } from '@material-ui/core';
import { CloudUpload as CloudUploadIcon, Search as SearchIcon } from '@material-ui/icons';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';

import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { openTopLevelDialog } from '../CmsMain/globals';
import { ConfirmModal } from '../ConfirmModal';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '../core';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { useCms } from '../hooks/useCms';
import LoadingDialog from '../LoadingDialog';
import { TextFieldFormik } from '../TextFieldFormik';

import { useHasPermission, useSanitizeByPermissions } from './useHasPermission';

import { useStyles } from '../../assets/styles';

export const CLAIM_TYPE = {
  wc_claim: 'wc_claim',
  gl_claim: 'gl_claim',
};

export const handleAutoFillCipValues = async ({
  user,
  values,
  setFieldValue,
  setFieldTouched,
  setCipContactsList,
  setLoading,
  involvedPersonId,
  claimType,
  setInitialValuesFilled,
}) => {
  try {
    if (!values['cip_number']) return;

    setLoading(true);

    const { data } = await axios.get(`/api/v1/organizations/${user.organization_id}/cip_autofill`, {
      params: { cip_number: values['cip_number'], claim_type: claimType },
    });
    Object.entries(data).forEach(([key, value]) => {
      setFieldValue(key, value);
      setFieldTouched(key, true);

      if (key === 'involved_person') {
        setCipContactsList(value);
      }

      ['primary_contact', 'reporter_contact'].forEach((key) => {
        if (isEmpty(values[key]) && !isEmpty(data[key])) {
          setFieldValue(`${key}_id`, data[key]?.id || '');
          setFieldValue(`${key}_full_name`, data[key]?.full_name || '');
          setFieldValue(key, data[key]);
        }
      });

      if (key === 'supervisor_contact') {
        if (isEmpty(values[key]) && !isEmpty(data[key])) {
          setFieldValue(`${involvedPersonId}.${key}_id`, data[key]?.id || '');
          setFieldValue(`${involvedPersonId}.${key}_full_name`, data[key]?.full_name || '');
          setFieldValue(`${involvedPersonId}.${key}`, data[key]);
        }
      }
    });

    if (!isEmpty(data?.error)) {
      openTopLevelDialog({ title: 'Error', message: data.error });
    }

    setInitialValuesFilled(true);
  } catch (error) {
    reportAxiosError(error);
  }

  setLoading(false);
};

export const handleUploadCipMediaFiles = async ({
  user,
  values,
  setFieldValue,
  setFieldTouched,
  setLoading,
  setUploadedPhotos,
}) => {
  try {
    if (!values['cip_number']) return;

    setLoading(true);

    const { data } = await axios.post(`/api/v1/organizations/${user.organization_id}/cip_autofill/upload_cip_media`, {
      params: { cip_number: values['cip_number'], files_info_list: values['files_info_list'] },
    });
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'stored_files') {
        setFieldTouched(key, true);
        setFieldValue('stored_files_ids', [...values['stored_files_ids'], ...value.map((photo) => photo.id)]);
        setUploadedPhotos(value);
      }
    });

    if (!isEmpty(data?.error)) {
      openTopLevelDialog({ title: 'Error', message: data.error });
    }
  } catch (error) {
    reportAxiosError(error);
  }

  setLoading(false);
};

const handleInvolvedPersonSelect = async ({
  value,
  cipContactsList,
  setFieldValue,
  setFieldTouched,
  values,
  involvedPersonId,
  hasPiiPermission,
  sanitizePayload,
}) => {
  const involvedPerson = cipContactsList.find((item) => item.id === value);
  Object.keys(involvedPerson).forEach((key) => {
    if (involvedPerson[key]) {
      setFieldValue(`${involvedPersonId}.${key}`, involvedPerson[key]);
      setFieldTouched(`${involvedPersonId}.${key}`, true);
    }
  });

  setFieldValue('date_hired', involvedPerson.date_hired);
  setFieldValue('termination_date', involvedPerson.termination_date);
  setFieldValue(`${involvedPersonId}.contact`, involvedPerson.employee_contact);
  setFieldValue(`${involvedPersonId}.select_involved_person`, involvedPerson.employee_contact);

  ['primary_contact', 'reporter_contact'].forEach((key) => {
    if (isEmpty(values[key])) {
      setFieldValue(`${key}_id`, involvedPerson ? involvedPerson.id : '');
      setFieldValue(`${key}_full_name`, involvedPerson?.full_name);
      setFieldValue(key, involvedPerson);
    }
  });

  if (involvedPerson.role !== involvedPersonId) {
    try {
      const { data: contactData } = await axios.get(`/api/v1/contacts/${involvedPerson.id}`);
      const role = involvedPersonId === 'involved_employee' ? 'injured_employee' : 'claimant';
      contactData.role = role;
      contactData.reason = 'typo';
      contactData.explanation = '';

      const fullIfPii = hasPiiPermission ? '/full' : '';
      const payload = sanitizePayload(contactData);

      await axios.patch(`/api/v1/contacts/${involvedPerson.id}/contact_identity${fullIfPii}`, payload);
    } catch (error) {
      reportAxiosError(error);
    }
  }
};

const CipNumber = () => {
  const classes = useStyles();

  return <TextFieldFormik id="cip_number" label="CIP number" className={classes.textField} fullWidth />;
};

const CipSearch = ({ setCipContactsList, involvedPersonId, claimType, setInitialValuesFilled }) => {
  const classes = useStyles();
  const { user } = useCms();
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const [isLoading, setLoading] = useState(false);
  const handleAutoFill = () =>
    handleAutoFillCipValues({
      user,
      values,
      setFieldValue,
      setFieldTouched,
      setCipContactsList,
      setLoading,
      involvedPersonId,
      claimType,
      setInitialValuesFilled,
    });

  return (
    <>
      {isLoading && <LoadingDialog isError={false} track="CIP Loading" text="Loading CIP Details" />}
      <TextFieldFormik
        id="cip_number"
        label="CIP number"
        className={classes.textField}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton style={{ padding: 4 }} onClick={handleAutoFill}>
                <Tooltip title="Autofill CIP Details">
                  <SearchIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

CipSearch.propTypes = {
  setCipContactsList: PropTypes.func,
  involvedPersonId: PropTypes.string.isRequired,
  setUploadedPhotos: PropTypes.func,
  setInitialValuesFilled: PropTypes.func,
  claimType: PropTypes.string.isRequired,
};

const InvolvedPersonSelect = ({ cipContactsList, involvedPersonId }) => {
  const classes = useStyles();
  const { handleChange, setFieldValue, setFieldTouched, values } = useFormikContext();
  const hasPiiPermission = useHasPermission({ action: PERMISSION_ACTIONS.CONTACT_PII, verb: PERMISSION_VERBS.WRITE });
  const sanitizePayload = useSanitizeByPermissions({
    fieldsPermissionActions: {
      date_of_birth: PERMISSION_ACTIONS.CONTACT_PII,
      government_id: PERMISSION_ACTIONS.CONTACT_PII,
    },
    verb: PERMISSION_VERBS.WRITE,
    forbiddenFields: ['tin'],
  });

  const handleInvolvedPersonSelectChange = (value) =>
    handleInvolvedPersonSelect({
      value,
      cipContactsList,
      setFieldValue,
      setFieldTouched,
      values,
      involvedPersonId,
      hasPiiPermission,
      sanitizePayload,
    });
  const involvedPersonLabel = involvedPersonId === 'involved_employee' ? 'Employee' : 'Claimant';

  useEffect(() => {
    setFieldValue(`${involvedPersonId}.involved_person_filled`, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TextFieldFormik
        id="select_involved_person"
        label={`Select ${involvedPersonLabel}`}
        className={classes.textField}
        fullWidth
        select
        onChange={async (event) => {
          await handleInvolvedPersonSelectChange(event.target.value);
          handleChange(event);
          setFieldValue(`${involvedPersonId}.involved_person_filled`, true);
        }}
      >
        {cipContactsList.map(({ id, label }) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </TextFieldFormik>
      <ErrorHelperTextFormik id={`${involvedPersonId}.involved_person_filled`} />
    </>
  );
};

InvolvedPersonSelect.propTypes = {
  cipContactsList: PropTypes.array.isRequired,
  involvedPersonId: PropTypes.string.isRequired,
};

const CipUploadMediaFiles = () => {
  const classes = useStyles();
  const { user } = useCms();
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); // not using WithConfirm due to issue with rendering children dynamically
  const [isLoading, setLoading] = useState(false);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const filesNames = !isEmpty(values['files_info_list'])
    ? values['files_info_list'][0].map(({ file_name }) => file_name)?.join(', ')
    : '';

  const handleUploadFiles = async () => {
    setIsConfirmOpen(false);
    await handleUploadCipMediaFiles({ user, setFieldValue, setFieldTouched, values, setLoading, setUploadedPhotos });
  };

  return (
    <CardDialog title="Upload CIP Media Files">
      {isLoading && <LoadingDialog isError={false} track="CIP Media Loading" text="Uploading CIP Media Files" />}
      <ConfirmModal
        isOpen={isConfirmOpen}
        title="CIP media files?"
        contentText={
          <>
            This operation will upload the following media files:
            <br />
            {filesNames}
            <br />
            This operation might take several minutes to complete.
          </>
        }
        primaryButtonName="Upload"
        onPrimaryBtnClick={handleUploadFiles}
        onClose={() => setIsConfirmOpen(false)}
      />
      {isEmpty(uploadedPhotos) && (
        <Button color="primary" onClick={() => setIsConfirmOpen(true)}>
          <CloudUploadIcon className={classes.leftButtonIcon} />
          Upload CIP Files
        </Button>
      )}
      {uploadedPhotos.length > 0 && (
        <List dense>
          {uploadedPhotos.map((photo) => (
            <ListItem key={photo.id}>
              <ListItemText primary={photo.stored_file_extra?.document_name || photo.original_filename} />
            </ListItem>
          ))}
        </List>
      )}
    </CardDialog>
  );
};

const useCip = ({ claimType }) => {
  const { userOrganization } = useCms();
  const [initialValuesFilled, setInitialValuesFilled] = useState(false);
  const [cipContactsList, setCipContactsList] = useState([]);
  const isCipAutofillEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FF_CIP_AUTOFILL);
  const involvedPersonId =
    claimType === CLAIM_TYPE.wc_claim
      ? 'involved_employee'
      : claimType === CLAIM_TYPE.gl_claim
      ? 'involved_claimant'
      : undefined;
  const CipComponent = () =>
    isCipAutofillEnabled ? (
      <CipSearch
        involvedPersonId={involvedPersonId}
        setCipContactsList={setCipContactsList}
        claimType={claimType}
        setInitialValuesFilled={setInitialValuesFilled}
      />
    ) : (
      <CipNumber />
    );
  const CipInvolvedPersonSelect =
    isCipAutofillEnabled && !isEmpty(cipContactsList)
      ? () => <InvolvedPersonSelect cipContactsList={cipContactsList} involvedPersonId={involvedPersonId} />
      : undefined;
  const CipUploadFiles = () => (isCipAutofillEnabled && initialValuesFilled ? <CipUploadMediaFiles /> : null);

  return {
    CipComponent,
    isCipAutofillEnabled,
    cipContactsList,
    CipInvolvedPersonSelect,
    CipUploadFiles,
  };
};

useCip.CLAIM_TYPE = CLAIM_TYPE;

export default useCip;
