import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CardLink from '~/components/core/CardLink';
import { ConfigurationValuePropertyDetails } from '~/components/LegalActions/components/ConfigurationValuePropertyDetails';
import { LEGAL_ACTION_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';

import { Heading } from '../../core';
import { PropertyDetailsRow } from '../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import OverflowTextWithToolTip from '../../OverflowTextWithToolTip';
import { ApplyToPropertyDetails } from '../components/ApplyToPropertyDetails';
import { PriorityPropertyDetails } from '../components/PriorityPropertyDetails';
import type { LegalActionApiResponse } from '../types';

export const LegalActionLinkCard: React.FC<{ legalAction: LegalActionApiResponse }> = ({ legalAction }) => {
  const match = useRouteMatch();
  const cardTitle = `${legalAction.display_name}${!legalAction.is_open ? ' (Closed)' : ''}`;

  return (
    <CardLink
      title={<OverflowTextWithToolTip>{cardTitle}</OverflowTextWithToolTip>}
      titleProps={legalAction.is_open ? undefined : { colorVariant: Heading.COLOR_VARIANTS.DISABLED }}
      to={`${match.url}/${legalAction.id}`}
    >
      <div className="flex flex-col gap-24 pt-32">
        <div className="flex w-full items-center justify-between">
          <ApplyToPropertyDetails legalAction={legalAction} />
        </div>
        <div className="flex w-full items-center justify-between">
          <PropertyDetailsRow>
            <PriorityPropertyDetails priority={legalAction.priority} />
            <ConfigurationValuePropertyDetails
              value={legalAction.nature_of_dispute}
              optionsKey="natureOfDisputeOptions"
              title={LEGAL_ACTION_FIELDS.natureOfDispute.label}
            />
          </PropertyDetailsRow>
        </div>
      </div>
    </CardLink>
  );
};
