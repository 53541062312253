import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import querystring from 'query-string';

import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';

import { CONFIGURATION_FEATURES_NAMES } from '../Types';
import { isFeatureEnabled, trackAction } from '../Utils';

import mixpanel from './CmsMain/mixpanel';
import TooltipIcon from './core/TooltipIcon';
import { useCms } from './hooks/useCms';
import { useHasPermission } from './hooks/useHasPermission';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from './core';
import InlineIconButton from './InlineIconButton';

const ClaimLink = ({ claimId, linkText, openInNewTab, extraLinkArgs, trackingInfo, onClick, disabled }) => {
  const { userOrganization } = useCms();
  const isNewClaimLinkComponentEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.PERFORMANCE_SPLIT_CLAIM_LINK_AND_OPEN_IN_NEW_TAB
  );

  const location = useLocation();
  const userHasClaimPermissions = useHasPermission({ action: PERMISSION_ACTIONS.CLAIM, verb: PERMISSION_VERBS.READ });

  let linkUrl = userHasClaimPermissions && !disabled ? `/claims/${claimId}` : null;

  if (extraLinkArgs && linkUrl) {
    linkUrl += `?${querystring.stringify(extraLinkArgs)}`;
  }

  const handleClickOpenInNewTabIcon = () => {
    mixpanel.track(MIXPANEL_EVENTS.OPEN_CLAIM_IN_NEW_TAB_CLICKED, {
      link_url: linkUrl,
      prev_location: location.pathname,
    });
    window.open(linkUrl, '_blank');
  };

  const handleClick = (e) => {
    mixpanel.track(MIXPANEL_EVENTS.OPEN_CLAIM_LINK_CLICKED, { link_url: linkUrl, prev_location: location.pathname });
    if (trackingInfo) {
      trackAction(location, trackingInfo);
    }
    e.stopPropagation();
  };

  const textStyle = {
    whiteSpace: 'nowrap',
    letterSpacing: '-0.3px',
    opacity: 0.7,
    color: '#000000',
  };

  if (!linkUrl) {
    return <span style={textStyle}>{linkText}</span>;
  }

  if (isNewClaimLinkComponentEnabled) {
    return (
      <div className="flex items-center">
        <Link
          style={textStyle}
          onMouseDown={handleClick} // use onMouseDown and not onClick to catch pressing the link with the mouse's middle button to open in new tab.
          onClick={onClick}
          to={linkUrl}
          className="pr-4"
        >
          {linkText}
        </Link>
        <div className="pt-4">
          <TooltipIcon title="Open in a new tab" arrow>
            <InlineIconButton defaultColor="action" icon={OpenInNewIcon} onClick={handleClickOpenInNewTabIcon} />
          </TooltipIcon>
        </div>
      </div>
    );
  }

  const target = openInNewTab ? '_blank' : undefined;
  const rel = openInNewTab ? 'noopener noreferrer' : undefined;

  return (
    <Link
      style={textStyle}
      onMouseDown={handleClick} // use onMouseDown and not onClick to catch pressing the link with the mouse's middle button to open in new tab.
      onClick={onClick}
      to={linkUrl}
      target={target}
      rel={rel}
    >
      {linkText}
    </Link>
  );
};

ClaimLink.propTypes = {
  claimId: PropTypes.number.isRequired,
  linkText: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
  extraLinkArgs: PropTypes.object,
  trackingInfo: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ClaimLink;
