import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const EmailNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 15.25C12.25 14.8358 12.5858 14.5 13 14.5H31C31.4142 14.5 31.75 14.8358 31.75 15.25V28C31.75 28.3978 31.592 28.7794 31.3107 29.0607C31.0294 29.342 30.6478 29.5 30.25 29.5H13.75C13.3522 29.5 12.9706 29.342 12.6893 29.0607C12.408 28.7794 12.25 28.3978 12.25 28V15.25ZM13.75 16V28H30.25V16H13.75Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4471 14.7432C12.727 14.4379 13.2015 14.4172 13.5068 14.6971L22 22.4826L30.4932 14.6971C30.7985 14.4172 31.273 14.4379 31.5529 14.7432C31.8328 15.0486 31.8121 15.523 31.5068 15.8029L22.5068 24.0529C22.2201 24.3157 21.78 24.3157 21.4932 24.0529L12.4932 15.8029C12.1879 15.523 12.1672 15.0486 12.4471 14.7432Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default EmailNoteIcon;
