import React, { useState } from 'react';
import axios from 'axios';

import { reportAxiosError } from '../Utils';
import { isClaimWriteDisabled } from '../Utils/ClaimUtils';

import { useCms } from './hooks/useCms';
import AssignHandlingAdjusterPopover from './AssignHandlingAdjusterPopover';
import { useClaim } from './ClaimContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from './core';
import HoverActionField from './HoverActionField';

function ClaimOwnerWithEdit() {
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user } = useCms();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(undefined);

  const handleNewAssign = async (values) => {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/assign_new_adjuster`, values);
      await onAsyncClaimUpdate();

      setPopoverAnchorEl(undefined);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <RestrictedPermissions action={PERMISSION_ACTIONS.CHANGE_CLAIM_OWNER} verb={PERMISSION_VERBS.WRITE}>
      <HoverActionField
        onAction={(e) => setPopoverAnchorEl(e.currentTarget)}
        disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
      >
        <span style={{ whiteSpace: 'nowrap' }}>{claim.handling_adjuster}</span>
      </HoverActionField>
      <AssignHandlingAdjusterPopover
        anchorEl={popoverAnchorEl}
        currentAdjusterId={claim.handling_adjuster_id}
        onAssignAdjuster={handleNewAssign}
        onClose={() => setPopoverAnchorEl(undefined)}
        title="Assign File Owner"
        isClaimAssign
      />
    </RestrictedPermissions>
  );
}

export default ClaimOwnerWithEdit;
