import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { PAYMENT_METHODS } from '../../../../../Types';
import CardDialog from '../../../../CardDialog';
import { ShowOnlyTextField } from '../../../../TextFieldFormik';

import { useStyles } from '../../../../../assets/styles';

const PaymentDetailsDialog = ({ paymentRequest, onClose }) => {
  const classes = useStyles();

  return (
    <CardDialog
      isDialog
      onClose={onClose}
      title={`${PAYMENT_METHODS[paymentRequest.payment_method]} Payment Details`}
      maxWidth="xs"
      fullWidth
    >
      <Grid container spacing={2}>
        {paymentRequest?.external_payment_id ? (
          <Grid item xs={12}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={paymentRequest.external_payment_id}
              label="External Id:"
            />
          </Grid>
        ) : null}
        {paymentRequest.financial_request_extra?.custom_fields?.map((field) => (
          <Grid item xs={6} key={field.id}>
            <ShowOnlyTextField classes={classes} showOnlyValueComponent={field.value} label={field.display_name} />
          </Grid>
        ))}
      </Grid>
    </CardDialog>
  );
};

PaymentDetailsDialog.propTypes = {
  paymentRequest: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PaymentDetailsDialog;
