import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const LoadingIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M0 16v-2h2.8l-.4-.3A6.3 6.3 0 0 1 .5 11C.2 10 0 9 0 8c0-1.8.6-3.4 1.7-4.9A7.8 7.8 0 0 1 6 .2v2.1a6 6 0 0 0-2.9 2.3 6 6 0 0 0 .6 7.6l.3.3V10h2v6H0Zm10-.3v-2a6 6 0 0 0 2.9-2.3 6 6 0 0 0-.7-7.6l-.2-.3V6h-2V0h6v2h-2.8l.5.4A7.8 7.8 0 0 1 16 7.8c0 1.9-.6 3.5-1.7 5a7.8 7.8 0 0 1-4.3 2.8Z"
      fill={iconColor}
      stroke="none"
    />
  </SvgIcon>
);

LoadingIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default LoadingIcon;
