import React, { useState } from 'react';
import { Menu } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FsButton } from '~/components/core';
import MenuItem from '~/components/core/Atomic/MenuItem';

interface Action {
  id: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface ActionsButtonProps {
  actions: Action[];
  disabled?: boolean;
}

export const ActionsButton: React.FC<ActionsButtonProps> = ({ actions, disabled = false }) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  return (
    <>
      <FsButton
        className="bg-teal-600 text-white hover:bg-teal-700"
        variant="contained"
        color="default"
        onClick={(e) => setActionsAnchorEl(e.currentTarget)}
        endIcon={<ExpandMoreIcon />}
        disabled={disabled}
      >
        Actions
      </FsButton>
      <Menu
        anchorEl={actionsAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuListProps={{ className: 'w-[110px]' }}
        getContentAnchorEl={null}
        open={!!actionsAnchorEl}
        onClose={() => setActionsAnchorEl(null)}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.id}
            onClick={() => {
              action.onClick();
              setActionsAnchorEl(null);
            }}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
