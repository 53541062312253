import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../../core/SvgIcon';

const VideoCallNoteIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <rect width="44" height="44" rx="10" fill="#EAEFF2" />
    <g clipPath="url(#clip0_5864_4655)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 15.25C12.1716 15.25 11.5 15.9216 11.5 16.75V27.25C11.5 28.0784 12.1716 28.75 13 28.75H28C28.8284 28.75 29.5 28.0784 29.5 27.25V24.9014L32.834 27.124C33.0641 27.2775 33.36 27.2918 33.6039 27.1613C33.8478 27.0307 34 26.7766 34 26.5V17.5C34 17.2234 33.8478 16.9693 33.6039 16.8387C33.36 16.7082 33.0641 16.7225 32.834 16.876L29.5 19.0986V16.75C29.5 15.9216 28.8284 15.25 28 15.25H13ZM28 20.4897C27.9999 20.497 27.9999 20.5043 28 20.5116V23.4884C27.9999 23.4957 27.9999 23.503 28 23.5103V27.25H13V16.75H28V20.4897ZM29.5 23.0986V20.9014L32.5 18.9014V25.0986L29.5 23.0986Z"
        fill={iconColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_5864_4655">
        <rect width="24" height="24" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default VideoCallNoteIcon;
