import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useStyles } from '~/assets/styles';
import LoadingIndicator from '~/components/LoadingIndicator';
import { ShowOnlyTextField } from '~/components/TextFieldFormik';

import type { OrganizationUnit } from './types';

interface OrgUnitSelectProps {
  label: string;
  showOnly?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<unknown>, value: OrganizationUnit | null) => void;
  units: OrganizationUnit[];
  isLoading: boolean;
  isError: boolean;
  value: OrganizationUnit | undefined;
  textFieldProps?: Record<string, unknown>;
}

const OrgUnitSelect: React.FC<OrgUnitSelectProps> = ({
  label,
  showOnly,
  disabled,
  onChange,
  units,
  isLoading,
  isError,
  value,
  textFieldProps,
}) => {
  const classes = useStyles();

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  if (showOnly) {
    return <ShowOnlyTextField classes={classes} showOnlyValueComponent={value} label={label} />;
  }

  return (
    <Autocomplete
      options={units}
      getOptionLabel={(unit) => unit.name}
      getOptionSelected={(option, value) => option.id === value?.id}
      loading={isLoading || isError}
      onChange={onChange}
      value={value}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          label={label}
          {...params}
          InputProps={{
            ...params.InputProps,
            margin: 'dense', // to match other TextField components
          }}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default OrgUnitSelect;
