import React from 'react';

import { FsMenuItem } from '~/components/core';
import { OptionsSelectFieldWithOtherText } from '~/components/LegalActions/components/OptionsSelectFieldWithOtherText';
import { ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import { useLegalActionsConfiguration } from '~/components/LegalActions/hooks/useLegalActionsConfiguration';
import TextFieldFormik from '~/components/TextFieldFormik';

export const AlternativeDisputeResolutionForm = () => {
  const { adrTypeOptions, earlyCaseResolutionExploredOptions } = useLegalActionsConfiguration();

  return (
    <>
      <div className="flex w-full gap-32">
        <div className="w-1/2">
          <TextFieldFormik
            id={ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS.earlyCaseResolutionExplored.key}
            label={ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS.earlyCaseResolutionExplored.label}
            select
            fullWidth
          >
            {earlyCaseResolutionExploredOptions.map((option) => (
              <FsMenuItem key={option.key} value={option.key}>
                {option.description}
              </FsMenuItem>
            ))}
          </TextFieldFormik>
        </div>
        <div className="w-1/2">
          <TextFieldFormik
            id={ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS.adrExplored.key}
            label={ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS.adrExplored.label}
            select
            fullWidth
          >
            {adrTypeOptions.map((option) => (
              <FsMenuItem key={option.key} value={option.key}>
                {option.description}
              </FsMenuItem>
            ))}
          </TextFieldFormik>
        </div>
      </div>
      <OptionsSelectFieldWithOtherText
        controllingFieldKey={ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS.adrExplored.key}
        controllingFieldLabel=""
        controllingFieldOptions={adrTypeOptions}
        controlledFieldKey={ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS.alternativeDisputeResolutionText.key}
        controlledFieldLabel={ALTERNATIVE_DISPUTE_RESOLUTION_FIELDS.alternativeDisputeResolutionText.label}
        otherFieldOnly
      />
    </>
  );
};
