import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const BriefcaseIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon fill="none" {...props}>
    <path
      d="M13.5 4.5H2.5C2.22386 4.5 2 4.72386 2 5V13C2 13.2761 2.22386 13.5 2.5 13.5H13.5C13.7761 13.5 14 13.2761 14 13V5C14 4.72386 13.7761 4.5 13.5 4.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path
      d="M10.5 4.5V3.5C10.5 3.23478 10.3946 2.98043 10.2071 2.79289C10.0196 2.60536 9.76522 2.5 9.5 2.5H6.5C6.23478 2.5 5.98043 2.60536 5.79289 2.79289C5.60536 2.98043 5.5 3.23478 5.5 3.5V4.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path
      d="M14 7.8938C12.1764 8.94844 10.1066 9.50255 8 9.50005C5.89303 9.50553 3.82242 8.95121 2 7.8938"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path d="M7.25 7.5H8.75" strokeLinecap="round" strokeLinejoin="round" stroke={iconColor} fill="none" />
  </SvgIcon>
);

BriefcaseIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default BriefcaseIcon;
