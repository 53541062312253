import React from 'react';

import SvgIcon from '../core/SvgIcon';

const TrashIcon_Deprecated = (props) => (
  <SvgIcon {...props} stroke="none">
    <mask id="path-1-inside-1_141_3920" fill="white" stroke="none">
      <path stroke="none" d="M6 6H7V12H6V6ZM9 6H10V12H9V6Z" />
    </mask>
    <path
      stroke="none"
      fill="current"
      d="M6 6V5H5V6H6ZM7 6H8V5H7V6ZM7 12V13H8V12H7ZM6 12H5V13H6V12ZM9 6V5H8V6H9ZM10 6H11V5H10V6ZM10 12V13H11V12H10ZM9 12H8V13H9V12ZM6 7H7V5H6V7ZM6 6V12H8V6H6ZM7 11H6V13H7V11ZM7 12V6H5V12H7ZM9 7H10V5H9V7ZM9 6V12H11V6H9ZM10 11H9V13H10V11ZM10 12V6H8V12H10Z"
      mask="url(#path-1-inside-1_141_3920)"
    />
    <mask id="path-3-inside-2_141_3920" fill="white" stroke="none">
      <path
        stroke="none"
        d="M2 3V4H3V14C3 14.2652 3.10536 14.5196 3.29289 14.7071C3.48043 14.8946 3.73478 15 4 15H12C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14V4H14V3H2ZM4 14V4H12V14H4ZM6 1H10V2H6V1Z"
      />
    </mask>
    <path
      stroke="none"
      fill="current"
      d="M2 3V2H1V3H2ZM2 4H1V5H2V4ZM3 4H4V3H3V4ZM3 14H2H3ZM13 4V3H12V4H13ZM14 4V5H15V4H14ZM14 3H15V2H14V3ZM4 14H3V15H4V14ZM4 4V3H3V4H4ZM12 4H13V3H12V4ZM12 14V15H13V14H12ZM6 1V0H5V1H6ZM10 1H11V0H10V1ZM10 2V3H11V2H10ZM6 2H5V3H6V2ZM1 3V4H3V3H1ZM2 5H3V3H2V5ZM2 4V14H4V4H2ZM2 14C2 14.5304 2.21071 15.0391 2.58579 15.4142L4 14H2ZM2.58579 15.4142C2.96086 15.7893 3.46957 16 4 16V14L2.58579 15.4142ZM4 16H12V14H4V16ZM12 16C12.5304 16 13.0391 15.7893 13.4142 15.4142L12 14V16ZM13.4142 15.4142C13.7893 15.0391 14 14.5304 14 14H12L13.4142 15.4142ZM14 14V4H12V14H14ZM13 5H14V3H13V5ZM15 4V3H13V4H15ZM14 2H2V4H14V2ZM5 14V4H3V14H5ZM4 5H12V3H4V5ZM11 4V14H13V4H11ZM12 13H4V15H12V13ZM6 2H10V0H6V2ZM9 1V2H11V1H9ZM10 1H6V3H10V1ZM7 2V1H5V2H7Z"
      mask="url(#path-3-inside-2_141_3920)"
    />
  </SvgIcon>
);

export default TrashIcon_Deprecated;
