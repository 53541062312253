import React from 'react';
import axios from 'axios';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { getAllClaimRelatedRoles } from '../communications/ContactUtils';
import { InvolvedPersonsListDetails } from '../InvolvedPerson';
import { InvolvedPropertiesListDetails } from '../InvolvedProperty';
import useOrganization from '../OrganizationContext';

import { useStyles } from '../../assets/styles';

function HomeInvolvedCard() {
  const { claim, onClaimUpdate } = useClaim();
  const { organizationContactRolesDict } = useOrganization();

  const classes = useStyles();

  async function callApi(url, method, values) {
    try {
      await axios({
        method,
        url,
        data: values,
      });
      await onClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  return (
    <CardDialog title="Involved Parties">
      <div className={classes.cardDivRowInternal}>
        <CardDialog title="Injured Persons">
          <InvolvedPersonsListDetails
            involvedPersons={claim.injured_persons}
            onSaveInvolvedPersonDetails={async (person) =>
              await callApi(`/api/v1/home_claims/${claim.id}/injured_persons/${person.id}`, 'put', person)
            }
            onAddInvolvedPerson={async (person) =>
              await callApi(`/api/v1/home_claims/${claim.id}/injured_persons`, 'post', person)
            }
            contactAcceptedRoles={getAllClaimRelatedRoles(organizationContactRolesDict).filter(
              (role) => !['witness', 'insured', 'named_driver'].includes(role)
            )}
            contactSearchProps={{ newContactRole: 'claimant' }}
            personLabel="Injured"
            allowDelete={false}
          />
        </CardDialog>
      </div>
      <div className={classes.cardDivRowInternal}>
        <CardDialog title="Damaged Properties">
          <InvolvedPropertiesListDetails
            involvedProperties={claim.damaged_properties}
            onSaveInvolvedPropertyDetails={async (property) =>
              await callApi(`/api/v1/home_claims/${claim.id}/damaged_properties/${property.id}`, 'put', property)
            }
            onAddInvolvedProperty={async (property) =>
              await callApi(`/api/v1/home_claims/${claim.id}/damaged_properties`, 'post', property)
            }
            // onDeleteInvolvedProperty={(_, idx) => setFieldValue('damaged_properties', damagedProperties.filter((_, currIdx) => idx !== currIdx))}
            propertyLabel="Damaged Property"
            allowDelete={false}
          />
        </CardDialog>
      </div>
    </CardDialog>
  );
}

export default HomeInvolvedCard;
