import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import { getPayableWithReserveType } from '~/components/exposures/ExposureUtils';
import { useExposureSubReserves } from '~/components/hooks/useSubReserves';

import { MGM_GL_COMPENSATION_DICT } from '../../../../Types';
import { isQoverClaim, reportErrorInProductionOrThrow } from '../../../../Utils';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../../../TextFieldFormik';

import PaymentAmountFields from './PaymentAmountFields';

import { useStyles } from '../../../../assets/styles';

function IndemnityPaymentAmountInner({ claim, exposure, readOnly, payableWithReserve }) {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const {
    gross_amount: grossAmount,
    depreciation,
    deductible_amount: deductibleAmount,
    deductible_type: policyDeductibleType,
  } = values;
  const payableType = getPayableWithReserveType(exposure, payableWithReserve);

  const { isSubReservesConfigEnabled } = useExposureSubReserves(payableType, exposure);

  const isQover = isQoverClaim(claim);

  useEffect(() => {
    if (!isQover) {
      return;
    }

    if (
      typeof grossAmount === 'number' &&
      typeof depreciation === 'number' &&
      typeof deductibleAmount === 'number' &&
      policyDeductibleType
    ) {
      if (policyDeductibleType === 'standard') {
        setFieldValue('amount', Math.max(grossAmount - depreciation - deductibleAmount, 0));
      } else if (policyDeductibleType === 'english') {
        setFieldValue('amount', grossAmount - depreciation > deductibleAmount ? grossAmount - depreciation : 0);
      } else {
        reportErrorInProductionOrThrow(
          `Unknown Qover deductible Type: ${policyDeductibleType}, claimId ${claim.id} exposureId ${exposure.id}`
        );
        setFieldValue('amount', Math.max(grossAmount - depreciation - deductibleAmount, 0));
      }
    }
  }, [
    setFieldValue,
    grossAmount,
    depreciation,
    deductibleAmount,
    isQover,
    policyDeductibleType,
    claim.id,
    exposure.id,
  ]);

  return (
    <>
      {isQover && (
        <>
          {exposure.policy_deductible_type ? (
            <Grid item xs={12}>
              <Typography display="block">Deductible Type: {exposure.policy_deductible_type}</Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <TextFieldFormik id="deductible_type" label="Deductible Type" className={classes.textFieldRow} select>
                  <MenuItem value="standard">Standard</MenuItem>
                  <MenuItem value="english">English</MenuItem>
                </TextFieldFormik>
              </Grid>
              <Grid item xs={6} />
            </>
          )}
          <Grid item xs={3}>
            <MonetaryValueTextFieldFormik
              id="gross_amount"
              label="Gross Amount"
              className={classes.textField}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={3}>
            <MonetaryValueTextFieldFormik
              id="depreciation"
              label="Depreciation"
              className={classes.textField}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={3}>
            <MonetaryValueTextFieldFormik
              id="deductible_amount"
              label="Deductible"
              className={classes.textField}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={3} />
        </>
      )}
      {values['payment_method'] === 'compensation' && (
        <>
          <Grid item xs={4}>
            <TextFieldFormik
              id="compensation_type"
              label="Compensation Type"
              className={classes.textField}
              fullWidth
              select
            >
              {Object.keys(MGM_GL_COMPENSATION_DICT).map((type) => (
                <MenuItem key={type} value={type}>
                  {MGM_GL_COMPENSATION_DICT[type]['desc']}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={3}>
            <TextFieldFormik
              id="number_of_units"
              label="Number of Units"
              className={classes.textField}
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={8} />
        </>
      )}
      <Grid item xs={isSubReservesConfigEnabled ? 12 : 3}>
        <PaymentAmountFields
          readOnly={readOnly || isQover}
          payableType="indemnity"
          exposure={exposure}
          payableWithReserve={payableWithReserve}
        />
      </Grid>
    </>
  );
}
IndemnityPaymentAmountInner.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  payableWithReserve: PropTypes.object.isRequired,
};

export default IndemnityPaymentAmountInner;
