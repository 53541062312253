import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';

import { reportAxiosError } from '../../../../../../Utils';
import AutocompleteFormik from '../../../../../AutocompleteFormik';

import styles from './ncicCode.module.scss';

const NCIC_CODES_URL = '/api/v1/auto_claims/ncic_codes';
const MIN_CHARS_TO_SEARCH = 2;

const NcicCodeFragment = ({ field }) => {
  return (
    <div className={styles.ncicCodeContainer}>
      <InnerCard
        title="NCIC Code"
        className={styles.ncicCodeContainerInner}
        titleClassName={styles.ncicCodeContainerInnerTitle}
      >
        <Grid item xs={12}>
          <NcicCodeAutocompleteFormik id={field.id} />
        </Grid>
      </InnerCard>
    </div>
  );
};

NcicCodeFragment.propTypes = {
  field: PropTypes.object.isRequired,
};

const NcicCodeAutocompleteFormik = ({ id }) => {
  const { isSubmitting, values } = useFormikContext();

  const [isLoading, setIsLoading] = useState(false);
  const [errorDetected, setErrorDetected] = useState(false);
  const [possibleOptionsDict, setPossibleOptionsDict] = useState();

  useEffect(() => {
    const getNcicCodesAsync = async () => {
      if (values?.vehicle?.make_ncic_code) {
        const { data } = await axios.get(NCIC_CODES_URL, { params: { search_term: values.vehicle.make_ncic_code } });
        setPossibleOptionsDict(data);
      }
    };
    return getNcicCodesAsync();
  }, [values.vehicle.make_ncic_code]);

  const handleInputChange = async (_event, newVal) => {
    if (!newVal || newVal.length < MIN_CHARS_TO_SEARCH) {
      setPossibleOptionsDict({});
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await axios.get('/api/v1/auto_claims/ncic_codes', { params: { search_term: newVal } });
      setPossibleOptionsDict(data);
    } catch (error) {
      if (!errorDetected) {
        reportAxiosError(error);
        setErrorDetected(true);
      }
    }

    setIsLoading(false);
  };

  return (
    <div>
      <AutocompleteFormik
        id={id}
        options={possibleOptionsDict ? Object.keys(possibleOptionsDict) : []}
        getOptionLabel={(key) => possibleOptionsDict?.[key] || key}
        loading={isLoading}
        disabled={isSubmitting}
        onInputChange={debounce(handleInputChange, 300)}
        sortAlphabetic
      />
    </div>
  );
};

NcicCodeAutocompleteFormik.propTypes = {
  id: PropTypes.string.isRequired,
};

export default NcicCodeFragment;
