import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { PencilIcon } from './icons';
import InlineIconButton from './InlineIconButton';

import styles from '../assets/styles';

const HoverActionField = ({
  children,
  classes,
  disabled = false,
  permanent,
  onAction,
  icon,
  iconStyle,
  fullWidth,
  highlight,
  tooltipTitle,
  centerContent,
  style = {},
  ignorePermissions = false,
  actionAriaLabel,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const showEdit = (!disabled && isMouseOver) || permanent;

  return (
    <div
      className={highlight ? classes.showValueEditableField : undefined}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      style={fullWidth ? { ...style, width: '100%' } : style}
    >
      <span
        className={classes.showValueField}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: centerContent ? 'center' : undefined,
          width: '100%',
        }}
      >
        <span style={{ height: '100%', width: fullWidth ? '100%' : undefined }}>{children}</span>
        <span style={{ visibility: showEdit ? 'visible' : 'hidden' }}>
          <InlineIconButton
            className={classes.hoverableNonFilledIcon}
            icon={icon ? icon : PencilIcon}
            onClick={(e) => onAction(e)}
            tooltipTitle={tooltipTitle}
            iconStyle={iconStyle}
            disabled={disabled}
            ignorePermissions={ignorePermissions}
            ariaLabel={actionAriaLabel}
          />
        </span>
      </span>
    </div>
  );
};

HoverActionField.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired, // comes from withStyles
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  iconStyle: PropTypes.object,
  style: PropTypes.object,
  permanent: PropTypes.bool,
  highlight: PropTypes.bool,
  onAction: requiredIf(PropTypes.func, (props) => !props.disabled),
  fullWidth: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  centerContent: PropTypes.bool,
  ignorePermissions: PropTypes.bool,
  actionAriaLabel: PropTypes.string,
};

export default withStyles(styles)(HoverActionField);
