import React from 'react';
import PropTypes from 'prop-types';

import { useHasPermission } from '../../hooks/useHasPermission';
import FsTooltip from '../FsWrappers/FsTooltip/FsTooltip';

import { getPermissionDeniedTooltipText } from './PermissionUtils';
import { useRestrictedPermissions } from './RestrictedPermissions';

const PermissionDeniedTooltip = ({ children, action, verb }) => {
  const { userHasContextPermissions, permissionDeniedTooltipText } = useRestrictedPermissions();
  const userHasExplicitPermissions = useHasPermission({ action, verb });
  const shouldUseExplicitPermissions = action && verb;
  const explicitPermissionsTooltipText = shouldUseExplicitPermissions
    ? getPermissionDeniedTooltipText({ action, verb })
    : null;

  const tooltipText = explicitPermissionsTooltipText || permissionDeniedTooltipText;
  const isPermissionDenied = shouldUseExplicitPermissions ? !userHasExplicitPermissions : !userHasContextPermissions;

  if (!children) return null;

  if (!isPermissionDenied) return <>{children}</>;

  return <FsTooltip title={tooltipText}>{children}</FsTooltip>;
};

PermissionDeniedTooltip.propTypes = {
  action: PropTypes.string,
  verb: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PermissionDeniedTooltip;
