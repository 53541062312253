import React, { useEffect, useRef } from 'react';
import { sortBy } from 'lodash';

import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import Text from '~/components/core/TextComponents/Text';
import type {
  CoverageConfig,
  CoveredEntity,
  CurrencyFormatterType,
} from '~/components/Policy/GenericPolicy/CoveredEntity/types';

import { useClaim } from '../../../ClaimContainer';
import { useCurrencyFormatter } from '../../../CurrencyFormatterContext';
import LoadingIndicator from '../../../LoadingIndicator';
import PlainTable from '../../../PlainTable';
import useDataFetcher from '../../../useDataFetcher';

interface CoveredEntityAccumulatedFinancePerCoverageContainerProps {
  coveredEntity: CoveredEntity;
  coverageConfigs: CoverageConfig[];
}

interface AccumulatedFinancePerCoverageData {
  coverages_accumulated: { [key: string]: number };
}

const CoveredEntityAccumulatedFinancePerCoverageContainer: React.FC<
  CoveredEntityAccumulatedFinancePerCoverageContainerProps
> = ({ coveredEntity, coverageConfigs }) => {
  const { claim } = useClaim();
  const entityId = coveredEntity.entity_id;

  let coveragesInPolicyWithPerPolicyPerEntityLimit = coverageConfigs.filter(
    (coverage) =>
      coverage.is_limit_per_policy_per_entity &&
      coveredEntity.coverages_dict &&
      coverage.coverage_key in coveredEntity.coverages_dict
  );
  coveragesInPolicyWithPerPolicyPerEntityLimit = sortBy(
    coveragesInPolicyWithPerPolicyPerEntityLimit,
    (coverageConfig) => coverageConfig.display_name
  );

  const isRelevantCoverageExists = coveragesInPolicyWithPerPolicyPerEntityLimit.length !== 0;

  const {
    isLoading,
    isError,
    data: accumulatedFinancePerCoverageData,
    reloadData,
  } = useDataFetcher(
    `/api/v1/claims/${claim.id}/claim_coverages_accumulated_finance`,
    {
      params: { entity_id: entityId },
    },
    isRelevantCoverageExists // don't fetch if no relevant coverages
  );
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // If anything changed in claim, reload
    reloadData();
  }, [reloadData, claim]);

  if (!isRelevantCoverageExists) {
    return null;
  }

  return (
    <CollapsibleWrapper noBackground actionCard title="Accumulated Payments per Coverage">
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
        Accumulating amount for related entity and policy (including this claim)
      </Text>
      {isLoading || isError ? (
        <LoadingIndicator isError={isError} size={20} />
      ) : (
        <CoveredEntityAccumulatedFinancialPerCoverageTable
          coveredEntity={coveredEntity}
          coveragesInPolicyWithPerPolicyPerEntityLimit={coveragesInPolicyWithPerPolicyPerEntityLimit}
          accumulatedFinancePerCoverageData={accumulatedFinancePerCoverageData}
        />
      )}
    </CollapsibleWrapper>
  );
};

interface CoveredEntityAccumulatedFinancialPerCoverageTableProps {
  coveredEntity: CoveredEntity;
  coveragesInPolicyWithPerPolicyPerEntityLimit: CoverageConfig[];
  accumulatedFinancePerCoverageData: AccumulatedFinancePerCoverageData;
}

const CoveredEntityAccumulatedFinancialPerCoverageTable: React.FC<
  CoveredEntityAccumulatedFinancialPerCoverageTableProps
> = ({ coveredEntity, coveragesInPolicyWithPerPolicyPerEntityLimit, accumulatedFinancePerCoverageData }) => {
  const { currencyFormatter } = useCurrencyFormatter() as CurrencyFormatterType;

  const columnData = [
    { id: 'coverage_key', numeric: false, label: 'Coverage' },
    { id: 'accumulated', numeric: false, label: 'Accumulated' },
    { id: 'policy_limit', numeric: false, label: 'Policy Limit' },
    { id: 'remaining', numeric: false, label: 'Remaining amount' },
  ];

  let rows = coveragesInPolicyWithPerPolicyPerEntityLimit.map((coverageConfig) => {
    const coverageKey = coverageConfig.coverage_key;
    const accumulated = accumulatedFinancePerCoverageData.coverages_accumulated[coverageKey] || 0;
    const policyLimit =
      (coveredEntity.coverages_dict && coveredEntity.coverages_dict[coverageKey].limit_per_policy_per_entity) || 0;
    return {
      coverage_key: coverageConfig.display_name,
      accumulated: currencyFormatter.format(accumulated),
      policy_limit: currencyFormatter.format(policyLimit),
      remaining: currencyFormatter.format(policyLimit - accumulated),
    };
  });
  rows = rows.map((row, idx) => ({ ...row, id: idx }));

  return <PlainTable columns={columnData} rows={rows} keepRowsOrder />;
};

export default CoveredEntityAccumulatedFinancePerCoverageContainer;
