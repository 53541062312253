import React from 'react';
import Popover from '@material-ui/core/Popover';

import ToolbarButton from '~/components/core/Editor/MenuBar/ToolbarButton';
import DropDownIcon from '~/components/icons/editor/DropDownIcon';

import cn from '../../../../../Utils/cn';

import colorsDataJson from './colors.json';

export interface PopoverColorPickerProps {
  onChange?: (hexColor: string) => void;
  isEnabledFunc?: () => boolean;
  icon: React.ReactNode;
}

const colorsData: { [key: string]: string[] } = colorsDataJson;
const WHITE = '#FFFFFF';

const PopoverColorPicker: React.FC<PopoverColorPickerProps> = ({ isEnabledFunc = () => true, icon, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ToolbarButton disabledHoverEffect disabled={!isEnabledFunc()} onClick={handleClick}>
        <div className="inline-flex items-center">
          {icon}
          <DropDownIcon className="ml-4" size={12} />
        </div>
      </ToolbarButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="p-4 pb-2">
          {Object.keys(colorsData).map((colorGroupName) => {
            const colors: string[] = colorsData[colorGroupName];
            return (
              <div key={colorGroupName} className="p-1 inline-block">
                {colors.map((color: string) => (
                  <button
                    key={color}
                    className={cn('m-2 box-border block h-[24px] w-[24px] rounded border-none hover:opacity-70', {
                      'border-1 border-solid border-slate-700 p-0': color === WHITE,
                    })}
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      onChange && onChange(color);
                      handleClose();
                    }}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </Popover>
    </>
  );
};

export default PopoverColorPicker;
