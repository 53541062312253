import React from 'react';

import { ConfirmModal } from '~/components/ConfirmModal';

export interface EditWarningDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  isDraft?: boolean;
}

const WARNING_TEXT =
  'If you choose to edit your work from the Reports and Dashboards tab, any changes made will be automatically published as soon as you save them. If you prefer to save your edits as a draft first, edit the work from the My Drafts tab';

const WARNING_TEXT_DRAFT =
  'If you choose to edit your work from the My Drafts tab, your changes will be saved to your draft only. If you wish to replace the shared report with the edited version, you will need to republish the updated report.';

export const EditWarningDialog: React.FC<EditWarningDialogProps> = ({ onClose, onConfirm, isDraft = false }) => {
  return (
    <ConfirmModal
      isOpen={true}
      onClose={onClose}
      onPrimaryBtnClick={onConfirm}
      title="Edit Workbook?"
      contentText={isDraft ? WARNING_TEXT_DRAFT : WARNING_TEXT}
      primaryButtonName="EDIT"
      secondaryButtonName="CANCEL"
    />
  );
};
