import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const NoteTemplateIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={iconColor} disableStrokeColor {...props}>
    <path
      d="M10.8 9.6a.6.6 0 0 1 .6-.6h4.2a.6.6 0 1 1 0 1.2h-4.2a.6.6 0 0 1-.6-.6ZM11.4 11.4a.6.6 0 1 0 0 1.2h4.2a.6.6 0 1 0 0-1.2h-4.2ZM10.8 14.4a.6.6 0 0 1 .6-.6h1.8a.6.6 0 1 1 0 1.2h-1.8a.6.6 0 0 1-.6-.6ZM8.4 9a.6.6 0 0 0 0 1.2H9A.6.6 0 0 0 9 9h-.6ZM7.8 12a.6.6 0 0 1 .6-.6H9a.6.6 0 1 1 0 1.2h-.6a.6.6 0 0 1-.6-.6ZM8.4 13.8a.6.6 0 1 0 0 1.2H9a.6.6 0 1 0 0-1.2h-.6Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.52 3.48c-.24-.24-.72-.48-1.08-.48H4.56c-.36 0-.84.12-1.08.48-.36.24-.48.6-.48.96v15c0 .36.12.84.48 1.08.24.24.72.48 1.08.48h10.2c.24 0 .36 0 .6-.12.12 0 .24-.12.24-.12.06 0 .09-.03.12-.06s.06-.06.12-.06l4.8-4.8s.12-.12.12-.24c.069-.069.098-.098.11-.134.01-.026.01-.055.01-.106.12-.24.12-.36.12-.6V4.44c0-.36-.12-.72-.48-.96Zm-15.96.96h15v9.72H15c-.36 0-.72.36-.72.72v4.56H4.56v-15Zm13.92 11.28-2.64 2.64v-2.64h2.64Z"
      fill={iconColor}
    />
  </SvgIcon>
);

NoteTemplateIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default NoteTemplateIcon;
