import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { JusterImage } from '~/components/AiChat/components/JusterImage';
import { BetaTag } from '~/components/AiChat/components/Message/BetaTag';
import { SIDEBAR_DIALOG_CONFIG } from '~/components/AiChat/SideBarDialog/SidebarDialogConstants';
import { Text } from '~/components/core';
import Chip from '~/components/core/Atomic/Chip/Chip';
import colors from '~/theme/tailwind/colors';
import cn from '~/Utils/cn';

const useChipStyles = makeStyles({
  root: {
    backgroundColor: 'rgb(0 0 0 / 30%)',
    color: colors.aiV2.grey[100],
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
  },
});

export const ChatDialogTitleBar: React.FC<{
  withAiIcon?: boolean;
  dialogTitle: React.ReactNode;
  actions: React.ReactNode[];
}> = ({ dialogTitle, actions, withAiIcon }) => {
  const chipStyles = useChipStyles();
  return (
    <div
      className={cn(
        '__TITLE_BAR__ h-hull row flex items-center justify-between border-0 border-b-1 border-solid border-b-slate-100 border-opacity-10 py-12',
        SIDEBAR_DIALOG_CONFIG.paddingX
      )}
    >
      <div className="row flex items-center gap-12">
        {withAiIcon ? (
          <>
            <JusterImage width={42} height={42} />

            <Text variant={Text.VARIANTS.SM} className="font-bold text-white">
              Clive
            </Text>
            <BetaTag />
          </>
        ) : null}
        <Chip label={dialogTitle} classes={chipStyles} />
      </div>
      <div className="__ACTIONS__ row flex gap-12">{actions}</div>
    </div>
  );
};
