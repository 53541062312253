import React from 'react';

import SvgIcon from '../core/SvgIcon';

const DownloadIcon = (props) => (
  <SvgIcon viewBox="0 0 12 12" stroke="none" {...props}>
    <path d="M6.35355 7.85355C6.15829 8.04882 5.84171 8.04882 5.64645 7.85355L3.02145 5.22855C2.82618 5.03329 2.82618 4.71671 3.02145 4.52145C3.21671 4.32619 3.53329 4.32619 3.72855 4.52145L6 6.79289L8.27145 4.52145C8.46671 4.32619 8.78329 4.32619 8.97855 4.52145C9.17381 4.71671 9.17381 5.03329 8.97855 5.22855L6.35355 7.85355Z" />
    <path d="M6 8C5.72386 8 5.5 7.77614 5.5 7.5L5.5 0.5C5.5 0.223857 5.72386 1.20706e-08 6 0C6.27614 -1.20706e-08 6.5 0.223857 6.5 0.5L6.5 7.5C6.5 7.77614 6.27614 8 6 8Z" />
    <path d="M0.5 7C0.776142 7 1 7.22386 1 7.5V11H11V7.5C11 7.22386 11.2239 7 11.5 7C11.7761 7 12 7.22386 12 7.5V11C12 11.2652 11.8946 11.5196 11.7071 11.7071C11.5196 11.8946 11.2652 12 11 12H1C0.734784 12 0.48043 11.8946 0.292893 11.7071C0.105357 11.5196 0 11.2652 0 11V7.5C0 7.22386 0.223858 7 0.5 7Z" />
  </SvgIcon>
);

export default DownloadIcon;
