import React from 'react';
import axios from 'axios';

import useScript from '~/components/hooks/useScript';
import { useTableauApiRoutes } from '~/components/ReportsPage/Hooks/useTableauApiRoutes';
import { useTableauConfiguration } from '~/components/ReportsPage/Hooks/useTableauConfiguration';

export interface ITableauEmbedAuth {
  token?: string;
  isLoading: boolean;
  isError: boolean;
}

export const useTableauEmbedAuth = (): ITableauEmbedAuth => {
  const { host_url } = useTableauConfiguration();
  const scriptUrl = `${host_url}/javascripts/api/tableau.embedding.3.latest.js`;
  const { isLoading, isError } = useScript({ id: 'tableau_embedding', scriptUrl, isModule: true });
  const { jwtUrl } = useTableauApiRoutes();
  const [token, setToken] = React.useState();

  React.useEffect(() => {
    if (!token && !isLoading && !isError) {
      const loadJwt = async () => {
        const { data } = await axios.get(jwtUrl);
        const { jwt } = data || {};
        setToken(jwt);
      };
      loadJwt();
    }
  }, [token, isLoading, jwtUrl, isError]);

  return {
    token,
    isLoading,
    isError,
  };
};
