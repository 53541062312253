const MARSHMALLOW_JOURNEY_PURPOSE_LIST = [
  'Commuting',
  'Company business',
  'Other business',
  'Other parked',
  'Parked at home',
  'Parked at work',
  'Pleasure',
  'Unknown',
  'Not applicable',
] as const;

export default MARSHMALLOW_JOURNEY_PURPOSE_LIST;
