import React, { useState } from 'react';
import { getIn, useFormikContext } from 'formik';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Text from '~/components/core/TextComponents/Text';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import FinancesTable from '~/components/Finances/FinancesTable';
import { COLUMN_KEYS, EXTENDABLE_COLUMN_KEYS } from '~/components/Finances/FinancesTable/constants';
import PencilIcon from '~/components/icons/PencilIcon';
import TrashIcon from '~/components/icons/TrashIcon';
import useDataFetcher from '~/components/useDataFetcher';
import { CONSTANTS } from '~/Types';
import { formatSortOrder, reportErrorInProductionOrThrow } from '~/Utils';

import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import LoadingSwitch from '../core/Loading/LoadingSwitch';

import type { Finance } from './types';

interface Props {
  id: string;
  label: string;
  exposureId?: number;
}

const PaymentSelectionFieldFormik: React.FC<Props> = ({ id, label, exposureId }) => {
  const { claim } = useClaim();
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const [openChoosePaymentDialog, setOpenChoosePaymentDialog] = useState(false);
  const [paymentShortDisplay, setPaymentShortDisplay] = useState<string | undefined>();

  if (!claim) {
    reportErrorInProductionOrThrow('PaymentSelectionFieldFormik must be within claim context');
    return null;
  }

  const paymentId = getIn(values, id);

  const onRemovePayment = () => {
    setFieldValue(id, '');
  };

  const handlePaymentSelected = ({
    paymentId,
    shortDisplayLabel,
  }: {
    paymentId: string;
    shortDisplayLabel: string;
  }) => {
    setFieldValue(id, paymentId);
    setPaymentShortDisplay(shortDisplayLabel);
    setOpenChoosePaymentDialog(false);
  };

  return (
    <>
      <div>
        <Text
          colorVariant={
            getIn(errors, id) && getIn(touched, id) ? Text.COLOR_VARIANTS.ERROR : Text.COLOR_VARIANTS.SECONDARY
          }
          variant={Text.VARIANTS.XS}
        >
          {label}
        </Text>
        <div className="inline-flex items-center">
          {paymentId ? (
            <>
              {paymentShortDisplay}
              <IconButton onClick={onRemovePayment}>
                <TrashIcon />
              </IconButton>
            </>
          ) : (
            <div>
              Select Payment
              <IconButton className="ml-4 hover:text-teal-700" onClick={() => setOpenChoosePaymentDialog(true)}>
                <PencilIcon iconColor="currentColor" />
              </IconButton>
            </div>
          )}
        </div>
        <ErrorHelperTextFormik id={id} />
      </div>
      {openChoosePaymentDialog && (
        <PaymentSelectionDialog
          onSelect={handlePaymentSelected}
          onClose={() => setOpenChoosePaymentDialog(false)}
          exposureId={exposureId}
        />
      )}
    </>
  );
};

const DEFAULT_SORT_BY_COLUMN = { id: 'request_date', order: 'desc' };
const DEFAULT_FINANCES_PER_PAGE = 10;
const RESULTS_PER_PAGE_MIN = 10;
const RESULTS_PER_PAGE_MAX = 30;

const DISPLAY_COLUMN_IDS = [
  COLUMN_KEYS.claim_id_display,
  COLUMN_KEYS.request_date,
  COLUMN_KEYS.financial_status_last_update,
  COLUMN_KEYS.exposure_label,
  COLUMN_KEYS.payable_with_reserve_type,
  COLUMN_KEYS.request_status,
  COLUMN_KEYS.financial_status,
  COLUMN_KEYS.amount,
  COLUMN_KEYS.select_row_button,

  EXTENDABLE_COLUMN_KEYS.issued_by,
  EXTENDABLE_COLUMN_KEYS.payees,
  EXTENDABLE_COLUMN_KEYS.sent_to,
  EXTENDABLE_COLUMN_KEYS.reference,
  EXTENDABLE_COLUMN_KEYS.note,
  EXTENDABLE_COLUMN_KEYS.deductible_applied,
  EXTENDABLE_COLUMN_KEYS.payment_method,
];

interface PaymentSelectionDialogProps {
  onSelect: ({ paymentId, shortDisplayLabel }: { paymentId: string; shortDisplayLabel: string }) => void;
  onClose: () => void;
  exposureId?: number;
}
const PaymentSelectionDialog: React.FC<PaymentSelectionDialogProps> = ({ onSelect, onClose, exposureId }) => {
  const { claim } = useClaim();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { currencyFormatter } = useCurrencyFormatter();
  const [filterParams, setFilterParams] = useState({
    page: 0,
    results_per_page: DEFAULT_FINANCES_PER_PAGE,
    sort_by_column: DEFAULT_SORT_BY_COLUMN,
    claim_number: claim.claim_number,
    ...(exposureId ? { exposures_filters: [exposureId] } : {}),
  });
  const { page, sort_by_column } = filterParams;
  const config = { params: { ...filterParams, page: page + 1, sort_by_column: formatSortOrder(sort_by_column) } }; // page in the flask-SQLAlchemy is 1-based
  const {
    isLoading,
    isError,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data: { count, finances } = {},
  } = useDataFetcher(`/api/v1/claims/${claim.id}/finances`, config);

  const onChangePage = (_: unknown, newPage: number) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      page: newPage,
    }));
  };

  const onChangeRowsPerPage = (event: { target: { value: string } }) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      results_per_page: parseInt(event.target.value),
      page: 0,
    }));
  };

  const onSortByColumn = (_: unknown, sort_by_column: { id: string; order: string }) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      sort_by_column,
      page: 0, // reset the page when sorting
    }));
  };

  return (
    <CardDialog title="Select payment" fullWidth isDialog maxWidth="lg" onClose={onClose}>
      <LoadingSwitch isError={isError} isLoading={isLoading}>
        <FinancesTable
          finances={finances}
          paginationProps={{
            page,
            rowsPerPage: filterParams?.results_per_page,
            onChangePage,
            count,
            onChangeRowsPerPage,
            rowsPerPageOptions: [
              RESULTS_PER_PAGE_MIN,
              DEFAULT_FINANCES_PER_PAGE,
              CONSTANTS.DEFAULT_CLAIMS_PER_PAGE,
              RESULTS_PER_PAGE_MAX,
            ],
          }}
          onSortByColumn={onSortByColumn}
          sortByColumn={filterParams.sort_by_column}
          displayColumnIds={DISPLAY_COLUMN_IDS}
          onSelectRow={(row: Finance) => {
            onSelect({
              paymentId: String(row?.id),
              shortDisplayLabel: `${row.exposure_label} payment of ${currencyFormatter.format(
                row.amount
              )} to ${row.payees.map((p) => p.contact?.full_name).join(', ')} using ${
                row.payment_method_display_name
              }.`,
            });
          }}
        />
      </LoadingSwitch>
    </CardDialog>
  );
};

export default PaymentSelectionFieldFormik;
