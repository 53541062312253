import { isEmpty } from 'lodash';

import { getLocalDateTime, getLocalDateToday } from '../../../DateTimeUtils';
import { MIXPANEL_EVENTS } from '../../../pocs/mixpanel';
import { isLocationEmpty } from '../../../Utils';
import mixpanel from '../../CmsMain/mixpanel';

export const GENERAL_EXPOSURE_FAKE_ID = 0;

export const GENERAL_EXPOSURE_LABEL = 'General (All exposures)';
export const GENERAL_EXPOSURE_OPTION = {
  id: GENERAL_EXPOSURE_FAKE_ID,
  label: GENERAL_EXPOSURE_LABEL,
  value: GENERAL_EXPOSURE_FAKE_ID,
};

export const CREATE_MOI_INSTRUCTIONS_TEXT =
  'Fill in the required details to submit a new MOI. After submission, a communication will be sent automatically or an email will be presented for review based on the selected assignee.';

export const EDIT_MOI_INSTRUCTIONS_TEXT = 'Update the assignment progress.';

export const MOI_PARAMS_INITIAL_VALUES = {
  id: undefined,
  assignee_type: undefined,
  assignment_date: getLocalDateToday(),
  completion_datetime: undefined,
  contact_id: undefined,
  user_id: undefined,
  due_datetime: undefined,
  moi_method_id: undefined,
  special_internal_note: '',
  status: 'pending',
  reject_reason: '',
  scheduling_inspection_contact: null,
  scheduling_inspection_contact_id: null,
  note: '',
  inspection_location: null,
  exposure_ids: [],
  method_specific: {},
  vendor_name: '',
  vendor_distance: 25,
  vendor_type: '',
  attached_documents: [],
};

export const getMoiOptionsForClaimType = (claimType, moiOptions) =>
  moiOptions[claimType] ? moiOptions[claimType] : moiOptions.default;

export const moiMethodToString = (moi, claim, moiOptions) => {
  const moiOptionsForClaimType = getMoiOptionsForClaimType(claim.type, moiOptions);

  return moi?.method ? moiOptionsForClaimType[moi.method].title : 'N/A';
};

export const isNewMoi = (moiRecord) => isEmpty(moiRecord?.method);

const mapInspectionLocation = (exposure, claim) => {
  // In non-auto_claim (GC) - loss location is the default inspection location
  const incidentLossLocation = claim?.incident?.loss_location || {};

  // In auto_claim - if vehicle has inspection location, return it
  if (claim?.type === 'auto_claim') {
    const exposurePartyId = exposure?.incident_involved?.incident_party_id;
    const involvedVehicle = claim?.incident?.vehicle_parties?.find(
      (party) => party.id === exposurePartyId
    )?.involved_vehicle;

    if (!isLocationEmpty(involvedVehicle?.inspection_location)) {
      return involvedVehicle.inspection_location;
    }
  }

  return incidentLossLocation;
};

export const mapToInitialValues = (moiRecord, exposure, claim) => {
  const lossLocation = mapInspectionLocation(exposure, claim);
  const insured = claim.policy.insured_contact;

  if (isEmpty(moiRecord)) {
    return {
      ...MOI_PARAMS_INITIAL_VALUES,
      inspection_location: lossLocation,
      exposure_ids: [exposure.id],
      scheduling_inspection_contact_id: insured?.id,
      scheduling_inspection_contact_full_name: insured?.full_name,
      scheduling_inspection_contact: insured,
    };
  }

  if (isLocationEmpty(moiRecord?.inspection_location)) {
    moiRecord.inspection_location = lossLocation;
  }

  if (!moiRecord?.scheduling_inspection_contact) {
    moiRecord.scheduling_inspection_contact = insured;
    moiRecord.scheduling_inspection_contact_id = insured?.id;
    moiRecord.scheduling_inspection_contact_full_name = insured?.full_name;
  }

  if (isNewMoi(moiRecord)) {
    moiRecord.moi_method_id = moiRecord?.moi_method?.id;
  }

  if (!moiRecord.reject_reason) {
    moiRecord.reject_reason = '';
  }

  if (!moiRecord.special_instructions) {
    moiRecord.special_instructions = '';
  }

  if (!isNewMoi(moiRecord)) {
    moiRecord.moi_method = moiRecord.method;
  }

  if (!isNewMoi(moiRecord) && moiRecord?.method === 'self_service') {
    moiRecord.assignee_type = 'internal_user';
    if (isEmpty(moiRecord?.user)) {
      moiRecord.user = { id: 'N/A' };
      moiRecord.user_id = 'N/A';
    }
  }

  if (!isNewMoi(moiRecord) && moiRecord.method !== 'self_service') {
    moiRecord.assignee_type = 'service_provider';
    if (isEmpty(moiRecord?.contact)) {
      moiRecord.contact = { id: 'N/A' };
      moiRecord.contact_id = -1;
    }
  }

  if (isNewMoi(moiRecord) && !isEmpty(moiRecord?.contact)) {
    moiRecord.assignee_type = 'service_provider';
  }

  if (isNewMoi(moiRecord) && !isEmpty(moiRecord?.user)) {
    moiRecord.assignee_type = 'internal_user';
  }

  if (moiRecord?.is_claim_level) {
    moiRecord.exposure_ids = [GENERAL_EXPOSURE_FAKE_ID];
  }

  moiRecord.assignment_date = moiRecord?.datetime ? getLocalDateTime(moiRecord.datetime) : '';
  moiRecord.due_datetime = moiRecord?.due_datetime ? getLocalDateTime(moiRecord.due_datetime) : '';
  moiRecord.completion_datetime = moiRecord?.completion_datetime ? getLocalDateTime(moiRecord.completion_datetime) : '';

  return moiRecord;
};

export const trackMoiSubmit = ({ claim, exposure, actionType, errorMessage }) => {
  mixpanel.track(MIXPANEL_EVENTS.NEW_MOI_SUBMITTED, {
    ...mixpanel.getMixpanelAttributes(claim, [exposure], {
      action_type: actionType,
      error_message: errorMessage,
    }),
  });
};

export const trackMoiClicked = ({ claim, exposure, actionType }) => {
  mixpanel.track(MIXPANEL_EVENTS.NEW_MOI_CLICKED, {
    ...mixpanel.getMixpanelAttributes(claim, [exposure], {
      action_type: actionType,
    }),
  });
};

export const trackMoiDetailsClicked = ({ claim, exposure, moiId }) => {
  mixpanel.track(MIXPANEL_EVENTS.MOI_VIEW_DETAILS_CLICKED, {
    ...mixpanel.getMixpanelAttributes(claim, [exposure], {
      moi_id: moiId,
    }),
  });
};
