import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import AttachCommunicationContainer from '~/components/communications/AttachCommunicationContainer';
import WithConfirm from '~/components/ConfirmModal';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import { useCms } from '~/components/hooks/useCms';

const PhoneCallCommunicationAction = ({ callStatus, isSubmitting, onSubmit, onDismiss, communication }) => {
  const classes = useStyles();
  const { callInProgress, setCallInProgress } = useCms();
  const { values } = useFormikContext();

  const isDismissEnabled = !communication.claim_id;

  const handleClaimAttached = async (claimId) => {
    // Attaching to claim will cause getting updated communication and re-initialization of the formik - save the current values
    await axios.patch(`/api/v1/calls/${communication.id}`, { content: values.content, summary: values.summary });
    setCallInProgress({ ...callInProgress, claimToAttachId: claimId });
  };

  return (
    <>
      {!communication.claim_id && (
        <>
          <AttachCommunicationContainer communication={communication} onAttach={handleClaimAttached} />
          <ErrorHelperTextFormik id="claim_id" />
        </>
      )}

      {callStatus === 'closed' && (
        <div className={classes.buttonsContainer}>
          {isDismissEnabled && (
            <WithConfirm
              title="Dismiss Call?"
              contentText="If you choose to dismiss, this conversation will not be documented"
              primaryButtonName="Dismiss"
              shouldCloseOnPrimary={false}
            >
              <CancelButton onClick={onDismiss} disabled={isSubmitting} content="Dismiss" />
            </WithConfirm>
          )}
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={isSubmitting}>
            Save
          </Button>
        </div>
      )}
    </>
  );
};

PhoneCallCommunicationAction.propTypes = {
  communication: PropTypes.object.isRequired,
  callStatus: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
};

export default PhoneCallCommunicationAction;
