import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const InProgressIcon = ({ ...props }) => (
  <SvgIcon width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <path d="M4.36406 5.95234H1.73906V3.32734" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.4016 4.09844C9.9551 3.65132 9.42486 3.29661 8.84119 3.0546C8.25752 2.81258 7.63186 2.68801 7 2.68801C6.36814 2.68801 5.74248 2.81258 5.15881 3.0546C4.57514 3.29661 4.0449 3.65132 3.59844 4.09844L1.73906 5.95234"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.63594 9.04766H12.2609V11.6727" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.59844 10.9016C4.0449 11.3487 4.57514 11.7034 5.15881 11.9454C5.74248 12.1874 6.36814 12.312 7 12.312C7.63186 12.312 8.25752 12.1874 8.84119 11.9454C9.42486 11.7034 9.9551 11.3487 10.4016 10.9016L12.2609 9.04766"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

InProgressIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default InProgressIcon;
