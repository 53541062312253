import React from 'react';

import { AiAvatar } from '~/components/AiChat/components/Message/AiAvatar';
import { AiMessageContainer } from '~/components/AiChat/components/Message/AiMessageContainer';
import { BaseMessage } from '~/components/AiChat/components/Message/Message';
import type { BaseMessageProps } from '~/components/AiChat/components/Message/MessageTypes';
import { AI_NAME } from '~/components/AiChat/components/Message/MessageTypes';

export const AiGreeting: React.FC<{ text: string } & Pick<BaseMessageProps, 'withTypeEffect'>> = ({
  text,
  withTypeEffect,
}) => {
  return (
    <BaseMessage
      text={text}
      avatar={<AiAvatar />}
      sender={AI_NAME}
      isLoading={false}
      withTypeEffect={withTypeEffect}
      messageContainer={AiMessageContainer}
    />
  );
};
