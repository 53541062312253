import React from 'react';

import { ConfirmModal } from '~/components/ConfirmModal';

export interface DeleteWarningDialogProps {
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export const DeleteWarningDialog: React.FC<DeleteWarningDialogProps> = ({ onClose, onConfirm }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const onConfirmCallback = React.useCallback(async () => {
    setIsSubmitting(true);
    await onConfirm();
    setIsSubmitting(false);
  }, [onConfirm]);

  return (
    <ConfirmModal
      isOpen={true}
      isSubmitting={isSubmitting}
      onClose={() => !isSubmitting && onClose()}
      onPrimaryBtnClick={onConfirmCallback}
      title="Delete This Workbook?"
      contentText="Are you sure you want to delete this workbook?"
      primaryButtonName="DELETE"
      secondaryButtonName="CANCEL"
    />
  );
};
