import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableSortLabel } from '@material-ui/core';

import Tooltip from '~/components/core/Atomic/Tooltip';

import { getColumnPadding } from '../utils';

import { useStyles } from '../../../../../assets/styles';

const SortableTableHead = ({ column, selectedColumnId, order, orderBy, disableSort, handleRequestSort }) => {
  const classes = useStyles();

  const tableCell = (
    <TableCell
      key={column.id}
      style={{
        width: column.width,
        backgroundColor: selectedColumnId === column.id ? 'rgba(0, 0, 0, 0.04)' : undefined,
        ...getColumnPadding(column),
      }}
      className={classes.headerTableCell}
      align={column.align || 'left'}
      padding={column.disablePadding ? 'none' : 'default'}
      sortDirection={orderBy === column.id ? order : false}
    >
      {disableSort || column.disableSort ? (
        column.label
      ) : (
        <TableSortLabel // NOTE: consider re-adding the tooltip
          active={orderBy === column.id}
          direction={order}
          onClick={(e) => handleRequestSort(e, column.id)}
        >
          {column.label}
        </TableSortLabel>
      )}
    </TableCell>
  );

  return column.toolTip ? (
    <Tooltip key={`tooltip_${column.id}`} title={column.toolTip}>
      {tableCell}
    </Tooltip>
  ) : (
    tableCell
  );
};

SortableTableHead.propTypes = {
  column: PropTypes.object.isRequired,
  selectedColumnId: PropTypes.string,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  disableSort: PropTypes.bool,
  handleRequestSort: PropTypes.func.isRequired,
};

export default SortableTableHead;
