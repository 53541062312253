import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import type { SafeComponentProps } from '~/components/core/SafeComponent';
import { SafeRoute } from '~/components/core/SafeComponent/SafeRoute';

import { useHasPermission } from '../../hooks/useHasPermission';

interface PermissionProtectedRouteProps extends RouteProps {
  path: string;
  action: string;
  verb: string;
  redirectPath?: string;
  safeConfig?: Partial<SafeComponentProps>;
}

const PermissionProtectedRoute: React.FC<PermissionProtectedRouteProps> = ({
  path,
  action,
  verb,
  redirectPath,
  safeConfig,
  ...restRouteProps
}) => {
  const userHasPermissions = useHasPermission({ action, verb });

  if (!action || !verb || userHasPermissions) {
    return <SafeRoute path={path} {...restRouteProps} safeConfig={safeConfig || {}} />;
  }

  const redirectParams = action && verb ? `/${action}/${verb}` : '';
  const redirectTo = redirectPath || `/permission_denied${redirectParams}`;

  return <Redirect from={path} to={redirectTo} />;
};

export default PermissionProtectedRoute;
