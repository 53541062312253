import React from 'react';

interface TextWithMdBoldProps {
  text: string;
}

const transformMarkdownBoldToStrongTag = (text: string): (JSX.Element | string)[] => {
  const regex = /\*\*(.*?)\*\*/g;

  return text.split(regex).map((part, index) => {
    // If the part is surrounded by **, wrap it in a span with class "font-bold"
    if (index % 2 === 1) {
      return <strong key={part}>{part}</strong>;
    }
    return part;
  });
};

const TextWithMDBold: React.FC<TextWithMdBoldProps> = ({ text }) => {
  return <span className="whitespace-pre-wrap">{transformMarkdownBoldToStrongTag(text)}</span>;
};

export default TextWithMDBold;
