import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const HomeIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.57031 3.86834C6.62604 4.00933 6.71479 4.13974 6.83584 4.24734L11.0002 7.94906V12.9999H13.0002V7.5V7.05094L12.6646 6.75259L8.16458 2.75253C8.09936 2.69456 8.02867 2.64667 7.95441 2.60876L6.57031 3.86834Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M9.5 13V10C9.5 9.8674 9.44732 9.74023 9.35355 9.64646C9.25979 9.55269 9.13261 9.50001 9 9.50001H7C6.86739 9.50001 6.74021 9.55269 6.64645 9.64646C6.55268 9.74023 6.5 9.8674 6.5 10V13C6.5 13.1326 6.44732 13.2598 6.35355 13.3536C6.25979 13.4473 6.13261 13.5 6 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V7.21876C2.50112 7.14957 2.51607 7.08129 2.54398 7.01796C2.57189 6.95463 2.61219 6.89753 2.6625 6.85001L7.6625 2.30626C7.75467 2.22194 7.87507 2.17517 8 2.17517C8.12493 2.17517 8.24533 2.22194 8.3375 2.30626L13.3375 6.85001C13.3878 6.89753 13.4281 6.95463 13.456 7.01796C13.4839 7.08129 13.4989 7.14957 13.5 7.21876V13C13.5 13.1326 13.4473 13.2598 13.3536 13.3536C13.2598 13.4473 13.1326 13.5 13 13.5H10C9.86739 13.5 9.74021 13.4473 9.64645 13.3536C9.55268 13.2598 9.5 13.1326 9.5 13Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default HomeIcon;
