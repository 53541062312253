import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import _ from 'lodash';

import { reportAxiosError } from '../../../Utils';
import useOrganization from '../../OrganizationContext';
import { MultiSelectTextFieldFormik, usePrevious } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

export const IncidentTypeMultiselectFormik = ({
  subOrganizationIds = [],
  lobs = [],
  fieldId,
  label = 'Incident Type',
  disabled = false,
  onTypesFetch = _.noop,
}) => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();
  const { organizationId, subOrganizationEnabled } = useOrganization();
  const types = getIn(values, fieldId);
  const prevSubOrgIdsRef = usePrevious(subOrganizationIds);
  const prevLobsRef = usePrevious(lobs);
  const prevTypesRef = usePrevious(types);
  const [incidentTypesOptionsDict, setIncidentTypesOptionsDict] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!_.isEqual(prevSubOrgIdsRef, subOrganizationIds) || !_.isEqual(prevLobsRef, lobs)) {
        setIsLoading(true);

        try {
          const responses = await Promise.all(
            lobs.map((lob) =>
              axios.get(`/api/v1/organizations/${organizationId}/incident_types`, {
                params: { sub_orgs_ids: subOrganizationIds, lob },
              })
            )
          );

          const results = responses.map((response) => response.data);
          const options = results.reduce((options, result) => ({ ...options, ...result }), {});

          setIncidentTypesOptionsDict(options);
          onTypesFetch(options);
        } catch (error) {
          await reportAxiosError(error);
        }
        setIsLoading(false);
      }
    })();
  }, [organizationId, lobs, subOrganizationIds, prevLobsRef, prevSubOrgIdsRef, onTypesFetch]);

  useEffect(() => {
    if (!_.isEqual(prevTypesRef, types)) {
      setFieldValue(fieldId, _.intersection(types, Object.keys(incidentTypesOptionsDict)));
    }
  }, [incidentTypesOptionsDict, fieldId, setFieldValue, types, prevTypesRef]);

  const isDisabled =
    disabled || isLoading || _.isEmpty(lobs) || (subOrganizationEnabled && _.isEmpty(subOrganizationIds));
  const getCoverageDesc = (coverage) => incidentTypesOptionsDict[coverage]?.desc || coverage;

  return (
    <MultiSelectTextFieldFormik
      id={fieldId}
      label={label}
      options={Object.keys(incidentTypesOptionsDict)}
      renderValue={(selected) => selected.map(getCoverageDesc).join(', ')}
      className={classes.textField}
      fullWidth
      renderOption={getCoverageDesc}
      disabled={isDisabled}
    />
  );
};

IncidentTypeMultiselectFormik.propTypes = {
  fieldId: PropTypes.string.isRequired,
  subOrganizationIds: PropTypes.array,
  lobs: PropTypes.array,
  label: PropTypes.string,
  onTypesFetch: PropTypes.func,
  disabled: PropTypes.bool,
};
