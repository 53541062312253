import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const AutoIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      d="M13.5 12V13L12 13L12 12H13.5Z"
      fill={colors.lobIconSecondary}
      fillOpacity="0.3"
      stroke={colors.lobIconMain}
    />
    <path
      d="M4 12L4 13L2.5 13L2.5 12H4Z"
      fill={colors.lobIconSecondary}
      fillOpacity="0.3"
      stroke={colors.lobIconMain}
    />
    <path
      d="M14 7.33329L13.4646 4.79996C13.4258 4.76047 13.362 4.72684 13.281 4.70317C13.2 4.67949 13.1053 4.6668 13.0083 4.66663H2.99167C2.89472 4.6668 2.79998 4.67949 2.71897 4.70317C2.63797 4.72684 2.57419 4.76047 2.53542 4.79996L2 7.33329H14Z"
      fill={colors.lobIconSecondary}
    />
    <path d="M1 7.5H15" stroke={colors.lobIconMain} strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path
      d="M14 11.5V13C14 13.1326 13.9473 13.2598 13.8536 13.3536C13.7598 13.4473 13.6326 13.5 13.5 13.5H12C11.8674 13.5 11.7402 13.4473 11.6464 13.3536C11.5527 13.2598 11.5 13.1326 11.5 13V11.5"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.5 11.5V13C4.5 13.1326 4.44732 13.2598 4.35355 13.3536C4.25979 13.4473 4.13261 13.5 4 13.5H2.5C2.36739 13.5 2.24021 13.4473 2.14645 13.3536C2.05268 13.2598 2 13.1326 2 13V11.5"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path d="M4 9.5H5" stroke={colors.lobIconMain} strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M11 9.5H12" stroke={colors.lobIconMain} strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path
      d="M14 7.5L12.1313 3.3C12.0925 3.21115 12.0287 3.13548 11.9477 3.08222C11.8667 3.02895 11.7719 3.00039 11.675 3H4.325C4.22805 3.00039 4.13331 3.02895 4.05231 3.08222C3.9713 3.13548 3.90753 3.21115 3.86875 3.3L2 7.5V11.5H14V7.5Z"
      stroke={colors.lobIconMain}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default AutoIcon;
