import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { isEqual, pick } from 'lodash';

const useFormikChangeListener = ({ onChange, listenForKeys, runOnFirstRender }) => {
  const { values } = useFormikContext();
  const [previousValues, updateValues] = useState(values);
  const [didRun, setDidRun] = useState(false);

  useEffect(() => {
    let isChanged = listenForKeys
      ? !isEqual(pick(values, listenForKeys), pick(previousValues, listenForKeys))
      : !isEqual(values, previousValues);
    if (isChanged || (runOnFirstRender && !didRun)) {
      onChange(values, previousValues);
      if (!didRun) {
        setDidRun(true);
      }
    }
    updateValues(values);
    // this is ignored to prevent calling this hook again after updateValues is called
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, onChange, listenForKeys]);
};

useFormikChangeListener.propTypes = {
  onChange: PropTypes.func.isRequired,
  listenForKeys: PropTypes.arrayOf(PropTypes.string),
  runOnFirstRender: PropTypes.bool,
};

export default useFormikChangeListener;
