import React from 'react';
import { ToggleButtonGroup as MuiToggleButtonGroup } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';

interface ToggleButtonProps {
  value: unknown;
  label: React.ReactNode;
  key: string;
}

export interface ToggleButtonGroupProps {
  id?: string;
  value: unknown;
  exclusive?: boolean;
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: unknown) => void;
  options: ToggleButtonProps[];
  disabled?: boolean;
}

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  id,
  value,
  onChange,
  options,
  exclusive = true,
  disabled = false,
}) => {
  return (
    <MuiToggleButtonGroup id={id} value={value} exclusive={exclusive} onChange={onChange} size="small">
      {options.map(({ key, value: currValue, label }) => (
        <ToggleButton
          key={key}
          value={currValue}
          disabled={disabled}
          classes={{
            root: 'border-2 border-slate-500 px-12 py-2 normal-case text-slate-800 group-[.is-selected]:text-teal-700 ',
            selected: 'is-selected group',
          }}
        >
          {label}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
