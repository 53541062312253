import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isEmpty } from 'lodash';

import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

import { serverDateTimeToLocal } from '../../DateTimeUtils';
import { getLobIcon } from '../../Utils/lobUtils';
import CardDialog from '../CardDialog';
import WithConfirm from '../ConfirmModal';
import { LoadingSwitch, SortableTable } from '../core';
import OverflowArrayTextDisplayWithTooltip from '../core/OverflowArrayTextWithTooltip/OverflowArrayTextDisplayWithTooltip';
import { useCms } from '../hooks/useCms';
import { PencilIcon, TrashIcon } from '../icons';
import InlineIconButton from '../InlineIconButton';
import useOrganization from '../OrganizationContext';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';
import styles from './fnolDraft.module.scss';

const FnolDraftsContainer = ({ onSelectDraft }) => {
  const { isLoading, isError, data: drafts, reloadData } = useDataFetcher('/api/v1/fnol_drafts');

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      {drafts && drafts.length > 0 && (
        <CardDialog title="Saved Drafts">
          <Grid container>
            <Grid item xs={12}>
              <FnolDraftsTable
                drafts={drafts}
                onSelectDraft={onSelectDraft}
                allowDiscard
                onDraftDiscarded={reloadData}
              />
            </Grid>
          </Grid>
        </CardDialog>
      )}
    </LoadingSwitch>
  );
};

FnolDraftsContainer.propTypes = {
  onSelectDraft: PropTypes.func.isRequired,
};

function FnolDraftsTable({ drafts, onSelectDraft, onDraftDiscarded, allowDiscard }) {
  const classes = useStyles();
  const { lobConfigurationsDict } = useLobConfiguration();
  const { subOrganizationEnabled } = useOrganization();
  const { userOrganization } = useCms();
  const isCommercialPoliciesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.COMMERCIAL_POLICIES
  );
  const isConfigurableFnolEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);

  const handleDiscardDraft = async (fnolDraft) => {
    try {
      await axios.delete(`/api/v1/fnol_drafts/${fnolDraft.id}`);
      await onDraftDiscarded(fnolDraft);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  let columnData = [
    {
      id: 'policy_number',
      numeric: false,
      label: 'Policy #',
      nowrap: true,
      specialCell: (fnolDraft) => (
        <div className={styles.policyNumberTableCell}>
          {fnolDraft.policy_lob &&
            getLobIcon({ lob: fnolDraft.policy_lob.replace('_policy', '_claim'), lobConfigurationsDict })}
          {fnolDraft.policy_number}
        </div>
      ),
      width: isCommercialPoliciesEnabled && isConfigurableFnolEnabled ? '150px' : undefined,
    },
    {
      id: 'first_party_name_chip',
      label: 'First Party Involved',
      isHidden: !isCommercialPoliciesEnabled || !isConfigurableFnolEnabled,
      specialCell: (row) => {
        const firstPartyInvolvedVehicle = [row?.draft?.first_party?.involved_vehicle?.vehicle?.display_name];
        const firstPartyInvolvedNonMotorist = [row?.draft?.first_party?.involved_non_motorist?.contact_full_name];
        const firstPartyInvolvedEntities =
          row?.draft?.first_party_values?.map((entity) => entity?.contact_full_name || entity?.display_name) || [];

        const firstPartyInvolvedEntitiesNames = firstPartyInvolvedEntities
          .concat(firstPartyInvolvedVehicle)
          .concat(firstPartyInvolvedNonMotorist)
          .filter((entity) => !isEmpty(entity));

        if (isEmpty(firstPartyInvolvedEntitiesNames)) return null;

        return (
          <div>
            <OverflowArrayTextDisplayWithTooltip
              value={firstPartyInvolvedEntitiesNames}
              renderItem={(label) => (
                <span key={label}>
                  <Chip label={label} size="small" className="mr-12" />
                </span>
              )}
            />
          </div>
        );
      },
      disableSort: true,
      width: '250px',
    },
    { id: 'sub_organization_name', numeric: false, label: 'Sub Organization' },
    {
      id: 'last_updated',
      numeric: false,
      label: 'Last updated on',
      specialCell: (row) => serverDateTimeToLocal(row.last_updated),
    },
    { id: 'last_editing_adjuster', numeric: false, label: 'Last updated by' },
    {
      id: 'datetime_created',
      numeric: false,
      label: 'Created on',
      specialCell: (row) => serverDateTimeToLocal(row.datetime_created),
    },
    { id: 'creating_adjuster', numeric: false, label: 'Created by' },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      disableSort: true,
      specialCell: (row) => (
        <span style={{ display: 'inline-flex' }}>
          <InlineIconButton
            className={classes.hoverableNonFilledIcon}
            icon={PencilIcon}
            onClick={async () => await onSelectDraft(row)}
          />
          {allowDiscard && (
            <WithConfirm title="Discard draft?" primaryButtonName="Yes" shouldCloseOnPrimary={false}>
              <InlineIconButton
                className={classes.hoverableNonFilledIcon}
                icon={TrashIcon}
                onClick={async () => await handleDiscardDraft(row)}
              />
            </WithConfirm>
          )}
        </span>
      ),
    },
  ];

  if (!allowDiscard) {
    columnData = columnData.filter((col) => col.id !== 'discard_button');
  }

  if (!subOrganizationEnabled) {
    columnData = columnData.filter((col) => col.id !== 'sub_organization_name');
  }

  return (
    <SortableTable
      rows={drafts}
      columns={columnData}
      defaultOrderColumn={columnData.findIndex((column) => column.id === 'last_updated')}
      order="desc"
    />
  );
}
FnolDraftsTable.propTypes = {
  drafts: PropTypes.array.isRequired,
  onSelectDraft: PropTypes.func.isRequired,
  allowDiscard: PropTypes.bool,
  onDraftDiscarded: requiredIf(PropTypes.func, (props) => props.allowDiscard),
};

export { FnolDraftsTable };
export default FnolDraftsContainer;
