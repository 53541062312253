import React from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import type { LawsuitDetailsFormValues } from '../../FormFragments/FormValuesTypes';
import { LawsuitDetailsForm } from '../../FormFragments/LawsuitDetailsForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const EditLawsuitDetailsDialog: React.FC<DialogPropsWithLegalAction> = ({
  legalAction,
  onClose,
  onSubmitSuccess,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: LawsuitDetailsFormValues) => {
    try {
      await axios.patch(backendRoutes.legalAction.details, values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<LawsuitDetailsFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: pick(legalAction, [
          'lawsuit_type',
          'complaint_number',
          'nature_of_dispute',
          'nature_of_dispute_text',
        ]),
        validationSchema: Yup.object().shape({}),
      }}
      title="Edit Lawsuit Details"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <LawsuitDetailsForm />
      </div>
    </FormDialog>
  );
};
