import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

import MentionList from './MentionList';

const MAN_ITEMS_DISPLAY = 10;

export const getSuggestionConfig = (suggestionOptions) => ({
  items: ({ query }) => {
    return suggestionOptions
      .filter(({ label }) => label.toLowerCase().includes(query.toLowerCase()))
      .slice(0, MAN_ITEMS_DISPLAY);
  },

  render: () => {
    let component;
    let popup;

    return {
      onStart: (props) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }
        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
});

export const flattenOptions = (options) => {
  const flatList = [];

  options.forEach((item) => {
    if (item.sub_items) {
      flatList.push(...flattenOptions(item.sub_items));
    } else {
      flatList.push({ label: item.label, id: item.id });
    }
  });
  return flatList;
};
