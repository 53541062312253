const WC_COVERAGE_DICT = {
  coverage_wc_medicals: {
    desc: 'Medicals',
  },
  coverage_wc_compensation: {
    desc: 'Compensation',
  },
  coverage_wc_other_expense: {
    desc: 'Other Expense',
  },
  coverage_wc_voc_rehab: {
    desc: 'Voc. Rehab',
  },
} as const;

export default WC_COVERAGE_DICT;
