import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useHasPermission } from '../../hooks/useHasPermission';

import { getPermissionDeniedTooltipText, isActionValid, isVerbValid, PERMISSION_VERBS } from './PermissionUtils';

const PermissionsContext = React.createContext({});

export const RestrictedPermissions = ({ action, verb, children }) => {
  if (action && !isActionValid(action)) {
    throw new Error(`RestrictedPermissions: action ${action} is not valid`);
  }

  if (verb && !isVerbValid(verb)) {
    throw new Error(`RestrictedPermissions: verb ${verb} are not valid`);
  }

  const contextValues = {
    action,
    verb,
    userHasContextPermissions: useHasPermission({ action, verb: verb ?? PERMISSION_VERBS.WRITE }),
    permissionDeniedTooltipText: getPermissionDeniedTooltipText({ action, verb: verb ?? PERMISSION_VERBS.WRITE }),
  };

  return <PermissionsContext.Provider value={contextValues}>{children}</PermissionsContext.Provider>;
};

export const useRestrictedPermissions = () => {
  const context = React.useContext(PermissionsContext);
  const isContextPresent = ['action', 'verb', 'userHasContextPermissions'].every((key) => _.has(context, key));
  return isContextPresent ? context : { userHasContextPermissions: true };
};

RestrictedPermissions.propTypes = {
  action: PropTypes.string,
  verb: PropTypes.string,
  children: PropTypes.node.isRequired,
};
