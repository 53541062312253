import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const MandatoryNotificationIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon fill="none" stroke={iconColor} {...props}>
    <path d="M8 2.5V13.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.23755 5.25L12.7625 10.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.23755 10.75L12.7625 5.25" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

MandatoryNotificationIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default MandatoryNotificationIcon;
