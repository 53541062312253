import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST } from '../../../Types';
import { capitalize, isInshurClaim, isTscClaim, reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import WithConfirm from '../../ConfirmModal';
import {
  FsButton,
  FsMenuItem,
  PERMISSION_ACTIONS,
  PERMISSION_VERBS,
  PermissionsButtonWrapper,
  RestrictedPermissions,
} from '../../core';
import { InvolvedPropertyParty } from '../../InvolvedProperty';
import { ShowOnlyTextField, TextFieldFormik } from '../../TextFieldFormik';

import NonMotoristDetails, { AddNonMotoristParty } from './NonMotoristInvolved';
import VehicleParty from './VehicleParty';

import { useStyles } from '../../../assets/styles';

class AutoInvolvedCard extends Component {
  state = {
    setIsFetching: false,
  };

  handleUpdateVehicleParty = async (partyUrlPrefix, operationType, data) => {
    try {
      switch (operationType) {
        case 'SET_INVOLVED_VEHICLE':
          await axios.post(`${partyUrlPrefix}/involved_vehicle`, data);
          break;
        case 'UPDATE_INVOLVED_VEHICLE':
          await axios.put(`${partyUrlPrefix}/involved_vehicle`, data);
          break;
        case 'DELETE_INVOLVED_VEHICLE':
          await axios.delete(`${partyUrlPrefix}/involved_vehicle`);
          break;

        case 'SET_DRIVER':
          await axios.post(`${partyUrlPrefix}/driver`, data);
          break;
        case 'UPDATE_DRIVER':
          await axios.put(`${partyUrlPrefix}/driver`, data);
          break;
        case 'DELETE_DRIVER':
          await axios.delete(`${partyUrlPrefix}/driver`);
          break;

        case 'ADD_PASSENGER':
          await axios.post(`${partyUrlPrefix}/passengers`, data);
          break;
        case 'UPDATE_PASSENGER':
          // data = { passenger, idx }
          await axios.put(`${partyUrlPrefix}/passengers/${data.passenger.id}`, data.passenger);
          break;

        case 'DELETE_PASSENGER':
          // data = { passenger, idx }
          await axios.delete(`${partyUrlPrefix}/passengers/${data.passenger.id}`);
          break;

        default:
          throw Error(`Should not happen Unknown ${operationType}`);
      }

      this.props.onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleUpdateFirstPartyVehicle = (operation, data) => {
    const { claim } = this.props;
    return this.handleUpdateVehicleParty(`/api/v1/auto_claims/${claim.id}/first_party_vehicle`, operation, data);
  };

  handleUpdateFirstPartyNonMotorist = async (data) => {
    const { claim, onUpdate } = this.props;
    try {
      await axios.put(`/api/v1/auto_claims/${claim.id}/first_party_non_motorist`, data);
      return await Promise.resolve(onUpdate());
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleUpdateThirdPartyVehicle = (idx, operation, data) => {
    const { claim, thirdPartyVehicles } = this.props;
    return this.handleUpdateVehicleParty(
      `/api/v1/auto_claims/${claim.id}/third_party_vehicles/${thirdPartyVehicles[idx].id}`,
      operation,
      data
    );
  };

  handleAddThirdPartyVehicle = () => {
    const { claim, onUpdate } = this.props;
    return axios
      .post(`/api/v1/auto_claims/${claim.id}/third_party_vehicles`)
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleDeleteThirdPartyVehicle = (idx) => {
    const { claim, onUpdate, thirdPartyVehicles } = this.props;
    return axios
      .delete(`/api/v1/auto_claims/${claim.id}/third_party_vehicles/${thirdPartyVehicles[idx].id}`)
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleUpdateThirdPartyNonMotorist = (idx, nonMotorist) => {
    const { claim, onUpdate, thirdPartyNonMotorists } = this.props;
    const currentNonMotorist = thirdPartyNonMotorists[idx];

    return axios({
      method: currentNonMotorist.involved_non_motorist ? 'put' : 'post',
      url: `/api/v1/auto_claims/${claim.id}/third_party_non_motorists/${currentNonMotorist.id}/involved_non_motorist`,
      data: nonMotorist,
    })
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleDeleteThirdPartyNonMotorist = (idx) => {
    const { claim, onUpdate, thirdPartyNonMotorists } = this.props;
    return axios
      .delete(`/api/v1/auto_claims/${claim.id}/third_party_non_motorists/${thirdPartyNonMotorists[idx].id}`)
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleAddThirdPartyNonMotorist = (newNonMotoristParty) => {
    const { claim, onUpdate } = this.props;
    return axios
      .post(`/api/v1/auto_claims/${claim.id}/third_party_non_motorists`, newNonMotoristParty)
      .then(() => Promise.resolve(onUpdate()))
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleAddThirdPartyOtherProperty = async () => {
    const { claim, onUpdate } = this.props;

    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/third_party_other_properties`);
      return await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleUpdateThirdPartyOtherProperty = async (idx, otherPropertyParty) => {
    const { claim, thirdPartyOtherProperties, onUpdate } = this.props;
    try {
      await axios.post(
        `/api/v1/auto_claims/${claim.id}/third_party_other_properties/${thirdPartyOtherProperties[idx].id}`,
        otherPropertyParty
      );
      return await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  handleUpdateLiabilityIndicator = async (liabilityIndicator, percentageOfInsuredLiability, liabilitySummary) => {
    const { claim, onUpdate } = this.props;
    try {
      this.setState({ isFetching: true });
      await axios.patch(`/api/v1/auto_claims/${claim.id}`, {
        incident: {
          liability_indicator: liabilityIndicator,
          percentage_of_insured_liability: percentageOfInsuredLiability,
          liability_summary: liabilitySummary,
        },
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    this.setState({ isFetching: false });
  };

  render() {
    const {
      classes,
      claim,
      policy,
      firstParty,
      thirdPartyVehicles,
      thirdPartyNonMotorists,
      thirdPartyOtherProperties,
    } = this.props;

    const { isFetching } = this.state;
    return (
      <AutoInvolvedFragment
        classes={classes}
        claim={claim}
        disabled={isFetching}
        coveredVehicles={policy.covered_vehicles}
        namedDrivers={policy.named_drivers}
        firstParty={firstParty}
        thirdPartyVehicles={thirdPartyVehicles}
        thirdPartyNonMotorists={thirdPartyNonMotorists}
        thirdPartyOtherProperties={thirdPartyOtherProperties}
        onUpdateFirstPartyVehicle={this.handleUpdateFirstPartyVehicle}
        onUpdateFirstPartyNonMotorist={this.handleUpdateFirstPartyNonMotorist}
        onUpdateThirdPartyVehicle={this.handleUpdateThirdPartyVehicle}
        onAddThirdPartyVehicle={this.handleAddThirdPartyVehicle}
        onDeleteThirdPartyVehicle={this.handleDeleteThirdPartyVehicle}
        onUpdateNonMotorist={this.handleUpdateThirdPartyNonMotorist}
        onDeleteNonMotorist={this.handleDeleteThirdPartyNonMotorist}
        onAddThirdPartyNonMotorist={this.handleAddThirdPartyNonMotorist}
        onAddOtherPropertyParty={this.handleAddThirdPartyOtherProperty}
        onUpdateOtherPropertyParty={this.handleUpdateThirdPartyOtherProperty}
        onUpdateLiabilityIndicator={this.handleUpdateLiabilityIndicator}
      />
    );
  }
}

AutoInvolvedCard.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  firstParty: PropTypes.object.isRequired,
  thirdPartyVehicles: PropTypes.array.isRequired,
  thirdPartyNonMotorists: PropTypes.array.isRequired,
  thirdPartyOtherProperties: PropTypes.array.isRequired,
};

function AutoInvolvedFragment(props) {
  const {
    disabled,
    claim,
    firstParty,
    thirdPartyVehicles,
    thirdPartyNonMotorists,
    thirdPartyOtherProperties,
    onUpdateLiabilityIndicator,
    onAddThirdPartyNonMotorist,
    onAddThirdPartyVehicle,
    onAddOtherPropertyParty,
    onUpdateFirstPartyVehicle,
    onUpdateFirstPartyNonMotorist,
    onUpdateThirdPartyVehicle,
    onUpdateNonMotorist,
    onUpdateOtherPropertyParty,
  } = props;

  const classes = useStyles();

  const [showLiabilityIndicatorEditDialog, setShowLiabilityIndicatorEditDialog] = useState(false);
  const [showNewNonMotoristParty, setShowNewNonMotoristParty] = useState(false);
  const [addNewPartyAnchorEl, setAddNewPartyAnchorEl] = useState(null);

  const liabilityOptions = claim.liability_options;

  // The sorting is to keep the order of the parties constant after updates
  const thirdPartyPedestrians = thirdPartyNonMotorists
    .filter((nonMotoristParty) => nonMotoristParty.non_motorist_type === 'pedestrian')
    .sort((party1, party2) => party1.id - party2.id);
  const thirdPartyBicyclists = thirdPartyNonMotorists
    .filter((nonMotoristParty) => nonMotoristParty.non_motorist_type === 'bicyclist')
    .sort((party1, party2) => party1.id - party2.id);

  return (
    <>
      <CardDialog
        title="Involved Parties"
        action={
          <>
            <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
              <FsButton
                className={`${classes.actionButton} ${classes.button}`}
                variant="contained"
                color="primary"
                onClick={(e) => setAddNewPartyAnchorEl(e.currentTarget)}
                endIcon={<ExpandMoreIcon />}
              >
                + Add
              </FsButton>
            </RestrictedPermissions>
            <Menu
              anchorEl={addNewPartyAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              getContentAnchorEl={null}
              open={!!addNewPartyAnchorEl}
              onClose={() => setAddNewPartyAnchorEl(null)}
            >
              {[
                { title: 'Add Vehicle', thirdParty: 'a vehicle', onClick: onAddThirdPartyVehicle },
                {
                  title: 'Add Pedestrian',
                  thirdParty: 'a pedestrian',
                  onClick: () => onAddThirdPartyNonMotorist({ non_motorist_type: 'pedestrian' }),
                },
                {
                  title: 'Add Bicyclist',
                  thirdParty: 'a bicyclist',
                  onClick: () => onAddThirdPartyNonMotorist({ non_motorist_type: 'bicyclist' }),
                },
                { title: 'Add Other Property', thirdParty: 'an other property', onClick: onAddOtherPropertyParty },
              ].map(({ title, thirdParty, onClick }, idx) => (
                <WithConfirm
                  key={idx}
                  title="Are you sure?"
                  contentText={`Adding parties to the claim will cause the claim type to change. Are you sure you want to add ${thirdParty}?`}
                  primaryButtonName="Add"
                  shouldCloseOnPrimary={true}
                  disableConfirm={!(isTscClaim(claim) || isInshurClaim(claim))}
                >
                  <FsMenuItem
                    onClick={() => {
                      onClick();
                      setAddNewPartyAnchorEl(null);
                    }}
                  >
                    {title}
                  </FsMenuItem>
                </WithConfirm>
              ))}
            </Menu>
          </>
        }
      >
        {liabilityOptions.should_show_liability_indicator &&
          (claim.incident.liability_indicator || claim.incident.liability_summary ? (
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
              <Grid item>
                <ShowOnlyTextField
                  label="Liability Indicator"
                  classes={classes}
                  showOnlyValueComponent={
                    liabilityOptions.liability_indicator_options_dict[claim.incident.liability_indicator]
                  }
                  fullWidth
                  onEdit={disabled ? undefined : () => setShowLiabilityIndicatorEditDialog(true)}
                />
              </Grid>
              <Grid item>
                <ShowOnlyTextField
                  label="% of Insured Liability"
                  classes={classes}
                  showOnlyValueComponent={claim.incident.percentage_of_insured_liability || ''}
                  fullWidth
                  onEdit={disabled ? undefined : () => setShowLiabilityIndicatorEditDialog(true)}
                />
              </Grid>
              <Grid item>
                <ShowOnlyTextField
                  label="Liability Summary"
                  classes={classes}
                  showOnlyValueComponent={
                    liabilityOptions.liability_summary_type === 'select' && claim.incident.liability_summary
                      ? liabilityOptions.liability_summary_options_dict[claim.incident.liability_summary]
                      : claim.incident.liability_summary
                  }
                  fullWidth
                  onEdit={disabled ? undefined : () => setShowLiabilityIndicatorEditDialog(true)}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" direction="row" style={{ height: '100%' }}>
              <Grid item>
                <PermissionsButtonWrapper>
                  <Button color="primary" disabled={disabled} onClick={() => setShowLiabilityIndicatorEditDialog(true)}>
                    Set Liability Indicator
                  </Button>
                </PermissionsButtonWrapper>
              </Grid>
            </Grid>
          ))}
        <div className={classes.cardDivRowInternal}>
          {firstParty.type === 'vehicle_party' ? (
            <VehicleParty
              classes={classes}
              title="Insured Party"
              isInsured
              disabled={disabled}
              vehicleParty={firstParty}
              onUpdateVehicle={onUpdateFirstPartyVehicle}
              shouldIncludePassengers
            />
          ) : (
            <NonMotoristDetails
              classes={classes}
              title="Insured Party"
              disabled={disabled}
              nonMotoristParty={firstParty}
              onSaveNonMotoristDetails={async (nonMotorist) => {
                return await onUpdateFirstPartyNonMotorist(nonMotorist);
              }}
            />
          )}
        </div>

        {thirdPartyVehicles.map((vehicleParty, curIdx) => (
          <div className={classes.cardDivRowInternal} key={curIdx}>
            <VehicleParty
              classes={classes}
              title={`3rd Party Vehicle (${curIdx + 1})`}
              disabled={disabled}
              isInsured={false}
              vehicleParty={vehicleParty}
              onUpdateVehicle={(operation, data) => onUpdateThirdPartyVehicle(curIdx, operation, data)}
              shouldIncludePassengers
            />
          </div>
        ))}

        {[
          { nonMotoristType: 'pedestrian', partiesList: thirdPartyPedestrians },
          { nonMotoristType: 'bicyclist', partiesList: thirdPartyBicyclists },
        ].map(({ nonMotoristType, partiesList }) =>
          partiesList.map((nonMotoristParty, curIdx) => (
            <div className={classes.cardDivRowInternal} key={nonMotoristParty.id}>
              <NonMotoristDetails
                classes={classes}
                title={`3rd Party ${capitalize(nonMotoristType?.replace('_', ' '))} (${curIdx + 1})`}
                disabled={disabled}
                nonMotoristParty={nonMotoristParty}
                onSaveNonMotoristDetails={async (nonMotorist) => {
                  const partyNonMotoristIndex = thirdPartyNonMotorists.findIndex(
                    (thirdPartyNonMotorist) => thirdPartyNonMotorist.id === nonMotoristParty.id
                  );
                  return await onUpdateNonMotorist(partyNonMotoristIndex, nonMotorist);
                }}
              />
            </div>
          ))
        )}

        {thirdPartyOtherProperties.map((involvedPropertyParty, curIdx) => (
          <div className={classes.cardDivRowInternal} key={curIdx}>
            <InvolvedPropertyParty
              title={`3rd Party Other Property (${curIdx + 1})`}
              propertyLabel="Other Property"
              involvedPropertyParty={involvedPropertyParty}
              onSaveInvolvedPropertyDetails={(involvedPropertyParty) =>
                onUpdateOtherPropertyParty(curIdx, involvedPropertyParty)
              }
              disabled={disabled}
            />
          </div>
        ))}
      </CardDialog>
      {showLiabilityIndicatorEditDialog && (
        <LiabilityEditDialog
          incident={claim.incident}
          onSubmit={async (liabilityIndicator, percentageOfInsuredLiability, liabilitySummary) => {
            await onUpdateLiabilityIndicator(liabilityIndicator, percentageOfInsuredLiability, liabilitySummary);
            setShowLiabilityIndicatorEditDialog(false);
          }}
          onCancel={() => setShowLiabilityIndicatorEditDialog(false)}
          liabilityOptions={liabilityOptions}
        />
      )}

      {showNewNonMotoristParty && (
        <AddNonMotoristParty
          onSubmitNewNonMotorist={(newParty) => {
            onAddThirdPartyNonMotorist(newParty);
            setShowNewNonMotoristParty(false);
          }}
          onCancel={() => setShowNewNonMotoristParty(false)}
        />
      )}
    </>
  );
}

AutoInvolvedFragment.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  firstParty: PropTypes.object.isRequired,
  thirdPartyVehicles: PropTypes.array.isRequired,
  thirdPartyNonMotorists: PropTypes.array.isRequired,
  thirdPartyOtherProperties: PropTypes.array.isRequired,
  onUpdateFirstPartyVehicle: PropTypes.func.isRequired,
  onUpdateFirstPartyNonMotorist: PropTypes.func.isRequired,
  onUpdateThirdPartyVehicle: PropTypes.func.isRequired,
  onAddThirdPartyVehicle: PropTypes.func.isRequired,
  onDeleteThirdPartyVehicle: PropTypes.func.isRequired,
  onUpdateNonMotorist: PropTypes.func.isRequired,
  onDeleteNonMotorist: PropTypes.func,
  onAddThirdPartyNonMotorist: PropTypes.func.isRequired,
  onAddOtherPropertyParty: PropTypes.func.isRequired,
  onUpdateOtherPropertyParty: PropTypes.func.isRequired,
  onUpdateLiabilityIndicator: PropTypes.func.isRequired,
};

function LiabilityEditDialog({ incident, onSubmit, onCancel, liabilityOptions }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        liability_indicator: incident.liability_indicator || '',
        percentage_of_insured_liability: incident.percentage_of_insured_liability || '',
        liability_summary: incident.liability_summary || '',
      }}
      validationSchema={Yup.object().shape({
        liability_indicator: Yup.string().test(
          'is-valid_liability',
          `Liability Indicator must be one of: ${Object.values(liabilityOptions.liability_indicator_options_dict).join(
            ', '
          )}`,
          (liability_indicator) =>
            Object.keys(liabilityOptions.liability_indicator_options_dict).includes(liability_indicator)
        ),
        percentage_of_insured_liability: Yup.string(),
        liability_summary: Yup.string(),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values.liability_indicator, values.percentage_of_insured_liability, values.liability_summary);
        } finally {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            isDialog
            open
            title="Edit Liability"
            fullWidth
            maxWidth="xs"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="liability_indicator"
                  select
                  label="Liability Indicator"
                  className={classes.textField}
                  fullWidth
                >
                  {Object.keys(liabilityOptions.liability_indicator_options_dict).map((option) => (
                    <MenuItem key={option} value={option}>
                      {liabilityOptions.liability_indicator_options_dict[option]}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="percentage_of_insured_liability"
                  select
                  label="% of Insured Liability"
                  className={classes.textField}
                  fullWidth
                >
                  {AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={12}>
                {liabilityOptions.liability_summary_type === 'text' && (
                  <TextFieldFormik
                    id="liability_summary"
                    label="Liability Summary"
                    className={classes.textField}
                    fullWidth
                  />
                )}
                {liabilityOptions.liability_summary_type === 'select' && (
                  <TextFieldFormik
                    id="liability_summary"
                    label="Liability Summary"
                    className={classes.textField}
                    fullWidth
                    select
                  >
                    {Object.keys(liabilityOptions.liability_summary_options_dict).map((option) => (
                      <MenuItem key={option} value={option}>
                        {liabilityOptions.liability_summary_options_dict[option]}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                )}
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" disabled={isSubmitting} color="primary" onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

LiabilityEditDialog.propTypes = {
  incident: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  liabilityOptions: PropTypes.object.isRequired,
};

export default AutoInvolvedCard;
