import React from 'react';

import SvgIcon from '../core/SvgIcon';

const CancelIcon = (props) => (
  <SvgIcon fill="none" width="17" height="16" viewBox="0 0 17 16" {...props}>
    <path
      d="M8.5 14C11.8137 14 14.5 11.3137 14.5 8C14.5 4.68629 11.8137 2 8.5 2C5.18629 2 2.5 4.68629 2.5 8C2.5 11.3137 5.18629 14 8.5 14Z"
      strokeMiterlimit="10"
    />
    <path d="M10.5 6L6.5 10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.5 10L6.5 6" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default CancelIcon;
