import React from 'react';
import { Divider } from '@material-ui/core';

import { useClaim } from '~/components/ClaimContainer';
import type { ClaimType } from '~/components/Contacts/ContactMiniCard/types';
import type { ContactFullModel } from '~/components/Contacts/types';
import { InvolvedPersonHoverInfo } from '~/components/InvolvedPersonHoverInfo';
import { InvolvedPropertyHoverInfo } from '~/components/InvolvedPropertyHoverInfo';

interface ContactInvolvedLinksPropsType {
  contact: ContactFullModel;
}

const InvolvedLinks: React.FC<ContactInvolvedLinksPropsType> = ({ contact }) => {
  const { claim }: { claim: ClaimType } = useClaim();

  const linkComponents = [];
  if (contact.role === 'insurer') {
    linkComponents.push(
      ...claim.incident.involved_properties
        .filter((involvedProperty) => involvedProperty.insurer_contact_id === contact.id)
        .map((involvedProperty) => (
          <div key={involvedProperty.id}>
            <InvolvedPropertyHoverInfo
              claimId={claim.id}
              involvedProperty={involvedProperty}
              textualOwnerButton
              textualExtraInfo={
                involvedProperty.insurer_reference_number ? `Ref #: ${involvedProperty.insurer_reference_number}` : ''
              }
            />
          </div>
        ))
    );
  }

  if (contact.role === 'attorney') {
    linkComponents.push(
      ...claim.incident.involved_properties
        .filter((involvedProperty) => involvedProperty.attorney_contact_id === contact.id)
        .map((involvedProperty) => (
          <div key={involvedProperty.id}>
            <InvolvedPropertyHoverInfo
              claimId={claim.id}
              involvedProperty={involvedProperty}
              textualOwnerButton
              textualExtraInfo={
                involvedProperty.attorney_reference_number ? `Ref #: ${involvedProperty.attorney_reference_number}` : ''
              }
            />
          </div>
        ))
    );
    linkComponents.push(
      ...claim.incident.involved_persons
        .filter((involvedPerson) => involvedPerson.attorney_contact_id === contact.id)
        .map((involvedPerson) => (
          <div key={involvedPerson.id}>
            <InvolvedPersonHoverInfo
              claimId={claim.id}
              involvedPerson={involvedPerson}
              textualButton
              textualExtraInfo={
                involvedPerson.attorney_reference_number ? `Ref #: ${involvedPerson.attorney_reference_number}` : ''
              }
            />
          </div>
        ))
    );
  }

  linkComponents.push(
    ...claim.incident.involved_properties
      .filter((involvedProperty) => involvedProperty.owner_contact_id === contact.id)
      .map((involvedProperty) => (
        <InvolvedPropertyHoverInfo
          key={involvedProperty.id}
          claimId={claim.id}
          involvedProperty={involvedProperty}
          textualOwnerButton
        />
      ))
  );
  linkComponents.push(
    ...claim.incident.involved_persons
      .filter((involvedPerson) => involvedPerson.contact_id === contact.id)
      .map((involvedPerson) => (
        <InvolvedPersonHoverInfo
          key={involvedPerson.id}
          claimId={claim.id}
          involvedPerson={involvedPerson}
          textualButton
        />
      ))
  );

  return (
    <div className="flex">
      {linkComponents.map((link, index) => (
        <>
          {link}
          {index < linkComponents.length - 1 ? <Divider orientation="vertical" variant="middle" flexItem /> : null}
        </>
      ))}
    </div>
  );
};

export default InvolvedLinks;
