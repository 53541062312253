import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const FinancialRequestResultIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon stroke="none" fill="none" viewBox="0 0 17 16" {...props}>
    <path d="M4.75 6.5H11.25" stroke={iconColor} strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path d="M4.75 8.5H9.25" stroke={iconColor} strokeLinecap="round" strokeLinejoin="round" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 2.5C2.23478 2.5 1.98043 2.60536 1.79289 2.79289C1.60536 2.98043 1.5 3.23478 1.5 3.5V13C1.5 13.1733 1.58973 13.3342 1.73713 13.4253C1.88454 13.5164 2.06861 13.5247 2.22361 13.4472L4 12.559L5.77639 13.4472C5.91716 13.5176 6.08284 13.5176 6.22361 13.4472L8 12.559L9.50452 13.3113C9.25891 12.906 9.09325 12.4468 9.02939 11.9557L8.22361 11.5528C8.08284 11.4824 7.91716 11.4824 7.77639 11.5528L6 12.441L4.22361 11.5528C4.08284 11.4824 3.91716 11.4824 3.77639 11.5528L2.5 12.191L2.5 3.5L13.5 3.5V8.14494C13.8612 8.25244 14.198 8.41669 14.5 8.62734V3.5C14.5 3.23478 14.3946 2.98043 14.2071 2.79289C14.0196 2.60536 13.7652 2.5 13.5 2.5H2.5Z"
      fill={iconColor}
    />
    <path
      d="M12.5 15C14.433 15 16 13.433 16 11.5C16 9.567 14.433 8 12.5 8C10.567 8 9 9.567 9 11.5C9 13.433 10.567 15 12.5 15Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 10L11.9989 11.9091L11 10.9545"
      stroke={iconColor}
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

FinancialRequestResultIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default FinancialRequestResultIcon;
