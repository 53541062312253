import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import axios from 'axios';

import useInterval from '~/components/hooks/useInterval';
import { reportAxiosError } from '~/Utils';

const CallInProgressAutoSave = ({ values, initialValues, communication }) => {
  const [lastSavedValues, setLastSavedValues] = useState(initialValues);
  const updatesIntervalMilliSec = 10 * 1000;
  useInterval(async () => {
    // TODO: Add call duration?
    const updatedValues = ['content', 'summary', 'exposure_ids'].reduce(
      (acc, curr) => (isEqual(values[curr], lastSavedValues[curr]) ? acc : { ...acc, [curr]: values[curr] }),
      {}
    );
    if (Object.keys(updatedValues).length !== 0) {
      try {
        await axios.patch(`/api/v1/calls/${communication.id}`, updatedValues);
        setLastSavedValues(values);
      } catch (error) {
        reportAxiosError(error);
      }
    }
  }, updatesIntervalMilliSec);

  return <></>;
};

CallInProgressAutoSave.propTypes = {
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  communication: PropTypes.object.isRequired,
};

export default CallInProgressAutoSave;
