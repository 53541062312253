import React, { useEffect } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Paper, Tab, Tabs } from '@material-ui/core';
import urljoin from 'url-join';

import EmptyState from '~/components/core/EmptyState';
import PermissionError from '~/components/core/PermissionError/PermissionError';
import { TableauSelfService } from '~/components/ReportsDashboardsPage/tableauSelfService';

import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { getCurrentTabValue, isFeatureEnabled } from '../../Utils';
import { useCms } from '../hooks/useCms';
import { EmptyReportsIllustration } from '../illustrations';
import useOrganization from '../OrganizationContext';
import useDataFetcher from '../useDataFetcher';

import { HeaderTabs } from './HeaderTabs/HeaderTabs';
import { buildViewItem, buildViewItems, onTabClick } from './ReportsDashboardUtils';
import { useReportsDashboardsPage } from './useReportsDashboardsPage';

import { useStyles } from '../../assets/styles';
import styles from '../reportsDashboardPage.module.scss';

const ReportsDashboardPage = () => {
  const { user, userOrganization, setPageTitle } = useCms();
  const classes = useStyles();
  const match = useRouteMatch();
  const { orgTableauConfigs } = useOrganization();
  const { dashboards, overrideDashboards, overrideReports, reports, selfServiceTabName } = orgTableauConfigs;
  const { operational, financial } = dashboards || {};
  const { claims, exposures, transactions } = reports || {};

  const { isLoading, isError, data } = useDataFetcher(
    `/api/v1/tableau_users/organizations/${userOrganization.id}/users/${user.id}`
  );

  const shouldDisplayTableau = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FF_TABLEAU_REPORTS);
  const shouldDisplaySelfServiceTab = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.FF_TABLEAU_CREATOR
  );

  const {
    isPermissionsFeatureEnabled,
    isTableauAccountFeatureEnabled,
    hasReportsPermission,
    hasDashboardsPermission,
    hasOldReportsPermission,
    hasOldDashboardsPermission,
  } = useReportsDashboardsPage();

  const showReportsTab = isPermissionsFeatureEnabled || hasOldReportsPermission;
  const showDashboardsTab = isPermissionsFeatureEnabled || hasOldDashboardsPermission;
  const hasTabsToShow = showReportsTab || showDashboardsTab;

  const title =
    showDashboardsTab && showReportsTab ? 'Reports & Dashboards' : showDashboardsTab ? 'Dashboards' : 'Reports';

  useEffect(() => setPageTitle(title, `${title} - Five Sigma CMS`), [setPageTitle, title]);

  if (!shouldDisplayTableau || !hasTabsToShow) {
    return <Redirect to="/" />;
  }

  const isTableauAccount = !isLoading && !isError && data;
  const shouldShowEmptyState = isTableauAccountFeatureEnabled && !isTableauAccount;

  const dashboardTabs = [
    buildViewItem({
      label: 'Operational',
      url: 'operational',
      workbook: operational?.workbook,
      view: operational?.view,
    }),
    buildViewItem({
      label: 'Financial Trends',
      url: 'financial',
      workbook: financial?.workbook,
      view: financial?.view,
    }),
    ...buildViewItems({ overrideViewConfiguration: overrideDashboards }),
  ];

  const reportsTabs = [
    buildViewItem({
      label: 'Claims',
      url: 'claims',
      workbook: claims?.workbook,
      view: claims?.view,
    }),
    buildViewItem({
      label: 'Exposures',
      url: 'exposures',
      workbook: exposures?.workbook,
      view: exposures?.view,
    }),
    buildViewItem({
      label: 'Transactions',
      url: 'transactions',
      workbook: transactions?.workbook,
      view: transactions?.view,
    }),
    ...buildViewItems({ overrideViewConfiguration: overrideReports }),
  ];

  const tabs = [
    {
      label: 'Dashboards',
      url: 'dashboards',
      component: HeaderTabs,
      props: { tabs: dashboardTabs },
      shouldDisplayTab: showDashboardsTab,
      hasPermissions: hasDashboardsPermission,
    },
    {
      label: 'Reports',
      url: 'reports',
      component: HeaderTabs,
      props: { tabs: reportsTabs },
      shouldDisplayTab: showReportsTab,
      hasPermissions: hasReportsPermission,
    },
    {
      label: selfServiceTabName ? selfServiceTabName : 'Self Service',
      url: 'self-service',
      component: () => <TableauSelfService />,
      shouldDisplayTab: shouldDisplaySelfServiceTab,
      hasPermissions: shouldDisplaySelfServiceTab,
      props: {},
    },
  ].filter((tab) => tab.shouldDisplayTab);

  return (
    <>
      <Paper className="flex justify-between bg-slate-200">
        <Tabs value={getCurrentTabValue({ tabs, match })} style={{ minHeight: 40 }} variant="scrollable">
          {tabs.map((tab) => (
            <Tab
              style={{
                minHeight: 40,
                padding: 0,
              }}
              key={tab.url}
              label={tab.label}
              component={Link}
              to={`${match.url}/${tab.url}`}
              onClick={onTabClick}
            />
          ))}
        </Tabs>
      </Paper>
      <div className={classes.pageBody}>
        <Switch>
          <Redirect exact from={`${match.path}/`} to={urljoin(match.path, tabs[0]?.url ?? '')} />
          {tabs.map((tab) => (
            <Route
              key={tab.url}
              path={urljoin(match.path, tab.url)}
              render={() => {
                if (isPermissionsFeatureEnabled && !tab.hasPermissions) {
                  return (
                    <div className={styles.missingPermissionsWarning}>
                      <PermissionError />
                    </div>
                  );
                }

                if (shouldShowEmptyState) {
                  return (
                    <EmptyState
                      title="This Feature is available for users with Tableau account access."
                      subtitle="Please contact your admin to upgrade"
                      illustration={<EmptyReportsIllustration />}
                      className={styles.emptyStateContainer}
                    />
                  );
                }

                return <tab.component {...tab.props} />;
              }}
            />
          ))}
          ;
          <Redirect to={match.path} />
        </Switch>
      </div>
    </>
  );
};

export default ReportsDashboardPage;
