import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const SmsErrorIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      d="M4.8375 11.952L2.81875 13.6395C2.74591 13.6998 2.65746 13.7382 2.56369 13.7502C2.46991 13.7623 2.37463 13.7475 2.28892 13.7076C2.20321 13.6676 2.13057 13.6042 2.07943 13.5247C2.02829 13.4452 2.00075 13.3528 2 13.2583V3.33325C2 3.20064 2.05268 3.07347 2.14645 2.9797C2.24021 2.88593 2.36739 2.83325 2.5 2.83325H13.5C13.6326 2.83325 13.7598 2.88593 13.8536 2.9797C13.9473 3.07347 14 3.20064 14 3.33325V11.3333C14 11.4659 13.9473 11.593 13.8536 11.6868C13.7598 11.7806 13.6326 11.8333 13.5 11.8333H5.15625C5.03919 11.8333 4.92605 11.8754 4.8375 11.952V11.952Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <mask id="path-2-inside-1_732_14462" fill="white">
      <path d="M8 8.08325C8.41421 8.08325 8.75 7.74747 8.75 7.33325C8.75 6.91904 8.41421 6.58325 8 6.58325C7.58579 6.58325 7.25 6.91904 7.25 7.33325C7.25 7.74747 7.58579 8.08325 8 8.08325Z" />
    </mask>
    <path
      d="M8 8.08325C8.41421 8.08325 8.75 7.74747 8.75 7.33325C8.75 6.91904 8.41421 6.58325 8 6.58325C7.58579 6.58325 7.25 6.91904 7.25 7.33325C7.25 7.74747 7.58579 8.08325 8 8.08325Z"
      fill={iconColor}
    />
    <path
      d="M7.25 7.33325C7.25 6.91904 7.58579 6.58325 8 6.58325V9.58325C9.24264 9.58325 10.25 8.57589 10.25 7.33325H7.25ZM8 6.58325C8.41421 6.58325 8.75 6.91904 8.75 7.33325H5.75C5.75 8.57589 6.75736 9.58325 8 9.58325V6.58325ZM8.75 7.33325C8.75 7.74747 8.41421 8.08325 8 8.08325V5.08325C6.75736 5.08325 5.75 6.09061 5.75 7.33325H8.75ZM8 8.08325C7.58579 8.08325 7.25 7.74747 7.25 7.33325H10.25C10.25 6.09061 9.24264 5.08325 8 5.08325V8.08325Z"
      fill={iconColor}
      mask="url(#path-2-inside-1_732_14462)"
    />
    <mask id="path-4-inside-2_732_14462" fill="white">
      <path d="M5 8.08325C5.41421 8.08325 5.75 7.74747 5.75 7.33325C5.75 6.91904 5.41421 6.58325 5 6.58325C4.58579 6.58325 4.25 6.91904 4.25 7.33325C4.25 7.74747 4.58579 8.08325 5 8.08325Z" />
    </mask>
    <path
      d="M5 8.08325C5.41421 8.08325 5.75 7.74747 5.75 7.33325C5.75 6.91904 5.41421 6.58325 5 6.58325C4.58579 6.58325 4.25 6.91904 4.25 7.33325C4.25 7.74747 4.58579 8.08325 5 8.08325Z"
      fill={iconColor}
    />
    <path
      d="M4.25 7.33325C4.25 6.91904 4.58579 6.58325 5 6.58325V9.58325C6.24264 9.58325 7.25 8.57589 7.25 7.33325H4.25ZM5 6.58325C5.41421 6.58325 5.75 6.91904 5.75 7.33325H2.75C2.75 8.57589 3.75736 9.58325 5 9.58325V6.58325ZM5.75 7.33325C5.75 7.74747 5.41421 8.08325 5 8.08325V5.08325C3.75736 5.08325 2.75 6.09061 2.75 7.33325H5.75ZM5 8.08325C4.58579 8.08325 4.25 7.74747 4.25 7.33325H7.25C7.25 6.09061 6.24264 5.08325 5 5.08325V8.08325Z"
      fill={iconColor}
      mask="url(#path-4-inside-2_732_14462)"
    />
    <mask id="path-6-inside-3_732_14462" fill="white">
      <path d="M11 8.08325C11.4142 8.08325 11.75 7.74747 11.75 7.33325C11.75 6.91904 11.4142 6.58325 11 6.58325C10.5858 6.58325 10.25 6.91904 10.25 7.33325C10.25 7.74747 10.5858 8.08325 11 8.08325Z" />
    </mask>
    <path
      d="M11 8.08325C11.4142 8.08325 11.75 7.74747 11.75 7.33325C11.75 6.91904 11.4142 6.58325 11 6.58325C10.5858 6.58325 10.25 6.91904 10.25 7.33325C10.25 7.74747 10.5858 8.08325 11 8.08325Z"
      fill={iconColor}
    />
    <path
      d="M10.25 7.33325C10.25 6.91904 10.5858 6.58325 11 6.58325V9.58325C12.2426 9.58325 13.25 8.57589 13.25 7.33325H10.25ZM11 6.58325C11.4142 6.58325 11.75 6.91904 11.75 7.33325H8.75C8.75 8.57589 9.75736 9.58325 11 9.58325V6.58325ZM11.75 7.33325C11.75 7.74747 11.4142 8.08325 11 8.08325V5.08325C9.75736 5.08325 8.75 6.09061 8.75 7.33325H11.75ZM11 8.08325C10.5858 8.08325 10.25 7.74747 10.25 7.33325H13.25C13.25 6.09061 12.2426 5.08325 11 5.08325V8.08325Z"
      fill={iconColor}
      mask="url(#path-6-inside-3_732_14462)"
    />
    <path
      d="M12.6665 14C14.3234 14 15.6665 12.6569 15.6665 11C15.6665 9.34315 14.3234 8 12.6665 8C11.0096 8 9.6665 9.34315 9.6665 11C9.6665 12.6569 11.0096 14 12.6665 14Z"
      fill="#F44336"
    />
    <path d="M11.3618 12.281L14.0001 9.74778" stroke="white" strokeLinecap="round" />
    <path d="M11.3618 9.66675L13.9169 12.2832" stroke="white" strokeLinecap="round" />
  </SvgIcon>
);

SmsErrorIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default SmsErrorIcon;
