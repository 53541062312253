import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import { ClaimContextProvider } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import SendSmsCommunicationCardContainer from '~/components/communications/SmsCommunicationCard/SmsCommunicationCard';
import CommunicationPopoverLoader from '~/components/Contacts/ContactCommunications/CommunicationPopoverLoader';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import { useCms } from '~/components/hooks/useCms';
import SmsIcon from '~/components/icons/notifications/SmsIcon';
import InlineIconButton from '~/components/InlineIconButton';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { useFetchClaim } from '~/Utils/ClaimUtils';
import cn from '~/Utils/cn';

const SmsCommunicationPopover = ({
  contact,
  onUpdate,
  communicationDetails = {},
  mixpanelSource,
  ignorePermissions = false,
  useIconButton = false,
  messageContent,
  claimId,
}) => {
  const classes = useStyles();
  const { user } = useCms();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [claim, isLoadingClaim, isError, onClaimUpdate] = useFetchClaim(claimId);
  const handleOpenDialog = () => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_SMS_COMMUNICATION_CLICKED, { source: mixpanelSource });
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const isDisabled = !user.is_sending_sms_allowed || contact.is_smsing_allowed === false || user.role.is_view_only;
  return (
    <>
      <InlineIconButton
        icon={SmsIcon}
        onClick={handleOpenDialog}
        disabled={isDisabled}
        className={cn({ [classes.hoverableNonFilledIcon]: !isDisabled })}
        ignorePermissions={ignorePermissions}
        useIconButton={useIconButton}
      />
      {isDialogOpen && (
        <LoadingSwitch isLoading={isLoadingClaim} isError={isError} LoadingComponent={CommunicationPopoverLoader}>
          <ClaimContextProvider refreshData={onClaimUpdate} claim={claim}>
            <SendSmsCommunicationCardContainer
              contact={contact}
              contactPhoneId={communicationDetails.id}
              onCancel={handleCloseDialog}
              onSendSms={() => {
                handleCloseDialog();
                if (onUpdate) {
                  onUpdate();
                }
              }}
              messageContent={messageContent}
            />
          </ClaimContextProvider>
        </LoadingSwitch>
      )}
    </>
  );
};

SmsCommunicationPopover.propTypes = {
  contact: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  communicationDetails: PropTypes.object,
  mixpanelSource: PropTypes.string,
  ignorePermissions: PropTypes.bool,
  useIconButton: PropTypes.bool,
  messageContent: PropTypes.string,
  claimId: PropTypes.number,
};

export default SmsCommunicationPopover;
