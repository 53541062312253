import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import InvolvedInsurerFields from '~/components/Fnol/NewFnolUI/InvolvedParties/InvolvedInsurerFields';
import LoadingDialog from '~/components/LoadingDialog';

import CardDialog from '../../../../CardDialog';
import WithConfirm from '../../../../ConfirmModal';
import { ContactEntity } from '../../../../Contact';
import {
  FsButton,
  FsIconButton,
  PERMISSION_ACTIONS,
  PERMISSION_VERBS,
  PermissionsButtonWrapper,
  RestrictedPermissions,
} from '../../../../core';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { TrashIcon } from '../../../../icons';
import { PreDefinedField, preparePreDefinedFields } from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';
import { involvedPersonFields } from '../../../../InvolvedPerson';
import InvolvedWrapper from '../../../../InvolvedWrapper';
import { usePolicy } from '../../../../PolicyContainer';
import IcdCodesFragment from '../IcdCodesFragment';

import BodilyInjuriesFragment from './BodilyInjuriesFragment';

import styles from '../index.module.scss';

const getInvolvedDriverPreDefinedFields = (incidentConfiguration) => {
  const preDefinedFields = {
    ...incidentConfiguration.involved_parties.involved_person,
    ...incidentConfiguration.involved_parties.involved_driver,
  };

  return preparePreDefinedFields(preDefinedFields);
};

const getInvolvedDriverValidationFields = (incidentConfiguration) =>
  getAdditionalDataValidations(getInvolvedDriverPreDefinedFields(incidentConfiguration));

const driverInitialValues = {
  ...involvedPersonFields,
  named_driver_id: '',
  license_number: '',
  license_type: '',
  license_issuing_state: '',
  license_issue_date: '',
  is_work_related_accident: '',
  contact_id: '',
  contact_full_name: '',
  contact: null,
  position: '',
  was_seat_belt_worn: '',
  was_ambulance_needed: '',
  was_hospital_attended: '',
  injuries: [],
  injuries_description: '',
  attorney_contact_id: '',
  attorney_contact_full_name: '',
  representative_contact_id: '',
  representative_contact_full_name: '',
  attorney_reference_number: '',
  note: '',
  is_medicare_eligible: null,
  medicare_number: '',
  hospitals: [],
  was_hospitalized: '',
  hospital_contact_id: '',
  admission_datetime: '',
  discharge_datetime: '',
  any_dvla_medical_conditions: '',
  dvla_medical_conditions_description: '',
};

const DriverInvolvedFragment = ({ showOnly, onSelectContact }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInvolvedDriverPreDefinedFields(incidentConfiguration);

  return (
    <Grid container alignItems="center" spacing={1}>
      <PreDefinedField
        id="contact_id"
        fields={preDefinedFields}
        inline
        readOnly={showOnly}
        fixedSearchResults
        onSelectContact={onSelectContact}
      />
      <PreDefinedField id="relationship_to_policyholder" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="license_type" fields={preDefinedFields} inline readOnly={showOnly} gridXs={4} />
      <PreDefinedField id="license_number" fields={preDefinedFields} inline gridXs={4} readOnly={showOnly} />
      <PreDefinedField id="license_issue_date" fields={preDefinedFields} inline readOnly={showOnly} gridXs={4} />
      <PreDefinedField id="license_issuing_state" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="years_of_driving_experience" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="was_seat_belt_worn" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="was_ambulance_needed" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="was_hospitalized" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField
        id="hospital_contact_id"
        fields={preDefinedFields}
        inline
        readOnly={showOnly}
        fixedSearchResults
        gridXs={4}
      />
      <PreDefinedField id="admission_datetime" fields={preDefinedFields} inline readOnly={showOnly} gridXs={4} />
      <PreDefinedField id="discharge_datetime" fields={preDefinedFields} inline readOnly={showOnly} gridXs={4} />
      <PreDefinedField id="any_dvla_medical_conditions" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="dvla_medical_conditions_description" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField
        id="attorney_contact_id"
        fields={preDefinedFields}
        inline
        readOnly={showOnly}
        fixedSearchResults
      />
      <PreDefinedField id="attorney_reference_number" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField
        id="representative_contact_id"
        fields={preDefinedFields}
        inline
        readOnly={showOnly}
        fixedSearchResults
        newRowAfter
      />
      <InvolvedInsurerFields preDefinedFields={preDefinedFields} showOnly={showOnly} />
      <PreDefinedField id="legal_venue_state" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="legal_venue_reason" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="injury_date" fields={preDefinedFields} inline readOnly={showOnly} />
      <BodilyInjuriesFragment showOnly={showOnly} />
      <PreDefinedField id="injuries_description" fields={preDefinedFields} inline readOnly={showOnly} gridXs={12} />
      <IcdCodesFragment showOnly={showOnly} fields={preDefinedFields} />
      <PreDefinedField id="note" fields={preDefinedFields} inline readOnly={showOnly} />
    </Grid>
  );
};

DriverInvolvedFragment.propTypes = {
  disabled: PropTypes.bool,
  isInsured: PropTypes.bool,
  disableEditIdentity: PropTypes.bool,
  showOnly: PropTypes.bool,
  onSelectContact: PropTypes.func,
};

const InvolvedDriverDialog = ({
  dialogAction,
  disableEditIdentity,
  driver,
  onCancel,
  onSubmit,
  open,
  title,
  showOnly,
  isInsured,
}) => {
  const { policy } = usePolicy();
  const { incidentConfiguration } = useIncidentConfiguration();

  if (!open) {
    return <></>;
  }
  const clonedDriverInitialValues = { ...driverInitialValues };
  if (isInsured && isEmpty(driver) && !isEmpty(policy?.named_drivers) && !clonedDriverInitialValues.contact_id) {
    clonedDriverInitialValues.contact = policy?.named_drivers[0];
    clonedDriverInitialValues.contact_id = policy?.named_drivers[0].id;
    clonedDriverInitialValues.license_issue_date =
      clonedDriverInitialValues.contact?.contact_extra.driving_license_issue_date;
    clonedDriverInitialValues.license_issuing_state =
      clonedDriverInitialValues.contact?.contact_extra.driving_license_state;
    clonedDriverInitialValues.license_number = clonedDriverInitialValues.contact?.contact_extra.driving_license_number;
  }

  return (
    <Formik
      initialValues={driver ? driver : clonedDriverInitialValues}
      validationSchema={Yup.object().shape({ ...getInvolvedDriverValidationFields(incidentConfiguration) })}
      onSubmit={(values, formikProps) => {
        onSubmit(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, setFieldValue } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title={title}
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
            action={dialogAction}
            footerActions={
              !showOnly && (
                <DialogFooterActions
                  disabled={isSubmitting}
                  onClickPrimary={handleSubmit}
                  onClickSecondary={onCancel}
                />
              )
            }
          >
            <DriverInvolvedFragment
              disableEditIdentity={disableEditIdentity}
              showOnly={showOnly}
              isInsured={isInsured}
              onSelectContact={(contact) => {
                if (contact?.contact_extra?.driving_license_issue_date) {
                  setFieldValue('license_issue_date', contact?.contact_extra?.driving_license_issue_date);
                }
                if (contact?.contact_extra?.driving_license_state) {
                  setFieldValue('license_issuing_state', contact?.contact_extra?.driving_license_state);
                }
                if (contact?.contact_extra?.driving_license_number) {
                  setFieldValue('license_number', contact?.contact_extra?.driving_license_number);
                }
              }}
            />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

InvolvedDriverDialog.propTypes = {
  dialogAction: PropTypes.node,
  disableEditIdentity: PropTypes.bool,
  driver: PropTypes.object,
  isInsured: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

function AddDriverInvolvedDialog(props) {
  const {
    open,
    onCancel,
    onSaveDriverDetails,
    isInsured,
    CustomInvolvedPersonFragment,
    customInitialFields,
    customValidationFields,
  } = props;

  return (
    <InvolvedDriverDialog
      isInsured={isInsured}
      onCancel={onCancel}
      onSubmit={onSaveDriverDetails}
      open={open}
      title="Set Driver"
      CustomInvolvedPersonFragment={CustomInvolvedPersonFragment}
      customInitialFields={customInitialFields}
      customValidationFields={customValidationFields}
    />
  );
}

AddDriverInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveDriverDetails: PropTypes.func.isRequired,
  isInsured: PropTypes.bool,
  CustomInvolvedPersonFragment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  customInitialFields: PropTypes.object,
  customValidationFields: PropTypes.object,
};

function EditDriverInvolvedDialog(props) {
  const {
    open,
    driver,
    onCancel,
    onSaveDriverDetails,
    isInsured,
    onDeleteDriver,
    CustomInvolvedPersonFragment,
    customInitialFields,
    customValidationFields,
  } = props;

  const dialogAction = onDeleteDriver && (
    <PermissionsButtonWrapper verb={PERMISSION_VERBS.FULL} action={PERMISSION_ACTIONS.CONTACT}>
      <WithConfirm title="Delete Driver?" primaryButtonName="Delete" shouldCloseOnPrimary={false}>
        <FsIconButton onClick={onDeleteDriver} icon={TrashIcon} />
      </WithConfirm>
    </PermissionsButtonWrapper>
  );

  return (
    <InvolvedDriverDialog
      disableEditIdentity={driver.is_locked}
      dialogAction={dialogAction}
      driver={driver}
      isInsured={isInsured}
      onCancel={onCancel}
      onSubmit={onSaveDriverDetails}
      open={open}
      title="Edit Driver"
      CustomInvolvedPersonFragment={CustomInvolvedPersonFragment}
      customInitialFields={customInitialFields}
      customValidationFields={customValidationFields}
    />
  );
}

EditDriverInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  driver: PropTypes.object.isRequired,
  isInsured: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveDriverDetails: PropTypes.func.isRequired,
  onDeleteDriver: PropTypes.func,
  CustomInvolvedPersonFragment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  customInitialFields: PropTypes.object,
  customValidationFields: PropTypes.object,
};

function ShowOnlyDriverInvolvedDialog(props) {
  const { driver, open, onCancel, isInsured } = props;

  return (
    <InvolvedDriverDialog
      driver={driver}
      isInsured={isInsured}
      onCancel={onCancel}
      open={open}
      title="Driver Details"
      showOnly
    />
  );
}

ShowOnlyDriverInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  driver: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  isInsured: PropTypes.bool,
};

function DriverDetails(props) {
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [involvedFetched, setInvolvedFetched] = React.useState();

  const {
    classes,
    driver,
    disabled,
    isInsured,
    onFetchDriverDetails,
    onSetDriverDetails,
    onSaveDriverDetails,
    onDeleteDriver,
    error,
  } = props;

  const openEditDialog = async () => {
    setEditDialogOpen(true);
    try {
      const driverInvolved = await onFetchDriverDetails();
      setInvolvedFetched(driverInvolved);
    } catch {
      setEditDialogOpen(false);
    }
  };
  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setInvolvedFetched(undefined);
  };
  const handleUpdateDriverDetails = async (values) => {
    await onSaveDriverDetails(values);
    closeEditDialog();
  };
  const handleAddDriver = async (values) => {
    await onSetDriverDetails(values);
    setAddDialogOpen(false);
  };
  const handleDeleteDriver = async () => {
    await onDeleteDriver();
    closeEditDialog();
  };

  return (
    <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
      <div>
        {driver ? (
          <InvolvedWrapper onEdit={openEditDialog} disabled={disabled} involved={driver} involvedType="person">
            <div className={classes.containerCentered}>
              <ContactEntity
                contactId={driver.contact_id === '' ? null : driver.contact_id}
                contactDisplayName={driver.contact_full_name}
              />
              <Typography style={{ paddingLeft: '8px' }} display="inline" variant="caption">
                Driver
              </Typography>
            </div>
          </InvolvedWrapper>
        ) : (
          <div className={styles.buttonWrapper}>
            <FsButton
              color="primary"
              className={styles.marginedSetButton}
              disabled={disabled}
              onClick={() => setAddDialogOpen(true)}
            >
              Set Driver
            </FsButton>
          </div>
        )}
        {error && <FormHelperText error>{error}</FormHelperText>}
      </div>

      {editDialogOpen &&
        (!involvedFetched ? (
          <LoadingDialog isError={false} track="EditDriverInvolvedDialog" />
        ) : (
          <EditDriverInvolvedDialog
            classes={classes}
            driver={involvedFetched}
            open
            onSaveDriverDetails={handleUpdateDriverDetails}
            onDeleteDriver={onDeleteDriver ? handleDeleteDriver : undefined}
            onCancel={closeEditDialog}
            isInsured={isInsured}
          />
        ))}
      <AddDriverInvolvedDialog
        open={addDialogOpen}
        onCancel={() => setAddDialogOpen(false)}
        onSaveDriverDetails={handleAddDriver}
        isInsured={isInsured}
      />
    </RestrictedPermissions>
  );
}

DriverDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isInsured: PropTypes.bool,
  driver: PropTypes.object,
  onFetchDriverDetails: PropTypes.func.isRequired,
  onSetDriverDetails: PropTypes.func.isRequired,
  onSaveDriverDetails: PropTypes.func.isRequired,
  onDeleteDriver: PropTypes.func,
  error: PropTypes.string,
};

export { AddDriverInvolvedDialog, DriverDetails, EditDriverInvolvedDialog, ShowOnlyDriverInvolvedDialog };
