import React from 'react';

import SvgIcon from '../../core/SvgIcon';
import type { IconProps } from '../types';

import { DEFAULT_ICON_PROPS } from './types';

const StrikeIcon: React.FC<IconProps> = ({
  iconColor = DEFAULT_ICON_PROPS.iconColor,
  className = DEFAULT_ICON_PROPS.className,
  size = DEFAULT_ICON_PROPS.size,
  disabled = DEFAULT_ICON_PROPS.disabled,
}) => (
  <SvgIcon
    disableStrokeColor
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    disabled={disabled}
    className={className}
  >
    <path
      d="M21 12.5C21 12.6989 20.921 12.8897 20.7803 13.0303C20.6397 13.171 20.4489 13.25 20.25 13.25H16.4934C17.355 13.9166 18 14.8625 18 16.25C18 17.5006 17.3438 18.6594 16.1484 19.5116C15.0309 20.3103 13.5572 20.75 12 20.75C10.4428 20.75 8.96906 20.3103 7.85156 19.5116C6.65625 18.6594 6 17.5006 6 16.25C6 16.0511 6.07902 15.8603 6.21967 15.7197C6.36032 15.579 6.55109 15.5 6.75 15.5C6.94891 15.5 7.13968 15.579 7.28033 15.7197C7.42098 15.8603 7.5 16.0511 7.5 16.25C7.5 17.8766 9.5625 19.25 12 19.25C14.4375 19.25 16.5 17.8766 16.5 16.25C16.5 14.8578 15.5119 14.0394 12.8653 13.25H3.75C3.55109 13.25 3.36032 13.171 3.21967 13.0303C3.07902 12.8897 3 12.6989 3 12.5C3 12.3011 3.07902 12.1103 3.21967 11.9697C3.36032 11.829 3.55109 11.75 3.75 11.75H20.25C20.4489 11.75 20.6397 11.829 20.7803 11.9697C20.921 12.1103 21 12.3011 21 12.5ZM7.15594 10.25C7.2745 10.2501 7.3914 10.2221 7.49705 10.1683C7.6027 10.1145 7.69408 10.0364 7.76371 9.94041C7.83333 9.84443 7.87921 9.73333 7.89757 9.61619C7.91594 9.49906 7.90628 9.37924 7.86938 9.26656C7.8157 9.09971 7.78942 8.92526 7.79156 8.75C7.79156 7.04 9.60094 5.75 12 5.75C13.7662 5.75 15.2025 6.44562 15.8438 7.61094C15.945 7.77413 16.1051 7.89223 16.2909 7.94091C16.4767 7.98958 16.6741 7.96512 16.8424 7.87255C17.0107 7.77999 17.1371 7.62638 17.1955 7.4434C17.2539 7.26042 17.2398 7.062 17.1562 6.88906C16.2497 5.23625 14.3222 4.25 12 4.25C8.74594 4.25 6.29156 6.18406 6.29156 8.75C6.28997 9.08377 6.34123 9.41569 6.44344 9.73344C6.49263 9.88363 6.58801 10.0144 6.71596 10.1072C6.84391 10.2 6.9979 10.2499 7.15594 10.25Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default StrikeIcon;
