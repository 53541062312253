import React from 'react';

import { WarningCircleIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';

const priorityToColorMap: Record<string, string> = {
  high: colors.status.failed,
  medium: colors.status.warning,
  low: colors.status.done,
};

export const LegalActionPriorityIcon: React.FC<{ priority: string; className?: string }> = ({
  priority,
  className,
}) => <WarningCircleIcon className={className} size={20} iconColor={priorityToColorMap[priority]} />;
