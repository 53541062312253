import React, { Component } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';

import Tooltip from '~/components/core/Atomic/Tooltip';

class DisableableToolTip extends Component {
  state = {
    tooltipOpen: false,
  };

  render() {
    const { children, disabled, title, placement } = this.props;
    const { tooltipOpen } = this.state;

    return (
      <Tooltip
        placement={placement}
        open={disabled && tooltipOpen}
        onClose={() => this.setState({ tooltipOpen: false })}
        onOpen={() => this.setState({ tooltipOpen: true })}
        title={title || ''}
      >
        <span>{children}</span>
      </Tooltip>
    );
  }
}

DisableableToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired, // here disabled means that the underlying note is disabled and thus DisableableToolTip should be active
  placement: PropTypes.string,
  title: requiredIf(PropTypes.string, (props) => props.disabled),
};

export default DisableableToolTip;
