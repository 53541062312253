import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import Typography from '~/components/core/Atomic/Typography';

function TitleWithColoredIndent({ title, colorPalette, colorType }) {
  const theme = useTheme();
  const backgroundColor = theme.palette[colorPalette]?.[colorType] || theme.palette.primary.main;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ width: '6px', height: '12px', backgroundColor, display: 'inline-block', marginRight: '4px' }} />
      <Typography
        display="block"
        variant="subtitle1"
        style={{ marginRight: '10px', fontWeight: 550, letterSpacing: '0px', fontSize: '14px' }}
      >
        {title}
      </Typography>
    </div>
  );
}

TitleWithColoredIndent.propTypes = {
  title: PropTypes.string.isRequired,
  colorPalette: PropTypes.string.isRequired,
  colorType: PropTypes.string.isRequired,
};

export default TitleWithColoredIndent;
