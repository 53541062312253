import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import ItalicIcon from '../../../../icons/editor/ItalicIcon';
import ToolbarButton from '../ToolbarButton';

const Italic: React.FC = () => {
  const { editor } = useCurrentEditor();

  const toggle = () => {
    editor?.chain().focus().toggleItalic().run();
  };

  return (
    <ToolbarButton onClick={toggle} disabled={!editor?.can().toggleItalic()} isActive={editor?.isActive('italic')}>
      <ItalicIcon />
    </ToolbarButton>
  );
};

export default Italic;
