import React from 'react';

import SvgIcon from '../core/SvgIcon';

const CheckIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path d="M6.75012 12.1274L3.62262 8.99988L2.55762 10.0574L6.75012 14.2499L15.7501 5.24988L14.6926 4.19238L6.75012 12.1274Z" />
  </SvgIcon>
);

export default CheckIcon;
