const MOI_STATUSES = {
  pending: 'Pending',
  done: 'Done',
  rejected: 'Rejected',
  called_off: 'Called Off',
  completed: 'For Review',
  accepted: 'In Progress',
} as const;

export default MOI_STATUSES;
