import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import { useFormikContext } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';

import ClaimLink from './ClaimLink';
import ClaimSearchContainer from './ClaimSearch';
import HoverActionField from './HoverActionField';
import { ShowOnlyTextField } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function ClaimSelectTextFieldFormik(props) {
  const { id, label, disabled } = props;
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const displayId = id + '_display';

  return values[displayId] ? (
    <HoverActionField
      icon={CancelIcon}
      disabled={disabled}
      onAction={() => {
        setFieldValue(id, undefined);
        setFieldValue(displayId, undefined);
      }}
    >
      <ShowOnlyTextField
        classes={classes}
        label="Claim"
        showOnlyValueComponent={<ClaimLink claimId={values[id]} linkText={values[displayId]} openInNewTab />}
        doNotRenderInTypography
      />
    </HoverActionField>
  ) : (
    <ClaimSearchContainer
      classes={classes}
      SelectComponent={({ claim }) => (
        <Button
          color="primary"
          disabled={disabled}
          onClick={() => {
            setFieldValue(id, claim.id);
            setFieldValue(displayId, claim.claim_id_display);
          }}
        >
          Select Claim
        </Button>
      )}
      label={label}
      shouldOpenClaimInNewTab
    />
  );
}

ClaimSelectTextFieldFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ClaimSelectTextFieldFormik;
