import React from 'react';
import { Collapse } from '@material-ui/core';

import type { MessageActionsProps } from '~/components/AiChat/components/Message/MessageActions';
import { MessageActions } from '~/components/AiChat/components/Message/MessageActions';
import { SIDEBAR_DIALOG_CONFIG } from '~/components/AiChat/SideBarDialog/SidebarDialogConstants';
import { FsIconButton, LoadingSwitch, Text } from '~/components/core';
import { useCollapsible } from '~/components/core/Molecules/Collapsible/useCollapsible';
import cn from '~/Utils/cn';

export const ClaimSummaryCollapsible: React.FC<{
  isCollapsible: boolean;
  isOpen: boolean;
  summary?: string;
  actions: Omit<MessageActionsProps, 'type'>;
  isChatLoading: boolean;
}> = ({ summary, actions, isCollapsible, isOpen, isChatLoading }) => {
  const { isOpenCombined, onClick, getChevron } = useCollapsible({
    collapsible: isCollapsible,
    openByDefault: false,
    isOpen: isCollapsible ? undefined : isOpen,
  });

  return (
    <div className={cn(SIDEBAR_DIALOG_CONFIG.paddingX, 'relative pb-32')}>
      <div
        className="flex flex-col rounded-[10px] bg-white"
        style={{
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <div
          className={cn(
            'row-auto flex items-center justify-between border-0 border-solid border-b-aiV2-fadedWhite px-24 py-16',
            {
              'border-b-1': isOpenCombined,
            }
          )}
        >
          <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} className="text-[#23376C]">
            Claim Summary
          </Text>
          {isCollapsible ? (
            <FsIconButton onClick={onClick} className="bg-aiV2-fadedWhite">
              {getChevron({ className: 'w-[18px] h-[18px]' })}
            </FsIconButton>
          ) : null}
        </div>
        <Collapse in={isOpenCombined} timeout="auto" unmountOnExit>
          <div className="flex flex-col justify-between gap-20 px-24 pb-16 pt-12">
            <LoadingSwitch isLoading={isChatLoading}>
              <div>{summary}</div>
              <MessageActions type="filled" onCopy={actions.onCopy} />
            </LoadingSwitch>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
