import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportErrorInProductionOrThrow } from '../Utils';

import { AutoIncidentDetailsCard } from './Auto/AutoIncidentDetails';
import { HomeIncidentDetailsCard } from './Home/HomeIncidentDetails';
import { withClaim } from './ClaimContainer';
import NotesSummary from './NotesSummary';

function ClaimSummaryPhoneRep(props) {
  const { classes, claim, ...rest } = props;
  const claimType = claim.type;

  let incidentDetailsCard = null;
  if (claimType === 'home_claim')
    incidentDetailsCard = <HomeIncidentDetailsCard classes={classes} claim={claim} readOnly {...rest} />;
  else if (claimType === 'auto_claim')
    incidentDetailsCard = <AutoIncidentDetailsCard classes={classes} claim={claim} readOnly {...rest} />;
  else reportErrorInProductionOrThrow(`${claimType} is not supported for phone rep`);

  return (
    <Fragment>
      <Grid container>
        <Grid item md={4}>
          <div className={classes.cardDivRow}>{incidentDetailsCard}</div>
        </Grid>
        <Grid item md={8}>
          <div className={classes.cardDivRow}>
            <NotesSummary classes={classes} claim={claim} onNoteClick={() => {}} />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}

ClaimSummaryPhoneRep.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired, // from claim context
};

export default withClaim(ClaimSummaryPhoneRep);
