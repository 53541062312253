import React from 'react';
import PropTypes from 'prop-types';
import LockIcon from '@material-ui/icons/Lock';

import Tooltip from '~/components/core/Atomic/Tooltip';

import InlineIconButton from './InlineIconButton';

import { useStyles } from '../assets/styles';

function TooltipLock(props) {
  const classes = useStyles();
  const { title } = props;
  const [isMouseOver, setIsMouseOver] = React.useState(false);

  return (
    <div onMouseOver={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)} style={{ width: '100%' }}>
      <Tooltip title={title} open={isMouseOver}>
        <span
          style={{
            height: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            visibility: isMouseOver ? 'visible' : 'hidden',
          }}
        >
          <InlineIconButton className={classes.inlineEditIcon} icon={LockIcon} disabled />
        </span>
      </Tooltip>
    </div>
  );
}

TooltipLock.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TooltipLock;
