import React, { useEffect, useRef, useState } from 'react';

import { getTextWidth } from '../core/ArrayMultiselectFieldWithChipsFormik/utils';

export const useArrayText = ({ array, renderLabel, labelAdditionalWidth = 0, showOnly = false }) => {
  const containerRef = useRef(null);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [remainingCount, setRemainingCount] = useState(0);

  const getDefaultItem = React.useCallback(() => {
    return array?.length > 0 ? array[0] : null;
  }, [array]);

  useEffect(() => {
    if (!array || array?.length === 0 || !containerRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    let currentWidth = 0;
    const items = [];

    array?.forEach((item, index) => {
      const label = renderLabel(item);
      const labelWidth = getTextWidth(
        label + (index !== array.length - 1 ? ', ' : ''),
        getComputedStyle(containerRef.current).font
      );

      const shouldDisplayLabel =
        currentWidth + labelWidth + labelAdditionalWidth + 6 <= containerWidth || (showOnly && index === 0);

      if (shouldDisplayLabel) {
        items.push(item);
        currentWidth += labelWidth + labelAdditionalWidth;
      }
    });

    if (items.length === 0) items.push(getDefaultItem());

    setDisplayedItems(items);
    setRemainingCount(array.length - items.length);
  }, [array, getDefaultItem, labelAdditionalWidth, renderLabel, showOnly]);

  return {
    containerRef,
    displayedItems,
    remainingCount,
  };
};
