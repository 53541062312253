import React, { useCallback, useEffect, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import axios from 'axios';

import { COLUMN_KEYS, EXTENDABLE_COLUMN_KEYS } from '~/components/Finances/FinancesTable/constants';

import { formatSortOrder, reportAxiosError } from '../../../Utils';
import FinancesTable from '../../Finances/FinancesTable';
import useOrganization from '../../OrganizationContext';

const RESULTS_PER_PAGE = 10;

const ApprovalFinances = ({
  page,
  setPage,
  onIsLoadingChanged,
  onIsErrorChanged,
  enableSelection,
  selectedFinances,
  onUpdatedSelectedFinances,
  shouldReload,
  onReload,
  filters = {},
  statuses = [],
}) => {
  const [finances, setFinances] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisablePagination, setIsDisablePagination] = useState(true);
  const [totalFinancesCount, setTotalFinancesCount] = useState(0);
  const [sortByColumn, setSortByColumn] = useState({ id: 'request_date', order: 'desc' });

  const { organizationId } = useOrganization();

  const handleIsLoadingChanged = useCallback(
    (val) => {
      setIsLoading(val);
      onIsLoadingChanged(val);
    },
    [onIsLoadingChanged, setIsLoading]
  );

  const getFinances = useCallback(async () => {
    const params = {
      page_number: page + 1,
      results_per_page: RESULTS_PER_PAGE,
      sort_by_column: formatSortOrder(sortByColumn),
      financial_status_filters: statuses,
      ...filters,
    };

    try {
      setIsDisablePagination(true);
      handleIsLoadingChanged(true);
      onIsErrorChanged(false);

      const { data } = await axios.get(`/api/v1/organizations/${organizationId}/finances`, { params });
      setFinances(data.finances);
      setTotalFinancesCount(data.count);
    } catch (err) {
      onIsErrorChanged(true);
      await reportAxiosError(err);
    }
    handleIsLoadingChanged(false);
    setIsDisablePagination(false);
  }, [filters, organizationId, page, onIsErrorChanged, handleIsLoadingChanged, sortByColumn, statuses]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    getFinances();
  }, [getFinances]);

  useEffect(() => {
    if (shouldReload && !isLoading) {
      // noinspection JSIgnoredPromiseFromCall
      getFinances();
      onReload();
    }
  }, [shouldReload, isLoading, getFinances, onReload]);

  return (
    <FinancesTable
      finances={finances}
      paginationProps={{
        page,
        rowsPerPage: RESULTS_PER_PAGE,
        onChangePage: (e, newPage) => {
          setPage(newPage);
        },
        disabled: isDisablePagination,
        count: totalFinancesCount || 0,
        rowsPerPageOptions: [RESULTS_PER_PAGE],
      }}
      onSortByColumn={(_, sortByColumn) => {
        setSortByColumn(sortByColumn);
        setPage(0);
      }}
      displayColumnIds={DISPLAY_COLUMN_IDS}
      sortByColumn={sortByColumn}
      onReloadFinances={getFinances}
      enableSelection={enableSelection}
      selectedFinances={selectedFinances}
      onUpdatedSelectedFinances={onUpdatedSelectedFinances}
    />
  );
};

ApprovalFinances.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.object,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  onIsLoadingChanged: PropTypes.func,
  onIsErrorChanged: PropTypes.func,
  enableSelection: PropTypes.bool,
  selectedFinances: requiredIf(PropTypes.array, (props) => props.enableSelection),
  onUpdatedSelectedFinances: requiredIf(PropTypes.func, (props) => props.enableSelection),
  shouldReload: PropTypes.bool,
  onReload: PropTypes.func,
};

const DISPLAY_COLUMN_IDS = [
  COLUMN_KEYS.select_finance,
  COLUMN_KEYS.claim_id_display,
  COLUMN_KEYS.requesting_user,
  COLUMN_KEYS.request_date,
  COLUMN_KEYS.exposure_label,
  COLUMN_KEYS.amount,
  COLUMN_KEYS.actions,

  EXTENDABLE_COLUMN_KEYS.payment_type,
  EXTENDABLE_COLUMN_KEYS.payees,
  EXTENDABLE_COLUMN_KEYS.sent_to,
  EXTENDABLE_COLUMN_KEYS.reference,
  EXTENDABLE_COLUMN_KEYS.note,
  EXTENDABLE_COLUMN_KEYS.deductible_applied,
  EXTENDABLE_COLUMN_KEYS.payment_method,
];

export default ApprovalFinances;
