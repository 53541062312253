const UK_VEHICLE_DAMAGED_CAR_PARTS_DICT = {
  'Total loss': 'Total loss',
  'Airbags deployed': 'Airbags deployed',
  'Front bumper': 'Front bumper',
  'Front grille': 'Front grille',
  'Driver-side head lights': 'Offside head lights',
  'Passenger-side head lights': 'Nearside headlights',
  Hood: 'Bonnet',
  Windshield: 'Windscreen',
  'Driver-side fender': 'Offside fender',
  'Passenger-side fender': 'Nearside fender',
  'Front driver-side door': 'Offside front door',
  'Front passenger-side door': 'Nearside front door',
  'Back driver-side door': 'Offside back door',
  'Back passenger-side door': 'Nearside back door',
  Trunk: 'Boot',
  'Driver-side taillights': 'Offside taillights',
  'Passenger-side taillights': 'Nearside taillights',
  'Rear bumper': 'Rear bumper',
  'Driver-side quarter panel': 'Offside quarter panel',
  'Passenger-side quarter panel': 'Nearside quarter panel',
  Roof: 'Roof',
  'Undercarriage / Suspension': 'Undercarriage / Suspension',
} as const;

export default UK_VEHICLE_DAMAGED_CAR_PARTS_DICT;
