import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const SelfConfiguredNotificationIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon fill="none" stroke={iconColor} {...props}>
    <path
      d="M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4813 4.06873C11.6397 4.21456 11.7897 4.36456 11.9313 4.51873L13.6376 4.76248C13.9156 5.24529 14.1299 5.76209 14.2751 6.29998L13.2376 7.68123C13.2376 7.68123 13.2563 8.10623 13.2376 8.31873L14.2751 9.69998C14.1306 10.2381 13.9162 10.755 13.6376 11.2375L11.9313 11.4812C11.9313 11.4812 11.6376 11.7875 11.4813 11.9312L11.2376 13.6375C10.7548 13.9155 10.238 14.1298 9.7001 14.275L8.31885 13.2375C8.10676 13.2562 7.89343 13.2562 7.68135 13.2375L6.3001 14.275C5.76199 14.1304 5.2451 13.9161 4.7626 13.6375L4.51885 11.9312C4.36468 11.7854 4.21468 11.6354 4.06885 11.4812L2.3626 11.2375C2.08459 10.7547 1.8703 10.2379 1.7251 9.69998L2.7626 8.31873C2.7626 8.31873 2.74385 7.89373 2.7626 7.68123L1.7251 6.29998C1.86963 5.76186 2.08395 5.24498 2.3626 4.76248L4.06885 4.51873C4.21468 4.36456 4.36468 4.21456 4.51885 4.06873L4.7626 2.36248C5.24541 2.08446 5.76222 1.87018 6.3001 1.72498L7.68135 2.76248C7.89343 2.74372 8.10676 2.74372 8.31885 2.76248L9.7001 1.72498C10.2382 1.86951 10.7551 2.08383 11.2376 2.36248L11.4813 4.06873Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

SelfConfiguredNotificationIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default SelfConfiguredNotificationIcon;
