import type { Ref } from 'react';
import React, { forwardRef, useImperativeHandle } from 'react';
import { EditorContent, useCurrentEditor } from '@tiptap/react';

import LinkBubbleMenu from './MenuBar/link/LinkBubbleMenu';
import { LinkModal } from './MenuBar/link/LinkModal';

const EditorContentComponent = forwardRef((_, ref) => {
  const { editor } = useCurrentEditor();

  // Expose a function to the parent component
  useImperativeHandle(ref, () => ({
    getHTML() {
      return editor ? editor.getHTML() : null;
    },
  }));

  return (
    <>
      <EditorContent editor={editor} ref={ref as Ref<HTMLDivElement>} />
      <LinkModal />
      <LinkBubbleMenu />
    </>
  );
});

EditorContentComponent.displayName = 'EditorContentComponent';

export default EditorContentComponent;
