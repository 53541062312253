import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { PermissionsButtonWrapper } from '../core';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function DeductibleContainer() {
  const { claim, onAsyncClaimUpdate } = useClaim();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isChangeClaimDeductibleOpen, setIsChangeClaimDeductibleOpen] = React.useState(false);
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();

  async function markAsPaid() {
    try {
      setIsSubmitting(true);
      await axios.post(`/api/v1/claims/${claim.id}/deductible/pay`);
      return onAsyncClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function markAsNotPaid() {
    try {
      setIsSubmitting(true);
      await axios.delete(`/api/v1/claims/${claim.id}/deductible/pay`);
      return onAsyncClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const payButtonProps = { className: classes.rightButtonIcon, disabled: isSubmitting, variant: 'outlined' };

  return (
    <>
      <Typography display="block" variant="subtitle2">
        <span
          style={{ textDecoration: claim.was_deductible_paid && 'line-through' }}
        >{`Deductible: ${currencyFormatter.format(claim.deductible)} (${claim.deductible_reason})`}</span>
        {claim.was_deductible_paid ? (
          <PermissionsButtonWrapper>
            <Button {...payButtonProps} onClick={markAsNotPaid}>
              Mark not paid
            </Button>
          </PermissionsButtonWrapper>
        ) : (
          <>
            <PermissionsButtonWrapper>
              <Button {...payButtonProps} onClick={() => setIsChangeClaimDeductibleOpen(true)}>
                Edit
              </Button>
            </PermissionsButtonWrapper>
            <PermissionsButtonWrapper>
              <Button {...payButtonProps} onClick={markAsPaid}>
                Mark paid
              </Button>
            </PermissionsButtonWrapper>
          </>
        )}
      </Typography>
      <ChangeClaimDeductibleDialog
        open={isChangeClaimDeductibleOpen}
        onCancel={() => setIsChangeClaimDeductibleOpen(false)}
        onDeductibleUpdated={() => setIsChangeClaimDeductibleOpen(false)}
      />
    </>
  );
}

function ChangeClaimDeductibleDialog(props) {
  const { claim, onAsyncClaimUpdate } = useClaim();
  const classes = useStyles();

  const { open, onDeductibleUpdated, onCancel } = props;

  return (
    <Formik
      initialValues={{
        deductible: claim.deductible,
        deductible_reason: claim.deductible_reason,
      }}
      validationSchema={Yup.object().shape({
        deductible: Yup.number().required('Required'),
        deductible_reason: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikBag) => {
        try {
          await axios.put(`/api/v1/claims/${claim.id}/deductible`, values);
          await onAsyncClaimUpdate();
          onDeductibleUpdated();
        } catch (error) {
          formikBag.setSubmitting(false);
          reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        return (
          <CardDialog title="Edit Claim Deductible" isDialog open={open} onClose={onCancel} maxWidth="xs" fullWidth>
            <MonetaryValueTextFieldFormik id="deductible" label="Deductible" className={classes.textField} fullWidth />
            <TextFieldFormik id="deductible_reason" label="Reason" className={classes.textField} fullWidth />
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                Update
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

ChangeClaimDeductibleDialog.propTypes = {
  open: PropTypes.bool,
  onDeductibleUpdated: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeductibleContainer;
