import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';

import { useClaim } from '../../../ClaimContainer';
import { SortableTable } from '../../../core';

import SumsHeader from './SumsHeader';

import styles from '../financesScreen.module.scss';

const SummaryTab = ({ data, isLoading, isError }) => {
  const classes = useStyles();
  const { claim } = useClaim();
  const { currencyFormatter } = useCurrencyFormatter();
  const displayLoading = isLoading || isError;

  const isWcClaim = claim.type === 'wc_claim';

  const currencyCell = currencyFormatter.format;

  let columnsData = [
    {
      id: 'exposure_name',
      label: 'Exposure Name',
      disableSort: true,
    },
    {
      id: 'payable_type',
      label: 'Payable Type',
      disableSort: true,
    },
    {
      id: 'paid_sum',
      label: 'Paid to Date',
      disableSort: true,
      specialCell: (row) => currencyCell(row.paid_sum),
    },
    {
      id: 'reserve',
      label: 'Reserve to Date',
      disableSort: true,
      specialCell: (row) => currencyCell(row.reserve),
    },
    {
      id: 'deductible_applied',
      label: 'Deductible Applied',
      disableSort: true,
      specialCell: (row) => (row.deductible_applied === null ? '' : currencyCell(row.deductible_applied)),
    },
    {
      id: 'incurred',
      label: 'Incurred',
      disableSort: true,
      specialCell: (row) => currencyCell(row.incurred),
    },
  ];

  if (data?.includes_sub_reserves) {
    columnsData.splice(2, 0, {
      id: 'sub_reserve_name',
      label: 'Sub Reserve Type',
      disableSort: true,
    });
  }

  // WC Claim doesn't have expenses per each exposure
  if (isWcClaim) {
    columnsData.splice(
      columnsData.findIndex(({ id }) => id === 'payable_type'),
      1
    );
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <div className={classes.cardDivRow}>
          {displayLoading && <SkeletonTable rowsCount={5} columnsCount={columnsData?.length || 7} isError={isError} />}
          {!displayLoading && (
            <>
              <div className={styles.sumsHeader}>
                {/* WC Claim doesn't have expenses per each exposure */}
                <SumsHeader amounts={data} shouldDisplayOnlyHeader={isWcClaim} />
              </div>
              <SortableTable
                rows={data?.rows || []}
                columns={columnsData}
                subRowsPropertyName="sub_rows"
                boldRowsIds={data?.rows.map((r) => r.id) || []}
              />
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

SummaryTab.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

export default SummaryTab;
