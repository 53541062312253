import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';

import Button from '~/components/core/Atomic/Buttons/Button';
import { GalleryIcon } from '~/components/icons';

import { GalleryCard } from './GalleryScreen';

function PhotosLink(props) {
  const { photos, text, galleryDialogTitle, disableExposuresFilter, displayLinkAsButton } = props;
  const [isGalleryCardOpen, setIsGalleryCardOpen] = React.useState(false);

  const onClick = (e) => {
    e.preventDefault();
    setIsGalleryCardOpen(true);
  };

  return (
    <>
      {displayLinkAsButton && (
        <Button
          className="flex max-w-max cursor-pointer items-center rounded border border-solid border-slate-500 bg-slate-100 p-12 normal-case underline hover:border-teal-700	hover:underline"
          variant="outlined"
          onClick={onClick}
          startIcon={<GalleryIcon className="fill-teal-700 stroke-teal-700" size={16} />}
          classes={{ label: 'font-normal' }}
        >
          {text}
        </Button>
      )}
      {!displayLinkAsButton && (
        <Link href="" style={{ margin: 0, textDecoration: 'underline' }} onClick={onClick}>
          {text}
        </Link>
      )}
      <GalleryCard
        allMedia={photos}
        title={galleryDialogTitle}
        open={isGalleryCardOpen}
        onClose={() => setIsGalleryCardOpen(false)}
        disableExposuresFilter={disableExposuresFilter}
        shouldDisableUpload
        shouldDisableLargePhotoActions
      />
    </>
  );
}

PhotosLink.propTypes = {
  photos: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  galleryDialogTitle: PropTypes.string.isRequired,
  disableExposuresFilter: PropTypes.bool,
  displayLinkAsButton: PropTypes.bool,
};

export default PhotosLink;
