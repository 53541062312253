import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const VoicemailIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" disableStrokeColor {...props}>
    <path
      d="M18.751 6.75C17.7085 6.75096 16.6899 7.06228 15.8249 7.64427C14.96 8.22626 14.2879 9.05258 13.8943 10.0179C13.5008 10.9833 13.4035 12.044 13.6149 13.0648C13.8264 14.0856 14.3369 15.0204 15.0816 15.75H8.92034C9.78971 14.8982 10.3361 13.7708 10.4661 12.5606C10.5961 11.3505 10.3015 10.1327 9.63284 9.11574C8.96418 8.09874 7.96293 7.34568 6.80036 6.98535C5.6378 6.62502 4.38616 6.67982 3.25954 7.14037C2.13291 7.60092 1.20131 8.43861 0.624078 9.51014C0.0468466 10.5817 -0.140145 11.8205 0.095087 13.0147C0.330319 14.2088 0.97316 15.2842 1.91365 16.0568C2.85414 16.8293 4.03384 17.2511 5.25096 17.25H18.751C20.1434 17.25 21.4787 16.6969 22.4633 15.7123C23.4478 14.7277 24.001 13.3924 24.001 12C24.001 10.6076 23.4478 9.27226 22.4633 8.28769C21.4787 7.30312 20.1434 6.75 18.751 6.75ZM1.50096 12C1.50096 11.2583 1.7209 10.5333 2.13295 9.91661C2.54501 9.29993 3.13068 8.81928 3.8159 8.53545C4.50112 8.25162 5.25512 8.17736 5.98255 8.32205C6.70998 8.46675 7.37817 8.8239 7.90261 9.34835C8.42706 9.8728 8.78421 10.541 8.92891 11.2684C9.0736 11.9958 8.99934 12.7498 8.71551 13.4351C8.43168 14.1203 7.95104 14.706 7.33435 15.118C6.71767 15.5301 5.99264 15.75 5.25096 15.75C4.2564 15.75 3.30258 15.3549 2.59931 14.6517C1.89605 13.9484 1.50096 12.9946 1.50096 12ZM18.751 15.75C18.0093 15.75 17.2843 15.5301 16.6676 15.118C16.0509 14.706 15.5702 14.1203 15.2864 13.4351C15.0026 12.7498 14.9283 11.9958 15.073 11.2684C15.2177 10.541 15.5749 9.8728 16.0993 9.34835C16.6238 8.8239 17.2919 8.46675 18.0194 8.32205C18.7468 8.17736 19.5008 8.25162 20.186 8.53545C20.8713 8.81928 21.4569 9.29993 21.869 9.91661C22.281 10.5333 22.501 11.2583 22.501 12C22.501 12.9946 22.1059 13.9484 21.4026 14.6517C20.6994 15.3549 19.7455 15.75 18.751 15.75Z"
      fill={iconColor}
    />
  </SvgIcon>
);

VoicemailIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default VoicemailIcon;
