import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../Utils';
import { PROS_AND_CONS_VALIDATION_SCHEMA } from '../../FormFragments/FormValidationSchemas';
import type { ProsAndConsFormValues } from '../../FormFragments/FormValuesTypes';
import { ProsAndConsForm } from '../../FormFragments/ProsAndConsForm';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type { DialogPropsWithLegalAction } from '../DialogTypes';
import { FormDialog } from '../FormDialog';

export const AddProsAndConsDialog: React.FC<DialogPropsWithLegalAction> = ({
  onClose,
  onSubmitSuccess,
  legalAction,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const handleSubmit = async (values: ProsAndConsFormValues) => {
    try {
      await axios.post(backendRoutes.legalAction.logs.pros_and_cons, values);
      await onSubmitSuccess();
      onClose();
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  return (
    <FormDialog<ProsAndConsFormValues>
      formikConfig={{
        onSubmit: handleSubmit,
        initialValues: {
          pro_con_type: null,
          rationale: null,
        },
        validationSchema: Yup.object().shape(PROS_AND_CONS_VALIDATION_SCHEMA),
      }}
      title="Add Pros and Cons"
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-20">
        <ProsAndConsForm />
      </div>
    </FormDialog>
  );
};
