import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { getExposuresLabels, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import DisableableTooltip from '../DisableableTooltip';
import LoadingIndicator from '../LoadingIndicator';
import TextFieldFormik from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

function LightDutyTrackFormFormik(props) {
  const { claim, onCancel, onDocumentCreated } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const involvedPerson = claim.employee_party.involved_person;
  const classes = useStyles();
  const initialValues = {
    exposure_id: '',
    subject: '',
    location: '',
    duration: '',
    schedule: '',
  };

  const uploadDocumentTemplate = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await axios.post(
        `/api/v1/wc_claims/${claim.id}/involved_employees_light_duty_statuses/${involvedPerson.id}/form`,
        values
      );
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      reportAxiosError(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        exposure_id: Yup.number().required('Required'),
        subject: Yup.string().required('Required'),
        location: Yup.string().required('Required'),
        duration: Yup.string().required('Required'),
        schedule: Yup.string().required('Required'),
      })}
      onSubmit={(values, formikPros) => {
        setPreviewOpen(true);
        formikPros.setSubmitting(false);
      }}
    >
      {(formikPros) => {
        const { handleSubmit, isSubmitting, values } = formikPros;
        const exposureLabels = getExposuresLabels(claim, true);

        return (
          <>
            <CardDialog
              isDialog
              title="Create Light Duty Form"
              maxWidth="xs"
              fullWidth
              onClose={onCancel}
              preventClose={isSubmitting}
            >
              <Grid container alignItems="flex-end" spacing={1}>
                <Grid item xs={12}>
                  <TextFieldFormik
                    id="exposure_id"
                    select
                    label="Exposure Label"
                    className={classes.textField}
                    fullWidth
                  >
                    {exposureLabels.map((exposure) => (
                      <MenuItem key={exposure.id} value={exposure.id}>
                        {exposure.label}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldFormik id="subject" label="Subject" className={classes.textField} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldFormik id="location" label="Location" className={classes.textField} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldFormik id="duration" label="Duration" className={classes.textField} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldFormik id="schedule" label="Schedule" className={classes.textField} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.buttonsContainer}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                      Preview
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardDialog>
            {previewOpen && (
              <TemplatePreviewDialog
                open={previewOpen}
                claim={claim}
                valuesForPreview={values}
                isSubmitting={isSubmitting}
                onClose={() => setPreviewOpen(false)}
                onCreateDocument={async () => {
                  await uploadDocumentTemplate(values, formikPros);
                  setPreviewOpen(false);
                  onDocumentCreated();
                }}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
}

LightDutyTrackFormFormik.propTypes = {
  claim: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDocumentCreated: PropTypes.func.required,
};

function TemplatePreviewDialog(props) {
  const { open, claim, valuesForPreview, isSubmitting, onClose, onCreateDocument } = props;

  const classes = useStyles();
  const involvedPerson = claim.employee_party.involved_person;
  const templatesParams = useMemo(() => ({ params: valuesForPreview }), [valuesForPreview]);

  const {
    isLoading,
    isError,
    data: docTemplateRes,
  } = useDataFetcher(
    `/api/v1/wc_claims/${claim.id}/involved_employees_light_duty_statuses/${involvedPerson.id}/form`,
    templatesParams
  );

  return (
    <Dialog open={open} fullScreen>
      {isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : (
        <>
          <div style={{ display: 'flex', margin: '15px' }}>
            <div style={{ flex: '1 1' }} />
            <DisableableTooltip
              disabled={docTemplateRes['is_missing']}
              title="Some values are still missing, download and edit manually"
            >
              <Button
                className={classes.leftButtonDialog}
                color="primary"
                variant="contained"
                size="small"
                disabled={docTemplateRes['is_missing'] || isSubmitting}
                onClick={onCreateDocument}
              >
                <span>Upload to Claim</span>
              </Button>
            </DisableableTooltip>
            <IconButton disabled={isSubmitting} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <object
            style={{ height: '100%', width: '100%', zIndex: '1' }}
            alt=""
            data={`data:application/pdf;base64,${docTemplateRes['document_base64']}`}
            type="application/pdf"
          />
        </>
      )}
    </Dialog>
  );
}

TemplatePreviewDialog.propTypes = {
  open: PropTypes.bool,
  claim: PropTypes.object.isRequired,
  valuesForPreview: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onCreateDocument: PropTypes.func.isRequired,
};

export { LightDutyTrackFormFormik };
