import { getParamByISO } from 'iso-country-currency';
import moment from 'moment-timezone';

import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/nl-be';
import 'moment/dist/locale/en-au';

export let globalDeployEnv = null;
export let topLevelDialogSetFunc = null;
export let localeDetails = null;

export function setGlobalDeployEnv(newEnv) {
  globalDeployEnv = newEnv;
}

export function setTopLevelDialogSetFunc(func) {
  topLevelDialogSetFunc = func;
}

export const updateOrganizationLocaleDetails = (organization) => {
  updateLocaleDetails(organization.configuration.locale?.toLowerCase());
};

export const updateLocaleDetails = (locale = 'en-us') => {
  let locale_obj = new Intl.Locale(locale);
  localeDetails = { locale: locale_obj, currency: getParamByISO(locale_obj.region, 'currency') };
  moment.locale(locale);
};

export const openTopLevelDialog = ({ title, message, contentComponent = null, showOkButton = false }) => {
  if (!topLevelDialogSetFunc) {
    // eslint-disable-next-line no-console
    console.log("Can't open top level dialog, function wasn't set yet");
    return;
  }

  topLevelDialogSetFunc({ title, message, contentComponent, showOkButton });
};
