import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '~/components/core/Atomic/MenuItem';

import LoadingIndicator from '../LoadingIndicator';
import { TextFieldFormik } from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

function OrganizationAdjusterTextFieldFormik(props) {
  const { organizationId, id, label, allowNone, ...restProps } = props;

  const {
    isLoading,
    isError,
    data: possibleAdjusters,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organizationId}/possible_adjusters`);

  if (isLoading) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <TextFieldFormik id={id} label={label} select {...restProps}>
      {allowNone && <MenuItem key="" value="" />}
      {possibleAdjusters &&
        possibleAdjusters.map((adjuster) => (
          <MenuItem key={adjuster.id} value={adjuster.id}>
            {adjuster.username}
          </MenuItem>
        ))}
    </TextFieldFormik>
  );
}

OrganizationAdjusterTextFieldFormik.propTypes = {
  organizationId: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  allowNone: PropTypes.bool,
};

export default OrganizationAdjusterTextFieldFormik;
