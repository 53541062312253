import React from 'react';
import { noop } from 'lodash';

import { useClaim } from '../../../../ClaimContainer';
import { MainCard } from '../../../../core';
import NotesSummary from '../../../../NotesSummary';
import { useLegalAction } from '../../../hooks/useLegalAction';

export const NotesCard: React.FC = () => {
  const { legalAction } = useLegalAction();
  const { claim } = useClaim();

  const exposureIds = legalAction.is_claim_level ? undefined : legalAction.exposures.map((exposure) => exposure.id);

  return (
    <MainCard type="elevated" title="Notes" collapsible openByDefault>
      <NotesSummary claim={claim} onNoteClick={noop} noCard hideTypeFilter exposureOptionsIds={exposureIds} />
    </MainCard>
  );
};
