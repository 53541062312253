import React from 'react';

import SvgIcon from '../../core/SvgIcon';

import colors from '../../../assets/colors.module.scss';

const BopIcon = (props) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path
      d="M14 7.38462C14 8.27677 13.1046 9 12 9C10.8954 9 9.99993 8.27677 9.99993 7.38462C9.99993 6.49246 10.8954 6 12 6C13.1046 6 14 6.49246 14 7.38462Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M9.99993 7.38462C9.99993 8.27677 9.10449 9 7.9999 9C6.89531 9 5.99987 8.27677 5.99987 7.38462C5.99987 6.49246 6.89531 6 7.9999 6C9.10449 6 9.99993 6.49246 9.99993 7.38462Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M5.99987 7.38462C5.99987 8.27677 5.10462 9 4.00003 9C2.89545 9 2 8.27677 2 7.38462C2 6.49246 2.89545 6 4.00003 6C5.10462 6 5.99987 6.49246 5.99987 7.38462Z"
      fill={colors.lobIconSecondary}
    />
    <path
      d="M14.48 5.91781C14.48 5.91781 14.48 5.87781 14.48 5.86781L13.58 2.72781C13.52 2.51781 13.4 2.34781 13.22 2.20781C13.05 2.07781 12.84 2.00781 12.62 2.00781H3.37C3.16 2.00781 2.94 2.07781 2.77 2.20781C2.6 2.33781 2.47 2.51781 2.41 2.72781L1.52 5.86781C1.52 5.86781 1.52 5.90781 1.52 5.92781C1.52 5.95781 1.5 5.97781 1.5 6.00781V7.00781C1.5 7.67781 1.76 8.29781 2.23 8.77781C2.31 8.85781 2.41 8.92781 2.5 8.99781V13.0078C2.5 13.2778 2.6 13.5278 2.79 13.7178C2.98 13.9078 3.23 14.0078 3.5 14.0078H12.5C12.77 14.0078 13.02 13.9078 13.21 13.7178C13.4 13.5278 13.5 13.2778 13.5 13.0078V8.99781C13.59 8.92781 13.68 8.85781 13.77 8.77781C14.24 8.30781 14.5 7.67781 14.5 7.00781V6.00781C14.5 6.00781 14.49 5.94781 14.48 5.92781V5.91781ZM12.63 2.99781L13.34 5.49781H2.66L3.37 2.99781H12.63ZM2.94 8.05781C2.66 7.77781 2.5 7.39781 2.5 6.99781V6.49781H5.5V6.99781C5.5 7.39781 5.34 7.77781 5.06 8.05781C4.5 8.61781 3.5 8.61781 2.94 8.05781ZM6.5 6.49781H9.5V6.99781C9.5 7.39781 9.34 7.77781 9.06 8.05781C8.5 8.61781 7.5 8.61781 6.94 8.05781C6.66 7.77781 6.5 7.39781 6.5 6.99781V6.49781ZM12.5 12.9978H3.5V9.44781C3.66 9.47781 3.83 9.49781 4 9.49781C4.67 9.49781 5.3 9.23781 5.77 8.76781C5.86 8.67781 5.93 8.57781 6 8.48781C6.07 8.58781 6.14 8.68781 6.23 8.76781C6.7 9.23781 7.33 9.49781 8 9.49781C8.67 9.49781 9.29 9.23781 9.77 8.76781C9.86 8.67781 9.93 8.57781 10 8.48781C10.07 8.58781 10.14 8.68781 10.23 8.76781C10.7 9.23781 11.33 9.49781 12 9.49781C12.17 9.49781 12.34 9.47781 12.5 9.44781V12.9978ZM13.06 8.05781C12.5 8.61781 11.5 8.61781 10.94 8.05781C10.66 7.77781 10.5 7.39781 10.5 6.99781V6.49781H13.5V6.99781C13.5 7.39781 13.34 7.77781 13.06 8.05781Z"
      fill={colors.lobIconMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12.9978V11.9978C9 11.4455 8.55228 10.9978 8 10.9978C7.44772 10.9978 7 11.4455 7 11.9978V12.9978H9ZM8 9.99781C6.89543 9.99781 6 10.8932 6 11.9978V13.9978H10V11.9978C10 10.8932 9.10457 9.99781 8 9.99781Z"
      fill={colors.lobIconMain}
    />
  </SvgIcon>
);

export default BopIcon;
