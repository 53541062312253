const LIGHT_DUTY_POSITIONS_DICT = {
  polishing_silverware: 'Polishing Silverware',
  polishing_glasses: 'Polishing Glasses',
  water_control: 'Water Control',
  towel_runner: 'Towel Runner',
  edr_turnstile_attendant: 'EDR Turnstile Attendant',
  folding_towels: 'Folding Towels',
  folding_bags: 'Folding Bags',
  lost_found_labeling_items: 'Lost and Found – Labeling Items',
  spa_fold_towels: 'Spa – Fold Towels',
  spa_greets_guest_sign_waivers: 'Spa – Greets Guest and Have Them Sign Waivers',
  pool_towel_handlers_greeters: 'Pool – Towel Handlers and Greeters',
  uniform_control_put_away_hangers: 'Uniform Control – Put Away Hangers',
  other: 'Other',
} as const;

export default LIGHT_DUTY_POSITIONS_DICT;
