import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../../CardDialog';
import { FsButton, LoadingSwitch } from '../../core';
import { useCms } from '../../hooks/useCms';
import { ShowOnlyTextField } from '../../TextFieldFormik';
import useDataFetcher from '../../useDataFetcher';

import { useStyles } from '../../../assets/styles';

const FIELD_NAMES = {
  claimspay_type: 'One Inc ClaimsPay Type',
  claimspay_method: 'One Inc Method',
  one_inc_payment_status: 'One Inc Status',
  is_approval_required: 'Approval Required',
  one_inc_paid_date: 'Paid Date',
  one_inc_cleared_date: 'Cleared Date',
  one_inc_escheat_date: 'Escheated Date',
  original_payment_method: 'Original Method',
};

const OneIncPaymentDetailsDialog = ({ payment_request_id, onClose }) => {
  const { userOrganization } = useCms();
  const classes = useStyles();
  const {
    isLoading,
    isError,
    data: oneIncPayment,
  } = useDataFetcher(`/api/v1/one_inc/organizations/${userOrganization?.id}/payments/${payment_request_id}`);

  const convertResponseToString = (value) => {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
  };

  return (
    <CardDialog isDialog onClose={onClose} title="One Inc Payment Details" maxWidth="xs" fullWidth>
      <LoadingSwitch isError={isError} isLoading={isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={oneIncPayment?.claimspay_type}
              label={FIELD_NAMES['claimspay_type']}
            />
          </Grid>
          {oneIncPayment
            ? Object.keys(oneIncPayment)
                ?.filter((key) => key !== 'claimspay_type' && (!isEmpty(oneIncPayment[key]) || oneIncPayment[key]))
                .map((key) => (
                  <Grid item xs={6} key={key}>
                    <ShowOnlyTextField
                      classes={classes}
                      showOnlyValueComponent={convertResponseToString(oneIncPayment[key])}
                      label={FIELD_NAMES[key]}
                    />
                  </Grid>
                ))
            : null}
        </Grid>
        <div className={classes.buttonRightAligned}>
          <FsButton size="small" variant="contained" color="primary" onClick={onClose}>
            Ok
          </FsButton>
        </div>
      </LoadingSwitch>
    </CardDialog>
  );
};

OneIncPaymentDetailsDialog.propTypes = {
  payment_request_id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OneIncPaymentDetailsDialog;
