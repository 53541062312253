import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const QuestionCircleIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
      fill={iconColor}
    />
    <path
      d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7084 7.00691C11.3251 6.75146 12.0037 6.68462 12.6584 6.81485C13.3131 6.94507 13.9145 7.26651 14.3865 7.73851C14.8585 8.21052 15.1799 8.81188 15.3102 9.46657C15.4404 10.1213 15.3735 10.7999 15.1181 11.4166C14.8626 12.0333 14.4301 12.5604 13.875 12.9312C13.531 13.1611 13.1494 13.3246 12.75 13.4156V13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5V12.75C11.25 12.5511 11.329 12.3603 11.4697 12.2197C11.6103 12.079 11.8011 12 12 12C12.3708 12 12.7334 11.89 13.0417 11.684C13.35 11.478 13.5904 11.1851 13.7323 10.8425C13.8742 10.4999 13.9113 10.1229 13.839 9.7592C13.7666 9.39549 13.588 9.0614 13.3258 8.79917C13.0636 8.53695 12.7295 8.35837 12.3658 8.28603C12.0021 8.21368 11.6251 8.25081 11.2825 8.39273C10.9399 8.53464 10.647 8.77496 10.441 9.08331C10.235 9.39165 10.125 9.75416 10.125 10.125C10.125 10.5392 9.78921 10.875 9.375 10.875C8.96079 10.875 8.625 10.5392 8.625 10.125C8.625 9.45749 8.82294 8.80497 9.19379 8.24995C9.56464 7.69493 10.0917 7.26235 10.7084 7.00691Z"
      fill={iconColor}
    />
  </SvgIcon>
);

QuestionCircleIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default QuestionCircleIcon;
