import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';

import useFetchExposure from '~/components/exposures/useFetchExposure';
import { useCms } from '~/components/hooks/useCms';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import { useFetchClaim } from '../../../Utils/ClaimUtils';
import CardDialog from '../../CardDialog';
import { ClaimContextProvider } from '../../ClaimContainer';
import LoadingIndicator from '../../LoadingIndicator';
import { getPayableWithReserveType } from '../ExposureUtils';

import { PaymentRequestContainer } from './PaymentRequestContainer';

function PaymentRequestDynamicContainer({
  claimId,
  exposureId,
  payableWithReserveId,
  payableWithReserveType,
  paymentRequest,
  cardDialogProps,
  onClose,
  onUpdate,
  overrideInitialValues,
  forceManualDataInit,
  paymentSuggestion,
}) {
  const [claim, isLoadingClaim, isErrorClaim, reloadClaim] = useFetchClaim(claimId);
  const {
    isLoadingExposure,
    isErrorExposure,
    exposure: exposureFetchedOrUndefined,
  } = useFetchExposure(claimId, exposureId); // exposureId might be undefined
  const { userOrganization } = useCms();

  const isEfficientClaimForFe =
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL) &&
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.EFFICIENT_CLAIM_FOR_FE);

  const isLoading = isLoadingClaim || isLoadingExposure;
  const isError = isErrorClaim || isErrorExposure;

  if (isLoading) {
    return (
      <CardDialog title="Loading..." {...cardDialogProps}>
        <LoadingIndicator isError={isError} />
      </CardDialog>
    );
  }

  let payableWithReserve = null;
  let exposure = null;
  if (
    (payableWithReserveId && claim.general_expenses.id === payableWithReserveId) ||
    (!exposureId && payableWithReserveType === 'expenses')
  ) {
    payableWithReserve = claim.general_expenses;
    // TODO: Move this to somewhere else
    exposure = {
      isGeneralExpenses: true,
      is_closed: false,
      expenses: claim.general_expenses,
    };
  } else {
    exposure = isEfficientClaimForFe
      ? exposureFetchedOrUndefined
      : claim.exposures.find((exposure) => exposure.id === exposureId);
    if (!exposure) {
      throw Error(`Couldn't find exposure ${exposureId} in claim ${claimId}`);
    }

    if (exposure.indemnity.id === payableWithReserveId || payableWithReserveType === 'indemnity') {
      payableWithReserve = exposure.indemnity;
    } else if (exposure.expenses.id === payableWithReserveId || payableWithReserveType === 'expenses') {
      payableWithReserve = exposure.expenses;
    } else {
      throw Error(`Couldn't find payableWithReserve ${payableWithReserveId} in exposure ${exposureId}`);
    }
  }

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <PaymentRequestContainer
        claim={claim}
        exposure={exposure}
        payableWithReserve={payableWithReserve}
        payableType={getPayableWithReserveType(exposure, payableWithReserve)}
        paymentRequest={paymentRequest}
        cardDialogProps={cardDialogProps}
        onClose={onClose}
        onUpdate={onUpdate}
        overrideInitialValues={overrideInitialValues}
        forceManualDataInit={forceManualDataInit}
        paymentSuggestion={paymentSuggestion}
      />
    </ClaimContextProvider>
  );
}
PaymentRequestDynamicContainer.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposureId: PropTypes.number.isRequired,
  payableWithReserveId: requiredIf(PropTypes.number, (props) => !props.payableWithReserveType),
  payableWithReserveType: requiredIf(
    PropTypes.oneOf(['indemnity', 'expenses']),
    (props) => !props.payableWithReserveId
  ),
  onUpdate: PropTypes.func.isRequired,
  paymentRequest: PropTypes.object,
  cardDialogProps: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  overrideInitialValues: PropTypes.object,
  forceManualDataInit: PropTypes.bool,
  paymentSuggestion: PropTypes.object,
};

export default PaymentRequestDynamicContainer;
