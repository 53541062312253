import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as Yup from 'yup';

const CustomFieldsContext = React.createContext({});

function CustomFieldsContextProvider(props) {
  const { children, customFieldsConfigurations } = props;

  const contextValues = {
    customFieldsConfigurations,
    additionalDataValidations: getAdditionalDataValidations(customFieldsConfigurations),
  };

  return <CustomFieldsContext.Provider value={contextValues}>{children}</CustomFieldsContext.Provider>;
}

const getAdditionalDataValidations = (customFieldsConfigurations) => {
  return _.chain(customFieldsConfigurations)
    .mapKeys((field) => field.id)
    .mapValues((field) => {
      switch (field.type) {
        case 'string':
          return field.mandatory ? Yup.string().required('Required') : Yup.string();
        case 'select':
          return field.mandatory
            ? Yup.string()
                .oneOf(field.options.map((o) => o.id))
                .required('Required')
            : Yup.string().oneOf(field.options.map((o) => o.id));
        case 'multiselect':
          return field.mandatory
            ? Yup.array()
                .of(Yup.string().oneOf(field.options.map((o) => o.id)))
                .min(1, 'Required')
            : Yup.array().of(Yup.string().oneOf(field.options.map((o) => o.id)));
        case 'date': {
          return field.mandatory ? Yup.date().required('Required') : Yup.date();
        }
        case 'time': {
          const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/;
          return field.mandatory
            ? Yup.string().matches(regex, 'Invalid Time Format').required('Required')
            : Yup.string().matches(regex, 'Invalid Time Format');
        }
        case 'number': {
          return field.mandatory ? Yup.number().required('Required') : Yup.number();
        }
        case 'boolean':
          return field.mandatory ? Yup.bool().required('Required') : Yup.bool();
        default:
          throw Error('Unknown custom field type');
      }
    })
    .value();
};

CustomFieldsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  customFieldsConfigurations: PropTypes.array.isRequired,
};

const withCustomFields = (WrappedComponent) => {
  class withCustomFields extends React.Component {
    render() {
      return (
        <CustomFieldsContext.Consumer>
          {(customFieldsContext) => <WrappedComponent {...customFieldsContext} {...this.props} />}
        </CustomFieldsContext.Consumer>
      );
    }
  }

  withCustomFields.displayName = `withCustomFields(${getDisplayName(WrappedComponent)})`;
  return withCustomFields;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function useCustomFields() {
  return useContext(CustomFieldsContext);
}

export default useCustomFields;
export { CustomFieldsContextProvider, withCustomFields };
