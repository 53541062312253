import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';

import { ChevronLeftIcon } from '../../icons';

import colors from '../../../assets/colors.module.scss';
import styles from './buttons.module.scss';

const BackTextButton = ({ title = 'Back', onClick = noop, disabled = false, upperCase = true }) => {
  return (
    <div className={styles.backButtonContainer}>
      <Button
        style={{ textTransform: upperCase ? 'uppercase' : 'none' }}
        startIcon={<ChevronLeftIcon iconColor={colors.textLink} />}
        onClick={onClick}
        variant="text"
        className={styles.backTextButton}
        disabled={disabled}
      >
        {title}
      </Button>
    </div>
  );
};

BackTextButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  upperCase: PropTypes.bool,
};

export default BackTextButton;
