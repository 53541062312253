import type { ReactElement } from 'react';
import React from 'react';

import { AiChatDisclaimer } from '~/components/AiChat/AiChatContainer/AiChatDisclaimer';
import type { ChatInputFieldProps } from '~/components/AiChat/AiChatContainer/ChatInputField';
import { ChatInputField } from '~/components/AiChat/AiChatContainer/ChatInputField';
import { AiGreeting } from '~/components/AiChat/components/Message/AiGreeting';
import { AiMessage } from '~/components/AiChat/components/Message/AiMessage';
import { HumanMessage } from '~/components/AiChat/components/Message/HumanMessage';
import { useChatContext } from '~/components/AiChat/hooks/useChat';
import type { AiChatExchangeApiResponse } from '~/components/AiChat/types';
import { Text } from '~/components/core';
import cn from '~/Utils/cn';

export interface AiChatContainerProps {
  messagesContainerClassName?: string;
  showGreetingAsMessage?: boolean;
  inputFieldType?: ChatInputFieldProps['type'];
  preDefinedMessages?: string[];
  isChatLoading?: boolean;
}

export const AiChatContainerComponent: React.FC<AiChatContainerProps> = ({
  messagesContainerClassName,
  showGreetingAsMessage = true,
  inputFieldType = 'elevated',
  preDefinedMessages,
}) => {
  const { chat, exchanges, getChatResponse, updateExchangeUserFeedback, regenerateResponse, isFetchingResponse } =
    useChatContext();
  const [userInput, setUserInput] = React.useState('');
  const [questionAwaitingResponse, setQuestionAwaitingResponse] = React.useState<string | null>(null);
  const [isLastMessageNew, setIsLastMessageNew] = React.useState<boolean>(false);

  const [messages, setMessages] = React.useState<ReactElement[]>([]);
  const [currentExchanges, setCurrentExchanges] = React.useState<AiChatExchangeApiResponse[]>(exchanges);

  React.useEffect(() => {
    setCurrentExchanges(exchanges);
  }, [exchanges]);

  const messagesContainerRef = React.useRef<HTMLDivElement>(null);
  const handleNewContent = () => {
    setTimeout(() => {
      if (messagesContainerRef.current) {
        const { current: container } = messagesContainerRef;
        container.scrollTop = container.scrollHeight + 200;
      }
    }, 100);
  };

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleRegenerateResponse = React.useCallback(async () => {
    const lastExchange = currentExchanges[currentExchanges.length - 1];
    const lastMessageUserInput = lastExchange.user_input;

    setCurrentExchanges(exchanges.slice(0, -1));
    setQuestionAwaitingResponse(lastMessageUserInput);

    await regenerateResponse(lastExchange.id as number);
  }, [currentExchanges, exchanges, regenerateResponse]);

  React.useEffect(() => {
    handleNewContent();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [messages, chat]);

  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };

  React.useEffect(() => {
    if (!isFetchingResponse) {
      setQuestionAwaitingResponse(null);
    }
  }, [isFetchingResponse]);

  React.useEffect(() => {
    if (!chat) return;

    const sortedExchanges = currentExchanges.sort((a, b) => a.id - b.id);
    const exchangesWithNewMessage = questionAwaitingResponse
      ? [
          ...sortedExchanges,
          {
            id: 0,
            chat_id: chat.id,
            user_input: questionAwaitingResponse,
            response: null,
          } as AiChatExchangeApiResponse,
        ]
      : sortedExchanges;

    setMessages(
      exchangesWithNewMessage.reduce((acc: ReactElement[], exchange, i) => {
        const isLast = i === exchangesWithNewMessage.length - 1;
        return [
          ...acc,
          <HumanMessage exchange={exchange} key={`human_${i}`} />,
          <AiMessage
            exchange={exchange}
            key={`ai_${i}`}
            onUserFeedback={updateExchangeUserFeedback}
            withTypeEffect={isLast && isLastMessageNew}
            onType={handleNewContent}
            onTypeEnd={() => setIsLastMessageNew(false)}
            onRegenerate={isLast ? handleRegenerateResponse : undefined}
            showDisclaimer={isLast}
          />,
        ];
      }, [])
    );
  }, [
    chat,
    currentExchanges,
    handleRegenerateResponse,
    isLastMessageNew,
    updateExchangeUserFeedback,
    questionAwaitingResponse,
  ]);

  const handleQuestionSubmit = async (questionText: string) => {
    setQuestionAwaitingResponse(questionText);
    setIsLastMessageNew(true);
    setUserInput('');
    try {
      await getChatResponse(questionText);
    } catch (error) {
      setIsLastMessageNew(false);
    }
  };

  const handleUserSubmit = async () => {
    if (userInput) {
      await handleQuestionSubmit(userInput);
    }
  };

  const handlePredefinedMessageClick = async (message: string) => {
    setUserInput(message);
    await handleQuestionSubmit(message);
  };

  const isChatEmpty = messages.length === 0;
  const shouldShowPreDefinedMessages = preDefinedMessages?.length && isChatEmpty && !showGreetingAsMessage;

  const isInputPositionTop = isChatEmpty && !showGreetingAsMessage;

  if (!chat) {
    return null;
  }

  return (
    <div
      className={cn('flex h-full w-full flex-col', {
        'justify-between': !isInputPositionTop,
      })}
    >
      <div ref={messagesContainerRef} className={cn('flex w-full flex-col overflow-auto', messagesContainerClassName)}>
        {showGreetingAsMessage ? (
          <AiGreeting text={chat?.greeting || ''} withTypeEffect={currentExchanges.length === 0} />
        ) : null}
        {messages}
      </div>
      <div
        className={cn('mt-0', {
          'mb-16': inputFieldType === 'elevated',
          'px-16': isInputPositionTop,
        })}
      >
        <ChatInputField
          onUserInput={handleUserInput}
          onUserSubmit={handleUserSubmit}
          disabled={isFetchingResponse}
          inputRef={inputRef}
          value={userInput}
          type={isInputPositionTop ? 'elevated' : inputFieldType} // Input is always elevated at the top position
        />
        {isChatEmpty && <AiChatDisclaimer />}
      </div>
      {shouldShowPreDefinedMessages ? (
        <div className="flex w-full flex-col items-center justify-center px-16 pt-32">
          <div className="mb-24">
            <Text variant={Text.VARIANTS.LG} className="font-bold text-aiV2-grey-200">
              Common questions I can answer
            </Text>
          </div>
          <div className="center flex w-full flex-col items-center justify-center gap-12">
            {preDefinedMessages?.map((message, i) => (
              <div
                className="cursor-pointer rounded-lg bg-aiV2-fadedWhite px-20 py-12 transition-shadow duration-500 hover:shadow-sm hover:shadow-blue-100"
                key={i}
                onClick={async () => {
                  await handlePredefinedMessageClick(message);
                }}
              >
                <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className="text-aiV2-grey-300">
                  {message}
                </Text>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const AiChatContainer = React.memo(AiChatContainerComponent);
