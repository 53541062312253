import React from 'react';

import { useStyles } from '~/assets/styles';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { TextFieldFormik } from '~/components/TextFieldFormik';
import COMMUNICATION_DIRECTION from '~/server_shared/generated-types/COMMUNICATION_DIRECTION';
import cn from '~/Utils/cn';

// assume isDocument - otherwise, it's just a bug we don't have direction
const SelectDirectionFormik: React.FC = () => {
  const classes = useStyles();

  return (
    <TextFieldFormik id="direction" label="Direction" className={cn(classes.textField, 'mb-12 w-1/2')} select>
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/*@ts-ignore*/}
      {COMMUNICATION_DIRECTION.map((direction) => (
        <MenuItem key={direction} value={direction}>
          {direction}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};
export default SelectDirectionFormik;
