import React from 'react';

import AlertBanner from '~/components/core/AlertBanner';
import type { MoiAlert } from '~/components/exposures/moi/MoiStepper/types';

interface MoiStepWrapperProps {
  children: React.JSX.Element;
  alertMessage: MoiAlert;
}

const MoiStepWrapper: React.FC<MoiStepWrapperProps> = ({ children, alertMessage }) => {
  return (
    <div className="flex flex-col">
      {!!alertMessage.message && (
        <div className="mb-40 mt-20">
          <AlertBanner alertType={alertMessage.type} note={alertMessage.message} withIcon />
        </div>
      )}
      {children}
    </div>
  );
};

export default MoiStepWrapper;
