import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';

import { NO_UPDATED_PAYEE_DETAILS_ERROR_ID } from '~/components/OneInc/AdditionalFinanceTableMenuOptions/UpdatePayeeDetailsDialog/updatePayeeDetailsDialogUtils';

const NoUpdatedPayeeDetailsFormikError = () => {
  const { errors } = useFormikContext();

  return <FormHelperText error>{getIn(errors, NO_UPDATED_PAYEE_DETAILS_ERROR_ID)}</FormHelperText>;
};

export default NoUpdatedPayeeDetailsFormikError;
