import React from 'react';

interface IUseWindowFocus {
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
}

const useWindowFocus = ({ onFocus, onBlur, disabled = false }: IUseWindowFocus): void => {
  React.useEffect(() => {
    if (disabled || window === undefined) return () => null;
    if (onFocus) window.addEventListener('focus', onFocus);
    if (onBlur) window.addEventListener('blur', onBlur);

    return () => {
      // cleanup
      if (disabled || window === undefined) return;
      if (onFocus) window.removeEventListener('focus', onFocus);
      if (onBlur) window.removeEventListener('blur', onBlur);
    };
  }, [onBlur, onFocus, disabled]);
};

export default useWindowFocus;
