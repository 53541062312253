import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import TextAlignCenterIcon from '../../../../icons/editor/TextAlignCenterIcon';
import TextAlignJustifyIcon from '../../../../icons/editor/TextAlignJustifyIcon';
import TextAlignLeftIcon from '../../../../icons/editor/TextAlignLeftIcon';
import TextAlignRightIcon from '../../../../icons/editor/TextAlignRightIcon';
import MultiOptions from '../MultiOptions';

type TextAlignOption = {
  content: JSX.Element;
  alignment: 'left' | 'right' | 'center' | 'justify';
};

const TextAlign: React.FC = () => {
  const { editor } = useCurrentEditor();

  const setTextAlign = (alignment: 'left' | 'right' | 'center' | 'justify') => {
    editor?.chain().focus().setTextAlign(alignment).run();
  };

  const options: TextAlignOption[] = [
    {
      content: <TextAlignLeftIcon />,
      alignment: 'left',
    },
    {
      content: <TextAlignRightIcon />,
      alignment: 'right',
    },
    {
      content: <TextAlignCenterIcon />,
      alignment: 'center',
    },
    {
      content: <TextAlignJustifyIcon />,
      alignment: 'justify',
    },
  ];

  return (
    <MultiOptions
      options={options.map(({ content, alignment }) => ({
        content,
        disabled: !editor?.can().setTextAlign(alignment),
        isActive: !!editor?.isActive({ textAlign: alignment }),
        onClick: () => setTextAlign(alignment),
      }))}
    />
  );
};

export default TextAlign;
