import React, { useRef } from 'react';

import { getTextWidth } from '../core/ArrayMultiselectFieldWithChipsFormik/utils';

export const useTextOverflow = (
  text: string,
  additionalWidth = 0
): { textContainerRef: React.Ref<HTMLDivElement>; isOverflowing: boolean } => {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  React.useLayoutEffect(() => {
    if (textContainerRef.current && text) {
      const textWidth = getTextWidth(text, getComputedStyle(textContainerRef.current).font);
      setIsOverflowing(textContainerRef.current.offsetWidth < textWidth + additionalWidth);
    }
  }, [additionalWidth, text]);

  return { textContainerRef, isOverflowing };
};
