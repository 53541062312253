import React from 'react';

import { AddItemButton, Text } from '../../core';

export const LegalActionsHeader: React.FC<{ onNewLegalActionClicked: () => void; showButton: boolean }> = ({
  onNewLegalActionClicked,
  showButton,
}) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD}>
        Legal Actions
      </Text>
      {showButton ? <AddItemButton variant="text" text="Add Legal Action" onClick={onNewLegalActionClicked} /> : null}
    </div>
  );
};
