import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import MenuItem from '~/components/core/Atomic/MenuItem';

import useOrganization from '../../OrganizationContext';
import { TextFieldFormik } from '../../TextFieldFormik';

import useCoverage from './hooks/useCoverage';

import { useStyles } from '../../../assets/styles';

export const CoveragesSingleSelectFormik = ({
  subOrganizationIds,
  lobs,
  coverageFieldId,
  coveragesToFilterOut = null,
  label = 'Coverage',
  disabled = false,
  includeOnlyInvolvedPersonsCoverages = false,
  disableGeneral = false,
}) => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();
  const { organizationId, subOrganizationEnabled } = useOrganization();
  const coverage = getIn(values, coverageFieldId);
  const { coveragesOptionsDict, isLoading, isInitialized } = useCoverage({
    subOrganizationIds,
    organizationId,
    lobs,
    coverageFieldId,
    disableGeneral,
    includeOnlyInvolvedPersonsCoverages,
  });

  const filterCoverages = (value) => {
    if (coveragesToFilterOut) {
      return !coveragesToFilterOut.includes(value);
    }
    return value;
  };

  useEffect(() => {
    if (!isLoading && isInitialized && coverage && !Object.keys(coveragesOptionsDict).includes(coverage)) {
      setFieldValue(coverageFieldId, '');
    }
  }, [coveragesOptionsDict, setFieldValue, coverageFieldId, coverage, isLoading, isInitialized]);

  const isDisabled =
    isLoading ||
    (subOrganizationEnabled && !subOrganizationIds.length) ||
    !lobs.length ||
    disabled ||
    Object.keys(coveragesOptionsDict).length === 0;

  return (
    <TextFieldFormik
      id={coverageFieldId}
      label={label}
      className={classes.textField}
      select
      fullWidth
      disabled={isDisabled}
    >
      {Object.keys(coveragesOptionsDict)
        .filter(filterCoverages)
        .map((coverage) => (
          <MenuItem key={coverage} value={coverage}>
            {coveragesOptionsDict[coverage]}
          </MenuItem>
        ))}
    </TextFieldFormik>
  );
};

CoveragesSingleSelectFormik.propTypes = {
  subOrganizationIds: PropTypes.array.isRequired,
  lobs: PropTypes.array.isRequired,
  coverageFieldId: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  coveragesToFilterOut: PropTypes.array,
  includeOnlyInvolvedPersonsCoverages: PropTypes.bool,
  disableGeneral: PropTypes.bool,
};
