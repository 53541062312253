import React from 'react';

import { filterProjects } from '~/components/ReportsPage/helpers/ReportsUtils';
import type { Project, User } from '~/components/ReportsPage/types';

interface IReportsFilter {
  projects: Project[];
  searchText: string;
  selectedOwners: User[];
  users: User[];
}

interface IReportsFilterResult {
  filteredProjects: Project[];
}

export const useReportsFilter = ({
  projects = [],
  searchText = '',
  selectedOwners = [],
}: IReportsFilter): IReportsFilterResult => {
  const filteredProjects = React.useMemo(() => {
    return filterProjects({
      searchText,
      selectedOwners,
      projects,
    });
  }, [projects, searchText, selectedOwners]);

  return {
    filteredProjects,
  };
};
