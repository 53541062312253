import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';

import Grid from '~/components/core/Atomic/Grid/Grid';

import styles from '../assets/styles';

const LoadingIndicatorContainer = ({ children }) => {
  return (
    <Grid container justify="center">
      <Grid item>{children}</Grid>
    </Grid>
  );
};

LoadingIndicatorContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const LoadingIndicator = ({ classes, isError, LoadingComponent, ErrorComponent, ...rest }) => {
  if (isError) {
    return (
      <LoadingIndicatorContainer>
        {ErrorComponent ? (
          <ErrorComponent />
        ) : (
          // Using data-testid since we can't fetch the SVG by other functionality
          <ErrorIcon data-testid="error-indicator" style={{ fontSize: 30 }} className={classes.loading} />
        )}
      </LoadingIndicatorContainer>
    );
  }

  return (
    <LoadingIndicatorContainer>
      {LoadingComponent ? (
        <LoadingComponent />
      ) : (
        // Using data-testid since we can't fetch the SVG by other functionality
        <CircularProgress data-testid="loading-indicator" className={classes.loading} {...rest} />
      )}
    </LoadingIndicatorContainer>
  );
};

LoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  isError: PropTypes.bool,
  LoadingComponent: PropTypes.node,
  ErrorComponent: PropTypes.node,
};

export default withStyles(styles)(LoadingIndicator);
