import { useCms } from '../../../../hooks/useCms';
import useOrganization from '../../../../OrganizationContext';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { LICENSE_EXPIRATION_STATUS } from '../consts';
import { getLicenseStatus, isLicensesEnabled } from '../utils';

const isLicenseValidForLossLocation = (license, lossLocationState) => {
  return (
    license.state === lossLocationState &&
    getLicenseStatus(license.expiration_date) !== LICENSE_EXPIRATION_STATUS.EXPIRED
  );
};

/**
 *
 * @returns {{isLicensesEnabled: *, isLicenseCorrelationEnabled: *, isAdjusterLicensedForLossLocation: ((function(*, *): (boolean|*))|*)}}
 */
export const useLicenses = () => {
  const { userOrganization } = useCms();
  const { organization: sysconfigOrganization } = useSysconfig();
  const { isLicenseCorrelationEnabled } = useOrganization();

  const organization = sysconfigOrganization || userOrganization;

  const isAdjusterLicensedForLossLocation = (adjuster, lossLocationState) => {
    if (!lossLocationState) {
      return false;
    }

    if (!(isLicensesEnabled(organization) && isLicenseCorrelationEnabled)) {
      return true;
    }

    return adjuster?.licenses?.some((license) => isLicenseValidForLossLocation(license, lossLocationState));
  };

  return {
    isLicensesFeatureEnabled: isLicensesEnabled(organization),
    isLicenseCorrelationEnabled: isLicensesEnabled(organization) && isLicenseCorrelationEnabled,
    isAdjusterLicensedForLossLocation,
  };
};
