import React from 'react';
import PropTypes from 'prop-types';

import { COUNTRY_TO_STATE_MAP } from '../Types';

import AutocompleteFormik from './AutocompleteFormik';

const StateAutocompleteFormik = ({ id, label, statesDict = COUNTRY_TO_STATE_MAP['US'], disabled }) => (
  <AutocompleteFormik
    id={id}
    label={label}
    options={Object.keys(statesDict)}
    getOptionLabel={(option) => statesDict[option]}
    sortAlphabetic
    disabled={disabled}
    fullWidth
  />
);

StateAutocompleteFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  statesDict: PropTypes.object,
  disabled: PropTypes.bool,
};

export default StateAutocompleteFormik;
