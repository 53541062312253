import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const ClaimsIcon = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      d="M8.25 12.75C8.25 12.3358 8.58579 12 9 12H15C15.4142 12 15.75 12.3358 15.75 12.75C15.75 13.1642 15.4142 13.5 15 13.5H9C8.58579 13.5 8.25 13.1642 8.25 12.75Z"
      fill={iconColor}
    />
    <path
      d="M9 15C8.58579 15 8.25 15.3358 8.25 15.75C8.25 16.1642 8.58579 16.5 9 16.5H15C15.4142 16.5 15.75 16.1642 15.75 15.75C15.75 15.3358 15.4142 15 15 15H9Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 2.25C4.85217 2.25 4.47065 2.40804 4.18934 2.68934C3.90804 2.97064 3.75 3.35217 3.75 3.75V20.25C3.75 20.6478 3.90804 21.0294 4.18934 21.3107C4.47065 21.592 4.85218 21.75 5.25 21.75H18.75C19.1478 21.75 19.5294 21.592 19.8107 21.3107C20.092 21.0294 20.25 20.6478 20.25 20.25V8.25C20.25 8.04864 20.1706 7.86582 20.0415 7.73109L20.0303 7.71967L14.7803 2.46967L14.7689 2.45849C14.6342 2.32935 14.4514 2.25 14.25 2.25H5.25ZM13.5 3.75H5.25V20.25H18.75V9H14.25C13.8358 9 13.5 8.66421 13.5 8.25V3.75ZM17.6893 7.5L15 4.81066V7.5H17.6893Z"
      fill={iconColor}
    />
  </SvgIcon>
);

ClaimsIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default ClaimsIcon;
