import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import CheckboxFormik from '~/components/CheckboxFormik';
import { getAllOrganizationWideRoles } from '~/components/communications/ContactUtils';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import useOrganization from '~/components/OrganizationContext';
import TextFieldFormik from '~/components/TextFieldFormik';
import { reportAxiosError, yupPhoneValidation } from '~/Utils';

const AddParticipantDialog = ({ isClaimCommunication, onSubmit, onCancel, isOnHold }) => {
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <Formik
      initialValues={{
        is_contact_selection: isClaimCommunication,
        contact_id: '',
        contact_phone_id: '',
        name: '',
        phone_number: '',
      }}
      validationSchema={Yup.object().shape({
        is_contact_selection: Yup.boolean().required('Required'),
        contact: Yup.object().when('is_contact_selection', {
          is: true,
          then: Yup.object().test(
            'is-phone-exists',
            'Contact must contain at least one phone number',
            (contact) => contact && contact.phones && contact.phones.length > 0
          ),
        }),
        contact_id: Yup.number().when('is_contact_selection', {
          is: true,
          then: Yup.number().required('Required'),
        }),
        contact_phone_id: Yup.number().when('is_contact_selection', {
          is: true,
          then: Yup.number().required('Required'),
        }),
        name: Yup.string().when('is_contact_selection', {
          is: false,
          then: Yup.string().required('Required'),
        }),
        phone_number: Yup.string().when('is_contact_selection', {
          is: false,
          then: yupPhoneValidation,
        }),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values);
        } catch (error) {
          formikProps.setSubmitting(false);
          reportAxiosError(error);
        }
      }}
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <CardDialog title="Add Participant" isDialog maxWidth="xs" onClose={onCancel} preventClose={isSubmitting}>
          <Grid container spacing={1}>
            {isOnHold && (
              <Grid item xs={12}>
                <Typography variant="caption" color="secondary">
                  {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                  {"New participant won't be on hold"}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <CheckboxFormik id="is_contact_selection" label="Contact Selection" />
            </Grid>
            {values.is_contact_selection ? (
              <>
                <Grid item xs={12}>
                  <ContactTextFieldFormik
                    id="contact"
                    label="Contact"
                    className={classes.textField}
                    acceptedRoles={
                      isClaimCommunication
                        ? Object.keys(organizationContactRolesDict)
                        : getAllOrganizationWideRoles(organizationContactRolesDict)
                    }
                    onSelectContact={(contact) => {
                      if (!contact || contact.id === '') {
                        setFieldValue('contact_phone_id', '');
                      } else {
                        if (contact.phones.length > 0) {
                          setFieldValue('contact_phone_id', contact.phones[0].id);
                        } else {
                          setFieldValue('contact_phone_id', '');
                        }
                      }
                    }}
                    outOfContactsContextSearch={!isClaimCommunication}
                    fixedSearchResults
                    fullWidth
                  />
                </Grid>
                {values.contact && values.contact.phones.length > 0 && (
                  <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
                    {values.contact.phones.length === 1 ? (
                      <Grid item xs={12}>
                        <Typography display="block" variant="subtitle2">
                          {values.contact.phones[0].phone}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <TextFieldFormik
                          id="contact_phone_id"
                          label="Phone"
                          className={classes.textField}
                          style={{ width: '280px' }}
                          select
                        >
                          {values.contact.phones.map((phone) => (
                            <MenuItem key={phone.id} value={phone.id}>
                              {phone.phone}
                            </MenuItem>
                          ))}
                        </TextFieldFormik>
                      </Grid>
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextFieldFormik id="name" label="Name" className={classes.textField} style={{ width: '280px' }} />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldFormik
                    id="phone_number"
                    label="Phone Number"
                    className={classes.textField}
                    style={{ width: '280px' }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
              Add
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
};

AddParticipantDialog.propTypes = {
  isClaimCommunication: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOnHold: PropTypes.bool,
};

export default AddParticipantDialog;
