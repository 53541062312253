const MGM_WC_INJURY_CODE_DICT = {
  '301': {
    desc: 'ABRASION',
  },
  '302': {
    desc: 'ALTERCATION/HORSEPLAY',
  },
  '303': {
    desc: 'AMPUTATION',
  },
  '305': {
    desc: 'ASPHYXIATION/SUFFOCATION',
  },
  '306': {
    desc: 'AVULSION',
  },
  '307': {
    desc: 'BITE/STING',
  },
  '308': {
    desc: 'BROKEN/CHIPPED TOOTH',
  },
  '310': {
    desc: 'BRUISE/CONTUSION',
  },
  '311': {
    desc: 'BURN - CHEMICAL',
  },
  '312': {
    desc: 'BURN - HEAT',
  },
  '313': {
    desc: 'BURSITIS',
  },
  '314': {
    desc: 'CHEMICAL IRRITANT',
  },
  '315': {
    desc: 'CHEMICAL IRRITATION',
  },
  '316': {
    desc: 'CHOKING',
  },
  '317': {
    desc: 'COMA',
  },
  '318': {
    desc: 'CONCUSSION',
  },
  '320': {
    desc: 'CRUSH',
  },
  '321': {
    desc: 'CUT/LACERATION/SCRATCH',
  },
  '322': {
    desc: 'CONJUNCTIVITIS',
  },
  '323': {
    desc: 'DERMATITIS-ACUTE INJURY',
  },
  '324': {
    desc: 'DISLOCATION',
  },
  '326': {
    desc: 'EAR INJURY',
  },
  '327': {
    desc: 'ELECTRIC SHOCK',
  },
  '328': {
    desc: 'EXPOSURE',
  },
  '329': {
    desc: 'FOREIGN BODY-EYE',
  },
  '330': {
    desc: 'FOREIGN BODY-OTHER',
  },
  '331': {
    desc: 'FOREIGN BODY-THROAT',
  },
  '333': {
    desc: 'FRACTURE',
  },
  '334': {
    desc: 'GUN SHOT WOUND',
  },
  '335': {
    desc: 'HERNIA',
  },
  '336': {
    desc: 'HYPEREXTENSION INJURY',
  },
  '337': {
    desc: 'HYPERTENSION',
  },
  '338': {
    desc: 'INFECTION',
  },
  '339': {
    desc: 'INFLAMMATION',
  },
  '340': {
    desc: 'INTERNAL INJURY',
  },
  '341': {
    desc: 'MISCELLANEOUS/OTHER',
  },
  '342': {
    desc: 'MOUTH (LIPS/TEETH/TONGUE)',
  },
  '345': {
    desc: 'MULTIPLE',
  },
  '346': {
    desc: 'PAIN',
  },
  '347': {
    desc: 'PARALYSIS',
  },
  '348': {
    desc: 'NEUROLOGICAL CONDITION/INJURY',
  },
  '349': {
    desc: 'POISONING',
  },
  '350': {
    desc: 'PUNCTURE',
  },
  '351': {
    desc: 'RESPIRATORY AILMENT',
  },
  '352': {
    desc: 'RUPTURE',
  },
  '355': {
    desc: 'SPRAIN/STRAIN',
  },
  '358': {
    desc: 'TENDONITIS',
  },
  '359': {
    desc: 'TINNITUS',
  },
  '361': {
    desc: 'WHIPLASH',
  },
  '365': {
    desc: 'TEAR',
  },
  '366': {
    desc: 'TRAUMA',
  },
  '398': {
    desc: 'UNKNOWN INSUFFICIENT DATA',
  },
  '313R': {
    desc: ' BURSITIS-RPTD TRMA',
  },
  NA: {
    desc: 'NOT APPLICABLE',
  },
} as const;

export default MGM_WC_INJURY_CODE_DICT;
