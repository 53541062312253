import type { BasicConfigurationHookResponse } from '~/components/hooks/types';
import { useCms } from '~/components/hooks/useCms';
import type { NotesConfiguration } from '~/components/SystemConfiguration/NotesConfiguration/types';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';

interface UseNotesConfigurationResponse extends BasicConfigurationHookResponse<NotesConfiguration> {
  notesConfigurationRoute: string;
}

const useNotesConfiguration = (inAdminPanel = false): UseNotesConfigurationResponse => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization: adminPanelOrganization } = useSysconfig();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const organizationId = inAdminPanel ? adminPanelOrganization?.id : userOrganization?.id;

  const notesConfigurationRoute = `/api/v1/notes_configuration/organizations/${organizationId}`;

  const { data, isLoading, isError, reloadData } = useDataFetcher(notesConfigurationRoute);

  return {
    notesConfigurationRoute,
    configuration: data,
    isLoading,
    isError,
    reloadData,
  };
};

export default useNotesConfiguration;
