import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const EmailErrorIcon = ({ iconColor = '#909090', ...props }) => (
  <SvgIcon fill="none" stroke="none" {...props}>
    <path d="M14 3.5L8 9L2 3.5" strokeLinecap="round" strokeLinejoin="round" stroke={iconColor} fill="none" />
    <path
      d="M2 3.5H14V12C14 12.1326 13.9473 12.2598 13.8536 12.3536C13.7598 12.4473 13.6326 12.5 13.5 12.5H2.5C2.36739 12.5 2.24021 12.4473 2.14645 12.3536C2.05268 12.2598 2 12.1326 2 12V3.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path d="M6.90625 8L2.15625 12.3562" strokeLinecap="round" strokeLinejoin="round" stroke={iconColor} fill="none" />
    <path d="M13.8438 12.3562L9.09375 8" strokeLinecap="round" strokeLinejoin="round" stroke={iconColor} fill="none" />
    <path
      d="M12.6665 14C14.3234 14 15.6665 12.6569 15.6665 11C15.6665 9.34315 14.3234 8 12.6665 8C11.0096 8 9.6665 9.34315 9.6665 11C9.6665 12.6569 11.0096 14 12.6665 14Z"
      fill="#F44336"
      stroke="none"
    />
    <path d="M11.3618 12.28L14.0001 9.7468" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
    <path d="M11.3618 9.66675L13.9169 12.2832" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
  </SvgIcon>
);

EmailErrorIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default EmailErrorIcon;
