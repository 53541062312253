import React from 'react';

import { useClaim } from '../../../../ClaimContainer';
import UploadDocument from '../../../../Documents/DocumentCard';
import type { DialogPropsWithLegalAction } from '../../../Dialogs/DialogTypes';
import { useLegalActionsBackendRoutes } from '../../../hooks/useLegalActionsBackendRoutes';

export const AddLegalActionDocument: React.FC<DialogPropsWithLegalAction> = ({
  legalAction,
  onSubmitSuccess,
  onClose,
}) => {
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);
  const { claim } = useClaim();

  const handleUploadComplete = async () => {
    await onSubmitSuccess();
    onClose();
  };

  return (
    <UploadDocument
      onSubmitDocument={handleUploadComplete}
      onCancel={onClose}
      alternativeUploadUrl={backendRoutes.legalAction.base}
      claim={claim}
    />
  );
};
