const LIABILITY_LIEN_TYPES_DICT = {
  workers_compensation: {
    desc: 'Workers Compensation',
  },
  loss_transfer_amount: {
    desc: 'Loss Transfer Amount',
  },
  additional_pip: {
    desc: 'Additional PIP',
  },
  health_insurance: {
    desc: 'Health Insurance',
  },
  other: {
    desc: 'Other',
  },
} as const;

export default LIABILITY_LIEN_TYPES_DICT;
