import { useCms } from '~/components/hooks/useCms';

interface ITableauApiRoutes {
  jwtUrl: string;
  workbooksUrl: string;
  workbookUrl: (p: { workbookId: string }) => string;
  viewsUrl: string;
  projectsUrl: string;
  usersUrl: string;
  hasTableauAccountUrl: string;
}

export const useTableauApiRoutes = (): ITableauApiRoutes => {
  const { userOrganization, user }: { userOrganization?: { id: number }; user?: { id: string } } = useCms();
  const baseUrl = `/api/v1/tableau_users/organizations/${userOrganization?.id}`;
  const baseUrlWithUser = `${baseUrl}/users/${user?.id}`;

  return {
    jwtUrl: `${baseUrlWithUser}/tableau_jwt`,
    workbooksUrl: `${baseUrlWithUser}/workbooks`,
    workbookUrl: ({ workbookId }) => `${baseUrlWithUser}/workbooks/${workbookId}`,
    viewsUrl: `${baseUrlWithUser}/views`,
    projectsUrl: `${baseUrlWithUser}/projects`,
    usersUrl: `${baseUrl}/users`,
    hasTableauAccountUrl: `${baseUrl}/has_tableau_account`,
  };
};
