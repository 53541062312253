import React, { forwardRef } from 'react';
import { EditorProvider } from '@tiptap/react';
import { isEmpty, noop } from 'lodash';

import cn from '~/Utils/cn';

import { getExtensions } from './extensions/config';
import { ModalLinkProvider } from './MenuBar/link/LinkModalContext';
import EditorContentComponent from './EditorContentComponent';
import MenuBar from './MenuBar';
import type { DisplayConfigProps, SuggestionOption } from './types';

export interface EditorProps {
  content?: string;
  className?: string;
  containerClassName?: string;
  suggestionOptions?: SuggestionOption[];
  displayConfig?: DisplayConfigProps;
  onChange?: (valueAsHtml: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

const Editor = forwardRef(
  (
    {
      content = '',
      suggestionOptions = [],
      displayConfig = {},
      onChange = noop,
      disabled,
      className,
      containerClassName,
      autoFocus,
    }: EditorProps,
    ref
  ) => (
    <div className={cn('rounded-md border-1 border-solid border-slate-600', containerClassName)}>
      <ModalLinkProvider>
        <EditorProvider
          editable={!disabled}
          extensions={getExtensions(suggestionOptions, displayConfig, disabled)}
          editorProps={{
            attributes: {
              class: cn('px-20 py-12 outline-none', className),
            },
          }}
          autofocus={autoFocus}
          content={content}
          onUpdate={({ editor }) => onChange(editor.getHTML())}
          slotBefore={
            !isEmpty(displayConfig) ? (
              <MenuBar suggestionOptions={suggestionOptions} displayConfig={displayConfig} />
            ) : null
          }
        >
          <EditorContentComponent ref={ref} />
        </EditorProvider>
      </ModalLinkProvider>
    </div>
  )
);

Editor.displayName = 'Editor';

export default Editor;
