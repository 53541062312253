import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import cliveSmallImage from '../../../assets/CliveSmall.png';

export const JusterImage: React.FC<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={cliveSmallImage} alt="Clive" {...props} />;
};
