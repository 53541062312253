import React from 'react';
import { useCurrentEditor } from '@tiptap/react';

import ListIcon from '../../../../icons/editor/ListIcon';
import ToolbarButton from '../ToolbarButton';

const OrderList: React.FC = () => {
  const { editor } = useCurrentEditor();

  const toggle = () => {
    editor?.chain().focus().toggleOrderedList().run();
  };

  return (
    <ToolbarButton
      onClick={toggle}
      disabled={!editor?.can().toggleOrderedList()}
      isActive={editor?.isActive('orderedList')}
    >
      <ListIcon />
    </ToolbarButton>
  );
};

export default OrderList;
