import React, { Fragment } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { FormHelperText, Link } from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';

import {
  CONFIGURATION_FEATURES_NAMES,
  MARSHMALLOW_VEHICLE_DAMAGE_LEVELS_LIST,
  UK_VEHICLE_DAMAGED_CAR_PARTS_DICT,
  VEHICLE_DAMAGED_CAR_PARTS_DICT,
} from '../../../Types';
import { isFeatureEnabled, isInshurUkPolicy, isMarshmallowPolicy } from '../../../Utils';
import { findIncidentParty } from '../../../Utils/ClaimUtils';
import CardDialog from '../../CardDialog';
import { useClaim } from '../../ClaimContainer';
import { localeDetails } from '../../CmsMain/globals';
import WithConfirm from '../../ConfirmModal';
import ContactTextFieldFormik from '../../ContactTextFieldFormik';
import { FsButton, FsIconButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../core';
import { ErrorHelperTextFormik } from '../../core/Formik/ErrorHelperTextFormik';
import { useCms } from '../../hooks/useCms';
import { TrashIcon } from '../../icons';
import InvolvedWrapper from '../../InvolvedWrapper';
import LocationTextFieldFormik from '../../LocationTextFieldFormik';
import useOrganization from '../../OrganizationContext';
import { usePolicy } from '../../PolicyContainer';
import RadioButtonFormik from '../../RadioButtonFormik';
import TextFieldFormik, { MultiSelectTextFieldFormik, YearPickerSelectTextFieldFormik } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const vehicleFields = {
  year: '',
  make: '',
  model: '',
  series: '',
  vin: '',
  plate_number: '',
  vehicle_type: '',
};

const vehicleInvolvedFields = {
  covered_vehicle_id: '',
  vehicle: null,
  owner_contact_id: '',
  owner_contact_full_name: '',
  attorney_contact_id: '',
  attorney_contact_full_name: '',
  attorney_reference_number: '',
  insurer_contact_id: '',
  insurer_contact_full_name: '',
  insurer_reference_number: '',
  insurer_policy_number: '',
  is_drivable: '',
  was_towed: '',
  is_dashcam_installed: '',
  is_dashcam_active: '',
  is_car_still_mobile: '',
  scheduling_inspection_contact_id: '',
  inspection_location: null,
  damages: [],
  damage_description: '',
  note: '',
  mileage: '',
  extra: {},
};

const vehicleValidationSchema = {
  vehicle: Yup.object().shape({
    year: Yup.mixed()
      .nullable()
      .test(
        'is-valid',
        'Invalid year',
        // values which are '', appear as undefined in Yup test func
        (year) =>
          year === '' || year === undefined || year === null || (year >= 1920 && year <= new Date().getFullYear() + 1)
      ),
  }),
};

const getVehicleInvolvedValidationSchema = (isInsured, policy) =>
  Yup.object().shape({
    ...vehicleValidationSchema,
    owner_contact_id: isInsured ? Yup.number().required('Required') : undefined,
    was_towed: !isInshurUkPolicy(policy)
      ? Yup.boolean()
          .nullable()
          .when('is_drivable', {
            is: false,
            then: Yup.boolean().nullable().required('Required'),
          })
      : undefined,
    is_dashcam_active: Yup.boolean()
      .nullable()
      .when('is_dashcam_installed', {
        is: true,
        then: Yup.boolean().nullable().required('Required'),
      }),
  });

const spacing = 1;

function VehicleInvolvedFragment(props) {
  const classes = useStyles();
  const { policy } = usePolicy();
  const { userOrganization } = useCms();

  const coveredVehicles = policy && !policy.is_manual ? policy.covered_vehicles : [];
  const { buttonsComponent, disabled, disableEditIdentity, isInsured, values, setFieldValue, showOnly } = props;

  if (!showOnly && !policy) {
    // Show only is not always in policy/claim context
    throw Error('Only in showOnly mode policy is not mandatory');
  }

  function handleSelectCoveredVehicle(e) {
    const coveredVehicleId = e.target.value;
    if (values['covered_vehicle_id'] === coveredVehicleId) {
      return;
    }

    setFieldValue('covered_vehicle_id', coveredVehicleId);

    if (coveredVehicleId === 0 || coveredVehicleId === -1) {
      // Unlisted Vehicle or Rental Vehicle
      setFieldValue('vehicle', vehicleFields);
      setFieldValue('owner_contact_id', '');
      setFieldValue('owner_contact_full_name', '');
      setFieldValue('note', '');
      return;
    }

    const coveredVehicle = coveredVehicles.find((cv) => cv.id === coveredVehicleId);
    const overNightAddress = coveredVehicle.vehicle_extra && coveredVehicle.vehicle_extra.overnight_address;
    const overNightAddressString = overNightAddress
      ? `Overnight Address: ${Object.keys(overNightAddress)
          .map((key) => `${key}: ${overNightAddress[key]}`)
          .join(', ')}`
      : '';

    setFieldValue('note', overNightAddressString);
    setFieldValue('vehicle', coveredVehicle.vehicle);
    setFieldValue('owner_contact_id', coveredVehicle.vehicle.vehicle_owner_contact?.id || policy.insured_contact.id);
    setFieldValue(
      'owner_contact_full_name',
      coveredVehicle.vehicle.vehicle_owner_contact?.full_name || policy.insured_contact.full_name
    );
  }

  let disableVehicleEditing = disabled || disableEditIdentity;
  if (isInsured) {
    const isManualVehicle = values['covered_vehicle_id'] === 0 || values['covered_vehicle_id'] === -1; // Unlisted Vehicle or Rental Vehicle
    disableVehicleEditing = disableVehicleEditing || !isManualVehicle;
  }

  const vehicle_damaged_car_parts_dict =
    localeDetails.locale.region === 'GB' ? UK_VEHICLE_DAMAGED_CAR_PARTS_DICT : VEHICLE_DAMAGED_CAR_PARTS_DICT;

  return (
    <Grid container spacing={spacing}>
      {policy && isInsured && !policy.is_manual && (
        <Grid item xs={12}>
          <TextFieldFormik
            id="covered_vehicle_id"
            label="Vehicle"
            select
            fullWidth
            disabled={disabled || disableEditIdentity}
            className={classes.textField}
            onChange={handleSelectCoveredVehicle}
            showOnly={showOnly}
          >
            {coveredVehicles
              .concat([
                { id: 0, vehicle: { display_name: 'Unlisted Vehicle' } },
                { id: -1, vehicle: { display_name: 'Rental Vehicle' } },
              ])
              .map((coveredVehicle) => (
                <MenuItem key={coveredVehicle.id} value={coveredVehicle.id}>
                  {coveredVehicle.vehicle.display_name}
                </MenuItem>
              ))}
          </TextFieldFormik>
        </Grid>
      )}
      <VehicleIdentityFragment
        disableEditOwner={disableEditIdentity}
        disableVehicleEditing={disableVehicleEditing}
        showOnly={showOnly}
        isInsured={isInsured}
        shouldIncludeMileage
      />
      {!isInsured && (
        <Grid item xs={12} container spacing={spacing}>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="insurer_contact"
              label="Adverse Carrier"
              className={classes.textField}
              showOnly={showOnly}
              acceptedRoles={['insurer']}
              fixedSearchResults
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id="insurer_reference_number"
              label="Adverse Carrier Reference Number"
              className={classes.textField}
              showOnly={showOnly}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id="insurer_policy_number"
              label="Adverse Carrier Policy Number"
              className={classes.textField}
              showOnly={showOnly}
              fullWidth
            />
          </Grid>
        </Grid>
      )}

      {(isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.UK_FNOL_FIELDS_MM_STYLE) ||
        isMarshmallowPolicy(policy)) && (
        <>
          <Grid item container xs={6}>
            <Grid item xs={7}>
              <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', fontSize: 13 }}>
                Is the car still mobile?
              </span>
            </Grid>
            <Grid item xs={5}>
              <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                <RadioButtonFormik
                  id="extra.is_car_still_mobile"
                  label={<span style={{ fontSize: 13 }}>No</span>}
                  optionValue={false}
                  size="small"
                  disabled={disabled || showOnly}
                />
                <RadioButtonFormik
                  id="extra.is_car_still_mobile"
                  label={<span style={{ fontSize: 13 }}>Yes</span>}
                  optionValue={true}
                  size="small"
                  disabled={disabled || showOnly}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6} />
        </>
      )}
      <Grid item container xs={6}>
        <Grid item xs={7}>
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', fontSize: 13 }}>
            {isInshurUkPolicy(policy) ? 'Is vehicle road-worthy?' : 'Is vehicle safe to drive?'}
          </span>
        </Grid>
        <Grid item xs={5}>
          <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            <RadioButtonFormik
              id="is_drivable"
              label={<span style={{ fontSize: 13 }}>No</span>}
              optionValue={false}
              size="small"
              disabled={disabled || showOnly}
            />
            <RadioButtonFormik
              id="is_drivable"
              label={<span style={{ fontSize: 13 }}>Yes</span>}
              optionValue={true}
              onChange={() => {
                setFieldValue('is_drivable', true);
                !isInshurUkPolicy(policy) && setFieldValue('was_towed', '');
              }}
              size="small"
              disabled={disabled || showOnly}
            />
          </div>
        </Grid>
      </Grid>
      {!isInshurUkPolicy(policy) && (
        <Grid item container xs={6} spacing={1}>
          <Grid item xs={6}>
            <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', fontSize: 13 }}>
              Was vehicle towed?
            </span>
          </Grid>
          <Grid item xs={6}>
            <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
              <RadioButtonFormik
                id="was_towed"
                label={<span style={{ fontSize: 13 }}>No</span>}
                size="small"
                optionValue={false}
                disabled={disabled || showOnly || values.is_drivable !== false}
              />
              <RadioButtonFormik
                id="was_towed"
                label={<span style={{ fontSize: 13 }}>Yes</span>}
                optionValue={true}
                size="small"
                disabled={disabled || showOnly || values.is_drivable !== false}
              />
            </div>
          </Grid>
          <ErrorHelperTextFormik style={{ marginTop: 0 }} id="was_towed" />
        </Grid>
      )}
      {isInshurUkPolicy(policy) && (
        <>
          <Grid item container xs={6} spacing={1}>
            <Grid item xs={6}>
              <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', fontSize: 13 }}>
                Does the client require recovery?
              </span>
            </Grid>
            <Grid item xs={6}>
              <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                <RadioButtonFormik
                  id="extra.require_recovery"
                  label={<span style={{ fontSize: 13 }}>No</span>}
                  size="small"
                  optionValue={false}
                  disabled={disabled || showOnly}
                />
                <RadioButtonFormik
                  id="extra.require_recovery"
                  label={<span style={{ fontSize: 13 }}>Yes</span>}
                  optionValue={true}
                  size="small"
                  disabled={disabled || showOnly}
                />
              </div>
            </Grid>
            <ErrorHelperTextFormik style={{ marginTop: 0 }} id="extra.require_recovery" />
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={7}>
              <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', fontSize: 13 }}>
                Is the vehicle mobile?
              </span>
            </Grid>
            <Grid item xs={5}>
              <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                <RadioButtonFormik
                  id="extra.is_vehicle_mobile"
                  label={<span style={{ fontSize: 13 }}>No</span>}
                  optionValue={false}
                  size="small"
                  disabled={disabled || showOnly}
                />
                <RadioButtonFormik
                  id="extra.is_vehicle_mobile"
                  label={<span style={{ fontSize: 13 }}>Yes</span>}
                  optionValue={true}
                  size="small"
                  disabled={disabled || showOnly}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6} />
        </>
      )}
      <Grid item container xs={6}>
        <Grid item xs={7}>
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', fontSize: 13 }}>
            Dash cam installed?
          </span>
        </Grid>
        <Grid item xs={5}>
          <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            <RadioButtonFormik
              id="is_dashcam_installed"
              label={<span style={{ fontSize: 13 }}>No</span>}
              optionValue={false}
              onChange={() => {
                setFieldValue('is_dashcam_installed', false);
                setFieldValue('is_dashcam_active', '');
              }}
              size="small"
              disabled={disabled || showOnly}
            />
            <RadioButtonFormik
              id="is_dashcam_installed"
              label={<span style={{ fontSize: 13 }}>Yes</span>}
              optionValue={true}
              size="small"
              disabled={disabled || showOnly}
            />
          </div>
        </Grid>
      </Grid>
      <Grid item container xs={6} spacing={1}>
        <Grid item xs={6}>
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', fontSize: 13 }}>
            Was it active at the time of loss?
          </span>
        </Grid>
        <Grid item xs={6}>
          <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            <RadioButtonFormik
              id="is_dashcam_active"
              label={<span style={{ fontSize: 13 }}>No</span>}
              size="small"
              optionValue={false}
              disabled={disabled || showOnly || values.is_dashcam_installed !== true}
            />
            <RadioButtonFormik
              id="is_dashcam_active"
              label={<span style={{ fontSize: 13 }}>Yes</span>}
              optionValue={true}
              size="small"
              disabled={disabled || showOnly || values.is_dashcam_installed !== true}
            />
          </div>
        </Grid>
        <ErrorHelperTextFormik style={{ marginTop: 0 }} id="is_dashcam_active" />
      </Grid>
      <Grid item xs={6}>
        <ContactTextFieldFormik
          id="scheduling_inspection_contact"
          label="Contact for Scheduling Inspection"
          className={classes.textField}
          showOnly={showOnly}
          disabled={disabled || showOnly}
          acceptedRoles={
            isInsured
              ? ['insured', 'named_driver', 'spouse', 'family_member', 'body_shop', 'tow_lot', 'other']
              : ['claimant', 'body_shop', 'tow_lot', 'other']
          }
          contactSearchProps={{ newContactRole: 'tow_lot' }}
          fixedSearchResults
          updateOnInit
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <LocationTextFieldFormik
          id="inspection_location"
          label={"Vehicle's Location"}
          location={values['inspection_location']}
          onChangeLocation={(vehicleLocation) => setFieldValue('inspection_location', vehicleLocation)}
          showOnly={showOnly}
          disabled={disabled || showOnly}
          additionalFieldsComponent={
            values['scheduling_inspection_contact'] ? (
              <FillContactAddressLinkLocationInner
                contact={values['scheduling_inspection_contact']}
                linkText="Use Contact for Scheduling Inspection Address"
              />
            ) : undefined
          }
          countriesConfigurationKey="vehicle_location"
        />
      </Grid>
      <Grid item xs={6}>
        <ContactTextFieldFormik
          id="attorney_contact"
          label="Attorney"
          className={classes.textField}
          showOnly={showOnly}
          acceptedRoles={['attorney']}
          fixedSearchResults
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id="attorney_reference_number"
          label="Attorney Reference Number"
          className={classes.textField}
          showOnly={showOnly}
          fullWidth
        />
      </Grid>
      {/* TODO Coupled with Damage Areas - total loss */}
      {(isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.UK_FNOL_FIELDS_MM_STYLE) ||
        isMarshmallowPolicy(policy)) && (
        <>
          <Grid item container xs={6}>
            <Grid item xs={7}>
              <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', fontSize: 13 }}>
                Did the car have any existing damage?
              </span>
            </Grid>
            <Grid item xs={5}>
              <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                <RadioButtonFormik
                  id="extra.existing_damage"
                  label={<span style={{ fontSize: 13 }}>No</span>}
                  optionValue={false}
                  size="small"
                  disabled={disabled || showOnly}
                />
                <RadioButtonFormik
                  id="extra.existing_damage"
                  label={<span style={{ fontSize: 13 }}>Yes</span>}
                  optionValue={true}
                  size="small"
                  disabled={disabled || showOnly}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item container xs={6} spacing={1}>
            <Grid item xs={6}>
              <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', fontSize: 13 }}>
                Customer happy to use our approved repairer?
              </span>
            </Grid>
            <Grid item xs={6}>
              <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                <RadioButtonFormik
                  id="extra.use_approved_provider"
                  label={<span style={{ fontSize: 13 }}>No</span>}
                  size="small"
                  optionValue={false}
                  disabled={disabled || showOnly}
                />
                <RadioButtonFormik
                  id="extra.use_approved_provider"
                  label={<span style={{ fontSize: 13 }}>Yes</span>}
                  optionValue={true}
                  size="small"
                  disabled={disabled || showOnly}
                />
              </div>
            </Grid>
            <ErrorHelperTextFormik style={{ marginTop: 0 }} id="extra.use_approved_provider" />
          </Grid>
          {values.extra?.existing_damage === true && (
            <Grid item xs={12}>
              <TextFieldFormik
                id="extra.existing_damage_description"
                label="Existing Damages Description"
                fullWidth
                className={classes.textField}
                showOnly={showOnly}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextFieldFormik
              id="extra.damage_level"
              label="Damage Level"
              className={classes.textField}
              fullWidth
              select
            >
              {MARSHMALLOW_VEHICLE_DAMAGE_LEVELS_LIST.map((damage_level) => (
                <MenuItem key={damage_level} value={damage_level}>
                  {damage_level}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        </>
      )}
      {!isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL) && (
        <Grid item xs={12}>
          <MultiSelectTextFieldFormik
            id="damages"
            label="Damage Areas"
            options={Object.keys(vehicle_damaged_car_parts_dict)}
            renderValue={(selected) => selected.map((val) => vehicle_damaged_car_parts_dict[val]).join(', ')}
            renderOption={(val) => vehicle_damaged_car_parts_dict[val]}
            className={classes.textField}
            fullWidth
            showOnly={showOnly}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {isInshurUkPolicy(policy) ? (
          <TextFieldFormik
            id="damage_description"
            label="Damage Description"
            fullWidth
            className={classes.textField}
            showOnly={showOnly}
            select
          >
            {['Minor', 'Medium', 'Severe'].map((damageDesc) => (
              <MenuItem key={damageDesc} value={damageDesc}>
                {damageDesc}
              </MenuItem>
            ))}
          </TextFieldFormik>
        ) : (
          <TextFieldFormik
            id="damage_description"
            label="Damage Description"
            fullWidth
            className={classes.textField}
            showOnly={showOnly}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik id="note" label="Note" fullWidth multiline className={classes.textField} showOnly={showOnly} />
      </Grid>
      {buttonsComponent && (
        <Grid item xs={12}>
          <div className={classes.buttonsContainer}>{buttonsComponent}</div>
        </Grid>
      )}
    </Grid>
  );
}

VehicleInvolvedFragment.propTypes = {
  isInsured: PropTypes.bool,
  buttonsComponent: PropTypes.node,
  disabled: PropTypes.bool,
  disableEditIdentity: PropTypes.bool,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
};

function FillContactAddressLinkLocationInner({ contact, linkText }) {
  // this is called from the internal formik of LocationFormik
  const { setValues } = useFormikContext();

  return (
    <Link
      component="button"
      variant="subtitle1"
      style={{ textDecoration: 'underline' }}
      onClick={() => {
        const schedulingInspectionContact = contact;
        const contactLocation = {
          address1: schedulingInspectionContact.street_address1,
          address2: schedulingInspectionContact.street_address2,
          city: schedulingInspectionContact.city,
          state: schedulingInspectionContact.state,
          country: schedulingInspectionContact.country,
          zipcode: schedulingInspectionContact.zipcode,
        };

        setValues(contactLocation);
      }}
    >
      {linkText}
    </Link>
  );
}

FillContactAddressLinkLocationInner.propTypes = {
  contact: PropTypes.object.isRequired,
  linkText: PropTypes.string.isRequired,
};

function EditVehicleDialog(props) {
  const { policy } = usePolicy();
  const { claim } = useClaim(); // May be undefined

  const { vehicleInvolved, onCancel, onSaveVehicleDetails, onDeleteVehicle, isInsured } = props;

  let initialValues = { ...vehicleInvolvedFields, ...vehicleInvolved };
  if (isInsured) {
    const vehicle_id = vehicleInvolved.vehicle.id; // insured vehicle must be set
    const coveredVehicle =
      policy.covered_vehicles && policy.covered_vehicles.find((nd) => nd.vehicle.id === vehicle_id);
    if (coveredVehicle) {
      initialValues = { ...initialValues, covered_vehicle_id: coveredVehicle.id };
    } else {
      // Unlisted or rental
      if (claim) {
        // In FNOL there is no claim, but covered_vehicle_id is defined in vehicleInvolved
        initialValues = { ...initialValues, covered_vehicle_id: claim.is_unlisted_vehicle_rental ? -1 : 0 };
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getVehicleInvolvedValidationSchema(isInsured, policy)}
      onSubmit={(values, formikProps) => {
        onSaveVehicleDetails(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const dialogAction = onDeleteVehicle && (
          <WithConfirm title="Delete Vehicle?" primaryButtonName="Delete" shouldCloseOnPrimary={false}>
            <FsIconButton onClick={onDeleteVehicle} disabled={isSubmitting} icon={TrashIcon} />
          </WithConfirm>
        );

        const buttonsComponentEdit = (
          <Fragment>
            <CancelButton disabled={isSubmitting} onClick={onCancel} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Save
            </Button>
          </Fragment>
        );

        return (
          <CardDialog
            isDialog={true}
            title="Edit Vehicle"
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
            action={dialogAction}
          >
            <VehicleInvolvedFragment
              isInsured={isInsured}
              disableEditIdentity={
                vehicleInvolved.incident_party_id &&
                findIncidentParty(claim, vehicleInvolved.incident_party_id).is_locked
              }
              buttonsComponent={buttonsComponentEdit}
              {...formikProps}
            />
          </CardDialog>
        );
      }}
    </Formik>
  );
}

EditVehicleDialog.propTypes = {
  vehicleInvolved: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveVehicleDetails: PropTypes.func.isRequired,
  onDeleteVehicle: PropTypes.func,
  isInsured: PropTypes.bool,
};

function ShowOnlyVehicleDialog(props) {
  const { policy } = usePolicy();
  const { claim } = useClaim(); // May be undefined

  const { vehicleInvolved, onCancel, isInsured } = props;

  let initialValues = { ...vehicleInvolved };
  if (isInsured && policy) {
    const vehicle_id = vehicleInvolved.vehicle.id; // insured vehicle must be set
    const coveredVehicle = policy.covered_vehicles.find((nd) => nd.id === vehicle_id);
    if (coveredVehicle) {
      initialValues = { ...initialValues, covered_vehicle_id: coveredVehicle.id };
    } else {
      if (claim && claim.is_unlisted_vehicle_rental) {
        initialValues = { ...initialValues, covered_vehicle_id: -1 };
      } else {
        initialValues = { ...initialValues, covered_vehicle_id: 0 };
      }
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {(formikProps) => (
        <CardDialog isDialog={true} title="Vehicle Details" maxWidth="sm" onClose={onCancel} closeOnBackdropClick>
          <VehicleInvolvedFragment isInsured={isInsured} {...formikProps} showOnly />
        </CardDialog>
      )}
    </Formik>
  );
}

ShowOnlyVehicleDialog.propTypes = {
  vehicleInvolved: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  isInsured: PropTypes.bool,
};

function AddVehicleDialog(props) {
  const { isInsured, onCancel, onSaveVehicleDetails } = props;
  const { policy } = usePolicy();

  const coveredVehicles = policy.covered_vehicles;

  let initialValues = { ...vehicleInvolvedFields, vehicle: { ...vehicleFields } };
  if (isInsured && coveredVehicles) {
    const overNightAddress = coveredVehicles[0].vehicle_extra && coveredVehicles[0].vehicle_extra.overnight_address;
    const overNightAddressString = overNightAddress
      ? `Overnight address: ${Object.keys(overNightAddress)
          .map((key) => `${key}: ${overNightAddress[key]}`)
          .join(', ')}`
      : '';

    initialValues = {
      ...initialValues,
      note: overNightAddressString,
      vehicle: { ...vehicleFields, ...coveredVehicles[0].vehicle },
      is_dashcam_installed: coveredVehicles[0].is_dashcam_installed ?? '',
      covered_vehicle_id: coveredVehicles[0].id,
      owner_contact_id: coveredVehicles[0].vehicle.vehicle_owner_contact?.id || policy.insured_contact.id,
      owner_contact_full_name:
        coveredVehicles[0].vehicle.vehicle_owner_contact?.full_name || policy.insured_contact.full_name,
    };
  } else if (policy.is_manual) {
    initialValues.covered_vehicle_id = 0;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getVehicleInvolvedValidationSchema(isInsured, policy)}
      onSubmit={(values, formikProps) => {
        onSaveVehicleDetails(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const buttonsComponentEdit = (
          <Fragment>
            <CancelButton disabled={isSubmitting} onClick={onCancel} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Add
            </Button>
          </Fragment>
        );

        return (
          <CardDialog isDialog={true} title="Add Vehicle" maxWidth="sm" onClose={onCancel} preventClose={isSubmitting}>
            <VehicleInvolvedFragment isInsured={isInsured} buttonsComponent={buttonsComponentEdit} {...formikProps} />
          </CardDialog>
        );
      }}
    </Formik>
  );
}

AddVehicleDialog.propTypes = {
  isInsured: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveVehicleDetails: PropTypes.func.isRequired,
};

function VehicleDisplayName(vehicle) {
  return [vehicle.year, vehicle.make, vehicle.model, vehicle.series].join(' ');
}

function VehicleInvolvedDetails(props) {
  const [editVehicleDialogOpen, setEditVehicleDialogOpen] = React.useState(false);
  const [addVehicleDialogOpen, setAddVehicleDialogOpen] = React.useState(false);

  function filterOutPassengerValues(values) {
    let filteredValues = {};
    Object.keys(vehicleInvolvedFields).forEach((k) => (filteredValues[k] = values[k]));
    return filteredValues;
  }

  const {
    classes,
    disabled,
    isInsured,
    vehicleInvolved,
    onDeleteVehicle,
    onSetVehicleDetails,
    onSaveVehicleDetails,
    error,
  } = props;

  return (
    <Fragment>
      <Grid container spacing={0}>
        <Grid item xs={9}>
          {vehicleInvolved ? (
            <div className={classes.containerCentered}>
              <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
                <InvolvedWrapper
                  onEdit={() => setEditVehicleDialogOpen(true)}
                  disabled={disabled}
                  involved={vehicleInvolved}
                  involvedType="property"
                >
                  {VehicleDisplayName(vehicleInvolved.vehicle)}
                </InvolvedWrapper>
              </RestrictedPermissions>
            </div>
          ) : (
            <Grid item xs={6}>
              <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
                <FsButton color="primary" disabled={disabled} onClick={() => setAddVehicleDialogOpen(true)}>
                  Set Vehicle
                </FsButton>
              </RestrictedPermissions>
            </Grid>
          )}
          {error && <FormHelperText error>{error}</FormHelperText>}
        </Grid>
      </Grid>
      {addVehicleDialogOpen && (
        <AddVehicleDialog
          isInsured={isInsured}
          onCancel={() => setAddVehicleDialogOpen(false)}
          onSaveVehicleDetails={async (values) => {
            await onSetVehicleDetails(filterOutPassengerValues(values));
            setAddVehicleDialogOpen(false);
          }}
        />
      )}
      {editVehicleDialogOpen && vehicleInvolved && (
        <EditVehicleDialog
          classes={classes}
          vehicleInvolved={vehicleInvolved}
          isInsured={isInsured}
          onSaveVehicleDetails={async (values) => {
            await onSaveVehicleDetails(filterOutPassengerValues(values));
            setEditVehicleDialogOpen(false);
          }}
          onDeleteVehicle={
            onDeleteVehicle &&
            (async () => {
              await onDeleteVehicle();
              setEditVehicleDialogOpen(false);
            })
          }
          onCancel={() => setEditVehicleDialogOpen(false)}
        />
      )}
    </Fragment>
  );
}

VehicleInvolvedDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isInsured: PropTypes.bool,
  vehicleInvolved: PropTypes.object,
  onSetVehicleDetails: PropTypes.func.isRequired,
  onSaveVehicleDetails: PropTypes.func.isRequired,
  onDeleteVehicle: PropTypes.func,
  error: PropTypes.string,
};

function VehicleIdentityFragment(props) {
  const { disableVehicleEditing, showOnly, disableEditOwner, hideOwner, isInsured, shouldIncludeMileage } = props;
  const classes = useStyles();
  const { vehicleTypesDict } = useOrganization();

  return (
    <>
      <Grid item xs={2}>
        <YearPickerSelectTextFieldFormik
          id="vehicle.year"
          label="Year"
          fullWidth
          disabled={disableVehicleEditing}
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldFormik
          id="vehicle.make"
          label="Make"
          fullWidth
          disabled={disableVehicleEditing}
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik
          id="vehicle.model"
          label="Model"
          fullWidth
          disabled={disableVehicleEditing}
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldFormik
          id="vehicle.series"
          label="Series"
          fullWidth
          disabled={disableVehicleEditing}
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id="vehicle.vin"
          label="VIN"
          fullWidth
          disabled={disableVehicleEditing}
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id="vehicle.plate_number"
          label="Plate Number"
          fullWidth
          disabled={disableVehicleEditing}
          className={classes.textField}
          showOnly={showOnly}
        />
      </Grid>
      {!hideOwner && (
        <Grid item xs={shouldIncludeMileage ? 5 : 6}>
          <ContactTextFieldFormik
            id="owner_contact"
            label="Owner"
            className={classes.textField}
            acceptedRoles={
              isInsured ? ['insured', 'named_driver', 'spouse', 'family_member', 'other'] : ['claimant', 'other']
            }
            contactSearchProps={isInsured ? undefined : { newContactRole: 'claimant' }}
            disabled={disableEditOwner}
            showOnly={showOnly}
            fixedSearchResults
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={shouldIncludeMileage ? 4 : 6}>
        <TextFieldFormik
          id="vehicle.vehicle_type"
          label="Vehicle Type"
          select
          fullWidth
          disabled={disableVehicleEditing}
          className={classes.textField}
          showOnly={showOnly}
        >
          {Object.keys(vehicleTypesDict).map((vehicleType) => (
            <MenuItem key={vehicleType} value={vehicleType}>
              {vehicleTypesDict[vehicleType].desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      {shouldIncludeMileage && (
        <Grid item xs={3}>
          <TextFieldFormik
            id="mileage"
            label="Mileage"
            type="number"
            showOnly={showOnly}
            className={classes.textField}
            fullWidth
          />
        </Grid>
      )}
    </>
  );
}

VehicleIdentityFragment.propTypes = {
  showOnly: PropTypes.bool,
  disableEditOwner: PropTypes.bool,
  disableVehicleEditing: PropTypes.bool,
  shouldIncludeMileage: PropTypes.bool,
  hideOwner: PropTypes.bool,
  isInsured: requiredIf(PropTypes.bool, (props) => !props.hideOwner),
};

function PolicyVehicleIdentityFragment() {
  const classes = useStyles();
  const { vehicleTypesDict } = useOrganization();
  const { values } = useFormikContext();
  const vehicle_owner_contact = getField('vehicle_owner_contact');
  const ownership_status = getField('ownership_status');
  const vehicle_use = getField('vehicle_use');
  const vehicle_mileage = getField('mileage');
  const vehicle_value = getField('vehicle_value');
  const vehicle_cost_new = getField('vehicle_cost_new');
  const fuel_type = getField('fuel_type');
  const vehicle_weight = getField('vehicle_weight');
  const engine_type = getField('engine_type');
  const engine_capacity = getField('engine_capacity');
  const is_vehicle_camera_installed = getField('is_vehicle_camera_installed');
  const existing_damage = getField('existing_damage');
  const claim_free_years = getField('claim_free_years');

  function getField(field) {
    if (values.vehicle?.[field] === '' || values.vehicle?.[field] === null) {
      return values.vehicle_extra?.vehicle_orig?.[field];
    } else {
      return values.vehicle?.[field];
    }
  }

  return (
    <Grid container xs={12} className={classes.spacedTextFieldWrapper}>
      <>
        {(vehicle_owner_contact || ownership_status) && (
          <>
            <Grid item xs={10}>
              <Typography display="block" className={classes.h4}>
                Owner Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ContactTextFieldFormik
                id="vehicle.vehicle_owner_contact"
                label="Owner"
                className={classes.textField}
                acceptedRoles={['insured', 'named_driver', 'spouse', 'family_member', 'other']}
                contactSearchProps={undefined}
                disabled
                showOnly
                fullWidth
              />
            </Grid>
            {ownership_status && (
              <Grid item xs={12}>
                <TextFieldFormik
                  value={ownership_status}
                  label="Ownership Status"
                  fullWidth
                  className={classes.textField}
                  showOnly
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          <Typography display="block" className={classes.h4}>
            Vehicle Details
          </Typography>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={3}>
            <YearPickerSelectTextFieldFormik
              id="vehicle.year"
              label="Year"
              fullWidth
              className={classes.textField}
              showOnly
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldFormik id="vehicle.make" label="Make" fullWidth className={classes.textField} showOnly />
          </Grid>
          <Grid item xs={3}>
            <TextFieldFormik id="vehicle.model" label="Model" fullWidth className={classes.textField} showOnly />
          </Grid>
          <Grid item xs={3}>
            <TextFieldFormik id="vehicle.series" label="Series" fullWidth className={classes.textField} showOnly />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={3}>
            <TextFieldFormik id="vehicle.vin" label="VIN" fullWidth className={classes.textField} showOnly />
          </Grid>
          <Grid item xs={9}>
            <TextFieldFormik
              id="vehicle.plate_number"
              label="Plate Number"
              fullWidth
              className={classes.textField}
              showOnly
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik
            id="vehicle.vehicle_type"
            label="Vehicle Type"
            select
            fullWidth
            className={classes.textField}
            showOnly
          >
            {Object.keys(vehicleTypesDict).map((vehicleType) => (
              <MenuItem key={vehicleType} value={vehicleType}>
                {vehicleTypesDict[vehicleType].desc}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
        <Grid container xs={12}>
          {vehicle_use && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={vehicle_use}
                label="Vehicle Use"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
          {vehicle_mileage && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={vehicle_mileage}
                label="Vehicle Mileage"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
        </Grid>
        <Grid container xs={12}>
          {vehicle_value && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={vehicle_value}
                label="Vehicle Value"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
          {vehicle_cost_new && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={vehicle_cost_new}
                label="New Vehicle Value"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
        </Grid>
        <Grid container xs={12}>
          {fuel_type && (
            <Grid item xs={3}>
              <TextFieldFormik value={fuel_type} label="Fuel Type" fullWidth className={classes.textField} showOnly />
            </Grid>
          )}
          {vehicle_weight && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={vehicle_weight}
                label="Vehicle Weight"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
        </Grid>
        <Grid container xs={12}>
          {engine_type && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={engine_type}
                label="Engine Type"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
          {engine_capacity && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={engine_capacity}
                label="Engine Capacity"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
          {is_vehicle_camera_installed !== undefined && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={is_vehicle_camera_installed ? 'Installed' : 'Not Installed'}
                label="Dash Cam"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
        </Grid>
        <Grid container xs={12}>
          {existing_damage && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={existing_damage}
                label="Existing Damage"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
          {claim_free_years && (
            <Grid item xs={3}>
              <TextFieldFormik
                value={claim_free_years}
                label="Claim Free Years"
                fullWidth
                className={classes.textField}
                showOnly
              />
            </Grid>
          )}
        </Grid>
      </>
    </Grid>
  );
}

function ShowOnlyVehicleDetailsInfoDialog(props) {
  const { vehicle, open, onClose } = props;
  const { policySearchConfig } = useOrganization();
  const policyApiConfiguration = policySearchConfig.policy_api_configuration;

  return (
    <Formik initialValues={{ ...vehicle }} onSubmit={() => {}}>
      {() => {
        return (
          <>
            {policyApiConfiguration?.enabled ? (
              <CardDialog isDialog open={open} title="Vehicle Info" maxWidth="md" fullWidth onClose={onClose}>
                <Grid container spacing={1}>
                  <PolicyVehicleIdentityFragment isInsured showOnly disableEditOwner />
                </Grid>
              </CardDialog>
            ) : (
              <CardDialog isDialog open={open} title="Vehicle Info" maxWidth="sm" onClose={onClose}>
                <Grid container spacing={1}>
                  <VehicleIdentityFragment showOnly hideOwner />
                </Grid>
              </CardDialog>
            )}
          </>
        );
      }}
    </Formik>
  );
}

ShowOnlyVehicleDetailsInfoDialog.propTypes = {
  vehicle: requiredIf(PropTypes.object, (props) => props.open),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

function EditVehicleDetailsInfoDialog(props) {
  const { vehicle, open, onClose, OnSaveVehicleDetails } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ ...vehicle }}
      validationSchema={Yup.object().shape(vehicleValidationSchema)}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await OnSaveVehicleDetails(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ resetForm, handleSubmit, isSubmitting }) => {
        return (
          <CardDialog
            isDialog
            open={open}
            title="Vehicle Info"
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <VehicleIdentityFragment hideOwner />
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton
                disabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

EditVehicleDetailsInfoDialog.propTypes = {
  vehicle: requiredIf(PropTypes.object, (props) => props.open),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  OnSaveVehicleDetails: PropTypes.func.isRequired,
};

export {
  AddVehicleDialog,
  EditVehicleDetailsInfoDialog,
  FillContactAddressLinkLocationInner,
  ShowOnlyVehicleDetailsInfoDialog,
  ShowOnlyVehicleDialog,
  vehicleInvolvedFields,
};
export default VehicleInvolvedDetails;
