import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';

import DocumentsAttachment from './DocumentsAttachment';

const FormikDocumentsAttachment = (props) => {
  const { id, maxHeight, documentsAttachmentProps } = props;
  const { values, setFieldValue } = useFormikContext();
  return (
    <>
      <DocumentsAttachment
        attachedDocuments={values[id]}
        onUpdateAttachedDocuments={(attachedDocuments) => setFieldValue(id, attachedDocuments)}
        maxHeight={maxHeight}
        {...documentsAttachmentProps}
      />
      <ErrorHelperTextFormik id={id} />
    </>
  );
};

FormikDocumentsAttachment.propTypes = {
  id: PropTypes.string.isRequired,
  maxHeight: PropTypes.string,
  documentsAttachmentProps: PropTypes.object,
};

export default FormikDocumentsAttachment;
