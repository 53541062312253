import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import useFetchExposure from '~/components/exposures/useFetchExposure';

import { serverDateTimeToLocalDate } from '../../DateTimeUtils';
import { isUserReadOnly } from '../../UserUtils';
import { reportErrorInProductionOrThrow } from '../../Utils';
import { useFetchClaim } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import PaymentRequestContainer from '../exposures/PaymentRequestContainer/PaymentRequestContainer';
import { useCms } from '../hooks/useCms';
import LoadingDialog from '../LoadingDialog';
import LoadingIndicator from '../LoadingIndicator';

function UploadAndPayInvoiceContainer({ invoiceData, attachment, getAttachmentFileUrl, claimId, onUploadDocument }) {
  const { user } = useCms();
  const [showUploadAndPayInvoice, setShowUploadAndPayInvoice] = useState(false);

  if (invoiceData.payable_type !== 'expenses') {
    reportErrorInProductionOrThrow(
      `UploadAndPayInvoiceContainer only support expenses for now, and not: ${invoiceData.payable_type}`
    );
    return <></>;
  }

  if (!['claimsolution_damage_assessment_invoice', 'medsource_invoice'].includes(invoiceData.name)) {
    reportErrorInProductionOrThrow(
      `UploadAndPayInvoiceContainer called for unsupported recommendation name:${invoiceData.name}`
    );
    return <></>;
  }

  return (
    <>
      <Button
        style={{ padding: '0px' }}
        color="primary"
        onClick={() => setShowUploadAndPayInvoice(true)}
        disabled={isUserReadOnly(user)}
      >
        Upload and Pay
      </Button>
      {showUploadAndPayInvoice && (
        <UploadAndPayInvoiceDialog
          invoiceData={invoiceData}
          attachment={attachment}
          getAttachmentFileUrl={getAttachmentFileUrl}
          claimId={claimId}
          exposureId={invoiceData.exposure_id}
          onClose={() => setShowUploadAndPayInvoice(false)}
          onUpdate={async (paymentRequest) => {
            await onUploadDocument(
              {
                type: invoiceData.document_type,
                document_name: invoiceData.document_name,
                document_date: serverDateTimeToLocalDate(attachment.stored_file.datetime_uploaded),
                exposure_ids: [invoiceData.exposure_id],
                summary: invoiceData.document_summary,
                recommendation_extra: {
                  recommendation_id: invoiceData.recommendation_id,
                  recommendation_type: invoiceData.type,
                  recommendation_name: invoiceData.recommendation_name,
                  recommendation_orig_communication_id: invoiceData.recommendation_orig_communication_id,
                  payment_request_id: paymentRequest.id,
                },
              },
              invoiceData.attachment_id
            );
          }}
        />
      )}
    </>
  );
}

UploadAndPayInvoiceContainer.propTypes = {
  invoiceData: PropTypes.object.isRequired,
  attachment: PropTypes.object.isRequired,
  getAttachmentFileUrl: PropTypes.func.isRequired,
  claimId: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUploadDocument: PropTypes.func.isRequired,
};

function UploadAndPayInvoiceDialog({ invoiceData, attachment, getAttachmentFileUrl, claimId, onClose, onUpdate }) {
  const [claim, isLoadingClaim, isErrorClaim, reloadClaim] = useFetchClaim(claimId);
  const { isLoadingExposure, isErrorExposure, exposure } = useFetchExposure(claimId, invoiceData.exposure_id);
  const [isLoadingPdf, setIsLoadingPdf] = useState(true);

  const isLoading = isLoadingClaim || isLoadingExposure;
  const isError = isErrorClaim || isErrorExposure;

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} track="isLoading Claim/Exposure In UploadAndPayInvoiceDialog" />;
  }

  const vendorPayee = claim.contacts.find((contact) => contact.id === invoiceData.contact_id);

  return (
    <CardDialog isDialog title="Upload and Pay invoice" onClose={onClose} maxWidth="xl" fullWidth>
      <Grid container>
        <Grid item xs={4}>
          <PaymentRequestContainer
            claim={claim}
            exposure={exposure}
            payableWithReserve={exposure.expenses}
            payableType="expenses"
            cardDialogProps={{
              isDialog: false,
              maxWidth: 'sm',
              fullWidth: true,
            }}
            onUpdate={async (paymentRequest) => {
              await onUpdate(paymentRequest);
              await reloadClaim();
              onClose();
            }}
            overrideInitialValues={{
              amount: invoiceData.total_amount,
              vendor_payee_id: invoiceData.contact_id,
              invoice_number: invoiceData.invoice_number,
              expense_type: invoiceData.expense_type,
              vendor_payee: vendorPayee,
              recommendation_extra: {
                recommendation_id: invoiceData.recommendation_id,
                recommendation_type: invoiceData.type,
                recommendation_name: invoiceData.name,
                recommendation_orig_communication_id: invoiceData.recommendation_orig_communication_id,
              },
            }}
          />
        </Grid>
        <Grid item xs={8}>
          {isLoadingPdf && <LoadingIndicator />}
          <object
            onLoad={() => setIsLoadingPdf(false)}
            data={getAttachmentFileUrl(attachment)}
            style={{ height: '85vh', width: '100%', zIndex: '1' }}
            type="application/pdf"
          >
            Invoice
          </object>
        </Grid>
      </Grid>
    </CardDialog>
  );
}

UploadAndPayInvoiceDialog.propTypes = {
  invoiceData: PropTypes.object.isRequired,
  attachment: PropTypes.object.isRequired,
  getAttachmentFileUrl: PropTypes.func.isRequired,
  claimId: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UploadAndPayInvoiceContainer;
