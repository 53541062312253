import React from 'react';

import useDataFetcher from '~/components/useDataFetcher';

import type { OrganizationUnit } from './types';

interface FetchOrgUnitsResult {
  isLoading: boolean;
  isError: boolean;
  units: OrganizationUnit[];
  isUnitLeader: (userId: string) => boolean;
}

const ORG_UNITS_URL = `/api/v1/org_units`;

export default function useFetchOrgUnits(config: { disabled?: boolean }): FetchOrgUnitsResult {
  config = { disabled: false, ...config };
  const { isLoading, isError, data } = useDataFetcher(ORG_UNITS_URL, undefined, !config.disabled);
  const units = data as OrganizationUnit[];

  const isUnitLeader = React.useCallback(
    (userId) => {
      if (isLoading || isError || !units) {
        return false;
      }
      return units.some((unit) => unit.leader_user_id === userId);
    },
    [isLoading, isError, units]
  );

  return { isLoading, isError, units, isUnitLeader };
}
