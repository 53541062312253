import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import SendEmailCommunicationCardContainer from '~/components/communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import { useCms } from '~/components/hooks/useCms';
import LoadingDialog from '~/components/LoadingDialog';

import { useReplyEmailText } from '../hooks/useReplyEmailText';

// We allow to replay to email not related to claim. example use case: we want to ask the sender to which claim it's related
// For sure we don't know the claim (communication.claim_id). communication.contact & communication.contact_email may be valid or none, but we'll ignore it anyway
const ReplyNonClaimEmailCommunicationDialog = ({
  communication,
  onClose,
  onReply,
  overrideShowWarning = true,
  shouldDisplaySenderEmail = true,
  onMinimized,
  disableMinimized,
  open,
  displayGeneralQueueActions = false,
  ccContacts,
}) => {
  const { user } = useCms();
  const classes = useStyles();
  const { emailText, isLoadingEmailSignature, isErrorEmailSignature } = useReplyEmailText(
    communication,
    user,
    displayGeneralQueueActions
  );

  // Starts with warning of unattached claim, before showing the reply email dialog
  const [showWarning, setShowWarning] = useState(overrideShowWarning);
  const [openReplyEmailCommunication, setOpenReplyEmailCommunication] = useState(!overrideShowWarning);

  if (isLoadingEmailSignature || isErrorEmailSignature) {
    return <LoadingDialog isError={isErrorEmailSignature} track="Reply Email" />;
  }

  return (
    <>
      {showWarning && (
        <CardDialog isDialog title="Reply to email?" open={open} onClose={onClose}>
          <Typography>
            Please note that the reply email will not be documented in the system as long as it is not attached to a
            specific claim
          </Typography>
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.leftButtonDialog}
              variant="contained"
              color="secondary"
              onClick={() => {
                onClose();
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowWarning(false);
                setOpenReplyEmailCommunication(true);
              }}
            >
              Continue anyway
            </Button>
          </div>
        </CardDialog>
      )}
      {openReplyEmailCommunication && (
        <SendEmailCommunicationCardContainer
          forcedRecipient={{
            emailAddress: communication.specific_identifier,
            optionalContact: communication.contact, // may be undefined
            optionalContactEmail: communication.contact_email, // may be undefined
          }}
          isNotClaimRelated={!communication?.claim_id}
          onClose={onClose}
          onSendEmail={onClose}
          onMinimized={onMinimized}
          disableMinimized={disableMinimized}
          open={open}
          emailText={emailText}
          emailTitle={`Re: ${communication.subject}`}
          contact={communication.contact}
          onSubmitOverride={onReply}
          referredCommunicationId={communication.id}
          communication={communication}
          shouldDisplaySenderEmail={shouldDisplaySenderEmail}
          cc_contacts={ccContacts}
        />
      )}
    </>
  );
};

ReplyNonClaimEmailCommunicationDialog.propTypes = {
  communication: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onReply: PropTypes.func,
  overrideShowWarning: PropTypes.bool,
  shouldDisplaySenderEmail: PropTypes.bool,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
  open: PropTypes.bool,
  displayGeneralQueueActions: PropTypes.bool,
  ccContacts: PropTypes.array,
};

export default ReplyNonClaimEmailCommunicationDialog;
