import React from 'react';
import PropTypes from 'prop-types';

import { FsTooltip } from '../';

const disableDescendants = (children) => {
  const disableChild = (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    return React.cloneElement(child, {
      disabled: true,
      children: disableDescendants(child.props.children),
    });
  };

  if (Array.isArray(children)) {
    return React.Children.map(children, disableChild);
  }

  return disableChild(children);
};

const DisabledChildrenWrapperWithTooltip = ({ children, tooltipText, key }) => {
  if (!children) {
    return null;
  }

  const modifiedChildren = disableDescendants(children);
  return (
    <FsTooltip title={tooltipText} key={key}>
      <span>{modifiedChildren}</span>
    </FsTooltip>
  );
};

DisabledChildrenWrapperWithTooltip.propTypes = {
  children: PropTypes.node,
  tooltipText: PropTypes.string.isRequired,
  key: PropTypes.string,
};

export default DisabledChildrenWrapperWithTooltip;
