import React from 'react';

import { LoadingSwitch } from '../../../core';
import useDataFetcher from '../../../useDataFetcher';
import { useLegalAction } from '../../hooks/useLegalAction';
import { useLegalActionsBackendRoutes } from '../../hooks/useLegalActionsBackendRoutes';
import type {
  DocumentApiResponse,
  LegalActionLogApiResponse,
  NegotiationLogApiResponse,
  ProsAndConsApiResponse,
} from '../../types';

import { LegalActionDocumentsCard } from './Documents/LegalActionDocumentsCard';
import { LegalActionLogsCard } from './Logs/LegalActionLogsCard';
import { NegotiationLogsCard } from './Negotiations/NegotiationLogsCard';
import { NotesCard } from './Notes/NotesCard';
import { ProsAndConsCard } from './ProsAndCons/ProsAndConsCard';

export const LogsTab: React.FC = () => {
  const { legalAction } = useLegalAction();
  const backendRoutes = useLegalActionsBackendRoutes(legalAction.id);

  const {
    data: logs,
    isLoading: isLogsLoading,
    isError: isLogsError,
    reloadData: reloadLogs,
  } = useDataFetcher(backendRoutes.legalAction.logs.base);

  const {
    data: documents,
    isLoading: isDocumentsLoading,
    isError: isDocumentsError,
    reloadData: reloadDocuments,
  } = useDataFetcher(backendRoutes.legalAction.documents);

  return (
    <>
      <LoadingSwitch isLoading={isLogsLoading || !legalAction} isError={isLogsError}>
        <div className="flex w-1/2 flex-col gap-24">
          <LegalActionLogsCard logs={logs as LegalActionLogApiResponse[]} reloadLogs={reloadLogs} />
          <ProsAndConsCard logs={logs as ProsAndConsApiResponse[]} reloadLogs={reloadLogs} />
          <NegotiationLogsCard logs={logs as NegotiationLogApiResponse[]} reloadLogs={reloadLogs} />
          <LoadingSwitch isLoading={isDocumentsLoading} isError={isDocumentsError}>
            <LegalActionDocumentsCard
              documents={documents as DocumentApiResponse[]}
              reloadDocuments={reloadDocuments}
            />
          </LoadingSwitch>
        </div>
        <div className="flex w-1/2 flex-col gap-24">
          <NotesCard />
        </div>
      </LoadingSwitch>
    </>
  );
};
