import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { ExposureDetailedTitle } from '~/components/exposures/ExposureDetailedTitle';

import { serverDateToLocalMoment } from '../../DateTimeUtils';
import CardDialog from '../CardDialog';
import { useLob } from '../hooks/useLob';
import { ShowOnlyTextField } from '../TextFieldFormik';

import CoveredEntityField from './CoveredEntityField';

import { useStyles } from '../../assets/styles';

function ExposureShowMoreDialog(props) {
  const { exposure, onClose } = props;
  const classes = useStyles();
  const { claimType } = useLob();
  const isGC = claimType === 'general_claim';

  return (
    <CardDialog isDialog open title="Exposure More info" fullWidth maxWidth="sm" onClose={onClose}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ExposureDetailedTitle exposure={exposure} />
        </Grid>
        <Grid item xs={6}>
          <ShowOnlyTextField
            label="Exposure ID"
            classes={classes}
            showOnlyValueComponent={exposure.claim_id_display_with_exposure_suffix}
          />
        </Grid>
        <Grid item xs={6}>
          {isGC && <CoveredEntityField exposure={exposure} />}
        </Grid>
        <Grid item xs={6}>
          <ShowOnlyTextField
            label="Date created"
            classes={classes}
            showOnlyValueComponent={serverDateToLocalMoment(exposure.date_created)}
          />
        </Grid>
        <Grid item xs={6}>
          <ShowOnlyTextField
            label="Coverage Group"
            classes={classes}
            showOnlyValueComponent={exposure.coverage_group_display_name ? exposure.coverage_group_display_name : 'N/A'}
          />
        </Grid>
        <Grid item xs={6}>
          <ShowOnlyTextField
            label="Date Closed"
            classes={classes}
            showOnlyValueComponent={exposure.is_closed ? serverDateToLocalMoment(exposure.date_closed) : 'N/A'}
          />
        </Grid>
        <Grid item xs={6}>
          <ShowOnlyTextField
            label="Close Reason"
            classes={classes}
            showOnlyValueComponent={exposure.close_reason ? exposure.close_reason : 'N/A'}
          />
        </Grid>
      </Grid>
    </CardDialog>
  );
}

ExposureShowMoreDialog.propTypes = {
  exposure: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExposureShowMoreDialog;
