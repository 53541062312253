import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, getIn, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import AutoCompleteFormik from '~/components/AutocompleteFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';

import { CONSTANTS, COUNTRIES_DICT } from '../../Types';
import { formatSortOrder, getPostalCodeYupTestParams, yupPhoneValidation } from '../../Utils';
import cn from '../../Utils/cn';
import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import ClaimsTable from '../ClaimsTable';
import { localeDetails } from '../CmsMain/globals';
import { Heading, Text } from '../core';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import TextFieldFormik, {
  DatePickerTextFieldFormik,
  MultiSelectTextFieldFormik,
  YearPickerSelectTextFieldFormik,
} from '../TextFieldFormik';
import { LobSelectFormik } from '../TPA/LOB/LobSelectFormik';
import SubOrganizationSelectFormik from '../TPA/SubOrganizations/SubOrganizationSelectFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';
import colors from '../../assets/colors.module.scss';

const DEFAULT_SORT_BY_COLUMN = { id: 'date_of_loss' };
const DEFAULT_CLAIMS_PER_PAGE = 15;
const ROWS_PER_PAGE_MIN = 10;
const ROWS_PER_PAGE_MAX = 30;

const FILTER_PARAMS_INITIAL_VALUES = {
  handling_adjuster: [],
  page: 0,
  rows_per_page: DEFAULT_CLAIMS_PER_PAGE,
  sort_by_column: DEFAULT_SORT_BY_COLUMN,
  status: '',
  sub_organization_id: '',
  lob: '',
  claim_date_of_loss_start_date: '',
  claim_date_of_loss_end_date: '',
  claim_reported_date_start_date: '',
  claim_reported_date_end_date: '',
  incident_type: '',
  claim_number: '',
  policy_number: '',
  contact_first_name: '',
  contact_last_name: '',
  contact_ssn: '',
  contact_company_name: '',
  vehicle_year: '',
  vehicle_make: '',
  vehicle_model: '',
  vehicle_vin: '',
  vehicle_plate_number: '',
  zipcode: '',
  phone_number: '',
  is_company: '',
  external_claim_number: '',
  email_address: '',
  policy_country: '',
};

const AdvancedClaimsSearchPage = () => {
  const [showResults, setShowResults] = useState(false);
  const [filterParams, setFilterParams] = useState(FILTER_PARAMS_INITIAL_VALUES);
  const { page, sort_by_column } = filterParams;
  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const config = { params: { ...filterParams, page: page + 1, sort_by_column: formatSortOrder(sort_by_column) } }; // page in the flask-SQLAlchemy is 1-based
  const { isLoading, data: { count, claims } = {} } = useDataFetcher(
    showResults ? '/api/v1/claims/advanced_claims_search' : undefined,
    config
  );

  const onSubmit = (values) => {
    if (values.handling_adjuster?.length > 1) {
      values.handling_adjuster = values.handling_adjuster.filter((adjuster_id) => adjuster_id !== 'All');
    }
    setIsSearchOpen(false);
    setShowResults(true);
    setFilterParams(values);
  };

  const onReset = (values) => {
    setShowResults(false);
    setFilterParams(values);
  };

  const onChangePage = (_, newPage) => {
    setFilterParams({
      ...filterParams,
      page: newPage,
    });
  };

  const onChangeRowsPerPage = (event) => {
    setFilterParams({
      ...filterParams,
      rows_per_page: parseInt(event.target.value),
      page: 0,
    });
  };

  const onSortByColumn = (_, sort_by_column) => {
    setFilterParams({
      ...filterParams,
      sort_by_column,
      page: 0, // reset the page when sorting
    });
  };

  return (
    <>
      <div>
        <CollapsibleWrapper
          title="Search Claims"
          actionCard
          noBorder
          open={isEmpty(claims) || isSearchOpen} //we add the isEmpty(claims) to insure that it collapses after the results are shown
          onCollapse={() => setIsSearchOpen((prevOpen) => !prevOpen)}
          unmountOnExit={false}
          isActionCardDrawer={false}
        >
          <AdvancedClaimsSearchDialog onSubmit={onSubmit} onReset={onReset} />
        </CollapsibleWrapper>
      </div>
      {isLoading && showResults && <LoadingIndicator />}
      {!isLoading && showResults && (
        <>
          {!isEmpty(claims) ? (
            <>
              <Text className="pt-20" weight={Text.WEIGHTS.MEDIUM} variant={Text.VARIANTS.LG}>
                Search Results
              </Text>
              <ClaimsTable
                claims={claims}
                openInNewTab
                hideLastTouchedDate
                paginationProps={{
                  page,
                  rowsPerPage: filterParams?.rows_per_page,
                  onChangePage,
                  count,
                  onChangeRowsPerPage,
                  rowsPerPageOptions: [
                    ROWS_PER_PAGE_MIN,
                    DEFAULT_CLAIMS_PER_PAGE,
                    CONSTANTS.DEFAULT_CLAIMS_PER_PAGE,
                    ROWS_PER_PAGE_MAX,
                  ],
                }}
                onSortByColumn={onSortByColumn}
              />
            </>
          ) : (
            <Text weight={Text.WEIGHTS.MEDIUM} variant={Text.VARIANTS.LG}>
              We couldn’t find any claims which match your current search, try refining your search
            </Text>
          )}
        </>
      )}
    </>
  );
};

AdvancedClaimsSearchPage.propTypes = {
  onReportNewFnol: PropTypes.func.isRequired,
};

const AdvancedClaimsSearchDialog = ({ onSubmit, onReset }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={FILTER_PARAMS_INITIAL_VALUES}
      validationSchema={Yup.object().shape({
        status: Yup.string().nullable(),
        lob: Yup.string().nullable(),
        claim_date_of_loss_start_date: Yup.date().nullable(),
        claim_date_of_loss_end_date: Yup.date().nullable(),
        claim_reported_date_start_date: Yup.date().nullable(),
        claim_reported_date_end_date: Yup.date().nullable(),
        external_claim_number: Yup.string().nullable(),
        incident_type: Yup.string().nullable(),
        claim_number: Yup.string().nullable(),
        policy_number: Yup.string().nullable(),
        contact_first_name: Yup.string().nullable(),
        contact_last_name: Yup.string().nullable(),
        contact_ssn: Yup.number().nullable(),
        contact_company_name: Yup.string().nullable(),
        vehicle_year: Yup.string().nullable(),
        vehicle_make: Yup.string().nullable(),
        vehicle_model: Yup.string().nullable(),
        vehicle_vin: Yup.string().nullable(),
        vehicle_plate_number: Yup.string().nullable(),
        email_address: Yup.string().nullable().email('Must be a valid email'),
        policy_country: Yup.string().nullable().oneOf(Object.keys(COUNTRIES_DICT)),
        zipcode: Yup.string()
          .nullable()
          .test(...getPostalCodeYupTestParams(false)),
        phone_number: yupPhoneValidation.nullable(),
        is_company: Yup.boolean().nullable(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      onReset={(values, { setSubmitting }) => {
        onReset(values);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => (
        <CardDialog containerStyle={{ background: colors.grey_02 }}>
          <div className="grid">
            <AdvancedClaimsSearchFiltersFormik />
            <div className="flex gap-12">
              <div className="mt-4">
                <Button onClick={resetForm} variant="contained" className={classes.cancelButton}>
                  Clear
                </Button>
              </div>
              <div>
                <Button
                  className={cn(classes.button, 'm-4, ml-20')}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
};

AdvancedClaimsSearchDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

const AdvancedClaimsSearchFiltersFormik = () => {
  const classes = useStyles();
  const { isSubmitting, values } = useFormikContext();
  const { subOrganizationEnabled, orgLevelSupportedLobs, isMultipleCountriesEnabled } = useOrganization();
  const { data: adjustersData = [] } = useDataFetcher('/api/v1/users/possible_adjusters', {});
  const possibleFileOwners = useMemo(
    () => [{ username: 'Unassigned FNOLs', id: null }, ...adjustersData],
    [adjustersData]
  );
  const possibleFileOwnersById = useMemo(
    () => possibleFileOwners.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
    [possibleFileOwners]
  );

  const subOrganizationIds = useMemo(() => {
    const subOrganization = getIn(values, 'sub_organization_id');
    return subOrganization ? [subOrganization] : [];
  }, [values]);

  const shouldShowSsn = localeDetails.locale.region === 'US';

  return (
    <div>
      <Heading variant={Heading.TYPES.H3}>General Details</Heading>
      <div className="mb-32 grid grid-cols-5 gap-32">
        <div>
          <MultiSelectTextFieldFormik
            id="handling_adjuster"
            label="File / Exposure owner"
            disabled={isSubmitting}
            options={Object.keys(possibleFileOwnersById)}
            renderValue={(selectedIds) =>
              selectedIds.map((selectedId) => possibleFileOwnersById[selectedId]?.username).join(', ')
            }
            renderOption={(val) => possibleFileOwnersById[val].username}
            className={classes.formTextFieldNoErrorSpacing}
            fullWidth
            sortAlphabetic
            addAllOption
            allOptionValue="All"
          />
        </div>

        {subOrganizationEnabled ? (
          <div>
            <SubOrganizationSelectFormik fieldId="sub_organization_id" disabled={isSubmitting} />
          </div>
        ) : null}

        <div>
          <LobSelectFormik fieldId="lob" subOrganizationIds={subOrganizationIds} disabled={isSubmitting} />
        </div>

        <div>
          <TextFieldFormik
            id="status"
            label="Status"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
            select
          >
            <MenuItem value={null}>All</MenuItem>
            <MenuItem value="open">Open</MenuItem>
            <MenuItem value="closed">Closed</MenuItem>
          </TextFieldFormik>
        </div>

        {isMultipleCountriesEnabled && (
          <div>
            <AutoCompleteFormik
              id="policy_country"
              label="Policy Country"
              className="-mt-2 ml-2 mr-2"
              options={Object.keys(COUNTRIES_DICT)}
              getOptionLabel={(countryKey) => COUNTRIES_DICT[countryKey]}
            />
          </div>
        )}
      </div>

      <Heading variant={Heading.TYPES.H3}>Claim Details</Heading>

      <div className="mb-32 grid grid-cols-5 gap-32">
        <div>
          <TextFieldFormik
            id="claim_number"
            label="Claim Number"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>
        <div>
          <TextFieldFormik
            id="external_claim_number"
            label="External Number"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>
        <div>
          <TextFieldFormik
            id="policy_number"
            label="Policy Number"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>
      </div>
      <div className="mb-32 grid grid-cols-5 gap-32">
        <div>
          <DatePickerTextFieldFormik
            id="claim_date_of_loss_start_date"
            label="Date of loss Start Date"
            className={classes.textField}
            disableFuture
            fullWidth
            disabled={isSubmitting}
            clearable
          />
        </div>

        <div>
          <DatePickerTextFieldFormik
            id="claim_date_of_loss_end_date"
            label="Date of loss End Date"
            className={classes.textField}
            disableFuture
            fullWidth
            disabled={isSubmitting}
            clearable
          />
        </div>

        <div>
          <DatePickerTextFieldFormik
            id="claim_reported_date_start_date"
            label="Report Start Date"
            className={classes.textField}
            disableFuture
            fullWidth
            disabled={isSubmitting}
            clearable
          />
        </div>

        <div>
          <DatePickerTextFieldFormik
            id="claim_reported_date_end_date"
            label="Report End Date"
            className={classes.textField}
            disableFuture
            fullWidth
            disabled={isSubmitting}
            clearable
          />
        </div>
      </div>

      <Heading variant={Heading.TYPES.H3}>Contact Details</Heading>

      <div className="mb-32 grid grid-cols-5 gap-32">
        <div className="mt-12">
          <CheckboxFormik id="is_company" label="Contact is a Company" />
        </div>
        {values['is_company'] ? (
          <div>
            <TextFieldFormik
              id="contact_company_name"
              label="Company Name"
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
            />
          </div>
        ) : (
          <>
            <div>
              <TextFieldFormik
                id="contact_first_name"
                label="First Name"
                className={classes.textField}
                fullWidth
                disabled={isSubmitting}
              />
            </div>

            <div>
              <TextFieldFormik
                id="contact_last_name"
                label="Last Name"
                className={classes.textField}
                fullWidth
                disabled={isSubmitting}
              />
            </div>
            {shouldShowSsn && (
              <div>
                <TextFieldFormik
                  id="contact_ssn"
                  label="Social Security Number"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </div>
            )}
          </>
        )}
      </div>

      <div className="mb-20 grid grid-cols-5 gap-32">
        <div>
          <TextFieldFormik
            id="zipcode"
            label="Zipcode"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>
        <div>
          <TextFieldFormik
            id="phone_number"
            label="Phone Number"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>
        <div>
          <TextFieldFormik
            id="email_address"
            label="Email Address"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>
      </div>

      {orgLevelSupportedLobs.includes('auto_claim') && (
        <>
          <Heading variant={Heading.TYPES.H3}>Auto Specific</Heading>
          <div className="mb-20 grid grid-cols-5 gap-32">
            <div>
              <YearPickerSelectTextFieldFormik
                id="vehicle_year"
                label="Year"
                fullWidth
                disabled={isSubmitting}
                className={classes.textField}
              />
            </div>
            <div>
              <TextFieldFormik
                id="vehicle_make"
                label="Make"
                fullWidth
                disabled={isSubmitting}
                className={classes.textField}
              />
            </div>
            <div>
              <TextFieldFormik
                id="vehicle_model"
                label="Model"
                fullWidth
                disabled={isSubmitting}
                className={classes.textField}
              />
            </div>
            <div>
              <TextFieldFormik
                id="vehicle_vin"
                label="VIN"
                fullWidth
                disabled={isSubmitting}
                className={classes.textField}
              />
            </div>
            <div>
              <TextFieldFormik
                id="vehicle_plate_number"
                label="Plate Number"
                fullWidth
                disabled={isSubmitting}
                className={classes.textField}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

AdvancedClaimsSearchFiltersFormik.propTypes = {};

export default AdvancedClaimsSearchPage;
