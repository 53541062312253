import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import { isoUtcDateTimeToLocal, serverDateTimeToLocal } from '../../DateTimeUtils';
import { getBasicFnolValues, reportAxiosError } from '../../Utils';
import { useContacts } from '../ContactsContext';
import { trackOldFnolClick } from '../Fnol/FnolScreen';
import useCurrentUserRoles from '../hooks/useCurrentUserRoles';
import { usePositionLeftZenDesk } from '../hooks/useZenDesk';
import LoadingDialog from '../LoadingDialog';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import { usePolicy } from '../PolicyContainer';

import DamagesDetailsFormikInner, {
  damagesDetailsFields,
  getDamagesDetailsValidationFields,
} from './DamagesDetailsCard';
import FnolHeader from './FnolHeader';
import IncidentDetailsFormikInner, {
  getIncidentDetailsValidationFields,
  incidentDetailsFields,
} from './IncidentDetailsCard';
import LossTakingConfirmationDialog from './LossTakingConfirmationDialog';

import { useStyles } from '../../assets/styles';

function HomeFnolPage({ onSubmit, claimValues, onSubmitDraft, fnolDraft, subOrganizationId }) {
  const { policy } = usePolicy();
  const { contacts } = useContacts();
  const { subOrganizationEnabled, subOrganizations } = useOrganization();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = React.useState(false);
  const [showLossConfirmationDialog, setShowLossConfirmationDialog] = useState(false);
  const [submittedNewClaim, setSubmittedNewClaim] = useState({});
  const [vendorsStatus, setVendorsStatus] = useState({});
  const emptyFnolStore = { damages: [], additional_components: [] };
  const [fnolStore, setFnolStore] = useState(
    fnolDraft ? fnolDraft.draft.partiesStore || emptyFnolStore : emptyFnolStore
  );
  const [draftLastUpdate, setDraftLastUpdate] = useState(
    fnolDraft ? serverDateTimeToLocal(fnolDraft.last_updated) : undefined
  );

  const { isUserPhoneRep } = useCurrentUserRoles();

  usePositionLeftZenDesk();

  const submitHomeFnol = (values, setSubmitting) => {
    axios
      .post('/api/v1/home_claims/fnol', {
        ...values,
        fnol_contacts_ids: contacts.map((contact) => contact.id),
      })
      .then((res) => {
        if (isUserPhoneRep) {
          setSubmittedNewClaim(res.data);
          setShowLossConfirmationDialog(true);
        } else {
          onSubmit(res.data.id);
        }

        setSubmitting(false);
        // TODO - Call callback with the claim display id and either insured email exists
      })
      .catch((error) => {
        setSubmitting(false);
        reportAxiosError(error);
      });
  };

  const handleSubmitDraft = async (values, setSubmitting) => {
    await onSubmitDraft({ ...values, fnolStore });
    setDraftLastUpdate(isoUtcDateTimeToLocal(new Date()));
    setSubmitting(false);
  };

  const mapPropsToFormikValues = () => {
    const INITIAL_FNOL_VALUES = {
      ...getBasicFnolValues(),
      ...incidentDetailsFields,
      ...damagesDetailsFields,
      sub_organization_id: subOrganizationId ?? '',
    };

    let initialValues = {
      ...INITIAL_FNOL_VALUES,
      ...policy,
      policy_id: policy.id,
      is_manual_policy: !!policy.is_manual,
      reporter: 'Insured', // because we're setting the insured as primary_contact, hence we assume it's reported by the insured
      primary_contact_id: policy.insured_contact.id,
      reporter_id: policy.insured_contact.id,
      loss_location: { ...policy.insured_property_location },
      verified_insured_info: !!policy.is_manual,
    };

    if (claimValues) {
      initialValues = { ...initialValues, ...claimValues };
      initialValues.should_lock_date_of_loss = !!claimValues.date_of_loss; // TODO: ugly hack! remove someday when we are more generic
    }

    return initialValues;
  };

  React.useEffect(() => {
    const getVendorsStatus = () => {
      axios
        .get('/api/v1/home_claims/vendors_status', { params: { organization_id: policy.organization_id } })
        .then((res) => {
          setVendorsStatus(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          reportAxiosError(error);
          setIsError(true);
        });
    };

    getVendorsStatus();
  }, [policy]);

  if (isLoading) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <Formik
      initialValues={{ ...mapPropsToFormikValues(), ...fnolDraft?.draft }}
      validationSchema={Yup.object().shape({
        ...getIncidentDetailsValidationFields(),
        ...getDamagesDetailsValidationFields(fnolStore, vendorsStatus),
        sub_organization_id:
          subOrganizationEnabled && policy.is_manual
            ? Yup.number()
                .required('Required')
                .oneOf(
                  subOrganizations.map((so) => so.id),
                  'Invalid sub organization'
                )
            : undefined,
      })}
      onSubmit={(values, formikBag) => {
        const { setSubmitting } = formikBag;
        setSubmitting(true);
        submitHomeFnol(values, setSubmitting);
        trackOldFnolClick(policy, 'old_home_fnol');
      }}
    >
      {(formikProps) => (
        <>
          <FnolHeader policy={policy} subOrganizationId={subOrganizationId} claimType="home_claim" />
          <Grid container alignItems="stretch">
            <Grid item md={6}>
              <div className={classes.cardDivRow}>
                <IncidentDetailsFormikInner setFieldValue={formikProps.setFieldValue} values={formikProps.values} />
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={classes.cardDivRow}>
                <DamagesDetailsFormikInner
                  fnolStore={fnolStore}
                  onChangeFnolStore={setFnolStore}
                  setFieldValue={formikProps.setFieldValue}
                  values={formikProps.values}
                  vendorsStatus={vendorsStatus}
                />
              </div>
            </Grid>
          </Grid>
          <div className={classes.cardDivRow}>
            <div className={classes.buttonsContainer}>
              {!policy.is_manual && (
                <>
                  {draftLastUpdate && (
                    <Typography
                      className={classes.cardDivRow}
                      color="textSecondary"
                    >{`Draft saved on ${draftLastUpdate}`}</Typography>
                  )}
                  <Button
                    onClick={async () => {
                      formikProps.setSubmitting(true);
                      await handleSubmitDraft(formikProps.values, formikProps.setSubmitting);
                    }}
                    variant="contained"
                    color="primary"
                    className={classes.leftButtonDialog}
                    disabled={formikProps.isSubmitting}
                  >
                    Save Draft
                  </Button>
                </>
              )}
              <Button
                onClick={formikProps.handleSubmit}
                variant="contained"
                color="primary"
                disabled={formikProps.isSubmitting}
              >
                Create FNOL
              </Button>
              {formikProps.isSubmitting && <LoadingDialog isError={false} track="Submit home fnol" />}
            </div>
          </div>
          <LossTakingConfirmationDialog
            open={showLossConfirmationDialog}
            claim={submittedNewClaim}
            vendorsStatus={vendorsStatus}
            onClose={() => {
              setShowLossConfirmationDialog(false);
              setSubmittedNewClaim({});
              onSubmit(0);
            }}
          />
        </>
      )}
    </Formik>
  );
}

HomeFnolPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  claimValues: PropTypes.object,
  onSubmitDraft: PropTypes.func.isRequired,
  fnolDraft: PropTypes.object,
  subOrganizationId: PropTypes.number,
};

export default HomeFnolPage;
