import React from 'react';

import { MainCard, PropertyDetails } from '../../../core';
import { PropertyDetailsRow } from '../../../core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { LegalActionEditButton } from '../../components/LegalActionEditButton';
import { EditLawsuitDetailsDialog } from '../../Dialogs/EditLawsuitDetails/EditLawsuitDetailsDialog';
import { LEGAL_ACTION_FIELDS } from '../../FormFragments/FormFieldsDefinitions';
import { useLegalAction } from '../../hooks/useLegalAction';
import type { Option } from '../../hooks/useLegalActionsConfiguration';
import { useLegalActionsConfiguration } from '../../hooks/useLegalActionsConfiguration';
import { getLabelByKey, isOptionOther } from '../../LegalActionsUtils';

import { DatesOfServiceCard } from './DateOfService/DatesOfServiceCard';

export const LawsuitDetailsCard: React.FC = () => {
  const { legalAction, reloadLegalAction } = useLegalAction();
  const [isEditLawsuitDetailsDialogOpen, setIsEditLawsuitDetailsDialogOpen] = React.useState(false);
  const { lawsuitTypeOptions, natureOfDisputeOptions } = useLegalActionsConfiguration();

  return (
    <>
      <MainCard
        type="elevated"
        title="Lawsuit Details"
        action={<LegalActionEditButton onClick={() => setIsEditLawsuitDetailsDialogOpen(true)} />}
        collapsible
        openByDefault
      >
        <div className="flex w-3/4">
          <PropertyDetailsRow>
            <PropertyDetails
              title={LEGAL_ACTION_FIELDS.lawsuitType.label}
              text={getLabelByKey(lawsuitTypeOptions as Option[], legalAction.lawsuit_type)}
            />
            <PropertyDetails
              title={LEGAL_ACTION_FIELDS.natureOfDispute.label}
              text={getLabelByKey(natureOfDisputeOptions as Option[], legalAction.nature_of_dispute)}
            />
            {isOptionOther(natureOfDisputeOptions as Option[], legalAction.nature_of_dispute) ? (
              <PropertyDetails
                title={LEGAL_ACTION_FIELDS.natureOfDisputeText.label}
                text={legalAction.nature_of_dispute_text}
              />
            ) : null}
          </PropertyDetailsRow>
        </div>
        <PropertyDetails title={LEGAL_ACTION_FIELDS.complaintNumber.label} text={legalAction.complaint_number} />
        <DatesOfServiceCard />
      </MainCard>
      {isEditLawsuitDetailsDialogOpen ? (
        <EditLawsuitDetailsDialog
          legalAction={legalAction}
          onClose={() => setIsEditLawsuitDetailsDialogOpen(false)}
          onSubmitSuccess={reloadLegalAction}
        />
      ) : null}
    </>
  );
};
