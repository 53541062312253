import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@material-ui/core';
import axios from 'axios';

import MenuItem from '~/components/core/Atomic/MenuItem';

import { reportAxiosError } from '../../../../Utils';
import { useClaim } from '../../../ClaimContainer';
import { useCms } from '../../../hooks/useCms';
import LoadingIndicator from '../../../LoadingIndicator';

const ClaimQAMenu = (props) => {
  const { anchorEl, onClose, onSelectExposure } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [exposureQas, setExposureQas] = useState([]);
  const { claim } = useClaim();
  const { user } = useCms();

  useEffect(() => {
    async function getAllExposureQas() {
      try {
        const allowedExposures = claim.exposures.filter((exposure) =>
          user.all_subordinates_recursive_users_ids.includes(exposure.effective_handling_adjuster_id)
        );
        const qaPromises = allowedExposures.map((exposure) =>
          axios.get(`/api/v1/claims/${claim.id}/qa`, { params: { exposure_id: exposure.id } })
        );

        const qaRets = await Promise.all(qaPromises);
        setExposureQas(qaRets.map((qaRet, idx) => ({ exposure: allowedExposures[idx], qa: qaRet.data })));
        setIsLoading(false);
      } catch (error) {
        reportAxiosError(error);
        setIsError(true);
      }
    }

    getAllExposureQas();
  }, [claim.exposures, claim.id, user.all_subordinates_recursive_users_ids]);

  const getMenuItemText = (exposure, qa) => {
    let text = exposure.label_text;
    if (!qa) {
      return text;
    }

    if (!qa.review_submitted_datetime) {
      return (
        <span>
          {text} - <em>In Progress</em>
        </span>
      );
    }

    return (
      <span>
        {text} - <strong>Submitted</strong>
      </span>
    );
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={!!anchorEl}
      onClose={onClose}
      getContentAnchorEl={null}
    >
      {isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : (
        exposureQas.map(({ exposure, qa }) => (
          <MenuItem
            key={exposure.id}
            disabled={!exposure.is_closed}
            onClick={() => {
              onSelectExposure(exposure.id);
              onClose();
            }}
          >
            {getMenuItemText(exposure, qa)}
          </MenuItem>
        ))
      )}
    </Menu>
  );
};

ClaimQAMenu.propTypes = {
  anchorEl: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectExposure: PropTypes.func.isRequired,
};

export default ClaimQAMenu;
