import axios from 'axios';

/**
 * Authentication methods
 * original implementation: https://github.com/bryanthowell-tableau/tableau_js_api/blob/master/auth_helpers.js
 */

const AUTH_TTL_IN_MS = 1000 * 60; // 1 minute
const TABLEAU_AUTH_TIME = 'tableau_auth_ttl';
const REDEMPTION_ERROR_MSG = 'Tableau trusted authentication failed';

// Load an image promise - fastest way to create an auth request to tableau
const imgLoadPromise = async (redemptionUrl) => {
  await new Promise((resolve, reject) => {
    const redemptionImg = new Image();
    redemptionImg.onload = () => resolve();
    redemptionImg.onerror = () => reject(new Error(REDEMPTION_ERROR_MSG));
    redemptionImg.src = redemptionUrl;
  });
};

const setTableauAuthTime = () => {
  const time = new Date().getTime().toString();
  window.localStorage.setItem(TABLEAU_AUTH_TIME, time);
};

const clearTableauAuthTime = () => {
  window.localStorage.removeItem(TABLEAU_AUTH_TIME);
};

const isTableauAuthTimeExpired = () => {
  const currTime = new Date().getTime();
  const prevTime = Number(window.localStorage.getItem(TABLEAU_AUTH_TIME));
  return currTime - prevTime > AUTH_TTL_IN_MS;
};

const buildAuthUrlPng = ({ serverBaseUrl, site, workbook, view, token }) =>
  `${serverBaseUrl}/trusted/${token}/t/${site}/views/${workbook}/${view}.png`;

const authenticateTableauServer = async ({ serverBaseUrl, site, workbook, view, authEndpointUrl, onError }) => {
  try {
    if (!isTableauAuthTimeExpired()) return;

    const { data } = await axios.get(authEndpointUrl);
    const { token } = data;
    const authUrl = buildAuthUrlPng({ serverBaseUrl, site, workbook, view, token });
    await imgLoadPromise(authUrl);
    setTableauAuthTime();
    return token;
  } catch (error) {
    onError(error);
    throw error;
  }
};

export { authenticateTableauServer, clearTableauAuthTime };
