import React from 'react';

import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import { PROS_AND_CONS_FIELDS } from '~/components/LegalActions/FormFragments/FormFieldsDefinitions';
import { useLegalActionsConfiguration } from '~/components/LegalActions/hooks/useLegalActionsConfiguration';
import TextFieldFormik from '~/components/TextFieldFormik';

export const ProsAndConsForm = () => {
  const { prosAndConsOptions } = useLegalActionsConfiguration();

  return (
    <>
      <RadioButtonGroupFormik
        btnClassName="bg-slate-100"
        sizeButtonsEvenly
        id={PROS_AND_CONS_FIELDS.proConType.key}
        label={PROS_AND_CONS_FIELDS.proConType.label}
        options={prosAndConsOptions.map((option) => ({
          text: option.description,
          optionValue: option.key,
        }))}
      />
      <TextFieldFormik id={PROS_AND_CONS_FIELDS.rationale.key} label={PROS_AND_CONS_FIELDS.rationale.label} fullWidth />
    </>
  );
};
