import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';

import { isPolicyManuallyFilled, reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions, Text } from '../../../../core';
import useOrganization from '../../../../OrganizationContext';
import { getAutoFieldsConfig } from '../../GenericPolicyPage';
import EditCoveredEntityDialog from '../EditCoveredEntityDialog/EditCoveredEntityDialog';

import styles from './UnlistedVehicleCard.module.scss';

const UnlistedVehicleCard = ({ claim, onClaimUpdate, coveredVehicle, isRental }) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const { vehicleTypesDict } = useOrganization();
  const [showEditCoveredEntityModal, setShowEditCoveredEntityModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { Icon, configuredFields, defaultFields } = getAutoFieldsConfig({
    incidentConfiguration,
    coveredObject: coveredVehicle,
    vehicleTypesDict,
    unlisted: true,
  });
  const isManualPolicy = isPolicyManuallyFilled(claim);
  const policyPrefix = `${isManualPolicy ? '' : '/api_policy'}`;

  const handleCreateCoveredEntityFromUnlisted = async (values) => {
    setIsSaving(true);
    try {
      await axios.patch(
        `/api/v1/auto_claims/${claim.id}${policyPrefix}/unlisted_vehicle/vehicle/${coveredVehicle.id}`,
        values['vehicle']
      );
      await axios.patch(`/api/v1/auto_claims/${claim.id}${policyPrefix}/unlisted_vehicle/set_listed`);
      await onClaimUpdate();
      setShowEditCoveredEntityModal(false);
    } catch (error) {
      reportAxiosError(error);
    }
    setIsSaving(false);
  };

  const Label = () => (
    <div className={styles.label}>
      <span className={styles.labelIcon}>
        <Icon />
      </span>
      <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD}>
        {coveredVehicle?.vehicle?.display_name}
      </Text>
    </div>
  );

  return (
    <CardDialog
      headerStyle={{ padding: '0 0 24px' }}
      containerStyle={{ padding: '24px', marginBottom: '24px' }}
      contentStyle={{ padding: '0' }}
      title={isRental ? 'Rental First Party' : 'Unlisted First Party'}
    >
      <AlertBanner
        title="Please Note"
        alertType={AlertBanner.ALERT_TYPES.INFO}
        note={`There is an ${
          isRental ? 'rental' : 'unlisted'
        } first party involved vehicle. Please check if the policy covers this vehicle.`}
        withIcon
      />
      <RestrictedPermissions
        action={isManualPolicy ? PERMISSION_ACTIONS.MANUAL_POLICY : PERMISSION_ACTIONS.API_POLICY}
        verb={PERMISSION_VERBS.WRITE}
      >
        <div className={styles.containerWithBorder}>
          <Grid container spacing={2} justify="space-between" alignItems="center" wrap="nowrap">
            <Grid item>
              <Label />
            </Grid>
            <Grid item>
              <CancelButton content="ADD TO POLICY" onClick={() => setShowEditCoveredEntityModal(true)} />
            </Grid>
          </Grid>
        </div>
      </RestrictedPermissions>
      {showEditCoveredEntityModal && (
        <EditCoveredEntityDialog
          icon={Icon}
          coveredObjectTypeLabel="Add Unlisted Vehicle to policy"
          defaultFields={defaultFields}
          configuredFields={configuredFields}
          coveredEntity={coveredVehicle}
          onSaveClick={(values) => handleCreateCoveredEntityFromUnlisted(values)}
          onClose={() => !isSaving && setShowEditCoveredEntityModal(false)}
          isSaving={isSaving}
          titleWithoutPrefix
        />
      )}
    </CardDialog>
  );
};

UnlistedVehicleCard.propTypes = {
  claim: PropTypes.object.isRequired,
  onClaimUpdate: PropTypes.func.isRequired,
  coveredVehicle: PropTypes.object.isRequired,
  isRental: PropTypes.bool,
};

export default UnlistedVehicleCard;
