import React from 'react';
import PropTypes from 'prop-types';

import { useCms } from '../hooks/useCms';
import TooltipLock from '../TooltipLock';

function ExposureTooltipedLock(props) {
  const {
    is_closed,
    coverage_decision,
    damage_assessment,
    methods_of_inspection,
    method_of_resolution,
    is_pending_request_exists,
    is_coverage_issue_exists,
  } = props.exposure;
  const { user } = useCms();
  let title = '';

  if (user.role.is_view_only) {
    title = '';
  } else if (is_pending_request_exists) {
    title = 'Pending request exists';
  } else if (is_closed) {
    title = 'Exposure Closed';
  } else if (coverage_decision && coverage_decision.coverage_decision === 'denied') {
    title = 'Coverage Denied';
  } else if (is_coverage_issue_exists) {
    title = 'Coverage Issue';
  } else if (!methods_of_inspection.filter((moi) => moi.is_active).length) {
    title = 'Assign MOI first';
  } else if (!damage_assessment) {
    title = 'Create damage assessment first';
  } else if (!(damage_assessment.is_estimation_report_exists || damage_assessment.amount !== null)) {
    title = 'Complete damage assessment by adding an estimate or specifying the amount';
  } else if (!method_of_resolution) {
    title = 'Choose MOR first';
  } else {
    throw Error('No Locked is needed');
  }

  return <TooltipLock title={title} />;
}

ExposureTooltipedLock.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export default ExposureTooltipedLock;
