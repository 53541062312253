import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { intersection } from 'lodash';
import * as Yup from 'yup';

import useFetchAdjusters from '~/Adjuster/useFetchAdjusters';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../Utils';

import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { MultiSelectIdLabelTextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function ShareNotificationsContainer(props) {
  const { currentSharedUsersIds, onClose, onUpdate } = props;
  const classes = useStyles();
  const { user } = useCms();
  const { possibleAdjustersWithDaSpecialist } = useFetchAdjusters(user.organization_id);

  let possibleAdjusters = possibleAdjustersWithDaSpecialist.filter((adjuster) => adjuster.id !== user.id);
  const possibleAdjustersIdLabel = possibleAdjusters.map((adjuster) => ({ id: adjuster.id, label: adjuster.username }));
  const sharedAdjusterIds = intersection(
    currentSharedUsersIds,
    possibleAdjusters.map((adjuster) => adjuster.id)
  );

  return (
    <Formik
      initialValues={{
        share_notification_with_users_ids: sharedAdjusterIds,
      }}
      validationSchema={Yup.object().shape({
        share_notification_with_users_ids: Yup.array(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await axios.post('/api/v1/users/me/share_notifications', values);
          await onUpdate();
          onClose();
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        return (
          <CardDialog title="Share Notifications" isDialog onClose={onClose}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <MultiSelectIdLabelTextFieldFormik
                  id="share_notification_with_users_ids"
                  select
                  label="Adjuster"
                  className={classes.textField}
                  fullWidth
                  options={possibleAdjustersIdLabel}
                  renderValue={(selectedAdjusters) => selectedAdjusters.map((adjuster) => adjuster.label).join(', ')}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.buttonsContainer}>
                  <CancelButton onClick={onClose} autoFocus />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={formikProps.handleSubmit}
                    disabled={formikProps.isSubmitting}
                  >
                    Set
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

ShareNotificationsContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  currentSharedUsersIds: PropTypes.array.isRequired,
};

export { ShareNotificationsContainer };
