import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../core/SvgIcon';

const ThumbDownIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" disableStrokeColor {...props}>
    <path
      d="M7.5 3.75H3C2.6 3.75 2.22 3.91 1.94 4.19C1.66 4.47 1.5 4.86 1.5 5.25V13.5C1.5 13.9 1.66 14.28 1.94 14.56C2.22 14.84 2.61 15 3 15H7.04L10.58 22.09C10.71 22.34 10.97 22.5 11.25 22.5C12.25 22.5 13.19 22.11 13.9 21.4C14.61 20.69 15 19.75 15 18.75V17.25H20.05C20.34 17.25 20.69 17.18 20.98 17.05C21.27 16.92 21.53 16.73 21.74 16.49C21.95 16.25 22.11 15.97 22.2 15.66C22.29 15.35 22.32 15.03 22.28 14.71L21.16 5.72C21.09 5.18 20.83 4.68 20.42 4.32C20.01 3.96 19.49 3.76 18.94 3.75H7.5ZM8.25 5.25H18.93C19.11 5.25 19.28 5.32 19.42 5.44C19.56 5.56 19.64 5.73 19.67 5.91L20.79 14.91C20.8 15.02 20.79 15.13 20.77 15.23C20.74 15.33 20.69 15.42 20.62 15.5C20.55 15.58 20.47 15.64 20.37 15.69C20.27 15.73 20.17 15.76 20.06 15.76H14.25C13.84 15.76 13.5 16.1 13.5 16.51V18.76C13.5 19.35 13.26 19.93 12.84 20.35C12.53 20.66 12.12 20.88 11.69 20.96L8.25 14.08V5.26V5.25ZM3 13.5V5.25H6.75V13.5H3Z"
      fill={iconColor}
    />
  </SvgIcon>
);

ThumbDownIcon.propTypes = {
  iconColor: PropTypes.string,
};
export default ThumbDownIcon;
